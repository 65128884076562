import {QueryResult} from '@apollo/react-common';
import {envIsLocal} from '@wandb/common/config';
import React from 'react';

import {
  EntityStorageQuery,
  Exact,
  Maybe,
  useEntityStorageQuery,
} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {bytesInGB, freeStorage} from '../util/storage';
import * as urls from '../util/urls';
import {
  FREE_PERSONAL_ACCOUNT,
  getStorageGBLimitCookie,
  getSubscriptionTypeCookie,
} from '../util/usageLimit';
import NudgeBar from './NudgeBar';

type UserEntity = QueryResult<
  EntityStorageQuery,
  Exact<{
    entityName?: Maybe<string> | undefined;
    enableReferenceTracking?: Maybe<boolean> | undefined;
  }>
>;

const StorageNudgeBar: React.FC = React.memo(() => {
  const viewer = useViewer();
  const hasTeam =
    (viewer?.teams.edges.filter(e => e.node.isTeam)?.length ?? 0) > 0;
  const hasOrg = (viewer?.organizations?.length ?? 0) > 0;
  const userEntity = useEntityStorageQuery({
    variables: {
      entityName: viewer?.username,
      enableReferenceTracking: false,
    },
  });

  const isOverLimit = isOverStorageLimit(userEntity);

  const mainText = `You’re exceeding the ${
    freeStorage / bytesInGB
  } GB free plan for storage and artifacts. Upgrade to keep growing your ML projects.`;
  const linkText = 'Manage plan →';
  const link = urls.subscriptions();

  return (
    <>
      {/* TODO?: Change hasTeam, hasOrg to be onTeamPage, onOrg */}
      {isOverLimit && !envIsLocal && !hasTeam && !hasOrg && (
        <NudgeBar
          content={mainText}
          trackingLocation={'storage nudge bar'}
          linkText={linkText}
          link={link}
        />
      )}
    </>
  );
});

function isOverStorageLimit(userEntity: UserEntity): boolean {
  // Simulate the storage nudge bar to audit the behavior
  // from subscription limit configured by W&B internal users
  const impersonatedSubscriptionType = getSubscriptionTypeCookie();
  const impersonatedStorageGB = getStorageGBLimitCookie();

  const impersonatingStorageLimit =
    impersonatedSubscriptionType === FREE_PERSONAL_ACCOUNT &&
    impersonatedStorageGB != null;

  if (!impersonatingStorageLimit) {
    return (
      userEntity.data?.entity?.storageBytes > freeStorage ||
      userEntity.data?.entity?.referenceBytes > freeStorage
    );
  }

  return impersonatedStorageGB! * bytesInGB > freeStorage;
}

export default StorageNudgeBar;
