import React from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {RenderElementProps, useSelected} from 'slate-react';

import * as S from './captivate.styles';
import {BlockWrapper} from './drag-drop';

export interface Captivate extends Element {
  type: 'captivate';
  captivateID: string;
}

export const isCaptivate = (node: Node): node is Captivate =>
  node.type === 'captivate';

export const CaptivateElement: React.FC<
  RenderElementProps & {
    element: Captivate;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();
  return (
    <BlockWrapper attributes={attributes} element={element}>
      {/* <div style="width: 100%; height: 170px; margin-bottom: 20px; border-radius: 10px; overflow:hidden;">
        <iframe
          style="width: 100%; height: 170px;"
          src="https://player.captivate.fm/episode/007d646a-ea29-435c-a951-0207c8933286"></iframe>
      </div> */}
      <S.EmbeddedIframe
        selected={selected}
        src={`https://player.captivate.fm/episode/${element.captivateID}`}
        title="captivate"
        frameBorder="no"
        scrolling="no"
        seamless
      />
      {children}
    </BlockWrapper>
  );
};

const CAPTIVATE_REGEX =
  /^(?<url>https?:\/\/player\.captivate\.fm\/episode\/(?<captivateID>(\w|-)+))\/?(\?[^#]*)?(#t=.+)?$/;

export const withCaptivate = <T extends Editor>(editor: T) => {
  const {isVoid, insertText} = editor;

  editor.isVoid = element => {
    return isCaptivate(element) ? true : isVoid(element);
  };

  editor.insertText = text => {
    const match = CAPTIVATE_REGEX.exec(text);
    if (match?.groups != null) {
      const {captivateID} = match.groups;
      insertCaptivateElement(captivateID);
      return;
    }

    insertText(text);
  };

  return editor;

  function insertCaptivateElement(captivateID: string) {
    const node: Node = {
      type: 'captivate',
      captivateID,
      children: [{text: ''}],
    };

    Transforms.splitNodes(editor);
    Transforms.insertNodes(editor, node);
  }
};
