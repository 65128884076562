import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {toast} from '@wandb/common/components/elements/Toast';
import {DragHandle} from '@wandb/common/containers/DragDropContainer';
import {ImageExportType} from '@wandb/common/util/panelExport';
import {makeDidPropsOrStateChange} from '@wandb/common/util/shouldUpdate';
import classNames from 'classnames';
import {cloneDeep, find} from 'lodash';
import moment from 'moment';
import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {useRef} from 'react';
import {Popup} from 'semantic-ui-react';

import {ViewSource} from '../../generated/graphql';
import {useApolloClient, useWaitToLoadTilOnScreen} from '../../state/hooks';
import {usePanelSearchDebouncedContext} from '../../state/panelbank/PanelSearchContextProvider';
import {saveView, usePanelComments} from '../../state/reports/hooks';
import {RunQueryContext} from '../../state/runs/context';
import * as RunHooks from '../../state/runs/hooks';
import {useViewer} from '../../state/viewer/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as InteractStateActions from '../../state/views/interactState/actions';
import * as InteractStateContext from '../../state/views/interactState/context';
import * as PanelBankSectionConfigActions from '../../state/views/panelBankSectionConfig/actions';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsViewTypes from '../../state/views/panelSettings/types';
import {
  EMPTY_PANEL_BANK_CONFIG,
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
  searchQueryMatchesPanel,
} from '../../util/panelbank';
import {getFullWidthPanelLayout} from '../../util/panelbankGrid';
import {getPanelSpec, PanelCompRedux} from '../../util/panels';
import * as Report from '../../util/report';
import {useFeatureFlagVegaEnabled} from '../../util/useFeatureFlag';
import PanelEditor from '../PanelEditor';
import {PanelImageExportModal} from '../PanelImageExportModal';
import {PanelActionsMenu} from './PanelActionsMenu';
import {ExportType} from './PanelActionsMenu';
import {PanelContext} from './PanelContextProvider';
import {PanelExportAPI} from './PanelExportAPI';
import {PanelExportCSV} from './PanelExportCSV';
import {ShareReportTriggerWrapper} from './ShareReportTriggerWrapper';
import {SinglePanelInspectorContainer} from './SinglePanelInspectorContainer';

enum PanelPopupMode {
  EDIT,
  FULL_SCREEN,
  VIEW_SPEC,
}

interface PanelProps {
  currentHeight: number;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  disableRunLinks?: boolean;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  shouldTurnOnComments: boolean;
  onContentHeightChange?(h: number): void;
}

export const Panel: FC<PanelProps> = props => {
  const domRef = useRef<HTMLDivElement>(null);
  const shouldRender = useWaitToLoadTilOnScreen(domRef); // TODO - change name?

  return (
    <div ref={domRef}>
      {shouldRender ? (
        <EditablePanel {...props} />
      ) : (
        <div // TODO - convert to .styles.ts
          style={{
            width: '100%',
            height: '100%',
            border: '1px solid #eee',
            backgroundColor: 'white',
          }}
        />
      )}
    </div>
  );
};

const EditablePanelComp: FC<PanelProps> = ({
  shouldTurnOnComments,
  panelSettings,
  disableRunLinks,
  currentHeight,
  defaultMoveToSectionRef,
  onContentHeightChange,
}) => {
  const {
    customRunColorsRef,
    isReadOnly,
    panelBankConfigRef,
    panelBankSectionConfigRef,
    panelRef,
    runSetRefs,
  } = useContext(PanelContext);

  const panel = ViewHooks.useWhole(panelRef);
  const panelType = panel.viewType;
  const panelSpec = getPanelSpec(panelType);

  const deletePanel = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.deletePanel
  );
  const onRemovePanel = () => deletePanel(panelRef, panelBankConfigRef);

  const panelSelection = InteractStateContext.useInteractState(
    interactState => interactState.panelSelection
  );
  const setPanelSelection = InteractStateContext.useInteractStateAction(
    InteractStateActions.setPanelSelection
  );

  let panelTitle: string | undefined;
  if (panelSpec && panelSpec.getTitleFromConfig && panel && panel.config) {
    panelTitle = panelSpec.getTitleFromConfig(panel.config as any);
  }

  const {panelCommentCount, openPanelComments, addComment, isHighlighted} =
    usePanelComments({panelRef});

  const runSets = ViewHooks.useWholeArray(runSetRefs);
  const customRunColors = ViewHooks.useWhole(customRunColorsRef);
  const {mergeFilters, runSetName} = useContext(RunQueryContext);
  const {entityName, projectName} = RunHooks.useRunSetsQuery(runSetRefs);

  const viewer = useViewer();
  const client = useApolloClient();

  const vegaPanelEnabled = useFeatureFlagVegaEnabled(entityName);

  const [reportID, setReportID] = useState<undefined | string>();
  const [reportName, setReportName] = useState('');

  const [exportType, setExportType] = useState<ExportType>();
  const [imageExportType, setImageExportType] = useState<ImageExportType>();
  const unsetExportType = useCallback(() => setExportType(undefined), []);

  const {isSearching, debouncedSearchQuery} = usePanelSearchDebouncedContext();

  const isSelected = useMemo(
    () => find(panelSelection, panelRef) != null,
    [panelRef, panelSelection]
  );

  const isSearchMiss = useMemo(
    () => isSearching && !searchQueryMatchesPanel(debouncedSearchQuery, panel),
    [debouncedSearchQuery, isSearching, panel]
  );

  const panelViewTypeClass = useMemo(
    () => panelType.toLowerCase().replace(' ', '-') + '-container',
    [panelType]
  );

  const panelClassNames = useMemo(() => {
    return classNames('editable-panel', panelViewTypeClass, {
      'editable-panel-markdown': panelType === 'Markdown Panel',
      'panel-selected': isSelected,
      'panel-highlighted': isHighlighted,
      'search-miss': isSearchMiss,
    });
  }, [isHighlighted, isSearchMiss, isSelected, panelType, panelViewTypeClass]);

  const viewerIsMemberOfTeam = viewer?.teams.edges.some(
    edge => edge.node.name === entityName
  );

  const sharePanel = useCallback(
    async (analyticsOrigin: string) => {
      const report = Report.fromSection(
        {
          runSets,
          customRunColors,
          settings: panelSettings ?? undefined,
          panelBankConfig: cloneDeep(EMPTY_PANEL_BANK_CONFIG),
          panelBankSectionConfig: {
            ...cloneDeep(EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT),
            panels: [{...panel, layout: getFullWidthPanelLayout()}],
          },
        },
        {mergeFilters, runSetName, noSectionHeader: true}
      );

      const name = `${panelTitle} (${moment().format('YY/MM/DD HH:mm:ss')})`;
      const savedView = await saveView({
        client,
        entityName,
        projectName,
        reportName: name,
        draft: false,
        config: report,
        createdUsing: ViewSource.WandbUiSharePanel,
      });
      if (savedView.id == null) {
        toast('Error occured while trying to share panel');
      } else {
        setReportID(savedView.id);
        setReportName(name);
      }

      window.analytics?.track('Share Panel Clicked', {
        reportID: savedView.id,
        location: analyticsOrigin,
      });
    },
    [
      client,
      customRunColors,
      entityName,
      mergeFilters,
      panel,
      panelSettings,
      panelTitle,
      projectName,
      runSetName,
      runSets,
    ]
  );

  const [panelPopupMode, setPanelPopupMode] = useState<PanelPopupMode>();
  const getPanelEditor = () => {
    if (panelPopupMode == null) {
      return;
    }

    if (panelPopupMode === PanelPopupMode.EDIT && panelSpec.useInspector) {
      return (
        <SinglePanelInspectorContainer
          panelRef={panelRef}
          customRunColorsRef={customRunColorsRef}
          runSetRefs={runSetRefs}
          panelSettings={panelSettings}
          onClose={() => setPanelPopupMode(undefined)}
        />
      );
    }

    const baseProps = {
      panelSpec,
      panelBankSectionConfigRef,
      customRunColorsRef,
      runSetRefs,
      panelRef,
      panelSettings,
      currentHeight,
      onCancel: () => setPanelPopupMode(undefined),
      onRemove: onRemovePanel,
      onOK: () => setPanelPopupMode(undefined),
    };
    const panelPopupProps = {
      [PanelPopupMode.EDIT]: {
        editable: true,
      },
      [PanelPopupMode.FULL_SCREEN]: {
        editable: false,
        disableRunLinks,
      },
      [PanelPopupMode.VIEW_SPEC]: {
        editable: true,
        initialConfigState: {editMode: true},
      },
    };

    return <PanelEditor {...baseProps} {...panelPopupProps[panelPopupMode]} />;
  };

  return (
    <>
      {getPanelEditor()}
      <div
        className={panelClassNames}
        onMouseDown={e => {
          if (viewer?.userInfo?.bio?.includes('INSPECTOR')) {
            if (e.metaKey) {
              document.getSelection()?.removeAllRanges();
              setPanelSelection(panelSelection.concat([panelRef]));
            } else {
              setPanelSelection([panelRef]);
            }
          }
        }}>
        {!isReadOnly && (
          <DragHandle
            key={`draghandle-${panelRef.id}`}
            className="draggable-handle"
            partRef={panelRef}>
            <LegacyWBIcon title="" name="handle" />
          </DragHandle>
        )}
        <div className="editable-panel__actions">
          {/* Share panel */}
          {!isReadOnly && (
            <Popup
              content="Share panel"
              inverted
              size="mini"
              position="top center"
              trigger={
                <LegacyWBIcon
                  name="share"
                  className="panel-action"
                  onClick={() => {
                    sharePanel('panel icon');
                  }}
                />
              }
            />
          )}
          {/* Edit panel */}
          {!isReadOnly &&
            !panelSpec.noEditMode &&
            (panelType !== 'Vega' || vegaPanelEnabled) && (
              <Popup
                content="Edit panel"
                inverted
                size="mini"
                position="top center"
                trigger={
                  <LegacyWBIcon
                    name="edit"
                    className="panel-action hide-in-hidden-panels"
                    data-test="edit-panel-button"
                    onClick={() => setPanelPopupMode(PanelPopupMode.EDIT)}
                  />
                }
              />
            )}
          {/* View spec */}
          {isReadOnly && panelType === 'Vega2' && (
            <Popup
              content="View panel spec"
              size="mini"
              position="top center"
              trigger={
                <LegacyWBIcon
                  name="show"
                  className="panel-action"
                  onClick={() => setPanelPopupMode(PanelPopupMode.VIEW_SPEC)}
                />
              }
            />
          )}
          {/* View full screen */}
          <Popup
            content="View full screen"
            inverted
            size="mini"
            position="top center"
            trigger={
              <LegacyWBIcon
                name="fullscreen"
                title="View fullscreen"
                className="panel-action"
                onClick={() => setPanelPopupMode(PanelPopupMode.FULL_SCREEN)}
              />
            }
          />
          {isReadOnly && shouldTurnOnComments && (
            <Popup
              content="Add a comment"
              inverted
              size="mini"
              position="top center"
              trigger={
                <LegacyWBIcon
                  name="chat"
                  title="Add comment"
                  className="panel-action"
                  onClick={addComment}
                />
              }
            />
          )}
          {/* Three-dot popup menu */}
          {!isReadOnly && (
            <>
              {reportID && (
                <ShareReportTriggerWrapper
                  reportID={reportID}
                  reportName={reportName}
                  unsetReportID={() => setReportID(undefined)}
                  userIsMemberOfTeam={viewerIsMemberOfTeam}
                />
              )}
              <PanelActionsMenu
                sharePanel={sharePanel}
                panelSpec={panelSpec}
                setExportType={setExportType}
                setImageExportType={setImageExportType}
                defaultMoveToSectionRef={defaultMoveToSectionRef}
              />
              <PanelExportAPI
                open={exportType === ExportType.API}
                onClose={unsetExportType}
              />
              <PanelExportCSV
                open={exportType === ExportType.CSV}
                onClose={unsetExportType}
                panelSettings={panelSettings}
              />
              {imageExportType && (
                <PanelImageExportModal
                  open={exportType === ExportType.Image}
                  type={imageExportType}
                  onClose={unsetExportType}
                  panelSettings={panelSettings}
                  panelSpec={panelSpec}
                />
              )}
            </>
          )}
          {shouldTurnOnComments &&
            panelCommentCount != null &&
            panelCommentCount > 0 && (
              <Popup
                content="View comments"
                inverted
                size="mini"
                position="top center"
                trigger={
                  <div className="panel-comments" onClick={openPanelComments}>
                    <span>{panelCommentCount}</span>
                  </div>
                }
              />
            )}
        </div>
        <div
          className="editable-panel__content"
          // editablePanelDOMID from reportExport.ts, not imported for code-splitting
          id={'editable-panel-' + panel.__id__}>
          {/* TODO - refactor so PanelCompRedux can use Context/Provider */}
          <PanelCompRedux
            configMode={false}
            runSetRefs={runSetRefs}
            panelRef={panelRef}
            panelSettings={panelSettings}
            customRunColorsRef={customRunColorsRef}
            currentHeight={currentHeight}
            dimensions={undefined as any}
            readOnly={isReadOnly}
            disableRunLinks={disableRunLinks}
            onContentHeightChange={onContentHeightChange}
          />
        </div>
      </div>
    </>
  );
};

const EditablePanel = memo(EditablePanelComp, (prevProps, nextProps) => {
  const didPropsOrStateChange = makeDidPropsOrStateChange({
    name: 'EditablePanel',
    deep: ['pageQuery', 'panel'],
    ignoreFunctions: true,
    ignoreJSX: true,
    debug: false,
    verbose: false,
  });

  return didPropsOrStateChange(prevProps, nextProps);
});
