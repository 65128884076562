import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {assertUnreachable} from '@wandb/common/util/types';
import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import {ApolloQueryResult} from 'apollo-client';
import classnames from 'classnames';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Icon} from 'semantic-ui-react';

import {
  Artifact,
  ProjectArtifactsQuery,
  useArtifactCollectionQuery,
  useMoveArtifactCollectionMutation,
  useUpdateArtifactCollectionMutation,
  useUpdateArtifactPortfolioMutation,
} from '../generated/graphql';
import {makeArtifactMembershipIdentifier} from '../util/artifacts';
import * as Urls from '../util/urls';
import * as S from './ArtifactSidebar.styles';
import {
  DeleteArtifactModal,
  MoveArtifactModal,
  RenameArtifactModal,
} from './ArtifactSidebarActions';
import {ArtifactCompareButton} from './ArtifactSidebarVersion';

interface ArtifactMin {
  id: string;
  name: string;
}

type ArtifactHeaderPopupDropdownProps = {
  onRenameArtifact?: () => void;
  onMoveArtifact?: () => void;
  onDeleteArtifact?: () => void;
};

export const ArtifactHeaderPopupDropdown = (
  props: ArtifactHeaderPopupDropdownProps
) => {
  const {onRenameArtifact, onMoveArtifact, onDeleteArtifact} = props;
  const options = useMemo(() => {
    const opts: WBMenuOption[] = [];

    if (onRenameArtifact != null) {
      opts.push({
        value: 'edit',
        name: 'Rename',
        icon: 'edit',
      });
    }
    if (onMoveArtifact != null) {
      opts.push({
        value: 'move',
        name: 'Move',
        icon: 'move',
      });
    }
    if (onDeleteArtifact != null) {
      opts.push({
        value: 'delete',
        name: 'Delete',
        icon: 'delete',
      });
    }

    return opts;
  }, [onDeleteArtifact, onMoveArtifact, onRenameArtifact]);

  type optionType = 'edit' | 'move' | 'delete';
  const optionRouter = (opt: optionType) => {
    switch (opt) {
      case 'edit':
        return onRenameArtifact != null && onRenameArtifact();
      case 'move':
        return onMoveArtifact != null && onMoveArtifact();
      case 'delete':
        return onDeleteArtifact != null && onDeleteArtifact();
    }
    return assertUnreachable(opt);
  };

  return (
    <WBPopupMenuTrigger
      options={options}
      onSelect={value => {
        optionRouter(value as optionType);
      }}>
      {({anchorRef, setOpen}) => (
        <S.EllipsesMenuButton
          className="row-actions-button"
          name="overflow"
          title="menu"
          ref={anchorRef}
          onClick={() => setOpen(true)}
        />
      )}
    </WBPopupMenuTrigger>
  );
};

type ArtifactItemVersionProps = Pick<
  Artifact,
  'commitHash' | 'digest' | 'state'
> & {
  to: string;
  isSelected: boolean;
  aliases: Array<{alias: string}>;
  isCompared: boolean;
  showCompare: boolean;
  onCompareClick: React.MouseEventHandler;
};

const ArtifactItemVersion: React.FC<ArtifactItemVersionProps> = props => {
  const {
    to,
    digest,
    state,
    aliases,
    isSelected,
    isCompared,
    onCompareClick,
    showCompare,
  } = props;

  const tags = aliases.map(a => a.alias);
  const version = tags.find(a => a.startsWith('v')) ?? digest;
  const aliasesStrings = tags.filter(t => t !== version);

  return (
    <Link
      to={to}
      onClick={() => window.analytics?.track('Artifact clicked', {version})}>
      <S.ArtifactItemString
        className={classnames('artifact-sidebar-item', 'version-option', {
          'selected-option': isSelected,
          'deleted-option': state === 'DELETED',
          'compared-option': isCompared,
        })}>
        <div>
          <span data-cy="artifact-version">{version} </span>
          {aliasesStrings.map(alias => (
            <S.AliasLabel data-cy="artifact-tag" key={alias}>
              {alias}
            </S.AliasLabel>
          ))}
        </div>
        <ArtifactCompareButton show={showCompare} onClick={onCompareClick} />
      </S.ArtifactItemString>
    </Link>
  );
};

type ArtifactSequenceHeaderProps = ArtifactMin & {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  isPortfolio?: boolean;
  expanded: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  refetchProjectArtifacts: () => Promise<
    ApolloQueryResult<ProjectArtifactsQuery>
  >;
};

const ArtifactSequenceHeader: React.FC<ArtifactSequenceHeaderProps> = props => {
  const {
    id,
    name,
    expanded,
    entityName,
    projectName,
    artifactTypeName,
    isPortfolio,
    onClick,
    refetchProjectArtifacts,
  } = props;

  const to = Urls.artifactSequence({
    entityName,
    projectName,
    artifactTypeName,
    artifactSequenceName: name,
  });
  const icon = isPortfolio
    ? expanded
      ? 'folder open outline'
      : 'folder outline'
    : expanded
    ? 'caret down'
    : 'caret right';
  const history = useHistory();
  const [isRenaming, setRenaming] = useState(false);
  const [isMoving, setMoving] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [updateArtifactCollection] = useUpdateArtifactCollectionMutation();
  const [updateArtifactPortfolio] = useUpdateArtifactPortfolioMutation();
  const [moveArtifactCollection] = useMoveArtifactCollectionMutation({
    onCompleted: refetchProjectArtifacts,
  });

  return (
    <S.ArtifactVersionContainer className={'overview-option'}>
      <RenameArtifactModal
        name={name}
        modalOpen={isRenaming}
        onRenameArtifact={async newName => {
          if (isPortfolio) {
            await updateArtifactPortfolio({
              variables: {
                artifactPortfolioID: id,
                name: newName,
              },
            });
          } else {
            await updateArtifactCollection({
              variables: {
                artifactSequenceID: id,
                name: newName,
              },
            });
          }
          setRenaming(false);
          history.replace(
            Urls.artifactSequence({
              entityName,
              projectName,
              artifactTypeName,
              artifactSequenceName: newName,
            })
          );
        }}
        onClose={async () => {
          setRenaming(false);
        }}
      />
      <MoveArtifactModal
        entityName={entityName}
        projectName={projectName}
        modalOpen={isMoving}
        onMoveArtifact={async destinationArtifactTypeName => {
          await moveArtifactCollection({
            variables: {
              artifactSequenceID: id,
              destinationArtifactTypeName,
            },
          });
          setMoving(false);
          history.push(
            Urls.artifactSequence({
              entityName,
              projectName,
              artifactTypeName: destinationArtifactTypeName,
              artifactSequenceName: name,
            })
          );
        }}
        onClose={() => setMoving(false)}
      />
      <DeleteArtifactModal
        id={id}
        entityName={entityName}
        isModalOpen={isDeleteModalOpen}
        isPortfolio={isPortfolio}
        projectName={projectName}
        refetchProjectArtifacts={refetchProjectArtifacts}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        newURL={Urls.projectArtifacts({entityName, name: projectName})}
      />
      <div
        className={'overview-option-inline'}
        style={{
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        onClick={onClick}>
        <Icon
          name={icon}
          style={{fontSize: isPortfolio ? '0.75em' : '1em', width: '1rem'}}
        />
        <Link
          to={to}
          style={{
            fontWeight:
              props.artifactCollectionName === props.name ? 'bold' : 'inherit',
          }}>
          {name}
        </Link>
      </div>
      <div className={'overview-option-ellipses'}>
        <ArtifactHeaderPopupDropdown
          onRenameArtifact={() => setRenaming(true)}
          onMoveArtifact={isPortfolio ? undefined : () => setMoving(true)}
          onDeleteArtifact={() => setIsDeleteModalOpen(true)}
        />
      </div>
    </S.ArtifactVersionContainer>
  );
};

type ArtifactSidebarPaginateProps = {
  size?: 'mini' | 'tiny';
  total?: number;
  page?: number;
  perPage?: number;
  pageInfo: {hasNextPage?: boolean; hasPreviousPage?: boolean};
  onPreviousClick?: () => void;
  onNextClick?: () => void;
};

export const ArtifactSidebarPaginate: React.FC<ArtifactSidebarPaginateProps> =
  ({
    onPreviousClick,
    onNextClick,
    total = 0,
    pageInfo,
    perPage = 0,
    page = 0,
    size = 'mini',
  }) => {
    const {hasNextPage, hasPreviousPage} = pageInfo;
    if (!hasNextPage && !hasPreviousPage) {
      return null;
    }

    const pageStart = page * perPage;
    const pageEnd = page * perPage + perPage;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          margin: 2,
          marginLeft: 14,
        }}>
        <div
          style={
            size === 'mini'
              ? {
                  lineHeight: '0.5em',
                  fontSize: '0.8em',
                }
              : {}
          }>
          {pageStart + 1} - {pageEnd} of {total}
        </div>
        <Button.Group className="pagination-buttons">
          <Button
            className="wb-icon-button only-icon page-down-button"
            size={size}
            disabled={!hasPreviousPage}
            onClick={onPreviousClick}>
            <LegacyWBIcon name="previous" />
          </Button>
          <Button
            className="wb-icon-button page-up-button only-icon"
            size={size}
            disabled={!hasNextPage}
            onClick={onNextClick}>
            <LegacyWBIcon name="next" />
          </Button>
        </Button.Group>
      </div>
    );
  };

const ARTIFACTS_PER_PAGE = 20;

interface ArtifactSidebarSequenceWithUrlToggleProps extends ArtifactMin {
  entityName: string;
  projectName: string;
  activeArtifactTypeName?: string;
  artifactTypeName: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  collectionTypeName?: string;
  refetchProjectArtifacts: () => Promise<
    ApolloQueryResult<ProjectArtifactsQuery>
  >;
}

export const ArtifactSidebarSequenceWithUrlToggle: React.FC<ArtifactSidebarSequenceWithUrlToggleProps> =
  props => {
    const urlHash = window.location.hash.slice(1) ?? '';
    const [, compareSequenceName] = urlHash.split('$');
    const [expanded, setExpanded] = useState(false);
    const toggle = React.useCallback(() => setExpanded(s => !s), []);
    const {name, artifactCollectionName} = props;

    // Automatically expand sections based on the url
    useLocation();
    useEffect(() => {
      const isArtifactCollection = artifactCollectionName === name;
      const isCompareCollection = compareSequenceName === name;
      if (isArtifactCollection || isCompareCollection) {
        setExpanded(true);
      }
    }, [artifactCollectionName, compareSequenceName, name]);

    return (
      <ArtifactSidebarSequence
        {...props}
        onHeaderClick={toggle}
        expanded={expanded}
      />
    );
  };

interface ArtifactSidebarSequenceProps
  extends ArtifactSidebarSequenceWithUrlToggleProps {
  expanded: boolean;
  onHeaderClick: React.MouseEventHandler;
  refetchProjectArtifacts: () => Promise<
    ApolloQueryResult<ProjectArtifactsQuery>
  >;
}

const ArtifactSidebarSequence: React.FC<ArtifactSidebarSequenceProps> =
  props => {
    const {
      name,
      expanded,
      entityName,
      projectName,
      artifactCommitHash,
      artifactCollectionName,
      activeArtifactTypeName,
      artifactTypeName,
      artifactTab,
      filePath,
      onHeaderClick,
      collectionTypeName,
    } = props;

    const history = useHistory();
    const {data, loading, refetch} = useArtifactCollectionQuery({
      variables: {
        artifactFirst: ARTIFACTS_PER_PAGE,
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName: name,
      },
      skip: !expanded,
    });

    // Here we force a refresh when the target commit hash changes This is
    // useful when new artifacts are linked to within the same collection. if
    // the user's URL is
    // entity/project/artifacts/artifact_type/artifact_name/artifact_commit_hash
    // then the artifactCommitHash variable is set to artifact_commit_hash...
    // this can either be `v5` or something like `3ws4de5frgt67iokp`. This essentially
    // forces a refresh when the commit hash changes.
    const lastRefreshedCommitHash = React.useRef(artifactCommitHash);
    useEffect(() => {
      if (
        expanded &&
        name === artifactCollectionName &&
        lastRefreshedCommitHash.current !== artifactCommitHash &&
        !loading
      ) {
        lastRefreshedCommitHash.current = artifactCommitHash;
        refetch();
      }
    }, [
      expanded,
      artifactCollectionName,
      artifactCommitHash,
      loading,
      name,
      refetch,
    ]);

    // TODO: can probably do this with react router. And is this really what
    // we want?
    const urlHash = window.location.hash.slice(1) ?? '';
    const [compareID] = urlHash.split('$');
    const artifactCollection = data?.project?.artifactType?.artifactCollection;
    const totalCount = artifactCollection?.artifacts?.totalCount;
    const pageInfo = artifactCollection?.artifactMemberships?.pageInfo;
    const artifactMemberships = React.useMemo(
      () =>
        artifactCollection?.artifactMemberships?.edges
          .map(n => n.node)
          .filter(n => n?.artifact != null),
      [artifactCollection]
    );
    const hasArtifacts =
      artifactMemberships != null && artifactMemberships?.length > 0;

    const fetchWithCursor = (cursor?: string) => {
      refetch({
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName: name,
        artifactCursor: cursor,
      });
    };

    const [cursors, setCursors] = useState<string[]>([]);
    const nextPage = () => {
      const cursor = pageInfo?.endCursor;
      if (cursor != null) {
        setCursors([...cursors, cursor]);
        fetchWithCursor(cursor);
      }
    };

    const previousPage = () => {
      const tmpCursors = [...cursors];
      tmpCursors.pop();
      const cursor = _.last(tmpCursors);
      setCursors(tmpCursors);
      fetchWithCursor(cursor);
    };

    return (
      <div>
        <ArtifactSequenceHeader
          {...props}
          onClick={onHeaderClick}
          isPortfolio={collectionTypeName === 'ArtifactPortfolio'}
        />
        {expanded && (
          <S.ArtifactsSidebarArtifactList>
            {loading && data == null ? (
              <S.ArtifactVersionLoad>
                Loading artifacts...
              </S.ArtifactVersionLoad>
            ) : (
              <>
                {!hasArtifacts && (
                  <span style={{marginLeft: '10px'}}>No artifacts found.</span>
                )}
                {artifactMemberships!.map(membership => {
                  const artifact = membership!.artifact;
                  const aliases = membership!.aliases;
                  const identifier = makeArtifactMembershipIdentifier(
                    membership!.commitHash,
                    membership!.versionIndex
                    // purposely excluding digest here
                  );
                  const digest = membership?.artifact?.digest;
                  const url = Urls.artifactSubpage({
                    entityName,
                    projectName,
                    artifactTypeName,
                    artifactCollectionName: name,
                    artifactCommitHash: `v${membership!.versionIndex}`,
                    tabName: artifactTab,
                    pathString: filePath,
                  });

                  const onCompareClick: React.MouseEventHandler = e => {
                    e.preventDefault();
                    if (
                      artifactCommitHash == null ||
                      artifactCollectionName == null ||
                      activeArtifactTypeName == null
                    ) {
                      return;
                    }
                    history.replace(
                      Urls.artifactSubpageCompare({
                        entityName,
                        projectName,
                        artifactTypeName: activeArtifactTypeName,
                        artifactCollectionName,
                        artifactCommitHash,
                        tabName: artifactTab,
                        pathString: filePath,
                        compareArtifactID: identifier,
                        compareArtifactSequence: props.name,
                      })
                    );
                  };
                  const isSelected =
                    (membership!.commitHash === artifactCommitHash ||
                      `v${membership!.versionIndex}` === artifactCommitHash ||
                      digest === artifactCommitHash) &&
                    artifactCollection?.name === artifactCollectionName;
                  const isCompared =
                    identifier === compareID || digest === compareID;
                  const showCompare =
                    artifactTab === 'files' && !isSelected && !isCompared;

                  return artifact == null ? (
                    <>Private Artifact</>
                  ) : (
                    <ArtifactItemVersion
                      key={artifact.id}
                      {...artifact}
                      aliases={aliases}
                      isSelected={isSelected}
                      isCompared={isCompared}
                      showCompare={showCompare}
                      to={url}
                      onCompareClick={onCompareClick}
                    />
                  );
                })}
              </>
            )}
            <S.ArtifactSidebarArtifactPaginate>
              <ArtifactSidebarPaginate
                page={cursors.length}
                perPage={ARTIFACTS_PER_PAGE}
                total={totalCount}
                pageInfo={pageInfo ?? {}}
                onPreviousClick={previousPage}
                onNextClick={nextPage}
              />
            </S.ArtifactSidebarArtifactPaginate>
          </S.ArtifactsSidebarArtifactList>
        )}
      </div>
    );
  };
