import {envIsLocal} from '@wandb/common/config';
import queryString from 'query-string';
import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {immerReducer} from '../reducer';
import * as Actions from './actions';
import * as Types from './types';

export type ActionType = TSActionType<typeof Actions>;

function viewer(draft: Types.StateType, action: ActionType) {
  switch (action.type) {
    case getType(Actions.setCurrentViewer): {
      draft.viewer = action.payload.viewer;
      draft.loading = action.payload.loading;
      draft.reload = false;
      break;
    }
    case getType(Actions.reloadCurrentViewer): {
      draft.reload = true;
      draft.claims = action.payload.claims;
    }
  }
}

// For local password reset and invitations we parse jwt claims on initial page load
let claims: Types.JWTClaims | undefined;
try {
  if (envIsLocal) {
    claims = {originalEmail: 'local@wandb.com'};
    const params = queryString.parse(document.location.search);
    if (params && params.jwt && !Array.isArray(params.jwt)) {
      const rawClaims = JSON.parse(atob(params.jwt.split('.')[1]));
      claims.name = rawClaims.name;
      claims.reset = rawClaims.reset as boolean;
      claims.originalEmail = rawClaims.sub.split('|')[1];
    } else {
      claims = undefined;
    }
  }
} catch (e) {
  console.error('Error parsing jwt', e);
}

export default immerReducer<Types.StateType, ActionType>(viewer, {
  viewer: undefined,
  reload: false,
  claims,
  loading: true, // we initialize the store knowing we need to load the viewer
});
