import {getFirebaseCookie} from '@wandb/common/util/cookie';
import {TargetBlank} from '@wandb/common/util/links';
import queryString from 'query-string';
import React, {useCallback} from 'react';
import {Button, Modal} from 'semantic-ui-react';

const BETA_VERSION_COOKIE_KEY = `betaVersion`;
export const betaVersion = getFirebaseCookie(BETA_VERSION_COOKIE_KEY);

type BetaVersionModalProps = {
  onClose: () => void;
};

const BetaVersionModal: React.FC<BetaVersionModalProps> = React.memo(
  ({onClose}) => {
    const unsetBetaVersion = useCallback(() => {
      const qsParams = queryString.parse(window.location.search);
      qsParams.unsetBetaVersion = `true`;
      window.location.search = queryString.stringify(qsParams);
    }, []);

    return (
      <Modal open onClose={onClose}>
        <Modal.Header>Beta Version</Modal.Header>
        <Modal.Content>
          You are currently viewing version{' '}
          <TargetBlank
            href={`https://github.com/wandb/core/commit/${betaVersion}`}>
            {betaVersion}
          </TargetBlank>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Close</Button>
          <Button primary onClick={unsetBetaVersion}>
            Unset Beta Version
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);

export default BetaVersionModal;
