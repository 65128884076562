import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import {useFetchPreviewFriendlyUrlQuery} from '../../generated/graphql';
import {useSelector} from '../../state/hooks';
import {getReportPart} from '../../state/reports/selectors';
import {propagateErrorsContext} from '../../util/errors';
import {useIsMounted} from '../../util/hooks';
import {reportView} from '../../util/urls/shared';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';
import {
  firstPanelExportableAsImageFromViewPart,
  useMaybeSurreptitiouslyCreatePreviewFriendlyURL,
} from './util';

export const NormalLinkSection: FC = () => {
  const {view, viewRef, userIsMemberOfTeam} = useContext(
    ShareReportTriggerContext
  );
  const isMounted = useIsMounted();

  const {origin, host} = window.location;
  const encodedPathname = encodeURI(
    reportView({
      entityName: view.project?.entityName ?? '',
      projectName: view.project?.name ?? '',
      reportID: view.id ?? '',
      reportName: view.displayName,
    })
  );
  const {data: previewFriendlyURLData, refetch: refetchPreviewFriendlyURL} =
    useFetchPreviewFriendlyUrlQuery({
      variables: {
        viewID: view.id ?? '',
      },
      context: propagateErrorsContext(),
    });

  const [isNormalLinkAnimated, setIsNormalLinkAnimated] = useState(false);

  const animateNormalLink = useCallback(() => {
    setIsNormalLinkAnimated(false);
    setTimeout(() => {
      setIsNormalLinkAnimated(true);
    });
  }, []);
  const viewPart = useSelector(state => getReportPart(viewRef)(state));

  const firstPanelExportableAsImage =
    firstPanelExportableAsImageFromViewPart(viewPart);

  const [currentPreviewFriendlyURL, setCurrentPreviewFriendlyURL] = useState(
    previewFriendlyURLData?.previewFriendlyURL?.URL
  );

  useMaybeSurreptitiouslyCreatePreviewFriendlyURL({
    firstPanelExportableAsImage,
    origin,
    encodedPathname,
    view,
    reportAuthors: viewPart.authors ?? [],
    isMounted,
    refetchPreviewFriendlyURL,
    userIsMemberOfTeam: userIsMemberOfTeam ?? false,
    viewLastUpdatedAt: view.updatedAt,
    fetchPreviewFriendlyURLData: previewFriendlyURLData,
  });

  useEffect(() => {
    if (previewFriendlyURLData?.previewFriendlyURL?.URL != null) {
      setCurrentPreviewFriendlyURL(
        previewFriendlyURLData.previewFriendlyURL.URL
      );
    }
  }, [previewFriendlyURLData]);

  return (
    <>
      <S.RowWrapper>
        <S.IconTextWrapper>
          <S.GrayCircleWBIcon name="link" />
          Anyone with the link can view
        </S.IconTextWrapper>
      </S.RowWrapper>
      <S.CopyableLinkWrapper>
        <S.CopyableLink
          isAnimated={isNormalLinkAnimated}
          text={currentPreviewFriendlyURL || `${host}${encodedPathname}`}
          copyText={currentPreviewFriendlyURL || `${origin}${encodedPathname}`}
          toastText="Link copied"
          onClick={() => {
            animateNormalLink();
            window.analytics?.track('Report View Only Link Copied', {
              reportID: view.cid,
              isPreviewFriendlyLink: currentPreviewFriendlyURL != null,
            });
          }}
        />
      </S.CopyableLinkWrapper>
    </>
  );
};
