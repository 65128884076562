// TODO: Later: refactor this to pull the comparison up to the PanelMembership level

import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {useConfigChild} from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import {FilesPanel} from '../../Panel2/PanelFiles';

const inputType = 'artifactVersion' as const;
type ConfigType = {
  compareArtifact?: {
    projectName: string;
    entityName: string;
    artifactCollectionName: string;
    membershipIdentifier: string;
  };
};

export const PanelArtifactVersionFiles: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType>
> = props => {
  const input = React.useMemo(() => {
    const baseArtifactVersion = props.input;
    if (props.config?.compareArtifact != null) {
      const compareArtifactVersion = Op.opArtifactMembershipArtifactVersion({
        artifactMembership: Op.opArtifactMembershipForAlias({
          artifact: Op.opProjectArtifact({
            project: Op.opRootProject({
              entityName: Op.constString(
                props.config.compareArtifact.entityName
              ),
              projectName: Op.constString(
                props.config.compareArtifact.projectName
              ),
            }),
            artifactName: Op.constString(
              props.config.compareArtifact.artifactCollectionName
            ),
          }),
          aliasName: Op.constString(
            props.config.compareArtifact.membershipIdentifier
          ),
        }),
      }) as GraphTypes.OutputNode<'artifactVersion'>;
      return Op.opArray({
        0: baseArtifactVersion,
        1: compareArtifactVersion,
      } as any) as GraphTypes.OutputNode<{
        type: 'list';
        objectType: 'artifactVersion';
      }>;
    }
    return baseArtifactVersion;
  }, [props.config, props.input]);

  const filesConfig = useConfigChild(
    'filesPanel',
    props.config,
    props.updateConfig
  );

  return (
    <FilesPanel
      input={input}
      config={filesConfig.config}
      updateConfig={filesConfig.updateConfig}
      context={props.context}
      updateContext={props.updateContext}
    />
  );
};
