import React, {FC, memo, useContext} from 'react';

import {PanelBankContext} from '../../state/panelbank/context';
import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {
  PanelBankSectionConfig,
  PanelBankSectionConfigWithVisiblePanels,
} from '../../util/panelbank';
import {LayedOutPanel} from '../../util/panels';
import {CreateReportModalContent} from './CreateReportModalContent';

export interface DropdownOption {
  text: string;
  value: string | number;
}

export type CreateReportModalProps = {
  panelSettingsRef?: PanelSettingsTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  customRunColorsRef: CustomRunColorsViewTypes.Ref;

  panelIsActive?: (
    section: PanelBankSectionConfig,
    panel: LayedOutPanel
  ) => boolean;
  useExportableSections(): PanelBankSectionConfigWithVisiblePanels[];
};

const CreateReportModalComp: FC<CreateReportModalProps> = ({
  panelSettingsRef,
  runSetRefs,
  customRunColorsRef,
  useExportableSections,
  panelIsActive,
}) => {
  const {
    exportingUnknownID: exporting,
    exportingPanelRefID,
    exportingSectionRefID,
  } = useContext(PanelBankContext);
  const isExporting =
    exporting || exportingPanelRefID != null || exportingSectionRefID != null;

  // Important: only render the modal if we need it! It fetches the entire
  // panelBank config, we don't want this to happen normally
  if (!isExporting) {
    return null;
  }
  return (
    <CreateReportModalContent
      useExportableSections={useExportableSections}
      panelSettingsRef={panelSettingsRef}
      runSetRefs={runSetRefs}
      customRunColorsRef={customRunColorsRef}
      panelIsActive={panelIsActive}
    />
  );
};

export const CreateReportModal = memo(CreateReportModalComp);
