import {RequireSome} from '@wandb/common/types/base';
import {useCallback, useEffect, useMemo, useState} from 'react';

import * as GQL from '../generated/graphql';
import {AVAILABLE_FILES_QUERY} from '../graphql/media';
import {useApolloClient} from '../state/hooks';
import {RunSignature} from '../types/run';
import {propagateErrorsContext} from './errors';

interface RetrySpec {
  maxRetries: number;
  calcDelay: (n: number) => number;
}

const STANDARD_RETRY: RetrySpec = {
  maxRetries: 10,
  calcDelay: (n: number) => 500 * Math.pow(1.51337, n),
};

export interface RetryRequest {
  abort: () => void;
}

// Makes an http request with retries included
// options:
//   onError(e, retry) - called every time a request returns a failure. This
//     allows the caller to check the error and decide to retry or perform
//     a different action
//   onFailure - called if the request fails more than the max limit
//   onSuccess - called when the request returns successful
//   retry - A spec to control the way the request is retried
//   method - defaults to GET
export function fetchWithRetry(
  url: string,
  options: {
    onError?: () => void;
    onSuccess?: (response: any) => void;
    onFailure?: CallableFunction;
    retry?: RetrySpec;
    method?: string;
    responseType?: XMLHttpRequestResponseType | 'file';
  }
): RetryRequest {
  const {
    onError,
    onSuccess,
    onFailure,
    retry = STANDARD_RETRY,
    method,
    responseType,
  } = options;
  const {maxRetries, calcDelay} = retry;

  // Keep track of the latest retry timeout
  let timerID: number;
  let xhr: XMLHttpRequest;

  // We hold the retry count as a local closure around the event.
  // Doing this instead of holding it in state allows us to create the
  // event listeners once and only recreated them on source change
  let retryCount = 0;

  // Use xhr instead of fetch because abort support for fetch is currently
  // experimental - Oct 24, 2019
  const makeRequest = () => {
    xhr = new XMLHttpRequest();

    // Create a new dom element so we don't affect the handlers on the original element
    xhr.open(method || 'get', url);
    if (responseType === 'file') {
      xhr.responseType = 'blob';
    } else if (responseType) {
      xhr.responseType = responseType;
    }

    xhr.onload = () => {
      if (onSuccess == null) {
        return;
      }
      const res =
        responseType === 'file'
          ? blobToFile(xhr.response, url) ?? xhr.response
          : xhr.response;
      onSuccess(res);
    };

    // Attach an event for retries
    xhr.onerror = () => {
      onError?.();
      if (retryCount >= maxRetries) {
        onFailure?.();
        return;
      }
      const delay = calcDelay(retryCount);
      timerID = window.setTimeout(() => {
        retryCount++;
        makeRequest();
      }, delay);
    };

    xhr.send();
  };

  makeRequest();

  return {
    abort: () => {
      if (timerID) {
        window.clearTimeout(timerID);
      }
      xhr?.abort();
    },
  };
}

export async function getUrlWithRetry(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    fetchWithRetry(url, {
      onSuccess: response => {
        resolve(response);
      },
      onError: () => {
        reject(`too many request failures for: ${url}`);
      },
    });
  });
}

interface GetDirectUrlArgs {
  entityName: string;
  projectName: string;
  runName: string;
  filename: string;
}

export type FileInfo = RequireSome<GQL.File, 'directUrl'>;

// Cache the file urls for N
export async function fetchFileInfo(
  client: ReturnType<typeof useApolloClient>,
  args: GetDirectUrlArgs
): Promise<FileInfo | null> {
  const {filename, ...passthroughArgs} = args;
  const variables = {
    ...passthroughArgs,
    filenames: [filename],
    includeDirectUrl: true,
  };
  const response = await client.query({
    query: AVAILABLE_FILES_QUERY,
    fetchPolicy: 'no-cache', // google cloud store urls expire in 60 seconds, so we need to disable apollo caching
    variables,
    context: propagateErrorsContext(),
  });
  const fileInfo: FileInfo | null =
    response.data?.project?.run?.files?.edges?.[0]?.node ?? null;
  return fileInfo?.directUrl ? fileInfo : null;
}

type DownloadFileFn = (r: RunSignature, f: string) => Promise<void>;

export function useDownloadFile(): DownloadFileFn {
  const client = useApolloClient();
  return useCallback(
    async (runSignature: RunSignature, filename: string) => {
      const fileInfo = await fetchFileInfo(client, {...runSignature, filename});
      if (fileInfo == null) {
        throw new Error(
          `failed to fetch url for file download: ${JSON.stringify({
            ...runSignature,
            filename,
          })}`
        );
      }
      window.open(fileInfo.directUrl);
    },
    [client]
  );
}

// `useLoadFile` is a react hook for loading files from our file store
// It handles the fetching of the signed urls for cloud storage,
// supports fallback for missing files, and request retries for failed
// requests

//
// useLoadFile:
// - options
//   fallback:  A function that is called when the requested
//              resource returns a 404
//   onSuccess: A function that is called when the file is successfully
//              fetched
//   onFailure: A function that is called after all retries have failed
//   retry:     A retry spec to control how retries operate
//   skip:      When true a request will not be made
export function useLoadFile(
  {entityName, projectName, runName}: RunSignature,
  filePath: string,
  options: {
    fallback?: () => void;
    onSuccess?: (response: any, metadata: GQL.File) => void;
    onFailure?: () => void;
    retry?: RetrySpec;
    skip?: boolean;
    responseType?: XMLHttpRequestResponseType | 'file';
  }
) {
  const {onSuccess, onFailure, fallback, retry, responseType, skip} = options;

  const client = useApolloClient();
  const [loading, setLoading] = useState(true);

  // Creat a memoized version of the runSignature, so it will only trigger
  // hook updates when its contents change
  const runSignature = useMemo(
    () => ({
      entityName,
      projectName,
      runName,
    }),
    [entityName, projectName, runName]
  );

  const filename = normalizeMediaFilepath(filePath);

  useEffect(() => {
    if (skip) {
      return;
    }

    let retryReq: RetryRequest | undefined;
    let aborted = false;
    (async () => {
      const fileInfo = await fetchFileInfo(client, {...runSignature, filename});

      if (aborted) {
        return;
      }

      // If the file is empty is hasn't uploaded yet we
      // call the fallback method,
      //
      // This allows the caller to perform actions like
      // rollback to an older image, display an error message, etc
      // TODO: in dev mode / CI we don't always have sizeBytes for reasons unclear
      const useFallbackIfAvailable =
        fileInfo == null || fileInfo.sizeBytes === 0;

      if (fallback != null && useFallbackIfAvailable) {
        fallback();
        return;
      }

      if (fileInfo == null) {
        return;
      }

      retryReq = fetchWithRetry(fileInfo.directUrl, {
        responseType,
        retry,
        onSuccess: res => {
          onSuccess?.(res, fileInfo);
          setLoading(false);
        },
        onFailure: () => {
          onFailure?.();
        },
      });
    })();

    return () => {
      aborted = true;
      retryReq?.abort();
    };
  }, [
    client,
    skip,
    runSignature,
    filename,
    fallback,
    onFailure,
    onSuccess,
    responseType,
    retry,
  ]);

  // Indicate loading finished for skipped hooks
  return skip ? false : loading;
}

// On windows, prior to CLI 0.8.26, we were saving '\' in media paths
// (in media file references that are saved in history or summary). We
// convert them back here. This isn't just a blind replace of all
// backslashes with forward-slashes, because we don't want to do that
// if the path was set on a non-windows machine. We don't have an easy
// way to detect if the data was logged from windows here, so we just
// fix up media paths (which is the only case that needs to be fixed
// anyway).
export function normalizeMediaFilepath(filePath: string): string {
  return filePath.startsWith('media\\')
    ? filePath.replace(/\\/g, '/')
    : filePath;
}

export function blobToFile(theBlob: Blob, url: string): File | null {
  try {
    return new File([theBlob], url);
  } catch {
    return null;
  }
}

export function downloadDataUrl(dataUrl: string, filename: string) {
  const a = document.createElement('a');
  a.href = dataUrl;
  a.setAttribute('download', filename);
  a.click();
}
