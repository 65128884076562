import React from 'react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import * as TypeHelpers from '@wandb/cg';

const inputType = 'type' as const;
type PanelTypeProps = Panel2.PanelProps<typeof inputType>;

export const PanelType: React.FC<PanelTypeProps> = props => {
  const nodeValueQuery = CGReact.useNodeValue(props.input);
  if (nodeValueQuery.loading) {
    return <div>-</div>;
  }
  return (
    <div>
      {TypeHelpers.defaultLanguageBinding.printType(
        nodeValueQuery.result,
        false
      )}
    </div>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'type',
  Component: PanelType,
  inputType,
};
