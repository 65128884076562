import React from 'react';
import {Segment} from 'semantic-ui-react';
import {DefaultResourceConfig} from 'src/generated/graphql';

import * as S from './DefaultResourceConfig.styles';
import {DefaultResourceConfigTable} from './DefaultResourceConfigTable';
import EditResourceConfigModal from './EditResourceConfigModal';

interface DefaultResourceConfigTabProps {
  entityName?: string;
  defaultResourceConfigs: DefaultResourceConfig[];
  refetchDefaultResourceConfigs: () => Promise<any>;
}

const DefaultResourceConfigTab: React.FC<DefaultResourceConfigTabProps> = ({
  entityName,
  defaultResourceConfigs,
  refetchDefaultResourceConfigs,
}) => {
  return (
    <Segment>
      <S.SectionHeader smallMargin>Resource Configuration</S.SectionHeader>
      <S.SectionSubheader smallMargin>
        Create default configurations for connected external resources like
        Sagemaker or Kubernetes. Then, create a Queue in the Launch tab and
        attach it to a configuration. All jobs pushed to this new queue will
        receive the "resource_args" set in the resource configuration.
      </S.SectionSubheader>
      <DefaultResourceConfigTable
        entityName={entityName ?? ''}
        isInSettingsPage={true}
        configs={defaultResourceConfigs}
        refetch={refetchDefaultResourceConfigs}
      />
      <S.SectionSubheader></S.SectionSubheader>
      <EditResourceConfigModal
        entityName={entityName ?? ''}
        mode="CREATE"
        refetch={refetchDefaultResourceConfigs}
      />
    </Segment>
  );
};

export default DefaultResourceConfigTab;
