import {ApolloQueryResult} from 'apollo-client';
import gql from 'graphql-tag';

import {BenchmarkRun} from '../generated/graphql';

export const SUBMIT_BENCHMARK_RUN_MUTATION = gql`
  mutation SubmitBenchmarkRun(
    $id: ID!
    $isCodeHidden: Boolean
    $benchmarkEntityName: String
    $benchmarkName: String
  ) {
    submitBenchmarkRun(
      input: {
        id: $id
        isCodeHidden: $isCodeHidden
        benchmarkEntityName: $benchmarkEntityName
        benchmarkName: $benchmarkName
      }
    ) {
      benchmarkRun {
        id
        results
      }
    }
  }
`;

export const PUBLISH_BENCHMARK_RUN_MUTATION = gql`
  mutation PublishBenchmarkRun(
    $id: ID!
    $isCodeHidden: Boolean
    $benchmarkEntityName: String
    $benchmarkName: String
  ) {
    publishBenchmarkRun(
      input: {
        id: $id
        isCodeHidden: $isCodeHidden
        benchmarkEntityName: $benchmarkEntityName
        benchmarkName: $benchmarkName
      }
    ) {
      benchmarkRun {
        id
        gitHubSubmissionPR
      }
    }
  }
`;

export const BENCHMARK_SUBMIT_DECISION = gql`
  mutation UpdateBenchmarkRun($id: ID!, $state: String, $details: JSONString) {
    updateBenchmarkRun(input: {id: $id, state: $state, details: $details}) {
      benchmarkRun {
        id
        state
        details
      }
    }
  }
`;

export type Verdict = 'ACCEPTED' | 'REJECTED' | 'SUBMITTED';
export type SubmitDecisionMutationFn = (
  id: string,
  verdict: Verdict,
  details: string
) => Request<{submitBenchmarkRun: {benchmarkRun: BenchmarkRun}}>;

export type Request<T> = Promise<ApolloQueryResult<T>>;

export type SubmitBenchmarkRunMutationFn = (vars: {
  id: string;
  isCodeHidden?: boolean;
  benchmarkName?: string;
  benchmarkEntityName?: string;
}) => SubmissionRequest;

export type PublishBenchmarkRunMutationFn = (vars: {
  id: string;
  benchmarkName?: string;
  benchmarkEntityName?: string;
}) => PublishRequest;

export type SubmissionRequest = Request<{
  submitBenchmarkRun: {
    benchmarkRun: BenchmarkRun;
  };
}>;

export type PublishRequest = Request<{
  publishBenchmarkRun: {
    benchmarkRun: BenchmarkRun;
  };
}>;
