import {RemoteEcosystemComputeGraphContextProvider} from '@wandb/weave-ui/contextProviders';
import {get} from 'lodash';
import React from 'react';
import {useCallback, useMemo} from 'react';
import {Message} from 'semantic-ui-react';
import {Editor, Element, Node, Transforms} from 'slate';
import {ReactEditor, RenderElementProps, useSlateStatic} from 'slate-react';

import {useBetaFeatureWeavePythonEcosystem} from '../../../util/useBetaFeature';
import {
  RootQueryPanel,
  RootQueryPanelConfig,
} from '../../Panel2/RootQueryPanel';
import {BlockWrapper} from './drag-drop';
import * as S from './weave-panels.styles';

export interface WeavePanel extends Element {
  type: 'weave-panel';
  config: RootQueryPanelConfig;
}
export const isWeavePanel = (node: Node): node is WeavePanel =>
  node.type === 'weave-panel';

export const withWeavePanels = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;
  editor.isVoid = element => (isWeavePanel(element) ? true : isVoid(element));

  return editor;
};

export const WeavePanelElement: React.FC<
  RenderElementProps & {
    element: WeavePanel;
  }
> = props => {
  return <WeavePanelElementInner {...props} />;
};

// Weave Python Specific Block
export interface WeavePythonPanel extends Element {
  type: 'weave-python-panel';
  config: RootQueryPanelConfig;
}
export const isWeavePythonPanel = (node: Node): node is WeavePythonPanel =>
  node.type === 'weave-python-panel';

export const withWeavePythonPanels = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;
  editor.isVoid = element =>
    isWeavePythonPanel(element) ? true : isVoid(element);

  return editor;
};
export const WeavePythonPanelElement: React.FC<
  RenderElementProps & {
    element: WeavePythonPanel;
  }
> = props => {
  const weaveEcosystemEnabled = useBetaFeatureWeavePythonEcosystem();
  if (weaveEcosystemEnabled) {
    return (
      <RemoteEcosystemComputeGraphContextProvider>
        <WeavePanelElementInner {...props} />
      </RemoteEcosystemComputeGraphContextProvider>
    );
  } else {
    // The following is displayed to users (possibly customers) who are viewing a report
    // and do not have Weave Python enabled.
    return (
      <BlockWrapper
        attributes={props.attributes}
        element={props.element}
        disableClickSelect>
        <S.WeavePanelWrapper>
          <Message
            info
            content="This panel is hidden because it uses beta features which are not currently enabled."
            size="mini"
          />
        </S.WeavePanelWrapper>
      </BlockWrapper>
    );
  }
};

// End Weave Python Specific Block

const WeavePanelElementInner: React.FC<
  RenderElementProps & {
    element: WeavePanel | WeavePythonPanel;
  }
> = ({attributes, element, children}) => {
  const editor = useSlateStatic();
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const config = useMemo(() => element.config ?? {}, [element.config]);

  const updateConfig = useCallback(
    (newConfig: Partial<RootQueryPanelConfig>) => {
      setTimeout(() => {
        // Must be in a timeout otherwise this will race with a layout hook
        // internal to slate and cause a full page crash
        try {
          Transforms.setNodes(
            editor,
            {config: {...config, ...newConfig}},
            {at: ReactEditor.findPath(editor, element)}
          );
        } catch (err) {
          console.warn(`Set nodes failed: `, get(err, 'message', err));
        }
      }, 0);
    },
    [editor, element, config]
  );

  // We need beforeinput to be handled normally for the embedded contentEditables to work.
  // If we don't stopPropagation, Slate will preventDefault it.
  // Can't use React's onBeforeInput because it's fake.
  React.useEffect(() => {
    const el = wrapperRef.current;
    if (!el) {
      return;
    }

    const onDOMBeforeInput = (e: Event) => {
      e.stopPropagation();
    };

    el.addEventListener('beforeinput', onDOMBeforeInput);

    return () => {
      el.removeEventListener('beforeinput', onDOMBeforeInput);
    };
  }, []);

  return (
    <BlockWrapper attributes={attributes} element={element} disableClickSelect>
      <S.WeavePanelWrapper
        ref={wrapperRef}
        contentEditable={false}
        onMouseUp={e => {
          Transforms.deselect(editor);
        }}
        onKeyDown={e => {
          // Slate will preventDefault onKeyDown if we don't stopPropagation,
          // making users unable to move the cursor

          if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            e.stopPropagation();
          }
        }}>
        {/*
          children MUST be rendered before our content because RootQueryPanel can contain
          a nested Slate editor (WeaveExpression), which can contain DOM nodes that don't
          belong to the Reports editor.  Slate searches for a specific, special leaf node
          that represents this and other void elements, which is passed as an ancestor
          nested inside the children prop.  When Slate incorrectly finds a leaf node inside
          the nested editor, the editor's internal state is corrupted and eventually causes
          crashes from within slate-react's react-editor.ts module.

          See https://github.com/wandb/core/pull/9989 for more details.
        */}
        {children}
        <RootQueryPanel config={config} updateConfig={updateConfig} />
      </S.WeavePanelWrapper>
    </BlockWrapper>
  );
};
