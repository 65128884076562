import {Tooltip} from '@material-ui/core';
import WandbLoader from '@wandb/common/components/WandbLoader';
import React, {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import {ReactComponent as OrganizationSvg} from '../assets/SVG/ic-organization.svg';
import {
  useCreateInviteMutation,
  useUpdateUserMutation,
  useUserOrganizationsQuery,
} from '../generated/graphql';
import {useDispatch} from '../state/hooks';
import {reloadCurrentViewer} from '../state/viewer/actions';
import {useViewer} from '../state/viewer/hooks';
import {
  extractErrorMessageFromApolloError,
  getErrMsgFromError,
  propagateErrorsContext,
} from '../util/errors';
import * as S from './JoinMatchingOrgTeams.styles';
import * as UsageStyles from './WBUsageTypeSelection.styles';

type Props = {
  onSuccess?: () => void;
  onSkip?: () => void;
  onNoMatches?: () => void;
  transitioningPage?: boolean;
  maxTeamsShown?: number;
  isOnboardingFlow?: boolean;
  viewerEmail?: string;
};

type MatchingTeamType = {
  id: string;
  name: string;
  orgName: string;
  memberCount: number;
};

const JoinMatchingOrgTeams = ({
  onNoMatches,
  onSuccess,
  onSkip,
  transitioningPage,
  maxTeamsShown = 5,
  isOnboardingFlow = true,
  viewerEmail,
}: Props) => {
  const viewer = useViewer();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!viewer?.username || !viewer?.email) {
      dispatch(reloadCurrentViewer());
    }
  }, [viewer, dispatch]);

  const viewerEmailDomain = (viewerEmail || viewer?.email)?.split('@')[1];

  const [
    haveReceivedInitialQueryResponse,
    setHaveReceivedInitialQueryResponse,
  ] = useState(false);

  const userOrganizationsQueryResult = useUserOrganizationsQuery({
    variables: {
      emailDomain: viewerEmailDomain,
    },
  });

  useEffect(() => {
    if (
      userOrganizationsQueryResult?.data != null &&
      userOrganizationsQueryResult.loading === false
    ) {
      const hasMatchingTeams =
        userOrganizationsQueryResult.data.viewer?.openMatchingOrganizations?.some(
          ({teams}) => teams?.length
        );
      if (!hasMatchingTeams) {
        onNoMatches?.();
      }
    }
  }, [userOrganizationsQueryResult, onNoMatches]);

  const [updateUser] = useUpdateUserMutation({
    context: propagateErrorsContext(),
  });

  const openMatchingOrganizations = useMemo(() => {
    return (
      userOrganizationsQueryResult?.data?.viewer?.openMatchingOrganizations ??
      []
    );
  }, [userOrganizationsQueryResult?.data]);

  const [createInvite] = useCreateInviteMutation({
    context: propagateErrorsContext(),
  });

  const userTeamIDs = useMemo(
    () => new Set(viewer?.teams?.edges?.map(({node}) => node.id) ?? []),
    [viewer]
  );

  const matchingTeams: MatchingTeamType[] = useMemo(() => {
    const matches: MatchingTeamType[] = [];
    openMatchingOrganizations.forEach(({name: orgName, teams}) => {
      teams.forEach(({name, id, memberCount}) => {
        if (isOnboardingFlow || !userTeamIDs.has(id)) {
          matches.push({name, orgName, memberCount, id});
        }
      });
    });
    return matches
      .sort((a, b) => b.memberCount - a.memberCount)
      .slice(0, maxTeamsShown);
  }, [openMatchingOrganizations, maxTeamsShown, userTeamIDs, isOnboardingFlow]);

  useEffect(() => {
    if (matchingTeams.length === 0) {
      return;
    }
    if (!haveReceivedInitialQueryResponse) {
      if (matchingTeams.length === 1) {
        setSelectedTeams(matchingTeams);
      }
      setHaveReceivedInitialQueryResponse(true);
    }
  }, [matchingTeams, haveReceivedInitialQueryResponse]);

  const [selectedTeams, setSelectedTeams] = useState<MatchingTeamType[]>([]);
  const [successfullyJoinedTeamIDs, setSuccessfullyJoinedTeamIDs] = useState<
    Set<string>
  >(new Set());

  const [requestsInProgress, setRequestsInProgress] = useState<Set<string>>(
    new Set()
  );

  const [teamsPendingAdminApproval, setTeamsPendingAdminApproval] = useState<
    Set<string>
  >(new Set());

  const [teamsWithErrors, setTeamsWithErrors] = useState<Map<string, string>>(
    new Map()
  );

  useEffect(() => {
    if (viewer != null && userOrganizationsQueryResult?.data?.viewer != null) {
      const userTeamIDsSet = new Set(
        viewer.teams.edges.map(({node}) => node.id)
      );
      if (
        userOrganizationsQueryResult.data.viewer.openMatchingOrganizations
          .length === 0 ||
        userOrganizationsQueryResult.data.viewer.openMatchingOrganizations.every(
          org =>
            org.teams.length === 0 ||
            org.teams.every(({id}) => userTeamIDsSet.has(id))
        )
      ) {
        // ensure we're not calling this if user has actually joined the teams surfaced here
        if (
          selectedTeams.length === 0 &&
          successfullyJoinedTeamIDs.size === 0 &&
          requestsInProgress.size === 0
        ) {
          onNoMatches?.();
        }
      }
    }
  }, [
    viewer,
    userOrganizationsQueryResult,
    onNoMatches,
    selectedTeams,
    successfullyJoinedTeamIDs,
    requestsInProgress,
  ]);

  const handleJoinTeam = async (team: MatchingTeamType) => {
    if (!viewer) {
      throw new Error('Error loading viewer - please refresh the page');
    }
    const variables = {
      username: viewer.username,
      entityName: team.name,
      role: 'member',
    };
    try {
      const queryResult = await createInvite({
        variables,
      });
      if (queryResult.errors != null) {
        const errs = queryResult.errors.map(e => e.message).join(', ');
        console.error(extractErrorMessageFromApolloError(queryResult.errors));
        throw new Error(errs);
      }
      if (
        queryResult.data?.createInvite?.remainingSeats != null &&
        queryResult.data.createInvite.remainingSeats <= 0
      ) {
        const errMsg = `No available seats in this organization. The ${team.orgName} admin was notified of your request to join the team.`;
        setTeamsPendingAdminApproval(prev => new Set([...prev, team.id]));
        window.analytics?.track('No Seats Available Error Viewed', {
          entityName: team.name,
          organizationName: team.orgName,
          location: 'join matching teams modal',
        });
        throw new Error(errMsg);
      } else {
        setSuccessfullyJoinedTeamIDs(prev => new Set([...prev, team.id]));
        try {
          updateUser({
            variables: {defaultEntity: team.name},
          });
          window.analytics?.track('Matching Team Joined', {
            entityName: team.name,
            organizationName: team.orgName,
          });
        } catch (e) {
          console.error('Error updating default entity after joining team:', e);
        }
      }
    } catch (e) {
      toast(`Error joining team ${team.name}: ${e}`);
      console.error(e);
      throw e;
    }
    dispatch(reloadCurrentViewer());
  };

  const handleClickSubmit = async () => {
    setRequestsInProgress(new Set(selectedTeams.map(t => t.id)));
    const requests = selectedTeams.map(team => handleJoinTeam(team));
    const responses = await Promise.allSettled(requests);
    const errors: string[] = [];
    const teamsWithErrorsMap = new Map<string, string>();
    responses.forEach((response, i) => {
      if (response.status === 'rejected') {
        const errMsg = getErrMsgFromError(response.reason) ?? 'unknown error';
        errors.push(errMsg);
        const teamID = selectedTeams[i].id;
        teamsWithErrorsMap.set(teamID, errMsg);
      }
    });
    if (errors.length === 0) {
      onSuccess?.();
    } else {
      console.error(errors);
      setTeamsWithErrors(teamsWithErrorsMap);
    }
    setRequestsInProgress(new Set());
  };

  const handleToggleTeam = (team: MatchingTeamType) => {
    if (selectedTeams.some(({id}) => team.id === id)) {
      setSelectedTeams(prev => prev.filter(({id}) => team.id !== id));
    } else {
      setSelectedTeams(prev => [...prev, team]);
    }
  };

  if (matchingTeams.length === 0) {
    return (
      <div>
        <WandbLoader />
      </div>
    );
  }
  return (
    <UsageStyles.MainContainer>
      <S.SubHeader>
        {`${
          isOnboardingFlow ? 'Collaborate' : 'Join teams to collaborate'
        } with your colleagues, share results, and track all of your team's experiments.`}
      </S.SubHeader>
      {matchingTeams.map(team => {
        const isSelected =
          selectedTeams.some(({id}) => id === team.id) ||
          successfullyJoinedTeamIDs.has(team.id) ||
          teamsPendingAdminApproval.has(team.id) ||
          userTeamIDs.has(team.id);
        const isDisabled =
          successfullyJoinedTeamIDs.has(team.id) ||
          requestsInProgress.has(team.id) ||
          teamsPendingAdminApproval.has(team.id) ||
          userTeamIDs.has(team.id);
        const isAlreadyMember =
          userTeamIDs.has(team.id) || successfullyJoinedTeamIDs.has(team.id);
        return (
          <Tooltip
            title={
              isAlreadyMember ? 'You are already a member of this team' : ''
            }
            key={team.id}>
            <UsageStyles.SelectionItemContainer
              data-test={`matching-team-${team.name}`}
              $selected={isSelected}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                handleToggleTeam(team);
              }}>
              <UsageStyles.IconAndTextContainer>
                <UsageStyles.IconContainer $selected={isSelected}>
                  {/* TODO: revert to WBIcon usage when we fix SVG export from Figma */}
                  {/* <UsageStyles.Icon name="organization" /> */}
                  <OrganizationSvg />
                </UsageStyles.IconContainer>
                <UsageStyles.ItemLabelAndDescriptionContainer>
                  <UsageStyles.ItemLabel>{team.name}</UsageStyles.ItemLabel>
                  {team.memberCount > 0 && (
                    <UsageStyles.ItemDescription>
                      {team.memberCount} member{team.memberCount > 1 ? 's' : ''}
                    </UsageStyles.ItemDescription>
                  )}
                  {teamsWithErrors.has(team.id) && (
                    <S.ErrorMsg>{teamsWithErrors.get(team.id)}</S.ErrorMsg>
                  )}
                </UsageStyles.ItemLabelAndDescriptionContainer>
              </UsageStyles.IconAndTextContainer>
              <UsageStyles.CheckboxContainer>
                <UsageStyles.CheckMarkIcon
                  name="heavy-checkmark"
                  $selected={isSelected}
                />
              </UsageStyles.CheckboxContainer>
            </UsageStyles.SelectionItemContainer>
          </Tooltip>
        );
      })}
      <S.ButtonContainer>
        <S.ContinueButton
          data-test="continue-button"
          primary
          disabled={
            selectedTeams.length === 0 ||
            requestsInProgress.size > 0 ||
            transitioningPage
          }
          loading={requestsInProgress.size > 0 || transitioningPage}
          onClick={handleClickSubmit}>
          {isOnboardingFlow
            ? 'Join and continue'
            : `Join ${selectedTeams.length} teams`}
        </S.ContinueButton>
        {onSkip && (
          <S.SkipButton
            data-test="skip-button"
            disabled={requestsInProgress.size > 0 || transitioningPage}
            loading={requestsInProgress.size > 0 || transitioningPage}
            onClick={onSkip}>
            Not now
          </S.SkipButton>
        )}
      </S.ButtonContainer>
    </UsageStyles.MainContainer>
  );
};

export default JoinMatchingOrgTeams;
