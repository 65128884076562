import {
  constFunction,
  constNumber,
  constString,
  opArtifactAliasAlias,
  opArtifactAliases,
  opArtifactId,
  opArtifactName,
  opArtifactProject,
  opArtifactType,
  opArtifactTypeName,
  opDict,
  opEntityArtifactPortfolios,
  opEntityName,
  opFilter,
  opLimit,
  opMap,
  opProjectEntity,
  opProjectName,
  opRootEntity,
  opStringEqual,
} from '@wandb/cg';
import {LinkNoCrawl} from '@wandb/common/util/links';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import _ from 'lodash';
import * as React from 'react';
import {Button, Form, Message, Modal, Popup} from 'semantic-ui-react';

import * as Generated from '../../../../generated/graphql';
import {trackModelRegistryClicked} from '../../../../util/navigation';
import {capitalizeFirstLetter} from '../../../../util/string';
import * as urls from '../../../../util/urls';
import {Alias} from '../../../ArtifactAlias';
import * as S from './style';

export const LinkModal: React.FC<{
  open: boolean;
  onClose: () => void;
  artifactTypeName: string;
  artifactName: string;
  entityName: string;
  projectName: string;
  currentArtifactVersionID: string;
  afterLink: (collection: ArtifactCollectionType) => void;
}> = props => {
  const possiblePortfolios = React.useMemo(
    () =>
      opFilter({
        arr: opEntityArtifactPortfolios({
          entity: opRootEntity({
            entityName: constString(props.entityName),
          }),
        }),
        filterFn: constFunction({row: 'artifact'}, ({row}) => {
          return opStringEqual({
            lhs: opArtifactTypeName({
              artifactType: opArtifactType({artifact: row}),
            }),
            rhs: constString(props.artifactTypeName),
          });
        }),
      }),
    [props.entityName, props.artifactTypeName]
  );

  const dataNode = React.useMemo(() => {
    return opMap({
      arr: possiblePortfolios,
      mapFn: constFunction({row: 'artifact'}, ({row}) => {
        const projectNode = opArtifactProject({
          artifact: row,
        });
        const entityNode = opProjectEntity({
          project: projectNode,
        });
        return opDict({
          entityName: opEntityName({
            entity: entityNode,
          }),
          projectName: opProjectName({
            project: projectNode,
          }),
          artifactCollectionName: opArtifactName({
            artifact: row,
          }),
          artifactCollectionAliases: opLimit({
            arr: opArtifactAliasAlias({
              artifactAlias: opArtifactAliases({
                artifact: row,
              }),
            }),
            limit: constNumber(30),
          }),
          artifactTypeName: opArtifactTypeName({
            artifactType: opArtifactType({
              artifact: row,
            }),
          }),
          artifactCollectionId: opArtifactId({
            artifact: row,
          }),
        } as any);
      }),
    });
  }, [possiblePortfolios]);

  const {loading, result} = useNodeValue(dataNode) as unknown as {
    loading: boolean;
    result: ArtifactCollectionType[];
  };

  let data = result;
  if (loading) {
    data = [];
  }

  return (
    <LinkModalInner
      open={props.open}
      onClose={props.onClose}
      afterLink={props.afterLink}
      artifactTypeName={props.artifactTypeName}
      currentArtifactVersionID={props.currentArtifactVersionID}
      possiblePortfolios={data}
    />
  );
};
type ArtifactCollectionType = {
  entityName: string;
  projectName: string;
  artifactCollectionName: string;
  artifactTypeName: string;
  artifactCollectionId: string;
  artifactCollectionAliases: [string];
};
const LinkModalInner: React.FC<{
  open: boolean;
  artifactTypeName: string;
  currentArtifactVersionID: string;
  possiblePortfolios: ArtifactCollectionType[];
  onClose: () => void;
  afterLink: (collection: ArtifactCollectionType) => void;
}> = ({
  possiblePortfolios,
  currentArtifactVersionID,
  artifactTypeName,
  onClose,
  open,
  afterLink,
}) => {
  const options = React.useMemo(() => {
    return possiblePortfolios.map(res => {
      return {
        value: res.artifactCollectionId,
        text: `${res.entityName}/${res.projectName}/${res.artifactCollectionName}`,
      };
    });
  }, [possiblePortfolios]);

  const idToCollection: {
    [id: string]: {
      name: string;
      data: ArtifactCollectionType;
      aliases: [string];
    };
  } = React.useMemo(() => {
    return _.fromPairs(
      possiblePortfolios.map(res => {
        return [
          res.artifactCollectionId,
          {
            name: `${res.entityName}/${res.projectName}/${res.artifactCollectionName}`,
            data: res,
            aliases: res.artifactCollectionAliases,
          },
        ];
      })
    );
  }, [possiblePortfolios]);

  const [selectedValue, setSelectedValue] = React.useState('');
  const [aliasesValue, setAliasesValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [linkArtifact] = Generated.useLinkArtifactMutation();

  const onAliasesInputChange = React.useCallback(e => {
    const value = '' + e.target.value;
    const error = validateAliases(value)[0];
    setErrorMessage(error);
    if (!error) {
      setAliasesValue(value);
    }
  }, []);

  const onLink = React.useCallback(async () => {
    let aliases = null;
    if (aliasesValue !== '') {
      aliases = _.split(aliasesValue, ',').map(aliasString => {
        const collectionPath = idToCollection[selectedValue].name.split('/');
        const collectionName = collectionPath[collectionPath.length - 1];
        return {
          artifactCollectionName: collectionName,
          alias: aliasString.trim(),
        };
      });
    }

    await linkArtifact({
      variables: {
        artifactID: currentArtifactVersionID,
        artifactPortfolioID: selectedValue,
        aliases,
      },
    });
    afterLink(idToCollection[selectedValue].data);
  }, [
    aliasesValue,
    linkArtifact,
    currentArtifactVersionID,
    selectedValue,
    afterLink,
    idToCollection,
  ]);

  const capitalizedArtifactTypeName = React.useMemo(() => {
    return capitalizeFirstLetter(artifactTypeName);
  }, [artifactTypeName]);

  return (
    <Modal size={'small'} open={open} onClose={onClose}>
      <Modal.Header>
        Link to Registered {capitalizedArtifactTypeName}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <b>
              Registered {capitalizedArtifactTypeName}&nbsp;
              <Popup
                trigger={
                  <span>
                    <S.GrayIcon name="question circle outline" />
                  </span>
                }
                content={
                  'A collection of your most important models for an ML task. '
                }
                position={'right center'}
                inverted
                wide={'very'}
                style={{opacity: 0.7, color: 'lightgrey', borderRadius: '10px'}}
              />
            </b>
          </Form.Field>
          <Form.Field>
            Link your model version to a Registered{' '}
            {capitalizedArtifactTypeName}.
          </Form.Field>
          <Form.Field>
            <LinkNoCrawl
              data-test="model-registry"
              onClick={() => trackModelRegistryClicked('Link modal')}
              to={urls.objectRegistry('model')}
              className="see-all-link">
              Create a Registered Model ➞
            </LinkNoCrawl>
          </Form.Field>
          <Form.Dropdown
            data-test="collection-selection"
            selection
            search
            value={selectedValue}
            options={options}
            onChange={(e, {value}) => {
              setSelectedValue('' + value);
            }}
          />
          <S.CurrentAliasesContainer>
            <b>
              Aliases&nbsp;
              <Popup
                trigger={
                  <span>
                    <S.GrayIcon name="question circle outline" />
                  </span>
                }
                content={
                  'Unique tags within your Registered Model. Use aliases in downstream pipelines to pull down models for evaluation or inference.'
                }
                position={'right center'}
                inverted
                wide={'very'}
                style={{
                  opacity: 0.7,
                  color: 'lightgrey',
                  borderRadius: '10px',
                }}
              />
            </b>
            {selectedValue && (
              <>
                <div style={{marginTop: '10px'}}>
                  The following aliases are currently used in the Registered{' '}
                  {capitalizedArtifactTypeName} above:
                </div>
                <S.CurrentAliasList>
                  {idToCollection[selectedValue].aliases.length > 0 ? (
                    idToCollection[selectedValue].aliases.map(alias => {
                      return (
                        <Alias key={alias} alias={{alias}} onClick={() => {}} />
                      );
                    })
                  ) : (
                    <span role={'img'} aria-label={'monocle face'}>
                      {String.fromCodePoint(0x1f9d0)} No aliases found.
                    </span>
                  )}
                </S.CurrentAliasList>
              </>
            )}
          </S.CurrentAliasesContainer>
          <Form.Field>
            Your newly linked model version will receive the alias named
            "latest". You can optionally add more aliases below:
          </Form.Field>
          <input
            data-test="aliases-input"
            style={{margin: '0 0 1em'}}
            placeholder={`challenger, champion, staging`}
            onChange={onAliasesInputChange}
          />
          {errorMessage && <Message negative>{errorMessage}</Message>}
        </Form>
      </Modal.Content>
      <Modal.Actions
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          onClick={() => {
            setErrorMessage('');
            setAliasesValue('');
            setSelectedValue('');
            onClose();
          }}>
          Cancel
        </Button>
        <S.LinkButton
          data-test="link-button"
          primary
          onClick={onLink}
          disabled={errorMessage ? true : false || selectedValue === ''}>
          Link
        </S.LinkButton>
      </Modal.Actions>
    </Modal>
  );
};
const validateAliases = (aliasesValue: string): [string, string[]] => {
  const aliases = _.split(aliasesValue, ',');
  let errorMessage = '';
  const validAliases: string[] = [];
  aliases.forEach(a => {
    a = a.trim();
    const invalid = new RegExp(/[^\w-_.]+/);
    if (invalid.test(a)) {
      errorMessage =
        'Aliases can only contain the following special characters: underscore, dash, period.';
    } else {
      validAliases.push(a);
    }
  });
  return [errorMessage, validAliases];
};
