import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {TargetBlank} from '@wandb/common/util/links';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';
import {Accordion, Form} from 'semantic-ui-react';
import styled from 'styled-components';

import * as S from '../../../../src/pages/EntityRegistryPage.styles';
import emptyImg2x from '../../../assets/il-no-submission@2x.png';
import {
  PORTFOLIO_COLLECTION_DISPLAY_NAME,
  SEQUENCE_COLLECTION_DISPLAY_NAME,
} from '../../../components/artifactConstants';
import {Highlight, Python} from '../../../components/Code';
import {capitalizeFirstLetter} from '../../../util/string';
import BlankPage from '../../BlankPage';

const CodeWrapper = styled.div`
  padding: 0px 12px;
`;

const CodeLine = styled.code`
  color: #fff;
  background-color: #2a2a2a;
  padding: 2px 6px;
  border-radius: 5px;
  text-shadow: 0 1px rgb(0 0 0 / 30%);
  font-family: 'Inconsolata', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
`;

const inputType = 'artifact' as const;
type ConfigType = {};

export const PanelEmptyArtifact: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {isPortfolio: boolean}
> = props => {
  const artifactCollectionAttributesNodeValue = useNodeValue(
    React.useMemo(() => dataDictNode(props.input), [props.input])
  );

  const {entityName, projectName, collectionName, artifactTypeName} =
    artifactCollectionAttributesNodeValue.result ?? {};

  return props.isPortfolio ? (
    <BlankPortfolio />
  ) : (
    <BlankCollection
      isPortfolio={props.isPortfolio ?? false}
      collectionName={collectionName ?? ''}
      entityName={entityName ?? ''}
      projectName={projectName ?? ''}
      artifactTypeName={artifactTypeName ?? ''}
    />
  );
};

const BlankPortfolio: React.FC = () => {
  const logArtifactCodeSnippet = [
    {
      key: 'artifacts-code-snippet',
      title: {
        content: <span>Code Snippet</span>,
        style: {background: 'white', textTransform: 'none'},
      },
      content: {
        content: (
          <div>
            <Python style={{fontSize: '16px'}}>
              <Highlight>
                {`art = wandb.Artifact("my-object-detector", type="model")
art.add_file("saved_model_weights.pt")
wandb.log_artifact(art)`}
              </Highlight>
            </Python>
          </div>
        ),
      },
    },
  ];

  return (
    <S.EmptyRegistryContainer>
      <S.TitleHeader>Add versions to this Registered Model</S.TitleHeader>
      <S.TitleDescription>
        Collect the best candidate models and use aliases to track a model on
        its journey to production.
      </S.TitleDescription>
      <S.Quickstart>
        <S.SectionHeader>Quickstart</S.SectionHeader>
        <S.QuickstartListContainer>
          <ol>
            <li className={'checked-quickstart-step'}>
              <span style={{marginLeft: '-18px', color: 'lightgrey'}}>
                On the top right of this page, click to create a Registered
                Model.
              </span>
            </li>
            <li>
              <p>
                In your script, log a model as an{' '}
                <TargetBlank href={'https://docs.wandb.ai/guides/artifacts'}>
                  artifact version
                </TargetBlank>
                .
              </p>
              <Accordion
                style={{
                  marginTop: '10px',
                  textTransform: 'none',
                  background: 'white',
                }}
                styled
                as={Form.Field}
                panels={logArtifactCodeSnippet}
              />
            </li>
            <li>
              From the Artifact page, link the artifact version to the registry.
            </li>
          </ol>
        </S.QuickstartListContainer>
        <S.SectionHeader>Try it yourself</S.SectionHeader>
        <S.SectionText>
          Watch this 1-minute video and follow along{' '}
          <TargetBlank href={'http://wandb.me/model-registry-quickstart'}>
            with this notebook &rarr;
          </TargetBlank>
        </S.SectionText>
        <S.IframeContainer>
          <iframe
            style={{borderRadius: '15px'}}
            title={'link-into-registry-quickstart'}
            width={'500'}
            height={'281'}
            allow={'fullscreen'}
            src={'https://www.youtube.com/embed/jy9Pk9riwZI'}></iframe>
        </S.IframeContainer>
      </S.Quickstart>
    </S.EmptyRegistryContainer>
  );
};

const BlankCollection: React.FC<{
  isPortfolio: boolean;
  collectionName: string;
  entityName: string;
  projectName: string;
  artifactTypeName: string;
}> = ({
  isPortfolio,
  collectionName,
  entityName,
  projectName,
  artifactTypeName,
}) => {
  const capitalizedArtifactTypeName = React.useMemo(
    () => capitalizeFirstLetter(artifactTypeName),
    [artifactTypeName]
  );
  return (
    <BlankPage
      extraTopMargin
      header={`Empty ${
        isPortfolio
          ? `${PORTFOLIO_COLLECTION_DISPLAY_NAME} ${capitalizedArtifactTypeName}`
          : SEQUENCE_COLLECTION_DISPLAY_NAME
      }`}
      subheader={`${collectionName} has no versions.`}
      graphics={[
        {
          src: emptyImg2x,
          alt: 'no-artifacts',
        },
      ]}
      secondaryAction={{
        content: 'View Docs',
        href: 'https://docs.wandb.ai/ref/python/artifact',
      }}>
      <h3>
        Use{' '}
        <CodeLine>
          {isPortfolio ? 'run.link_artifact' : 'wandb.log_artifact'}
        </CodeLine>{' '}
        to add a version to this{' '}
        {isPortfolio
          ? `${PORTFOLIO_COLLECTION_DISPLAY_NAME} ${capitalizedArtifactTypeName}`
          : SEQUENCE_COLLECTION_DISPLAY_NAME}
        .
      </h3>
      <CodeWrapper>
        <Python>
          <Highlight iconOnly>
            {isPortfolio
              ? `import wandb
wandb.init()
art = wandb.use_artifact(...)
wandb.run.link_artifact(art, '${entityName}/${projectName}/${collectionName}')`
              : `import wandb
wandb.init(entity='${entityName}', project='${projectName}')
art = wandb.Artifact('${collectionName}', type='${artifactTypeName}')
# ... add content to artifact ...
wandb.log_artifact(art)`}
          </Highlight>
        </Python>
      </CodeWrapper>
    </BlankPage>
  );
};

function dataDictNode(artifactNode: GraphTypes.Node<'artifact'>) {
  const projectNode = Op.opArtifactProject({artifact: artifactNode});
  return Op.opDict({
    entityName: Op.opEntityName({
      entity: Op.opProjectEntity({
        project: projectNode,
      }),
    }),
    projectName: Op.opProjectName({
      project: projectNode,
    }),
    collectionName: Op.opArtifactName({artifact: artifactNode}),
    artifactTypeName: Op.opArtifactTypeName({
      artifactType: Op.opArtifactType({artifact: artifactNode}),
    }),
  } as any);
}
