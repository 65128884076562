import Markdown from '@wandb/common/components/Markdown';
import {getLeafNode} from '@wandb/common/util/dom';
import {NameProps} from '@wandb/common/util/reactUtils';
import {ApolloQueryResult} from 'apollo-client';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {UploadState} from '../../util/images';
import TextEditor from './TextEditor';

export type SaveHandler = (
  text: string
) => Promise<ApolloQueryResult<{}>> | void;

interface InlineMarkdownEditorProps {
  readOnly: boolean; // if true, field won't be editable
  rows?: number; // number of rows, passed to TextEditor
  minRows?: number; // minimum number of rows, passed to TextEditor
  maxRows?: number; // maximum number of rows, passed to TextEditor
  autosave?: boolean; // does not actually save but, if true, it'll show a 'saving... saved' message onblur
  enableMarkdown?: boolean; // if true, input will accept + display markdown
  placeholder?: string; // default text
  editPlaceholder?: string; // placeholder while editing
  serverText?: string; // text as stored on the server
  updateFromServerText?: boolean;
  onBlur?: SaveHandler; // handler for saving the text
  saveText?: boolean; // Should the save text be displayed
  onChange?: SaveHandler;
  uploadState?: UploadState;
  cursorIdx?: number;
  noHelpText?: boolean;
  // setting this makes the component controlled; leave undefined for auto
  editing?: boolean;
  onHelpSelect?(): void;
  onPaste?(files: File[]): void;
  onSelect?(idx: number): void;
  onContentHeightChange?(h: number): void;
  onDeleteEmpty?(): void;
  onStartEditing?(): void;
  onStopEditing?(): void;
}

const InlineMarkdownEditorComp: FC<InlineMarkdownEditorProps & NameProps> = ({
  readOnly,
  minRows,
  autosave,
  placeholder,
  editPlaceholder,
  serverText,
  updateFromServerText,
  onBlur,
  saveText,
  onChange,
  uploadState,
  cursorIdx,
  editing: propsEditing,
  onPaste,
  onSelect,
  onContentHeightChange,
  onDeleteEmpty,
  onStartEditing,
  onStopEditing,
  className,
}) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(serverText ?? '');
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const focusTextRef = useRef('');

  useEffect(() => {
    if (updateFromServerText) {
      setText(serverText || '');
    }
  }, [serverText, updateFromServerText]);

  useEffect(() => {
    if (uploadState && !readOnly) {
      setEditing(true);
    }
  }, [uploadState, readOnly]);

  // this ensures we don't attempt to setState after the component unmounts (prevents memory leak)
  const cancelAsync = useRef(false);

  useEffect(() => {
    return () => {
      cancelAsync.current = true;
    };
  }, []);

  useEffect(() => {
    if (!saved) {
      return;
    }
    setTimeout(() => {
      if (!cancelAsync.current) {
        setSaved(false);
      }
    }, 3000);
  }, [saved]);

  const onChangeThrottled = useMemo(
    () =>
      onChange
        ? _.throttle(onChange, 1000, {
            trailing: true,
            leading: false,
          })
        : undefined,
    [onChange]
  );

  const isEmpty = useCallback((): boolean => text.trim() === '', [text]);

  const currentlyEditing = propsEditing ?? editing;

  saveText = saveText ?? true;

  if (isEmpty() && readOnly) {
    return null;
  }

  return (
    <div
      className={classNames('inline-markdown-editor', className, {
        editing: currentlyEditing,
      })}
      onClick={e => {
        const node = getLeafNode(e.target as Node);
        focusTextRef.current = (node as any).src ?? node.textContent;
        if (!saving && !readOnly) {
          setEditing(true);
          if (onStartEditing != null) {
            onStartEditing();
          }
        }
      }}>
      {currentlyEditing ? (
        <React.Fragment>
          <TextEditor
            autoFocus
            focusText={focusTextRef.current}
            value={text}
            uploadState={uploadState}
            onDeleteEmpty={onDeleteEmpty}
            onBlur={() => {
              const shouldSave = onBlur ? text !== serverText : false;
              setEditing(false);
              setSaving(autosave ? shouldSave : false);
              if (onStopEditing != null) {
                onStopEditing();
              }
              if (shouldSave && onBlur) {
                Promise.resolve(onBlur)
                  .then(() => {
                    onBlur!(text);
                  })
                  .then(() => {
                    if (autosave) {
                      setSaving(false);
                      setSaved(true);
                    }
                  });
              }
            }}
            onChange={value => {
              setText(value);
              if (onChangeThrottled != null) {
                onChangeThrottled(value);
              }
            }}
            onStopEditing={() => {
              setEditing(false);
              if (onStopEditing != null) {
                onStopEditing();
              }
            }}
            onPaste={onPaste}
            onSelect={onSelect}
            cursorIdx={cursorIdx}
            placeholder={editPlaceholder ?? placeholder}
            minRows={minRows}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="inline-markdown-editor-markdown">
            {isEmpty() ? (
              <span className="placeholder">{placeholder}</span>
            ) : (
              <Markdown
                content={text}
                condensed={false}
                onContentHeightChange={onContentHeightChange}
              />
            )}
          </div>
          {saveText && autosave && (saving || saved) && (
            <p
              className="inline-markdown-editor-tip"
              style={{marginTop: '5px'}}>
              {saving ? 'Saving notes...' : saved && 'Notes saved'}
            </p>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export const InlineMarkdownEditor = memo(InlineMarkdownEditorComp);
