import * as Panel2 from '../panel';
import * as TypeHelpers from '@wandb/cg';
import * as GraphTypes from '@wandb/cg';
import * as Types from '@wandb/cg';
import * as Op from '@wandb/cg';
import * as TableType from '../PanelTable/tableType';
import * as PUtil from './util';

export const outputType: Panel2.PanelSpec['outputType'] = inType => {
  let sourceType: Types.Type = inType;
  if (TableType.isTableTypeLike(inType)) {
    sourceType = TypeHelpers.list(TypeHelpers.typedDict({}));
  }
  return TypeHelpers.list(
    TypeHelpers.typedDict({
      source: TypeHelpers.listObjectType(sourceType),
      projection: TypeHelpers.list('number'),
    }),
    TypeHelpers.listMinLength(sourceType),
    TypeHelpers.listMaxLength(sourceType)
  );
};

export const equivalentTransform: Panel2.PanelSpec['equivalentTransform'] =
  async (inputNode, config, refineType) => {
    let castedNode: GraphTypes.Node = inputNode as any;
    if (TableType.isTableTypeLike(inputNode.type)) {
      castedNode = await refineType(castedNode);
      castedNode = TableType.normalizeTableLike(castedNode);
      castedNode = await refineType(castedNode);
    }
    // In cases where the resolved type is not assignable to our expected return
    // type, we return the default node instead. The primary case where this
    // happens is when the input list is empty, then the resolved type ends up
    // being a list<none> which does not conform to the output contract
    if (
      !TypeHelpers.isListLike(castedNode.type) ||
      !TypeHelpers.isTypedDictLike(TypeHelpers.listObjectType(castedNode.type))
    ) {
      return Op.constNodeUnsafe(
        TypeHelpers.list(
          TypeHelpers.typedDict({
            projection: TypeHelpers.typedDict({
              x: 'number',
              y: 'number',
            }),
            source: 'none',
          })
        ),
        []
      );
    }
    const pConfig = PUtil.processConfig(config, castedNode as any);
    return Op.opTable2DProjection({
      table: castedNode,
      projectionAlgorithm: Op.constString(pConfig.projectionAlgorithm),
      inputCardinality: Op.constString(pConfig.inputCardinality),
      inputColumnNames: Op.constStringList(pConfig.inputColumnNames),
      algorithmOptions: Op.constNodeUnsafe(
        TypeHelpers.typedDict({
          tsne: {
            type: 'typedDict' as const,
            propertyTypes: {
              perplexity: 'number',
              learningRate: 'number',
              iterations: 'number',
            },
          },
          pca: {type: 'typedDict' as const, propertyTypes: {}},
          umap: {
            type: 'typedDict' as const,
            propertyTypes: {
              neighbors: 'number',
              minDist: 'number',
              spread: 'number',
            },
          },
        }),
        pConfig.algorithmOptions
      ),
    });
  };
