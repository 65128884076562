import {captureError} from '@wandb/common/util/integrations';
import {InMemoryCache} from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {routerMiddleware} from 'connected-react-router';
import {applyMiddleware, compose, createStore} from 'redux';
import {enableBatching} from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import {apolloClient} from './apolloClient';
import createRootReducer from './reducers';
import allSagas from './state/sagas';
import {createCircularDependencies} from './util/apollo';
import {
  apolloLink as apolloLink2,
  createCircularDependencies as createCircularDependencies2,
} from './util/apollo2';
import {Auth} from './util/auth';
import DebugCollector from './util/debug-collector';
import globalHistory from './util/history';

export const apolloClient2 = new ApolloClient({
  link: apolloLink2,
  connectToDevTools: false,
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
});

export type ApolloClientType = typeof apolloClient2;

// Create auth client
export const auth = new Auth();

// Setup browser history

// Setup redux
export const history = globalHistory;

// TODO: Get this through redux
export const getHistory = () => history;

// Create initial state.
const initialState = {};

// This hides bits of state from redux devtools. Otherwise the devtools don't work
// because the objects are too large.
const actionSanitizer = (action: any) => {
  if (action.type === 'view/INIT_ALL') {
    return {
      ...action,
      payload: {...action.payload, viewApi: '<sanitized viewApi>'},
    };
  }
  return action;
};

// This hides bits of state from redux devtools. Otherwise the devtools don't work
// because the objects are too large.
const stateSanitizer = (state: any) => {
  return {
    ...state,
    views2: {...state.views2, viewApi: '<sanitized viewApi>'},
  };
};

// Create store middleware
const devtoolsMiddleware =
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__({
        // these actions aren't logged in devtools, but they are still dispatched
        actionsBlacklist: [
          '@view/interactionState',
          `@global/resetErrorPortal`,
        ],
        actionSanitizer,
        stateSanitizer,
        // Uncomment this line to enable action tracing
        // trace: true,
      })
    : (f: any) => f;

const sagaMiddleware = createSagaMiddleware({
  onError: (error, {sagaStack}) => {
    // Note that we don't anticipate errors reaching this point since we catch all exceptions in rootSaga
    console.error('Saga middleware error:', error, sagaStack);
    captureError(error, 'saga_middleware', {
      extra: {
        sagaStack,
      },
    });
  },
});

const middleware = compose(
  applyMiddleware(
    routerMiddleware(history),
    thunk.withExtraArgument(apolloClient),
    sagaMiddleware
  ),
  devtoolsMiddleware
);

// Create the store
const reducer = enableBatching(createRootReducer(history));
export const store = createStore(reducer, initialState as any, middleware);

// Give the apollo client backend access to the store and auth (which it uses
// to get the current JWT or refresh it if needed).
createCircularDependencies(store, auth);
createCircularDependencies2(store, auth);

// Give our auth library access to the store
auth.store = store;

// Don't flag as IFrame if we're mounted in a service like Kubeflow.
// Do flag as IFrame if we're rendered in Kubeflow notebooks.
export function isInIframe() {
  try {
    return (
      window.self !== window.top &&
      (window.self.location.origin !== window.top?.location.origin ||
        window.top.location.pathname.startsWith('/notebook'))
    );
  } catch (e) {
    return true;
  }
}

// Test if jupyter=true is set on document load. If it's set, then you should
// continue to act as if we're in jupyter notebook mode even if the href
// changes later.
// TODO: revisit this, we may want to differentiate between jupyter and other sites embedding us
const isJupyter = isInIframe() || document.location.href.match(/jupyter=true/);

// FeaturePeek runs containers in an iframe, so we have to differentiate from
// the Jupyter Notebook iframe
const isFeaturePeek = document.location.href.match(/\.featurepeek\.com/);
const isLocalLogin = document.location.href.match(/local=true/);

export function isInJupyterNotebook() {
  return isJupyter && !isLocalLogin && !isFeaturePeek;
}

// Global error notification
window.addEventListener('unhandledrejection', event => {
  // NOTE there is no need for this error to be displayed in flash message
  // as it is caught by apollo's `errorLink`
  console.error('Unhandled rejection', event);
});

sagaMiddleware.run(allSagas, apolloClient);

export const debugCollector = new DebugCollector();
