import Markdown from '@wandb/common/components/Markdown';
import * as _ from 'lodash';
import * as React from 'react';
import {Header, Icon, Segment} from 'semantic-ui-react';

import WBReactTable from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as Run from '../../util/runs';
import * as Leaderboard from './leaderboard';
import {Theme} from './Theme';

const RANK_METRIC = 'val_perceptual_distance';

const keys = [RANK_METRIC, 'val_loss', 'perceptual_distance', 'loss'];

const scoreCols = _.map(keys, k => ({
  Header:
    k === RANK_METRIC ? (
      <span style={{fontWeight: 'bold'}}>
        {k}
        <Icon name="caret down" />
      </span>
    ) : (
      k
    ),
  id: k,
  accessor: (run: WithSummary<BenchmarkRun>) => {
    return Run.displayValue(run.summary[k]);
  },
}));

const leaderboardCols = [...Leaderboard.cols, ...scoreCols];

const CONCEPT = `
This is the Weights & Biases community benchmark for learning the behavior of catz from a dataset of cat GIFs.
The goal is to predict the 6th frame given 5 consecutive previous frames of a GIF.

#### Getting started

Follow the instructions [in the starter GitHub repository](https://github.com/wandb/catz) to upload your experiment runs to W&B.
You can change the existing hyperparameters and architecture to see if you can improve the model's performance. You could try using an RNN,
changing the loss function, augmenting the training data, and so on. 

#### Evaluation

We use a [perceptual distance](https://www.compuphase.com/cmetric.htm) metric (val_perceptual_distance) on the validation set to rank results (lower values are better).
The starter repository automatically generates this metric.

#### How to submit your results

You can submit runs to this benchmark from the "Runs" table in your "Project workspace" tab. To submit a specific run, hover over the run's name, click on the
three-dot menu icon that appears to the left of the name, and select "Submit to benchmark". All submissions are reviewed by the benchmark administrators before acceptance.
`;

const SUBMISSION_INSTRUCTIONS = `
Runs in this project can be submitted to the Catz benchmark.

To submit a run to the public benchmark, first add a run to this project by following
the README in the starter code repository: [https://github.com/wandb/catz](https://github.com/wandb/catz)

After you've trained a model and have your results here in Weights & Biases, you can
submit your run to the public benchmark by using the menu that appears next to the run
name in the table. From the "Runs" table in the "Project workspace" tab, hover over the run's name, click on the
three-dot menu icon that appears to the left of the name, and select "Submit to benchmark".
`;

const overview = (approvedRuns: Array<WithSummary<BenchmarkRun>>) => {
  return (
    <React.Fragment>
      <Header as="h3">Overview</Header>
      <Segment>
        <Markdown condensed={false} content={CONCEPT} />
      </Segment>
      <Header as="h3">Top submissions</Header>
      <WBReactTable
        pageSize={20}
        columns={leaderboardCols}
        data={Leaderboard.rowsForMetric(approvedRuns, RANK_METRIC, false)}
      />
    </React.Fragment>
  );
};

const theme: Theme = {
  keys,
  benchmarkLink: 'https://github.com/wandb/catz',
  overview,
  submissionGuidelines: SUBMISSION_INSTRUCTIONS,
};

export default theme;
