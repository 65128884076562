import _ from 'lodash';
import React from 'react';
import {useMemo, useCallback} from 'react';
import styled from 'styled-components';
import {Button} from 'semantic-ui-react';

import * as GraphTypes from '@wandb/cg';
import * as Graph from '@wandb/cg';
import * as TypeHelpers from '@wandb/cg';
import {PanelContextProvider, usePanelContext} from './PanelContext';
import {usePanelStacksForType} from './availablePanels';
import {Panel, PanelConfigEditor} from './PanelComp';
import {PanelInput} from './panel';
import {useLet} from '../../cgreact';
import {toWeaveType, PANEL_GROUP_DEFAULT_CONFIG} from './PanelGroup2';
import {getStackIdAndName} from './panellib/libpanel';
import {WeaveExpression} from '../../panel/WeaveExpression';
import {linkHoverBlue} from '@wandb/common/css/globals.styles';
import EditableField from '@wandb/common/components/EditableField';
import PanelNameEditor from './PanelNameEditor';
import {TableState} from './PanelTable/tableState';
import {NodeOrVoidNode} from '@wandb/cg';

// This could be rendered as a code block with assignments, like
// so.
// ```
// a = input + 4; // will be in scope of descendent panels
// return PanelWhatever(a / 2, panel_whatever_config)
// ```

export interface ChildPanelFullConfig {
  vars: GraphTypes.Frame;
  input_node: GraphTypes.NodeOrVoidNode;
  id: string;
  config: any;
}

export type ChildPanelConfig =
  | undefined
  | ChildPanelFullConfig
  | GraphTypes.NodeOrVoidNode;

export const getFullChildPanel = (
  item: ChildPanelConfig
): ChildPanelFullConfig => {
  if (item == null) {
    return CHILD_PANEL_DEFAULT_CONFIG;
  } else if (Graph.isNodeOrVoidNode(item)) {
    return {...CHILD_PANEL_DEFAULT_CONFIG, input_node: item};
  } else {
    return item;
  }
};

export const childPanelFromTableState = (
  tableState: TableState,
  colId: string
): ChildPanelFullConfig => {
  return {
    vars: {},
    input_node: tableState.columnSelectFunctions[colId],
    id: tableState.columns[colId].panelId,
    config: tableState.columns[colId].panelConfig,
  };
};

export const getChildPanelConfig = (item: ChildPanelConfig): any => {
  return getFullChildPanel(item).config;
};

const CHILD_PANEL_DEFAULT_CONFIG: ChildPanelFullConfig = {
  vars: {},
  input_node: Graph.voidNode(),
  id: '',
  config: undefined,
};
const useChildPanelConfig = (
  config: ChildPanelConfig
): ChildPanelFullConfig => {
  return useMemo(() => getFullChildPanel(config), [config]);
};

interface ChildPanelProps {
  pathEl?: string;
  config: ChildPanelConfig | undefined;
  updateConfig: (newConfig: ChildPanelFullConfig) => void;
  updateConfig2?: (change: (oldConfig: any) => Partial<any>) => void;
  updateInput?(partialInput: Partial<PanelInput>): void;
}

const useChildPanelCommon = (props: ChildPanelProps) => {
  const config = useChildPanelConfig(props.config);
  const {updateConfig} = props;
  const {input_node: panelInputExpr, id: panelId, config: panelConfig} = config;
  const updateAssignment = useCallback(
    (key: string, val: any) => {
      updateConfig({
        ...config,
        vars: {
          ...config.vars,
          [key]: Graph.constNodeUnsafe(toWeaveType(val), val),
        },
      });
    },
    [config, updateConfig]
  );
  const assignments = useLet(config.vars, updateAssignment);
  const {handler, curPanelId, stackIds} = usePanelStacksForType(
    panelInputExpr.type,
    panelId
  );

  const {updateConfig2} = props;
  const updatePanelConfig2 = useCallback(
    (change: <T>(oldConfig: T) => Partial<T>) => {
      if (updateConfig2 == null) {
        return;
      }
      updateConfig2(oldConfig => {
        oldConfig = getFullChildPanel(oldConfig);
        return {
          ...oldConfig,
          config: {...oldConfig.config, ...change(oldConfig.config)},
        };
      });
    },
    [updateConfig2]
  );

  const updatePanelConfig = useCallback(
    newPanelConfig =>
      updateConfig({
        ...config,
        config: {...config.config, ...newPanelConfig},
      }),

    [config, updateConfig]
  );

  return useMemo(
    () => ({
      assignments,
      curPanelId,
      handler,
      panelConfig,
      panelInput: panelInputExpr,
      stackIds,
      updatePanelConfig,
      updatePanelConfig2,
    }),
    [
      assignments,
      curPanelId,
      handler,
      panelConfig,
      panelInputExpr,
      stackIds,
      updatePanelConfig,
      updatePanelConfig2,
    ]
  );
};

// This is the standard way to render subpanels. We should migrate
// other cases to this (Table cell, SelectPanel in Facet, and probably
// PanelExpression and PanelRootQuery)
export const ChildPanel: React.FC<ChildPanelProps> = props => {
  const {
    panelInput,
    panelConfig,
    assignments,
    handler,
    curPanelId,
    updatePanelConfig,
    updatePanelConfig2,
  } = useChildPanelCommon(props);
  return curPanelId == null || handler == null ? (
    <div>
      No panel for type{' '}
      {TypeHelpers.defaultLanguageBinding.printType(panelInput.type)}
    </div>
  ) : (
    <PanelContextProvider newVars={assignments} newPath={props.pathEl}>
      <Panel
        input={panelInput}
        panelSpec={handler}
        config={panelConfig}
        updateConfig={updatePanelConfig}
        updateConfig2={updatePanelConfig2}
        updateInput={props.updateInput}
      />
    </PanelContextProvider>
  );
};

const ChildPanelConfigWrapper = styled.div`
  padding: 5px;
  border-left: 1px solid #eee;
  margin-bottom: 5px;
`;

const nextVarName = (vars: {[key: string]: any}) => {
  for (let i = 0; i < 26; i++) {
    const chr = String.fromCharCode(97 + i);
    if (vars[chr] == null) {
      return chr;
    }
  }
  return Graph.ID();
};

let panelIndex = 0;
export const nextPanelName = () => {
  return `panel${panelIndex++}`;
};

const MinimalEditableField = styled(EditableField)`
  margin: 0;
`;

export const ChildPanelConfigComp: React.FC<ChildPanelProps> = props => {
  const {updateConfig} = props;
  const {
    panelInput,
    panelConfig,
    assignments,
    handler,
    curPanelId,
    stackIds,
    updatePanelConfig,
    updatePanelConfig2,
  } = useChildPanelCommon(props);
  const config = useMemo(() => getFullChildPanel(props.config), [props.config]);
  const panelOptions = useMemo(() => {
    return stackIds.map(si => {
      const isActive =
        handler != null &&
        si.displayName === getStackIdAndName(handler).displayName;
      return {
        text: si.displayName,
        value: si.id,
        key: si.id,
        active: isActive,
        selected: isActive,
      };
    });
  }, [handler, stackIds]);

  const curPanelName =
    handler != null ? getStackIdAndName(handler).displayName : '';

  const handlePanelChange = useCallback(
    (panelId: string): void | undefined => {
      updateConfig({...config, id: panelId, config: undefined}); // TODO: need any here
    },
    [config, updateConfig]
  );

  const {path, selectedPath} = usePanelContext();

  const pathStr = useMemo(() => {
    const fullPath = ['<root>', ...path, props.pathEl].filter(el => el != null);
    return fullPath.join('.');
  }, [path, props.pathEl]);
  const selectedPathStr = useMemo(() => {
    console.log(`selectedPath = ${JSON.stringify(selectedPath)}`);
    return ['<root>', ...selectedPath!].join('.');
  }, [selectedPath]);

  const handleAddLabel = useCallback(() => {
    console.log(`APPLYING LABEL`);
    updateConfig({
      config: {
        item: config,
        label: 'Label',
      },
      id: 'LabeledItem',
      input_node: Graph.voidNode(),
      vars: {},
    });
  }, [config, updateConfig]);

  const handleDuplicate = useCallback(() => {
    console.log(`DUPLICATING`);
    updateConfig({
      config: {
        ...PANEL_GROUP_DEFAULT_CONFIG(),
        items: {
          [nextPanelName()]: config,
          [nextPanelName()]: _.cloneDeep(config),
        },
      },
      id: 'Group2',
      input_node: Graph.voidNode(),
      vars: {},
    });
  }, [config, updateConfig]);

  const nextFrame = {...usePanelContext().frame};

  // console.log(`path`, path, pathStr);
  // console.log(`selectedPath`, selectedPath, selectedPathStr);

  if (!selectedPathStr.startsWith(pathStr)) {
    // Off the path
    return <></>;
  }

  if (selectedPathStr === pathStr) {
    // We are selected, expose controls for input expression, panel selection,
    // our config, and misc operations
    return (
      <ChildPanelConfigWrapper>
        <div>
          {_.map(config.vars, (value, key) => {
            const varEditor = (
              <div key={key} style={{display: 'flex', alignItems: 'center'}}>
                <MinimalEditableField
                  value={key}
                  placeholder="var"
                  save={newVarName => {
                    const newVars: {[key: string]: any} = {};
                    for (const [k, v] of Object.entries(config.vars)) {
                      if (k === key) {
                        newVars[newVarName] = v;
                      } else {
                        newVars[k] = v;
                      }
                    }
                    props.updateConfig({...config, vars: newVars});
                  }}
                />
                <div style={{marginRight: 4, marginLeft: 4}}>= </div>
                <div style={{flexGrow: 1}}>
                  <WeaveExpression
                    expr={value}
                    frame={{...nextFrame}}
                    noBox
                    liveUpdate
                    setExpression={val =>
                      props.updateConfig({
                        ...config,
                        vars: {...config.vars, [key]: val},
                      })
                    }
                  />
                </div>
              </div>
            );
            nextFrame[key] = value;
            return varEditor;
          })}
          <div
            style={{cursor: 'pointer', color: linkHoverBlue}}
            onClick={() =>
              props.updateConfig({
                ...config,
                vars: {
                  ...config.vars,
                  [nextVarName(config.vars)]: Graph.voidNode(),
                },
              })
            }>
            + New variable
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 4,
            marginBottom: 4,
          }}>
          <div style={{marginRight: 4}}>input =</div>
          <div style={{flexGrow: 1}}>
            <WeaveExpression
              frame={nextFrame}
              expr={panelInput}
              noBox
              liveUpdate
              setExpression={newExpr =>
                updateConfig({
                  ...config,
                  input_node: newExpr as NodeOrVoidNode,
                  id: '',
                  config: undefined,
                })
              }
            />
          </div>
        </div>
        <PanelNameEditor
          value={curPanelName}
          autocompleteOptions={panelOptions}
          setValue={handlePanelChange}
        />
        <hr />
        {curPanelId == null || handler == null ? (
          <div>
            No panel for type{' '}
            {TypeHelpers.defaultLanguageBinding.printType(panelInput.type)}
          </div>
        ) : (
          <PanelContextProvider newVars={assignments} newPath={props.pathEl}>
            <PanelConfigEditor
              input={panelInput}
              panelSpec={handler}
              config={panelConfig}
              updateConfig={updatePanelConfig}
              updateConfig2={updatePanelConfig2}
              updateInput={props.updateInput}
            />
          </PanelContextProvider>
        )}
        <Button fluid size="mini" onClick={handleAddLabel}>
          Label
        </Button>
        <Button fluid size="mini" onClick={handleDuplicate}>
          Duplicate
        </Button>
      </ChildPanelConfigWrapper>
    );
  }

  // Child is selected -- render our config only
  // hack: config component must check path for itself and passthrough
  //       as needed, for now
  return (
    <>
      {curPanelId == null || handler == null ? (
        <div>
          No panel for type{' '}
          {TypeHelpers.defaultLanguageBinding.printType(panelInput.type)}
        </div>
      ) : (
        <PanelContextProvider newVars={assignments} newPath={props.pathEl}>
          <PanelConfigEditor
            input={panelInput}
            panelSpec={handler}
            config={panelConfig}
            updateConfig={updatePanelConfig}
            updateConfig2={updatePanelConfig2}
            updateInput={props.updateInput}
          />
        </PanelContextProvider>
      )}
    </>
  );
};
