import {
  ADMIN_BETA_FEATURES,
  BETA_FEATURES,
} from '@wandb/common/util/betaFeatures';
import React, {FC} from 'react';

import {useViewer} from '../../state/viewer/hooks';
import {BetaFeatureToggle, FeatureTable} from '../BetaFeatures';

export const UserSettingsBetaFeatures: FC = React.memo(() => {
  return (
    <FeatureTable
      info={`In this section, you can enable fun add-ons and sneak previews of new products in development.`}>
      {BETA_FEATURES.map((betaFeature, i) => (
        <BetaFeatureToggle key={i} betaFeature={betaFeature} />
      ))}
    </FeatureTable>
  );
});

// These features are only visible if the user is a wandb admin (internal employee)
export const UserSettingsBetaFeaturesAdmin: FC = React.memo(() => {
  const viewer = useViewer();
  if (!viewer?.admin) {
    return <></>;
  }

  return (
    <FeatureTable
      info={`In this section, toggle on and off W&B Admin-only features. This includes early prototype features that we're testing internally.`}>
      {ADMIN_BETA_FEATURES.map((betaFeature, i) => (
        <BetaFeatureToggle key={i} betaFeature={betaFeature} />
      ))}
    </FeatureTable>
  );
});
