import React, {FC, ReactNode, useCallback, useMemo, useState} from 'react';

import * as Generated from '../../generated/graphql';
import {useQuery} from '../../state/graphql/query';
import {useApolloClient, useSelector} from '../../state/hooks';
import {ReportViewRef} from '../../state/reports/types';
import {useViewer} from '../../state/viewer/hooks';
import {deleteView} from '../../state/views/api';
import {ViewPermissionsData} from '../../state/views/graphql';
import {isOwnView} from '../../state/views/util';
import {viewerUsingAdminPrivileges} from '../../util/admin';
import {ModalHeaderAction} from './ModalHeaderAction';
import {PersonalContent} from './PersonalContent';
import {ShareInputSection} from './ShareInputSection';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContextProvider} from './ShareReportTriggerContextProvider';
import {TeamContent} from './TeamContent';

export enum ShareReportModalHeaderAction {
  COPY_EMBEDED_CODE,
  EDIT_REPORT_BEFORE_SHARING,
}

interface ShareReportTriggerProps {
  children?: ReactNode;
  isModalInitiallyOpen?: boolean;
  isSharePanel?: boolean;
  modalHeaderAction?: ShareReportModalHeaderAction;
  modalTitle?: string;
  userIsMemberOfTeam?: boolean;
  userIsTeamAdmin?: boolean;
  viewRef: ReportViewRef;
  onModalClose?(): void;
  onTriggerContentClick?(): void;
}

export const ShareReportTrigger: FC<ShareReportTriggerProps> = ({
  children,
  isModalInitiallyOpen = false,
  modalHeaderAction = ShareReportModalHeaderAction.COPY_EMBEDED_CODE,
  modalTitle = 'Share Report',
  onModalClose,
  onTriggerContentClick,
  userIsMemberOfTeam,
  userIsTeamAdmin,
  viewRef,
  isSharePanel = false,
}) => {
  const view = useSelector(state => state.views.views[viewRef.id]);
  const accessTokens = useSelector(
    state => state.views.views[viewRef.id].accessTokens
  );
  const viewer = useViewer();
  const accessQuery = useQuery<
    ViewPermissionsData,
    Generated.ViewPermissionsQueryVariables
  >(Generated.ViewPermissionsDocument, {
    variables: {
      projectName: view.project?.name ?? '',
      entityName: view.project?.entityName ?? '',
    },
    skip: view.project == null,
  });

  const [isModalOpen, setIsModalOpen] = useState(isModalInitiallyOpen);
  const client = useApolloClient();

  const deleteViewIfNotUsed = async () => {
    if (view.id && isSharePanel && accessTokens?.length === 0) {
      deleteView(client, view.id);
    }
  };

  const closeModal = () => {
    deleteViewIfNotUsed();
    onModalClose?.();
    setIsModalOpen(false);
  };

  const {entity: {isTeam} = {isTeam: true}, project: {access} = {access: ''}} =
    accessQuery.loading ? {} : accessQuery;

  const isPrivateAccess = ['USER_READ', 'USER_WRITE'].indexOf(access) === -1;
  const currentAccessToken = view.accessTokens?.find(
    token => token.type === 'PUBLIC'
  );

  const hasAdminSharePrivileges = useMemo(
    () =>
      viewer != null &&
      (view.user.id === viewer.id ||
        (viewer.admin && viewerUsingAdminPrivileges()) ||
        userIsTeamAdmin),
    [view, viewer, userIsTeamAdmin]
  );

  const canChangeSharingSettings = useMemo(
    () => isOwnView(viewer, view) || hasAdminSharePrivileges,
    [viewer, view, hasAdminSharePrivileges]
  );

  const onClick = useCallback(() => {
    setIsModalOpen(true);
    onTriggerContentClick?.();
    window.analytics?.track?.('Share Report Clicked', {
      reportId: view.cid,
    });
    let projectPrivacy = '';
    if (isTeam) {
      if (isPrivateAccess) {
        projectPrivacy = 'team: private';
      } else {
        projectPrivacy = 'team: public';
      }
    } else {
      if (isPrivateAccess) {
        projectPrivacy = 'personal: private';
      } else {
        projectPrivacy = 'personal: public';
      }
    }
    let viewerDescription = '';
    if (isOwnView(viewer, view)) {
      viewerDescription = 'author';
    } else if (canChangeSharingSettings) {
      viewerDescription = 'team admin';
    } else if (isTeam) {
      if (userIsMemberOfTeam) {
        viewerDescription = 'team member';
      } else {
        viewerDescription = 'view only';
      }
    }
    window.analytics?.track('Share Report Modal Viewed', {
      reportId: view.cid,
      projectPrivacy,
      viewer: viewerDescription || 'other',
    });
  }, [
    onTriggerContentClick,
    isTeam,
    isPrivateAccess,
    viewer,
    view,
    canChangeSharingSettings,
    userIsMemberOfTeam,
  ]);

  // TODO - Move away from .less and inline style
  const triggerContent = (
    <div style={{display: 'contents'}} onClick={onClick}>
      {children}
    </div>
  );

  const isHeaderActionEnabled = useMemo(() => {
    return (
      canChangeSharingSettings || currentAccessToken != null || !isPrivateAccess
    );
  }, [canChangeSharingSettings, currentAccessToken, isPrivateAccess]);

  if (view.project == null) {
    return null;
  }
  if (accessQuery.loading) {
    return triggerContent;
  }

  return (
    <ShareReportTriggerContextProvider
      access={access}
      currentAccessToken={currentAccessToken}
      isPrivateAccess={isPrivateAccess}
      userIsMemberOfTeam={userIsMemberOfTeam}
      userIsTeamAdmin={userIsTeamAdmin}
      view={view}
      viewRef={viewRef}
      viewer={viewer}>
      {triggerContent}
      <S.Modal
        // className="share-report-modal"
        size="tiny"
        open={isModalOpen}
        onClose={closeModal}>
        <S.ModalContent>
          <S.ModalTitleWrapper
            isHeaderActionEnabled={isHeaderActionEnabled}
            modalHeaderAction={modalHeaderAction}>
            <p>{modalTitle}</p>
            {isHeaderActionEnabled && (
              <ModalHeaderAction modalHeaderAction={modalHeaderAction} />
            )}
          </S.ModalTitleWrapper>
          <ShareInputSection />
          {isTeam ? <TeamContent /> : <PersonalContent />}
        </S.ModalContent>
      </S.Modal>
    </ShareReportTriggerContextProvider>
  );
};
