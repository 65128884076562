import _ from 'lodash';

type DomToImage = {
  toSvg(node: Element, options?: Options): Promise<string>;
  toPng(node: Element, options?: Options): Promise<string>;
  toJpeg(node: Element, options?: Options): Promise<string>;
  toBlob(node: Element, options?: Options): Promise<Blob>;
  toPixelData(node: Element, options?: Options): Promise<Uint8ClampedArray>;
};

type DomToImageInternal = DomToImage & {
  impl: {
    fontFaces: ReturnType<typeof newFontFaces>;
    images: ReturnType<typeof newImages>;
    util: ReturnType<typeof newUtil>;
    inliner: ReturnType<typeof newInliner>;
    options: Options;
  };
};

type Options = {
  filter?: ((node: Node) => boolean) | undefined;
  bgcolor?: string | undefined;
  width?: number | undefined;
  height?: number | undefined;
  style?: Partial<CSSStyleDeclaration> | undefined;
  quality?: number | undefined;
  imagePlaceholder?: string | undefined;
  cacheBust?: boolean | undefined;
};

const util = newUtil();
const inliner = newInliner();
const fontFaces = newFontFaces();
const images = newImages();

// Default impl options
const defaultOptions = {
  // Default is to fail on error, no placeholder
  imagePlaceholder: undefined,
  // Default cache bust is false, it will use the cache
  cacheBust: false,
};

const domToImage: DomToImageInternal = {
  toSvg,
  toPng,
  toJpeg,
  toBlob,
  toPixelData,
  impl: {
    fontFaces,
    images,
    util,
    inliner,
    options: {},
  },
};

export default domToImage as DomToImage;

/**
 * @param {Element} node - The DOM Element object to render
 * @param {Object} options - Rendering options
 * @param {Function} options.filter - Should return true if passed node should be included in the output (excluding node means excluding it's children as well). Not called on the root node.
 * @param {String} options.bgcolor - color for the background, any valid CSS color value.
 * @param {Number} options.width - width to be applied to node before rendering.
 * @param {Number} options.height - height to be applied to node before rendering.
 * @param {Object} options.style - an object whose properties to be copied to node's style before rendering.
 * @param {Number} options.quality - a Number between 0 and 1 indicating image quality (applicable to JPEG only), defaults to 1.0.
 * @param {String} options.imagePlaceholder - dataURL to use as a placeholder for failed images, default behaviour is to fail fast on images we can't fetch
 * @param {Boolean} options.cacheBust - set to true to cache bust by appending the time to the request url
 * @return {Promise} - A promise that is fulfilled with a SVG image data URL
 * */
function toSvg(node: Element, options: Options = {}) {
  copyOptions(options);
  return Promise.resolve(node)
    .then(n => cloneNode(n, options.filter, true) as Promise<Node>)
    .then(embedFonts)
    .then(inlineImages)
    .then(applyOptions)
    .then(clone =>
      makeSvgDataUri(
        clone,
        options.width || util.width(node),
        options.height || util.height(node)
      )
    );

  function applyOptions(clone: Node): Node {
    if (!(clone instanceof HTMLElement) && !(clone instanceof SVGElement)) {
      return clone;
    }
    if (options.bgcolor) {
      clone.style.backgroundColor = options.bgcolor;
    }

    if (options.width) {
      clone.style.width = options.width + 'px';
    }
    if (options.height) {
      clone.style.height = options.height + 'px';
    }

    if (options.style != null) {
      _.each(options.style, (value, property) => {
        if (!_.isString(value)) {
          return;
        }
        clone.style[property as any] = value;
      });
    }

    return clone;
  }
}

/**
 * @param {Element} node - The DOM Element object to render
 * @param {Object} options - Rendering options, @see {@link toSvg}
 * @return {Promise} - A promise that is fulfilled with a Uint8Array containing RGBA pixel data.
 * */
function toPixelData(node: Element, options: Options = {}) {
  return draw(node, options || {}).then(
    canvas =>
      canvas
        .getContext('2d')!
        .getImageData(0, 0, util.width(node), util.height(node)).data
  );
}

/**
 * @param {Element} node - The DOM Element object to render
 * @param {Object} options - Rendering options, @see {@link toSvg}
 * @return {Promise} - A promise that is fulfilled with a PNG image data URL
 * */
function toPng(node: Element, options: Options = {}) {
  return draw(node, options ?? {}).then(canvas => canvas.toDataURL());
}

/**
 * @param {Element} node - The DOM Element object to render
 * @param {Object} options - Rendering options, @see {@link toSvg}
 * @return {Promise} - A promise that is fulfilled with a JPEG image data URL
 * */
function toJpeg(node: Element, options: Options = {}) {
  options = options || {};
  return draw(node, options).then(canvas =>
    canvas.toDataURL('image/jpeg', options.quality || 1.0)
  );
}

/**
 * @param {Element} node - The DOM Element object to render
 * @param {Object} options - Rendering options, @see {@link toSvg}
 * @return {Promise} - A promise that is fulfilled with a PNG image blob
 * */
function toBlob(node: Element, options: Options = {}) {
  return draw(node, options || {}).then(util.canvasToBlob);
}

function copyOptions(options: Options = {}) {
  // Copy options to impl options for use in impl
  if (typeof options.imagePlaceholder === 'undefined') {
    domToImage.impl.options.imagePlaceholder = defaultOptions.imagePlaceholder;
  } else {
    domToImage.impl.options.imagePlaceholder = options.imagePlaceholder;
  }

  if (typeof options.cacheBust === 'undefined') {
    domToImage.impl.options.cacheBust = defaultOptions.cacheBust;
  } else {
    domToImage.impl.options.cacheBust = options.cacheBust;
  }
}

function draw(domNode: Element, options: Options = {}) {
  return toSvg(domNode, options)
    .then(util.makeImage)
    .then(util.delay(100))
    .then(image => {
      const canvas = newCanvas();
      canvas.getContext('2d')!.drawImage(image, 0, 0);
      return canvas;
    });

  function newCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = options.width || util.width(domNode);
    canvas.height = options.height || util.height(domNode);

    if (options.bgcolor) {
      const ctx = canvas.getContext('2d');
      ctx!.fillStyle = options.bgcolor;
      ctx!.fillRect(0, 0, canvas.width, canvas.height);
    }

    return canvas;
  }
}

function cloneNode(
  node: Node,
  filter: Options['filter'],
  root: boolean
): Promise<Node | void> {
  if (!root && filter && !filter(node)) {
    return Promise.resolve();
  }

  return Promise.resolve(node)
    .then(makeNodeCopy)
    .then(clone => cloneChildren(node, clone))
    .then(clone => processClone(node, clone));

  function makeNodeCopy() {
    if (node instanceof HTMLCanvasElement) {
      return util.makeImage(node.toDataURL());
    }
    return node.cloneNode(false);
  }

  function cloneChildren(original: Node, clone: Node) {
    const children = original.childNodes;
    if (children.length === 0) {
      return Promise.resolve(clone);
    }

    return cloneChildrenInOrder(clone, util.asArray(children)).then(
      () => clone
    );

    function cloneChildrenInOrder(parent: Node, childrenArray: ChildNode[]) {
      let done = Promise.resolve();
      childrenArray.forEach(
        child =>
          (done = done
            .then(() => cloneNode(child, filter, false))
            .then(childClone => {
              if (childClone) {
                parent.appendChild(childClone);
              }
            }))
      );
      return done;
    }
  }

  function processClone(originalNode: Node, clonedNode: Node) {
    if (
      !(
        originalNode instanceof HTMLElement && clonedNode instanceof HTMLElement
      ) &&
      !(originalNode instanceof SVGElement && clonedNode instanceof SVGElement)
    ) {
      return clonedNode;
    }
    const original = originalNode;
    const clone = clonedNode;

    return Promise.resolve()
      .then(cloneStyle)
      .then(clonePseudoElements)
      .then(copyUserInput)
      .then(fixSvg)
      .then(() => clone);

    function cloneStyle() {
      copyStyle(window.getComputedStyle(original), clone.style);
      revertTransformNone(clone);

      function copyStyle(
        source: CSSStyleDeclaration,
        target: CSSStyleDeclaration
      ) {
        if (source.cssText) {
          target.cssText = source.cssText;
        } else {
          copyProperties();
        }

        function copyProperties() {
          util.asArray(source).forEach(name => {
            target.setProperty(
              name,
              getPropertyValueStandardized(source, name),
              source.getPropertyPriority(name)
            );
          });
        }
      }
    }

    function clonePseudoElements() {
      [':before', ':after'].forEach(clonePseudoElement);

      function clonePseudoElement(element: string) {
        const style = window.getComputedStyle(original, element);
        const content = getPropertyValueStandardized(style, 'content');

        if (content === '""' || content === '"none"') {
          return;
        }

        const className = util.uid();
        if (clone instanceof HTMLElement) {
          clone.className = clone.className + ' ' + className;
        }
        const styleElement = document.createElement('style');
        styleElement.appendChild(formatPseudoElementStyle());
        clone.appendChild(styleElement);

        function formatPseudoElementStyle() {
          const selector = '.' + className + ':' + element;
          const cssText = style.cssText
            ? formatCssText()
            : formatCssProperties();
          return document.createTextNode(selector + '{' + cssText + '}');

          function formatCssText() {
            return style.cssText + ' content: ' + content + ';';
          }

          function formatCssProperties() {
            return util.asArray(style).map(formatProperty).join('; ') + ';';

            function formatProperty(name: string) {
              return (
                name +
                ': ' +
                getPropertyValueStandardized(style, name) +
                (style.getPropertyPriority(name) ? ' !important' : '')
              );
            }
          }
        }
      }
    }

    function copyUserInput() {
      if (original instanceof HTMLTextAreaElement) {
        clone.innerHTML = original.value;
      }
      if (original instanceof HTMLInputElement) {
        clone.setAttribute('value', original.value);
      }
    }

    function fixSvg() {
      if (!(clone instanceof SVGElement)) {
        return;
      }
      clone.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

      if (!(clone instanceof SVGRectElement)) {
        return;
      }
      ['width', 'height'].forEach(attribute => {
        const value = clone.getAttribute(attribute);
        if (!value) {
          return;
        }

        clone.style.setProperty(attribute, value);
      });
    }
  }
}

function embedFonts(node: Node): Promise<Node> {
  return fontFaces.resolveAll().then(cssText => {
    const styleNode = document.createElement('style');
    node.appendChild(styleNode);
    styleNode.appendChild(document.createTextNode(cssText));
    return node;
  });
}

function inlineImages(node: Node): Promise<Node> {
  return images.inlineAll(node).then(() => node);
}

function makeSvgDataUri(
  node: Node,
  width: number,
  height: number
): Promise<string> {
  if (!(node instanceof Element)) {
    return Promise.resolve('');
  }
  return Promise.resolve(node)
    .then(n => {
      n.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
      return new XMLSerializer().serializeToString(n);
    })
    .then(util.escapeXhtml)
    .then(
      xhtml =>
        '<foreignObject x="0" y="0" width="100%" height="100%">' +
        xhtml +
        '</foreignObject>'
    )
    .then(
      foreignObject =>
        '<svg xmlns="http://www.w3.org/2000/svg" width="' +
        width +
        '" height="' +
        height +
        '">' +
        foreignObject +
        '</svg>'
    )
    .then(svgStr =>
      svgStr.replace(
        /text-decoration: [^;]+;/g,
        match => `${match} -webkit-${match}`
      )
    )
    .then(svg => 'data:image/svg+xml;charset=utf-8,' + svg);
}

function newUtil() {
  return {
    escape,
    parseExtension,
    mimeType,
    dataAsUrl,
    isDataUrl,
    canvasToBlob,
    resolveUrl,
    getAndEncode,
    uid: makeUID(),
    delay,
    asArray,
    escapeXhtml,
    makeImage,
    width,
    height,
  };

  function getMimes() {
    /*
     * Only WOFF and EOT mime types for fonts are 'real'
     * see http://www.iana.org/assignments/media-types/media-types.xhtml
     */
    const WOFF = 'application/font-woff';
    const JPEG = 'image/jpeg';

    return {
      woff: WOFF,
      woff2: WOFF,
      ttf: 'application/font-truetype',
      eot: 'application/vnd.ms-fontobject',
      png: 'image/png',
      jpg: JPEG,
      jpeg: JPEG,
      gif: 'image/gif',
      tiff: 'image/tiff',
      svg: 'image/svg+xml',
    };
  }

  function parseExtension(url: string): string {
    const match = /\.([^./]*?)$/g.exec(url);
    if (match) {
      return match[1];
    } else {
      return '';
    }
  }

  function mimeType(url: string): string {
    const extension = parseExtension(url).toLowerCase();
    const mimes = getMimes();
    return mimes[extension as keyof typeof mimes] ?? '';
  }

  function isDataUrl(url: string): boolean {
    return url.search(/^(data:)/) !== -1;
  }

  function utilToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise(resolve => {
      const binaryString = window.atob(canvas.toDataURL().split(',')[1]);
      const length = binaryString.length;
      const binaryArray = new Uint8Array(length);

      for (let i = 0; i < length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
      }

      resolve(
        new Blob([binaryArray], {
          type: 'image/png',
        })
      );
    });
  }

  function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    if (canvas.toBlob) {
      return new Promise(resolve =>
        canvas.toBlob(b => {
          if (b != null) {
            resolve(b);
          }
        })
      );
    }

    return utilToBlob(canvas);
  }

  function resolveUrl(url: string, baseUrl: string): string {
    const doc = document.implementation.createHTMLDocument();
    const base = doc.createElement('base');
    doc.head.appendChild(base);
    const a = doc.createElement('a');
    doc.body.appendChild(a);
    base.href = baseUrl;
    a.href = url;
    return a.href;
  }

  function makeUID() {
    let index = 0;

    return () => {
      return 'u' + fourRandomChars() + index++;

      function fourRandomChars() {
        /* see http://stackoverflow.com/a/6248722/2519373 */
        return ('0000' + ((Math.random() * Math.pow(36, 4)) << 0).toString(36)) // tslint:disable-line:no-bitwise
          .slice(-4);
      }
    };
  }

  function makeImage(uri: string): Promise<HTMLImageElement> {
    // speculation: rejecting the promise directly might be throwing cryptic sentry error. Testing...
    // https://sentry.io/organizations/weights-biases/issues/2437783348/events/42479a4a639149b49567c69e0e7e6db4/?project=1201719&query=is%3Aunresolved
    // https://github.com/getsentry/sentry-javascript/issues/2546#issuecomment-703331367
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.onerror = e => {
        reject(new Error('Failed to load image with src: ' + uri));
      };
      image.src = uri;
    });
  }

  function getAndEncode(url: string): Promise<string> {
    const TIMEOUT = 30000;
    if (domToImage.impl.options.cacheBust) {
      // Cache bypass so we dont have CORS issues with cached images
      // Source: https://developer.mozilla.org/en/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#Bypassing_the_cache
      url += (/\?/.test(url) ? '&' : '?') + new Date().getTime();
    }

    return new Promise(resolve => {
      const request = new XMLHttpRequest();

      request.onreadystatechange = done;
      request.ontimeout = timeout;
      request.responseType = 'blob';
      request.timeout = TIMEOUT;
      request.open('GET', url, true);
      request.send();

      let placeholder: string | undefined;
      if (domToImage.impl.options.imagePlaceholder) {
        const split = domToImage.impl.options.imagePlaceholder.split(/,/);
        if (split && split[1]) {
          placeholder = split[1];
        }
      }

      function done() {
        if (request.readyState !== 4) {
          return;
        }

        if (request.status !== 200) {
          if (placeholder) {
            resolve(placeholder);
          } else {
            fail(
              'cannot fetch resource: ' + url + ', status: ' + request.status
            );
          }

          return;
        }

        const encoder = new FileReader();
        encoder.onloadend = () => {
          if (!_.isString(encoder.result)) {
            return;
          }
          const content = encoder.result.split(/,/)[1];
          resolve(content);
        };
        encoder.readAsDataURL(request.response);
      }

      function timeout() {
        if (placeholder) {
          resolve(placeholder);
        } else {
          fail(
            'timeout of ' +
              TIMEOUT +
              'ms occured while fetching resource: ' +
              url
          );
        }
      }

      function fail(message: string): void {
        console.error(message);
        resolve('');
      }
    });
  }

  function dataAsUrl(content: string, type: string): string {
    return 'data:' + type + ';base64,' + content;
  }

  function escape(str: string): string {
    return str.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
  }

  function delay<T>(ms: number): (arg: T) => Promise<T> {
    return arg => new Promise(resolve => setTimeout(() => resolve(arg), ms));
  }

  type ArrayLike<T> = {length: number; [index: number]: T};
  function asArray<T>(arrayLike: ArrayLike<T>): T[] {
    const arr: T[] = [];
    const length = arrayLike.length;
    for (let i = 0; i < length; i++) {
      arr.push(arrayLike[i]);
    }
    return arr;
  }

  function escapeXhtml(str: string): string {
    return str.replace(/#/g, '%23').replace(/\n/g, '%0A');
  }

  function width(node: Element): number {
    const leftBorder = px(node, 'border-left-width');
    const rightBorder = px(node, 'border-right-width');
    return node.scrollWidth + leftBorder + rightBorder;
  }

  function height(node: Element): number {
    const topBorder = px(node, 'border-top-width');
    const bottomBorder = px(node, 'border-bottom-width');
    return node.scrollHeight + topBorder + bottomBorder;
  }

  function px(node: Element, styleProperty: string) {
    const value = window.getComputedStyle(node).getPropertyValue(styleProperty);
    return parseFloat(value.replace('px', ''));
  }
}

function newInliner() {
  const URL_REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;

  return {
    inlineAll,
    shouldProcess,
    impl: {
      readUrls,
      inline,
    },
  };

  function shouldProcess(str: string): boolean {
    return str.search(URL_REGEX) !== -1;
  }

  function readUrls(str: string): string[] {
    const result = [];
    let match: RegExpExecArray | null = URL_REGEX.exec(str);
    while (match != null) {
      result.push(match[1]);
      match = URL_REGEX.exec(str);
    }
    return result.filter(url => !util.isDataUrl(url));
  }

  function inline(
    str: string,
    url: string,
    baseUrl?: string | null | undefined,
    get?: (s: string) => Promise<string>
  ) {
    return Promise.resolve(url)
      .then(() => (baseUrl ? util.resolveUrl(url, baseUrl) : url))
      .then(get || util.getAndEncode)
      .then(data => util.dataAsUrl(data, util.mimeType(url)))
      .then(dataUrl =>
        wrapFormatInQuotes(str).replace(urlAsRegex(), '$1' + dataUrl + '$3')
      );

    function urlAsRegex(): RegExp {
      return new RegExp(
        '(url\\([\'"]?)(' + util.escape(url) + ')([\'"]?\\))',
        'g'
      );
    }
  }

  function inlineAll(
    str: string,
    baseUrl?: string | null | undefined,
    get?: (s: string) => Promise<string>
  ) {
    if (nothingToInline()) {
      return Promise.resolve(str);
    }

    return Promise.resolve(str)
      .then(readUrls)
      .then(urls => {
        let done = Promise.resolve(str);
        urls.forEach(url => {
          done = done.then(s => inline(s, url, baseUrl, get));
        });
        return done;
      });

    function nothingToInline() {
      return !shouldProcess(str);
    }
  }
}

function newFontFaces() {
  return {resolveAll};

  async function resolveAll(): Promise<string> {
    const webFontPromises = await readAll();
    const cssStrings = await Promise.all(webFontPromises);
    return cssStrings.join('\n');
  }

  async function readAll(): Promise<Array<Promise<string>>> {
    const styleSheets = util.asArray(document.styleSheets);
    const cssRules = await getCssRules();
    const fontFaceRules = selectWebFontRules();
    return fontFaceRules.map(getWebFontPromise);

    function selectWebFontRules(): CSSFontFaceRule[] {
      return cssRules
        .filter(
          (rule): rule is CSSFontFaceRule =>
            rule.type === CSSRule.FONT_FACE_RULE
        )
        .filter((rule: CSSFontFaceRule) =>
          inliner.shouldProcess(rule.style.getPropertyValue('src'))
        );
    }

    async function getCssRules(): Promise<CSSRule[]> {
      const rules: CSSRule[] = [];
      const externalCSSRulePromises: Array<Promise<CSSRule[]>> = [];

      for (const sheet of styleSheets) {
        try {
          util.asArray(sheet.cssRules || []).forEach(r => rules.push(r));
        } catch (err) {
          console.log(
            `Error while reading CSS rules from ${sheet.href}: ${err}`
          );
          if (sheet.href != null) {
            externalCSSRulePromises.push(
              getCSSRulesFromExternalStylesheet(sheet.href)
            );
          }
        }
      }

      if (externalCSSRulePromises.length > 0) {
        const externalCSSRules = _.flatten(
          await Promise.all(externalCSSRulePromises)
        );
        rules.push(...externalCSSRules);
      }
      return rules;
    }

    function getWebFontPromise(webFontRule: CSSFontFaceRule): Promise<string> {
      const baseUrl = webFontRule.parentStyleSheet?.href;
      return inliner.inlineAll(webFontRule.cssText, baseUrl, undefined);
    }

    async function getCSSRulesFromExternalStylesheet(
      href: string
    ): Promise<CSSRule[]> {
      try {
        // eslint-disable-next-line wandb/no-unprefixed-urls
        const response = await fetch(href);
        const text = await response.text();
        const styleEl = document.createElement('style');
        styleEl.textContent = text;
        document.body.appendChild(styleEl);
        const externalRules = util.asArray(styleEl.sheet?.cssRules ?? []);
        styleEl.remove();
        return externalRules;
      } catch (err) {
        console.log(`Error fetching external stylesheet from ${href}: ${err}`);
        return [];
      }
    }
  }
}

function newImages() {
  return {
    inlineAll,
    impl: {
      newImage,
    },
  };

  function newImage(image: HTMLImageElement) {
    return {
      inline,
    };

    function inline(): Promise<void> {
      if (util.isDataUrl(image.src)) {
        return Promise.resolve();
      }

      return Promise.resolve(image.src)
        .then(util.getAndEncode)
        .then(data => util.dataAsUrl(data, util.mimeType(image.src)))
        .then(
          dataUrl =>
            new Promise((resolve, reject) => {
              image.onload = () => resolve();
              image.onerror = reject;
              image.src = dataUrl;
            })
        );
    }
  }

  function inlineAll(node: Node): Promise<void> {
    if (!(node instanceof HTMLElement) && !(node instanceof SVGElement)) {
      return Promise.resolve();
    }

    return inlineBackground(node).then(() => {
      if (node instanceof HTMLImageElement) {
        return newImage(node).inline();
      }
      return Promise.all(
        util.asArray(node.childNodes).map(child => inlineAll(child))
      ).then();
    });

    function inlineBackground(
      element: HTMLElement | SVGElement
    ): Promise<HTMLElement | SVGElement> {
      const background = element.style.getPropertyValue('background');

      if (!background) {
        return Promise.resolve(element);
      }

      return inliner
        .inlineAll(background)
        .then(inlined =>
          element.style.setProperty(
            'background',
            inlined,
            element.style.getPropertyPriority('background')
          )
        )
        .then(() => element);
    }
  }
}

function getPropertyValueStandardized(
  style: CSSStyleDeclaration,
  name: string
): string {
  const val = style.getPropertyValue(name);
  if (name === 'content') {
    // Some browsers need this property to be wrapped in quotes
    return wrapInQuotes(val);
  }
  return val;
}

function wrapInQuotes(val: string): string {
  if (val.startsWith('"') && val.endsWith('"')) {
    return val;
  }
  return `"${val}"`;
}

const WEBFONT_FORMAT_REGEX: RegExp = /format\(([^)]+)\)/g;
function wrapFormatInQuotes(str: string): string {
  return str.replace(WEBFONT_FORMAT_REGEX, (match, formatStr) => {
    const formatStrWrapped = wrapInQuotes(formatStr);
    if (formatStr === formatStrWrapped) {
      return match;
    }
    return match.replace(formatStr, formatStrWrapped);
  });
}

function revertTransformNone(clone: HTMLElement | SVGElement): void {
  if (!isTransformedSVGElement(clone) || clone.style.transform !== 'none') {
    return;
  }
  clone.style.transform = '';
}

function isTransformedSVGElement(node: Node): boolean {
  if (!(node instanceof SVGGraphicsElement)) {
    return false;
  }
  const {
    transform: {baseVal, animVal},
  } = node;
  return baseVal.numberOfItems > 0 || animVal.numberOfItems > 0;
}
