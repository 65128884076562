import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import * as globals from '@wandb/common/css/globals.styles';
import React, {memo, useCallback, useState} from 'react';
import {Icon, Popup} from 'semantic-ui-react';

import {ValueOp} from '../../util/filters';
import {
  displayValue,
  displayValueSpan,
  sortableValue,
} from '../../util/runhelpers';
import {Key as RunKey, Value as RunValue} from '../../util/runs';
import {FilterUneditable} from '../Filters/FilterUneditable';

interface WBTableCellDefaultProps {
  columnKey: RunKey;
  cellValue: RunValue;
  isExpanded?: boolean;
  popupTrigger?: JSX.Element; // if true, cellValue will be displayed in a popup
  addFilter?(key: RunKey, op: ValueOp, value: RunValue): void;
  toggleExpandedRow?(): void;
}

const WBTableCellDefaultComp: React.FC<WBTableCellDefaultProps> = ({
  columnKey,
  cellValue,
  addFilter,
  toggleExpandedRow,
  isExpanded,
  popupTrigger,
}) => {
  const [hover, setHover] = useState(false);
  const onIconHover = useCallback(() => setHover(true), []);
  const onIconUnhover = useCallback(() => setHover(false), []);

  // We conditionally render the popup as a performance optimization.
  const filterIcon = (
    <LegacyWBIcon
      title=""
      className="icon-filter"
      name="filter"
      style={{pointerEvents: 'none'}}
    />
  );
  return (
    <React.Fragment>
      {toggleExpandedRow && (
        <Icon
          className={`triangle ${isExpanded ? 'down' : 'right'}`}
          onClick={toggleExpandedRow}
        />
      )}
      {popupTrigger ? (
        <Popup
          hoverable
          trigger={popupTrigger}
          content={displayValue(cellValue)}
        />
      ) : (
        displayValueSpan(cellValue)
      )}
      {addFilter && (
        <div
          style={{display: 'inline-block', cursor: 'pointer'}}
          className="icon-wrap"
          onClick={() => {
            addFilter(columnKey, '=', sortableValue(cellValue));
          }}
          onMouseEnter={onIconHover}
          onMouseLeave={onIconUnhover}>
          {hover ? (
            <Popup
              className="popup--cell-header"
              inverted
              position="bottom center"
              on="hover"
              hoverable
              flowing
              open
              trigger={filterIcon}
              size="mini"
              content={
                <React.Fragment>
                  <div style={{color: globals.gray500}}>
                    Apply filter to table:
                  </div>
                  <div style={{display: 'flex'}}>
                    <FilterUneditable
                      filter={{
                        key: columnKey,
                        op: '=',
                        value: cellValue,
                      }}
                    />
                  </div>
                </React.Fragment>
              }
            />
          ) : (
            filterIcon
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export const WBTableCellDefault = memo(WBTableCellDefaultComp);
