import {Buffer} from 'buffer';
import fnv from 'fnv-plus';
import {sortBy} from 'lodash';
import {useContext, useMemo} from 'react';

import {ActiveExperiment, ActiveVariant} from '../../generated/graphql';
import {ExperimentContext} from './ExperimentContextProvider';
import {
  DEFAULT_EXPERIMENT_DATA,
  ExperimentData,
  ExperimentQueryParams,
} from './types';
import {validateExperimentVariants} from './validation';

export const getExperimentData = (
  activeExperiment: ActiveExperiment,
  observationalId: string,
  controlBucketValue: number
): ExperimentData => {
  const {
    id: graphqlExperimentId,
    name: experimentName,
    startAt,
    endAt,
    activeVariants,
  } = activeExperiment;
  const experimentQueryParams: ExperimentQueryParams =
    getExperimentQueryParams();

  // Default to control if observationalUnitId is not populated
  const observationalUnitId = observationalId ?? '';

  const defaultExperimentData: ExperimentData = {
    ...DEFAULT_EXPERIMENT_DATA,
    observationalUnitId,
    graphqlExperimentId,
    experimentName,
  };

  // Force-bucketing an experiment by using query params
  if (hasValidVariantQueryParams(graphqlExperimentId, experimentQueryParams)) {
    return {
      ...defaultExperimentData,
      treatment: parseInt(experimentQueryParams.experimentBucketParam, 10),
    };
  }

  const isExperimentRunning = startAt != null && endAt == null;
  if (!isExperimentRunning || observationalUnitId === '') {
    // Default to control when observationalUnitId is invalid
    return {
      ...defaultExperimentData,
      treatment: controlBucketValue,
    };
  }

  // hash the graphqlExperimentId and observationalUnitId to assign a treatment. The experimentId is the "salt"
  // that prevents users from consistently being bucketed into the same variants across different experiments
  const hash = Number(
    fnv.hash(graphqlExperimentId + observationalUnitId).dec()
  );
  const assignedBucket = getAssignedBucket(activeVariants, hash);
  if (assignedBucket == null) {
    // Default to control
    return {
      ...defaultExperimentData,
      hash,
      treatment: controlBucketValue,
    };
  }

  return {
    ...defaultExperimentData,
    hash,
    treatment: assignedBucket,
    log: true,
  };
};

const getExperimentQueryParams = (): ExperimentQueryParams => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    experimentIdParam: queryParams.get('experimentId') ?? '',
    experimentBucketParam: queryParams.get('experimentBucket') ?? '',
  };
};

const hasValidVariantQueryParams = (
  experimentId: string,
  experimentQueryParams: ExperimentQueryParams
): boolean => {
  const {experimentIdParam, experimentBucketParam} = experimentQueryParams;

  if (
    experimentIdParam !== experimentId ||
    experimentBucketParam == null ||
    isNaN(parseInt(experimentBucketParam, 10))
  ) {
    return false;
  }

  return true;
};

const getAssignedBucket = (
  experimentVariants: ActiveVariant[],
  hash: number
): number | null => {
  const assignment = hash % 100;

  // determine which variant to use based on the assignment and variant allocation
  const sortedVariants = sortBy(experimentVariants, ['bucket']);
  let allocation = 0;
  for (const v of sortedVariants) {
    allocation += v.allocation;
    if (assignment < allocation) {
      return v.bucket;
    }
  }

  return null;
};

/**
 * Decode graphql experiment id to database id in experiments table.
 * Note: it will be prefixed with "Experiment:"
 */
export function decodedExperimentId(graphqlExperimentId: string) {
  return Buffer.from(graphqlExperimentId, 'base64')
    .toString()
    .replace('Experiment:', '');
}

export const useExperiment = (
  experimentId: string,
  observationalUnitId: string,
  controlBucketValue: number,
  bucketValues: Set<number> // The set of buckets that the code is expecting for this experiment
): {
  isLoading: boolean;
  error: boolean;
  activeExperiment?: ActiveExperiment;
  experimentData?: ExperimentData;
} => {
  const {activeExperiments, isExperimentsQueryLoading} =
    useContext(ExperimentContext);

  return useMemo(() => {
    if (isExperimentsQueryLoading) {
      return {
        isLoading: true,
        error: false,
        activeExperiment: undefined,
        experimentData: undefined,
      };
    }

    const activeExperiment = activeExperiments.find(e => e.id === experimentId);

    if (!activeExperiment) {
      return {
        isLoading: false,
        error: true,
        activeExperiment: undefined,
        experimentData: undefined,
      };
    }

    validateExperimentVariants(activeExperiment, bucketValues);

    const experimentData = getExperimentData(
      activeExperiment,
      observationalUnitId,
      controlBucketValue
    );
    return {isLoading: false, error: false, activeExperiment, experimentData};
  }, [
    experimentId,
    bucketValues,
    isExperimentsQueryLoading,
    activeExperiments,
    observationalUnitId,
    controlBucketValue,
  ]);
};
