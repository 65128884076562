import Tooltip from '@material-ui/core/Tooltip';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import classNames from 'classnames';
import {compact, findIndex, indexOf, isEqual, uniqBy} from 'lodash';
import moment from 'moment';
import * as queryString from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Mention, MentionsInput} from 'react-mentions';
import {Link, useHistory} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {Button, Icon, Image, Loader, Popup} from 'semantic-ui-react';
import {useSlate} from 'slate-react';

import {useSelector} from '../state/hooks';
import * as DiscussionCommentHooks from '../state/views/discussionComment/hooks';
import * as DiscussionCommentThunks from '../state/views/discussionComment/thunks';
import {
  DiscussionCommentUser,
  Ref as DiscussionCommentRef,
} from '../state/views/discussionComment/types';
import {Ref as DiscussionThreadRef} from '../state/views/discussionThread/types';
import * as ViewHooks from '../state/views/hooks';
import {useAdminModeActive} from '../util/admin';
import {hideZendeskChat, showZendeskChat} from '../util/analytics';
import {setAuthRedirect} from '../util/auth';
import {scrollToElement, scrollToElementByID} from '../util/document';
import {KeysHandler, useKeyPressHandler} from '../util/hooks';
import {profilePage} from '../util/urls';
import {
  ReportDiscussionContext,
  ReportDiscussionDraftContext,
  ReportDiscussionUpdaterContext,
} from './ReportDiscussionContext';
import {
  EditorWithComments,
  getInlineRefIDsFromCommentBody,
  INLINE_COMMENT_PREFIX,
} from './Slate/plugins/inline-comments-common';

// A report has many DiscussionThreads
// A DiscussionThread has many DiscussionComments (the original comment, plus all the replies)
// When you create a new thread, it automatically creates the first comment in the thread
export const ReportDiscussion: React.FC<{isViewingReport: boolean}> = ({
  isViewingReport,
}) => {
  const {
    viewer,
    loadingDiscussionThreads,
    discussionThreadRefs,
    commentFrame,
    activeThreadRef,
  } = useContext(ReportDiscussionContext);

  const {setCommentFrame} = useContext(ReportDiscussionUpdaterContext);

  const [editingCommentRefs, setEditingCommentRefs] = useState<
    DiscussionCommentRef[]
  >([]);

  const history = useHistory();
  // Hide the Zendesk popup (overlaps with comment thread frame)
  useEffect(() => {
    if (commentFrame != null) {
      hideZendeskChat();
    } else {
      showZendeskChat();
    }
    return () => {
      showZendeskChat();
    };
  }, [commentFrame]);

  const redirectViewerToLogin = () => {
    setAuthRedirect(history.location.pathname);
    history.push('/login');
  };

  return (
    <div id="comments" className="report-discussion">
      {(isViewingReport || activeThreadRef != null) && (
        <div className="discussion-cta">
          {viewer != null && (
            <div className="comment-pic">
              <Image src={viewer.photoUrl} avatar />
            </div>
          )}
          <Tooltip // TODO: use followCursor={true} when we upgrade to mui v5
            title={viewer == null ? 'Please login to leave a comment' : ''}
            enterDelay={0}>
            <div
              className="discussion-cta-comment-box"
              onClick={() => {
                if (viewer == null) {
                  redirectViewerToLogin();
                } else {
                  setCommentFrame('editor');
                }
              }}>
              {COMMENT_PLACEHOLDER}
            </div>
          </Tooltip>
        </div>
      )}
      {loadingDiscussionThreads ? (
        <div style={{position: 'relative', height: 200}}>
          <Loader active />
        </div>
      ) : (
        discussionThreadRefs != null &&
        discussionThreadRefs.length > 0 && (
          <div className="discussion-thread-container">
            {discussionThreadRefs.map(dtRef => (
              <DiscussionThreadComp
                key={dtRef.id}
                active={
                  commentFrame === 'thread' && isEqual(dtRef, activeThreadRef)
                }
                discussionThreadRef={dtRef}
                editingCommentRefs={editingCommentRefs}
                setEditingCommentRefs={setEditingCommentRefs}
              />
            ))}
          </div>
        )
      )}
      {/* Creating a new thread */}
      {viewer != null && <CommentEditorFrame />}
      {/* Reading + replying to existing threads */}
      {discussionThreadRefs.length > 0 && activeThreadRef != null && (
        <CommentThreadFrame
          editingCommentRefs={editingCommentRefs}
          setEditingCommentRefs={setEditingCommentRefs}
        />
      )}
    </div>
  );
};

const DiscussionThreadComp: React.FC<{
  discussionThreadRef: DiscussionThreadRef;
  active: boolean; // whether or not this is the thread open in the CommentThreadFrame
  editingCommentRefs: DiscussionCommentRef[];
  setEditingCommentRefs(refs: DiscussionCommentRef[]): void;
}> = ({
  discussionThreadRef,
  active,
  editingCommentRefs,
  setEditingCommentRefs,
}) => {
  const discussionThread = ViewHooks.useWhole(discussionThreadRef);
  const commentRefs = ViewHooks.usePart(discussionThreadRef).commentRefs;
  const {setActiveThread} = useContext(ReportDiscussionUpdaterContext);
  const threadParticipants = useMemo(
    () => discussionThread.comments.slice(1).map(c => c.poster),
    [discussionThread.comments]
  );
  const replyCount = discussionThread.comments.length - 1;

  const openDiscussionThread = useCallback(
    () => setActiveThread(discussionThreadRef, replyCount === 0),
    [setActiveThread, discussionThreadRef, replyCount]
  );

  // scroll to discussion thread and open
  const threadRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const qs = queryString.parse(window.location.search);
    if (
      qs.discussionThread != null &&
      qs.discussionThread === discussionThread.id
    ) {
      threadRef.current?.scrollIntoView();
      openDiscussionThread();
    }
    // eslint-disable-next-line
  }, []);

  const commentActions = useMemo(
    () => (
      <div className="comment-actions">
        <div className="comment-actions-reply" onClick={openDiscussionThread}>
          {replyCount === 0 ? (
            'Reply'
          ) : (
            <>
              <ReplyAvatars
                users={threadParticipants as DiscussionCommentUser[]}
              />
              {`${replyCount} ${replyCount === 1 ? 'reply' : 'replies'}`}
            </>
          )}
        </div>
        {/* <div>Resolve</div> */}
      </div>
    ),
    [replyCount, openDiscussionThread, threadParticipants]
  );

  const editCommentCallback = useCallback(() => {
    setActiveThread(discussionThreadRef, false);
    setEditingCommentRefs([...editingCommentRefs, commentRefs[0]]);
  }, [
    commentRefs,
    discussionThreadRef,
    editingCommentRefs,
    setActiveThread,
    setEditingCommentRefs,
  ]);

  const cancelEditCallback = useCallback(() => {
    setEditingCommentRefs(
      editingCommentRefs.filter(ref => !isEqual(ref, commentRefs[0]))
    );
  }, [commentRefs, editingCommentRefs, setEditingCommentRefs]);

  if (commentRefs[0] == null) {
    return <div />;
  }
  return (
    <div
      ref={threadRef}
      className={classNames('discussion-thread', {
        active,
      })}>
      <ReportComment
        discussionCommentRef={commentRefs[0]}
        discussionThreadRef={discussionThreadRef}
        commentActions={commentActions}
        editing={false}
        editComment={editCommentCallback}
        cancelEdit={cancelEditCallback}
      />
    </div>
  );
};

const COMMENT_PLACEHOLDER = 'Write a comment...';

// For browsing/replying to existing threads
const CommentThreadFrame: React.FC<{
  editingCommentRefs: DiscussionCommentRef[];
  setEditingCommentRefs(refs: DiscussionCommentRef[]): void;
}> = ({editingCommentRefs, setEditingCommentRefs}) => {
  const {
    viewer,
    commentFrame,
    loadingCreateComment,
    autofocus,
    activeThreadRef,
    allActiveThreadRefs,
    panelCommentsEnabled,
    teamMembers,
    reportServerID,
    readOnly,
  } = useContext(ReportDiscussionContext);

  if (activeThreadRef == null) {
    throw new Error('undefined discussion thread');
  }

  const {
    commentThreadFrameReplyDraftMap: replyDraftMap,
    setCommentThreadFrameReplyDraftMap: setReplyDraftMap,
  } = useContext(ReportDiscussionDraftContext);

  const {createComment, setActiveThread, setInlineCommentDetails} = useContext(
    ReportDiscussionUpdaterContext
  );

  const open = commentFrame === 'thread';

  const activeThreadIndex = Math.max(
    0,
    indexOf(allActiveThreadRefs, activeThreadRef)
  );
  const activeThreadCommentRefs =
    ViewHooks.usePart(activeThreadRef).commentRefs;
  const activeThreadID = ViewHooks.usePart(activeThreadRef).id;
  const activeReplyDraft = replyDraftMap[activeThreadRef.id] || '';

  const clearDraftAndCloseReply = useCallback(() => {
    setReplyDraftMap({...replyDraftMap, [activeThreadRef.id]: ''});
    setInlineCommentDetails(undefined);
  }, [
    activeThreadRef.id,
    replyDraftMap,
    setReplyDraftMap,
    setInlineCommentDetails,
  ]);

  const onSubmit = useCallback(() => {
    if (activeReplyDraft.trim() === '') {
      return;
    }

    window.analytics?.track('Submit Reports Comment Clicked', {
      reportID: reportServerID,
      location: 'reply',
      mode: readOnly ? 'view' : 'edit',
    });
    if (createComment != null) {
      createComment(
        {
          body: activeReplyDraft,
          discussionThreadID: activeThreadID,
        },
        activeThreadRef
      );
      clearDraftAndCloseReply();
    }
  }, [
    reportServerID,
    readOnly,
    createComment,
    activeReplyDraft,
    activeThreadID,
    activeThreadRef,
    clearDraftAndCloseReply,
  ]);

  return (
    <div
      className="comment-frame comment-thread-frame"
      style={{
        visibility: open ? 'visible' : 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <LegacyWBIcon
        onClick={() => {
          setActiveThread(undefined);
        }}
        name="close"
      />
      <div className="comment-frame-actions">
        <Button.Group className="pagination-buttons">
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            disabled={activeThreadIndex <= 0}
            onClick={() => {
              setActiveThread(
                allActiveThreadRefs[activeThreadIndex - 1],
                true,
                allActiveThreadRefs
              );
            }}>
            <LegacyWBIcon name="previous" />
          </Button>
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            disabled={activeThreadIndex >= allActiveThreadRefs.length - 1}
            onClick={() => {
              setActiveThread(
                allActiveThreadRefs[activeThreadIndex + 1],
                true,
                allActiveThreadRefs
              );
            }}>
            <LegacyWBIcon name="next" />
          </Button>
        </Button.Group>
        <div>
          {`${activeThreadIndex + 1} of ${allActiveThreadRefs.length} thread${
            allActiveThreadRefs.length === 1 ? '' : 's'
          }`}
        </div>
        <div style={{flexGrow: 1}} />
      </div>
      <div className="comment-frame-comments">
        {activeThreadCommentRefs.map(cRef => {
          return (
            <ReportComment
              key={cRef.id}
              discussionCommentRef={cRef}
              discussionThreadRef={activeThreadRef}
              editComment={() =>
                setEditingCommentRefs([...editingCommentRefs, cRef])
              }
              editing={
                findIndex(editingCommentRefs, ref => isEqual(ref, cRef)) > -1
              }
              cancelEdit={() => {
                setEditingCommentRefs(
                  editingCommentRefs.filter(ref => !isEqual(ref, cRef))
                );
              }}
            />
          );
        })}
      </div>
      {viewer != null && (
        <>
          <div className="comment-reply-editor">
            <div className="comment-pic">
              {<Image src={viewer.photoUrl} avatar />}
            </div>
            <div className="comment-reply">
              <MentionableTextarea
                key={activeThreadRef.id}
                autofocus={autofocus}
                placeholder="Write a response..."
                commentBody={activeReplyDraft}
                setCommentBody={value =>
                  setReplyDraftMap({
                    ...replyDraftMap,
                    [activeThreadRef.id]: value,
                  })
                }
                onSubmit={onSubmit}
              />
              {activeReplyDraft.trim() !== '' && (
                <div className="comment-reply-actions">
                  <Button
                    data-test="submit-reply"
                    primary
                    size="mini"
                    disabled={loadingCreateComment}
                    onClick={onSubmit}>
                    Reply
                  </Button>
                  <Button
                    className="cancel"
                    size="mini"
                    onClick={clearDraftAndCloseReply}>
                    Cancel
                  </Button>
                  <div className="comment-shortcuts">
                    {teamMembers.length > 1 && (
                      <div>
                        <strong>@</strong> to mention a user
                      </div>
                    )}
                    {panelCommentsEnabled && (
                      <div>
                        <strong>&</strong> to mention a panel
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// For creating a new thread
const CommentEditorFrame: React.FC = () => {
  const {
    viewer,
    loadingCreateComment,
    commentFrame,
    panelCommentsEnabled,
    teamMembers,
    reportServerID,
    readOnly,
  } = useContext(ReportDiscussionContext);

  const {
    commentEditorFrameCommentBody: commentBody,
    setCommentEditorFrameCommentBody: setCommentBody,
  } = useContext(ReportDiscussionDraftContext);

  const {
    setActiveThread,
    createComment,
    replacePanelMentionTokens,
    setInlineCommentDetails,
  } = useContext(ReportDiscussionUpdaterContext);

  const open = commentFrame === 'editor';

  const clearAndClose = useCallback(() => {
    setCommentBody('');
    setActiveThread(undefined);
    setInlineCommentDetails(undefined);
  }, [setCommentBody, setActiveThread, setInlineCommentDetails]);

  const [minimized, setMinimized] = useState(false);

  const onSubmit = useCallback(() => {
    if (commentBody.trim() === '') {
      return;
    }
    window.analytics?.track('Submit Reports Comment Clicked', {
      reportID: reportServerID,
      location: 'new thread',
      mode: readOnly ? 'view' : 'edit',
    });
    if (createComment != null) {
      createComment({body: commentBody});
      clearAndClose();
    }
  }, [reportServerID, readOnly, createComment, commentBody, clearAndClose]);

  return (
    <div
      className="comment-frame"
      style={{visibility: open ? 'visible' : 'hidden'}}>
      {minimized ? (
        <div
          className="comment-editor-minimized"
          onClick={() => setMinimized(false)}>
          {commentBody.trim() === ''
            ? COMMENT_PLACEHOLDER
            : replacePanelMentionTokens(commentBody)}
        </div>
      ) : (
        <>
          <Icon onClick={() => setMinimized(true)} name="minus" size="mini" />
          {viewer != null && (
            <CommentMeta poster={viewer as DiscussionCommentUser} />
          )}
          <div className="comment-editor">
            <MentionableTextarea
              commentBody={commentBody}
              setCommentBody={setCommentBody}
              autofocus={open}
              placeholder={COMMENT_PLACEHOLDER}
              onSubmit={onSubmit}
            />
            <div className="comment-reply-actions">
              <Button
                data-test="submit-comment"
                disabled={loadingCreateComment || commentBody.trim() === ''}
                primary
                size="mini"
                onClick={onSubmit}>
                Post comment
              </Button>
              <Button className="cancel" size="mini" onClick={clearAndClose}>
                Cancel
              </Button>
              <div className="comment-shortcuts">
                {teamMembers.length > 1 && (
                  <div>
                    <strong>@</strong> to mention a user
                  </div>
                )}
                {panelCommentsEnabled && (
                  <div>
                    <strong>&</strong> to mention a panel
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const CommentPic: React.FC<{poster: DiscussionCommentUser}> = ({poster}) => {
  const {username, photoUrl} = poster;
  return (
    <div className="comment-pic">
      {username != null &&
        photoUrl != null &&
        (userIsDeleted(username) ? (
          <Image src={photoUrl} avatar />
        ) : (
          <Link to={profilePage(username)}>
            {<Image src={photoUrl} avatar />}
          </Link>
        ))}
    </div>
  );
};

const CommentMeta: React.FC<{
  poster: DiscussionCommentUser;
  createdAt?: Date;
  updatedAt?: Date;
}> = ({poster, createdAt, updatedAt}) => {
  const {name, username} = poster;
  const createdTime = `${moment(createdAt + 'Z').format(
    'MMMM D, YYYY'
  )} at ${moment(createdAt + 'Z').format('h:mma')}`;
  const updatedTime =
    updatedAt == null
      ? undefined
      : `Edited ${moment(updatedAt + 'Z').format('MMMM D, YYYY')} at ${moment(
          updatedAt + 'Z'
        ).format('h:mma')}`;
  return (
    <div className="comment-meta">
      <CommentPic poster={poster} />
      <div>
        {username != null &&
          name != null &&
          (userIsDeleted(name) ? (
            <span className="comment-username">{name}</span>
          ) : (
            <Link to={profilePage(username)}>
              <span className="comment-username">{name}</span>
            </Link>
          ))}{' '}
        {createdAt != null && (
          <Popup
            trigger={
              <span className="comment-createdat">
                &bull;&nbsp;&nbsp;
                {(new Date().getTime() - new Date(createdAt + 'Z').getTime()) /
                  1000 <
                60 ? (
                  'just now'
                ) : (
                  <TimeAgo date={createdAt + 'Z'} live={false} />
                )}
                {updatedAt != null && createdAt !== updatedAt && '*'}
              </span>
            }
            content={
              <div>
                <div>{createdTime}</div>
                {updatedTime != null && <div>{updatedTime}</div>}
              </div>
            }
            inverted
            size="mini"
            position="top center"
          />
        )}
      </div>
    </div>
  );
};

// A single comment
const ReportComment: React.FC<{
  discussionThreadRef: DiscussionThreadRef;
  discussionCommentRef: DiscussionCommentRef;
  commentActions?: JSX.Element;
  editing: boolean;
  editComment(): void;
  cancelEdit?(): void;
}> = ({
  discussionThreadRef,
  discussionCommentRef,
  commentActions,
  editComment,
  editing,
  cancelEdit,
}) => {
  const {viewer, activeThreadRef, reportServerID, reportViewRef} = useContext(
    ReportDiscussionContext
  );
  const {replacePanelMentionTokens, deleteComment, setActiveThread} =
    useContext(ReportDiscussionUpdaterContext);
  const comment = ViewHooks.useWhole(discussionCommentRef);
  const discussionThread = ViewHooks.useWhole(discussionThreadRef);
  const editor = useSlate();

  const [commentBody, setCommentBody] = useState(comment.body);

  const updateComment = DiscussionCommentHooks.useDiscussionCommentRefThunk(
    discussionCommentRef,
    DiscussionCommentThunks.updateReportDiscussionComment
  );

  const panelMentionOnClick = useCallback(
    (panelId?: string) => {
      if (panelId != null) {
        setActiveThread(discussionThreadRef);
        scrollToElement(`.panel-bank__panel-id__${panelId}`);
      }
    },
    [discussionThreadRef, setActiveThread]
  );

  const inlineTextMentionOnClick = useCallback(
    (inlineRefID?: string) => {
      if (inlineRefID != null) {
        setActiveThread(discussionThreadRef);
        scrollToElementByID(INLINE_COMMENT_PREFIX + inlineRefID);
      }
    },
    [discussionThreadRef, setActiveThread]
  );

  const allThreadCommentRefs =
    ViewHooks.usePart(discussionThreadRef).commentRefs;

  const deleteCommentCallback = useCallback(() => {
    if (editing && cancelEdit != null) {
      cancelEdit();
    }
    // If the first comment in the thread is deleted, also delete the thread
    const deleteThread =
      allThreadCommentRefs.indexOf(discussionCommentRef) === 0;

    const refIDs = [];
    if (deleteThread) {
      // all the comments in thread are getting deleted, get refIDs from all comments
      discussionThread.comments.forEach(c => {
        refIDs.push(...getInlineRefIDsFromCommentBody(c.body));
      });
    } else {
      // only one comment getting deleted, get inline text refIDs from this comment
      refIDs.push(...getInlineRefIDsFromCommentBody(comment.body));
    }

    deleteComment({
      discussionCommentServerID: comment.id,
      discussionCommentRef,
      discussionThreadRef,
      deleteThread,
    });

    if (deleteThread && isEqual(activeThreadRef, discussionThreadRef)) {
      setActiveThread(undefined);
    }

    // remove all the inline text marks from slate nodes for deleted comment(s)
    EditorWithComments.removeMarks(editor, refIDs);
  }, [
    activeThreadRef,
    allThreadCommentRefs,
    cancelEdit,
    comment,
    deleteComment,
    discussionThread,
    discussionCommentRef,
    discussionThreadRef,
    editing,
    setActiveThread,
    editor,
  ]);

  const view = useSelector(state =>
    reportViewRef != null ? state.views.views[reportViewRef.id] : null
  );
  const viewOwnerID = view === null ? undefined : view.user.id;

  const commentOwner =
    viewer?.id === comment.poster?.id && viewer?.id !== undefined;
  const admin = useAdminModeActive();
  const reportOwner = viewOwnerID === viewer?.id && viewOwnerID !== undefined;

  const readOnly = !(commentOwner || admin || reportOwner);
  const overflowOptions = readOnly
    ? []
    : compact([
        !editing &&
          editComment != null &&
          commentOwner && {
            text: 'Edit',
            icon: 'wbic-ic-edit',
            onClick: editComment,
          },
        deleteComment != null && {
          text: 'Delete',
          icon: 'wbic-ic-delete',
          onClick: deleteCommentCallback,
        },
      ]);

  const renderComment = replacePanelMentionTokens(
    comment.body,
    panelMentionOnClick,
    inlineTextMentionOnClick
  );

  const updateCommentHandler = useCallback(() => {
    // currently only delete inline text reference from editing comments
    const oldRefIDs = getInlineRefIDsFromCommentBody(comment.body);
    const newRefIDs = getInlineRefIDsFromCommentBody(commentBody);
    const refIDsToRemove = oldRefIDs.filter(id => !newRefIDs.includes(id));

    updateComment(
      commentBody,
      reportServerID ?? undefined,
      undefined,
      cancelEdit
    );

    EditorWithComments.removeMarks(editor, refIDsToRemove);
  }, [commentBody, comment, reportServerID, editor, cancelEdit, updateComment]);

  return (
    <div className="discussion-comment">
      <div className="comment-thread-line" />
      <div className="comment-header">
        <CommentMeta
          poster={comment.poster!}
          createdAt={comment.createdAt}
          updatedAt={comment.updatedAt}
        />
        <LegacyWBIcon
          style={{marginRight: -4}}
          name="launch"
          onClick={(_: React.SyntheticEvent) => {
            setActiveThread(discussionThreadRef);
          }}
        />
        {overflowOptions.length > 0 && (
          <PopupDropdown
            offset={'20px, -16px'}
            position="bottom right"
            trigger={
              <LegacyWBIcon
                name="overflow"
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            }
            options={overflowOptions}
          />
        )}
      </div>
      <div className="comment-content">
        {editing && cancelEdit != null ? (
          <div className="comment-edit">
            <MentionableTextarea
              commentBody={commentBody}
              setCommentBody={setCommentBody}
              autofocus={true}
              placeholder="Write a response..."
              onSubmit={updateCommentHandler}
            />
            <div className="comment-edit-actions">
              <div data-test="save-edit-button" onClick={updateCommentHandler}>
                Save
              </div>
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              <div onClick={cancelEdit}>Cancel</div>
            </div>
          </div>
        ) : (
          <div className="comment-body">{renderComment}</div>
        )}
        {commentActions}
      </div>
    </div>
  );
};

export interface MentionableUser {
  id: string;
  username: string;
  name: string;
  photoUrl: string;
}

// Renders a textarea with @username and &panel mentions enabled
// Note: the available @users are the report team members
const MentionableTextarea: React.FC<{
  commentBody: string;
  autofocus: boolean;
  placeholder?: string;
  setCommentBody(body: string): void;
  onSubmit(): void;
}> = ({commentBody, autofocus, placeholder, setCommentBody, onSubmit}) => {
  const {
    teamMembers,
    reportPanels,
    getPanelData,
    panelCommentsEnabled,
    debouncedDeleteDetails,
  } = useContext(ReportDiscussionContext);

  const keyPressHandlerParams: KeysHandler[] = useMemo(
    () => [
      {keys: ['Meta', 'Enter'], handler: onSubmit}, // Command+Enter to submit comment
    ],
    [onSubmit]
  );
  const {onKeyDown, onKeyUp} = useKeyPressHandler(keyPressHandlerParams);
  const userMentionOptions = teamMembers.map(m => ({
    id: m.username,
    display: m.name || m.username,
  }));

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    // Slate interacts with autofocus badly in edit mode (not sure what specifically)
    // so we defer the focusing with setTimeout until slate is done
    setTimeout(() => {
      if (autofocus && inputRef.current != null) {
        inputRef.current.focus();
      }
    });
  }, [autofocus]);

  const panelMentionOptions = reportPanels.map(p => {
    const {displayText} = getPanelData(p.__id__);
    return {
      id: p.__id__,
      display: displayText,
    };
  });

  const onInputChange = React.useCallback(
    data => {
      const value = data.target.value;
      debouncedDeleteDetails(value);
      setCommentBody(value);
    },
    [debouncedDeleteDetails, setCommentBody]
  );

  return (
    <MentionsInput
      className="comment-mentions"
      inputRef={inputRef}
      value={commentBody}
      allowSuggestionsAboveCursor
      placeholder={placeholder}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}>
      {/* &panel mentions */}
      <Mention
        trigger="&"
        data={panelCommentsEnabled ? panelMentionOptions : []}
        markup={'&[__display__](__id__)'}
        appendSpaceOnAdd={true}
        renderSuggestion={(
          entry,
          search,
          highlightedDisplay
          // index,
          // focused
        ) => {
          const {displayIcon} = getPanelData(entry.id as string);
          return (
            <>
              <LegacyWBIcon name={displayIcon} />
              <div className="mention-info">
                <div className="mention-info-name">{highlightedDisplay}</div>
              </div>
            </>
          );
        }}
      />
      {/* @user mentions */}
      <Mention
        trigger="@"
        data={userMentionOptions.length > 1 ? userMentionOptions : []}
        appendSpaceOnAdd={true}
        renderSuggestion={(
          entry,
          search,
          highlightedDisplay
          // index,
          // focused
        ) => {
          const item = teamMembers.find(u => u.username === entry.id);
          const photoUrl = item?.photoUrl;
          return (
            <>
              <div className="mention-avatar">
                {photoUrl != null && <Image avatar src={photoUrl} />}
              </div>
              <div className="mention-info">
                <div className="mention-info-name">{highlightedDisplay}</div>
                <div className="mention-info-username">{item?.username}</div>
              </div>
            </>
          );
        }}
      />
      {/* |inlineText mentions */}
      <Mention trigger="|" data={[]} markup={'|[__display__](__id__)'} />
    </MentionsInput>
  );
};

// The circular avatars of thread participants
const ReplyAvatars: React.FC<{
  users: DiscussionCommentUser[];
  excludeAuthor?: boolean;
}> = ({users, excludeAuthor = false}) => {
  const MAX_VISIBLE_USERS = 4;
  const uniqueUsers = uniqBy(users, 'username');
  const avatarUsers = excludeAuthor ? uniqueUsers.slice(1) : uniqueUsers;
  const visibleUsers = avatarUsers.slice(0, MAX_VISIBLE_USERS);
  const overflowUsers = avatarUsers.slice(MAX_VISIBLE_USERS);
  return (
    <div className="reply-avatars">
      {visibleUsers.map(u => {
        return (
          <div key={u.id}>
            <Popup
              trigger={<Image src={u.photoUrl} avatar />}
              content={u.name}
              size="mini"
              position="top center"
              inverted
            />
          </div>
        );
      })}
      {overflowUsers.length > 0 && (
        <Popup
          trigger={
            <div className="reply-avatars-overflow">
              +{overflowUsers.length}
            </div>
          }
          content={overflowUsers.map(u => (
            <div>{u.name}</div>
          ))}
          size="mini"
          position="top center"
          inverted
        />
      )}
    </div>
  );
};

const userIsDeleted = (name: string) => name === '[deleted]';
