// Get the text content of a node.
// Prefer the node’s plain-text fields, otherwise serialize its children,
// and if the given value is an array, serialize the nodes in it.
function toString(node: any): string {
  return (
    (node &&
      (node.value ||
        node.alt ||
        node.title ||
        ('children' in node && all(node.children)) ||
        ('length' in node && all(node)))) ||
    ''
  );
}

function all(values: any) {
  const result = [];
  const length = values.length;
  let index = -1;

  while (++index < length) {
    result[index] = toString(values[index]);
  }

  return result.join('');
}

export default toString;

/**
 * some other mdast libraries import this as
 *
 * import {toString} from 'mdast-util-to-string';
 *
 * so this extra export keeps them happy
 */
export {toString};
