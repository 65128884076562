import {ID} from '@wandb/cg';
import _ from 'lodash';

import {HistorySpec} from '../../types/run';
import * as Filter from '../../util/filters';
import * as Run from '../../util/runs';
import {HistoryKeyInfoState} from './reducer';
import * as Types from './types';

function isAllKeyInfoFilters(filter: Filter.Filter<Run.Key>): boolean {
  if (Filter.isIndividual(filter)) {
    return (
      filter.key.section === 'keys_info' &&
      filter.op === '!=' &&
      filter.value == null
    );
  }
  return filter.filters.every(f => isAllKeyInfoFilters(f));
}

export function extractKeyInfoFilters(filters: Filter.Filter<Run.Key>): {
  filters: Filter.Filter<Run.Key> | boolean;
  keyInfoFilters: Filter.Filter<Run.Key> | null;
} {
  const simpleFilters = Filter.simplify(filters);
  if (simpleFilters === false || simpleFilters === true) {
    return {
      filters: simpleFilters,
      keyInfoFilters: null,
    };
  }
  if (Filter.isIndividual(simpleFilters)) {
    if (simpleFilters.key.section === 'keys_info') {
      return {
        filters: true,
        keyInfoFilters: simpleFilters,
      };
    } else {
      return {
        filters: simpleFilters,
        keyInfoFilters: null,
      };
    }
  }
  if (isAllKeyInfoFilters(simpleFilters)) {
    return {
      filters: true,
      keyInfoFilters: simpleFilters,
    };
  }
  if (simpleFilters.op !== 'AND') {
    return {
      filters: simpleFilters,
      keyInfoFilters: null,
    };
  }
  const regFilters: Array<Filter.Filter<Run.Key>> = [];
  const keyInfoFilters: Array<Filter.Filter<Run.Key>> = [];
  for (const subFilt of simpleFilters.filters) {
    if (isAllKeyInfoFilters(subFilt)) {
      keyInfoFilters.push(subFilt);
    } else {
      regFilters.push(subFilt);
    }
  }
  if (keyInfoFilters.length === 0) {
    return {
      filters: simpleFilters,
      keyInfoFilters: null,
    };
  }
  const simpleKeyInfoFilters = Filter.simplify(Filter.And(keyInfoFilters));
  if (simpleKeyInfoFilters === false || simpleKeyInfoFilters === true) {
    // This shouldn't happen since we ensured that keyInfoFilters only contains
    // keyInfo filters, and has more than one.
    return {
      filters: simpleFilters,
      keyInfoFilters: null,
    };
  }
  return {
    filters: Filter.simplify(Filter.And(regFilters)),
    keyInfoFilters: simpleKeyInfoFilters,
  };
}

export function findHistoryKeyInfoForFilters(
  query: Types.CachedQuery['query'],
  filters: Filter.Filter<Run.Key> | boolean,
  historyKeyInfos: HistoryKeyInfoState[]
) {
  const hkiIndex = historyKeyInfos.findIndex(hkiState => {
    return _.isEqual(
      {
        entityName: hkiState.queryVars.entityName,
        projectName: hkiState.queryVars.projectName,
        filters:
          hkiState.queryVars.filters != null
            ? Filter.simplify(hkiState.queryVars.filters)
            : true,
        sort: hkiState.queryVars.sort.keys,
      },
      {
        entityName: query.entityName,
        projectName: query.projectName,
        filters:
          filters === true || filters === false
            ? filters
            : Filter.simplify(filters),
        sort: query.sort.keys,
      }
    );
  });
  return hkiIndex;
}

export function keySetMatch(
  filters: Filter.Filter<Run.Key>,
  keySet: {[key: string]: true}
): boolean {
  if (Filter.isIndividual(filters)) {
    if (
      filters.key.section !== 'keys_info' ||
      filters.op !== '!=' ||
      filters.value != null
    ) {
      throw new Error('Invalid key filters');
    }
    return keySet[filters.key.name] != null;
  }
  if (filters.op === 'AND') {
    return filters.filters.every(f => keySetMatch(f, keySet));
  } else {
    return filters.filters.some(f => keySetMatch(f, keySet));
  }
}

export function filterKeySets(
  filters: Filter.Filter<Run.Key>,
  keySets: HistoryKeyInfoState['historyKeySets']
) {
  return keySets.filter(keySet => keySetMatch(filters, keySet.set));
}

export function queryMergeSignature(
  cachedQuery: Types.CachedQuery,
  historyKeyInfos: HistoryKeyInfoState[]
) {
  const query = cachedQuery.query;

  // Separate filters from keys_info filters
  const {filters, keyInfoFilters} = extractKeyInfoFilters(query.filters);

  const historyKeySetsIndex = findHistoryKeyInfoForFilters(
    query,
    filters,
    historyKeyInfos
  );
  let filteredKeySets: HistoryKeyInfoState['historyKeySets'] | undefined;
  if (keyInfoFilters != null) {
    // Note: historyKeyInfo can be out of date, since we don't try to
    //   block loading while historyKeyInfo is loading. So if the user
    //   interacts with the app, the history key info we get will be
    //   stale for a little while. When we can't find any matches, we
    //   use the original filters in the signature, to force separate
    //   queries.
    // This checks all registered historyKeyInfo queries to see if there's
    //   one that matches our filters.
    if (historyKeySetsIndex !== -1) {
      // If we found one, filter the keySets for that historyKeyInfo query
      // down to only ones that match our keys_info filters. filteredKeySets
      // becomes part of the query signature, any other queries that have
      // the same filteredKeySets (and the other parts of the signature)
      // can be merged with this one.
      const historyKeySets =
        historyKeyInfos[historyKeySetsIndex].historyKeySets;
      filteredKeySets = filterKeySets(keyInfoFilters, historyKeySets || []);
    }
  }

  return {
    lastUpdatedAt: cachedQuery.lastUpdatedAt,

    entityName: query.entityName,
    projectName: query.projectName,
    filters:
      filteredKeySets == null || filteredKeySets.length === 0
        ? query.filters
        : filters,
    historyKeySetsIndex,
    filteredKeySets:
      filteredKeySets != null ? filteredKeySets.map(ks => ks.setID) : undefined,
    grouping: query.grouping,
    sort: query.sort,
    limit: query.limit,

    enableBasic: query.enableBasic,
    enableHistoryKeyInfo: query.enableHistoryKeyInfo,
    enableSystemMetrics: query.enableSystemMetrics,

    fullConfig: query.fullConfig,
    configKeys: query.configKeys,
    fullSummary: query.fullSummary,
    summaryKeys: query.summaryKeys,
    wandbKeys: query.wandbKeys,
  };
}

export function historySpecMergeSignature(
  historySpec: HistorySpec,
  keySets: HistoryKeyInfoState['historyKeySets']
) {
  // find keysets that historySpec.keys is a subset of.
  const filteredKeySets = keySets
    .filter(ks => {
      for (const key of historySpec.keys) {
        if (!ks.set[key]) {
          return false;
        }
      }
      return true;
    })
    .map(s => s.setID);
  return JSON.stringify({
    filteredKeySets,
    forceSeperate: filteredKeySets.length > 0 ? 'allow-merge' : ID(),
    samples: historySpec.samples,
    minStep: historySpec.minStep != null ? historySpec.minStep : null,
    maxStep: historySpec.maxStep != null ? historySpec.maxStep : null,
  });
}
