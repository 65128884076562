import React from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {RenderElementProps, useSelected} from 'slate-react';

import {BlockWrapper} from './drag-drop';
import * as S from './spotify.styles';

type SpotifyType = 'track' | 'album' | 'playlist' | 'show' | 'episode';

export interface Spotify extends Element {
  type: 'spotify';
  spotifyType: SpotifyType;
  spotifyID: string;
}

export const isSpotify = (node: Node): node is Spotify =>
  node.type === 'spotify';

export const SpotifyElement: React.FC<
  RenderElementProps & {
    element: Spotify;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.EmbeddedIframe
        selected={selected}
        src={`https://open.spotify.com/embed/${element.spotifyType}/${element.spotifyID}`}
        title="spotify"
        frameBorder="0"
        allowTransparency
        allow="encrypted-media"
      />
      {children}
    </BlockWrapper>
  );
};

const SPOTIFY_REGEX =
  /^(?<url>https?:\/\/open\.spotify\.com\/(?<spotifyType>[a-z]+)\/(?<spotifyID>\w+))\/?(\?[^#]*)?(#t=.+)?$/;
const SPOTIFY_URI_REGEX = /^spotify:(?<spotifyType>[a-z]+):(?<spotifyID>\w+)$/;

export const withSpotify = <T extends Editor>(editor: T) => {
  const {isVoid, insertText} = editor;

  editor.isVoid = element => {
    return isSpotify(element) ? true : isVoid(element);
  };

  editor.insertText = text => {
    const match = SPOTIFY_REGEX.exec(text);
    if (match?.groups != null) {
      const {spotifyType, spotifyID} = match.groups;
      insertSpotifyElement(spotifyType as SpotifyType, spotifyID);
      return;
    }

    const uriMatch = SPOTIFY_URI_REGEX.exec(text);
    if (uriMatch?.groups != null) {
      const {spotifyType, spotifyID} = uriMatch.groups;
      insertSpotifyElement(spotifyType as SpotifyType, spotifyID);
      return;
    }

    insertText(text);
  };

  return editor;

  function insertSpotifyElement(spotifyType: SpotifyType, spotifyID: string) {
    const node: Node = {
      type: 'spotify',
      spotifyType,
      spotifyID,
      children: [{text: ''}],
    };

    Transforms.splitNodes(editor);
    Transforms.insertNodes(editor, node);
  }
};
