import React, {FC} from 'react';

import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {Panel} from './Panel';
import {PanelContextProvider} from './PanelContextProvider';

interface PanelContainerProps {
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  disableRunLinks?: boolean;
  historyKeyInfo?: RunHistoryKeyInfo;
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigTypes.Ref;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  panelRef: PanelTypes.Ref;
  panelSettings?: PanelSettingsTypes.PanelSettings;
  runSetRefs: RunSetViewTypes.Ref[];
  shouldTurnOnComments?: boolean;
  onContentHeightChange?(h: number): void;
}

export const PanelContainer: FC<PanelContainerProps> = ({
  customRunColorsRef,
  defaultMoveToSectionRef,
  disableRunLinks,
  historyKeyInfo,
  isReadOnly,
  panelBankConfigRef,
  panelBankSectionConfigRef,
  panelRef,
  panelSettings,
  runSetRefs,
  shouldTurnOnComments,
  onContentHeightChange,
}) => {
  return (
    // // Dummy div for testing -- use this if you want to render placeholder boxes without charts
    // <div style={{border: '1px solid'}} draggable="true" className="blank-box">
    //   <DragHandle className="draggable-handle" partRef={panelRef}>
    //     <WBIcon name="handle" />
    //   </DragHandle>
    //   {panelRef.id}
    // </div>

    <PanelContextProvider
      customRunColorsRef={customRunColorsRef}
      historyKeyInfo={historyKeyInfo}
      isReadOnly={isReadOnly}
      panelBankConfigRef={panelBankConfigRef}
      panelBankSectionConfigRef={panelBankSectionConfigRef}
      panelRef={panelRef}
      runSetRefs={runSetRefs}>
      <Panel
        key={panelRef.id}
        disableRunLinks={disableRunLinks}
        panelSettings={panelSettings}
        currentHeight={0}
        onContentHeightChange={onContentHeightChange}
        defaultMoveToSectionRef={defaultMoveToSectionRef}
        shouldTurnOnComments={!!shouldTurnOnComments}
      />
    </PanelContextProvider>
  );
};
