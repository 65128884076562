import {RadioGroup} from '@material-ui/core';
import WandbLoader from '@wandb/common/components/WandbLoader';
import React, {useEffect} from 'react';
import {Dropdown, Icon, Message, Popup} from 'semantic-ui-react';

import {
  CloudProvider,
  StorageBucketInfo,
  StorageBucketInfoInput as StorageBucketInfoInputType,
  useSecureStorageConnectorEnabledQuery,
} from '../../generated/graphql';
import {
  cloudProviderValuesToDisplayNames,
  getAzureName,
  StorageBucketInfoProps,
} from './hooks';
import * as S from './styles';
import {StorageBucketInfoFormValue} from './types';

interface SelectStorageBucketInfoProps {
  bucketInfo: NonNullable<StorageBucketInfoFormValue>;
  setBucketInfo: (
    storageBucketInfo: NonNullable<StorageBucketInfoFormValue>
  ) => void;
  storageBucketInfoOptions: StorageBucketInfo[];
  getInitialBucketInfo: () => StorageBucketInfoInputType;
}
function SelectStorageBucketInfo({
  bucketInfo,
  setBucketInfo,
  storageBucketInfoOptions,
  getInitialBucketInfo,
}: SelectStorageBucketInfoProps) {
  /**
   * Used to keep around the state of an unsaved bucket while the user is editing.
   * If the user switches to a previously saved bucket, we keep this around so that
   * if they switch *back*, they won't have to re-enter it.
   */
  const bucketInfoIsNewBucket = bucketInfo && !('ID' in bucketInfo);
  const lastSeenNewBucketInfo = React.useRef<StorageBucketInfoInputType>(
    bucketInfoIsNewBucket
      ? (bucketInfo as StorageBucketInfoInputType)
      : getInitialBucketInfo()
  );

  useEffect(() => {
    // whenever the bucket info changes AND it's a new bucket,
    // save it here
    if (bucketInfo && (!('ID' in bucketInfo) || !bucketInfo.ID)) {
      lastSeenNewBucketInfo.current = bucketInfo;
    }
  }, [bucketInfo]);

  return (
    <S.BucketStorageSelectDropdown
      data-test="storage-bucket-info-select"
      selection
      options={[
        ...storageBucketInfoOptions.map(option => ({
          text: (
            <S.BucketStorageSelectWrapper data-test="bucket-name-option">
              <S.BucketStorageSelectName>
                {option.name}
              </S.BucketStorageSelectName>
              <S.BucketStorageSelectProvider>
                {cloudProviderValuesToDisplayNames[option.provider]}
              </S.BucketStorageSelectProvider>
            </S.BucketStorageSelectWrapper>
          ),
          content: option.name,
          description: cloudProviderValuesToDisplayNames[option.provider],
          value: option.ID,
        })),
        {content: 'New bucket', text: 'New bucket', value: 'pending'},
      ]}
      value={('ID' in bucketInfo && bucketInfo?.ID) || 'pending'}
      onChange={(_: any, d: any) => {
        if (d.value === 'pending') {
          setBucketInfo(lastSeenNewBucketInfo.current);
          return;
        }

        const option = storageBucketInfoOptions.find(o => o.ID === d.value);

        if (!option) {
          throw new Error(`Could not find option with id ${d.value}`);
        }

        setBucketInfo(option);
      }}
    />
  );
}

interface NotReadyBucketInfo {
  formIsReady: false;
  bucketInfo: null;
}
interface ReadyBucketInfo {
  formIsReady: true;
  bucketInfo: NonNullable<StorageBucketInfoFormValue>;
}
function useLoadedStorageBucketInfo(
  formIsReady: boolean,
  bucketInfo: StorageBucketInfoFormValue
): NotReadyBucketInfo | ReadyBucketInfo {
  if (!formIsReady || !bucketInfo) {
    return {formIsReady: false, bucketInfo: null};
  }

  // this provides type narrowing -- if you condition on the returned
  // formIsReady, TS will know whether bucketInfo exists
  return {bucketInfo, formIsReady: true};
}

export default function StorageBucketInfoInput({
  usingExternalStorage,
  setUsingExternalStorage,
  bucketInfo: bucketInfoProp,
  setBucketInfo,
  getInitialBucketInfo,
  isValidState,
  storageBucketInfoOptions,
  cloudProviderOptions,
  formIsReady: formIsReadyProp,
}: StorageBucketInfoProps) {
  const {formIsReady, bucketInfo} = useLoadedStorageBucketInfo(
    formIsReadyProp,
    bucketInfoProp
  );
  const setBucketInfoWrapped = (newValue: StorageBucketInfoFormValue) => {
    setBucketInfo(newValue);
  };

  const onChangeField = <K extends keyof StorageBucketInfoInputType>(
    key: K,
    keyValue: StorageBucketInfoInputType[K]
  ) => {
    if (!formIsReady) {
      throw new Error(
        'onChangeField should never be called when bucketInfo is null'
      );
    }
    setBucketInfo({
      ...bucketInfo,
      [key]: keyValue,
    });
  };

  // for Azure, the user enters the account name and container name separately,
  // then we join them, separated by a space, as the "name" we send to the
  // backend
  const azureName = getAzureName(bucketInfo);
  const setAzureAccountName = (accountName: string) => {
    accountName = accountName.replaceAll('/', '');
    const name = `${accountName}/${azureName?.containerName ?? ''}`;

    if (!formIsReady) {
      return;
    }

    if (name !== bucketInfo.name) {
      onChangeField('name', name);
    }
  };
  const setAzureContainerName = (containerName: string) => {
    containerName = containerName.replaceAll('/', '');
    const name = `${azureName?.accountName ?? ''}/${containerName}`;

    if (!formIsReady) {
      return;
    }

    if (name !== bucketInfo.name) {
      onChangeField('name', name);
    }
  };

  const sscEnabledResult = useSecureStorageConnectorEnabledQuery();
  const externalDisabled =
    sscEnabledResult.loading ||
    !sscEnabledResult.data?.serverInfo?.secureStorageConnectorEnabled;

  return (
    <div data-test="storage-bucket-info">
      <RadioGroup>
        <S.StorageTypeControlLabel
          value={false}
          label={
            <>
              <S.StorageTypeLabel>Server storage</S.StorageTypeLabel>
              <S.StorageTypeDescription>
                Use the same file storage as the rest of this W&amp;B instance
              </S.StorageTypeDescription>
            </>
          }
          control={
            <S.StorageTypeRadio
              checked={!usingExternalStorage}
              onClick={() => setUsingExternalStorage(false)}
            />
          }
        />
        <Popup
          content={
            <>
              External storage is not available with your current license. To
              enable this feature, please contact{' '}
              <a href="mailto:sales@wandb.com">sales@wandb.com</a>.
            </>
          }
          disabled={!externalDisabled}
          position="left center"
          trigger={
            <S.StorageTypeControlLabel
              data-test="external-storage-label"
              value={true}
              disabled={externalDisabled}
              label={
                <>
                  <S.StorageTypeLabel>External storage</S.StorageTypeLabel>
                  <S.StorageTypeDescription>
                    Specify a bucket in any cloud provider to use as storage
                  </S.StorageTypeDescription>
                </>
              }
              control={
                <S.StorageTypeRadio
                  data-test="external-storage-radio"
                  checked={usingExternalStorage}
                  onClick={() => setUsingExternalStorage(true)}
                />
              }
            />
          }
        />
      </RadioGroup>
      {!!usingExternalStorage &&
        (formIsReady ? (
          <S.BucketStorageForm>
            <SelectStorageBucketInfo
              bucketInfo={bucketInfo}
              setBucketInfo={setBucketInfoWrapped}
              getInitialBucketInfo={getInitialBucketInfo}
              storageBucketInfoOptions={storageBucketInfoOptions}
            />

            {bucketInfo && (
              <>
                <S.InputLabel>Cloud provider</S.InputLabel>
                <Dropdown
                  data-test="storage-bucket-info-cloud-provider"
                  selection
                  value={
                    bucketInfo.provider || cloudProviderOptions[0]?.value || ''
                  }
                  onChange={(e, d) =>
                    onChangeField('provider', d.value as CloudProvider)
                  }
                  disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                  options={cloudProviderOptions || []}
                />
                {/* azureName will be truthy when Azure is the selected cloud provider */}
                {azureName ? (
                  <>
                    <S.InputLabel>Account name</S.InputLabel>
                    <S.InputText
                      data-test="storage-bucket-info-azure-account-name"
                      value={azureName.accountName}
                      onChange={e => setAzureAccountName(e.target.value)}
                      disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                      placeholder="Name of Azure storage account"
                    />

                    <S.InputLabel>Container name</S.InputLabel>
                    <S.InputText
                      data-test="storage-bucket-info-azure-container-name"
                      value={azureName.containerName}
                      onChange={e => setAzureContainerName(e.target.value)}
                      disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                      placeholder="Name of Azure storage container"
                    />
                  </>
                ) : (
                  <>
                    <S.InputLabel>Name</S.InputLabel>
                    <S.InputText
                      data-test="storage-bucket-info-name"
                      value={bucketInfo.name}
                      onChange={e => onChangeField('name', e.target.value)}
                      disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                      placeholder="Name of storage bucket"
                    />
                  </>
                )}
                <S.InputLabel>KMS key ID (optional)</S.InputLabel>
                <S.InputText
                  data-test="storage-bucket-info-kms-key-id"
                  value={bucketInfo.kmsKeyID || ''}
                  onChange={e => onChangeField('kmsKeyID', e.target.value)}
                  disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                  placeholder="ID of encryption key"
                />
              </>
            )}

            {isValidState.state === 'loading' && (
              <S.BucketStorageMessage>
                <Icon name="circle notched" loading />
                Checking if we can connect to your storage
              </S.BucketStorageMessage>
            )}
            {isValidState.state === 'invalid' && (
              <>
                {isValidState.errors && (
                  <S.BucketStorageMessage error>
                    {isValidState.errors.length === 1 ? (
                      isValidState.errors[0] + '.'
                    ) : (
                      <Message.List>
                        {isValidState.errors.map(error => (
                          <Message.Item key={error}>{error}.</Message.Item>
                        ))}
                      </Message.List>
                    )}
                  </S.BucketStorageMessage>
                )}
              </>
            )}

            {isValidState.state === 'valid' && (
              <S.BucketStorageMessage success>
                <Icon name="check" /> Your storage is successfully connected.
              </S.BucketStorageMessage>
            )}
          </S.BucketStorageForm>
        ) : (
          <WandbLoader />
        ))}
    </div>
  );
}
