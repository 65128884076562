import * as TableState from '../PanelTable/tableState';
import * as TypeHelpers from '@wandb/cg';
import * as GraphTypes from '@wandb/cg';
import * as Types from '@wandb/cg';
import {immerable, produce} from 'immer';
import * as Graph from '@wandb/cg';
import {voidNode} from '@wandb/cg';
import * as _ from 'lodash';
import {
  DEFAULT_POINT_SIZE,
  DIM_NAME_MAP,
  MARK_OPTIONS,
  migrate,
  PLOT_DIMS_UI,
  PlotConfig,
  SeriesConfig,
  LINE_SHAPES,
  POINT_SHAPES,
} from './versions';
import * as Op from '@wandb/cg';
import {allObjPaths} from '@wandb/cg';
import {WeaveInterface} from '@wandb/cg';

export type DimType =
  | 'optionSelect'
  | 'weaveExpression'
  | 'group'
  | 'dropdownWithExpression';
export type DimName = keyof typeof DIM_NAME_MAP;
export type DropdownWithExpressionMode = 'dropdown' | 'expression';

interface DimState {
  value: any;
  compareValue: string;
}

export function isGroup(dim: DimensionLike): dim is MultiFieldDimension {
  return dim.type === 'group';
}

export function isDropdown(dim: DimensionLike): dim is DropDownDimension {
  return dim.type === 'optionSelect';
}

export function isTopLevelDimension(
  dimName: string
): dimName is typeof PLOT_DIMS_UI[number] {
  return PLOT_DIMS_UI.includes(dimName as any);
}

export function isWeaveExpression(
  dim: DimensionLike
): dim is WeaveExpressionDimension {
  return dim.type === 'weaveExpression';
}

export function isDropdownWithExpression(
  dim: DimensionLike
): dim is DropdownWithExpressionDimension {
  return dim.type === 'dropdownWithExpression';
}

/* A DimensionLike object is as a container for the state of a single config panel UI component
 or group of related components for a single series. DimensionLike objects can be compared
 to other DimensionLike objects, and can perform immutable state updates on SeriesConfig
 objects.
 */
export abstract class DimensionLike {
  readonly type: DimType;
  readonly name: DimName;
  readonly series: SeriesConfig;

  // lets us use produce() to create new instances of these classes via mutation
  public [immerable] = true;

  protected readonly weave: WeaveInterface;

  protected constructor(
    type: DimType,
    name: DimName,
    series: SeriesConfig,
    weave: WeaveInterface
  ) {
    this.type = type;
    this.name = name;
    this.series = series;
    this.weave = weave;
  }

  withSeries(series: SeriesConfig): DimensionLike {
    return produce(this, draft => {
      draft.series = series;
    });
  }

  // abstract method to impute a series with the default value for this dimension, returning a new series
  abstract imputeThisSeriesWithDefaultState(): SeriesConfig;

  // given another series, return
  abstract imputeOtherSeriesWithThisState(s: SeriesConfig): SeriesConfig;

  isVoid(): boolean {
    return _.isEqual(
      this.state().compareValue,
      this.defaultState().compareValue
    );
  }

  // return true if two dimensions are the same type, have the same state, have the same name, and
  // have equal children. optionally return true if at least one of the dimensions is in the void state
  equals(other: DimensionLike, tolerateVoid: boolean = false): boolean {
    return (
      this.type === other.type &&
      this.name === other.name &&
      (_.isEqual(this.state().compareValue, other.state().compareValue) ||
        (tolerateVoid && (this.isVoid() || other.isVoid())))
    );
  }

  // default state of the dimension, e.g., mark setting = undefined for mark, null expression for weave
  abstract defaultState(): DimState;

  // current state of the dimension e.g., mark setting for mark, or table Select function for expression dim
  abstract state(): DimState;
}

export type ExpressionDimName = keyof PlotConfig['series'][number]['dims'];
export type DropdownDimName =
  | Exclude<DimName, ExpressionDimName>
  | 'pointShape'
  | 'label';

export const EXPRESSION_DIM_NAMES: ExpressionDimName[] = [
  'x' as const,
  'y' as const,
  'color' as const,
  'label' as const,
  'tooltip' as const,
  'pointSize' as const,
  'pointShape' as const,
];

type ExpressionState = {
  value: GraphTypes.NodeOrVoidNode;
  compareValue: string;
};

abstract class MultiFieldDimension extends DimensionLike {
  private static dimStateFromState(
    dimensions: DimensionLike[],
    state: DimState[]
  ) {
    const value = state.map(dimState => dimState.value);
    const compareValue = JSON.stringify(
      dimensions.reduce((acc, dim, i) => {
        acc[dim.name] = state[i].compareValue;
        return acc;
      }, {} as {[K in DimName]?: string})
    );
    return {value, compareValue};
  }

  public readonly dimensions: {[K in DimName]?: DimensionLike};

  protected constructor(
    name: DimName,
    series: SeriesConfig,
    weave: WeaveInterface,
    dimensions: {[K in DimName]?: DimensionLike}
  ) {
    super('group', name, series, weave);
    this.dimensions = dimensions;
  }

  isVoid(): boolean {
    return this.activeDimensions().every(dim => dim.isVoid());
  }

  // return the active dimensions, i.e. the dimensions that are not hidden
  abstract activeDimensions(): DimensionLike[];

  defaultState(): DimState {
    return MultiFieldDimension.dimStateFromState(
      this.activeDimensions(),
      this.activeDimensions().map(dim => dim.defaultState())
    );
  }

  state(): DimState {
    return MultiFieldDimension.dimStateFromState(
      this.activeDimensions(),
      this.activeDimensions().map(dim => dim.state())
    );
  }

  primaryDimension(): DimensionLike {
    return this.dimensions[this.name] as DimensionLike;
  }

  imputeThisSeriesWithDefaultState(): SeriesConfig {
    let series = this.series;
    Object.values(this.dimensions).forEach(dim => {
      if (dim) {
        const newDim = dim.withSeries(series);
        series = newDim.imputeThisSeriesWithDefaultState();
      }
    });
    return series;
  }

  imputeOtherSeriesWithThisState(s: SeriesConfig): SeriesConfig {
    // TODO(DG): should we use dimensions() here or is activeDimensions() OK?
    this.activeDimensions().forEach(dim => {
      s = dim.imputeOtherSeriesWithThisState(s);
    });
    return s;
  }
}

class YDimensionWithConditionalY2 extends MultiFieldDimension {
  public dimensions: {y: DimensionLike; y2: DimensionLike};
  private markDimension: DropDownDimension;

  constructor(
    series: SeriesConfig,
    weave: WeaveInterface,
    markDimension: DropDownDimension // used to determine if y2 is active
  ) {
    const yDimension = new WeaveExpressionDimension('y', series, weave);

    const y2Dimension = new WeaveExpressionDimension('y2', series, weave);

    const dimensions = {
      y: yDimension,
      y2: y2Dimension,
    };

    super('y', series, weave, dimensions);
    this.markDimension = markDimension;
    this.dimensions = dimensions;
  }

  activeDimensions(): DimensionLike[] {
    const dims = [this.dimensions.y]; // y is always active
    if (this.markDimension.state().value === 'area') {
      dims.push(this.dimensions.y2);
    }
    return dims;
  }
}

type DropdownOption = {
  key: string;
  value: any;
  text: string;
  representableAsExpression?: boolean;
};

class DropDownDimension extends DimensionLike {
  public readonly options: DropdownOption[];
  public readonly name: DropdownDimName;
  public readonly placeholder?: string;
  protected readonly defaultOption: DropdownOption;

  constructor(
    name: DropdownDimName,
    series: SeriesConfig,
    weave: WeaveInterface,
    options: DropdownOption[],
    defaultOption: DropdownOption
  ) {
    super('optionSelect', name, series, weave);
    this.options = options;
    this.name = name;
    this.defaultOption = defaultOption;
  }

  imputeThisSeriesWithDefaultState(): SeriesConfig {
    return produce(this.series, draft => {
      // @ts-ignore
      draft.constants[this.name] = this.defaultOption.value;
    });
  }

  imputeOtherSeriesWithThisState(s: SeriesConfig): SeriesConfig {
    return produce(s, draft => {
      // @ts-ignore
      draft.constants[this.name] = this.state().value;
    });
  }

  defaultState(): DimState {
    return {
      value: this.defaultOption.value,
      compareValue: this.defaultOption.text,
    };
  }

  state(): DimState {
    const value = this.series.constants[this.name];
    const option = this.options.find(o => o.value === value);
    return {value, compareValue: option ? option.text : ''};
  }
}

const lineStyleOptions = LINE_SHAPES.map(o => ({
  key: o,
  value: o,
  text: o === 'series' ? 'Encode from series' : o,
  representableAsExpression: o !== 'series',
}));

const markOptions = [
  {key: 'auto' as const, value: null, text: 'auto' as const},
  ...MARK_OPTIONS.map(o => ({
    key: o,
    value: o,
    text: o,
  })),
];

const pointShapeOptions = POINT_SHAPES.map(o => ({
  key: o,
  value: o,
  text: o === 'series' ? 'Encode from series' : o,
  representableAsExpression: o !== 'series',
}));

export const dimensionTypeOptions = [
  'expression' as const,
  'constant' as const,
].map(o => ({
  key: o,
  value: o,
  text: o,
}));

export const labelOptions = ['series' as const].map(o => ({
  key: o,
  value: o,
  text: o === 'series' ? 'Encode from series' : o,
}));

type DimWithDropdownAndExpressionName = 'pointShape' | 'label';
export class DropdownWithExpressionDimension extends DimensionLike {
  public readonly name: DimWithDropdownAndExpressionName;
  readonly defaultMode: DropdownWithExpressionMode;

  // state managers for expression and dropdown state
  readonly expressionDim: WeaveExpressionDimension;
  readonly dropdownDim: DropDownDimension;

  constructor(
    name: DimWithDropdownAndExpressionName,
    series: SeriesConfig,
    expressionDim: WeaveExpressionDimension,
    dropdownDim: DropDownDimension,
    weave: WeaveInterface,
    defaultMode: DropdownWithExpressionMode = 'dropdown'
  ) {
    super('dropdownWithExpression', name, series, weave);
    this.name = name;
    this.defaultMode = defaultMode;
    this.expressionDim = expressionDim;
    this.dropdownDim = dropdownDim;
  }

  mode(): DropdownWithExpressionMode {
    return this.series.uiState[this.name];
  }

  state(): DimState {
    const mode = this.mode();
    const childState: DimState =
      mode === 'dropdown'
        ? this.dropdownDim.state()
        : this.expressionDim.state();
    const compareValue: string = JSON.stringify({
      mode,
      compareValue: childState.compareValue,
    });
    const value: any = {mode, value: childState.value};
    return {value, compareValue};
  }

  defaultState(): DimState {
    const childState =
      this.defaultMode === 'dropdown'
        ? this.dropdownDim.defaultState()
        : this.expressionDim.defaultState();
    const compareValue: string = JSON.stringify({
      mode: this.defaultMode,
      compareValue: childState.compareValue,
    });
    const value: any = {mode: this.defaultMode, value: childState.value};
    return {value, compareValue};
  }

  imputeThisSeriesWithDefaultState(): SeriesConfig {
    const {
      value: {mode: defaultMode},
    } = this.defaultState();
    const dimWithDefaultMode = produce(this, draft => {
      draft.series.uiState[this.name] = defaultMode;
    });
    if (defaultMode === 'dropdown') {
      return dimWithDefaultMode.dropdownDim.imputeThisSeriesWithDefaultState();
    } else {
      return dimWithDefaultMode.expressionDim.imputeThisSeriesWithDefaultState();
    }
  }

  imputeOtherSeriesWithThisState(s: SeriesConfig): SeriesConfig {
    const mode = this.mode();
    const newSeries =
      mode === 'dropdown'
        ? this.dropdownDim.imputeOtherSeriesWithThisState(s)
        : this.expressionDim.imputeOtherSeriesWithThisState(s);
    return produce(newSeries, draft => {
      draft.uiState[this.name] = mode;
    });
  }
}

const topLevelMarkDimensionConstructor = (
  series: SeriesConfig,
  weave: WeaveInterface
) => new DropDownDimension('mark', series, weave, markOptions, markOptions[0]);

class MarkDimensionGroup extends MultiFieldDimension {
  constructor(series: SeriesConfig, weave: WeaveInterface) {
    const pointShapeExpressionDim = new WeaveExpressionDimension(
      'pointShape',
      series,
      weave
    );
    const pointShapeDropdownDim = new DropDownDimension(
      'pointShape',
      series,
      weave,
      pointShapeOptions,
      pointShapeOptions[0]
    );

    const dimensions = {
      mark: topLevelMarkDimensionConstructor(series, weave),
      pointSize: new WeaveExpressionDimension('pointSize', series, weave),
      pointShape: new DropdownWithExpressionDimension(
        'pointShape',
        series,
        pointShapeExpressionDim,
        pointShapeDropdownDim,
        weave,
        'expression'
      ),
      lineStyle: new DropDownDimension(
        'lineStyle',
        series,
        weave,
        lineStyleOptions,
        lineStyleOptions[0]
      ),
    };
    super('mark', series, weave, dimensions);
  }

  activeDimensions(): DimensionLike[] {
    const dimensions = this.dimensions;
    if (
      dimensions.mark &&
      dimensions.pointShape &&
      dimensions.pointSize &&
      dimensions.lineStyle
    ) {
      if (dimensions.mark.state().value === 'point') {
        return [dimensions.mark, dimensions.pointShape, dimensions.pointSize];
      } else if (dimensions.mark.state().value === 'line') {
        return [dimensions.mark, dimensions.lineStyle];
      }
      return [dimensions.mark];
    }
    return [];
  }
}

class WeaveExpressionDimension extends DimensionLike {
  private static updateSeriesWithState(
    series: SeriesConfig,
    state: DimState,
    dimName: ExpressionDimName
  ): SeriesConfig {
    return produce(series, draft => {
      const colId = draft.dims[dimName];
      const defaultState = state.value;
      draft.table = TableState.updateColumnSelect(
        draft.table,
        colId,
        defaultState
      );
    });
  }

  public readonly name: ExpressionDimName;
  constructor(
    name: ExpressionDimName,
    series: SeriesConfig,
    weave: WeaveInterface
  ) {
    super('weaveExpression', name, series, weave);
    this.name = name;
  }

  imputeThisSeriesWithDefaultState(): SeriesConfig {
    return WeaveExpressionDimension.updateSeriesWithState(
      this.series,
      this.defaultState(),
      this.name
    );
  }

  imputeOtherSeriesWithThisState(s: SeriesConfig): SeriesConfig {
    return WeaveExpressionDimension.updateSeriesWithState(
      s,
      this.state(),
      this.name
    );
  }

  defaultState(): ExpressionState {
    return {compareValue: '', value: voidNode()};
  }

  state(): ExpressionState {
    const colId = this.series.dims[this.name];
    const selectFunction = this.series.table.columnSelectFunctions[colId];
    return {
      compareValue: this.weave.expToString(selectFunction),
      value: selectFunction,
    };
  }
}

export const dimConstructors: Record<
  typeof PLOT_DIMS_UI[number],
  (series: SeriesConfig, weave: WeaveInterface) => DimensionLike
> = {
  x: (series: SeriesConfig, weave: WeaveInterface) =>
    new WeaveExpressionDimension('x', series, weave),
  y: (series: SeriesConfig, weave: WeaveInterface) => {
    const markDimension = topLevelMarkDimensionConstructor(series, weave);
    return new YDimensionWithConditionalY2(series, weave, markDimension);
  },
  tooltip: (series: SeriesConfig, weave: WeaveInterface) =>
    new WeaveExpressionDimension('tooltip', series, weave),
  label: (series: SeriesConfig, weave: WeaveInterface) => {
    const expressionDimension = new WeaveExpressionDimension(
      'label',
      series,
      weave
    );
    const dropdownDimension = new DropDownDimension(
      'label',
      series,
      weave,
      labelOptions,
      labelOptions[0]
    );
    return new DropdownWithExpressionDimension(
      'label',
      series,
      expressionDimension,
      dropdownDimension,
      weave,
      'dropdown'
    );
  },
  mark: (series: SeriesConfig, weave: WeaveInterface) =>
    new MarkDimensionGroup(series, weave),
};

export function addSeriesFromSeries(
  config: PlotConfig,
  series: SeriesConfig,
  blankDimName: typeof PLOT_DIMS_UI[number],
  weave: WeaveInterface
) {
  const dimConstructor = dimConstructors[blankDimName];
  const dim = dimConstructor(series, weave);
  const newSeries: SeriesConfig = dim.imputeThisSeriesWithDefaultState();
  return allowProgrammaticEEUpdate(
    produce(config, draft => {
      draft.series.push(newSeries);
      draft.configOptionsExpanded[blankDimName] = true;
    })
  );
}

export function removeManySeries(config: PlotConfig, series: SeriesConfig[]) {
  const newConfig = produce(config, draft => {
    let newSeries = [...draft.series];
    series.forEach(s => {
      let index = -1;
      for (let i = 0; i < newSeries.length; i++) {
        const ns = newSeries[i];
        if (_.isEqual(ns, s)) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        return;
      }

      newSeries = newSeries.slice(0, index).concat(newSeries.slice(index + 1));
    });
    draft.series = newSeries;
    draft.configOptionsExpanded =
      newSeries.length === 1
        ? _.mapValues(draft.configOptionsExpanded, () => false)
        : draft.configOptionsExpanded;
  });

  return allowProgrammaticEEUpdate(newConfig);
}

export function removeSeries(config: PlotConfig, series: SeriesConfig) {
  return allowProgrammaticEEUpdate(removeManySeries(config, [series]));
}

// Return true if all series have the same selectFunction for a given dimension,
// false otherwise. Since pointSize and pointShape are subdimensions of mark,
// they are checked for equality in when dimName = mark and mark=point.
export function isDimShared(
  seriesList: SeriesConfig[],
  dimName: typeof PLOT_DIMS_UI[number],
  weave: WeaveInterface
): boolean {
  return (
    seriesList.length > 1 &&
    seriesList.every(series => {
      const firstSeries = seriesList[0];
      const constructor = dimConstructors[dimName];
      const firstDim = constructor(firstSeries, weave);
      const thisDim = constructor(series, weave);
      return firstDim.equals(thisDim);
    })
  );
}

export function removeRedundantSeries(
  config: PlotConfig,
  weave: WeaveInterface
): PlotConfig {
  const seriesToRemove: SeriesConfig[] = [];

  const isInGroup = (group: SeriesConfig, s: SeriesConfig): boolean => {
    return PLOT_DIMS_UI.every(val => {
      const groupDim = dimConstructors[val](group, weave);
      const sDim = dimConstructors[val](s, weave);
      return groupDim.equals(sDim, true);
    });
  };

  // populate seriesToRemove
  config.series.reduce((acc, s) => {
    // when should a series be removed?
    // 0. when it's degenerate with other series up to void nodes
    // 1. when all of its non shared dims are voidNodes
    // 2. when all of its dims are shared

    let inGroup = false;
    for (const group of acc) {
      if (isInGroup(group, s)) {
        // TODO(DG): maybe merge s into group here.
        seriesToRemove.push(s);
        inGroup = true;
        break;
      }
    }

    if (!inGroup) {
      acc.push(s);
    }

    return acc;
  }, [] as SeriesConfig[]);

  return allowProgrammaticEEUpdate(removeManySeries(config, seriesToRemove));
}

export function makeDimensionShared(
  config: PlotConfig,
  series: SeriesConfig,
  dimName: typeof PLOT_DIMS_UI[number],
  weave: WeaveInterface
): PlotConfig {
  return allowProgrammaticEEUpdate(
    config.series.length > 1
      ? produce(config, draft => {
          const replacementDim = dimConstructors[dimName](series, weave);
          draft.series = draft.series.map(s => {
            return replacementDim.imputeOtherSeriesWithThisState(s);
          });
          draft.configOptionsExpanded[dimName] = false;
        })
      : config
  );
}

export function collapseRedundantDimensions(
  config: PlotConfig,
  weave: WeaveInterface
): PlotConfig {
  return allowProgrammaticEEUpdate(
    config.series.length > 1
      ? produce(config, draft => {
          PLOT_DIMS_UI.forEach(dim => {
            const stateMap: {[compareValue: string]: DimensionLike} = {};
            const keyOrder: string[] = [];

            const defaultStateStr: string = dimConstructors[dim](
              draft.series[0],
              weave
            ).defaultState().compareValue;

            draft.series.forEach(s => {
              const constructor = dimConstructors[dim];
              const dimension = constructor(s, weave);
              const state = dimension.state();
              if (!(state.compareValue in stateMap)) {
                // we found something new
                stateMap[state.compareValue] = dimension;
                keyOrder.push(state.compareValue);
              }
            });

            let replacementDim: DimensionLike;
            if (Object.keys(stateMap).length === 1) {
              replacementDim = stateMap[keyOrder[0]];
            } else if (
              Object.keys(stateMap).length === 2 &&
              defaultStateStr in stateMap
            ) {
              const key = keyOrder.find(k => k !== defaultStateStr) as string;
              replacementDim = stateMap[key];
            } else {
              // we have more than one different state (excluding void), so we can't collapse
              return;
            }

            // replace the dimension with the new one
            draft.series = draft.series.map(s =>
              replacementDim.imputeOtherSeriesWithThisState(s)
            );

            // merge the dims in the UI
            draft.configOptionsExpanded[dim] = false;
          });
        })
      : config
  );
}

// Transform a plot config to its equivalent condensed representation,
// eliminating redundant series and collapsing redundant dimensions
export function condensePlotConfig(
  config: PlotConfig,
  weave: WeaveInterface
): PlotConfig {
  return allowProgrammaticEEUpdate(
    collapseRedundantDimensions(removeRedundantSeries(config, weave), weave)
  );
}

export function markType(xDimType: Types.Type, yDimType: Types.Type) {
  if (
    TypeHelpers.isAssignableTo(xDimType, TypeHelpers.maybe('number')) &&
    TypeHelpers.isAssignableTo(yDimType, TypeHelpers.maybe('number'))
  ) {
    return 'point';
  } else if (
    TypeHelpers.isAssignableTo(
      xDimType,
      TypeHelpers.union(['string', 'date', TypeHelpers.numberBin])
    ) &&
    TypeHelpers.isAssignableTo(yDimType, TypeHelpers.maybe('number'))
  ) {
    return 'bar';
  } else if (
    TypeHelpers.isAssignableTo(xDimType, TypeHelpers.maybe('number')) &&
    TypeHelpers.isAssignableTo(yDimType, TypeHelpers.union(['string', 'date']))
  ) {
    return 'bar';
  } else if (
    TypeHelpers.isAssignableTo(
      xDimType,
      TypeHelpers.list(TypeHelpers.maybe('number'))
    ) &&
    TypeHelpers.isAssignableTo(
      yDimType,
      TypeHelpers.union(['string', 'number'])
    )
  ) {
    return 'boxplot';
  } else if (
    TypeHelpers.isAssignableTo(
      yDimType,
      TypeHelpers.list(TypeHelpers.maybe('number'))
    ) &&
    TypeHelpers.isAssignableTo(
      xDimType,
      TypeHelpers.union(['string', 'number'])
    )
  ) {
    return 'boxplot';
  } else if (
    TypeHelpers.isAssignableTo(xDimType, TypeHelpers.list('number')) &&
    TypeHelpers.isAssignableTo(yDimType, TypeHelpers.list('number'))
  ) {
    return 'line';
  }
  return 'point';
}

export function axisType(dimType: Types.Type, isDashboard: boolean) {
  if (
    TypeHelpers.isAssignableTo(
      dimType,
      TypeHelpers.oneOrMany(TypeHelpers.maybe('number'))
    ) ||
    TypeHelpers.isAssignableTo(dimType, TypeHelpers.numberBin)
  ) {
    return {axisType: 'quantitative', timeUnit: undefined};
  } else if (
    TypeHelpers.isAssignableTo(
      dimType,
      TypeHelpers.oneOrMany(TypeHelpers.maybe('string'))
    ) ||
    TypeHelpers.isAssignableTo(
      dimType,
      TypeHelpers.oneOrMany(TypeHelpers.maybe('boolean'))
    )
  ) {
    return {axisType: 'nominal', timeUnit: undefined};
  } else if (
    TypeHelpers.isAssignableTo(
      dimType,
      TypeHelpers.oneOrMany(TypeHelpers.maybe('date'))
    )
  ) {
    return {
      axisType: 'temporal',
      // TODO: hard-coded to month, we should encode this in the
      // type system and make it automatic (we know we used opDateRoundMonth)
      timeUnit: isDashboard ? 'yearweek' : 'yearmonth',
    };
  } else if (
    TypeHelpers.isAssignableTo(
      dimType,
      TypeHelpers.oneOrMany(TypeHelpers.maybe({type: 'timestamp', unit: 'ms'}))
    )
  ) {
    return {axisType: 'temporal', timeUnit: 'undefined'};
  }
  return undefined;
}

// TODO, this produces ugly keys
export const fixKeyForVega = (key: string) => {
  // Scrub these characters: . [ ] \
  return key.replace(/[.[\]\\]/g, '');
};

export function dimNames(
  tableState: TableState.TableState,
  dims: SeriesConfig['dims'],
  weave: WeaveInterface
) {
  // filter out weave1 _type key
  dims = _.omitBy(dims, (v, k) => k.startsWith('_')) as SeriesConfig['dims'];
  return _.mapValues(dims, tableColId =>
    fixKeyForVega(
      TableState.getTableColumnName(
        tableState.columnNames,
        tableState.columnSelectFunctions,
        tableColId,
        weave.client.opStore
      )
    )
  );
}

export function isValidConfig(config: PlotConfig): {
  valid: boolean;
  reason: string;
} {
  if (config.series) {
    // check that x dimensions and y dimensions have the same interpretation across series
    // (e.g., temporal, quantitative, ordinal, nominal, etc.)
    for (const matchDim of ['x' as const, 'y' as const]) {
      const flattenedDims = config.series.reduce((acc, series) => {
        acc.push({table: series.table, columnId: series.dims[matchDim]});
        return acc;
      }, [] as Array<{table: TableState.TableState; columnId: TableState.ColumnId}>);

      const firstDim = flattenedDims[0];
      const dimTypesMatch = flattenedDims.every(dim => {
        const currentColSelectFunc =
          dim.table.columnSelectFunctions[dim.columnId];
        const targetColSelectFunc =
          firstDim.table.columnSelectFunctions[firstDim.columnId];
        return TypeHelpers.isAssignableTo(
          currentColSelectFunc.type,
          targetColSelectFunc.type
        );
      });

      if (!dimTypesMatch) {
        return {
          valid: false,
          reason: `Series ${matchDim} dimension types do not match`,
        };
      }
    }
  }
  return {valid: true, reason: ''};
}

// HACK: By default, expressions emitted by WeaveExpression are tagged
// to prevent undesirable feedback loops wherein the expression is updated
// while the user is actively typing.  In certain workflows, the UI must
// programmatically copy expressions from one instance of WeaveExpression to
// another, but these will be ignored unless the tag is removed.  This
// function performs that removal, ensuring that any instances of
// WeaveExpression will accept the new config.
export function allowProgrammaticEEUpdate(config: PlotConfig): PlotConfig {
  return produce(config, draft => {
    draft.series.forEach(series => {
      EXPRESSION_DIM_NAMES.forEach(dimName => {
        const colId = series.dims[dimName];
        (series.table.columnSelectFunctions[colId] as any).__userInput =
          undefined;
      });
    });
  });
}

export function defaultPlot(
  inputNode: GraphTypes.Node,
  frame: GraphTypes.Frame
): PlotConfig {
  const exampleRow = TableState.getExampleRow(inputNode);

  let tableState = TableState.emptyTable();
  tableState = TableState.appendEmptyColumn(tableState);
  const xColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const yColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const colorColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const labelColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const tooltipColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const pointSizeColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const pointShapeColId = tableState.order[tableState.order.length - 1];

  const axisSettings: PlotConfig['axisSettings'] = {
    x: {},
    y: {},
    color: {},
  };
  const legendSettings: PlotConfig['legendSettings'] = {
    color: {},
  };

  let labelAssigned = false;
  if (
    frame.runColors != null &&
    frame.runColors.nodeType === 'const' &&
    Object.keys(frame.runColors.val).length > 1
  ) {
    if (
      TypeHelpers.isAssignableTo(
        exampleRow.type,
        TypeHelpers.withNamedTag('run', 'run', 'any')
      )
    ) {
      tableState = TableState.updateColumnSelect(
        tableState,
        labelColId,
        Op.opGetRunTag({obj: Graph.varNode(exampleRow.type, 'row')})
      );
      labelAssigned = true;
    } else if (TypeHelpers.isAssignableTo(exampleRow.type, 'run')) {
      tableState = TableState.updateColumnSelect(
        tableState,
        labelColId,
        Graph.varNode(exampleRow.type, 'row')
      );
      labelAssigned = true;
    }
  }

  // If we have a list of dictionaries, try to make a good guess at filling in the dimensions
  if (TypeHelpers.isAssignableTo(exampleRow.type, TypeHelpers.typedDict({}))) {
    const propertyTypes = allObjPaths(
      TypeHelpers.nullableTaggableValue(exampleRow.type)
    );
    let xCandidate: string | null = null;
    let yCandidate: string | null = null;
    let labelCandidate: string | null = null;
    let mediaCandidate: string | null = null;
    // Assign the first two numeric columns to x an y if available
    for (const propertyKey of propertyTypes) {
      if (
        TypeHelpers.isAssignableTo(propertyKey.type, {
          type: 'timestamp',
          unit: 'ms',
        })
      ) {
        // always set xaxis to date if it exists
        xCandidate = propertyKey.path.join('.');
      }
      if (
        TypeHelpers.isAssignableTo(
          propertyKey.type,
          TypeHelpers.maybe('number')
        )
      ) {
        if (xCandidate == null) {
          xCandidate = propertyKey.path.join('.');
        } else if (yCandidate == null) {
          yCandidate = propertyKey.path.join('.');
        }
      } else if (
        TypeHelpers.isAssignableTo(
          propertyKey.type,
          TypeHelpers.maybe('string')
        )
      ) {
        // don't default to the run name field
        if (
          labelCandidate == null &&
          propertyKey.path.indexOf('runname') === -1
        ) {
          labelCandidate = propertyKey.path.join('.');
        }
      } else if (
        mediaCandidate == null &&
        TypeHelpers.isAssignableTo(
          propertyKey.type,
          TypeHelpers.maybe(
            TypeHelpers.union([
              {type: 'image-file'},
              {type: 'video-file'},
              {type: 'audio-file'},
              {type: 'html-file'},
              {type: 'bokeh-file'},
              {type: 'object3D-file'},
              {type: 'molecule-file'},
            ])
          )
        )
      ) {
        mediaCandidate = propertyKey.path.join('.');
      }
    }

    if (xCandidate != null && yCandidate != null) {
      tableState = TableState.updateColumnSelect(
        tableState,
        xColId,
        Op.opPick({
          obj: Graph.varNode(exampleRow.type, 'row'),
          key: Op.constString(xCandidate),
        })
      );

      tableState = TableState.updateColumnSelect(
        tableState,
        yColId,
        Op.opPick({
          obj: Graph.varNode(exampleRow.type, 'row'),
          key: Op.constString(yCandidate),
        })
      );

      // assign a default pointsize
      tableState = TableState.updateColumnSelect(
        tableState,
        pointSizeColId,
        Op.constNumber(DEFAULT_POINT_SIZE)
      );

      // assign a default shape of circle
      tableState = TableState.updateColumnSelect(
        tableState,
        pointShapeColId,
        Op.constString('circle')
      );

      if (labelCandidate != null && !labelAssigned) {
        tableState = TableState.updateColumnSelect(
          tableState,
          labelColId,
          Op.opPick({
            obj: Graph.varNode(exampleRow.type, 'row'),
            key: Op.constString(labelCandidate),
          })
        );
      }

      if (mediaCandidate != null) {
        tableState = TableState.updateColumnSelect(
          tableState,
          tooltipColId,
          Op.opPick({
            obj: Graph.varNode(exampleRow.type, 'row'),
            key: Op.constString(mediaCandidate),
          })
        );
      }
    }
  }

  // If we have an array of number, default to a scatter plot
  // by index (for the moment).
  if (
    TypeHelpers.isAssignableTo(
      inputNode.type,
      TypeHelpers.list(TypeHelpers.maybe('number'))
    )
  ) {
    if (frame.domain != null) {
      tableState = TableState.updateColumnSelect(
        tableState,
        xColId,
        Op.opNumberBin({
          in: Graph.varNode(exampleRow.type, 'row') as any,
          binFn: Op.opNumbersBinEqual({
            arr: Graph.varNode(TypeHelpers.list('number'), 'domain') as any,
            bins: Op.constNumber(10),
          }),
        }) as any
      );
      tableState = {...tableState, groupBy: [xColId]};
      tableState = TableState.updateColumnSelect(
        tableState,
        yColId,
        Op.opCount({
          arr: Graph.varNode(TypeHelpers.list(exampleRow.type), 'row') as any,
        })
      );
      axisSettings.x = {
        noTitle: true,
      };
      axisSettings.y = {
        noTitle: true,
      };
      legendSettings.color = {
        noLegend: true,
      };
    } else if (
      TypeHelpers.isAssignableTo(
        inputNode.type,
        TypeHelpers.list(
          TypeHelpers.taggedValue(
            TypeHelpers.typedDict({run: 'run'}),
            TypeHelpers.maybe('number')
          )
        )
      )
    ) {
      tableState = TableState.updateColumnSelect(
        tableState,
        yColId,
        Op.opRunName({
          run: Op.opGetRunTag({obj: Graph.varNode(exampleRow.type, 'row')}),
        })
      );
      tableState = TableState.updateColumnSelect(
        tableState,
        xColId,
        Graph.varNode(exampleRow.type, 'row')
      );
    }
  }

  // If we have an array of string, default to a histogram configuration
  if (
    TypeHelpers.isAssignableTo(
      inputNode.type,
      TypeHelpers.list(TypeHelpers.maybe('string'))
    ) &&
    frame.domain != null
  ) {
    tableState = TableState.updateColumnSelect(
      tableState,
      yColId,
      Graph.varNode(exampleRow.type, 'row')
    );
    tableState = {...tableState, groupBy: [yColId]};
    tableState = TableState.updateColumnSelect(
      tableState,
      xColId,
      Op.opCount({
        arr: Graph.varNode(TypeHelpers.list(exampleRow.type), 'row') as any,
      })
    );
    axisSettings.x = {
      noTitle: true,
    };
    axisSettings.y = {
      noTitle: true,
    };
    legendSettings.color = {
      noLegend: true,
    };
  }

  // If we have an dict of number, default to a bar chart configuration
  if (
    TypeHelpers.isAssignableTo(
      inputNode.type,
      TypeHelpers.dict(TypeHelpers.maybe('number'))
    ) &&
    frame.domain != null
  ) {
    tableState = TableState.updateColumnSelect(
      tableState,
      yColId,
      Graph.varNode('string', 'key')
    );
    tableState = TableState.updateColumnSelect(
      tableState,
      xColId,
      Graph.varNode(exampleRow.type, 'row')
    );
    axisSettings.x = {
      noTitle: true,
    };
    axisSettings.y = {
      noTitle: true,
    };
    legendSettings.color = {
      noLegend: true,
    };
  }

  // If we have an dict of array of number, default to a boxplot
  // (note this is calculated on the frontend currently. TODO fix)
  if (
    TypeHelpers.isAssignableTo(
      inputNode.type,
      TypeHelpers.dict(TypeHelpers.list(TypeHelpers.maybe('number')))
    ) &&
    frame.domain != null
  ) {
    tableState = TableState.updateColumnSelect(
      tableState,
      yColId,
      Graph.varNode('string', 'key')
    );
    tableState = TableState.updateColumnSelect(
      tableState,
      xColId,
      Graph.varNode(exampleRow.type, 'row')
    );
    tableState = TableState.updateColumnSelect(
      tableState,
      colorColId,
      Graph.varNode('string', 'key')
    );
    axisSettings.x = {
      noTitle: true,
    };
    axisSettings.y = {
      noTitle: true,
    };
    legendSettings.color = {
      noLegend: true,
    };
  }

  const dims = {
    x: xColId,
    y: yColId,
    color: colorColId,
    label: labelColId,
    tooltip: tooltipColId,
    pointSize: pointSizeColId,
    pointShape: pointShapeColId,
  };

  const migrated = migrate({
    configVersion: 1,
    axisSettings,
    legendSettings,
    dims,
    table: tableState,
  });

  return produce(migrated, draft => {
    draft.series.forEach(s => {
      s.uiState.pointShape = 'dropdown';
    });
  });
}

export function defaultSeriesName(
  series: SeriesConfig,
  weave: WeaveInterface
): string {
  if (series.name != null) {
    return series.name;
  }

  const yColId = series.dims.y;
  const yColSelectFn = series.table.columnSelectFunctions[yColId];

  if (!Graph.isVoidNode(yColSelectFn)) {
    return fixKeyForVega(
      TableState.getTableColumnName(
        series.table.columnNames,
        series.table.columnSelectFunctions,
        series.dims.y,
        weave.client.opStore
      )
    );
  }
  return 'string';
}
