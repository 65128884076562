import {ID} from '@wandb/cg';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import ReactTimeago from 'react-timeago';
import {Button, Modal} from 'semantic-ui-react';

import {useApolloClient, useSelector} from '../state/hooks';
import {ReportViewRef} from '../state/reports/types';
import {useViewer} from '../state/viewer/hooks';
import * as ViewApi from '../state/views/api';
import {LoadResultType} from '../state/views/api';
import * as ViewNormalize from '../state/views/normalize';
import * as ViewSelectors from '../state/views/selectors';
import {isOnGalleryDiscussionView} from '../util/url';
import * as Urls from '../util/urls';
import DraftWarningModal from './DraftWarningModal';

interface EditReportTriggerProps {
  viewRef: ReportViewRef;
}

const EditReportTrigger: React.FC<EditReportTriggerProps> = props => {
  const viewer = useViewer();
  const client = useApolloClient();
  const view = useSelector(state => state.views.views[props.viewRef.id]);
  const views = useSelector(ViewSelectors.getViews);
  const reportID = view.id;
  const history = useHistory();
  if (reportID == null) {
    throw new Error('invalid state');
  }
  const viewPartRef = view.partRef;
  if (viewPartRef == null) {
    throw new Error('View in redux missing part ref');
  }

  const [draftWarningOpen, setDraftWarningOpen] = React.useState(false);
  const [editedWarningOpen, setEditedWarningOpen] = React.useState(false);
  const [loadedReport, setLoadedReport] = React.useState<LoadResultType | null>(
    null
  );

  const openDraftWarning = useCallback(() => setDraftWarningOpen(true), []);
  const closeDraftWarning = useCallback(() => setDraftWarningOpen(false), []);

  const createAndGotoDraft = useCallback(async () => {
    const base = loadedReport == null ? view : loadedReport;
    const newDraft = await ViewApi.save(client, {
      type: 'runs/draft',
      name: ID(12),
      displayName: base.displayName,
      description: base.description,
      spec:
        loadedReport == null
          ? ViewNormalize.denormalize(views.parts, viewPartRef)
          : loadedReport.spec,
      project: base.project,
      parentId: base.id,
      previewUrl: base.previewUrl,
      coverUrl: base.coverUrl,
    });
    history.push(
      base.project != null
        ? Urls.reportEdit({
            entityName: base.project.entityName,
            projectName: base.project.name,
            reportID: newDraft.id as string,
            reportName: newDraft.displayName,
          })
        : (isOnGalleryDiscussionView()
            ? Urls.galleryDiscussionEdit
            : Urls.galleryPostEdit)({
            entityName: base.entityName,
            reportID: newDraft.id as string,
            reportName: newDraft.displayName,
          })
    );
  }, [client, history, loadedReport, view, viewPartRef, views.parts]);

  if (viewer == null) {
    return <>{props.children}</>;
  }

  return (
    <>
      <div
        style={{display: 'contents'}}
        onClick={async () => {
          const loaded = await ViewApi.load(client, reportID);
          setLoadedReport(loaded);
          if (loaded.updatedAt > view.updatedAt) {
            setEditedWarningOpen(true);
          } else {
            openDraftWarning();
          }
        }}>
        {props.children}
      </div>
      {loadedReport != null && editedWarningOpen && (
        <Modal
          size="mini"
          open={true}
          onClose={() => {
            setEditedWarningOpen(false);
          }}>
          <Modal.Content>
            <p>
              This report was updated{' '}
              <ReactTimeago live={false} date={loadedReport.updatedAt + 'Z'} />{' '}
              {loadedReport.updatedBy != null && (
                <>by {loadedReport.updatedBy.username}</>
              )}
              . Refresh the page to see its updated contents.
            </p>
            <Button size="tiny" primary onClick={openDraftWarning}>
              Edit updated report
            </Button>
            <Button
              size="tiny"
              onClick={() => {
                setEditedWarningOpen(false);
              }}>
              Cancel
            </Button>
          </Modal.Content>
        </Modal>
      )}
      {draftWarningOpen && (
        <DraftWarningModal
          parentViewID={reportID}
          onNoDraftFound={createAndGotoDraft}
          onDiscard={createAndGotoDraft}
          onClose={closeDraftWarning}
        />
      )}
    </>
  );
};

export default EditReportTrigger;
