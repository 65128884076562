import './polyfills';
import './integrations';
import './globalStyleImports';

import config, {envIsDev, envIsIntegration} from '@wandb/common/config';
import {ConnectedRouter} from 'connected-react-router';
import React from 'react';
import {ApolloProvider} from 'react-apollo';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {enableBatching} from 'redux-batched-actions';
import {Loader} from 'semantic-ui-react';

import {apolloClient} from './apolloClient';
import installServiceWorker from './installServiceWorker';
import Routes from './routes';
import {apolloClient2, debugCollector, history, store} from './setup';
import {Apollo2Context} from './util/apollo2-hooks';

if (config.ENVIRONMENT_IS_PRIVATE) {
  // this obfuscates the original log call locations, so only use it
  // in local, when Sentry et all aren't available
  debugCollector.instrument();
}

installServiceWorker();

const ConfigOverrideProvider: React.FC = props => {
  const [configOverridesLoading, setConfigOverridesLoading] =
    React.useState<boolean>(
      envIsIntegration && !!config.LOCAL_TEST_FIXTURE_SERVER_HOST
    );
  React.useEffect(() => {
    // This is used by integration tests to override config values
    if (configOverridesLoading) {
      // eslint-disable-next-line wandb/no-unprefixed-urls
      fetch(`${config.LOCAL_TEST_FIXTURE_SERVER_HOST}/app-config-override`, {
        method: 'GET',
      }).then(async response => {
        const overrides = await response.json();
        Object.assign(config, overrides);
        setConfigOverridesLoading(false);
      });
    }
  }, [configOverridesLoading, setConfigOverridesLoading]);

  if (configOverridesLoading) {
    return <Loader name="config-override-loader" />;
  }

  return <>{props.children}</>;
};

const shouldRedirectToHTTPS = () =>
  (envIsDev || envIsIntegration) &&
  window.location.hostname !== 'localhost' &&
  window.location.protocol !== 'https:';

const render = async (Component: React.FC) => {
  if (shouldRedirectToHTTPS()) {
    window.location.protocol = 'https:';
    return;
  }

  ReactDOM.render(
    <ConfigOverrideProvider>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <Apollo2Context.Provider value={apolloClient2}>
            <ConnectedRouter history={history}>
              <Component />
            </ConnectedRouter>
          </Apollo2Context.Provider>
        </ApolloProvider>
      </Provider>
    </ConfigOverrideProvider>,
    document.getElementById('root')
  );
};
// Note: Routes is App from App.js
render(Routes);

const hot = import.meta.hot;
if (hot) {
  hot.accept('./reducers', updatedCreateRootReducer => {
    store.replaceReducer(enableBatching(updatedCreateRootReducer(history)));
  });
  hot.accept('./routes', async ({default: NextApp}) => {
    render(NextApp);
  });
}
