type FeatureKey = 'aggregate-runs' | 'create-benchmark' | 'vega-panel';

export interface FeatureFlag {
  featureKey: FeatureKey; // unique id of the feature, e.g. 'vega-panel'
  enabledFor: string; //  entity name, project name, etc
}

type FeatureFlagData = {
  [featureKey in FeatureKey]: {[enabledFor: string]: 0 | 1};
};

// Feature Flags are enabled/disabled by W&B internally, typically for a whole entity.
// Note: This is different from Beta Features, which are experimental features that users can enable/disable on their settings page, and only affect that user.
// (See betaFeatures.ts and useBetaFeature.ts if that's what you're looking for.)

export const ALL_FEATURE_FLAGS: Readonly<FeatureFlagData> = {
  // Context on run aggregation: https://weightsandbiases.slack.com/archives/C04A258K214
  'aggregate-runs': {
    justintulk: 1,
    wandb: 1,
  },
  'create-benchmark': {
    carey: 1,
    clwainwright: 1,
    deepform: 1,
    dronedeploy: 1,
    github: 1,
    hamelsmu: 1,
    jeffr: 1,
    lavanya: 1,
    lyft: 1,
    nbaryd: 1,
    pde: 1,
    safelife: 1,
    shawn: 1,
    stacey: 1,
    thinkingmachines: 1,
    tri: 1,
    twitter: 1,
    wandb: 1,
  },
  'vega-panel': {
    adrianbg: 1,
    adrnswanberg: 1,
    altay: 1,
    Angle: 1,
    angle: 1,
    axel: 1,
    blueriver: 1,
    carey: 1,
    csordas: 1,
    hamelsmu: 1,
    jeffr: 1,
    kylegoyette: 1, // Added 6/10/20 Kyle Goyette
    l2k2: 1,
    lavanya: 1,
    lavanyashukla: 1,
    'louis-wb': 1, // Added 4/11/20
    lshukla: 1,
    nbaryd: 1,
    sculpturearts: 1,
    shawn: 1,
    stacey: 1,
    syllogismos: 1,
    tom: 1,
    tri: 1,
    wandb: 1,
  },
};
