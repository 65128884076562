import * as globals from '@wandb/common/css/globals.styles';
import {TargetBlank} from '@wandb/common/util/links';
import {Checkbox, Icon, Table} from 'semantic-ui-react';
import styled from 'styled-components';

export const ToggleWrapper = styled.div`
  display: flex;
`;

export const Toggle = styled(Checkbox)<{$unicornModeEnabled?: boolean}>`
  &&& {
    > *:before {
      background-color: ${props => {
        if (props.$colorTheme === 'dark') {
          if (props.checked) {
            return `${globals.primaryLight} !important`;
          }
          return `${globals.gray100alpha38} !important`;
        }
        if (props.checked) {
          return `${globals.primary} !important`;
        }
        return '#d9d9d9 !important';
      }};
    }
    &&& {
      label {
        cursor: ${props =>
          props.$unicornModeEnabled
            ? "url('https://wandb.ai/unicorn.png'), pointer"
            : 'pointer'};
        padding-left: 3rem;
      }
    }
  }
`;

export const TargetBlankLink = styled(TargetBlank)`
  color: ${globals.gray600};
  text-decoration: none;
`;

export const GrayIcon = styled(Icon)`
  color: ${globals.gray600};
`;

export const MobileAlertTypeLabel = styled.div`
  min-width: 50px;
`;

export const TableCellInner = styled.div<{isMobile?: boolean}>`
  display: ${props => (props.isMobile ? 'flex' : 'block')};
  align-items: center;
  justify-content: start;
  > * {
    display: ${props => (props.isMobile ? 'flex' : 'block')};
    align-items: center;
    justify-content: start;
  }
`;

export const HeaderCellBold = styled(Table.HeaderCell)`
  font-weight: 600;
`;
