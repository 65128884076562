import {Link} from 'react-router-dom';
import {Feed, Pagination} from 'semantic-ui-react';
import styled from 'styled-components';

export const ArtifactActionsContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export const ArtifactActionsWrapper = styled.div`
  background: white;
  padding: 0em 1em 1em 1em;
`;

export const ArtifactLinkActionsWrapper = styled.div`
  background: white;
  padding-left: 3em;
  padding-bottom: 1em;
  margin-bottom: 1em;
`;

export const Header = styled.div`
  display: inline-flex;
  margin-top: -5px;
`;

export const HeaderText = styled.div`
  font-size: 20px;
  display: block;
  margin-bottom: 1em;
  padding-right: 0.5em;
  margin-left: -10px;
`;

export const AliasHeader = styled.div`
  margin-right: 0.5em;
`;

export const RowsContainer = styled.div`
  border-left: 1px solid #e8e8e8;
`;

export const LabelWrapper = styled.div`
  align-items: center;
  margin-left: -17px;
`;

export const IconWrapper = styled.div`
  display: block;
  font-size: 1.3em;
  background: #e8e8e8;
  padding: 6px;
  border-radius: 50%;
`;

export const Dot = styled.span`
  margin-left: 3px;
  margin-right: 3px;
`;

export const CreatedAt = styled.div`
  font-size: 12px;
`;

export const UserTime = styled.div`
  display: inline-flex;
`;

export const Message = styled.div`
  display: block;
  margin-top: 2px;
`;

export const MessageItems = styled.div`
  display: inline-flex;
`;

export const Alias = styled.div`
  margin-left: 4px;
`;

export const Paginator = styled(Pagination)`
  margin-top: 1em;
`;

export const FeedEvent = styled(Feed.Event)`
  display: flex;
  margin-bottom: 1em;
`;

export const FeedContent = styled(Feed.Content)`
  margin-left: 1em;
`;

export const SpacedLink = styled(Link)`
  margin-right: 4px;
  margin-left: 4px;
`;

export const LeftSpacedLink = styled(Link)`
  margin-left: 4px;
`;
