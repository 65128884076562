import fetch from 'isomorphic-unfetch';
import _ from 'lodash';
import pako from 'pako';

import {GlobalCGEventTracker} from '../analytics/tracker';
import type {Node} from '../model';
import {serialize} from '../model';
import type {OpStore} from '../opStore';
import type {Server} from './types';

const BATCH_INTERVAL_MS = 20;

// from https://www.jpwilliams.dev/how-to-unpack-the-return-type-of-a-promise-in-typescript
// when all of our apps are on TS 4.x we can use Awaited<> instead
// type Unwrap<T> = T extends Promise<infer U>
//   ? U
//   : T extends (...args: any) => Promise<infer U2>
//   ? U2
//   : T extends (...args: any) => infer U3
//   ? U3
//   : T;

// to prevent importing the DOM types here (they're all or nothing),
// we get the Response type off of the fetch polyfill instead
//
// because the library doesn't actually export it, we need to unpack the promise
// type returned by the fetch() function
// type Response = Unwrap<ReturnType<typeof fetch>>;

export interface RemoteWeaveOptions {
  weaveUrl: string;

  // An async function for retrieving auth token
  tokenFunc: () => Promise<string | undefined>;

  useAdminPrivileges: boolean;
  useGzip: boolean;
  isShadow: boolean;
  verbose: boolean;
}

const defaultOpts: RemoteWeaveOptions = {
  weaveUrl: 'https://localhost:9004/execute',
  tokenFunc: () => Promise.resolve(''),
  useAdminPrivileges: false,
  useGzip: false,
  isShadow: false,
  verbose: true,
};

// Handles (de)serialization to send to a remote CG server
export class RemoteHttpServer implements Server {
  private pendingRequests: Array<[Node, (r: any) => void]> = [];
  private readonly opts: RemoteWeaveOptions;

  private flush = _.throttle(
    async (useGzip: boolean, retryNum = 0) => {
      const requests = [...this.pendingRequests];
      this.pendingRequests.length = 0;
      const nodes = requests.map(r => r[0]);
      const payloadJSON = {
        graphs: serialize(nodes),
      };
      const payload = JSON.stringify(payloadJSON);

      // Compression
      let body: string | Uint8Array = payload;
      const additionalHeaders: Record<string, string> = {};
      if (payload.length > 1024 && useGzip) {
        const originalLength = payload.length;
        body = pako.gzip(payload);
        console.debug(
          `[GZIP] Compressed ${requests.length} graphs ${Math.round(
            (1 - body.length / originalLength) * 100
          )}% (${originalLength} -> ${body.length} bytes)`
        );
        additionalHeaders['Content-Encoding'] = 'gzip';
      }

      // This is also found in cookie, which to prefer?
      if (this.opts.useAdminPrivileges) {
        additionalHeaders['use-admin-privileges'] = 'true';
      }

      if (this.opts.isShadow) {
        additionalHeaders['weave-shadow'] = 'true';
        additionalHeaders['x-weave-include-execution-time'] = 'true';
      }

      let respJson: any = {
        data: new Array(requests.length).fill(null),
      };
      let response: any = null;
      try {
        response = await fetch(this.opts.weaveUrl, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...additionalHeaders,
          },
          body,
        });
      } catch (err) {
        // TODO(np): Retry is disabled because we may be requesting very expensive graphs
        // or hitting remote APIs.  Don't endlessly hammer services.
        // TODO(np): Uncrapify: We are just requeuing failed requests then scheduling a flush w/
        // exponential backoff, up to around ~1 min/retry.  We never give up.
        // const retryWait = 500 * Math.exp(Math.min(retryNum, 10) / 2);
        // this.pendingRequests.push.apply(this.pendingRequests, requests);
        // setTimeout(() => this.flush(useGzip, ++retryNum), retryWait);
        // console.error(
        //   `[REMOTE CG] retry ${requests.length} graphs in ${(
        //     retryWait / 1000
        //   ).toFixed(2)}s because fetch failed: ${(err as Error).message}`
        // );

        // Instead, warn in console and return nulls for failed values.
        if (this.opts.verbose) {
          console.warn(
            'Weave fetch request failed. Fetch Details:\n',
            JSON.stringify(
              {
                payloadJSON,
                nodes,
                err,
              },
              null,
              2
            )
          );
        }
      }
      if (response != null) {
        if (response.ok) {
          try {
            const resp = await response.json();
            if (resp.data == null && this.opts.verbose) {
              console.warn(
                'Weave response was missing data. Fetch Details:\n',
                JSON.stringify(
                  {
                    payloadJSON,
                    nodes,
                    resp,
                  },
                  null,
                  2
                )
              );
            } else {
              respJson = resp;
            }
          } catch (err) {
            if (this.opts.verbose) {
              console.warn(
                'Weave response deserialization failed. Fetch Details:\n',
                JSON.stringify(
                  {
                    payloadJSON,
                    nodes,
                    err,
                  },
                  null,
                  2
                )
              );
            }
          }
        } else {
          let errorDetails = null;
          try {
            errorDetails = await response.json();
            if (errorDetails.error != null) {
              errorDetails = errorDetails.error;
            }
          } catch (err) {
            // pass - we don't want a big html block in the error message
          }
          if (this.opts.verbose) {
            console.warn(
              'Weave response not OK. Fetch Details:\n',
              JSON.stringify(
                {
                  payloadJSON,
                  nodes,
                  status: response.status,
                  statusText: response.statusText,
                },
                null,
                2
              ),
              '\nServer Error:\n',
              errorDetails
            );
          }
        }
      }
      // Get rid of pineapple encoding for developing Weave Python server.
      // const values = unpineapple(respJson.data);
      const values = respJson.data;

      for (let i = 0; i < requests.length; i++) {
        // console.log(`result`, values[i]);
        requests[i][1](values[i]);
      }
    },
    BATCH_INTERVAL_MS,
    {leading: false, trailing: true}
  );

  public constructor(
    inOpts: Partial<RemoteWeaveOptions>,
    public readonly opStore: OpStore
  ) {
    this.opts = _.defaults({}, inOpts, defaultOpts);
  }

  public async query(
    nodes: Node[],
    withBackendCacheReset?: boolean
  ): Promise<any[]> {
    GlobalCGEventTracker.remoteHttpServerQueryBatchRequests++;
    // TODO: pass withBackendCacheReset across the network
    return await Promise.all(
      nodes.map(
        node =>
          new Promise((resolve /* reject */) => {
            this.pendingRequests.push([node, resolve]);
            this.flush(this.opts.useGzip);
          })
      )
    );
  }
}
