import {PanelProps} from '../../util/panelHelpers';
import {PlotFontSizeOrAuto} from '../../util/plotHelpers';
import {GradientStop} from '../GradientPicker';

export type PCColumn = {
  accessor?: string; // should be something like export loss.value
  value?: string;
  log?: boolean;
  inverted?: boolean;
  displayName?: string;
  params?: {[key: string]: string};
};

export type PCConfig = {
  columns?: PCColumn[];
  dimensions?: string[];
  customGradient?: GradientStop[];
  gradientColor?: boolean;
  legendFields?: string[];
  chartTitle?: string;
  fontSize?: PlotFontSizeOrAuto;
};

export type ParallelCoordPanelProps = PanelProps<PCConfig>;

export function getTitleFromConfig(config: PCConfig): string {
  return config.chartTitle ?? '';
}
