import * as Op from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import moment from 'moment';
import * as React from 'react';

import * as Generated from '../../../../generated/graphql';
import {OItem} from './OItem';
import * as S from './style';

const artifactInputType = 'artifact' as const;
export const PanelArtifactMembershipOverviewCollectionSection: React.FC<
  Panel2.PanelProps<typeof artifactInputType, {}> & {
    readOnly: boolean;
    artifactRegistry?: boolean;
  }
> = props => {
  const {input: artifactNode, readOnly} = props;
  const [updateArtifactCollection] =
    Generated.useUpdateArtifactCollectionMutation();
  const [updateArtifactPortfolio] =
    Generated.useUpdateArtifactPortfolioMutation();
  const {result, loading} = useNodeValue(
    React.useMemo(() => {
      return Op.opDict({
        artifactId: Op.opArtifactId({
          artifact: artifactNode,
        }),
        artifactName: Op.opArtifactName({
          artifact: artifactNode,
        }),
        artifactDescription: Op.opArtifactDescription({
          artifact: artifactNode,
        }),
        artifactCreatedAt: Op.opArtifactCreatedAt({
          artifact: artifactNode,
        }),
        artifactTypeName: Op.opArtifactTypeName({
          artifactType: Op.opArtifactType({artifact: artifactNode}),
        }),
        artifactIsPortfolio: Op.opArtifactIsPortfolio({
          artifact: artifactNode,
        }),
      } as any);
    }, [artifactNode])
  );

  const onUpdateDescription: React.ComponentProps<
    typeof S.OverviewValueMarkdownEditor
  >['onChange'] = React.useCallback(
    (value: string) => {
      if (result?.artifactIsPortfolio) {
        updateArtifactPortfolio({
          variables: {
            artifactPortfolioID: result?.artifactId,
            description: value,
          },
        });
      } else {
        updateArtifactCollection({
          variables: {
            artifactSequenceID: result?.artifactId,
            description: value,
          },
        });
      }
    },
    [result, updateArtifactCollection, updateArtifactPortfolio]
  );

  return (
    <S.OverviewPanelSection>
      <S.OverviewPanelSectionHeader>
        <S.OverviewPanelSectionHeaderTitle>
          {result?.artifactIsPortfolio ? 'Collection' : 'Artifact'}
        </S.OverviewPanelSectionHeaderTitle>
      </S.OverviewPanelSectionHeader>
      <S.OverviewPanelSectionBody>
        <S.OverviewPanelSectionBodyRow>
          <S.OverviewPanelSectionBodyColumn>
            {!props.artifactRegistry && (
              <OItem label={'Type'} value={result?.artifactTypeName} />
            )}
            <OItem
              label={'Created At'}
              value={moment(
                moment.utc(result?.artifactCreatedAt).toDate()
              ).format('MMMM Do, YYYY')}
            />
          </S.OverviewPanelSectionBodyColumn>
        </S.OverviewPanelSectionBodyRow>
        <S.OverviewPanelSectionBodyRow>
          <OItem
            label={'Description'}
            value={
              loading ? (
                <></>
              ) : (
                <S.OverviewValueMarkdownEditor
                  readOnly={readOnly}
                  saveText={false}
                  placeholder={'Add a markdown description...'}
                  serverText={result?.artifactDescription}
                  onChange={onUpdateDescription}
                />
              )
            }
          />
        </S.OverviewPanelSectionBodyRow>
      </S.OverviewPanelSectionBody>
    </S.OverviewPanelSection>
  );
};
