import {useState} from 'react';

export function usePaginate<T>(items: T[], perPage: number) {
  const total = items.length;
  const totalPages = Math.ceil(total / perPage);

  const [page, setPage] = useState(0);
  const pageWithMax = Math.min(page, totalPages - 1);

  const hasPreviousPage = pageWithMax > 0;
  const hasNextPage = pageWithMax < totalPages - 1;

  const skip = perPage * pageWithMax;
  const itemsPage = items.slice(skip, skip + perPage);

  return {
    total,
    totalPages,
    page: pageWithMax,
    setPage,
    itemsPage,
    hasNextPage,
    hasPreviousPage,
  };
}
