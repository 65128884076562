import {list, varNode} from '@wandb/cg';
import React from 'react';

import {WeaveConfig} from './PanelWeave';

export const useRecommendedWeavePanels = (
  // This can be expanded to include more data about the runset for more complicated recommendations
  keysOfType: (type: string) => string[] | undefined
): {
  [type: string]: {
    name: string;
    icon: string;
    enabled: boolean;
    defaultConfig: WeaveConfig;
  };
} => {
  const hasLoggedTable = keysOfType('table-file');
  return React.useMemo(
    () => ({
      'weave-table': {
        name: 'Data Table',
        icon: 'panel-text-table',
        defaultConfig: {
          panel2Config: {
            exprAndPanelLocked: true,
            panelId: 'runs-table.row.table',
            exp: varNode(list('run' as const), 'runs'),
          },
        },
        enabled: !!hasLoggedTable,
      },
      'weave-plot': {
        name: 'Table Plot',
        icon: 'panel-scatter-plot',
        defaultConfig: {
          panel2Config: {
            exprAndPanelLocked: true,
            panelId: 'runs-table.row.plot',
            exp: varNode(list('run' as const), 'runs'),
          },
        },
        enabled: !!hasLoggedTable,
      },
      'weave-projector': {
        name: 'Projector',
        icon: 'projector',
        defaultConfig: {
          panel2Config: {
            exprAndPanelLocked: true,
            panelId: 'runs-table.row.projection.plot',
            exp: varNode(list('run' as const), 'runs'),
          },
        },
        enabled: !!hasLoggedTable,
      },
    }),
    [hasLoggedTable]
  );
};
