import {DragDropProvider} from '@wandb/common/containers/DragDropContainer';
import {PanelExportContextProvider} from '@wandb/weave-ui/components/Panel2/PanelExportContext';
import React, {FC, useCallback, useContext, useState} from 'react';
import {Editor, Node, NodeEntry, Range, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
} from 'slate-react';

import {useSelector} from '../../../state/hooks';
import {useReportContext} from '../../../state/reports/context';
import {ReportViewRef} from '../../../state/reports/types';
import {ReportWidthOption} from '../../../state/views/report/types';
import {useAdminModeActive} from '../../../util/admin';
import {useScrollToURLHash} from '../../../util/document';
import {useIsGalleryReport} from '../../../util/gallery';
import FloatingReportDiscussion from '../../FloatingReportDiscussion';
import {ReportDiscussion} from '../../ReportDiscussion';
import {ReportDiscussionContext} from '../../ReportDiscussionContext';
import ReportPageSubscribePrompt from '../../ReportPageSubscribePrompt';
import {useCompositionKeyboard} from '../CompositionKeyboardContext';
import {FocusedContext} from '../FocusedContext';
import {HoveringToolbar} from '../HoveringToolbar';
import {autoScrollOnKeyDown} from '../plugins/auto-scroll';
import {useCodeHighlighting} from '../plugins/code-blocks';
import {WBSlateDragDropContext} from '../plugins/drag-drop';
import {hardBreakOnKeyDown} from '../plugins/hard-break';
import {useInlineCommentHighlighting} from '../plugins/inline-comment';
import {EditorWithLists} from '../plugins/lists';
import {markShortcutsOnKeyDown} from '../plugins/mark-shortcuts';
import {EditorWithPersistentBlankLines} from '../plugins/persistent-blank-lines';
import {ShiftPressedContext} from '../ShiftPressedContext';
import SlashMenu from '../SlashMenu';
import {TypingContext} from '../TypingContext';
import {stripZeroWidthChars} from '../util';
import {WBSlateElementType} from './types';
import {createWBSlateEditor, hasEditableTarget} from './utils';
import * as S from './WBSlate.styles';
import {WBSlateElement} from './WBSlateElement';
import {WBSlateLeaf} from './WBSlateLeaf';

export interface WBSlateProps {
  readOnly: boolean;
  widthMode: ReportWidthOption;
  value: WBSlateElementType[];
  viewRef: ReportViewRef | null;
  viewID: string;
  onChange(value: Node[]): void;
}

export const WBSlate: FC<WBSlateProps> = ({
  readOnly,
  widthMode,
  value,
  viewRef,
  viewID,
  onChange,
}) => {
  const admin = useAdminModeActive();
  const {disableComments, disablePersistentTopLine} = useReportContext();
  const editor = React.useMemo(
    // Due to security/usability concerns, we want replit embeds in reports to be usable only by W&B admins.
    () => createWBSlateEditor({enableReplit: admin, disablePersistentTopLine}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initialFocused = ReactEditor.isFocused(editor);
  const [focused, setFocused] = useState(initialFocused);

  const [slashMenuOpen, setSlashMenuOpen] = React.useState(false);
  const {inlineCommentDetails} = useContext(ReportDiscussionContext);

  const view = useSelector(state =>
    viewRef != null ? state.views.views[viewRef.id] : null
  );
  const parentViewId = view?.parentId ?? view?.id;

  const [dragItem, setDragItem] = React.useState<Node | undefined>(undefined);
  // This is true if any of the drag handle menus are open. Disables the popup on the other handles.
  const [dragHandleMenuOpen, setDragHandleMenuOpen] = React.useState(false);
  const dragContextValue = React.useMemo(
    () => ({
      parentViewId,
      dragItem,
      setDragItem,
      dragHandleMenuOpen,
      setDragHandleMenuOpen,
    }),
    [
      parentViewId,
      dragItem,
      setDragItem,
      dragHandleMenuOpen,
      setDragHandleMenuOpen,
    ]
  );

  const [typing, setTyping] = React.useState(true);
  const typingContextValue = React.useMemo(
    () => ({typing, setTyping}),
    [typing]
  );

  const [shiftPressed, setShiftPressed] = React.useState(false);
  const shiftPressedContextValue = React.useMemo(
    () => ({shiftPressed, setShiftPressed}),
    [shiftPressed]
  );

  const {startComposition, endComposition} = useCompositionKeyboard();

  // HAX: DO NOT REMOVE. THE "CONVERT ALL MARKDOWN" BUTTON IS RELIANT ON THIS.
  // "sry not sry" - Axel
  (window as any).editor = editor;
  (window as any).Editor = Editor;
  (window as any).ReactEditor = ReactEditor;
  (window as any).Transforms = Transforms;
  (window as any).value = value;

  useScrollToURLHash(3000);

  const {isGalleryReport} = useIsGalleryReport(viewID);

  const renderElement = React.useCallback(
    (props: RenderElementProps) => {
      // check if the focus has shifted from/in slate
      // if so, rerender WBSlate to rerender child elements
      const editorIsFocused = ReactEditor.isFocused(editor);
      setFocused(editorIsFocused);

      return <WBSlateElement {...props} />;
    },
    [editor]
  );
  const renderLeaf = React.useCallback(
    (props: RenderLeafProps) => {
      return <WBSlateLeaf {...props} />;
    },
    // this dep list allows re-rendering all the elements when inlineCommentDetails
    // get changed (i.e. user added inline text to comment) to call decorate
    // and properly highlight the temporary inline text that is getting commented on.
    // without this, slate doesn't re-render all the elements for performance optimization.
    // eslint-disable-next-line
    [inlineCommentDetails]
  );

  const addWeavePanel = React.useCallback(
    (panel: any) => {
      const weavePanelNode: Node = {
        type: 'weave-panel',
        children: [{text: ''}],
        config: {
          exp: panel.node,
          panelId: panel.panelId,
          panelConfig: panel.config,
        },
      };
      Editor.insertNode(editor, weavePanelNode);
    },
    [editor]
  );

  const codeDecorate = useCodeHighlighting(editor);
  const inlineDecorate = useInlineCommentHighlighting();

  const decorate = useCallback(
    ([node, path]: NodeEntry<Node>) => {
      // decorate the code syntax
      const codeRanges = codeDecorate([node, path]);
      // decorate the temporary inline text comments
      const inlineRanges = inlineDecorate([node, path]);
      return [...codeRanges, ...inlineRanges];
    },
    [codeDecorate, inlineDecorate]
  );

  const selectSlateRangeInViewMode = useCallback(() => {
    /* For slate to get selection from browser in view mode
          to enable inline text comment */
    if (!readOnly || isGalleryReport) {
      // edit mode already has onMouseUp implemented in Editable
      // inline text comment is disabled for gallery reports
      return;
    }

    const el = ReactEditor.toDOMNode(editor, editor);
    const root = el.getRootNode();

    if (root instanceof Document && root.getSelection != null) {
      const domSelection = root.getSelection();
      if (
        domSelection == null ||
        domSelection.type === 'None' ||
        domSelection.type === 'Caret'
      ) {
        // clear out the selection to not show hoveringToolbar and
        // not add old selected text in comment when clicking once
        Transforms.deselect(editor);
        return;
      }

      const range = ReactEditor.toSlateRange(editor, domSelection, {
        exactMatch: false,
        suppressThrow: false,
      });
      Transforms.select(editor, range);
    }
  }, [readOnly, isGalleryReport, editor]);

  // TODO(axel): figure out how to normalize on init in a cleaner fashion
  React.useLayoutEffect(() => {
    EditorWithPersistentBlankLines.ensureBlankLine(editor, {
      disablePersistentTopLine,
    });
    if (!readOnly) {
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.start(editor, []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, readOnly]);

  return (
    <S.SlateWrapper data-test="wbslate-report-content">
      {viewRef != null && isGalleryReport && (
        <FloatingReportDiscussion
          viewRef={viewRef}
          isFeatured={isGalleryReport}
        />
      )}
      <S.PrintReportHack />
      <Slate editor={editor} value={value} onChange={onChange}>
        <TypingContext.Provider value={typingContextValue}>
          {/* For letting child components know focus has changed */}
          <FocusedContext.Provider value={focused}>
            <ShiftPressedContext.Provider value={shiftPressedContextValue}>
              <SlashMenu
                open={slashMenuOpen}
                onClose={React.useCallback(() => setSlashMenuOpen(false), [])}
              />
              <HoveringToolbar readOnly={readOnly} />
              <PanelExportContextProvider addPanel={addWeavePanel}>
                {/* For reordering blocks */}
                <WBSlateDragDropContext.Provider value={dragContextValue}>
                  {/* For panels in panel grids */}
                  <DragDropProvider>
                    <S.StyledEditable
                      data-test="report-editable-content"
                      decorate={decorate}
                      readOnly={readOnly}
                      $widthMode={widthMode}
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      scrollSelectionIntoView={() => {}} // no-op to disable Slate's default scroll behavior when dragging an overflowed element
                      onMouseUp={() => {
                        try {
                          selectSlateRangeInViewMode();
                        } catch (e) {
                          // user selected the void elements,
                          // deselect the range to not accidentally open hovering toolbar
                          Transforms.deselect(editor);
                        }
                      }}
                      onCopyCapture={(
                        event: React.ClipboardEvent<HTMLDivElement>
                      ) => {
                        if (readOnly) {
                          stripZeroWidthChars(event);
                        }
                      }}
                      onPaste={(
                        event: React.ClipboardEvent<HTMLDivElement>
                      ) => {
                        // this is slightly modified from slate-react's internal onPaste handler
                        // we need this because slate doesn't handle paste correctly on Safari
                        if (
                          hasEditableTarget(editor, event.target) &&
                          !readOnly
                        ) {
                          event.preventDefault();
                          ReactEditor.insertData(editor, event.clipboardData);
                        }
                      }}
                      onCut={() => {
                        /** Ugly hack to fix cut on a single void node */
                        const {selection} = editor;
                        if (
                          selection &&
                          Range.isCollapsed(selection) &&
                          Editor.void(editor)
                        ) {
                          window.setTimeout(() => {
                            Editor.deleteBackward(editor);
                          });
                        }
                      }}
                      onKeyDown={e => {
                        if (autoScrollOnKeyDown(editor, e)) {
                          return;
                        }

                        if (markShortcutsOnKeyDown(editor, e)) {
                          return;
                        }

                        if (hardBreakOnKeyDown(editor, e)) {
                          return;
                        }

                        if (e.key === '/') {
                          window.setTimeout(() => {
                            setSlashMenuOpen(true);
                          });
                        }

                        // Prevents newline. Necessary in Firefox.
                        if (e.key === 'Enter') {
                          if (slashMenuOpen) {
                            e.preventDefault();
                          }
                        }

                        if (e.key === 'Tab') {
                          e.preventDefault();

                          if (EditorWithLists.onTab(editor, e)) {
                            return;
                          }

                          Transforms.insertText(editor, '\t');
                          return;
                        }

                        // Unfortunate hack because Slate doesn't
                        // detect backspaces on inline voids
                        // in Chrome.
                        if (e.key === 'Backspace') {
                          const inlineVoid = Editor.above(editor, {
                            match: n =>
                              Editor.isVoid(editor, n) &&
                              Editor.isInline(editor, n),
                          });
                          if (inlineVoid != null) {
                            e.preventDefault();
                            Editor.deleteBackward(editor);
                            return;
                          }
                        }

                        if (e.key === 'Shift') {
                          setShiftPressed(true);
                          editor.shiftPressed = true;
                        }

                        setTyping(true);
                      }}
                      onKeyUp={e => {
                        if (e.key === 'Shift') {
                          setShiftPressed(false);
                          editor.shiftPressed = false;
                        }
                      }}
                      onMouseMove={() => {
                        setTyping(false);
                      }}
                      onCompositionStart={startComposition}
                      onCompositionEnd={endComposition}
                    />
                  </DragDropProvider>
                  <ReportPageSubscribePrompt viewID={viewID} />
                  {!disableComments && (
                    <ReportDiscussion isViewingReport={readOnly} />
                  )}
                </WBSlateDragDropContext.Provider>
              </PanelExportContextProvider>
            </ShiftPressedContext.Provider>
          </FocusedContext.Provider>
        </TypingContext.Provider>
      </Slate>
    </S.SlateWrapper>
  );
};
