import * as CG from '@wandb/cg';
import * as TypeHelpers from '@wandb/cg';
import * as Op from '@wandb/cg';
import {escapeDots} from '@wandb/cg';

export interface WeaveConfig {
  panel2Config?: any;
  key?: string;
}

export function weavePanelForSummaryTableKey(key: string): WeaveConfig {
  // var name does not matter, PanelExpression will auto-replace.
  // Using something unique & ugly to force out errors.
  const varNode = CG.varNode(TypeHelpers.list('run'), 'runs');
  const runSummaryNode = Op.opRunSummary({run: varNode});
  const runTableNode = Op.opPick({
    obj: runSummaryNode,
    key: Op.constString(escapeDots(key)),
  });
  return {
    panel2Config: {exp: runTableNode},
    key,
  };
  // Uncomment the below to auto-inject the table merge. For now, I
  // prefer not auto-injecting, but this may change as we get feedback.
  // const newExp = callOp(Panel2.panelIdToPanelOpName('merge'), {
  //   input: runTableNode,
  //   config: Op.constNodeUnsafe('any', {}),
  // });

  // return {
  //   panel2Config: {exp: newExp},
  // };
}
