import {ApolloQueryResult} from 'apollo-client';
import React, {createContext, FC, useContext, useMemo} from 'react';

import {useUpdateUserInfoMutation} from '../../generated/graphql';
import {GetViewerQueryResponse} from '../../graphql/users_get_viewer';
import {User} from '../../types/graphql';

export type UserSettingsContextState = {
  viewer?: User;
  refetch?: (() => Promise<ApolloQueryResult<GetViewerQueryResponse>>) &
    (() => void);
  updateUserInfo?: ReturnType<typeof useUpdateUserInfoMutation>[0];
};

const UserSettingsContext = createContext<UserSettingsContextState>({
  viewer: undefined,
  refetch: undefined,
  updateUserInfo: undefined,
});

export const UserSettingsContextProvider: FC<{
  viewer: User;
  refetch: (() => Promise<ApolloQueryResult<GetViewerQueryResponse>>) &
    (() => void);
}> = ({viewer, refetch, children}) => {
  const [updateUserInfo] = useUpdateUserInfoMutation();

  const userSettingsContextState: UserSettingsContextState = useMemo(
    () => ({viewer, refetch, updateUserInfo}),
    [refetch, updateUserInfo, viewer]
  );
  return (
    <UserSettingsContext.Provider value={userSettingsContextState}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettingsContext = () => {
  return useContext(UserSettingsContext);
};
