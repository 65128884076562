import React from 'react';

import {EntityResultsList} from './EntityResultsList';
import {useSearchContext} from './SearchContext';

export interface EntityPageStarterContentProps {
  blurAndClear: () => void;

  setShowAllEntityResults: (showAll: boolean) => void;
  showAllEntityResults: boolean;
}

export const EntityPageStarterContent = ({
  blurAndClear,
  setShowAllEntityResults,
  showAllEntityResults,
}: EntityPageStarterContentProps) => {
  const {displayedResults} = useSearchContext();

  if (displayedResults.entity.length > 0) {
    return (
      <>
        <div className="search-result-header">In this entity</div>
        <EntityResultsList
          blurAndClear={blurAndClear}
          setShowAllEntityResults={setShowAllEntityResults}
          showAllEntityResults={showAllEntityResults}
        />
      </>
    );
  }

  return <div className="no-search-results">No projects in this entity</div>;
};
