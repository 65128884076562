import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React, {memo, useState} from 'react';
import {Button, Modal, Popup} from 'semantic-ui-react';

import {GetTaskComponent, TaskState} from '../generated/graphql';
import {ProjectPicker} from './ProjectPicker';

interface RunMoverProps {
  entityName: string;
  requireSameEntity?: boolean;
  requireSameEntityReason?: string;
  isInReport?: boolean;
  projectName: string;
  selectedCount: number;
  refetch(): void;
  onMove(
    destinationEntityName: string,
    destinationProjectName: string
  ): Promise<string>;
}

const RunMoverComp: React.FC<RunMoverProps> = ({
  entityName,
  requireSameEntity,
  requireSameEntityReason,
  isInReport,
  projectName,
  selectedCount,
  refetch,
  onMove,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [destinationEntityName, setDestinationEntityName] = useState<
    string | undefined
  >();
  const [destinationProjectName, setDestinationProjectName] = useState<
    string | undefined
  >();
  const [inProgressTaskID, setInProgressTaskID] = useState<
    string | undefined
  >();

  let button: React.ReactNode;
  let buttonClass: string;
  if (popupOpen) {
    buttonClass = 'action-button--focused';
  } else if (inProgressTaskID) {
    buttonClass = 'action-button--active';
  } else {
    buttonClass = 'action-button--static';
  }

  if (inProgressTaskID) {
    button = (
      <RunMoverProgress
        inProgressTaskID={inProgressTaskID!}
        buttonClass={buttonClass + ' wb-icon-button'}
        onFinished={() => {
          setInProgressTaskID(undefined);
          refetch();
        }}
        onError={() => {
          setInProgressTaskID(undefined);
          setErrorOpen(true);
        }}
      />
    );
  } else {
    button = (
      <Button
        size="tiny"
        onClick={() => {
          const actionLocation = isInReport ? 'report' : 'runs table';
          window.analytics?.track('Move button clicked', {
            location: actionLocation,
          });
        }}
        className={
          buttonClass +
          ' enable-pointer-events wb-icon-button' +
          (selectedCount === 0 ? ' disabled' : '')
        }>
        <LegacyWBIcon name="move-runs" />
        Move
      </Button>
    );
  }

  if (selectedCount === 0) {
    return (
      <Popup
        basic
        on="hover"
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        content={
          <p className="hint-text small">
            Select runs to move by hovering over a row and clicking the checkbox
            on the left.
          </p>
        }
        trigger={button}
      />
    );
  }

  return (
    <React.Fragment>
      <ProjectPicker
        entityName={entityName}
        requireSameEntity={requireSameEntity}
        requireSameEntityReason={requireSameEntityReason}
        projectName={projectName}
        onOpen={() => setPopupOpen(true)}
        onClose={() => setPopupOpen(false)}
        setProject={(entity, proj) => {
          setDestinationEntityName(entity);
          setDestinationProjectName(proj);
          setModalOpen(true);
        }}
        triggerOverride={button}
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <p>
            {`Are you sure you want to move ${selectedCount} ${
              selectedCount === 1 ? 'run' : 'runs'
            } to project `}
            <b>{`${destinationEntityName!}/${destinationProjectName!}`}</b>?
          </p>
          <p className="disclaimer">
            Note: Any runs that are still running will not be moved.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-test="run-move-popup"
            onClick={() => setModalOpen(false)}>
            Nevermind
          </Button>
          <Button
            positive
            onClick={() => {
              onMove(destinationEntityName!, destinationProjectName!).then(
                taskID => {
                  setInProgressTaskID(taskID);
                  setModalOpen(false);
                }
              );
            }}>
            Move runs
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={errorOpen}>
        <Modal.Content>
          <p>
            Some of your runs didn't make the move. Please try moving them
            again. If this keeps happening, you can message us with the chat in
            the lower right corner of the page.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setErrorOpen(false)}>Ok</Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export const RunMover = memo(RunMoverComp);

interface RunMoverProgressProps {
  buttonClass: string;
  inProgressTaskID: string;
  onFinished: () => void;
  onError: () => void;
}

const RunMoverProgress: React.FC<RunMoverProgressProps> = React.memo(
  ({buttonClass, inProgressTaskID, onFinished, onError}) => {
    const [progressText, setProgressText] = useState('Moving...');

    return (
      <GetTaskComponent
        pollInterval={500}
        fetchPolicy="no-cache"
        notifyOnNetworkStatusChange
        onCompleted={({task}) => {
          const {state, progress} = task!;

          setProgressText(`Moving... (${progress}%)`);

          if (state === TaskState.Finished) {
            onFinished();
          } else if (state === TaskState.Failed) {
            onError();
          }
        }}
        onError={() => onError()}
        variables={{
          id: inProgressTaskID,
        }}>
        {() => {
          return (
            <Button disabled className={buttonClass} size="tiny">
              <LegacyWBIcon name="move-runs" />
              {progressText}
            </Button>
          );
        }}
      </GetTaskComponent>
    );
  }
);
