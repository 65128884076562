import {toast} from '@wandb/common/components/elements/Toast';
import {isNotNullOrUndefined} from '@wandb/common/util/types';
import React, {useCallback, useMemo, useState} from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlate,
} from 'slate-react';

import {useFeaturedReportsMetadataQuery} from '../../../generated/graphql';
import {parseNameAndIDFromURLSafe} from '../../../util/url';
import ReportShowcase, {ShowcaseItem} from '../../ReportShowcase';
import {ReportsTableReport} from '../../ReportsTable';
import {BlockWrapper} from './drag-drop';
import * as S from './gallery.styles';

type GalleryLinkReport = {
  type: `report`;
  id: string;
};

export type GalleryLinkURL = {
  type: `url`;
  url: string;
  title: string;
  description: string;
  imageURL: string;
};

type GalleryLink = GalleryLinkReport | GalleryLinkURL;

export interface Gallery extends Element {
  type: 'gallery';
  links?: GalleryLink[];

  // DEPRECATED: This is the old schema.
  ids?: string[];
}

export const isGallery = (node: Node): node is Gallery =>
  node.type === 'gallery';

export const GalleryElement: React.FC<
  RenderElementProps & {
    element: Gallery;
  }
> = ({attributes, element, children}) => {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const selected = useSelected();

  // For adding a report
  const [reportURL, setReportURL] = useState('');

  // For adding an arbitrary URL
  const [linkURL, setLinkURL] = useState(``);
  const [linkTitle, setLinkTitle] = useState(``);
  const [linkDescription, setLinkDescription] = useState(``);
  const [linkImageURL, setLinkImageURL] = useState(``);
  const isLinkInputValid =
    !!linkURL && !!linkTitle && !!linkDescription && !!linkImageURL;

  const links: GalleryLink[] = useMemo(() => {
    if (element.links != null) {
      return element.links;
    }
    return element.ids?.map(reportID => ({type: `report`, id: reportID})) ?? [];
  }, [element.ids, element.links]);

  const reportIDs = useMemo(
    () =>
      links
        .map(l => (l.type === `report` ? l.id : null))
        .filter(isNotNullOrUndefined),
    [links]
  );

  const {data} = useFeaturedReportsMetadataQuery({
    skip: reportIDs.length === 0,
    variables: {ids: reportIDs},
  });
  const showcasedReportByID: Map<string, ReportsTableReport> = useMemo(() => {
    const reports = (data?.views?.edges
      .map(edge => edge.node)
      .filter(isNotNullOrUndefined) ?? []) as unknown as ReportsTableReport[];
    return new Map(reports.map(r => [r.id, r]));
  }, [data]);

  const showcaseItems: ShowcaseItem[] = useMemo(() => {
    return links
      .map(l => (l.type === `report` ? showcasedReportByID.get(l.id) : l))
      .filter(isNotNullOrUndefined);
  }, [links, showcasedReportByID]);

  const addReportID = useCallback(() => {
    const reportID = parseNameAndIDFromURLSafe(reportURL)?.id;
    if (reportID == null) {
      toast(`Invalid report URL`);
      return;
    }
    if (reportIDs.includes(reportID)) {
      toast('This report already exists in gallery');
      return;
    }

    Transforms.setNodes(
      editor,
      {links: [...links, {type: `report`, id: reportID}], ids: undefined},
      {at: ReactEditor.findPath(editor, element)}
    );

    setReportURL('');
  }, [editor, element, links, reportIDs, reportURL]);

  const addLink = useCallback(() => {
    if (!isLinkInputValid) {
      toast(`Please fill out all of the required fields`);
      return;
    }

    Transforms.setNodes(
      editor,
      {
        links: [
          ...links,
          {
            type: `url`,
            url: linkURL,
            title: linkTitle,
            description: linkDescription,
            imageURL: linkImageURL,
          },
        ],
        ids: undefined,
      },
      {at: ReactEditor.findPath(editor, element)}
    );

    setLinkURL(``);
    setLinkTitle(``);
    setLinkDescription(``);
    setLinkImageURL(``);
  }, [
    editor,
    element,
    links,
    isLinkInputValid,
    linkURL,
    linkTitle,
    linkDescription,
    linkImageURL,
  ]);

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.Container selected={selected} contentEditable={false}>
        {!readOnly && (
          <S.EditContainer>
            <S.EditMessage>
              This section is only visible while editing.
            </S.EditMessage>
            <S.EditInputContainer>
              <S.EditTextField
                placeholder="Input your report URL"
                value={reportURL}
                onChange={e => setReportURL(e.target.value)}
              />
              <S.SubmitButton marginLeft onClick={addReportID}>
                Add Report
              </S.SubmitButton>
            </S.EditInputContainer>
            <S.EditMessage marginTop>OR</S.EditMessage>
            <S.EditInputContainer>
              <S.EditTextField
                placeholder="Input your link URL"
                value={linkURL}
                onChange={e => setLinkURL(e.target.value)}
              />
            </S.EditInputContainer>
            <S.EditInputContainer>
              <S.EditTextField
                placeholder="Input your link title"
                value={linkTitle}
                onChange={e => setLinkTitle(e.target.value)}
              />
            </S.EditInputContainer>
            <S.EditInputContainer>
              <S.EditTextField
                placeholder="Input your link description"
                value={linkDescription}
                onChange={e => setLinkDescription(e.target.value)}
              />
            </S.EditInputContainer>
            <S.EditInputContainer>
              <S.EditTextField
                placeholder="Input your link image URL"
                value={linkImageURL}
                onChange={e => setLinkImageURL(e.target.value)}
              />
            </S.EditInputContainer>
            <S.SubmitButton marginTop onClick={addLink}>
              Add Link
            </S.SubmitButton>
          </S.EditContainer>
        )}
        {showcaseItems.length > 0 && (
          <ReportShowcase items={showcaseItems} readOnly={readOnly} />
        )}
      </S.Container>
      {children}
    </BlockWrapper>
  );
};

export const withGallerys = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isGallery(element) ? true : isVoid(element);
  };
  return editor;
};
