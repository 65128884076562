import {captureError} from '@wandb/common/util/integrations';
import {OperationVariables, QueryHookOptions} from 'react-apollo';

import {propagateErrorsContext} from '../../util/errors';
import {usePollInterval} from '../polling/hooks';
import {useQuery as useApolloQuery} from './apolloWithAbort';

// Our version of useQuery. It returns a loading state that can
// be used in a type-guard to ensure we get non-null data values
// when loading is false.

export type UseQueryOptions<D = any, V = OperationVariables> = Omit<
  QueryHookOptions<D, V>,
  'pollInterval'
> & {
  enablePolling?: boolean;
  pollMultiplier?: number;
};

export function useQuery<D, V>(document: any, options: UseQueryOptions<D, V>) {
  const globalPollInterval = usePollInterval();

  let pollInterval = options.enablePolling
    ? globalPollInterval *
      (options.pollMultiplier != null ? options.pollMultiplier : 1)
    : 0;
  if (pollInterval === 0) {
    pollInterval = 365 * 24 * 60 * 60000;
  }

  const {loading, data, refetch, networkStatus, fetchMore, error} =
    useApolloQuery<D, V>(document, {
      ...options,
      context: {...propagateErrorsContext(), ...options.context},
      pollInterval,
    });
  // Network status states are defined here:
  // https://www.apollographql.com/docs/react/api/react-apollo/
  // If this is the very first time we're loading this query we return initialLoading = true.
  // Hack: If skip=true, just treat it like it's loading
  // console.log('NETWORK STATUS', networkStatus);
  if (networkStatus === 1 || options.skip) {
    return {initialLoading: true as true, loading: true as true};
  }
  // Otherwise, data will be valid because apollo will return cache results
  if (data == null) {
    try {
      captureError('invalid apollo response', 'useQuery', {
        extra: {error, loading, networkStatus},
      });
    } catch {
      // do nothing
    }
    throw new Error('invalid apollo response');
  }
  // If Apollo's loading value is true, we return loading: true. This happens when you change
  // passed in variables.
  if (loading) {
    return {initialLoading: false as false, loading: true as true, ...data};
  }
  return {
    initialLoading: false as false,
    loading: false as false,
    refetch,
    fetchMore,
    ...data,
  };
}
