import EditableField from '@wandb/common/components/EditableField';
import makeComp from '@wandb/common/util/profiler';
import React, {FC} from 'react';
import {Divider} from 'semantic-ui-react';

import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsLocalAccountDetails: FC = makeComp(
  () => {
    const {viewer} = useUserSettingsContext();
    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }
    return (
      <>
        <EditableField
          readOnly
          label="Username"
          value={viewer.username}
          placeholder="Username"
        />
        <Divider />
        <EditableField
          readOnly
          label="Email"
          value={viewer.email}
          placeholder="Email"
        />
      </>
    );
  },
  {id: 'UserSettingsLocalAccountDetails', memo: true}
);
