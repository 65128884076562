import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

import {
  LABEL_MODES,
  LABEL_VARIANTS,
  LabelColor,
  LabelMode,
  LabelVariant,
} from './Label.constants';

const LABEL_BACKGROUND_COLORS = {
  green: globals.GREEN_TRANSPARENT,
  teal: `${globals.TEAL_DARK}24`,
  sienna: globals.SIENNA_TRANSPARENT,
  gold: globals.GOLD_DARK_TRANSPARENT,
  red: globals.RED_LIGHT_TRANSPARENT,
  gray: `${globals.GRAY_500}24`,
};

const LABEL_LIGHT_COLORS = {
  green: globals.GREEN_LIGHT,
  teal: globals.TEAL_LIGHT,
  sienna: globals.SIENNA_LIGHT,
  gold: globals.GOLD_LIGHT,
  red: globals.RED_LIGHT,
  gray: globals.GRAY_450,
};

const LABEL_NIGHT_COLORS = {
  green: globals.GREEN_DARK,
  teal: globals.TEAL_DARK,
  sienna: globals.SIENNA_DARK,
  gold: globals.GOLD_DARK2,
  red: globals.RED_DARK,
  gray: globals.GRAY_600,
};

export const StyledLabel = styled.span<{
  color: LabelColor;
  mode: LabelMode;
  variant: LabelVariant;
}>`
  border-radius: ${({variant}) =>
    variant === LABEL_VARIANTS.PILL ? '16px' : '4px'};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px 5px 6px;
  gap: 4px;
  height: 24px;

  background-color: ${({color}) => LABEL_BACKGROUND_COLORS[color]};

  color: ${({color, mode}) =>
    mode === LABEL_MODES.NIGHT
      ? LABEL_NIGHT_COLORS[color]
      : LABEL_LIGHT_COLORS[color]};

  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;
