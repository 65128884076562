import React from 'react';
import {Table} from 'semantic-ui-react';

type FeatureTableProps = {
  info: string;
};

export const FeatureTable: React.FC<FeatureTableProps> = React.memo(
  ({info, children}) => {
    return (
      <>
        <p>{info}</p>
        <Table basic="very">
          <Table.Body>{children}</Table.Body>
        </Table>
      </>
    );
  }
);
