import styled from 'styled-components';

export const NodeHeader = styled.div<{type: string; selected: boolean}>`
  margin-top: -10px;
  margin-left: -10px;
  height: 23px;
  width: 150px;
  font-size: 12px;
  border-left: ${p => (p.selected ? '2px solid gold' : 'none')};
  border-right: ${p => (p.selected ? '2px solid gold' : 'none')};
  border-top: ${p => (p.selected ? '2px solid gold' : 'none')};
  background: ${p => (p.type === 'artifact' ? '#528aeb' : '#229487')};
  color: #d3eae7;
  text-align: left;
  padding-left: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
  cursor: pointer;
`;

export const NodeGroupText = styled.span`
  padding-left: 10px;
  color: white;
  text-overflow: ellipsis;
`;

export const NodeContent = styled.div<{type: string; selected: boolean}>`
  cursor: pointer;
  text-align: left;
  margin-left: -10px;
  background: ${p => (p.type === 'artifact' ? '#dceafc' : '#d3eae7')};
  border-left: ${p => (p.selected ? '3px solid gold' : 'none')};
  border-right: ${p => (p.selected ? '3px solid gold' : 'none')};
  border-bottom: ${p => (p.selected ? '3px solid gold' : 'none')};
  width: 150px;
  height: 34px;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;
