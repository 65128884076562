import {useQuery as useApolloQuery} from '@apollo/react-hooks';
import produce from 'immer';
import {useEffect, useMemo} from 'react';

export * from '@apollo/react-hooks';

// We wrap Apollo's `useQuery` in logic which aborts the query when the component unmounts.
// Apollo uses `fetch` under the hood, which is cancellable via `AbortController`.
// See https://developer.mozilla.org/en-US/docs/Web/API/AbortController.
export const useQuery: typeof useApolloQuery = (document, options = {}) => {
  // This `useMemo` is required so we only create one `AbortController` on mount.
  const controller = useMemo(() => new AbortController(), []);
  // This only runs once on component unmount.
  useEffect(() => () => controller.abort(), [controller]);

  // Preserve passed in options while populating `options.context.fetchOptions.signal`.
  const optionsWithSignal = produce(options, draft => {
    draft.context = draft.context ?? {};
    draft.context.fetchOptions = draft.context.fetchOptions ?? {};
    draft.context.fetchOptions.signal = controller.signal;
  });

  return useApolloQuery(document, optionsWithSignal);
};
