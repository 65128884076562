import {InlineCommentDetail} from '../../../components/Slate/plugins/inline-comments-common';
import * as Generated from '../../../generated/graphql';
import {ThunkResult} from '../../../types/redux';
import {parseUser} from '../api';
import * as DiscussionCommentActions from './actions';
import {Ref as DiscussionCommentRef} from './types';

export function updateReportDiscussionComment(
  discussionCommentRef: DiscussionCommentRef,
  body: string,
  viewID?: string,
  inlineCommentDetails?: InlineCommentDetail[],
  callback?: () => void
): ThunkResult<void> {
  return (dispatch, getState, client) => {
    const state = getState();
    const commentServerID =
      state.views.parts[discussionCommentRef.type][discussionCommentRef.id].id;

    // // for now, we can't add new inline text or panel reference to editing comments.
    // // replace the following code once inline text reference is enabled in editing comments
    // const details =
    //   inlineCommentDetails == null
    //     ? JSON.stringify(inlineCommentDetails)
    //     : undefined;
    const details = undefined;

    client
      .mutate<Generated.UpdateDiscussionCommentMutation>({
        mutation: Generated.UpdateDiscussionCommentDocument,
        variables: {
          viewID,
          discussionCommentID: commentServerID,
          body,
          inlineCommentDetails: details,
        },
        refetchQueries: [],
      })
      .then(response => {
        const updatedComment =
          response.data?.updateDiscussionComment?.discussionComment;
        if (updatedComment != null) {
          dispatch(
            DiscussionCommentActions.updateDiscussionComment(
              discussionCommentRef,
              {
                ...updatedComment,
                poster: parseUser(updatedComment.poster!),
                updatedAt: updatedComment.updatedAt ?? undefined,
              }
            )
          );
          if (callback != null) {
            callback();
          }
        }
      });
  };
}
