import {Autocomplete} from '@material-ui/lab';
import React, {FC, useMemo} from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlate,
} from 'slate-react';

import {useGallerySpec} from '../../../util/gallery';
import {InstrumentedLoader} from '../../utility/InstrumentedLoader';
import * as S from './cta-banner.styles';
import {BlockWrapper} from './drag-drop';

export type CTABanner = Element & {
  type: 'cta-banner';
  tagID?: string;
};

export const isCTABanner = (node: Node): node is CTABanner =>
  node.type === 'cta-banner';

export const CTABannerElement: FC<
  RenderElementProps & {
    element: CTABanner;
  }
> = ({attributes, element, children}) => {
  const {tagID} = element;
  const editor = useSlate();
  const readOnly = useReadOnly();
  const selected = useSelected();
  const {
    loading,
    gallerySpec: {tagsV2},
  } = useGallerySpec();

  const selectedTag = useMemo(
    () => tagsV2.find(t => t.id === tagID),
    [tagID, tagsV2]
  );

  const tagOptions = useMemo(() => tagsV2.filter(t => !!t.cta), [tagsV2]);

  if (loading) {
    return <InstrumentedLoader name="cta-banner-loader" />;
  }

  if (readOnly && !selectedTag?.cta) {
    return null;
  }

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.Container selected={selected} contentEditable={false}>
        {!readOnly && (
          <S.EditContainer>
            <S.EditMessage>
              This section is only visible while editing.
            </S.EditMessage>
            <S.EditAutocompleteContainer>
              <Autocomplete
                value={selectedTag}
                options={tagOptions}
                getOptionLabel={t => t.name}
                onChange={(e, v) => {
                  Transforms.setNodes(
                    editor,
                    {tagID: v?.id},
                    {at: ReactEditor.findPath(editor, element)}
                  );
                }}
                renderInput={params => (
                  <S.EditTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      placeholder: `Select tag`,
                    }}
                  />
                )}
              />
            </S.EditAutocompleteContainer>
          </S.EditContainer>
        )}
        {!!selectedTag?.cta && (
          <S.Banner dangerouslySetInnerHTML={{__html: selectedTag.cta}} />
        )}
      </S.Container>
      {children}
    </BlockWrapper>
  );
};

export const withCTABanner = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isCTABanner(element) ? true : isVoid(element);
  };
  return editor;
};
