import gql from 'graphql-tag';
import _ from 'lodash';
import React, {FunctionComponent, useMemo, useState} from 'react';
import {useQuery} from 'react-apollo';
import {
  Button,
  Form,
  Header,
  Input,
  Modal,
  SemanticCOLORS,
  SemanticSIZES,
} from 'semantic-ui-react';

import {useViewer} from '../state/viewer/hooks';
import {setViewingAs, unsetViewingAs, viewingAs} from '../util/admin';
import {slugFormat} from '../util/text';

interface CheckAvailabilityData {
  entity: {
    id: string;
    available: boolean;
    isTeam: boolean;
  };
}

interface CheckAvailabilityVars {
  name: string;
}

const CHECK_AVAILABILITY_QUERY = gql`
  query CheckAvailability($name: String) {
    entity(name: $name) {
      id
      available
      isTeam
    }
  }
`;

interface ImpersonateButtonProps {
  size?: SemanticSIZES;
  color?: SemanticCOLORS;
  readonly?: boolean;
  renderButton?(onClick: () => void): JSX.Element;
}

interface ImpersonateUserModalContentProps extends ImpersonateButtonProps {
  teamAdminUsername?: string;
  open?: boolean;
  trigger?: React.ReactNode;
  onClose?(): void;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  onCreate(): void;
}

export const ImpersonateUserModal: FunctionComponent<ImpersonateUserModalContentProps> =
  props => {
    const viewer = useViewer();

    const {
      loading: loadingAvailability,
      data: availabilityData,
      refetch: refetchAvailability,
    } = useQuery<CheckAvailabilityData, CheckAvailabilityVars>(
      CHECK_AVAILABILITY_QUERY
    );

    const [username, setUsername] = useState('');

    const usernameIsValid = (uname: string) =>
      3 <= uname.length && uname.length <= 30;

    const userExists = (uname: string) =>
      usernameIsValid(uname) &&
      availabilityData &&
      availabilityData.entity &&
      !availabilityData.entity.available &&
      !availabilityData.entity.isTeam;

    const isSubmitDisabled = !userExists(username);

    const usernameIcon = useMemo(
      () => ({
        name: (isSubmitDisabled ? 'stop' : 'check') + ' circle outline',
        color: isSubmitDisabled ? 'red' : 'green',
        size: 'small',
      }),
      [isSubmitDisabled]
    );
    const loading = viewer == null;

    return (
      <Modal
        open={props.open}
        onClose={props.onClose}
        onClick={props.onClick}
        trigger={props.trigger}>
        <Header>
          Please type the username of the user you would like to impersonate.
        </Header>
        <Modal.Content>
          <Form size="small">
            <Form.Field inline loading={loadingAvailability}>
              <label>Username</label>
              <Input
                data-test="username-input"
                name="defaultEntity"
                placeholder={viewer?.username}
                loading={loading}
                value={username}
                onChange={(e, {value}) => {
                  const newUsername = slugFormat(value);
                  setUsername(newUsername);
                  _.debounce(() => {
                    if (usernameIsValid(newUsername)) {
                      refetchAvailability({name: newUsername});
                    }
                  }, 100)();
                }}
                icon={usernameIcon}
              />
            </Form.Field>
            {username && !userExists(username) && (
              <div>User does not exist</div>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {props.onClose && <Button content="Cancel" onClick={props.onClose} />}
          <Button
            color="red"
            data-test="stop-impersonate-button"
            disabled={viewingAs() === ''}
            onClick={() => {
              unsetViewingAs();
              window.location.reload();
            }}>
            Stop Impersonating
          </Button>
          <Button
            data-test="impersonate-button"
            primary
            disabled={isSubmitDisabled || loading}
            onClick={() => {
              setViewingAs(username);
              props.onCreate();
              window.location.reload();
            }}>
            Impersonate ➞
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
