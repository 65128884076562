import type {ListType, OutputNode} from '@wandb/cg';
import {
  constFunction,
  constString,
  opArray,
  opArtifactType,
  opArtifactTypeName,
  opDict,
  opEntityArtifactPortfolios,
  opEntityIsTeam,
  opEntityName,
  opEntityProjects,
  opFilter,
  opFlatten,
  opMap,
  opProjectName,
  opRootEntity,
  opRootUser,
  opRootViewer,
  opStringEqual,
  opUserEntities,
} from '@wandb/cg';
import {Match} from '@wandb/common/types/base';
import {TargetBlank} from '@wandb/common/util/links';
import {useNodeValue, useRefreshAllNodes} from '@wandb/weave-ui/cgreact';
import {useConfig} from '@wandb/weave-ui/components/Panel2/panel';
import {History as RHistory} from 'history';
import React, {useEffect} from 'react';
import {Accordion, Button, Form, Modal} from 'semantic-ui-react';

import {PORTFOLIO_COLLECTION_DISPLAY_NAME} from '../components/artifactConstants';
import {Highlight, Python} from '../components/Code';
import {
  ConfigType as PanelArtifactRegistryConfigType,
  PanelArtifactRegistry,
} from '../components/panels.domain/artifact/PanelArtifactRegistry';
import * as Generated from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {setDocumentTitle} from '../util/document';
import {
  ModelLimitReachedMessage,
  useEntityHasReachedModelLimit,
} from '../util/modelRegistryRestrictions';
import {
  trackCreatePortfolioButtonClicked,
  trackPageViewed,
} from '../util/navigation';
import {capitalizeFirstLetter} from '../util/string';
import * as urls from '../util/urls';
import * as S from './EntityRegistryPage.styles';

type EntityRegistryPageProps = {match: Match; history: RHistory};
export type RegistryName = 'model';
const EntityRegistryPage: React.FC<EntityRegistryPageProps> = props => {
  // for page view tracking
  useEffect(() => {
    trackPageViewed('Model registry');
  }, []);

  const viewer = useViewer();
  const viewerEntityName = viewer?.username;
  const {registryName, entityName} = props.match.params;
  const {
    selectionPath,
    selectedCollectionView,
    selectedMembershipIdentifier,
    selectedMembershipTab,
  } = urls.parseEntityRegistrySearch(props.history.location.search);

  React.useEffect(() => {
    if (registryName !== 'model') {
      props.history.push(urls.objectRegistry('model'));
    }
  }, [registryName, props.history]);

  if (registryName !== 'model') {
    console.warn('Registry name has to be of type model');
    return <></>;
  }

  // Cast registryName now that we've checked that the value is
  // one of the RegistryName values
  const validatedRegistryName = registryName as RegistryName;

  // Triggered when an anon user (not logged in) is viewing the url: wandb.ai/registry/model.
  if (viewerEntityName == null && entityName == null) {
    return <></>;
  }

  return (
    <CurrentViewerRegistryContainer
      history={props.history}
      registryName={validatedRegistryName}
      registryEntityName={entityName}
      viewerEntityName={viewerEntityName}
      selectionPath={selectionPath}
      selectedCollectionView={selectedCollectionView}
      selectedMembershipIdentifier={selectedMembershipIdentifier}
      selectedMembershipTab={selectedMembershipTab}
    />
  );
};

export function getArtifactPortfoliosNode(
  artifactType: string,
  registryEntityName?: string
): OutputNode<ListType<'any'>> {
  const entitiesNode =
    registryEntityName !== undefined
      ? opRootEntity({
          entityName: constString(registryEntityName),
        })
      : opUserEntities({
          user: opRootViewer({}),
        });
  const portfoliosNode = opFlatten({
    arr: opEntityArtifactPortfolios({entity: entitiesNode}) as any,
  });
  const filteredPortfoliosNode = opFilter({
    arr: portfoliosNode,
    filterFn: constFunction({row: 'artifact'}, ({row}) => {
      return opStringEqual({
        lhs: opArtifactTypeName({
          artifactType: opArtifactType({artifact: row}),
        }),
        rhs: constString(artifactType),
      });
    }),
  });

  return filteredPortfoliosNode as OutputNode<ListType<'any'>>;
}

const CurrentViewerRegistryContainer: React.FC<{
  history: RHistory;
  registryName: RegistryName;
  registryEntityName?: string;
  viewerEntityName?: string;
  selectionPath?: {
    entityName: string;
    projectName: string;
    collectionName: string;
  };
  selectedCollectionView?: string;
  selectedMembershipIdentifier?: string;
  selectedMembershipTab?: string;
}> = props => {
  // For now, this is a 1-1 mapping. I expect us to expand this in future.
  const artifactType = props.registryName;
  const registryTitle =
    props.registryName[0].toUpperCase() + props.registryName.slice(1);
  const portfoliosNode = React.useMemo(
    () => getArtifactPortfoliosNode(artifactType, props.registryEntityName),
    [artifactType, props.registryEntityName]
  );

  const [context, updateContext] = React.useState({});
  const [showNewPortfolioModal, setShowNewPortfolioModal] =
    React.useState(false);

  const [artifactRegistryConfig, updateArtifactRegistryConfig] =
    useConfig<PanelArtifactRegistryConfigType>({
      collectionPath: props.selectionPath
        ? {...props.selectionPath, artifactTypeName: artifactType}
        : undefined,
      selectedArtifactConfig: {
        selectedCollectionView: props.selectedCollectionView as any,
        tabConfigs: {
          overview: {
            selectedTab: props.selectedMembershipTab as any,
          },
        },
        // The following field is only applicable when selectedCollectionView is 'membership'
        // selectedMembershipIdentifier determines the membership identifier selected - null should default to latest membership version
        selectedMembershipIdentifier: props.selectedMembershipIdentifier,
      },
      searchFilter: undefined,
    });

  useEffect(() => {
    if (props.selectionPath != null) {
      setDocumentTitle(props.selectionPath.collectionName);
    }
  }, [props.selectionPath]);

  const updateConfig = React.useCallback(
    partialUpdate => {
      const currentURL = window.location.pathname + window.location.search;
      const newConfig = {...artifactRegistryConfig, ...partialUpdate};
      const newURL = urls.objectRegistry(
        props.registryName,
        {
          entityName: newConfig.collectionPath?.entityName,
          projectName: newConfig.collectionPath?.projectName,
          collectionName: newConfig.collectionPath?.collectionName,
        },
        props.registryEntityName,
        newConfig.selectedArtifactConfig?.selectedCollectionView,
        newConfig.selectedArtifactConfig?.selectedMembershipIdentifier,
        newConfig.selectedArtifactConfig?.tabConfigs?.overview?.selectedTab
      );

      if (newURL !== currentURL && newConfig.collectionPath != null) {
        props.history.replace(newURL);
      }

      updateArtifactRegistryConfig(partialUpdate);
    },
    [
      artifactRegistryConfig,
      props.history,
      props.registryName,
      props.registryEntityName,
      updateArtifactRegistryConfig,
    ]
  );

  const logArtifactCodeSnippet = [
    {
      key: 'artifacts-code-snippet',
      title: {
        content: <span>Code Snippet</span>,
        style: {background: 'white', textTransform: 'none'},
      },
      content: {
        content: (
          <div>
            <Python style={{fontSize: '16px'}}>
              <Highlight>
                {`art = wandb.Artifact("my-object-detector", type="model")
art.add_file("saved_model_weights.pt")
wandb.log_artifact(art)`}
              </Highlight>
            </Python>
          </div>
        ),
      },
    },
  ];

  const emptyState = (
    <S.EmptyRegistryContainer>
      <S.TitleHeader>Welcome to the Model Registry</S.TitleHeader>
      <S.TitleDescription>
        Use the Model Registry to organize your best models and manage the
        lifecycle of moving models from training all the way to production.
      </S.TitleDescription>
      <S.Quickstart>
        <S.SectionHeader>Quickstart</S.SectionHeader>
        <S.QuickstartListContainer>
          <ol>
            <li>
              On the top right of this page, click the button to create a
              Registered Model.
            </li>
            <li>
              <p>
                In your script, log a model as an{' '}
                <TargetBlank href={'https://docs.wandb.ai/guides/artifacts'}>
                  artifact version
                </TargetBlank>
                .
              </p>
              <Accordion
                style={{
                  marginTop: '10px',
                  textTransform: 'none',
                  background: 'white',
                }}
                styled
                as={Form.Field}
                panels={logArtifactCodeSnippet}
              />
            </li>
            <li>
              From the Artifact page, link the artifact version to the registry.
            </li>
          </ol>
        </S.QuickstartListContainer>
        <S.SectionHeader>Try it yourself</S.SectionHeader>
        <S.SectionText>
          Watch this 1-minute video and follow along{' '}
          <TargetBlank href={'http://wandb.me/model-registry-quickstart'}>
            with this notebook &rarr;
          </TargetBlank>
        </S.SectionText>
        <S.IframeContainer>
          <iframe
            style={{borderRadius: '15px'}}
            title={'link-into-registry-quickstart'}
            width={'500'}
            height={'281'}
            allow={'fullscreen'}
            src={'https://www.youtube.com/embed/jy9Pk9riwZI'}></iframe>
        </S.IframeContainer>
      </S.Quickstart>
    </S.EmptyRegistryContainer>
  );

  return (
    <S.GeneralPageContainer>
      <S.DesktopLayout>
        <PanelArtifactRegistry
          input={portfoliosNode as any}
          config={artifactRegistryConfig}
          updateConfig={updateConfig}
          emptyState={emptyState}
          onCreateButtonClick={() => {
            setShowNewPortfolioModal(true);
            trackCreatePortfolioButtonClicked('Registry homepage');
          }}
          registryEntityName={props.registryEntityName}
          registryName={props.registryName}
          context={context}
          updateContext={updateContext}
        />
      </S.DesktopLayout>
      <CreatePortfolioArtifactModal
        title={registryTitle}
        artifactTypeName={artifactType}
        open={showNewPortfolioModal}
        registryEntityName={props.registryEntityName}
        onClose={() => {
          setShowNewPortfolioModal(false);
        }}
        afterCreateComplete={path => {
          updateConfig({
            collectionPath: path,
            selectedArtifactConfig: undefined,
          });
          setShowNewPortfolioModal(false);
        }}
      />
    </S.GeneralPageContainer>
  );
};

const CreatePortfolioArtifactModal: React.FC<{
  title: string;
  open: boolean;
  artifactTypeName: string;
  registryEntityName?: string;
  onClose: () => void;
  afterCreateComplete: (collectionPath: {
    entityName: string;
    projectName: string;
    collectionName: string;
    artifactTypeName: string;
  }) => void;
}> = ({
  title,
  open,
  onClose,
  afterCreateComplete,
  artifactTypeName,
  registryEntityName,
}) => {
  const defaultProjectName = `${artifactTypeName}-registry`;
  const viewer = useViewer();
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [entityName, setEntityName] = React.useState<string | undefined>(
    registryEntityName
  );
  const [projectName, setProjectName] = React.useState<string | undefined>(
    defaultProjectName
  );
  const possibleEntitiesNode = React.useMemo(() => {
    const username = viewer?.username;
    if (username == null) {
      return opArray({} as any);
    }
    return opMap({
      arr: opUserEntities({
        user: opRootUser({userName: constString(username)}),
      }),
      mapFn: constFunction({row: 'entity'}, ({row}) => {
        return opDict({
          entityName: opEntityName({entity: row}),
          isTeam: opEntityIsTeam({entity: row}),
          projectNames: opProjectName({
            project: opEntityProjects({entity: row}),
          }),
        } as any);
      }),
    });
  }, [viewer]);
  const possibleEntities = useNodeValue(possibleEntitiesNode);
  const teamOptions = React.useMemo(() => {
    return (possibleEntities.result ?? [])
      .sort((a: any, b: any) => {
        return a.isTeam - b.isTeam;
      })
      .map((team: any) => {
        if (team.isTeam) {
          return {
            value: team.entityName,
            text: team.entityName,
          };
        } else {
          return {
            value: team.entityName,
            text: `${team.entityName} (me)`,
          };
        }
      });
  }, [possibleEntities.result]);

  useEffect(() => {
    if (entityName == null) {
      // viewer.entity is always set to the default entity
      setEntityName(viewer?.entity);
    }
    if (projectName == null) {
      setProjectName(defaultProjectName);
    }
  }, [entityName, projectName, defaultProjectName, viewer]);

  const projectNames = React.useMemo(() => {
    return (
      possibleEntities.result?.find((e: any) => e.entityName === entityName)
        ?.projectNames ?? []
    );
  }, [possibleEntities.result, entityName]);

  // Commenting out project dropdown in case we want to re-add due to customer feedback

  //   const projectOptions = React.useMemo(() => {
  //   const res = projectNames.map((pName: string) => {
  //     return {
  //       value: pName,
  //       text: pName + (pName === defaultProjectName ? ' (default)' : ''),
  //     };
  //   });
  //   if (projectNames.indexOf(defaultProjectName) === -1) {
  //     res.unshift({
  //       value: defaultProjectName,
  //       text: defaultProjectName + ' (will be created)',
  //     });
  //   }
  //
  //   return res;
  // }, [defaultProjectName, projectNames]);

  const [createArtifactPortfolio] =
    Generated.useCreateArtifactPortfolioMutation();
  const [upsertProject] = Generated.useUpsertModelMutation();
  const [createArtifactType] = Generated.useCreateArtifactTypeMutation();
  const refreshNodes = useRefreshAllNodes();
  const [isCreating, setIsCreating] = React.useState(false);
  const selectedProjectExists = React.useMemo(() => {
    return projectNames.indexOf(projectName) > -1;
  }, [projectNames, projectName]);
  const onCreate = React.useCallback(async () => {
    if (name != null && projectName != null && entityName != null) {
      setIsCreating(true);
      if (!selectedProjectExists) {
        await upsertProject({
          variables: {
            entityName,
            name: projectName,
          },
        });
      }
      const artifactTypeResult = await createArtifactType({
        variables: {
          entityName,
          projectName,
          name: artifactTypeName,
        },
      });
      const typeId =
        artifactTypeResult?.data?.createArtifactType?.artifactType?.id;
      if (typeId != null) {
        await createArtifactPortfolio({
          variables: {
            name,
            entityName,
            artifactTypeID: typeId,
            projectName,
          },
        });
        await refreshNodes();
        setName(undefined);
        setIsCreating(false);
        afterCreateComplete({
          entityName,
          projectName,
          collectionName: name,
          artifactTypeName,
        });
        // window.location.reload();
      }
    }
  }, [
    name,
    projectName,
    upsertProject,
    entityName,
    createArtifactType,
    artifactTypeName,
    createArtifactPortfolio,
    refreshNodes,
    afterCreateComplete,
    selectedProjectExists,
  ]);

  const modelLimitReached = useEntityHasReachedModelLimit(entityName!);
  const showModelLimitReached =
    modelLimitReached.result && artifactTypeName === 'model';

  return (
    <Modal open={open} onClose={onClose} onOpen={() => {}} size="tiny">
      <Modal.Header>
        <S.ModalHeaderWrapper>
          Create{' '}
          {`${PORTFOLIO_COLLECTION_DISPLAY_NAME} ${capitalizeFirstLetter(
            artifactTypeName
          )}`}
        </S.ModalHeaderWrapper>
      </Modal.Header>
      <S.ModalContentWrapper>
        <Modal.Content>
          <Form>
            <Form.Field>Owning Entity</Form.Field>
            <Form.Dropdown
              options={teamOptions}
              value={entityName}
              fluid
              button
              scrolling
              search
              selectOnBlur={false}
              onChange={(e, {value}) => {
                setEntityName('' + value);
                setProjectName(defaultProjectName);
              }}
            />
            {showModelLimitReached ? (
              <ModelLimitReachedMessage entityName={entityName!} />
            ) : (
              <>
                {/*Commenting out project dropdown in case we want to re-add due to customer feedback*/}
                {/*<Form.Field>Owning Project</Form.Field>*/}
                {/*<Form.Dropdown*/}
                {/*  options={projectOptions}*/}
                {/*  value={projectName}*/}
                {/*  fluid*/}
                {/*  button*/}
                {/*  scrolling*/}
                {/*  search*/}
                {/*  selectOnBlur={false}*/}
                {/*  onChange={(e, {value}) => setProjectName('' + value)}*/}
                {/*/>*/}
                <Form.Field>{title} Name</Form.Field>
                <Form.Input
                  data-test="name-input"
                  onChange={(e, {value}) => setName(value)}
                />
              </>
            )}
            <S.ButtonWrapper>
              <Button
                type="button"
                content="Cancel"
                onClick={() => {
                  setEntityName(viewer?.entity);
                  setName(undefined);
                  setProjectName(undefined);
                  onClose();
                }}
              />
              {!showModelLimitReached && (
                <Button
                  data-test="create-button"
                  color="blue"
                  disabled={
                    name == null ||
                    name.length < 3 ||
                    projectName == null ||
                    isCreating ||
                    modelLimitReached.loading
                  }
                  onClick={onCreate}>
                  {isCreating ? 'Creating...' : 'Create'}
                </Button>
              )}
            </S.ButtonWrapper>
          </Form>
        </Modal.Content>
      </S.ModalContentWrapper>
    </Modal>
  );
};

export default EntityRegistryPage;
