import {
  createdRoutedPerformanceServer,
  createLocalServer,
  createRemoteServer,
  createServerWithShadow,
  LocalServer,
  OpStore,
} from '@wandb/cg';
import {LOG_DEBUG_MESSAGES} from '@wandb/cg';
import {BasicClient, CachedClient, Client} from '@wandb/cg/client';
import {bindClientToGlobalThis} from '@wandb/cg/main';
import {Server} from '@wandb/cg/server';
import {
  backendWeaveExecutionUrl,
  weaveShadowModeEnabled,
} from '@wandb/common/config';
import {GlobalCGReactTracker} from '@wandb/weave-ui/cgreact';
import {useLoadWeaveObjects} from '@wandb/weave-ui/components/Panel2/weaveBackend';
import {ComputeGraphContextProviderFromClient} from '@wandb/weave-ui/contextProviders';
import {mapKeys, omit} from 'lodash';
import React, {useMemo} from 'react';

import * as ServerApiProd from './cgservers/serverApiProd';
import {Variant, VariantWrapper} from './components/ExperimentVariant';
import {auth} from './setup';
import {useApolloClient} from './state/hooks';
import {useViewer} from './state/viewer/hooks';
import {viewerUsingAdminPrivileges} from './util/admin';
import {
  useBetaFeatureWeave1,
  useBetaFeatureWeavePythonPerformance,
} from './util/useBetaFeature';

const useClient = (server: Server): Client => {
  return useMemo(() => {
    let client: Client = new BasicClient(server);
    if (!(server instanceof LocalServer)) {
      client = new CachedClient(client);
    }
    bindClientToGlobalThis(client);
    return client;
  }, [server]);
};

const useLocalServer = () => {
  const apolloClient = useApolloClient();
  return useMemo(() => {
    const serverApi = new ServerApiProd.Client(apolloClient);
    return createLocalServer(serverApi);
  }, [apolloClient]);
};

const useRemoteServer = (skip: boolean, opStore?: OpStore) => {
  const {loading, remoteOpStore} = useLoadWeaveObjects(skip || !!opStore);
  return useMemo(() => {
    const finalStore: OpStore | null = opStore || remoteOpStore;

    if (!finalStore || (!opStore && loading)) {
      return null;
    }

    return createRemoteServer(
      backendWeaveExecutionUrl(),
      auth.ensureCurrentIdToken.bind(auth),
      finalStore,
      viewerUsingAdminPrivileges(),
      false,
      true
    );
  }, [loading, remoteOpStore, opStore]);
};

const useRemoteShadowServer = (skip: boolean, opStore: OpStore) => {
  const remoteServer = useMemo(() => {
    return createRemoteServer(
      backendWeaveExecutionUrl(),
      auth.ensureCurrentIdToken.bind(auth),
      opStore,
      viewerUsingAdminPrivileges(),
      true,
      false
    );
  }, [opStore]);
  return skip ? null : remoteServer;
};

const useRoutedPerformanceServer = (
  localServer: Server | null,
  remoteServer: Server | null
) => {
  return useMemo(() => {
    if (remoteServer == null || localServer == null) {
      return null;
    }
    return createdRoutedPerformanceServer(localServer, remoteServer);
  }, [localServer, remoteServer]);
};

const useServerWithShadow = (
  mainServer: Server,
  shadowServer: Server | null
) => {
  return useMemo(() => {
    if (!shadowServer) {
      return null;
    }
    return createServerWithShadow(mainServer, shadowServer);
  }, [mainServer, shadowServer]);
};

const ComputeGraphContextProviderInner: React.FC<{allowShadow: boolean}> = ({
  children,
  allowShadow,
}) => {
  const shadowEnabled = !allowShadow ? false : weaveShadowModeEnabled();
  const weavePythonPerformanceEnabled = useBetaFeatureWeavePythonPerformance();
  const weave1Enabled = useBetaFeatureWeave1();
  const hasRemote = !!backendWeaveExecutionUrl();
  const localServer = useLocalServer();
  const remoteShadowServer = useRemoteShadowServer(
    !hasRemote || !shadowEnabled,
    localServer.opStore
  );
  const performanceServer = useRemoteServer(
    !hasRemote || !weavePythonPerformanceEnabled
  );

  const remoteExecutionServer = useRemoteServer(
    !hasRemote || !weave1Enabled,
    localServer.opStore
  );

  const localServerWithShadow = useServerWithShadow(
    localServer,
    remoteShadowServer
  );
  const routedPerformanceServerWithShadow = useRoutedPerformanceServer(
    localServerWithShadow,
    performanceServer
  );
  const routedPerformanceServerNoShadow = useRoutedPerformanceServer(
    localServer,
    performanceServer
  );

  let server: Server | null = null;

  if (weave1Enabled) {
    server = remoteExecutionServer;
  } else {
    if (shadowEnabled) {
      if (weavePythonPerformanceEnabled && routedPerformanceServerWithShadow) {
        server = routedPerformanceServerWithShadow;
      } else if (!weavePythonPerformanceEnabled && localServerWithShadow) {
        server = localServerWithShadow;
      }
    } else {
      if (weavePythonPerformanceEnabled && routedPerformanceServerNoShadow) {
        server = routedPerformanceServerNoShadow;
      }
    }
  }

  if (server == null) {
    server = localServer;
  }

  const client = useClient(server);
  return (
    <ComputeGraphContextProviderFromClient
      client={client}
      children={children}
    />
  );
};

export const ComputeGraphContextProvider: React.FC = React.memo(
  ({children}) => {
    const viewer = useViewer();
    const experimentId =
      'RXhwZXJpbWVudDpkZjNjZDViZS01YTY5LTQ4YjMtYTZlMi0wMTk1ZTg1ODlhYzE=';
    return (
      <VariantWrapper
        // feature flag for weave shadow service routing. requests for N% (configurable) of users to
        // local engine are routed to shadow service
        graphqlExperimentId={experimentId}
        observationalUnitId={viewer?.id ?? ''}>
        <Variant bucket={0} control>
          <ComputeGraphContextProviderInner
            children={children}
            allowShadow={false}
          />
        </Variant>
        <Variant bucket={1}>
          <ComputeGraphContextProviderInner
            children={children}
            allowShadow={true}
          />
        </Variant>
      </VariantWrapper>
    );
  }
);

//// Logging Execution Metrics ////

const globalCGReactTrackerInterval = 30000;
let hasStarted = false;

const sumDict = (dict: {[key: string]: any}): number => {
  return Object.values(dict).reduce(
    (acc, val) => acc + (typeof val === 'number' ? val : sumDict(val)),
    0
  );
};

const flattenDict = (dict: {[key: string]: any}): {[key: string]: number} => {
  let flatDict: {[key: string]: number} = {};
  for (const key in dict) {
    if (typeof dict[key] === 'number') {
      flatDict[key] = dict[key];
    } else {
      flatDict = {
        ...flatDict,
        ...mapKeys(flattenDict(dict[key]), (v, k) => `${key}.${k}`),
      };
    }
  }
  return flatDict;
};

const logGlobalCGReactTracker = () => {
  hasStarted = true;
  const summary = GlobalCGReactTracker.summary();
  // Ignore duration (since it is always non-negative) and _0_useNodeValue since
  // this has super high volume, but high cache rates. This will reduce the number
  // of log events produced, but not the accuracy of the metrics.
  const sumOfValues = sumDict(omit(summary, ['__duration', '_0_useNodeValue']));
  if (sumOfValues > 0) {
    if (LOG_DEBUG_MESSAGES) {
      console.log('cg-react-tracker', flattenDict(summary));
    }
    window.analytics?.track('cg-react-tracker', summary);
    GlobalCGReactTracker.reset();
  }
  setTimeout(logGlobalCGReactTracker, globalCGReactTrackerInterval);
};

if (!hasStarted) {
  setTimeout(logGlobalCGReactTracker, globalCGReactTrackerInterval);
}
