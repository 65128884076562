import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React from 'react';
import {Checkbox, Dropdown, Popup} from 'semantic-ui-react';

import {
  displayKey,
  EMPTY_FILTER_VALUE,
  FilterKey,
  FilterMeta,
  getDefaultAbsoluteDateValue,
  getKeyAllowedOps,
  IndividualFilter,
  IndividualOp,
} from '../../util/filters';
import * as Run from '../../util/runs';
import {FilterKeySelectorCreatorProps} from './FilterKeySelector';
import {FilterValueSelectorCreatorProps} from './FilterValueSelector';
import {FilterTagOpSelector} from './FilterValueSelector';
const OperatorUserLabels = new Map<IndividualOp, string>([
  ['=', '='],
  ['!=', '\u2260'],
  ['>=', '\u2265'],
  ['<=', '\u2264'],
  ['IN', 'IN'],
  ['NIN', 'NOT IN'],
  ['WITHINSECONDS', 'within last'],
]);

interface FilterEditableProps {
  filter: IndividualFilter;

  justAdded: boolean;

  setFilter(filter: IndividualFilter): void;
  setFilterOp(op: IndividualOp): void;
  setFilterValue(value: Run.Value): void;
  setFilterMultiValue(value: Run.Value[]): void;
  setFilterMeta(meta: FilterMeta): void;
  setFilterDisabled(disabled: boolean): void;
  deleteFilter(): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(
    props: FilterValueSelectorCreatorProps<FilterKey>
  ): React.ReactNode;
}

export const FilterEditable = ({
  filter,
  justAdded,
  setFilter,
  setFilterOp,
  setFilterDisabled,
  deleteFilter,
  filterKeySelector,
  filterValueSelector,
}: FilterEditableProps) => {
  const operatorDisplayList = getKeyAllowedOps(filter.key).map(op => {
    const opLabel = OperatorUserLabels.get(op);
    if (!opLabel) {
      throw new Error('OperatorUserLabels missing op: ' + op);
    }
    const isSymbol = opLabel.length === 1;
    return {
      text: isSymbol ? <span className="symbol">{opLabel}</span> : opLabel,
      value: op,
    };
  });

  return (
    <div data-test="filter-item" className="filter-list__item">
      <Checkbox
        data-test="filter-toggle"
        className="filter-list__checked"
        checked={!filter.disabled}
        onChange={() => {
          // Only toggle if all fields are set.
          const newActive = !filter.disabled;
          setFilterDisabled(newActive);
        }}
      />
      {filterKeySelector({
        focusOnMount: justAdded,
        keyValue: displayKey(filter.key),
        storedKey: displayKey(filter.key),
        onValidSelection: keyString => {
          const filterKey = Run.keyFromString(keyString);
          if (filterKey != null) {
            if (filterKey.section === 'run' && filterKey.name === 'createdAt') {
              setFilter({
                key: filterKey,
                op: '<=',
                value: getDefaultAbsoluteDateValue(),
                disabled: false,
              });
            } else if (filterKey.section === 'tags') {
              setFilter({
                key: filterKey,
                op: '=',
                value: true,
                disabled: true,
              });
            } else {
              setFilter({
                key: filterKey,
                op: '=',
                value: EMPTY_FILTER_VALUE,
                disabled: true,
              });
            }
          }
        },
      })}
      {filter.key.section === 'tags' ? (
        <FilterTagOpSelector keys={[]} filter={filter} setFilter={setFilter} />
      ) : (
        <Dropdown
          className="filter-dropdown filter-list__operation"
          data-test="filter-operation"
          options={operatorDisplayList}
          placeholder={'operator'}
          selectOnNavigation={false}
          value={filter.op}
          onChange={(e, {value}) => {
            setFilterOp(value as IndividualOp);
          }}
        />
      )}
      {filterValueSelector({
        filter,
        setFilter,
      })}
      <Popup
        inverted
        size="mini"
        content="Remove filter"
        position="right center"
        on="hover"
        trigger={
          <div className="filter-list__delete filter-list__action">
            <LegacyWBIcon
              data-test="filter-delete"
              name="close"
              onClick={deleteFilter}
              title="remove filter"
            />
          </div>
        }
      />
    </div>
  );
};

export default React.memo(FilterEditable);
