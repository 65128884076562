export const clear = (storage: Storage | null = window.localStorage) => {
  try {
    // @ts-ignore
    storage.clear();
  } catch (e) {
    console.error(
      'Error attempting to clear localStorage. It may not be available in this environment.'
    );
  }
};

export const getItem = (
  key: string,
  storage: Storage | null = window.localStorage
) => {
  try {
    // @ts-ignore
    return storage.getItem(key);
  } catch (e) {
    console.error(
      `Error attempting to retrieve item: "${key}" from localStorage. LocalStorage may not be available in this environment.`
    );
    return null;
  }
};

export const removeItem = (
  key: string,
  storage: Storage | null = window.localStorage
) => {
  try {
    // @ts-ignore
    storage.removeItem(key);
  } catch (e) {
    console.error(
      `Error attempting to remove item: "${key}" from localStorage. LocalStorage may not be available in this environment.`
    );
  }
};

export const setItem = (
  key: string,
  value: string,
  storage: Storage | null = window.localStorage
) => {
  try {
    // @ts-ignore
    storage.setItem(key, value);
  } catch (e) {
    console.error(
      `Error attempting to set item: "${key}" from localStorage. LocalStorage may not be available in this environment.`
    );
  }
};

/**
 * Safe wrappers around local storage
 * LocalStorage will fail in certain environments (like private mode) and those
 * failed operations should no-op instead of throwing errors.
 *
 * These functions work indentically to the standard localStorage API.
 * ```
 * import localStorage from './localStorage';
 * localStorage.setItem('foo', 'bar');
 * localStorage.getItem('foo');
 * ```
 */
export default {
  clear,
  getItem,
  removeItem,
  setItem,
};
