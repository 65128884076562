import SliderInput from '@wandb/common/components/elements/SliderInput';
import React from 'react';

import {
  InspectorPropertyLabel,
  InspectorPropertyWrapper,
} from '../Inspector.styles';
import {PropertyEditorComponent} from './property-editors';

// TODO: add mixed state
const SliderPropertyEditor: PropertyEditorComponent<'slider'> = props => {
  const displayedVal = props.values.length === 1 ? props.values[0] : undefined;
  return (
    <InspectorPropertyWrapper>
      <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
      <SliderInput
        min={props.min}
        max={props.max}
        step={props.step}
        value={displayedVal || 0}
        onChange={val => {
          props.save(val);
        }}></SliderInput>
    </InspectorPropertyWrapper>
  );
};

export default SliderPropertyEditor;
