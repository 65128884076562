import {WBSelect} from '@wandb/ui';
import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectWrapper = styled.div`
  display: flex;
  margin-bottom: 2px;
`;

export const Select = styled(WBSelect)`
  width: 0;
  flex-grow: 1;
`;

export const NewSpecButton = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 8px;
  margin-right: -6px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 2px;
  &:hover {
    border: 1px solid #ddd;
  }
`;

export const EditButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  i.icon {
    margin: 0;
  }
`;

export const TextAreaWrapper = styled.div<{editing: boolean}>`
  position: relative;
  ${EditButton} {
    opacity: 0;
    transition: opacity 0.15s;
    ${props =>
      props.editing &&
      css`
        opacity: 1;
        background: rgb(70, 160, 244);
      `}
  }
  &:hover {
    ${EditButton} {
      opacity: 1;
    }
  }
`;

export const TextArea = styled.textarea`
  background: #f6f6f6;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Inconsolata;
  width: 100%;
  font-size: 13px;
  padding: 4px;
`;

export const EditorWrapper = styled.div`
  width: 600px;
`;
