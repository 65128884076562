import * as globals from '@wandb/common/css/globals.styles';
import {Tab} from 'semantic-ui-react';
import styled from 'styled-components';

export const TabWrapperColumnSectionHeader = styled.div`
  color: #555;
  font-size: 1.2em;
  flex: 0 0 auto;
  padding-bottom: 0.5em;
`;
export const TabWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1 1 50px;
  overflow: hidden;
  padding-top: 1em;
  padding-left: calc(${globals.spu} * 2);
  background-color: #fff;
`;
export const TabWrapperColumn = styled(TabWrapper)`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

export const VersionTabs = styled(Tab)`
  display: flex;
  flex-direction: column;
  border-top: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1 50px;
  .ui.tabular.menu {
    background-color: #fff;
    flex: 0 0 39px;
    padding: 0px;
    .item:last-child {
      margin-right: 2em;
    }
    .item:first-child {
      margin-left: 2em;
    }
    border-top: none;
    font-size: 16px;
    min-height: fit-content;
    max-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    > .item {
      position: relative;
      opacity: 0.95;
      transition: none;
      background-color: none;
      border: none;
      .icon {
        margin-top: 4px;
      }
      /* If there's an ellispis menu, make it fit nicely */
      > .dropdown.icon > i.icon {
        width: auto;
        margin-left: 24px;
        margin-right: 0;
      }
      border-bottom: 2px solid transparent;
    }
    > .item,
    > .active.item {
      margin-bottom: 0;
      /* color: @gray700; */
      cursor: pointer;
      font-weight: normal;
      padding: 12px 16px 11px;
      margin-right: 10px;
      background: none;
      .label {
        /* color: @gray500; */
        background-color: white;
        font-size: 18px;
        margin-left: 8px;
        padding: 1px 3px;
      }
    }
    > .active.item {
      color: black;
      border-bottom: 2px solid #2e78c7;
    }
  }
`;
