import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React from 'react';
import {Button} from 'semantic-ui-react';

import * as S from './CreateSweepButton.styles';

type Props = {
  onClick: () => void;
};

export const CreateSweepButton: React.FC<Props> = ({onClick}) => {
  return (
    <Button
      size="tiny"
      className="wb-ic-sweep action-button--static"
      onClick={onClick}>
      <S.ButtonWrapper>
        <LegacyWBIcon name="sweep" size="large" />
        <div>Create Sweep</div>
      </S.ButtonWrapper>
    </Button>
  );
};
