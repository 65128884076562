import {toast} from '@wandb/common/components/elements/Toast';
import {captureError} from '@wandb/common/util/integrations';
import {TargetBlank} from '@wandb/common/util/links';
import {ApolloQueryResult} from 'apollo-client';
import * as _ from 'lodash';
import {editor, IDisposable, languages, Position} from 'monaco-editor';
import React, {useMemo, useState} from 'react';
import {Button, DropdownItemProps, Modal} from 'semantic-ui-react';

import {
  DefaultResourceConfig,
  useCreateDefaultResourceConfigMutation,
  useEntityArtifactsQuery,
  useUpdateDefaultResourceConfigMutation,
} from '../../../generated/graphql';
import {propagateErrorsContext} from '../../../util/errors';
import {InstrumentedLoader as Loader} from '../../utility/InstrumentedLoader';
import {
  LaunchDropdown,
  renderLaunchDropdown,
  RESOURCE_OPTIONS,
} from '../EditLaunchConfigModal';
import * as S from '../EditLaunchConfigModal.styles';
import {
  ArtifactInfos,
  autoPopulateConfigResources,
  cleanConfig,
  getCompletionValues,
  REQUIRED_STR,
  RESOURCE_CONFIGS,
  ResourceConfigKeys,
  swapArtifactObjects,
  useNamesAndAliasCollector,
} from '../LaunchConfigEditor';
import {EditResourceConfigMode} from './defaultResourceConfigQuery';

const RESOURCE_ARG_STR = `{
  "resource_args": {}
}`;
interface EditResourceConfigModalProps {
  entityName: string;
  projectName?: string;
  mode: EditResourceConfigMode;
  config?: DefaultResourceConfig;
  refetch?: () => Promise<ApolloQueryResult<any>>;
  onClose?: () => void;
}

const EditResourceConfigModal: React.FC<EditResourceConfigModalProps> = ({
  entityName,
  projectName,
  mode,
  config,
  refetch,
  onClose,
}) => {
  const entityArtifactsQuery = useEntityArtifactsQuery({
    variables: {entityName},
  });
  const [createDefaultResourceConfig] =
    useCreateDefaultResourceConfigMutation();
  const [updateDefaultResourceConfig] =
    useUpdateDefaultResourceConfigMutation();
  const [defaultConfigJson, setDefaultConfigJson] =
    useState<string>(RESOURCE_ARG_STR);

  // Allow modal controls to be passed in
  const [closed, setClosed] = useState(onClose == null);

  const closeModal = (setClosedBool: boolean = false) => {
    if (onClose) {
      onClose();
    } else {
      setClosed(setClosedBool);
    }
  };

  const [saving, setSaving] = useState(false);
  const [resource, setResource] = useState<ResourceConfigKeys>(
    config
      ? (config.resource as ResourceConfigKeys)
      : (RESOURCE_OPTIONS[0].value as ResourceConfigKeys)
  );
  const namesAndAliases: {[key: string]: ArtifactInfos} =
    useNamesAndAliasCollector(entityName, entityArtifactsQuery);
  const disposableRef = React.useRef<IDisposable | null>(null);
  const [editorError, setEditorError] = useState('');

  const setDefaultConfigJsonWithError = (jsonString: string) => {
    if (jsonString.includes(REQUIRED_STR)) {
      setEditorError('Config has unconfigured required sections.');
    } else if (!jsonString.includes('"resource_args"')) {
      setEditorError(
        'Config must have exactly one top level resource_args key.'
      );
    } else {
      setEditorError('');
    }
    setDefaultConfigJson(jsonString);
  };

  // if we get passed a config, use that
  useMemo(() => {
    if (config?.config) {
      setDefaultConfigJsonWithError(JSON.stringify(config.config, null, 2));
      setResource(config?.resource as ResourceConfigKeys);
    }
  }, [config]);

  const changeResource = (newResource: string) => {
    if (!Object.keys(RESOURCE_CONFIGS).includes(newResource)) {
      console.error('Invalid resource selection');
      return;
    } else if (mode !== 'CREATE') {
      console.log("Can't change resource in EDIT or VIEW mode");
      return;
    }
    setResource(newResource as ResourceConfigKeys);
    const resourceConfigString =
      autoPopulateConfigResources(newResource as ResourceConfigKeys) ||
      RESOURCE_ARG_STR;

    if (defaultConfigJson !== null) {
      setDefaultConfigJsonWithError(resourceConfigString);
    }
  };

  const resourceDropDown: LaunchDropdown = {
    key: 'resourceDropdown',
    label: 'Resource:',
    options: RESOURCE_OPTIONS as DropdownItemProps[],
    value: resource,
    setValue: changeResource,
  };

  const saveEditedConfig = async () => {
    window.analytics?.track(
      'In launch default resource config editor, clicked save',
      {
        entity: entityName,
      }
    );
    if (saving) {
      return;
    }
    setSaving(true);

    let parsedConfig;
    try {
      parsedConfig = JSON.parse(defaultConfigJson);
    } catch {
      toast('Problem parsing config. Please check your JSON and try again.');
      setSaving(false);
      return;
    }

    const filteredConfig = _.omit(cleanConfig(parsedConfig), '_wandb');
    const swappedConfig = swapArtifactObjects(filteredConfig);
    setDefaultConfigJson(JSON.stringify(swappedConfig, null, 2));

    try {
      if (mode === 'CREATE') {
        if (!entityName) {
          throw new Error('Entity name must be passed to editor when creating');
        }
        await createDefaultResourceConfig({
          variables: {
            entityName,
            resource,
            config: defaultConfigJson,
          },
          context: propagateErrorsContext(),
        });
      } else if (config?.id) {
        await updateDefaultResourceConfig({
          variables: {
            defaultResourceConfigID: config?.id,
            resource,
            config: defaultConfigJson,
          },
          context: propagateErrorsContext(),
        });
      }
    } catch (err) {
      captureError(err, `EditResourceConfigModal`);
      toast(
        `We could not save the resource config. Please contact support@wandb.com if this error persists.`
      );
      setSaving(false);
      return;
    }
    setDefaultConfigJson(RESOURCE_ARG_STR);
    toast(`Saved default resource config`);
    await refetch?.();

    setSaving(false);
    closeModal(true);
  };

  const renderButton = (text: string) => {
    return (
      <S.ButtonWrapper>
        <Button
          size="tiny"
          primary
          disabled={!closed}
          onClick={() => closeModal(false)}>
          {text}
        </Button>
      </S.ButtonWrapper>
    );
  };

  return (
    <>
      {mode === 'CREATE' ? (
        renderButton(`Create Resource`)
      ) : mode === 'EDIT' ? (
        renderButton(`Edit`)
      ) : mode === 'VIEW' && config ? (
        renderButton(`View Config`)
      ) : (
        <></>
      )}
      {!closed && (
        <Modal
          open={true}
          className="edit-resource-config-modal"
          onClose={() => closeModal(true)}>
          {mode === 'CREATE' ? (
            <Modal.Header>Create Resource Configuration</Modal.Header>
          ) : mode === 'EDIT' ? (
            <Modal.Header>Edit Resource Configuration</Modal.Header>
          ) : mode === 'VIEW' ? (
            <Modal.Header>View Resource Configuration</Modal.Header>
          ) : (
            <Modal.Header>Default Resource Configuration</Modal.Header>
          )}
          <Modal.Content>
            <S.ErrorLabel>{editorError}</S.ErrorLabel>
            <S.EditorWrapper>
              {defaultConfigJson === null && config !== null ? (
                <Loader name="edit-resource-config" />
              ) : (
                <S.MonacoEditor
                  value={defaultConfigJson}
                  onChange={setDefaultConfigJsonWithError}
                  height={400}
                  options={{readOnly: mode === 'VIEW'}}
                  language="json"
                  theme="vs-dark"
                  onMount={(monacoEditor: editor.IStandaloneCodeEditor) => {
                    const disposable = languages.registerCompletionItemProvider(
                      'json',
                      {
                        provideCompletionItems: (
                          model: editor.ITextModel,
                          position: Position
                        ) => {
                          const items = getCompletionValues(
                            namesAndAliases,
                            model,
                            position
                          );
                          return {suggestions: items};
                        },
                      }
                    );
                    disposableRef.current = disposable;
                  }}
                />
              )}
            </S.EditorWrapper>
          </Modal.Content>
          <Modal.Actions>
            <S.Dropdowns>
              {renderLaunchDropdown(resourceDropDown, mode !== 'CREATE')}
            </S.Dropdowns>
            <S.ActionsBottom>
              <span>
                Check the{' '}
                <TargetBlank href="https://docs.wandb.ai/guides/launch">
                  launch documentation
                </TargetBlank>{' '}
                to see what options are available for{' '}
                {RESOURCE_OPTIONS.find(r => r.value === resource)?.text}.
              </span>
              <S.Buttons>
                <Button
                  size="tiny"
                  disabled={saving}
                  onClick={() => {
                    window.analytics?.track(
                      'In resource config editor, clicked cancel',
                      {
                        sourceEntity: entityName,
                        sourceProject: projectName,
                      }
                    );
                    closeModal(true);
                  }}>
                  {mode === 'VIEW' ? <p>Back</p> : <p>Cancel</p>}
                </Button>
                <Button
                  size="tiny"
                  primary
                  disabled={saving || mode === 'VIEW' || editorError !== ''}
                  loading={saving}
                  onClick={saveEditedConfig}>
                  Save
                </Button>
              </S.Buttons>
            </S.ActionsBottom>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default EditResourceConfigModal;
