import {gray100} from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import classNames from 'classnames';
import React from 'react';
import Dropzone, {DropzoneRef} from 'react-dropzone';

import {AvatarPlaceholder} from '../pages/Team/TeamPage.styles';
import {
  entityImageUpload,
  profileImageUpload,
  publicImageUpload,
  squareCrop,
  useUploadMarkdownImage,
  viewImageUpload,
} from '../util/images';
import * as S from './EditableImage.styles';

export type UpdateOverlaySize = `small` | `medium`;

interface EditableImageProps {
  photoUrl?: string;
  readOnly?: boolean;
  label?: boolean;
  imageSize?: number;
  displaySize?: number;
  viewID?: string;
  profileViewID?: string;
  entityName?: string;
  profile?: boolean;
  updateOverlay?: JSX.Element | string;
  updateOverlaySize?: UpdateOverlaySize;
  className?: string;
  placeholderText?: string;
  save(value: string): void;
}

interface EditableImageState {
  uploading: boolean;
  image: string | ArrayBuffer | null;
}

class EditableImageComp extends React.Component<
  EditableImageProps & ReturnType<typeof useEditableImageProps>,
  EditableImageState
> {
  state: EditableImageState = {
    uploading: false,
    image: null,
  };
  dropzoneRef = React.createRef<DropzoneRef>();
  imageRef = React.createRef<HTMLDivElement>();

  upload(image: Blob) {
    if (this.imageRef.current) {
      const fr = new FileReader();
      fr.onload = () => this.setState({image: fr.result});
      fr.readAsDataURL(image);
    }
    if (this.props.viewID) {
      return this.uploadView(image);
    } else if (this.props.profileViewID) {
      return this.uploadProfileView(image);
    } else if (this.props.entityName) {
      return this.uploadEntity(this.props.entityName, image);
    } else if (this.props.profile) {
      return this.uploadProfile(image);
    } else {
      return this.uploadPublic(image);
    }
  }

  async uploadProfile(image: Blob) {
    try {
      const updatedUrl = await profileImageUpload(image);
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save profile image: ' + error);
    }
  }

  async uploadEntity(entityName: string, image: Blob) {
    try {
      const updatedUrl = await entityImageUpload(entityName, image);
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save profile image: ' + error);
    }
  }

  async uploadView(image: Blob) {
    try {
      const name = 'preview.png';
      const updatedUrl = await viewImageUpload(
        image,
        name,
        this.props.viewID!,
        'image/png',
        true
      );
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save report image: ' + error);
    }
  }

  async uploadProfileView(image: Blob) {
    try {
      const updatedUrls = await this.props.uploadImages([image as File]);
      if (!Array.isArray(updatedUrls)) {
        throw new Error(`uploadImages returned non-array: ${updatedUrls}`);
      }
      const updatedUrl = updatedUrls[0];
      if (typeof updatedUrl !== `string`) {
        throw new Error(`uploadImages returned non-string: ${updatedUrl}`);
      }
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save report image: ' + error);
    }
  }

  async uploadPublic(image: Blob) {
    try {
      const updatedUrl = await publicImageUpload(image);
      this.props.save(updatedUrl);
    } catch (error) {
      alert('Unable to save image: ' + error);
    }
  }

  onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length === 0) {
      return;
    }
    const file = acceptedFiles[0];

    if (file.type === 'image/gif') {
      this.upload(file);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const origImg = new Image();
      if (reader.result) {
        origImg.src = reader.result.toString();
        origImg.onload = () => {
          squareCrop(origImg, this.props.imageSize ?? 300).then(blob =>
            this.upload(blob)
          );
        };
      }
    };
    reader.readAsDataURL(file);
  }

  render() {
    const {
      className,
      label = true,
      readOnly,
      displaySize = 300,
      photoUrl,
      updateOverlay,
      updateOverlaySize = `medium`,
      placeholderText,
    } = this.props;
    return (
      <div
        className={classNames('editable-field editable-image', className)}
        onClick={e => e.preventDefault()}>
        {label && <label>Avatar</label>}
        <Dropzone
          accept={['image/*']}
          disabled={readOnly}
          ref={this.dropzoneRef}
          onDrop={acceptedFiles => {
            this.onDrop(acceptedFiles);
          }}>
          {({getRootProps, getInputProps}) => {
            const picUrl = this.state.image || photoUrl;
            return (
              <div
                {...getRootProps()}
                className="field-content"
                ref={this.imageRef}
                style={{
                  width: displaySize,
                  height: displaySize,
                  background: !picUrl ? gray100 : `url("${picUrl}") no-repeat`,
                }}>
                {!readOnly &&
                  (!picUrl ? (
                    <>
                      <AvatarPlaceholder>
                        <WBIcon name="camera" />
                        <div>{placeholderText ?? 'Add an image'}</div>
                      </AvatarPlaceholder>
                    </>
                  ) : (
                    <S.UpdateButton
                      className="update-button"
                      fontSize={updateOverlaySize}>
                      <S.EditIcon />
                      {updateOverlay ?? `Update photo`}
                    </S.UpdateButton>
                  ))}
                <input {...getInputProps()} />
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

function useEditableImageProps(props: EditableImageProps) {
  const {uploadImages} = useUploadMarkdownImage(
    props.profileViewID ?? null,
    true
  );
  return {uploadImages};
}

const EditableImage: React.FC<EditableImageProps> = React.memo(props => {
  return <EditableImageComp {...props} {...useEditableImageProps(props)} />;
});

export default EditableImage;
