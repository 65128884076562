import {createEditor, Editor, Node} from 'slate';
import {withHistory} from 'slate-history';
import {ReactEditor, withReact} from 'slate-react';

import {withBetterVoidHandling} from '../plugins/better-void-handling';
import {withBlockQuotes} from '../plugins/block-quotes';
import {withCallout} from '../plugins/callout-blocks';
import {withCaptivate} from '../plugins/captivate';
import {withCode} from '../plugins/code-blocks';
import {withCraiyon} from '../plugins/craiyon';
import {withCTABanner} from '../plugins/cta-banner';
import {withCTAButton} from '../plugins/cta-button';
import {withDeleteLineBackwards} from '../plugins/delete-line-backwards';
import {withEmojis} from '../plugins/emojis';
import {withGallerys} from '../plugins/gallery';
import {withHeadings} from '../plugins/headings';
import {withHorizontalRules} from '../plugins/horizontal-rules';
import {withImages} from '../plugins/images';
import {isInlineCommentMark} from '../plugins/inline-comment';
import {withLatex} from '../plugins/latex';
import {withLinks} from '../plugins/links';
import {withLists} from '../plugins/lists';
import {withMarkdownBlocks} from '../plugins/markdown-blocks';
import {withMarkdownShortcuts} from '../plugins/markdown-shortcuts';
import {withPanelGrids} from '../plugins/panel-grid';
import {isParagraph} from '../plugins/ParagraphTypes';
import {withPersistentBlankLine} from '../plugins/persistent-blank-lines';
import {withReplit} from '../plugins/replit';
import {withSoundCloud} from '../plugins/soundcloud';
import {withSpotify} from '../plugins/spotify';
import {withTableOfContents} from '../plugins/table-of-contents';
import {withTables} from '../plugins/tables';
import {withTwitter} from '../plugins/twitter';
import {withVideos} from '../plugins/videos';
import {withWeavePanels, withWeavePythonPanels} from '../plugins/weave-panels';
import {TextWithFormatting, WBSlateElementType} from './types';

type DOMNodeType = globalThis.Node;

const DOMNode = globalThis.Node;

function isDOMNode(value: any): value is DOMNodeType {
  return value instanceof DOMNode;
}

export function hasEditableTarget(
  editor: ReactEditor,
  target: EventTarget | null
): target is DOMNodeType {
  return (
    isDOMNode(target) &&
    ReactEditor.hasDOMNode(editor, target, {editable: true})
  );
}

type WBSlateEditorOptions = {
  enableReplit?: boolean;
  disablePersistentTopLine?: boolean;
};

export function createWBSlateEditor({
  enableReplit,
  disablePersistentTopLine,
}: WBSlateEditorOptions = {}): Editor {
  return withCTABanner(
    withCTAButton(
      withGallerys(
        withBlockQuotes(
          withHeadings(
            withReplit(enableReplit)(
              withCaptivate(
                withSoundCloud(
                  withSpotify(
                    withTwitter(
                      withCraiyon(
                        withVideos(
                          withEmojis(
                            withLatex(
                              withTableOfContents(
                                withHorizontalRules(
                                  withCode(
                                    withCallout(
                                      withTables(
                                        withLists(
                                          withDeleteLineBackwards(
                                            withImages(
                                              withWeavePanels(
                                                withWeavePythonPanels(
                                                  withPanelGrids(
                                                    withPersistentBlankLine({
                                                      disablePersistentTopLine,
                                                    })(
                                                      withMarkdownBlocks(
                                                        withLinks(
                                                          withMarkdownShortcuts(
                                                            withBetterVoidHandling(
                                                              withReact(
                                                                withHistory(
                                                                  createEditor()
                                                                )
                                                              )
                                                            )
                                                          )
                                                        )
                                                      )
                                                    )
                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
}

export function isTextWithComments(node: Node): boolean {
  return Object.keys(node).some(isInlineCommentMark);
}

export function isTextWithFormatting(node: Node): node is TextWithFormatting {
  return typeof (node as any).text === 'string';
}

export function isEmptySlateValue(value: WBSlateElementType[]): boolean {
  return value.every(isEmptyParagraph);
}

function isEmptyParagraph(element: WBSlateElementType): boolean {
  return isParagraph(element) && element.children.every(isEmptyTextNode);
}

function isEmptyTextNode(node: Node): boolean {
  return (
    `text` in node && typeof node.text === `string` && node.text.trim() === ``
  );
}
