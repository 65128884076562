import React from 'react';
import {Route} from 'react-router';

import NoMatch from '../components/NoMatch';
import {isInIframe} from '../setup';

export type RouteWithLayoutProps = {
  component: React.ComponentType<any>;
  error?: any;
  allowIframes?: boolean;
  additionalProps?: {[prop: string]: any};
  [prop: string]: any;
};

// adds the Nav and Footer before and after the route component
const RouteWithLayout: React.FC<RouteWithLayoutProps> = ({
  component: Component,
  error,
  allowIframes,
  additionalProps = {},
  ...rest
}) => {
  const preventIframe = isInIframe() && !allowIframes;
  return (
    <Route
      {...rest}
      render={routeProps => {
        return preventIframe ? (
          <NoMatch unrecoverable />
        ) : (
          <Component error={error} {...routeProps} {...additionalProps} />
        );
      }}
    />
  );
};

export default RouteWithLayout;
