import {GradientStop} from '../../components/GradientPicker';
import {PlotFontSizeOrAuto} from '../../util/plotHelpers';
import * as Run from '../../util/runs';

export type ScatterPlotConfig = {
  xAxis?: string;
  yAxis?: string;
  zAxis?: string;
  chartTitle?: string;
  showLinearRegression?: boolean;
  showMaxYAxisLine?: boolean;
  showMinYAxisLine?: boolean;
  showAvgYAxisLine?: boolean;
  yAxisLineSmoothingWeight?: number;
  color?: string;
  minColor?: string;
  maxColor?: string;
  xAxisLogScale?: boolean;
  yAxisLogScale?: boolean;
  zAxisLogScale?: boolean;
  xAxisMin?: number;
  xAxisMax?: number;
  yAxisMin?: number;
  yAxisMax?: number;
  zAxisMin?: number;
  zAxisMax?: number;
  legendFields?: string[];
  customGradient?: GradientStop[];
  fontSize?: PlotFontSizeOrAuto;
};

export // default title cases
// "y and z v. x" if z-axis exists
// "y v. x" if no z-axis
// considered "z v. x" if no y-axis, but this doesn't seem to be possible from the UI anymore
function defaultTitle(config: ScatterPlotConfig): string {
  const {xAxis, yAxis, zAxis} = config;
  const xDisplayName = getAxisDisplayName(xAxis);
  const yDisplayName = getAxisDisplayName(yAxis);
  const zDisplayName = getAxisDisplayName(zAxis);
  if (!xDisplayName || !yDisplayName) {
    return '';
  }
  return `${yDisplayName}${
    zDisplayName ? ` and ${zDisplayName}` : ``
  } v. ${xDisplayName}`;
}

export function getAxisDisplayName(axis: string | undefined): string {
  if (!axis) {
    return '';
  }
  return lowercaseSpecialColumnsInTitle(Run.keyStringDisplayName(axis));
}

export function lowercaseSpecialColumnsInTitle(columnName: string): string {
  if (columnName === 'Created') {
    return 'created';
  }
  if (columnName === 'End Time') {
    return 'end time';
  }
  return columnName;
}

export function getTitleFromConfig(config: ScatterPlotConfig): string {
  return config.chartTitle || defaultTitle(config);
}
