// Panels specifically for Weave Python, these are not
// production ready, which is why we separate this file

import {Spec as RunSpec} from './PanelRun';
import {Spec as PanelAnyObj} from './PanelAnyObj';
import {Spec as PanelGpt3Model} from './PanelGpt3Model';
import {Spec as PanelStringEditor} from './PanelStringEditor';
import {Spec as PanelTextEditor} from './PanelTextEditor';
import {Spec as PanelPanel} from './PanelPanel';
import {Spec as PanelContainer} from './PanelContainer';
import {Spec as SliderSpec} from './PanelSlider';
import {Spec as Slider2Spec} from './PanelSlider2';
import {Spec as PanelRef} from './PanelRef';
import {Spec as PanelObjectPicker} from './PanelObjectPicker';
import {Spec as PanelExpressionEditor} from './PanelExpressionEditor';
import {Spec as PanelNewImage} from './PanelNewImage';
import {Spec as PanelStringHistogramWeave} from './PanelStringHistogramWeave';
import {Spec as PanelLabeledItem} from './PanelLabeledItem';
import {Spec as PanelEach} from './PanelEach';
import {Spec as PanelGroup} from './PanelGroup';
import {Spec as PanelGroup2} from './PanelGroup2';
import {Spec as PanelCard} from './PanelCard';
import {Spec as PanelWeaveLink} from './PanelWeaveLink';
import {Spec as PanelPlotly} from './PanelPlotly';
import {Spec as PanelColor} from './PanelColor';

export const weavePythonPanelSpecs = () => {
  return [
    RunSpec,
    PanelAnyObj,
    PanelGpt3Model,
    PanelStringEditor,
    PanelTextEditor,
    PanelPanel,
    PanelContainer,
    SliderSpec,
    Slider2Spec,
    PanelRef,
    PanelObjectPicker,
    PanelExpressionEditor,
    PanelNewImage,
    PanelStringHistogramWeave,
    PanelLabeledItem,
    PanelEach,
    PanelGroup,
    PanelGroup2,
    PanelCard,
    PanelWeaveLink,
    PanelPlotly,
    PanelColor,
  ];
};
