import {
  Node,
  opArtifactVersionUsedBy,
  opRunConfig,
  OutputNode,
} from '@wandb/cg';
import {
  constFunction,
  constNone,
  constString,
  opArtifactAliasAlias,
  opArtifactId,
  opArtifactIsPortfolio,
  opArtifactLastMembership,
  opArtifactMembershipArtifactAliases,
  opArtifactMembershipArtifactVersion,
  opArtifactMembershipForAlias,
  opArtifactMemberships,
  opArtifactMembershipVersionIndex,
  opArtifactName,
  opArtifactProject,
  opArtifactType,
  opArtifactTypeName,
  opArtifactVersionId,
  opArtifactVersions,
  opCount,
  opDict,
  opEntityName,
  opMap,
  opProjectEntity,
  opProjectName,
  opProjectRunQueues,
  opRunQueueId,
} from '@wandb/cg';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {WBPopupMenuTrigger} from '@wandb/ui';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import _ from 'lodash';
import * as React from 'react';
import {Dropdown} from 'semantic-ui-react';

import {EditLaunchConfigModalControlled} from '../../../../../components/Launch/EditLaunchConfigModal';
import {Job} from '../../../../../components/Launch/LaunchConfigEditor';
import {isVersionAlias} from '../../../../../util/artifacts';
import NoMatch from '../../../../NoMatch';
import {DeleteMembershipModal} from '../../../artifactMembership/PanelArtifactMembershipOverview/DeleteMembershipModal';
import {PanelJobOverview} from './PanelJobOverview';
import * as S from './style';

const inputType = 'artifact' as const;

const getAliasFromVersionName = (versionName: string) => {
  return versionName.split(':')[1];
};

const getCollectionFromVersionName = (versionName: string) => {
  return versionName.split(':')[0];
};

export type ConfigType = {
  selectedJobVersion?: string;
};

const PanelJob: React.FC<Panel2.PanelProps<typeof inputType, ConfigType>> =
  props => {
    const panelArtifactValue = useNodeValue(props.input);
    if (panelArtifactValue.loading) {
      return <WandbLoader />;
    } else if (panelArtifactValue.result == null) {
      return <NoMatch />;
    }
    return <PJInner {...props} />;
  };

const PJInner: React.FC<Panel2.PanelProps<typeof inputType, ConfigType>> =
  props => {
    const {updateConfig, config} = props;
    const {selectedJobVersion} = config ?? {};
    const artifactCollectionNode = props.input;
    const [isLaunchConfigModalOpen, setIsLaunchConfigModalOpen] =
      React.useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);

    const {result} = useNodeValue(
      React.useMemo(
        () => dataDictNode(artifactCollectionNode),
        [artifactCollectionNode]
      )
    );
    const selectedCollection =
      selectedJobVersion != null
        ? getCollectionFromVersionName(selectedJobVersion)
        : null;
    const selectedMembershipIdentifier =
      selectedJobVersion != null
        ? getAliasFromVersionName(selectedJobVersion)
        : undefined;

    const targetIdentifier = React.useMemo(
      () =>
        getDefaultIdentifier(
          selectedMembershipIdentifier,
          result?.lastMembershipVersionIndex
        ),
      [result, selectedMembershipIdentifier]
    );

    const setTargetIdentifier = React.useCallback(
      (newIdentifier?: string) => {
        let identifier = newIdentifier;
        if (identifier == null) {
          identifier = targetIdentifier;
        }

        updateConfig({
          selectedJobVersion: `${selectedCollection}:${identifier}`,
        });
      },
      [updateConfig, targetIdentifier, selectedCollection]
    );

    const artifactMembershipNode = React.useMemo(() => {
      if (targetIdentifier == null) {
        return constNone();
      }
      return opArtifactMembershipForAlias({
        artifact: props.input,
        aliasName: constString(targetIdentifier),
      }) as OutputNode<'artifactMembership'>;
    }, [props.input, targetIdentifier]);

    const currentVersion = React.useMemo(() => {
      return opDict({
        index: opArtifactMembershipVersionIndex({
          artifactMembership: artifactMembershipNode,
        }),
        id: opArtifactVersionId({
          artifactVersion: opArtifactMembershipArtifactVersion({
            artifactMembership: artifactMembershipNode,
          }),
        }),
        usedByConfig: opRunConfig({
          run: opArtifactVersionUsedBy({
            artifactVersion: opArtifactMembershipArtifactVersion({
              artifactMembership: artifactMembershipNode,
            }),
          }),
        }),
      } as any) as OutputNode<{
        type: 'typedDict';
        propertyTypes: {
          index: 'number';
          id: 'string';
          usedByConfig: {
            type: 'list';
            objectType: 'any';
          };
        };
      }>;
    }, [artifactMembershipNode]);

    const artifactMembershipNodeValue = useNodeValue(artifactMembershipNode);
    const currentVersionValue = useNodeValue(currentVersion);

    const isPortfolio = result?.collectionIsPortfolio;

    const dropdownOptions = React.useMemo(() => {
      if (result == null) {
        return [];
      }
      const alias2version: {[key: string]: string} = {};
      const versions = result.collectionMemberships.map(m => {
        const v = `v${m.versionIndex}`;
        m.aliases.filter(a => a !== v).forEach(a => (alias2version[a] = v));
        return {
          value: v,
          text: v,
        };
      });

      const aliasOptions = Object.keys(alias2version).map(a => {
        return {value: `${a}`, text: a};
      });

      const options: Array<{
        value?: string;
        text?: string;
        header?: boolean;
        headerContent?: string;
        divider?: boolean;
      }> = [
        {header: true, headerContent: 'Aliases'},
        {divider: true},
        ...aliasOptions,
        {header: true, headerContent: 'Versions'},
        {divider: true},
        ...versions,
      ];

      return options;
    }, [result]);

    const hasAliases = React.useMemo(() => {
      if (result == null) {
        return false;
      }
      for (const m of result.collectionMemberships) {
        if (m.aliases.length > 0) {
          return true;
        }
      }
      return false;
    }, [result]);

    const dropdownText = React.useMemo(() => {
      if (currentVersionValue.loading) {
        return '';
      }
      if (isVersionAlias(selectedMembershipIdentifier)) {
        return `Version ${currentVersionValue.result.index}`;
      } else {
        const identifier = selectedMembershipIdentifier ?? targetIdentifier;
        return `${identifier}`;
      }
    }, [currentVersionValue, selectedMembershipIdentifier, targetIdentifier]);

    const job: Job | undefined = React.useMemo(() => {
      if (result == null) {
        return;
      }
      const rawRunConfig =
        currentVersionValue.result?.usedByConfig?.length > 0
          ? currentVersionValue.result?.usedByConfig[0]
          : {};
      const fullJobName: string = `${result.entityName}/${result.projectName}/${config?.selectedJobVersion}`;
      return {
        fullJobName,
        createdBy: {
          config: JSON.stringify(_.omit(rawRunConfig, ['_wandb', 'wandb'])),
        },
      };
    }, [currentVersionValue, result, config?.selectedJobVersion]);

    if (artifactMembershipNodeValue.loading) {
      return <WandbLoader name="artifact-collection-membership" />;
    }

    if (result == null) {
      return null;
    }

    return (
      <S.JobOverviewMain>
        {artifactMembershipNodeValue.result == null ? (
          <S.BlankPageWrapper>
            No versions logged for this job.
          </S.BlankPageWrapper>
        ) : (
          <>
            <S.PanelJobHeaderContent>
              <S.HeaderContainer>
                <S.TitleAndMenusContainer>
                  <S.PanelArtifactHeaderTitle>
                    {result.collectionName}
                    <S.CollectionDropdown
                      data-test="artifact-collection-dropdown"
                      button
                      compact
                      scrolling
                      text={dropdownText}>
                      <Dropdown.Menu>
                        {dropdownOptions.map((option, i) => {
                          if (option.header === true) {
                            return (
                              <Dropdown.Header
                                key={`header-${i}`}
                                content={option.headerContent}
                              />
                            );
                          } else if (option.divider === true) {
                            return <Dropdown.Divider key={`divider-${i}`} />;
                          } else {
                            return (
                              <Dropdown.Item
                                key={`item-${i}`}
                                text={option.text}
                                value={option.value}
                                onClick={(d, {value}) => {
                                  setTargetIdentifier('' + value);
                                }}
                              />
                            );
                          }
                        })}
                      </Dropdown.Menu>
                    </S.CollectionDropdown>
                  </S.PanelArtifactHeaderTitle>
                  <S.TriggerContainer>
                    <WBPopupMenuTrigger
                      data-test="membership-options-popup"
                      options={[
                        {
                          value: 'delete',
                          name: isPortfolio ? 'Unlink' : 'Delete',
                          icon: 'delete',
                        },
                      ]}
                      onSelect={value => {
                        if (value === 'delete') {
                          setDeleteModalOpen(true);
                        }
                      }}>
                      {({anchorRef, setOpen}) => (
                        <S.VersionMenuButton
                          data-test="membership-options-trigger"
                          name="overflow"
                          title="menu"
                          ref={anchorRef}
                          onClick={() => setOpen(true)}
                        />
                      )}
                    </WBPopupMenuTrigger>
                  </S.TriggerContainer>
                  {!currentVersionValue.loading && (
                    <DeleteMembershipModal
                      open={isDeleteModalOpen}
                      onClose={() => setDeleteModalOpen(false)}
                      isPortfolio={isPortfolio}
                      artifactName={result.collectionName}
                      versionIndex={currentVersionValue.result.index}
                      hasAliases={hasAliases}
                      hasMemberships={result.collectionMemberships.length > 0}
                      afterDelete={() => {}}
                      artifactId={result.collectionId}
                      artifactVersionId={currentVersionValue.result.id}
                    />
                  )}
                </S.TitleAndMenusContainer>
                <S.BlueButton
                  onClick={() => {
                    setIsLaunchConfigModalOpen(true);
                  }}>
                  Use Job
                </S.BlueButton>

                {!currentVersionValue.loading &&
                  isLaunchConfigModalOpen &&
                  job != null && (
                    <EditLaunchConfigModalControlled
                      entityName={result.entityName}
                      projectName={result.projectName}
                      onClose={() => {
                        setIsLaunchConfigModalOpen(false);
                      }}
                      job={job}
                    />
                  )}
              </S.HeaderContainer>
            </S.PanelJobHeaderContent>
            {
              <PanelJobOverview
                input={artifactMembershipNode as any}
                config={config}
                updateConfig={updateConfig}
                context={props.context}
                updateContext={props.updateContext}
              />
            }
          </>
        )}
      </S.JobOverviewMain>
    );
  };

export default PanelJob;

function getDefaultIdentifier(
  propsIdentifier?: string,
  lastVersionIndex?: number
) {
  return (
    propsIdentifier ??
    (lastVersionIndex != null ? `v${lastVersionIndex}` : undefined)
  );
}

function dataDictNode(artifactNode: Node<'artifact'>) {
  return opDict({
    collectionName: opArtifactName({artifact: artifactNode}),
    collectionId: opArtifactId({artifact: artifactNode}),
    collectionIsPortfolio: opArtifactIsPortfolio({
      artifact: artifactNode,
    }),
    collectionMemberships: opMap({
      arr: opArtifactMemberships({
        artifact: artifactNode,
      }),
      mapFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opDict({
          versionIndex: opArtifactMembershipVersionIndex({
            artifactMembership: row,
          }),
          versionId: opArtifactMembershipArtifactVersion({
            artifactMembership: row,
          }),
          aliases: opArtifactAliasAlias({
            artifactAlias: opArtifactMembershipArtifactAliases({
              artifactMembership: row,
            }),
          }),
        } as any);
      }),
    }),
    versionCount: opCount({
      arr: opArtifactVersions({artifact: artifactNode}),
    }),
    lastMembershipVersionIndex: opArtifactMembershipVersionIndex({
      artifactMembership: opArtifactLastMembership({
        artifact: artifactNode,
      }),
    }),
    entityName: opEntityName({
      entity: opProjectEntity({
        project: opArtifactProject({
          artifact: artifactNode,
        }),
      }),
    }),
    projectName: opProjectName({
      project: opArtifactProject({
        artifact: artifactNode,
      }),
    }),
    runQueues: opRunQueueId({
      runQueue: opProjectRunQueues({
        project: opArtifactProject({
          artifact: artifactNode,
        }),
      }),
    }),
    artifactTypeName: opArtifactTypeName({
      artifactType: opArtifactType({
        artifact: artifactNode,
      }),
    }),
  } as any) as OutputNode<{
    type: 'typedDict';
    propertyTypes: {
      collectionName: 'string';
      collectionId: 'string';
      collectionIsPortfolio: 'boolean';
      collectionMemberships: {
        type: 'list';
        objectType: {
          type: 'typedDict';
          propertyTypes: {
            versionIndex: 'number';
            versionId: 'string';
            aliases: {type: 'list'; objectType: 'string'};
          };
        };
      };
      versionCount: 'number';
      lastMembershipVersionIndex: 'number';
      entityName: 'string';
      projectName: 'string';
      artifactTypeName: 'string';
      runQueues: {
        type: 'list';
        objectType: 'string';
      };
    };
  }>;
}
