import Analytics from '@segment/analytics.js-core/build/analytics';
import SegmentIntegration from '@segment/analytics.js-integration-segmentio';
import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import config, {
  backendHost,
  envIsCloudOnprem,
  envIsLocal,
} from '@wandb/common/config';
import {getFullStoryUrl} from '@wandb/common/util/integrations';

// To avoid race condition related to `window.thirdPartyAnalyticsOK`
// which was preventing Sentry from being initialized when it should,
// we simply always init Sentry, which is a no-op if the env doesn't
// contain a valid Sentry DSN
Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({levels: ['error']}),
  ],
  tracesSampleRate: 0.1,
  release: config.GIT_TAG,
  normalizeDepth: Infinity,
  beforeSend(event, hint) {
    const error = hint && hint.originalException;
    event.extra = event.extra || {};
    event.extra.fullstory =
      getFullStoryUrl() || 'current session URL API not ready';

    if (window.FS && window.FS.event) {
      window.FS.event('Application Error', {
        name: typeof error === 'string' ? error : error?.name,
        message: typeof error === 'string' ? error : error?.message,
        fileName: typeof error !== 'string' && error?.message,
        stack: typeof error !== 'string' && error?.stack,
        sentryEventId: hint?.event_id,
      });
    }

    // Check if this is a full-page error, and if so, show the report dialog
    if (
      typeof error === 'string' &&
      error.includes('Encountered ErrorBoundary')
    ) {
      Sentry.showReportDialog({eventId: event.event_id});
    }

    return event;
  },
  ignoreErrors: [
    // From RO author: "This error means that ResizeObserver was not able to deliver all observations within
    // a single animation frame. It is benign (your site will not break)."
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',

    // This is MSFT Safe Link agent with poor JS compatibility
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/4
    // (as mentioned in that thread, confirmed the IPs via who.is)
    'Object Not Found Matching Id',

    // Displayed on every rate-limited request
    'status code 429',

    // This is an Edge Bing Instant Search bar error
    // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
  ],
});

// analyticsOK is set by index.html
if ((envIsLocal || envIsCloudOnprem) && (window as any).analyticsOK) {
  let host = backendHost();
  if (host === '') {
    host = document.location.origin;
  }
  if (host.startsWith('https://')) {
    const apiHost = host.replace('https://', '') + '/analytics';
    const integrationSettings = {
      'Segment.io': {
        apiHost,
        retryQueue: true,
      },
    };
    window.analytics = new (Analytics as any)();
    window.analytics.use(SegmentIntegration);
    window.analytics.init(integrationSettings);
  }
}
