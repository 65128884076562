import {isNotNullOrUndefined, isTruthy} from '@wandb/common/util/types';
import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import * as Filter from '../../util/filters';
import * as Panels from '../../util/panels';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import {isOneOf} from '../../util/utility';
import {useSampleAndQueryToTable} from '../Export';
import {getTitleFromConfig, ScatterPlotConfig} from './common';
export type {ScatterPlotConfig};

const PANEL_TYPE = 'Scatter Plot';

function scatterPlotTransformQuery(
  query: Query.Query,
  config: ScatterPlotConfig
) {
  const result = toRunsDataQuery(query, undefined, {
    page: {size: 500},
  });

  const {axisKeys, legendKeys, groupingKeys, selectionKeys} = getKeysForQuery(
    query,
    config
  );

  const displayKeys = [
    ...axisKeys,
    ...legendKeys,
    ...groupingKeys,
    ...selectionKeys,
  ];
  result.configKeys = displayKeys
    .filter(key => key.section === 'config')
    .map(key => key.name);
  result.summaryKeys = displayKeys
    .filter(key => key.section === 'summary')
    .map(key => key.name);

  const firstQuery = result.queries[0];
  const filterKeys = [...axisKeys, ...legendKeys, ...selectionKeys];
  if (firstQuery != null && filterKeys.length > 0) {
    firstQuery.filters = addRequiredKeysToFilter(
      firstQuery.filters,
      filterKeys
    );
  }

  return result;
}

function getKeysForQuery(
  query: Query.Query,
  config: ScatterPlotConfig
): {
  axisKeys: Run.Key[];
  legendKeys: Run.Key[];
  groupingKeys: Run.Key[];
  selectionKeys: Run.Key[];
} {
  const axisKeys = [config.xAxis, config.yAxis, config.zAxis]
    .filter(isTruthy)
    .map(Run.keyFromString)
    .filter(isNotNullOrUndefined)
    .filter(key => isOneOf(key.section, ['config', 'summary']));

  const legendKeys =
    config.legendFields?.map(Run.keyFromString).filter(isNotNullOrUndefined) ??
    [];

  // We need the grouping values to color properly
  const groupingKeys =
    query.runSets?.flatMap?.(rs => rs.grouping).filter(isNotNullOrUndefined) ??
    [];

  // we need the keys from selections to color the visible runs differently
  // We look for the selections in the first runset which is probably
  // something we should change.  query.selections appears to be undefined.
  const selectionKeys: Run.Key[] = [];
  const firstRunsetSelections = query.runSets?.[0]?.selections;
  if (firstRunsetSelections != null) {
    Filter.treeForEach(firstRunsetSelections, (f: Filter.Filter<Run.Key>) => {
      if (!Filter.isRunFilter(f)) {
        const error = 'Receieved non run filter in scatter plot';
        console.error(error);
        throw new Error(error);
      }
      if (Filter.isIndividual(f)) {
        selectionKeys.push(f.key);
      }
    });
  }

  return {
    axisKeys,
    legendKeys,
    groupingKeys,
    selectionKeys,
  };
}

function addRequiredKeysToFilter(
  f: Filter.Filter<Run.Key>,
  keys: Run.Key[]
): Filter.Filter<Run.Key> {
  return Filter.And([
    f,
    ...keys.map(key => ({
      key,
      op: '!=' as Filter.ValueOp,
      value: null as Run.Value,
    })),
  ]);
}

function useTableData(pageQuery: Query.Query, config: ScatterPlotConfig) {
  const query = scatterPlotTransformQuery(pageQuery, config);
  return useSampleAndQueryToTable(query, pageQuery, config);
}

const configSpec = {
  chartTitle: {
    editor: 'string' as const,
    displayName: 'Chart title',
  },
  xAxisMin: {editor: 'number' as const, displayName: 'X min'},
  xAxisMax: {editor: 'number' as const, displayName: 'X max'},
  xAxisLogScale: {
    editor: 'checkbox' as const,
    displayName: 'X log scale',
    default: false,
  },
  yAxisMin: {editor: 'number' as const, displayName: 'Y min'},
  yAxisMax: {editor: 'number' as const, displayName: 'Y max'},
  yAxisLogScale: {
    editor: 'checkbox' as const,
    displayName: 'Y log scale',
    default: false,
  },
  zAxisMin: {editor: 'number' as const, displayName: 'Z min'},
  zAxisMax: {editor: 'number' as const, displayName: 'Z max'},
  zAxisLogScale: {
    editor: 'checkbox' as const,
    displayName: 'Z log scale',
    default: false,
  },
  customGradient: {
    editor: 'gradient' as const,
    displayName: 'Gradient',
    default: [
      {offset: 0, color: '#900000'},
      {offset: 50, color: '#D64F04'},
      {offset: 100, color: '#FFE600'},
    ],
  },
  showMaxYAxisLine: {
    editor: 'checkbox' as const,
    displayName: 'Plot max y',
    default: false,
  },
  showMinYAxisLine: {
    editor: 'checkbox' as const,
    displayName: 'Plot min y',
    default: false,
  },
  showAvgYAxisLine: {
    editor: 'checkbox' as const,
    displayName: 'Plot avg y',
    default: false,
  },
};

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, ScatterPlotConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  getTitleFromConfig,

  exportable: {
    image: true,
    csv: true,
    api: true,
  },

  transformQuery: scatterPlotTransformQuery,
  configSpec,
  useTableData,
  icon: 'panel-scatter-plot',
};
