import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

export const BannerWrapper = styled.div<{variant: string}>`
  display: flex;
  justify-content: space-between;

  border-radius: 8px;
  padding: 16px;

  ${({variant}) => {
    switch (variant) {
      case 'announcement':
        return css`
          background-color: ${globals.GOLD_DARK_TRANSPARENT};
          color: ${globals.GOLD_DARK_2};
        `;
      case 'error':
        return css`
          background-color: ${globals.RED_TRANSPARENT};
          color: ${globals.RED};
        `;

      case 'info':
      default:
        return css`
          background-color: ${globals.GRAY_TRANSPARENT};
          color: ${globals.GRAY_800};
        `;
    }
  }};

  font-family: Source Sans Pro;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const LeftWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BannerBoldText = styled.span`
  font-weight: 600;
`;

export const BannerUnboldedLabel = styled.span`
  font-weight: 400;
`;
