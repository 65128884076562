import {Editor, Node, Path, RangeRef, Text, Transforms} from 'slate';

// Prevent duplicate slate modules from being imported
// into gorilla-slate by passing them through this file.
export {Transforms} from 'slate';
export {createEditor} from 'slate';
export {Node} from 'slate';
export {Editor} from 'slate';

export const INLINE_COMMENT_PREFIX = 'inlineComment_';

export interface InlineCommentMark {
  refID: string;
  threadID?: string;
  commentID?: string;
}

interface NodeToDelete {
  refID: string;
  node: Node;
  path: Path;
}

// used to change the selection of text to have inline comment mark
export interface InlineCommentDetail {
  refID: string; // generated in FE
  text?: string;
  rangeRef: RangeRef;
}

export function getMarkKey(refID: string): string {
  return INLINE_COMMENT_PREFIX + refID;
}

// check if the node has specific inlineCommentMark
// that contains given refID and commentID
export function hasInlineCommentMark(node: Node, refID: string): boolean {
  const markKey = getMarkKey(refID);
  return Object(node).hasOwnProperty(markKey);
}

// inline comment mention is formatted as |[__display__](__id__)
export const INLINE_COMMENT_MENTION_REGEX = /\|\[.*?]\((.*?)\)/g;

export const EditorWithComments = {
  // addMark finds slate text node with refID stored in context's inlineCommentDetails
  // and add inlineCommentMark to the node to let slate know this is inline text comments.
  addMark(editor: Editor, mark: InlineCommentMark, rangeRef: RangeRef) {
    const markKey = getMarkKey(mark.refID);

    if (mark.threadID == null || mark.commentID == null) {
      return;
    }

    Transforms.setNodes(
      editor,
      {[markKey]: mark},
      {at: rangeRef.current ?? [], match: Text.isText, split: true}
    );
  },

  // for each slate text node associated with given refID's,
  // remove the mark for the refID from text nodes to unhighlight the nodes
  removeMarks(editor: Editor, refIDs: string[]) {
    if (refIDs.length === 0) {
      return;
    }

    const nodesToDelete: NodeToDelete[] = [];

    for (const refID of refIDs) {
      for (const [node, path] of Editor.nodes(editor, {
        at: [],
        match: (n: Node) => {
          return (
            // check if the node has this specific inlineComment. if so, remove it.
            hasInlineCommentMark(n, refID)
          );
        },
      })) {
        // removeMark in the reverse order to avoid selection conflict
        nodesToDelete.unshift({node, path, refID});
      }
    }

    nodesToDelete.forEach((nodeInfo: NodeToDelete) => {
      this.removeMark(editor, nodeInfo.node, nodeInfo.path, nodeInfo.refID);
    });
  },

  removeMark(editor: Editor, node: Node, path: Path, refID: string) {
    if (!hasInlineCommentMark(node, refID)) {
      return;
    }

    const markKey = getMarkKey(refID);
    Transforms.unsetNodes(editor, markKey, {
      at: path,
      match: Text.isText,
      split: true,
    });
  },
};

// get all the ref IDs associated with inline texts mentioned in the comment body
export function getInlineRefIDsFromCommentBody(commentBody: string): string[] {
  const refIDs: string[] = [];

  let match = INLINE_COMMENT_MENTION_REGEX.exec(commentBody);
  while (match != null) {
    refIDs.push(match[1]);
    match = INLINE_COMMENT_MENTION_REGEX.exec(commentBody);
  }

  return refIDs;
}
