import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Modal, Popup, Table} from 'semantic-ui-react';

import {
  Email,
  EmailType,
  Identity,
  useDeleteUserEmailMutation,
  useResendEmailVerificationMutation,
  useUpdateUserInfoMutation,
  useUserEmailsQuery,
} from '../generated/graphql';
import {User} from '../types/graphql';
import {setAuthRedirect} from '../util/auth';
import {propagateErrorsContext} from '../util/errors';
import {login} from '../util/urls';
import {getDomainFromEmail} from '../util/utility';
import * as S from './EmailsTable.styles';
import {Icon} from './Icon';
import {LABEL_COLORS} from './Label';

function useDeleteEmail(
  emailAddress: string,
  emailId: string,
  entityName: string,

  cb?: () => void
) {
  const [deleteUserEmail, {loading}] = useDeleteUserEmailMutation({
    context: propagateErrorsContext(),
    onCompleted: () => {
      window.analytics?.track('Email Deleted', {
        entityName,
        email: emailAddress,
      });

      if (cb) {
        cb();
      }
    },
    onError: () => {
      window.analytics?.track('Delete Email Failed', {
        entityName,
        email: emailAddress,
      });
    },
  });

  const handleDeleteUserEmail = useCallback(() => {
    deleteUserEmail({
      variables: {
        id: emailId,
      },
    });
  }, [deleteUserEmail, emailId]);

  return [handleDeleteUserEmail, loading] as const;
}

export interface EmailsTableProps {
  entity: User;
}

const EmailsTable = (props: EmailsTableProps) => {
  const {entity} = props;
  const userEmailsQuery = useUserEmailsQuery();
  const emails = userEmailsQuery.data?.viewer?.emails;
  const onClose = () => {
    setEmailType(null);
    setRejectedEmail(null);
  };

  const [updateUserInfo] = useUpdateUserInfoMutation();

  const [resendVerification] = useResendEmailVerificationMutation();

  const [emailType, setEmailType] = useState<EmailType | null>(null);
  const [rejectedEmail, setRejectedEmail] = useState<Email | null>(null);
  const [emailToBeDeleted, setEmailToBeDeleted] = useState<Email | null>(null);

  const onChangePrimaryEmail = useCallback(
    async email => {
      try {
        const res = await updateUserInfo({
          variables: {
            primaryEmail: email.emailAddress,
          },
        });

        if (res.errors != null) {
          window.analytics?.track('Primary Email Change Failed', {
            entityName: entity.name,
            email: email.emailAddress,
          });
        }
      } catch (e) {
        window.analytics?.track('Primary Email Change Failed', {
          entityName: entity.name,
          email: email.emailAddress,
        });
        return;
      } finally {
        window.location.reload();
      }
      window.analytics?.track('Primary Email Updated', {
        entityName: entity.name,
        newPrimaryEmail: email.emailAddress,
      });
    },
    [entity, updateUserInfo]
  );

  const onResendVerification = useCallback(
    async email => {
      try {
        const res = await resendVerification({
          variables: {
            email,
          },
        });

        if (res.errors != null || !res.data?.resendEmailVerification?.success) {
          window.analytics?.track('Resend Email Verification Failed', {
            entityName: entity.name,
            email,
          });
          return;
        }
        window.analytics?.track('Resend Email Verification Request Sent', {
          email,
        });
        toast('Email verification sent');
      } catch (e) {
        window.analytics?.track('Resend Email Verification Failed', {
          entityName: entity.name,
          email,
        });
        return;
      }
    },
    [entity, resendVerification]
  );

  const [onDeleteEmail, loading] = useDeleteEmail(
    emailToBeDeleted?.emailAddress || '',
    emailToBeDeleted?.id || '',
    entity.name,
    () => window.location.reload()
  );

  useEffect(() => {
    setEmailToBeDeleted(loading ? null : emailToBeDeleted);
  }, [setEmailToBeDeleted, loading, emailToBeDeleted]);

  return (
    <div>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{fontWeight: 600}}>Email</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell style={{fontWeight: 600, width: '240px'}}>
              Login
            </Table.HeaderCell>
            <Table.HeaderCell style={{width: 10}} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {emails?.map(email => (
            <Table.Row key={email.id}>
              <Table.Cell>
                <S.EmailCell>{email.emailAddress}</S.EmailCell>
              </Table.Cell>
              <Table.Cell>
                <EmailBadge
                  isPrimary={entity.email === email.emailAddress}
                  isVerified={email.verified}
                  emailCreatedAt={email.createdAt}
                />
              </Table.Cell>
              <Table.Cell>
                {email.identities.map((identity: Identity, index: number) => (
                  <span key={identity.id}>
                    {index > 0 && ', '}
                    <span>{getProvider(identity.provider ?? '')}</span>
                  </span>
                ))}
              </Table.Cell>
              <Table.Cell>
                <EmailPopupDropdown
                  email={email}
                  isPrimary={entity.email === email.emailAddress}
                  changePrimary={() => {
                    onChangePrimaryEmail(email);
                  }}
                  deleteUserEmail={() => setEmailToBeDeleted(email)}
                  resendVerification={() => {
                    onResendVerification(email.emailAddress);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <S.AddEmailLink
        href={login({signup: true, loggedIn: true})}
        onClick={() => {
          setAuthRedirect('/settings?addEmail=true');
          window.analytics?.track('Add Email Clicked', {
            entityName: entity.name,
            location: 'Settings Page',
          });
        }}>
        + Add Email
      </S.AddEmailLink>
      {rejectedEmail && (
        <EmailDisputeModal
          emailType={emailType}
          email={rejectedEmail}
          entityName={entity.name}
          onClose={onClose}
        />
      )}
      {emailToBeDeleted != null && (
        <DeleteEmailConfirmationModal
          email={emailToBeDeleted}
          deleteUserEmail={onDeleteEmail}
          onClose={() => setEmailToBeDeleted(null)}
        />
      )}
    </div>
  );
};

function getProvider(raw: string): string {
  if (raw.startsWith('google')) {
    return 'Google';
  } else if (raw.startsWith('auth0')) {
    return 'Email';
  } else if (raw.startsWith('github')) {
    return 'Github';
  } else if (raw.startsWith('windowslive')) {
    return 'Microsoft';
  } else {
    return 'Unknown';
  }
}

const ONE_DAY = 1000 * 60 * 60 * 24;
// Emails created before this date should not be expired based on the created_at date
const NEW_EMAIL_SYSTEM_DATE = new Date('2022-12-01');
function daysLeft(createdAt: Date): number {
  const maxDays = 14;
  const isBeforeEmailSystemDate = createdAt < NEW_EMAIL_SYSTEM_DATE;
  const base = isBeforeEmailSystemDate ? NEW_EMAIL_SYSTEM_DATE : createdAt;
  const expirationDate = new Date(base);
  expirationDate.setDate(NEW_EMAIL_SYSTEM_DATE.getDate() + maxDays);
  const diffMs = Math.abs(expirationDate.getTime() - Date.now());
  return Math.round(diffMs / ONE_DAY);
}

interface EmailDisputeModalProps {
  onClose: () => void;
  emailType: EmailType | null;
  email: Email;
  entityName: string;
}

const EmailDisputeModal: React.FC<EmailDisputeModalProps> = ({
  onClose,
  emailType,
  email,
  entityName,
}) => {
  const support = 'support@wandb.com';
  return (
    <Modal open onClose={onClose} size="mini">
      <Modal.Header>
        Invalid {emailType?.toLowerCase() ?? ''} email
      </Modal.Header>
      <Modal.Content>
        We flagged this domain <b>{getDomainFromEmail(email.emailAddress)}</b>{' '}
        as a{email.type === EmailType.Academic && 'n'}{' '}
        {email.type.toLowerCase()} email domain, which means you can’t set it as
        a{emailType === EmailType.Academic && 'n'} {emailType?.toLowerCase()}{' '}
        email. Contact us for help:
        <S.CopyableTextWrapper>
          <S.CopyableIframe
            text={support}
            copyText={support}
            toastText="Support email address copied"
            onClick={() => {
              window.analytics?.track('Email Dispute Modal Email Copied', {
                entityName,
                email,
                emailType,
              });
            }}
          />
        </S.CopyableTextWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

type EmailPopupDropdownProps = {
  email: Email;
  isPrimary: boolean;
  changePrimary(): void;
  deleteUserEmail(): void;
  resendVerification(): void;
};

const EmailPopupDropdown: React.FC<EmailPopupDropdownProps> = ({
  email,
  isPrimary,
  changePrimary,
  deleteUserEmail,
  resendVerification,
}) => {
  // isVerified - if at least one identity is verified
  // isNotVerified - if an unverified identity exists
  const isVerified = email.identities.some(
    ({emailIdentityVerified}) => emailIdentityVerified
  );
  const isNotVerified = email.identities.some(
    ({emailIdentityVerified}) => !emailIdentityVerified
  );
  const options = useMemo(() => {
    const opts: Array<{
      key: string;
      text: React.ReactElement;
      disabled: boolean;
      onClick: () => any;
      shouldShowOption: boolean;
    }> = [
      {
        key: 'update',
        text: (
          <>
            <Icon name="checkmark-circle" />
            <S.OptionText>Set as primary</S.OptionText>
          </>
        ),
        disabled: isPrimary || !isVerified,
        onClick: changePrimary,
        shouldShowOption: isVerified || isPrimary,
      },
      {
        key: 'resend',
        text: (
          <>
            <Icon name="email-envelope" />
            <S.OptionText>Resend verification</S.OptionText>
          </>
        ),
        disabled: !isNotVerified,
        onClick: resendVerification,
        shouldShowOption: isNotVerified,
      },
      {
        key: 'delete',
        text: (
          <>
            <Icon name="delete" />
            <S.OptionText>Delete</S.OptionText>
          </>
        ),
        disabled: isPrimary,
        onClick: deleteUserEmail,
        shouldShowOption: true,
      },
    ];
    return opts.filter(option => option.shouldShowOption);
  }, [
    changePrimary,
    isPrimary,
    isVerified,
    isNotVerified,
    deleteUserEmail,
    resendVerification,
  ]);

  const trigger = useMemo(
    () => (
      <LegacyWBIcon
        className="row-actions-button"
        name="overflow"
        title="menu"
        size="large"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
        }}
      />
    ),
    []
  );

  return (
    <S.PopupDropdownWrapper>
      <PopupDropdown trigger={trigger} options={options} />
    </S.PopupDropdownWrapper>
  );
};

interface DeleteEmailConfirmationModalProps {
  onClose: () => void;
  email: Email;
  deleteUserEmail(): void;
}

const DeleteEmailConfirmationModal: React.FC<DeleteEmailConfirmationModalProps> =
  ({onClose, email, deleteUserEmail}) => {
    return (
      <Modal open onClose={onClose} size="mini">
        <Modal.Header>Remove a linked email</Modal.Header>
        <Modal.Content>
          Are you sure you want to remove the email address{' '}
          <b>{email.emailAddress}</b> from your account?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Nevermind</Button>
          <Button primary onClick={deleteUserEmail}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

const EmailBadge = ({
  isPrimary,
  isVerified,
  emailCreatedAt,
}: {
  isPrimary: boolean;
  isVerified: boolean;
  emailCreatedAt: Date;
}) => {
  return (
    <>
      {isPrimary && (
        <S.StyledLabel
          color={LABEL_COLORS.GREEN}
          Icon={<Icon name="checkmark-circle" />}>
          Primary
        </S.StyledLabel>
      )}{' '}
      {!isVerified && !isPrimary && (
        <Popup
          inverted
          size="mini"
          position="top left"
          trigger={
            <S.StyledLabel
              color={LABEL_COLORS.SIENNA}
              Icon={<Icon name="failed" />}>
              Unverified
            </S.StyledLabel>
          }
          content={`
          We emailed you a link to verify this email.  It will be automatically deleted in ${daysLeft(
            new Date(emailCreatedAt)
          )} days if not verified.`}
        />
      )}
    </>
  );
};
export default EmailsTable;
