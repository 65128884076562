import domToImage from './domToImage';
import wait from './wait';

const DEFAULT_ZOOM_FACTOR = window.devicePixelRatio * 4;

export enum ImageExportType {
  PDF = 'pdf',
  PNG = 'png',
  SVG = 'svg',
}

type ImageExportOptions = {
  zoomFactor?: number;
  backgroundColor?: string;
};

export async function downloadElementAsPNG(
  el: HTMLElement,
  name: string,
  opts: ImageExportOptions = {}
): Promise<void> {
  const dataURL = await generateImageDataURL(domToImage.toPng, el, opts);
  await commenceDownload(`${name}.png`, dataURL);
}

export async function downloadElementAsSVG(
  el: HTMLElement,
  name: string,
  opts: ImageExportOptions = {}
): Promise<void> {
  const dataURL = await generateImageDataURL(domToImage.toSvg, el, opts);
  await commenceDownload(`${name}.svg`, dataURL);
}

export async function generatePNGDataURL(
  el: HTMLElement,
  opts: ImageExportOptions = {}
): Promise<string> {
  return generateImageDataURL(domToImage.toPng, el, opts);
}

export async function generateSVGDataURL(
  el: HTMLElement,
  opts: ImageExportOptions = {}
): Promise<string> {
  return generateImageDataURL(domToImage.toSvg, el, opts);
}

type GenerateImageFn = typeof domToImage.toPng | typeof domToImage.toSvg;

export async function generateImageDataURL(
  generateFn: GenerateImageFn,
  el: HTMLElement,
  opts: ImageExportOptions = {}
): Promise<string> {
  const zoomFactor = opts.zoomFactor ?? DEFAULT_ZOOM_FACTOR;
  const backgroundColor = opts.backgroundColor ?? `#ffffff`;

  const {width, height} = el.getBoundingClientRect();
  return generateFn(el, {
    bgcolor: backgroundColor,
    width: width * zoomFactor,
    height: height * zoomFactor,
    style: {
      transform: `scale(${zoomFactor})`,
      transformOrigin: `top left`,
    },
  });
}

export async function commenceDownload(
  filename: string,
  dataURL: string
): Promise<void> {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('download', filename);
  a.setAttribute('href', dataURL);
  a.style.display = 'none';
  a.click();

  await wait(10);
  document.body.removeChild(a);
}

export async function downloadElementAsPDF(
  el: HTMLElement,
  name: string
): Promise<void> {
  const blob = await domToImage.toBlob(el, {
    bgcolor: '#ffffff',
  });
  const url = URL.createObjectURL(blob);
  const {width, height} = el.getBoundingClientRect();
  printPDFInNewWindow(
    url,
    name,
    width * DEFAULT_ZOOM_FACTOR,
    height * DEFAULT_ZOOM_FACTOR
  );
  URL.revokeObjectURL(url);
}

export function printPDFInNewWindow(
  url: string,
  name: string,
  width: number,
  height: number
): void {
  // eslint-disable-next-line wandb/no-unprefixed-urls
  const w = window.open(url, name, `width=${width},height=${height}`);
  if (w == null) {
    return;
  }
  w.document.title = name;
  w.print();
}
