import React, {createContext, useContext} from 'react';

import {PanelBankConfigWithRefs} from './usePanelBankConfigWithRefs';

type ExtendedPanelBankConfigWithRefs = PanelBankConfigWithRefs & {
  entityName: string;
  projectName: string;
  workspaceId: string;
};
const PanelBankConfigContext = createContext<
  ExtendedPanelBankConfigWithRefs | undefined
>(undefined);

const PanelBankConfigProviderComp = ({
  children,
  entityName,
  panelBankConfigWithRefs,
  projectName,
  workspaceId,
}: {
  children: React.ReactNode;
  entityName: string;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  projectName: string;
  workspaceId: string;
}) => (
  <PanelBankConfigContext.Provider
    value={{
      ...panelBankConfigWithRefs,
      entityName,
      projectName,
      workspaceId,
    }}>
    {children}
  </PanelBankConfigContext.Provider>
);

export const PanelBankConfigProvider = React.memo(PanelBankConfigProviderComp);

export const usePanelBankConfigContext = ():
  | ExtendedPanelBankConfigWithRefs
  | undefined => {
  const context = useContext(PanelBankConfigContext);

  return context;
};
