import * as globals from '@wandb/common/css/globals.styles';
import {Button, Checkbox} from 'semantic-ui-react';
import styled from 'styled-components';

export const ExistingTeamContainer = styled.div`
  margin-bottom: calc(2 * ${globals.spu});
`;

export const CheckboxAndDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const JoinTeamCheckbox = styled(Checkbox)`
  &&&&& {
  }
`;

export const JoinTeamCheckboxContainer = styled.div`
  display: flex;
  margin-right: calc(2 * ${globals.spu});
`;

export const ContinueButton = styled(Button)`
  display: flex;
  flex-grow: 1;
`;

export const SkipButton = styled(Button)`
  display: flex;
  flex-grow: 0;
`;

export const Container = styled.div`
  padding-bottom: ${globals.spu};
`;

export const SubHeader = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${globals.gray800};
  margin-bottom: calc(2 * ${globals.spu});
`;

export const TeamDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TeamName = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const OrganizationName = styled.div`
  font-size: 14px;
`;

export const MemberCount = styled.div`
  font-size: 14px;
`;

export const ErrorMsg = styled.div`
  color: ${globals.errorText};
  font-size: 14px;
`;

export const InfoMsg = styled.div`
  color: ${globals.gray600};
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  margin-top: ${globals.spu};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
