import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import * as globals from '@wandb/common/css/globals.styles';
import React, {memo, useState} from 'react';
import {Button, Popup} from 'semantic-ui-react';

import * as S from './DeleteRunsButton.styles';
import RadioForm from './RadioForm';

interface DeleteRunsButtonProps {
  selectedCount: number;
  isInReport?: boolean;
  outputArtifactsCount: number;
  activeRunsCount: number;
  onDelete(deleteArtifacts: boolean): void;
}

const DeleteRunsButtonComp: React.FC<DeleteRunsButtonProps> = ({
  selectedCount,
  isInReport,
  onDelete,
  outputArtifactsCount,
  activeRunsCount,
}) => {
  const [deleteArtifacts, setDeleteArtifacts] = useState('');
  const [open, setOpen] = useState(false);

  let buttonClass;
  if (open) {
    buttonClass = 'action-button--active';
  } else {
    buttonClass = 'action-button--static';
  }
  const button = (
    <Button
      data-test="run-delete-popup"
      size="tiny"
      className={
        buttonClass +
        ' enable-pointer-events wb-icon-button only-icon' +
        (selectedCount === 0 ? ' disabled' : '')
      }
      onClick={() => {
        if (selectedCount !== 0) {
          setOpen(true);
          const actionLocation = isInReport ? 'report' : 'runs table';
          window.analytics?.track('Delete run clicked', {
            location: actionLocation,
          });
        }
      }}>
      <LegacyWBIcon name="delete" />
    </Button>
  );

  if (selectedCount === 0) {
    return (
      <Popup
        basic
        on="hover"
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        content={
          <p className="hint-text small">
            Select runs to delete by hovering over a row and clicking the
            checkbox on the left.
          </p>
        }
        trigger={button}
      />
    );
  }

  const showCheckbox = outputArtifactsCount > 0;

  return (
    <React.Fragment>
      <Popup
        basic
        style={{
          color: globals.white,
          backgroundColor: globals.error,
        }}
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        position="top center"
        content="Delete from project"
        trigger={button}
      />
      <S.StyledWBModal
        open={open}
        theme="danger"
        header={`Delete runs`}
        onClose={() => setOpen(false)}
        includeCancel
        primaryAction={{
          content: `Delete ${selectedCount} run${
            selectedCount === 1 ? '' : 's'
          }`,
          disabled: !deleteArtifacts && showCheckbox,
          onClick: () => {
            onDelete(deleteArtifacts === 'true');
            setOpen(false);
          },
        }}>
        <p>
          Delete {selectedCount} run{selectedCount === 1 ? '' : 's'}? This
          affects all pages, including reports.{' '}
          {activeRunsCount > 0 && (
            <span>
              You’re deleting {activeRunsCount} active run
              {activeRunsCount === 1 ? '' : 's'}, which might crash live
              processes if your run is actually still running. We suggest that
              you stop the run before deleting it.
            </span>
          )}
        </p>
        {showCheckbox && (
          <RadioForm
            name="deleteArtifactsGroup"
            options={[
              {
                label: `Also delete the ${outputArtifactsCount} artifact${
                  outputArtifactsCount > 1 ? 's' : ''
                } this run created`,
                key: 'true',
              },
              {label: 'Keep the artifacts', key: 'false'},
            ]}
            value={deleteArtifacts}
            onChange={newValue => setDeleteArtifacts(newValue)}
          />
        )}
      </S.StyledWBModal>
    </React.Fragment>
  );
};

export const DeleteRunsButton = memo(DeleteRunsButtonComp);
