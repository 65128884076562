import {TargetBlank} from '@wandb/common/util/links';
import React, {FC} from 'react';

import * as S from './BlankPage.styles';

type Graphic = {
  src: string;
  alt: string;
};

type Action = {
  content: string;
  href?: string;
  onClick?: () => void;
};

type DocLink = {
  href: string;
  onClick?: () => void;
};

type BlankPageProps = {
  header: string;
  subheader: string;
  info?: string;
  graphics: Graphic[];
  secondaryAction?: Action;
  primaryAction?: Action;
  extraTopMargin?: boolean;
  docLink?: DocLink;
  children?: React.ReactNode;
};

const BlankPage: FC<BlankPageProps> = React.memo(
  ({
    header,
    subheader,
    info,
    docLink,
    graphics,
    secondaryAction,
    primaryAction,
    extraTopMargin,
    children,
  }) => {
    return (
      <S.BlankPageContainer extraTopMargin={extraTopMargin}>
        <h1>{header}</h1>
        <h2>{subheader}</h2>
        <S.BlankPageGraphics>
          {graphics.map(g => (
            <img key={g.alt} src={g.src} alt={g.alt}></img>
          ))}
        </S.BlankPageGraphics>
        {info && <h3>{info}</h3>}
        {children}
        <S.ButtonGroup>
          {secondaryAction && (
            <TargetBlank
              href={secondaryAction.href}
              onClick={secondaryAction.onClick}>
              <S.SecondaryButton content={secondaryAction.content} />
            </TargetBlank>
          )}
          {primaryAction && (
            <TargetBlank
              href={primaryAction.href}
              onClick={primaryAction.onClick}>
              <S.PrimaryButton content={primaryAction.content} />
            </TargetBlank>
          )}
        </S.ButtonGroup>
        {docLink && (
          <S.DocLink href={docLink.href} onClick={docLink.onClick}>
            See docs →
          </S.DocLink>
        )}
      </S.BlankPageContainer>
    );
  }
);

export default BlankPage;
