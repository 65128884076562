import React from 'react';
import {Editor, Range} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from 'slate-react';

import {useCompositionKeyboard} from '../CompositionKeyboardContext';
import {BlockWrapper} from './drag-drop';
import * as S from './paragraphs.styles';
import {Paragraph} from './ParagraphTypes';

export {isParagraph} from './ParagraphTypes';

export type {Paragraph};

export const ParagraphElement: React.FC<
  RenderElementProps & {
    element: Paragraph;
  }
> = ({attributes, element, children}) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const readOnly = useReadOnly();
  const {isDoingComposition} = useCompositionKeyboard();
  const empty = Editor.isEmpty(editor, element);
  const collapsed =
    editor.selection != null && Range.isCollapsed(editor.selection);

  const path = ReactEditor.findPath(editor, element);

  if (path.length > 1) {
    return (
      <S.Paragraph {...attributes} textAlign={element.textAlign}>
        {children}
      </S.Paragraph>
    );
  }

  const isFirstNode = path.length === 1 && path[0] === 0;
  const isLastNode =
    path.length === 1 && path[0] === editor.children.length - 1;

  const showHint =
    !readOnly &&
    empty &&
    (isFirstNode || (selected && collapsed)) &&
    !isDoingComposition;

  if (isLastNode) {
    /** Persistent blank line under report. We don't want to be able to drag it
     * or drag anything under it. It should be extra fat for easy clicking.
     * When this is the only paragraph and the focus is outside the slate,
     * show hint message so users know they can click and type.
     */
    return (
      <S.Paragraph
        {...attributes}
        showHint={showHint}
        data-test="trailing-paragraph"
        style={readOnly ? {display: 'none'} : undefined}>
        {children}
      </S.Paragraph>
    );
  }

  if (isFirstNode && Editor.isEmpty(editor, element)) {
    /** Persistent blank line above report. We don't want to be
     * able to drag it, but we do want to be able to drag other
     * block onto (under) it.
     * The hint message is displayed when the slate editor is not focused.
     */
    return (
      <BlockWrapper attributes={attributes} element={element} noDragging>
        <S.Paragraph
          showHint={showHint && editor.children.length === 1}
          data-test="leading-paragraph"
          style={readOnly ? {display: 'none'} : undefined}>
          {children}
        </S.Paragraph>
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.Paragraph showHint={showHint} textAlign={element.textAlign}>
        {children}
      </S.Paragraph>
    </BlockWrapper>
  );
};

export const paragraphNodeToLatex = (node: Paragraph, inner: string) => {
  return `${inner}\n`;
};
