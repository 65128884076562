/**
 * This file tracks events specific to product.
 */
import {makeTrackEvent} from './makeTrackEvent';
import {ActivationMethods, NoEventData} from './types';

export const enum ProdEventNames {
  AdBlockerStatus = 'ad-blocker-status',
  AppLauncherFailure = 'app-launcher-failure', // tracks non-executable app launcher attempts
  AppLauncherUsed = 'app-launcher-used', // tracks each time the app launcher is used to navigate
  AppLauncherOnScreen = 'app-launcher-on-screen', // tracks each time the app launcher is triggered
  RunsAggregationToggled = 'Runs aggregation toggled', // tracks each time the user toggles the runs aggregation
  SearchNavOnScreen = 'search-nav-on-screen', // tracks each time the search nav is triggered
  SearchNavUsed = 'search-nav-used', // tracks each time the search nav is used to navigate
}

export type QuickNavData = {
  activationMethod: ActivationMethods;
  destination: string;
  location: string;
  type: 'option-selected' | 'direct-navigation';
};

export type QuickNavDescriptions = {
  activationMethod: {
    description: 'Whether the action was triggered by keyboard shortcut or mouse';
    exampleValues: [
      ActivationMethods.MouseClick,
      ActivationMethods.KeyboardHotkey
    ];
  };
  destination: {
    description: 'The new app location being navigated to';
    exampleValues: ['/home', '/[entityName]'];
  };
  location: {
    description: 'The current location within the application';
    exampleValues: ['/home', '/[entityName]'];
  };
  type: {
    description: 'This determines if a user found a matching option in the results list or if they successfully navigated to a location by typing the URL directly';
    exampleValues: ['option-selected', 'direct-navigation'];
  };
};

type AdBlockerEventData = {
  isActive: 'true' | 'false' | 'unknown';
};
type AdBlockerEventDataDescriptions = {
  isActive: {
    description: 'If a user has an ad-blocker enabled (tested by blocking Pendo tracking pixel). Three possible states to be able to quantify conditions where a seam in our check might exist.';
    exampleValues: ['true', 'false', 'unknown'];
  };
};

export const checkIsAdBlockerActive = makeTrackEvent<
  AdBlockerEventData,
  AdBlockerEventDataDescriptions & {
    _description: 'A user has an ad blocker active';
    _location: 'Happens on app initialization';
    _motivation: 'Ad blockers interfere with displaying user guides from Pendo or accurately gauging user sentiment with NPS surveys';
  }
>(ProdEventNames.AdBlockerStatus);

export const withinLastFilterAdded = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires once when the user adds the "within last" op to a filter';
    _location: 'Workspace > edit filters UI';
    _motivation: "We want to track usage of this new feature we've added";
  }
>('User added Within Last Filter');
export const withinLastFilterRemoved = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires once when the user removes the "within last" op from a filter';
    _location: 'Workspace > edit filters UI';
    _motivation: "We want to track usage of this new feature we've added";
  }
>('User removed Within Last Filter');

export const appLauncherFailure = makeTrackEvent<
  Pick<QuickNavData, 'destination' | 'location'>,
  QuickNavDescriptions & {
    _description: 'A user has tried to enter a search term in the launcher nav that does not link to a destination';
    _location: 'Global - App launcher bar';
    _motivation: 'Identify destinations users desire but we do not support';
  }
>(ProdEventNames.AppLauncherFailure);

export const appLauncherUsed = makeTrackEvent<
  Omit<QuickNavData, 'activationMethod'>,
  QuickNavDescriptions & {
    _description: 'A successful entry in the app launcher leading to a new destination';
    _location: 'Global - App launcher bar';
    _motivation: 'Identify where users quickly navigate to';
  }
>(ProdEventNames.AppLauncherUsed);

export const appLauncherOnScreen = makeTrackEvent<
  Pick<QuickNavData, 'activationMethod' | 'location'>,
  QuickNavDescriptions & {
    _description: 'The app launcher widget has become visible in the app';
    _location: 'Global - App launcher bar';
    _motivation: 'Track usage / ease of discovery';
  }
>(ProdEventNames.AppLauncherOnScreen);

export const searchNavOnScreen = makeTrackEvent<
  Pick<QuickNavData, 'activationMethod' | 'location'>,
  QuickNavDescriptions & {
    _description: 'The search nav dropdown is being used';
    _location: 'Global - Header nav search input';
    _motivation: 'Track usage of search in header nav';
  }
>(ProdEventNames.SearchNavOnScreen);

export const searchNavUsed = makeTrackEvent<
  Pick<QuickNavData, 'destination' | 'location'>,
  QuickNavDescriptions & {
    _description: 'A successful entry in the search nav leading to a new destination';
    _location: 'Global - Header nav search input';
    _motivation: 'Identify where users quickly navigate to';
  }
>(ProdEventNames.SearchNavUsed);

export const pointCloudViewed = makeTrackEvent<
  {
    type: 'fullscreen' | 'preview';
  },
  {
    type: {
      description: 'is the point cloud being viewed inside of the workspace panel as a preview, or has the user clicked to view the pointcloud fullscreen?';
      exampleValues: ['fullscreen', 'preview'];
    };
  } & {
    _description: 'fired for each instance of a point cloud - eg if 5 point cloud previews are rendered (visible in any part of html then this will fire 5 times. Full screen will fire once per time the user clicks to open full screen mode';
    _location: 'Workspaces > Media Panel > Point Cloud viewer';
    _motivation: 'How many folks are using the point cloud viewer?';
  }
>('3D Point Cloud Viewed');

export const pointCloudFileWorkaroundUsed = makeTrackEvent<
  {},
  {
    _description: 'There was a bug in the way the SDK was submitting files, this tracks when we have to use the workaround to view those files correctly. See detailed discussion in https://github.com/wandb/wandb/pull/4353';
    _location: 'Object3DCard';
    _motivation: 'Do we even need to keep this workaround in the code?';
  }
>('3D Point Cloud Viewed, required workaround');

export const toggleRunAggregation = makeTrackEvent<
  {
    entityName: string;
    projectName: string;
    workspaceId: string | number;
  },
  {
    _description: 'A user has toggled the aggregation of runs in the runs table';
    _location: 'Multirun workspace settings';
    _motivation: 'Track usage of this feature';
    entityName: {
      description: 'The entity currently viewing the workspace';
      exampleValues: ['my-org', 'my-user'];
    };
    projectName: {
      description: 'The project being viewed';
      exampleValues: ['my-project'];
    };
    workspaceId: {
      description: 'The workspace being viewed';
      exampleValues: ['12asdf34'];
    };
  }
>(ProdEventNames.RunsAggregationToggled);
