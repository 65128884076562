import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type NavContextState = {
  hidden: boolean;
};
type NavUpdaterContextState = {
  setHidden: (h: boolean) => void;
};

const NavContext = createContext<NavContextState>({hidden: false});
const NavUpdaterContext = createContext<NavUpdaterContextState>({
  setHidden: () => {},
});

export function useNavContext(): NavContextState {
  return useContext(NavContext);
}
export function useNavUpdaterContext(): NavUpdaterContextState {
  return useContext(NavUpdaterContext);
}

type NavContextProviderProps = {};

const NavContextProvider: React.FC<NavContextProviderProps> = React.memo(
  ({children}) => {
    const [hidden, setHidden] = useState(false);

    const value = useMemo(
      () => ({
        hidden,
      }),
      [hidden]
    );

    const updaterValue = useMemo(() => ({setHidden}), []);

    return (
      <NavContext.Provider value={value}>
        <NavUpdaterContext.Provider value={updaterValue}>
          {children}
        </NavUpdaterContext.Provider>
      </NavContext.Provider>
    );
  }
);

export default NavContextProvider;

export function useHideNavOnPage(): void {
  const {setHidden} = useNavUpdaterContext();
  useEffect(() => {
    setHidden(true);
    return () => setHidden(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
