import React, {AnchorHTMLAttributes, ReactNode} from 'react';
import {Button, Icon, StrictButtonProps} from 'semantic-ui-react';
import styled from 'styled-components';

import {auth} from '../setup';
import {isUsingAccessToken} from '../util/projectAccess';
import {login} from '../util/urls';

type LoginButtonProps = StrictButtonProps & {};

const StyledButton = styled(Button)`
  &&& {
    :hover {
      color: #ffc94d !important;
    }
  }
`;

export const LoginButton: React.FC<LoginButtonProps> = props => {
  const isSignup = isUsingAccessToken();
  return (
    <StyledButton
      data-test="login-button"
      color="yellow"
      {...props}
      onClick={() => auth.login({signup: isSignup})}>
      {isSignup ? `Sign Up` : `Login`}
    </StyledButton>
  );
};

type SignupButtonProps = StrictButtonProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    text?: ReactNode;
    hideIcon?: boolean;
  };

export const SignupButton: React.FC<SignupButtonProps> = ({
  hideIcon,
  ...props
}) => {
  const text = props.text;
  return (
    <Button
      data-test="signup"
      className="signup-button"
      primary
      {...props}
      as="a"
      href={login({signup: true})}>
      {text != null ? (
        text
      ) : (
        <>
          Sign up
          {!hideIcon && <Icon name="arrow right" />}
        </>
      )}
    </Button>
  );
};
