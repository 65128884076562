export const GALLERY_PATH_SEGMENT = 'fully-connected';
export const PUBLISHED_WORK_PATH_SEGMENT = 'published-work';
export const GALLERY_PATH = [
  `/${GALLERY_PATH_SEGMENT}/projects/:repoName`,
  `/${GALLERY_PATH_SEGMENT}/topics/:topic`,
  `/${GALLERY_PATH_SEGMENT}/:page?/:tag?/:reportID?`,
];
export const STORAGE_EXPLORER_TRACKED_PATH =
  '/usage/:entityName/tracked/:filePath*';
export const STORAGE_EXPLORER_COMPUTE_HOURS_PATH =
  '/usage/:entityName/computehour';
export const USAGE_PATH = '/usage/:entityName';
export const BILLING_PAGE = ['/billing', '/subscriptions'];
export const REPORT_PAGE_VIEW_PATH =
  '/:entityName/:projectName/reports/:reportNameAndID';
export const REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH = `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}/:reportNameAndID`;
export const GALLERY_POST_VIEW_PATH = '/:entityName/posts/:reportNameAndID';
export const GALLERY_POST_EDIT_PATH = `${GALLERY_POST_VIEW_PATH}/edit`;
export const GALLERY_DISCUSSION_VIEW_PATH =
  '/:entityName/discussions/:reportNameAndID';
export const GALLERY_DISCUSSION_EDIT_PATH = `${GALLERY_DISCUSSION_VIEW_PATH}/edit`;
export const PROFILE_PATH = '/:entityName';
export const PROFILE_REPORTS_PATH = `${PROFILE_PATH}/reports`;
export const PROFILE_PROJECTS_PATH = `${PROFILE_PATH}/projects`;
export const PROFILE_LIKES_PATH = `${PROFILE_PATH}/likes`;
export const TEAM_MEMBERS_PATH = `${PROFILE_PATH}/members`;
// NOTE: These paths are risky since they overlap with the
// schema of PROJECT_BASE_PATH. It would be nice to always
// mediate variable route components with a static path. For
// example: `/:entityName/project/:projectName` instead of
// `/:entityName/:projectName`.
export const ENTITY_REGISTRY_PATHS = [
  '/registry/',
  '/registry/:registryName?',
  '/:entityName/registry/',
  '/:entityName/registry/:registryName?',
];
export const ENTITY_LAUNCH_PATHS = [
  '/launch/',
  '/:entityName/launch',
  '/launch/agents',
  '/:entityName/launch/agents',
  '/launch/resources',
  '/:entityName/launch/resources',
];
export const PROJECT_BASE_PATH = '/:entityName/:projectName';
export const QUICKSTART_PATH = '/quickstart/:framework?';
export const ONBOARDING_FLOW_PATH = `/signup`;
export const PROJECT_PATHS = [
  PROJECT_BASE_PATH,
  '/:entityName/:projectName/:tab(overview|workspace|table|reportlist|sweeps|launch|artifacts)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName(new)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCommitHash(new)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/_view/:artifactCollectionView',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/:artifactCommitHash/:artifactTab(files)/:filePath*',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/:artifactCommitHash/:artifactTab?',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName',
  '/:entityName/:projectName/:tab(launch)/:launchTab?',
];
export const RUN_PATHS = [
  '/:entityName/:projectName/runs/:runName',
  '/:entityName/:projectName/runs/:runName/:tab(files|code)/:filePath*',
  '/:entityName/:projectName/runs/:runName/:tab',
];
export const GROUP_PATHS = [
  '/:entityName/:projectName/groups/:groupName',
  '/:entityName/:projectName/groups/:groupName/:tab(files)/:filePath*',
  '/:entityName/:projectName/groups/:groupName/:tab',
];
export const SWEEP_PATHS = [
  '/:entityName/:projectName/sweeps/:sweepName',
  '/:entityName/:projectName/sweeps/:sweepName/:tab',
];
export const BENCHMARK_PATHS = [
  '/:entityName/:projectName/benchmark',
  '/:entityName/:projectName/benchmark/:tab/:threadID?',
];
