import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {blue} from '@wandb/common/css/globals.styles';
import {ApolloCurrentQueryResult} from 'apollo-client';
import * as _ from 'lodash';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {CellInfo, Column} from 'react-table';
import {Button, Modal, Popup} from 'semantic-ui-react';

import {
  FetchRunQueuesFromProjectQuery,
  RunQueue,
  useDeleteFromRunQueueMutation,
  useRunDisplayNameQuery,
} from '../generated/graphql';
import {ProjectPageQueryData} from '../state/graphql/projectPageQuery';
import * as urls from '../util/urls';
import * as S from './RunQueueItemsTable.styles';
import {RunQueueItemWithRunInfo} from './RunQueueTab';

interface DeleteRunQueueInfo {
  runQueueId: string;
  runQueueItemId: string;
}

interface DeleteRunQueueItemModalProps {
  open: boolean;
  deleteRunQueueInfo: DeleteRunQueueInfo | null;
  onClose(): void;
  onDelete(): void;
}

const DeleteRunQueueItemModal: React.FC<DeleteRunQueueItemModalProps> = ({
  open,
  deleteRunQueueInfo,
  onClose,
  onDelete,
}) => {
  const [deleteFromRunQueue] = useDeleteFromRunQueueMutation();
  const [deletingRunQueueItem, setDeletingRunQueueItem] = useState(false);
  if (deleteRunQueueInfo == null) {
    return <></>;
  }
  return (
    <Modal className="runQueue-item-delete-modal" open={open} onClose={onClose}>
      <Modal.Content>
        <p>Are you sure you want to DELETE this queue item?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          color="red"
          disabled={deletingRunQueueItem}
          loading={deletingRunQueueItem}
          onClick={async e => {
            setDeletingRunQueueItem(true);
            e.preventDefault();
            e.stopPropagation();
            await deleteFromRunQueue({
              variables: {
                queueID: deleteRunQueueInfo.runQueueId,
                runQueueItemId: deleteRunQueueInfo.runQueueItemId,
              },
            });
            setDeletingRunQueueItem(false);
            onDelete();
          }}>
          Delete this item
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface ForkedRunCellProps {
  entityName: string;
  projectName: string;
  runName: string;
}

const ForkedRunCell: React.FC<ForkedRunCellProps> = ({
  entityName,
  projectName,
  runName,
}) => {
  const runQuery = useRunDisplayNameQuery({
    variables: {
      entityName,
      projectName,
      runName,
    },
  });

  if (runQuery.data?.project?.run?.displayName != null) {
    return (
      <Link to={urls.run({entityName, projectName, name: runName})}>
        <p style={{color: blue}}>{runQuery.data?.project?.run?.displayName}</p>
      </Link>
    );
  } else {
    return <>-</>;
  }
};

interface RunQueuesItemsTableProps {
  runQueue: RunQueue;
  runQueueItemsWithRunInfo: RunQueueItemWithRunInfo[];
  projectName: string;
  entityName: string;
  launchAgentsMap: {[key: string]: string};
  refetchQueues: () => Promise<
    ApolloCurrentQueryResult<
      ProjectPageQueryData | FetchRunQueuesFromProjectQuery
    >
  >;
}

export const RunQueueItemsTable: React.FC<RunQueuesItemsTableProps> = props => {
  const {
    runQueue,
    runQueueItemsWithRunInfo,
    projectName,
    entityName,
    launchAgentsMap,
    refetchQueues,
  } = props;

  const [deletingRunQueueItem, setDeletingRunQueueItem] =
    useState<DeleteRunQueueInfo | null>(null);

  const sortedRunQueueItemsWithRunInfo = _.sortBy(
    runQueueItemsWithRunInfo,
    rqi => new Date(rqi.createdAt + 'Z').getTime()
  );
  let ind = 0;
  const rowData = sortedRunQueueItemsWithRunInfo.map(rqi => {
    if (rqi.runName == null) {
      ind += 1;
    }
    const runName = rqi.runName ?? `Run ${ind}`;
    const spec = rqi.runSpec;

    const forkedRunUri = spec.uri;
    const launchAgentName =
      rqi.launchAgentId != null && launchAgentsMap[rqi.launchAgentId] != null
        ? launchAgentsMap[rqi.launchAgentId]
        : '-';
    return {
      searchString: runName,
      row: {
        runInfo: {
          urlName: rqi.urlName,
          displayName: runName,
          projectName: spec.project,
        },
        rqiDeleteInfo: {
          runQueueId: runQueue.id,
          runQueueItemId: rqi.id,
        },
        state: rqi.runState,
        resource: spec.resource,
        forkedRun: forkedRunUri,
        agentId: launchAgentName,
      },
    };
  });

  const runColumn: Column = {
    Header: 'Run',
    accessor: 'runInfo',
    className: 'run',
    Cell: (cellInfo: CellInfo) => {
      if (cellInfo.value.urlName == null) {
        return <span className="run-string">{cellInfo.value.displayName}</span>;
      }
      return (
        <>
          <Link
            to={urls.run({
              entityName,
              projectName: cellInfo.value.projectName,
              name: cellInfo.value.urlName,
            })}
            className="link">
            <p style={{color: blue}}>
              {cellInfo.value.projectName}/{cellInfo.value.displayName}
            </p>
          </Link>
        </>
      );
    },
  };

  const statusColumn: Column = {
    Header: 'Status',
    accessor: 'state',
    className: 'state',
    Cell: (cellInfo: CellInfo) => (
      <>
        <span className="run-string">{cellInfo.value}</span>
      </>
    ),
  };

  const resourceColumn: Column = {
    Header: 'Resource',
    accessor: 'resource',
    className: 'resource',
    Cell: (cellInfo: CellInfo) => (
      <>
        <span className="run-string">{cellInfo.value}</span>
      </>
    ),
  };

  const forkedRunColumn: Column = {
    Header: 'Forked Run',
    accessor: 'forkedRun',
    className: 'forked-run',
    Cell: (cellInfo: CellInfo) => {
      if (cellInfo.value == null) {
        return <>-</>;
      }
      if (!cellInfo.value.startsWith('http')) {
        return <>-</>;
      }
      const uriInfo = cellInfo.value.split('/').slice(-4);
      if (uriInfo.length !== 4) {
        return <>-</>;
      }
      const uriEntityName = uriInfo[0];
      const uriProjectName = uriInfo[1];
      const uriRunName = uriInfo[3];
      return (
        <>
          <ForkedRunCell
            entityName={uriEntityName}
            projectName={uriProjectName}
            runName={uriRunName}
          />
        </>
      );
    },
  };

  const agentColumn: Column = {
    Header: 'Agent',
    accessor: 'agentId',
    className: 'agent',
    Cell: (cellInfo: CellInfo) => {
      return (
        <>
          <span className="run-string">{cellInfo.value}</span>
        </>
      );
    },
  };

  const deleteColumn: Column = {
    Header: '',
    accessor: 'rqiDeleteInfo',
    className: 'delete',
    Cell: (cellInfo: CellInfo) => {
      return (
        <LegacyWBIcon
          style={{cursor: 'pointer'}}
          name="delete"
          onClick={async (e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setDeletingRunQueueItem(cellInfo.value);
          }}
        />
      );
    },
  };

  const columns = [
    runColumn,
    statusColumn,
    resourceColumn,
    forkedRunColumn,
    agentColumn,
    deleteColumn,
  ];

  return (
    <>
      {deletingRunQueueItem != null && (
        <DeleteRunQueueItemModal
          open
          deleteRunQueueInfo={deletingRunQueueItem}
          onClose={() => {
            setDeletingRunQueueItem(null);
          }}
          onDelete={async () => {
            window.analytics?.track('Deleted run queue item', {
              sourceEntity: entityName,
              sourceProject: projectName,
            });
            await refetchQueues();
            setDeletingRunQueueItem(null);
          }}
        />
      )}
      <S.StyledReactTable
        data={rowData}
        columns={columns}
        headerInfo={
          <Popup
            popperModifiers={{
              preventOverflow: {
                boundariesElement: 'offsetParent',
              },
            }}
            content="All runs that have been successfully started are now available in the Project table. This includes all finished, crashed and killed runs."
            inverted
            size="mini"
            on="hover"
            position="top left"
            hoverable
            trigger={
              <S.HeaderInfo>
                View finished runs in the{' '}
                <S.HeaderLink href={urls.projectTable(entityName, projectName)}>
                  {' '}
                  project table →
                </S.HeaderLink>
              </S.HeaderInfo>
            }
          />
        }
      />
    </>
  );
};
