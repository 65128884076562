import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import config from '@wandb/common/config';
import {PUBLISHED_PROJECT_NAME} from '@wandb/common/util/constants';
import * as String from '@wandb/common/util/string';
import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Breadcrumb} from 'semantic-ui-react';

import {useSelector} from '../state/hooks';
import {
  tagFromQS,
  useDiscussionTag,
  useFormatTag,
  usePostTag,
} from '../util/gallery';
import history from '../util/history';
import {trackBreadcrumbClicked} from '../util/navigation';
import {AccessOptions} from '../util/permissions';
import {isUsingAccessToken} from '../util/projectAccess';
import * as URL from '../util/url';
import * as links from '../util/urls';
import ProjectAccess, {ProjectAccessProject} from './ProjectAccess';

interface BreadcrumbsProps {
  entityName: string;
  projectName?: string;
  tab?: string;
  filePath?: string;
  project?: ProjectAccessProject;
  updateProjectAccess?: (access: AccessOptions) => void;
  sweepName?: string;
  sweepDisplayName?: string;
  runName?: string;
  runDisplayName?: string;
  runState?: string;

  groupName?: string;

  reportNameAndId?: string;
  authorName?: string;

  artifactTypeName?: string;
  artifactCollectionName?: string;
  artifactCommitHash?: string;
  artifactTab?: string;

  isBenchmark?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = React.memo(
  ({
    entityName,
    projectName,
    tab,
    filePath,
    project,
    updateProjectAccess,
    sweepName,
    sweepDisplayName,
    runName,
    runDisplayName,
    runState,
    groupName,
    reportNameAndId,
    authorName,
    artifactTypeName,
    artifactCollectionName,
    artifactCommitHash,
    artifactTab,
    isBenchmark,
  }) => {
    const postTag = usePostTag();
    const discussionTag = useDiscussionTag();
    const galleryTag = tagFromQS();
    const formattedGalleryTag = useFormatTag(galleryTag ?? '');

    const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;

    // HAXX: would be better if we have a way to get the user
    //       with the entityname and get the accountType of the user
    const isAnonymous =
      entityName.lastIndexOf('anony-moose-', 0) === 0 ||
      entityName.lastIndexOf('anony-mouse-', 0) === 0;

    const projObjType =
      groupName != null
        ? 'groups'
        : sweepName != null
        ? 'sweeps'
        : reportNameAndId != null
        ? 'reports'
        : runName != null
        ? 'runs'
        : undefined;
    const projObjName =
      groupName || groupName || sweepName || reportNameAndId || runName;
    let reportName = '';
    let reportId = '';
    if (reportNameAndId) {
      try {
        const parsed = URL.parseNameAndID(reportNameAndId || '');
        reportName = parsed.name;
        reportId = parsed.id;
      } catch {
        // do nothing
      }
    }

    // Special characters in international report names are stripped from the url.
    // Need to access the report view in redux to fetch the display name for nav breadcrumbs.
    const view = useSelector(state => {
      if (projObjType !== 'reports') {
        return null;
      }
      return Object.values(state.views.views).find(v => v.id === reportId);
    });
    reportName = view?.displayName ?? reportName;

    const tabName = tab === 'reportlist' ? 'reports' : tab;
    const filePathParts = URL.parseRunTabPath(filePath);

    // HAX: this works with host/src/report.ts to ensure the app does not override custom SEO breadcrumbs
    const seoBreadcrumbs: Array<{
      name: string;
      path: string;
    }> = (window as any).__WB_SEO_BREADCRUMBS;
    if (seoBreadcrumbs != null) {
      const withoutDividers = seoBreadcrumbs.map(({name, path}) => (
        <Breadcrumb.Section key={`${name}-${path}`}>
          <NavLink to={path}>{name}</NavLink>
        </Breadcrumb.Section>
      ));
      const withDividers = [];
      let i = 0;
      for (const b of withoutDividers) {
        withDividers.push(b);
        withDividers.push(
          <Breadcrumb.Divider
            key={`divider-${i}`}
            icon={<LegacyWBIcon name="next" />}
          />
        );
        i++;
      }
      withDividers.splice(-1, 1);
      return (
        <div className="nav-breadcrumbs-wrapper">
          <Breadcrumb className="nav-breadcrumbs" size="small">
            {withDividers}
          </Breadcrumb>
        </div>
      );
    }

    if (URL.isOnReportView() && isUsingAccessToken()) {
      return null;
    }

    if (
      projectName == null &&
      reportNameAndId != null &&
      !URL.isOnPublishedReportView() &&
      postTag != null &&
      discussionTag != null
    ) {
      const isDiscussion = URL.isOnGalleryDiscussionPage();
      const tag = isDiscussion ? discussionTag : postTag;
      const tagLinkForm = tag.linkForm;
      const label = isDiscussion ? `Discussions` : `Posts`;
      return (
        <div className="nav-breadcrumbs-wrapper">
          <Breadcrumb className="nav-breadcrumbs" size="small">
            <Breadcrumb.Section>
              <NavLink to={links.reportGallery()}>Fully Connected</NavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            <Breadcrumb.Section>
              <NavLink
                to={links.reportGallery({
                  tag: tagLinkForm,
                })}>
                {label}
              </NavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            <Breadcrumb.Section>
              <NavLink
                onClick={() => trackBreadcrumbClicked('Report')}
                to={`${(isDiscussion
                  ? links.galleryDiscussionView
                  : links.galleryPostView)({
                  entityName,
                  reportID: reportId,
                  reportName,
                })}${window.location.search}`}>
                {reportName}
              </NavLink>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
      );
    }

    if (reportNameAndId != null && galleryTag != null) {
      return (
        <div className="nav-breadcrumbs-wrapper">
          <Breadcrumb className="nav-breadcrumbs" size="small">
            <Breadcrumb.Section>
              <NavLink to={links.reportGallery()}>Fully Connected</NavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            <Breadcrumb.Section>
              <NavLink to={links.reportGallery({tag: galleryTag})}>
                {formattedGalleryTag}
              </NavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            <Breadcrumb.Section>
              <NavLink
                onClick={() => trackBreadcrumbClicked('Report')}
                to={`${links.reportView({
                  entityName,
                  projectName: projectName ?? PUBLISHED_PROJECT_NAME,
                  reportID: reportId,
                  reportName,
                })}${window.location.search}`}>
                {reportName}
              </NavLink>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
      );
    }

    if (URL.isOnPublishedReportView() && entityName != null) {
      return (
        <div className="nav-breadcrumbs-wrapper">
          <Breadcrumb className="nav-breadcrumbs" size="small">
            <Breadcrumb.Section>
              <NavLink
                onClick={() => trackBreadcrumbClicked('Entity')}
                to={`/${entityName}`}>
                {entityName}
              </NavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            <Breadcrumb.Section>
              <NavLink to={`/${entityName}`}>Published Work</NavLink>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            <Breadcrumb.Section>
              <NavLink
                onClick={() => trackBreadcrumbClicked('Report')}
                to={`${links.publishedReportView({
                  entityName,
                  reportID: reportId,
                  reportName,
                })}${window.location.search}`}>
                {reportName}
              </NavLink>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
      );
    }

    return (
      <div className="nav-breadcrumbs-wrapper">
        <Breadcrumb className="nav-breadcrumbs" size="small">
          {isBenchmark && (
            <>
              <Breadcrumb.Section>
                {isOnPrem ? (
                  'Benchmarks'
                ) : (
                  <a href="/site/benchmarks">Benchmarks</a> // eslint-disable-line wandb/no-a-tags
                )}
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            </>
          )}
          <Breadcrumb.Section>
            {isAnonymous ? (
              <>{entityName}</>
            ) : (
              <NavLink
                onClick={() => trackBreadcrumbClicked('Entity')}
                to={`/${entityName}`}>
                {entityName}
              </NavLink>
            )}
          </Breadcrumb.Section>
          {projectName != null && (
            <>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Projects')}
                  to={`/${entityName}/projects`}>
                  Projects
                </NavLink>
              </Breadcrumb.Section>
            </>
          )}

          {projectName != null && (
            <>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                {project != null && updateProjectAccess != null && (
                  <ProjectAccess
                    project={project}
                    compact
                    updateProjectAccess={updateProjectAccess}
                  />
                )}
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Project')}
                  to={links.project({
                    entityName,
                    name: projectName,
                  })}>
                  {projectName}
                </NavLink>
              </Breadcrumb.Section>
            </>
          )}
          {projectName != null && sweepName != null && (
            <React.Fragment>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Sweeps')}
                  to={links.projectSweeps({
                    entityName,
                    name: projectName,
                  })}>
                  Sweeps
                </NavLink>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Sweep')}
                  to={links.sweep({
                    entityName,
                    projectName,
                    sweepName,
                  })}>
                  {sweepDisplayName}
                </NavLink>
              </Breadcrumb.Section>
            </React.Fragment>
          )}
          {projectName != null && runName != null && (
            <React.Fragment>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Runs')}
                  to={links.project({
                    entityName,
                    name: projectName,
                  })}>
                  Runs
                </NavLink>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => {
                    let itemClicked = 'Run';
                    if (groupName != null) {
                      itemClicked = 'Group run';
                    } else if (sweepName != null) {
                      itemClicked = 'Sweep run';
                    }
                    trackBreadcrumbClicked(itemClicked);
                  }}
                  to={links.run({
                    entityName,
                    projectName,
                    name: runName,
                  })}>
                  {runDisplayName || runName}
                </NavLink>
              </Breadcrumb.Section>
            </React.Fragment>
          )}
          {projectName != null && groupName != null && (
            <React.Fragment>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>Run Groups</Breadcrumb.Section>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Group')}
                  to={links.runGroup({
                    entityName,
                    projectName,
                    name: groupName,
                  })}>
                  {groupName}
                </NavLink>
              </Breadcrumb.Section>
            </React.Fragment>
          )}
          {projectName != null && reportNameAndId != null && (
            <React.Fragment>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Reports')}
                  to={links.reportList({
                    entityName,
                    projectName,
                  })}>
                  Reports
                </NavLink>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Report')}
                  to={links.reportView({
                    entityName,
                    projectName,
                    reportID: reportId,
                    reportName,
                  })}>
                  {reportName}
                </NavLink>
              </Breadcrumb.Section>
            </React.Fragment>
          )}
          {projectName != null && tabName != null && tab != null && (
            <React.Fragment>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => {
                    if (tabName === 'artifacts') {
                      trackBreadcrumbClicked(String.capitalizeFirst(tabName));
                    }
                  }}
                  to={links.projectObjTab(
                    entityName,
                    projectName,
                    projObjType,
                    projObjName,
                    tab
                  )}>
                  {String.capitalizeFirst(tabName)}
                </NavLink>
              </Breadcrumb.Section>
            </React.Fragment>
          )}
          {projectName != null && artifactTypeName != null && (
            <React.Fragment>
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
              <Breadcrumb.Section>
                <NavLink
                  onClick={() => trackBreadcrumbClicked('Artifact type')}
                  to={links.artifactType({
                    entityName,
                    projectName,
                    artifactTypeName,
                  })}>
                  {artifactTypeName}
                </NavLink>
              </Breadcrumb.Section>
            </React.Fragment>
          )}
          {projectName != null &&
            artifactTypeName != null &&
            artifactCollectionName != null && (
              <React.Fragment>
                <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
                <Breadcrumb.Section>
                  <NavLink
                    onClick={() =>
                      trackBreadcrumbClicked('Artifact collection')
                    }
                    to={links.artifactSequence({
                      entityName,
                      projectName,
                      artifactTypeName,
                      artifactSequenceName: artifactCollectionName,
                    })}>
                    {artifactCollectionName}
                  </NavLink>
                </Breadcrumb.Section>
              </React.Fragment>
            )}
          {projectName != null &&
            artifactTypeName != null &&
            artifactCollectionName != null &&
            artifactCommitHash != null && (
              <React.Fragment>
                <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
                <Breadcrumb.Section>
                  <NavLink
                    onClick={() => trackBreadcrumbClicked('Artifact')}
                    to={links.artifact({
                      entityName,
                      projectName,
                      artifactTypeName,
                      artifactCollectionName,
                      artifactCommitHash,
                    })}>
                    {artifactCommitHash.slice(0, 9)}
                  </NavLink>
                </Breadcrumb.Section>
              </React.Fragment>
            )}
          {projectName != null &&
            artifactTypeName != null &&
            artifactCollectionName != null &&
            artifactCommitHash != null &&
            artifactTab != null && (
              <React.Fragment>
                <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
                <Breadcrumb.Section>
                  <NavLink
                    to={links.artifactTab({
                      entityName,
                      projectName,
                      artifactTypeName,
                      artifactCollectionName,
                      artifactCommitHash,
                      tabName: artifactTab,
                    })}>
                    {artifactTab}
                  </NavLink>
                </Breadcrumb.Section>
              </React.Fragment>
            )}
          {projectName != null &&
            artifactTypeName != null &&
            artifactCollectionName != null &&
            artifactCommitHash != null &&
            filePathParts != null && (
              <>
                {filePathParts.map((p, i) => (
                  <React.Fragment key={'file-path-' + p}>
                    <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
                    <Breadcrumb.Section>
                      <NavLink
                        to={links.artifactFile({
                          entityName,
                          projectName,
                          artifactTypeName,
                          artifactSequenceName: artifactCollectionName,
                          artifactCommitHash,
                          path: filePathParts.slice(0, i + 1),
                        })}>
                        {p}
                      </NavLink>
                    </Breadcrumb.Section>
                  </React.Fragment>
                ))}
              </>
            )}
          {runName != null && filePathParts != null && (
            <>
              {filePathParts.map((p, i) => (
                <React.Fragment key={'file-path-' + p}>
                  <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
                  <Breadcrumb.Section>
                    {/* We have to explicitly use an 'a' element here.
                      React-router's NavLink element runs the inconsistent URI decode when creating
                      an 'a' element under the hood, and when a user clicks the 'a' element, then
                      it decodes again because NavLink uses history.push().
                      So, we have double decoding going on */}
                    {/* eslint-disable-next-line wandb/no-a-tags */}
                    <a
                      onClick={e => {
                        e.preventDefault();
                        history.push(
                          links.runTabFile(
                            entityName,
                            projectName,
                            runName!,
                            filePathParts.slice(0, i + 1)
                          )
                        );
                      }}
                      // we want the link to appear correctly when hovered over. If we don't
                      // disable the historyHax here then we get our jank string-replaced link.
                      href={links.runTabFile(
                        entityName,
                        projectName,
                        runName!,
                        filePathParts.slice(0, i + 1),
                        true
                      )}>
                      {p}
                    </a>
                  </Breadcrumb.Section>
                </React.Fragment>
              ))}
            </>
          )}
        </Breadcrumb>
      </div>
    );
  }
);

/**
 * A component that will render breadcrumbs based on the path
 * components of the current URL. The primary `Breadcrumb` component
 * above needs custom branching for each route. This component
 * blindly splits the path and makes routes for each path component.
 */
export const PathBasedBreadcrumbs: React.FC = React.memo(() => {
  const location = useLocation();
  const parts = React.useMemo(() => {
    const pathParts = ((location as any)?.pathname ?? '')
      .split('/')
      .filter((part: string) => part !== '');
    return pathParts.map((part: string, ndx: number) => ({
      text: part,
      link: '/' + pathParts.slice(0, ndx + 1).join('/'),
    }));
  }, [location]);
  return <StatelessBreadcrumbs parts={parts} />;
});

/**
 * This is a stateless breadcrumb component that can be used to
 * render simple breadcrumbs in our app header.
 *
 * TODO: Refactor the big `Breadcrumbs` component above to use this.
 */
const StatelessBreadcrumbs: React.FC<{
  parts: Array<{
    text: string;
    link: string;
  }>;
  divider?: React.ComponentProps<typeof Breadcrumb.Divider>['icon'];
}> = React.memo(props => {
  const divider = props.divider ?? <LegacyWBIcon name="next" />;
  const parts = props.parts;
  return (
    <div className="nav-breadcrumbs-wrapper">
      <Breadcrumb className="nav-breadcrumbs" size="small">
        {parts.map((part, ndx) => {
          const needsDivider = ndx < parts.length - 1;
          return (
            <span key={`${part.text}-${part.link}-${needsDivider}`}>
              <Breadcrumb.Section>
                <NavLink to={part.link}>{part.text}</NavLink>
              </Breadcrumb.Section>
              {needsDivider && divider}
            </span>
          );
        })}
      </Breadcrumb>
    </div>
  );
});
