import * as Op from '@wandb/cg';
import {useGatedValue} from '@wandb/common/state/hooks';
import * as LLReact from '@wandb/weave-ui/cgreact';
import {getPanelStacksForType} from '@wandb/weave-ui/components/Panel2/availablePanels';
import {themes} from '@wandb/weave-ui/components/Panel2/Editor.styles';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {
  PanelComp2,
  TransactionalPanelConfigEditor,
} from '@wandb/weave-ui/components/Panel2/PanelComp';
import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {Button, Dropdown} from 'semantic-ui-react';
import {ThemeProvider} from 'styled-components';

import {AddToReportModal} from '../AddToReportModal';
import {InstrumentedLoader as Loader} from './../../components/utility/InstrumentedLoader';

const inputType = {
  type: 'union' as const,
  members: [
    'artifactVersion' as const,
    {type: 'list' as const, objectType: 'artifactVersion' as const},
  ],
};

export interface PanelFilesConfig {
  path?: string[];
}

type FilesProps = Panel2.PanelProps<typeof inputType, PanelFilesConfig> & {
  hideHeader?: boolean;
};

export const FilesPanel: React.FC<FilesProps> = props => {
  const {config, context, updateConfig, updateContext} = props;
  const rawConfigPath = useMemo(() => config?.path, [config]);
  const configPath = useMemo(() => rawConfigPath ?? [], [rawConfigPath]);
  const pathString = useMemo(
    () => (Array.isArray(configPath) ? configPath.join('/') : configPath),
    [configPath]
  );

  const artifacts = props.input;

  const files = useMemo(() => {
    return Op.applyOpToOneOrMany(
      Op.opArtifactVersionFile,
      'artifactVersion',
      artifacts,
      {
        path: Op.constString(pathString),
      }
    );
  }, [artifacts, pathString]);
  const fileWithTypeRaw = LLReact.useNodeWithServerType(files);
  const fileWithType = useGatedValue(fileWithTypeRaw, ft => !ft.loading);

  const params = useParams<{reportNameAndID?: string}>();
  const showExportToReportButton = params.reportNameAndID == null;

  // We currently just store configs of our previews here, keyed by previewer
  // type. This is probably not really right. Maybe use file path?
  // TODO: better config strategy
  const [subConfigs, setSubConfigs] = useState<{[key: string]: any}>({});

  const [curPanelIdState, setCurPanelId] = useState<string | undefined>(
    undefined
  );

  const panelInputType = fileWithType.result.type;
  const {curPanelId, stackIds, handler} = useMemo(
    () => getPanelStacksForType(panelInputType, curPanelIdState),
    [panelInputType, curPanelIdState]
  );
  const finalInput = useMemo(() => {
    return fileWithType.result;
  }, [fileWithType.result]);

  const panelConfig = useMemo(
    () =>
      curPanelId != null ? subConfigs[curPanelId + '-' + pathString] : null,
    [curPanelId, pathString, subConfigs]
  );

  const panelUpdateConfig = useCallback(
    (newConfig: any) => {
      if (curPanelId != null) {
        // console.log("updating",newConfig )
        setSubConfigs(curSubConfigs => ({
          ...curSubConfigs,
          [curPanelId! + '-' + pathString]: {
            ...curSubConfigs[curPanelId! + '-' + pathString],
            ...newConfig,
          },
        }));
      }
    },
    [curPanelId, pathString]
  );

  const newPanelContext = useMemo(
    () => ({
      ...context,
      path: configPath,
    }),
    [context, configPath]
  );

  const panelUpdateContext = useCallback(
    (newContext: any) => {
      if (newContext.path != null) {
        updateConfig({path: newContext.path});
      }
      updateContext(newContext);
    },
    [updateConfig, updateContext]
  );

  if (fileWithTypeRaw.loading) {
    return <Loader name="panel-files-loader" />;
  }

  return (
    <ThemeProvider theme={themes.light}>
      <div
        className="file-browser artifact-file-browser"
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
          padding: 0,
        }}>
        {!props.hideHeader && (
          <div
            className="file-browser-path"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
              position: 'sticky',
              left: '0px',
              zIndex: 1001,
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: '0 0 auto',
              }}>
              <div
                style={{
                  fontSize: 24,
                  opacity: 0.7,
                }}>
                &gt;&nbsp;
                {['root'].concat(configPath).map((folderName, i) => {
                  const newPath = configPath.slice(0, i);
                  return [
                    <span
                      className="file-browser-path-item"
                      style={{cursor: 'pointer'}}
                      key={'path' + i}
                      onClick={e => updateConfig({path: newPath})}>
                      {folderName}
                    </span>,
                    i !== configPath.length ? ' / ' : undefined,
                  ];
                })}
              </div>

              {stackIds.length > 1 && (
                <Dropdown
                  selection
                  className="clone-report-modal__select"
                  style={{marginLeft: 24, zIndex: 1001}}
                  options={stackIds.map(si => ({
                    key: si.id,
                    text: si.displayName,
                    value: si.id,
                  }))}
                  value={curPanelId}
                  onChange={(e, {value}) => setCurPanelId(value as string)}
                />
              )}
            </div>
            {showExportToReportButton &&
              curPanelId != null &&
              window.location.pathname.indexOf('reports') === -1 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <AddToReportModal
                    trigger={<Button size="tiny">Add to report</Button>}
                    reportBlock={{
                      type: 'weave-panel',
                      config: {
                        panelConfig: {
                          panelConfig,
                          exp: fileWithType.result,
                          panelId: curPanelId,
                        },
                        height: 800,
                      },
                      children: [{text: ''}],
                    }}
                  />
                </div>
              )}
          </div>
        )}
        <>
          {handler != null && (
            <div style={{flex: '0 1 auto', overflow: 'show'}}>
              <TransactionalPanelConfigEditor
                panelSpec={handler}
                input={finalInput as any}
                config={panelConfig}
                updateConfig={panelUpdateConfig}
              />
            </div>
          )}
          <div style={{flex: '1 1 auto', overflow: 'hidden'}}>
            {handler != null ? (
              <PanelComp2
                panelSpec={handler}
                input={finalInput as any}
                inputType={panelInputType}
                config={panelConfig}
                configMode={false}
                context={newPanelContext}
                updateConfig={panelUpdateConfig}
                updateContext={panelUpdateContext}
              />
            ) : (
              <div>no preview</div>
            )}
          </div>
        </>
      </div>
    </ThemeProvider>
  );
};
