import {TargetBlank} from '@wandb/common/util/links';
import * as _ from 'lodash';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Image, Popup} from 'semantic-ui-react';

import {DataRow} from '../../components/WBReactTable';
import {BenchmarkRun} from '../../generated/graphql';
import {WithSummary} from '../../types/graphql';
import * as RunHelpers from '../../util/runhelpers';
import {LookupKey, lookupKey} from '../../util/runs';
import * as url from '../../util/urls';

// enable ranking benchmark submissions by submission timestamp
// instead of any particular result metric
export const RANK_BY_TIMESTAMP = 'submissionTime';

export const cols = [
  {
    id: 'rank',
    minWidth: 50,
    Cell: (row: any) => {
      return (
        <>
          <span style={{opacity: 0.4}}>#</span>
          {row.index + 1}
        </>
      );
    },
  },
  {
    Header: 'Run',
    id: 'run',
    minWidth: 200,
    accessor: (br: WithSummary<BenchmarkRun>) => {
      return (
        <Link to={url.benchmarkRunOverview(br)}>{br.run.displayName}</Link>
      );
    },
  },
  {
    Header: 'Author',
    id: 'author',
    minWidth: 160,
    accessor: (br: WithSummary<BenchmarkRun>) => {
      return (
        <Link to={`/${br.user.username}`}>
          <Image
            src={br.user.photoUrl}
            avatar
            onError={(i: any) => (i.target.style.display = 'none')}
          />
          {br.user.username}
        </Link>
      );
    },
  },
  {
    Header: 'Code',
    id: 'code',
    minWidth: 70,
    accessor: (br: WithSummary<BenchmarkRun>) => {
      return (
        <>
          {br.run.github && br.run.commit && (
            <TargetBlank href={br.run.github}>
              <Popup
                trigger={<Icon name="github" />}
                content="View commit on GitHub"
              />
            </TargetBlank>
          )}
          {RunHelpers.hasDiffPatchFile(br.run as any) && (
            <Link
              to={url.runDiffPatchFile({
                entityName: br.benchmark.entityName,
                projectName: br.benchmark.name,
                runName: br.run.name,
              } as any)}>
              <Popup
                trigger={<Icon name="file alternate outline" />}
                content="This run had uncommitted changes. You can see them by clicking to download this diff.patch file."
              />
            </Link>
          )}
        </>
      );
    },
  },
];

// convert the run.created_at timestamp string to a value
function stringToVal(dateStr: string): number {
  return new Date(dateStr).valueOf();
}

interface Opts {
  showMissing?: boolean;
  calculatedResults?: boolean;
}

// rowsForMetric is a utility function for building rows for tables
//               from runs
// runs: runs to display in table
// metric: a key as a string, will lookup a value in the summary
//         or an array of values
//
export function rowsForMetric(
  runs: Array<WithSummary<BenchmarkRun>>,
  metric: LookupKey,
  desc: boolean, // sort order for the evaluation metric
  opts?: Opts
) {
  const filtered =
    opts && opts.showMissing
      ? runs
      : _.filter(runs, r =>
          // allow a sort by submission time instead of any particular result metric
          metric === RANK_BY_TIMESTAMP
            ? r.createdAt != null
            : lookupKey(r, metric)
        );

  // sortby typing doesn't work with function iterators, so we have to cast.
  let sortedRuns = _.sortBy(filtered, [
    (run: WithSummary<BenchmarkRun>) => {
      if (opts && opts.calculatedResults) {
        return run.results;
        // return submission time stamps as numeric values
      } else if (metric === RANK_BY_TIMESTAMP) {
        return stringToVal(run.createdAt as any);
      } else {
        return lookupKey(run, metric);
      }
    },
  ]) as Array<WithSummary<BenchmarkRun>>;

  if (desc) {
    sortedRuns = _.reverse(sortedRuns);
  }

  const dataRows: DataRow[] = sortedRuns.map(run => {
    const dataRow: DataRow = {
      searchString: run.user.username + ' ' + run.run.name,
      row: run,
    };
    return dataRow;
  });

  return dataRows;
}
