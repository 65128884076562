import Tooltip from '@material-ui/core/Tooltip';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import produce from 'immer';
import _ from 'lodash';
import React, {ReactNode} from 'react';
import {Button, Popup} from 'semantic-ui-react';

import * as Query from '../../util/queryts';
import {DEFAULT_RUNS_SORT, DEFAULT_RUNS_SORT_KEY} from '../../util/queryts';
import * as Run from '../../util/runs';
import * as UIHelpers from '../../util/uihelpers';
import * as S from './WBTableActionsSort.styles';

interface WBTableActionSortButtonProps {
  compact?: boolean;
  disabled?: boolean;
  sortOpen: boolean;
  defaultSort?: Query.Sort;
  isInReport?: boolean;
  sort: Query.Sort;
}

export const WBTableActionSortButton = (
  props: WBTableActionSortButtonProps
) => {
  let sortButtonClass;

  const {isInReport, compact, sort, sortOpen, defaultSort, ...rest} = props;

  if (props.sortOpen) {
    sortButtonClass = 'action-button--focused';
  } else if (
    !_.isEqual(
      props.sort.keys,
      props.defaultSort?.keys ?? DEFAULT_RUNS_SORT.keys
    )
  ) {
    sortButtonClass = 'action-button--active';
  } else {
    sortButtonClass = 'action-button--static';
  }

  const buttonText = 'Sort';
  return (
    <Tooltip
      placement="top"
      enterDelay={0}
      title={props.compact ? 'Sort' : ''}
      arrow>
      <span
        onClick={() => {
          const actionLocation = props.isInReport ? 'report' : 'runs table';
          window.analytics?.track('Sort dropdown clicked', {
            location: actionLocation,
          });
        }}>
        <Button
          {...rest} // Required for use as popup trigger
          disabled={props.disabled}
          data-test="sort-popup"
          size="tiny"
          className={sortButtonClass + ' wb-icon-button table-sort-button'}>
          <LegacyWBIcon name="sort" title={buttonText} />
          {props.compact ? '' : buttonText}
        </Button>
      </span>
    </Tooltip>
  );
};

interface WBTableActionSortDropdownProps {
  sortableKeys: string[];
  loading: boolean;
  sort: Query.Sort;
  sortKeyIdx: number;

  setSort(sort: Query.Sort): void;
}

export const WBTableActionSortDropdown = (
  props: WBTableActionSortDropdownProps
) => {
  const {sortableKeys, loading, sort, sortKeyIdx, setSort} = props;
  const sortKey = sort.keys[sortKeyIdx] ?? DEFAULT_RUNS_SORT_KEY;
  const options = UIHelpers.makeOptions(sortableKeys);
  return (
    <ModifiedDropdown
      data-test="sort-dropdown"
      className="sort"
      placeholder="Sort by..."
      search={UIHelpers.searchFunction}
      selection
      lazyLoad
      optionTransform={UIHelpers.beautify}
      itemLimit={100}
      options={options}
      value={Run.keyToString(sortKey.key)}
      disabled={loading}
      loading={loading}
      onChange={(e, {value}) => {
        // We know it's string because we pass in options
        const typedValue: string = value as string;
        const key = Run.keyFromString(typedValue);
        if (key != null) {
          setSort(
            produce(sort, draft => {
              draft.keys[sortKeyIdx].key = key;
            })
          );
        }
      }}
    />
  );
};

interface WBTableActionSortPickerProps {
  defaultSort?: Query.Sort; // the default sort
  sort: Query.Sort; // the current sort

  makeDropdown(sortKeyIdx: number): JSX.Element;
  setSort(sort: Query.Sort): void;
}

export const WBTableActionSortPicker = (
  props: WBTableActionSortPickerProps
) => {
  const {defaultSort, sort, makeDropdown, setSort} = props;
  return (
    <>
      {sort.keys.map(({key, ascending}, i) => (
        <S.SortPickerRow key={i}>
          <S.SortPickerRowClose
            hidden={sort.keys.length <= 1}
            onClick={() => {
              if (sort.keys.length <= 1) {
                return;
              }
              setSort(
                produce(sort, draft => {
                  draft.keys.splice(i, 1);
                })
              );
            }}
          />
          {makeDropdown(i)}
          <S.SortPickerButton
            data-test="sort-order"
            icon={`wbic-ic-${ascending ? 'up' : 'down'}-arrow`}
            onClick={() => {
              setSort(
                produce(sort, draft => {
                  draft.keys[i].ascending = !draft.keys[i].ascending;
                })
              );
            }}
          />
        </S.SortPickerRow>
      ))}
      <S.SortPickerActions>
        <S.SortPickerAction
          data-test="sort-add"
          className="fake-link"
          onClick={() => {
            setSort(
              produce(sort, draft => {
                draft.keys.push(
                  defaultSort?.keys?.[0] ?? DEFAULT_RUNS_SORT_KEY
                );
              })
            );
          }}>
          Add another field
        </S.SortPickerAction>
        <S.SortPickerAction
          data-test="sort-reset"
          className="fake-link"
          onClick={() => setSort(defaultSort ?? DEFAULT_RUNS_SORT)}>
          Reset to default
        </S.SortPickerAction>
      </S.SortPickerActions>
    </>
  );
};

interface WBTableActionSortProps {
  trigger: (isOpen: boolean) => ReactNode;
  content: ReactNode;
  open: boolean;
  onOpen(): void;
  onClose(): void;
}

export const WBTableActionSort = (props: WBTableActionSortProps) => {
  return (
    <Popup
      basic
      className="wb-table-action-popup"
      on="click"
      position="bottom left"
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      trigger={props.trigger(props.open)}
      content={props.content}
      popperModifiers={{
        preventOverflow: {enabled: false},
        flip: {enabled: false},
      }}
    />
  );
};
