import {envIsIntegration} from '@wandb/common/config';
import {
  ALL_BETA_FEATURE_KEYS,
  BetaFeature,
  BetaFeatureKey,
} from '@wandb/common/util/betaFeatures';
import {useCallback, useEffect, useMemo} from 'react';

import {useUpdateUserInfoMutation} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {UserInfo} from '../types/graphql';
import {viewingAs} from './admin';

export function useBetaFeatureRunQueueEnabled(): boolean {
  return useBetaFeature('instant replay').isEnabled;
}

export function useBetaFeatureUnicornModeEnabled(): boolean {
  return useBetaFeature('unicorn-plot').isEnabled;
}

export function useBetaFeatureModelRegistryEnabled(): boolean {
  return useBetaFeature('model-registry').isEnabled;
}

export function useBetaFeatureNightModeEnabled(): boolean {
  return useBetaFeature('night').isEnabled;
}

export function useBetaFeatureVega3Enabled(): boolean {
  return useBetaFeature('vega3').isEnabled;
}

export function useBetaFeatureWeavePythonPerformance(): boolean {
  return useBetaFeature('weave-python-performance').isEnabled;
}

export function useBetaFeatureWeave1(): boolean {
  return useBetaFeature('weave1').isEnabled;
}

export function useBetaFeatureWeavePythonEcosystem(): boolean {
  return useBetaFeature('weave-python-ecosystem').isEnabled;
}

export function useBetaFeatureAccountSettingsPageEnabled(): boolean {
  return useBetaFeature('account-settings').isEnabled;
}

export function useBetaFeature(featureKey: BetaFeatureKey): BetaFeature {
  // Migrate the user's feature flags from their bio to viewer.userInfo.betaFeatures (if we haven't done so already)
  useBetaFeaturesMigration();

  const viewer = useViewer();
  const userInfoBetaFeatures: UserInfo['betaFeatures'] =
    viewer?.userInfo?.betaFeatures;

  const [updateUserInfo] = useUpdateUserInfoMutation();

  const enableForCI =
    envIsIntegration &&
    featureKey !== 'weave-devpopup' &&
    featureKey !== 'weave-python-performance' &&
    featureKey !== 'weave1' &&
    featureKey !== 'unicorn-plot' &&
    featureKey !== 'lazy-table';

  const isEnabled =
    (enableForCI || userInfoBetaFeatures?.[featureKey]) ?? false;

  const toggleBetaFeature = useCallback(() => {
    if (viewer == null) {
      return;
    }

    updateUserInfo({
      variables: {
        userInfo: JSON.stringify({
          ...viewer.userInfo,
          betaFeatures: {
            ...userInfoBetaFeatures,
            [featureKey]: !isEnabled,
          },
        }),
      },
    });
  }, [featureKey, isEnabled, updateUserInfo, userInfoBetaFeatures, viewer]);

  return useMemo(
    () => ({isEnabled, toggleBetaFeature}),
    [isEnabled, toggleBetaFeature]
  );
}

// Beta features used to be enabled by the presence of keywords in viewer.bio.
// Now we store flags in viewer.userInfo.betaFeatures.
// This hook returns a function that migrates viewer.bio feature keywords to viewer.userInfo.betaFeatures
// Note: it does *not* delete the keywords from viewer.bio
function useBetaFeaturesMigration(): void {
  const viewer = useViewer();
  const [updateUserInfo] = useUpdateUserInfoMutation();

  const userInfoBetaFeatures = viewer?.userInfo?.betaFeatures;

  const migrateBetaFeatures = useCallback(() => {
    if (viewer == null || viewingAs()) {
      return;
    }

    // No-op if we've already migrated
    // TODO: this will infinite loop if the update fails
    if (userInfoBetaFeatures != null) {
      return;
    }

    // Search the user's bio for beta feature keywords
    const bioBetaFeatures: {[k: string]: boolean} = {};
    for (const key of ALL_BETA_FEATURE_KEYS) {
      bioBetaFeatures[key] =
        viewer?.userInfo?.bio?.toLowerCase()?.includes(key) ?? false;
    }

    // Copy bio-enabled features to viewer.userInfo.betaFeatures
    try {
      updateUserInfo({
        variables: {
          userInfo: JSON.stringify({
            ...viewer.userInfo,
            betaFeatures: bioBetaFeatures,
          }),
        },
      });
    } catch (e) {
      // TODO: fix catch
      console.log(e);
    }
  }, [updateUserInfo, userInfoBetaFeatures, viewer]);

  useEffect(migrateBetaFeatures, [migrateBetaFeatures]);
}
