// TODO: Later: Make this a panel against ArtifactVersion

import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import {trackArtifactLineageTabInteraction} from '../../../util/navigation';
import ArtifactDag from '../../ArtifactDag';

const inputType = 'artifactVersion' as const;
type ConfigType = {};

export const PanelArtifactVersionLineage: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType>
> = ({input: artifactVersionNode}) => {
  React.useEffect(() => {
    // Use to track when lineage tab is clicked
    trackArtifactLineageTabInteraction('direct-lineage-default');
  }, []);
  const {result, loading} = useNodeValue(
    React.useMemo(() => {
      // Here we do extra walks to guarantee we are looking at the sequence flavour
      // to ensure there is a hash to use for the dag.
      const artifactNode = Op.opArtifactVersionArtifactSequence({
        artifactVersion: artifactVersionNode,
      });
      const projectNode = Op.opArtifactProject({artifact: artifactNode});

      return Op.opDict({
        artifactTypeName: Op.opArtifactTypeName({
          artifactType: Op.opArtifactVersionArtifactType({
            artifactVersion: artifactVersionNode,
          }),
        }),
        entityName: Op.opEntityName({
          entity: Op.opProjectEntity({project: projectNode}),
        }),
        projectName: Op.opProjectName({
          project: projectNode,
        }),
        artifactSequenceName: Op.opArtifactName({
          artifact: artifactNode,
        }),
        commitHash: Op.opArtifactVersionHash({
          artifactVersion: artifactVersionNode,
        }),
      } as any) as unknown as GraphTypes.OutputNode<'any'>;
    }, [artifactVersionNode])
  );

  // When an anon user (not logged in) tries to view a private artifact's lineage, artifactTypeName and
  // artifactSequenceName will be null. ['private' meaning that the artifact belongs to a private project].
  if (
    loading ||
    result.artifactTypeName == null ||
    result.artifactSequenceName == null
  ) {
    return <></>;
  }
  return (
    <ArtifactDag
      entityName={result.entityName}
      projectName={result.projectName}
      artifactTypeName={result.artifactTypeName}
      artifactCollectionName={result.artifactSequenceName}
      artifactCommitHash={result.commitHash}
    />
  );
};
