import {black, gray100, gray700} from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import styled, {css} from 'styled-components';

import {GLOBAL_COLORS} from '../../../../util/colors';
import {BLOCK_WRAPPER_PADDING} from '../../constants';

const dragHandleActive = css`
  background: ${gray100};
  color: ${black};
`;

export const DragHandle = styled(WBIcon).attrs({name: 'vertical-handle'})`
  position: absolute;
  z-index: 1;
  left: -24px;
  top: ${props => props.offsetTop ?? -4}px;
  font-size: 28px;
  border-radius: 100px;
  color: ${gray700};
  user-select: none;
  cursor: pointer;
  &:hover {
    ${dragHandleActive}
  }
  ${props =>
    props.forceVisible &&
    css`
      display: block !important;
      ${dragHandleActive}
    `}

  &&&&& {
    ${props => props.hidden && `display: none;`}
  }
`;

export const FullBlockWrapper = styled.div`
  width: 100%;

  // Makes drag and drop ghost work correctly
  position: relative;
  z-index: 1;
  cursor: default;
`;

export const BlockWrapper = styled.div<{
  isDropTarget?: boolean;
  dragging?: boolean;
  disableClickSelect?: boolean;
}>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  cursor: ${props => (props.disableClickSelect ? 'default' : 'text')};
  padding-left: ${BLOCK_WRAPPER_PADDING}px;
  padding-right: ${BLOCK_WRAPPER_PADDING}px;

  transition: margin 0.15s, border 0.15s;
  margin-bottom: 0px;
  border-bottom: 0px solid transparent;
  ${props =>
    props.isDropTarget &&
    css`
      border-bottom: 2px solid ${GLOBAL_COLORS.primary.toString()};
      margin-bottom: 12px;
    `}

  ${props =>
    props.dragging &&
    css`
      opacity: 0.25;
    `}
`;
