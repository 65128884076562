import makeComp from '@wandb/common/util/profiler';
import React, {FC} from 'react';

import AnonymousEntityClaimer from '../AnonymousEntityClaimer';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsClaimedAccounts: FC = makeComp(
  () => {
    const {viewer, refetch} = useUserSettingsContext();
    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }
    return (
      <>
        <p>
          Claim your anonymous accounts to assume ownership of all their
          projects and runs.
        </p>
        <AnonymousEntityClaimer viewer={viewer} refetch={refetch} />
      </>
    );
  },
  {id: 'UserSettingsClaimedAccounts', memo: true}
);
