import styled from 'styled-components';

export const EXPORT_PADDING = 16;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
`;

export const HorizontalSpace = styled.div`
  margin-right: 10px;
`;

export const Preview = styled.div`
  overflow: auto;
`;

export const PreviewInner = styled.div<{width: number; height: number}>`
  width: ${({width}) => width + EXPORT_PADDING * 2}px;
  height: ${({height}) => height + EXPORT_PADDING * 2}px;
  margin: auto;
`;
