import * as React from 'react';

import * as S from './style';

export const OItem: React.FC<{label: string; value: React.ReactNode}> =
  props => {
    return (
      <S.OverviewItem>
        <S.OverviewKey>{props.label}</S.OverviewKey>
        <S.OverviewValue>{props.value}</S.OverviewValue>
      </S.OverviewItem>
    );
  };
