import config from '@wandb/common/config';
import {Link} from '@wandb/common/util/links';
import React, {FC} from 'react';

import * as URLs from '../../util/urls';

export const UserSettingsPolicies: FC = () => {
  return (
    <>
      <Link style={{marginRight: 24}} to={URLs.privacyPolicy()}>
        Privacy policy
      </Link>
      <Link style={{marginRight: 24}} to={URLs.termsOfService()}>
        Terms of service
      </Link>
      {!config.ENVIRONMENT_IS_PRIVATE && isOptanonActive() && (
        /* eslint-disable-next-line */
        <a style={{marginRight: 24}} className="optanon-toggle-display">
          Cookie Settings
        </a>
      )}
    </>
  );
};

// Optanon only enables their cookie settings when the user is in
// a GDPR country. This is an indirect way to check whether or not
// Optanon is enabled. We only render the "Cookie Settings" link when
// it's enabled so we don't have a link that does nothing.
function isOptanonActive(): boolean {
  return document.getElementById(`optanon`) != null;
}
