import * as globals from '@wandb/common/css/globals.styles';
import {Popup} from 'semantic-ui-react';
import styled from 'styled-components';

export const RunPendingResultsPopup = styled(Popup)`
  &&&& {
    color: white;
    border-color: ${globals.gray700};
    border-radius: 3px;
    background: ${globals.gray700};
    font-size: 12px;
    line-height: 15px;
    padding: 6px;

    &:before {
      background: ${globals.gray700};
      box-shadow: none;
    }
  }
`;
