import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {
  DragDropProvider,
  DragHandle,
  DragSource,
  DropTarget,
} from '@wandb/common/containers/DragDropContainer';
import classNames from 'classnames';
import * as queryString from 'query-string';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {Popup, Transition} from 'semantic-ui-react';

import * as ViewHooks from '../state/views/hooks';
import * as SectionActions from '../state/views/section/actions';
import * as SectionViewTypes from '../state/views/section/types';
import {PersistentFlasher} from './elements/PersistentFlasher';
import RunSelector from './RunSelector';
import * as S from './RunSets.styles';
import {RunSetSummary} from './RunSetSummary';

// This is a group of RunSummary components and the associated (collapsible) RunSelector components.
// Note: only one RunSelector is visible at a time.
interface RunSetsProps {
  entityName: string;
  projectName: string;
  sectionRef: SectionViewTypes.Ref;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  isInReport?: boolean;
  onHeightChange?(): void;
}

type AllRunSetsProps = RunSetsProps & ReturnType<typeof useRunSetsProps>;

const RunSetsComp: React.FC<AllRunSetsProps> = React.memo(props => {
  const {
    entityName,
    projectName,
    sectionPart,
    readOnly,
    disableRunLinks,
    setActiveIndex,
    setHideRunSets,
    reorderRunSet,
    removeRunSet,
    duplicateRunSet,
    addRunSet,
    onHeightChange,
    isInReport,
  } = props;
  const activeIndex = sectionPart.openRunSet;

  const [movingRunset, setMovingRunset] = useState<number | null>();
  const ACTIVE_TARGET_CLASS = 'run-set-drop-target--glowing';
  const DROP_TARGET_CLASS = 'run-set-drop-target';

  // Do state mutations in mouse over to avoid re-renders for
  // each mouse movement
  const clearTargets = useCallback(() => {
    if (runSetWrapperRef.current == null) {
      return;
    }

    const targets: Element[] = Array.from(
      runSetWrapperRef.current.querySelectorAll('.' + DROP_TARGET_CLASS)
    );
    targets.forEach(t => t.classList.remove(ACTIVE_TARGET_CLASS));
  }, []);

  const onDragOver = (e: React.DragEvent<Element>, i: number) => {
    clearTargets();
    e.currentTarget.classList.add(ACTIVE_TARGET_CLASS);
    targetIndex.current = i;
  };

  const onDrop = () => {
    if (movingRunset != null && targetIndex.current != null) {
      reorderRunSet(movingRunset, targetIndex.current);
    }
    clearTargets();
    setMovingRunset(null);
    targetIndex.current = undefined;
  };

  const startMovingRunset = (i: number) => {
    setMovingRunset(i);
  };

  const runSetWrapperRef = React.useRef<HTMLDivElement>(null);
  const targetIndex = React.useRef<number>();

  const qs = queryString.parse(window.location.search);
  const flasherVisible = qs.runsetFilter != null;
  const popupContent = (
    <>
      <div>Filter added, limiting</div>
      <div>to existing runs</div>
    </>
  );

  return (
    <DragDropProvider>
      <div
        ref={runSetWrapperRef}
        className={classNames('run-sets-wrapper', {
          active: activeIndex != null,
          'run-set-is-dragging': movingRunset != null,
        })}>
        <div className="run-sets">
          <div className="run-set-summaries">
            {sectionPart.runSetRefs.map((runSetRef, i) => {
              return (
                <PersistentFlasher
                  key={i}
                  visible={flasherVisible && i === 0}
                  popupContent={popupContent}
                  offsetX={0}
                  style={{zIndex: 475}}
                  trigger={
                    <React.Fragment>
                      <DropTarget
                        className={DROP_TARGET_CLASS}
                        key={i}
                        onDragEnter={(c, e) => onDragOver(e, i)}
                        partRef={{id: i.toString()}}>
                        <div
                          className={classNames({
                            'run-set-drop-indicator--active':
                              movingRunset != null &&
                              movingRunset !== i &&
                              movingRunset + 1 !== i,
                            'run-set-drop-indicator': true,
                          })}
                        />
                      </DropTarget>
                      <DragSource
                        partRef={{id: i.toString()}}
                        onDragEnd={onDrop}
                        onDragStart={e => {
                          startMovingRunset(i);
                        }}
                        className="run-set-summary-container">
                        <DragHandle partRef={{id: i.toString()}}>
                          <RunSetSummary
                            className={i === 0 ? 'first-runset-summary' : null}
                            key={'runSet-' + runSetRef.id}
                            pageEntityName={entityName}
                            pageProjectName={projectName}
                            runSetRef={runSetRef}
                            toggleActive={() =>
                              setActiveIndex(activeIndex === i ? undefined : i)
                            }
                            active={i === activeIndex}
                            readOnly={readOnly}
                            removeRunSet={
                              sectionPart.runSetRefs.length > 1
                                ? () => removeRunSet(runSetRef)
                                : undefined
                            }
                            duplicateRunSet={() => duplicateRunSet(runSetRef)}
                          />
                        </DragHandle>
                      </DragSource>
                    </React.Fragment>
                  }
                />
              );
            })}
            <DropTarget
              partRef={{id: sectionPart.runSetRefs.length.toString()}}
              onDragOver={(c, e) =>
                onDragOver(e, sectionPart.runSetRefs.length)
              }
              className={DROP_TARGET_CLASS}>
              <div
                className={classNames(
                  {
                    'run-set-drop-indicator--active':
                      sectionPart.runSetRefs.length - 1 !== movingRunset,
                  },
                  'run-set-drop-indicator',
                  'run-set-drop-indicator--last'
                )}
              />
            </DropTarget>
            {!props.readOnly && (
              <>
                <Popup
                  size="mini"
                  inverted
                  content="Add run set"
                  position="right center"
                  trigger={
                    <LegacyWBIcon
                      className="add-run-set-button"
                      name="plus"
                      title="Add run set"
                      onClick={() => {
                        addRunSet();
                      }}
                    />
                  }
                />
                <Popup
                  size="mini"
                  inverted
                  content={`Click to ${
                    sectionPart.hideRunSets ? 'show' : 'hide'
                  } these run sets for report viewers.`}
                  position="right center"
                  trigger={
                    <S.HideRunSetsIcon
                      name={sectionPart.hideRunSets ? 'hide' : 'show'}
                      onClick={() => {
                        setHideRunSets(!sectionPart.hideRunSets);
                      }}
                    />
                  }
                />
              </>
            )}
          </div>
          <Transition
            visible={activeIndex != null}
            animation="slide down"
            unmountOnHide
            duration={250}>
            <div className="run-selector-box">
              {activeIndex != null && sectionPart.runSetRefs[activeIndex] && (
                <RunSelector
                  key={sectionPart.runSetRefs[activeIndex].id}
                  pageEntityName={entityName}
                  pageProjectName={projectName}
                  customRunColorsRef={sectionPart.customRunColorsRef}
                  runSetRef={sectionPart.runSetRefs[activeIndex]}
                  pollInterval={0}
                  enableSetProject={true}
                  enableFreezeRunset={true}
                  readOnly={readOnly}
                  disableRunLinks={disableRunLinks}
                  onHeightChange={onHeightChange}
                  isInReport={isInReport}
                />
              )}
            </div>
          </Transition>
        </div>
      </div>
    </DragDropProvider>
  );
});

function useRunSetsProps(props: RunSetsProps) {
  const sectionPart = ViewHooks.usePart(props.sectionRef);

  const addRunSet = ViewHooks.useViewAction(
    props.sectionRef,
    SectionActions.addNewRunSet
  );

  const removeRunSet = ViewHooks.useViewAction(
    props.sectionRef,
    SectionActions.removeRunSet
  );

  const duplicateRunSet = ViewHooks.useViewAction(
    props.sectionRef,
    SectionActions.duplicateRunSet
  );

  const reorderRunSet = ViewHooks.useViewAction(
    props.sectionRef,
    SectionActions.reorderRunSet
  );

  const setActiveIndex = ViewHooks.useViewAction(
    props.sectionRef,
    SectionActions.setActiveIndex
  );

  const setHideRunSets = ViewHooks.useViewAction(
    props.sectionRef,
    SectionActions.setHideRunSets
  );

  return {
    sectionPart,
    addRunSet,
    removeRunSet,
    reorderRunSet,
    duplicateRunSet,
    setActiveIndex,
    setHideRunSets,
  };
}

export const RunSets = (props: RunSetsProps) => {
  const selectedProps = useRunSetsProps(props);
  return <RunSetsComp {...props} {...selectedProps} />;
};
