import * as QueryString from 'query-string';

// represents what kind of owner the viewed workspace has
export type WorkspaceSpecOwner =
  | {type: 'user'; user: string}
  | {type: 'default'}
  | {type: 'empty'}
  | {type: 'none'};

export function readWorkspaceUrl(
  searchParams: URL['search']
): WorkspaceSpecOwner {
  const parsed = QueryString.parse(searchParams);
  if (parsed && parsed.workspace) {
    const workspace = Array.isArray(parsed.workspace)
      ? parsed.workspace[0]
      : parsed.workspace;
    if (workspace === 'empty') {
      // This will load an empty workspace. Useful for getting out of broken states
      return {
        type: 'empty',
      };
    } else if (workspace === 'default') {
      return {
        type: 'default',
      };
    } else if (workspace.startsWith('user-')) {
      return {
        type: 'user',
        user: workspace.slice(5),
      };
    }
  }
  return {
    type: 'none',
  };
}
