import styled from 'styled-components';

import {GLOBAL_COLORS} from '../util/colors';

export const LaunchRoutedDiv = styled.div`
  margin: 16px;
  .routed-tab .ui.tabular.menu {
    padding: 0;
    background: none;
    border: none;
    margin-bottom: 24px;
    border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
    overflow: visible;
    .item {
      border: none;
    }
    .item.active {
      border: 1px solid ${GLOBAL_COLORS.outline.toString()};
      border-bottom: none;
      background: ${GLOBAL_COLORS.background.toString()};
      margin-bottom: -1px;
      padding-bottom: 12px;
    }
  }
`;
