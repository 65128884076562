// A 4-state checkbox. Unfortunately a non-standard form element, so
// doesn't work with the keyboard etc.
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React, {memo} from 'react';
import {Icon} from 'semantic-ui-react';

export type MultiStateCheckboxCheckedState =
  | 'checked'
  | 'unchecked'
  | 'partial'
  | 'unknown';

interface MultiStateCheckboxProps {
  className?: string;
  checked: MultiStateCheckboxCheckedState;
  style?: any;
  dropdown?: boolean;
  theme?: string;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

const MultiStateCheckboxComp: React.FC<MultiStateCheckboxProps> = ({
  className: propsClassName,
  checked,
  style,
  dropdown,
  theme,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  let className = `multi-state-checkbox ${dropdown ? 'with-dropdown' : ''}${
    propsClassName ? ' ' + propsClassName : ''
  }`;

  let checkIcon =
    checked === 'checked' ? (
      <LegacyWBIcon name="check" />
    ) : checked === 'unchecked' ? (
      ' ' // <Icon style={{color: 'white'}} name="flag" />
    ) : checked === 'partial' ? (
      <Icon style={{backgroundColor: 'white'}} name="minus" />
    ) : (
      ' ' // <Icon style={{backgroundColor: 'white'}} name="minus" />
    );

  if (theme === 'visibility') {
    className = `visibility-indicator visible-${checked}`;
    checkIcon =
      checked === 'checked' ? (
        <LegacyWBIcon name="show" />
      ) : checked === 'partial' ? (
        <LegacyWBIcon name="partial" />
      ) : (
        <LegacyWBIcon name="hide" />
      );
  }

  return (
    <div
      className={className}
      style={style}
      onClick={(e: React.SyntheticEvent) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {checkIcon}
      {dropdown && <Icon style={{marginRight: 0}} name="dropdown" />}
    </div>
  );
};

export const MultiStateCheckbox = memo(MultiStateCheckboxComp);
