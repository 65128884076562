import {WBIcon, WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Input, Label, Loader} from 'semantic-ui-react';

import * as Generated from '../generated/graphql';
import {useRunSetSummaryQuery} from '../state/graphql/runSetSummaryQuery';
import * as ViewHooks from '../state/views/hooks';
import * as RunSetActions from '../state/views/runSet/actions';
import * as RunSetTypes from '../state/views/runSet/types';
import {MultiStateCheckbox} from './MultiStateCheckbox';

interface RunSetSummaryProps {
  className?: string | null;
  pageEntityName: string;
  pageProjectName: string;
  active: boolean;
  readOnly?: boolean;
  runSetRef: RunSetTypes.Ref;
  preChild?: React.ReactNode;
  toggleActive(): void;
  removeRunSet?(): void;
  duplicateRunSet(): void;
}

type AllRunSetSummaryProps = RunSetSummaryProps &
  ReturnType<typeof useRunSetSummaryProps>;

const RunSetSummaryComp: React.FC<AllRunSetSummaryProps> = React.memo(
  ({
    active,
    className,
    readOnly,
    toggleActive,
    removeRunSet,
    duplicateRunSet,
    runSetSummaryQuery,
    runSetPart,
    setEnabled,
    setName,
  }) => {
    const [editNameText, setEditNameText] = useState(runSetPart.name);
    const [isEditingName, setIsEditingName] = useState(false);
    const nameInputRef = useRef<Input | null>(null);

    const saveName = useCallback(
      (newName: string) => {
        setName(newName);
        setIsEditingName(false);
      },
      [setName]
    );

    useEffect(() => {
      if (isEditingName) {
        nameInputRef.current?.focus();
      }
    }, [isEditingName]);

    const ellipsisMenuItems: WBMenuOption[] = useMemo(() => {
      return [
        ...(!readOnly
          ? [
              {
                icon: 'edit',
                value: 'edit',
                name: 'Rename',
                onSelect: () => {
                  setIsEditingName(true);
                },
              },
              {
                icon: 'copy',
                value: 'copy',
                name: 'Clone',
                onSelect: duplicateRunSet,
              },
            ]
          : []),
        ...(!readOnly && removeRunSet != null
          ? [
              {
                icon: 'delete',
                value: 'delete',
                name: 'Delete',
                onSelect: () => {
                  if (removeRunSet != null) {
                    removeRunSet();
                  }
                },
              },
            ]
          : []),
        {
          icon: 'table',
          value: 'maximize',
          name: active ? 'Minimize' : 'Edit',
          onSelect: () => toggleActive(),
        },
      ];
    }, [active, duplicateRunSet, readOnly, removeRunSet, toggleActive]);

    return (
      <div
        className={classNames(
          'run-set-summary ',
          active ? 'active' : '',
          className
        )}
        onClick={toggleActive}>
        {isEditingName ? (
          <Input
            ref={nameInputRef}
            value={editNameText}
            onClick={(e: React.SyntheticEvent<HTMLInputElement>) =>
              e.stopPropagation()
            }
            onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
              setEditNameText(e.currentTarget.value);
            }}
            onBlur={(e: React.SyntheticEvent<HTMLInputElement>) => {
              saveName(e.currentTarget.value);
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                saveName(e.currentTarget.value);
              }
            }}
            onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => {
              e.stopPropagation();
            }}
          />
        ) : (
          <React.Fragment>
            <MultiStateCheckbox
              checked={runSetPart.enabled ? 'checked' : 'unchecked'}
              onClick={() => setEnabled(!runSetPart.enabled)}
            />
            {runSetPart.name}
            <Label className="count-label">
              {runSetSummaryQuery.loading ? (
                <Loader inline active size="tiny" />
              ) : (
                runSetSummaryQuery.project?.selectedCount
              )}
            </Label>
          </React.Fragment>
        )}
        <WBPopupMenuTrigger options={ellipsisMenuItems}>
          {({setOpen, anchorRef}) => {
            return (
              <WBIcon
                name="overflow"
                className="run-set-summary-overflow"
                onClick={e => {
                  e.stopPropagation();
                  setOpen(o => !o);
                }}
                ref={anchorRef}
              />
            );
          }}
        </WBPopupMenuTrigger>
      </div>
    );
  }
);

function useRunSetSummaryProps(props: RunSetSummaryProps) {
  const [deleteRun] = Generated.useDeleteRunMutation();
  const [stopRun] = Generated.useStopRunMutation();

  const runSetPart = ViewHooks.usePart(props.runSetRef);
  const runSetFull = ViewHooks.useWhole(props.runSetRef);

  const runSetSummaryQuery = useRunSetSummaryQuery({
    pageEntityName: props.pageEntityName,
    pageProjectName: props.pageProjectName,
    runSet: runSetFull,
  });

  const runSetUpdate = ViewHooks.useViewAction(
    props.runSetRef,
    RunSetActions.update
  );

  const setEnabled = useCallback(
    (enabled: boolean) => runSetUpdate({enabled}),
    [runSetUpdate]
  );

  const setName = useCallback(
    (name: string) => runSetUpdate({name}),
    [runSetUpdate]
  );

  return {
    deleteRun,
    stopRun,
    runSetPart,
    runSetSummaryQuery,
    setEnabled,
    setName,
  };
}

export const RunSetSummary = (props: RunSetSummaryProps) => {
  const selectedProps = useRunSetSummaryProps(props);
  return <RunSetSummaryComp {...props} {...selectedProps} />;
};
