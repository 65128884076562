import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

export const EmptyContainer = styled.div`
  position: relative;
  padding: 18px 24px;
`;

export const EmptyHeader = styled.div`
  align-items: top;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const EmptyHeaderText = styled.h2`
  font-size: 20px;
  line-height: 25px;
`;

export const EmptyButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 24px;
`;
