import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {Node, Transforms} from 'slate';
import {ReactEditor, useSlateStatic} from 'slate-react';

import {DragType} from './BlockWrapper';
import {WBSlateDragDropContext} from './WBSlateDragDropContext';

export const getDragType = (items?: DataTransferItemList) => {
  if (items == null) {
    return 'text';
  }

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < items.length; i++) {
    const {kind, type} = items[i];
    if (type === 'application/x-slate-fragment' || kind === 'file') {
      return 'block';
    }
  }

  return 'text';
};

export const useDrag = (item: Node, options?: {onHandleMouseDown?(): void}) => {
  const {setDragItem} = useContext(WBSlateDragDropContext);
  const [dragPhase, setDragPhase] = useState<
    'none' | 'renderingGhost' | 'dragging'
  >('none');
  const [grabbedHandle, setGrabbedHandle] = useState(false);

  const editor = useSlateStatic();

  const sourceAttributes = useMemo(
    () => ({
      draggable: grabbedHandle,
      onDragStart() {
        if (grabbedHandle) {
          Transforms.select(editor, ReactEditor.findPath(editor, item));
          setDragItem(item);
          setDragPhase('renderingGhost');
          setGrabbedHandle(false);
        }
      },
      onDragEnd() {
        Transforms.deselect(editor);
        setDragItem(undefined);
        setDragPhase('none');
      },
      onDrag() {
        if (dragPhase === 'renderingGhost') {
          setDragPhase('dragging');
        }
      },
    }),
    [grabbedHandle, editor, item, setDragItem, dragPhase]
  );

  const handleAttributes = useMemo(
    () => ({
      onMouseDown() {
        options?.onHandleMouseDown?.();
        setGrabbedHandle(true);
      },
      onMouseUp() {
        setGrabbedHandle(false);
      },
    }),
    [options]
  );

  return {sourceAttributes, handleAttributes, dragPhase};
};

export const useDrop = (options?: {
  onDragEnter?(e: React.DragEvent<HTMLDivElement>): void;
  onDragLeave?(e: React.DragEvent<HTMLDivElement>): void;
  onDrop?(e: React.DragEvent<HTMLDivElement>): void;
}) => {
  const {setDragItem} = useContext(WBSlateDragDropContext);
  const [dragType, setDragType] = useState<DragType | undefined>(undefined);
  const counter = useRef<number>(0);

  const onDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (counter.current === 0) {
        options?.onDragEnter?.(e);

        setDragType(getDragType(e?.dataTransfer?.items));
      }

      counter.current++;
    },
    [options]
  );

  const onDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      counter.current--;

      if (counter.current === 0) {
        options?.onDragLeave?.(e);

        setDragType(undefined);
      }
    },
    [options]
  );

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      options?.onDrop?.(e);

      counter.current = 0;
      setDragType(undefined);
      setDragItem(undefined);
    },
    [options, setDragItem]
  );

  const targetAttributes = useMemo(
    () => ({
      onDragEnter,
      onDragLeave,
      onDrop,
    }),
    [onDragEnter, onDragLeave, onDrop]
  );

  return {targetAttributes, dragType};
};
