import React from 'react';
import {useLocation} from 'react-router-dom';
import {Button, Modal} from 'semantic-ui-react';

import {useLicenseExpirationUiQuery} from '../generated/graphql';
import * as S from './LocalLicenseNudgeBar.styles';
import {NudgeBarLarge} from './NudgeBarLarge';

const LocalLicenseNudgeBar: React.FC = () => {
  const licenseExpirationUIQuery = useLicenseExpirationUiQuery();
  const licenseExpirationUI =
    licenseExpirationUIQuery.data?.serverInfo?.licenseExpirationUI ?? null;

  const location = useLocation();

  // either we're not currently expiring, or we're on an exempt page
  // (like the landing page or login UI)
  if (
    licenseExpirationUI == null ||
    ['/', '/login', '/signup'].includes(location.pathname)
  ) {
    return null;
  }

  return licenseExpirationUI.bannerDaysRemaining > 0 ? (
    <NudgeBarLarge>
      <p data-test="grace-period-nudge">
        You need to renew your license immediately. In{' '}
        {licenseExpirationUI.bannerDaysRemaining}{' '}
        {licenseExpirationUI.bannerDaysRemaining === 1 ? 'day' : 'days'}, the UI
        will shut down.{' '}
        {licenseExpirationUI.renewUrl ? (
          <S.NudgeButtonContainer>
            <Button as="a" inverted href={licenseExpirationUI.renewUrl}>
              {licenseExpirationUI.renewUrl.endsWith('local-contact')
                ? 'Contact sales'
                : 'Manage license'}
            </Button>
          </S.NudgeButtonContainer>
        ) : (
          <>Ask your admin to get a new license.</>
        )}
      </p>
    </NudgeBarLarge>
  ) : (
    <Modal className="license-expired-block" open>
      <Modal.Header>License Expired</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            You need to renew your license immediately. You can continue logging
            data, but since the {licenseExpirationUI.bannerTotalDays}
            -day grace period has elapsed, you won't be able to use the UI until
            you renew your license.{' '}
            {!licenseExpirationUI.renewUrl &&
              'Ask your admin to get a new license.'}
          </p>
        </Modal.Description>
      </Modal.Content>
      {licenseExpirationUI.renewUrl && (
        <Modal.Actions>
          <Button as="a" primary href={licenseExpirationUI.renewUrl}>
            {licenseExpirationUI.renewUrl.endsWith('local-contact')
              ? 'Contact sales'
              : 'Manage license'}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default LocalLicenseNudgeBar;
