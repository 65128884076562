import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {Input} from 'semantic-ui-react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${globals.gray100};
`;

export const StickySearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${globals.gray200};
  position: sticky;
  top: 0;
  flex-grow: 1;
  background: rgba(255, 255, 255, 1);
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  color: ${globals.gray500};
  padding: 0.5rem;
  font-size: 14px;
`;

export const StickyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${globals.gray200};
  position: sticky;
  top: 40px;
`;

export const LabelCell = styled.div<{level: number; isParent?: boolean}>`
  padding-left: ${props => props.level * 20 + (props.isParent ? 0 : 39)}px;
  width: ${props => (props.isParent ? 100 : 40)}%;
  background-color: ${props =>
    props.isParent ? globals.gray50 : globals.white};
  cursor: ${props => (props.isParent ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  ${Row}:hover & {
    background-color: ${globals.gray100};
  }
  color: ${props => (props.isParent ? globals.gray600 : globals.gray900)};
  min-height: 32px;
  font-size: ${props => (props.isParent ? 14 : 16)}px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const ValueCell = styled.div<{extraPadding?: boolean}>`
  width: 60%;
  background-color: ${globals.white};
  min-height: 32px;
  color: ${globals.gray900};
  font-size: ${globals.fontSizeStandard};
  display: flex;
  align-items: center;
  padding-left: 10px;
  ${Row}:hover & {
    background-color: ${globals.gray100};
  }
  overflow-wrap: break-word;
  word-break: break-word;
  padding-top: ${props => (props.extraPadding ? '10px' : '0px')};
  padding-bottom: ${props => (props.extraPadding ? '10px' : '0px')};
`;

export const TableWrapper = styled.div`
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 200px;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  border: ${globals.separatorBorder};
  max-height: 650px;
  border-radius: 4px;
  background-color: ${globals.white};
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
  min-height: 100%;
  background-color: white;
`;

export const HeaderCell = styled.div<{isKeyHeader?: boolean}>`
  width: ${props => (props.isKeyHeader ? 40 : 60)}%;
  display: flex;
  align-items: center;
  padding: 5px 0 5px ${props => (props.isKeyHeader ? 40 : 10)}px;
  background-color: ${globals.gray50};
  font-size: 14px;
  height: 32px;
  color: ${globals.gray800};
`;

export const TextInput = styled(Input)`
  && {
    > input {
      border: none !important;
    }
    > i.icon {
      font-size: 20px !important;
      margin-left: -6px !important;
    }
    border-bottom-width: 0 !important;
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-left-width: 0 !important;
    &:focus {
      outline: none;
    }
    padding: 0;
    margin: 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: ${globals.gray500};
  }
`;

export const CaretIcon = styled(WBIcon)`
  margin-left: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: ${globals.spu};
  font-size: 20px;
`;
