import React from 'react';

import * as S from './RunPendingResultsPopup.styles';

type RunPendingResultsPopupProps = {
  isPendingUpdates: boolean;
  trigger: JSX.Element;
};

export const RunPendingResultsPopup = ({
  isPendingUpdates,
  trigger,
}: RunPendingResultsPopupProps): JSX.Element => (
  <S.RunPendingResultsPopup
    content="The metrics you see in the Runs Table for this run have a 5
      minute delay because of the large size of this run’s summary.
      Click this run to view real-time metrics."
    disabled={!isPendingUpdates}
    on="hover"
    onOpen={() => window.analytics?.track('Run pending updates tooltip viewed')}
    popperModifiers={{
      preventOverflow: {enabled: false},
    }}
    position="top center"
    trigger={trigger}
  />
);
