import * as QueryString from 'query-string';

import {parseNameAndIDFromURL} from './shared';

export * from './shared';
export * from '@wandb/common/util/url';

export function modifyQueryString(
  modFn: (orig: QueryString.ParsedQuery) => QueryString.ParsedQuery
) {
  const parsed = QueryString.parse(window.location.search) || {};
  const modded = modFn(parsed);
  window.history.replaceState(
    null,
    document.title,
    window.location.pathname + '?' + QueryString.stringify(modded)
  );
}

export function removeHashFromURL(url: string): string {
  return url.split('#')[0];
}

export function checkURLPointToCurrentReport(url: string): boolean {
  try {
    const currentReportURL = removeHashFromURL(window.location.href);
    const {id: currentId} = parseNameAndIDFromURL(currentReportURL);

    const targetURL = removeHashFromURL(url);
    const {id: targetId} = parseNameAndIDFromURL(targetURL);

    return currentId === targetId;
  } catch (e) {
    return false;
  }
}

export function getURLAndSection(url: string): {
  reportURL: string;
  section?: string;
} {
  const splitURLWithHash = url.split('#');
  if (splitURLWithHash.length === 1) {
    return {reportURL: splitURLWithHash[0]};
  }
  return {reportURL: splitURLWithHash[0], section: '#' + splitURLWithHash[1]};
}

// this helper function assumes that url is /:entityName/:projectName/reports/:reportNameAndID format
export function replaceViewIDInURL(url: string, newViewId: string): string {
  const indexBeforeID = url.lastIndexOf('--');
  const urlName = url.slice(0, indexBeforeID);
  return urlName + '--' + newViewId;
}

export function getParentReportURL(
  url: string,
  parentViewId: string | undefined
): string {
  const childUrl = url.split('/edit')[0].split('#')[0];
  const {id} = parseNameAndIDFromURL(childUrl);

  if (!parentViewId || parentViewId.length === 0) {
    return childUrl;
  }

  // remove any padding from the view id
  const parentViewIdWithoutPad = parentViewId.replace(/=/g, '');
  if (id === parentViewIdWithoutPad) {
    return childUrl;
  }

  return replaceViewIDInURL(childUrl, parentViewIdWithoutPad);
}

export function urlSafeSlug(slug: string) {
  // Legacy urls have encoded components that google doesn't like, this makes
  // old urls use the same logic as makeNameAndID
  const parts = slug.split('--');
  return (
    parts[0].replace(/%[A-Z0-9]{2}/g, '-').replace(/-+/g, '-') + '--' + parts[1]
  );
}

export function queryStringParamExists(param: string): boolean {
  const qs = QueryString.parse(window.location.search);
  return param in qs;
}

export function getQueryStringParamValue(
  param: string
): string | null | undefined {
  const qs = QueryString.parse(window.location.search);
  const res = qs[param];
  return Array.isArray(res) ? res[0] : res;
}

export function stripOriginFromURL(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return url;
  }
  const {pathname, search, hash} = new URL(url);
  return pathname + search + hash;
}
