import React, {useState} from 'react';

import {useRunArtifactVersionsQuery} from '../generated/graphql';
import * as S from './DeleteRunModal.styles';
import RadioForm from './RadioForm';

interface DeleteRunModalProps {
  entityName: string;
  projectName: string;
  runName: string;
  runDisplayName: string;
  isRunActive: boolean;
  trigger?: React.ReactNode;
  onModalCanceled?(): void;
  onDeleteRun(deleteArtifacts?: boolean): void;
}

const DeleteRunModal: React.FC<DeleteRunModalProps> = React.memo(
  ({
    entityName,
    projectName,
    runName,
    runDisplayName,
    isRunActive,
    trigger,
    onModalCanceled,
    onDeleteRun,
  }) => {
    const runArtifacts = useRunArtifactVersionsQuery({
      variables: {
        entityName,
        projectName,
        runName,
        includeOutput: true,
        includeInput: false,
        pageSize: 1, // we only care about the total count, so keep the size low
      },
      fetchPolicy: 'no-cache',
    });

    const outputArtifactsCount =
      runArtifacts.data?.project?.run?.outputArtifacts?.totalCount ?? 0;

    const showCheckbox = (outputArtifactsCount ?? 0) > 0;
    const [deleteArtifacts, setDeleteArtifacts] = useState('');

    return (
      <S.StyledWBModal
        open
        theme="danger"
        header={`Delete run`}
        onClose={onModalCanceled}
        includeCancel
        cancelButtonText="Nevermind"
        primaryAction={{
          content: isRunActive ? `Yes, delete run` : `Delete run`,
          disabled: !deleteArtifacts && showCheckbox,
          onClick: () => onDeleteRun(deleteArtifacts === 'true'),
        }}>
        {isRunActive ? (
          <p>
            You’re deleting an active run, which might crash live processes if
            your run is actually still running. We suggest that you stop the run
            before deleting it.
          </p>
        ) : (
          <p>
            Delete run "{runDisplayName}"? This affects all pages, including
            reports.
          </p>
        )}
        {showCheckbox && (
          <RadioForm
            name="deleteArtifactsGroup"
            options={[
              {
                label: `Also delete the ${outputArtifactsCount} artifact${
                  outputArtifactsCount > 1 ? 's' : ''
                } this run created`,
                key: 'true',
              },
              {label: 'Keep the artifacts', key: 'false'},
            ]}
            value={deleteArtifacts}
            onChange={newValue => setDeleteArtifacts(newValue)}
          />
        )}
      </S.StyledWBModal>
    );
  }
);

export default DeleteRunModal;
