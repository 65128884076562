import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

export const Nudge = styled.div<{inline?: true; bg?: string}>`
  display: flex;
  align-items: flex-start;
  background: ${props => (props.bg ? props.bg : globals.deepTeal)};
  align-items: center;
  width: 100%;
  z-index: 1000;
  justify-content: space-between;
  min-height: 52px;
  color: ${globals.white};
  transition: margin-top 0.2s;
  font-size: 16px;

  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  ${({inline}) =>
    inline &&
    `
    border: 1px solid;
    border-radius: 6px;
    margin-bottom: 32px;
  `}
`;

export const NudgeInner = styled.div`
  display: flex;
  margin: 14px;
  margin-left: 24px;
`;

export const NudgeMessage = styled.div``;

export const NudgeLink = styled.div`
  margin-left: 12px;
  .nudge-info-link {
    color: ${globals.white};
    font-weight: 600;
  }
`;

export const NudgeDismiss = styled.div`
  margin: 12px;
`;

export const NudgeBarActionLink = styled.a`
  color: ${globals.white};
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 5px 5px;
  background-color: transparent;

  &:hover {
    color: ${globals.white};
  }
`;
