import {ID} from '@wandb/cg';
import produce from 'immer';
import * as queryString from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Node} from 'slate';

import {MentionableUser} from '../../components/ReportDiscussion';
import {
  CommentableRef,
  createCommentCallbackFnType,
  INLINE_MENTION_REGEXP,
  PANEL_MENTION_REGEXP,
  ReportDiscussionContext,
  ReportDiscussionUpdaterContext,
  USER_MENTION_REGEXP,
} from '../../components/ReportDiscussionContext';
import {InlineCommentDetail} from '../../components/Slate/plugins/inline-comments-common';
import {
  CreateViewDiscussionCommentMutationVariables,
  useCreateViewCommentsAlertSubscriptionMutation,
  useCreateViewDiscussionCommentMutation,
  useDeleteAlertSubscriptionMutation,
  useDeleteDiscussionCommentMutation,
  useTeamMembersQuery,
  useViewDiscussionThreadsQuery,
  ViewSource,
} from '../../generated/graphql';
import {propagateErrorsContext} from '../../util/errors';
import {useIsGalleryReport} from '../../util/gallery';
import globalHistory from '../../util/history';
import {fromJSON} from '../../util/report';
import * as Urls from '../../util/urls';
import {openInNewTab} from '../../util/window';
import {
  useApolloClient,
  useDispatch,
  usePropsSelector,
  useSelector,
} from '../hooks';
import {ApolloClient} from '../types';
import {useViewer} from '../viewer/hooks';
import {setCommentAlertSubscription} from '../views/actions';
import * as ViewApi from '../views/api';
import {Ref as DiscussionCommentRef} from '../views/discussionComment/types';
import {Ref as DiscussionThreadRef} from '../views/discussionThread/types';
import * as ViewHooks from '../views/hooks';
import * as ReportActions from '../views/report/actions';
import * as ReportTypes from '../views/report/types';
import * as ViewSelectors from '../views/selectors';
import * as ViewTypes from '../views/types';
import * as Actions from './actions';
import * as ReportViewsSelectors from './selectors';
import * as Thunks from './thunks';
import {ReportViewRef} from './types';

export function useReport(reportID: string) {
  // TODO(views): Pass in params to do load.
  const dispatch = useDispatch();

  const loadStartAction = Actions.loadStarted(reportID);

  const [reportCID] = useState<string>(loadStartAction.payload.id);

  // We explicitly don't include everything as dependencies here because we only
  // want this to happen once, and for the cleanup to only fire on unmount.
  // This means that for now you can't switch between reports without remounting
  // the component using this hook.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(Thunks.load(loadStartAction));
    return () => dispatch(Thunks.unload(reportCID));
  }, [dispatch]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return reportCID;
}

// This isn't careful about canceling its internal requests, so it will
// glitch if you try to switch names. It's only used to redirect old
// report pages to the new page structure, so it's ok.
export function useLoadReportByName(
  entityName: string,
  projectName: string,
  userName: string,
  viewName: string
) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [loadedReportID, setLoadedReportID] = useState<string>();

  useEffect(() => {
    setLoading(true);
    ViewApi.loadMetadataSpecList(client, {
      entityName,
      projectName,
      viewType: ReportTypes.REPORT_VIEW_TYPE,
      userName: userName || undefined,
      viewName: viewName || undefined,
    }).then(result => {
      if (result.length > 0) {
        const report = result[0];
        setLoadedReportID(report.id!);
      }
      setLoading(false);
    });
  }, [client, entityName, projectName, userName, viewName]);

  return {loading, loadedReportID};
}

export function useLoadSpec(
  entityName: string,
  projectName: string,
  viewType: string,
  userName?: string,
  viewName?: string
) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [loadedSpec, setLoadedSpec] = useState<any>();

  useEffect(() => {
    if (entityName === '' || projectName === '') {
      return;
    }
    setLoading(true);

    const loadSpec = async () => {
      try {
        const params: ViewTypes.LoadMetadataListParams = {
          entityName,
          projectName,
          viewType,
          userName,
          viewName,
        };
        const loaded = await ViewApi.loadMetadataSpecList(client, params);
        if (loaded.length === 0) {
          return;
        }
        setLoadedSpec(loaded[0].spec);
      } catch (e) {
        console.log('no spec to load', e);
      } finally {
        setLoading(false);
      }
    };

    loadSpec();
  }, [client, entityName, projectName, viewType, userName, viewName]);

  return {loading, loadedSpec};
}

interface PushReportOpts {
  redirectQS?: {[key: string]: string | null};
  newTab?: true;
}

export function usePushReport(
  entityName: string,
  projectName?: string,
  draft = true
) {
  const client = useApolloClient();

  return useCallback(
    (
      config: ReportTypes.ReportConfig,
      reportName?: string,
      opts?: PushReportOpts
    ) =>
      pushReport({
        client,
        entityName,
        projectName,
        reportName,
        draft,
        config,
        opts,
      }),
    [client, entityName, projectName, draft]
  );
}

export function usePushGalleryPost(entityName: string, reportName: string) {
  const client = useApolloClient();

  return useCallback(
    (config: ReportTypes.ReportConfig) =>
      pushReport({
        client,
        entityName,
        reportName,
        config,
      }),
    [client, entityName, reportName]
  );
}

export function usePushGalleryDiscussion(
  entityName: string,
  reportName: string
) {
  const client = useApolloClient();

  return useCallback(
    (config: ReportTypes.ReportConfig) =>
      pushReport({
        client,
        entityName,
        reportName,
        config,
        discussion: true,
      }),
    [client, entityName, reportName]
  );
}

interface PushReportParams {
  client: ApolloClient;
  entityName: string;
  projectName?: string;
  reportName?: string | null;
  draft?: boolean;
  discussion?: boolean;
  config: ReportTypes.ReportConfig;
  createdUsing?: ViewSource;
  opts?: PushReportOpts;
}

export async function saveView({
  client,
  entityName,
  projectName,
  reportName,
  draft,
  config,
  createdUsing,
}: PushReportParams) {
  return await ViewApi.save(client, {
    name: ID(12),
    displayName: reportName || 'Untitled Report',
    type: draft ? 'runs/draft' : 'runs',
    project: {
      entityName,
      name: projectName,
    },
    description: '',
    spec: config,
    createdUsing,
  });
}

export async function pushReport({
  client,
  entityName,
  projectName,
  reportName,
  draft = true,
  discussion,
  config,
  opts,
}: PushReportParams) {
  const savedView = await saveView({
    client,
    entityName,
    projectName,
    reportName,
    draft,
    config,
  });
  if (savedView.id == null) {
    throw new Error('invalid state');
  }

  let redirectTo: string;
  if (projectName == null) {
    const urlParams = {
      entityName,
      reportID: savedView.id,
      reportName: savedView.displayName,
    };
    redirectTo = discussion
      ? draft
        ? Urls.galleryDiscussionEdit(urlParams)
        : Urls.galleryDiscussionView(urlParams)
      : draft
      ? Urls.galleryPostEdit(urlParams)
      : Urls.galleryPostView(urlParams);
  } else {
    const urlParams = {
      entityName,
      projectName,
      reportID: savedView.id,
      reportName: savedView.displayName,
    };
    redirectTo = draft
      ? Urls.reportEdit(urlParams)
      : Urls.reportView(urlParams);
  }
  if (opts?.redirectQS != null) {
    redirectTo += `?${queryString.stringify(opts.redirectQS)}`;
  }

  if (opts?.newTab) {
    openInNewTab(redirectTo);
  } else {
    globalHistory.push(redirectTo);
  }
}

export function usePushReportMapped<T extends ViewTypes.ObjType, D>(
  entityName: string,
  projectName: string,
  ref: ViewTypes.PartRefFromType<T> | undefined,
  map: (
    whole: ViewTypes.WholeFromType<T>,
    userData: D
  ) => ReportTypes.ReportConfig,
  reportName?: string,
  draft?: boolean
) {
  const dispatch = useDispatch();

  const doPush = usePushReport(entityName, projectName, draft);

  return useCallback(
    (userData: D) => {
      if (ref != null) {
        dispatch(
          Thunks.pushReportMapped(
            ref,
            map as (
              whole: ViewTypes.WholeFromType<ViewTypes.ObjType>,
              userData: D
            ) => ReportTypes.ReportConfig,
            doPush,
            userData
          )
        );
      }
    },
    [dispatch, ref, map, doPush]
  );
}

export function useAppendSectionToReport(
  entityName: string,
  projectName: string,
  reportID: string,
  opts?: EditReportOpts
) {
  const client = useApolloClient();

  return useCallback(
    (newBlock: Node) =>
      appendSectionToReport({
        client,
        entityName,
        projectName,
        reportID,
        newBlock,
        opts,
      }),
    [client, entityName, projectName, reportID, opts]
  );
}

type EditReportOpts = PushReportOpts;

interface EditReportPanelGroupsParams {
  client: ApolloClient;
  entityName: string;
  projectName: string;
  reportID: string;
  opts?: EditReportOpts;
  editBlocks(blocks: Node[]): void;
}

export async function editReportPanelGroups({
  client,
  entityName,
  projectName,
  reportID,
  editBlocks,
  opts,
}: EditReportPanelGroupsParams) {
  const loaded = await ViewApi.load(client, reportID);
  const spec = fromJSON(loaded.spec);
  const newSpec = {
    ...spec,
    blocks: produce(spec.blocks, editBlocks),
  };
  let draft;
  if (loaded.parentId == null) {
    draft = await ViewApi.save(client, {
      type: 'runs/draft',
      name: ID(12),
      displayName: loaded.displayName,
      description: loaded.description,
      spec: newSpec,
      previewUrl: loaded.previewUrl,
      coverUrl: loaded.coverUrl,
      project: {
        entityName,
        name: loaded.project?.name,
      },
      parentId: loaded.id,
    });
  } else {
    draft = await ViewApi.save(client, {...loaded, spec: newSpec});
  }
  const urlParams = {
    entityName,
    projectName,
    reportID: draft.id!,
    reportName: draft.displayName,
  };
  const url = Urls.reportEdit(urlParams);
  if (opts?.newTab) {
    openInNewTab(url);
  } else {
    globalHistory.push(url);
  }
}

type BaseAppendSectionToReportParams = {
  client: ApolloClient;
  entityName: string;
  projectName: string;
  reportID: string;
  opts?: EditReportOpts;
};

type AppendSectionToReportParams = BaseAppendSectionToReportParams & {
  newBlock: Node;
};

export async function appendSectionToReport({
  client,
  entityName,
  projectName,
  reportID,
  newBlock,
  opts,
}: AppendSectionToReportParams) {
  return appendSectionsToReport({
    client,
    entityName,
    projectName,
    reportID,
    newBlocks: [newBlock],
    opts,
  });
}

type AppendSectionsToReportParams = BaseAppendSectionToReportParams & {
  newBlocks: Node[];
};

export async function appendSectionsToReport({
  client,
  entityName,
  projectName,
  reportID,
  newBlocks,
  opts,
}: AppendSectionsToReportParams) {
  return editReportPanelGroups({
    client,
    entityName,
    projectName,
    reportID,
    opts,
    editBlocks: blocks => {
      blocks.splice(-1, 0, ...newBlocks);
    },
  });
}
// Subscribe / unsubscribe for report comments
export function useReportCommentAlerts(viewRef: ReportViewRef) {
  const view = useSelector(state => state.views.views[viewRef.id]);
  const reportServerID = view.id;
  if (reportServerID == null) {
    throw new Error('invalid state');
  }

  const setAlertSubscription = ViewHooks.useViewAction(
    viewRef as ViewTypes.PartRefFromObjSchema<ReportTypes.ReportObjSchema>,
    setCommentAlertSubscription
  );

  const [createSubscriptionMutation] =
    useCreateViewCommentsAlertSubscriptionMutation();
  const subscribe = useCallback(
    () =>
      createSubscriptionMutation({
        variables: {viewID: reportServerID},
      }).then(response => {
        const subscriptionID =
          response.data?.createViewCommentsAlertSubscription?.subscription.id;
        if (subscriptionID != null) {
          setAlertSubscription(subscriptionID);
        }
      }),
    [createSubscriptionMutation, reportServerID, setAlertSubscription]
  );

  const [deleteSubscriptionMutation] = useDeleteAlertSubscriptionMutation();
  const existingSubscriptionID = view.alertSubscription?.id;
  const unsubscribe = useCallback(() => {
    if (existingSubscriptionID != null) {
      deleteSubscriptionMutation({
        variables: {id: existingSubscriptionID},
      }).then(response => {
        if (response.data?.deleteAlertSubscription?.success) {
          setAlertSubscription(undefined);
        }
      });
    }
  }, [
    existingSubscriptionID,
    deleteSubscriptionMutation,
    setAlertSubscription,
  ]);

  return {isSubscribed: view.alertSubscription != null, subscribe, unsubscribe};
}

export function useReportDiscussion(props: {
  reportServerID: string;
  reportViewRef: ReportViewRef;
}) {
  const viewer = useViewer();
  const view = useSelector(state => state.views.views[props.reportViewRef.id]);

  const readOnly = view.parentId == null;
  const reportServerID = view.parentId ?? props.reportServerID;

  /* Load discussion threads */
  const discussionThreadsQuery = useViewDiscussionThreadsQuery({
    variables: {
      viewID: reportServerID,
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    context: propagateErrorsContext(),
  });
  const discussionThreadsData = discussionThreadsQuery.data;
  const reportRef = useSelector(
    ReportViewsSelectors.getReportRef(props.reportViewRef)
  );
  const loadDiscussionThreads = ViewHooks.useViewAction(
    reportRef,
    ReportActions.loadDiscussionThreads
  );

  React.useEffect(() => {
    if (discussionThreadsData != null) {
      loadDiscussionThreads({
        discussionThreads: ViewApi.parseDiscussionThreads(
          discussionThreadsData
        ),
      });
    }
  }, [discussionThreadsData, loadDiscussionThreads]);

  const {isGalleryReport} = useIsGalleryReport(reportServerID);

  /* Create comment */
  const [createDiscussionCommentMutation, {loading: loadingCreateComment}] =
    useCreateViewDiscussionCommentMutation();
  const addDiscussionComment = ViewHooks.useViewAction(
    reportRef,
    ReportActions.addDiscussionComment
  );
  const createComment = useCallback(
    (
      mutationVars: Omit<
        CreateViewDiscussionCommentMutationVariables,
        'viewID' | 'notifyAllSubscribers'
      >,
      discussionThreadRef?: DiscussionThreadRef,
      commentDetails?: InlineCommentDetail[],
      callbackFn?: createCommentCallbackFnType
    ) => {
      const inlineCommentDetails =
        commentDetails != null && commentDetails.length > 0
          ? JSON.stringify(commentDetails)
          : undefined;

      createDiscussionCommentMutation({
        variables: {
          ...mutationVars,
          viewID: reportServerID,
          notifyAllSubscribers: !isGalleryReport,
          inlineCommentDetails,
        },
      }).then(({data}) => {
        if (data?.createViewDiscussionComment == null) {
          throw new Error('Unexpected response for createDiscussionComment');
        }

        const mentionTypes = [];
        if (USER_MENTION_REGEXP.test(mutationVars.body)) {
          mentionTypes.push('user');
        }
        if (INLINE_MENTION_REGEXP.test(mutationVars.body)) {
          mentionTypes.push('inline text');
        }
        if (PANEL_MENTION_REGEXP.test(mutationVars.body)) {
          mentionTypes.push('panel');
        }

        const {discussionThread, discussionComment, alertSubscription} =
          data.createViewDiscussionComment;

        window.analytics?.track('Comment Created', {
          mode: readOnly ? 'view' : 'edit',
          reportID: reportServerID,
          commentID: discussionComment.id,
          mentionTypes,
        });

        callbackFn?.(commentDetails, discussionThread.id, discussionComment.id);
        addDiscussionComment(
          {
            // Discussion thread containing the new comment (may or may not be newly-created)
            discussionThread: {
              ...discussionThread,
              poster: ViewApi.parseUser(discussionThread.poster),
              comments: [], // no need to fetch all comments, just need the new one
            },
            // The new comment
            discussionComment: {
              ...discussionComment,
              poster: ViewApi.parseUser(discussionComment.poster),
              updatedAt: discussionComment.updatedAt ?? undefined,
            },
            // The AlertSubscription for the poster
            alertSubscription,
          },
          discussionThreadRef
        );
      });
    },
    [
      readOnly,
      addDiscussionComment,
      createDiscussionCommentMutation,
      reportServerID,
      isGalleryReport,
    ]
  );

  /* Delete comment */
  const [deleteDiscussionCommentMutation] =
    useDeleteDiscussionCommentMutation();
  const deleteDiscussionCommentAction = ViewHooks.useViewAction(
    reportRef,
    ReportActions.deleteDiscussionComment
  );
  const deleteDiscussionThreadAction = ViewHooks.useViewAction(
    reportRef,
    ReportActions.deleteDiscussionThread
  );

  const deleteComment = useCallback(
    (params: {
      discussionCommentServerID: string;
      discussionCommentRef: DiscussionCommentRef;
      discussionThreadRef: DiscussionThreadRef;
      deleteThread: boolean;
    }) => {
      const {
        discussionCommentServerID,
        discussionCommentRef,
        discussionThreadRef,
        deleteThread,
      } = params;

      deleteDiscussionCommentMutation({
        variables: {id: discussionCommentServerID, deleteThread},
      }).then(({data}) => {
        if (data?.deleteDiscussionComment?.success) {
          // If we delete the first comment in a thread, that's equivalent to deleting the thread
          if (deleteThread) {
            deleteDiscussionThreadAction({
              discussionThreadRef,
            });
          } else {
            deleteDiscussionCommentAction({
              discussionCommentRef,
              discussionThreadRef,
              deleteThread,
            });
          }
        } else {
          throw new Error(
            `Delete comment failed (id: ${discussionCommentServerID}`
          );
        }
      });
    },
    [
      deleteDiscussionCommentAction,
      deleteDiscussionCommentMutation,
      deleteDiscussionThreadAction,
    ]
  );

  const discussionThreadRefs = useSelector(
    ReportViewsSelectors.getReportDiscussionThreadRefs(props.reportViewRef)
  );

  const entityName = useSelector(
    ReportViewsSelectors.getReportEntityName(props.reportViewRef)
  );

  const teamMembersQuery = useTeamMembersQuery({
    variables: {entityName},
  });

  const teamMembers: MentionableUser[] = useMemo(() => {
    if (
      !teamMembersQuery.loading &&
      teamMembersQuery.data?.entity?.members != null
    ) {
      return teamMembersQuery.data.entity.members
        .filter(m => m.username != null && m.username !== viewer?.username)
        .map(
          m =>
            ({
              id: m.id,
              username: m.username,
              name: m.name,
              photoUrl: m.photoUrl,
            } as MentionableUser)
        );
    } else {
      return [];
    }
  }, [teamMembersQuery.data, teamMembersQuery.loading, viewer]);

  const getReportPanels = useMemo(
    () => ReportViewsSelectors.makeReportPanelsSelector(props.reportViewRef),
    [props.reportViewRef]
  );
  const reportPanels = getReportPanels();

  // Panel comments are disabled in old reports until the author saves them again, because the panels don't have persistent IDs
  // See ActionsInternal.loadFinished in reducer.ts for more info
  const panelCommentsEnabled = usePropsSelector(
    ViewSelectors.makePanelCommentsEnabledSelector,
    props.reportViewRef
  );

  return useMemo(
    () => ({
      loadingDiscussionThreads: discussionThreadsQuery.loading,
      discussionThreadRefs,
      createComment,
      loadingCreateComment,
      deleteComment,
      viewer,
      readOnly,
      teamMembers,
      reportPanels,
      panelCommentsEnabled,
    }),
    [
      createComment,
      deleteComment,
      discussionThreadRefs,
      discussionThreadsQuery.loading,
      loadingCreateComment,
      panelCommentsEnabled,
      reportPanels,
      teamMembers,
      viewer,
      readOnly,
    ]
  );
}

// For managing discussion/comments on a single panel
// Currently it hackily scans all the discussion threads looking for references to the panel
// Ok for now, but we might want to optimize this later e.g., by associating in redux.
export function usePanelComments(props: {panelRef: CommentableRef}) {
  const {discussionThreadRefs, panelCommentsEnabled, highlightIds} = useContext(
    ReportDiscussionContext
  );

  const {appendPanelMentionToComment, setActiveThread} = useContext(
    ReportDiscussionUpdaterContext
  );

  const panel = ViewHooks.useWhole(props.panelRef);
  const allDiscussionThreads = ViewHooks.useWholeArray(discussionThreadRefs);

  const {panelDiscussionThreadRefs, panelCommentCount} = useMemo(() => {
    let commentCount = 0;
    const pnlDiscussionThreadRefs: DiscussionThreadRef[] = [];

    allDiscussionThreads.forEach((dt, i) => {
      if (
        dt.comments
          .map(comment => comment.body)
          .some(comment => comment.includes(`(${panel.__id__})`))
      ) {
        commentCount = commentCount + 1;
        pnlDiscussionThreadRefs.push(discussionThreadRefs[i]);
      }
    });

    return {
      panelDiscussionThreadRefs: pnlDiscussionThreadRefs,
      panelCommentCount: commentCount,
    };
  }, [allDiscussionThreads, panel.__id__, discussionThreadRefs]);

  const openPanelComments = useCallback(() => {
    setActiveThread(
      panelDiscussionThreadRefs[0],
      true,
      panelDiscussionThreadRefs
    );
  }, [panelDiscussionThreadRefs, setActiveThread]);

  const addComment = useCallback(() => {
    appendPanelMentionToComment(panel.__id__);
  }, [appendPanelMentionToComment, panel.__id__]);

  const isHighlighted = highlightIds.includes(panel.__id__);

  const result = useMemo(
    () =>
      // Panel comments are disabled in old reports until the author saves them again, because the panels don't have persistent IDs
      // See ActionsInternal.loadFinished in reducer.ts for more info
      panelCommentsEnabled
        ? {
            panelCommentCount,
            openPanelComments,
            addComment,
            isHighlighted,
          }
        : {},
    [
      addComment,
      isHighlighted,
      openPanelComments,
      panelCommentCount,
      panelCommentsEnabled,
    ]
  );

  return result;
}
