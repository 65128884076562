import * as _ from 'lodash';

import {
  QueryToRunsDataQueryParams,
  toRunsDataQuery,
} from '../../containers/RunsDataLoader';
import {Expression} from '../../util/expr';
import * as Filters from '../../util/filters';
import {legendTemplateFieldNames} from '../../util/legend';
import * as PanelHelpers from '../../util/panelHelpers';
import {
  ChartAggOption,
  ChartAreaOption,
  PlotFontSizeOrAuto,
} from '../../util/plotHelpers';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import {getExpressionFields} from '../PanelExpressionOptions';
export const DEFAULT_MAX_GROUP_RUNS = 1000;
export type PlotStyle = 'bar' | 'boxplot' | 'violin';

export function isPlotStyle(s: string): s is PlotStyle {
  return s === 'bar' || s === 'boxplot' || s === 'violin';
}

export interface BarChartConfig {
  metrics?: string[];
  chartTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  xAxisMin?: number;
  xAxisMax?: number;
  limit?: number; // max number of runs or groups to show
  barLimit?: number; // max number of bars to show - relevant if multiple metrics used
  aggregate?: boolean; // panel level grouping
  aggregateMetrics?: boolean;
  groupBy?: string; // panel level groupBy
  groupRunsLimit?: number;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  plotStyle?: PlotStyle;
  vertical?: boolean;
  legendFields?: string[];
  legendTemplate?: string; // used to generate the default legend
  colorEachMetricDifferently?: boolean; // if we have multiple metrics, override the run colors - NOT USED
  overrideSeriesTitles?: {[key: string]: string};
  overrideColors?: {[key: string]: {color: string; transparentColor: string}};
  expressions?: string[];
  fontSize?: PlotFontSizeOrAuto;
  useRunsTableGroupingInPanels?: boolean;
}

export const defaultTitle = (config: BarChartConfig) => {
  if (config.metrics == null) {
    return '';
  }

  return config.metrics
    .map(m => {
      const key = Run.keyFromString(m);
      return key != null ? Run.keyDisplayName(key, true) : m;
    })
    .join(', ');
};

export const metricStringsToKeys = (metrics: string[]) => {
  // metrics should be in the form config:metricName, if not we assume they
  // are summary metrics.
  const metricKeys = (metrics || []).map(metricStr => {
    if (metricStr.includes(':')) {
      return (
        Run.keyFromString(metricStr) ||
        ({section: 'summary', name: metricStr} as Run.Key) // shouldn't happen
      );
    } else {
      return {section: 'summary', name: metricStr} as Run.Key;
    }
  });
  return metricKeys;
};

export const barChartTransformQuery = (
  query: Query.Query,
  config: BarChartConfig,
  parsedExpressions: {
    expressions?: Expression[];
    xExpressions?: Expression;
  }
) => {
  const queryToDataQueryParams: QueryToRunsDataQueryParams = {
    selectionsAsFilters: true,
  };

  const expressionFields = getExpressionFields(parsedExpressions);

  const metricKeys = metricStringsToKeys(config.metrics || []);

  if (config.metrics && config.metrics.length > 0) {
    const filters: Array<Filters.Filter<Run.Key>> = metricKeys.map(
      metricKey => ({
        key: metricKey,
        op: '!=',
        value: null,
      })
    );
    const mergeFilters: Filters.Filter<Run.Key> = Filters.Or(filters);
    queryToDataQueryParams.mergeFilters = mergeFilters;
  }

  const transformed = toRunsDataQuery(query, queryToDataQueryParams);

  let legendFields = config.legendFields || [];
  if (config.legendTemplate != null) {
    const templateFields = legendTemplateFieldNames(config.legendTemplate);
    const extraLegendFields = _.difference(
      templateFields,
      config.legendFields || []
    );
    legendFields = legendFields.concat(extraLegendFields);
  }

  let displayFields = [
    ...legendFields.map(Run.keyFromString),
    ...(query.grouping || []),
    config.aggregate && config.groupBy
      ? Run.key('config', config.groupBy)
      : null,
  ];

  if (query.runSets != null) {
    query.runSets.forEach(rs => {
      if (rs.grouping) {
        displayFields = displayFields.concat(rs.grouping);
      }
    });
  }

  const extraFields = _.uniq(_.concat(displayFields, expressionFields));

  // And then add them into the correct query fields.
  transformed.configKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'config')
    .map(key => key!.name);
  // Have to concat here to preserve the config key we added above.
  transformed.summaryKeys = extraFields
    .concat(metricKeys ?? [])
    .filter(key => key != null && key.section === 'summary')
    .map(key => key!.name);

  transformed.page = {
    size: config.limit ?? 10,
  };

  if (PanelHelpers.isGrouped(query, config)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // optionally compute group statistics over all runs instead of sub-sampling
    transformed.page.size = config.groupRunsLimit ?? DEFAULT_MAX_GROUP_RUNS;
    // Disable grouping for this query, we'll do it ourselves.
    transformed.queries = transformed.queries.map(q => ({...q, grouping: []}));
  }

  return transformed;
};

export function getTitleFromConfig(config: BarChartConfig): string {
  return config.chartTitle || defaultTitle(config);
}
