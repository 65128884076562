import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import styled, {css} from 'styled-components';

export const WIDTH = 230;
export const NARROW_WIDTH = 32;
const DISTANCE_FROM_CONTENT = 16;

export const FloatingContainer = styled.div<{active: boolean; narrow: boolean}>`
  position: sticky;
  top: 100px;
  height: 0;
  width: ${WIDTH}px;
  margin-left: -${WIDTH + DISTANCE_FROM_CONTENT}px;
  z-index: 1;

  transition: visibility 0.5s, opacity 0.5s;
  ${props =>
    props.active
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  ${props =>
    props.narrow &&
    css`
      width: ${NARROW_WIDTH}px;
      margin-left: -${NARROW_WIDTH + DISTANCE_FROM_CONTENT}px;
    `}
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const Icon = styled(WBIcon)<{starred?: boolean}>`
  color: ${props =>
    props.starred != null ? globals.heartColor : globals.gray700};
  font-size: 24px;
  margin-left: 4px;
  margin-right: 16px;
`;

export const Row = styled.div<{
  light?: boolean;
  backLink?: boolean;
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;

  ${props =>
    props.light &&
    css`
      color: ${globals.gray500};
      &:hover {
        color: ${globals.gray700};
      }
    `}
  ${props =>
    props.backLink &&
    css`
      margin-top: 12px;
    `}
    
  ${props =>
    props.active &&
    css`
      ${Icon} {
        color: ${globals.heartColor};
      }
    `}
  ${props =>
    props.active === false &&
    css`
      &:hover ${Icon} {
        color: ${globals.heartColor};
      }
    `}
`;

export const Header = styled.div`
  padding-top: 18px;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid ${globals.gray400};
  margin-bottom: 12px;

  ${Row} {
    padding: 4px 0;
  }
`;
