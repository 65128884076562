import * as _ from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import * as ViewHooks from '../state/views/hooks';
import * as InteractStateContext from '../state/views/interactState/context';
import * as PanelActions from '../state/views/panel/actions';
import * as PanelViewTypes from '../state/views/panel/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {PartRefFromObjSchema} from '../state/views/types';
import {getPanelSpec, LayedOutPanel, PanelType} from '../util/panels';
import * as S from './Inspector.styles';
import {
  PanelConfigSpec,
  propertyEditorMap,
} from './property-editors/property-editors';

export function getConfigWithDefaults(configSpec: any, config: any) {
  const spec = configSpec || {};
  const result = {} as any;
  for (const key of Object.keys(spec)) {
    result[key] = config[key] ?? spec[key].default;
  }
  return result;
}

interface InspectorProps {
  className?: string;
  panelRefs: Array<PartRefFromObjSchema<PanelViewTypes.PanelObjSchema>>;
  runSetRefs: RunSetViewTypes.Ref[];
}

export const Inspector: React.FC<InspectorProps> = props => {
  const dispatch = useDispatch();
  const updateConfigs = (
    configUpdate: Partial<PanelViewTypes.Panel['config']>
  ) => {
    dispatch(PanelActions.updateConfigs(props.panelRefs, configUpdate));
  };

  const panels = ViewHooks.useParts(props.panelRefs);

  const [openedPopout, setOpenedPopout] = React.useState<string | null>(null);
  React.useEffect(() => {
    // close popout whenever selection changes
    setOpenedPopout(null);
  }, [props.panelRefs]);

  const panelTypes = new Set<PanelType>();
  for (const panel of panels) {
    panelTypes.add(panel.viewType);
  }
  const configSpecs: PanelConfigSpec[] = [];
  for (const panelType of panelTypes) {
    const spec = getPanelSpec(panelType);
    if (spec.configSpec == null) {
      configSpecs.push({});
    } else {
      configSpecs.push(spec.configSpec);
    }
  }

  /**
   * Returns the panel's spec with its dependencies computed
   */
  function getDerivedSpec(panel: LayedOutPanel): PanelConfigSpec {
    const spec = _.cloneDeep(getPanelSpec(panel.viewType).configSpec || {}) as {
      [key: string]: any;
    };
    for (const property of Object.keys(spec)) {
      for (const propKey of Object.keys(spec[property])) {
        const propVal = spec[property][propKey];
        if (typeof propVal === 'function' && (propVal as any).isDerived) {
          spec[property][propKey] = propVal(
            getConfigWithDefaults(
              getPanelSpec(panel.viewType).configSpec,
              panel.config
            )
          );
        }
        if (propKey === 'visible') {
          if (spec[property][propKey]) {
            // visible: true is equivalent to undefined
            delete spec[property][propKey];
          } else {
            // visible: false is equivalent to not having the property at all
            delete spec[property];
          }
        }
      }
    }
    return spec;
  }

  // Compiles a set of properties present and equivalent
  // in all selected panels' configs.
  let commonProperties: PanelConfigSpec = {};
  if (panels.length > 0) {
    commonProperties = getDerivedSpec(panels[0]);
    for (let i = 1; i < panels.length; i++) {
      const derivedSpec = getDerivedSpec(panels[i]);
      for (const property of Object.keys(commonProperties)) {
        if (!_.isEqual(derivedSpec[property], commonProperties[property])) {
          delete commonProperties[property];
        }
      }
    }
  }

  // Compiles a list of distinct values for each property.
  // This operation is O(n^2) with deep compares but n probably won't get too big.
  const propertyValues: {[key: string]: any[]} = {};
  for (const key of Object.keys(commonProperties)) {
    for (const panel of panels) {
      // for dict of lists initialization
      if (!propertyValues.hasOwnProperty(key)) {
        propertyValues[key] = [];
      }
      const panelPropertyVal =
        (panel.config as any)[key] ?? commonProperties[key].default;
      if (
        _.findIndex(
          propertyValues[key],
          o =>
            (panelPropertyVal == null && o == null) ||
            _.isEqual(panelPropertyVal, o)
        ) === -1
      ) {
        propertyValues[key].push(panelPropertyVal);
      }
    }
  }

  let panelTypeString = '';
  if (panelTypes.size > 1) {
    panelTypeString = 'Mixed';
  } else {
    panelTypeString = panelTypes.values().next().value;
  }

  if (panels.length > 1) {
    panelTypeString += ` (${panels.length})`;
  }

  const baseKey = props.panelRefs.map(p => p.id).join('-');

  return (
    <>
      <S.Wrapper
        className={props.className}
        collapsed={false}
        onClick={() => setOpenedPopout(null)}>
        {panelTypeString && <S.Title>{panelTypeString}</S.Title>}
        <div>
          {Object.entries(commonProperties).map(([key, value]) => {
            const EditorComponent = propertyEditorMap[value.editor];
            const otherProps = _.cloneDeep(value);
            delete (otherProps as any).editor;
            return (
              // complex key to distinguish PE's when selection changes
              <S.PropertyEditorWrapper key={baseKey + '-' + key}>
                <EditorComponent
                  propertyName={key}
                  openedPopout={openedPopout}
                  setOpenedPopout={setOpenedPopout}
                  values={propertyValues[key] as never}
                  runSetRefs={props.runSetRefs}
                  save={(val: any) => {
                    const updateObj: any = {};
                    updateObj[key] = val;
                    updateConfigs(updateObj);
                  }}
                  {...(otherProps as any)}
                />
              </S.PropertyEditorWrapper>
            );
          })}
        </div>
      </S.Wrapper>
    </>
  );
};

const WrapperHeightAdapted = styled(Inspector)<{scrollY: number}>`
  height: calc(100vh - ${props => 52 - Math.min(props.scrollY, 52)}px);
  margin-bottom: -59px;
  padding-bottom: 59px;
`;

const InspectorWithHeightAdaptedForNavbar: React.FC<InspectorProps> = props => {
  const [scrollY, setScrollY] = React.useState(0);
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <WrapperHeightAdapted {...props} scrollY={scrollY}></WrapperHeightAdapted>
  );
};

const InspectorFromPanelSelection: React.FC<
  Pick<InspectorProps, 'runSetRefs'>
> = props => {
  const panelSelection = InteractStateContext.useInteractState(
    interactState => interactState.panelSelection
  );
  return (
    <InspectorWithHeightAdaptedForNavbar
      panelRefs={panelSelection}
      runSetRefs={props.runSetRefs}
    />
  );
};

export default InspectorFromPanelSelection;
