import {urlPrefixed} from '@wandb/common/config';
import React from 'react';
import {useLocation} from 'react-router-dom';

import {useHasLocalLicenseQuery} from '../generated/graphql';
import {
  NudgeBarLarge,
  NudgeBarLargeAction,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

const GetFreeLicenseButton: React.FC = () => (
  <NudgeBarLargeActionLink
    href="https://api.wandb.ai/deploy/redirect/unlicensed"
    onClick={() => {
      window.analytics?.track('Get License Clicked');
    }}>
    Get a free license
  </NudgeBarLargeActionLink>
);

const AddLicenseButton: React.FC = () => (
  <NudgeBarLargeActionLink
    href={urlPrefixed('/system-admin', false)}
    onClick={() => {
      window.analytics?.track('Add License Clicked');
    }}>
    Add license
  </NudgeBarLargeActionLink>
);

export const GetLicenseNudgeBar: React.FC = React.memo(() => {
  const {data, loading} = useHasLocalLicenseQuery();

  const location = useLocation();

  if (
    loading ||
    data?.serverInfo?.hasLocalLicense ||
    ['/', '/login', '/signup'].includes(location.pathname)
  ) {
    return null;
  }
  return (
    <NudgeBarLarge>
      <NudgeBarLargeTitle>
        You need a license for this instance of W&amp;B Local
      </NudgeBarLargeTitle>
      <NudgeBarLargeAction>
        <GetFreeLicenseButton />
        <AddLicenseButton />
      </NudgeBarLargeAction>
    </NudgeBarLarge>
  );
});
