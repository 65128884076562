import * as _ from 'lodash';
import * as React from 'react';
import {useMemo, useState} from 'react';
import {Checkbox, Dropdown} from 'semantic-ui-react';

import {trackArtifactLineageTabInteraction} from '../../src/util/navigation';
import {
  Artifact,
  Dag,
  Direction,
  Edge,
  Run,
  useArtifactDagQueryCaller,
} from '../state/graphql/artifactDagQuery';
import ArtifactFlowDag from './ArtifactFlowDag';
import {InstrumentedLoader as Loader} from './utility/InstrumentedLoader';

interface ArtifactDagProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCommitHash: string;
}

interface ArtifactGroup {
  artifactTypeName: string;
  artifacts: Artifact[];
}

interface RunGroup {
  jobType: string;
  runs: Run[];
  alias?: string;
}

interface EdgeGroupID {
  artifactTypeName: string;
  jobType: string;
  dir: Direction;
}

type EdgeGroup = EdgeGroupID & {
  edges: Edge[];
};

function edgeGroupStringID(edgeGroup: EdgeGroupID) {
  return `${edgeGroup.artifactTypeName} ${
    edgeGroup.dir === Direction.TowardArtifact ? '<-' : '->'
  } ${edgeGroup.jobType}`;
}

export function runGroupKeyFromSignature(
  dag: Dag,
  runID: string,
  uniqueTypes: boolean
) {
  let {inputs, outputs} = Object.values(dag.edges).reduce<{
    inputs: string[];
    outputs: string[];
  }>(
    (memo, edge) => {
      if (edge.runID === runID) {
        const artifact = dag.artifacts[edge.artifactID];
        const entry = artifact.artifactTypeName;
        if (edge.dir === Direction.AwayFromArtifact) {
          memo.inputs.push(entry);
        } else {
          memo.outputs.push(entry);
        }
      }

      return memo;
    },
    {inputs: [], outputs: []}
  );

  if (uniqueTypes) {
    inputs = _.uniq(inputs);
    outputs = _.uniq(outputs);
  }

  const inputsStr = inputs.join(',');
  const outputsStr = outputs.join(',');
  const runSignature =
    `run` +
    (inputsStr.length > 0 ? `(${inputsStr})` : '') +
    (outputsStr.length > 0
      ? ` -> ${outputs.length !== 1 ? `(${outputsStr})` : outputsStr}`
      : '');

  return runSignature;
}

function makeGroupDag(dag: Dag, uniqueTypes: boolean) {
  const {artifacts, runs, edges} = dag;

  const artifactGroups: {[id: string]: ArtifactGroup} = {};
  _.forEach(artifacts, artifact => {
    const groupKey = artifact.artifactTypeName;
    if (artifactGroups[groupKey] == null) {
      artifactGroups[groupKey] = {
        artifactTypeName: artifact.artifactTypeName,
        artifacts: [],
      };
    }
    artifactGroups[groupKey].artifacts.push(artifact);
  });

  const runGroups: {[id: string]: RunGroup} = {};
  _.forEach(runs, (run, key) => {
    const groupKey =
      run.jobType || runGroupKeyFromSignature(dag, key, uniqueTypes);
    if (runGroups[groupKey] == null) {
      runGroups[groupKey] = {
        jobType: groupKey,
        alias: uniqueTypes ? 'run' : undefined,
        runs: [],
      };
    }
    runGroups[groupKey].runs.push(run);
  });

  const edgeGroups: {[id: string]: EdgeGroup} = {};
  _.forEach(edges, edge => {
    const edgeGroupID = {
      artifactTypeName: artifacts[edge.artifactID].artifactTypeName,
      jobType:
        runs[edge.runID].jobType ||
        runGroupKeyFromSignature(dag, edge.runID, uniqueTypes),
      dir: edge.dir,
    };
    const eid = edgeGroupStringID(edgeGroupID);
    if (edgeGroups[eid] == null) {
      edgeGroups[eid] = {
        ...edgeGroupID,
        edges: [],
      };
    }
    edgeGroups[eid].edges.push(edge);
  });

  return {artifacts: artifactGroups, runs: runGroups, edges: edgeGroups};
}

function dfsVisitHelper(graph: any, nodeID: string) {
  const keys: string[] = [];
  const nodes: string[] = [];

  function dfsVisit(nID: string) {
    if (nodes.includes(nID)) {
      return;
    }
    nodes.push(nID);

    if (!(nID in graph)) {
      return;
    }

    for (const edge of graph[nID]) {
      keys.push(edge.key);
      dfsVisit(edge.destID);
    }
  }

  dfsVisit(nodeID);
  return [keys, nodes];
}

const DAG_REQUEST_LIMIT = 2000;

const ArtifactDag = (props: ArtifactDagProps) => {
  const {
    entityName,
    projectName,
    artifactTypeName,
    artifactCollectionName,
    artifactCommitHash,
  } = props;
  const [limit] = useState(DAG_REQUEST_LIMIT);
  const artifactID = `${artifactCollectionName}:${artifactCommitHash}`;
  const fullArtifactID = `${entityName}/${projectName}/${artifactTypeName}/${artifactID}`;
  const [dagMode, setDagMode] = useState<
    'collapsed' | 'collapsed-show-all' | 'exploded' | 'direct-lineage'
  >('direct-lineage');
  const [showAutoArtifacts, setShowAutoArtifacts] = useState(false);
  const [modificationCount, setModificationCount] = useState(0);

  // We do this twice since useArtifactDagQuery is optimized to
  // run once and only once, regardless of inputs.
  const fullDagResults = useArtifactDagQueryCaller(
    entityName,
    projectName,
    artifactCollectionName,
    artifactCommitHash,
    limit,
    false
  );

  // Note: A better way to do this would be using a backend endpoint that gives us the
  // direct ancestors/descendants of an artifact, probably with a `depth` variable. Right now, this relies on fetching the full DAG
  // and then traversing it for direct ancestors/descendants for our specific artifact version of interest.
  // This is inefficient if the user only cares about direct ancestors.
  const getDirectLineageDag = React.useCallback(
    (
      completeDag: typeof fullDagResults.dag,
      completeLoading: boolean,
      completeLimited: boolean
    ): typeof fullDagResults => {
      interface DirectedEdge {
        key: string;
        destID: string;
      }
      const ancestorMap: {[nID: string]: DirectedEdge[]} = {};
      const descendantMap: {[nID: string]: DirectedEdge[]} = {};

      Object.entries(completeDag.edges).forEach(([key, value]) => {
        // default: value.dir == 0 means that a run has generated an artifact
        let srcID = value.runID;
        let destID = value.artifactID;
        if (value.dir === 1) {
          [srcID, destID] = [destID, srcID];
        }
        if (!(srcID in descendantMap)) {
          descendantMap[srcID] = [];
        }
        if (!(destID in ancestorMap)) {
          ancestorMap[destID] = [];
        }
        descendantMap[srcID].push({key, destID});
        ancestorMap[destID].push({key, destID: srcID});
      });

      const [ancestorKeys, ancestorNodes] = dfsVisitHelper(
        ancestorMap,
        `${entityName}/${projectName}/${artifactTypeName}/${artifactID}`
      );

      const [descendantKeys, descendantNodes] = dfsVisitHelper(
        descendantMap,
        `${entityName}/${projectName}/${artifactTypeName}/${artifactID}`
      );

      const lineageDag: typeof fullDagResults = {
        dag: {
          edges: {},
          artifacts: {},
          runs: {},
        },
        loading: completeLoading,
        limited: completeLimited,
      };

      [...ancestorKeys, ...descendantKeys].forEach((key: string) => {
        lineageDag.dag.edges[key] = completeDag.edges[key];
      });

      [...ancestorNodes, ...descendantNodes].forEach((nodeID: string) => {
        if (nodeID in completeDag.artifacts) {
          lineageDag.dag.artifacts[nodeID] = completeDag.artifacts[nodeID];
        } else {
          lineageDag.dag.runs[nodeID] = completeDag.runs[nodeID];
        }
      });

      return lineageDag;
    },
    [artifactID, artifactTypeName, entityName, projectName, fullDagResults]
  );

  const filteredDagResults = useArtifactDagQueryCaller(
    entityName,
    projectName,
    artifactCollectionName,
    artifactCommitHash,
    limit,
    true
  );

  const {dag, loading, limited} = useMemo(() => {
    if (showAutoArtifacts) {
      return fullDagResults;
    } else {
      return filteredDagResults;
    }
  }, [showAutoArtifacts, fullDagResults, filteredDagResults]);

  const finalDag = React.useMemo(() => {
    if (dagMode === 'exploded') {
      return dag;
    } else if (dagMode === 'direct-lineage') {
      return getDirectLineageDag(dag, loading, limited).dag;
    }
    return makeGroupDag(dag, dagMode === 'collapsed');
  }, [dag, dagMode, loading, limited, getDirectLineageDag]);

  if (loading) {
    return <Loader name="artifact-dag-lineage" samplingRate={0.1} />;
  }
  return (
    <>
      <div style={{height: '100%'}}>
        <div>
          <label style={{marginRight: '10px'}}>Style</label>
          <Dropdown
            options={[
              {text: 'Simple', value: 'collapsed'},
              {text: 'Detailed', value: 'collapsed-show-all'},
              {text: 'Direct Lineage', value: 'direct-lineage'},
              {text: 'Complete', value: 'exploded'},
            ]}
            value={dagMode}
            selection
            onChange={(ev: React.SyntheticEvent, data: any) => {
              trackArtifactLineageTabInteraction(data.value);
              setDagMode(data.value);
              setModificationCount(modificationCount + 1);
            }}
          />
          <div style={{display: 'inline-block', marginLeft: '30px'}}>
            <Checkbox
              toggle
              checked={showAutoArtifacts}
              label={'Include generated Artifacts'}
              onClick={() => {
                setShowAutoArtifacts(!showAutoArtifacts);
                setModificationCount(modificationCount + 1);
              }}
            />
          </div>
        </div>
        <ArtifactFlowDag
          dag={finalDag}
          dagMode={dagMode}
          artifactID={fullArtifactID}
        />
      </div>
    </>
  );
};

export default ArtifactDag;
