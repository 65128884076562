import {createAction} from 'typesafe-actions';

import * as PanelViewTypes from '../panel/types';
import * as Types from './types';

export const setHighlight = createAction(
  '@view/interactionState/setHighlight',
  action =>
    (ref: Types.Ref, axis: string, value: string | number | undefined) =>
      action({ref, axis, value})
);

export const setHighlights = createAction(
  '@view/interactionState/setHighlights',
  action =>
    (
      ref: Types.Ref,
      highlights: Array<{
        axis: string;
        value: string | number | undefined;
      }>
    ) =>
      action({ref, highlights})
);

export const setPanelSelection = createAction(
  '@view/interactionState/setPanelSelection',
  action => (ref: Types.Ref, value: PanelViewTypes.Ref[]) =>
    action({ref, value})
);

export type ActionType = ReturnType<
  typeof setHighlight | typeof setPanelSelection
>;
