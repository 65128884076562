import * as v2 from './v2';
import * as v3 from './v3';
import * as v4 from './v4';
import * as v5 from './v5';
import * as v6 from './v6';
import * as v7 from './v7';
import * as v8 from './v8';
import * as migrator from './migrator';

export const PLOT_DIMS_UI = v2.PLOT_DIMS_UI;
export const MARK_OPTIONS = v7.MARK_OPTIONS;
export const DEFAULT_POINT_SIZE = v2.DEFAULT_POINT_SIZE;
export const DIM_NAME_MAP = v7.DIM_NAME_MAP;
export const POINT_SHAPES = v6.POINT_SHAPES;
export const LINE_SHAPES = v6.LINE_SHAPE_OPTIONS;

export const {migrate} = migrator
  .makeMigrator(v2.migrate)
  .add(v3.migrate)
  .add(v4.migrate)
  .add(v5.migrate)
  .add(v6.migrate)
  .add(v7.migrate)
  .add(v8.migrate);

export type AnyPlotConfig = Parameters<typeof migrate>[number];
export type PlotConfig = ReturnType<typeof migrate>;

export type SeriesConfig = PlotConfig['series'][number];
export type MarkOption = PlotConfig['series'][number]['constants']['mark'];
export type LineShapeOption =
  PlotConfig['series'][number]['constants']['lineStyle'];
