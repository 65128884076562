import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

export const MissingText = styled.div`
  color: ${globals.gray600};
  margin-bottom: 16px;
`;

export const Prompt = styled.div``;

export const PromptHeader = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 8px;
`;

export const PromptBody = styled.div`
  line-height: 20px;
`;

export const PromptButton = styled(Button)`
  &&& {
    margin: 24px 0;
  }
`;

export const PromptHint = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
`;

export const PromptHintIcon = styled(WBIcon)`
  font-size: 20px;
  margin-right: 3px;
`;

export const PromptHintBody = styled.div``;

export const Container = styled.div`
  background-color: ${globals.gray200};
  border-radius: 3px;
  max-width: 920px;
  @media (max-width: ${globals.MOBILE_WIDTH}px) {
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${Prompt} {
      margin-top: 32px;
    }
    i {
      transform: rotate(90deg);
    }
  }
  @media (min-width: ${globals.MOBILE_WIDTH}px) {
    column-gap: 40px;
    display: grid;
    grid-template-columns: 224px 1fr;
    padding: 32px;
  }
`;

export const TooltipHeader = styled.h3`
  color: ${globals.white};
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const TooltipBody = styled.div`
  color: ${globals.gray300};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const TooltipImage = styled.img`
  width: 318px;
  height: 327px;
`;
