import React from 'react';
import {Checkbox, Message, Modal} from 'semantic-ui-react';

import * as ViewHooks from '../../state/views/hooks';
import * as PanelBankConfigActions from '../../state/views/panelBankConfig/actions';
import {Ref} from '../../state/views/panelBankConfig/types';
import * as WorkspaceActions from '../../state/workspaces/actions';
import {useWorkspaceAction} from '../../state/workspaces/hooks';
import {
  OrganizationPrefix,
  PanelBankSettings,
  SectionPanelSorting,
} from '../../util/panelbank';
import * as track from './../../services/analytics';
import {useFeatureFlagAggregateRunsEnabled} from './../../util/useFeatureFlag';
import * as S from './PanelBankControlsModal.styles';
import {useEditableWorkspaceSettings} from './useEditableWorkspaceSettings';
import {PanelBankConfigWithRefs} from './usePanelBankConfigWithRefs';
import {usePanelBankConfigContext} from './usePanelBankContext';

interface PanelBankControlsModalProps {
  panelBankConfigRef: Ref;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  workspaceID: string;
  isModalOpen: boolean;
  onClose(): void;
  updatePanelBankSettings(panelSettings: PanelBankSettings): void;
}

export const PanelBankControlsModal = ({
  isModalOpen,
  onClose,
  panelBankConfigRef,
  panelBankConfigWithRefs,
  updatePanelBankSettings,
  workspaceID,
}: PanelBankControlsModalProps) => {
  const panelBankConfigContext = usePanelBankConfigContext();

  const [modalSettings, setModalSettings] = useEditableWorkspaceSettings(
    panelBankConfigWithRefs.settings
  );
  const [sortAllSections, setSortAllSections] = React.useState(false);

  const allowRunAggregation = useFeatureFlagAggregateRunsEnabled(
    panelBankConfigContext?.entityName || ''
  );

  const reorganizePanelSections = useWorkspaceAction(
    workspaceID,
    WorkspaceActions.resetWithPanelBankSettings
  );

  const showSortAllOption =
    panelBankConfigWithRefs.sections.some(
      section => section.sorted === SectionPanelSorting.Manual
    ) && modalSettings.sortAlphabetically;

  const updateSettingsAndSortPanels = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.updateSettingsAndSortPanels
  );

  return (
    <Modal open={isModalOpen} onClose={onClose}>
      <Modal.Header>Workspace Settings</Modal.Header>
      <Modal.Content className="panel-bank__modal">
        <S.MainOption>
          Organize panels into sections based on metric name.
          <div>
            <S.SubOption
              onClick={() => {
                setModalSettings({
                  ...modalSettings,
                  autoOrganizePrefix: OrganizationPrefix.FirstPrefix,
                });
              }}>
              <S.SubOptionRadio
                active={
                  modalSettings.autoOrganizePrefix ===
                    OrganizationPrefix.FirstPrefix ||
                  modalSettings.autoOrganizePrefix == null
                }
              />
              Group first prefix
            </S.SubOption>
            <S.SubOptionDescription>
              Ex. Log metric "a/b/c/d" → section a
            </S.SubOptionDescription>
            <S.SubOption
              onClick={() => {
                setModalSettings({
                  ...modalSettings,
                  autoOrganizePrefix: OrganizationPrefix.LastPrefix,
                });
              }}>
              <S.SubOptionRadio
                active={
                  modalSettings.autoOrganizePrefix ===
                  OrganizationPrefix.LastPrefix
                }
              />
              Group last prefix
            </S.SubOption>
            <S.SubOptionDescription>
              Ex. Log metric "a/b/c/d" → section a/b/c
            </S.SubOptionDescription>
          </div>
        </S.MainOption>

        {allowRunAggregation && (
          <S.MainOption>
            <Checkbox
              toggle
              label="Aggregate runs"
              checked={modalSettings.toggleRunAggregation}
              onChange={(_, value) => {
                setModalSettings({
                  ...modalSettings,
                  toggleRunAggregation: value.checked ?? false,
                });
                track.toggleRunAggregation({
                  entityName: panelBankConfigContext?.entityName || '',
                  projectName: panelBankConfigContext?.projectName || '',
                  workspaceId: panelBankConfigContext?.workspaceId || '',
                });
              }}
            />
            <S.MainOptionDescription>
              Grouped runs visualized on a line plot will be aggregated to share
              x-axis values.
            </S.MainOptionDescription>
          </S.MainOption>
        )}

        <S.MainOption>
          <Checkbox
            toggle
            label="Show empty sections"
            checked={modalSettings.showEmptySections}
            onChange={(_, value) =>
              setModalSettings({
                ...modalSettings,
                showEmptySections: value.checked ?? false,
              })
            }
          />
          <S.MainOptionDescription>
            By default, we hide sections that have no available panels.
          </S.MainOptionDescription>
        </S.MainOption>

        <S.MainOption>
          <Checkbox
            toggle
            label="Sort panels alphabetically"
            checked={modalSettings.sortAlphabetically}
            onChange={(_, value) => {
              setModalSettings({
                ...modalSettings,
                sortAlphabetically: value.checked ?? false,
              });
            }}
          />
        </S.MainOption>
        {showSortAllOption && (
          <S.SubOptionAlert>
            Some sections have been sorted manually. Do you want to override all
            sections with this global sort setting?
            <S.OptionCheckbox
              toggle
              label="Override all sections on the page"
              checked={sortAllSections}
              onChange={() => {
                setSortAllSections(!sortAllSections);
              }}
            />
          </S.SubOptionAlert>
        )}

        <div>
          {modalSettings.autoOrganizePrefix !==
            panelBankConfigWithRefs.settings.autoOrganizePrefix && (
            <Message negative style={{borderRadius: 0, padding: '8px 12px'}}>
              Reconfiguring panel organization clears your workspace and is
              irreversible. All your changes will be lost.
            </Message>
          )}
          <S.Actions>
            <S.WBModalButton onClick={onClose}>Cancel</S.WBModalButton>
            <S.WBModalButton
              primary
              onClick={() => {
                if (
                  modalSettings.autoOrganizePrefix !==
                  panelBankConfigWithRefs.settings.autoOrganizePrefix
                ) {
                  reorganizePanelSections(modalSettings);
                }
                if (modalSettings.sortAlphabetically) {
                  updateSettingsAndSortPanels({
                    panelBankSettings: modalSettings,
                    sortAllSections,
                  });
                } else {
                  updatePanelBankSettings(modalSettings);
                }
                setSortAllSections(false);
                onClose();
              }}>
              Apply
            </S.WBModalButton>
          </S.Actions>
        </div>
      </Modal.Content>
    </Modal>
  );
};
