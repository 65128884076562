import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import _ from 'lodash';
import React, {memo, useMemo, useState} from 'react';

import {isTablet} from '../../breakpoints';
import {ValueOp} from '../../util/filters';
import {Query as QueryTS, Sort as QuerySort} from '../../util/queryts';
import * as RunFeed from '../../util/runfeed';
import * as Run from '../../util/runs';
import * as TableCols from '../../util/tablecols';
import * as WBTreeHelpers from '../../util/wbtree';
import {
  WBTableColumn,
  WBTableHoverCellCoords,
  WBTableRowFields,
} from './WBTable';
import {WBTableGrid} from './WBTableGrid';
import {WBTableSortIndicatorComponent} from './WBTableSortIndicator';

export interface WBTableGridContainerProps {
  loading: boolean; // from withRunsDataLoader
  columns: WBTableColumn[];
  fixedColumns: string[];
  rows: Array<WBTableRowFields<Run.Run>>; // from parent
  tableSettings: RunFeed.Config;
  scrollXBounds: [number, number];
  childrenPaginationMap: {
    [key: string]: number | undefined;
  }; // maps row id to the active page for each paginated group, like {groupId2 => 2, groupId2 => 5}
  columnDragAccessor?: string;
  columnDropAccessor?: string;
  columnResizingAccessor?: string;
  expandedRowAddresses: string[];
  pinnedTreeRef: React.RefObject<HTMLDivElement>;
  unpinnedTreeRef: React.RefObject<HTMLDivElement>;
  expandable?: boolean;
  expanded?: boolean;
  isSingleMode?: boolean;
  isInReport?: boolean;
  showArtifactCounts?: boolean;
  showLogCounts?: boolean;
  SortIndicatorComponent?: WBTableSortIndicatorComponent;
  readOnly?: boolean;
  topLevelQueryVariables: QueryTS;
  moveColumn(): void;
  togglePinnedColumn(columnAccessor: string): void; // add column to (or remove from) conf.columnPinned
  hideColumn(columnAccessor: string): void;
  toggleExpandedRow(rowAddress: string): void;
  setTableSettings(config: Partial<RunFeed.Config>): void;
  addFilter(key: Run.Key, op: ValueOp, value: Run.Value): void;
  updateSort(updateFn: (sort: QuerySort) => void): void;
  addGroup(newGroup: Run.Key): void;
  setChildrenPagination(rowAddress: string, activePage?: number): void;
  setTableState(stateUpdate: {
    columnDragAccessor?: string;
    columnDropAccessor?: string;
    columnResizingAccessor?: string;
  }): void;
  openSortPopup?(): void;
}

const WBTableGridContainerComp: React.FC<WBTableGridContainerProps> = ({
  addFilter,
  addGroup,
  childrenPaginationMap,
  columns,
  columnDragAccessor,
  columnDropAccessor,
  columnResizingAccessor,
  expandable,
  expanded,
  expandedRowAddresses,
  fixedColumns,
  hideColumn,
  isSingleMode,
  isInReport,
  loading,
  moveColumn,
  rows,
  openSortPopup,
  pinnedTreeRef,
  readOnly,
  scrollXBounds,
  setChildrenPagination,
  setTableSettings,
  setTableState,
  showArtifactCounts,
  showLogCounts,
  SortIndicatorComponent,
  tableSettings,
  toggleExpandedRow,
  togglePinnedColumn,
  topLevelQueryVariables,
  unpinnedTreeRef,
  updateSort,
}: WBTableGridContainerProps) => {
  const [hoverCellCoords, setHoverCellCoords] =
    useState<WBTableHoverCellCoords>([]);

  const pinnedColumns = useMemo(
    () => columns.filter(c => TableCols.isPinned(tableSettings, c.accessor)),
    [tableSettings, columns]
  );

  const unPinnedColumns = useMemo(
    () => columns.filter(c => !TableCols.isPinned(tableSettings, c.accessor)),
    [tableSettings, columns]
  );

  const pinnedColumnWidth = _.sum(
    pinnedColumns.map(c => WBTreeHelpers.getColumnWidth(tableSettings, c.key))
  );

  // Create a wrapper function so we can quickly render a pinned and unpinned table
  const renderTableBody = (opts: {
    cols: WBTableColumn[];
    pinned: boolean;
    leftMargin?: number;
  }) => {
    return (
      <WBTableGrid
        topLevelQueryVariables={topLevelQueryVariables}
        treeRef={opts.pinned ? pinnedTreeRef : unpinnedTreeRef}
        loadingTable={loading}
        childrenPaginationMap={childrenPaginationMap}
        setChildrenPagination={setChildrenPagination}
        pinned={opts.pinned}
        leftMargin={opts.leftMargin || 0}
        columns={opts.cols}
        fixedColumns={fixedColumns}
        rows={rows}
        updateSort={updateSort}
        hoverCellCoords={hoverCellCoords}
        tableSettings={tableSettings}
        SortIndicatorComponent={SortIndicatorComponent}
        setTableSettings={setTableSettings}
        setTableState={setTableState}
        setHoverCellCoords={setHoverCellCoords}
        addFilter={addFilter}
        addGroup={addGroup}
        columnDragAccessor={columnDragAccessor}
        columnDropAccessor={columnDropAccessor}
        columnResizingAccessor={columnResizingAccessor}
        expandable={expandable}
        expanded={expanded}
        isSingleMode={isSingleMode}
        isInReport={isInReport}
        showArtifactCounts={showArtifactCounts}
        showLogCounts={showLogCounts}
        readOnly={readOnly}
        moveColumn={moveColumn}
        togglePinnedColumn={togglePinnedColumn}
        hideColumn={hideColumn}
        expandedRowAddresses={expandedRowAddresses}
        toggleExpandedRow={toggleExpandedRow}
        scrollXBounds={opts.pinned ? [0, 10000] : scrollXBounds}
        openSortPopup={openSortPopup}
      />
    );
  };

  // When screen size is below tablet breakpoint we don't provide pinned columns behavior.
  // Render the table with normal inline scroll.
  if (isTablet()) {
    return renderTableBody({
      cols: columns,
      pinned: false,
    });
  } else {
    // Otherwise render the table with pinned columns on top
    return (
      <>
        {renderTableBody({
          cols: pinnedColumns,
          pinned: true,
        })}
        {(expandable == null || expanded) &&
          renderTableBody({
            cols: unPinnedColumns,
            pinned: false,
            leftMargin: pinnedColumnWidth,
          })}
      </>
    );
  }
};

export const WBTableGridContainer = memo(
  WBTableGridContainerComp,
  makePropsAreEqual({
    name: 'WBTableGridContainerMemo',
    deep: [
      'query',
      'scrollXBounds',
      'groupSelection',
      'groupTempSelection',
      'tempSelectedCount',
      'fixedColumns',
    ],
    ignoreFunctions: true,
  })
);
