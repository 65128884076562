import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {
  GALLERY_PATH_SEGMENT,
  PUBLISHED_WORK_PATH_SEGMENT,
} from '../../routes/paths';

// TODO: Actually pull readonly from config or context or something
export const useReadOnly = () => {
  // THIS IS A SIN
  const history = useHistory();
  return React.useMemo(() => {
    return (
      history.location.pathname.includes('reports') ||
      history.location.pathname.includes(GALLERY_PATH_SEGMENT) ||
      history.location.pathname.includes(PUBLISHED_WORK_PATH_SEGMENT)
    );
  }, [history.location.pathname]);
};
