import React from 'react';
import {StrictTabProps, Tab} from 'semantic-ui-react';

import history from '../util/history';

type RoutedTabProps = {
  activeTabSlug?: string;
  tabSlugs: string[]; // the first tab's slug is unimportant.
  baseUrl: string;
};

/**
 * A Tab component where each tab is hooked to its own URL, so you can
 * refresh without losing your tab.
 *
 * The full URL of a tab is {baseUrl / tabSlugs[activeTabSlug]} except
 * for the first tab, which is just {baseUrl}.
 */
const RoutedTab: React.FC<RoutedTabProps & StrictTabProps> = React.memo(
  ({tabSlugs, baseUrl, activeTabSlug, ...passThroughProps}) => {
    // if the URL specifies an invalid tab, just go to baseUrl.
    if (activeTabSlug && !(tabSlugs.indexOf(activeTabSlug) > -1)) {
      history.replace(baseUrl);
    }

    return (
      <Tab
        {...passThroughProps}
        className="routed-tab"
        activeIndex={activeTabSlug ? tabSlugs.indexOf(activeTabSlug) : 0}
        onTabChange={(e, {activeIndex}) => {
          if (typeof activeIndex === 'number') {
            history.push(
              baseUrl + (activeIndex === 0 ? '' : '/' + tabSlugs[activeIndex])
            );
          }
        }}
      />
    );
  }
);

export default RoutedTab;
