import * as Obj from '@wandb/cg';
import {flatten} from '@wandb/common/util/flatten';
import * as String from '@wandb/common/util/string';
import {UserSettings} from '@wandb/common/util/vega2';
import * as _ from 'lodash';
import {get} from 'lodash';
import {Spec as VegaSpec} from 'vega';

import {RunKeyInfo, RunKeyInfoInfo} from '../types/run';
import {keyStringDisplayName} from './runs';

export * from '@wandb/common/util/vega2';

// A reference to a generic input config.
export interface FieldRef {
  type: 'field';
  name: string;
}

// A reference to a wandb run field.
export interface RunFieldRef {
  type: 'run-field';
  name: string;
}

export interface RunFieldListRef {
  type: 'run-field-list';
  name: string;
}

// A reference to a wandb run history field
export interface HistoryFieldRef {
  type: 'history-field';
  name: string;
}

export interface HistoryTableRef {
  type: 'history-table';
  tableName: string;
  keys: string[];
}

export interface RunFieldTableRef {
  type: 'run-field-table';
  tableName: string;
}

export type Ref =
  | FieldRef
  | RunFieldRef
  | RunFieldListRef
  | HistoryFieldRef
  | HistoryTableRef
  | RunFieldTableRef;

type FullRef = Ref & {raw: string};

function parseFieldRef(s: string): FieldRef | null {
  if (s.length === 0) {
    return null;
  }
  return {type: 'field', name: s};
}

function toRef(s: string): Ref | null {
  const [refName, rest] = String.splitOnce(s, ':');
  if (rest == null) {
    return null;
  }
  switch (refName) {
    case 'field':
      return parseFieldRef(rest);
    // case 'field':
    //   return parseRunFieldRef(rest);
    default:
      return null;
  }
}

export function extractRefs(s: string): FullRef[] {
  const match = s.match(new RegExp(`\\$\\{.*?\\}`, 'g'));
  if (match == null) {
    return [];
  }
  return match
    .map(m => {
      const ref = toRef(m.slice(2, m.length - 1));
      return ref == null ? null : {...ref, raw: m};
    })
    .filter(Obj.notEmpty);
}

export function hasDataset(spec: VegaSpec, name: string): boolean {
  if (spec.data == null) {
    return false;
  }
  return _.find(spec.data, dataset => dataset.name === name) != null;
}

export function isBaseDatasetName(name: string) {
  return name === 'runs' || name === 'history';
}

export function getRefDatasetNames(spec: VegaSpec): string[] {
  if (spec.data == null) {
    return [];
  }
  const dataVals = _.isArray(spec.data) ? spec.data : [spec.data];
  return dataVals
    .filter(
      d =>
        d.name != null &&
        !isBaseDatasetName(d.name) &&
        d.transform == null &&
        (d as any).url == null
    )
    .map(d => d.name);
}

export function parseSpec(spec: VegaSpec): FullRef[] | null {
  const refs = _.uniqWith(
    _.flatMap(
      _.filter(flatten(spec), v => typeof v === 'string'),
      v => extractRefs(v)
    ),
    _.isEqual
  );

  // validate
  let valid = true;

  // history tables must only be defined once
  const historyTableRefs = refs.filter(
    r => r.type === 'history-table'
  ) as HistoryTableRef[];
  for (const htr of historyTableRefs) {
    if (
      historyTableRefs.filter(checkHTR => htr.tableName === checkHTR.tableName)
        .length > 1
    ) {
      console.warn(
        'Found different definitions for history-table:',
        htr.tableName
      );
      valid = false;
    }
  }

  // history fields must refer to a valid key in a history table
  const historyFieldRefs = refs.filter(
    r => r.type === 'history-field'
  ) as HistoryFieldRef[];
  for (const hfr of historyFieldRefs) {
    if (
      !_.includes(
        _.flatMap(historyTableRefs, htr => htr.keys),
        hfr.name
      )
    ) {
      console.warn('History field refers to invalid table key:', hfr.name);
      valid = false;
    }
  }

  if (!valid) {
    return null;
  }
  return refs;
}

export function fieldInjectResult(
  ref: FullRef,
  userSettings: UserSettings
): string | null {
  let result = '';
  switch (ref.type) {
    case 'field':
      result = userSettings.fieldSettings[ref.name] || '';
      result = result.replace(/\./g, '\\.');
      // result = _.replace(result, '.', '\\.');
      return result;
  }
  return null;
}

export function makeInjectMap(
  refs: FullRef[],
  userSettings: UserSettings
): Array<{from: string; to: string}> {
  const result: Array<{from: string; to: string}> = [];
  for (const ref of refs) {
    const inject = fieldInjectResult(ref, userSettings);
    if (inject != null) {
      result.push({
        from: ref.raw,
        to: inject,
      });
    }
  }
  return result;
}

export function injectFields(
  spec: VegaSpec,
  refs: FullRef[],
  userSettings: UserSettings
): VegaSpec | null {
  const injectMap = makeInjectMap(refs, userSettings);
  return Obj.deepMapValuesAndArrays(spec, (s: any) => {
    if (typeof s === 'string') {
      for (const mapping of injectMap) {
        // Replace all (s.replace only replaces the first occurrence)
        s = s.split(mapping.from).join(mapping.to);
      }
    }
    return s;
  });
}

export function refsToInputs(refs: Ref[]) {
  const fieldInputs = (refs.filter(r => r.type === 'field') as FieldRef[]).map(
    r => r.name
  );
  const runFieldInputs = refs
    .map(r => {
      if (r.type === 'run-field') {
        return r.name;
      } else if (r.type === 'run-field-table') {
        return r.tableName;
      }
      return null;
    })
    .filter(Obj.notEmpty);
  const runFieldListInputs = (
    refs.filter(r => r.type === 'run-field-list') as RunFieldListRef[]
  ).map(r => r.name);
  const historyFieldInputs = _.uniq(
    _.flatMap(
      refs.filter(r => r.type === 'history-table') as HistoryTableRef[],
      r => r.keys
    )
  );

  return {
    fieldInputs,
    runFieldInputs,
    runFieldListInputs,
    historyFieldInputs,
  };
}

export function parseSpecJSON(specString: string): {
  parsedSpec: {[key: string]: any};
  err: string | null;
} {
  let spec: any;
  try {
    spec = JSON.parse(specString);
  } catch (err) {
    // TODO: Do this earlier so we can show nice warning.
    // TODO: parse it with a schema aware parser so we can show good errors
    console.log('INVALID JSON');
    return {
      parsedSpec: {},
      err: get(err, 'message', 'An error occurred while parsing the schema.'),
    };
  }
  if (!_.isObject(spec)) {
    return {parsedSpec: {}, err: 'Spec is not an object'};
  }
  return {parsedSpec: spec, err: null};
}

const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];

export function runFieldOptions(runKeyInfo: RunKeyInfo) {
  let keys = _.chain(runKeyInfo)
    .map((ki, k) => [k, ki])
    .map(([k, ki]: [string, RunKeyInfoInfo]) => k)
    // Typescript hacking
    .value() as unknown as string[];

  keys = _.concat(TIME_KEYS, keys);

  return keys.map(name => ({
    text: keyStringDisplayName(name),
    key: name,
    value: name,
  }));
}

export function fieldOptions(cols: string[]) {
  return cols.map(k => ({
    key: k,
    text: k,
    value: k,
  }));
}

export function getCustomChartDescription(spec: string): string | undefined {
  let parsed: any;
  try {
    parsed = JSON.parse(spec);
  } catch {
    return undefined;
  }
  if (parsed != null) {
    return parsed.description;
  }
  return undefined;
}
