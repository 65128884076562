import {captureError} from '@wandb/common/util/integrations';
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {useDispatch} from 'react-redux';
import {Path, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from 'slate-react';

import {useSelector} from '../../../../state/hooks';
import {
  addNormalizedPanelGrid,
  removeNormalizedPanelGrid,
} from '../../../../state/views/actionsInternal';
import {useWhole} from '../../../../state/views/hooks';
import {normalize} from '../../../../state/views/normalize';
import * as SectionTypes from '../../../../state/views/section/types';
import {ErrorBoundary} from '../../../ErrorBoundary';
import {WBSlateReduxBridgeContext} from '../../WBSlateReduxBridge';
import {BlockWrapper} from '../drag-drop';
import * as S from './PanelGridElement.styles';
import {PanelGridError} from './PanelGridError';
import {PanelGrid} from './types';

/**
 * This is an insane, indecipherable state machine, and I will
 * forget how it works in a couple weeks. So I hope it doesn't break.
 * The complexity comes from the fact that panel grids (ReportPanelGridSection.tsx)
 * expect refs and make their updates through our normalized Redux architecture.
 * So we need to make a normalized copy of the panel grid's metadata.
 * We then need to mirror changes made to the normalized copy
 * onto the denormalized copy. Simple enough. But there's a catch:
 * when the user hits undo, a change is made only to the denormalized copy.
 * We then need to renormalize to update what's actually displayed.
 * This is prone to infinite loops, which the state machine is designed to prevent.
 */
export const PanelGridElement: FC<
  RenderElementProps & {
    element: PanelGrid;
  }
> = ({attributes, element, children}) => {
  const {viewRef} = useContext(WBSlateReduxBridgeContext);
  if (viewRef == null) {
    throw new Error(`panel grid rendered with null view ref`);
  }
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const {metadata} = element;
  const updateDenormalizedOnNormalizedUpdate = useRef(true);
  const renormalizeOnDenormalizedUpdate = useRef(true);
  const partRefRef = useRef<SectionTypes.Ref | null>(null);

  /** On an undo, renormalize. */
  useEffect(() => {
    if (renormalizeOnDenormalizedUpdate.current) {
      updateDenormalizedOnNormalizedUpdate.current = false;

      if (partRefRef.current != null) {
        dispatch(removeNormalizedPanelGrid(viewRef.id, partRefRef.current));
      }

      const {partRef, partsWithRefs} = normalize(
        'section',
        viewRef.id,
        metadata
      );

      partRefRef.current = partRef;
      dispatch(addNormalizedPanelGrid(viewRef.id, partsWithRefs));
    }
    renormalizeOnDenormalizedUpdate.current = true;
  }, [dispatch, metadata, viewRef.id]);

  /** On dismount, remove from redux. */
  useEffect(() => {
    return () => {
      dispatch(removeNormalizedPanelGrid(viewRef.id, partRefRef.current));
    };
  }, [dispatch, viewRef.id]);

  const isLoadedIntoRedux = useSelector(
    state =>
      partRefRef.current != null &&
      state.views.parts.section[partRefRef.current.id] != null
  );

  return (
    <BlockWrapper attributes={attributes} element={element} disableClickSelect>
      <S.PanelGridWrapper
        onMouseUp={e => {
          Transforms.deselect(editor);
        }}>
        {children}
        <div contentEditable={false}>
          {partRefRef.current != null && isLoadedIntoRedux && (
            <PanelGridElementInner
              updateDenormalizedOnNormalizedUpdate={
                updateDenormalizedOnNormalizedUpdate
              }
              renormalizeOnDenormalizedUpdate={renormalizeOnDenormalizedUpdate}
              element={element}
              partRef={partRefRef.current}></PanelGridElementInner>
          )}
        </div>
      </S.PanelGridWrapper>
    </BlockWrapper>
  );
};

const PanelGridElementInner: FC<{
  element: PanelGrid;
  partRef: SectionTypes.Ref;
  updateDenormalizedOnNormalizedUpdate: MutableRefObject<boolean>;
  renormalizeOnDenormalizedUpdate: MutableRefObject<boolean>;
}> = ({
  element,
  partRef,
  updateDenormalizedOnNormalizedUpdate,
  renormalizeOnDenormalizedUpdate,
}) => {
  const {projectName, entityName, viewRef, panelSettingsRef} = useContext(
    WBSlateReduxBridgeContext
  );
  if (viewRef == null) {
    throw new Error(`panel grid rendered with null view ref`);
  }
  if (panelSettingsRef == null) {
    throw new Error(`panel grid rendered with null panel settings ref`);
  }
  const selected = useSelected();
  const whole = useWhole(partRef);
  const readOnly = useReadOnly();
  const editor = useSlateStatic();

  const pathRef = useRef<Path>(ReactEditor.findPath(editor, element));
  pathRef.current = ReactEditor.findPath(editor, element);

  /** On a normal change, quietly make a saved change. */
  useEffect(() => {
    if (updateDenormalizedOnNormalizedUpdate.current) {
      renormalizeOnDenormalizedUpdate.current = false;
      Transforms.setNodes(
        editor,
        {metadata: whole},
        {
          at: pathRef.current,
        }
      );
    }
    updateDenormalizedOnNormalizedUpdate.current = true;
  }, [
    editor,
    pathRef,
    renormalizeOnDenormalizedUpdate,
    updateDenormalizedOnNormalizedUpdate,
    whole,
  ]);

  // logics for ErrorBoundary wrapping panel grids
  const errorParams = useMemo(
    () => ({entityName, projectName}),
    [entityName, projectName]
  );

  const logError = useCallback(
    (error: any) => {
      const key = readOnly ? 'PanelGridView' : 'PanelGridEdit';
      window.analytics?.track(`${key} error`, {
        errorParams,
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack,
      });
      captureError(error, `${key} ErrorBoundary error`);
    },
    [errorParams, readOnly]
  );

  const renderPanelGridError = useCallback(() => <PanelGridError />, []);

  return (
    <ErrorBoundary renderError={renderPanelGridError} onError={logError}>
      <S.PanelGrid
        $selected={selected}
        entityName={entityName}
        projectName={projectName}
        panelSettingsRef={panelSettingsRef}
        sectionRef={partRef}
        viewRef={viewRef}
        readOnly={readOnly}></S.PanelGrid>
    </ErrorBoundary>
  );
};
