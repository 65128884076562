import React, {useContext} from 'react';

import {useRunSetsQuery} from '../../state/runs/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelSettingsViewTypes from '../../state/views/panelSettings/types';
import Export from '../Export';
import {PanelContext} from './PanelContextProvider';

interface PanelExportCSVProps {
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
}

export const PanelExportCSV = ({
  panelSettings,
  trigger,
  open,
  onClose,
}: PanelExportCSVProps) => {
  const {panelRef, runSetRefs} = useContext(PanelContext);
  let panel = ViewHooks.usePart(panelRef);
  const pageQuery = useRunSetsQuery(runSetRefs);
  if (panelSettings != null && panel.viewType === 'Run History Line Plot') {
    panel = {
      ...panel,
      config: {
        ...panelSettings,
        ...panel.config,
      },
    };
  }
  if (panelSettings != null && panel.viewType === 'Run History Line Plot') {
    panel = {
      ...panel,
      config: {
        ...panelSettings,
        ...panel.config,
      },
    };
  }

  return (
    <Export
      panel={panel}
      pageQuery={pageQuery}
      trigger={trigger}
      open={open}
      onClose={onClose}
    />
  );
};
