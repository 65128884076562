import React from 'react';
import {Dropdown} from 'semantic-ui-react';

import {
  InspectorPropertyLabel,
  InspectorPropertyWrapper,
} from '../Inspector.styles';
import {PropertyEditorComponent} from './property-editors';

/**
 * For selecting from a list of options.
 * Also consider icon-menu for a more compact representation.
 * Default is required.
 * @param props
 */
const DropdownPropertyEditor: PropertyEditorComponent<'dropdown'> = props => {
  const displayedVal = props.values.length === 1 ? props.values[0] : undefined;
  return (
    <InspectorPropertyWrapper>
      <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
      <Dropdown
        placeholder="Mixed"
        value={props.values.length > 1 ? undefined : displayedVal}
        options={props.options}
        onChange={(e, {value}) => {
          props.save(value as string | number);
        }}></Dropdown>
    </InspectorPropertyWrapper>
  );
};

export default DropdownPropertyEditor;
