import {createAction} from 'typesafe-actions';

import {WBSlateElementType} from '../../../components/Slate/WBSlate';
import {AlertSubscription} from '../../../generated/graphql';
import {ReportSpecVersion} from '../../../util/report';
import {ReportAuthor} from '../../../util/section';
import * as ReportTypes from '../../reports/types';
import {
  DiscussionComment,
  Ref as DiscussionCommentRef,
} from '../discussionComment/types';
import {
  DiscussionThread,
  Ref as DiscussionThreadRef,
} from '../discussionThread/types';
import * as Types from './types';

export const removeSection = createAction(
  '@view/report/removeSection',
  action => (ref: ReportTypes.ReportRef, sectionRef: Types.BlockRef) =>
    action({ref, sectionRef})
);

export const setWidth = createAction(
  '@view/report/setWidth',
  action => (ref: ReportTypes.ReportRef, width: Types.ReportWidthOption) =>
    action({ref, width})
);

export const setSpecVersion = createAction(
  '@view/report/setSpecVersion',
  action => (ref: ReportTypes.ReportRef, specVersion: ReportSpecVersion) =>
    action({ref, specVersion})
);

export const copySection = createAction(
  '@view/section/copySection',
  action => (ref: ReportTypes.ReportRef, fromIndex: number, toIndex: number) =>
    action({ref, fromIndex, toIndex})
);

export const insertSection = createAction(
  '@view/section/insertSection',
  action =>
    (ref: ReportTypes.ReportRef, index: number, section?: Types.BlockConfig) =>
      action({ref, index, section})
);

export const moveSection = createAction(
  '@view/section/move',
  action => (ref: ReportTypes.ReportRef, fromIndex: number, toIndex: number) =>
    action({ref, fromIndex, toIndex})
);

export const addAuthor = createAction(
  '@view/report/addAuthor',
  action => (ref: ReportTypes.ReportRef, author: ReportAuthor) =>
    action({ref, author})
);

export const removeAuthor = createAction(
  '@view/report/removeAuthor',
  action => (ref: ReportTypes.ReportRef, author: ReportAuthor) =>
    action({ref, author})
);

export const setBlocks = createAction(
  '@view/report/setBlocks',
  action => (ref: ReportTypes.ReportRef, blocks: WBSlateElementType[]) =>
    action({ref, blocks})
);

export const loadDiscussionThreads = createAction(
  '@nosave_view/report/loadDiscussionThreads',
  action =>
    (
      ref: ReportTypes.ReportRef,
      response: {discussionThreads: DiscussionThread[]}
    ) =>
      action({ref, response})
);

export const addDiscussionComment = createAction(
  '@nosave_view/report/addDiscussionComment',
  action =>
    (
      ref: ReportTypes.ReportRef,
      response: {
        discussionThread: DiscussionThread;
        discussionComment: DiscussionComment;
        alertSubscription: AlertSubscription;
      },
      discussionThreadRef?: DiscussionThreadRef
    ) =>
      action({ref, response, discussionThreadRef})
);

export const deleteDiscussionComment = createAction(
  '@nosave_view/report/deleteDiscussionComment',
  action =>
    (
      ref: ReportTypes.ReportRef,
      params: {
        discussionThreadRef: DiscussionThreadRef;
        discussionCommentRef: DiscussionCommentRef;
        deleteThread: boolean;
      }
    ) =>
      action({ref, params})
);

export const deleteDiscussionThread = createAction(
  '@nosave_view/report/deleteDiscussionThread',
  action =>
    (
      ref: ReportTypes.ReportRef,
      params: {
        discussionThreadRef: DiscussionThreadRef;
      }
    ) =>
      action({ref, params})
);
