import {isOneOf} from './utility';

export function userFlags(user: any) {
  if (user && user.flags) {
    return JSON.parse(user.flags);
  } else {
    return {};
  }
}

export const allAccessOptions = [
  'USER_WRITE',
  'USER_READ',
  'ENTITY_WRITE',
  'ENTITY_READ',
  'PRIVATE',
] as const;
export type AccessOptions = typeof allAccessOptions[number];

export function availableAccessOptions(user?: any) {
  let opts = ['USER_WRITE', 'USER_READ'];
  if (user && userFlags(user).private_projects) {
    opts = opts.concat(['ENTITY_WRITE', 'ENTITY_READ', 'PRIVATE']);
  }
  return opts;
}

export function getNameForProjectAccess(
  access: AccessOptions,
  isTeamProject: boolean
): string {
  switch (access) {
    case 'ENTITY_WRITE':
    case 'ENTITY_READ':
    case 'PRIVATE':
      return isTeamProject ? 'Team' : 'Private';
    case 'USER_READ':
      return 'Public';
    case 'USER_WRITE':
      return 'Open';
  }
}

export function getDescriptionForProjectAccess(
  access: AccessOptions,
  isTeamProject: boolean
): string {
  switch (access) {
    case 'ENTITY_WRITE':
    case 'ENTITY_READ':
    case 'PRIVATE':
      return `Only ${
        isTeamProject ? `members` : `you`
      } can view and contribute`;
    case 'USER_READ':
      return `Anyone can view`;
    case 'USER_WRITE':
      return `Anyone can submit runs or write reports (Intended for classroom projects or benchmark competitions)`;
  }
}

export function getTooltipTextForProjectAccess(
  access: AccessOptions,
  isTeamProject: boolean
): string {
  const name = getNameForProjectAccess(access, isTeamProject);
  const description = getDescriptionForProjectAccess(access, isTeamProject);
  return `${name} Project - ${description}`;
}

export function accessIsPublic(a: AccessOptions): boolean {
  return ['USER_WRITE', 'USER_READ'].indexOf(a) !== -1;
}

export function accessIsPrivate(a: AccessOptions): boolean {
  return !accessIsPublic(a);
}

export function isProjectAccess(s: string): s is AccessOptions {
  return isOneOf(s, allAccessOptions as unknown as string[]);
}
