import gql from 'graphql-tag';
import {useMemo} from 'react';

import * as Generated from '../../generated/graphql';
import * as Filter from '../../util/filters';
import * as Run from '../../util/runs';

export const FILTER_VALUE_SUGGESTIONS = gql`
  query RunValueSuggestions(
    $projectName: String!
    $entityName: String
    $keyPath: String!
    $filters: JSONString
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      runKeySuggestions(keyPath: $keyPath, filters: $filters) {
        value
        displayValue
        count
      }
    }
  }
`;

interface InputProps {
  entityName: string;
  projectName: string;
  keyPath: string;
  filters: Filter.Filter<Run.Key>;
}

export function useRunValueSuggestionsQuery(inputProps: InputProps) {
  const variables = {
    ...inputProps,
    filters: JSON.stringify(Filter.toMongo(inputProps.filters)),
  };

  const result = Generated.useRunValueSuggestionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: inputProps.keyPath === '',
    variables,
  });

  const runKeySuggestions = !result.loading
    ? result.data?.project?.runKeySuggestions
    : null;

  const valueSuggestions = useMemo(() => {
    if (runKeySuggestions == null) {
      return [];
    }

    return runKeySuggestions.map(suggestion => {
      return {
        text: Run.displayValue(suggestion.displayValue, 'null'),
        key: Run.domValue(suggestion.value),
        value: JSON.parse(suggestion.value),
        count: suggestion.count,
      };
    });
  }, [runKeySuggestions]);

  if (result.loading) {
    return {loading: true as true};
  }
  return {loading: false as false, valueSuggestions};
}
