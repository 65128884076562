import * as React from 'react';

import {Table} from '../../common/src/util/csv';
import {RunsDataQuery, toRunsDataQuery} from '../containers/RunsDataLoader';
import * as RunHooks from '../state/runs/hooks';
import * as Panels from '../util/panels';
import * as QueryTS from '../util/queryts';
import * as Query from '../util/queryts';
import * as RunFeed from '../util/runfeed';
import Export, {captureUniqueError, queryToTable} from './Export';

interface TableExportProps {
  query: RunsDataQuery;
  pageQuery: QueryTS.Query;
  tableSettings: RunFeed.Config;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
}

const TableExport = (props: TableExportProps) => {
  return (
    <Export
      pageQuery={props.pageQuery}
      trigger={props.trigger}
      open={props.open}
      onClose={props.onClose}
      panel={
        {
          __id__: 'dummyPanel',
          viewType: 'Run Selector',
          config: {columnAccessors: props.tableSettings.columnOrder},
        } as Panels.LayedOutPanel
      }
    />
  );
};

export default TableExport;

const useTableData = (pageQuery: Query.Query, config: any) => {
  let query = toRunsDataQuery(
    pageQuery,
    {selectionsAsFilters: true},
    {fullConfig: true, fullSummary: true}
  );

  query = {
    ...query,
    page: {size: 10000},
  };

  const runsData = RunHooks.useRunsData(query);

  let table: Table = {cols: [], data: []};
  try {
    table = queryToTable(runsData.data, query, pageQuery, config);
  } catch (e) {
    captureUniqueError(e);
  }

  return {table, loading: runsData.loading};
};

interface PanelRunSelectorConfig {
  columnAccessors: string[];
}

const RunSelectorPanel: React.FC<Panels.PanelProps<PanelRunSelectorConfig>> =
  () => {
    return null;
  };

export const Spec: Panels.PanelSpec<'Run Selector', PanelRunSelectorConfig> = {
  type: 'Run Selector',
  Component: RunSelectorPanel as any,
  useTableData,
  transformQuery: () => ({} as RunsDataQuery),
};
