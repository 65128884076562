import React from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {RenderElementProps, useReadOnly, useSelected} from 'slate-react';

import {BlockWrapper} from './drag-drop';
import * as S from './replit.styles';

export interface Replit extends Element {
  type: 'replit';
  replitUser: string;
  replName: string;
}

export const isReplit = (node: Node): node is Replit =>
  (node as any).type === 'replit';

export const ReplitElement: React.FC<
  RenderElementProps & {
    element: Replit;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();
  const readOnly = useReadOnly();
  return (
    <BlockWrapper attributes={attributes} element={element}>
      {!readOnly && <ReplitWarning />}
      <S.EmbeddedIframe
        selected={selected}
        src={`https://replit.com/${element.replitUser}/${element.replName}?lite=true`}
        title="replit"
        frameBorder="0"
      />
      {children}
    </BlockWrapper>
  );
};

const ReplitWarning: React.FC = React.memo(() => {
  return (
    <S.WarningContainer>
      <S.Warning>
        <S.WarningHeader>
          DO NOT PASTE ANY SENSITIVE INFORMATION INTO REPLIT.
        </S.WarningHeader>
        <S.WarningListContainer>
          This includes, but is not limited to:
          <S.WarningList>
            <S.WarningListItem>API Keys</S.WarningListItem>
            <S.WarningListItem>Sensitive company data</S.WarningListItem>
            <S.WarningListItem>Private user data</S.WarningListItem>
          </S.WarningList>
        </S.WarningListContainer>
      </S.Warning>
    </S.WarningContainer>
  );
});

const REPLIT_REGEX =
  /^(?<url>https?:\/\/replit\.com\/(?<replitUser>[^/]+)\/(?<replName>[^/?#]+))/;

export function withReplit<T extends Editor>(
  writeEnabled = false
): (editor: T) => T {
  return editor => {
    const {isVoid, insertText} = editor;

    editor.isVoid = element => {
      return isReplit(element) ? true : isVoid(element);
    };

    editor.insertText = text => {
      if (!writeEnabled) {
        insertText(text);
        return;
      }

      const match = REPLIT_REGEX.exec(text);
      if (match?.groups != null) {
        const {replitUser, replName} = match.groups;
        insertReplitElement(replitUser, replName);
        return;
      }

      insertText(text);
    };

    return editor;

    function insertReplitElement(replitUser: string, replName: string): void {
      const node: Node = {
        type: 'replit',
        replitUser,
        replName,
        children: [{text: ''}],
      };

      Transforms.splitNodes(editor);
      Transforms.insertNodes(editor, node);
    }
  };
}
