import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {WBMenuOnSelectHandler} from '@wandb/ui';
import React, {memo} from 'react';
import {Button} from 'semantic-ui-react';

import {EMPTY_CONFIG as EMPTY_TABLE_SETTINGS} from '../../util/runfeed';
import * as S from './WBTablePagination.styles';

interface WBTablePaginationProps {
  loading: boolean;
  currentPage: number;
  maxPage: number;
  currentPageSize?: number;
  totalRowCount: number;
  style?: any;
  onClickPrevPage(): void;
  onClickNextPage(): void;
  setPageSize(newPageSize: number): void;
}

const WBTablePaginationComp: React.FC<WBTablePaginationProps> = ({
  loading,
  currentPage,
  maxPage,
  onClickPrevPage,
  onClickNextPage,
  setPageSize,
  totalRowCount,
  currentPageSize,
  style,
}) => {
  currentPageSize = currentPageSize || EMPTY_TABLE_SETTINGS.pageSize;

  return (
    <div className="pagination-controls-wrapper" style={style}>
      <div className="pagination-controls">
        {!loading && (
          <S.PageSizeSelectWrapper>
            <S.PageSizeSelect
              data-test="pageSize-popup"
              menuTheme="light"
              menuFontSize={16}
              menuLineHeight={24}
              options={[10, 20, 50, 100].map(size => ({
                name: `${size}`,
                value: size,
                icon: null,
                'data-test': 'pageSize-set',
              }))}
              value={currentPageSize}
              displayedValue={
                totalRowCount > 0
                  ? `${1 + (currentPage - 1) * currentPageSize}-${Math.min(
                      totalRowCount,
                      currentPage * currentPageSize
                    )}`
                  : 0
              }
              autoMenuWidth
              onSelect={setPageSize as WBMenuOnSelectHandler}
            />
            of {totalRowCount}
          </S.PageSizeSelectWrapper>
        )}
        <Button.Group className="pagination-buttons">
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            disabled={loading || currentPage === 1}
            onClick={onClickPrevPage}>
            <LegacyWBIcon name="previous" />
          </Button>
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            disabled={loading || currentPage === maxPage}
            onClick={onClickNextPage}>
            <LegacyWBIcon name="next" />
          </Button>
        </Button.Group>
      </div>
    </div>
  );
};

export const WBTablePagination = memo(WBTablePaginationComp);
