import {urlPrefixed} from '@wandb/common/config';
import React from 'react';

import {
  Preview,
  PreviewHint,
  PreviewImage,
  PreviewSubtitle,
  PreviewTitle,
} from './ReportPreview.styles';

type ReportPreviewProps = {
  entityName: string;
  projectName: string;
  small?: boolean;
  onClick?: () => void;
};

export const ReportPreview: React.FC<ReportPreviewProps> = React.memo(
  ({entityName, projectName, small, onClick}) => {
    return (
      <Preview id="report-preview" small={small} onClick={onClick}>
        <PreviewTitle>This week's findings</PreviewTitle>
        <PreviewSubtitle>
          {entityName}/{projectName}
        </PreviewSubtitle>
        <PreviewImage src={urlPrefixed(`/report-preview-charts.png`)} />
        <PreviewHint>Add description...</PreviewHint>
      </Preview>
    );
  }
);
