import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {envIsLocal, urlPrefixed} from '@wandb/common/config';
import {MatchParams} from '@wandb/common/types/base';
import {updateProfilerContext} from '@wandb/common/util/profiler';
import {WBIcon} from '@wandb/ui';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import {
  useSearchNavQueryQuery,
  useUpsertModelMutation,
} from '../../generated/graphql';
import {GALLERY_PATH_SEGMENT} from '../../routes/paths';
import {getRouteMatch} from '../../routes/utils';
import {isInIframe} from '../../setup';
import {useViewer} from '../../state/viewer/hooks';
import {hotkeyPressed} from '../../util/hotkeys';
import {AccessOptions} from '../../util/permissions';
import {getSweepDisplayName} from '../../util/sweeps';
import {decodeURIComponentSafe} from '../../util/url';
import {AddUserModal} from '../AddUserModal';
import {Breadcrumbs} from '../Breadcrumbs';
import {InviteTeamButton} from '../InviteTeamButton';
import {useNavContext} from '../NavContextProvider';
import {ActivationMethods} from './../../services/analytics/types';
import AlertsManagementModal from './../AlertsManagementModal';
import ProfileDropdownMenu from './../ProfileDropdownMenu';
import WBLogo from './../WBLogo';
import {LocalSearch} from './LocalSearch';
import * as S from './SearchNav.styles';
import {SearchNavQueryData} from './types';
import {useSearchNavAnalytics} from './useSearchNavAnalytics';

const SearchNav: React.FC = React.memo(() => {
  const viewer = useViewer();
  const history = useHistory();
  const location = useLocation();
  const {activationMethods, trackSearchNavOnScreen} = useSearchNavAnalytics();
  const {hidden} = useNavContext();
  const match: {params: MatchParams} = getRouteMatch(location.pathname) ?? {
    params: {},
  };
  const [showModal, setShowModal] = useState(false);
  const [searching, setSearching] = useState(false);

  const setSearchingWithMetaInfo = useCallback(
    (isSearching: boolean, activationMethod: ActivationMethods) => {
      if (isSearching) {
        trackSearchNavOnScreen(activationMethod);
      }
      setSearching(isSearching);
    },
    [setSearching, trackSearchNavOnScreen]
  );

  const handleGlobalKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (hotkeyPressed(e, 'globalSearch')) {
        setSearchingWithMetaInfo(true, activationMethods.KeyboardHotkey);
      }
    },
    [activationMethods, setSearchingWithMetaInfo]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const {entityName, runName, groupName, sweepName, reportNameAndID} =
    match.params;

  // HAX: the project name URI we get from react-router might be encoded.
  // Decode this URI component before using it.
  // If this happens again on another URI component (e.g. entity name), we
  // should do more general fix at the react-router layer.
  const projectName =
    match.params.projectName != null
      ? decodeURIComponentSafe(match.params.projectName)
      : undefined;

  const {data: searchNavData} = useSearchNavQueryQuery({
    variables: {
      entityName: entityName ?? '',
      projectName: projectName ?? '',
      runName: runName ?? '',
      sweepName: sweepName ?? '',
    },
  });
  const data = searchNavData as SearchNavQueryData;

  useEffect(() => {
    updateProfilerContext(entityName, projectName, viewer?.admin);
  }, [entityName, projectName, viewer?.admin]);

  const [upsertModelMutation] = useUpsertModelMutation();

  // hide the search nav in gallery for mobile
  const currentPath: string = window.location.pathname.split('/')[1] ?? '';
  const isMobileGallery: boolean = currentPath === GALLERY_PATH_SEGMENT;
  const isSignup = currentPath === 'signup';

  // If artifact type name is available, decode any '@' chars
  // for the correct artifact type name to display in the nav.
  const artifactTypeName =
    match.params.artifactTypeName != null
      ? decodeURIComponent(match.params.artifactTypeName)
      : undefined;

  if (hidden) {
    return null;
  }

  // TODO: this component is getting re-rendered on each poll...
  return (
    <>
      <S.SearchNav
        className={`search-nav ${
          isMobileGallery ? 'hide-in-mobile-gallery' : ''
        }`}
        id="searchNav">
        {!isInIframe() && (
          <S.ExperimentalTestBar id="search-nav-experimental-test-bar" />
        )}
        <div className="nav-left-container">
          <WBLogo
            onClick={(e: any) => {
              window.analytics?.track('Home clicked');
              if (isInIframe()) {
                e.preventDefault();
                window.open(urlPrefixed());
              }
            }}
          />
          {isInIframe() && (
            <>
              <h1>Weights &amp; Biases</h1>
              <WBIcon
                className="nav"
                title="Go backward"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  history.goBack();
                }}
                useNewIconComponent
                name="chevron-left"
              />
              <WBIcon
                className="nav"
                title="Go forward"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  history.goForward();
                }}
                useNewIconComponent
                name="chevron-right"
              />
            </>
          )}
          {!isInIframe() && (
            <>
              {!isSignup && data && viewer && (
                <>
                  <LegacyWBIcon
                    name="search"
                    className="local-search-toggle"
                    onClick={() =>
                      setSearchingWithMetaInfo(
                        !searching,
                        activationMethods.MouseClick
                      )
                    }
                  />
                  {searching && (
                    <LocalSearch
                      data={data}
                      matchParams={match.params}
                      history={history}
                      onClose={() => {
                        setSearching(false);
                      }}
                    />
                  )}
                </>
              )}
              {entityName && (
                <Breadcrumbs
                  entityName={entityName}
                  projectName={projectName}
                  project={data && data.project}
                  tab={match.params.tab}
                  artifactTypeName={artifactTypeName}
                  artifactCollectionName={match.params.artifactCollectionName}
                  artifactCommitHash={match.params.artifactCommitHash}
                  artifactTab={match.params.artifactTab}
                  filePath={match.params.filePath}
                  updateProjectAccess={(access: AccessOptions) =>
                    projectName != null &&
                    data &&
                    data.project &&
                    upsertModelMutation({
                      variables: {
                        entityName,
                        name: projectName,
                        access,
                      },
                    })
                  }
                  groupName={groupName}
                  sweepName={
                    data &&
                    data.project &&
                    data.project.sweep &&
                    data.project.sweep.name
                  }
                  sweepDisplayName={
                    data &&
                    data.project &&
                    data.project.sweep &&
                    getSweepDisplayName(data.project.sweep)
                  }
                  reportNameAndId={reportNameAndID}
                  isBenchmark={data && data.project && data.project.isBenchmark}
                  runName={runName}
                  runDisplayName={
                    data &&
                    data.project &&
                    data.project.run &&
                    data.project.run.displayName
                  }
                  runState={
                    data &&
                    data.project &&
                    data.project.run &&
                    data.project.run.state
                  }
                  // authorName={authorName}
                />
              )}
            </>
          )}
        </div>
        {isInIframe() ? (
          <S.OpenPageLink
            href={document.location.href.replace('jupyter=true', '')} // TODO: actually parse the query and handle other flags?
          >
            Open page <WBIcon style={{marginLeft: 5}} name="right-arrow" />
          </S.OpenPageLink>
        ) : (
          <>
            {viewer != null && !envIsLocal && <InviteTeamButton />}
            {viewer != null && envIsLocal && (
              <>
                <Button
                  color="yellow"
                  onClick={() => {
                    setShowModal(true);
                    window.analytics?.track('Create Team Started', {
                      location: 'search nav',
                    });
                  }}
                  style={{
                    padding: '6px 9px',
                    alignSelf: 'center',
                    margin: '0 16px 0 0 ',
                  }}>
                  <span style={{fontSize: '14px'}}>Invite team</span>
                </Button>
                {showModal && (
                  <AddUserModal
                    data-test="invite-team"
                    open={showModal}
                    header={<div>Invite team</div>}
                    onOpen={() => setShowModal(true)}
                    onClose={() => setShowModal(false)}
                  />
                )}
              </>
            )}
          </>
        )}
        {!isInIframe() && (
          <>
            {viewer && <AlertsManagementModal location="search nav" />}
            <ProfileDropdownMenu isSignup={isSignup} />
          </>
        )}
      </S.SearchNav>
    </>
  );
});

export default SearchNav;
