import {isNotNullOrUndefined, Struct} from '@wandb/common/util/types';

import {GALLERY_PATH_SEGMENT} from '../../routes/paths';
import {courses} from '../urls';
import {isOneOf} from '../utility';

export const FC_PROJECT_USAGE_PATH = '/__WB_PROJECT_USAGE__';
export const BIZZABO_PATH = '/__WB_BIZZABO__';

export const SORT_ORDERS = ['Latest', 'Popular', 'Trending'] as const;
export type SortOrder = typeof SORT_ORDERS[number];
export const SORT_ORDERS_SET = new Set(SORT_ORDERS);
export const DEFAULT_SORT_ORDER = SORT_ORDERS[0];

export const LANGUAGES = [
  'English',
  '中文 (简体)',
  '日本語',
  '한국어',
  'Español',
  'Français',
  'Deutsch',
  'Русский',
] as const;
export type Language = typeof LANGUAGES[number];
export const LANGUAGES_SET = new Set(LANGUAGES);
export function isLanguage(str: string): str is Language {
  return LANGUAGES_SET.has(str as Language);
}
export const DEFAULT_LANGUAGE = LANGUAGES[0];

const hrefLangByLanguage: {[l in Language]: string} = {
  English: 'en',
  '中文 (简体)': 'zh',
  日本語: 'ja',
  한국어: 'ko',
  Español: 'es',
  Français: 'fr',
  Deutsch: 'de',
  Русский: 'ru',
};
export function getHREFLang(l: Language): string {
  return hrefLangByLanguage[l];
}

export const POST_CATEGORY_ID = 'ir6df9nxe';
export const DISCUSSION_CATEGORY_ID = '90vhuh15v';
export const GRADIENT_DISSENT_CATEGORY_ID = 's4jjx15g2';

export type ReportAuthor = {username: string; name?: string};

export type ReportIDWithTagIDs = {
  id: string;
  tagIDs: string[];
  primaryTagID?: string;
  onlyShowOnTagIDs?: string[];
  authors: ReportAuthor[];
  addedAt: string;
  language: Language;
  isTranslationOf?: string;
  pending?: true;
  announcement?: true;
  customSchema?: string;
};

export type Headings = {
  title: string;
  subtitle: string;
  description: string;
};

export type GallerySpec = {
  headings: Headings;
  reportIDsWithTagIDs: ReportIDWithTagIDs[];
  reportIDsWithTagV2IDs: ReportIDWithTagIDs[];
  categories: Tag[];
  tags: Tag[];
  tagsV2: TagV2[];
  topLevelFeaturedReportIDs: string[];
  repos: Repo[];
  eventMetadatas: FCEventMetadata[];
};

export const EMPTY_GALLERY_SPEC: GallerySpec = {
  headings: {
    title: '',
    subtitle: '',
    description: '',
  },
  reportIDsWithTagIDs: [],
  reportIDsWithTagV2IDs: [],
  categories: [],
  tags: [],
  tagsV2: [],
  topLevelFeaturedReportIDs: [],
  repos: [],
  eventMetadatas: [],
};

export type Tag = {
  id: string;
  name: string;
  description: string;
  imageURL: string;
  linkForm: string;
  featuredReportIDs: string[];
  color?: string;
  updatedAt?: string;
};

export type TagV2 = Tag & {
  parent: string | null;
  featuredReports: FeaturedReportEntry[];
  hidden: boolean;
  cta?: string;
};

export type FeaturedReportCategory = 'new' | 'popular';

export type FeaturedReportEntry = {
  reportID: string;
  category: FeaturedReportCategory;
};

export type Repo = {
  id: string;
  url: string;
  name: string;
  description: string;
  linkForm: string;
  customContent: string;
  links: RepoLink[];
  imageURL: string;
  addedAt?: string;
  pending?: boolean;
};

export type RepoWithUsage = Repo & Pick<ProjectUsage, 'userCounts'>;

export type RepoLink = {
  id: string;
  url: string;
  name: string;
  description: string;
  imageURL: string;
  addedAt?: string;
  pending?: boolean;
};

export type FCEventMetadata = {
  bizzaboID: number;
  imageURL: string;
};

export type ProjectUsage = ProjectMetadata & {
  userCounts: UserCountForWeek[];
};

export type ProjectUsageForWeek = ProjectMetadata & UserCountForWeek;

export type ProjectMetadata = {
  projectType: string;
  projectName: string;
  source: string;
};

export type UserCountForWeek = {
  userCount: number;
  weekStr: string;
};

export const repoNameByProjectUsageProjectName: {[key: string]: string} = {
  jax: ``,
  keras: `Keras`,
  spacy: `spaCy`,
  torch: `PyTorch`,
  yolov5: `YOLOv5`,
  sklearn: `Scikit-Learn`,
  xgboost: `XGBoost`,
  catboost: ``,
  lightgbm: `LightGBM`,
  tensorflow: `TensorFlow`,
  transformers: `Hugging Face Transformers`,
  pytorch_lightning: `PyTorch Lightning`,
  simpletransformers: ``,
};

export type BizzaboEvent = {
  id: number;
  name: string;
  url: string;
  startDate: string;
  timezone: string;
  type?: string[];
  fcMetadata: FCEventMetadata | null;
};

export type ReportMetadata = {
  id: string;
  language: Language;
  isTranslationOf?: string;
  pending?: true;
  announcement?: true;
  tags?: Tag[];
  primaryTagID?: string;
  onlyShowOnTagIDs?: string[];
  authors?: ReportAuthor[];
  addedAt?: string;
  entityName: string;
  project?: {
    id: string;
    entityName: string;
    name: string;
  };
  displayName: string;
  description: string;
  previewUrl?: string;
  starCount: number;
  recentStarCount: number;
  starred?: boolean;
  discussionThreads: Struct;
  customSchema?: string;
};

export type ReportRSSMetadata = {
  id: string;
  displayName: string;
  authors: string[];
  tags: string[];
  addedAt: string;
  description?: string;
  link: string;
};

export type ReportAuthorMetadata = {
  id: string;
  username: string;
  name: string;
  reportCount: number;
  bio?: string;
  company?: string;
  location?: string;
  photoUrl?: string;
};

export type NewsletterSubscriptionQueryData = {
  id: string;
  user?: {
    username: string;
    email: string;
  };
  email?: string;
  createdAt: string;
};

export type NewsletterSubscription = {
  id: string;
  username?: string;
  email?: string;
  createdAt: string;
};

export function sortOrTagToPath(tags: Tag[], s: string): string {
  for (const t of tags) {
    if (t.name === s) {
      return t.linkForm;
    }
  }
  return formatToPath(s);
}

function formatToPath(s: string): string {
  return s.toLowerCase().replace('&', 'and').split(' ').join('-').trim();
}

const gallerySortPathSet = new Set(SORT_ORDERS.map(formatToPath));
export function pathSegmentIsGallerySort(segment: string): boolean {
  return gallerySortPathSet.has(segment);
}

export type TranslationData = {
  inputReport: ReportIDWithTagIDs;
  originalReport: ReportIDWithTagIDs;
  translations: ReportIDWithTagIDs[];
};

export function getTranslationData(
  reportIDsWithTagIDs: ReportIDWithTagIDs[],
  reportID: string
): TranslationData | null {
  const inputReport = reportIDsWithTagIDs.find(({id}) => id === reportID);
  if (inputReport == null) {
    return null;
  }

  const originalReport =
    inputReport.isTranslationOf != null
      ? reportIDsWithTagIDs.find(({id}) => id === inputReport.isTranslationOf)
      : inputReport;
  if (originalReport == null) {
    return null;
  }

  const translations = reportIDsWithTagIDs.filter(
    ({isTranslationOf}) => isTranslationOf === originalReport.id
  );
  if (translations.length === 0) {
    return null;
  }

  return {inputReport, originalReport, translations};
}

export function getTagByID(tags: TagV2[]): Map<string, TagV2> {
  return new Map(tags.map(t => [t.id, t]));
}

const FC_PAGES = [
  `blog`,
  `ml-news`,
  `podcast`,
  `events`,
  `projects`,
  `topics`,
] as const;
export type FCPage = typeof FC_PAGES[number];
export function isFCPage(s: string): s is FCPage {
  return isOneOf(s, FC_PAGES as unknown as string[]);
}

export const MAIN_PAGE: FCPage = `blog`;
export const TAG_PAGES: FCPage[] = [`ml-news`, `podcast`];

export function getSetPageLink(newPage: FCPage): string {
  if (newPage === MAIN_PAGE) {
    return getPathFromSegments([GALLERY_PATH_SEGMENT]);
  }
  return getPathFromSegments([GALLERY_PATH_SEGMENT, newPage]);
}

export function getSetActiveTagLink(tagLinkForm: string): string {
  return getPathFromSegments([GALLERY_PATH_SEGMENT, `blog`, tagLinkForm]);
}

export function getSetActiveRepoLink(repo: RepoWithUsage): string {
  return getPathFromSegments([GALLERY_PATH_SEGMENT, `projects`, repo.linkForm]);
}

export function getSetActiveTopicLink(topic: TagV2): string {
  return getPathFromSegments([GALLERY_PATH_SEGMENT, `topics`, topic.linkForm]);
}

function getPathFromSegments(pathSegments: string[]): string {
  return `/${pathSegments.join('/')}`;
}

type DeriveTagPageParams = {
  reportIDsWithTagV2IDs: ReportIDWithTagIDs[];
  tags: TagV2[];
  reportID: string | null;
};

export function deriveTagPage({
  reportIDsWithTagV2IDs,
  tags,
  reportID,
}: DeriveTagPageParams): FCPage | null {
  if (reportID == null) {
    return null;
  }

  const reportIDWithTagV2IDs = reportIDsWithTagV2IDs.find(
    r => r.id === reportID
  );
  if (reportIDWithTagV2IDs == null) {
    return null;
  }

  const tagPageLinkFormSet: Set<string> = new Set(TAG_PAGES);
  const tagPageByID = new Map(
    tags.filter(t => tagPageLinkFormSet.has(t.linkForm)).map(t => [t.id, t])
  );

  return (
    reportIDWithTagV2IDs.tagIDs
      .map(tagID => tagPageByID.get(tagID))
      .filter(isNotNullOrUndefined)
      .map(t => t.linkForm)
      .filter(isFCPage)[0] ?? null
  );
}

export type FCNavLink = {
  label: string;
} & ({page: FCPage} | {url: string});

export const FC_NAV_LINKS: FCNavLink[] = [
  {page: `blog`, label: `Articles`},
  {page: `projects`, label: `Projects`},
  {page: `ml-news`, label: `ML News`},
  {page: `events`, label: `Events`},
  {page: `podcast`, label: `Podcast`},
  {url: courses(), label: `Courses`},
];

export function getLabelForFCPage(page: FCPage): string {
  return FC_NAV_LINKS.find(l => `page` in l && l.page === page)?.label ?? page;
}
