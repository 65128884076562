import Editor from '@wandb/common/components/Monaco/Editor';
import * as _ from 'lodash';
import {
  editor,
  IDisposable,
  IPosition,
  languages,
  Position,
} from 'monaco-editor';
import React, {useEffect, useMemo, useState} from 'react';

import {
  useEntityArtifactsQuery,
  useRunInfoAndConfigQuery,
} from '../../generated/graphql';
import {InstrumentedLoader as Loader} from '../utility/InstrumentedLoader';

export interface Job {
  fullJobName: string;
  createdBy: {
    config?: string;
  };
}

export const cleanConfig = (config: {[key: string]: any}) => {
  const configCopy = {...config};
  Object.entries(configCopy).forEach(([k, v]) => {
    if (_.isObject(v) && 'value' in v) {
      configCopy[k] = configCopy[k].value;
    } else if (k.includes('.desc')) {
      delete configCopy[k];
    } else if (_.isObject(v)) {
      configCopy[k] = cleanConfig(v);
    }
  });
  return configCopy;
};

export interface ArtifactInfos {
  name: string;
  entity: string;
  project: string;
  label: string;
  version: string;
}

export const useNamesAndAliasCollector = (
  entityName: string,
  entityArtifactsQuery: ReturnType<typeof useEntityArtifactsQuery>
) => {
  return useMemo(() => {
    const namesAndAliasesCollector: {[key: string]: ArtifactInfos} = {};
    if (entityArtifactsQuery.data == null) {
      return {};
    }
    entityArtifactsQuery.data.entity?.projects?.edges.forEach(projectEdge => {
      const artifactProjectName = projectEdge.node?.name;
      if (artifactProjectName == null) {
        return;
      }
      projectEdge.node?.artifactTypes.edges.forEach(artifactTypeEdge => {
        artifactTypeEdge.node?.artifactSequences?.edges.forEach(
          artifactSequenceEdge => {
            const artifactSequenceName = artifactSequenceEdge.node?.name;
            artifactSequenceEdge.node?.artifacts.edges.forEach(
              artifactsEdge => {
                const artifactId = artifactsEdge.node.id;
                artifactsEdge.node.aliases.forEach(alias => {
                  const aliasName = `${artifactSequenceName}:${alias.alias}`;
                  const aliasLabel = `Artifact/${aliasName}`;
                  namesAndAliasesCollector[aliasLabel] = {
                    label: aliasLabel,
                    name: aliasName,
                    project: artifactProjectName,
                    entity: entityName,
                    version: artifactId,
                  };
                });
                const versionName = `${artifactSequenceName}:v${artifactsEdge.node.versionIndex}`;
                const versionLabel = `Artifact/${versionName}`;
                namesAndAliasesCollector[versionLabel] = {
                  label: versionLabel,
                  name: versionName,
                  project: artifactProjectName,
                  entity: entityName,
                  version: artifactId,
                };
              }
            );
          }
        );
      });
    });
    return namesAndAliasesCollector;
  }, [entityArtifactsQuery, entityName]);
};

export const REQUIRED_STR = '<REQUIRED>';
export const RESOURCE_CONFIGS = {
  kubernetes: {
    namespace: 'wandb',
    job_labels: {},
    registry: '',
    job_spec: '',
  },
  'gcp-vertex': {
    gcp_staging_bucket: '',
    gcp_artifact_repo: '',
    gcp_machine_type: 'n1-standard-4',
    gcp_accelerator_type: 'ACCELERATOR_TYPE_UNSPECIFIED',
    gcp_accelerator_count: 0,
  },
  sagemaker: {
    RoleArn: REQUIRED_STR,
    EcrRepoName: '',
    ResourceConfig: {
      InstanceType: 'ml.m4.xlarge',
      InstanceCount: 1,
      VolumeSizeInGB: 2,
    },
    OutputDataConfig: {S3OutputPath: REQUIRED_STR},
    StoppingCondition: {
      MaxRuntimeInSeconds: 3600,
    },
  },
  'local-container': '',
  'local-process': '',
};

export type ResourceConfigKeys = keyof typeof RESOURCE_CONFIGS;
export const getCompletionValues = (
  dict: {[key: string]: ArtifactInfos},
  model: editor.ITextModel,
  position: IPosition
) => {
  const items: languages.CompletionItem[] = Object.keys(dict).map(key => {
    return {
      filterText: key,
      label: key,
      insertText: key,
      kind: languages.CompletionItemKind.Function,
      range: {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        endColumn: model.getWordUntilPosition(position).endColumn,
        startColumn: model.getWordUntilPosition(position).startColumn,
      },
    };
  });
  return items;
};

interface LaunchConfigEditorProps {
  entityName: string;
  projectName: string;
  runName?: string;
  job?: Job;
  launchConfig: string;
  setLaunchConfig: (v: string) => void;
}

export const LaunchConfigEditor: React.FC<LaunchConfigEditorProps> = ({
  entityName,
  projectName,
  runName,
  job,
  launchConfig,
  setLaunchConfig,
}) => {
  const [completionDisposable, setCompletionDisposable] =
    useState<IDisposable | null>(null);
  const disposableRef = React.useRef<IDisposable | null>(null);
  const isSourcedFromRun = runName != null;
  const isSourcedFromJob = job != null;
  const noJobOrRunInfo = !isSourcedFromRun && !isSourcedFromJob;
  const runInfoAndConfig = useRunInfoAndConfigQuery({
    variables: {
      projectName,
      entityName,
      runName: runName ?? '',
    },
    skip: !isSourcedFromRun,
  });

  const disposeProvider = (provider: IDisposable | null) => {
    if (provider != null) {
      provider.dispose();
    }
    setCompletionDisposable(null);
  };

  useEffect(() => {
    return () => disposeProvider(disposableRef.current);
  }, []);
  const entityArtifactsQuery = useEntityArtifactsQuery({
    variables: {entityName},
  });
  const namesAndAliases: {[key: string]: ArtifactInfos} =
    useNamesAndAliasCollector(entityName, entityArtifactsQuery);

  // TODO: this should really be done in something like `useLaunchConfig` to decouple it from the UI
  // initialize config
  useEffect(() => {
    if (
      isSourcedFromRun &&
      (runInfoAndConfig.loading || runInfoAndConfig.error)
    ) {
      return;
    }

    let runConfig: any = {};
    if (isSourcedFromRun) {
      try {
        runConfig = JSON.parse(runInfoAndConfig.data?.project?.run?.config);
      } catch {
        console.log('failed to fetch config or config malformed');
      }
    } else if (job != null) {
      // tsc is unhappy with isSourcedFromJob here
      try {
        runConfig = JSON.parse(job.createdBy.config ?? '{}');
      } catch {
        console.log('failed to fetch config or config malformed');
      }
    }
    const filteredRunConfig = _.omit(cleanConfig(runConfig), '_wandb');
    const swappedRunConfig = swapArtifactObjects(filteredRunConfig);
    const shownLaunchConfig: any = {
      overrides: {
        ...((!isSourcedFromRun ||
          runInfoAndConfig.data?.project?.run?.runInfo?.args != null) && {
          args: runInfoAndConfig.data?.project?.run?.runInfo?.args ?? [],
        }),
        run_config: swappedRunConfig ?? {},
        ...(!isSourcedFromRun &&
          !isSourcedFromJob && {
            entry_point: [],
          }),
      },
    };
    setLaunchConfig(JSON.stringify(shownLaunchConfig, null, 2));
  }, [
    entityName,
    projectName,
    isSourcedFromRun,
    runInfoAndConfig,
    isSourcedFromJob,
    noJobOrRunInfo,
    setLaunchConfig,
    job,
  ]);

  // reset the provided artifacts when the entity changes
  useEffect(() => {
    if (entityArtifactsQuery.loading) {
      disposeProvider(disposableRef.current);
    }
  }, [completionDisposable, entityArtifactsQuery.loading]);

  const showLoader =
    launchConfig == null ||
    (isSourcedFromRun && runInfoAndConfig.data == null) ||
    entityArtifactsQuery.loading ||
    entityArtifactsQuery.data == null;

  return (
    <div className="editor-wrapper">
      {showLoader ? (
        <Loader name="edit-launch-config" />
      ) : (
        <Editor
          value={launchConfig}
          onChange={setLaunchConfig}
          height={200}
          language="json"
          theme="vs-dark"
          onMount={(monacoEditor: editor.IStandaloneCodeEditor) => {
            const disposable = languages.registerCompletionItemProvider(
              'json',
              {
                provideCompletionItems: (
                  model: editor.ITextModel,
                  position: Position
                ) => {
                  const items = getCompletionValues(
                    namesAndAliases,
                    model,
                    position
                  );
                  return {suggestions: items};
                },
              }
            );
            setCompletionDisposable(disposable);
            disposableRef.current = disposable;
          }}
        />
      )}
    </div>
  );
};

export const autoPopulateConfigResources = (
  resourceName: ResourceConfigKeys
) => {
  const resourceConfig = RESOURCE_CONFIGS[resourceName];
  if (resourceConfig === '') {
    return resourceConfig;
  }
  return JSON.stringify(
    {resource_args: {[resourceName]: RESOURCE_CONFIGS[resourceName]}},
    null,
    2
  );
};

interface TypedObject {
  _type: string;
  id: string;
}

function isTypedObject(o: object): o is TypedObject {
  return '_type' in o && 'id' in o;
}

function isArtifactVersion(t: TypedObject) {
  return t._type === 'artifactVersion' && t.id != null;
}

export const swapArtifactObjects = (
  config: Record<string, unknown> | unknown
): any => {
  const clonedConfig = _.cloneDeep(config);
  if (!_.isObject(clonedConfig)) {
    return clonedConfig;
  }
  if (isTypedObject(clonedConfig) && isArtifactVersion(clonedConfig)) {
    return 'wandb-artifact://_id/' + clonedConfig.id;
  }
  if (Array.isArray(clonedConfig)) {
    return clonedConfig.map(c => swapArtifactObjects(c));
  }
  if (_.isPlainObject(clonedConfig)) {
    return Object.fromEntries(
      Object.entries(clonedConfig).map(([k, v]) => [k, swapArtifactObjects(v)])
    );
  }
  return clonedConfig;
};
