import type {ListType, Node, OutputNode} from '@wandb/cg';
import {
  constFunction,
  constNumber,
  constString,
  isAssignableTo,
  listObjectType,
  maybe,
  opArray,
  opArtifactMembershipArtifactAliases,
  opArtifactMembershipArtifactVersion,
  opArtifactMemberships,
  opArtifactMembershipVersionIndex,
  opArtifactVersionCreatedAt,
  opArtifactVersionCreatedBy,
  opArtifactVersionCreatedByUser,
  opArtifactVersionMetadata,
  opArtifactVersionRunHistoryRow,
  opArtifactVersionSize,
  opArtifactVersionUsedBy,
  opCount,
  opLimit,
  opNoneCoalesce,
  opNumberToByteString,
  opPick,
  opRunLink,
  opSort,
  opUserLink,
  typedDictPropertyTypes,
  varNode,
} from '@wandb/cg';
import {useNodeWithServerType} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {QuickPanelWBReactTable} from '../../../components/Panel2/PanelWBReactTable';

const HistoryTableWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  .wb-react-table {
    border: none;
  }
`;
const inputType = 'artifact' as const;
export type ConfigType = {};

export const PanelArtifactHistory: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {
    onRowClick?: (index: number, node: Node) => void;
  }
> = props => {
  const tableNode = React.useMemo(() => {
    return opSort({
      arr: opArtifactMemberships({
        artifact: props.input,
      }),
      compFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opArray({
          0: opArtifactMembershipVersionIndex({
            artifactMembership: row,
          }),
        } as any);
      }),
      columnDirs: opArray({
        0: constString('desc'),
      } as any),
    });
  }, [props.input]) as OutputNode<ListType<'any'>>;

  // Adding a limit here so we don't look at all the runs for all the artifacts greedily
  // TODO: Use the table pagination to select the columns
  const viewableTableNode = opLimit({
    arr: tableNode,
    limit: constNumber(10),
  });

  const tableColumns = React.useMemo(() => {
    return artifactVersionTableColumns(tableNode);
  }, [tableNode]);

  const metadataTypeNode = useNodeWithServerType(
    opArtifactVersionMetadata({
      artifactVersion: opArtifactMembershipArtifactVersion({
        artifactMembership: viewableTableNode,
      }),
    })
  );

  const metadataKeys = React.useMemo(() => {
    if (metadataTypeNode.loading) {
      return [];
    } else {
      try {
        return Object.keys(
          typedDictPropertyTypes(listObjectType(metadataTypeNode.result.type))
        );
      } catch (e) {
        return [];
      }
    }
  }, [metadataTypeNode]);

  const tableColumnsWithMetadata = React.useMemo(() => {
    const newCols = metadataKeys
      .filter(key => !key.startsWith('_'))
      .map(key => {
        return {
          name: `m.${key}`,
          selectFn: opPick({
            obj: opArtifactVersionMetadata({
              artifactVersion: opArtifactMembershipArtifactVersion({
                artifactMembership: varNode('artifactMembership', 'row'),
              }),
            }),
            key: constString(key),
          }),
        };
      });
    return [...tableColumns, ...newCols];
  }, [tableColumns, metadataKeys]);

  const historyMetricsNode = React.useMemo(() => {
    return opArtifactVersionRunHistoryRow({
      artifactVersion: opArtifactMembershipArtifactVersion({
        artifactMembership: viewableTableNode,
      }),
    });
  }, [viewableTableNode]);

  const historyMetricsNodeTyped = useNodeWithServerType(historyMetricsNode);

  const historyMetricsKeys = React.useMemo(() => {
    if (historyMetricsNodeTyped.loading) {
      return [];
    }
    const historyKeyTypes = typedDictPropertyTypes(
      listObjectType(historyMetricsNodeTyped.result.type)
    );

    return Object.keys(
      _.pickBy(
        historyKeyTypes,
        (val, key) =>
          !key.startsWith('_') && isAssignableTo(val, maybe('number'))
      )
    );
  }, [historyMetricsNodeTyped]);

  const tableColumnsWithHistory = React.useMemo(() => {
    const newCols = historyMetricsKeys.map(key => {
      return {
        name: `h.${key}`,
        selectFn: opPick({
          obj: opArtifactVersionRunHistoryRow({
            artifactVersion: opArtifactMembershipArtifactVersion({
              artifactMembership: varNode('artifactMembership', 'row'),
            }),
          }),
          key: constString(key),
        }),
      };
    });
    return [...tableColumnsWithMetadata, ...newCols];
  }, [tableColumnsWithMetadata, historyMetricsKeys]);

  return (
    <HistoryTableWrapper>
      <QuickPanelWBReactTable
        input={tableNode}
        columns={tableColumnsWithHistory}
        onRowClick={props.onRowClick}
      />
    </HistoryTableWrapper>
  );
};

function artifactVersionTableColumns(tableNode: OutputNode<ListType<'any'>>) {
  const rowType = listObjectType(tableNode.type);
  const artifactVersionNode = opArtifactMembershipArtifactVersion({
    artifactMembership: varNode(rowType, 'row'),
  });
  return [
    {
      name: 'Version',
      selectFn: opArtifactMembershipVersionIndex({
        artifactMembership: varNode(rowType, 'row'),
      }),
    },
    {
      name: 'Aliases',
      selectFn: opArtifactMembershipArtifactAliases({
        artifactMembership: varNode(rowType, 'row'),
      }),
    },
    // TODO: This is a good example of a missing part of Weave.
    // We can't realistically make an op that returns a union of user
    // and run, and then make meaningful ops downstream. We need to
    // switch on the type somehow.
    {
      name: 'Logged By',
      selectFn: opNoneCoalesce({
        lhs: opRunLink({
          run: opArtifactVersionCreatedBy({
            artifactVersion: artifactVersionNode,
          }),
        }),
        rhs: opUserLink({
          user: opArtifactVersionCreatedByUser({
            artifactVersion: artifactVersionNode,
          }),
        }),
      }),
    },
    {
      name: 'Created',
      selectFn: opArtifactVersionCreatedAt({
        artifactVersion: artifactVersionNode,
      }),
      panelDef: {panelID: 'date', panelConfig: {format: 'MMMM Do, YYYY'}},
    },
    {
      name: '# of Consuming Runs',
      selectFn: opCount({
        arr: opArtifactVersionUsedBy({
          artifactVersion: artifactVersionNode,
        }),
      }),
    },
    {
      name: 'Size',
      selectFn: opNumberToByteString({
        in: opArtifactVersionSize({
          artifactVersion: artifactVersionNode,
        }),
      }),
    },
  ];
}
