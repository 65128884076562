// TODO: When we add some more oauth integrations refactor
//       the auth bits out of SlackIntegration, and GitHubIntegration

import config from '@wandb/common/config';
import {randID} from '@wandb/common/util/data';
import {captureError} from '@wandb/common/util/integrations';
import * as querystring from 'querystring';
import React, {useCallback, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Header, Modal} from 'semantic-ui-react';

import {
  DeleteIntegrationComponent,
  GitHubOAuthIntegration,
  Integration,
} from '../generated/graphql';
import {
  createGitHubIntegrationMutation,
  CreateGitHubIntegrationMutationFn,
} from '../graphql/integrations';
import {Entity} from '../types/graphql';

const GITHUB_CODE = 'github';
const STATE_STORE_KEY = '__github_oauth_state';

// We store the request type in the oauth state variable
// This way, when the page is loaded with the query params we
// can make the correct integration consume the tokens
const isGitHubOAuth = (state: string | string[] | undefined) => {
  if (typeof state === 'string') {
    const codes = state.split(':');
    return (codes && codes[0]) === GITHUB_CODE;
  } else {
    return false;
  }
};

export interface GitHubIntegrationProps {
  entity: Entity;
  createGitHubIntegration: CreateGitHubIntegrationMutationFn; // from withMutations()
  entityRefetch: any;
}

export interface GitHubState {
  disconnectGitHubModalOpen: boolean;
  githubIntegrationInProgress: boolean;
}

function isGitHubIntegration(
  integration: Integration
): integration is GitHubOAuthIntegration {
  return (integration as any).__typename === 'GitHubOAuthIntegration';
}

export const getGitHubIntegration = (
  entity: Entity
): GitHubOAuthIntegration | undefined => {
  for (const integrationEdge of entity.integrations.edges) {
    if (isGitHubIntegration(integrationEdge.node)) {
      return integrationEdge.node;
    }
  }

  return undefined;
};

const GitHubIntegration: React.FC<GitHubIntegrationProps> = React.memo(
  ({entity, createGitHubIntegration, entityRefetch}) => {
    const history = useHistory();
    const [disconnectGitHubModalOpen, setDisconnectGitHubModalOpen] =
      useState(false);
    const [githubIntegrationInProgress, setGithubIntegrationInProgress] =
      useState(false);
    const redirectUriRef = useRef(
      window.location.origin + window.location.pathname
    );

    const startGitHubOAuth = useCallback(() => {
      const oAuthState = `${GITHUB_CODE}:wandb:` + randID(20);
      localStorage.setItem(STATE_STORE_KEY, oAuthState);

      // eslint-disable-next-line wandb/no-unprefixed-urls
      window.open(
        `https://github.com/login/oauth/authorize?${querystring.stringify({
          scope: 'user,public_repo',
          client_id: config.GITHUB_CLIENT_ID,
          state: oAuthState,
          redirect_uri: redirectUriRef.current,
        })}`,
        '_self'
      );
    }, []);

    const openDisconnectGitHubModal = useCallback(
      () => setDisconnectGitHubModalOpen(true),
      []
    );

    const closeDisconnectGitHubModal = useCallback(
      () => setDisconnectGitHubModalOpen(false),
      []
    );

    const handleGitHubIntegration = useCallback((): void => {
      const location = history.location;

      let params = history.location.search;

      // Extract query params
      if (location.search.startsWith('?')) {
        params = location.search.substring(1);
      }

      const queryParams = querystring.parse(params);
      const code = queryParams.code;
      const serverOAuthState = queryParams.state;

      // Only act for relevant responses, determined by a code we put in the state.
      if (!isGitHubOAuth(serverOAuthState)) {
        return;
      }

      // Remove query params from url
      if (history.location.search !== '') {
        history.replace({search: ''});
      }

      // Missing code means the user is not going through the integration flow.
      if (code === undefined || typeof code !== 'string') {
        return;
      }

      // Quit if in progress
      if (githubIntegrationInProgress) {
        return;
      }

      // Check that state matches across request to prevent xsrf
      const storedOAuthState = localStorage.getItem(STATE_STORE_KEY);
      // Reset local state
      localStorage.removeItem(STATE_STORE_KEY);

      if (storedOAuthState === undefined) {
        captureError('No stored state ', 'githubintegration1', {
          extra: {oAuthState: serverOAuthState, storedOAuthState},
        });
        return;
      }

      // Check auth state to prevent csrf
      if (serverOAuthState !== storedOAuthState) {
        // Scope the response to the specific integration so we don't hoist responses
        captureError(
          "Oauth state doesn't match local value",
          'githubintergration2',
          {
            extra: {oAuthState: serverOAuthState, storedOAuthState},
          }
        );
      }

      setGithubIntegrationInProgress(true);

      createGitHubIntegration({
        state: storedOAuthState || '',
        code,
        redirectURI: redirectUriRef.current,
        entityName: entity.name,
      })
        .then(entityRefetch)
        .then(() => {
          setGithubIntegrationInProgress(false);
        });
    }, [
      createGitHubIntegration,
      entity.name,
      entityRefetch,
      githubIntegrationInProgress,
      history,
    ]);

    const githubIntegrationExists = getGitHubIntegration(entity) !== undefined;

    if (!githubIntegrationExists) {
      handleGitHubIntegration();
    }

    return (
      <div className="alerts">
        <DeleteIntegrationComponent onCompleted={entityRefetch}>
          {(deleteIntegrationMutation: any) => {
            if (githubIntegrationExists) {
              return (
                <Modal
                  className="alerts--disconnect-modal"
                  open={disconnectGitHubModalOpen}
                  onClose={closeDisconnectGitHubModal}
                  trigger={
                    <Button
                      className="alerts--disconnect-modal-button"
                      size="tiny"
                      onClick={openDisconnectGitHubModal}>
                      Disconnect GitHub
                    </Button>
                  }>
                  <Header content="Are you sure you want to disconnect GitHub?" />
                  <Modal.Content>
                    <p>
                      We will no longer be able to submit pull requests on your
                      behalf
                    </p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic onClick={closeDisconnectGitHubModal}>
                      Nevermind
                    </Button>
                    <Button
                      negative
                      onClick={() => {
                        deleteIntegrationMutation({
                          variables: {
                            id: getGitHubIntegration(entity)!.id,
                          },
                        });

                        closeDisconnectGitHubModal();
                      }}>
                      Disconnect
                    </Button>
                  </Modal.Actions>
                </Modal>
              );
            } else {
              return (
                <Button
                  className="alerts--connect-button"
                  positive
                  onClick={startGitHubOAuth}>
                  Connect GitHub
                </Button>
              );
            }
          }}
        </DeleteIntegrationComponent>
      </div>
    );
  }
);

const withMutations = createGitHubIntegrationMutation as any;

export default withMutations(GitHubIntegration);
