import * as _ from 'lodash';

import {Line, Point} from './types';

export const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];

export function isTimeKey(k: string) {
  return _.includes(TIME_KEYS, k);
}

export type Timestep = 'seconds' | 'minutes' | 'hours' | 'days';

export function appropriateTimestep(lines: Line[]) {
  /**
   * Returns "seconds", "minutes", "hours", "days" depending on the
   * max values of lines.  Only appropriate if the x axis is relative time.
   */

  if (!lines || lines.length === 0) {
    return 'seconds' as Timestep;
  }
  let maxTime = 0;
  lines.forEach(l => {
    const last = _.last(l.data);
    if (last && last.x > maxTime) {
      maxTime = last.x;
    }
  });
  if (maxTime < 60 * 10) {
    return 'seconds' as Timestep;
  } else if (maxTime < 60 * 60 * 10) {
    return 'minutes' as Timestep;
  } else if (maxTime < 60 * 60 * 24 * 100) {
    return 'hours' as Timestep;
  } else {
    return 'days' as Timestep;
  }
}

function timestepToFactor(timestep: Timestep) {
  if (timestep === 'seconds') {
    return 1.0;
  } else if (timestep === 'minutes') {
    return 60.0;
  } else if (timestep === 'hours') {
    return 60.0 * 60;
  } else if (timestep === 'days') {
    return 60.0 * 60 * 24;
  }
  return 1.0;
}

export function convertTimestepToSeconds(time: number, timestep: Timestep) {
  const factor = timestepToFactor(timestep);
  return time * factor;
}

export function convertSecondsToTimestep(lines: Line[], timestep?: Timestep) {
  /**
   * Converts all ther xAxis values to minutes hours or days by dividing by "factor"
   */
  if (timestep == null) {
    timestep = appropriateTimestep(lines);
  }
  const factor = timestepToFactor(timestep);
  lines.forEach(l => {
    (l.data as Point[]).forEach(p => {
      p.x = p.x / factor;
    });
    l.timestep = timestep;
  });
}

export function convertTimestampLinesToMiliseconds(lines: Line[]) {
  lines.forEach(line => {
    line.data.forEach(points => {
      points.x = points.x * 1000;
    });
  });
}
