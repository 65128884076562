import React from 'react';

import {flashFocusCircle} from '../../util/flash';
import {EmptyVisualizations} from './EmptyVisualizations';

const EmptyPanelBankSectionWatermark = () => {
  return (
    <EmptyVisualizations
      headerText="No visualizations in this section."
      helpText={
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 4}}>
            <span className="hide-in-report">Drag a panel here, or </span>
            <span
              className="add-vis-helper underline-dashed"
              onClick={(e: React.MouseEvent) => {
                const el = (
                  e.currentTarget.closest('.panel-bank__section') ||
                  e.currentTarget.closest('.report-section')
                )?.querySelector('.add-vis-button');
                if (el) {
                  flashFocusCircle(el, {
                    minSpaceFromTop: 60,
                    padding: 3,
                    popping: true,
                  });
                }
              }}>
              add a visualization
            </span>
            .
          </div>
        </div>
      }
    />
  );
};

export default EmptyPanelBankSectionWatermark;
