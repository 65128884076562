import makeComp from '@wandb/common/util/profiler';
import React, {FC} from 'react';

import Alerts from '../Alerts';
import * as S from '../Alerts.styles';
import SlackIntegration from '../SlackIntegration';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsAlerts: FC = makeComp(
  () => {
    const {viewer, refetch} = useUserSettingsContext();
    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }

    return (
      <>
        <p>
          Get notified when your runs crash, finish, or set custom alerts
          with&nbsp;
          <S.TargetBlankLink href="https://docs.wandb.ai/guides/track/alert">
            wandb.alert()
          </S.TargetBlankLink>
          .
        </p>
        <Alerts
          entity={viewer.userEntity}
          entityRefetch={refetch}
          subscriptionTypes={['EmailSubscription', 'SlackChannelSubscription']}
        />
        <SlackIntegration
          entity={viewer.userEntity}
          entityRefetch={refetch}
          integrationReason={
            'Configure a Slack integration so we can send you alerts.'
          }
        />
      </>
    );
  },
  {id: 'UserSettingsAlerts', memo: true}
);
