import React, {useCallback} from 'react';

import {EntitySettings, useUpdateEntityMutation} from '../../generated/graphql';
import {FeatureToggle} from './FeatureToggle';

type EntitySettingFeatureToggleProps = {
  entityName: string;
  featureKey: keyof EntitySettings;
  isEnabled: boolean;
  refetchEntitySettings: () => Promise<void>;
  name: string;
  description: string;
  docUrl?: string;
};

export const EntitySettingFeatureToggle: React.FC<EntitySettingFeatureToggleProps> =
  React.memo(
    ({
      entityName,
      featureKey,
      isEnabled,
      refetchEntitySettings,
      ...passProps
    }) => {
      const [updateEntity] = useUpdateEntityMutation();

      const toggleFeature = useCallback(async () => {
        await updateEntity({
          variables: {
            entityName,
            settings: {
              [featureKey]: !isEnabled,
            },
          },
        });
        await refetchEntitySettings();
      }, [
        updateEntity,
        refetchEntitySettings,
        entityName,
        featureKey,
        isEnabled,
      ]);

      return <FeatureToggle {...passProps} {...{isEnabled, toggleFeature}} />;
    }
  );
