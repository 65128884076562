import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import {isEqual as _isEqual} from 'lodash';
import React, {
  memo,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import {ValueOp} from '../../util/filters';
import {rowIsRun} from '../../util/runhelpers';
import {getValue, Key as RunKey, Value as RunValue} from '../../util/runs';
import {WBTableColumn, WBTableRowInterface} from './WBTable';

interface WBTableCellProps {
  className: string;
  style?: React.CSSProperties;
  column: WBTableColumn;
  row: WBTableRowInterface;
  loadingChildren: boolean;
  recursionDepth: number;
  cellHoverProps: {
    onMouseEnter(): void;
    onMouseLeave(): void;
  };
  isHoveringRow: boolean;
  isGroup: boolean;

  // Props below are used to trigger rerenders
  isGroupExpanded: boolean;
  isSingleMode?: boolean;
  showArtifactCounts?: boolean;
  showLogCounts?: boolean;

  expanded?: boolean;
  addFilter(key: RunKey, op: ValueOp, value: RunValue): void;
  toggleExpandedRow(rowAddress: string): void;
}

const WBTableCellComp: React.FC<WBTableCellProps> = ({
  className,
  style,
  column,
  row,
  loadingChildren,
  recursionDepth,
  cellHoverProps,
  isHoveringRow,
  isGroup,
  isGroupExpanded,
  addFilter,
  toggleExpandedRow,
}) => {
  const [changeAnimating, setChangeAnimating] = useState<boolean>(false);
  const ref: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current == null) {
      return;
    }
    ref.current.addEventListener('animationend', () => {
      setChangeAnimating(false);
    });
  }, []);

  return (
    <div
      ref={ref}
      style={style}
      className={
        className + (changeAnimating ? ' highlight-change-animation' : '')
      }
      // TODO: debounce this if necessary for performance
      onMouseEnter={cellHoverProps.onMouseEnter}
      onMouseLeave={cellHoverProps.onMouseLeave}>
      {column.renderCell
        ? column.renderCell(column, row, {
            addFilter,
            toggleExpandedRow,
            recursionDepth,
            isGroup,
            isGroupExpanded,
            loadingChildren,
            isHoveringRow,
          })
        : getValue(row as any, column.key)}
    </div>
  );
};

export const WBTableCell = memo(WBTableCellComp, (prevProps, nextProps) => {
  if (
    rowIsRun(nextProps.row) &&
    rowIsRun(prevProps.row) &&
    !_isEqual(nextProps.row.state, prevProps.row.state)
  ) {
    return false;
  }
  const valChanged = !_isEqual(
    // Completely boneheaded:
    getValue(nextProps.row as any, nextProps.column.key),
    getValue(prevProps.row as any, prevProps.column.key)
  );
  if (valChanged) {
    return false;
  }
  const propsAreEqual = makePropsAreEqual({
    name: 'WBTableCell',
    deep: ['columnKey', 'column'],
    ignore: ['row', 'cellHoverProps'],
    ignoreFunctions: true,
    debug: false,
    verbose: true,
  });
  return propsAreEqual(prevProps, nextProps);
});
