import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {Button, Dropdown, Input, Popup} from 'semantic-ui-react';
import styled from 'styled-components';

import ActiveRunIndicator from './ActiveRunIndicator';

const textColor = globals.white;

export const IconContainer = styled.div<{
  location: 'search nav' | 'runs table' | 'single run page';
}>(props =>
  props.location !== 'runs table'
    ? `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(${globals.spu} / 2);
  `
    : `
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  }
`
);

export const Content = styled.div`
  padding: 12px;
  @media (max-width: 710px) {
    max-width: 100% !important;
  }
`;

export const Header = styled.h2`
  font-size: 20px;
  margin-bottom: calc(${globals.spu} * 2);
  color: ${textColor};
`;

export const Row = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: ${props => (props.isMobile ? 'flex-start' : 'center')};
  justify-content: space-between;
  border-bottom: 1px solid ${globals.gray700};
`;

export const RowSimple = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${globals.spu};
  flex-direction: column;
  @media (max-width: 710px) {
    max-width: 100% !important;
  }
`;

export const Div = styled.div`
  margin-top: ${globals.spu};
  @media (max-width: 710px) {
    max-width: 100% !important;
  }
`;

export const CustomAlertDesc = styled.div`
  margin-top: calc(${globals.spu} * 2);
`;

export const CustomAlertCodeWrapper = styled.div`
  .code__block {
    padding: 0;
  }
  .copyable__item {
    padding: calc(${globals.spu} * 2) 0 calc(${globals.spu} * 2) ${globals.spu};
  }
`;

export const SubscriptionStatusLabel = styled.div`
  font-size: 14px;
  color: ${globals.gray400};
  font-weight: 600;
`;

export const th = styled.div<{wide?: boolean}>`
  font-size: 14px;
  color: ${globals.gray300};
  width: ${props => (props.wide ? 50 : 25)}%;
  font-weight: 600;
`;

export const td = styled.div<{wide?: boolean; isMobile?: boolean}>`
  font-size: ${globals.fontSizeStandard};
  color: ${textColor};
  margin: calc(${globals.spu} * 2) 0px;
  width: ${props => (props.isMobile ? 100 : props.wide ? 50 : 25)}%;
  display: flex;
  align-items: center;
  @media (max-width: 710px) {
    margin: 0 0 calc(${globals.spu} * 2);
    &:first-child {
      margin-top: calc(${globals.spu} * 2);
    }
  }
`;

export const Icon = styled(WBIcon)<{$isInNav?: boolean}>`
  color: ${props => (props.$isInNav ? globals.gray500 : globals.successText)};
  cursor: pointer;
  font-size: 24px;
  margin-right: ${props => (props.$isInNav ? '19px' : '0')};
  &:hover {
    color: ${props => (props.$isInNav ? globals.white : globals.successDark)};
  }
  > * {
    color: ${props => props.color || globals.successText};
  }
  :hover {
    cursor: pointer;
  }
`;

export const ActiveRunDot = styled(ActiveRunIndicator)`
  :hover {
    cursor: pointer;
  }
`;

export const StyledPopup = styled(Popup)`
  &&& {
    background-color: ${globals.gray800};
    min-width: 700px;
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%),
      0 2px 10px 0 rgb(34 36 38 / 15%);
    @media (max-width: 710px) {
      min-width: 97% !important;
    }
    margin: 0;
    margin-top: -${globals.largeSpu};
  }
`;

export const TextInput = styled(Input)`
  &&& {
    > input {
      background-color: ${globals.gray800};
      color: ${globals.gray50};
      border: 1px solid ${globals.gray600};
      border-radius: 4px;
      width: 50px;
      height: 32px;
      margin-left: ${globals.spu};
    }
  }
`;

export const DropdownInput = styled(Dropdown)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${globals.gray100alpha38};
    color: ${globals.white};
    border-radius: 4px;
    font-weight: 600;
    height: 32px;
    .dropdown.icon {
      font-size: 12px;
      margin-left: ${globals.spu};
    }
  }
`;

export const BlueButton = styled(Button)`
  &&& {
    background-color: ${globals.primary};
    transition: all 0.2s ease;
    &:hover {
      background-color: ${globals.primaryDark};
    }
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LinkDiv = styled.div`
  margin-right: ${globals.fontSizeStandard};
  color: ${globals.gray400};
  > a {
    color: ${globals.gray500};
  }
`;

export const MobileAlertTypeLabel = styled.div`
  min-width: 55px;
`;

export const AlertLabel = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
`;
