import React, {FC, createElement} from 'react';
import {
  toast as toastify,
  ToastContainer as ToastifyContainer,
  ToastOptions,
  ToastContent,
} from 'react-toastify';
import {LegacyWBIcon} from './LegacyWBIcon';

interface CloseButtonProps {
  closeToast: any;
}

export const ToastContainer: FC = React.memo(() => {
  return (
    <ToastifyContainer
      position={toastify.POSITION.TOP_RIGHT}
      hideProgressBar={true}
      className="toast-container"
      toastClassName="toast"
      closeButton={createElement(({closeToast}: CloseButtonProps) => (
        <LegacyWBIcon
          name="close"
          onClick={closeToast}
          className="close-toast"
        />
      ))}
    />
  );
});

export function toast(text: ToastContent, options?: ToastOptions): void {
  toastify(text, options);
}
