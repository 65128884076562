import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {toast} from '@wandb/common/components/elements/Toast';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Popup} from 'semantic-ui-react';

import {useRunsData} from '../../state/runs/hooks';
import {toRunsDataQuery} from '../../state/runs/lib';
import * as Query from '../../util/queryts';
import * as RunFeed from '../../util/runfeed';
import {EMPTY_CONFIG} from '../../util/runfeed';
import * as TableCols from '../../util/tablecols';
import {InstrumentedLoader as Loader} from '../utility/InstrumentedLoader';
import {WBTableColumn} from '../WBTable/WBTable';

interface RunsAutoColsBaseProps {
  isInReport?: boolean;
  onClick(): void;
}

const RunsAutoColsBaseButton = (props: RunsAutoColsBaseProps) => {
  const {onClick, isInReport, ...passThrough} = props;

  return (
    <Button
      size="tiny"
      className="wb-icon-button only-icon"
      onClick={() => {
        onClick();
        const actionLocation = isInReport ? 'report' : 'runs table';
        window.analytics?.track('Autohide columns button clicked', {
          location: actionLocation,
        });
      }}
      {...passThrough}>
      <LegacyWBIcon name="wandb" />
    </Button>
  );
};

const RunsAutoColsLoadingButton = (props: RunsAutoColsProps) => {
  const {
    query,
    tableSettings,
    setTableSettings,
    currentPage,
    columns,
    ...passThrough
  } = props;

  const runsQuery = toRunsDataQuery(query, undefined, {
    fullConfig: true,
    fullSummary: true,
    configKeys: undefined,
    summaryKeys: undefined,
    wandbKeys: undefined,
    page: {size: 500},
  });
  const {loading, data} = useRunsData(runsQuery);
  const once = useRef(false);

  useEffect(() => {
    if (!loading && tableSettings != null && !once.current) {
      once.current = true;
      const pageSize = tableSettings.pageSize ?? EMPTY_CONFIG.pageSize;
      const i = pageSize * (currentPage - 1);
      const rows = data.filtered.slice(i, i + pageSize);
      setTableSettings(
        TableCols.deriveAutoColumns(tableSettings, rows, columns)
      );
    }
  }, [loading, data, setTableSettings, tableSettings, currentPage, columns]);

  return (
    <Button size="tiny" {...passThrough}>
      <Loader
        size="tiny"
        active
        inline
        name="run-auto-cols-loading-button-loader"
      />
    </Button>
  );
};

interface RunsAutoColsProps {
  query: Query.Query;
  tableSettings?: RunFeed.Config;
  isInReport?: boolean;
  currentPage: number;
  columns: WBTableColumn[];

  setTableSettings(config: RunFeed.Config): void;
}

export const RunsAutoCols = (props: RunsAutoColsProps) => {
  const {
    query,
    tableSettings,
    isInReport,
    setTableSettings,
    currentPage,
    columns,
  } = props;
  const [loading, setLoading] = useState(false);

  const setTableSettingsWrapped = useCallback(
    (config: RunFeed.Config) => {
      setTableSettings(config);
      setLoading(false);
      toast('Now showing recommended columns.');
    },
    [setTableSettings, setLoading]
  );

  const startLoad = useCallback(() => setLoading(true), [setLoading]);

  return (
    <Popup
      content={'Automatically show the most useful columns.'}
      position="top right"
      style={{marginRight: -6, top: 4}}
      popperModifiers={{
        preventOverflow: {
          // prevent popper from erroneously constraining the popup to the
          // table header
          boundariesElement: 'viewport',
        },
      }}
      trigger={
        loading ? (
          <RunsAutoColsLoadingButton
            query={query}
            tableSettings={tableSettings}
            setTableSettings={setTableSettingsWrapped}
            currentPage={currentPage}
            columns={columns}
          />
        ) : (
          <RunsAutoColsBaseButton onClick={startLoad} isInReport={isInReport} />
        )
      }
    />
  );
};
