import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {
  useEach,
  useNodeValue,
  useRefreshAllNodes,
} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {Panel2Loader} from '@wandb/weave-ui/components/Panel2/PanelComp';
import {debounce, isEqual, omit} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import TimeAgo from 'react-timeago';
import {Button, Input} from 'semantic-ui-react';

import {useUpdateArtifactPortfolioMutation} from '../../../generated/graphql';
import * as S from '../../../pages/EntityRegistryPage.styles';
import {capitalizeFirstLetter} from '../../../util/string';
import {PORTFOLIO_COLLECTION_DISPLAY_NAME} from '../../artifactConstants';
import {
  DeleteArtifactModal,
  RenameArtifactModal,
} from '../../ArtifactSidebarActions';
import {ArtifactHeaderPopupDropdown} from '../../ArtifactSidebarSequence';
import {WeaveErrorBoundary} from '../../Panel2/ErrorBoundary';
import PanelArtifact, {
  ConfigType as PanelArtifactConfigType,
} from './PanelArtifact/component';

const inputType = {type: 'list' as const, objectType: 'artifact' as const};
export type ConfigType = {
  collectionPath?: {
    entityName: string;
    projectName: string;
    artifactTypeName: string;
    collectionName: string;
  };
  searchFilter?: string;
  selectedArtifactConfig?: PanelArtifactConfigType;
};
type PanelArtifactRegistryProps = {
  emptyState?: React.ReactNode;
  registryEntityName?: string;
  registryName: string;
  onCreateButtonClick: () => void;
};

export const PanelArtifactRegistry: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & PanelArtifactRegistryProps
> = props => {
  /*
  
    The input is a list of portfolios. 
    We also receive the config, a dict which holds the currently selected portfolio which we will render in the UI.
    The currently selected portfolio is defined by a full path: {entityName}, {projectName}, {collectionName}
    and this path is determined from the URL.
    A properly formed registry URL ends with /registry/model?selectionPath={entityName}%2F{projectName}%2F{collectionName}.
  
   */
  const {input, config} = props;

  const artifactCountValue = useNodeValue(Op.opCount({arr: input}));
  const firstArtifactFullPathValue = useNodeValue(
    React.useMemo(() => {
      if (artifactCountValue.loading || artifactCountValue.result === 0) {
        return Op.constNone() as GraphTypes.Node;
      }
      return artifactNodeToPathDictNode(
        Op.opIndex({arr: input, index: Op.constNumber(0)}) as any
      );
    }, [artifactCountValue.loading, artifactCountValue.result, input])
  );
  const entityName = React.useMemo(
    () => config?.collectionPath?.entityName,
    [config?.collectionPath]
  );
  const projectName = React.useMemo(
    () => config?.collectionPath?.projectName,
    [config?.collectionPath]
  );
  const collectionName = React.useMemo(
    () => config?.collectionPath?.collectionName,
    [config?.collectionPath]
  );
  const currentArtifact = useNodeValue(
    React.useMemo(() => {
      if (entityName == null || projectName == null || collectionName == null) {
        return Op.constNone();
      }
      return Op.opProjectArtifact({
        project: Op.opRootProject({
          entityName: Op.constString(entityName),
          projectName: Op.constString(projectName),
        }),
        artifactName: Op.constString(collectionName),
      });
    }, [collectionName, entityName, projectName])
  );

  // This handles the URLs: /registry/model and /registry/model/?selectionPath={invalid path}
  React.useEffect(() => {
    if (currentArtifact.loading || firstArtifactFullPathValue.loading) {
      return;
    }
    const firstArtifactConfigCollectionPath = omit(
      firstArtifactFullPathValue.result,
      ['collectionID']
    ) as ConfigType['collectionPath'];
    if (
      currentArtifact.result == null &&
      firstArtifactFullPathValue.result != null &&
      !isEqual(config?.collectionPath, firstArtifactConfigCollectionPath)
    ) {
      props.updateConfig({
        collectionPath: firstArtifactConfigCollectionPath,
      });
    }
  }, [
    props,
    config?.collectionPath,
    currentArtifact.loading,
    firstArtifactFullPathValue.loading,
    currentArtifact.result,
    firstArtifactFullPathValue.result,
  ]);

  return <PanelArtifactRegistryInner {...props} />;
};

export const PanelArtifactRegistryInner: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & PanelArtifactRegistryProps
> = props => {
  const collectionNodes = useEach(props.input as any);
  const updateConfig = props.updateConfig;
  const onSearchInputChange = React.useMemo(() => {
    return debounce((e, {value}) => {
      updateConfig({searchFilter: value});
    });
  }, [updateConfig]);

  const artifactConfig = Panel2.useConfigChild(
    'selectedArtifactConfig',
    props.config,
    props.updateConfig
  );

  const selectedArtifactInput = React.useMemo(() => {
    if (
      props.config?.collectionPath == null ||
      (['entityName', 'projectName', 'collectionName'] as const).some(
        k => props.config?.collectionPath?.[k] == null
      )
    ) {
      return Op.constNone();
    }
    return Op.opProjectArtifact({
      project: Op.opRootProject({
        entityName: Op.constString(props.config.collectionPath.entityName),
        projectName: Op.constString(props.config.collectionPath.projectName),
      }),
      artifactName: Op.constString(props.config.collectionPath.collectionName),
    }) as GraphTypes.OutputNode<'artifact'>;
  }, [props.config?.collectionPath]);

  const [collapsed, setCollapsed] = React.useState(false);

  if (collectionNodes.loading) {
    return <Panel2Loader />;
  } else if (collectionNodes.result.length === 0) {
    return (
      <>
        <S.RegistryHeader>
          <Button
            data-test="create-collection"
            primary
            size="tiny"
            onClick={props.onCreateButtonClick}>
            Create {PORTFOLIO_COLLECTION_DISPLAY_NAME}{' '}
            {capitalizeFirstLetter(props.registryName)}
          </Button>
        </S.RegistryHeader>
        {props.emptyState}
      </>
    );
  }
  return (
    <S.RegistryContents>
      <S.MinimizedSidebar collapsed={collapsed}>
        <S.ChevronRight
          name={'chevron-right'}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        />
      </S.MinimizedSidebar>
      <S.CollectionListView collapsed={collapsed}>
        <S.RegistrySidebarHeader>
          <div>
            <S.RegistrySidebarEntityName>
              {props.registryEntityName == null
                ? ''
                : `${props.registryEntityName}`}
            </S.RegistrySidebarEntityName>
            &nbsp;&nbsp;
            <S.RegistrySidebarRegistryName>
              {capitalizeFirstLetter(props.registryName) + 's'}
            </S.RegistrySidebarRegistryName>
          </div>
          <S.ChevronLeft
            name={'chevron-left'}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        </S.RegistrySidebarHeader>
        <S.SearchButtonContainer>
          <S.CollectionListSearch>
            <Input
              placeholder={'Search'}
              style={{width: '100%'}}
              onChange={onSearchInputChange}
            />
          </S.CollectionListSearch>
          <Button
            style={{height: '32px'}}
            data-test="create-collection"
            primary
            size="tiny"
            onClick={props.onCreateButtonClick}>
            New {capitalizeFirstLetter(props.registryName)}
          </Button>
        </S.SearchButtonContainer>
        <S.CollectionList>
          {collectionNodes.result.map((collectionNode, index) => {
            return (
              <PanelCollectionListItem
                key={JSON.stringify(collectionNode)}
                index={index}
                input={collectionNode as any}
                config={props.config}
                updateConfig={props.updateConfig}
                context={props.context}
                updateContext={props.updateContext}
              />
            );
          })}
        </S.CollectionList>
      </S.CollectionListView>
      {props.config != null && props.config.collectionPath != null && (
        <S.CollectionDetailViewContainer>
          <S.CollectionDetailView>
            <WeaveErrorBoundary
              canReset={false}
              canUndo={false}
              errorConfig={artifactConfig.config}
              sourcePanel={'PanelArtifact'}
              onReset={() => {}}
              onUndo={() => {}}>
              <PanelArtifact
                {...Panel2.dummyProps}
                config={artifactConfig.config}
                updateConfig={artifactConfig.updateConfig}
                input={selectedArtifactInput as any}
                artifactRegistry={true}
              />
            </WeaveErrorBoundary>
          </S.CollectionDetailView>
        </S.CollectionDetailViewContainer>
      )}
    </S.RegistryContents>
  );
};

const artifactNodeToPathDictNode = (
  artifactNode: GraphTypes.Node<'artifact'>
) => {
  const projectNode = Op.opArtifactProject({
    artifact: artifactNode,
  });
  return Op.opDict({
    entityName: Op.opEntityName({
      entity: Op.opProjectEntity({project: projectNode}),
    }),
    projectName: Op.opProjectName({
      project: projectNode,
    }),
    collectionID: Op.opArtifactId({
      artifact: artifactNode,
    }),
    artifactTypeName: Op.opArtifactTypeName({
      artifactType: Op.opArtifactType({artifact: artifactNode}),
    }),
    collectionName: Op.opArtifactName({
      artifact: artifactNode,
    }),
  } as any);
};

const PanelCollectionListItemInputType = 'artifact' as const;

export const PanelCollectionListItem: React.FC<
  Panel2.PanelProps<typeof PanelCollectionListItemInputType, ConfigType> & {
    index: number | string;
  }
> = props => {
  const [updateArtifactPortfolio] = useUpdateArtifactPortfolioMutation();
  const [renameModalOpen, setRenameModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const refreshNodes = useRefreshAllNodes();
  const pathDictValue = useNodeValue(
    React.useMemo(() => {
      return artifactNodeToPathDictNode(props.input);
    }, [props.input])
  );

  const versionsValue = useNodeValue(
    React.useMemo(
      () =>
        Op.opArtifactVersionCreatedAt({
          artifactVersion: Op.opArtifactVersions({artifact: props.input}),
        }),
      [props.input]
    )
  );

  const membershipsCreatedAt = useNodeValue(
    React.useMemo(
      () =>
        Op.opArtifactMembershipCreatedAt({
          artifactMembership: Op.opArtifactMemberships({artifact: props.input}),
        }),
      [props.input]
    )
  );

  const isSelected = React.useMemo(() => {
    if (props.config?.collectionPath == null || pathDictValue.loading) {
      return false;
    } else {
      return (
        pathDictValue.result.entityName ===
          props.config?.collectionPath.entityName &&
        pathDictValue.result.projectName ===
          props.config?.collectionPath.projectName &&
        pathDictValue.result.collectionName ===
          props.config?.collectionPath.collectionName
      );
    }
  }, [
    props.config?.collectionPath,
    pathDictValue.loading,
    pathDictValue.result,
  ]);
  const updateConfig = props.updateConfig;
  const onClick = React.useCallback(() => {
    if (!pathDictValue.loading) {
      updateConfig({
        collectionPath: pathDictValue.result as any,
        selectedArtifactConfig: undefined,
      });
    }
  }, [pathDictValue.loading, pathDictValue.result, updateConfig]);
  const lastDate = React.useMemo(
    () =>
      !membershipsCreatedAt.loading
        ? moment([...membershipsCreatedAt.result].sort().reverse()[0] + 'Z')
        : null,
    [membershipsCreatedAt.loading, membershipsCreatedAt.result]
  );

  const shouldShow = React.useMemo(() => {
    return (
      isSelected ||
      props.config?.searchFilter == null ||
      props.config?.searchFilter === '' ||
      pathDictValue.loading ||
      pathDictValue.result.collectionName
        .toLowerCase()
        .includes(props.config?.searchFilter.toLowerCase()) ||
      pathDictValue.result.entityName
        .toLowerCase()
        .includes(props.config?.searchFilter.toLowerCase()) ||
      pathDictValue.result.projectName
        .toLowerCase()
        .includes(props.config?.searchFilter.toLowerCase())
    );
  }, [
    isSelected,
    pathDictValue.loading,
    pathDictValue.result,
    props.config?.searchFilter,
  ]);

  if (pathDictValue.loading || versionsValue.loading) {
    return <Panel2Loader />;
  }
  if (!shouldShow) {
    return null;
  }
  return (
    <S.CollectionListItem onClick={onClick} selected={isSelected}>
      <S.CollectionListItemContents selected={isSelected}>
        <S.CollectionListItemName>
          {pathDictValue.result.collectionName}
          <div className={'overview-option-ellipses'}>
            <ArtifactHeaderPopupDropdown
              onRenameArtifact={() => setRenameModalOpen(true)}
              onDeleteArtifact={() => setDeleteModalOpen(true)}
            />
          </div>
        </S.CollectionListItemName>
        <S.CollectionListItemProjectName>
          {pathDictValue.result.entityName}/{pathDictValue.result.projectName}
        </S.CollectionListItemProjectName>
        <S.CollectionListItemStats>
          <S.CollectionListItemVersions>
            {versionsValue.result.length} Versions
          </S.CollectionListItemVersions>
          <S.CollectionListItemUpdatedAt>
            {lastDate != null && <TimeAgo date={lastDate.toDate()} />}
          </S.CollectionListItemUpdatedAt>
        </S.CollectionListItemStats>
      </S.CollectionListItemContents>
      <RenameArtifactModal
        name={pathDictValue.result.collectionName}
        modalOpen={renameModalOpen}
        onRenameArtifact={async newName => {
          await updateArtifactPortfolio({
            variables: {
              artifactPortfolioID: pathDictValue.result.collectionID,
              name: newName,
            },
          });
          setRenameModalOpen(false);
          await refreshNodes();
          updateConfig({
            collectionPath: {
              artifactTypeName: pathDictValue.result.artifactTypeName,
              entityName: pathDictValue.result.entityName,
              projectName: pathDictValue.result.projectName,
              collectionName: newName,
            },
          });
        }}
        onClose={async () => {
          setRenameModalOpen(false);
        }}
      />
      <DeleteArtifactModal
        id={pathDictValue.result.collectionID}
        entityName={pathDictValue.result.entityName}
        projectName={pathDictValue.result.projectName}
        isPortfolio={true}
        isModalOpen={deleteModalOpen}
        refetchProjectArtifacts={async () => {
          await refreshNodes();
          updateConfig({collectionPath: undefined});
        }}
        setIsDeleteModalOpen={setDeleteModalOpen}
      />
    </S.CollectionListItem>
  );
};

export default PanelArtifactRegistry;
