import * as _ from 'lodash';

import * as CustomRunColorsNormalize from '../customRunColors/normalize';
import * as Normalize from '../normalize';
import * as PanelBankConfigNormalize from '../panelBankConfig/normalize';
import * as PanelBankSectionConfigNormalize from '../panelBankSectionConfig/normalize';
import * as PanelsNormalize from '../panels/normalize';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import * as RunSetNormalize from '../runSet/normalize';
import * as Types from './types';

const omit = _.omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = Normalize.normFn<Types.SectionObjSchema>(
  'section',
  (whole, ctx) => {
    return {
      ...omit(
        whole,
        'runSets',
        'panels',
        'panelBankConfig',
        'panelBankSectionConfig',
        'settings',
        'customRunColors'
      ),
      runSetRefs:
        whole.runSets != null
          ? whole.runSets.map(r => RunSetNormalize.normalize(r, ctx))
          : [],
      panelsRef:
        whole.panels != null
          ? PanelsNormalize.normalize(whole.panels, ctx)
          : undefined,
      panelBankConfigRef: PanelBankConfigNormalize.normalize(
        whole.panelBankConfig,
        ctx
      ),
      panelBankSectionConfigRef: PanelBankSectionConfigNormalize.normalize(
        whole.panelBankSectionConfig,
        ctx
      ),
      panelSettingsRef:
        whole.settings != null
          ? PanelSettingsNormalize.normalize(whole.settings, ctx)
          : undefined,
      customRunColorsRef: CustomRunColorsNormalize.normalize(
        whole.customRunColors || {},
        ctx
      ),
    };
  }
);

export const denormalize = Normalize.denormFn<Types.SectionObjSchema>(
  (part, ctx) => ({
    ...omit(
      part,
      'runSetRefs',
      'panelsRef',
      'panelBankConfigRef',
      'panelBankSectionConfigRef',
      'panelSettingsRef',
      'customRunColorsRef'
    ),
    runSets: part.runSetRefs.map(r => RunSetNormalize.denormalize(r, ctx)),
    panels:
      part.panelsRef != null
        ? PanelsNormalize.denormalize(part.panelsRef, ctx)
        : undefined,
    panelBankConfig: PanelBankConfigNormalize.denormalize(
      part.panelBankConfigRef,
      ctx
    ),
    panelBankSectionConfig: PanelBankSectionConfigNormalize.denormalize(
      part.panelBankSectionConfigRef,
      ctx
    ),
    settings:
      part.panelSettingsRef != null
        ? PanelSettingsNormalize.denormalize(part.panelSettingsRef, ctx)
        : undefined,
    customRunColors: CustomRunColorsNormalize.denormalize(
      part.customRunColorsRef,
      ctx
    ),
  })
);
