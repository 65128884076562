// PanelWeaveLink makes clickable links that update the root expression.
//
// The "to" config field is a Weave expression Node. Think of it like an
// f-string. Any variables present in the "to" expression will be evaluated,
// and then passed into the "to" expression as consts.
//
// For example, if to is model(input) where input is a variable that represents
// PanelLink's input node, PanelLink will evaluate the input node, and pass
// the result in place of the variable. So if input evaluates to 'x', when clicked
// the root expression will change to model('x').

import React from 'react';
import {useMemo} from 'react';
import * as Panel2 from './panel';
import * as Graph from '@wandb/cg';
import * as HL from '@wandb/cg';
import * as GraphTypes from '@wandb/cg';
import {useCallback} from 'react';
import styled from 'styled-components';
import * as CGReact from '../../cgreact';
import {ChildPanelFullConfig, ChildPanel} from './ChildPanel';
import {constNodeUnsafe} from '@wandb/cg';
import {usePanelContext} from './PanelContext';
import {Frame} from '@wandb/cg';
import {useWeaveContext} from '../../context';

interface WeaveLinkConfig {
  to: GraphTypes.NodeOrVoidNode;
  vars: Frame;
}

const PANEL_WEAVE_LINK_DEFAULT_CONFIG: WeaveLinkConfig = {
  to: Graph.voidNode(),
  vars: {},
};

// Don't show up in any suggestions for now.
const inputType = 'invalid';

type PanelLabeledItemProps = Panel2.PanelProps<
  typeof inputType,
  WeaveLinkConfig
>;

export const WeaveLink = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const PanelWeaveLink: React.FC<PanelLabeledItemProps> = props => {
  const config = props.config ?? PANEL_WEAVE_LINK_DEFAULT_CONFIG;
  const {input, updateInput} = props;
  const updateChildPanelConfig = useCallback(
    (newItemConfig: ChildPanelFullConfig) => {
      console.warn(
        'Attempt to update child panel config in PanelWeave link: not yet supported'
      );
    },
    []
  );
  const weave = useWeaveContext();
  const {frame: contextFrame} = usePanelContext();
  const frame = useMemo(
    () => ({
      ...contextFrame,
      ...config.vars,
      // This 'input' key is by convention, written in panel_weavelink.py
      // : weave_internal.make_var_node(self.input_node.type, "input")
      input,
    }),
    [input, contextFrame, config.vars]
  );
  const vars = useMemo(() => HL.expressionVariables(config.to), [config.to]);
  if (vars.length > 1) {
    // This will be pretty easy to fix, just need to evaluate all the variable nodes.
    throw new Error(
      'More than one var node found in WeaveLink "to" expression, not yet supported'
    );
  }
  const var0Node = (vars[0] ?? Graph.voidNode()) as
    | GraphTypes.VarNode
    | GraphTypes.VoidNode;
  const var0Expr =
    var0Node.nodeType === 'var'
      ? weave.callFunction(var0Node, frame)
      : Graph.voidNode();
  const var0Result = CGReact.useNodeValue(var0Expr);

  const linkTo = useMemo(() => {
    if (var0Node.nodeType === 'void' || var0Result.loading) {
      return Graph.voidNode();
    }
    // Pass the evaluated variable results back into the config.to expression
    // as const nodes.
    return weave.callFunction(config.to, {
      [var0Node.varName]:
        var0Result.result?.nodeType != null
          ? var0Result.result
          : constNodeUnsafe(var0Node.type, var0Result.result),
    });
  }, [config.to, var0Node, var0Result, weave]);

  const onClickLink = useCallback(() => {
    if (updateInput != null && linkTo.nodeType !== 'void') {
      updateInput(linkTo as any);
    }
  }, [updateInput, linkTo]);
  return (
    <WeaveLink
      onClick={onClickLink}
      title={
        linkTo.nodeType !== 'void' ? weave.expToString(linkTo) : undefined
      }>
      <ChildPanel config={input} updateConfig={updateChildPanelConfig} />
    </WeaveLink>
  );
};

export const Spec: Panel2.PanelSpec = {
  hidden: true,
  id: 'weavelink',
  Component: PanelWeaveLink,
  inputType,
};
