import gql from 'graphql-tag';
import {useLocation} from 'react-router';

import {getRouteMatch, isBillingPath, isUsagePath} from '../routes/utils';
import {useQuery} from '../state/graphql/query';

export const TEAM_ORG_QUERY = gql`
  query TeamOrg($entityName: String!) {
    entity(name: $entityName) {
      id
      organization {
        id
        name
      }
    }
  }
`;

export interface TeamOrgQueryResult {
  entity: {
    id: string;
    name: string;
    organization?: {
      id: string;
      name: string;
    };
  };
}

interface TeamOrgQueryVariables {
  entityName: string;
}

export function useCurrentTeamOrg(): TeamOrgQueryResult | null {
  const location = useLocation();
  const routeMatch = getRouteMatch(location.pathname);
  const params = routeMatch?.params as TeamOrgQueryVariables | undefined;
  const entityName = params?.entityName;

  const skipQuery = !entityName;

  const teamOrgQuery = useQuery<TeamOrgQueryResult, TeamOrgQueryVariables>(
    TEAM_ORG_QUERY,
    {
      variables: {
        entityName: entityName as string,
      },
      skip: skipQuery,
    }
  );

  // this check goes after teamOrgQuery to not call useQuery conditionally.
  if (skipQuery) {
    return null;
  }

  if (teamOrgQuery.loading) {
    return null;
  }

  return teamOrgQuery;
}

export function useShowNudgeBar(): boolean {
  const location = useLocation();
  const onUsagePage = isUsagePath(location.pathname);
  const onBillingPage = isBillingPath(location.pathname);

  if (onUsagePage || onBillingPage) {
    return false;
  }

  return true;
}
