import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import * as globals from '@wandb/common/css/globals.styles';
import {useEffectExceptFirstRender} from '@wandb/common/util/hooks';
import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {Link} from 'react-router-dom';
import {Icon, Label, Popup} from 'semantic-ui-react';
import styled from 'styled-components';

import * as CustomRunColorsActions from '../../state/views/customRunColors/actions';
import * as CustomRunColorsTypes from '../../state/views/customRunColors/types';
import * as GroupSelectionsActions from '../../state/views/groupSelections/actions';
import * as GroupSelectionsTypes from '../../state/views/groupSelections/types';
import * as ViewHooks from '../../state/views/hooks';
import * as InteractStateActions from '../../state/views/interactState/actions';
import * as InteractStateContext from '../../state/views/interactState/context';
import * as TempSelectionsActions from '../../state/views/tempSelections/actions';
import * as TempSelectionsTypes from '../../state/views/tempSelections/types';
import {BenchmarkProject} from '../../types/graphql';
import {runColor} from '../../util/colors';
import {runPath, WBTableRowWithRunSetInfo} from '../../util/runhelpers';
import * as Run from '../../util/runs';
import * as SM from '../../util/selectionmanager';
import {runGroup as runGroupURL} from '../../util/urls';
import {versionGte} from '../../util/version';
import AlertsManagementModal from '../AlertsManagementModal';
import ColorPicker from '../ColorPicker';
import DeleteRunModal from '../DeleteRunModal';
import {EditableLabel} from '../elements/EditableLabel';
import {EditLaunchConfigModalControlled} from '../Launch/EditLaunchConfigModal';
import {MultiStateCheckbox} from '../MultiStateCheckbox';
import StopRunModal from '../StopRunModal';
import SubmitToBenchmarkModal from '../SubmitToBenchmarkModal';
import {InstrumentedLoader} from '../utility/InstrumentedLoader';
import {RunPendingResultsPopup} from './RunPendingResultsPopup';
import {DisabledFeaturesMap} from './RunSelectorInner';

const StyledLoader = styled(InstrumentedLoader)`
  margin-left: -4px;
`;
const colorPickerStyle = {marginRight: '6px', marginBottom: '8px'};

export interface RunSelectorRowHeaderProps {
  entityName: string;
  projectName: string;
  className?: string;
  isGroup?: boolean; // false if row is individual run, true if it's a group of runs
  groupSelectionsRef: GroupSelectionsTypes.Ref;
  tempSelectionsRef: TempSelectionsTypes.Ref;
  recursionDepth: number;
  loadingChildren?: boolean;
  row: WBTableRowWithRunSetInfo;
  isGroupExpanded: boolean;
  isSingleMode?: boolean;
  selectedRunName?: string;
  onSelectRunName?: (runId: string) => void;
  benchmarkMeta?: BenchmarkProject;
  displayBenchmark?: boolean;
  customRunColorsRef: CustomRunColorsTypes.Ref;
  displayTempCheckbox: boolean;
  isTableCollapsed?: boolean;
  isFirstActiveRun?: boolean;
  isInReport?: boolean;

  // If true, users won't be able to edit filter, grouping, etc.
  readOnly?: boolean;
  // If true, users won't be able to rename runs, etc.
  noEdit?: boolean;

  showArtifactCounts?: boolean;
  showLogCounts?: boolean;
  disabledFeatures?: DisabledFeaturesMap;
  disableRunLink?: boolean;
  runQueuesEnabled: boolean;
  onTempCheckboxClick(): void;
  onVisibilityCheckboxClick(): void;
  onGroupButtonClick?(): void;
  saveRunName(run: Run.Run, newName: string): void;
  deleteRun(id: string, deleteArtifacts?: boolean): void;
  stopRun(id: string): Promise<any>; // from withMutations
}

type AllRunSelectorRowHeaderProps = Omit<
  RunSelectorRowHeaderProps,
  'deleteRun'
> &
  ReturnType<typeof useRunSelectorRowHeaderProps>;

const RunSelectorRowHeaderInnerComp: React.FC<AllRunSelectorRowHeaderProps> = ({
  entityName,
  projectName,
  className,
  isGroup,
  recursionDepth,
  loadingChildren,
  row,
  isGroupExpanded,
  benchmarkMeta,
  displayBenchmark,
  displayTempCheckbox,
  isTableCollapsed,
  readOnly,
  noEdit,
  disabledFeatures,
  disableRunLink,
  isSingleMode,
  onSelectRunName,
  onTempCheckboxClick,
  onVisibilityCheckboxClick,
  onGroupButtonClick,
  saveRunName,
  deleteRun,
  stopRun,
  editingName,
  groupedBy,
  showArtifactCounts,
  showLogCounts,
  toggleSelection,
  visCheckboxState,
  runIsInBounds,
  tempCheckboxState,
  isRunGroup,
  customRunColor,
  setCustomRunColor,
  deleteModalOpen,
  closeDeleteModal,
  stopModalOpen,
  closeStopModal,
  launchConfigModalOpen,
  openLaunchConfigModal,
  closeLaunchConfigModal,
  startEditingName,
  stopEditingName,
  openStopModal,
  openDeleteModal,
  isAnyCellSelected,
  tempSelectToggle,
  setHighlight,
  runQueuesEnabled,
  isFirstActiveRun,
  isInReport,
}) => {
  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [remountLabelHax, setRemountLabelHax] = useState(false);
  const [alertsModalViewed, setAlertsModalViewed] = useState(true);

  /*
   * Due to the way we style and structure the runs table, doing a
   * a purely CSS fix isn't currently possible. We'll have to eventually
   * overhaul our styling (and possibly structure?) for the runs rows.
   * For now, however, this hacky fix remounts the row name cell after a
   * row name is edited. Super long names will not overflow anymore.
   */
  useEffectExceptFirstRender(
    () => {
      if (!editingName) {
        setRemountLabelHax(true);
      }
    },
    [editingName],
    true
  );
  useEffectExceptFirstRender(
    () => {
      if (remountLabelHax) {
        setTimeout(() => setRemountLabelHax(false));
      }
    },
    [remountLabelHax],
    true
  );
  useLayoutEffect(() => {
    // IMPORTANT: this useEffect must be placed after the previous 2
    setAlertsModalViewed(localStorage.getItem('alertsModalViewed') === 'true');
  }, []);

  const openBenchmarkModal = useCallback(() => {
    setShowBenchmarkModal(true);
  }, []);

  const closeBenchmarkModal = useCallback(() => {
    setShowBenchmarkModal(false);
  }, []);

  const groupedText = _.isEqual(groupedBy, Run.GROUP_BY_ALL_KEY)
    ? Run.keyDisplayName(groupedBy)
    : `${groupedBy ? Run.keyDisplayName(groupedBy) + ': ' : ''}${
        row.displayName
      }`;

  const stopRunAvailable = useCallback(() => {
    // TODO(adrnswanberg): Centralize this with similar logic in RunPageActions.tsx,
    // probably around when we start using the same `Run` type everywhere.
    const cliVersion =
      row._wandb['value.cli_version'] ?? row._wandb.cli_version;
    if (cliVersion == null || typeof cliVersion !== 'string') {
      return false;
    }

    const isJupyterRun =
      row._wandb['value.is_jupyter_run'] ?? row._wandb.is_jupyter_run ?? false;

    return versionGte(cliVersion, '0.8.6') && !isJupyterRun;
  }, [row]);

  const groupCounts = row.groupCounts ?? [];

  let runCount;
  let groupCount;
  if (groupCounts.length >= 2) {
    groupCount = groupCounts[groupCounts.length - 2];
    runCount = groupCounts[groupCounts.length - 1];
  } else if (groupCounts.length === 1) {
    [runCount] = groupCounts;
  }

  // Show color picker whenever we're not grouped, or when we're at
  // the deepest grouping level.
  const shouldShowColorPicker = isGroup
    ? groupCount == null
    : recursionDepth === 0;

  return (
    <React.Fragment>
      {deleteModalOpen && (
        <DeleteRunModal
          entityName={entityName}
          projectName={projectName}
          runName={row.name}
          runDisplayName={row.displayName}
          isRunActive={row.state === 'running'}
          onModalCanceled={closeDeleteModal}
          onDeleteRun={deleteRun}
        />
      )}
      <StopRunModal
        shouldShowModal={stopModalOpen}
        onModalCanceled={closeStopModal}
        onStopRun={stopRun}
      />
      {launchConfigModalOpen && (
        <EditLaunchConfigModalControlled
          entityName={entityName}
          projectName={projectName}
          runName={row.name}
          runDisplayName={row.displayName}
          onClose={closeLaunchConfigModal}
        />
      )}
      <RunPendingResultsPopup
        isPendingUpdates={row.pendingUpdates ?? false}
        trigger={
          <div
            className={`
          wb-tree-cell--header-row
          wb-tree-cell--name
          ${className ? className : ''}
          ${loadingChildren ? 'wb-tree-cell--loading-children' : ''}
          `}
            // onClick={isRunGroup ? undefined : onClick}
            style={{paddingLeft: 16 + recursionDepth * 26}}>
            {!readOnly && !noEdit && !isGroup && (
              <RowHeaderPopupDropdown
                displayBenchmark={
                  !!displayBenchmark &&
                  row.state !== 'running' &&
                  !row.benchmarkRun
                }
                displayStop={
                  row.state === 'running' &&
                  (row.sweep != null || stopRunAvailable())
                }
                displayRunQueue={runQueuesEnabled}
                editName={startEditingName}
                openStopModal={openStopModal}
                openDeleteModal={openDeleteModal}
                openBenchmarkModal={openBenchmarkModal}
                openLaunchModal={openLaunchConfigModal}
              />
            )}

            {!readOnly &&
              !isTableCollapsed &&
              (displayTempCheckbox || isAnyCellSelected ? (
                <MultiStateCheckbox
                  data-test="select-run-checkbox"
                  className="row-index-or-checkbox"
                  checked={tempCheckboxState}
                  onClick={() => {
                    tempSelectToggle();
                    onTempCheckboxClick();
                    const actionLocation = isInReport ? 'report' : 'runs table';
                    window.analytics?.track('Run selected', {
                      location: actionLocation,
                    });
                  }}
                />
              ) : (
                <div className="row-index-or-checkbox row-index">•</div>
              ))}

            {!isSingleMode && !disabledFeatures?.['toggle-visibility'] && (
              <RowHeaderVisCheckbox
                row={row}
                recursionDepth={recursionDepth}
                runIsInBounds={runIsInBounds}
                visCheckboxState={visCheckboxState}
                toggleSelection={toggleSelection}
                onVisibilityCheckboxClick={onVisibilityCheckboxClick}
                isInReport={isInReport}
              />
            )}
            {shouldShowColorPicker && (
              <ColorPicker
                activeColor={customRunColor}
                style={colorPickerStyle}
                setRunColor={setCustomRunColor}
                onClick={() => {
                  const actionLocation = isInReport ? 'report' : 'runs table';
                  window.analytics?.track('Run color dropdown clicked', {
                    location: actionLocation,
                  });
                }}
              />
            )}
            {isGroup && (
              <React.Fragment>
                <StyledLoader
                  active
                  inline
                  name="is-group-loader"
                  size="mini"
                />
                <Icon
                  style={{marginLeft: -6}}
                  className={`triangle ${isGroupExpanded ? 'down' : 'right'}`}
                  onClick={onGroupButtonClick}
                />
              </React.Fragment>
            )}
            <div
              className={classNames({
                'run-row-name': true,
                failed: row.state === 'failed' && showLogCounts && !isGroup,
                'single-mode': isSingleMode && !isGroup,
              })}
              onMouseOver={() => {
                setHighlight('run:name', row.name);
              }}
              onMouseOut={() => {
                setHighlight('run:name', undefined);
              }}>
              {isRunGroup ? (
                <Link
                  to={runGroupURL({
                    entityName,
                    projectName,
                    name: row.name,
                    tab: 'workspace',
                  })}
                  title={groupedText}>
                  {groupedText}
                </Link>
              ) : isGroup ? (
                <span title={groupedText}>{groupedText}</span>
              ) : (
                !remountLabelHax && (
                  <EditableLabel
                    key={row.name}
                    title={row.displayName}
                    onBlur={(newName: string) => {
                      saveRunName(row, newName);
                      stopEditingName();
                    }}
                    linkTo={
                      disableRunLink || isSingleMode
                        ? undefined
                        : runPath({
                            entityName,
                            projectName,
                            runName: row.name,
                          })
                    }
                    editing={editingName}
                    serverText={row.displayName}
                    extraMarginRight={
                      row.state === 'running' &&
                      isFirstActiveRun &&
                      !alertsModalViewed
                        ? '28px'
                        : '12px'
                    }
                    onClick={() =>
                      isSingleMode ? onSelectRunName?.(row.name) : false
                    } // we use the menu option to trigger editing mode, rather than direct click
                  />
                )
              )}
            </div>
            {isGroup && (
              <React.Fragment>
                {!_.isUndefined(groupCount) && (
                  <Label className="count-label">{groupCount}</Label>
                )}
                {!_.isUndefined(runCount) && (
                  <Label className="count-label">{runCount}</Label>
                )}
              </React.Fragment>
            )}
            {!isGroup && showArtifactCounts && (
              <>
                {row.inputArtifactsCount !== 0 && (
                  <Label className="count-label">
                    In {row.inputArtifactsCount}
                  </Label>
                )}
                {row.outputArtifactsCount !== 0 && (
                  <Label className="count-label">
                    Out {row.outputArtifactsCount}
                  </Label>
                )}
              </>
            )}

            {!isGroup && showLogCounts && row.logLineCount != null && (
              <Label className="count-label">{row.logLineCount}</Label>
            )}

            {row.state === 'running' && (
              <AlertsManagementModal
                location="runs table"
                color={globals.successText}
                onClose={() => setAlertsModalViewed(true)}
                displayIcon={
                  isFirstActiveRun && !alertsModalViewed
                    ? 'bell'
                    : 'active run indicator'
                }
              />
            )}
            {row.benchmarkRun && benchmarkMeta && (
              <Popup
                hoverable={true}
                trigger={<Icon className="wbic-ic-benchmark" />}
                horizontalOffset={10}
                flowing={true}
                size="tiny"
                content={
                  <span>
                    Submitted to{' '}
                    <Link
                      to={
                        '/' +
                        benchmarkMeta.entityName +
                        '/' +
                        benchmarkMeta.name +
                        '/benchmark'
                      }>
                      {benchmarkMeta.name}
                    </Link>{' '}
                    benchmark
                  </span>
                }
              />
            )}
          </div>
        }
      />
      {benchmarkMeta && (
        <SubmitToBenchmarkModal
          runId={row.id}
          run={row}
          runName={row.displayName}
          runSummary={row.summary}
          shouldShowModal={showBenchmarkModal}
          closeModal={closeBenchmarkModal}
          benchmarkMeta={benchmarkMeta}
          project={{name: projectName, entityName}}
        />
      )}
    </React.Fragment>
  );
};

const RunSelectorRowHeaderInner = memo(
  RunSelectorRowHeaderInnerComp,
  (prevProps, nextProps) => {
    if (prevProps.row.state !== nextProps.row.state) {
      return false;
    }
    const propsAreEqual = makePropsAreEqual({
      name: 'RunSelectorRowHeader',
      deep: ['groupKey', 'tempCheckboxState', 'customRunColors'],
      // Can ignore, the row remounts when run id changes. This will screw up polling though
      // We should pass the value in rather than row probably, or maybe just check if our value
      // changes here in shouldUpdate
      ignore: ['row', 'cellHoverProps'],
      ignoreFunctions: true,
      debug: false,
      verbose: true,
    });
    return propsAreEqual(prevProps, nextProps);
  }
);

function useRunSelectorRowHeaderProps(props: RunSelectorRowHeaderProps) {
  const grouping = ViewHooks.useWholeMapped(
    props.groupSelectionsRef,
    groupSelections => groupSelections.grouping
  );
  const expandedRowAddresses = ViewHooks.useWholeMapped(
    props.groupSelectionsRef,
    groupSelections => groupSelections.expandedRowAddresses
  );
  const visCheckboxState = ViewHooks.useWholeMapped(
    props.groupSelectionsRef,
    groupSelections => {
      return SM.getCheckedState(
        groupSelections,
        props.row,
        props.recursionDepth + 1
      );
    }
  );

  // For some reason TS infers the type as `boolean` without the
  // type cast even though `runIsInBounds()` returns `boolean | null`
  const runIsInBounds = ViewHooks.useWholeMapped(
    props.groupSelectionsRef,
    groupSelections => {
      return SM.runIsInBounds(
        groupSelections,
        props.row,
        props.recursionDepth + 1
      );
    }
  ) as boolean | null;

  // TODO(adrnswanberg): This might not be the most efficient thing to do..
  const isAnyCellSelected = ViewHooks.useWholeMapped(
    props.tempSelectionsRef,
    state =>
      !(state.bounds.length === 0 && state.tree.length === 0) ||
      state.root === SM.Root.All
  );
  const tempCheckboxState = ViewHooks.useWholeMapped(
    props.tempSelectionsRef,
    state => {
      const tempGroupSelections = {
        grouping,
        selections: state,
        expandedRowAddresses,
      };
      return SM.getCheckedState(
        tempGroupSelections,
        props.row,
        props.recursionDepth + 1
      );
    }
  );

  const toggleSelection = ViewHooks.useViewAction(
    props.groupSelectionsRef,
    GroupSelectionsActions.toggleSelection
  );

  const tempSelectToggle = ViewHooks.useViewActionBindAll(
    TempSelectionsActions.selectToggle,
    props.tempSelectionsRef,
    props.groupSelectionsRef,
    props.row,
    props.recursionDepth + 1
  );

  const uniqueId = Run.uniqueId(props.row, grouping);

  const setHighlight = InteractStateContext.useInteractStateAction(
    InteractStateActions.setHighlight
  );

  let customRunColor = ViewHooks.useWholeMapped(
    props.customRunColorsRef,
    runColors => runColors[uniqueId]
  );

  customRunColor = runColor(props.row, grouping, customRunColor);

  const setCustomRunColor = ViewHooks.useViewAction(
    props.customRunColorsRef,
    CustomRunColorsActions.setCustomRunColor
  );
  const setCustomRunColorCurried = useCallback(
    (color: string) => {
      setCustomRunColor(uniqueId, color);
    },
    [uniqueId, setCustomRunColor]
  );

  const groupedBy = grouping[props.recursionDepth];
  const isRunGroup = _.isEqual(groupedBy, {section: 'run', name: 'group'});

  const [stopModalOpen, setStopModalOpen] = useState(false);
  const openStopModal = useCallback(
    () => setStopModalOpen(true),
    [setStopModalOpen]
  );
  const closeStopModal = useCallback(
    () => setStopModalOpen(false),
    [setStopModalOpen]
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = useCallback(
    () => setDeleteModalOpen(true),
    [setDeleteModalOpen]
  );
  const closeDeleteModal = useCallback(
    () => setDeleteModalOpen(false),
    [setDeleteModalOpen]
  );

  const [launchConfigModalOpen, setLaunchConfigModalOpen] = useState(false);
  const openLaunchConfigModal = useCallback(() => {
    window.analytics?.track(
      'From project page runs table, opened launch config editor',
      {
        entityName: props.entityName,
        projectName: props.projectName,
        runName: props.row.name,
      }
    );
    setLaunchConfigModalOpen(true);
  }, [props.entityName, props.projectName, props.row.name]);
  const closeLaunchConfigModal = useCallback(
    () => setLaunchConfigModalOpen(false),
    []
  );

  const {id} = props.row;
  const {stopRun, deleteRun} = props;
  const stopRunCurried = useCallback(() => stopRun(id), [stopRun, id]);
  const deleteRunCurried = useCallback(
    (deleteArtifacts?: boolean) => deleteRun(id, deleteArtifacts),
    [deleteRun, id]
  );

  const [editingName, setEditingName] = useState(false);
  const startEditingName = useCallback(
    () => setEditingName(true),
    [setEditingName]
  );
  const stopEditingName = useCallback(
    () => setEditingName(false),
    [setEditingName]
  );

  return {
    groupedBy,
    toggleSelection,
    tempSelectToggle,
    isRunGroup,
    visCheckboxState,
    runIsInBounds,
    tempCheckboxState,
    customRunColor,
    setCustomRunColor: setCustomRunColorCurried,
    isAnyCellSelected,
    setHighlight,

    stopModalOpen,
    openStopModal,
    closeStopModal,

    deleteModalOpen,
    openDeleteModal,
    closeDeleteModal,

    launchConfigModalOpen,
    openLaunchConfigModal,
    closeLaunchConfigModal,

    stopRun: stopRunCurried,
    deleteRun: deleteRunCurried,

    editingName,
    startEditingName,
    stopEditingName,
  };
}

export const RunSelectorRowHeader = (props: RunSelectorRowHeaderProps) => {
  const reduxProps = useRunSelectorRowHeaderProps(props);
  return <RunSelectorRowHeaderInner {...props} {...reduxProps} />;
};

type RowHeaderPopupDropdownProps = {
  displayBenchmark: boolean;
  displayStop: boolean;
  displayRunQueue: boolean;
  editName(): void;
  openDeleteModal(): void;
  openBenchmarkModal(): void;
  openStopModal(): void;
  openLaunchModal(): void;
};

const RowHeaderPopupDropdown: React.FC<RowHeaderPopupDropdownProps> = ({
  displayBenchmark,
  displayStop,
  displayRunQueue,
  editName,
  openDeleteModal,
  openBenchmarkModal,
  openStopModal,
  openLaunchModal,
}) => {
  const options = useMemo(() => {
    const opts: Array<{
      key: string;
      text: string;
      icon: string | JSX.Element;
      onClick: () => any;
    }> = [];
    opts.push({
      key: 'edit',
      text: 'Rename run',
      icon: 'wbic-ic-edit',
      onClick: editName,
    });
    opts.push({
      key: 'delete',
      text: 'Delete run',
      icon: 'wbic-ic-delete',
      onClick: openDeleteModal,
    });
    if (displayBenchmark) {
      opts.push({
        key: 'benchmark',
        text: 'Submit to benchmark',
        icon: 'wbic-ic-benchmark',
        onClick: openBenchmarkModal,
      });
    }
    if (displayStop) {
      opts.push({
        key: 'stop',
        text: 'Stop run',
        icon: <LegacyWBIcon name="stop" />,
        onClick: openStopModal,
      });
    }
    if (displayRunQueue) {
      opts.push({
        key: 'runqueue',
        text: 'Add to Launch queue',
        icon: <LegacyWBIcon name="rocket" />,
        onClick: openLaunchModal,
      });
    }
    return opts;
  }, [
    displayBenchmark,
    displayStop,
    displayRunQueue,
    editName,
    openBenchmarkModal,
    openDeleteModal,
    openStopModal,
    openLaunchModal,
  ]);

  const stopPropagation = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const trigger = useMemo(
    () => (
      <LegacyWBIcon
        className="row-actions-button"
        name="overflow"
        title="menu"
        size="large"
        onClick={stopPropagation}
      />
    ),
    [stopPropagation]
  );

  return <PopupDropdown trigger={trigger} options={options} />;
};

type RowHeaderVisCheckboxProps = Pick<
  AllRunSelectorRowHeaderProps,
  | 'row'
  | 'recursionDepth'
  | 'runIsInBounds'
  | 'visCheckboxState'
  | 'toggleSelection'
  | 'onVisibilityCheckboxClick'
  | 'isInReport'
>;

const RowHeaderVisCheckbox: React.FC<RowHeaderVisCheckboxProps> = ({
  row,
  recursionDepth,
  runIsInBounds,
  visCheckboxState,
  toggleSelection,
  onVisibilityCheckboxClick,
  isInReport,
}) => {
  const checkbox = (
    <MultiStateCheckbox
      data-test="run-visibility-checkbox"
      checked={visCheckboxState}
      onClick={() => {
        toggleSelection(row, recursionDepth + 1);
        onVisibilityCheckboxClick();
        window.analytics?.track('Run visibility toggled', {
          location: isInReport ? 'report' : 'runs table',
        });
      }}
      theme="visibility"
    />
  );

  if (runIsInBounds == null) {
    return checkbox;
  }
  if (runIsInBounds) {
    return checkbox;
  }
  return (
    <Popup
      inverted
      position="right center"
      content="This run is hidden by a parallel coordinates or scatter plot. Deselect the range on that chart first."
      size="mini"
      on="hover"
      trigger={checkbox}
      offset={-3}
      popperModifiers={{
        preventOverflow: {
          // prevent popper from erroneously constraining the popup to the
          // table header
          boundariesElement: 'viewport',
        },
      }}
    />
  );
};
