import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import React, {FC, useCallback, useContext, useState} from 'react';

import * as ViewActions from '../../state/views/actions';
import {useViewRefAction} from '../../state/views/hooks';
import {MagicLinkSection} from './MagicLinkSection';
import {NormalLinkSection} from './NormalLinkSection';
import {SharedWithIndividualsSection} from './SharedWithIndividualsSection';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';

const LINK_PERMISSION_EDIT_VALUE = 'edit';
const LINK_PERMISSION_VIEW_VALUE = 'view';
export const LINK_PERMISSION_OPTIONS: WBMenuOption[] = [
  {name: 'can edit', value: LINK_PERMISSION_EDIT_VALUE},
  {name: 'can view', value: LINK_PERMISSION_VIEW_VALUE},
];

function getLinkPermissionFromViewLocked(locked: boolean): WBMenuOption {
  const linkPermissionVal = locked
    ? LINK_PERMISSION_VIEW_VALUE
    : LINK_PERMISSION_EDIT_VALUE;
  return (
    LINK_PERMISSION_OPTIONS.find(o => o.value === linkPermissionVal) ??
    LINK_PERMISSION_OPTIONS[0]
  );
}

export const TeamContent: FC = () => {
  const {
    canChangeSharingSettings,
    isPrivateAccess,
    isPrivateWriteAccess,
    userIsMemberOfTeam,
    viewRef,
    view,
  } = useContext(ShareReportTriggerContext);

  const setLocked = useViewRefAction(viewRef, ViewActions.setLocked);

  const [linkPermission, setLinkPermission] = useState<WBMenuOption>(
    getLinkPermissionFromViewLocked(view.locked)
  );

  const setLinkPermissionByValue = useCallback(
    (v: string | number) => {
      if (!canChangeSharingSettings) {
        return;
      }
      const lp = LINK_PERMISSION_OPTIONS.find(o => o.value === v);
      if (lp != null) {
        setLinkPermission(lp);
        setLocked(v !== LINK_PERMISSION_EDIT_VALUE);
      }
    },
    [setLocked, canChangeSharingSettings]
  );

  return (
    <>
      {isPrivateWriteAccess && userIsMemberOfTeam && (
        <S.ControlsWrapper>
          <S.RowWrapper>
            <S.IconTextWrapper>
              <S.GrayCircleWBIcon name="team" />
              Anyone on the team&nbsp;
              <S.EmphasizedText>
                {view.project?.entityName ?? ''}
              </S.EmphasizedText>
            </S.IconTextWrapper>
            <S.ShareOptionsWrapper>
              <WBPopupMenuTrigger
                options={LINK_PERMISSION_OPTIONS}
                selected={linkPermission.value}
                onSelect={setLinkPermissionByValue}>
                {({anchorRef, setOpen}) => (
                  <S.Tooltip
                    on="hover"
                    inverted
                    position="top center"
                    size="mini"
                    content={
                      canChangeSharingSettings
                        ? ''
                        : 'Only team admin or author can change member permissions'
                    }
                    hidden={canChangeSharingSettings}
                    trigger={
                      <S.DropdownTrigger
                        disabled={!canChangeSharingSettings}
                        ref={anchorRef}
                        onClick={() => {
                          if (canChangeSharingSettings) {
                            setOpen(o => !o);
                          }
                        }}>
                        {linkPermission.name} <LegacyWBIcon name="down" />
                      </S.DropdownTrigger>
                    }
                  />
                )}
              </WBPopupMenuTrigger>
            </S.ShareOptionsWrapper>
          </S.RowWrapper>
          {canChangeSharingSettings && <SharedWithIndividualsSection />}
        </S.ControlsWrapper>
      )}
      {isPrivateAccess ? <MagicLinkSection /> : <NormalLinkSection />}
    </>
  );
};
