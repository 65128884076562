import {
  constBoolean,
  constFunction,
  opArtifactAliasAlias,
  opArtifactAliasArtifact,
  opArtifactAliases,
  opArtifactIsPortfolio,
  opArtifactMembershipArtifactAliases,
  opArtifactMembershipArtifactVersion,
  opArtifactMembershipCollection,
  opArtifactName,
  opArtifactProject,
  opArtifactVersionAliases,
  opArtifactVersionId,
  opArtifactVersionMemberships,
  opDict,
  opEntityName,
  opMap,
  opProjectEntity,
  opProjectName,
} from '@wandb/cg';
import {toast} from '@wandb/common/components/elements/Toast';
import {isVersionAlias} from '@wandb/common/util/artifacts';
import {useValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';
import styled from 'styled-components';

import {AddAliasButton, Alias} from '../../../components/ArtifactAlias';
import * as Generated from '../../../generated/graphql';
import {trackArtifactInteraction} from '../../../util/navigation';

const inputType = 'artifactMembership' as const;
type ConfigType = {};

const ArtifactAliasList = styled.div`
  display: flex;
`;

export const PanelArtifactMembershipOverviewAliasList: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType>
> = props => {
  const artifactMembershipNode = props.input;
  const [addAliases] = Generated.useAddAliasesMutation();
  const [deleteAliases] = Generated.useDeleteAliasesMutation();
  const overviewValue = useValue(
    React.useMemo(() => {
      const artifactNode = opArtifactMembershipCollection({
        artifactMembership: artifactMembershipNode,
      });
      const artifactVersionNode = opArtifactMembershipArtifactVersion({
        artifactMembership: artifactMembershipNode,
      });
      const allAliases = opArtifactVersionAliases({
        artifactVersion: artifactVersionNode,
        hideVersions: constBoolean(true),
      });
      return opDict({
        artifactName: opArtifactName({
          artifact: artifactNode,
        }),
        projectName: opProjectName({
          project: opArtifactProject({
            artifact: artifactNode,
          }),
        }),
        entityName: opEntityName({
          entity: opProjectEntity({
            project: opArtifactProject({
              artifact: artifactNode,
            }),
          }),
        }),
        artifactAliases: opMap({
          arr: opArtifactAliases({
            artifact: artifactNode,
          }),
          mapFn: aliasMapFn,
        }),
        artifactVersionId: opArtifactVersionId({
          artifactVersion: artifactVersionNode,
        }),
        currentAliases: opMap({
          arr: opArtifactMembershipArtifactAliases({
            artifactMembership: artifactMembershipNode,
          }),
          mapFn: aliasMapFn,
        }),
        allAliases: opMap({
          arr: allAliases,
          mapFn: aliasMapFn,
        }),
        artifactMemberships: opArtifactVersionMemberships({
          artifactVersion: artifactVersionNode,
        }),
        isPortfolio: opArtifactIsPortfolio({artifact: artifactNode}),
      } as any);
    }, [artifactMembershipNode])
  );

  const deleteAlias = React.useCallback(
    async (alias: string) => {
      if (isVersionAlias({alias})) {
        return null;
      }
      try {
        await deleteAliases({
          variables: {
            artifactID: overviewValue.result.artifactVersionId,
            aliases: [
              {
                alias,
                entityName: overviewValue.result.entityName,
                projectName: overviewValue.result.projectName,
                artifactCollectionName: overviewValue.result.artifactName,
              },
            ],
          },
        });
        await overviewValue.refresh();
      } catch (error) {
        console.error(error);
        toast('Something went wrong when trying to delete this alias.');
      }
      return;
    },
    [deleteAliases, overviewValue]
  );

  const createAlias = React.useCallback(
    async (alias: string) => {
      if (
        isVersionAlias({alias}) ||
        overviewValue.result.currentAliases.find((a: any) => a.alias === alias)
      ) {
        return;
      }
      try {
        await addAliases({
          variables: {
            artifactID: overviewValue.result.artifactVersionId,
            aliases: [
              {
                alias,
                entityName: overviewValue.result.entityName,
                projectName: overviewValue.result.projectName,
                artifactCollectionName: overviewValue.result.artifactName,
              },
            ],
          },
        });
        trackArtifactInteraction({
          eventType: 'create_alias',
          artifactCollectionType: overviewValue.result.isPortfolio
            ? 'portfolio'
            : 'sequence',
        });
        await overviewValue.refresh();
      } catch (error) {
        console.error(error);
        toast('Something went wrong when trying to add this alias.');
      }
    },
    [addAliases, overviewValue]
  );

  const copyAlias = React.useCallback(
    (alias: string) => {
      if (overviewValue.loading) {
        return;
      }
      const copyText = `${overviewValue.result.artifactName}:${alias}`;
      copyToClipboard(copyText);
      toast(`Copied alias '${copyText}' to clipboard`);
    },
    [overviewValue]
  );
  if (overviewValue.loading) {
    return <></>;
  }

  return (
    <ArtifactAliasList>
      {overviewValue.result.currentAliases.map(({alias}: {alias: string}) => {
        return (
          <Alias
            key={overviewValue.result.artifactName + alias}
            alias={{alias}}
            onDelete={() => deleteAlias(alias)}
            onClick={() => copyAlias(alias)}
          />
        );
      })}
      {
        <AddAliasButton
          aliases={overviewValue.result.currentAliases}
          availableAliases={overviewValue.result.artifactAliases}
          onCreate={createAlias}
        />
      }
    </ArtifactAliasList>
  );
};
const aliasMapFn = constFunction({row: 'artifactAlias'}, ({row}) => {
  return opDict({
    alias: opArtifactAliasAlias({artifactAlias: row}),
    artifactCollectionName: opArtifactName({
      artifact: opArtifactAliasArtifact({artifactAlias: row}),
    }),
  } as any);
});
