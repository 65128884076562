import 'whatwg-fetch';

import config, {
  envIsIntegration,
  envIsLocal,
  envIsProd,
} from '@wandb/common/config';
import {ALL_BETA_FEATURE_KEYS} from '@wandb/common/util/betaFeatures';
import {PUBLISHED_PROJECT_NAME} from '@wandb/common/util/constants';
import {getCookie} from '@wandb/common/util/cookie';
import {useIsFirstRender} from '@wandb/common/util/hooks';
import {
  captureError,
  datadogSetUserInfo,
  shouldReloadOnError,
} from '@wandb/common/util/integrations';
import {startProfilerPageView} from '@wandb/common/util/profiler';
import {DateFromUTCString} from '@wandb/common/util/time';
import {ThemeProvider} from '@wandb/ui';
import {
  WeaveWBBetaFeatures,
  WeaveWBBetaFeaturesContext,
} from '@wandb/weave-ui/context';
import classNames from 'classnames';
import * as queryString from 'query-string';
import * as React from 'react';
import {useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import {Redirect, useLocation} from 'react-router-dom';

import ErrorPage from './components/ErrorPage';
import ErrorPortal from './components/ErrorPortal';
import {ExperimentContextProvider} from './components/ExperimentVariant';
import {initializeLocalStorageExperiments} from './components/ExperimentVariant';
import {Launcher} from './components/Launcher/Launcher';
import NoMatch from './components/NoMatch';
import {CompositionKeyboardContextProvider} from './components/Slate/CompositionKeyboardContext';
import {InstrumentedLoader as Loader} from './components/utility/InstrumentedLoader';
import {ComputeGraphContextProvider} from './ComputeGraphContextProvider';
import RouteWithLayout from './routes/RouteWithLayout';
import {checkPendoPixelAvailability} from './services/pendo/availabilityCheck';
import {history, isInIframe, isInJupyterNotebook} from './setup';
import {displayError} from './state/global/actions';
import {useDispatch, useSelector} from './state/hooks';
import {useActivityDetectionLoop} from './state/polling/hooks';
import * as ViewerHooks from './state/viewer/hooks';
import {Viewer} from './state/viewer/types';
import {InteractStateProvider} from './state/views/interactState/context';
import {UserInfo} from './types/graphql';
import {viewerUsingAdminPrivileges} from './util/admin';
import {hideZendeskChat, showZendeskChat} from './util/analytics';
import {disableBeamer, openBeamerOnLoad} from './util/beamer';
import {
  formatGalleryTag,
  getReportGalleryPathParams,
  isOnReportGallery,
  Tag,
  tagFromQS,
  useGalleryTags,
  useInitGalleryTags,
} from './util/gallery';
import * as localStorageUtil from './util/localStorage';
import {mouseListenersStart} from './util/mouse';
import startTrace from './util/trace';
import {isOnReportView} from './util/url';
import {
  useBetaFeature,
  useBetaFeatureNightModeEnabled,
} from './util/useBetaFeature';

const HOW_OFTEN_QUESTION_DATE = DateFromUTCString('2021-10-20T08:00:00');
const WHICH_TOOLS_QUESTION_DATE = DateFromUTCString('2022-01-29T08:00:00');
const ML_USE_CASES_QUESTION_DATE = DateFromUTCString('2022-02-12T08:00:00');

interface AppProps {
  children: React.ReactNode;
}

type AllAppProps = AppProps & ReturnType<typeof useAppProps>;

class App extends React.Component<AllAppProps> {
  componentDidCatch(error: any, info: any) {
    captureError(error, 'app_componentdidcatch');
    this.props.dispatch(displayError(error));
    if (!shouldReloadOnError()) {
      console.log({error});
    }
  }

  componentDidMount() {
    mouseListenersStart();
    checkPendoPixelAvailability();

    initializeLocalStorageExperiments();

    if (isInIframe()) {
      hideZendeskChat();
    }
  }

  componentWillUnmount() {
    if (isInIframe()) {
      showZendeskChat();
    }
  }

  render() {
    const {
      viewerState,
      error,
      showErrorPortal,
      errorPortalContent,
      children,
      appClassNames,
    } = this.props;

    /**
     * WARNING: Returning the loader here instead of the app should be done with extreme caution. The reason to block the app until a query returns is that the query data is _required_ for a minimally functional app. This is rarely the case.
     *
     * Because data fetching in a React app is tied to component lifecycles, blocking subcomponents from rendering with loading screens will defer the initiation of those data requests, and create a cascade of serially data calls. E.g.
     * 1a. the app mounts and requests the viewer object: request takes 300ms
     * 1b. the app mounts and requests the gallery tags: request takes 500ms
     * 2. the project page component mounts and requests project data: request 400ms
     * 3. the workspace pane then renders and fetches its data: 350ms
     * Total app loading time _after_ the DOM is mounted is an additional (500+400+350)ms. This is because the slowest resolving query at each level will block _all_ subsequent queries from firing. Paralleization helps, but the minimium response to undo each block will always be the slowest query in the batch.
     *
     * Most of the time the proper place is to block rendering UI at the lowest possible level, not the highest.
     * ```
     * <App>
     *   <ProjectPage>
     *     <MultiRunsWorkSpace>
     *       <Loader> || <MultiRunsWorkspaceData />
     *     </MultiRunsWorkSpace>
     *   </ProjectPage>
     * </App>
     * ```
     * This ensures that the rest of the UI not dependent on the async data can render as fast as possible.
     *
     * Note: The viewer and the galleryTags are legacy, and an ongoing performance initiative is hoping to remove one or both (if possible) from being a critical rendering block here.
     */
    if (viewerState.loading) {
      return <Loader name="viewer-state-loader" />;
    }
    const {viewer} = viewerState;
    datadogSetUserInfo(viewer ?? {username: 'anonymous'});

    const classes = [];
    if (isInIframe()) {
      classes.push('iframe');
    }
    if (isInJupyterNotebook()) {
      classes.push('jupyter');
    }
    // Enforce user to choose a username
    const isAtSignup = window.location.pathname.endsWith('/signup');
    const isAtLogout = window.location.pathname.endsWith('/logout');
    const isUsernameRequired = viewer != null && !(isAtLogout || isAtSignup);
    const hasIncompleteSurvey = viewerHasIncompleteSurvey(viewer);
    const needsToCompleteSurvey =
      hasIncompleteSurvey && !config.ENVIRONMENT_IS_PRIVATE;
    if (needsToCompleteSurvey) {
      localStorageUtil.setItem('survey_incomplete', 'true');
    }

    if (isUsernameRequired && viewer?.signupRequired) {
      // We disable Beamer auto-open for first time signups for a day
      disableBeamer();
      return <Redirect to={{pathname: '/signup', state: {internal: true}}} />;
    }
    // disable beamer on the email verification page
    if (
      error != null &&
      error.code === 403 &&
      error.message &&
      typeof error.message === 'string' &&
      error.message.match('Email must be verified')
    ) {
      disableBeamer();
    }
    if (viewer != null) {
      openBeamerOnLoad();
    }

    const usingAdminPowers = viewer?.admin && viewerUsingAdminPrivileges();
    const onPublishedEditPage = history.location.pathname.includes(
      PUBLISHED_PROJECT_NAME
    );
    const onHiddenPage = !usingAdminPowers && onPublishedEditPage;
    return (
      <ThemeProvider>
        <div className={appClassNames}>
          {error ? (
            <RouteWithLayout
              error={error}
              component={ErrorPage}
              auth={undefined}
              allowIframes
            />
          ) : onHiddenPage ? (
            <RouteWithLayout component={NoMatch} />
          ) : (
            children
          )}
          <ErrorPortal open={showErrorPortal}>{errorPortalContent}</ErrorPortal>
          <Launcher />
        </div>
      </ThemeProvider>
    );
  }
}

function useAppProps() {
  // DO NOT REMOVE THIS useOnLocationChange()
  // We need it to re-render App on every location change,
  // which is necessary due to how routes are being rendered.
  useOnLocationChange();

  const galleryTagsQuery = useInitGalleryTags();
  const dispatch = useDispatch();
  // Note: Very strange... if the selector passed in to the next line
  // (to grab state.global.error) is a globally defined function instead
  // of an inline function, we always get undefined back, at least after
  // faking a login error. This could be a serious issue but I haven't
  // been able to figure out the cause. Defining it inline at least
  // gives the correct result.
  const error = useSelector(state => state.global.error);
  const showErrorPortal = useSelector(state => state.global.showErrorPortal);
  const errorPortalContent = useSelector(
    state => state.global.errorPortalContent
  );

  // We keep the viewer state in the redux store, you can't call Auth.loggedIn
  // until this has been loaded.
  ViewerHooks.useInitViewer();
  // TODO: we should verify the unstable profile images don't cause the app
  // to re-render.  See state/viewer/hooks.ts#useViewer
  const viewerState = useSelector(state => state.viewer);

  const nightModeEnabled = useBetaFeatureNightModeEnabled();

  const appClassNames = useMemo(
    () =>
      classNames('app-root', {
        iframe: isInIframe(),
        jupyter: isInJupyterNotebook(),
      }),
    []
  );

  // Turn on night mode if applicable
  useLayoutEffect(() => {
    if (nightModeEnabled) {
      // Note: This adds the 'night-mode' class to the <html> element.
      // If we add the class to a different element, the night mode css filter breaks position:fixed components, e.g. <ViewBar>.
      // Google 'css filter position fixed' for more details.
      // Surprising fix found here: https://developpaper.com/explain-the-reasons-and-solutions-of-the-conflict-between-filter-and-fixed-in-detail/
      document.documentElement.classList.add('night-mode');
      return;
    }
    document.documentElement.classList.remove('night-mode');
  }, [nightModeEnabled]);

  return {
    appClassNames,
    viewerState,
    error,
    showErrorPortal,
    errorPortalContent,
    dispatch,
    galleryTagsQuery,
  };
}

export default (props: AppProps) => {
  const selectedProps = useAppProps();

  // This starts the loop that detects when the tab is backgrounded or
  // the user is inactive, which is used to control polling.
  useActivityDetectionLoop();

  const weaveAppState: any = {};
  for (const key of ALL_BETA_FEATURE_KEYS) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    weaveAppState[key] = useBetaFeature(key).isEnabled;
  }

  return (
    <WeaveWBBetaFeaturesContext.Provider
      value={weaveAppState as WeaveWBBetaFeatures}>
      {/*
      // ExperimentContextProvider needs to be above ComputeGraphContextProvider
      // because there is a compute graph feature flag experiment that requires the
      // context.
      */}
      <ExperimentContextProvider>
        <ComputeGraphContextProvider>
          <InteractStateProvider>
            <CompositionKeyboardContextProvider>
              <App {...props} {...selectedProps} />
            </CompositionKeyboardContextProvider>
          </InteractStateProvider>
        </ComputeGraphContextProvider>
      </ExperimentContextProvider>
    </WeaveWBBetaFeaturesContext.Provider>
  );
};

function addGalleryTagData(
  tags: Tag[],
  pageViewProps: {[key: string]: string}
): void {
  const galleryPathParams = getReportGalleryPathParams(tags);

  let tag: string | null = null;
  if (isOnReportGallery() && galleryPathParams != null) {
    tag = galleryPathParams.tag;
    if (tag) {
      pageViewProps.value = 'Tag Home';
    }
  } else if (isOnReportView()) {
    tag = tagFromQS();
  }

  if (tag) {
    const formattedTag = formatGalleryTag(tags, tag);
    pageViewProps.category = 'Tag';
    pageViewProps.label = formattedTag;
  }
}

function useOnLocationChange(): void {
  useLocation();
  // Note that due to this useLocation(), the below
  // code with [window.location.href] dependencies
  // runs on every page nav.

  const isInitialPageview = useIsFirstRender();
  const lastHREFRef = useRef<string>(window.location.href);
  useEffect(() => {
    if (!isInitialPageview) {
      trackHostPageView(lastHREFRef.current);
    }
    lastHREFRef.current = window.location.href;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const tags = useGalleryTags();
  useEffect(() => {
    if (tags.length > 0) {
      trackPageViewWithGalleryTags(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, tags]);

  useEffect(() => {
    startTrace();
    startProfilerPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);
}

function trackHostPageView(referrer: string): void {
  if (config.ENVIRONMENT_IS_PRIVATE || !envIsProd) {
    return;
  }

  const qs = queryString.parse(window.location.search);
  const body = {
    appPageParams: {
      properties: {
        url: window.location.href,
        path: window.location.pathname,
        search: window.location.search,
        referrer,
        title: document.title,
      },
      context: {
        userAgent: window.navigator.userAgent,
        locale: window.navigator.language,
        campaign: {
          name: qs.utm_campaign,
          source: qs.utm_source,
          medium: qs.utm_medium,
          term: qs.utm_term,
          content: qs.utm_content,
        },
      },
    },
  };

  // eslint-disable-next-line wandb/no-unprefixed-urls
  fetch('/__WB_PAGEVIEW__', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  });
}

const HOST_SESSION_ID_COOKIE = `host_session_id`;

function trackPageViewWithGalleryTags(tags: Tag[]): void {
  const properties: {[key: string]: string} = {};
  addGalleryTagData(tags, properties);
  window.analytics?.page?.(properties, {
    context: {
      hostSessionID: getCookie(HOST_SESSION_ID_COOKIE),
    },
  });
}

type UserInfoCheck = {
  accessor: keyof UserInfo;
  liveDate: Date;
};

function viewerHasIncompleteSurvey(viewer: Viewer | undefined) {
  // test integration initiates users without survey
  // so always return false to skip this checking and not block tests
  if (envIsIntegration || envIsLocal) {
    return false;
  }

  const createdAt = viewer?.createdAt;
  if (createdAt == null) {
    return false;
  }
  const viewerCreatedAtDate = DateFromUTCString(createdAt);

  const userInfoChecks: UserInfoCheck[] = [
    {accessor: `howOften`, liveDate: HOW_OFTEN_QUESTION_DATE},
    {accessor: `whichTools`, liveDate: WHICH_TOOLS_QUESTION_DATE},
    {accessor: `mlUseCases`, liveDate: ML_USE_CASES_QUESTION_DATE},
  ];
  return userInfoChecks
    .filter(c => c.liveDate <= viewerCreatedAtDate)
    .some(c => viewer?.userInfo?.[c.accessor] == null);
}
