import isHotkey from 'is-hotkey';
import {Editor, Transforms} from 'slate';

export const EditorWithHardBreak = {
  hardBreak(editor: Editor) {
    Transforms.splitNodes(editor, {always: true});
    Transforms.setNodes(editor, {type: 'paragraph'});
  },
};

export const hardBreakOnKeyDown = (editor: Editor, e: React.KeyboardEvent) => {
  if (isHotkey('shift+enter')(e.nativeEvent)) {
    e.preventDefault();
    EditorWithHardBreak.hardBreak(editor);
    return true;
  }

  return false;
};
