import {WBIcon} from '@wandb/ui';
import styled from 'styled-components';

export const Alert = styled.div`
  background-color: rgb(14, 115, 198);
  color: #fff;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

export const Icon = styled(WBIcon)`
  font-size: 20px;
  margin-right: 8px;
`;

export const Message = styled.div`
  // This value chosen to make the alert the same height as our source select widgets.
  padding: 3px;
`;
