import {autoScrollWhenDragging} from '@wandb/common/util/dom';
import React, {FC, useContext, useMemo} from 'react';
import {Node, Path, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSlateStatic,
} from 'slate-react';

import {getDeepLinkId} from '../../util';
import {WBSlateElementType} from '../../WBSlate';
import {EditorWithImages} from '../images';
import {isPanelGrid} from '../panel-grid';
import {BlockDragHandle} from './BlockDragHandle';
import * as S from './drag-drop.styles';
import {useDrag, useDrop} from './utils';
import {WBSlateDragDropContext} from './WBSlateDragDropContext';

export type DragType = 'block' | 'text';

export const BlockWrapper: FC<
  RenderElementProps & {
    disableClickSelect?: boolean;
    noDragging?: boolean;
  }
> = ({attributes, element, children, disableClickSelect, noDragging}) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const {dragItem} = useContext(WBSlateDragDropContext);

  const {sourceAttributes, handleAttributes, dragPhase} = useDrag(element, {
    onHandleMouseDown() {
      if (isPanelGrid(element)) {
        Transforms.select(editor, ReactEditor.findPath(editor, element));
      } else {
        Transforms.deselect(editor);
      }
    },
  });

  const {targetAttributes, dragType} = useDrop({
    onDragEnter(e) {
      autoScrollWhenDragging(e.clientY);
      e.preventDefault();
    },
    onDrop(e) {
      const data = e.dataTransfer;
      if (data == null) {
        return;
      }

      const fragment = data.getData('application/x-slate-fragment');

      if (fragment) {
        const decoded = decodeURIComponent(window.atob(fragment));
        const parsed = JSON.parse(decoded) as WBSlateElementType[];

        const destinationPath = ReactEditor.findPath(editor, element);

        let sourcePath: Path | null = null;

        if (dragItem != null) {
          sourcePath = ReactEditor.findPath(editor, dragItem);
        }

        if (sourcePath == null) {
          // Assume external source; simply insert.
          destinationPath[destinationPath.length - 1]++;
          Transforms.insertNodes(editor, parsed, {
            at: destinationPath,
          });
          Transforms.select(editor, destinationPath);
        } else {
          if (
            destinationPath.length !== sourcePath.length ||
            Path.isBefore(destinationPath, sourcePath)
          ) {
            destinationPath[destinationPath.length - 1]++;
          }

          Transforms.moveNodes(editor, {
            at: sourcePath,
            to: destinationPath,
          });
        }

        e.stopPropagation();
        e.preventDefault();
        return;
      }

      // For dropping images directly into report; doesn't really belong in this file
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.files.length; i++) {
        const file = data.files[i];
        if (file.type.includes('image')) {
          const destinationPath = ReactEditor.findPath(editor, element);
          destinationPath[destinationPath.length - 1]++;
          EditorWithImages.insertImageFromData(editor, file, {
            at: destinationPath,
          });
          e.stopPropagation();
          e.preventDefault();
          return;
        }
      }
    },
  });

  const deepLinkId = useMemo(() => getDeepLinkId(element), [element]);

  if (readOnly) {
    return children;
  }

  return (
    <S.FullBlockWrapper
      {...attributes}
      {...targetAttributes}
      {...sourceAttributes}
      onDragOver={e => {
        if (dragType === 'block') {
          e.preventDefault();
        }
      }}
      onMouseDown={e => {
        // Markdown and panel grids have this option enabled
        if (disableClickSelect) {
          // Only enable Slate selection when they click on the element itself.
          // Note: the element is wrapped around a Slate div node with left and right margin, so
          // this logic makes sure that it doesn't trigger the blue border for panel grids
          // when a click happens in the white space around it.
          if (e.target === attributes.ref.current) {
            e.preventDefault();
            ReactEditor.focus(editor);
            Transforms.deselect(editor);
          }
        }
      }}
      onClick={e => {
        if (disableClickSelect) {
          e.stopPropagation();
          return;
        }

        // Since we force deselection logic in onMouseDown, we need to make sure
        // Slate is selecting the right location when clicking elsewhere
        const rootNode = ReactEditor.toDOMNode(editor, editor).getRootNode();

        // Deselect panels that were selected from dragging/resizing if click occurs outside of editor
        if (!Node.isNode(rootNode)) {
          // Removed a ReactEditor.focus() because it would deselect link to report inputs
          Transforms.deselect(editor);
        }

        if (rootNode instanceof Document) {
          const domSelection = rootNode.getSelection();

          if (
            domSelection != null &&
            domSelection.type !== 'None' &&
            domSelection.rangeCount > 0
          ) {
            const range = ReactEditor.toSlateRange(editor, domSelection, {
              exactMatch: false,
              // domSelection is not necessarily a valid Slate range like
              // clicking on void elements
              suppressThrow: true,
            });

            if (range != null) {
              // Select where the click occurred
              Transforms.select(editor, range);
            }
          }
        }
      }}>
      <S.BlockWrapper
        id={deepLinkId}
        dragging={dragPhase === 'dragging'}
        isDropTarget={dragType === 'block'}
        disableClickSelect={disableClickSelect}>
        {!readOnly && !noDragging && (
          <BlockDragHandle
            handleAttributes={handleAttributes}
            element={element}
            deepLinkId={deepLinkId}
          />
        )}
        <div>{children}</div>
      </S.BlockWrapper>
    </S.FullBlockWrapper>
  );
};
