import {gray700} from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;
export const MessageWrapper = styled.div`
  line-height: 28px;
  font-size: 16px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: center;
`;

export const SubMessageWrapper = styled.div`
  margin: 20px 40px 0 40px;
  color: ${gray700};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
`;

export const LinkAnchor = styled.a`
  color: ${gray700};
  border-bottom: 1px solid;
  border-color: ${gray700 + '80'};
`;
