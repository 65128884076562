import React from 'react';

export type Nullable<T> = T | null | undefined;

export type ValueOf<T> = T[keyof T];

export type Struct<V = any> = Record<string, V>;

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export type FCWithRef<P, T> = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<T>
>;

export function isNotNullOrUndefined<T>(v: T | null | undefined): v is T {
  return v != null;
}

export function isTruthy<T>(v: T | null | undefined): v is T {
  return !!v;
}

export function assertUnreachable(x: never): never {
  throw new Error('unreachable code');
}
