import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

export const PreviewTitle = styled.div`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  line-height: 20px;
`;

export const PreviewSubtitle = styled.div`
  color: ${globals.gray500};
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;
`;

export const PreviewImage = styled.img`
  display: block;
  width: 286px;
  height: 215px;
  margin: 9px 0;
`;

export const PreviewHint = styled.div`
  color: ${globals.gray500};
  font-size: 12px;
  line-height: 15px;
`;

export const Preview = styled.div<{onClick?: () => void; small?: boolean}>`
  background-color: ${globals.white};
  border-radius: 5px;
  flex: 0 0 224px;
  padding: ${p => (p.small ? 16 : 20)}px 12px;
  ${p => p.onClick != null && `cursor: pointer;`}

  ${p =>
    p.small &&
    css`
      ${PreviewTitle} {
        font-size: 12px;
        line-height: 15px;
      }
      ${PreviewSubtitle} {
        font-size: 8px;
        line-height: 10px;
        margin-top: 2px;
      }
      ${PreviewImage} {
        height: auto;
        margin: 5px 0;
        width: 100%;
      }
      ${PreviewHint} {
        font-size: 8px;
        line-height: 10px;
      }
    `}
`;
