import * as globals from '@wandb/common/css/globals.styles';
import {gold} from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

export const Button = styled.button`
  background: Transparent;
  border: 1px solid ${gold};
  box-sizing: border-box;
  border-radius: 3px;
  color: ${globals.gold};
  cursor: pointer;
  width: fit-content;
  height: 30px;
  margin-top: 12px;
  margin-right: 18px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
`;
