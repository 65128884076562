import React, {FC} from 'react';

import {usePropsSelector} from '../../state/hooks';
import {useReport} from '../../state/reports/hooks';
import {makeReportViewIDSelector} from '../../state/reports/selectors';
import {
  ShareReportModalHeaderAction,
  ShareReportTrigger,
} from '../ShareReportTrigger';

interface ShareReportTriggerWrapperProps {
  reportID: string;
  reportName: string;
  userIsMemberOfTeam?: boolean;
  unsetReportID: () => void;
}

export const ShareReportTriggerWrapper: FC<ShareReportTriggerWrapperProps> = ({
  reportID,
  reportName,
  unsetReportID,
  userIsMemberOfTeam,
}) => {
  const reportCID = useReport(reportID);
  const {viewID} = usePropsSelector(makeReportViewIDSelector, reportCID);

  if (!viewID) {
    return null;
  }

  return (
    <ShareReportTrigger
      isModalInitiallyOpen={true}
      onModalClose={unsetReportID}
      modalHeaderAction={
        ShareReportModalHeaderAction.EDIT_REPORT_BEFORE_SHARING
      }
      modalTitle={`Share "${reportName}"`}
      viewRef={viewID}
      isSharePanel={true}
      userIsMemberOfTeam={userIsMemberOfTeam}
    />
  );
};
