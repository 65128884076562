import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Modal} from 'semantic-ui-react';

import {useKeyInfoQuery} from '../state/runs/hooks';
import * as ViewActions from '../state/views/actions';
import * as CustomRunColorsTypes from '../state/views/customRunColors/types';
import * as ViewHooks from '../state/views/hooks';
import * as PanelViewTypes from '../state/views/panel/types';
import {insertUpdatedPanel} from '../state/views/panelBankSectionConfig/actions';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import * as PanelSettingsViewTypes from '../state/views/panelSettings/types';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../types/run';
import {haveDataForPanel} from '../util/panelbank';
import {PanelCompRedux, PanelSpec} from '../util/panels';
import {InstrumentedLoader as Loader} from './utility/InstrumentedLoader';

export interface PanelEditorProps {
  readOnly?: boolean;
  disableRunLinks?: boolean;
  currentHeight: number;
  editable: boolean;

  initialConfigState?: {[key: string]: any};

  panelSpec: PanelSpec<any, any>;

  runSetRefs: RunSetViewTypes.Ref[];
  panelRef: PanelViewTypes.Ref;
  customRunColorsRef: CustomRunColorsTypes.Ref;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
  newPanel?: boolean;

  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;

  onRemove?(): void;
  onCancel?(): void;
  onOK?(): void;
}

export interface LoadedKeyInfoQuery {
  loading: false;
  error: null | undefined;
  historyKeyInfo: RunHistoryKeyInfo;
}

function isLoadedKeyInfoQuery(object: any): object is LoadedKeyInfoQuery {
  return !object.loading && object.error == null && 'historyKeyInfo' in object;
}

const PanelEditor = (props: PanelEditorProps) => {
  const dispatch = useDispatch();
  const panelCopy = ViewHooks.useObjectCopy(props.panelRef);
  const keyInfoQuery = useKeyInfoQuery(props.runSetRefs);

  // Disable the "Ok" button if we don't have enough info to render the plot
  const panelPreview = ViewHooks.useWhole(
    panelCopy.copying ? props.panelRef : panelCopy.ref
  );
  const okDisabled =
    (panelPreview.viewType === 'Run History Line Plot' &&
      _isEmpty(panelPreview.config.metrics)) ||
    (panelPreview.viewType === 'Scatter Plot' &&
      (_isEmpty(panelPreview.config.xAxis) ||
        _isEmpty(panelPreview.config.yAxis))) ||
    (panelPreview.viewType === 'Parallel Coordinates Plot' &&
      (panelPreview.config.columns == null ||
        panelPreview.config.columns.length < 2)) ||
    (panelPreview.viewType === 'Vega2' &&
      !(
        isLoadedKeyInfoQuery(keyInfoQuery) &&
        haveDataForPanel(keyInfoQuery.historyKeyInfo, panelPreview)
      ));

  return (
    <Modal
      closeOnEscape={false}
      className={[
        props.panelSpec.type.toLowerCase().replace(' ', '-') + '-panel-editor',
        props.editable ? 'edit-mode' : 'fullscreen-mode',
        'panel-editor',
      ].join(' ')}
      open={true}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
      onClose={props.onCancel}
      size="fullscreen">
      <Modal.Content
        className={props.editable ? 'edit-mode' : 'fullscreen-mode'}>
        {panelCopy.copying ||
        keyInfoQuery.loading ||
        keyInfoQuery.error != null ? (
          <div style={{height: 500}}>
            <Loader name="key-info-query" />
          </div>
        ) : (
          <PanelCompRedux
            customRunColorsRef={props.customRunColorsRef}
            runSetRefs={props.runSetRefs}
            panelRef={panelCopy.ref}
            panelSettings={props.panelSettings}
            configMode={props.editable}
            currentHeight={props.currentHeight}
            disableRunLinks={props.disableRunLinks}
            keyInfo={keyInfoQuery.historyKeyInfo}
            dimensions={undefined as any}
            initialConfigState={props.initialConfigState}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {props.editable && props.onRemove && (
          <Button floated="left" onClick={props.onRemove}>
            Delete
          </Button>
        )}
        {props.onCancel && (
          <Button onClick={props.onCancel}>
            {props.editable ? 'Cancel' : 'Close'}
          </Button>
        )}
        {props.editable && props.onOK && (
          <Button
            data-test="submit-panel-config"
            primary
            disabled={panelCopy.copying || okDisabled}
            onClick={() => {
              if (!panelCopy.copying) {
                if (props.newPanel) {
                  dispatch(
                    ViewActions.copyObject(panelCopy.ref, props.panelRef)
                  );
                } else {
                  dispatch(
                    insertUpdatedPanel(
                      props.panelBankSectionConfigRef,
                      panelCopy.ref,
                      props.panelRef
                    )
                  );
                  window.analytics?.track('Panel added', {
                    type: props.panelSpec.type,
                  });
                }
                if (props.onOK != null) {
                  props.onOK();
                }
              }
            }}>
            OK
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default PanelEditor;
