import styled from 'styled-components';

export const TableActionButtonsWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 12px;
  grid-auto-flow: column;
  & .ui.button {
    margin-right: 0px;
  }
`;
