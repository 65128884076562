import {toast} from '@wandb/common/components/elements/Toast';
import * as _ from 'lodash';
import {Action} from 'redux';
import {Channel, channel} from 'redux-saga';
import {
  actionChannel,
  ActionPattern,
  all,
  call,
  flush,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import {createAction, getType} from 'typesafe-actions';

import * as Redux from '../../types/redux';
import {propagateErrorsContext} from '../../util/errors';
import {ApolloClient} from '../types';
import * as ViewerSelectors from '../viewer/selectors';
import * as ViewerTypes from '../viewer/types';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as Api from './api';
import * as Normalize from './normalize';
import * as Reducer from './reducer';
import * as Selectors from './selectors';

interface ViewPartUpdateActionShape {
  type: string;
  payload: {
    ref: {
      viewID: string;
    };
  };
}
type ViewPartUpdateActionHelper<A extends Redux.RootAction> =
  A extends ViewPartUpdateActionShape ? A : never;
type ViewPartUpdateAction = ViewPartUpdateActionHelper<Redux.RootAction>;

interface ViewPartsUpdateActionShape {
  type: string;
  payload: {
    refs: Array<{
      viewID: string;
    }>;
  };
}
type ViewPartsUpdateActionHelper<A extends Redux.RootAction> =
  A extends ViewPartsUpdateActionShape ? A : never;
type ViewPartsUpdateAction = ViewPartsUpdateActionHelper<Redux.RootAction>;

interface ViewUpdateActionShape {
  type: string;
  payload: {
    ref: {
      id: string;
    };
  };
}
type ViewUpdateActionHelper<A extends Redux.RootAction> =
  A extends ViewUpdateActionShape ? A : never;
type ViewUpdateAction = ViewUpdateActionHelper<Redux.RootAction>;

// Determine if an action should trigger a view save. This
// is a little fragile. It checks if the action type begins
// with '@view' and if the payload has a field called ref
// that has a field called viewID inside. So all part
// updates need to conform to this action pattern.
function isViewPartUpdateAction(
  a: Redux.RootAction
): a is ViewPartUpdateAction {
  if (!a.type.startsWith('@view')) {
    return false;
  }
  const payload = (a as any).payload;
  if (payload == null) {
    return false;
  }
  const ref = payload.ref;
  if (ref == null) {
    return false;
  }
  const viewID = ref.viewID;
  if (viewID == null) {
    return false;
  }
  return true;
}

// setConfigs and updateConfigs takes multiple refs
function isViewPartsUpdateAction(
  a: Redux.RootAction
): a is ViewPartsUpdateAction {
  if (!a.type.startsWith('@view')) {
    return false;
  }
  const payload = (a as any).payload;
  if (payload == null) {
    return false;
  }
  const refs = payload.refs;
  if (refs == null) {
    return false;
  }
  return true;
}

function isViewUpdateAction(a: Redux.RootAction): a is ViewUpdateAction {
  if (!a.type.startsWith('@view')) {
    return false;
  }
  const payload = (a as any).payload;
  if (payload == null) {
    return false;
  }
  const ref = payload.ref;
  if (ref == null) {
    return false;
  }
  const viewID = ref.id;
  if (viewID == null) {
    return false;
  }
  return true;
}

function isSaveableAction(a: Redux.RootAction): boolean {
  if (
    a.type === getType(Actions.undo) ||
    a.type === getType(Actions.redo) ||
    a.type === getType(Actions.save)
  ) {
    return true;
  }
  return (
    isViewPartUpdateAction(a) ||
    isViewPartsUpdateAction(a) ||
    isViewUpdateAction(a)
  );
}

function isAlwaysSavedAction(a: Redux.RootAction): boolean {
  if (
    a.type === getType(Actions.save) ||
    a.type === getType(Actions.setLocked)
  ) {
    return true;
  }
  return false;
}

// Greedily grabs viewIDs out of saveChan, and executes save requests for
// each unique viewID. This is so we can batch saves that come in while
// we have a save request in flight.
function* saveLoop(client: ApolloClient, saveChan: Channel<SaveAction>) {
  const saveFailures = new Set<string>();
  while (true) {
    // Block until there's a viewID available in the channel.
    const saveAction0: SaveAction = yield take(saveChan);
    // Flush the channel, getting any other remaining viewIDs in the channel.
    // flush is non-blocking.
    const saveActionsRest: SaveAction[] = yield flush(saveChan);
    const saveActions = [saveAction0, ...saveActionsRest];
    const saveViewIDs = _.uniq(saveActions.map(a => a.payload.viewID));

    // Save each view. We save whatever the latest state of each view happens to
    // be. Note there is no way to cancel a save as implemented. If a user navigates
    // away from a page where they've triggered a bunch of save actions, that view will
    // still get saved if it ends up in state.views.views.
    for (const viewID of saveViewIDs) {
      const views: Reducer.ViewReducerState = yield select(Selectors.getViews);

      yield put(ActionsInternal.saveStarted(viewID));
      const view = views.views[viewID];
      if (view == null || view.partRef == null) {
        continue;
      }

      const spec = Normalize.denormalize(views.parts, view.partRef);

      const response: Api.SaveResultType = yield call(() =>
        Api.save(client, {...view, spec}, propagateErrorsContext()).catch(
          err => {
            if (!saveFailures.has(viewID)) {
              // We don't want to spam a user repeatedly with errors, so
              // only show the error once
              toast(
                'Error: changes failed to save to server, any further changes will not be saved.',
                {
                  type: 'error',
                }
              );
            }
            saveFailures.add(viewID);
            console.error('Failed to save view, error:', err);
            return null;
          }
        )
      );
      if (response === null) {
        yield put(ActionsInternal.saveFailed(viewID));
      } else {
        if (saveFailures.delete(viewID)) {
          toast('Changes are saving to server successfully.', {
            type: 'success',
          });
        }
        yield put(ActionsInternal.saveFinished(viewID, response));
      }
    }
  }
}

// Fake action to trigger save
const triggerSave = createAction(
  '@view/sagaTriggerSave',
  action => (viewID: string) => action({viewID})
);
type SaveAction = ReturnType<typeof triggerSave>;

function* saveableActionLoop(client: ApolloClient) {
  const triggerSaveChan: Channel<SaveAction> = yield channel<SaveAction>();
  yield fork(saveLoop, client, triggerSaveChan);

  const saveableActionChan: ActionPattern<Action<boolean>> =
    yield actionChannel(isSaveableAction);
  while (true) {
    let action: Redux.RootAction = yield take(saveableActionChan);

    const views: Reducer.ViewReducerState = yield select(Selectors.getViews);
    const viewer: ViewerTypes.Viewer = yield select(ViewerSelectors.getViewer);

    // A little hacky, we can get the ID of views affected by undo/redo by
    // looking in redoActions (for an undo) or undoActions (for a redo)
    // because undo/redo push actions there. If they seemed swapped, it's because
    // we know for sure that the reducer (which swaps an undo action into the redo
    // list and vice versa) always gets called before this saga.
    if (action.type === getType(Actions.redo)) {
      action = views.undoActions[views.undoActions.length - 1];
    } else if (action.type === getType(Actions.undo)) {
      action = views.redoActions[views.redoActions.length - 1];
    }
    let viewID: string;
    if (isViewPartUpdateAction(action)) {
      viewID = action.payload.ref.viewID;
    } else if (isViewPartsUpdateAction(action)) {
      if (action.payload.refs.length === 0) {
        continue;
      } else {
        viewID = action.payload.refs[0].viewID;
      }
    } else if (isViewUpdateAction(action)) {
      viewID = action.payload.ref.id;
    } else if (action.type === getType(Actions.save)) {
      viewID = action.payload.viewRef.id;
    } else {
      continue;
    }

    const view = views.views[viewID];
    if (view == null) {
      // This happens for parts that don't have views (parts that were
      // copied or created).
      continue;
    }
    if (view.partRef == null) {
      // View not ready
      continue;
    }
    if (
      viewer == null ||
      (view.project == null && view.user?.id !== viewer.id) ||
      view.project?.readOnly
    ) {
      // If it's not ours, don't save it, just mark modified.
      yield put(ActionsInternal.markModified(viewID));
      continue;
    }
    if (view.autoSave && view.user.id !== viewer.id) {
      // Can't autosave views that aren't ours, mark modified
      yield put(ActionsInternal.markModified(viewID));
      continue;
    }
    if (!view.autoSave && !isAlwaysSavedAction(action)) {
      // If autosave is disabled, don't save it, just mark modified.
      yield put(ActionsInternal.markModified(viewID));
      continue;
    }

    if (action.type === getType(Actions.setAutosave) && !view.modified) {
      // If this is the autoSave action itself, don't save if the view is not
      // modified. We currently use an action to mark reports for autosave
      // every time the edit report page is loaded. This prevents us from
      // triggering a save on page load.
      continue;
    }

    // If we get here, we want to do an actual save. Notify the save
    // loop that this viewID needs to be saved.
    yield put(triggerSaveChan, triggerSave(viewID));
  }
}

export default function* allSagas(client: ApolloClient) {
  yield all([saveableActionLoop(client)]);
}
