import {
  TABLE_DEFAULT_COLUMN_WIDTH,
  TABLE_DEFAULT_NAME_COLUMN_WIDTH,
  TABLE_MIN_COLUMN_WIDTH,
} from '@wandb/common/util/constants';

import {ConfigV2} from './runfeed';
import * as Run from './runs';

export function defaultColumnWidth(k: Run.Key) {
  if (k.section === 'run' && k.name === 'displayName') {
    return TABLE_DEFAULT_NAME_COLUMN_WIDTH;
  } else if (
    k.section === 'tags' ||
    (k.section === 'run' && k.name === 'username')
  ) {
    return TABLE_MIN_COLUMN_WIDTH;
  }
  return TABLE_DEFAULT_COLUMN_WIDTH;
}

export function getColumnWidth(tableSettings: ConfigV2, key: Run.Key) {
  const columnKeyString = Run.keyToString(key);
  return (
    (tableSettings.columnWidths &&
      tableSettings.columnWidths[columnKeyString]) ||
    defaultColumnWidth(key)
  );
}
