import {fuzzyMatch} from '@wandb/common/util/fuzzyMatch';
import {ApolloQueryResult} from 'apollo-client';
import classNames from 'classnames';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {Loader} from 'semantic-ui-react';

import emptyImg2x from '../../assets/il-no-launch-alt.png';
import {Highlight, Python} from '../../components/Code';
import * as Generated from '../../generated/graphql';
import {ArtifactCollection} from '../../state/graphql/projectArtifactsQuery';
import {useDebounceState} from '../../util/hooks';
import {useResizer} from '../../util/resize';
import {usePaginate} from '../../util/usePaginate';
import * as SArtifactSidebar from '../ArtifactSidebar.styles';
import {ArtifactSidebarPaginate} from '../ArtifactSidebarSequence';
import BlankPage from '../BlankPage';
import {JobPanelRoot} from '../panels.domain/artifact/job/JobPanelRoot';
import {JobSidebarSequence} from './JobSidebarSequence';
import * as S from './JobsViewer.styles';

interface JobsViewerProps {
  projectName: string;
  entityName: string;
}

const JOBS_PER_SECTION = 10;

const JobsViewer: React.FC<JobsViewerProps> = props => {
  const {data, loading, refetch} = Generated.useFetchProjectJobsQuery({
    variables: {
      projectName: props.projectName,
      entityName: props.entityName,
    },
  });
  const [searchFocused, setSearchFocused] = React.useState(false);
  const [noJobs, setNoJobs] = React.useState(false);
  const [selectedJobVersion, setSelectedJobVersion] = React.useState<
    string | null
  >(null);
  const [searchQuery, searchQueryDebounced, setSearchQuery] = useDebounceState(
    '',
    250
  );
  const {onMouseDown, cursor, dropSize} = useResizer('right', 251, {
    min: 200,
  });

  const allJobNames = React.useMemo(() => {
    const names: string[] = [];
    for (const e of data?.project?.artifactType?.artifactCollections?.edges ??
      []) {
      const name = e.node?.name;
      if (name != null) {
        names.push(name);
      }
    }
    return names;
  }, [data?.project?.artifactType]);

  const filteredJobs = React.useMemo(() => {
    const jobNamesFiltered = fuzzyMatch(allJobNames, searchQueryDebounced);
    return (data?.project?.artifactType?.artifactCollections?.edges ?? [])
      .map(e => e.node)
      .filter(
        seq =>
          seq != null &&
          (searchQueryDebounced === '' || jobNamesFiltered.includes(seq.name))
      );
  }, [allJobNames, searchQueryDebounced, data?.project?.artifactType]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const jobEdges =
      data?.project?.artifactType?.artifactCollections?.edges ?? [];
    if (jobEdges != null && jobEdges.length > 0) {
      const collectionName = jobEdges[0].node?.name;
      const index = jobEdges[0].node?.artifacts.edges[0]?.node.versionIndex;
      if (index != null) {
        setSelectedJobVersion(`${collectionName}:v${index}`);
        setNoJobs(false);
      } else {
        const alias = jobEdges[0].node?.artifacts.edges[0]?.node.aliases[0];
        setSelectedJobVersion(`${collectionName}:${alias}`);
        setNoJobs(false);
      }
    } else {
      setNoJobs(true);
    }
  }, [data?.project?.artifactType?.artifactCollections?.edges, loading]);

  if (noJobs) {
    return (
      <BlankPage
        header="Jobs"
        subheader="This project has no jobs."
        info="Jobs represent reproducible units of work that can be executed through wandb Launch. 
      Jobs can be created from a run that logged a code artifact, had a git repo associated 
      with it, or had specified a container through the WANDB_DOCKER environment variable."
        graphics={[{src: emptyImg2x, alt: 'no-jobs'}]}>
        {' '}
        <S.CodeDiv>
          <Python>
            <Highlight iconOnly>
              {`import wandb
run = wandb.init()
# ... train model
run.log_code()
`}
            </Highlight>
          </Python>
        </S.CodeDiv>
      </BlankPage>
    );
  }

  if (loading || selectedJobVersion === null) {
    return <Loader active />;
  }

  return (
    <div className="artifact-page">
      <div className="artifact-page-side-container">
        <div className="artifact-page-side" style={{width: dropSize}}>
          <SArtifactSidebar.ArtifactSidebarWrapper>
            <SArtifactSidebar.ArtifactSidebarHeader>
              <SArtifactSidebar.ArtifactSearchBar
                className={classNames({
                  'artifact-sidebar-search-bar-focus': searchFocused,
                })}
                icon={{className: 'wbic-ic-search', size: 'large'}}
                iconPosition="left"
                onChange={(event, {value}) => setSearchQuery(value)}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(searchQuery.length > 0)}
                placeholder="Find matching jobs"
              />
            </SArtifactSidebar.ArtifactSidebarHeader>
            <SArtifactSidebar.ArtifactSidebarContents>
              <JobsSidebar
                {...props}
                jobs={filteredJobs}
                refetchJobs={refetch}
                setSelectedJobVersion={setSelectedJobVersion}
                selectedJobVersion={selectedJobVersion}
              />
            </SArtifactSidebar.ArtifactSidebarContents>
          </SArtifactSidebar.ArtifactSidebarWrapper>
        </div>
        <div
          className="artifact-page-side-resizer"
          onMouseDown={onMouseDown}
          style={{cursor}}
        />
      </div>
      <div className="artifact-page-main">
        {loading || selectedJobVersion == null ? (
          <Loader active />
        ) : (
          <JobPanelRoot
            projectName={props.projectName}
            entityName={props.entityName}
            selectedJobVersion={selectedJobVersion}
            setSelectedJobVersion={setSelectedJobVersion}
          />
        )}
      </div>
    </div>
  );
};

interface JobsSidebarProps {
  projectName: string;
  entityName: string;
  jobs: Array<
    Generated.Maybe<Pick<ArtifactCollection, 'id' | 'name'>> | undefined
  >;
  selectedJobVersion: string;
  refetchJobs: () => Promise<ApolloQueryResult<any>>;
  setSelectedJobVersion: (v: string) => void;
}

const JobsSidebar: React.FC<JobsSidebarProps> = props => {
  const filteredSortedJobs = React.useMemo(() => {
    const filteredJobs = (props.jobs?.filter(s => s != null) ??
      []) as ArtifactCollection[];
    return _.sortBy(filteredJobs, s => s.__typename !== 'ArtifactPortfolio');
  }, [props.jobs]);

  const {
    totalPages,
    hasNextPage,
    hasPreviousPage,
    setPage,
    total,
    page,
    itemsPage,
  } = usePaginate(filteredSortedJobs, JOBS_PER_SECTION);

  return (
    <div>
      {itemsPage.map(a => (
        <JobSidebarSequenceWithExpansion
          key={a.id}
          projectName={props.projectName}
          entityName={props.entityName}
          job={a}
          refetchJobs={props.refetchJobs}
          setSelectedJobVersion={props.setSelectedJobVersion}
          selectedJobVersion={props.selectedJobVersion}
        />
      ))}
      {totalPages > 0 && (
        <SArtifactSidebar.ArtifactSidebarTypePaginate>
          <ArtifactSidebarPaginate
            size="tiny"
            perPage={JOBS_PER_SECTION}
            page={page}
            total={total}
            onNextClick={() => setPage(page + 1)}
            onPreviousClick={() => setPage(page - 1)}
            pageInfo={{hasNextPage, hasPreviousPage}}
          />
        </SArtifactSidebar.ArtifactSidebarTypePaginate>
      )}
    </div>
  );
};

interface JobSidebarSequenceWithExpansionProps {
  projectName: string;
  entityName: string;
  job: ArtifactCollection;
  selectedJobVersion: string;
  refetchJobs: () => Promise<ApolloQueryResult<any>>;
  setSelectedJobVersion: (v: string) => void;
}

const JobSidebarSequenceWithExpansion: React.FC<JobSidebarSequenceWithExpansionProps> =
  props => {
    const [expanded, setExpanded] = React.useState(false);
    useEffect(() => {
      if (props.selectedJobVersion.startsWith(props.job.name)) {
        setExpanded(true);
      }
    }, [props.selectedJobVersion, props.job.name]);
    return (
      <JobSidebarSequence
        key={props.job.id}
        {...props}
        {...props.job}
        collectionTypeName={props.job.__typename}
        refetchProjectJobs={props.refetchJobs}
        expanded={expanded}
        onHeaderClick={() => {
          setExpanded(!expanded);
        }}
        selectedJobVersion={props.selectedJobVersion}
        setSelectedJob={props.setSelectedJobVersion}
      />
    );
  };

export default JobsViewer;
