import React from 'react';
import Select from 'react-select';

import {FetchJobVersionsQuery} from '../../generated/graphql';

// Option for an alias/version
interface JobVersionOption {
  label: string;
  value: string;
}

// Option for group separator in select
export interface JobVersionGroupOption {
  label: string;
  options: JobVersionOption[];
}

interface SelectJobVersionProps {
  options: JobVersionGroupOption[];
  selectedAlias: string;
  onSelectJobAlias: (jobAlias: string) => void;
}

const RE_VERSION = /^v(\d+)$/;

// Parse query results to separate aliases from versions.
export const getJobVersionOptions = (
  data: FetchJobVersionsQuery
): [JobVersionGroupOption[], string] => {
  const aliases: string[] = [];
  const versions: number[] = [];

  const artifactEdges = data.project?.artifactCollection?.artifacts.edges ?? [];
  for (const artifactEdge of artifactEdges) {
    const artifactAliases = artifactEdge.node.aliases;
    for (const artifactAlias of artifactAliases) {
      const match = artifactAlias.alias.match(RE_VERSION);
      if (match) {
        versions.push(parseInt(match[1], 10));
      } else {
        aliases.push(artifactAlias.alias);
      }
    }
  }

  // "latest" is special, sort it first.
  const sortedAliases = aliases.sort((a, b) => {
    if (a === 'latest') {
      return b === 'latest' ? 0 : -1;
    }
    if (b === 'latest') {
      return 1;
    }
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  });
  const sortedVersions = versions.sort().reverse();
  const aliasOptions = sortedAliases.map(a => ({value: a, label: a}));
  const versionOptions = sortedVersions.map(v => ({
    value: `v${v}`,
    label: `v${v}`,
  }));
  const options = [
    {
      label: 'Aliases',
      options: aliasOptions,
    },
    {
      label: 'Versions',
      options: versionOptions,
    },
  ];

  // At the moment, users are permitted to delete "latest".
  // If they have, fall back to highest version number.
  const defaultVersion =
    aliases.length && aliases[0] === 'latest' ? 'latest' : `v${versions[0]}`;

  return [options, defaultVersion];
};

const SelectJobVersion: React.FC<SelectJobVersionProps> = ({
  options,
  selectedAlias,
  onSelectJobAlias,
}) => {
  const onChange = (option: JobVersionOption | null) => {
    if (option) {
      onSelectJobAlias(option.value);
    }
  };

  const value = {value: selectedAlias, label: selectedAlias};
  return <Select options={options} value={value} onChange={onChange} />;
};

export default SelectJobVersion;
