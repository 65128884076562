import makeComp from '@wandb/common/util/profiler';
import React, {FC} from 'react';

import EmailsTable from '../EmailsTable';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsEmails: FC = makeComp(
  () => {
    const {viewer} = useUserSettingsContext();
    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }
    return (
      <>
        <p>Associate one or more emails with your account.</p>
        <EmailsTable entity={viewer} />
      </>
    );
  },
  {id: 'UserSettingsEmails', memo: true}
);
