import React from 'react';
import {Button, Modal} from 'semantic-ui-react';

interface StopRunModalProps {
  shouldShowModal: boolean;
  onModalCanceled(): void;
  onStopRun(): void;
}

const StopRunModal: React.FC<StopRunModalProps> = React.memo(
  ({shouldShowModal, onModalCanceled, onStopRun}) => {
    return (
      <Modal open={shouldShowModal} onClose={onModalCanceled}>
        <Modal.Content>
          <p>
            Are you sure you want to STOP this run? This action cannot be
            undone!
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onModalCanceled}>Cancel</Button>
          <Button color="red" onClick={onStopRun}>
            Stop this run
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);

export default StopRunModal;
