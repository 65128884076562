import type {MatchMode} from '@wandb/common/util/columnMatching';
import * as React from 'react';
import {useState} from 'react';

import * as RunFeed from '../../util/runfeed';
import TableEditor from '../TableEditor';

interface WBTableColumnEditorProps {
  config: RunFeed.Config;
  allColumns: string[];
  fixedColumns?: string[];
  objectType?: string; // Just a string used in the UI. defaults to Columns, but is Parameters in the parameter importance panel

  update(config: RunFeed.Config): void;
}

const WBTableColumnEditor = (props: WBTableColumnEditorProps) => {
  const [search, setSearch] = useState('');
  const [searchMode, setMatchMode] = useState<MatchMode>('fuzzy');

  return (
    <TableEditor
      config={props.config}
      allColumns={props.allColumns}
      fixedColumns={props.fixedColumns}
      searchQuery={search}
      setSearch={setSearch}
      searchMode={searchMode}
      setMatchMode={setMatchMode}
      update={props.update}
      objectType={props.objectType}
    />
  );
};

export default WBTableColumnEditor;
