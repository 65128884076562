// Public actions, to be called by React components.
import {ID} from '@wandb/cg';
import {createAction} from 'typesafe-actions';

export const loadStarted = createAction(
  '@report/loadStarted',
  action => (reportID: string) =>
    action({
      id: ID(),
      reportID,
    })
);
