import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import * as React from 'react';
import {useEffect, useMemo} from 'react';

import {RunQueue} from '../generated/graphql';
import * as CustomRunColorsTypes from '../state/views/customRunColors/types';
import {EMPTY_CUSTOM_RUN_COLORS} from '../state/views/customRunColors/util';
import * as ViewHooks from '../state/views/hooks';
import * as RunSetTypes from '../state/views/runSet/types';
import * as TempSelectionsTypes from '../state/views/tempSelections/types';
import {BenchmarkProject} from '../types/graphql';
import * as Query from '../util/queryts';
import * as Run from '../util/runs';
import {emptyRunSetSelectAll} from '../util/section';
import * as SM from '../util/selectionmanager';
import {InstrumentedLoader as Loader} from './../components/utility/InstrumentedLoader';
import {
  DisabledFeaturesMap,
  RunSelectorInner,
} from './RunSelector/RunSelectorInner';

/*
 * RunSelector is WBTable, wrapped with RunSelectorQuery (to fetch tags)
 * And n RunsDataLoader queries (one for the top level query, and one for each expanded group)
 */

interface RunSelectorProps {
  className?: string;
  pageEntityName: string;
  pageProjectName: string;
  isExpandable?: boolean;
  isExpanded?: boolean;
  defaultKeys?: Run.Key[]; // fka baseKeys
  pollInterval: number;
  benchmarkMeta?: BenchmarkProject;
  displayBenchmark?: boolean;
  isSingleMode?: boolean;
  selectedRunName?: string;
  onSelectRunName?: (runId: string) => void;
  customRunColorsRef?: CustomRunColorsTypes.Ref;
  runSetRef?: RunSetTypes.Ref;
  tempSelectionsRef?: TempSelectionsTypes.Ref;
  title?: string;
  enableSetProject?: boolean;
  enableFreezeRunset?: boolean;
  recommendedGrouping?: Query.Grouping;
  isInReport?: boolean;

  // If true, users won't be able to edit filter, grouping, etc.
  readOnly?: boolean;
  // If true, users won't be able to rename runs, etc.
  noEdit?: boolean;

  showArtifactCounts?: boolean;
  showLogCounts?: boolean;
  onSetExpanded?: (expanded: boolean) => void;
  disabledFeatures?: DisabledFeaturesMap;
  disableRunLinks?: boolean;
  runQueues?: RunQueue[];
  onHeightChange?(): void;
}

const RunSelector = (props: RunSelectorProps) => {
  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const customRunColorsRef = ViewHooks.useExistingOrNewObjectRef(
    'run-colors',
    EMPTY_CUSTOM_RUN_COLORS,
    props.customRunColorsRef
  );

  const emptyRunSet = useMemo(emptyRunSetSelectAll, []);
  const runSetRef = ViewHooks.useExistingOrNewObjectRef(
    'runSet',
    emptyRunSet,
    props.runSetRef
  );

  const tempSelectionsRef = ViewHooks.useExistingOrNewObjectRef(
    'temp-selections',
    SM.EMPTY_NONE_SELECTIONS,
    props.tempSelectionsRef
  );

  if (
    !customRunColorsRef.ready ||
    !runSetRef.ready ||
    !tempSelectionsRef.ready
  ) {
    return <Loader name="run-selector-loader" />;
  }

  const refProps = {
    customRunColorsRef: customRunColorsRef.ref,
    runSetRef: runSetRef.ref,
    tempSelectionsRef: tempSelectionsRef.ref,
  };

  return <RunSelectorInner {...props} {...refProps} />;
};

export default RunSelector;
