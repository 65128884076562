import React, {FC} from 'react';
import {useSlate} from 'slate-react';

import {EditorWithMarkShortcuts} from '../plugins/mark-shortcuts';
import * as S from './HoveringToolbar.styles';

interface FormatButtonProps {
  format: string;
  icon: string;
}

export const FormatButton: FC<FormatButtonProps> = ({format, icon}) => {
  const editor = useSlate();
  return (
    <S.FormatButton
      name={icon}
      $active={EditorWithMarkShortcuts.hasMark(editor, format)}
      onMouseDown={e => e.preventDefault()}
      onClick={() => {
        EditorWithMarkShortcuts.toggleMark(editor, format);
      }}
    />
  );
};
