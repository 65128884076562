import config from '@wandb/common/config';
import {getCookie} from '@wandb/common/util/cookie';
import {ActionType, getType} from 'typesafe-actions';

import * as AuthActions from '../actions/auth';

export type AuthAction = ActionType<typeof AuthActions>;

// Increment these names to log everyone out if changing the format.
const ID_TOKEN_COOKIE = 'id_token_2';
const ID_EXPIRY_COOKIE = 'id_expiry_2';
const LOCALSTORAGE_AUTH_KEY = 'auth_2';

interface AuthReducerState {
  currentIdToken: string | null;
  currentExpiry: number | null;
}

function checkForAuthKey() {
  let key = null;
  try {
    key = localStorage.getItem(LOCALSTORAGE_AUTH_KEY);
  } catch (e) {
    // Local storage can get blocked, just return null if it fails.
    // https://sentry.io/organizations/weights-biases/issues/2980180855/?project=1201719
  }
  return key;
}
window.__PREVIOUSLY_LOGGED_IN =
  getCookie(ID_TOKEN_COOKIE) !== '' || checkForAuthKey() != null;

export function getPreviouslyLoggedIn(): boolean {
  return window.__PREVIOUSLY_LOGGED_IN ?? false;
}

// Set cookie path -- this must be consistent in order to be cleared correctly.
// Remove `app.` from host so that cookie is shared between app and api when not local.
// Remove port from cookie so we can actually write one to localhost
let cookieHost = window.location.host.split(':')[0];
if (config.ENVIRONMENT_NAME !== 'local') {
  cookieHost = cookieHost.replace('app.', '');
}
// Only make the cookie secure on HTTPS so it works on the VM over HTTP.
const cookieSecure = window.location.protocol === 'https:' ? 'secure;' : '';
const cookiePath = `domain=${cookieHost};path=/;SameSite=strict;${cookieSecure}`;

// Save the id token to a cookie, for two reasons:
// 1) for jupyter notebook integration, safari doesn't share localStorage
//    between iframes
// 2) for file download links from the browser, headers or localstorage
//    can't be shared, so you need a cookie to send to the server.
function saveAuthCookie(state: AuthReducerState): void {
  const {currentIdToken: idToken, currentExpiry: expiry} = state;
  if (idToken === null || expiry === null) {
    return;
  }
  document.cookie =
    `${ID_TOKEN_COOKIE}=${encodeURIComponent(idToken)};${cookiePath}` +
    `expires=${new Date(expiry).toUTCString()}`;
  document.cookie =
    `${ID_EXPIRY_COOKIE}=${expiry};${cookiePath}` +
    `expires=${new Date(expiry).toUTCString()}`;
}

function clearAuthCookie(): void {
  // You can't clear cookies with a path unless you specify the same path.
  // stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
  const expireNow = 'expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = `${ID_TOKEN_COOKIE}=;${cookiePath}${expireNow}`;
  document.cookie = `${ID_EXPIRY_COOKIE}=;${cookiePath}${expireNow}`;
}

function saveAuthLocalStorage(state: AuthReducerState): void {
  localStorage.setItem(LOCALSTORAGE_AUTH_KEY, JSON.stringify(state));
}

function clearAuthLocalStorage(): void {
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEY);
}

function saveAuthState(state: AuthReducerState): void {
  if (isValid(state)) {
    // Save all auth information to local storage.
    // This should persist beyond page refreshes.
    // Logging out will delete this entirely.
    saveAuthLocalStorage(state);

    // Save auth information in cookie so we're still authenticated outside the app.
    // This is necessary for file downloads, etc.
    saveAuthCookie(state);
  } else {
    clearAuthLocalStorage();
    clearAuthCookie();
  }
}

const emptyState: AuthReducerState = {
  currentIdToken: null,
  currentExpiry: null,
};

export function readCachedAuthState(): AuthReducerState {
  let state = emptyState;

  // Read stub auth jwt if passed in.
  if (config.AUTH_STUB_JWT && config.AUTH_STUB_JWT !== 'false') {
    const stubExpiry = new Date().getTime() + 1095 * 86400 * 1000;
    state = {
      currentIdToken: config.AUTH_STUB_JWT,
      currentExpiry: stubExpiry,
    };
  } else {
    const fromLocalStorage = getStateFromLocalStorage();
    const fromCookies = getStateFromCookies();

    if (fromLocalStorage !== null && fromCookies !== null) {
      state =
        fromLocalStorage.currentExpiry! >= fromCookies.currentExpiry!
          ? fromLocalStorage
          : fromCookies;
    } else if (fromLocalStorage !== null) {
      state = fromLocalStorage;
    } else if (fromCookies !== null) {
      state = fromCookies;
    }
  }

  saveAuthState(state);
  return state;
}

function getStateFromLocalStorage(): AuthReducerState | null {
  const cachedJsonAuthState = localStorage.getItem(LOCALSTORAGE_AUTH_KEY);
  if (cachedJsonAuthState) {
    const parsedState = JSON.parse(cachedJsonAuthState) as AuthReducerState;
    if (isValid(parsedState)) {
      return parsedState;
    }
  }
  return null;
}

function getStateFromCookies(): AuthReducerState | null {
  const state = {
    currentIdToken: getCookie(ID_TOKEN_COOKIE),
    currentExpiry: Number(getCookie(ID_EXPIRY_COOKIE)),
  };
  if (isValid(state)) {
    return state;
  }
  return null;
}
export default function auth(
  state: AuthReducerState = readCachedAuthState(),
  action: AuthAction
): AuthReducerState {
  let newState = state;
  switch (action.type) {
    case getType(AuthActions.setCurrentIdToken):
      newState = {
        ...state,
        currentIdToken: action.payload.idToken,
        currentExpiry: action.payload.expiry,
      };
      break;
    case getType(AuthActions.clearCurrentIdToken):
      newState = {...state, currentIdToken: null, currentExpiry: null};
      break;
  }
  if (newState !== state) {
    saveAuthState(newState);
  }
  return newState;
}

export function authCacheLoggedIn(): boolean {
  return isValid(getStateFromLocalStorage());
}

function isValid(state: AuthReducerState | null): boolean {
  return (
    state?.currentIdToken != null &&
    state.currentIdToken.length > 0 &&
    state?.currentExpiry != null &&
    state.currentExpiry > Date.now()
  );
}
