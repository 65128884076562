// Functions for working with our "views" database table.
// The views table is used to store user-generated "documents",
// ie, any structured data that needs to be persisted. As I
// write this, reports, workspaces and Vega panels are stored
// in views.
//
// We want this to be a generic mechanism that we can use
// for fast frontend development. It's likely that we'll add
// many more types of things here, as the product evolves.
//
// The views database table has the following fields:
//   user_id: id of user who created the view
//   type: a string, that represents the type of object
//     being stored.
//   name: an arbitrary string. Currently used as report
//     and Vega panel names. For workspaces it's either
//     'workspace' or 'default' (potentially postfixed
//     with an ID string).
//   description: arbitrary string.
//   spec: JSON blob. This is where all the data is stored.
//     Fully controlled by the frontend. The backend treats
//     it as opaque.

import {ID} from '@wandb/cg';
import {move} from '@wandb/common/util/data';
import {startTimedPerfEvent} from '@wandb/common/util/profiler';
import * as _ from 'lodash';
import {ActionType as TSActionType, getType} from 'typesafe-actions';

import * as Filter from '../../util/filters';
import {
  addMetricsToPanelConfig,
  getDefaultPanelConfig,
  getDefaultPanelSectionConfig,
  migrateWorkspaceToPanelBank,
  PANEL_BANK_CUSTOM_CHARTS_NAME,
  PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  PANEL_BANK_SYSTEM_NAME,
  PANEL_BANK_TABLES_NAME,
  PanelBankConfigState,
  panelSortingKeyFromPanel,
  SectionPanelSorting,
  updateDefaultxAxis,
} from '../../util/panelbank';
import {
  findNextPanelLoc,
  getNewGridItemLayout,
  GRID_COLUMN_COUNT,
  GRID_ITEM_DEFAULT_HEIGHT,
  GRID_ITEM_DEFAULT_WIDTH,
} from '../../util/panelbankGrid';
import * as PanelsUtil from '../../util/panels';
import {Settings} from '../../util/panelsettings';
import * as ReportUtil from '../../util/report';
import {Key, keyFromString} from '../../util/runs';
import * as SectionUtil from '../../util/section';
import * as SM from '../../util/selectionmanager';
import {immerReducer} from '../reducer';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as CustomRunColorsActions from './customRunColors/actions';
import * as DiscussionCommentActions from './discussionComment/actions';
import * as FilterActions from './filter/actions';
import * as GroupSelectionsActions from './groupSelections/actions';
import * as GroupSelectionsActionsInternal from './groupSelections/actionsInternal';
import * as InteractStateActions from './interactState/actions';
import * as InteractStateTypes from './interactState/types';
import * as MarkdownBlockActions from './markdownBlock/actions';
import * as MultiRunWorkspaceActions from './multiRunWorkspace/actions';
import * as Normalize from './normalize';
// It would probably be nice to write separate reducers for sub-dirs
import * as PanelActions from './panel/actions';
import * as PanelTypes from './panel/types';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import {sortPanelsInternal} from './panelBankSectionConfig/actionsInternal';
import * as PanelBankSectionConfigTypes from './panelBankSectionConfig/types';
import {PanelBankSectionConfigNormalized} from './panelBankSectionConfig/types';
import * as PanelSettingsActions from './panelSettings/actions';
import * as ReportActions from './report/actions';
import * as RunPageActions from './runPage/actions';
import * as RunSetActions from './runSet/actions';
import * as SectionActions from './section/actions';
import * as SortActions from './sort/actions';
import * as TempSelectionsActions from './tempSelections/actions';
import * as TempSelectionsActionsInternal from './tempSelections/actionsInternal';
import * as Types from './types';

export type ActionType = TSActionType<
  | typeof Actions
  | typeof ActionsInternal
  | typeof SortActions
  | typeof FilterActions
  | typeof CustomRunColorsActions
  | typeof GroupSelectionsActions
  | typeof GroupSelectionsActionsInternal
  | typeof MultiRunWorkspaceActions
  | typeof PanelActions
  | typeof PanelBankConfigActions
  | typeof PanelBankConfigActionsInternal
  | typeof PanelBankSectionConfigActions
  | typeof PanelSettingsActions
  | typeof RunPageActions
  | typeof SectionActions
  | typeof MarkdownBlockActions
  | typeof RunSetActions
  | typeof ReportActions
  | typeof TempSelectionsActions
  | typeof TempSelectionsActionsInternal
  | typeof InteractStateActions
  | typeof DiscussionCommentActions
>;

interface ViewList {
  loading: boolean;
  // The metadatalist query that produced this list
  query: Types.LoadMetadataListParams;
  // Pointers to the .views field of the reducer
  viewIds: string[];
}

export interface ViewReducerState {
  // lists are the results of querying the server for lists of views
  lists: {[id: string]: ViewList};
  // any views that are loaded from the server or user created
  // (and not yet saved).
  views: {[id: string]: Types.LoadableView};
  // normalized view specs
  parts: Normalize.StateType;

  undoActions: ActionType[]; // stack of actions that undo past actions
  redoActions: ActionType[]; // stack of actions that undo future actions
}

export function removeHistoryForObject(
  state: ViewReducerState,
  ref: Types.AllPartRefs
) {
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefIDs = new Set(partsWithRef.map(p => p.ref.id));
  const filterActionsForDeletedRefsFn = (undoAction: ActionType) => {
    if ('payload' in undoAction && 'ref' in undoAction.payload) {
      return !subRefIDs.has(undoAction.payload.ref.id);
    }

    return true;
  };
  state.undoActions = state.undoActions.filter(filterActionsForDeletedRefsFn);
  state.redoActions = state.redoActions.filter(filterActionsForDeletedRefsFn);
}

export function deleteParts(state: ViewReducerState, ref: Types.AllPartRefs) {
  if (!Normalize.partExists(state.parts, ref)) {
    return;
  }
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefs = partsWithRef.map(p => p.ref);
  for (const subRef of subRefs) {
    delete state.parts[subRef.type][subRef.id];
  }
}

const movePanelAlphabeticallyKey = (key: string | undefined) => {
  if (key === undefined) {
    return '0';
  } else {
    return '1' + key;
  }
};

function insertPanelAlphabetically(
  state: ViewReducerState,
  normalizedSectionConfig: PanelBankSectionConfigTypes.PanelBankSectionConfigNormalized,
  panel: PanelsUtil.LayedOutPanel | PanelsUtil.LayedOutPanelWithRef,
  panelRef: PanelTypes.Ref
) {
  const panelKey = panelSortingKeyFromPanel(panel);
  const panelKeys = normalizedSectionConfig.panelRefs.map(oldPanelRef => {
    const sectionPanel = Normalize.denormalize(state.parts, oldPanelRef);
    return panelSortingKeyFromPanel(sectionPanel);
  });
  const index = _.sortedIndexBy(
    panelKeys,
    panelKey,
    movePanelAlphabeticallyKey
  );
  normalizedSectionConfig.panelRefs.splice(index, 0, panelRef);
}

function replacePart(
  state: ViewReducerState,
  oldRef: Types.AllPartRefs,
  newRef: Types.AllPartRefs
) {
  // Normalize.addObj adds the object under a newly generated ID.
  // So move it to the requested ID.
  state.parts[oldRef.type][oldRef.id] = state.parts[newRef.type][newRef.id];
  delete state.parts[newRef.type][newRef.id];
}

function movePanelAlphabeticallyInSection(
  state: ViewReducerState,
  normalizedSectionConfig: PanelBankSectionConfigTypes.PanelBankSectionConfigNormalized,
  panelRef: PanelTypes.Ref,
  panel: PanelsUtil.LayedOutPanel
) {
  const panelKeys = normalizedSectionConfig.panelRefs
    .filter(oldPanelRef => oldPanelRef.id !== panelRef.id)
    .map(oldPanelRef => {
      const sectionPanel = Normalize.denormalize(state.parts, oldPanelRef);
      return panelSortingKeyFromPanel(sectionPanel);
    });
  const newPanelIndex = _.sortedIndexBy(
    panelKeys,
    panelSortingKeyFromPanel(panel),
    movePanelAlphabeticallyKey
  );
  const origPanelIndex = normalizedSectionConfig.panelRefs.findIndex(
    sectionPanelRef => sectionPanelRef.id === panelRef.id
  );
  normalizedSectionConfig.panelRefs.splice(origPanelIndex, 1);
  normalizedSectionConfig.panelRefs.splice(newPanelIndex, 0, panelRef);
}

function copyObject(
  state: ViewReducerState,
  fromRef: Types.AllPartRefs,
  ref: Types.AllPartRefs
) {
  const whole = Normalize.denormalize(state.parts, fromRef);
  const addedRef = Normalize.addObj(
    state.parts,
    fromRef.type,
    ref.viewID,
    whole
  );
  if (ref.type !== addedRef.type) {
    throw new Error('invalid action');
  }
  replacePart(state, ref, addedRef);
}

// helper function for addPanel and addPanelWithoutRef
function addPanel(
  state: ViewReducerState,
  panel: PanelsUtil.LayedOutPanelWithRef,
  ref: PanelBankSectionConfigTypes.Ref,
  fatPanel?: boolean,
  callbackFn?: (
    panel: PanelsUtil.LayedOutPanelWithRef,
    newPanelRef: PanelTypes.Ref
  ) => void
) {
  // Add layout for grid sections
  const panelWithLayout = {
    ...panel,
    layout: getNewGridItemLayout(
      Normalize.denormalize(state.parts, ref)
        .panels.map(p => p.layout)
        .filter(l => l),
      fatPanel
    ),
  };
  const newPanelRef = Normalize.addObj(
    state.parts,
    'panel',
    ref.viewID,
    panelWithLayout
  );
  callbackFn?.(panel, newPanelRef);

  const normalizedSectionConfig: PanelBankSectionConfigNormalized =
    state.parts[ref.type][ref.id];

  if (normalizedSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
    insertPanelAlphabetically(
      state,
      normalizedSectionConfig,
      panel,
      newPanelRef
    );
  } else {
    // Inserts at the beginning of section.panels
    normalizedSectionConfig.panelRefs.splice(0, 0, newPanelRef);
  }

  // If section is closed, open it
  if (!normalizedSectionConfig.isOpen) {
    normalizedSectionConfig.isOpen = true;
  }

  return PanelBankSectionConfigActions.deletePanel(ref, newPanelRef);
}

// Internal use, but must be implemented for all undoable actions.
// Should apply action, and return an inverse action (an action that
// does the original action). The inverse action must be inversible
// itself.
//
// Warning: 'state' here is actually an immer draft, which we mutate.
//     We should actually pass both the immutable state, and the immer
//     draft in, so that read actions (like getting previous values)
//     can just access the state. We need to do some cloneDeeps here
//     to ensure we save previous values before a mutation. We could
//     get rid of cloneDeep if we switched to reading from immutable
//     state.
function applyAndMakeInverseAction(
  state: ViewReducerState,
  action: ActionType
) {
  switch (action.type) {
    case getType(Actions.noop): {
      return Actions.noop();
    }
    case getType(SectionActions.addNewRunSet): {
      const {ref} = action.payload;
      const section = state.parts.section[ref.id];
      const runSet =
        section.runSetRefs.length === 0
          ? SectionUtil.emptyReportRunSetSelectAll()
          : SectionUtil.emptyReportRunSetSelectNone();

      const partRef = Normalize.addObj(state.parts, 'runSet', ref.viewID, {
        ...runSet,
        name: `Run set ${section.runSetRefs.length + 1}`,
      });

      section.runSetRefs.push(partRef);
      section.openRunSet = section.runSetRefs.length - 1;
      return SectionActions.removeRunSet(ref, partRef);
    }
    case getType(SectionActions.removeRunSet): {
      const {ref, runSetRef} = action.payload;
      const section = state.parts.section[ref.id];
      const index = _.findIndex(
        section.runSetRefs,
        r => r.viewID === runSetRef.viewID && r.id === runSetRef.id
      );
      if (index === -1) {
        throw new Error('invalid action');
      }
      section.runSetRefs.splice(index, 1);
      if (
        section.openRunSet != null &&
        section.openRunSet >= section.runSetRefs.length
      ) {
        section.openRunSet =
          section.openRunSet === 0 ? undefined : section.runSetRefs.length - 1;
      }
      const runSet = Normalize.denormalize(state.parts, runSetRef);
      removeHistoryForObject(state, runSetRef);
      deleteParts(state, runSetRef);
      return SectionActions.insertRunSet(ref, index, runSet);
    }
    case getType(SectionActions.duplicateRunSet): {
      const {ref, runSetRef} = action.payload;
      const section = state.parts.section[ref.id];
      const sourceRunSet = Normalize.denormalize(state.parts, runSetRef);
      const partRef = Normalize.addObj(state.parts, 'runSet', ref.viewID, {
        ...sourceRunSet,
        id: ID(9),
        name: `Run set ${section.runSetRefs.length + 1}`,
      });
      section.runSetRefs.push(partRef);
      section.openRunSet = section.runSetRefs.length - 1;
      return SectionActions.removeRunSet(ref, partRef);
    }
    case getType(SectionActions.reorderRunSet): {
      const {ref, indexFrom, indexTo} = action.payload;

      const section = state.parts.section[ref.id];

      const openID =
        section.openRunSet != null && section.runSetRefs[section.openRunSet].id;

      section.runSetRefs = move(section.runSetRefs, indexFrom, indexTo);
      // If there is an open section maintain it.
      if (section.openRunSet != null) {
        const idx = section.runSetRefs.findIndex(
          runSetRef => runSetRef.id === openID
        );
        section.openRunSet = idx;
      }

      return SectionActions.reorderRunSet(ref, indexTo, indexFrom);
    }
    case getType(SectionActions.insertRunSet): {
      const {ref, runSet, index} = action.payload;
      const section = state.parts.section[ref.id];

      const runSetRef = Normalize.addObj(
        state.parts,
        'runSet',
        ref.viewID,
        runSet
      );

      section.runSetRefs.splice(index, 0, runSetRef);
      return SectionActions.removeRunSet(ref, runSetRef);
    }
    case getType(SectionActions.setActiveIndex): {
      const {ref, index} = action.payload;
      const section = state.parts.section[ref.id];
      const prevIndex = section.openRunSet;
      section.openRunSet = index;
      return SectionActions.setActiveIndex(ref, prevIndex);
    }
    case getType(SectionActions.setHideRunSets): {
      const {ref, hide} = action.payload;
      const section = state.parts.section[ref.id];
      const prevHide = section.hideRunSets ?? false;
      section.hideRunSets = hide;
      return SectionActions.setHideRunSets(ref, prevHide);
    }
    case getType(SectionActions.setOpen): {
      const {ref, open} = action.payload;
      const section = state.parts.section[ref.id];
      const prevOpen = section.openViz || false;
      section.openViz = open;
      return SectionActions.setOpen(ref, prevOpen);
    }
    case getType(SectionActions.setName): {
      const {ref, name} = action.payload;
      const section = state.parts.section[ref.id];
      const prevName = section.name || '';
      section.name = name;
      return SectionActions.setName(ref, prevName);
    }
    case getType(MarkdownBlockActions.setContent): {
      const {ref, content} = action.payload;
      const block = state.parts['markdown-block'][ref.id];
      if (block == null) {
        return Actions.noop();
      }
      const prevContent = block.content;
      block.content = content;
      return MarkdownBlockActions.setContent(ref, prevContent);
    }
    case getType(MarkdownBlockActions.setCollapsed): {
      const {ref, collapsed} = action.payload;
      const block = state.parts['markdown-block'][ref.id];
      const prevCollapsed = !!block.collapsed;
      block.collapsed = collapsed;
      return MarkdownBlockActions.setCollapsed(ref, prevCollapsed);
    }
    case getType(PanelSettingsActions.set): {
      const {ref, panelSettings} = action.payload;
      const prevSettings = state.parts.panelSettings[ref.id];
      state.parts.panelSettings[ref.id] = panelSettings;
      return PanelSettingsActions.set(ref, prevSettings);
    }
    case getType(PanelSettingsActions.update): {
      const {ref, panelSettingsUpdate} = action.payload;
      const prevSettings = _.cloneDeep(state.parts.panelSettings[ref.id]);
      Object.assign(state.parts.panelSettings[ref.id], panelSettingsUpdate);
      return PanelSettingsActions.set(ref, prevSettings);
    }
    case getType(PanelSettingsActions.setLocalAndWorkspacePanelSettings): {
      const {ref, workspaceRef, localPanelSettings, workspacePanelSettings} =
        action.payload;
      const prevLocalSettings = state.parts.panelSettings[ref.id];
      const prevWorkspaceSettings = state.parts.panelSettings[workspaceRef.id];
      state.parts.panelSettings[ref.id] = localPanelSettings;
      state.parts.panelSettings[workspaceRef.id] = workspacePanelSettings;
      return PanelSettingsActions.setLocalAndWorkspacePanelSettings(
        ref,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(PanelSettingsActions.updateLocalAndWorkspacePanelSettings): {
      const {
        ref,
        workspaceRef,
        localPanelSettingsUpdate,
        workspacePanelSettingsUpdate,
      } = action.payload;
      const prevLocalSettings = _.cloneDeep(state.parts.panelSettings[ref.id]);
      const prevWorkspaceSettings = _.cloneDeep(
        state.parts.panelSettings[workspaceRef.id]
      );
      Object.assign(
        state.parts.panelSettings[ref.id],
        localPanelSettingsUpdate
      );
      Object.assign(
        state.parts.panelSettings[workspaceRef.id],
        workspacePanelSettingsUpdate
      );
      return PanelSettingsActions.setLocalAndWorkspacePanelSettings(
        ref,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(PanelSettingsActions.setAllLocalAndWorkspacePanelSettings): {
      const {refs, workspaceRef, localPanelSettings, workspacePanelSettings} =
        action.payload;
      const prevLocalSettings: Settings[] = [];
      refs.forEach((localRef, index) => {
        prevLocalSettings.push(state.parts.panelSettings[localRef.id]);
        state.parts.panelSettings[localRef.id] = localPanelSettings[index];
      });
      const prevWorkspaceSettings = state.parts.panelSettings[workspaceRef.id];

      state.parts.panelSettings[workspaceRef.id] = workspacePanelSettings;
      return PanelSettingsActions.setAllLocalAndWorkspacePanelSettings(
        refs,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(
      PanelSettingsActions.updateAllLocalAndWorkspacePanelSettings
    ): {
      const {
        refs,
        workspaceRef,
        localPanelSettingsUpdate,
        workspacePanelSettingsUpdate,
      } = action.payload;
      const prevLocalSettings: Settings[] = [];
      refs.forEach(localRef => {
        prevLocalSettings.push(
          _.cloneDeep(state.parts.panelSettings[localRef.id])
        );
        Object.assign(
          state.parts.panelSettings[localRef.id],
          localPanelSettingsUpdate
        );
      });
      const prevWorkspaceSettings = _.cloneDeep(
        state.parts.panelSettings[workspaceRef.id]
      );
      Object.assign(
        state.parts.panelSettings[workspaceRef.id],
        workspacePanelSettingsUpdate
      );
      return PanelSettingsActions.setAllLocalAndWorkspacePanelSettings(
        refs,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(RunSetActions.set): {
      const {ref, runSetNorm} = action.payload;
      const prev = state.parts.runSet[ref.id];
      state.parts.runSet[ref.id] = runSetNorm;
      return RunSetActions.set(action.payload.ref, prev);
    }
    case getType(RunSetActions.update): {
      const {ref, runSetUpdate} = action.payload;
      const prev = _.cloneDeep(state.parts.runSet[ref.id]);
      Object.assign(state.parts.runSet[ref.id], runSetUpdate);
      return RunSetActions.set(action.payload.ref, prev);
    }
    case getType(SortActions.set): {
      const prevSort = state.parts.sort[action.payload.ref.id];
      state.parts.sort[action.payload.ref.id] = action.payload.sort;
      return SortActions.set(action.payload.ref, prevSort);
    }
    case getType(FilterActions.set): {
      const {ref, filters} = action.payload;
      const prevFilters = state.parts.filters[ref.id];
      state.parts.filters[ref.id] = filters;
      return FilterActions.set(action.payload.ref, prevFilters);
    }
    case getType(FilterActions.selectionsToFilters): {
      const {ref, selections, axes} = action.payload;
      const prevFilters = state.parts.filters[ref.id];
      const newFilters: Array<Filter.Filter<Key>> = [];
      let existingFilters = _.cloneDeep(prevFilters);
      // If axes aren't specified, create filters for all selections
      (axes || Object.keys(selections)).forEach(axis => {
        const axisSelect = selections[axis];
        const key = keyFromString(axis);
        ['high', 'low'].forEach(boundary => {
          const value = axisSelect[boundary as 'high' | 'low'];
          if (key != null && value != null) {
            const existingFilterIndex =
              existingFilters.filters[0].filters.findIndex(
                f =>
                  Filter.isIndividual<Key>(f) &&
                  _.isEqual(key, f.key) &&
                  f.op === (boundary === 'low' ? '>=' : '<=')
              );
            // If we already have a filter for this bound, remove it
            if (existingFilterIndex > -1) {
              existingFilters = Filter.Update.groupRemove(
                existingFilters,
                [0],
                existingFilterIndex
              );
            }
            // Add the new filter
            newFilters.push({
              key,
              op: boundary === 'low' ? '>=' : '<=',
              value,
            });
          }
        });
        // Categorical variables (string/boolean columns)
        if (
          key != null &&
          axisSelect.match != null &&
          axisSelect.match.length > 0
        ) {
          newFilters.push({
            key,
            op: 'IN',
            value: axisSelect.match,
          });
        }
      });
      if (newFilters.length > 0) {
        state.parts.filters[ref.id] = Filter.Update.groupPush(
          existingFilters,
          [0],
          newFilters
        );
      }
      return FilterActions.set(ref, prevFilters);
    }
    case getType(CustomRunColorsActions.setCustomRunColor): {
      const {ref, id, color} = action.payload;
      const prevColor = state.parts[ref.type][ref.id][id];
      state.parts[ref.type][ref.id][id] = color;
      return CustomRunColorsActions.setCustomRunColor(ref, id, prevColor);
    }
    case getType(GroupSelectionsActions.setGrouping): {
      const ref = action.payload.ref;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = {
        ...SM.setGrouping(prevGroupSelections, action.payload.grouping),
        expandedRowAddresses: [],
      };

      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.toggleExpandedRowAddress): {
      const ref = action.payload.ref;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = SM.toggleExpandedRowAddress(
        prevGroupSelections,
        action.payload.rowAddress
      );
      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.toggleSelection): {
      const ref = action.payload.ref;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = SM.toggleSelection(
        prevGroupSelections,
        action.payload.run,
        action.payload.depth
      );
      state.parts[ref.type][ref.id] = newGroupSelections;

      // If we're turning on an eyeball, ensure that the runSet is also
      // enabled. Users get confused when the runset is disabled, it's hard to figure
      // out why nothing shows up on charts.
      if (
        SM.getCheckedState(
          newGroupSelections,
          action.payload.run,
          action.payload.depth
        ) === 'checked'
      ) {
        // Iterate through all runsets to find the one that contains this groupSelection
        // ref.
        for (const runSet of Object.values(state.parts.runSet)) {
          if (_.isEqual(runSet.groupSelectionsRef, ref)) {
            runSet.enabled = true;
          }
        }
      }

      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.addBound): {
      const {ref, bound} = action.payload;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = SM.addBound(prevGroupSelections, bound);
      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.selectAll): {
      const ref = action.payload.ref;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = SM.selectAll(prevGroupSelections);
      state.parts[ref.type][ref.id] = newGroupSelections;
      // If the user has chosen to visualize all, ensure the runSet is enabled.
      // Iterate through all runsets to find the one that contains this groupSelection
      // ref.
      for (const runSet of Object.values(state.parts.runSet)) {
        if (_.isEqual(runSet.groupSelectionsRef, ref)) {
          runSet.enabled = true;
        }
      }
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.selectNone): {
      const ref = action.payload.ref;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = SM.selectNone(prevGroupSelections);
      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActionsInternal.setGroupSelections): {
      const ref = action.payload.ref;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      state.parts[ref.type][ref.id] = action.payload.groupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(TempSelectionsActions.selectAllVisible): {
      const {ref, groupSelectionsRef} = action.payload;
      const groupSelections =
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id];
      const prevTempSelections = state.parts[ref.type][ref.id];

      state.parts[ref.type][ref.id] = _.cloneDeep(groupSelections.selections);

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectNone): {
      const {ref, groupSelectionsRef} = action.payload;
      const groupSelections =
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id];
      const prevTempSelections = state.parts[ref.type][ref.id];
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] =
        SM.selectNone(tempGroupSelection).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectToggle): {
      const {ref, groupSelectionsRef, run, depth} = action.payload;
      const groupSelections =
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id];
      const prevTempSelections = state.parts[ref.type][ref.id];
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] = SM.toggleSelection(
        tempGroupSelection,
        run,
        depth
      ).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectSome): {
      const {ref, groupSelectionsRef, runs} = action.payload;
      const groupSelections =
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id];
      const prevTempSelections = state.parts[ref.type][ref.id];
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] = SM.selectSome(
        tempGroupSelection,
        runs
      ).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectAll): {
      const {ref, groupSelectionsRef} = action.payload;
      const groupSelections =
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id];
      const prevTempSelections = state.parts[ref.type][ref.id];
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] =
        SM.selectAll(tempGroupSelection).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActionsInternal.setTempSelections): {
      const {ref, tempSelections} = action.payload;
      const prevTempSelections = state.parts[ref.type][ref.id];

      state.parts[ref.type][ref.id] = tempSelections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(PanelActions.setConfig): {
      const prevPanelConfig = state.parts.panel[action.payload.ref.id].config;
      const prevPanelKey = state.parts.panel[action.payload.ref.id].key;

      state.parts.panel[action.payload.ref.id].config = action.payload.config;
      state.parts.panel[action.payload.ref.id].key = action.payload.key;

      return PanelActions.setConfig(
        action.payload.ref,
        prevPanelConfig,
        prevPanelKey
      );
    }
    case getType(PanelActions.setConfigs): {
      const {refs, configs} = action.payload;
      const prevPanelConfigs = [];
      for (let i = 0; i < refs.length; i++) {
        prevPanelConfigs.push(state.parts.panel[refs[i].id].config);
        state.parts.panel[refs[i].id].config = configs[i];
      }
      return PanelActions.setConfigs(refs, prevPanelConfigs);
    }
    case getType(PanelActions.updateConfig): {
      const {ref, configUpdate} = action.payload;
      const prevPanelConfig = _.cloneDeep(state.parts.panel[ref.id].config);
      const prevPanelKey = state.parts.panel[ref.id].key;

      Object.assign(state.parts.panel[ref.id].config, configUpdate);
      state.parts.panel[ref.id].key = undefined;

      return PanelActions.setConfig(
        action.payload.ref,
        prevPanelConfig,
        prevPanelKey
      );
    }
    case getType(PanelActions.updateConfigs): {
      const {refs, configUpdate} = action.payload;
      const prevPanelConfigs = [];
      for (const ref of refs) {
        prevPanelConfigs.push(_.cloneDeep(state.parts.panel[ref.id].config));
        Object.assign(state.parts.panel[ref.id].config, configUpdate);
      }
      return PanelActions.setConfigs(action.payload.refs, prevPanelConfigs);
    }
    case getType(PanelBankConfigActions.addSection): {
      // sectionRef is the existing section that you're inserting before or after
      const {ref, sectionRef, options} = action.payload;
      const newSectionRef =
        PanelBankConfigActionsInternal.addPanelBankSectionInternal(
          state,
          ref,
          sectionRef,
          options || {}
        );
      return PanelBankConfigActions.deleteSection(ref, newSectionRef);
    }
    case getType(PanelBankConfigActions.deleteSection): {
      // sectionRef is the section you're deleting
      const {ref, sectionRef} = action.payload;
      const undoAction =
        PanelBankConfigActionsInternal.deletePanelBankSectionInternal(
          state,
          ref,
          sectionRef
        );
      return undoAction;
    }
    case getType(PanelBankConfigActionsInternal.putSection): {
      // This internal action is the inverse of deleteSection.
      const {
        ref,
        sectionRef,
        sectionNorm,
        prevIndex,
        panelsToCreate,
        hiddenPanelsToMove,
        localPanelSettings,
        localPanelSettingsRef,
      } = action.payload;
      // Add section ref back to config.
      state.parts[ref.type][ref.id].sectionRefs.splice(
        prevIndex,
        0,
        sectionRef
      );
      // Put panel settings back in
      state.parts[localPanelSettingsRef.type][localPanelSettingsRef.id] =
        localPanelSettings;
      // Put normalized section back in parts.
      state.parts[sectionRef.type][sectionRef.id] = sectionNorm;

      // Create panels in panelsToCreate.
      const createdPanelsRef = panelsToCreate.map(p => ({
        ...Normalize.addObj(state.parts, 'panel', sectionRef.viewID, p),
        seqNum: p.seqNum,
      }));
      // Sort refs into original order.
      const sortedRefs = [...hiddenPanelsToMove, ...createdPanelsRef].sort(
        (a, b) => a.seqNum - b.seqNum
      );
      // Put 'em into the restored section.
      sectionNorm.panelRefs = sortedRefs;

      // Now move the hidden panels
      const hiddenPanelsToMoveIDs = new Set(hiddenPanelsToMove.map(p => p.id));
      const hiddenSectionRef =
        state.parts[ref.type][ref.id].sectionRefs.slice(-1)[0];
      state.parts[hiddenSectionRef.type][hiddenSectionRef.id].panelRefs =
        state.parts[hiddenSectionRef.type][
          hiddenSectionRef.id
        ].panelRefs.filter(pr => !hiddenPanelsToMoveIDs.has(pr.id));

      return PanelBankConfigActions.deleteSection(ref, sectionRef);
    }
    case getType(PanelBankConfigActions.moveSectionBefore): {
      const {ref, moveSectionRef, beforeSectionRef} = action.payload;
      const moveSectionIndex = state.parts[ref.type][
        ref.id
      ].sectionRefs.findIndex(sr => sr.id === moveSectionRef.id);
      // Used to undo the action
      const nextSectionRef =
        state.parts[ref.type][ref.id].sectionRefs[moveSectionIndex + 1];
      // Remove the section
      state.parts[ref.type][ref.id].sectionRefs.splice(moveSectionIndex, 1);
      if (beforeSectionRef == null) {
        // Moving to the last position in the array
        state.parts[ref.type][ref.id].sectionRefs.splice(
          state.parts[ref.type][ref.id].sectionRefs.length - 1, // (-1 accounts for the 'Hidden Panels' section)
          0,
          moveSectionRef
        );
      } else {
        const beforeSectionIndex = state.parts[ref.type][
          ref.id
        ].sectionRefs.findIndex(sr => sr.id === beforeSectionRef.id);
        // // TODO(views): is this right? how do you cancel an action?
        // if (moveSectionIndex === beforeSectionIndex - 1) {
        //   return
        // }
        // Re-add it in the new index
        state.parts[ref.type][ref.id].sectionRefs.splice(
          beforeSectionIndex,
          0,
          moveSectionRef
        );
      }
      return PanelBankConfigActions.moveSectionBefore(
        ref,
        moveSectionRef,
        nextSectionRef
      );
    }
    case getType(PanelBankConfigActions.updateSettings): {
      const {ref, panelBankSettings} = action.payload;
      const prev = state.parts[ref.type][ref.id].settings;
      Object.assign(state.parts[ref.type][ref.id].settings, panelBankSettings);
      return PanelBankConfigActions.updateSettings(ref, prev);
    }
    case getType(PanelBankConfigActions.updateSettingsAndSortPanels): {
      const {
        ref,
        args: {panelBankSettings, sortAllSections},
      } = action.payload;
      // update settings
      const prev = state.parts[ref.type][ref.id].settings;
      Object.assign(state.parts[ref.type][ref.id].settings, panelBankSettings);
      const sectionRefs = state.parts[ref.type][ref.id].sectionRefs;
      const sectionRefsToSort = sortAllSections
        ? sectionRefs
        : sectionRefs.filter(
            sectionRef =>
              state.parts[sectionRef.type][sectionRef.id].sorted !==
              SectionPanelSorting.Manual
          );
      // sort panels
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      sectionRefsToSort.forEach(sectionRef => {
        const panelRefs = state.parts[sectionRef.type][sectionRef.id].panelRefs;
        prevPanelRefs.push(panelRefs);
        sortPanelsInternal(state, sectionRef, panelRefs);
      });
      return PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels(
        ref,
        prev,
        sectionRefsToSort,
        prevPanelRefs
      );
    }
    case getType(
      PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels
    ): {
      const {ref, panelBankSettings, sectionRefs, panelRefs} = action.payload;
      const prev = state.parts[ref.type][ref.id].settings;
      Object.assign(state.parts[ref.type][ref.id].settings, panelBankSettings);
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      sectionRefs.forEach((sectionRef, index) => {
        prevPanelRefs.push(
          state.parts[sectionRef.type][sectionRef.id].panelRefs
        );
        state.parts[sectionRef.type][sectionRef.id].panelRefs =
          panelRefs[index];
      });
      return PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels(
        ref,
        prev,
        sectionRefs,
        prevPanelRefs
      );
    }
    // Only used for development (to stub undo actions)
    case getType(PanelBankConfigActions.noOp): {
      const {ref} = action.payload;
      return PanelBankConfigActions.noOp(ref);
    }
    case getType(PanelBankSectionConfigActions.addPanelWithoutRef): {
      const {ref, panel, fatPanel, callbackFn} = action.payload;
      return addPanel(state, panel, ref, fatPanel, callbackFn);
    }
    case getType(PanelBankSectionConfigActions.addPanel): {
      const {ref, panelRef, fatPanel, callbackFn} = action.payload;
      const panel = Normalize.denormalize(state.parts, panelRef);
      return addPanel(state, panel, ref, fatPanel, callbackFn);
    }
    case getType(PanelBankSectionConfigActions.duplicatePanel): {
      const {ref, panelRef} = action.payload;
      const panel = Normalize.denormalize(state.parts, panelRef);
      const panelIndex = state.parts[ref.type][ref.id].panelRefs.findIndex(
        pRef => pRef.id === panelRef.id
      );
      const clonedPanel = {
        ..._.cloneDeep(panel),
        __id__: ID(),
      };
      const newPanelRef = Normalize.addObj(
        state.parts,
        'panel',
        ref.viewID,
        clonedPanel
      );
      state.parts[ref.type][ref.id].panelRefs.splice(
        panelIndex,
        0,
        newPanelRef
      );
      return PanelBankSectionConfigActions.deletePanel(ref, newPanelRef);
    }
    case getType(PanelBankSectionConfigActions.deletePanel): {
      const {ref, panelRef, panelBankConfigRef} = action.payload;
      const panel = Normalize.denormalize(state.parts, panelRef);

      // Remove the panelRef from the panelbank section
      const panelIndex = state.parts[ref.type][ref.id].panelRefs.findIndex(
        pRef => pRef.id === panelRef.id
      );
      state.parts[ref.type][ref.id].panelRefs.splice(panelIndex, 1);

      if (panelBankConfigRef != null) {
        // If it's a basic panel (single key) and another section (including Hidden Panels) doesn't already contain the key,
        // move to Hidden Panels.  Otherwise, delete the panel completely
        const key = PanelsUtil.getKey(panel);
        if (key) {
          const existingKeys = _.flatten(
            Normalize.denormalize(state.parts, panelBankConfigRef).sections.map(
              s => s.panels
            )
          ).map(PanelsUtil.getKey);
          if (!_.includes(existingKeys, key)) {
            // NOTE: this assumes Hidden Panels is always the last section
            const hiddenSectionRef =
              state.parts[panelBankConfigRef.type][
                panelBankConfigRef.id
              ].sectionRefs.slice(-1)[0];
            // Add to Hidden Panels
            state.parts[hiddenSectionRef.type][hiddenSectionRef.id].panelRefs =
              [
                panelRef,
                ...state.parts[hiddenSectionRef.type][hiddenSectionRef.id]
                  .panelRefs,
              ];
            return PanelBankConfigActionsInternal.putPanel(
              panelRef,
              panelIndex,
              ref,
              undefined,
              panelBankConfigRef
            );
          }
        }
      }
      // Set up undo action.
      const undoAction = PanelBankConfigActionsInternal.putPanel(
        panelRef,
        panelIndex,
        ref,
        panel,
        panelBankConfigRef
      );
      // Delete the panel completely
      removeHistoryForObject(state, panelRef);
      deleteParts(state, panelRef);

      return undoAction;
    }
    case getType(PanelBankConfigActionsInternal.putPanel): {
      const {panelRef, prevIndex, sectionRef, panel, panelBankConfigRef} =
        action.payload;
      if (panelBankConfigRef != null && panel == null) {
        // This is a ref, so the actual panel is still around in the hidden section. Remvove it
        // from the hidden section.
        const hiddenSectionRef =
          state.parts[panelBankConfigRef.type][
            panelBankConfigRef.id
          ].sectionRefs.slice(-1)[0];
        const hiddenSection =
          state.parts[hiddenSectionRef.type][hiddenSectionRef.id];
        hiddenSection.panelRefs = hiddenSection.panelRefs.filter(
          pr => pr.id !== panelRef.id
        );
      } else if (panel != null) {
        // Put new panel in redux.
        const addedPanelRef = Normalize.addObj(
          state.parts,
          'panel',
          (panelBankConfigRef || sectionRef).viewID,
          panel
        );
        replacePart(state, panelRef, addedPanelRef);
      }

      // Move panel ref back into the right section.
      state.parts[sectionRef.type][sectionRef.id].panelRefs.splice(
        prevIndex,
        0,
        panelRef
      );

      return PanelBankSectionConfigActions.deletePanel(
        sectionRef,
        panelRef,
        panelBankConfigRef
      );
    }
    // Create a new section and move a panel to it in one shot
    case getType(PanelBankConfigActions.movePanelToNewSection): {
      const {ref, args} = action.payload;
      const {fromSectionRef, panelRef, newSectionName} = args;

      const newSectionRef =
        PanelBankConfigActionsInternal.addPanelBankSectionInternal(
          state,
          ref,
          fromSectionRef,
          {newSectionName}
        );
      const panelRefs =
        state.parts[fromSectionRef.type][fromSectionRef.id].panelRefs;
      const fromIndex = panelRefs.findIndex(pRef => pRef.id === panelRef.id);

      PanelBankConfigActionsInternal.movePanelInternal(state, {
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef: newSectionRef,
        toIndex: 0,
      });

      return PanelBankConfigActionsInternal.undoMovePanelToNewSection(
        ref,
        panelRef,
        newSectionRef,
        fromSectionRef,
        fromIndex,
        newSectionName
      );
    }

    case getType(PanelBankConfigActionsInternal.undoMovePanelToNewSection): {
      const {
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
        newSectionName,
      } = action.payload;
      PanelBankConfigActionsInternal.movePanelInternal(state, {
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
      });
      PanelBankConfigActionsInternal.deletePanelBankSectionInternal(
        state,
        ref,
        fromSectionRef
      );
      return PanelBankConfigActions.movePanelToNewSection(ref, {
        panelRef,
        fromSectionRef: toSectionRef,
        newSectionName,
      });
    }
    case getType(PanelBankConfigActions.movePanel): {
      const {ref, panelRef, fromSectionRef, toSectionRef, inactivePanelRefIDs} =
        action.payload;
      const panelRefs =
        state.parts[fromSectionRef.type][fromSectionRef.id].panelRefs;
      // If toIndex is not specified, add panel to the end of the list
      const toIndex =
        action.payload.toIndex == null
          ? state.parts[toSectionRef.type][toSectionRef.id].panelRefs.length
          : action.payload.toIndex;
      // The panel's index in the fromSection
      const fromIndex = panelRefs.findIndex(pRef => pRef.id === panelRef.id);

      PanelBankConfigActionsInternal.movePanelInternal(state, {
        ref,
        fromSectionRef,
        panelRef,
        toSectionRef,
        toIndex,
        inactivePanelRefIDs,
      });
      return PanelBankConfigActions.movePanel(
        ref,
        panelRef,
        toSectionRef,
        fromSectionRef,
        fromIndex
      );
    }
    case getType(PanelBankSectionConfigActions.toggleType): {
      const {ref} = action.payload;
      const panelRefs = state.parts[ref.type][ref.id].panelRefs;
      state.parts[ref.type][ref.id].type =
        state.parts[ref.type][ref.id].type === 'grid' ? 'flow' : 'grid';
      // If we're switching to grid type, add layout to any panels that don't already have it
      if (state.parts[ref.type][ref.id].type === 'grid') {
        const panels = Normalize.denormalize(state.parts, ref).panels;
        panels.forEach((panel, i) => {
          if (panel.layout == null) {
            const panelRef = panelRefs[i];
            state.parts[panelRef.type][panelRef.id].layout = {
              ...findNextPanelLoc(
                Normalize.denormalize(state.parts, ref)
                  .panels.map(p => p.layout)
                  .filter(l => l),
                GRID_COLUMN_COUNT,
                GRID_ITEM_DEFAULT_WIDTH
              ),
              w: GRID_ITEM_DEFAULT_WIDTH,
              h: GRID_ITEM_DEFAULT_HEIGHT,
            };
          }
        });
      }
      return PanelBankSectionConfigActions.toggleType(ref);
    }
    case getType(PanelBankSectionConfigActions.toggleIsOpen): {
      const {ref} = action.payload;
      state.parts[ref.type][ref.id].isOpen =
        !state.parts[ref.type][ref.id].isOpen;
      return PanelBankSectionConfigActions.toggleIsOpen(ref);
    }
    case getType(PanelBankSectionConfigActions.updateName): {
      const {ref, newName} = action.payload;
      const prev = state.parts[ref.type][ref.id].name;
      state.parts[ref.type][ref.id].name = newName;
      return PanelBankSectionConfigActions.updateName(ref, prev);
    }
    case getType(PanelBankSectionConfigActions.updateFlowConfig): {
      const {ref, newFlowConfig} = action.payload;
      const prev = state.parts[ref.type][ref.id].flowConfig;
      state.parts[ref.type][ref.id].flowConfig = {
        ...state.parts[ref.type][ref.id].flowConfig,
        ...newFlowConfig,
      };
      return PanelBankSectionConfigActions.updateFlowConfig(ref, prev);
    }
    case getType(PanelBankSectionConfigActions.setGridLayout): {
      const {ref, newGridLayout} = action.payload;
      const prevGridLayout = Normalize.denormalize(state.parts, ref).panels.map(
        (p, i) => ({
          ...p.layout,
          id: state.parts[ref.type][ref.id].panelRefs[i].id,
        })
      );
      const panelRefs = state.parts[ref.type][ref.id].panelRefs;
      panelRefs.forEach(panelRef => {
        const newLayoutIndex = newGridLayout.findIndex(
          l => l.id === panelRef.id
        );
        if (newLayoutIndex > -1) {
          const l = newGridLayout[newLayoutIndex];
          state.parts.panel[panelRef.id] = {
            ...state.parts.panel[panelRef.id],
            layout: {
              x: l.x,
              y: l.y,
              w: l.w,
              h: l.h,
            },
          };
        }
      });

      return PanelBankSectionConfigActions.setGridLayout(ref, prevGridLayout);
    }
    // Sorts panels within a section
    case getType(PanelBankSectionConfigActions.sortPanels): {
      const refs = action.payload;
      const prevSorting: SectionPanelSorting[] = [];
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      refs.forEach(ref => {
        const panelRefs = state.parts[ref.type][ref.id].panelRefs;
        prevPanelRefs.push(panelRefs);
        prevSorting.push(state.parts[ref.type][ref.id].sorted);
        sortPanelsInternal(state, ref, panelRefs);
      });
      return PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting(
        refs,
        prevPanelRefs,
        prevSorting
      );
    }
    case getType(
      PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting
    ): {
      const {refs, orderedPanelRefs, sectionSortings} = action.payload;
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      const prevSorting: SectionPanelSorting[] = [];
      refs.forEach((ref, index) => {
        prevPanelRefs.push(state.parts[ref.type][ref.id].panelRefs);
        prevSorting.push(state.parts[ref.type][ref.id].sorted);

        state.parts[ref.type][ref.id].sorted = sectionSortings[index];
        state.parts[ref.type][ref.id].panelRefs = orderedPanelRefs[index];
      });

      return PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting(
        refs,
        prevPanelRefs,
        prevSorting
      );
    }
    case getType(PanelBankSectionConfigActions.insertUpdatedPanel): {
      const {ref, fromPanelRef, panelRef} = action.payload;
      const whole = Normalize.denormalize(state.parts, fromPanelRef);
      const oldPanel = Normalize.denormalize(state.parts, panelRef);
      const normalizedSectionConfig: PanelBankSectionConfigNormalized =
        state.parts[ref.type][ref.id];
      const addedRef = Normalize.addObj(
        state.parts,
        fromPanelRef.type,
        panelRef.viewID,
        whole
      );
      if (fromPanelRef.type !== addedRef.type) {
        throw new Error('invalid action');
      }
      if (normalizedSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
        movePanelAlphabeticallyInSection(
          state,
          normalizedSectionConfig,
          panelRef,
          whole
        );
      }
      replacePart(state, panelRef, addedRef);

      return PanelBankSectionConfigActions.undoInsertUpdatedPanel(
        ref,
        oldPanel,
        panelRef
      );
    }
    case getType(PanelBankSectionConfigActions.undoInsertUpdatedPanel): {
      const {ref, panel, panelRef} = action.payload;
      const oldPanel = Normalize.denormalize(state.parts, panelRef);
      const normalizedSectionConfig: PanelBankSectionConfigNormalized =
        state.parts[ref.type][ref.id];
      const addedRef = Normalize.addObj(
        state.parts,
        panelRef.type,
        panelRef.viewID,
        panel
      );
      if (normalizedSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
        movePanelAlphabeticallyInSection(
          state,
          normalizedSectionConfig,
          panelRef,
          panel
        );
      }
      replacePart(state, panelRef, addedRef);

      return PanelBankSectionConfigActions.undoInsertUpdatedPanel(
        ref,
        oldPanel,
        panelRef
      );
    }
    case getType(ReportActions.setWidth): {
      const {ref, width} = action.payload;
      const prevWidth = state.parts[ref.type][ref.id].width;
      state.parts[ref.type][ref.id].width = width;
      return ReportActions.setWidth(ref, prevWidth);
    }
    case getType(ReportActions.setSpecVersion): {
      const {ref, specVersion} = action.payload;
      const prevVersion =
        state.parts[ref.type][ref.id].version ||
        ReportUtil.ReportSpecVersion.V0;
      state.parts[ref.type][ref.id].version = specVersion;
      return ReportActions.setSpecVersion(ref, prevVersion);
    }
    case getType(ReportActions.addAuthor): {
      const {ref, author} = action.payload;
      const prevAuthors = state.parts[ref.type][ref.id].authors ?? [];
      if (!prevAuthors.some(a => a.username === author.username)) {
        state.parts[ref.type][ref.id].authors = [...prevAuthors, author];
      }
      return ReportActions.removeAuthor(ref, author);
    }
    case getType(ReportActions.removeAuthor): {
      const {ref, author} = action.payload;
      const prevAuthors = state.parts[ref.type][ref.id].authors ?? [];
      const newAuthors = prevAuthors.filter(
        a => a.username !== author.username
      );
      state.parts[ref.type][ref.id].authors = newAuthors;
      return ReportActions.addAuthor(ref, author);
    }
    case getType(ReportActions.setBlocks): {
      const {ref, blocks} = action.payload;
      const prevBlocks = state.parts[ref.type][ref.id].blocks;
      state.parts[ref.type][ref.id].blocks = blocks;
      return ReportActions.setBlocks(ref, prevBlocks);
    }
    case getType(Actions.rename): {
      const {ref, name} = action.payload;
      const prevName = state.views[ref.id].displayName;
      state.views[ref.id].displayName = name;
      return Actions.rename(ref, prevName);
    }
    case getType(Actions.setDescription): {
      const {ref, description} = action.payload;
      const prevDescription = state.views[ref.id].description;
      state.views[ref.id].description = description;
      return Actions.setDescription(ref, prevDescription);
    }
    case getType(Actions.setPreviewUrl): {
      const {ref, previewUrl} = action.payload;
      const prevPreviewUrl = state.views[ref.id].previewUrl;
      state.views[ref.id].previewUrl = previewUrl;
      return Actions.setPreviewUrl(ref, prevPreviewUrl);
    }
    case getType(Actions.setCoverUrl): {
      const {ref, coverUrl} = action.payload;
      const prevCoverUrl = state.views[ref.id].coverUrl;
      state.views[ref.id].coverUrl = coverUrl;
      return Actions.setCoverUrl(ref, prevCoverUrl);
    }
  }
  throw new Error('Action not undoable');
}

// Should be called from the main reducer for all undoable actions.
function applyUndoableAction(state: ViewReducerState, action: ActionType) {
  const inverseAction = applyAndMakeInverseAction(state, action);
  state.undoActions.push(inverseAction);
  state.redoActions = [];
}

function views(draft: ViewReducerState, action: ActionType) {
  const endPerfEvent = startTimedPerfEvent(
    `view reducer, action: ${action.type}`
  );
  try {
    switch (action.type) {
      case getType(Actions.loadMetadataListStarted): {
        draft.lists[action.payload.id] = {
          loading: true,
          query: action.payload.params,
          viewIds: [],
        };
        break;
      }

      case getType(ActionsInternal.loadMetadataListFinished): {
        const query = draft.lists[action.payload.id];
        if (!query) {
          // Happens if we unload before the metadatalist query finishes
          return;
        }
        query.loading = false;
        query.viewIds = action.payload.result.map(v => v.cid);
        for (const v of action.payload.result) {
          draft.views[v.cid] = {
            ...v,
            autoSave: false,
            saving: false,
            modified: false,
            loading: false,
            starLoading: false,
            panelCommentsEnabled: false,
          };
        }
        break;
      }

      case getType(ActionsInternal.loadStarted): {
        const viewId = action.payload.id;
        const view = draft.views[viewId];
        if (view == null) {
          throw new Error('Loading view without prior metadata');
        }
        view.loading = true;

        // Clear undo/redo state
        draft.redoActions = [];
        draft.undoActions = [];

        break;
      }

      case getType(ActionsInternal.loadFinished): {
        const viewId = action.payload.id;
        const view = action.payload.result;
        const autoSave = action.payload.autoSave;

        const stateView = draft.views[viewId];
        if (stateView != null && stateView.type !== view.type) {
          throw new Error('View type change');
        }

        let spec = view.spec;

        let panelCommentsEnabled = false;

        // the "runs" view type is actually reports
        if (view.type === 'runs' || view.type === 'runs/draft') {
          // We only enable panel comments if panels already have persistent IDs *before* the fromJSON migrations run.
          // This is because a) each comment needs to be associated with a persistent panel ID,
          // and b) we can only add/save panel IDs in report edit mode (since we can't write IDs to the view spec in read mode).
          // This has the unfortunate effect of disabling panel comments when viewing an older report (until the author re-saves the report),
          // but we decided this is preferable to doing a server-side view spec migration to add panel IDs.
          if (
            view.type === 'runs' &&
            spec.version != null &&
            spec.version >= ReportUtil.ReportSpecVersion.AddPanelIds
          ) {
            panelCommentsEnabled = true;
          }
          spec = ReportUtil.fromJSON(spec);
        } else if (
          view.type === 'project-view' ||
          view.type === 'sweep-view' ||
          view.type === 'group-view'
        ) {
          spec = {
            ...spec,
            section: ReportUtil.sectionFromJSON(spec.section),
          };
        } else if (view.type === 'run-view') {
          const panels = PanelsUtil.configFromJSON(spec.panels);
          const panelBankConfig = migrateWorkspaceToPanelBank(
            spec.panelBankConfig,
            panels
          );

          spec = {
            ...spec,
            panels,
            panelBankConfig,
          };
        }

        // Store normalized result
        const partRef = Normalize.addObj(draft.parts, view.type, viewId, spec, {
          // We just parsed spec from javascript, so its definitely new (and safe
          // for addObj to freeze as a performance optimization)
          wholeIsDefinitelyNew: true,
        });

        // Store raw result
        draft.views[viewId] = {
          ..._.omit(action.payload.result, 'spec'),
          loading: false,
          saving: false,
          modified: false,
          starLoading: false,
          autoSave,
          // relies on normalize returning top-level view as the first result.
          // TODO: had to cast to any in a hurry
          partRef,
          panelCommentsEnabled,
        };

        break;
      }

      case getType(ActionsInternal.addNormalizedPanelGrid): {
        const {partsWithRefs} = action.payload;

        for (const {ref, part} of partsWithRefs) {
          draft.parts[ref.type as keyof Normalize.StateType][ref.id] = part;
        }

        break;
      }

      case getType(ActionsInternal.removeNormalizedPanelGrid): {
        const {sectionRef} = action.payload;

        deleteParts(draft, sectionRef);

        break;
      }

      case getType(ActionsInternal.unloadMetadataList): {
        const viewListID = action.payload.id;
        delete draft.lists[viewListID];
        break;
      }

      case getType(ActionsInternal.unloadView): {
        const viewID = action.payload.id;
        delete draft.views[viewID];
        break;
      }

      case getType(ActionsInternal.updateViewSpec): {
        const viewId = action.payload.cid;
        const spec = action.payload.spec;

        // Store normalized result
        const stateView = draft.views[viewId];
        const partRef = Normalize.addObj(
          draft.parts,
          stateView.type,
          viewId,
          spec,
          {wholeIsDefinitelyNew: true}
        );

        // Store raw result
        if (stateView == null) {
          throw new Error('invalid view state');
        }
        stateView.partRef = partRef;

        break;
      }

      case getType(ActionsInternal.deleteUndoRedoHistory): {
        draft.undoActions = [];
        draft.redoActions = [];
        break;
      }

      case getType(Actions.undo): {
        const undoableAction = draft.undoActions.pop();
        if (undoableAction != null) {
          const redoAction = applyAndMakeInverseAction(draft, undoableAction);
          draft.redoActions.push(redoAction);
        }
        break;
      }

      case getType(Actions.redo): {
        const undoableAction = draft.redoActions.pop();
        if (undoableAction != null) {
          const undoAction = applyAndMakeInverseAction(draft, undoableAction);
          draft.undoActions.push(undoAction);
        }
        break;
      }

      case getType(Actions.copyObject): {
        const {fromRef, ref} = action.payload;
        copyObject(draft, fromRef, ref);
        break;
      }

      case getType(Actions.addObject): {
        const {wholeAndType, ref} = action.payload;
        const addedRef = Normalize.addObj(
          draft.parts,
          wholeAndType.type,
          ref.viewID,
          wholeAndType.whole
        );
        if (ref.type !== addedRef.type) {
          throw new Error('invalid action');
        }
        replacePart(draft, ref, addedRef);
        break;
      }

      case getType(ActionsInternal.deleteObject): {
        const {ref} = action.payload;
        deleteParts(draft, ref);
        break;
      }

      case getType(ActionsInternal.deleteHistoryForObject): {
        const {ref} = action.payload;
        removeHistoryForObject(draft, ref);
        break;
      }

      case getType(RunSetActions.visualizeAllIfNoneVisualized): {
        const {ref} = action.payload;
        const runSetPart = Normalize.lookupPart(draft.parts, ref);
        const groupSelectionsPart = Normalize.lookupPart(
          draft.parts,
          runSetPart.groupSelectionsRef
        );
        if (SM.isNoneSelected(groupSelectionsPart)) {
          SM.selectAllMutate(groupSelectionsPart);
        }
        break;
      }

      case getType(ActionsInternal.markModified): {
        draft.views[action.payload.id].modified = true;
        break;
      }

      case getType(ActionsInternal.saveStarted): {
        draft.views[action.payload.id].saving = true;
        break;
      }

      case getType(ActionsInternal.saveFailed): {
        // Note that the UI isn't well tested after we've
        // had a sync failure.
        const {id} = action.payload;
        draft.views[id].saving = false;
        break;
      }

      case getType(ActionsInternal.saveFinished): {
        const {id, result} = action.payload;
        draft.views[id] = {
          ...draft.views[id],

          // we have to update these values from the server
          updatedAt: result.updatedAt,
          updatedBy: result.updatedBy,
          previewUrl: result.previewUrl,
          coverUrl: result.coverUrl,

          saving: false,
          modified: false,
        };
        break;
      }

      case getType(ActionsInternal.starViewStarted): {
        const {id} = action.payload;
        draft.views[id].starLoading = true;
        break;
      }

      case getType(ActionsInternal.starViewFinished): {
        const {id, starCount} = action.payload;
        draft.views[id].starLoading = false;
        draft.views[id].starCount = starCount;
        draft.views[id].starred = true;
        break;
      }

      case getType(ActionsInternal.unstarViewStarted): {
        const {id} = action.payload;
        draft.views[id].starLoading = true;
        break;
      }

      case getType(ActionsInternal.unstarViewFinished): {
        const {id, starCount} = action.payload;
        draft.views[id].starLoading = false;
        draft.views[id].starCount = starCount;
        draft.views[id].starred = false;
        break;
      }

      case getType(Actions.setLocked): {
        const {ref, locked} = action.payload;
        draft.views[ref.id].locked = locked;
        break;
      }

      case getType(Actions.addAccessToken): {
        const {ref, accessToken} = action.payload;
        const v = draft.views[ref.id];
        if (v.accessTokens == null) {
          v.accessTokens = [];
        }
        v.accessTokens.push(accessToken);
        break;
      }

      case getType(Actions.updateAccessToken): {
        const {ref, accessToken} = action.payload;
        const view = draft.views[ref.id];
        if (view.accessTokens == null) {
          view.accessTokens = [];
        }
        view.accessTokens = [
          ...view.accessTokens.filter(t => t.token !== accessToken.token),
          accessToken,
        ];
        break;
      }

      case getType(Actions.removeAccessToken): {
        const {ref, token} = action.payload;
        const v = draft.views[ref.id];
        if (v.accessTokens != null) {
          v.accessTokens = v.accessTokens.filter(at => at.token !== token);
        }
        break;
      }

      case getType(ReportActions.deleteDiscussionComment): {
        const {discussionThreadRef, discussionCommentRef} =
          action.payload.params;
        // Remove the comment from the thread
        const discussionThread =
          draft.parts[discussionThreadRef.type][discussionThreadRef.id];
        const commentIndex = _.findIndex(
          discussionThread.commentRefs,
          discussionCommentRef
        );
        if (commentIndex === -1) {
          throw new Error('invalid action');
        }
        discussionThread.commentRefs.splice(commentIndex, 1);

        // Delete the comment
        removeHistoryForObject(draft, discussionCommentRef);
        deleteParts(draft, discussionCommentRef);
        break;
      }

      case getType(ReportActions.deleteDiscussionThread): {
        const {ref, params} = action.payload;
        const {discussionThreadRef} = params;

        // Remove the thread from the report
        const report = draft.parts[ref.type][ref.id];
        const threadIndex = _.findIndex(
          report.discussionThreadRefs,
          discussionThreadRef
        );
        if (threadIndex === -1) {
          throw new Error('invalid action');
        }
        report.discussionThreadRefs.splice(threadIndex, 1);

        // Delete the thread
        removeHistoryForObject(draft, discussionThreadRef);
        deleteParts(draft, discussionThreadRef);
        break;
      }

      case getType(ReportActions.loadDiscussionThreads): {
        const {ref, response} = action.payload;
        const reportPart = draft.parts[ref.type][ref.id];
        const existingThreadRefs = reportPart.discussionThreadRefs;
        const newThreadRefs = [];
        let existingThreadI = 0;
        for (const responseThread of response.discussionThreads) {
          // if the discussion thread ref already exists, don't replace it with a new one
          const existingThreadRef = existingThreadRefs[existingThreadI];
          if (existingThreadRef != null) {
            const existingThreadPart =
              draft.parts['discussion-thread'][existingThreadRef.id];
            if (existingThreadPart.id === responseThread.id) {
              const existingCommentRefs = existingThreadPart.commentRefs;
              const newCommentRefs = [];
              let existingCommentI = 0;
              for (const responseComment of responseThread.comments) {
                const existingCommentRef =
                  existingCommentRefs[existingCommentI];
                if (existingCommentRef != null) {
                  const existingCommentPart =
                    draft.parts['discussion-comment'][existingCommentRef.id];
                  if (existingCommentPart.id === responseComment.id) {
                    draft.parts['discussion-comment'][existingCommentRef.id] =
                      responseComment;
                    newCommentRefs.push(existingCommentRef);
                    existingCommentI++;
                    continue;
                  }
                }
                newCommentRefs.push(
                  Normalize.addObj(
                    draft.parts,
                    'discussion-comment',
                    ref.viewID,
                    responseComment
                  )
                );
              }

              draft.parts['discussion-thread'][existingThreadRef.id] = {
                ..._.omit(responseThread, 'comments'),
                commentRefs: newCommentRefs,
              };
              newThreadRefs.push(existingThreadRef);
              existingThreadI++;
              continue;
            }
          }
          newThreadRefs.push(
            Normalize.addObj(
              draft.parts,
              'discussion-thread',
              ref.viewID,
              responseThread
            )
          );
        }
        // Associate the threads with the report
        reportPart.discussionThreadRefs = newThreadRefs;
        break;
      }

      case getType(ReportActions.addDiscussionComment): {
        const {ref, response} = action.payload;
        let {discussionThreadRef} = action.payload;
        let isNewThread = false;
        if (discussionThreadRef == null) {
          // creating a new thread
          isNewThread = true;
          discussionThreadRef = Normalize.addObj(
            draft.parts,
            'discussion-thread',
            ref.viewID,
            response.discussionThread
          );
        }
        const newCommentRef = Normalize.addObj(
          draft.parts,
          'discussion-comment',
          ref.viewID,
          response.discussionComment
        );
        const discussionThreadPart =
          draft.parts[discussionThreadRef.type][discussionThreadRef.id];
        // Add new comment to store
        discussionThreadPart.commentRefs.push(newCommentRef);
        // Add new thread to store
        if (isNewThread) {
          draft.parts[ref.type][ref.id].discussionThreadRefs.unshift(
            discussionThreadRef
          );
        }
        // Subscribe the user to comment alerts
        draft.views[ref.viewID].alertSubscription = response.alertSubscription;
        break;
      }

      case getType(DiscussionCommentActions.updateDiscussionComment): {
        const {ref, updatedComment} = action.payload;
        draft.parts[ref.type][ref.id] = updatedComment;
        break;
      }

      case getType(Actions.setCommentAlertSubscription): {
        const {ref, subscriptionID} = action.payload;
        draft.views[ref.id].alertSubscription =
          subscriptionID == null ? undefined : {id: subscriptionID};
        break;
      }

      case getType(InteractStateActions.setHighlight): {
        const {ref, axis, value} = action.payload;
        const interactState = Normalize.lookupPart(draft.parts, ref);
        if (value == null) {
          delete interactState.highlight[axis];
        } else {
          interactState.highlight[axis] = value;
        }
        break;
      }

      case getType(InteractStateActions.setHighlights): {
        const {ref, highlights} = action.payload;
        const interactState = Normalize.lookupPart(draft.parts, ref);
        for (const {axis, value} of highlights) {
          if (value == null) {
            delete interactState.highlight[axis];
          } else {
            interactState.highlight[axis] = value;
          }
        }
        break;
      }

      case getType(InteractStateActions.setPanelSelection): {
        const {ref, value} = action.payload;
        const interactState = Normalize.lookupPart(draft.parts, ref);
        interactState.panelSelection = value;
        break;
      }

      case getType(PanelBankConfigActions.initializeNewPanels): {
        const {ref, panelBankDiff} = action.payload;
        const prevPanelBankConfig = Normalize.denormalize(draft.parts, ref);

        const seenInDiff = new Set();

        for (const [key, operation] of Object.entries(panelBankDiff)) {
          if (seenInDiff.has(key)) {
            console.error(`Duplicated key in panelBankDiff ${key}`);
          } else {
            seenInDiff.add(key);
          }
          if (operation.type === 'update') {
            const existingPanel = draft.parts.panel[operation.currentRef.id];
            if (
              operation.spec.type === 'legacy-vega' ||
              operation.spec.type === 'api-added-panel'
            ) {
              if (operation.spec.type === 'legacy-vega') {
                existingPanel.viewType = 'Vega';
              } else {
                existingPanel.viewType = operation.spec.viewType;
              }
              existingPanel.config = operation.spec.config;
            } else if (
              existingPanel.viewType === 'Run History Line Plot' &&
              operation.spec.type === 'default-panel'
            ) {
              addMetricsToPanelConfig(existingPanel, operation.spec.metrics);
              updateDefaultxAxis(existingPanel, operation.spec.defaultXAxis);
            }
          } else if (operation.type === 'add') {
            // this is either a new panel, or a just-deleted panel we need to add
            // to the "Hidden" section
            let targetSectionRef = draft.parts['panel-bank-config'][
              ref.id
            ].sectionRefs.find(sectionRef => {
              const section =
                draft.parts['panel-bank-section-config'][sectionRef.id];

              if (
                operation.spec.defaultSection === 'System' &&
                section.name === 'system'
              ) {
                // only for the system section, match both cases as the same
                // (this is to improve the experience for users who already
                // had a system section before we capitalized the name)
                return true;
              }

              return section.name === operation.spec.defaultSection;
            });

            if (!targetSectionRef) {
              const defaultConfig = getDefaultPanelSectionConfig({
                name: operation.spec.defaultSection,
              });
              if (
                operation.spec.defaultSection === PANEL_BANK_TABLES_NAME &&
                ['table-file', 'partitioned-table', 'joined-table'].includes(
                  operation.spec.keyType ?? ''
                )
              ) {
                defaultConfig.flowConfig.boxHeight = 500;
              }
              // we didn't find the section, which means we'll need to create it:
              targetSectionRef = Normalize.addObj(
                draft.parts,
                'panel-bank-section-config',
                ref.viewID,
                defaultConfig
              );

              const sectionRefs =
                draft.parts['panel-bank-config'][ref.id].sectionRefs;
              let firstAvailableIndex = 0;
              if (sectionRefs.length > 0) {
                // if the 'Sweep' section exists, inject after it:
                const section =
                  draft.parts['panel-bank-section-config'][sectionRefs[0].id];
                if (section.name === 'Sweep') {
                  firstAvailableIndex += 1;
                }
              }

              // since firstAvailableIndex defaults to zero and sectionRefs
              // determines the order that sections are displayed to users,
              // this behavior means that new sections will be inserted
              // at the top of the panelbank
              draft.parts['panel-bank-config'][ref.id].sectionRefs.splice(
                operation.spec.defaultSection === 'System'
                  ? draft.parts['panel-bank-config'][ref.id].sectionRefs
                      .length - 1
                  : firstAvailableIndex,
                0,
                targetSectionRef
              );
            }

            // now that we're guaranteed to have a ref for the target section,
            // we can resolve it:
            const targetSection =
              draft.parts['panel-bank-section-config'][targetSectionRef.id];
            const panel = getDefaultPanelConfig(key, operation.spec);
            const panelRef = Normalize.addObj(
              draft.parts,
              'panel',
              ref.viewID,
              panel
            );
            if (targetSection.sorted === SectionPanelSorting.Alphabetical) {
              insertPanelAlphabetically(draft, targetSection, panel, panelRef);
            } else {
              targetSection.panelRefs = [panelRef, ...targetSection.panelRefs];
            }
          }
        }

        // Special stuff that happens the first time you initialize the PanelBank
        if (draft.parts[ref.type][ref.id].state === PanelBankConfigState.Init) {
          // Sort sections by name, with these special behaviors:
          //   - Custom Visualizations is first if found
          //   - System is second to last
          //   - Hidden Panels is last

          // Find the Hidden Panels section
          const hiddenSectionRef =
            draft.parts[ref.type][ref.id].sectionRefs.slice(-1)[0];
          const legacyCustomVizSectionRefIndex = _.findIndex(
            prevPanelBankConfig.sections,
            {
              name: PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
            }
          );
          // Find the Custom Visualizations section
          const legacyCustomVizSectionRef =
            legacyCustomVizSectionRefIndex > -1
              ? draft.parts[ref.type][ref.id].sectionRefs[
                  legacyCustomVizSectionRefIndex
                ]
              : null;
          // Sort sections alphabetically by name
          const sortedSectionRefs = draft.parts[ref.type][ref.id].sectionRefs
            .filter(
              r => r !== hiddenSectionRef && r !== legacyCustomVizSectionRef
            )
            .sort((a, b) => {
              const nameA = draft.parts[a.type][a.id].name.toLowerCase();
              const nameB = draft.parts[b.type][b.id].name.toLowerCase();

              // Ensure system is last
              const systemLower = PANEL_BANK_SYSTEM_NAME.toLowerCase();
              if (nameB === systemLower && nameA !== systemLower) {
                return -1;
              } else if (nameB !== systemLower && nameA === systemLower) {
                return 1;
              }

              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            });
          // Add the Custom Visualizations section at the beginning, and the Hidden Panels section at the end
          draft.parts[ref.type][ref.id].sectionRefs = _.compact(
            [legacyCustomVizSectionRef]
              .concat(sortedSectionRefs)
              .concat(hiddenSectionRef)
          );
          // Open the first section by default, if nothing is open.
          if (
            draft.parts[ref.type][ref.id].sectionRefs.length > 0 &&
            draft.parts[ref.type][ref.id].sectionRefs.every(
              sectionRef => !draft.parts[sectionRef.type][sectionRef.id].isOpen
            )
          ) {
            const firstSectionRef =
              draft.parts[ref.type][ref.id].sectionRefs[0];
            draft.parts[firstSectionRef.type][firstSectionRef.id].isOpen = true;
          }

          // Open the Custom Charts section if it exists
          const customChartSectionRef = draft.parts[ref.type][
            ref.id
          ].sectionRefs.find(
            a =>
              draft.parts[a.type][a.id].name === PANEL_BANK_CUSTOM_CHARTS_NAME
          );
          if (customChartSectionRef != null) {
            draft.parts[customChartSectionRef.type][
              customChartSectionRef.id
            ].isOpen = true;
          }
        }
        draft.parts[ref.type][ref.id].state = PanelBankConfigState.Ready;
        break;
      }

      case getType(Actions.setAutosave): {
        const {ref, autosave} = action.payload;
        draft.views[ref.id].autoSave = autosave;
        break;
      }

      case getType(MultiRunWorkspaceActions.hideSlowWarning): {
        const {ref, at} = action.payload;
        draft.parts[ref.type][ref.id].slowWarningHiddenAt = at;
        break;
      }

      case getType(RunPageActions.hideSlowWarning): {
        const {ref, at} = action.payload;
        draft.parts[ref.type][ref.id].slowWarningHiddenAt = at;
        break;
      }

      case getType(RunPageActions.hideCliVersionWarning): {
        const {ref, at} = action.payload;
        draft.parts[ref.type][ref.id].cliVersionWarningHiddenAt = at;
        break;
      }

      case getType(Actions.noop):
      case getType(SectionActions.setRunColor):
      case getType(SectionActions.addNewRunSet):
      case getType(SectionActions.removeRunSet):
      case getType(SectionActions.duplicateRunSet):
      case getType(SectionActions.reorderRunSet):
      case getType(SectionActions.insertRunSet):
      case getType(SectionActions.setActiveIndex):
      case getType(SectionActions.setHideRunSets):
      case getType(SectionActions.setOpen):
      case getType(SectionActions.setName):
      case getType(MarkdownBlockActions.setContent):
      case getType(MarkdownBlockActions.setCollapsed):
      case getType(PanelSettingsActions.set):
      case getType(PanelSettingsActions.update):
      case getType(PanelSettingsActions.setLocalAndWorkspacePanelSettings):
      case getType(PanelSettingsActions.updateLocalAndWorkspacePanelSettings):
      case getType(PanelSettingsActions.setAllLocalAndWorkspacePanelSettings):
      case getType(
        PanelSettingsActions.updateAllLocalAndWorkspacePanelSettings
      ):
      case getType(RunSetActions.set):
      case getType(RunSetActions.update):
      case getType(SortActions.set):
      case getType(FilterActions.set):
      case getType(FilterActions.selectionsToFilters):
      case getType(CustomRunColorsActions.setCustomRunColor):
      case getType(GroupSelectionsActions.setGrouping):
      case getType(GroupSelectionsActions.toggleSelection):
      case getType(GroupSelectionsActions.toggleExpandedRowAddress):
      case getType(GroupSelectionsActions.addBound):
      case getType(GroupSelectionsActions.selectAll):
      case getType(GroupSelectionsActions.selectNone):
      case getType(GroupSelectionsActionsInternal.setGroupSelections):
      case getType(TempSelectionsActions.selectAllVisible):
      case getType(TempSelectionsActions.selectNone):
      case getType(TempSelectionsActions.selectToggle):
      case getType(TempSelectionsActions.selectSome):
      case getType(TempSelectionsActions.selectAll):
      case getType(TempSelectionsActionsInternal.setTempSelections):
      case getType(PanelActions.setConfig):
      case getType(PanelActions.setConfigs):
      case getType(PanelActions.updateConfig):
      case getType(PanelActions.updateConfigs):
      case getType(PanelBankConfigActions.updateSettings):
      case getType(PanelBankConfigActions.updateSettingsAndSortPanels):
      case getType(PanelBankConfigActions.addSection):
      case getType(PanelBankConfigActions.deleteSection):
      case getType(PanelBankConfigActions.moveSectionBefore):
      case getType(PanelBankConfigActions.movePanel):
      case getType(PanelBankConfigActions.movePanelToNewSection):
      case getType(PanelBankConfigActionsInternal.undoMovePanelToNewSection):
      case getType(
        PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels
      ):
      case getType(PanelBankConfigActions.noOp):
      case getType(PanelBankSectionConfigActions.sortPanels):
      case getType(
        PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting
      ):
      case getType(PanelBankSectionConfigActions.insertUpdatedPanel):
      case getType(PanelBankSectionConfigActions.addPanelWithoutRef):
      case getType(PanelBankSectionConfigActions.addPanel):
      case getType(PanelBankSectionConfigActions.deletePanel):
      case getType(PanelBankSectionConfigActions.duplicatePanel):
      case getType(PanelBankSectionConfigActions.toggleType):
      case getType(PanelBankSectionConfigActions.toggleIsOpen):
      case getType(PanelBankSectionConfigActions.updateName):
      case getType(PanelBankSectionConfigActions.updateFlowConfig):
      case getType(PanelBankSectionConfigActions.setGridLayout):
      case getType(PanelBankSectionConfigActions.undoInsertUpdatedPanel):
      case getType(ReportActions.removeSection):
      case getType(ReportActions.setWidth):
      case getType(ReportActions.setSpecVersion):
      case getType(ReportActions.copySection):
      case getType(ReportActions.insertSection):
      case getType(ReportActions.moveSection):
      case getType(ReportActions.addAuthor):
      case getType(ReportActions.removeAuthor):
      case getType(ReportActions.setBlocks):
      case getType(Actions.rename):
      case getType(Actions.setPreviewUrl):
      case getType(Actions.setCoverUrl):
      case getType(Actions.setDescription): {
        applyUndoableAction(draft, action);
        break;
      }
    }
  } finally {
    endPerfEvent();
  }
}

export default immerReducer<ViewReducerState, ActionType>(views, {
  lists: {},
  views: {},
  parts: {
    'project-view': {},
    'group-view': {},
    'sweep-view': {},
    'run-view': {},
    runs: {},
    'runs/draft': {},
    section: {},
    'markdown-block': {},
    runSet: {},
    sort: {},
    filters: {},
    panels: {},
    panel: {},
    panelSettings: {},
    'group-selections': {},
    'run-colors': {},
    'temp-selections': {},
    // interactState has a special 'empty' id, which is a constant. We use
    // this ref when elements are offscreen, to avoid updating them.
    interactState: {empty: InteractStateTypes.EMPTY_STATE},
    'panel-bank-config': {},
    'panel-bank-section-config': {},
    'discussion-thread': {},
    'discussion-comment': {},
  },
  undoActions: [],
  redoActions: [],
});
