import {envIsPublicCloud} from '@wandb/common/config';
import {getCookie, setCookie, unsetCookie} from '@wandb/common/util/cookie';

import {useViewer} from '../state/viewer/hooks';

const USE_ADMIN_PRIVILEGES_COOKIE_KEY = 'use_admin_privileges';
export const USE_VIEW_AS_COOKIE_KEY = 'impersonated_username';

export function useAdminModeActive(): boolean {
  const viewer = useViewer();
  if (!viewer?.admin) {
    return false;
  }
  return viewerUsingAdminPrivileges();
}

export function viewerUsingAdminPrivileges(): boolean {
  if (!envIsPublicCloud) {
    return true;
  }
  return getCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY) === 'true';
}

export function viewingAs(): string {
  return getCookie(USE_VIEW_AS_COOKIE_KEY);
}

export function setViewerUsingAdminPrivileges(enable: boolean): void {
  if (enable) {
    setCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY, 'true');
  } else {
    unsetCookie(USE_ADMIN_PRIVILEGES_COOKIE_KEY);
  }
}

export function setViewingAs(username: string): void {
  setCookie(USE_VIEW_AS_COOKIE_KEY, username);
}

export function unsetViewingAs(): void {
  unsetCookie(USE_VIEW_AS_COOKIE_KEY);
}

const wandbDomainEmailRegex = new RegExp('^.+?@wandb.com$');
export function isWandbDomainEmail(email: string): boolean {
  return wandbDomainEmailRegex.test(email);
}
