import {RunHistoryKeyInfo} from '../types/run';
import {
  defaultSectionToSectionWithVisiblePanelsMapping,
  getDefaultPanelSectionConfig,
  getSectionsWithVisiblePanels,
  PanelBankFlowSectionConfig,
  PanelBankSectionConfig,
  PanelBankSectionConfigWithVisiblePanels,
  panelIsActive,
  searchRegexFromQuery,
} from './panelbank';
import {LayedOutPanel} from './panels';
import * as RunHelpers from './runhelpers';

export interface CurrentPageBySectionRefID {
  [sectionRefID: string]: number;
}

interface GetFlowSectionsWithVisiblePanelsParams {
  sections: readonly PanelBankSectionConfig[];
  currentPageBySectionRefID: CurrentPageBySectionRefID;
  singleRun?: boolean;
  debouncedSearchQuery: string;
  historyKeyInfo: RunHistoryKeyInfo;
  panelBankWidth: number;
}

// TODO: refactor all this to use hooks
export function getFlowSectionsWithVisiblePanels({
  sections,
  currentPageBySectionRefID,
  singleRun,
  debouncedSearchQuery,
  historyKeyInfo,
  panelBankWidth,
}: GetFlowSectionsWithVisiblePanelsParams): PanelBankSectionConfigWithVisiblePanels[] {
  return getSectionsWithVisiblePanels(sections, s => {
    if (s.type !== 'flow') {
      return defaultSectionToSectionWithVisiblePanelsMapping(s);
    }
    const sectionRef = (s as any).ref;
    return {
      ...s,
      ref: sectionRef,
      visiblePanels: getVisiblePanels({
        section: s,
        singleRun,
        debouncedSearchQuery,
        historyKeyInfo,
        currentPage: currentPageBySectionRefID[sectionRef?.id] ?? 0,
        panelBankWidth,
      }),
    };
  });
}

export function panelOnActivePage(
  panelIndex: number,
  currentPage: number,
  panelsPerPage: number
): boolean {
  const startPanelIndex = currentPage * panelsPerPage;
  return (
    panelIndex >= startPanelIndex &&
    panelIndex < (currentPage + 1) * panelsPerPage
  );
}

export interface GetVisiblePanelsParams {
  section: PanelBankSectionConfig;
  singleRun?: boolean;
  debouncedSearchQuery: string;
  historyKeyInfo: RunHistoryKeyInfo;
  currentPage: number;
  panelBankWidth: number;
}

export function getVisiblePanels({
  section,
  singleRun,
  debouncedSearchQuery,
  historyKeyInfo,
  currentPage,
  panelBankWidth,
}: GetVisiblePanelsParams): LayedOutPanel[] {
  const keyTypes = RunHelpers.keyTypes(historyKeyInfo);
  const searchRegex = searchRegexFromQuery(debouncedSearchQuery);
  const activePanels = section.panels.filter(p =>
    panelIsActive({
      section,
      panel: p,
      singleRun,
      searchRegex,
      historyKeyInfo,
      keyTypes,
    })
  );
  const {panelsPerPage} = getPagingParams({
    panelBankWidth,
    panelCount: activePanels.length,
    flowConfig: section.flowConfig,
  });
  return activePanels.filter((p, i) =>
    panelOnActivePage(i, currentPage, panelsPerPage)
  );
}

export interface GetPagingParamsParams {
  panelBankWidth: number;
  panelCount: number;
  flowConfig: PanelBankFlowSectionConfig;
}

export interface PagingParams {
  panelsPerRow: number;
  panelsPerPage: number;
  maxPage: number;
}

export function getPagingParams({
  panelBankWidth,
  panelCount,
  flowConfig,
}: GetPagingParamsParams): PagingParams {
  const {gutterWidth, rowsPerPage} = flowConfig;
  const {boxWidth} = getBoxDimensions(panelBankWidth, flowConfig);
  const panelsPerRow = Math.max(
    1,
    Math.floor(panelBankWidth / (boxWidth + gutterWidth))
  );
  const panelsPerPage = panelsPerRow * rowsPerPage;
  const maxPage = Math.max(0, Math.ceil(panelCount / panelsPerPage) - 1);
  return {
    panelsPerRow,
    panelsPerPage,
    maxPage,
  };
}

export interface BoxDimensions {
  boxWidth: number;
  boxHeight: number;
}

// Returns a standard size if on mobile, custom size if not
export function getBoxDimensions(
  panelBankWidth: number,
  flowConfig: PanelBankFlowSectionConfig
): BoxDimensions {
  const {gutterWidth} = flowConfig;
  const mobile = isMobile();
  const boxWidth = mobile
    ? panelBankWidth - 2 * gutterWidth
    : flowConfig.snapToColumns
    ? getColumnWidth(panelBankWidth, flowConfig)
    : flowConfig.boxWidth;
  const boxHeight = mobile
    ? getDefaultPanelSectionConfig().flowConfig.boxHeight
    : flowConfig.boxHeight;
  return {boxWidth, boxHeight};
}

export function getColumnWidth(
  panelBankWidth: number,
  flowConfig: PanelBankFlowSectionConfig,
  columnCount?: number
): number {
  const {gutterWidth} = flowConfig;
  columnCount = columnCount || flowConfig.columnsPerPage;
  return Math.floor(
    (panelBankWidth - gutterWidth * (columnCount + 1)) / columnCount
  );
}

export function isMobile(): boolean {
  return window.innerWidth <= 852;
}
