import React, {ReactChild} from 'react';

import * as S from './Banner.styles';

type Props = {
  variant?: string;
  Icon?: ReactChild;
  Actions?: ReactChild;
  boldText: string;
  text?: string;
};
export const Banner = ({
  variant = 'info',
  Icon,
  Actions,
  boldText,
  text,
}: Props) => {
  return (
    <S.BannerWrapper variant={variant}>
      <S.LeftWrapper>
        {Icon && Icon}
        <S.TextWrapper>
          <S.BannerBoldText>{boldText}</S.BannerBoldText>
          <S.BannerUnboldedLabel>{text}</S.BannerUnboldedLabel>
        </S.TextWrapper>
      </S.LeftWrapper>
      {Actions && <S.RightWrapper>{Actions}</S.RightWrapper>}
    </S.BannerWrapper>
  );
};
