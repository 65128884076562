import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Stripe} from '@stripe/stripe-js';
import * as globals from '@wandb/common/css/globals.styles';
import {TargetBlank} from '@wandb/common/util/links';
import _ from 'lodash';
import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {Message, StrictInputProps} from 'semantic-ui-react';

import {useViewer} from '../../../state/viewer/hooks';
import {Viewer} from '../../../state/viewer/types';
import {slugFormat} from '../../../util/text';
import {privacyPolicy, termsOfService} from '../../../util/urls';
import {BillingAddressContext} from './BillingAddressContext';
import {BillingAddressInput} from './BillingAddressInput';
import {
  CheckoutModalContext,
  CheckoutModalUpdaterContext,
} from './CheckoutModal';
import * as S from './StripeForm.styles';

export type SubmitParams = {
  stripe: Stripe;
  orgName: string;
  custoEmail: string;
  paymentMethodID?: string;
  setErrMsg: (errMsg: string) => void;
};

export type RenderButtonsParams = {
  submitWrapper: SubmitWrapper;
  submitButtonDisabled: boolean;
  submitting: boolean;
};

export type SubmitWrapper = (parentSubmit: ParentSubmitFn) => Promise<void>;
type ParentSubmitFn = (p: SubmitParams) => Promise<boolean>;

type StripeFormProps = {
  orgName?: string;
  hideOrgName?: boolean;
  hideCardFields?: boolean;
  renderButtons: (p: RenderButtonsParams) => ReactNode;
};

export const StripeFormComp: FC<StripeFormProps> = props => {
  const viewer = useViewer();

  if (viewer == null) {
    return null;
  }

  return <StripeFormInner {...props} viewer={viewer} />;
};

type StripeFormInnerProps = StripeFormProps & {viewer: Viewer};

const StripeFormInner: React.FC<StripeFormInnerProps> = React.memo(props => {
  const {hideOrgName, hideCardFields, renderButtons, viewer} = props;
  const stripe = useStripe();
  const elements = useElements();

  const {city, country, line1, line2, postalCode, state, filledAllRequired} =
    useContext(BillingAddressContext);
  const checkoutModalContext = useContext(CheckoutModalContext);
  const checkoutModalUpdaterContext = useContext(CheckoutModalUpdaterContext);
  if (checkoutModalContext == null || checkoutModalUpdaterContext == null) {
    throw new Error(
      'this component must be put under a CheckoutModalContext.Provider'
    );
  }
  const {submitting} = checkoutModalContext;
  const {setSubmitting} = checkoutModalUpdaterContext;

  const [orgName, setOrgName] = useState(props.orgName ?? '');
  const [custoName, setCustoName] = useState('');
  const [error, setError] = useState<string | null>(null);

  const stripeFormDisabled = submitting || stripe == null || elements == null;
  // TODO: we might want to check cardElement.complete for submitButtonDisabled
  const submitButtonDisabled = stripeFormDisabled || !filledAllRequired;

  const onCustoNameChange = useCallback<
    NonNullable<StrictInputProps['onChange']>
  >((e, data) => {
    trackCustoNameChange(data.value);
    setCustoName(data.value);
  }, []);

  const onOrganizationNameChange = useCallback<
    NonNullable<StrictInputProps['onChange']>
  >((event, data) => {
    const val = slugFormat(data.value);
    trackOrgNameChange(val);
    setOrgName(val);
  }, []);

  const submitWrapper = useCallback(
    async (parentSubmit: ParentSubmitFn) => {
      if (!hideOrgName && orgName.length === 0) {
        setError('Must provide organization name.');
        return;
      }

      if (stripe == null || elements == null) {
        throw new Error('Stripe not loaded.');
      }

      const cardElement = elements.getElement(CardElement);
      if (!hideCardFields && cardElement == null) {
        throw new Error('Stripe card element not loaded.');
      }

      // Clear status messages and disable further input
      setError(null);
      setSubmitting(true);

      const paymentMethod =
        cardElement != null
          ? await stripe.createPaymentMethod({
              type: 'card',
              card: cardElement,
              billing_details: {
                name: custoName,
                email: viewer.email,
                address: {
                  city,
                  country,
                  line1,
                  line2,
                  postal_code: postalCode,
                  state,
                },
              },
            })
          : null;

      if (
        paymentMethod != null &&
        (paymentMethod.error != null || paymentMethod.paymentMethod == null)
      ) {
        setError(
          paymentMethod.error?.message ?? 'Error validating payment method.'
        );
        setSubmitting(false);
        return;
      }

      await parentSubmit({
        stripe,
        orgName,
        custoEmail: viewer.email,
        paymentMethodID: paymentMethod?.paymentMethod?.id,
        setErrMsg: setError,
      });

      setSubmitting(false);
    },
    [
      orgName,
      custoName,
      viewer.email,
      elements,
      hideOrgName,
      hideCardFields,
      stripe,
      city,
      country,
      line1,
      line2,
      postalCode,
      state,
      setSubmitting,
    ]
  );

  const renderButtonsParams = useMemo(
    () => ({submitWrapper, submitButtonDisabled, submitting}),
    [submitWrapper, submitButtonDisabled, submitting]
  );

  return (
    <div className="checkout-form stripe-form" style={{padding: '0'}}>
      {error != null && <Message negative content={error} className="alert" />}
      <div className="checkout-fields">
        <S.Label htmlFor="stripe-form-email">Account email</S.Label>
        <div
          onClick={() => {
            window.analytics?.track(
              'stripe form disabled email input clicked',
              {value: viewer.email}
            );
          }}>
          <S.Input
            id="stripe-form-email"
            className="custo-input"
            value={viewer.email}
            disabled
          />
        </div>
        {!hideOrgName && (
          <>
            <S.Label htmlFor="stripe-form-org">Organization</S.Label>
            <div
              onClick={() => {
                if (props.orgName != null) {
                  window.analytics?.track(
                    'stripe form disabled org name input clicked',
                    {value: orgName}
                  );
                }
              }}>
              <S.Input
                id="stripe-form-org"
                data-test="org-name-input"
                className="custo-input"
                value={orgName}
                onChange={onOrganizationNameChange}
                disabled={props.orgName != null || stripeFormDisabled}
              />
            </div>
          </>
        )}
        {!hideCardFields && (
          <>
            <S.Label htmlFor="stripe-form-name">Cardholder Name</S.Label>
            <S.Input
              id="stripe-form-name"
              data-test="cardholder-name-input"
              className="custo-input"
              onChange={onCustoNameChange}
              disabled={stripeFormDisabled}
            />
            <S.Label>Credit Card</S.Label>
            <div className="card-element">
              <CardElement
                options={{
                  disabled: stripeFormDisabled,
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: '15px',
                      color: '#424770',
                      fontFamily: 'sans-serif',
                      fontWeight: '300',
                      letterSpacing: '0.025em',
                      ':disabled': {
                        backgroundColor: globals.gray200,
                        color: globals.gray600,
                      },
                    },
                  },
                }}
              />
            </div>
            <div>
              <S.Label htmlFor="stripe-form-billing-address">
                Billing address
              </S.Label>
              <BillingAddressInput />
            </div>
          </>
        )}

        {submitting && (
          <S.ProcessingMessage>
            Your payment is processing.
            <br />
            Please don't close this tab.
          </S.ProcessingMessage>
        )}
        <div className="subscribe-button-group">
          {renderButtons(renderButtonsParams)}
        </div>
        <S.AgreementLabel>
          <p>
            By clicking "Subscribe," you agree to W&B's{' '}
            <TargetBlank
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              href={termsOfService()}>
              terms and conditions
            </TargetBlank>
            .
          </p>
          <p>
            To learn more about how W&B collects, uses, shares, and protects
            your personal data, please see W&B's{' '}
            <TargetBlank
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              href={privacyPolicy()}>
              Privacy Policy
            </TargetBlank>
            .
          </p>
        </S.AgreementLabel>
      </div>
    </div>
  );
});

export const StripeForm = memo(StripeFormComp);

const trackCustoNameChange = _.debounce((value: string) => {
  window.analytics?.track('stripe form customer name changed', {value});
}, 500);
const trackOrgNameChange = _.debounce((value: string) => {
  window.analytics?.track('stripe form org name changed', {value});
}, 500);
