import {useEffectExceptFirstRender} from '@wandb/common/util/hooks';
import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import _ from 'lodash';
import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Button} from 'semantic-ui-react';

import {
  RunWithRunsetInfo,
  toRunsDataQuery,
} from '../../containers/RunsDataLoader';
import {useRunsData} from '../../state/runs/hooks';
import {isRunWithRunsetInfo, Query} from '../../state/runs/types';
import {useInteractStateWhenOnScreen} from '../../state/views/interactState/context';
import * as Filter from '../../util/filters';
import {ValueOp} from '../../util/filters';
import {Grouping, Query as QueryTS} from '../../util/queryts';
import * as Run from '../../util/runs';
import {Key as RunKey, keyToCss, Value as RunValue} from '../../util/runs';
import {makeRowAddressComponent} from '../../util/tablecols';
import {
  WBTableColumn,
  WBTableHoverCellCoords,
  WBTableRowFields,
} from './WBTable';
import {WBTableCell} from './WBTableCell';

interface WBTableRowProps {
  columns: WBTableColumn[];
  row: WBTableRowFields<Run.Run>;
  pinned?: boolean;
  recursionDepth: number;
  expandedRowAddresses: string[]; // list of expanded rows, an array of rowAddresses
  childrenPaginationMap: {
    [key: string]: number | undefined;
  };
  hoverCellCoords: WBTableHoverCellCoords;
  expanded?: boolean;
  isSingleMode?: boolean;
  showArtifactCounts?: boolean;
  showLogCounts?: boolean;
  topLevelQueryVariables: QueryTS;
  style?: React.CSSProperties;
  setHoverCellCoords(newCoords: WBTableHoverCellCoords): void;
  setChildrenPagination(rowAddress: string, activePage?: number): void;
  toggleExpandedRow(rowAddress: string): void; // expand or collapse this row
  addFilter(key: RunKey, op: ValueOp, value: RunValue): void;
}

const WBTableRowComp: React.FC<WBTableRowProps> = ({
  style,
  columns,
  row,
  recursionDepth,
  expandedRowAddresses,
  childrenPaginationMap,
  setChildrenPagination,
  hoverCellCoords,
  setHoverCellCoords,
  isSingleMode,
  showArtifactCounts,
  showLogCounts,
  pinned,
  expanded, // whether or not the *table* is expanded (not this row - use expandedRowAddresses for that)
  topLevelQueryVariables,

  addFilter,
  toggleExpandedRow,
}) => {
  const [changeAnimating, setChangeAnimating] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const nextRecursionDepthRef = useRef<number>(recursionDepth + 1);

  useLayoutEffect(() => {
    if (ref.current != null) {
      ref.current.addEventListener('animationend', () => {
        setChangeAnimating(false);
      });
    }
    // If this run was started recently, highlight it
    if (Date.now() - new Date(row.createdAt).getTime() < 30000) {
      setChangeAnimating(true);
    }
    // eslint-disable-next-line
  }, []);

  // comment out to disable row highlighting
  const rowHeartbeatAt: string = row.heartbeatAt;
  useEffectExceptFirstRender(
    () => {
      setChangeAnimating(true);
    },
    [rowHeartbeatAt],
    true
  );

  const rowAddress: string = row.__address__;
  const isGroupExpanded: boolean = _.includes(expandedRowAddresses, rowAddress);
  const isHoveringRow: boolean =
    hoverCellCoords && hoverCellCoords[0] === rowAddress; // TODO

  // If a row is a group, it has children.

  /* Children Pagination */
  const childrenPageSize: number = 10;
  const childrenActivePage: number = childrenPaginationMap[rowAddress] || 1;
  const childrenCount: number = row.groupCounts?.[0] || 0;
  const childrenTotalPages: number = Math.ceil(
    childrenCount / childrenPageSize
  );
  const childrenSliceStart: number =
    (childrenActivePage - 1) * childrenPageSize;
  const childrenSliceEnd: number = childrenSliceStart + childrenPageSize;
  const childrenHiddenStyle = useMemo(() => {
    return isGroupExpanded && childrenCount > 0 ? style : {display: 'none'};
  }, [childrenCount, isGroupExpanded, style]);

  // Transforms the top-level queryVariables into queryVariables for this row's children
  const childrenQuery = useMemo((): Query => {
    const allGroupKeys: Grouping =
      _.clone(topLevelQueryVariables.grouping) || [];
    let childrenQueryVariables: QueryTS = _.clone(topLevelQueryVariables);
    _.times(nextRecursionDepthRef.current, () => {
      // convert the first group into a filter. (if no groupKey, fetch an individual run.)
      const childrenFilter: Run.Key = allGroupKeys.shift() || {
        section: 'run',
        name: 'name',
      };
      childrenQueryVariables = {
        ...childrenQueryVariables,
        filters: {
          op: 'AND',
          filters: [childrenQueryVariables.filters].concat([
            {
              key: childrenFilter,
              op: '=',
              value: Run.getValue(row, childrenFilter),
            } as Filter.Filter<Run.Key>,
          ]),
        },
        // the children grouping is the parent grouping minus the first grouping key (which is now a filter)
        grouping: allGroupKeys,
      };
    });
    return toRunsDataQuery(childrenQueryVariables, undefined, {
      page: {size: childrenPageSize * (childrenActivePage + 2)}, // preload a couple pages so it feels snappy
    });
  }, [childrenActivePage, row, topLevelQueryVariables]);

  const {loading: loadingChildren, data: childrenData} = useRunsData(
    childrenQuery,
    !isGroupExpanded
  );
  const childDataRows = useMemo(
    () => (childrenData ? childrenData.filtered : []),
    [childrenData]
  );

  const childRows = useMemo(() => {
    return childDataRows.map((dataRow: RunWithRunsetInfo) => {
      return {
        ...dataRow,
        selectedRunName: row.selectedRunName,
        __address__: `${rowAddress}-${makeRowAddressComponent(dataRow.name)}`,
      };
    });
  }, [childDataRows, row.selectedRunName, rowAddress]);

  const isGroup: boolean = row.groupCounts != null || childRows.length > 0;

  const uniqueId: string | null =
    isRunWithRunsetInfo(row) && topLevelQueryVariables.grouping != null
      ? Run.uniqueId(row, topLevelQueryVariables.grouping)
      : null;
  const [domRef, isRowHighlighted] = useInteractStateWhenOnScreen(
    interactState =>
      uniqueId != null && interactState.highlight['run:name'] === uniqueId
  );

  const combinedRef = useCallback(
    (el: HTMLDivElement) => {
      ref.current = el;
      domRef(el);
    },
    [domRef]
  );

  return (
    <React.Fragment>
      <div
        ref={combinedRef}
        style={style}
        className={`wb-tree-cell-row-background
          ${isHoveringRow ? 'wb-tree-cell--hovering' : ''}
          ${changeAnimating ? 'highlight-change-animation' : ''}
        `}
      />
      <div className="wb-tree-padding" />
      {columns.map((column, columnIndex) => {
        const columnKey: Run.Key = column.key;
        const className: string = `wb-tree-cell
          wb-tree-cell--column-${keyToCss(columnKey)}
          wb-tree-cell--column-${columnIndex}
          wb-tree-cell--row-${rowAddress} 
          ${
            row?.selectedRunName === row.name || isRowHighlighted
              ? 'wb-tree-cell--active'
              : ''
          }
        `;

        return (
          <WBTableCell
            key={`${rowAddress}-${column.accessor}`}
            className={className}
            style={style}
            column={column}
            row={row}
            loadingChildren={loadingChildren}
            recursionDepth={recursionDepth}
            isGroup={isGroup}
            isGroupExpanded={isGroupExpanded}
            isSingleMode={isSingleMode}
            showArtifactCounts={showArtifactCounts}
            showLogCounts={showLogCounts}
            addFilter={addFilter}
            toggleExpandedRow={toggleExpandedRow}
            isHoveringRow={isHoveringRow}
            expanded={expanded}
            cellHoverProps={{
              onMouseEnter: () => {
                setHoverCellCoords([rowAddress, keyToCss(columnKey)]);
              },
              onMouseLeave: () => {
                setHoverCellCoords([]);
              },
            }}
          />
        );
      })}
      <div className="wb-tree-padding" />
      {isGroup && (
        /* GROUPS */
        <React.Fragment>
          {childRows
            .slice(childrenSliceStart, childrenSliceEnd)
            .map(childRow => {
              return (
                <WBTableRowComp
                  key={childRow.__address__}
                  style={childrenHiddenStyle}
                  topLevelQueryVariables={topLevelQueryVariables}
                  columns={columns}
                  row={childRow}
                  pinned={pinned}
                  expandedRowAddresses={expandedRowAddresses}
                  toggleExpandedRow={toggleExpandedRow}
                  recursionDepth={nextRecursionDepthRef.current}
                  addFilter={addFilter}
                  setHoverCellCoords={setHoverCellCoords}
                  expanded={expanded}
                  isSingleMode={isSingleMode}
                  showArtifactCounts={showArtifactCounts}
                  showLogCounts={showLogCounts}
                  childrenPaginationMap={childrenPaginationMap}
                  setChildrenPagination={setChildrenPagination}
                  hoverCellCoords={hoverCellCoords}
                />
              );
            })}

          {childrenTotalPages > 1 && (
            <div
              className="wb-tree-cell wb-tree-pagination"
              style={{
                ...childrenHiddenStyle,
                gridColumn: `2 / -1`,
                paddingLeft:
                  37 + (expanded ? 18 : 0) + nextRecursionDepthRef.current * 18,
              }}>
              {pinned && (
                <Button.Group>
                  <Button
                    size="tiny"
                    icon="chevron left"
                    disabled={childrenActivePage < 2}
                    onClick={() => {
                      setChildrenPagination(rowAddress, childrenActivePage - 1);
                    }}
                  />
                  <Button>
                    {childrenSliceStart === 0 ? '01' : childrenSliceStart + 1}-
                    {Math.min(childrenCount, childrenSliceEnd)} of{' '}
                    {childrenCount}
                  </Button>
                  <Button
                    size="tiny"
                    icon="chevron right"
                    disabled={childrenActivePage >= childrenTotalPages}
                    onClick={() => {
                      setChildrenPagination(rowAddress, childrenActivePage + 1);
                    }}
                  />
                </Button.Group>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const WBTableRow = memo(WBTableRowComp, (prevProps, nextProps) => {
  const {row, expandedRowAddresses, hoverCellCoords} = prevProps;

  const isHoveringRowOrChild = (
    rowAddressInner: string,
    hoverCellCoordsInner: WBTableHoverCellCoords
  ): boolean => {
    return (
      hoverCellCoordsInner != null &&
      hoverCellCoordsInner[0] != null &&
      (hoverCellCoordsInner[0] === rowAddressInner ||
        (hoverCellCoordsInner[0] as string).startsWith(rowAddressInner + '-'))
    );
  };

  const rowAddress: string = row.__address__;
  // re-render if user has expanded/closed this row or any child row
  if (expandedRowAddresses !== nextProps.expandedRowAddresses) {
    if (
      !_.isEqual(
        expandedRowAddresses.filter(ra => _.startsWith(ra, rowAddress)), // child row addresses are prefixed with rowAddress
        nextProps.expandedRowAddresses.filter(ra =>
          _.startsWith(ra, rowAddress)
        )
      )
    ) {
      return false;
    }
  }
  // entering or leaving this row/group
  if (
    isHoveringRowOrChild(rowAddress, hoverCellCoords) !==
    isHoveringRowOrChild(nextProps.row.__address__, nextProps.hoverCellCoords)
  ) {
    return false;
  }
  // Moving rows within this group
  if (
    isHoveringRowOrChild(
      nextProps.row.__address__,
      nextProps.hoverCellCoords
    ) &&
    (hoverCellCoords && hoverCellCoords[0]) !== nextProps.hoverCellCoords[0]
  ) {
    return false;
  }
  const propsAreEqual = makePropsAreEqual({
    name: 'WBTableRow',
    deep: ['columns', 'row'],
    // We remount when run.id changes
    ignore: ['expandedRowAddresses', 'hoverCellCoords'],
    ignoreFunctions: true,
    debug: false,
    verbose: true,
  });
  return propsAreEqual(prevProps, nextProps);
});
