/**
 * Display an info message to the user.
 *
 * TODO: Consider replacing with MUI Alert after v5 upgrade happens.
 * https://mui.com/material-ui/react-alert/
 */

import React from 'react';

import * as S from './Alert.styles';

interface AlertProps {
  children: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({children}) => (
  <S.Alert>
    <S.Icon name="info" />
    <S.Message>{children}</S.Message>
  </S.Alert>
);

export default Alert;
