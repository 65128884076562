import {TextField} from '@material-ui/core';
import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

import {WBButton} from '../../WBButtonNew';

export const Container = styled.div<{selected: boolean}>`
  ${p =>
    p.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;

export const EditContainer = styled.div`
  background-color: ${globals.GRAY_TRANSPARENT};
  padding: 24px;
  margin-bottom: 24px;
`;

export const EditMessage = styled.div<{marginTop?: boolean}>`
  font-style: italic;
  margin-bottom: 24px;
  ${p =>
    p.marginTop &&
    css`
      margin-top: 24px;
    `}
`;

export const EditInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const EditTextField = styled(TextField)`
  flex-grow: 1;
`;

export const SubmitButton = styled(WBButton).attrs({variant: `confirm`})<{
  marginLeft?: boolean;
  marginTop?: boolean;
}>`
  &&& {
    ${p =>
      p.marginLeft &&
      css`
        margin-left: 16px;
      `}

    ${p =>
      p.marginTop &&
      css`
        margin-top: 16px;
      `}
  }
`;
