import {Icon, Message, Modal} from 'semantic-ui-react';
import styled from 'styled-components';

export const CreateTeamModalContent = styled(Modal.Content)`
  min-height: 600px;
`;

export const HelperText = styled.p`
  font-size: 14px;
`;

export const FlexLabel = styled.label`
  /* need to override semantic here */
  display: inline-flex !important;
  align-items: initial;
`;

export const FlexLabelText = styled.span`
  flex: 1 0 auto;
`;

export const FlexLabelIcon = styled(Icon)`
  margin: 0 5px 0 5px !important;
  flex: 0 0 auto;
`;

export const WarningMessage = styled(Message).attrs({size: 'mini'})`
  padding-top: 14px !important;
  padding-bottom: 14px !important;
`;

export const ActionsWrapper = styled.span`
  display: inline-flex;
  width: 100%;
  align-items: center;
`;

export const ActionIcon = styled(Icon)`
  flex: 0 0 auto;
  margin: 0 10px 0 4px !important;
`;

export const DoubleCheckMessage = styled.span`
  text-align: left;
  flex: 1 0 auto;
`;

export const ActionButtonsWrapper = styled.span`
  flex: 0 0 auto;
`;
