import {TargetBlank} from '@wandb/common/util/links';
import {Input} from 'semantic-ui-react';
import styled from 'styled-components';

export const CTAInput = styled(Input)`
  padding: 16px;
`;

export const CTAContainer = styled(TargetBlank)`
  display: flex;
  justify-content: center;
  cursor: default;
`;
