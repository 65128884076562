import {QueryResult} from '@apollo/react-common';
import {WBIcon} from '@wandb/ui';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Column} from 'react-table';
import TimeAgo from 'react-timeago';
import {Button, Modal, Popup} from 'semantic-ui-react';
import {Viewer} from 'src/state/viewer/types';

import {
  FetchLaunchAgentsFromProjectQuery,
  RunQueue,
  useDeleteLaunchAgentsMutation,
  useStopLaunchAgentMutation,
} from '../../generated/graphql';
import {InstrumentedLoader as Loader} from '../utility/InstrumentedLoader';
import * as S from './LaunchAgentTab.styles';

interface AgentTableProps {
  runQueues: RunQueue[];
  launchAgentsQuery: QueryResult<FetchLaunchAgentsFromProjectQuery>;
  viewer?: Viewer;
}

const AgentTable: React.FC<AgentTableProps> = ({
  launchAgentsQuery,
  runQueues,
  viewer,
}) => {
  const agentIDColumn: Column = {
    Header: 'Agent ID',
    accessor: 'id',
  };
  const startedAtColumn: Column = {
    Header: 'Started at',
    accessor: 'startedAt',
  };
  const statusColumn: Column = {
    Header: 'Status',
    accessor: 'status',
  };
  const queuesColumn: Column = {
    id: 'queues',
    Header: 'Queues',
    accessor: row => row.queues.join(', '),
  };
  const hostColumn: Column = {
    Header: 'Host',
    accessor: 'host',
  };

  const killColumn: Column = {
    Header: '',
    accessor: 'killAgent',
  };
  const columns = [
    agentIDColumn,
    startedAtColumn,
    statusColumn,
    queuesColumn,
    hostColumn,
    killColumn,
  ];

  const queueIdMap = useMemo(() => {
    return Object.fromEntries(runQueues.map(q => [q.id, q.name]));
  }, [runQueues]);

  const [stoppingAgentId, setStoppingAgentId] = useState<string | null>(null);
  const [deletingAgentId, setDeletingAgentId] = useState<string | null>(null);
  const [deletingAllStoppedAgents, setDeletingAllStoppedAgents] =
    useState(false);
  const [deletingOrStopping, setDeletingOrStopping] = useState(false);
  const [stopAgent] = useStopLaunchAgentMutation();
  const [deleteAgents] = useDeleteLaunchAgentsMutation();

  const rows = useMemo(() => {
    const agents = launchAgentsQuery.data?.project?.launchAgents ?? [];
    return agents.map(a => {
      const status =
        a.stopPolling && !(a.agentStatus === 'KILLED')
          ? 'stopping'
          : a.agentStatus.toLowerCase();
      const queueNames = a.runQueues.map(q => queueIdMap[q]);
      return {
        searchString: a.id,
        row: {
          id: a.name,
          startedAt: <TimeAgo date={a.createdAt + 'Z'} />,
          status,
          queues: queueNames,
          host: a.hostname,
          killAgent: (
            <AgentActions
              agentId={a.id}
              agentStatus={status}
              setStoppingAgentId={setStoppingAgentId}
              setDeletingAgentId={setDeletingAgentId}
            />
          ),
        },
      };
    });
  }, [launchAgentsQuery.data, queueIdMap]);
  const agentsToBatchDelete = launchAgentsQuery.data?.project?.launchAgents
    ?.filter(a => a.agentStatus === 'KILLED')
    .map(a => a.id);
  const {modalInfoString, modalButtonString} = useMemo(() => {
    if (deletingAllStoppedAgents) {
      return {
        modalInfoString: 'Are you sure you want to delete all stopped agents?',
        modalButtonString: 'Delete all stopped agents',
      };
    }
    if (deletingAgentId != null) {
      return {
        modalInfoString: 'Are you sure you want to DELETE this agent?',
        modalButtonString: 'Delete agent',
      };
    } else if (stoppingAgentId != null) {
      return {
        modalInfoString:
          'Are you sure you want to STOP this launch agent? This will interrupt the launch agent process on your local machine. Stopping it from starting new runs after completing current runs.',
        modalButtonString: 'Stop agent',
      };
    }
    return {modalInfoString: '', modalButtonString: ''};
  }, [deletingAgentId, stoppingAgentId, deletingAllStoppedAgents]);
  const onModalClick = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setDeletingOrStopping(true);
      if (deletingAllStoppedAgents && agentsToBatchDelete != null) {
        await deleteAgents({
          variables: {
            agentIds: agentsToBatchDelete,
          },
        });
      }
      if (stoppingAgentId != null) {
        window.analytics?.track('stopped launch agent from agent tab', {
          entity: viewer?.entity,
        });
        await stopAgent({
          variables: {
            agentId: stoppingAgentId,
            stopPolling: true,
          },
        });
      } else if (deletingAgentId != null) {
        window.analytics?.track('deleted launch agent from agent tab', {
          entity: viewer?.entity,
        });
        await deleteAgents({
          variables: {
            agentIds: [deletingAgentId],
          },
        });
      }
      setStoppingAgentId(null);
      setDeletingAgentId(null);
      setDeletingAllStoppedAgents(false);
      await launchAgentsQuery.refetch();
      setDeletingOrStopping(false);
    },
    [
      deletingAllStoppedAgents,
      agentsToBatchDelete,
      stoppingAgentId,
      deletingAgentId,
      launchAgentsQuery,
      viewer,
      deleteAgents,
      stopAgent,
    ]
  );

  if (deletingOrStopping) {
    return <Loader name="launch-agent-tab" />;
  }

  return (
    <>
      {(stoppingAgentId != null ||
        deletingAgentId != null ||
        deletingAllStoppedAgents) && (
        <AgentModal
          open
          infoString={modalInfoString}
          buttonString={modalButtonString}
          onClick={onModalClick}
          onClose={() => {
            setStoppingAgentId(null);
            setDeletingAgentId(null);
            setDeletingAllStoppedAgents(false);
          }}
        />
      )}

      <S.LaunchAgentTable
        data={rows}
        columns={columns}
        pageSize={20}
        defaultSorted={[{id: 'startedAt', desc: true}]}
      />
      {agentsToBatchDelete != null && agentsToBatchDelete.length > 0 && (
        <S.BatchDeleteAgentsInfo>
          Agents that have been killed for 7 days will automatically be
          deleted.&nbsp;
          <S.BatchDeleteAgents
            onClick={() => setDeletingAllStoppedAgents(true)}>
            Delete killed agents now.
          </S.BatchDeleteAgents>
        </S.BatchDeleteAgentsInfo>
      )}
    </>
  );
};

interface AgentActionsProps {
  agentId: string;
  agentStatus: string;
  setStoppingAgentId: (id: React.SetStateAction<string | null>) => void;
  setDeletingAgentId: (id: React.SetStateAction<string | null>) => void;
}

const AgentActions: React.FC<AgentActionsProps> = ({
  agentId,
  agentStatus,
  setStoppingAgentId,
  setDeletingAgentId,
}) => {
  if (agentStatus === 'stopping') {
    return (
      <Popup
        content={`The agent will finish running all current runs then shut down`}
        inverted
        size="mini"
        position="top center"
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
        trigger={<S.PopupCellText>Killing agent...</S.PopupCellText>}
      />
    );
  }
  const iconName = agentStatus === 'killed' ? 'delete' : 'stop';
  const hoverText =
    agentStatus === 'killed'
      ? 'Delete this agent and all associated run queue items.'
      : 'Stopping the agent will stop all current jobs and prevent the agent from picking up new jobs.';
  const iconFn =
    agentStatus === 'killed' ? setDeletingAgentId : setStoppingAgentId;
  return (
    <Popup
      content={hoverText}
      inverted
      size="mini"
      position="top center"
      popperModifiers={{
        preventOverflow: {
          boundariesElement: 'offsetParent',
        },
      }}
      trigger={
        <WBIcon
          size="huge"
          style={{cursor: 'pointer'}}
          name={iconName}
          onClick={async (e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            iconFn(agentId);
          }}
        />
      }
    />
  );
};

interface AgentModalProps {
  open: boolean;
  buttonString: string;
  infoString: string;
  onClick(e: React.SyntheticEvent): void;
  onClose(): void;
}

const AgentModal: React.FC<AgentModalProps> = ({
  open,
  infoString,
  buttonString,
  onClick,
  onClose,
}) => {
  return (
    <Modal className="stop-agent-modal" open={open} onClose={onClose}>
      <Modal.Content>
        <p>{infoString}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          color="red"
          onClick={e => {
            onClick(e);
            onClose();
          }}>
          {buttonString}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface LaunchAgentTabProps {
  runQueues: RunQueue[];
  launchAgentsQuery: QueryResult<FetchLaunchAgentsFromProjectQuery>;
  projectName: string;
  entityName: string;
  viewer?: Viewer;
}

const LaunchAgentTab: React.FC<LaunchAgentTabProps> = ({
  runQueues,
  launchAgentsQuery,
  viewer,
  projectName,
  entityName,
}) => {
  useEffect(() => {
    window.analytics?.track('viewed launch agents tab', {
      entity: viewer?.entity,
      sourceEntity: entityName,
      sourceProject: projectName,
    });
  }, [entityName, projectName, viewer]);
  return (
    <AgentTable runQueues={runQueues} launchAgentsQuery={launchAgentsQuery} />
  );
};

export default LaunchAgentTab;
