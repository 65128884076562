import {Subset} from '@wandb/common/types/base';
import React, {useCallback, useState} from 'react';
import {MutationFetchResult} from 'react-apollo';
import {Divider, Input, List, Message, Progress} from 'semantic-ui-react';

import {
  ClaimAnonymousEntityComponent,
  ClaimAnonymousEntityMutation,
  GetTaskComponent,
  Task,
  TaskState,
} from '../generated/graphql';
import {User} from '../types/graphql';
import {propagateErrorsContext} from '../util/errors';

export type ClaimTask = Subset<Task, 'id' | 'name'>;

interface AnonymousEntityClaimerProps {
  viewer: User;
  refetch: any;
}

const AnonymousEntityClaimer: React.FC<AnonymousEntityClaimerProps> =
  React.memo(({viewer, refetch}) => {
    const [claimApiKey, setClaimApiKey] = useState('');
    const [claims, setClaims] = useState<ClaimTask[]>([]);
    const [claimErrorHeader, setClaimErrorHeader] = useState<
      string | undefined
    >(undefined);
    const [claimErrorMessage, setClaimErrorMessage] = useState<
      string | undefined
    >(undefined);

    const addClaim = useCallback(
      (res: MutationFetchResult<ClaimAnonymousEntityMutation>): void => {
        if (!res || !res.data || !res.data.claimAnonymousEntity) {
          return;
        }

        const {task} = res.data.claimAnonymousEntity;
        setClaims(prev => prev.concat(task));
      },
      []
    );

    const removeClaim = useCallback(
      (claimTaskID: string): void => {
        setClaims(claims.filter(filterTask => filterTask.id !== claimTaskID));
      },
      [claims]
    );

    return (
      <ClaimAnonymousEntityComponent
        context={propagateErrorsContext()}
        onError={() => {
          setClaimErrorHeader('Invalid API Key');
          setClaimErrorMessage(
            `Could not find an anonymous account with API key '${claimApiKey}'.`
          );
        }}>
        {claimMutation => (
          <React.Fragment>
            <List>
              {viewer.userEntity.claimedEntities.edges.map((edge, idx) => {
                return (
                  <React.Fragment key={edge.node.id}>
                    <List.Item>
                      <List.Icon name="question" />
                      <List.Content>{edge.node.name}</List.Content>
                    </List.Item>
                    {(idx !==
                      viewer.userEntity.claimedEntities.edges.length - 1 ||
                      claims.length > 0) && <Divider />}
                  </React.Fragment>
                );
              })}
              {claims.map((claimTask, idx) => {
                return (
                  <React.Fragment key={claimTask.id}>
                    <List.Item>
                      <List.Icon name="question" />
                      <List.Content>
                        <AnonymousEntityClaim
                          task={claimTask}
                          onFinished={() => {
                            removeClaim(claimTask.id);
                            refetch();
                          }}
                          onError={() => {
                            removeClaim(claimTask.id);
                            refetch();
                          }}
                        />
                      </List.Content>
                    </List.Item>
                    {idx !== claims.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </List>
            <Input
              fluid
              onChange={e => {
                setClaimErrorHeader(undefined);
                setClaimErrorMessage(undefined);
                setClaimApiKey(e.currentTarget.value);
              }}
              action={{
                content: 'Claim',
                onClick: () => {
                  claimMutation({
                    variables: {anonymousApiKey: claimApiKey},
                  })
                    .then(addClaim)
                    .then(refetch);
                },
              }}
              value={claimApiKey}
              placeholder={'Enter the API key of an anonymous account'}
            />
            {claimErrorHeader && (
              <Message negative>
                <Message.Header>{claimErrorHeader}</Message.Header>
                <p>{claimErrorMessage}</p>
              </Message>
            )}
          </React.Fragment>
        )}
      </ClaimAnonymousEntityComponent>
    );
  });

export default AnonymousEntityClaimer;

interface AnonymousEntityClaimProps {
  task: ClaimTask;
  onFinished: () => void;
  onError: () => void;
}

export const AnonymousEntityClaim: React.FC<AnonymousEntityClaimProps> =
  React.memo(({task, onFinished, onError}) => {
    return (
      <GetTaskComponent
        pollInterval={500}
        onError={onError}
        variables={{id: task.id}}>
        {({loading, data}) => {
          if (loading) {
            return null;
          }

          const {progress, state, name} = data!.task!;
          const success = state === TaskState.Finished;
          const failure = state === TaskState.Failed;

          if (state === TaskState.Finished) {
            onFinished();
          } else if (state === TaskState.Failed) {
            onError();
          }

          return (
            <React.Fragment>
              {name}
              <Progress
                percent={progress}
                success={success}
                error={failure}
                attached={'bottom'}
              />
            </React.Fragment>
          );
        }}
      </GetTaskComponent>
    );
  });
