import React from 'react';
import {Card} from 'semantic-ui-react';

interface HtmlViewerProps {
  headerElements?: JSX.Element[];
  notFoundElements?: React.ReactNode;
  htmlNotFound?: boolean;
  height?: number;
  src?: string;
}

// a single image card
const HtmlViewer = (props: HtmlViewerProps) => {
  const {headerElements, notFoundElements, height, htmlNotFound, src} = props;

  return (
    <div style={{padding: '10px', height: '100%', width: '100%'}}>
      <Card className="html-card" style={{width: '100%'}}>
        {headerElements}

        {!!htmlNotFound ? (
          notFoundElements
        ) : (
          <iframe
            title="Html card"
            sandbox="allow-same-origin allow-scripts"
            style={{border: 'none', height: (height ? height : 300) - 50}}
            src={src}
          />
        )}
      </Card>
    </div>
  );
};

export default HtmlViewer;
