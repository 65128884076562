import {TextField} from '@material-ui/core';
import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

export const Container = styled.div<{selected: boolean}>`
  ${p =>
    p.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;

export const EditContainer = styled.div`
  background-color: ${globals.GRAY_TRANSPARENT};
  padding: 24px;
  margin-bottom: 24px;
`;

export const EditMessage = styled.div`
  font-style: italic;
  margin-bottom: 24px;
`;

export const EditAutocompleteContainer = styled.div`
  width: 300px;
`;

export const EditTextField = styled(TextField)``;

export const Banner = styled.div``;
