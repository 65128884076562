import {WBSuggesterOptionFetcher} from '@wandb/common/components/elements/WBSuggester';
import {WBMenuOption} from '@wandb/ui';
import React from 'react';

import * as ApiSchemaTypes from '../../generated/apiSchema';
import * as Update from '../../util/update';
import {getDefaultArgFromSchemaArgType, valueCharCount} from '../../util/vega3';
import * as S from './InlineListEditor.styles';
import PlusButton from './PlusButton';
import * as QueryEditorStyles from './QueryEditor.styles';
import QueryEditorDeleteButton from './QueryEditorDeleteButton';
import QueryInputValueEditor from './QueryInputValueEditor';

interface InlineListEditorProps {
  type: ApiSchemaTypes.__Type;
  val: any[];
  autocompleteOptions?: WBMenuOption[] | WBSuggesterOptionFetcher;
  indentLevel: number;
  charsBefore: number;
  setVal(newVal: any[]): void;
}

const InlineListEditor: React.FC<InlineListEditorProps> = props => {
  const expandedView =
    props.indentLevel * 2 + props.charsBefore + valueCharCount(props.val) >
    QueryEditorStyles.MAX_CHARS_PER_LINE;
  const [hoveringDeleteIndex, setHoveringDeleteIndex] = React.useState(-1);
  const val = props.val || [];
  return (
    <>
      [
      <S.ItemsWrapper expanded={expandedView}>
        {val.map((v, i) => (
          <>
            <S.ItemWrapper
              expanded={expandedView}
              key={i} /* not really correct key; consider fixing */
            >
              <QueryEditorDeleteButton
                onMouseEnter={() => setHoveringDeleteIndex(i)}
                onMouseLeave={() => setHoveringDeleteIndex(-1)}
                onClick={() => {
                  props.setVal(Update.deleteArrayIndex(val, i));
                  setHoveringDeleteIndex(-1);
                }}></QueryEditorDeleteButton>
              <QueryEditorStyles.Fadeable fade={hoveringDeleteIndex === i}>
                <QueryInputValueEditor
                  val={v}
                  type={props.type.ofType!}
                  indentLevel={props.indentLevel}
                  charsBefore={expandedView ? 0 : props.charsBefore}
                  setVal={innerV =>
                    props.setVal(Update.updateArrayIndex(val, i, innerV))
                  }
                  autocompleteOptions={props.autocompleteOptions}
                />
              </QueryEditorStyles.Fadeable>
            </S.ItemWrapper>
            {i < val.length - 1 ? ', ' : ''}
            {expandedView && <br />}
          </>
        ))}
        {val.length > 0 && ' '}
        <PlusButton
          onClick={() =>
            props.setVal([
              ...val,
              getDefaultArgFromSchemaArgType(props.type.ofType!),
            ])
          }></PlusButton>
      </S.ItemsWrapper>
      ]
    </>
  );
};

export default InlineListEditor;
