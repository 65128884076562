import React from 'react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import {Panel2Loader} from './PanelComp';
import EditableField from '@wandb/common/components/EditableField';
import * as S from './PanelString.styles';
import {toWeaveType} from './PanelGroup2';
import * as Op from '@wandb/cg';

const inputType = 'string' as const;
type PanelStringEditorProps = Panel2.PanelProps<typeof inputType>;

export const PanelStringEditor: React.FC<PanelStringEditorProps> = props => {
  const updateVal = CGReact.useAction(
    Op.constNodeUnsafe(toWeaveType(props.input), props.input),
    'set'
  );
  const nodeValueQuery = CGReact.useNodeValue(props.input);
  if (nodeValueQuery.loading) {
    return <Panel2Loader />;
  }
  const fullStr = String(
    nodeValueQuery.result == null ? '-' : nodeValueQuery.result
  );

  return (
    <S.StringContainer>
      <EditableField
        value={fullStr}
        placeholder="-"
        save={val => updateVal({val: Op.constString(val)})}
      />
    </S.StringContainer>
  );
};

export const Spec: Panel2.PanelSpec = {
  hidden: false,
  id: 'string-editor',
  Component: PanelStringEditor,
  inputType,
};
