import {toast} from '@wandb/common/components/elements/Toast';
import React, {FC, useCallback, useMemo, useState} from 'react';
import {Button, Icon, InputOnChangeData, Modal} from 'semantic-ui-react';

import {usePurgeUserMutation} from '../generated/graphql';
import {auth} from '../setup';
import {propagateErrorsContext} from '../util/errors';
import * as S from './DeleteUserConfirmationModal.style';

interface DeleteUserConfirmationModalProps {
  isOpen: boolean;
  username: string;
  email: string;
  onClose: () => void;
}

export const DeleteUserConfirmationModal: FC<DeleteUserConfirmationModalProps> =
  ({isOpen, username, email, onClose}) => {
    const [confirmationInput, setConfirmationInput] = useState('');
    const onDeleteUserConfirmationChange = useCallback(
      (_, data: InputOnChangeData) => {
        setConfirmationInput(data.value);
      },
      [setConfirmationInput]
    );
    const isDeleteDisabled = useMemo(
      () => confirmationInput !== username,
      [confirmationInput, username]
    );

    const [purgeUser, {loading}] = usePurgeUserMutation({
      variables: {username, email},
      context: propagateErrorsContext(),
      onCompleted: () => {
        window.analytics?.track('User Deleted', {
          username,
          email,
        });
        auth.logout();
      },
      onError: err => {
        window.analytics?.track('User Delete Failed', {
          username,
          email,
        });
        toast('Unable to delete account');
        console.error('Error deleting account', err);
      },
    });

    if (!isOpen) {
      return null;
    }

    return (
      <Modal open onClose={onClose} size="small">
        <Modal.Header>
          <Icon name="exclamation triangle" />
          Delete Account
        </Modal.Header>
        <Modal.Content>
          {loading ? (
            <p>Account deletion in progress...</p>
          ) : (
            <>
              <p>
                Are you sure you want to <b>DELETE</b> the account{' '}
                <b>{username}</b>?
              </p>
              <p>
                <b>This action cannot be undone.</b>
              </p>
              <p>To proceed, please type the username below.</p>
              <S.DeleteUserInput
                placeholder={username}
                onChange={onDeleteUserConfirmationChange}
              />
            </>
          )}
        </Modal.Content>
        {!loading && (
          <Modal.Actions>
            <Button onClick={onClose}>Nevermind</Button>
            <Button
              onClick={() => purgeUser()}
              color="red"
              disabled={isDeleteDisabled}>
              Delete
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  };
