import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import Editor from '@wandb/common/components/Monaco/Editor';
import {WBMenuOption} from '@wandb/ui';
import React from 'react';

import {useVegaPanelQuery, View} from '../../state/graphql/vega2PanelQuery';
import {useRunsQueryContext} from '../../state/runs/hooks';
import {getVegaPanelDef} from '../../util/vega3';
import {InspectorPropertyWrapper} from '../Inspector.styles';
import InspectorPopout from '../InspectorPopout';
import * as BuiltinPanelDefs from '../Vega2/builtinPanelDefs';
import {PropertyEditorComponent, VegaSpecInfo} from './property-editors';
import * as S from './VegaSpecPropertyEditor.styles';

interface InnerProps {
  propertyName: string;
  displayedVal?: VegaSpecInfo;
  views: View[];
  openedPopout: string | null;
  setOpenedPopout: React.Dispatch<React.SetStateAction<string | null>>;
  save: (val: VegaSpecInfo | undefined) => void;
}

const VegaSpecPropertyEditorInner: React.FC<InnerProps> = props => {
  const [wrapperNode, setWrapperNode] = React.useState<HTMLElement | null>(
    null
  );
  const [currentSpec, setCurrentSpec] = React.useState('');
  React.useEffect(() => {
    const spec =
      props.displayedVal == null
        ? ''
        : getVegaPanelDef(props.displayedVal, props.views)?.spec;
    setCurrentSpec(spec ? spec.toString() : '');
  }, [props.displayedVal, props.views]);

  if (props.displayedVal == null) {
    return <InspectorPropertyWrapper>Mixed</InspectorPropertyWrapper>;
  }
  let options: WBMenuOption[] = props.views.map(vegaView => {
    const optID = 'lib:' + vegaView.id;
    return {
      value: optID,
      text: vegaView.name,
    };
  });
  options = options.concat(
    BuiltinPanelDefs.IDS.map(id => ({
      value: id,
      name: '[W&B builtin] ' + BuiltinPanelDefs.getPanelDef(id)!.name,
    }))
  );
  if (!props.displayedVal.panelDefId) {
    return <InspectorPropertyWrapper>null</InspectorPropertyWrapper>;
  }
  return (
    <InspectorPropertyWrapper ref={node => setWrapperNode(node)}>
      <S.Wrapper>
        <S.SelectWrapper>
          <S.Select
            options={options}
            value={props.displayedVal.panelDefId}
            onSelect={val =>
              props.save({...props.displayedVal, panelDefId: val as string})
            }
          />
          <S.NewSpecButton>+</S.NewSpecButton>
        </S.SelectWrapper>
        <S.TextAreaWrapper editing={props.openedPopout === props.propertyName}>
          <S.TextArea rows={3} value={currentSpec} />
          <S.EditButton
            onClick={e => {
              e.stopPropagation();
              props.setOpenedPopout(o =>
                o === props.propertyName ? null : props.propertyName
              );
            }}>
            <LegacyWBIcon name="edit" />
          </S.EditButton>
        </S.TextAreaWrapper>
      </S.Wrapper>
      {props.openedPopout === props.propertyName && wrapperNode && (
        <InspectorPopout anchor={wrapperNode}>
          <S.EditorWrapper>
            <Editor
              value={currentSpec}
              onChange={(value: string) => setCurrentSpec(value)}
              language="json"
            />
          </S.EditorWrapper>
        </InspectorPopout>
      )}
    </InspectorPropertyWrapper>
  );
};

/**
 * For vega3.
 * @param props
 */
const VegaSpecPropertyEditor: PropertyEditorComponent<'vega-spec'> = props => {
  const displayedVal = props.values.length === 1 ? props.values[0] : undefined;
  const context = useRunsQueryContext();
  const vegaQuery = useVegaPanelQuery({
    entityName: context.entityName,
    projectName: context.projectName,
    viewId: displayedVal?.panelDefId,
  });
  if (vegaQuery.loading) {
    return <InspectorPropertyWrapper>Loading</InspectorPropertyWrapper>;
  }
  return (
    <VegaSpecPropertyEditorInner
      propertyName={props.propertyName}
      displayedVal={displayedVal}
      views={vegaQuery.views}
      openedPopout={props.openedPopout}
      setOpenedPopout={props.setOpenedPopout}
      save={props.save}
    />
  );
};

export default VegaSpecPropertyEditor;
