import React, {FC} from 'react';
import {RenderLeafProps} from 'slate-react';

import {getMarkKeyFromNode} from '../plugins/inline-comment';
import {TextWithFormatting} from './types';
import * as S from './WBSlate.styles';

export const LeafNode: FC<RenderLeafProps & {leaf: TextWithFormatting}> = ({
  attributes,
  children,
  leaf,
}) => {
  // this id is for scrolling to the text node from inline text comments
  const id = getMarkKeyFromNode(leaf);
  return (
    <S.Leaf
      id={id}
      strong={leaf.strong}
      emphasis={leaf.emphasis}
      inlineCode={leaf.inlineCode}
      underline={leaf.underline}
      delete={leaf.delete}
      codeComment={leaf.codeComment}
      inlineComment={leaf.inlineComment}
      activeInlineComment={leaf.activeInlineComment}
      tempInlineComment={leaf.tempInlineComment}
      operator={leaf.operator}
      url={leaf.url}
      keyword={leaf.keyword}
      variable={leaf.variable}
      regex={leaf.regex}
      number={leaf.number}
      boolean={leaf.boolean}
      tag={leaf.tag}
      constant={leaf.constant}
      symbol={leaf.symbol}
      attr={leaf.attr}
      selector={leaf.selector}
      punctuation={leaf.punctuation}
      string={leaf.string}
      char={leaf.char}
      function={leaf.function}
      isClass={leaf.class}
      onClick={leaf.onClick}
      {...attributes}>
      {children}
    </S.Leaf>
  );
};
