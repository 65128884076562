import * as globals from '@wandb/common/css/globals.styles';
import {Link} from '@wandb/common/util/links';
import {WBIcon} from '@wandb/ui';
import {
  Button as SemanticButton,
  Input as SemanticInput,
} from 'semantic-ui-react';
import styled, {css} from 'styled-components';

export const CRAIYON_BACKGROUND_COLOR = `rgb(17, 24, 39)`;

export const Container = styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${CRAIYON_BACKGROUND_COLOR};
  border: 1px solid ${globals.border};
  border-radius: 8px;
  max-width: 666px;
  margin: 0 auto;

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;

export const DownloadArea = styled.div`
  width: 100%;
  padding: 16px;
`;

export const DownloadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CraiyonLink = styled(Link)``;

export const CraiyonLogo = styled.img`
  max-width: 260px;
  margin: 16px 0;
`;

export const InputContainer = styled.form`
  display: flex;
  width: 100%;
`;

export const Input = styled(SemanticInput)`
  flex-grow: 1;
`;

export const Button = styled(SemanticButton)`
  &&& {
    margin-left: 16px;
  }
`;

export const ImageGrid = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 31.65%;
  margin-top: 2.53%;
`;

export const DownloadButton = styled(SemanticButton)`
  &&& {
    margin-bottom: 16px;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 7.5% 0 5%;
`;

export const ProgressBar = styled.div`
  border: 2px solid ${globals.white};
  width: 50%;
  margin-bottom: 1%;

  height: 32px;
  @media (max-width: 600px) {
    height: 28px;
  }
  @media (max-width: 500px) {
    height: 24px;
  }
  @media (max-width: 400px) {
    height: 22px;
  }
`;

export const ProgressBarFill = styled.div<{progress: number}>`
  background-color: ${globals.white};
  height: 100%;
  width: ${p => p.progress}%;

  transition: width 0.5s;
`;

export const ProgressBarMessage = styled.div`
  margin-top: 2.5%;
  font-weight: 600;
  color: ${globals.white};

  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export const ProgressBarMessageIcon = styled(WBIcon)`
  position: relative;
  top: 2px;
`;

export const CancelButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;

export const CancelButton = styled(SemanticButton)``;

export const Disclaimer = styled.div`
  padding: 16px;
  color: ${globals.white};
  font-style: italic;
  text-align: center;
`;
