import config, {envIsCloudOnprem, envIsLocal} from '@wandb/common/config';
import {ApolloQueryResult} from 'apollo-client';
import {compact} from 'lodash';
import qs from 'query-string';
import React, {FC, useEffect, useMemo} from 'react';
import {RouteComponentProps} from 'react-router';
import {Container, Header, Placeholder, Segment} from 'semantic-ui-react';

import {
  useLatestLocalVersionInfoQuery,
  useUpdateUserMutation,
} from '../../generated/graphql';
import {
  GetViewerQueryResponse,
  withViewer,
} from '../../graphql/users_get_viewer';
import {User} from '../../types/graphql';
import {viewingAs} from '../../util/admin';
import {useScrollToURLHash} from '../../util/document';
import {flashFocus} from '../../util/flash';
import {trackPageViewed} from '../../util/navigation';
import {UserSettingsAlerts} from './UserSettingsAlerts';
import {
  UserSettingsBetaFeatures,
  UserSettingsBetaFeaturesAdmin,
} from './UserSettingsBetaFeatures';
import {UserSettingsClaimedAccounts} from './UserSettingsClaimedAccounts';
import {UserSettingsContextProvider} from './UserSettingsContext';
import {UserSettingsDangerZone} from './UserSettingsDangerZone';
import {UserSettingsEmails} from './UserSettingsEmails';
import {UserSettingsLocalAccountDetails} from './UserSettingsLocalAccountDetails';
import {UserSettingsPersonalGithub} from './UserSettingsPersonalGithub';
import {UserSettingsPolicies} from './UserSettingsPolicies';
import {UserSettingsProfile} from './UserSettingsProfile';
import {UserSettingsProjectDefaults} from './UserSettingsProjectDefaults';
import {UserSettingsStorage} from './UserSettingsStorage';
import {UserSettingsTeams} from './UserSettingsTeams';

interface UserSettingsPageProps extends RouteComponentProps {
  viewer: User; // from withViewer()
  viewerLoading: boolean; // from withViewer()
  refetch: ((
    variables?: {} | undefined
  ) => Promise<ApolloQueryResult<GetViewerQueryResponse>>) &
    (() => void); // from withViewer()
}

const UserSettingsPage: FC<UserSettingsPageProps> = React.memo(
  ({viewer, viewerLoading, refetch}) => {
    // for page view tracking
    useEffect(() => {
      trackPageViewed('Settings');
    }, []);

    const [updateUser] = useUpdateUserMutation();

    useEffect(() => {
      if (viewerLoading || !viewer) {
        return;
      }

      // updateUser will fail if in impersonating mode so just return here
      if (viewingAs()) {
        return;
      }

      const qsParsed = qs.parse(window.location.search);
      if (qsParsed.focusNewTeam !== undefined) {
        // Remove all variables from URL.
        window.history.replaceState(null, '', window.location.pathname);

        const el = document.querySelector('.create-team-button');
        if (el != null) {
          flashFocus(el, {
            minSpaceFromBottom: 200,
            popping: true,
            borderRadius: 5,
          });
        }
      }

      updateUser({variables: {settingsVisited: true}});
    }, [updateUser, viewer, viewerLoading]);

    const allSettings: Array<[string, FC]> = useMemo(
      () =>
        compact([
          ['Profile', UserSettingsProfile],
          envIsLocal || envIsCloudOnprem
            ? ['Account Details', UserSettingsLocalAccountDetails]
            : ['Emails', UserSettingsEmails],
          ['Project Defaults', UserSettingsProjectDefaults],
          ['Teams', UserSettingsTeams],
          ['Beta Features', UserSettingsBetaFeatures],
          !envIsLocal &&
            !envIsCloudOnprem &&
            viewer?.admin && [
              'W&B Internal Beta Features',
              UserSettingsBetaFeaturesAdmin,
            ],
          ['Alerts', UserSettingsAlerts],
          !config.ENVIRONMENT_IS_PRIVATE && [
            'Personal Github Integration',
            UserSettingsPersonalGithub,
          ],
          ['Danger Zone', UserSettingsDangerZone],
          ['Anonymous Accounts', UserSettingsClaimedAccounts],
          ['Storage', UserSettingsStorage],
          ['Policies', UserSettingsPolicies],
        ]),
      [viewer]
    );

    // Auto-scroll to component if url contains urlHash, e.g. #betafeatures
    useScrollToURLHash();

    return (
      <div className="settings-page">
        <UserSettingsContextProvider viewer={viewer} refetch={refetch}>
          <Container text>
            <Header as="h1">User Settings</Header>
            <UserSettingsLocalVersion />
            {allSettings.map(([name, SettingsComponent], i) => {
              // urlHash enables useScrollToURLHash() above (direct linking to e.g. wandb.ai/settings#dangerzone)
              const urlHash = name.toLowerCase().replace(/ /g, '');
              return (
                <UserSettingsSection key={i} header={name} elementId={urlHash}>
                  <SettingsComponent />
                </UserSettingsSection>
              );
            })}
          </Container>
        </UserSettingsContextProvider>
      </div>
    );
  }
);

export const UserSettingsSection: FC<{
  header: string;
  elementId?: string; // used with useScrollToURLHash()
}> = ({header, elementId, children}) => {
  return (
    <Segment id={elementId}>
      <Header as="h2">{header}</Header>
      {children}
    </Segment>
  );
};

export const UserSettingsPlaceholder: FC = () => {
  return (
    <Placeholder fluid>
      <Placeholder.Header image>
        <Placeholder.Line length={'full'} />
        <Placeholder.Line length={'full'} />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line length="medium" />
        <Placeholder.Line length="short" />
      </Placeholder.Paragraph>
    </Placeholder>
  );
};

export const UserSettingsLocalVersion: FC = () => {
  const {data} = useLatestLocalVersionInfoQuery();

  const version =
    data?.serverInfo?.latestLocalVersionInfo?.versionOnThisInstanceString;

  const isOnPrem = envIsLocal || envIsCloudOnprem;
  const hasVersion = version != null;
  if (!isOnPrem || !hasVersion) {
    return null;
  }

  return (
    <Header as="h2">
      W&amp;B Local <span>{version}</span>
    </Header>
  );
};

export default withViewer(UserSettingsPage);
