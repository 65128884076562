import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import * as Filter from '../../util/filters';
import * as Panels from '../../util/panels';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import {MultiRunTableConfig} from './common';
export type {MultiRunTableConfig};

const PANEL_TYPE = 'Multi Run Table';

function transformQuery(query: Query.Query, config: MultiRunTableConfig) {
  const result = toRunsDataQuery(query, {selectionsAsFilters: true});
  const extraFilters: Array<Filter.Filter<Run.Key>> = [];
  result.page = {
    size: 100,
  };
  if (config.tableKey) {
    result.page.size = 20;
    const key = Run.keyFromString(config.tableKey);
    if (key && (key.section === 'config' || key.section === 'summary')) {
      extraFilters.push({key, op: '!=', value: null});
    }
  }
  if (extraFilters.length > 0 && result.queries.length > 0) {
    result.queries[0].filters = Filter.And([
      result.queries[0].filters,
      ...extraFilters,
    ]);
  }
  result.summaryKeys = config.tableKey
    ? [config.tableKey.split(':')[1]]
    : undefined;
  return result;
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, MultiRunTableConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
};
