import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import * as Panels from '../../util/panels';
import * as Query from '../../util/queryts';
import {useSampleAndQueryToTable} from '../Export';
import {RunComparerConfig} from './common';

const PANEL_TYPE = 'Run Comparer';

const runComparerTransformQuery = (
  query: Query.Query,
  config: RunComparerConfig
) => {
  const transformed = toRunsDataQuery(
    query,
    {
      selectionsAsFilters: true,
      disableGrouping: config.useRunsTableGroupingInPanels === false,
    },
    {fullConfig: true, fullSummary: true}
  );
  return transformed;
};

const useTableData = (pageQuery: Query.Query, config: RunComparerConfig) => {
  const query = toRunsDataQuery(
    pageQuery,
    {
      selectionsAsFilters: true,
      disableGrouping: config.useRunsTableGroupingInPanels === false,
    },
    {fullConfig: true, fullSummary: true}
  );
  // TODO: config is not updating, useRunsTableGroupingInPanels is always false
  // this means ungrouped panel comparer panels don't export csv properly
  return useSampleAndQueryToTable(query, pageQuery, config);
};

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, RunComparerConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery: runComparerTransformQuery,
  noEditMode: true,

  exportable: {
    csv: true,
    api: true,
  },

  useTableData,
  icon: 'panel-run-comparer',
};
