import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

export const EmbeddedIframe = styled.iframe<{
  selected: boolean;
}>`
  display: block;
  margin: 0 auto;
  height: 800px;

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;

export const WarningContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Warning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  padding: 16px;
  margin-bottom: 16px;
  text-align: center;
  color: ${globals.error};
  background-color: ${globals.errorBackground};
  border: 2px solid ${globals.errorBorder};
`;

export const WarningHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const WarningListContainer = styled.div`
  text-align: left;
`;

export const WarningList = styled.ul`
  margin: 0;
  padding-left: 22px;
`;

export const WarningListItem = styled.li``;
