import {Node, opArtifactVersionFile, opRunLink, OutputNode} from '@wandb/cg';
import {
  constFunction,
  constString,
  opArtifactAliasAlias,
  opArtifactAliases,
  opArtifactId,
  opArtifactMembershipArtifactAliases,
  opArtifactMembershipArtifactVersion,
  opArtifactMembershipCollection,
  opArtifactMemberships,
  opArtifactMembershipVersionIndex,
  opArtifactName,
  opArtifactProject,
  opArtifactType,
  opArtifactTypeName,
  opArtifactVersionCreatedAt,
  opArtifactVersionDescription,
  opArtifactVersionDigest,
  opArtifactVersionId,
  opArtifactVersionUsedBy,
  opDict,
  opEntityName,
  opFileContents,
  opMap,
  opProjectEntity,
  opProjectName,
  opRunConfig,
  opRunCreatedAt,
  opRunId,
} from '@wandb/cg';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {TargetBlank} from '@wandb/common/util/links';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {Buffer} from 'buffer';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {CellInfo, Column} from 'react-table';
import {Grid} from 'semantic-ui-react';

import * as Generated from '../../../../../generated/graphql';
import * as urls from '../../../../../util/urls';
import WBReactTable from '../../../../WBReactTable';
import * as S from './style';

interface GitSourceDict {
  git: {
    remote: string;
    commit: string;
  };
  entrypoint: string[];
  args: string[];
}

interface ArtifactSourceDict {
  artifact: string;
  entrypoint: string[];
  args: string[];
}

interface ImageSourceDict {
  image: string;
  args: string[];
}

interface TypedDictFromSDK {
  wb_type: string;
  params?: {type_map: TypedDictFromSDK};
}

interface CleanedTypeDict {
  [key: string]: string | CleanedTypeDict;
}

interface SourceInfo {
  // TODO: handle source with generics
  _version: string;
  source_type: string;
  source: GitSourceDict | ArtifactSourceDict | ImageSourceDict;
  input_types: TypedDictFromSDK;
  output_types: TypedDictFromSDK;
}

function collectInputAndOutputStructures(
  structure: TypedDictFromSDK
): CleanedTypeDict {
  const collectedVals: CleanedTypeDict = {};
  for (const [key, val] of Object.entries(structure)) {
    if (key.startsWith('_')) {
      continue;
    }
    if ('params' in val && 'type_map' in val.params) {
      collectedVals[key] = collectInputAndOutputStructures(val.params.type_map);
    } else {
      collectedVals[key] = val.wb_type as string;
    }
  }
  return collectedVals;
}

function convertTypeStructToDisplayInner(input: CleanedTypeDict) {
  return Object.entries(input).map(([key, val], index) => {
    if (typeof val === 'string') {
      return (
        <S.InputOutputDiv key={`${key}-${index}`}>
          <b>{key}</b>: {val}
        </S.InputOutputDiv>
      );
    } else {
      return (
        <S.InputOutputDiv key={`${key}-${index}`}>
          <b>{key}</b>: {convertTypeStructToDisplay(val)}
        </S.InputOutputDiv>
      );
    }
  });
}

function convertTypeStructToDisplay(input: CleanedTypeDict) {
  return <code>{convertTypeStructToDisplayInner(input)}</code>;
}

export type ConfigType = {};
const inputType = 'artifactMembership' as const;
export const PanelJobOverview: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {
    updateSelectedMembershipIdentifier?: (identifier: string) => void;
  }
> = props => {
  const artifactMembershipNode = props.input;
  const artifactVersionNode = React.useMemo(
    () =>
      opArtifactMembershipArtifactVersion({
        artifactMembership: artifactMembershipNode,
      }),
    [artifactMembershipNode]
  );
  const artifactNode = React.useMemo(
    () =>
      opArtifactMembershipCollection({
        artifactMembership: artifactMembershipNode,
      }),
    [artifactMembershipNode]
  );

  const {result, loading} = useNodeValue(
    React.useMemo(
      () =>
        getDataDict(
          artifactNode as any,
          artifactVersionNode as any,
          artifactMembershipNode as any
        ),
      [artifactMembershipNode, artifactNode, artifactVersionNode]
    )
  );

  const fileNode = React.useMemo(
    () =>
      opArtifactVersionFile({
        artifactVersion: artifactVersionNode,
        path: constString('wandb-job.json'),
      }) as Node<{
        type: 'file';
        extension: string;
      }>,
    [artifactVersionNode]
  );
  const contentsNode = React.useMemo(
    () => opFileContents({file: fileNode}),
    [fileNode]
  );

  const contentsValueQuery = useNodeValue(contentsNode);
  const sourceInfo: SourceInfo = JSON.parse(contentsValueQuery.result || '{}');

  if (loading || contentsValueQuery.loading) {
    return <WandbLoader name="artifact-membership" />;
  }
  const inputTypes = collectInputAndOutputStructures(
    sourceInfo.input_types?.params?.type_map || ({} as TypedDictFromSDK)
  );
  const inputTypeDisplayed =
    Object.keys(inputTypes).length > 0
      ? convertTypeStructToDisplay(inputTypes)
      : 'None';
  const outputTypes = collectInputAndOutputStructures(
    sourceInfo.output_types?.params?.type_map || ({} as TypedDictFromSDK)
  );
  const outputTypeDisplayed =
    Object.keys(outputTypes).length > 0
      ? convertTypeStructToDisplay(outputTypes)
      : 'None';
  let sourceElement: any;
  if (sourceInfo.source_type === 'repo') {
    const gitCodeSource: GitSourceDict = sourceInfo.source as GitSourceDict;
    // TODO: this is definitely not the right way to do this
    const remoteURL = gitCodeSource.git.remote
      .replace(':', '/')
      .replace('git@', 'https://')
      .replace('.git', '');
    const remoteWithCommit = `${remoteURL}/tree/${gitCodeSource.git.commit}`;
    sourceElement = (
      <S.SourceBlock>
        <S.SourceDescriptor>Source Code </S.SourceDescriptor>{' '}
        <TargetBlank href={remoteWithCommit}>Repo →</TargetBlank>
      </S.SourceBlock>
    );
  } else if (sourceInfo.source_type === 'artifact') {
    const artifactCodeSource: ArtifactSourceDict =
      sourceInfo.source as ArtifactSourceDict;
    sourceElement = (
      <S.SourceBlock>
        <S.SourceDescriptor>Source Code </S.SourceDescriptor>
        <JobArtifactLinkID artifactString={artifactCodeSource.artifact} />
      </S.SourceBlock>
    );
  } else if (sourceInfo.source_type === 'image') {
    const imageCodeSource: ImageSourceDict =
      sourceInfo.source as ImageSourceDict;
    sourceElement = (
      <S.SourceBlock>
        <S.SourceDescriptor>Source Code </S.SourceDescriptor>
        Source image {imageCodeSource.image}
      </S.SourceBlock>
    );
  } else {
    sourceElement = <>Unrecognized source</>;
  }

  return (
    <S.Overview>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <S.Panel>
              <S.OverviewPanelHeader>
                <S.OverviewPanelHeaderTitle>Input</S.OverviewPanelHeaderTitle>
              </S.OverviewPanelHeader>
              <S.InputOutputPanelContents>
                <>{inputTypeDisplayed}</>
              </S.InputOutputPanelContents>
            </S.Panel>

            <S.Panel>
              <S.OverviewPanelHeader>
                <S.OverviewPanelHeaderTitle>Output</S.OverviewPanelHeaderTitle>
              </S.OverviewPanelHeader>
              <S.InputOutputPanelContents>
                <>
                  {outputTypes
                    ? outputTypeDisplayed
                    : 'This run had no output values'}
                </>
              </S.InputOutputPanelContents>
            </S.Panel>
          </Grid.Column>
          <Grid.Column width={8}>
            <S.SourceBlock>
              <S.SourceDescriptor>Source Run </S.SourceDescriptor>{' '}
              {result.consumersInfo[0]?.runLink != null ? (
                <Link to={result.consumersInfo[0]?.runLink.url}>
                  {result.consumersInfo[0]?.runLink.name}
                </Link>
              ) : (
                <span>Unknown</span>
              )}
            </S.SourceBlock>
            {sourceElement}
            <S.SourceDescriptor>Created Runs</S.SourceDescriptor>
            <S.TableDiv>
              <JobRunsTable runs={result.consumersInfo} />
            </S.TableDiv>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </S.Overview>
  );
};
function getDataDict(
  artifactNode: OutputNode<'artifact'>,
  artifactVersionNode: OutputNode<'artifactVersion'>,
  artifactMembershipNode: OutputNode<'artifactMembership'>
) {
  const artifactTypeNode = opArtifactType({artifact: artifactNode});
  const artifactProjectNode = opArtifactProject({
    artifact: artifactNode,
  });

  const consumersNode = opArtifactVersionUsedBy({
    artifactVersion: artifactVersionNode,
  });

  return opDict({
    artifactVersionId: opArtifactVersionId({
      artifactVersion: opArtifactMembershipArtifactVersion({
        artifactMembership: artifactMembershipNode,
      }),
    }),
    artifactId: opArtifactId({
      artifact: artifactNode,
    }),
    artifactAliases: opArtifactAliasAlias({
      artifactAlias: opArtifactAliases({
        artifact: artifactNode,
      }),
    }),
    artifactTypeName: opArtifactTypeName({
      artifactType: artifactTypeNode,
    }),
    artifactMembershipsMaterialized: opMap({
      arr: opArtifactMemberships({
        artifact: artifactNode,
      }),
      mapFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opDict({
          versionIndex: opArtifactMembershipVersionIndex({
            artifactMembership: row,
          }),
          aliases: opArtifactAliasAlias({
            artifactAlias: opArtifactMembershipArtifactAliases({
              artifactMembership: row,
            }),
          }),
        } as any);
      }),
    }),
    entityName: opEntityName({
      entity: opProjectEntity({
        project: artifactProjectNode,
      }),
    }),
    projectName: opProjectName({
      project: artifactProjectNode,
    }),
    artifactName: opArtifactName({
      artifact: artifactNode,
    }),
    versionIndex: opArtifactMembershipVersionIndex({
      artifactMembership: artifactMembershipNode,
    }),
    versionDescription: opArtifactVersionDescription({
      artifactVersion: artifactVersionNode,
    }),
    digest: opArtifactVersionDigest({
      artifactVersion: artifactVersionNode,
    }),
    createdAt: opArtifactVersionCreatedAt({
      artifactVersion: artifactVersionNode,
    }),
    consumersInfo: opMap({
      arr: consumersNode,
      mapFn: constFunction({row: 'run'}, ({row}) => {
        return opDict({
          runLink: opRunLink({run: row}),
          runId: opRunId({run: row}),
          runCreatedAt: opRunCreatedAt({run: row}),
          runConfig: opRunConfig({run: row}),
        } as any);
      }),
    }),
  } as any) as OutputNode<{
    type: 'typedDict';
    propertyTypes: {
      artifactId: 'string';
      artifactAliases: {type: 'list'; objectType: 'string'};
      artifactMembershipsMaterialized: {
        type: 'list';
        objectType: {
          type: 'typedDict';
          propertyTypes: {
            versionIndex: 'number';
            aliases: {type: 'list'; objectType: 'string'};
          };
        };
      };
      artifactVersionId: 'string';
      artifactTypeName: 'string';
      entityName: 'string';
      projectName: 'string';
      artifactName: 'string';
      versionIndex: 'number';
      versionDescription: 'string';
      consumersInfo: 'any'; // TODO: set type
      numFiles: 'number';
      artifactSize: 'number';
      digest: 'string';
      createdAt: 'any';
    };
  }>;
}

interface RunDict {
  runID: string;
  runName: string;
  runLink: string;
  runCreatedAt: string;
  runConfig: {[key: string]: any};
}

interface JobRunsTableProps {
  runs: RunDict[];
}

const JobRunsTable: React.FC<JobRunsTableProps> = ({runs}) => {
  const nameColumn: Column = {
    Header: 'Name',
    accessor: 'runLink',
    className: 'run-name',
    Cell: (cellInfo: CellInfo) => {
      if (cellInfo.value == null) {
        return null;
      }
      return <Link to={cellInfo.value.url}>{cellInfo.value.name}</Link>;
    },
  };

  const createdAtColumn: Column = {
    Header: 'CreatedAt',
    accessor: 'createdAt',
    className: 'created-at',
    Cell: (cellInfo: CellInfo) => {
      if (cellInfo.value == null) {
        return <></>;
      }

      return <span>{cellInfo.value.toDateString()}</span>;
    },
  };

  // get a list of all the config keys
  const configColumnNames: string[] = [
    ...new Set(runs.map(run => Object.keys(run.runConfig)).flat()),
  ];
  const filteredConfigColumnNames = configColumnNames.filter(
    c => !c.startsWith('_')
  );

  const configColumns = filteredConfigColumnNames.map((col: string) => {
    return {
      Header: col,
      accessor: col,
      className: `config-${col}`,
      Cell: (cellInfo: CellInfo) => {
        if (cellInfo.value == null) {
          return <></>;
        }
        if (typeof cellInfo.value === 'object') {
          return <span>{JSON.stringify(cellInfo.value)}</span>;
        }
        return <span>{cellInfo.value}</span>;
      },
    };
  });
  const columns = [nameColumn, createdAtColumn, ...configColumns];
  const rowData = runs.map(run => {
    const configData = Object.fromEntries(
      filteredConfigColumnNames.map(c => {
        return [c, run.runConfig[c]];
      })
    );
    return {
      row: {
        runLink: run.runLink,
        runID: run.runID,
        createdAt: run.runCreatedAt,
        ...configData,
      },
      searchString: run.runName,
    };
  });

  return <WBReactTable data={rowData} columns={columns} />;
};
interface JobArtifactLinkProps {
  artifactString: string;
}

const useSafeArtifactPathQuery = (artifactId: string) => {
  const v = Buffer.from(artifactId, 'base64').toString();
  const skip = !v.startsWith('Artifact:');
  const {data, loading} = Generated.useArtifactPathInfoQuery({
    variables: {artifactId},
    skip,
  });
  return {data, loading, err: skip};
};

const JobArtifactLinkID: React.FC<JobArtifactLinkProps> = ({
  artifactString,
}) => {
  const splitString = artifactString.split('/');
  const artifactId = splitString[splitString.length - 1];
  const {data, loading, err} = useSafeArtifactPathQuery(artifactId);
  if (err) {
    return <>Source artifact not found</>;
  }
  if (loading || data == null) {
    return <></>;
  }

  const projectName = data.artifact?.artifactType.project.name;
  const entityName = data.artifact?.artifactType.project?.entityName;
  const artifactTypeName = data.artifact?.artifactType.name;
  const artifactCollectionName = data.artifact?.artifactSequence.name;
  const artifactCommitHash = data.artifact?.commitHash;
  if (
    projectName == null ||
    entityName == null ||
    artifactTypeName == null ||
    artifactCollectionName == null ||
    artifactCommitHash == null
  ) {
    return <>Artifact not found</>;
  }
  const linkPath = urls.artifact({
    projectName,
    entityName,
    artifactTypeName,
    artifactCollectionName,
    artifactCommitHash,
  });
  return <Link to={linkPath}>Artifact →</Link>;
};
