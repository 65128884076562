import {DefaultViewer} from '@babylonjs/viewer';
import * as React from 'react';

import {isSafari} from '../util/browser';
import {Media3DProps} from './PointCloud';
import * as S from './Viewer3D.styles';

export const Viewer3D: React.FunctionComponent<Media3DProps> = props => {
  const {url} = props;

  const babylonContainerRef = React.useRef<HTMLDivElement>(null);

  // Load babylon lib asynchronously to perform bundle splitting
  type BabylonLib = typeof import('@wandb/common/util/render_babylon');
  const [babylonLib, setBabylon] = React.useState<BabylonLib>();
  React.useEffect(() => {
    import('@wandb/common/util/render_babylon').then(b => {
      setBabylon(b);
    });
  }, []);

  const viewer = React.useRef<DefaultViewer>();

  // First render of Viewer
  React.useEffect(() => {
    const container = babylonContainerRef.current;
    if (container && babylonLib) {
      // Create viewer on first render
      if (viewer.current == null) {
        viewer.current = babylonLib.renderViewer(container, url);
      } else {
        viewer.current.loadModel(url);
      }
    }
  }, [url, babylonLib]);

  // Cleanup at unmount
  React.useEffect(() => {
    return () => {
      if (viewer.current) {
        viewer.current.dispose();
      }
    };
  }, []);

  return (
    <S.BabylonContainer
      disableFullscreen={isSafari}
      className="object3D-card-babylon"
      ref={babylonContainerRef}
    />
  );
};

export default Viewer3D;
