import {toast} from '@wandb/common/components/elements/Toast';
import {white} from '@wandb/common/css/globals.styles';
import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import copyToClipboard from 'copy-to-clipboard';
import {cloneDeep, isEqual} from 'lodash';
import React, {FC, memo, useContext, useMemo} from 'react';
import {Popup} from 'semantic-ui-react';
import {Editor, Node, Transforms} from 'slate';
import {ReactEditor, useSlateStatic} from 'slate-react';

import {getParentReportURL} from '../../../../util/url';
import {useTyping} from '../../TypingContext';
import * as S from './drag-drop.styles';
import {WBSlateDragDropContext} from './WBSlateDragDropContext';

interface BlockDragHandleProps {
  handleAttributes: any;
  element: Node;
  deepLinkId?: string;
}

const BlockDragHandleComp: FC<BlockDragHandleProps> = ({
  handleAttributes,
  element,
  deepLinkId,
}) => {
  const {typing} = useTyping();
  const editor = useSlateStatic();
  const {parentViewId, dragItem, dragHandleMenuOpen, setDragHandleMenuOpen} =
    useContext(WBSlateDragDropContext);

  const dragging = dragItem != null;

  const menuItems: WBMenuOption[] = useMemo(() => {
    const items: WBMenuOption[] = [];
    items.push({
      value: 'duplicate',
      name: 'Duplicate',
      icon: 'copy',
      onSelect: () => {
        const path = ReactEditor.findPath(editor, element);
        const copy = cloneDeep(Editor.node(editor, path)[0]);
        Transforms.insertNodes(editor, copy, {at: path});
        Transforms.deselect(editor);
        setDragHandleMenuOpen(false);
      },
    });
    items.push({
      value: 'delete',
      name: 'Delete',
      icon: 'delete',
      onSelect: () => {
        Transforms.removeNodes(editor, {
          at: ReactEditor.findPath(editor, element),
        });
        Transforms.deselect(editor);
        setDragHandleMenuOpen(false);
      },
    });
    if (deepLinkId != null) {
      items.push({
        value: 'link',
        name: 'Copy link',
        icon: 'link',
        onSelect: () => {
          const deepUrl =
            getParentReportURL(window.location.href, parentViewId) +
            '#' +
            deepLinkId;
          copyToClipboard(deepUrl);
          toast('Copied link');
          Transforms.deselect(editor);
          setDragHandleMenuOpen(false);
        },
      });
    }
    return items;
  }, [deepLinkId, editor, element, parentViewId, setDragHandleMenuOpen]);

  const offsetTop =
    element.type === 'heading' && (element as any).level === 1 ? 4 : undefined;

  if (dragging) {
    return isEqual(dragItem, element) ? (
      // Drag handle without popups (displayed on element while actively dragging)
      <S.DragHandle
        offsetTop={offsetTop}
        contentEditable={false}
        hidden={typing}
        {...handleAttributes}
      />
    ) : (
      // Disable drag handle if you're dragging a different element
      <></>
    );
  }

  return (
    // Drag handle with popup menus
    <WBPopupMenuTrigger
      menuBackgroundColor={white}
      theme="light"
      direction="center left"
      options={menuItems}>
      {({anchorRef, setOpen, open: menuOpen}) => (
        <Popup
          size="tiny"
          position="top center"
          basic
          disabled={dragHandleMenuOpen}
          inverted
          trigger={
            <S.DragHandle
              forceVisible={menuOpen}
              ref={anchorRef}
              offsetTop={offsetTop}
              onClick={() => {
                setOpen(o => {
                  setDragHandleMenuOpen(!o);
                  return !o;
                });
              }}
              contentEditable={false}
              hidden={typing}
              {...handleAttributes}
            />
          }
          content={
            <div>
              Drag to move
              <br />
              Click to open menu
            </div>
          }
        />
      )}
    </WBPopupMenuTrigger>
  );
};

export const BlockDragHandle = memo(BlockDragHandleComp);
