import React from 'react';
import {Icon} from 'semantic-ui-react';

import * as Filter from '../../util/filters';
import {truncateString} from '../../util/runhelpers';
import {formatTimestamp, Key, Value} from '../../util/runs';
import {Tag} from '../Tags';

interface FilterUneditableProps {
  filter: Filter.IndividualFilter<Key>;
  onAdd?(e?: React.SyntheticEvent<EventTarget>): void;
  onDelete?(e?: React.SyntheticEvent<EventTarget>): void;
}

export const FilterUneditable = (props: FilterUneditableProps) => {
  const {onAdd, onDelete, filter} = props;
  const {key, op, value} = filter;
  return (
    <React.Fragment>
      {onDelete && (
        <Icon
          className="filter--delete"
          name="x"
          size="small"
          onClick={onDelete}
        />
      )}
      {onAdd && (
        <Icon
          className="filter--add"
          name="plus"
          size="small"
          onClick={onAdd}
        />
      )}
      {!onAdd && !onDelete && <div />}
      {key.section === 'tags' ? (
        <React.Fragment>
          <div className="filter--key">{`${value ? 'T' : 'Not t'}agged `}</div>
          <Tag tag={{name: key.name, colorIndex: -1}} />
          {/* tags:{key.name} is {value === true ? 'Set' : 'Not Set'} */}
        </React.Fragment>
      ) : key.name === 'createdAt' ? (
        <>
          <div className="filter--key">
            Created&nbsp;&nbsp;{'<='}&nbsp;&nbsp;
          </div>
          <div className="filter--value">
            {formatTimestamp(filter.value as Value)}
          </div>
        </>
      ) : (
        <React.Fragment>
          <div className="filter--key">{key.name}</div>{' '}
          <div>&nbsp;{op ? op : '_'}&nbsp;</div>{' '}
          <div className="filter--value">
            {value != null
              ? truncateString(Filter.displayIndividualValue(filter))
              : '-'}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
