/* This is nestable context available to Panel2 panels */

import React, {useContext, useMemo} from 'react';
import {Frame} from '@wandb/cg';

export interface PanelContextState {
  frame: Frame;
  path: string[];

  // TODO: currently only populated in config component hierarchy
  // but may want it in component hierarchy to highlight selected
  // panel
  selectedPath?: string[];
}

export const PanelContext = React.createContext<PanelContextState>({
  frame: {},
  path: [],
  selectedPath: [],
});

export const PanelContextProvider: React.FC<{
  newVars: Frame;
  newPath?: string;
  selectedPath?: string[];
}> = React.memo(({newVars, newPath, selectedPath, children}) => {
  const {
    frame,
    path,
    selectedPath: prevSelectedPath,
  } = useContext(PanelContext);
  const ctxValue = useMemo(() => {
    return {
      frame: {...frame, ...newVars},
      path: newPath ? path.concat(newPath) : path,
      selectedPath: selectedPath ?? prevSelectedPath,
    };
  }, [frame, newPath, newVars, path, prevSelectedPath, selectedPath]);

  return (
    <PanelContext.Provider value={ctxValue}>{children}</PanelContext.Provider>
  );
});

export function usePanelContext() {
  return useContext(PanelContext);
}
