import styled from 'styled-components';

import {EXPORT_PADDING} from './ImageExportModal.styles';

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${EXPORT_PADDING}px;
`;
