import {RootState} from '../../types/redux';
import * as ViewsNormalize from '../views/normalize';
import * as ViewTypes from '../views/types';
import * as Types from './types';

export function makeWorkspaceSelector(workspaceID: string) {
  return (state: RootState) => {
    return state.workspaces[workspaceID];
  };
}

export function makeWorkspaceViewSelector(workspaceID: string) {
  return (state: RootState) => {
    const workspace = state.workspaces[workspaceID];
    if (workspace == null) {
      return {loading: true as true};
    }
    if (workspace.viewRef == null) {
      return {loading: true as true};
    }
    const view = state.views.views[workspace.viewRef.id];
    if (view.loading) {
      return {loading: true as true};
    }
    return {
      loading: false as false,
      view,
    };
  };
}

export function makeWorkspaceSpecSelector<T extends Types.WorkspaceTypes>(
  workspaceID: string,
  type: T
) {
  const workspaceViewSel = makeWorkspaceViewSelector(workspaceID);
  return (state: RootState) => {
    const workspaceView = workspaceViewSel(state);
    if (workspaceView.loading) {
      return {loading: true as true};
    }
    const partRef = workspaceView.view.partRef;
    if (partRef == null) {
      throw new Error('invalid state');
    }

    const fullSpec = ViewsNormalize.denormalize(state.views.parts, partRef);

    return {
      loading: false as false,
      view: workspaceView.view,
      fullSpec,
    };
  };
}

export function makeWorkspacePartSelector<T extends Types.WorkspaceTypes>(
  workspaceID: string,
  type: T
) {
  const workspaceViewSel = makeWorkspaceViewSelector(workspaceID);
  return (state: RootState) => {
    const workspaceView = workspaceViewSel(state);
    if (workspaceView.loading) {
      return {loading: true as true};
    }
    const view = workspaceView.view;
    if (view.partRef == null) {
      return {loading: true as true};
    }

    if (type !== view.partRef.type) {
      throw new Error('Invalid state');
    }

    const part = ViewsNormalize.lookupPart(
      state.views.parts,
      view.partRef as ViewTypes.PartRefFromType<T>
    );

    return {
      loading: false as false,
      part,
      partRef: view.partRef as ViewTypes.PartRefFromType<T>,
    };
  };
}

export function makeMultiRunWorkspaceSelector(
  workspaceID: string,
  type: 'project-view' | 'sweep-view' | 'group-view'
) {
  const workspacePartSel = makeWorkspacePartSelector(workspaceID, type);
  return (state: RootState) => {
    const workspace = workspacePartSel(state);
    if (workspace.loading) {
      return {loading: true as true};
    }
    const sectionPart = ViewsNormalize.lookupPart(
      state.views.parts,
      workspace.part.sectionRef
    );
    if (sectionPart.runSetRefs.length !== 1) {
      throw new Error('invalid state');
    }

    const runSetPart = ViewsNormalize.lookupPart(
      state.views.parts,
      sectionPart.runSetRefs[0]
    );

    const panelsPart =
      sectionPart.panelsRef != null
        ? ViewsNormalize.lookupPart(state.views.parts, sectionPart.panelsRef)
        : undefined;

    const panelBankConfigPart = ViewsNormalize.lookupPart(
      state.views.parts,
      sectionPart.panelBankConfigRef
    );

    if (sectionPart.panelSettingsRef == null) {
      // We populate this by default, it should always be defined in
      // workspaces.
      throw new Error('Invalid state');
    }

    return {
      loading: false as false,
      sectionRef: workspace.part.sectionRef,
      sectionPart,
      panelSettingsRef: sectionPart.panelSettingsRef,
      runSetRef: sectionPart.runSetRefs[0],
      runSetPart,
      panelsRef: sectionPart.panelsRef,
      panelsPart,
      panelBankConfigRef: sectionPart.panelBankConfigRef,
      panelBankConfigPart,
      part: workspace.part,
      partRef: workspace.partRef,
    };
  };
}

export function makeRunWorkspaceSelector(workspaceID: string) {
  const workspacePartSel = makeWorkspacePartSelector(workspaceID, 'run-view');
  return (state: RootState) => {
    const workspacePart = workspacePartSel(state);
    if (workspacePart.loading) {
      return {loading: true as true};
    }

    return {
      loading: false as false,
      panelsRef: workspacePart.part.panelsRef,
      partRef: workspacePart.partRef,
    };
  };
}
