/**
 * Select a Launch Queue.
 */
import Chip from '@material-ui/core/Chip';
import {WBIcon} from '@wandb/ui';
import React from 'react';
import Select from 'react-select';

import * as S from './SelectComponents.styles';

export interface QueueOption {
  isTeam: boolean;
  value: string;
  entity: string;
  project: string;
  queue: string;
  queueID: string;
  isPrivate: boolean;
  defaultResourceConfigID: string;
}

export interface GroupedQueueOptions {
  label: string;
  options: QueueOption[];
}

interface SelectLaunchQueueProps {
  options: QueueOption[] | GroupedQueueOptions[];
  defaultQueueOption?: QueueOption;
  onSelectQueue: (option: QueueOption) => void;
}

export const isGroupedQueueOption = (
  options: QueueOption[] | GroupedQueueOptions[]
): options is GroupedQueueOptions[] => {
  for (const option of options) {
    if (!('label' in option)) {
      return false;
    }
  }
  return true;
};

const GroupLabel = ({label}: GroupedQueueOptions) => (
  <S.SelectGroupOptionLabel>{label}</S.SelectGroupOptionLabel>
);

const OptionLabel = ({queue, isTeam, isPrivate}: QueueOption) => (
  <S.SelectOptionLabel>
    <S.SelectOptionLabel>{queue}</S.SelectOptionLabel>
    {isTeam && isPrivate && (
      <Chip icon={<WBIcon name="lock" />} label="Private" />
    )}
  </S.SelectOptionLabel>
);

const SelectLaunchQueue: React.FC<SelectLaunchQueueProps> = ({
  options,
  defaultQueueOption,
  onSelectQueue,
}) => {
  const onChange = (option: QueueOption | null) => {
    if (option) {
      onSelectQueue(option);
    }
  };

  if (isGroupedQueueOption(options)) {
    return (
      <Select<QueueOption, false, GroupedQueueOptions>
        options={options}
        defaultValue={defaultQueueOption}
        onChange={onChange}
        formatGroupLabel={GroupLabel}
        formatOptionLabel={OptionLabel}
        placeholder="Select a Launch Queue..."
      />
    );
  }

  return (
    <Select
      options={options}
      defaultValue={defaultQueueOption}
      formatOptionLabel={OptionLabel}
      placeholder="Select a Launch Queue..."
      onChange={onChange}
    />
  );
};

export default SelectLaunchQueue;
