import * as d3 from 'd3';

export function avg(arr: number[], defaultValue: number = NaN) {
  if (arr.length === 0) {
    return defaultValue;
  }
  return arr.reduce((a: number, b: number) => a + b, 0) / arr.length;
}

export function median(arr: number[], defaultValue: number = NaN) {
  if (arr.length === 0) {
    return defaultValue;
  }

  const sorted = arr.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export function quartiles(
  arr: number[]
): [number, number, number, number, number] {
  const sorted = arr.slice().sort((a, b) => a - b);
  const q1 = Math.floor(sorted.length * 0.25);
  const middle = Math.floor(sorted.length * 0.5);
  const q3 = Math.floor(sorted.length * 0.5);
  const max = sorted.length - 1;
  return [sorted[0], sorted[q1], sorted[middle], sorted[q3], sorted[max]];
}

export function bin(arr: number[], numBins: number) {
  if (arr.length === 0) {
    return [];
  }
  const x = d3
    .scaleLinear()
    .domain(d3.extent(arr) as [number, number])
    .nice(numBins);
  const histogram = d3
    .histogram()
    .domain(x.domain() as [number, number])
    .thresholds(x.ticks(numBins));
  const bins = histogram(arr);
  const ret = bins.map(d => ({
    bin: ((d.x0 || 0) + (d.x1 || 0)) / 2.0,
    count: d.length,
  }));
  return ret;
}

export function stddev(arr: number[]) {
  const m = avg(arr);
  return Math.sqrt(
    arr.reduce((sq, n) => {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (arr.length - 1)
  );
}

export function stderr(arr: number[], defaultValue: number = 0) {
  if (arr.length === 0) {
    return defaultValue;
  }
  return stddev(arr) / Math.sqrt(arr.length);
}

export function arrMax(arr: number[], defaultValue: number = -Infinity) {
  return arr.reduce((a: number, b: number) => Math.max(a, b), defaultValue);
}

export function arrMin(arr: number[], defaultValue: number = Infinity) {
  return arr.reduce((a: number, b: number) => Math.min(a, b), defaultValue);
}

export function argMax(arr: number[]) {
  return arr.reduce((m, c, i, ar) => (c > ar[m] ? i : m), 0);
}

export function argMin(arr: number[]) {
  return arr.reduce((m, c, i, ar) => (c < ar[m] ? i : m), 0);
}
