import styled from 'styled-components';

import WBReactTable from '../WBReactTable';

export const LaunchAgentTable = styled(WBReactTable)`
  margin: 16px;
`;

export const BatchDeleteAgents = styled.div`
  color: blue;
  cursor: pointer;
`;

export const BatchDeleteAgentsInfo = styled.div`
  display: flex;
  font-size: 12px;
  margin: 16px;
  padding-left: 2px;
`;

export const PopupCellText = styled.div`
  display: inline;
`;
