import React, {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {Settings} from 'src/util/panelsettings';

import * as ReportViewsSelectors from '../../state/reports/selectors';
import * as ReportsTypes from '../../state/reports/types';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelViewTypes from '../../state/views/panel/types';
import * as PanelBankSectionConfigActions from '../../state/views/panelBankSectionConfig/actions';
import * as SectionViewTypes from '../../state/views/section/types';
import AddVisMenu from '../AddVisMenu';
import {AddVisNewPanelEditor} from '../AddVisNewPanelEditor';
import ImportPanelMenu from './ImportPanelMenu';
import {ReportPanelGridButton} from './ReportPanelGridButton';
import * as S from './ReportPanelGridSection.styles';
import {REPORT_PANEL_GRID_MENU} from './types';

type ReportPanelGridActionsProps = {
  entityName: string;
  projectName: string;
  sectionPart: SectionViewTypes.SectionNormalized;
  panelSettings: Settings;
  viewRef: ReportsTypes.ReportViewRef;
  openedMenu?: REPORT_PANEL_GRID_MENU;
  setOpenedMenu: (menu?: REPORT_PANEL_GRID_MENU) => void;
};

export const ReportPanelGridActions: FC<ReportPanelGridActionsProps> = ({
  sectionPart,
  entityName,
  projectName,
  viewRef,
  panelSettings,
  openedMenu,
  setOpenedMenu,
}) => {
  const [newPanel, setNewPanel] = useState<PanelViewTypes.Panel>();
  const [isEditable, setIsEditable] = useState(false);

  const addPanel = ViewHooks.useViewAction(
    sectionPart.panelBankSectionConfigRef,
    PanelBankSectionConfigActions.addPanel
  );

  const reportWidth = useSelector(ReportViewsSelectors.getReportWidth(viewRef));

  return (
    <>
      <S.PanelButtonWrapper>
        <ReportPanelGridButton
          buttonText="Import panel"
          openedMenu={openedMenu}
          menu={REPORT_PANEL_GRID_MENU.IMPORT_PANEL}
          setOpenedMenu={setOpenedMenu}
          analytics={() =>
            window.analytics?.track(`Import Panel Menu Closed`, {
              location: `report panel grid`,
            })
          }
          menuPopup={
            <ImportPanelMenu
              entityName={entityName}
              projectName={projectName}
              sectionPart={sectionPart}
              viewRefID={viewRef.id}
            />
          }
        />
        <ReportPanelGridButton
          buttonText="Add panel"
          dataTest="add vis"
          openedMenu={openedMenu}
          menu={REPORT_PANEL_GRID_MENU.ADD_PANEL}
          setOpenedMenu={setOpenedMenu}
          menuPopup={
            <AddVisMenu
              entityName={entityName}
              runSetRefs={sectionPart.runSetRefs}
              onSelect={(panel, skipEdit) => {
                setOpenedMenu(undefined);
                setNewPanel(panel);
                setIsEditable(!!skipEdit);
              }}
            />
          }
        />
      </S.PanelButtonWrapper>
      {newPanel && (
        <AddVisNewPanelEditor
          panelBankSectionConfigRef={sectionPart.panelBankSectionConfigRef}
          currentHeight={400}
          editable
          customRunColorsRef={sectionPart.customRunColorsRef}
          runSetRefs={sectionPart.runSetRefs}
          panel={newPanel}
          panelSettings={panelSettings}
          noEdit={isEditable}
          onCancel={() => {
            setNewPanel(undefined);
          }}
          onOK={panelRef => {
            setNewPanel(undefined);
            addPanel(panelRef, reportWidth === 'readable');
          }}
        />
      )}
    </>
  );
};
