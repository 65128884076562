import classNames from 'classnames';
import produce from 'immer';
import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {Button, Checkbox, Popup} from 'semantic-ui-react';

import ModifiedDropdown from '../../../common/src/components/elements/ModifiedDropdown';
import introGraphicNewFindings from '../../assets/intro-report-graphic-new-findings.svg';
import introGraphicNoteToSelf from '../../assets/intro-report-graphic-note-to-self.svg';
import introGraphicWorkLog from '../../assets/intro-report-graphic-work-log.svg';
import {useUserProjectsQuery} from '../../generated/graphql';
import {useApolloClient} from '../../state/hooks';
import {
  PanelBankContext,
  PanelBankUpdaterContext,
} from '../../state/panelbank/context';
import {
  appendSectionsToReport,
  editReportPanelGroups,
  pushReport,
} from '../../state/reports/hooks';
import {RunQueryContext} from '../../state/runs/context';
import {useViewer} from '../../state/viewer/hooks';
import * as ViewHooks from '../../state/views/hooks';
import {useWholeArray} from '../../state/views/hooks';
import * as Filters from '../../util/filters';
import {
  getFullWidthPanelLayout,
  getNewGridItemLayout,
} from '../../util/panelbankGrid';
import {LayedOutPanel, LayoutParameters} from '../../util/panels';
import * as Report from '../../util/report';
import {CreateReportOpts} from '../../util/report';
import * as Run from '../../util/runs';
import {emptyReportRunSetSelectAll} from '../../util/section';
import {
  CREATED_FIRST_REPORT_FEATURE_KEY,
  useOnboardingContext,
} from '../../util/userContext';
import * as AddToReportModal from '../AddToReportModal';
import DraftWarningModal, {DraftWarningModalProps} from '../DraftWarningModal';
import ReportSectionPicker, {NEW_SECTION_IDX} from '../ReportSectionPicker';
import {isPanelGrid} from '../Slate/plugins/panel-grid';
import * as S from '../WBModal.styles';
import {CreateReportModalProps} from './CreateReportModal';
import {CreateReportParams, SrcSectionOption} from './types';
import {
  getProjectsFromUserProjectsQuery,
  normalizePanelLayouts,
  normalizeRunPagePanelExport,
} from './utils';

const NEW_REPORT_ID = 'new';

type ModalPage = 'intro' | 'import' | 'export';

const CreateReportModalContentComp: FC<CreateReportModalProps> = ({
  panelSettingsRef,
  runSetRefs,
  customRunColorsRef,
  useExportableSections,
  panelIsActive,
}) => {
  const client = useApolloClient();
  const viewer = useViewer();

  const {
    entityName,
    projectName,
    mergeFilters,
    runSetName,
    report: currentReport,
    runId,
  } = useContext(RunQueryContext);
  if (entityName == null || projectName == null) {
    throw new Error('missing RunQueryContext info');
  }
  const {exportingPanelRefID, exportingSectionRefID} =
    useContext(PanelBankContext);
  const isSinglePanelExport = exportingPanelRefID != null;
  const exportingPanelOrSection =
    isSinglePanelExport || exportingSectionRefID != null;
  const {
    setExportingUnknownID,
    setExportingPanelRefID,
    setExportingSectionRefID,
  } = useContext(PanelBankUpdaterContext);
  const {onboardingState, setOnboardingState} = useOnboardingContext(
    CREATED_FIRST_REPORT_FEATURE_KEY
  );

  const runSets = useWholeArray(runSetRefs);
  const sectionsWithVisiblePanels = useExportableSections();
  const panelSettings = ViewHooks.useWholeMaybe(panelSettingsRef);
  const customRunColors = ViewHooks.useWhole(customRunColorsRef);

  const [modalPage, setModalPage] = useState<ModalPage>('import');
  const [creatingReport, setCreatingReport] = useState(false);
  const [reportSearchQuery, setReportSearchQuery] = useState('');
  const [draftWarningModalOpen, setDraftWarningModalOpen] = useState(false);
  const [srcSectionOptions, setSrcSectionOptions] =
    useState<SrcSectionOption[]>(getSrcSectionOptions);
  const [freezeRunSet, setFreezeRunSet] = useState(false);

  const [selectedProjectID, setSelectedProjectID] = useState('');
  const [selectedReportID, setSelectedReportID] = useState(NEW_REPORT_ID);
  const [selectedDstSectionIdx, setSelectedDstSectionIdx] =
    useState(NEW_SECTION_IDX);
  const addingToReportSection =
    isSinglePanelExport && selectedDstSectionIdx !== NEW_SECTION_IDX;

  const currentDate = useMemo(() => new Date(), []);

  const onProjectChange = useCallback((e, data) => {
    setSelectedProjectID(data.value);
    setSelectedReportID(NEW_REPORT_ID);
  }, []);
  const onReportChange = useCallback((e, data) => {
    setSelectedReportID(data.value);
    setReportSearchQuery('');
    setSelectedDstSectionIdx(NEW_SECTION_IDX);
  }, []);
  const onDstSectionChange = useCallback(i => setSelectedDstSectionIdx(i), []);

  const onCloseModal = useCallback(() => {
    setExportingUnknownID(false);
    setExportingPanelRefID(null);
    setExportingSectionRefID(null);
  }, [setExportingUnknownID, setExportingPanelRefID, setExportingSectionRefID]);

  const userProjectsQuery = useUserProjectsQuery({
    variables: {userName: viewer?.username ?? '', includeReports: true},
    skip: viewer == null,
  });

  const {projectOptions, reportOptionsByProjectID, projectInfoByID} =
    AddToReportModal.useAddToReportOptions({
      userProjectsQuery,
      currentReport,
    });

  // set modal page based on whether user is adding to or creating their first report
  useLayoutEffect(() => {
    if (exportingPanelOrSection) {
      setModalPage('export');
    } else if (!onboardingState.createdFirstReport) {
      setModalPage('intro');
    }
  }, [exportingPanelOrSection, onboardingState.createdFirstReport]);

  // Select a source section by default
  useEffect(() => {
    if (srcSectionOptions.length === 0) {
      return;
    }

    if (exportingSectionRefID == null) {
      selectSrcSectionOption(srcSectionOptions[0]);
      return;
    }

    const currentSectionOpt = srcSectionOptions.find(
      s => s.ref.id === exportingSectionRefID
    );
    if (currentSectionOpt != null) {
      selectSrcSectionOption(currentSectionOpt);
    }

    // eslint-disable-next-line
  }, []);

  // Select current project by default
  useEffect(() => {
    if (userProjectsQuery.data == null) {
      return;
    }
    const projects = getProjectsFromUserProjectsQuery(userProjectsQuery);
    if (projects.length === 0) {
      return;
    }

    // If a project on the list is already selected, don't change it against the user's will
    let projo = projects.find(p => p.id === selectedProjectID);
    // otherwise, select current project
    if (projo == null) {
      projo = projects.find(
        p => p.entityName === entityName && p.name === projectName
      );
    }
    // If current project is not on the list, default to first project
    if (projo == null) {
      projo = projects[0];
    }
    setSelectedProjectID(projo.id);
    // eslint-disable-next-line
  }, [userProjectsQuery, entityName, projectName]);

  const applyIntroModalWidth = modalPage === 'intro' ? '670px' : undefined;

  return (
    <>
      <S.WBModal
        className="save-snapshot-modal"
        width={applyIntroModalWidth}
        open={true}
        onClose={onCloseModal}>
        {modalPage === 'intro' && renderIntroModal()}
        {modalPage === 'import' && renderImportModal()}
        {modalPage === 'export' && renderExportModal()}
      </S.WBModal>
      {draftWarningModalOpen && selectedReportID !== NEW_REPORT_ID && (
        <DraftWarningModal
          parentViewID={selectedReportID}
          flipButtonColors
          onNoDraftFound={() => createReportInSelectedProject(selectedReportID)}
          onDiscard={() => createReportInSelectedProject(selectedReportID)}
          onClose={() => setDraftWarningModalOpen(false)}
          {...getDraftWarningModalProps()}
        />
      )}
    </>
  );

  function getSrcSectionOptions(): SrcSectionOption[] {
    return sectionsWithVisiblePanels.map(s => {
      let totalPanels = s.panels;
      if (panelIsActive != null) {
        totalPanels = totalPanels.filter(p => panelIsActive(s, p));
      }
      return {
        ...s,
        visiblePanelCount: s.visiblePanels.length,
        totalPanelCount: totalPanels.length,
        selected: false,
        visibleOnly: true,
      };
    });
  }

  function toggleSrcSectionOption(
    o: SrcSectionOption,
    toggleVisibleOnly?: true
  ) {
    setSrcSectionOptions(prev =>
      produce(prev, draft => {
        for (const s of draft) {
          if (s.ref.id !== o.ref.id) {
            continue;
          }
          if (toggleVisibleOnly) {
            s.visibleOnly = !s.visibleOnly;
          } else {
            s.selected = !s.selected;
          }
          return;
        }
      })
    );
  }

  function selectSrcSectionOption(o: SrcSectionOption) {
    if (!o.selected) {
      toggleSrcSectionOption(o);
    }
  }

  async function createReport({
    reportID,
    dstEntityName,
    dstProjectName,
  }: CreateReportParams) {
    setCreatingReport(true);
    const runSetsWithProject = getRunSetsWithProject();
    const reportSections = getReportSections();
    const reportConfig = getReportConfig();

    try {
      if (reportID === NEW_REPORT_ID) {
        await createNewReport();
        if (!onboardingState.createdFirstReport) {
          setOnboardingState({...onboardingState, createdFirstReport: true});
        }
      } else if (addingToReportSection) {
        await addToReportSection();
      } else {
        await appendSectionsToReport({
          client,
          entityName: dstEntityName,
          projectName: dstProjectName,
          reportID,
          newBlocks: reportConfig.blocks.filter(isPanelGrid),
          opts: {newTab: true},
        });
      }
      onCloseModal();
    } catch (err) {
      console.error(`Error creating report`, err);
      setCreatingReport(false);
    }

    function getRunSetsWithProject() {
      if (entityName == null || projectName == null) {
        throw new Error('this should never happen');
      }
      const runSetsWithEmptyDefault =
        runSets.length > 0 ? runSets : [emptyReportRunSetSelectAll()];
      return runSetsWithEmptyDefault.map(rs => ({
        ...rs,
        project: rs.project ?? {entityName, name: projectName},
      }));
    }

    function getReportSections() {
      if (isSinglePanelExport) {
        for (const s of srcSectionOptions) {
          for (const p of s.panels) {
            if ((p as any).ref?.id === exportingPanelRefID) {
              const exportedPanels =
                runId == null ? [p] : normalizeRunPagePanelExport(runId, [p]);
              const reportSection = Report.createReportSection(
                runSetsWithProject,
                customRunColors,
                panelSettings,
                s,
                exportedPanels
              );
              return [reportSection];
            }
          }
        }
        return [];
      }

      return srcSectionOptions
        .filter(o => o.selected)
        .map(section => {
          let totalPanels = section.panels;
          if (panelIsActive != null) {
            totalPanels = totalPanels.filter(panel =>
              panelIsActive(section, panel)
            );
          }
          let exportedPanels = (
            section.visibleOnly ? section.visiblePanels : totalPanels
          ) as LayedOutPanel[];
          if (runId != null) {
            exportedPanels = normalizeRunPagePanelExport(runId, exportedPanels);
          }
          return Report.createReportSection(
            runSetsWithProject,
            customRunColors,
            panelSettings,
            section,
            exportedPanels
          );
        });
    }

    function getReportConfig() {
      const createReportOpts: CreateReportOpts = {
        mergeFilters,
        runSetName,
      };

      if (freezeRunSet) {
        const runCreatedAtFilter: Filters.Filter<Run.Key> = {
          key: {section: 'run', name: 'createdAt'},
          op: '<=',
          value: currentDate.toISOString(),
          disabled: false,
        };
        if (createReportOpts.mergeFilters != null) {
          createReportOpts.mergeFilters = Filters.And([
            createReportOpts.mergeFilters,
            runCreatedAtFilter,
          ]);
        } else {
          createReportOpts.mergeFilters = runCreatedAtFilter;
        }
      }

      const config =
        reportSections.length > 0
          ? Report.fromSections(reportSections, createReportOpts)
          : Report.getEmptyReportConfig();
      normalizePanelLayouts(config);

      return config;
    }

    async function createNewReport() {
      if (isSinglePanelExport) {
        const panelGrids = reportConfig.blocks.filter(isPanelGrid);
        for (const pg of panelGrids) {
          const config = pg.metadata.panelBankSectionConfig;
          config.panels.forEach(p => (p.layout = getFullWidthPanelLayout()));
        }
      }
      await pushReport({
        client,
        entityName: dstEntityName,
        projectName: dstProjectName,
        config: reportConfig,
        opts: {
          newTab: true,
          redirectQS: {
            runsetFilter: freezeRunSet ? '' : null,
            firstReport: onboardingState.createdFirstReport ? null : '',
          },
        },
      });
    }

    async function addToReportSection() {
      await editReportPanelGroups({
        client,
        entityName: dstEntityName,
        projectName: dstProjectName,
        reportID,
        opts: {newTab: true},
        editBlocks: blocks => {
          const panelGrids = blocks.filter(isPanelGrid);
          if (selectedDstSectionIdx >= panelGrids.length) {
            blocks.splice(-1, 0, ...reportConfig.blocks.filter(isPanelGrid));
            return;
          }
          const config =
            panelGrids[selectedDstSectionIdx].metadata.panelBankSectionConfig;
          // ensure panel layouts
          const panelLayouts: LayoutParameters[] = [];
          for (const p of config.panels) {
            p.layout = p.layout ?? getNewGridItemLayout(panelLayouts);
            panelLayouts.push(p.layout);
          }
          config.panels = [
            ...config.panels,
            {
              ...reportSections[0].panelBankSectionConfig.panels[0],
              layout: getNewGridItemLayout(config.panels.map(p => p.layout)),
            },
          ];
        },
      });
    }
  }

  function createNewReportInCurrentProject() {
    if (entityName == null || projectName == null) {
      throw new Error('this should never happen');
    }
    createReport({
      reportID: NEW_REPORT_ID,
      dstEntityName: entityName,
      dstProjectName: projectName,
    });
  }

  function createReportInSelectedProject(reportID: string) {
    const projectInfo = projectInfoByID[selectedProjectID];
    if (projectInfo == null) {
      return;
    }
    const {entityName: dstEntityName, name: dstProjectName} = projectInfo;
    createReport({
      reportID,
      dstEntityName,
      dstProjectName,
    });
  }

  function createReportOrCheckDraft() {
    selectedReportID === NEW_REPORT_ID
      ? createReportInSelectedProject(selectedReportID)
      : setDraftWarningModalOpen(true);
  }

  function renderIntroModal() {
    const introExamples = [
      {
        text: 'Add quick notes to a chart.',
        src: introGraphicNoteToSelf,
      },
      {
        text: "Track what you've tried.",
        src: introGraphicWorkLog,
      },
      {
        text: 'Collaborate with your team.',
        src: introGraphicNewFindings,
      },
    ];

    return (
      <>
        <S.WBModalHeader>
          Reports = Google Docs + Live Data
          <div>Here are a few examples of how you can use Reports:</div>
        </S.WBModalHeader>
        <S.WBModalContent>
          <S.WBModalImageGallery>
            {introExamples.map(e => (
              <div>
                <img src={e.src} alt="intro-report-graphic" />
                <div>{e.text}</div>
              </div>
            ))}
          </S.WBModalImageGallery>
        </S.WBModalContent>
        <S.WBModalActions>
          <S.WBModalButton onClick={onCloseModal}>Cancel</S.WBModalButton>
          <S.WBModalButton primary onClick={() => setModalPage('import')}>
            Next: Import Charts
          </S.WBModalButton>
        </S.WBModalActions>
      </>
    );
  }

  // TODO - remove this when refactoring and triple check that it isn't being used anywhere else
  function renderImportModal() {
    return (
      <>
        <S.WBModalHeader>Create Report</S.WBModalHeader>
        <S.WBModalContent>
          {sectionsWithVisiblePanels.length === 0 ? (
            <p>
              You have no panels on this page, so this will create an empty
              report.
            </p>
          ) : (
            <>
              <S.WBModalContentSection>
                <S.WBModalContentHeader>Import charts</S.WBModalContentHeader>
                <div className="section-options-container">
                  {srcSectionOptions.map(renderSectionOption)}
                </div>
              </S.WBModalContentSection>
              <S.WBModalContentSection>
                <S.WBModalContentHeader>Filter run sets</S.WBModalContentHeader>
                <div className="section-filter-container">
                  <div className="save-snapshot-modal__checkbox">
                    <Checkbox
                      label={`Only include runs created before ${currentDate.toLocaleTimeString()}`}
                      checked={freezeRunSet}
                      onChange={() => setFreezeRunSet(prev => !prev)}
                    />
                    <Popup
                      inverted
                      size="mini"
                      position="top center"
                      trigger={<S.WBModalInfoIcon name="info" />}>
                      You can change this anytime for individual run sets with
                      the "freeze run set" button
                    </Popup>
                  </div>
                </div>
              </S.WBModalContentSection>
            </>
          )}
        </S.WBModalContent>
        <S.WBModalActions>
          <S.WBModalButton
            onClick={
              !onboardingState.createdFirstReport
                ? () => setModalPage('intro')
                : onCloseModal
            }>
            {!onboardingState.createdFirstReport ? 'Back' : 'Cancel'}
          </S.WBModalButton>
          <S.WBModalButton
            primary
            loading={creatingReport}
            disabled={creatingReport}
            onClick={() => {
              window.analytics?.track('Create Report Clicked', {
                entityName,
                projectName,
                location: 'create report modal',
              });
              createNewReportInCurrentProject();
            }}>
            Create report
          </S.WBModalButton>
        </S.WBModalActions>
      </>
    );
  }

  function renderSectionOption(sectionOpt: SrcSectionOption) {
    const panelCount = sectionOpt.visibleOnly
      ? sectionOpt.visiblePanelCount
      : sectionOpt.totalPanelCount;
    const panelNoun = panelCount === 1 ? 'panel' : 'panels';

    return (
      <div className="section-options" key={sectionOpt.ref.id}>
        <div className="save-snapshot-modal__checkbox">
          <Checkbox
            label={sectionOpt.name}
            checked={sectionOpt.selected}
            onChange={() => toggleSrcSectionOption(sectionOpt)}
          />
          <span className="section-panel-count">
            ({panelCount} {panelNoun})
          </span>
        </div>
        {sectionOpt.selected && (
          <div className="section-toggle-container">
            <Checkbox
              toggle
              className="section-toggle"
              checked={sectionOpt.visibleOnly}
              onChange={() => toggleSrcSectionOption(sectionOpt, true)}
            />
            <span className="section-toggle-label">
              Only import the first page of the section
            </span>
          </div>
        )}
      </div>
    );
  }

  function renderExportModal() {
    return (
      <>
        <S.WBModalHeader>Add To Report</S.WBModalHeader>
        <S.WBModalContent>
          <div
            className={classNames('destination-section', {
              'no-margin': currentReport != null,
            })}>
            <p>Pick a destination report.</p>
            <div className="dest-row">
              <div className="dest-row-dest">Project</div>
              <ModifiedDropdown
                selection
                search
                className="save-snapshot-modal__select"
                options={projectOptions}
                value={selectedProjectID}
                onChange={onProjectChange}
              />
            </div>
            <div className="dest-row">
              <div className="dest-row-dest">Report</div>
              <ModifiedDropdown
                selection
                className="save-snapshot-modal__select"
                options={reportOptionsByProjectID[selectedProjectID] ?? []}
                value={selectedReportID}
                onChange={onReportChange}
                search
                searchQuery={reportSearchQuery}
                onSearchChange={(e, data) =>
                  setReportSearchQuery(data.searchQuery)
                }
              />
            </div>
            {selectedReportID !== NEW_REPORT_ID && (
              <div className="dest-row">
                <div className="dest-row-dest">Section</div>
                <ReportSectionPicker
                  reportID={selectedReportID}
                  selectedSectionIdx={selectedDstSectionIdx}
                  className="save-snapshot-modal__select"
                  onSelectSection={onDstSectionChange}
                />
              </div>
            )}
          </div>
        </S.WBModalContent>
        <S.WBModalActions>
          <S.WBModalButton onClick={onCloseModal}>Cancel</S.WBModalButton>
          <S.WBModalButton
            primary
            loading={creatingReport}
            disabled={creatingReport}
            onClick={createReportOrCheckDraft}>
            Add to report
          </S.WBModalButton>
        </S.WBModalActions>
      </>
    );
  }

  function getDraftWarningModalProps(): Pick<
    DraftWarningModalProps,
    'mainText' | 'discardText' | 'renderResumeButton'
  > {
    if (addingToReportSection) {
      return {
        mainText:
          'You have unsaved edits to this report. Do you want to discard unsaved edits and import new panels?',
        discardText: 'Import panels',
        renderResumeButton: () => (
          <Button size="tiny" onClick={() => setDraftWarningModalOpen(false)}>
            Go back
          </Button>
        ),
      };
    }
    return {
      mainText:
        'You have unsaved edits to this report. Do you want to resume or discard these unsaved edits?',
      discardText: 'Discard',
      renderResumeButton: (
        draftID: string,
        resuming: boolean,
        discarding: boolean,
        setResuming: (r: boolean) => void
      ) => (
        <Button
          size="tiny"
          loading={resuming}
          disabled={resuming || discarding}
          onClick={() => {
            setResuming(true);
            createReportInSelectedProject(draftID);
          }}>
          Resume
        </Button>
      ),
    };
  }
};

export const CreateReportModalContent = memo(CreateReportModalContentComp);
