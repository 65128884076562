import {BoundingBoxFileData} from '@wandb/common/types/media';
import {BoundingBoxesCanvas} from '@wandb/weave-ui/components/Panel2/ImageWithOverlays';
import * as _ from 'lodash';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {clearBoxData, loadBoxData} from '../../state/media/actions';
import * as PanelTypes from '../../state/views/panel/types';
import {PartRefFromObjSchema} from '../../state/views/types';
import {RunSignature} from '../../types/run';
import {boxColor, WBFile} from '../../util/media';
import {FileInfo, useLoadFile} from '../../util/requests';
import {
  BoundingBoxClassControl,
  BoundingBoxSliderControl,
  Style,
} from '../MediaCard';

type BoundingBoxesProps = {
  style?: React.CSSProperties;
  classLabels:
    | {
        [key: number]: string;
      }
    | undefined;
  mediaKey: string;
  boxKey: string;
  boxFileInfo: WBFile;
  runSignature: RunSignature;
  mediaSize: {
    width: number;
    height: number;
  };
  cardSize: {
    width: number;
    height: number;
  };
  mediaPanelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;
  boxStyle?: Style;
  boxToggles?: {
    [classOrAll: string]: BoundingBoxClassControl;
  };
  boxSliders?: {
    [sliderKey: string]: BoundingBoxSliderControl;
  };
};

const BoundingBoxesComp = ({
  runSignature,
  boxFileInfo,
  mediaKey,
  mediaPanelRef,
  boxKey,
  cardSize,
  style,
  mediaSize,
  boxStyle,
  boxSliders,
  boxToggles,
}: BoundingBoxesProps) => {
  const dispatch = useDispatch();

  const [boxData, setBoxData] = React.useState<BoundingBoxFileData | null>(
    null
  );
  const [fileMetadata, setFileMetadata] = React.useState<FileInfo | null>(null);
  const onSuccess = useCallback((b: BoundingBoxFileData, m: FileInfo) => {
    setBoxData(b);
    setFileMetadata(m);
  }, []);

  const classStates = useMemo(
    () =>
      Object.fromEntries(
        _.map(boxData?.class_labels, (label, id) => [
          id,
          {name: label, color: boxColor(parseInt(id, 10))},
        ])
      ),
    [boxData]
  );

  const classOverlayStates = useMemo(
    () =>
      Object.fromEntries(
        _.map(boxToggles, (controls, id) => [id, {opacity: 1, ...controls}])
      ),
    [boxToggles]
  );

  useLoadFile(runSignature, boxFileInfo.path, {
    onSuccess,
    responseType: 'json',
  });

  useEffect(() => {
    if (fileMetadata == null || boxData == null) {
      return;
    }

    dispatch(
      loadBoxData({
        mediaKey,
        panelID: mediaPanelRef.id,
        // Use the file ID as the identifier instead of
        // combining mediaKey, boxKey, step, index, run.id
        mediaID: fileMetadata.id,
        boxData: boxData.box_data,
      })
    );

    return () => {
      if (fileMetadata == null || boxData == null) {
        return;
      }
      dispatch(
        clearBoxData({
          mediaKey,
          panelID: mediaPanelRef.id,
          mediaID: fileMetadata.id,
        })
      );
    };
  }, [boxData, fileMetadata, mediaPanelRef.id, mediaKey, dispatch]);

  return (
    <div
      data-test="bounding-box"
      key={boxKey}
      style={{
        width: cardSize.width,
        height: cardSize.height,
        ...style,
      }}>
      {/* TODO: Don't make the canvas scale make the drawing scale */}
      {boxData && (
        <BoundingBoxesCanvas
          mediaSize={mediaSize}
          boxData={boxData.box_data}
          classStates={classStates}
          bboxControls={{
            type: 'box',
            classSearch: '',
            classSetID: '',
            name: '',
            disabled: false,
            classOverlayStates,
            lineStyle: boxStyle?.lineStyle ?? 'line',
          }}
          sliderControls={boxSliders}
        />
      )}
    </div>
  );
};

export const BoundingBoxes = React.memo(BoundingBoxesComp);
