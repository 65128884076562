import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon, WBIconButton} from '@wandb/ui';
import {Button, Icon} from 'semantic-ui-react';
import styled from 'styled-components';

import {GLOBAL_COLORS} from '../../../../util/colors';
import {InlineMarkdownEditor} from '../../../elements/InlineMarkdownEditor';

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  // Hide scrollbars
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
export const OverviewPanel = styled.div`
  min-width: 50px;
  flex: 1 0 50px;
  height: 100%;
`;
export const OverviewItem = styled.div`
  display: flex;
  min-height: 32px;
  align-items: top;
  flex: 1 1 auto;
  width: 100%;
`;
export const OverviewKey = styled.div`
  flex: 0 0 128px;
  color: ${GLOBAL_COLORS.lightGray.toString()};
`;
export const OverviewValue = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-right: 35px; */

  .copyable-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .artifact-add-alias-button {
    height: 26px;
    width: 26px;
    text-align: center;
    margin: 0;
    color: ${GLOBAL_COLORS.gray.toString()};
    font-size: 18px;
    padding: 0px;
  }
`;

export const OverviewValueMarkdownEditor = styled(InlineMarkdownEditor)`
  white-space: normal;
  cursor: pointer;
`;
export const OverviewPanelSectionVersionSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-start;
  [role='combobox'] > [role='listbox'] {
    max-height: 200px !important;
    & > [role='option'] {
      padding: 0.6em 1em !important;
    }
  }
`;
export const OverviewPanelSectionHeaderControls = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const VersionMenuButton = styled(WBIcon)`
  font-size: 1.9em;
  border-radius: 5px;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: #ddd;
  }
`;
export const OverviewPanelSectionBodyColumn = styled.div`
  flex: 1 1 100px;
  width: 100px;
`;
export const OverviewPanelSectionBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const OverviewPanelSectionBody = styled.div`
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  padding: 0.5em;
`;
export const OverviewPanelSection = styled.div`
  margin-top: 0.5em;
`;
export const OverviewPanelSectionHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 8px;
  .ui.button {
    margin-left: 15px;
  }
`;
export const OverviewPanelSectionHeader = styled.div`
  padding-bottom: 6px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const CustomWBIconButton = styled(WBIconButton)`
  flex: 0 0 auto;

  .MuiIcon-root {
    font-size: 1.2rem;
  }
`;

export const GrayIcon = styled(Icon)`
  color: ${globals.gray600};
`;

export const CurrentAliasesContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const LinkButton = styled(Button)`
  width: 95px;
`;

export const CurrentAliasList = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
