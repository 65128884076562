import {isValidEmail} from '@wandb/cg';
import {WBIcon} from '@wandb/ui';
import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {StrictInputProps} from 'semantic-ui-react';

import {
  useCreateNewsletterSubscriptionMutation,
  useNewsletterSubscriptionQuery,
} from '../generated/graphql';
import * as S from '../pages/FC.styles';
import {useViewer} from '../state/viewer/hooks';
import {propagateErrorsContext} from '../util/errors';
import {useIsGalleryReport} from '../util/gallery';

// Only display for these reports
const REPORTS_TO_INCLUDE = [
  'VmlldzoyMDE4NDAy',
  'Vmlldzo4NjIxODA=',
  'VmlldzoxODMxMDI2',
];

type ReportPageSubscribePromptProps = {
  viewID: string;
};

const ReportPageSubscripePrompt: FC<ReportPageSubscribePromptProps> = ({
  viewID,
}) => {
  const viewer = useViewer();
  const loggedIn: boolean = useMemo(() => viewer != null, [viewer]);

  const subscriptionInputRef = useRef<HTMLDivElement>(null);

  const [subscriptionInput, setSubscriptionInput] = useState('');
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [subscribeError, setSubscribeError] = useState('');

  const [createNewsletterSubscription] =
    useCreateNewsletterSubscriptionMutation({
      context: propagateErrorsContext(),
    });

  const createSubscription = useCallback(async () => {
    try {
      if (!loggedIn && !isValidEmail(subscriptionInput)) {
        setSubscribeError('Please enter a valid email.');
        subscriptionInputRef.current?.focus();
        return;
      }

      const mutationVars = loggedIn ? {} : {email: subscriptionInput};
      await createNewsletterSubscription({variables: mutationVars});

      setSubscribeSuccess(true);
      setSubscriptionInput('');
      setSubscribeError('');
    } catch (err) {
      console.error(err);
      subscriptionInputRef.current?.focus();
      setSubscriptionInput('');
      setSubscribeError('Oops, something went wrong.');
    }
  }, [loggedIn, subscriptionInput, createNewsletterSubscription]);

  const {
    loading: loadingNewsletterSubscription,
    data: newsletterSubscriptionData,
  } = useNewsletterSubscriptionQuery({
    variables: {
      userName: viewer?.username ?? '',
    },
    skip: !viewer?.username,
  });

  const {loading: loadingIsGalleryReport, isGalleryReport} =
    useIsGalleryReport(viewID);

  const displaySubscribePrompt: boolean = useMemo(() => {
    const reportIncluded = REPORTS_TO_INCLUDE.includes(viewID);
    const inGallery = !loadingIsGalleryReport && isGalleryReport;
    const isSubscribed =
      !loadingNewsletterSubscription &&
      newsletterSubscriptionData?.user?.newsletterSubscription != null;

    return reportIncluded && inGallery && !isSubscribed;
  }, [
    viewID,
    loadingIsGalleryReport,
    isGalleryReport,
    loadingNewsletterSubscription,
    newsletterSubscriptionData,
  ]);

  return displaySubscribePrompt ? (
    <S.InlineSubscribePrompt>
      <S.SubscribeHeader inlinePrompt>
        Subscribe to our Newsletter for more ML News like DALLE.
      </S.SubscribeHeader>
      {subscribeSuccess ? (
        <S.SubscribeSuccessMessage>
          <WBIcon name="check" />
          Subscribed!
        </S.SubscribeSuccessMessage>
      ) : (
        <S.SubscribeInputContainer>
          <S.SubscribeInputMessageContainer loggedIn={loggedIn}>
            <S.SubscribeInput
              placeholder="Email address"
              invalid={subscribeError !== ''}
              value={subscriptionInput}
              ref={subscriptionInputRef}
              onChange={
                ((e, {value}) =>
                  setSubscriptionInput(value)) as StrictInputProps['onChange']
              }
            />
            <S.SubscribeInvalidMessage
              inlinePrompt
              invalid={subscribeError !== ''}>
              {subscribeError}
            </S.SubscribeInvalidMessage>
          </S.SubscribeInputMessageContainer>
          <S.SubscribeButton
            inlinePrompt
            content="Subscribe"
            onClick={createSubscription}
          />
        </S.SubscribeInputContainer>
      )}
    </S.InlineSubscribePrompt>
  ) : (
    <></>
  );
};

export default ReportPageSubscripePrompt;
