import * as Sentry from '@sentry/react';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {ImageMetadata} from '@wandb/common/types/media';
import {Struct} from '@wandb/common/util/types';
import {SegmentationMaskLoader} from '@wandb/weave-ui/components/Panel2/ImageWithOverlays';
import * as _ from 'lodash';
import * as React from 'react';
import {CSSProperties, useCallback, useMemo, useRef, useState} from 'react';
import {Icon, Modal, Popup} from 'semantic-ui-react';

import * as PanelTypes from '../../state/views/panel/types';
import {PartRefFromObjSchema} from '../../state/views/types';
import {RunSignature} from '../../types/run';
import {
  getBoundingBoxes,
  getMasks,
  getSingletonValue,
  labelComponent,
  makeCaptions,
  mediaFilePath,
  mediaSrc,
  runFileSource,
  segmentationMaskColor,
  useNaturalDimensions,
  WANDB_BBOX_CLASS_LABEL_KEY,
  WANDB_DELIMETER,
  WANDB_MASK_CLASS_LABEL_KEY,
  WBFile,
} from '../../util/media';
import {useLoadFile} from '../../util/requests';
import {runLink} from '../../util/runhelpers';
import {
  getImageMedia,
  MaskControl,
  MediaCardProps,
  RunWithHistoryAndMediaWandb,
} from '../MediaCard';
import MessageMediaNotFound from '../MessageMediaNotFound';
import {BoundingBoxes} from './BoundingBoxes';
import {downloadSpritePart} from './downloadSpritePart';
import * as S from './ImageCard.styles';

type ImageCardProps = MediaCardProps;

const ImageCardComp = (props: ImageCardProps) => {
  const {width, height, mediaIndex, mediaKey, tileMedia, globalStep} = props;
  if (tileMedia == null) {
    return (
      <div data-test="image-card-wrapper" style={{height: '100%'}}>
        <div
          data-test="image-card-comp"
          className="image-card"
          style={{width, height, display: 'flex'}}>
          <MessageMediaNotFound
            basic
            mediaIndex={mediaIndex}
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaType="images"
          />
        </div>
      </div>
    );
  }

  return <ImageCardInner {...props} tileMedia={tileMedia} />;
};
export const ImageCard = React.memo(ImageCardComp);
export default ImageCard;

type ImageCardInnerProps = ImageCardProps & {
  tileMedia: NonNullable<ImageCardProps['tileMedia']>;
};

export const ImageCardInner: React.FunctionComponent<ImageCardInnerProps> =
  React.memo(props => {
    const {
      run,
      globalStep,
      width,
      height,
      mediaKey,
      mediaIndex,
      mediaWidth,
      mediaHeight,
      runSignature,
      maskOptions,
      controls,
      mediaPanelRef,
      actualSize,
      tileMedia,
    } = props;
    const {step, historyRow, objectURL, internalURL, filePath} = tileMedia;

    let imageURL = objectURL;
    if (internalURL) {
      try {
        const url = new URL(internalURL);
        /**
         * Notes on image sizing:
         * We request a larger dimensioned image what the panel technically needs for safety. Why is this "more safe"?
         * 1. We won't request new images if the panel is resized, therefore it'll be nice to have an image that's big enough to handle some expansion without losing resolution
         * 2. Is there still some display constraint around needing higher pixel images for devices with high-pixel density displays? I vaguely remember this from HTML of yesteryear
         * 3. Because the primary value in doing this at all is throttling down YUUUUUGE images o(think medical images of 3500x3500 at 150mb), the performance cost of being a little greedy on asking for extra dimensions is low.
         * - A 200x200 image contains 4000 pixel
         * - A 400x400 image contains 16000 pixels (2x as big, 4x more pixels)
         * So while this seems like we might be asking for a lot of extra pixels we don't need, the value in this is going from 3500 -> 400, and the cost of getting 400 instead of 200 is negligible.
         */
        url.searchParams.set('height', Math.round(height * 2).toString());
        url.searchParams.delete('width');
        imageURL = url.toString();
      } catch (err) {
        // if anything goes wrong here we'll log it but we've got the blob URL as fallback
        Sentry.captureException(err);
      }
    }

    const [fullscreenModalOpen, setFullscreenModalOpen] = useState(false);
    const masksRef = useRef<HTMLDivElement | null>(null);
    const boxesRef = useRef<HTMLDivElement | null>(null);

    const [naturalDimensions, imgRef] = useNaturalDimensions();

    const showMetadataInline = width > 100; // if this is false, metadata will appear in a popup on the image
    const rolledBack = globalStep !== step;

    const mediaMetadata = getImageMedia(
      {
        historyRow,
        mediaKey,
      },
      mediaIndex
    ) as ImageMetadata | null;

    const paths = getImageInfo({
      imgMedia: mediaMetadata,
      step,
      mediaIndex,
      mediaKey,
      runSignature,
    });
    const isSprite = paths.type === 'sprite';

    // Compute pixel offset for sprite based images
    //
    // This will be scaled to the card and used for display
    // to calculate to offset.
    const spritePixelOffset = isSprite ? mediaIndex * width : 0;

    // Compute the pixel offset for the original sprite
    // This is used for downloading
    const originalWidth = mediaMetadata?.width ?? null;
    const originalPixelOffset =
      isSprite && originalWidth != null ? mediaIndex * originalWidth : 0;

    const getWBFileDataParams = useMemo(
      () => ({
        currentMediaMetadata: mediaMetadata,
        mediaIndex,
        mediaKey,
        run,
      }),
      [mediaMetadata, mediaIndex, mediaKey, run]
    );
    const maskData = useMemo(
      () => getMaskData(getWBFileDataParams),
      [getWBFileDataParams]
    );
    const boundingBoxData = useMemo(
      () => getBBoxData(getWBFileDataParams),
      [getWBFileDataParams]
    );

    const mediaSize = useMemo(() => {
      if (!isSprite && naturalDimensions != null) {
        // HAX: Turns out the media metadata in the run history only stores one media size per step,
        // so we don't even have data for how large the non-first image is when a user logs multiple media under one key in a single step.
        // So to hack around this limitation, we wait until the image is rendered to get its natural dimensions and use that instead.
        return naturalDimensions;
      }
      if (mediaWidth && mediaHeight) {
        return {width: mediaWidth, height: mediaHeight};
      }
      return null;
    }, [isSprite, naturalDimensions, mediaWidth, mediaHeight]);

    const showImage = !!maskOptions?.showImage;

    const imgStyle: CSSProperties = {
      left: -(actualSize ? originalPixelOffset : spritePixelOffset),
      height: '100%',
      display: showImage ? 'initial' : 'none',
      ...(!isSprite ? {width: '100%', objectFit: 'contain'} : {}),
    };

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    // get captions for all images in the group
    const captions = makeCaptions(mediaMetadata, mediaIndex);

    const cardJSX = (
      <div
        className="image-card content-card"
        data-test="image-content-card"
        style={{width}}>
        {labelComponent(props, step, titleLink)}
        <div className="image-card-image" style={{width, height}}>
          <img
            data-test="image-card-img"
            ref={imgRef}
            src={imageURL}
            alt="card"
            style={imgStyle}
          />

          {rolledBack && (
            <div className={'content-card__fallback'}>
              <Popup
                trigger={
                  <Icon
                    style={{color: 'white'}}
                    size="small"
                    name="exclamation circle"
                  />
                }>
                No media was found for step: {globalStep}
                <br />
                The most recent step: {step} is being displayed
              </Popup>
            </div>
          )}

          {filePath && originalWidth && (
            <LegacyWBIcon
              className="content-card__download"
              onClick={() => {
                downloadSpritePart(
                  runSignature,
                  filePath,
                  originalPixelOffset,
                  originalWidth,
                  {boxesRef, masksRef}
                );
              }}
              name="download"
            />
          )}

          {mediaSize && maskData.length > 0 && (
            <div ref={masksRef} className="segmentation-mask__container">
              {maskData.map(
                ({key, wbFile, classLabels}) =>
                  _.includes(maskOptions?.maskKeys ?? [], key) && (
                    <SegmentationMaskFromFile
                      style={{position: 'absolute', top: 0}}
                      key={key}
                      mediaKey={mediaKey}
                      maskControls={
                        controls?.segmentationMaskControl?.toggles?.[
                          mediaKey
                        ]?.[key]
                      }
                      maskKey={key}
                      classLabels={classLabels}
                      runSignature={runSignature}
                      mask={wbFile}
                      cardSize={{width, height}}
                      mediaSize={mediaSize}
                      mediaPanelRef={mediaPanelRef}
                    />
                  )
              )}
            </div>
          )}
          {mediaSize && boundingBoxData.length > 0 && (
            <div ref={boxesRef} className="bounding-boxes__container">
              {boundingBoxData.map(({key, wbFile, classLabels}) => (
                <BoundingBoxes
                  style={{position: 'absolute', top: 0}}
                  key={key}
                  mediaKey={mediaKey}
                  boxStyle={
                    controls?.boundingBoxControl?.styles?.[mediaKey]?.[key]
                  }
                  boxToggles={
                    controls?.boundingBoxControl?.toggles?.[mediaKey]?.[key]
                  }
                  boxSliders={controls?.boundingBoxControl?.sliders}
                  boxKey={key}
                  classLabels={classLabels}
                  runSignature={runSignature}
                  boxFileInfo={wbFile}
                  cardSize={{width, height}}
                  mediaSize={mediaSize}
                  mediaPanelRef={mediaPanelRef}
                />
              ))}
            </div>
          )}
        </div>
        {/* CAPTIONS */}
        {showMetadataInline && captions.length > 0 && (
          <div className="image-card-caption">{captions}</div>
        )}
      </div>
    );

    const enableFullscreen = showImage;
    if (!enableFullscreen) {
      return cardJSX;
    }

    return (
      // Click image to show original size in modal
      <Modal
        open={fullscreenModalOpen}
        onOpen={() => setFullscreenModalOpen(true)}
        onClose={() => setFullscreenModalOpen(false)}
        size="fullscreen"
        trigger={cardJSX}
        content={
          <S.FullscreenWrapper>
            <S.FullscreenImageContainer>
              <S.FullscreenImage
                style={{marginLeft: -originalPixelOffset}}
                alt="card"
                src={imageURL}
              />
              {mediaSize && boundingBoxData.length > 0 && (
                <S.FullscreenBoundingBoxContainer>
                  {boundingBoxData.map(({key, wbFile, classLabels}) => (
                    <BoundingBoxes
                      style={{position: 'absolute', top: 0}}
                      key={key}
                      mediaKey={mediaKey}
                      boxStyle={
                        controls?.boundingBoxControl?.styles?.[mediaKey]?.[key]
                      }
                      boxToggles={
                        controls?.boundingBoxControl?.toggles?.[mediaKey]?.[key]
                      }
                      boxSliders={controls?.boundingBoxControl?.sliders}
                      boxKey={key}
                      classLabels={classLabels}
                      runSignature={runSignature}
                      boxFileInfo={wbFile}
                      cardSize={mediaSize}
                      mediaSize={mediaSize}
                      mediaPanelRef={mediaPanelRef}
                    />
                  ))}
                </S.FullscreenBoundingBoxContainer>
              )}
            </S.FullscreenImageContainer>
          </S.FullscreenWrapper>
        }
      />
    );
  });

type SegmentationMaskFromFileProps = {
  style?: React.CSSProperties;
  classLabels:
    | {
        [key: number]: string;
      }
    | undefined;
  mediaKey: string;
  maskKey: string;
  mask: WBFile;
  runSignature: RunSignature;
  mediaSize: {
    width: number;
    height: number;
  };
  cardSize: {
    width: number;
    height: number;
  };
  mediaPanelRef: PartRefFromObjSchema<PanelTypes.PanelObjSchema>;
  maskControls?: {
    [classOrAll: string]: MaskControl;
  };
};

/**
 * Renders segmentation mask from WBFile
 */
const SegmentationMaskFromFile: React.FC<SegmentationMaskFromFileProps> =
  React.memo(props => {
    const {
      maskControls,
      mask,
      maskKey,
      runSignature,
      cardSize,
      mediaSize,
      style,
      classLabels,
    } = props;

    const [directURL, setDirectURL] = useState<string | undefined>(undefined);
    const onSuccess = useCallback(
      (__, metadata) => setDirectURL(metadata.directUrl),
      []
    );

    const loaderStyle = useMemo(
      () => ({...cardSize, ...style}),
      [cardSize, style]
    );

    const classOverlay = useMemo(() => maskControls ?? {}, [maskControls]);

    const classState = useMemo(
      () =>
        Object.fromEntries(
          _.map(classLabels, (name, classID) => {
            const [r, g, b] = segmentationMaskColor(parseInt(classID, 10));
            const color = `rgb(${r}, ${g}, ${b})`;
            return [classID, {name, color}];
          })
        ),
      [classLabels]
    );

    useLoadFile(runSignature, mask.path, {
      onSuccess,
      responseType: 'blob',
    });

    if (directURL == null) {
      return <div />;
    }

    return (
      <SegmentationMaskLoader
        key={maskKey}
        style={loaderStyle}
        mediaSize={mediaSize}
        classOverlay={classOverlay}
        classState={classState}
        directUrl={directURL}
      />
    );
  });

type ImageInfo = {
  imgSrc: string;
  imgFile: string;
  type: 'single' | 'sprite';
};

type GetImageInfoParams = {
  imgMedia: ImageMetadata | null;
  step: number;
  mediaIndex: number;
  mediaKey: string;
  runSignature: RunSignature;
};

export function getImageInfo({
  imgMedia,
  step,
  mediaIndex,
  mediaKey,
  runSignature,
}: GetImageInfoParams): ImageInfo {
  if (imgMedia?.path) {
    return {
      imgSrc: runFileSource(runSignature, imgMedia.path),
      imgFile: imgMedia.path,
      type: 'single',
    };
  }

  let fileParams: Array<string | number>;
  let type: ImageInfo['type'];
  if (imgMedia?._type === 'images/separated') {
    // This is the new format for a collection images which
    // is a set of individual images, not a sprite
    fileParams = [mediaKey, step, mediaIndex];
    type = 'single';
  } else {
    // Our old multiple image format expected a sprite
    // we use the mediaIndex to determine the pixel offset
    // for the image we want
    fileParams = [mediaKey, step];
    type = 'sprite';
  }

  const format = imgMedia?.format ?? 'jpg';

  return {
    imgSrc: mediaSrc(runSignature, fileParams, 'images', format),
    imgFile: mediaFilePath(fileParams, 'images', format),
    type,
  };
}

type WBFileData = {
  key: string;
  wbFile: WBFile;
  classLabels: {
    [key: number]: string;
  };
};

type GetWBFileByKeyFn = (
  mediaMetadata: ImageMetadata,
  mediaIndex: number
) => Struct<WBFile>;

type GetWBFileDataParams = {
  currentMediaMetadata: ImageMetadata | null;
  mediaIndex: number;
  mediaKey: string;
  run: RunWithHistoryAndMediaWandb;
};

function getMaskData(params: GetWBFileDataParams): WBFileData[] {
  return getWBFileData(getMasks, WANDB_MASK_CLASS_LABEL_KEY, params);
}
function getBBoxData(params: GetWBFileDataParams): WBFileData[] {
  return getWBFileData(getBoundingBoxes, WANDB_BBOX_CLASS_LABEL_KEY, params);
}

function getWBFileData(
  getWBFileByKeyFn: GetWBFileByKeyFn,
  classLabelKey: string,
  {currentMediaMetadata, mediaIndex, mediaKey, run}: GetWBFileDataParams
): WBFileData[] {
  if (currentMediaMetadata == null) {
    return [];
  }

  const wbFileByKey = getWBFileByKeyFn(currentMediaMetadata, mediaIndex);

  return Object.entries(wbFileByKey).map(([key, wbFile]) => ({
    key,
    wbFile,
    classLabels: getSingletonValue(
      run,
      classLabelKey,
      mediaKey + WANDB_DELIMETER + key
    ) as {[key: number]: string},
  }));
}
