import {debounce} from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import {useReportSearchQuery} from './../../generated/graphql';
import {reportEdit} from './../../util/urls';
import {extractReports} from './utils';

export const useReports = (searchTerm: string) => {
  const [term, setTerm] = useState(searchTerm);

  const debouncedSetTerm = useMemo(() => {
    return debounce(setTerm, 200);
  }, [setTerm]);

  useEffect(() => {
    debouncedSetTerm(searchTerm);
  }, [searchTerm, debouncedSetTerm]);

  const reportQueryResult = useReportSearchQuery({
    fetchPolicy: 'no-cache',
    skip: term === '',
    variables: {
      query: term === 'report' ? '' : term, // fetch all the reports if the search term is 'report'
    },
  });

  if (reportQueryResult.loading || !reportQueryResult.data) {
    return {
      data: [],
      loading: reportQueryResult.loading,
    };
  }

  const reports = extractReports(reportQueryResult.data);

  return {
    data: reports.map(report => ({
      ...report,
      route: reportEdit({
        entityName: report.entityName,
        projectName: report.projectName,
        reportID: report.id,
        reportName: report.displayName,
      }),
    })),
    loading: reportQueryResult.loading,
  };
};
