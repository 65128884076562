import * as Panel2 from '../panel';
import * as TableState from '../PanelTable/tableState';
import React, {useCallback, useMemo} from 'react';
import {PanelPlotProps} from '../PanelPlot/PanelPlot';
import * as ConfigPanel from '../ConfigPanel';
import * as Graph from '@wandb/cg';
import {usePanelStacksForType} from '../availablePanels';
import {useWeaveContext} from '../../../context';
import {ChildPanelConfigComp} from '../ChildPanel';
import {usePanelContext, PanelContextProvider} from '../PanelContext';

export const inputType = {type: 'list' as const, objectType: 'any' as const};

interface FacetConfig {
  table: TableState.TableState;
  dims: {
    x: TableState.ColumnId;
    y: TableState.ColumnId;
    select: TableState.ColumnId;
    detail: TableState.ColumnId;
  };
  manualSize: boolean;
  padding: number;
  cellSize: {
    w: number;
    h: number;
  };
  selectedCell?: {
    x: string;
    y: string;
  };
}

export function defaultFacet(): FacetConfig {
  let tableState = TableState.emptyTable();
  tableState = TableState.appendEmptyColumn(tableState);
  const xColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const yColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const selectColId = tableState.order[tableState.order.length - 1];
  tableState = TableState.appendEmptyColumn(tableState);
  const detailColId = tableState.order[tableState.order.length - 1];

  tableState = {...tableState, groupBy: [xColId, yColId]};
  tableState = {
    ...tableState,
    sort: [
      {columnId: xColId, dir: 'asc'},
      {columnId: yColId, dir: 'asc'},
    ],
  };

  return {
    table: tableState,
    dims: {
      x: xColId,
      y: yColId,
      select: selectColId,
      detail: detailColId,
    },
    padding: 0,
    manualSize: false,
    cellSize: {
      w: 200,
      h: 20,
    },
  };
}

export const useConfig = (
  propsConfig: FacetConfig | undefined
): FacetConfig => {
  return useMemo(() => {
    if (
      propsConfig == null ||
      propsConfig.dims == null ||
      propsConfig.dims.select == null
    ) {
      return defaultFacet();
    }
    return propsConfig;
  }, [propsConfig]);
};

export type PanelFacetProps = Panel2.PanelProps<typeof inputType, FacetConfig>;

export const DimConfig: React.FC<{
  dimName: string;
  input: PanelPlotProps['input'];
  colId: TableState.ColumnId;
  // Hack: you can pass an extra colID to include in when grouping is
  // toggled for this dim. This is used to toggle grouping for color/label
  // together.
  extraGroupColId?: string;
  tableConfig: TableState.TableState;
  updateTableConfig: (newTableState: TableState.TableState) => void;
}> = props => {
  const weave = useWeaveContext();
  const {colId, tableConfig, input, updateTableConfig} = props;

  const inputNode = input;

  const updateDim = useCallback(
    (node: Graph.Node) => {
      updateTableConfig(
        TableState.updateColumnSelect(tableConfig, colId, node)
      );
    },
    [updateTableConfig, tableConfig, colId]
  );

  const {frame} = usePanelContext();
  const {rowsNode} = useMemo(
    () =>
      TableState.tableGetResultTableNode(tableConfig, inputNode, frame, weave),
    [tableConfig, inputNode, frame, weave]
  );
  const cellFrame = useMemo(
    () =>
      TableState.getCellFrame(
        inputNode,
        rowsNode,
        frame,
        tableConfig.groupBy,
        tableConfig.columnSelectFunctions,
        colId
      ),
    [
      colId,
      inputNode,
      rowsNode,
      frame,
      tableConfig.columnSelectFunctions,
      tableConfig.groupBy,
    ]
  );

  return (
    <ConfigPanel.ExpressionConfigField
      frame={cellFrame}
      expr={tableConfig.columnSelectFunctions[colId]}
      setExpression={updateDim as any}
    />
  );
};
export const PanelFacetConfig: React.FC<PanelFacetProps> = props => {
  const {input, updateConfig: propsUpdateConfig} = props;
  const weave = useWeaveContext();
  const config = useConfig(props.config);
  const updateConfig = useCallback(
    (newConfig: Partial<FacetConfig>) => {
      propsUpdateConfig({
        ...config,
        ...newConfig,
      });
    },
    [config, propsUpdateConfig]
  );

  const tableConfig = config.table;
  const updateTableConfig = useCallback(
    (newTableConfig: TableState.TableState) =>
      updateConfig({
        table: newTableConfig,
      }),
    [updateConfig]
  );

  const cellSelectFunction =
    tableConfig.columnSelectFunctions[config.dims.select];
  const columnVars = useMemo(
    () => TableState.tableGetColumnVars(tableConfig, input, weave),
    [input, tableConfig, weave]
  );
  const cellPanel = tableConfig.columns[config.dims.select];
  const {stackIds: cellPanelStackOptions, curPanelId: curCellPanelId} =
    usePanelStacksForType(cellSelectFunction.type, cellPanel.panelId, {
      excludeTable: true,
    });
  console.log(
    'PANEL INFO',
    cellSelectFunction.type,
    cellPanel.panelId,
    cellPanelStackOptions,
    curCellPanelId
  );

  const updateCellPanelConfig = useCallback(
    (newConfig: any) => {
      updateTableConfig(
        TableState.updateColumnPanelConfig(
          tableConfig,
          config.dims.select,
          newConfig
        )
      );
    },
    [updateTableConfig, tableConfig, config.dims.select]
  );

  const {path, selectedPath} = usePanelContext();
  const pathStr = path.join('.');
  const selectedPathStr = selectedPath?.join('.') ?? '';

  if (selectedPathStr !== pathStr) {
    return (
      <PanelContextProvider newVars={columnVars}>
        <ChildPanelConfigComp
          pathEl="cell"
          config={{
            vars: {},
            input_node: config.table.columnSelectFunctions[config.dims.select],
            id: cellPanel.panelId,
            config: cellPanel.panelConfig,
          }}
          updateConfig={newChildPanelConfig => {
            // TODO: not complete yet.
            updateCellPanelConfig(newChildPanelConfig.config);
          }}
        />
      </PanelContextProvider>
    );
  }

  return (
    <div>
      <ConfigPanel.ConfigOption label={'x'}>
        <DimConfig
          dimName="x"
          colId={config.dims.x}
          input={input}
          tableConfig={tableConfig}
          updateTableConfig={updateTableConfig}
        />
      </ConfigPanel.ConfigOption>
      <ConfigPanel.ConfigOption label={'y'}>
        <DimConfig
          dimName="y"
          colId={config.dims.y}
          input={input}
          tableConfig={tableConfig}
          updateTableConfig={updateTableConfig}
        />
      </ConfigPanel.ConfigOption>
    </div>
  );
};
