import config from '@wandb/common/config';
import {ApolloQueryResult} from 'apollo-client/core/types';
import {useEffect} from 'react';

import {FetchPreviewFriendlyUrlQuery} from '../../generated/graphql';
import {ReportPageNormalized} from '../../state/views/report/types';
import {LoadableView} from '../../state/views/types';
import {ReportAuthor} from '../../util/gallery/shared';
import {getPanelSpec, isExportableAs, LayedOutPanel} from '../../util/panels';
import {editablePanelDOMID} from '../../util/reportExport';
import {generatePanelURL} from '../Panel/util';

type CreatePreviewFriendlyURLInput = {
  token?: string; // Access token for the report view
  firstPanelExportableAsImage: LayedOutPanel | undefined; // The first panel in the report that can be exported as an image
  origin: string; // Report URL origin
  encodedPathname: string; // Report URL pathname
  view: LoadableView; // Report view
  reportAuthors: ReportAuthor[]; // Report authors
  isMounted: () => boolean; // Function to check if the component is mounted
  setDidAttemptCreatePFURLSinceMount?: (didCreatePFRUL: boolean) => void; // Function to set whether we've attempted to create a preview-friendly URL since the component mounted
  refetchPreviewFriendlyURL?: (
    variables?:
      | {
          viewID: string;
        }
      | undefined
  ) => Promise<ApolloQueryResult<FetchPreviewFriendlyUrlQuery>>; // Function to refetch the preview-friendly URL
};

// This function is used to create a preview-friendly URL for the report if one
// does not already exist and the report has an exportable panel.
export const createPreviewFriendlyURL = async ({
  token,
  firstPanelExportableAsImage,
  origin,
  encodedPathname,
  view,
  reportAuthors,
  isMounted,
  setDidAttemptCreatePFURLSinceMount,
  refetchPreviewFriendlyURL,
}: CreatePreviewFriendlyURLInput) => {
  if (firstPanelExportableAsImage == null || view.id == null) {
    return;
  }
  if (config.ENVIRONMENT_IS_PRIVATE) {
    console.info(
      'Private environment detected - skipping creation of preview-friendly URL.'
    );
    return;
  }
  const panelDomID = editablePanelDOMID(firstPanelExportableAsImage);
  const panelDomEl = document.getElementById(panelDomID);
  if (panelDomEl == null) {
    console.error(
      `Invalid panel: ${panelDomID} not found in the DOM. Cannot create a preview-friendly URL.`
    );
    return;
  }
  const reportAuthorsString =
    reportAuthors
      ?.map(author => author?.name)
      .filter(name => name != null && name !== '')
      .join(', ') || 'Weights & Biases';
  const redirectURL = `${origin}${encodedPathname}${
    token == null ? `` : `?accessToken=${token}`
  }`;
  const prevFriendlyURL = await generatePanelURL({
    panelDomEl,
    entityName: view.project?.entityName ?? '',
    title: view.displayName,
    description: view.description,
    panel: firstPanelExportableAsImage,
    viewID: view.id,
    redirectURL,
    author: reportAuthorsString,
  });
  if (prevFriendlyURL && refetchPreviewFriendlyURL) {
    try {
      await refetchPreviewFriendlyURL();
    } catch (e) {
      if (isMounted()) {
        console.error(e);
      }
    }
    setDidAttemptCreatePFURLSinceMount?.(true);
  }
  return prevFriendlyURL;
};

type IsPreviewFriendlyURLOutdatedInput = Pick<
  UseMaybeSurreptitiouslyCreatePreviewFriendlyURLInput,
  | 'fetchPreviewFriendlyURLData'
  | 'viewLastUpdatedAt'
  | 'refetchPreviewFriendlyURL'
>;

/**
 * This function is used to check if the current preview-friendly URL is outdated.
 * Returns true if there does not yet exist a preview-friendly URL or if the
 * preview-friendly URL was created/updated before the report was last updated.
 *
 */
export const isPreviewFriendlyURLOutdated = async ({
  viewLastUpdatedAt,
  fetchPreviewFriendlyURLData,
  refetchPreviewFriendlyURL,
}: IsPreviewFriendlyURLOutdatedInput) => {
  // Only create a new PFURL if the view has been updated since the current PFURL was created/updated
  let pfURLLastUpdatedAt: Date | undefined;
  if (fetchPreviewFriendlyURLData == null) {
    // Await the refetch of the preview-friendly URL if it hasn't been fetched yet
    try {
      const queryRes = await refetchPreviewFriendlyURL?.();
      if (
        queryRes?.data?.previewFriendlyURL?.updatedAt ||
        queryRes?.data?.previewFriendlyURL?.createdAt
      ) {
        pfURLLastUpdatedAt =
          queryRes.data.previewFriendlyURL.updatedAt ??
          queryRes.data.previewFriendlyURL.createdAt;
      }
    } catch (e) {
      console.error(e);
    }
  } else {
    if (
      fetchPreviewFriendlyURLData.previewFriendlyURL?.updatedAt ||
      fetchPreviewFriendlyURLData.previewFriendlyURL?.createdAt
    ) {
      pfURLLastUpdatedAt =
        fetchPreviewFriendlyURLData.previewFriendlyURL.updatedAt ??
        fetchPreviewFriendlyURLData.previewFriendlyURL.createdAt;
    }
  }
  if (pfURLLastUpdatedAt != null) {
    // If the PFURL was created/updated after the report was last updated, we don't need to create a new one
    if (pfURLLastUpdatedAt > viewLastUpdatedAt) {
      return false;
    }
  }
  return true;
};

type UseMaybeSurreptitiouslyCreatePreviewFriendlyURLInput = Omit<
  CreatePreviewFriendlyURLInput,
  'token'
> & {
  // Generated types do not match manually-defined types - using "any" hack for now
  currentAccessToken?: string; // Current public access token for the report view
  shouldCreateAndRevokeAccessTokenIfNotExists?: boolean; // Whether to create and revoke a public access token if one does not already exist to be used in the redirect URL
  createMagicLink?: (
    shouldSkipPFURLCreation?: boolean
  ) => Promise<string | undefined>; // async function to create a magic link
  deactivateLink?: (token: string) => Promise<void>; // async function to deactivate a magic link
  setIsSurreptitiouslyCreatingAndRevokingToken?: (
    isSurreptitiouslyCreatingAndRevokingToken: boolean
  ) => void; // Function to set whether the surreptitious access token creation/revoking is in progress
  userIsMemberOfTeam: boolean; // Whether the current user is a member of the team
  viewLastUpdatedAt: Date; // Timestamp of when the report was last updated
  fetchPreviewFriendlyURLData?: FetchPreviewFriendlyUrlQuery; // Data from the fetchPreviewFriendlyURL query
};

/** This function is used to pre-generate a preview-friendly URL for the report
 * if one does not already exist and the report has an exportable panel, tracking
 * that we've just generated the
 * URL so that we can skip this time-consuming step when the user toggles the magic
 * link on.
 */
export function useMaybeSurreptitiouslyCreatePreviewFriendlyURL({
  firstPanelExportableAsImage,
  origin,
  encodedPathname,
  view,
  reportAuthors,
  isMounted,
  setDidAttemptCreatePFURLSinceMount,
  currentAccessToken,
  refetchPreviewFriendlyURL,
  shouldCreateAndRevokeAccessTokenIfNotExists,
  createMagicLink,
  deactivateLink,
  setIsSurreptitiouslyCreatingAndRevokingToken,
  userIsMemberOfTeam,
  viewLastUpdatedAt,
  fetchPreviewFriendlyURLData,
}: UseMaybeSurreptitiouslyCreatePreviewFriendlyURLInput) {
  useEffect(() => {
    const maybeCreatePFURL = async () => {
      if (firstPanelExportableAsImage == null) {
        return;
      }
      // Only create a new PFURL if the user is a member of the team
      if (!userIsMemberOfTeam) {
        return;
      }
      // Don't attempt to create PFURL in private environments
      if (config.ENVIRONMENT_IS_PRIVATE) {
        console.info(
          'Private environment detected - skipping creation of preview-friendly URL.'
        );
        return;
      }

      const isCurrentPFURLOutdated = await isPreviewFriendlyURLOutdated({
        viewLastUpdatedAt,
        fetchPreviewFriendlyURLData,
        refetchPreviewFriendlyURL,
      });

      // Only create a new PFURL if the view has been updated since the current PFURL was created/updated
      if (!isCurrentPFURLOutdated) {
        return;
      }

      let token = currentAccessToken;

      // This section is concerned w/ creating and revoking a new access token to be used in the redirect URL
      if (
        shouldCreateAndRevokeAccessTokenIfNotExists &&
        currentAccessToken == null
      ) {
        // Ensure caller has provided functions to create and revoke access tokens
        if (createMagicLink == null || deactivateLink == null) {
          console.error(
            'createMagicLink or deactivateLink is null, but shouldCreateAndRevokeAccessTokenIfNotExists is true and currentAccessToken is null'
          );
          return;
        }
        // Create a new access token to be used in the redirect URL (and revoke it after the PFURL is created)
        setIsSurreptitiouslyCreatingAndRevokingToken?.(true);
        try {
          const newToken = await createMagicLink(true);
          if (newToken == null) {
            console.error('createMagicLink returned null');
            setIsSurreptitiouslyCreatingAndRevokingToken?.(false);
            return;
          }
          token = newToken;
          // Revoke the access token we created
          try {
            await deactivateLink(token);
          } catch (e) {
            console.error('Error revoking access token:', e);
          }
          setIsSurreptitiouslyCreatingAndRevokingToken?.(false);
        } catch (e) {
          console.error(e);
          setIsSurreptitiouslyCreatingAndRevokingToken?.(false);
          return;
        }
      }

      try {
        await createPreviewFriendlyURL({
          token,
          firstPanelExportableAsImage,
          setDidAttemptCreatePFURLSinceMount,
          origin,
          encodedPathname,
          view,
          reportAuthors,
          isMounted,
          refetchPreviewFriendlyURL,
        });
      } catch (e) {
        console.error(e);
      }
    };

    maybeCreatePFURL();
    // We only want to run this effect once when the component mounts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const firstPanelExportableAsImageFromViewPart = (
  viewPart: ReportPageNormalized
) =>
  viewPart.blocks
    .map(block => {
      const {metadata} = block;
      const {panels}: {panels: LayedOutPanel[]} =
        metadata?.panelBankSectionConfig ?? {panels: []};
      return panels.find(panel => {
        const panelSpec = getPanelSpec(panel.viewType);
        return isExportableAs(panelSpec, 'image');
      });
    })
    .filter(panel => panel != null)[0];
