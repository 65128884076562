import {captureError} from '@wandb/common/util/integrations';
import {FC, memo, useEffect} from 'react';

import * as Track from '../../services/analytics';
import {
  checkExperimentLocalStorage,
  setLocalStorageExperiments,
} from './localStorage';
import {ExperimentData} from './types';
import {decodedExperimentId} from './utils';

export const VARIANT_COMPONENT_DISPLAY_NAME = 'VariantComponent';

export type VariantProps = {
  experimentData?: ExperimentData;
  bucket: number;
  control?: boolean;
  children: any;
};

const VariantComp: FC<VariantProps> = ({experimentData, bucket, children}) => {
  useEffect(() => {
    if (experimentData != null) {
      const {
        graphqlExperimentId,
        experimentName,
        observationalUnitId,
        hash,
        treatment: experimentTreatment,
        log: logExperiment,
      } = experimentData;

      const {isAlreadyLogged, storedExperiments} =
        checkExperimentLocalStorage(experimentData);
      if (logExperiment && experimentTreatment === bucket && !isAlreadyLogged) {
        try {
          Track.allocatedToExperiment({
            experimentName,
            graphqlId: graphqlExperimentId,
            id: decodedExperimentId(graphqlExperimentId),
            observationalUnitId,
            hash,
            bucket,
            experimentBucket: bucket,
          });

          // Update local storage only when experiment event is logged successfully
          setLocalStorageExperiments(storedExperiments);
        } catch (e) {
          captureError(
            'Error sending "Allocated To Experiment" event to Segment',
            'Variant Component',
            {extra: {experimentData, bucket}}
          );
        }
      }
    }
  }, [bucket, experimentData]);

  if (experimentData == null) {
    captureError(
      'Invalid experiment data in Variant component',
      'Variant Component',
      {extra: {experimentData, bucket}}
    );
    return null;
  }

  return experimentData.treatment === bucket ? children : null;
};

export const Variant = memo(VariantComp);
Variant.displayName = VARIANT_COMPONENT_DISPLAY_NAME;
