import classNames from 'classnames';
import React from 'react';
import {Form, Radio} from 'semantic-ui-react';

interface RadioFormOption {
  label: string;
  key: string;
}

interface RadioFormProps {
  name: string;
  options: RadioFormOption[];
  value: string;
  onChange: (newValue: string) => void;
}

const RadioForm: React.FC<RadioFormProps> = ({
  options,
  name,
  value,
  onChange,
}) => {
  return (
    <>
      {options.map(({label, key}) => {
        const checked = value === key;
        return (
          <Form.Field
            key={key}
            className={classNames({checked})}
            control={Radio}
            name={name}
            value={key}
            label={label}
            checked={checked}
            onChange={() => onChange(key)}
          />
        );
      })}
    </>
  );
};

export default RadioForm;
