import React, {FC} from 'react';
import {RenderElementProps} from 'slate-react';

import {BlockQuoteElement, isBlockQuote} from '../plugins/block-quotes';
import {
  CalloutBlockElement,
  CalloutLineElement,
  isCalloutBlock,
  isCalloutLine,
} from '../plugins/callout-blocks';
import {CaptivateElement, isCaptivate} from '../plugins/captivate';
import {
  CodeBlockElement,
  CodeLineElement,
  isCodeBlock,
  isCodeLine,
} from '../plugins/code-blocks';
import {CraiyonElement, isCraiyon} from '../plugins/craiyon';
import {CTABannerElement, isCTABanner} from '../plugins/cta-banner';
import {CTAButtonElement, isCTAButton} from '../plugins/cta-button';
import {GalleryElement, isGallery} from '../plugins/gallery';
import {HeadingElement, isHeading} from '../plugins/headings';
import {
  HorizontalRuleElement,
  isHorizontalRule,
} from '../plugins/horizontal-rules';
import {ImageElement, isImage} from '../plugins/images';
import {isLatex, LatexElement} from '../plugins/latex';
import {isLink, LinkElement} from '../plugins/links';
import {
  isList,
  isListItem,
  ListElement,
  ListItemElement,
} from '../plugins/lists';
import {MarkdownBlockElement} from '../plugins/markdown-blocks';
import {isMarkdownBlock} from '../plugins/markdown-blocks-common';
import {isPanelGrid, PanelGridElement} from '../plugins/panel-grid';
import {isParagraph, Paragraph, ParagraphElement} from '../plugins/paragraphs';
import {isReplit, ReplitElement} from '../plugins/replit';
import {isSoundCloud, SoundCloudElement} from '../plugins/soundcloud';
import {isSpotify, SpotifyElement} from '../plugins/spotify';
import {
  isTableOfContents,
  TableOfContentsElement,
} from '../plugins/table-of-contents';
import {
  isTable,
  isTableCell,
  isTableRow,
  TableCellElement,
  TableElement,
  TableRowElement,
} from '../plugins/tables';
import {isTwitter, TwitterElement} from '../plugins/twitter';
import {isVideo, VideoElement} from '../plugins/videos';
import {
  isWeavePanel,
  isWeavePythonPanel,
  WeavePanelElement,
  WeavePythonPanelElement,
} from '../plugins/weave-panels';

export const WBSlateElement: FC<RenderElementProps> = props => {
  const {element} = props;
  if (isParagraph(element)) {
    return <ParagraphElement {...props} element={element} />;
  }
  if (isListItem(element)) {
    return <ListItemElement {...props} element={element} />;
  }
  if (isList(element)) {
    return <ListElement {...props} element={element} />;
  }
  if (isBlockQuote(element)) {
    return <BlockQuoteElement {...props} element={element} />;
  }
  if (isGallery(element)) {
    return <GalleryElement {...props} element={element} />;
  }
  if (isHeading(element)) {
    return <HeadingElement {...props} element={element} />;
  }
  if (isCodeBlock(element)) {
    return <CodeBlockElement {...props} element={element} />;
  }
  if (isCodeLine(element)) {
    return <CodeLineElement {...props} element={element} />;
  }
  if (isCalloutBlock(element)) {
    return <CalloutBlockElement {...props} element={element} />;
  }
  if (isCalloutLine(element)) {
    return <CalloutLineElement {...props} element={element} />;
  }
  if (isLink(element)) {
    return <LinkElement {...props} element={element} />;
  }
  if (isMarkdownBlock(element)) {
    return <MarkdownBlockElement {...props} element={element} />;
  }
  if (isPanelGrid(element)) {
    return <PanelGridElement {...props} element={element} />;
  }
  if (isWeavePanel(element)) {
    return <WeavePanelElement {...props} element={element} />;
  }
  if (isWeavePythonPanel(element)) {
    return <WeavePythonPanelElement {...props} element={element} />;
  }
  if (isImage(element)) {
    return <ImageElement {...props} element={element} />;
  }
  if (isTable(element)) {
    return <TableElement {...props} element={element} />;
  }
  if (isTableRow(element)) {
    return <TableRowElement {...props} element={element} />;
  }
  if (isTableOfContents(element)) {
    return <TableOfContentsElement {...props} element={element} />;
  }
  if (isTableCell(element)) {
    return <TableCellElement {...props} element={element} />;
  }
  if (isHorizontalRule(element)) {
    return <HorizontalRuleElement {...props} element={element} />;
  }
  if (isLatex(element)) {
    return <LatexElement {...props} element={element} />;
  }
  if (isVideo(element)) {
    return <VideoElement {...props} element={element} />;
  }
  if (isCraiyon(element)) {
    return <CraiyonElement {...props} element={element} />;
  }
  if (isTwitter(element)) {
    return <TwitterElement {...props} element={element} />;
  }
  if (isSpotify(element)) {
    return <SpotifyElement {...props} element={element} />;
  }
  if (isReplit(element)) {
    return <ReplitElement {...props} element={element} />;
  }
  if (isSoundCloud(element)) {
    return <SoundCloudElement {...props} element={element} />;
  }
  if (isCaptivate(element)) {
    return <CaptivateElement {...props} element={element} />;
  }
  if (isCTABanner(element)) {
    return <CTABannerElement {...props} element={element} />;
  }
  if (isCTAButton(element)) {
    return <CTAButtonElement {...props} element={element} />;
  }
  // default to paragraph for unknowns, to prevent crashes after funky migrations
  return <ParagraphElement {...props} element={element as Paragraph} />;
};
