import * as globals from '@wandb/common/css/globals.styles';
import React from 'react';
import styled from 'styled-components';

export const NudgeBarLargeTitle = styled.h1`
  font-size: 1.5rem;
`;

export const NudgeBarLargeText = styled.div`
  font-size: 1rem;
  margin-bottom: 34px;
`;

export const NudgeBarLargeAction = styled.h1`
  padding: 18px 0;
`;

export const NudgeBarLargeActionLink = styled.a`
  font-size: 1.4rem;
  color: ${globals.white};
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 10px 20px;
  margin: 0.5rem;
  background-color: transparent;

  &:hover {
    color: ${globals.white};
  }
`;

export const NudgeBarLargeActionButton = styled.div`
  font-color: ${globals.gray500};
  display: inline-flex;
`;

export const NudgeBarLarge: React.FC = styled.div<{bgColor?: 'errorDark'}>`
  background-color: ${p => globals[p.bgColor ?? 'errorDark']};
  color: ${globals.white};
  text-align: center;
  padding: 60px 10px;

  border-bottom: 1px solid ${globals.white};
`;
