import {match, matchPath} from 'react-router';

import {
  BILLING_PAGE,
  ONBOARDING_FLOW_PATH,
  PROFILE_PATH,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  USAGE_PATH,
} from './paths';
import {ROUTES} from './routes';

export function getRouteMatch(path: string): match | null {
  for (const r of ROUTES) {
    const m = matchPath(path, {path: r.path, exact: r.exact});
    if (m != null) {
      return m;
    }
  }
  return null;
}

export function isReportViewPath(path: string): boolean {
  return isSpecificPath(path, [
    REPORT_PAGE_VIEW_PATH,
    REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  ]);
}

export function hasEntityNameRouteParameter(path: string): boolean {
  const m = getRouteMatch(path);
  return m?.params.hasOwnProperty('entityName') ?? false;
}

export function isUsagePath(path: string): boolean {
  return isSpecificPath(path, [USAGE_PATH]);
}

export function isBillingPath(path: string): boolean {
  return isSpecificPath(path, [...BILLING_PAGE]);
}

export function isSpecificPath(path: string, pathFormats: string[]): boolean {
  const m = getRouteMatch(path);
  if (m == null) {
    return false;
  }
  return pathFormats.indexOf(m.path) !== -1;
}

export function isProfilePath(path: string): boolean {
  const m = getRouteMatch(path);
  return m?.path === PROFILE_PATH;
}

export function isOnboardingFlowPath(path: string): boolean {
  const m = getRouteMatch(path);
  return m?.path === ONBOARDING_FLOW_PATH;
}
