import * as React from 'react';

import emptyImg from '../../assets/il-no-visuals-yet.png';
import emptyImg2x from '../../assets/il-no-visuals-yet@2x.png';
import EmptyWatermark from '../EmptyWatermark';

interface EmptyVisualizationsProps {
  headerText?: string;
  helpText?: string | JSX.Element;
}

export const EmptyVisualizations = (props: EmptyVisualizationsProps) => {
  return (
    <EmptyWatermark
      className="empty-watermark-visualizations"
      imageSource={emptyImg}
      imageSource2x={emptyImg2x}
      header={props.headerText || 'No visualizations yet.'}
      details={
        props.helpText ||
        "Add visual components to illustrate the runs from this section's data."
      }
      wide
    />
  );
};
