import * as _ from 'lodash';
import {useMemo} from 'react';

import {useMemoizedSelector} from '../../state/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as Normalize from '../../state/views/normalize';
import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import {PanelBankConfig} from '../../util/panelbank';
import {SectionWithRef} from './PanelBank';

export type PanelBankConfigWithRefs = Omit<PanelBankConfig, 'sections'> & {
  sections: SectionWithRef[];
};

export function usePanelBankConfigWithRefs(
  panelBankConfigRef: PanelBankConfigTypes.Ref
) {
  const panelBankConfig = ViewHooks.useWhole(panelBankConfigRef);
  const panelBankConfigPart = ViewHooks.usePart(panelBankConfigRef);
  const panelBankSectionConfigRefs = panelBankConfigPart.sectionRefs;
  const {panelRefsBySectionRefId, panelSettingsRefBySectionRefId} =
    useMemoizedSelector(state => {
      const refMap: {[keys: string]: PanelTypes.Ref[]} = {};
      const settingsRefMap: {[keys: string]: PanelSettingsTypes.Ref} = {};
      const parts = state.views.parts;
      panelBankSectionConfigRefs.forEach(sectionRef => {
        const panelBankSectionConfigPart = Normalize.lookupPart(
          parts,
          sectionRef
        );
        refMap[sectionRef.id] = panelBankSectionConfigPart.panelRefs;
        settingsRefMap[sectionRef.id] =
          panelBankSectionConfigPart.localPanelSettingsRef;
      });

      return {
        panelRefsBySectionRefId: refMap,
        panelSettingsRefBySectionRefId: settingsRefMap,
      };
    }, _.isEqual);
  const panelBankConfigWithRefs: PanelBankConfigWithRefs = useMemo(() => {
    // whole panelBankConfig, with section and panel refs
    return {
      ...panelBankConfig,
      sections: panelBankConfig.sections.map((s, i) => {
        const sectionRef = panelBankSectionConfigRefs[i];
        return {
          ...s,
          localPanelSettingsRef: panelSettingsRefBySectionRefId[sectionRef.id],
          ref: sectionRef,
          panels: s.panels.map((p, j) => {
            return {
              ...p,
              ref: panelRefsBySectionRefId[sectionRef.id][j],
            };
          }),
        };
      }),
    };
  }, [
    panelRefsBySectionRefId,
    panelBankConfig,
    panelBankSectionConfigRefs,
    panelSettingsRefBySectionRefId,
  ]);

  return panelBankConfigWithRefs;
}
