import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Button, Input, Popup} from 'semantic-ui-react';

import {useProjectPageQuery} from '../../generated/graphql';
import {isInJupyterNotebook} from '../../setup';
import {PanelBankContext} from '../../state/panelbank/context';
import {
  usePanelSearchDebouncedContext,
  usePanelSearchInputContext,
} from '../../state/panelbank/PanelSearchContextProvider';
import {usePushReport} from '../../state/reports/hooks';
import {useViewer} from '../../state/viewer/hooks';
import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelBankConfigActions from '../../state/views/panelBankConfig/actions';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import {PanelBankSectionConfig} from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {panelIsActive, searchRegexFromQuery} from '../../util/panelbank';
import {getFlowSectionsWithVisiblePanels} from '../../util/panelbankFlow';
import {LayedOutPanel} from '../../util/panels';
import * as Report from '../../util/report';
import * as RunHelpers from '../../util/runhelpers';
import {
  CREATE_REPORT_FLASHER_FEATURE_KEY,
  useOnboardingContext,
  USER_TOGGLED_TABLE_VIEW_FEATURE_KEY,
} from '../../util/userContext';
import {CreateReportModal} from '../CreateReportModal';
import {PersistentFlasher} from '../elements/PersistentFlasher';
import {LinePlotPanel, PanelSettingsComponent} from '../PanelSettings';
import {CreateReportTooltipPrompt} from '../Reports';
import * as PanelRunsLinePlot from './../PanelRunsLinePlot';
import {PanelBankControlsModal} from './PanelBankControlsModal';
import {PanelBankConfigWithRefs} from './usePanelBankConfigWithRefs';

interface PanelBankControlsProps {
  entityName: string;
  projectName: string;
  readOnly?: boolean;
  panelSettingsRef: PanelSettingsTypes.Ref;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  runSetRefs: RunSetViewTypes.Ref[];
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  singleRun?: boolean;
  historyKeyInfo: RunHistoryKeyInfo;
  panelBankWidth: number;
  workspaceID?: string;
  resetAllLocals(
    localSettings: PanelSettingsTypes.PanelSettings,
    workspaceSettings: PanelSettingsTypes.PanelSettings
  ): void;
}

// The input box used for searching panels
export const PanelSearchInput: React.FC = () => {
  const {searchInputCallbackRef, searchQuery, onChange} =
    usePanelSearchInputContext();
  return (
    <Input
      ref={searchInputCallbackRef}
      className="panel-bank__search"
      icon={<LegacyWBIcon name="search" />}
      iconPosition="left"
      tabIndex={0}
      value={searchQuery}
      placeholder="Search panels"
      onChange={onChange}
    />
  );
};

// This is the bar at the top of the PanelBank that contains search and settings
const PanelBankControls = (props: PanelBankControlsProps) => {
  const {
    entityName,
    projectName,
    readOnly,
    panelBankConfigRef,
    panelBankConfigWithRefs,
    panelSettingsRef,
    runSetRefs,
    customRunColorsRef,
    singleRun,
    historyKeyInfo,
    panelBankWidth,
    workspaceID,
    resetAllLocals,
  } = props;

  const viewer = useViewer();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const updatePanelBankSettings = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.updateSettings
  );

  const {currentPageBySectionRefID} = useContext(PanelBankContext);

  const {debouncedSearchQuery} = usePanelSearchDebouncedContext();

  const useExportableSections = () => {
    const {sections} = ViewHooks.useWhole(panelBankConfigRef);
    return useMemo(
      () =>
        getFlowSectionsWithVisiblePanels({
          sections,
          currentPageBySectionRefID,
          singleRun,
          debouncedSearchQuery,
          historyKeyInfo,
          panelBankWidth,
        }),
      [sections]
    );
  };

  const pushDraftReport = usePushReport(entityName, projectName);

  const useAllLinePlotPanels = () => {
    const {sections} = ViewHooks.useWhole(panelBankConfigRef);
    return useMemo(
      () =>
        _.flatten(
          sections.map(s =>
            s.panels.filter(p => p.viewType === PanelRunsLinePlot.PANEL_TYPE)
          )
        ) as unknown as LinePlotPanel[],
      [sections]
    );
  };

  const keyTypes = RunHelpers.useKeyTypes(historyKeyInfo);
  const searchRegex = searchRegexFromQuery(debouncedSearchQuery);
  const usePanelIsActive = useCallback(
    (section: PanelBankSectionConfig, panel: LayedOutPanel) =>
      panelIsActive({
        section,
        panel,
        singleRun,
        searchRegex,
        historyKeyInfo,
        keyTypes,
      }),
    [singleRun, searchRegex, historyKeyInfo, keyTypes]
  );

  const [workspaceSettingsLabelPopupOpen, setWorkspaceSettingsLabelPopupOpen] =
    useState(false);

  return (
    <div className="panel-bank__actions-wrapper">
      <div className="panel-bank__actions">
        <PanelSearchInput />
        <PanelSettingsComponent
          runSetRefs={runSetRefs}
          workspacePanelSettingsRef={panelSettingsRef}
          useAllLinePlotPanels={useAllLinePlotPanels}
          resetAllLocals={resetAllLocals}
        />
        {workspaceID != null && (
          <Popup
            content="Workspace settings"
            open={workspaceSettingsLabelPopupOpen}
            onClose={() => setWorkspaceSettingsLabelPopupOpen(false)}
            onOpen={() => {
              if (!isModalOpen) {
                setWorkspaceSettingsLabelPopupOpen(true);
              }
            }}
            position="bottom right"
            trigger={
              <Button
                size="tiny"
                className={'wb-icon-button only-icon'}
                onClick={() => {
                  setWorkspaceSettingsLabelPopupOpen(false);
                  setIsModalOpen(true);
                }}>
                <LegacyWBIcon name="configuration" />
              </Button>
            }
          />
        )}
        {workspaceID != null && (
          <PanelBankControlsModal
            panelBankConfigRef={panelBankConfigRef}
            panelBankConfigWithRefs={panelBankConfigWithRefs}
            workspaceID={workspaceID}
            isModalOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
            updatePanelBankSettings={updatePanelBankSettings}
          />
        )}
        {!readOnly && viewer != null && (
          <>
            {!isInJupyterNotebook() && (
              <CreateReportButton
                disabled={false}
                entityName={entityName}
                projectName={projectName}
                onClick={() => {
                  window.analytics?.track('Create Report Started', {
                    location: `workspace create report button`,
                    entityName,
                    projectName,
                  });

                  const report = Report.getEmptyReportConfig();
                  pushDraftReport(report, undefined, {
                    newTab: true,
                  });
                }}
              />
            )}
            <CreateReportModal
              useExportableSections={useExportableSections}
              panelSettingsRef={panelSettingsRef}
              runSetRefs={runSetRefs}
              customRunColorsRef={customRunColorsRef}
              panelIsActive={usePanelIsActive}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PanelBankControls;

const CreateReportButton: React.FC<{
  disabled: boolean;
  entityName: string;
  projectName: string;
  onClick(): void;
}> = ({disabled, entityName, projectName, onClick}) => {
  const projectQuery = useProjectPageQuery({
    variables: {
      entityName,
      projectName,
    },
  });
  const runCount = projectQuery.data?.project?.runCount;

  const {onboardingState, setOnboardingState, loading} = useOnboardingContext(
    CREATE_REPORT_FLASHER_FEATURE_KEY
  );
  const {onboardingState: tableViewState, loading: tableViewLoading} =
    useOnboardingContext(USER_TOGGLED_TABLE_VIEW_FEATURE_KEY);

  // Show the flashing blue dot if there are more than 20 runs
  // and the user hasn't yet clicked this button to dismiss createReport onboarding
  // and the 'toggle table view' flasher isn't present.
  const tableViewToggled =
    !tableViewLoading && !!tableViewState.toggledTableView;
  const flasherEnabled =
    !loading &&
    !onboardingState.createReportFlasher &&
    tableViewToggled &&
    runCount != null &&
    runCount > 20;

  useEffect(() => {
    if (flasherEnabled) {
      window.analytics?.track('Flasher Viewed', {
        entityName,
        projectName,
        location: 'create report button',
      });
    }
  }, [flasherEnabled, entityName, projectName]);

  const onCreateReportClick = () => {
    onClick();
    if (flasherEnabled) {
      window.analytics?.track('Flasher Dismissed', {
        entityName,
        projectName,
        location: 'create report button',
      });
    }
    setOnboardingState({...onboardingState, createReportFlasher: true});
  };
  return (
    <PersistentFlasher
      visible={flasherEnabled}
      offsetX={-69}
      style={{zIndex: 99}}
      trigger={
        <Button
          className="save-snapshot-button"
          primary
          onClick={onCreateReportClick}
          disabled={disabled}>
          Create report
        </Button>
      }
      popupClassName={`create-report-prompt-tooltip`}
      popupPosition={`bottom right`}
      popupOffsetX={13}
      popupContent={
        <CreateReportTooltipPrompt
          location={`workspace`}
          entityName={entityName}
          projectName={projectName}
        />
      }
    />
  );
};
