import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {
  DragDropContext,
  DragDropProvider,
  DragDropState,
  DropTarget,
} from '@wandb/common/containers/DragDropContainer';
import {compact, findIndex, flatten, isEqual} from 'lodash';
import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Button} from 'semantic-ui-react';

import {usePrevious} from '../../state/hooks';
import {usePanelSearchDebouncedContext} from '../../state/panelbank/PanelSearchContextProvider';
import {useRunsWandbConfigQuery} from '../../state/runs/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankConfigActions from '../../state/views/panelBankConfig/actions';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import {PanelBankSectionConfigObjSchema} from '../../state/views/panelBankSectionConfig/types';
import {PartRefFromObjSchema} from '../../state/views/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {
  getMousePositionMemoized,
  getMousePositionRelativeTo,
} from '../../util/mouse';
import {
  EMPTY_PANEL_BANK_SETTINGS,
  expectedAPIAddedPanelSpecsFromPanelBankConfigs,
  getPanelBankDiff,
  isPanel,
  isPanelBankSection,
  isSectionVisible,
  keyInfoToAddedPanels,
  keyInfoToDefaultPanelSpecs,
  PanelBankConfigState,
  panelBankConfigToFoundPanelSpecs,
  panelIsActive,
  searchRegexFromQuery,
} from '../../util/panelbank';
import * as Panels from '../../util/panels';
import * as RunHelpers from '../../util/runhelpers';
import {EmptyVisualizations} from './EmptyVisualizations';
import {PanelBankProps} from './PanelBank';
import PanelBankAddVisButton from './PanelBankAddVisButton';
import {PanelBankSection} from './PanelBankSection';
import {PanelBankConfigWithRefs} from './usePanelBankConfigWithRefs';

const MARGIN_DRAG_HANDLE_ICON = 12;
const SECTION_TITLE_HEIGHT = 49;

type PanelBankSectionsProps = PanelBankProps & {
  warningVisible?: boolean;
  historyKeyInfo: RunHistoryKeyInfo;
  historyKeyViz: {[key: string]: any};
  panelBankWidth: number;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  panelBankSectionsRef: RefObject<HTMLDivElement>;
};

const MAX_SECTIONS_TO_DISPLAY = 100;

// This component renders the Panelbank sections (everything besides search + options bar)
// Most of the PanelBank logic lives here
const PanelBankSections: React.FC<PanelBankSectionsProps> = props => {
  const {
    initializeNewPanels,
    panelBankDiff,
    addSection,
    deleteSection,
    moveSectionBefore,
    movePanel,
  } = usePanelBankSectionsProps(props);

  const {
    panelBankConfigRef,
    warningVisible,
    historyKeyInfo,
    historyKeyViz,
    readOnly,
    panelSettingsRef,
    panelBankWidth,
    runSetRefs,
    customRunColorsRef,
    singleRun,
    entityName,
    panelBankConfigWithRefs,
    panelBankSectionsRef,
  } = props;

  const {debouncedSearchQuery} = usePanelSearchDebouncedContext();

  useEffect(
    () =>
      panelBankSectionsEffect({
        initializeNewPanels,
        panelBankDiff,
      }),
    [
      historyKeyInfo,
      historyKeyViz,
      initializeNewPanels,
      panelBankDiff,
      runSetRefs,
      debouncedSearchQuery,
      panelSettingsRef,
      panelBankConfigRef,
    ]
  );
  const lastShiftKeyRef = useRef(false);

  const {visibleSectionsWithRefs, startIdx, endIdx, prevPage, nextPage} =
    usePanelBankSectionList(
      panelBankConfigWithRefs,
      debouncedSearchQuery,
      singleRun,
      historyKeyInfo
    );

  const {defaultMoveToSectionName} = panelBankConfigWithRefs.settings;
  const defaultMoveToSection = panelBankConfigWithRefs.sections.find(s => {
    return s.name === defaultMoveToSectionName;
  });

  // NOTE: this assumes Hidden Panels is the last section
  const hiddenSection = panelBankConfigWithRefs.sections.slice(-1)[0];

  // While dragging sections, all sections are collapsed.
  // This function adds a spacer to account for the height difference.
  const getTopSpacerHeightWhenDraggingSection = useCallback(
    (dragDropContext: DragDropState) => {
      const draggingSectionIndex = findIndex(visibleSectionsWithRefs, s => {
        return s.ref.id === dragDropContext.dragRef?.id;
      });
      const panelBankSectionsElement = panelBankSectionsRef.current;
      const mousePosition =
        dragDropContext.mouseDownEvent != null &&
        panelBankSectionsElement != null
          ? getMousePositionRelativeTo(
              dragDropContext.mouseDownEvent,
              panelBankSectionsElement
            )
          : null;

      const result =
        draggingSectionIndex > 0 && mousePosition != null
          ? mousePosition.client.y -
            draggingSectionIndex * SECTION_TITLE_HEIGHT -
            mousePosition.offset.y -
            MARGIN_DRAG_HANDLE_ICON
          : 0;

      return result;
    },
    [visibleSectionsWithRefs, panelBankSectionsRef]
  );

  if (
    panelBankConfigWithRefs.state !== PanelBankConfigState.Ready &&
    visibleSectionsWithRefs.every(s => s.activePanelRefs.length === 0) &&
    historyKeyInfo.lastStep != null &&
    historyKeyInfo.lastStep < 0
  ) {
    return (
      <>
        <div className="no-metrics-logged">
          <EmptyVisualizations
            headerText="No metrics logged yet."
            helpText={<div>Charts will appear here as data is logged.</div>}
          />
        </div>
        <div className="panel-bank__divider" />
        <div style={{padding: 16}}>
          <Button
            data-test="panelbank-add-section-button"
            size="tiny"
            content={'Add section'}
            onClick={() => {
              addSection(hiddenSection.ref);
            }}
          />
        </div>
      </>
    );
  }

  const getTopSpacerHeightWhenDraggingPanel = (
    dragDropContext: DragDropState
  ) => {
    const sectionIndexOnShift = findIndex(visibleSectionsWithRefs, s => {
      return s.ref.id === dragDropContext.dropRefOnShift?.id;
    });
    const mouseYOnShift = dragDropContext.mouseEventOnShift?.pageY!;
    const result =
      sectionIndexOnShift > -1
        ? mouseYOnShift -
          72 - // panelbank top padding
          52 - // nav bar height
          (singleRun ? 44 : 0) - // run workspace run name header
          (warningVisible ? 52 : 0) - // warning message height (e.g. slow-warning)
          (sectionIndexOnShift + 0.5) * SECTION_TITLE_HEIGHT
        : 0;
    return result;
  };

  const scrollToElementOnShiftRelease = (dragDropContext: DragDropState) => {
    if (
      dragDropContext.elementOnShiftRelease != null &&
      dragDropContext.mouseEventOnShiftRelease != null
    ) {
      window.scrollTo({
        top:
          (dragDropContext.elementOnShiftRelease as any).offsetTop +
          72 +
          52 -
          getMousePositionMemoized(dragDropContext.mouseEventOnShiftRelease)
            .client.y +
          25,
      });
    }
  };

  return (
    <>
      <DragDropProvider>
        <DragDropContext.Consumer>
          {dragDropContext => {
            const isDraggingPanel =
              dragDropContext.dragRef != null &&
              isPanel(dragDropContext.dragRef);
            const isDraggingSection =
              dragDropContext.dragRef != null &&
              isPanelBankSection(dragDropContext.dragRef);

            if (!dragDropContext.shiftKey && lastShiftKeyRef.current) {
              setTimeout(() => {
                scrollToElementOnShiftRelease(dragDropContext);
              });
            }
            lastShiftKeyRef.current = dragDropContext.shiftKey;

            return (
              <>
                {((isDraggingPanel && dragDropContext.shiftKey) ||
                  isDraggingSection) &&
                  dragDropContext.mouseDownEvent != null && (
                    <PanelBankSpacer
                      dropTargetSectionRef={visibleSectionsWithRefs[0].ref}
                      onDrop={() => {
                        if (isDraggingSection) {
                          moveSectionBefore(
                            dragDropContext.dragRef as PartRefFromObjSchema<PanelBankSectionConfigObjSchema>,
                            visibleSectionsWithRefs[0].ref
                          );
                        }
                      }}
                      style={{
                        height: isDraggingSection
                          ? getTopSpacerHeightWhenDraggingSection(
                              dragDropContext
                            )
                          : getTopSpacerHeightWhenDraggingPanel(
                              dragDropContext
                            ),
                      }}
                    />
                  )}
                {visibleSectionsWithRefs.map(renderSectionWithRefs => {
                  const sectionRef = renderSectionWithRefs.ref;
                  return (
                    <PanelBankSection
                      key={sectionRef.id}
                      forceCollapsed={
                        isDraggingSection || dragDropContext.shiftKey
                      }
                      panelBankWidth={panelBankWidth}
                      readOnly={readOnly}
                      workspacePanelSettingsRef={panelSettingsRef}
                      panelBankConfigRef={panelBankConfigRef}
                      historyKeyInfo={historyKeyInfo}
                      panelBankSectionConfigRef={sectionRef}
                      customRunColorsRef={customRunColorsRef}
                      runSetRefs={runSetRefs}
                      activePanelRefs={renderSectionWithRefs.activePanelRefs}
                      inactivePanelRefs={
                        renderSectionWithRefs.inactivePanelRefs
                      }
                      moveSectionBefore={moveSectionBefore}
                      addSectionAbove={() => {
                        addSection(sectionRef);
                      }}
                      addSectionBelow={() => {
                        addSection(sectionRef, {addAfter: true});
                      }}
                      deleteSection={() => deleteSection(sectionRef)}
                      // deletePanel={panelRef =>
                      //   deletePanel(panelRef, sectionRef)
                      // }
                      defaultMoveToSectionRef={
                        defaultMoveToSection
                          ? defaultMoveToSection.ref
                          : undefined
                      }
                      movePanel={movePanel}
                      addVisButton={
                        <PanelBankAddVisButton
                          singleRun={singleRun}
                          entityName={entityName}
                          customRunColorsRef={customRunColorsRef}
                          runSetRefs={runSetRefs}
                          panelSettingsRef={panelSettingsRef}
                          panelBankSectionConfigRef={sectionRef}
                        />
                      }
                    />
                  );
                })}
                {((isDraggingPanel && dragDropContext.shiftKey) ||
                  isDraggingSection) &&
                  dragDropContext.mouseDownEvent != null && (
                    <PanelBankSpacer
                      dropTargetSectionRef={
                        visibleSectionsWithRefs.slice(-1)[0].ref
                      }
                      onDrop={() => {
                        moveSectionBefore(
                          dragDropContext.dragRef as PartRefFromObjSchema<PanelBankSectionConfigObjSchema>
                        );
                      }}
                      style={{
                        height: '100vh',
                      }}
                    />
                  )}
              </>
            );
          }}
        </DragDropContext.Consumer>
      </DragDropProvider>
      <div
        style={{
          padding: 16,
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '12px',
        }}>
        <Button
          data-test="panelbank-add-section-button"
          size="tiny"
          content={'Add section'}
          onClick={() => addSection(hiddenSection.ref)}
        />
        {visibleSectionsWithRefs.length > MAX_SECTIONS_TO_DISPLAY && (
          <Pagination
            startIdx={startIdx}
            endIdx={endIdx}
            numSections={visibleSectionsWithRefs.length}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        )}
      </div>
    </>
  );
};

const usePanelBankSectionList = (
  panelBankConfigWithRefs: PanelBankConfigWithRefs,
  debouncedSearchQuery: string,
  singleRun: boolean | undefined,
  historyKeyInfo: RunHistoryKeyInfo
) => {
  const keyTypes = RunHelpers.useKeyTypes(historyKeyInfo);

  const {showEmptySections} =
    panelBankConfigWithRefs.settings || EMPTY_PANEL_BANK_SETTINGS;
  // Filter out panels that don't have data, and panels that don't match the search query

  const searchFilteredSectionsWithRefs = useMemo(() => {
    const searchRegex = searchRegexFromQuery(debouncedSearchQuery);
    const isSearching = !!searchRegex;
    return panelBankConfigWithRefs.sections
      .map(section => {
        // activePanelRefs are the panels that get rendered.
        // for flow sections, activePanelRefs = panels with data filtered by debouncedSearchQuery
        // for grid sections, activePanelRefs = all panels filtered by debouncedSearchQuery (we show placeholders for panels with no data)
        const activePanelRefs: Array<
          PartRefFromObjSchema<PanelTypes.PanelObjSchema>
        > = [];

        const inactivePanelRefs: Array<
          PartRefFromObjSchema<PanelTypes.PanelObjSchema>
        > = [];

        section.panels.forEach(panel => {
          if (
            panelIsActive({
              section,
              panel,
              singleRun,
              searchRegex,
              historyKeyInfo,
              keyTypes,
            })
          ) {
            activePanelRefs.push(panel.ref);
          } else {
            inactivePanelRefs.push(panel.ref);
          }
        });

        return {
          ...section,
          activePanelRefs,
          inactivePanelRefs,
          // when the search matches the title of the section we want to display it
          isNameSearchMatch: searchRegex?.test(section.name) ?? false,
        };
      })
      .filter(renderSection =>
        isSectionVisible(showEmptySections, isSearching, renderSection)
      );
  }, [
    panelBankConfigWithRefs,
    historyKeyInfo,
    keyTypes,
    debouncedSearchQuery,
    singleRun,
    showEmptySections,
  ]);

  const {startIdx, endIdx, nextPage, prevPage} = usePagination(
    panelBankConfigWithRefs.sections.length,
    searchFilteredSectionsWithRefs.length,
    debouncedSearchQuery
  );

  const visibleSectionsWithRefs = useMemo(
    () => searchFilteredSectionsWithRefs.slice(startIdx, endIdx + 1),
    [startIdx, endIdx, searchFilteredSectionsWithRefs]
  );

  return {
    visibleSectionsWithRefs,
    startIdx,
    endIdx,
    prevPage,
    nextPage,
  };
};

const usePagination = (
  numAllSections: number, // Used to detect when user adds a new section
  numVisibleSections: number, // Used to calculate pages to show/maxIdx
  searchQuery: string
): {
  startIdx: number;
  endIdx: number;
  prevPage: () => void;
  nextPage: () => void;
} => {
  const [startIdx, setStartIdx] = useState(0);
  const [previousNumAllSections, setPreviousNumAllSections] =
    useState(numAllSections);
  const [previousSearchQuery, setPreviousSearchQuery] = useState(searchQuery);
  const maxIdx = numVisibleSections - 1;
  const startEndIndexDelta = MAX_SECTIONS_TO_DISPLAY - 1;

  // when we add sections, we want to move to the end to display that section
  useEffect(() => {
    if (previousNumAllSections !== numAllSections) {
      setStartIdx(safeStartIdx(maxIdx - startEndIndexDelta));
      setPreviousNumAllSections(numAllSections);
    }
  }, [numAllSections, maxIdx, startEndIndexDelta, previousNumAllSections]);

  // when the user changes the search query, move to the begining of the search results
  useEffect(() => {
    if (searchQuery !== previousSearchQuery) {
      setStartIdx(0);
      setPreviousSearchQuery(searchQuery);
    }
  }, [searchQuery, previousSearchQuery]);

  const prevPage = useCallback(
    () => setStartIdx(safeStartIdx(startIdx - MAX_SECTIONS_TO_DISPLAY)),
    [startIdx]
  );

  const nextPage = useCallback(
    () => setStartIdx(safeStartIdx(startIdx + MAX_SECTIONS_TO_DISPLAY)),
    [startIdx]
  );

  return {
    startIdx,
    endIdx: safeEndIdx(startIdx + startEndIndexDelta, maxIdx),
    prevPage,
    nextPage,
  };
};

const safeStartIdx = (idx: number) => Math.max(idx, 0);
const safeEndIdx = (idx: number, maxIdx: number) => Math.min(idx, maxIdx);

interface PaginationProps {
  startIdx: number;
  endIdx: number;
  numSections: number;
  prevPage: () => void;
  nextPage: () => void;
}

const Pagination: FC<PaginationProps> = React.memo(
  ({startIdx, endIdx, numSections, nextPage, prevPage}) => {
    return (
      <div style={{display: 'flex'}}>
        <div>
          Sections {startIdx + 1} - {endIdx + 1} of {numSections}
        </div>
        <Button.Group className="pagination-buttons">
          <Button
            disabled={startIdx === 0}
            className="page-up wb-icon-button only-icon"
            size="tiny"
            onClick={prevPage}>
            <LegacyWBIcon name="previous" />
          </Button>
          <Button
            disabled={endIdx >= numSections - 1}
            className="page-down wb-icon-button only-icon"
            size="tiny"
            onClick={nextPage}>
            <LegacyWBIcon name="next" />
          </Button>
        </Button.Group>
      </div>
    );
  }
);

export default PanelBankSections;

// All sections collapse to just headers when you're dragging.
// This spacer preserves the height and provides a drop target above+below the sections.
const PanelBankSpacer = (props: {
  dropTargetSectionRef: PartRefFromObjSchema<PanelBankSectionConfigObjSchema>;
  style: React.CSSProperties;
  onDrop(): void;
}) => {
  return (
    <DropTarget
      isValidDropTarget={({dragRef}) => {
        return isPanelBankSection(dragRef);
      }}
      partRef={props.dropTargetSectionRef}
      onDrop={({dragRef}) => {
        if (dragRef && !isEqual(dragRef, props.dropTargetSectionRef)) {
          props.onDrop();
        }
      }}
      style={props.style}
    />
  );
};

function usePanelBankConfigActions(
  panelBankConfigRef: PanelBankConfigTypes.Ref
) {
  const initializeNewPanels = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.initializeNewPanels
  );
  const addSection = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.addSection
  );
  const deleteSection = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.deleteSection
  );
  const moveSectionBefore = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.moveSectionBefore
  );
  const movePanel = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.movePanel
  );

  return {
    initializeNewPanels,
    addSection,
    deleteSection,
    moveSectionBefore,
    movePanel,
  };
}

function usePanelBankSectionsProps(props: PanelBankSectionsProps) {
  const panelBankConfigActions = usePanelBankConfigActions(
    props.panelBankConfigRef
  );
  const keysInPanel: string[] = compact(
    flatten(
      props.panelBankConfigWithRefs.sections.map(section =>
        section.panels.map(panel => Panels.getKey(panel))
      )
    )
  );
  const prevPropsKeys = usePrevious(keysInPanel);
  const wandbConfigQueryResult = useRunsWandbConfigQuery(props.runSetRefs);

  if (
    !wandbConfigQueryResult ||
    wandbConfigQueryResult.loading ||
    wandbConfigQueryResult.error
  ) {
    return {
      ...panelBankConfigActions,
      panelBankDiff: {},
    };
  }
  const wbConfig = wandbConfigQueryResult.wandbConfig;

  const expectedPanelSpecs = keyInfoToDefaultPanelSpecs(
    props.historyKeyInfo,
    props.historyKeyViz,
    !!props.singleRun,
    props.panelBankConfigWithRefs.settings,
    wbConfig
  );

  const addedPanelSpecs = keyInfoToAddedPanels(
    props.historyKeyViz,
    props.panelBankConfigWithRefs.settings.autoOrganizePrefix
  );

  const foundPanelSpecs = panelBankConfigToFoundPanelSpecs(
    props.panelBankConfigWithRefs
  );

  const expectedAddedPanelConfigsWithRefs =
    expectedAPIAddedPanelSpecsFromPanelBankConfigs(
      props.panelBankConfigWithRefs,
      addedPanelSpecs
    );

  const panelBankDiff = getPanelBankDiff(
    expectedPanelSpecs,
    expectedAddedPanelConfigsWithRefs,
    addedPanelSpecs,
    foundPanelSpecs,
    prevPropsKeys || [],
    props.panelBankConfigWithRefs.state
  );

  return {
    ...panelBankConfigActions,
    panelBankDiff,
  };
}

function panelBankSectionsEffect(
  effectProps: Pick<
    ReturnType<typeof usePanelBankSectionsProps>,
    'initializeNewPanels' | 'panelBankDiff'
  >
) {
  const {initializeNewPanels, panelBankDiff} = effectProps;

  // Short-circuit if there are no new keys
  if (Object.keys(panelBankDiff).length === 0) {
    return;
  }
  initializeNewPanels(panelBankDiff);
}
