import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import styled from 'styled-components';

export const Dropdown = styled(ModifiedDropdown)`
  &&&&&& [role='option'],
  &&&&&& .text[role='alert'] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;
