import Tab from '@material-ui/core/Tab';
import Editor from '@wandb/common/components/Monaco/Editor';
import {Modal} from 'semantic-ui-react';
import styled from 'styled-components';

export const ModalContent = styled(Modal.Content)`
  // Tabs have considerable padding, so we don't need more above them
  padding: 0 1.5rem 1.5rem 1.5rem !important;
`;

export const ClonedRunLabel = styled.div`
  padding: 10px 0;
`;

export const SourceRow = styled.div`
  display: flex;
`;

export const SourceLabel = styled.div`
  align-self: center;
  padding-right: 10px;
`;

export const SourceTab = styled(Tab)`
  // Reducing min width to eliminate a smidge of horizontal scrolling
  min-width: 150px !important;
`;

export const JobRow = styled.div`
  display: flex;
`;

export const JobSelect = styled.div`
  flex: 1 1 auto;
`;

export const JobVersion = styled.div`
  min-width: 100px;
  margin-left: 10px;
`;

export const GitRow = styled.div`
  display: flex;
`;

export const GitSelect = styled.div`
  flex: 1 1 auto;
`;

export const Label = styled.span`
  margin-left: 8px;
  margin-right: 4px;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Dropdowns = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const DropdownDiv = styled.div`
  display: flex;
  width: 288px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ActionsBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Spacer = styled.div`
  height: 10px;
`;

export const SelectRow = styled.div`
  display: flex;
  padding-right: 18px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 15px;
`;

export const SelectLabel = styled.div`
  align-self: center;
  padding-right: 10px;
`;

export const SelectContainer = styled.div`
  flex: 1 1 auto;
`;

export const QueueCreateLink = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const AlertBin = styled.div`
  padding: 8px 18px 0px 0px;
`;

export const ErrorLabel = styled.div`
  margin-left: 8px;
  margin-right: 4px;
  margin-bottom: 8px;
  color: red;
`;

export const DefaultResourceConfigBin = styled.div`
  display: flex;
  margin-left: 8px;
`;

export const EditorWrapper = styled.div`
  position: relative;
  padding: 24px 0 24px 20px;
  background-color: #2a2a2a;
  border-radius: 4px;
`;

export const MonacoEditor = styled(Editor)`
  .monaco-editor {
    .margin,
    .monaco-editor-background {
      background-color: #2a2a2a;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-right: 1px;
  margin-top: 2px;
`;
