import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

import CopyableText from './CopyableText';
import {Label, LABEL_COLORS} from './Label';

export const PopupDropdownWrapper = styled.div`
  cursor: pointer;
  &:hover {
    color: ${globals.gray500};
  }
`;

export const AddEmailLink = styled.a`
  color: ${globals.gray500};

  &:hover {
    color: ${globals.gray900};
  }
`;

export const CopyableTextWrapper = styled.div`
  padding-top: 12px;
`;

export const CopyableIframe = styled(CopyableText)`
  color: ${globals.gray700};
  background: ${globals.gray100};
  width: auto;
  max-width: unset;
  overflow: hidden;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  padding: 0.5em 0.75em;
  margin: 0;
  .copy-icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&& {
    .copy-icon {
      opacity: 1;
    }
  }

  .copyable-text-content {
      .single-line-text();
  }

  &:hover {
    color: ${globals.textPrimary};
    &&& {
        .copy-icon {
            color: ${globals.textPrimary};
        }
    }
  }
`;

export const StyledLabel = styled(Label)`
  width: ${({color}) => (color === LABEL_COLORS.GREEN ? '90px' : '104px')};
`;

export const EmailCell = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const OptionText = styled.span`
  padding-left: 8px;
`;
