import {
  FetchEntityProjectsQuery,
  FetchProjectLaunchQueuesQueryResult,
} from '../../generated/graphql';
import {JobInfo} from './SelectJob';
import {ProjectOption} from './SelectProject';
import {
  GroupedQueueOptions,
  isGroupedQueueOption,
  QueueOption,
} from './SelectQueue';

export const constructQueueOptions = (
  queryResult: FetchProjectLaunchQueuesQueryResult
): QueueOption[] => {
  const queueOptions: QueueOption[] = [];
  const projectName = queryResult?.data?.project?.name ?? '';
  const entityName = queryResult?.data?.project?.entity?.name ?? '';
  const isTeam = queryResult?.data?.project?.entity?.isTeam ?? false;
  const runQueues = queryResult?.data?.project?.runQueues ?? [];
  for (const queue of runQueues) {
    if (queue.name == null || isTeam == null || queue.id == null) {
      continue;
    }
    const queueOption: QueueOption = {
      isTeam,
      value: queue.id,
      entity: entityName,
      project: projectName,
      queue: queue.name,
      queueID: queue.id,
      isPrivate: queue.access === 'USER',
      defaultResourceConfigID: queue.defaultResourceConfigID || '',
    };
    queueOptions.push(queueOption);
  }
  return queueOptions;
};

export const getProjectOptions = (
  data: FetchEntityProjectsQuery
): ProjectOption[] => {
  const options: ProjectOption[] = [];
  const projectEdges = data?.entity?.projects?.edges ?? [];
  for (const projectEdge of projectEdges) {
    const project = projectEdge?.node;
    if (project) {
      options.push({value: project.id, label: project.name});
    }
  }
  return options;
};

export const sortQueues = (queues: QueueOption[]) => {
  queues.sort((a, b) => {
    if (a.queue === 'default') {
      return b.queue === 'default' ? 0 : -1;
    }
    if (b.queue === 'default') {
      return 1;
    }
    return a.queue.toLowerCase() < b.queue.toLowerCase() ? -1 : 1;
  });
};

export const countQueueOptions = (
  queues: QueueOption[] | GroupedQueueOptions[]
) => {
  return isGroupedQueueOption(queues)
    ? (queues as GroupedQueueOptions[]).reduce((acc, queue) => {
        return acc + queue.options.length;
      }, 0)
    : (queues as QueueOption[]).length;
};

export const suggestDefaultQueueForGrouped = (
  options: GroupedQueueOptions[],
  defaultQueueID?: string
): QueueOption | undefined => {
  for (const group of options) {
    if (group.options.length > 0) {
      if (defaultQueueID == null) {
        return group.options[0];
      }
      const defaultQueue = group.options.find(
        option => option.queueID === defaultQueueID
      );
      if (defaultQueue != null) {
        return defaultQueue;
      }
    }
  }
  return;
};

export const parseJobInput = (
  jobName: string | undefined
): JobInfo | undefined => {
  if (jobName == null || jobName === '') {
    return;
  }
  const splitJobInfo = jobName.split('/');
  const jobNameAndAlias = splitJobInfo[splitJobInfo.length - 1].split(':');
  const jobProject =
    splitJobInfo.length > 1 ? splitJobInfo[splitJobInfo.length - 2] : undefined;
  if (jobNameAndAlias.length !== 2) {
    return {
      jobName: jobNameAndAlias[0],
      jobVersion: 'latest',
      jobProject,
    };
  }
  return {
    jobName: jobNameAndAlias[0],
    jobVersion: jobNameAndAlias[1],
    jobProject,
  };
};
