import * as globals from '@wandb/common/css/globals.styles';
import {Editable} from 'slate-react';
import styled, {createGlobalStyle, css} from 'styled-components';

import {ReportWidthOption} from '../../../state/views/report/types';
import {GLOBAL_COLORS} from '../../../util/colors';
import * as BlockQuoteStyles from '../plugins/block-quotes.styles';
import * as CalloutBlockStyles from '../plugins/callout-blocks.styles';
import * as CaptivateStyles from '../plugins/captivate.styles';
import * as CodeBlockStyles from '../plugins/code-blocks.styles';
import * as CraiyonStyles from '../plugins/craiyon.styles';
import * as CTABannerStyles from '../plugins/cta-banner.styles';
import {DragHandle} from '../plugins/drag-drop/drag-drop.styles';
import * as GalleryStyles from '../plugins/gallery.styles';
import * as HeadingStyles from '../plugins/headings.styles';
import * as HorizontalRuleStyles from '../plugins/horizontal-rules.styles';
import * as ImageStyles from '../plugins/images.styles';
import * as LatexStyles from '../plugins/latex.styles';
import * as ListStyles from '../plugins/lists.styles';
import * as MarkdownBlockStyles from '../plugins/markdown-blocks.styles';
import * as PanelGridStyles from '../plugins/panel-grid/PanelGridElement.styles';
import * as ParagraphStyles from '../plugins/paragraphs.styles';
import * as ReplitStyles from '../plugins/replit.styles';
import * as TableOfContentsStyles from '../plugins/table-of-contents.styles';
import * as TableStyles from '../plugins/tables.styles';
import * as VideoStyles from '../plugins/videos.styles';
import * as WeavePanelStyles from '../plugins/weave-panels.styles';

export const Leaf = styled.span<{
  strong?: boolean;
  emphasis?: boolean;
  inlineCode?: boolean;
  underline?: boolean;
  delete?: boolean;

  codeComment?: boolean;
  inlineComment?: boolean;
  activeInlineComment?: boolean;
  tempInlineComment?: boolean;
  operator?: boolean;
  url?: boolean;
  keyword?: boolean;
  variable?: boolean;
  regex?: boolean;
  number?: boolean;
  boolean?: boolean;
  tag?: boolean;
  constant?: boolean;
  symbol?: boolean;
  attr?: boolean;
  selector?: boolean;
  punctuation?: boolean;
  string?: boolean;
  char?: boolean;
  function?: boolean;
  isClass?: boolean;
}>`
  ${props => props.strong && 'font-weight: 600'};
  ${props => props.emphasis && 'font-style: italic'};
  text-decoration: ${props => (props.underline ? 'underline' : '')}
    ${props => (props.delete ? 'line-through' : '')};
  ${props =>
    props.inlineCode &&
    css`
      background: #f6f6f6;
      border-radius: 2px;
      border: 1px solid ${GLOBAL_COLORS.outline.toString()};
      font-family: Inconsolata;
      font-size: 16px;
    `};
  ${props =>
    props.codeComment &&
    css`
      color: slategray;
    `}
  ${props =>
    props.inlineComment &&
    css`
      background: ${globals.lightYellowOther};
    `}
  ${props =>
    props.activeInlineComment &&
    css`
      background: ${globals.commentYellow};
    `}
    ${props =>
    props.tempInlineComment &&
    css`
      background: ${globals.yellow};
    `}
  ${props =>
    (props.operator || props.url) &&
    css`
      color: #9a6e3a;
    `}
  ${props =>
    props.keyword &&
    css`
      color: #07a;
    `}
  ${props =>
    (props.variable || props.regex) &&
    css`
      color: #e90;
    `}
  ${props =>
    (props.number ||
      props.boolean ||
      props.tag ||
      props.constant ||
      props.symbol ||
      props.attr ||
      props.selector) &&
    css`
      color: #905;
    `}
  ${props =>
    props.punctuation &&
    css`
      color: #999;
    `}
  ${props =>
    (props.string || props.char) &&
    css`
      color: #690;
    `}
  ${props =>
    (props.function || props.isClass) &&
    css`
      color: #dd4a68;
    `}
`;

export const SlashMenuAffordance = styled.div`
  pointer-events: none;
  color: #999;
  position: absolute;
`;

export const SlateWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 100%;
`;

const narrowElements = `${ParagraphStyles.Paragraph}, ${HeadingStyles.HeadingOne}, ${HeadingStyles.HeadingTwo}, ${HeadingStyles.HeadingThree}, ${HeadingStyles.HeadingFour}, ${ListStyles.ListItemWrapper}, ${CodeBlockStyles.CodeBlockWrapper}, ${CalloutBlockStyles.CalloutBlock}, ${BlockQuoteStyles.BlockQuote}, ${ImageStyles.ImagePlaceholderWrapper}, ${ImageStyles.ImageOuterWrapper}, ${TableStyles.TableWrapper}, ${MarkdownBlockStyles.MarkdownBlockWrapper}, ${HorizontalRuleStyles.HorizontalRule},  ${TableOfContentsStyles.TableOfContents}, .run-sets-wrapper:not(.active), ${LatexStyles.BlockLatexWrapper}, ${WeavePanelStyles.WeavePanelWrapper}, ${CaptivateStyles.EmbeddedIframe}`;
const wideElements = `${PanelGridStyles.PanelGridWrapper}, ${VideoStyles.EmbeddedIframe}, ${ReplitStyles.EmbeddedIframe}, ${CraiyonStyles.Container}, ${GalleryStyles.Container}, ${CTABannerStyles.Container}`;

export const StyledEditable = styled(Editable)<{
  readOnly: boolean;
  $widthMode: ReportWidthOption;
}>`
  ${narrowElements}, ${wideElements} {
    margin-left: auto;
    margin-right: auto;
    width: calc(100vw - 128px);
  }
  > div {
    ${DragHandle} {
      display: none;
    }
    &:hover {
      ${DragHandle} {
        display: block;
      }
    }
  }
  > ul,
  > ol {
    ${DragHandle} {
      display: none;
    }
    > div {
      &:hover {
        ${DragHandle} {
          display: block;
        }
      }
    }
  }

  ${props =>
    props.$widthMode === 'profile' &&
    css`
      ${narrowElements}, ${wideElements} {
        width: ${props.readOnly ? `100%` : `min(calc(100vw - 128px), 854px)`};
      }

      // Hack to eliminate extra space at the top
      &
        > ${ParagraphStyles.Paragraph}:first-child
        + ${HeadingStyles.HeadingOne},
        &
        > ${ParagraphStyles.Paragraph}:first-child
        + ${HeadingStyles.HeadingTwo},
        &
        > ${ParagraphStyles.Paragraph}:first-child
        + ${HeadingStyles.HeadingThree},
        &
        > ${ParagraphStyles.Paragraph}:first-child
        + ${HeadingStyles.HeadingFour} {
        margin-top: 0 !important;
      }
    `}

  ${props =>
    props.$widthMode === 'readable' &&
    css`
      ${narrowElements} {
        width: min(calc(100vw - 128px), 716px);
      }
      ${wideElements} {
        width: min(calc(100vw - 128px), 1072px);
      }
    `}

  ${props =>
    props.$widthMode === 'fixed' &&
    css`
      ${narrowElements}, ${wideElements} {
        width: min(calc(100vw - 128px), 1072px);
      }
    `}

  @media only screen and (max-width: 768px) {
    ${narrowElements}, ${wideElements} {
      width: min(calc(100vw - 64px), 1072px);
    }
  }

  // Hack to make the trailing paragraph more clickable
  & > ${ParagraphStyles.Paragraph}:last-child {
    height: 300px;
  }
`;

export const PrintReportHack = createGlobalStyle`
  .report-page.report-width-print-portrait {
    ${narrowElements}, ${wideElements} {
      width: calc(8.5in - 256px);
    }
  }
  .report-page.report-width-print-landscape {
    ${narrowElements}, ${wideElements} {
      width: calc(11in - 256px);
    }
  }
`;
