import {TargetBlank} from '@wandb/common/util/links';
import React, {FC} from 'react';

import errorImage from '../../../../assets/il-question-plant.svg';
import * as S from './PanelGridElement.styles';

export const PanelGridError: FC = () => (
  <S.PanelGridErrorWrapper>
    <S.ErrorImageWrapper src={errorImage} alt="confused plant" />
    <S.ErrorMessageWrapper>
      <h1>Oops, these panels didn't load properly.</h1>
      If this keeps happening, contact{' '}
      <TargetBlank
        href="mailto:support@wandb.com"
        target="_blank"
        rel="noopener noreferrer">
        support@wandb.com
      </TargetBlank>{' '}
      with a link to this page.
    </S.ErrorMessageWrapper>
  </S.PanelGridErrorWrapper>
);
