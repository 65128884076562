import * as QueryString from 'query-string';
import AnalyticsJS from '@segment/analytics.js-core';
import * as Sentry from '@sentry/react';
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';

import {getAllCookies} from './cookie';
import {datadogDebugOverride} from '../config';

declare global {
  interface Window {
    analytics?: typeof AnalyticsJS;
  }
}

interface FullStoryInterface {
  getCurrentSessionURL: (addTime?: boolean) => string;
  event: (name: string, object: any) => void;
  restart: () => void;
}

// Expect these items to be in the window object.
declare global {
  interface Window {
    FS?: FullStoryInterface;
  }
}

interface PendoInterface {
  identify: (visitor: {}, account?: {}) => any;
  initialize: (user: object) => any;
  track: (trackType: string, metadata: object) => any;
  showGuideById: (id: string) => any;
}

declare global {
  interface Window {
    pendo?: PendoInterface;
  }
}

declare global {
  interface Window {
    // Todo: Figure out how to hook up types from @types/prismjs

    Prism: any;
  }
}

export const Prism = window.Prism;

export function getFullStoryUrl(): string | undefined {
  return (
    window.FS &&
    window.FS.getCurrentSessionURL &&
    window.FS.getCurrentSessionURL(true)
  );
}

interface ErrorParams {
  tags?: {[key: string]: string};
  extra?: {[key: string]: any};
  level?: Sentry.Severity;
  fingerprint?: string[];
}

// You must pass callsite, a string indicating what part of our code
// base captureError was called from.
export function captureError(
  err: Error | string | unknown,
  callsite: string,
  errorParams: ErrorParams = {}
) {
  const extra = {
    ...(errorParams.extra || {}),
    callsite,
    state: getStateDump(),
  };
  Sentry.withScope(scope => {
    scope.setTag('callsite', callsite);
    scope.setExtras(extra);
    if (errorParams.level) {
      scope.setLevel(errorParams.level);
    }
    Object.entries(errorParams.tags || {}).forEach(([key, value]) => {
      scope.setTag(key, value);
    });
    if (errorParams.fingerprint != null) {
      scope.setFingerprint(errorParams.fingerprint);
    }
    if (typeof err === 'string') {
      Sentry.captureMessage(err);
    } else {
      Sentry.captureException(err);
    }
  });
}

export const getStateDump = () => ({
  localStorage: dumpStorageData(localStorage),
  sessionStorage: dumpStorageData(sessionStorage),
  cookies: dumpCookieData(),
});

const dumpStorageData = (s: Storage) => {
  const data: {[key: string]: any} = {};
  for (let i = 0; i < s.length; i++) {
    const key = s.key(i)!;
    const val = s.getItem(key);
    try {
      data[key] = JSON.parse(val!);
    } catch {
      data[key] = val;
    }
  }
  return data;
};

const dumpCookieData = () => {
  const data: {[key: string]: any} = {};
  const cookies = getAllCookies();
  for (const [key, val] of Object.entries(cookies)) {
    try {
      data[key] = JSON.parse(val as string);
    } catch {
      data[key] = val;
    }
  }
  return data;
};

// Reload on error on dashboard pages
export function shouldReloadOnError(): boolean {
  return window.location.pathname.indexOf('/dashboards') > -1;
}

// it gets shutdown in index.html, we restart it for logged in users in util/analytics.ts
export function restartFullstoryScript() {
  (window as any).dontShutdownFS = true;
  window.FS?.restart();
}

declare global {
  interface Window {
    DatadogEnabled?: boolean;
  }
}

// A note for future devs:
// 1) Datadog appears to wait until a couple seconds (and/or until cpu activity dies down)
//    before sending logs to the server. So if it's not logging, make sure you're waiting long enough.
//    Even though there is a delay, it will still log slow events when the user leaves the
//    navigates (including leaving the site.) In my experience "log as you're navigating away"
//    (before unload/sendBeacon) isn't a 100% guarantee, but since most navigations should be
//    staying within the w&b SPA (hopefully?) we should gather enough data.
// 2) Datadog has built in support for sampling, and won't always log if sampleRate below is < 100
let datadogInitDone = false;
const DATADOG_CLIENT_TOKEN = 'pubd5ed7cb03440cfa062ac078ece38b277';
const DATADOG_SITE = 'datadoghq.com';
const DATADOG_UI_SERVICE = 'wandb-web-ui';

export const getDatadog = (
  isAdmin: boolean // when the user is admin, don't report back to DD, since it polllutes the logs.
): typeof datadogLogs | undefined => {
  // ensure we're adhering to thirdPartyAnalyticsOK
  if (
    !datadogDebugOverride &&
    (typeof window === 'undefined' || !window.DatadogEnabled || isAdmin)
  ) {
    return;
  }
  if (!datadogInitDone) {
    datadogLogs.init({
      // yes, this is a token that's intended to be used in web clients where users
      // can access it.
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      forwardErrorsToLogs: false,
      sampleRate: datadogDebugOverride() ? 100 : 25, // only send logs for X% of _sessions_
      service: DATADOG_UI_SERVICE,
    });
    datadogInitDone = true;
  }
  return datadogLogs;
};

const DATADOG_RUM_APPLICATION_ID = 'ddeeb29c-5e8c-4579-90be-f7c0cc91dbcd';

const doDatadogRumInit = () => {
  // ensure we're adhering to thirdPartyAnalyticsOK
  if (
    !datadogDebugOverride &&
    (typeof window === 'undefined' || !window.DatadogEnabled)
  ) {
    return;
  }

  const forceSampled =
    QueryString.parse(window.location.search).forceRum === 'true';

  datadogRum.init({
    // TODO(np): get and append username from env
    env: window.CONFIG?.ENVIRONMENT_NAME,
    applicationId: DATADOG_RUM_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_UI_SERVICE,
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    allowedTracingUrls: [
      // local dev
      'http://api.wandb.test',
      'https://api.wandb.test',
      'https://weave-python.wandb.test',

      // qa
      'https://api.qa.wandb.ai',
      'https://weave-python.qa.wandb.ai',

      // prod
      'https://api.wandb.ai',
      'https://weave-python.wandb.ai',
    ].map(url => ({match: url, propagatorTypes: ['b3multi']})),
    defaultPrivacyLevel: 'mask-user-input',
    sampleRate: forceSampled ? 100 : 10,
    sessionReplaySampleRate: 100,
    tracingSampleRate: 100,
  });
};

doDatadogRumInit();

export function datadogSetUserInfo(userInfo: {
  username?: string;
  name?: string;
}) {
  datadogRum.setUser({
    id: userInfo.username,
    name: userInfo.name,
  });
  if (userInfo.username !== 'anonymous') {
    datadogRum.startSessionReplayRecording();
  }
}
