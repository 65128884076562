import styled from 'styled-components';

import {GLOBAL_COLORS} from '../../../util/colors';
import {REPORT_FONT_SIZE} from '../constants';

export const TableWrapper = styled.div``;

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin-bottom ${Math.round(REPORT_FONT_SIZE * 0.8)}px;
`;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
  border: 1px solid ${GLOBAL_COLORS.outline.toString()};
  padding: 8px;
`;
