import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';
import {useHistory} from 'react-router';

import * as Urls from '../../../../util/urls';
import {WeaveErrorBoundary} from '../../../Panel2/ErrorBoundary';
import {isPossibleMembershipTab} from '../../artifactMembership/PanelArtifactMembership';
import PanelArtifact, {
  ConfigType as PanelArtifactConfigType,
} from './component';

interface ArtifactPanelRootProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  selectedMembershipIdentifier?: string;
  selectedCollectionView?: string;
  selectedTab?: string;
  filePath?: string;
  compareArtifact?: {
    artifactCollectionName: string;
    membershipIdentifier: string;
  };
}

export type ArtifactCollectionViewType =
  | 'action_history'
  | 'versions'
  | 'settings';

export const ArtifactCollectionViews: ArtifactCollectionViewType[] = [
  'action_history',
  'versions',
  'settings',
];

export const isArtifactCollectionView = (
  s: string
): s is ArtifactCollectionViewType => {
  return ArtifactCollectionViews.includes(s as ArtifactCollectionViewType);
};

const urlFromConfig = (config: any, props: ArtifactPanelRootProps) => {
  const {entityName, projectName, artifactTypeName, artifactCollectionName} =
    props;

  const targetCollectionView = config.selectedCollectionView;
  if (ArtifactCollectionViews.includes(targetCollectionView)) {
    return Urls.artifactView({
      entityName,
      projectName,
      artifactTypeName,
      artifactCollectionName,
      artifactCollectionView: targetCollectionView,
    });
  }

  const targetTab =
    config.selectedTab === 'overview'
      ? config.tabConfigs.overview.selectedTab ?? 'overview'
      : config.selectedTab ?? props.selectedMembershipIdentifier ?? 'overview';
  const targetCommitHash =
    config.selectedMembershipIdentifier ??
    props.selectedMembershipIdentifier ??
    '';
  const targetPath =
    targetTab === 'files'
      ? config.tabConfigs?.overview?.tabConfigs?.files?.filesPanel?.path
      : (props.filePath?.split('/').map(decodeURIComponent), undefined);
  if (targetTab === 'files') {
    const compareArtifact =
      config.tabConfigs?.overview?.tabConfigs?.files?.compareArtifact ??
      props.compareArtifact;
    const targetCompareArtifactName = compareArtifact?.artifactCollectionName;
    const targetCompareMembershipIdentifier =
      compareArtifact?.membershipIdentifier;
    return Urls.artifactFile({
      entityName,
      projectName,
      artifactTypeName,
      artifactSequenceName: artifactCollectionName,
      artifactCommitHash: targetCommitHash,
      path: targetPath ?? [],
      compareArtifactSequence: targetCompareArtifactName,
      compareArtifactID: targetCompareMembershipIdentifier,
    });
  } else {
    return Urls.artifactTab({
      entityName,
      projectName,
      artifactTypeName,
      artifactCollectionName,
      artifactCommitHash: targetCommitHash,
      tabName: targetTab,
    });
  }
};

export const ArtifactPanelURLProvider = (props: ArtifactPanelRootProps) => {
  const {
    selectedMembershipIdentifier,
    selectedTab,
    entityName,
    projectName,
    artifactCollectionName,
    selectedCollectionView,
    filePath,
    compareArtifact,
  } = props;
  const history = useHistory();
  const [context, updateContext] = React.useState({});
  const [config, updateConfig] = Panel2.useConfig<PanelArtifactConfigType>({});

  const input = React.useMemo(() => {
    return Op.opProjectArtifact({
      project: Op.opRootProject({
        entityName: Op.constString(entityName),
        projectName: Op.constString(projectName),
      }),
      artifactName: Op.constString(artifactCollectionName),
    }) as GraphTypes.OutputNode<'artifact'>;
  }, [artifactCollectionName, entityName, projectName]);

  const controlledConfig = React.useMemo(() => {
    return rewriteConfigBasedOnURLProperties(
      config,
      selectedMembershipIdentifier,
      selectedTab,
      selectedCollectionView,
      compareArtifact,
      projectName,
      entityName,
      filePath
    );
  }, [
    compareArtifact,
    config,
    entityName,
    filePath,
    projectName,
    selectedMembershipIdentifier,
    selectedCollectionView,
    selectedTab,
  ]);

  const controlledUpdateConfig: typeof updateConfig = React.useCallback(
    update => {
      const futureConfig = {
        ...controlledConfig,
        ...update,
      };
      const priorURL = urlFromConfig(controlledConfig, props);
      const futureURL = urlFromConfig(futureConfig, props);
      if (priorURL !== futureURL) {
        history.push(futureURL);
      }
      updateConfig(update);
    },
    [controlledConfig, history, props, updateConfig]
  );

  return (
    <WeaveErrorBoundary
      canReset={false}
      canUndo={false}
      errorConfig={controlledConfig}
      sourcePanel={'PanelArtifact'}
      onReset={() => {}}
      onUndo={() => {}}>
      <PanelArtifact
        {...{
          input,
          context,
          updateContext,
          selectedCollectionView,
          config: controlledConfig,
          updateConfig: controlledUpdateConfig,
        }}
      />
    </WeaveErrorBoundary>
  );
};

const cloneObjectWithModifiedPathValue = (
  incomingObject: any,
  path: string[],
  value: any
): any => {
  const [head, ...tail] = path;
  if (incomingObject == null) {
    incomingObject = {};
  }
  if (tail.length === 0) {
    return {...incomingObject, [head]: value};
  } else {
    return {
      ...incomingObject,
      [head]: cloneObjectWithModifiedPathValue(
        incomingObject[head],
        tail,
        value
      ),
    };
  }
};

const rewriteConfigBasedOnURLProperties = (
  config: PanelArtifactConfigType,
  selectedMembershipIdentifier: string | undefined,
  selectedTab: string | undefined,
  selectedCollectionView: string | undefined,
  compareArtifact:
    | {artifactCollectionName: string; membershipIdentifier: string}
    | undefined,
  projectName: string,
  entityName: string,
  filePath: string | undefined
): PanelArtifactConfigType => {
  // set the top-level Collection View
  config = cloneObjectWithModifiedPathValue(
    config,
    ['selectedCollectionView'],
    selectedCollectionView
  );

  // Ensure that the target identifier matches the url
  config = cloneObjectWithModifiedPathValue(
    config,
    ['selectedMembershipIdentifier'],
    selectedMembershipIdentifier
  );

  // If the incoming tab is null, matches a Membership tab, or doesn't match a
  // Collection tab, use 'overview', else the selected tab for the top tab level
  config = cloneObjectWithModifiedPathValue(
    config,
    ['selectedTab'],
    selectedTab == null || isPossibleMembershipTab(selectedTab)
      ? 'overview'
      : selectedTab
  );

  // If the incoming tab is null or doesn't match a Membership tab, use
  // 'overview', else the selected tab for the second tab level
  config = cloneObjectWithModifiedPathValue(
    config,
    ['tabConfigs', 'overview', 'selectedTab'],
    selectedTab == null || !isPossibleMembershipTab(selectedTab)
      ? 'overview'
      : selectedTab
  );

  // If a compare artifact is specified, use it (just assume we are in the same
  // entity/project) When we eventually make comparison possible for more than
  // just files, we will probably want to rewrite this to bring the comparison
  // up to the artifact membership level
  config = cloneObjectWithModifiedPathValue(
    config,
    ['tabConfigs', 'overview', 'tabConfigs', 'files', 'compareArtifact'],
    compareArtifact != null
      ? {
          ...compareArtifact,
          projectName,
          entityName,
        }
      : undefined
  );

  // Set the artifact file path to the provided path, or []
  config = cloneObjectWithModifiedPathValue(
    config,
    ['tabConfigs', 'overview', 'tabConfigs', 'files', 'filesPanel', 'path'],
    filePath?.split('/').map(decodeURIComponent) ?? []
  );

  return config as PanelArtifactConfigType;
};
