import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {project, reportView, teamPage} from './../../util/urls';
import {ResultTypes} from './types';
import {Project, Report, Team} from './utils';

export const MiniSpan = styled.span`
  color: #ddd;
  font-size: 12px;
`;

export const StyledLink = styled(Link)`
  color: #fff;
  display: block;
  padding: 5px;
  width: 100%;

  background: ${(props: {$isSelected: boolean}) =>
    props.$isSelected ? '#555' : 'none'};

  :focus,
  :hover {
    color: rgb(255, 201, 51);
  }a
`;

type BaseLinkProps = {
  id: string;
  isSelected: boolean;
  trackClick: (destination: string) => void;
};

type NavigationLinkProps = BaseLinkProps & {
  to: string;
  resultType: ResultTypes.Navigation;
};
type ProjectLinkProps = BaseLinkProps & Project;
type ReportLinkProps = BaseLinkProps & Report;
type TeamLinkProps = BaseLinkProps & Team;

type LinkProps =
  | NavigationLinkProps
  | ProjectLinkProps
  | ReportLinkProps
  | TeamLinkProps;

const isNavigationLink = (props: LinkProps): props is NavigationLinkProps => {
  return props.resultType === ResultTypes.Navigation;
};

const isProjectLink = (props: LinkProps): props is ProjectLinkProps => {
  return props.resultType === ResultTypes.Project;
};

const isReportLink = (props: LinkProps): props is ReportLinkProps => {
  return props.resultType === ResultTypes.Report;
};

const isTeamLink = (props: LinkProps): props is TeamLinkProps => {
  return props.resultType === ResultTypes.Team;
};

export const BaseLink = (props: LinkProps) => {
  const p = {
    $isSelected: props.isSelected,
    tabIndex: -1,
  };

  if (isNavigationLink(props)) {
    return (
      <StyledLink
        {...p}
        id={`navigation:${props.id}`}
        onClick={() => props.trackClick(props.to)}
        to={props.to}>
        {props.to}
      </StyledLink>
    );
  }

  if (isProjectLink(props)) {
    const destination = project({
      entityName: props.entityName,
      name: props.name,
    });
    return (
      <StyledLink
        {...p}
        id={`project:${props.id}`}
        onClick={() => props.trackClick(destination)}
        to={destination}>
        Project: {props.name} <MiniSpan>({props.entityName})</MiniSpan>
      </StyledLink>
    );
  }

  if (isReportLink(props)) {
    const destination = reportView({
      entityName: props.entityName,
      reportID: props.id,
      reportName: props.displayName,
      projectName: props.projectName,
    });
    return (
      <StyledLink
        {...p}
        id={`report:${props.id}`}
        onClick={() => props.trackClick(destination)}
        to={destination}>
        Report: {props.displayName}
      </StyledLink>
    );
  }

  if (isTeamLink(props)) {
    return (
      <StyledLink
        {...p}
        id={`team:${props.id}`}
        key={props.id}
        onClick={() => props.trackClick(teamPage(props.name))}
        to={teamPage(props.name)}>
        Team: {props.name}
      </StyledLink>
    );
  }

  return null;
};
