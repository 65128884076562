import styled, {css} from 'styled-components';

export const LoaderWrapper = styled.div`
  position: relative;
  height: 400px;
`;

export const DRCTable = styled.div<{
  $isInSettingsPage: boolean;
}>`
  ${({$isInSettingsPage}) =>
    $isInSettingsPage &&
    css`
      .wb-react-table {
        border-radius: unset;
        border: unset;
        font-size: 16px;
        margin-bottom: unset;
      }
    `}
`;
