import {removeNonASCII} from '@wandb/cg';
import {urlPrefixed} from '@wandb/common/config';
import * as PanelExport from '@wandb/common/util/panelExport';
import {stripIndents} from 'common-tags';
import {csvToMarkdown} from 'csv-to-markdown-table';
import JSZip from 'jszip';
import {Element} from 'slate';

import {
  calloutBlockNodeToLatex,
  calloutLineNodeToLatex,
  isCalloutBlock,
  isCalloutLine,
} from '../components/Slate/plugins/callout-blocks';
import {
  codeBlockNodeToLatex,
  codeLineNodeToLatex,
  isCodeBlock,
  isCodeLine,
} from '../components/Slate/plugins/code-blocks';
import {
  headingNodeToLatex,
  isHeading,
} from '../components/Slate/plugins/headings';
import {
  imageBlockToReportContent,
  isImage,
} from '../components/Slate/plugins/images';
import {isLatex, latexNodeToLatex} from '../components/Slate/plugins/latex';
import {isLink, linkNodeToLatex} from '../components/Slate/plugins/links';
import {convertMarkdownToSlate} from '../components/Slate/plugins/markdown-blocks';
import {isMarkdownBlock} from '../components/Slate/plugins/markdown-blocks-common';
import {isPanelGrid} from '../components/Slate/plugins/panel-grid';
import {
  isParagraph,
  paragraphNodeToLatex,
} from '../components/Slate/plugins/paragraphs';
import {
  isTextWithFormatting,
  TextWithFormatting,
} from '../components/Slate/WBSlate';
import * as ReportTypes from '../state/reports/types';
import * as RunsHooks from '../state/runs/hooks';
import * as RunsLib from '../state/runs/lib';
import * as RunsLowAPI from '../state/runs-low/api';
import {ApolloClient} from '../state/types';
import * as ViewsNormalize from '../state/views/normalize';
import * as ViewsReducer from '../state/views/reducer';
import * as ViewTypes from '../state/views/types';
import * as Filter from '../util/filters';
import * as QueryTS from '../util/queryts';
import * as Run from '../util/runs';
import * as SectionUtil from '../util/section';
import {getPanelSpec, isExportableAs, LayedOutPanel} from './panels';

export function editablePanelDOMID(panel: LayedOutPanel) {
  return 'editable-panel-' + panel.__id__;
}

export interface ReportContent {
  type: 'md' | 'img' | 'chart' | 'csv' | 'latex';
  name: string;
  data: string;
}

export type LaTeXTemplate = 'default' | 'reproducibilityChallenge';

interface GenerateZipParams {
  name: string;
  author: string;
  contents: ReportContent[];
  template?: LaTeXTemplate;
}

export async function generateZip({
  name,
  author,
  contents,
  template = 'default',
}: GenerateZipParams) {
  const zip = new JSZip();
  const charts = zip.folder('charts');
  const images = zip.folder('images') as JSZip;
  const runsets = zip.folder('runsets');
  // TODO: should we expose serverReportNotes?

  const README = stripIndents`
    # W&B LaTeX Source

    This directory was generated from the report named [${name}](${document.location.href}).

    All charts are rendered as high resolution PNG's in the **charts** directory.  The tables in
    this report were generated from the pinned columns in the open runsets.  The underlying data
    is available in the **runsets** directory.

    ## Quickstart

    You can upload this zip into a new project at [Overleaf](https://www.overleaf.com/).
    Choose "Upload Project" from the "New Project" menu to edit online.

    ## Rendering Locally

    You'll need a tex distribution, specifically the \`pdflatex\` command.  The following are good options:

    1. [TinyTex](https://yihui.name/tinytex/) - \`wget -qO- "https://yihui.name/gh/tinytex/tools/install-unx.sh" | sh\`
    2. [TexLive](https://tug.org/texlive/) - \`apt-get install texlive\`
    3. [BasicTex](http://tug.org/cgi-bin/mactex-download/BasicTeX.pkg) *(Mac)* - \`brew cask install basictex\`
    4. [MiKTeX](https://miktex.org/download) *(Windows)*

    Once installed, run: \`pdflatex report.tex\` to generate a pdf.`;
  zip.file('README.md', README);

  const BIB_FILE = stripIndents`
    @misc{wandb,
      title = "Weights and Biases",
      url   = "https://wandb.ai/site"
    }
  `;
  zip.file('bibliography.bib', BIB_FILE);

  // let found = false;
  contents.forEach(content => {
    if (content.type === 'chart') {
      const data = content.data.split(';base64,').pop() as string;
      charts?.file(`${content.name}.png`, data, {base64: true});
    } else if (content.type === 'img') {
      const data = content.data.split(';base64,').pop() as string;
      images?.file(`${content.name}.png`, data, {base64: true});
    } else if (content.type === 'csv') {
      runsets?.file(content.name + '.csv', content.data);
    }
  });

  const generateLatexParams: GenerateLaTeXParams = {
    title: name,
    author,
    contents,
    images,
    packages: [
      {name: 'graphicx'},
      {name: 'booktabs'},
      {name: 'longtable'},
      {name: 'tabu'},
      {name: 'listings'},
      {name: 'hyperref'},
      {name: 'tcolorbox'},
    ],
  };

  if (template === 'reproducibilityChallenge') {
    generateLatexParams.packages.push(
      {name: 'neurips_2019'},
      {name: 'inputenc', options: ['utf8']},
      {name: 'fontenc', options: ['T1']},
      {name: 'url'},
      {name: 'amsfonts'},
      {name: 'nicefrac'},
      {name: 'microtype'}
    );
    generateLatexParams.disableLinks = true;
    const styFile = await fetch(urlPrefixed('/neurips_2019.sty'));
    zip.file('neurips_2019.sty', await styFile.blob());
  }

  zip.file('report.tex', await generateLaTeX(generateLatexParams));

  const blob = await zip.generateAsync({type: 'blob'});
  return URL.createObjectURL(blob);
}

interface LaTeXPackage {
  name: string;
  options?: string[];
}

interface GenerateLaTeXParams {
  title: string;
  author: string;
  contents: ReportContent[];
  images: JSZip;
  packages: LaTeXPackage[];
  disableLinks?: boolean;
}

function generatePackageList(packages: LaTeXPackage[]): string {
  return packages.map(generatePackage).join('\n');
}

function generatePackage({name, options}: LaTeXPackage): string {
  const parts = [
    '\\usepackage',
    options != null ? `[${options.join(',')}]` : '',
    `{${name}}`,
  ];
  return parts.join('');
}

export async function generateLaTeX({
  title,
  author,
  contents,
  images,
  packages,
  disableLinks,
}: GenerateLaTeXParams) {
  const HEADER = stripIndents`
    \\documentclass{article}

    ${generatePackageList(packages)}

    \\setlength\\parindent{0pt}

    \\title{${title}}

    \\author{%
      ${author}
    }

    \\begin{document}

    \\maketitle`;

  let body = HEADER + '\n';

  let imageQueue: string[] = [];
  const sections = await Promise.all(
    contents.map(async rc => {
      let content = '';
      if (rc.type !== 'chart' && imageQueue.length > 0) {
        content += generateFigure(imageQueue);
        imageQueue = [];
      }
      switch (rc.type) {
        case 'img':
          content += generateImage({
            url: `images/${rc.name}`,
            width: '\\textwidth',
          });
          break;
        case 'chart':
          imageQueue.push(`charts/${rc.name}`);
          if (imageQueue.length >= 2) {
            content += generateFigure(imageQueue);
            imageQueue = [];
          }
          break;
        case 'md':
          content += convertMarkdownToSlate(rc.data).map(nodeToLatex).join('');
          break;
        case 'csv':
          const md = csvToMarkdown(rc.data, ',', true);
          content += convertMarkdownToSlate(md).map(nodeToLatex).join('');
          break;
        case 'latex':
          content += rc.data;
          break;
      }
      return content;
    })
  );
  body += `\n${sections.join('')}\n`;

  const FOOTER = stripIndents`
    \\nocite{*}
    \\bibliographystyle{unsrt}
    \\bibliography{bibliography}
    \\end{document}`;
  body += '\n' + FOOTER;

  return body.replace(/\n{3,}/g, '\n\n');
}

const nodeToLatex = (node: TextWithFormatting | Element): string => {
  if (isTextWithFormatting(node)) {
    let text = node.text;

    if (node.strong) {
      text = `\\textbf{${text}}`;
    }

    if (node.emphasis) {
      text = `\\textit{${text}}`;
    }

    if (node.underline) {
      text = `\\underline{${text}}`;
    }

    return text;
  }

  const inner = node.children.map(nodeToLatex).join('');

  if (isLatex(node)) {
    return latexNodeToLatex(node);
  }

  if (isHeading(node)) {
    return headingNodeToLatex(node, inner);
  }

  if (isParagraph(node)) {
    return paragraphNodeToLatex(node, inner);
  }

  if (isCodeLine(node)) {
    return codeLineNodeToLatex(node, inner);
  }

  if (isCodeBlock(node)) {
    return codeBlockNodeToLatex(node, inner);
  }

  if (isCalloutLine(node)) {
    return calloutLineNodeToLatex(node, inner);
  }

  if (isCalloutBlock(node)) {
    return calloutBlockNodeToLatex(node, inner);
  }

  if (isLink(node)) {
    return linkNodeToLatex(node, inner);
  }

  return inner;
};

export async function reportToReportContent(
  entityName: string,
  projectName: string,
  client: ApolloClient,
  viewsState: ViewsReducer.ViewReducerState,
  reportViewRef: ViewTypes.ViewRef
) {
  const view = viewsState.views[reportViewRef.id];
  const reportRef = view.partRef;
  if (
    reportRef == null ||
    (reportRef.type !== ReportTypes.REPORT_VIEW_TYPE &&
      reportRef.type !== ReportTypes.REPORT_DRAFT_VIEW_TYPE)
  ) {
    throw new Error('invalid report state');
  }

  const parts = viewsState.parts;
  const reportContent: ReportContent[] = [];
  const report = ViewsNormalize.lookupPart(parts, reportRef);
  const {blocks} = report;
  for (let sectionIndex = 0; sectionIndex < blocks.length; sectionIndex++) {
    const block = blocks[sectionIndex];

    if (isMarkdownBlock(block)) {
      reportContent.push({
        type: 'latex',
        name: 'markdown',
        data: convertMarkdownToSlate(removeNonASCII(block.content))
          .map(nodeToLatex)
          .join(''),
      });
      continue;
    }

    if (isImage(block)) {
      const content = await imageBlockToReportContent(block, sectionIndex);
      if (content != null) {
        reportContent.push(content);
      }
      continue;
    }

    if (isPanelGrid(block)) {
      const {metadata} = block;
      const {panels} = metadata.panelBankSectionConfig;

      for (let panelIndex = 0; panelIndex < panels.length; panelIndex++) {
        const panel = panels[panelIndex];
        if (panel.viewType === 'Markdown Panel') {
          reportContent.push({
            type: 'latex',
            name: 'markdown',
            data: convertMarkdownToSlate(removeNonASCII(panel.config.value))
              .map(nodeToLatex)
              .join(''),
          });
        }
        const panelSpec = getPanelSpec(panel.viewType);
        if (isExportableAs(panelSpec, 'image')) {
          const domEl = document.getElementById(editablePanelDOMID(panel));
          if (domEl == null) {
            console.warn(
              `Invalid panel: ${editablePanelDOMID(panel)} skipping...`
            );
            continue;
          }
          const imageName = `Section-${sectionIndex}-Panel-${panelIndex}-${panel.__id__}`;
          try {
            const imageDataURL = await PanelExport.generatePNGDataURL(domEl, {
              zoomFactor: 3,
            });
            if (imageDataURL) {
              reportContent.push({
                type: 'chart',
                name: imageName,
                data: imageDataURL,
              });
            }
          } catch (e) {
            console.error('Unable to generate panel: ', imageName, panelSpec);
          }
        } else if (isExportableAs(panelSpec, 'csv')) {
          try {
            // TODO: Make this work for Media Browser tables.
            // Currently PanelMediaPanel is not marked as exportable and we need
            // to query for the media key to know if it's a table to export
            console.log('Rendering tables is currently unsupported');
          } catch (e) {
            console.error('Unable to generate panel: ', panelSpec);
          }
        }
      }
      // We make a single table for the open runset. This may not be exactly
      // what's on the charts.
      if (metadata.openRunSet != null && metadata.runSets != null) {
        const runSetRef = metadata.runSets[metadata.openRunSet];
        if (runSetRef == null) {
          throw new Error('invalid runset state');
        }
        const runSet = {
          ...metadata.runSets[metadata.openRunSet],
          enabled: true,
        };

        // There are a bunch of hoops to jump through to get the run data
        // for a given runset.
        const query: QueryTS.Query = {
          id: 'project-page-not-used',
          entityName,
          projectName,
          filters: Filter.EMPTY_FILTERS,
          sort: QueryTS.CREATED_AT_DESC,
          runSets: SectionUtil.runSetsToRunSetQuery([runSet]),
        };
        const runsDataQuery = RunsLib.toRunsDataQuery(query);
        const runsLowQuery = RunsHooks.queryToRunsQueries(runsDataQuery)[0];
        const tableData = await RunsLowAPI.doSingleRunsQuery(
          client,
          runsLowQuery
        );

        const csvName = `Section-${sectionIndex}-Table`;

        let columns: any[] = [];
        // TODO: Some older reports don't have columnOrder...
        if (runSet.runFeed.columnOrder === undefined) {
          if (
            tableData.runs[0] !== undefined &&
            tableData.runs[0].historyKeys !== undefined
          ) {
            columns = Object.keys(tableData.runs[0].historyKeys.keys)
              .slice(0, 3)
              .map(k => `summary:${k}`);
          } else {
            continue;
          }
        } else {
          columns = runSet.runFeed.columnOrder.filter(
            c => runSet.runFeed.columnPinned[c]
          );
          if (columns.length <= 1) {
            columns = columns.concat(
              runSet.runFeed.columnOrder.slice(columns.length, 3)
            );
          }
        }

        // Old reports may have older versions of RunFeed Config. RunSelector
        // fixes this up itself. We fix it up here manually.
        if (columns.length === 0) {
          continue;
        } else if (typeof columns[0] !== 'string') {
          if (columns[0].name != null && columns[0].section != null) {
            columns = columns.map(c => Run.keyToString(c));
          } else {
            throw new Error('unexpected column state');
          }
        }
        const colKeys = columns.map(c => {
          const key = Run.keyFromString(c) as unknown as Run.Key;
          return key;
        });
        if (colKeys.length > 0 && colKeys[0].name === 'name') {
          colKeys[0] = {section: 'run', name: 'displayName'};
        }
        const lines = [colKeys.map(c => c.name).join(',')];
        for (const run of tableData.runs) {
          const vals: any[] = [];
          for (const col of colKeys) {
            vals.push(Run.displayValue(Run.getValue(run, col)));
          }
          lines.push(vals.map(v => `${v}`).join(','));
        }
        const csv = lines.join('\n');
        reportContent.push({type: 'csv', name: csvName, data: csv});
      }
    }

    reportContent.push({
      type: 'latex',
      name: 'no-name',
      data: nodeToLatex(block),
    });
  }
  return {view, reportContent};
}

interface ExportReportAsLaTeXParams {
  entityName: string;
  projectName: string;
  client: ApolloClient;
  viewsState: ViewsReducer.ViewReducerState;
  reportViewRef: ViewTypes.ViewRef;
  template: LaTeXTemplate;
}

export async function exportReportAsLaTeX({
  entityName,
  projectName,
  client,
  viewsState,
  reportViewRef,
  template,
}: ExportReportAsLaTeXParams) {
  const {reportContent, view} = await reportToReportContent(
    entityName,
    projectName,
    client,
    viewsState,
    reportViewRef
  );
  const zip = await generateZip({
    name: view.displayName,
    author: view.user.username,
    contents: reportContent,
    template,
  });
  await PanelExport.commenceDownload(`${view.displayName}.zip`, zip);
}

interface GenerateImageParams {
  url: string;
  width?: string;
}

function generateImage({url, width}: GenerateImageParams): string {
  const widthPart = width ? `[width=${width}]` : '';
  return `\\includegraphics${widthPart}{${url}}`;
}

function generateFigure(imageURLs: string[]): string {
  const minipages = imageURLs
    .map(
      url =>
        stripIndents`
          \\minipage{0.49\\textwidth}
          ${generateImage({url, width: '\\linewidth'})}
          \\caption{}
          \\endminipage
        `
    )
    .join('\\hfill\n');
  return (
    '\n' +
    stripIndents`
      \\begin{figure}[!htb]
      ${minipages}
      \\end{figure}
    ` +
    '\n\n'
  );
}
