import {ApolloQueryResult} from 'apollo-client';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {
  CreateFinishedRunAlertMutation,
  CreateFinishedRunAlertMutationVariables,
  CreateSlackChannelSubscriptionMutation,
  CreateSlackChannelSubscriptionMutationVariables,
  CreateStoppedRunAlertMutation,
  CreateStoppedRunAlertMutationVariables,
  DeleteAlertMutation,
  DeleteAlertMutationVariables,
  DeleteAlertSubscriptionMutation,
  DeleteAlertSubscriptionMutationVariables,
} from '../generated/graphql';

export const CREATE_FINISHED_RUN_ALERT = gql`
  mutation CreateFinishedRunAlert($entityName: String!) {
    createFinishedRunAlert(input: {entityName: $entityName}) {
      alert {
        id
        condition {
          __typename
          ... on FinishedRunCondition {
            success
          }
        }
        subscriptions {
          id
        }
      }
    }
  }
`;

export const createFinishedRunAlertMutation = graphql<
  {},
  CreateFinishedRunAlertMutation,
  CreateFinishedRunAlertMutationVariables,
  {}
>(CREATE_FINISHED_RUN_ALERT, {
  props: ({mutate}) => ({
    createFinishedRunAlert: (
      variables: CreateFinishedRunAlertMutationVariables
    ) => mutate!({variables}),
  }),
});

export type CreateFinishedRunAlertMutationFn = (
  variables: CreateFinishedRunAlertMutationVariables
) => Promise<ApolloQueryResult<CreateFinishedRunAlertMutation>>;

export const CREATE_STOPPED_RUN_ALERT = gql`
  mutation CreateStoppedRunAlert(
    $entityName: String!
    $minimumRunDuration: Duration!
  ) {
    createStoppedRunAlert(
      input: {entityName: $entityName, minimumRunDuration: $minimumRunDuration}
    ) {
      alert {
        id
        condition {
          __typename
          ... on StoppedRunCondition {
            minimumRunDuration
          }
        }
        subscriptions {
          id
        }
      }
    }
  }
`;

export const createStoppedRunAlertMutation = graphql<
  {},
  CreateStoppedRunAlertMutation,
  CreateStoppedRunAlertMutationVariables,
  {}
>(CREATE_STOPPED_RUN_ALERT, {
  props: ({mutate}) => ({
    createStoppedRunAlert: (
      variables: CreateStoppedRunAlertMutationVariables
    ) => mutate!({variables}),
  }),
});

export type CreateStoppedRunAlertMutationFn = (
  variables: CreateStoppedRunAlertMutationVariables
) => Promise<ApolloQueryResult<CreateStoppedRunAlertMutation>>;

export const UPDATE_STOPPED_RUN_ALERT = gql`
  mutation UpdateStoppedRunAlert($id: ID!, $minimumRunDuration: Duration) {
    updateStoppedRunAlert(
      input: {id: $id, minimumRunDuration: $minimumRunDuration}
    ) {
      alert {
        id
        condition {
          __typename
          ... on StoppedRunCondition {
            minimumRunDuration
          }
        }
        subscriptions {
          id
        }
      }
    }
  }
`;

export const CREATE_SCRIPTABLE_RUN_ALERT = gql`
  mutation CreateScriptableRunAlert($entityName: String!) {
    createScriptableRunAlert(input: {entityName: $entityName}) {
      alert {
        id
        condition {
          __typename
          ... on ScriptableRunCondition {
            success
          }
        }
        subscriptions {
          id
        }
      }
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation DeleteAlert($id: ID!) {
    deleteAlert(input: {id: $id}) {
      success
    }
  }
`;

export const deleteAlertMutation = graphql<
  {},
  DeleteAlertMutation,
  DeleteAlertMutationVariables,
  {}
>(DELETE_ALERT, {
  props: ({mutate}) => ({
    deleteAlert: (variables: DeleteAlertMutationVariables) =>
      mutate!({variables}),
  }),
});

export type DeleteAlertMutationFn = (
  variables: DeleteAlertMutationVariables
) => Promise<ApolloQueryResult<DeleteAlertMutation>>;

export const CREATE_SLACK_CHANNEL_SUBSCRIPTION = gql`
  mutation CreateSlackChannelSubscription($alertID: ID!, $integrationID: ID!) {
    createSlackChannelSubscription(
      input: {alertID: $alertID, integrationID: $integrationID}
    ) {
      subscription {
        id
      }
    }
  }
`;

export const createSlackChannelSubscrptionMutation = graphql<
  {},
  CreateSlackChannelSubscriptionMutation,
  CreateSlackChannelSubscriptionMutationVariables,
  {}
>(CREATE_SLACK_CHANNEL_SUBSCRIPTION, {
  props: ({mutate}) => ({
    createSlackChannelSubscription: (
      variables: CreateSlackChannelSubscriptionMutationVariables
    ) => mutate!({variables}),
  }),
});

export type CreateSlackChannelSubscriptionMutationFn = (
  variables: CreateSlackChannelSubscriptionMutationVariables
) => Promise<ApolloQueryResult<CreateSlackChannelSubscriptionMutation>>;

export const CREATE_EMAIL_SUBSCRIPTION = gql`
  mutation CreateEmailSubscription($alertID: ID!) {
    createEmailSubscription(input: {alertID: $alertID}) {
      subscription {
        id
      }
    }
  }
`;

export const CREATE_VIEW_COMMENTS_ALERT_SUBSCRIPTION = gql`
  mutation CreateViewCommentsAlertSubscription($viewID: ID!) {
    createViewCommentsAlertSubscription(input: {viewID: $viewID}) {
      subscription {
        id
      }
    }
  }
`;

export const DELETE_ALERT_SUBSCRIPTION = gql`
  mutation DeleteAlertSubscription($id: ID!) {
    deleteAlertSubscription(input: {id: $id}) {
      success
    }
  }
`;

export const deleteAlertSubscriptionMutation = graphql<
  {},
  DeleteAlertSubscriptionMutation,
  DeleteAlertSubscriptionMutationVariables,
  {}
>(DELETE_ALERT_SUBSCRIPTION, {
  props: ({mutate}) => ({
    deleteAlertSubscription: (
      variables: DeleteAlertSubscriptionMutationVariables
    ) => mutate!({variables}),
  }),
});

export type DeleteAlertSubscriptionMutationFn = (
  variables: DeleteAlertSubscriptionMutationVariables
) => Promise<ApolloQueryResult<DeleteAlertMutation>>;
