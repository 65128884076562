import React, {FC} from 'react';

import {RESULTS_LIMIT} from './constants';
import {LocalSearchResultItem} from './LocalSearchResultItem';
import {useSearchContext} from './SearchContext';

type RemainingResultsListProps = {
  blurAndClear: () => void;
  setShowAllRemainingResults: (value: boolean) => void;
  showAllRemainingResults: boolean;
};

export const RemainingResultsList: FC<RemainingResultsListProps> = ({
  blurAndClear,
  setShowAllRemainingResults,
  showAllRemainingResults,
}) => {
  const {
    currentQuery,
    displayedResults,
    hiddenResultsCount,
    results,
    selectedIndex,
  } = useSearchContext();
  return (
    <>
      {displayedResults.remaining.map((result, index) => (
        <LocalSearchResultItem
          key={result.name + '|' + result.meta}
          onClick={blurAndClear}
          result={result}
          query={currentQuery}
          selected={
            selectedIndex ===
            displayedResults.entity.length +
              displayedResults.contributed.length +
              index
          }
        />
      ))}
      {!showAllRemainingResults && results.remaining.length > RESULTS_LIMIT && (
        <div
          className="show-more"
          onClick={() => {
            setShowAllRemainingResults(true);
          }}>
          {hiddenResultsCount.remaining} more result
          {hiddenResultsCount.remaining === 1 ? '' : 's'} ➞
        </div>
      )}
    </>
  );
};
