import React from 'react';
import {useMemo} from 'react';
import * as TypeHelpers from '@wandb/cg';
import * as Types from '@wandb/cg';
import * as CGReact from '../../cgreact';

import * as Panel2 from './panel';
import {Panel2Loader, PanelComp2} from './PanelComp';

type PanelMaybeProps = Panel2.PanelConverterProps;

const PanelMaybe: React.FC<PanelMaybeProps> = props => {
  const {input} = props;
  const nodeValueQuery = CGReact.useNodeValue(props.input);

  const nodeWithConvertedType = useMemo(() => {
    let convertedType = Spec.convert(input.type);
    if (convertedType == null) {
      // Hack to workaround the Weave Python not sending nullable
      // types correctly.
      // throw new Error('Invalid (null) panel input type');
      convertedType = input.type;
    }
    return {
      ...input,
      type: convertedType,
    };
  }, [input]);

  if (nodeValueQuery.loading) {
    return <Panel2Loader />;
  } else if (nodeValueQuery.result == null) {
    return <div>-</div>;
  } else {
    return (
      <PanelComp2
        input={nodeWithConvertedType}
        inputType={nodeWithConvertedType.type}
        loading={props.loading}
        panelSpec={props.child}
        configMode={false}
        config={props.config}
        context={props.context}
        updateInput={props.updateInput}
        updateConfig={props.updateConfig}
        updateContext={props.updateContext}
      />
    );
  }
};

export const Spec: Panel2.PanelConvertSpec = {
  id: 'maybe',
  displayName: 'Maybe',
  Component: PanelMaybe,
  convert: (inputType: Types.Type) => {
    let tags: Types.Type | undefined;
    if (TypeHelpers.isTaggedValueLike(inputType)) {
      tags = TypeHelpers.taggedValueTagType(inputType);
      inputType = TypeHelpers.taggedValueValueType(inputType);
    }
    if (
      !TypeHelpers.isUnion(inputType) ||
      !TypeHelpers.isAssignableTo('none', inputType)
    ) {
      return null;
    }
    return TypeHelpers.taggedValue(
      tags,
      TypeHelpers.nonNullableDeep(inputType)
    );
  },
  defaultFixedSize: childDims => childDims,
};
