import NumberInput from '@wandb/common/components/elements/NumberInput';
import React, {useCallback, useState} from 'react';
import {Checkbox, Dropdown, Popup} from 'semantic-ui-react';

import {
  OrganizationSubscriptionStatus,
  Plan,
  useUserOrganizationsQuery,
} from '../generated/graphql';
import {PlanInfoWithActualPlans, useSubscriptionPlans} from '../util/pricing';
import {
  COMPUTE_HOUR_AGGRESSIVE_DAYS_THRESHOLD,
  FREE_ACADEMIC_TRIAL,
  FREE_ENTERPRISE_PLAN_TRIAL,
  FREE_PERSONAL_ACCOUNT,
  FREE_STARTER_PLAN_TRIAL,
  getAggressiveCookie,
  getComputeHourLimitCookie,
  getDaysUntilExpireCookie,
  getLimitString,
  getOrganizationIDCookie,
  getStorageGBLimitCookie,
  getSubscriptionStatusCookie,
  getSubscriptionTypeCookie,
  getTrackedGBLimitCookie,
  PAID_ENTERPRISE_PLAN,
  PAID_STARTER_TIER_1_PLAN,
  setAggressiveCookie,
  setComputeHourLimitCookie,
  setDaysUntilExpireCookie,
  setOrganizationIDCookie,
  setStorageGBLimitCookie,
  setSubscriptionStatusCookie,
  setSubscriptionTypeCookie,
  setTrackedGBLimitCookie,
  SubscriptionType,
  unsetAllUsageLimitCookies,
} from '../util/usageLimit';
import * as S from './UsageSimulationModal.styles';
import {
  WBModal,
  WBModalActions,
  WBModalButton,
  WBModalContent,
  WBModalHeader,
} from './WBModal.styles';

interface UsageSimulationModalProps {
  onClose: () => void;
}

const SUBSCRIPTION_TYPE_OPTIONS = [
  {text: 'Free personal subscription', value: FREE_PERSONAL_ACCOUNT},
  {text: 'Free academic trial', value: FREE_ACADEMIC_TRIAL},
  {text: 'Free starter trial', value: FREE_STARTER_PLAN_TRIAL},
  {text: 'Starter tier 1 plan', value: PAID_STARTER_TIER_1_PLAN},
  {
    text: 'Free enterprise trial',
    value: FREE_ENTERPRISE_PLAN_TRIAL,
  },
  {text: 'Enterprise plan', value: PAID_ENTERPRISE_PLAN},
];

type LimitType = 'Storage GB' | 'Tracked GB' | 'Tracked hours';

interface LimitInputAttributes {
  privilegeLimitName: string;
  unit: string;
  limit: number | null;
  setLimit: (value: React.SetStateAction<number | null>) => void;
}

const UsageSimulationModal: React.FC<UsageSimulationModalProps> = React.memo(
  ({onClose}) => {
    const subscriptionTypeInCookie = getSubscriptionTypeCookie();
    const initialOrgID = getOrganizationIDCookie();
    const [orgID, setOrgID] = useState<string | undefined>(initialOrgID);
    const initialSubscriptionType: SubscriptionType =
      subscriptionTypeInCookie || FREE_PERSONAL_ACCOUNT;

    const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(
      initialSubscriptionType
    );
    const [storageGBLimit, setStorageGBLimit] = useState(
      getStorageGBLimitCookie()
    );
    const [trackedGBLimit, setTrackedGBLimit] = useState(
      getTrackedGBLimitCookie()
    );
    const [computeHourLimit, setComputeHourLimit] = useState(
      getComputeHourLimitCookie()
    );
    const [daysUntilExpire, setDaysUntilExpire] = useState(
      getDaysUntilExpireCookie()
    );
    const [aggressive, setAggressive] = useState(getAggressiveCookie());
    const [subscriptionStatus, setSubscriptionStatus] = useState(
      getSubscriptionStatusCookie()
    );

    const {planInfo} = useSubscriptionPlans();

    const userOrgs = useUserOrganizationsQuery();
    const orgOptions =
      userOrgs.data?.viewer?.organizations.map(org => {
        return {value: org.id, text: org.name};
      }) ?? [];

    const onSubmit = useCallback(() => {
      setOrganizationIDCookie(orgID ?? '');
      setSubscriptionTypeCookie(subscriptionType);
      setStorageGBLimitCookie(storageGBLimit);
      setTrackedGBLimitCookie(trackedGBLimit);
      setComputeHourLimitCookie(computeHourLimit);
      setDaysUntilExpireCookie(daysUntilExpire);
      setAggressiveCookie(aggressive);
      setSubscriptionStatusCookie(subscriptionStatus);
      onClose();
      window.location.reload();
    }, [
      onClose,
      orgID,
      subscriptionType,
      storageGBLimit,
      trackedGBLimit,
      computeHourLimit,
      daysUntilExpire,
      aggressive,
      subscriptionStatus,
    ]);

    const onReset = useCallback(() => {
      unsetAllUsageLimitCookies();
      window.location.reload();
    }, []);

    const getLimitInputAttributes = useCallback(
      (type: LimitType): LimitInputAttributes | null => {
        switch (type) {
          case 'Storage GB':
            return {
              privilegeLimitName: 'storage_gigs',
              unit: 'GB',
              limit: storageGBLimit,
              setLimit: setStorageGBLimit,
            };
          case 'Tracked GB':
            return {
              privilegeLimitName: 'reference_gigs',
              unit: 'GB',
              limit: trackedGBLimit,
              setLimit: setTrackedGBLimit,
            };
          case 'Tracked hours':
            return {
              privilegeLimitName: 'compute_hours',
              unit: 'hrs',
              limit: computeHourLimit,
              setLimit: setComputeHourLimit,
            };
          default:
            return null;
        }
      },
      [storageGBLimit, trackedGBLimit, computeHourLimit]
    );

    return (
      <WBModal open onClose={onClose}>
        <WBModalHeader>Simulate Usage for Testing Banners</WBModalHeader>
        <WBModalContent>
          <S.SubscriptionTypeSection>
            <h3>Subscription type</h3>
            <Dropdown
              options={SUBSCRIPTION_TYPE_OPTIONS}
              value={subscriptionType}
              direction="left"
              onChange={(e, data) => {
                if (data?.value == null) {
                  return;
                }
                setSubscriptionType(data?.value as SubscriptionType);
              }}></Dropdown>
          </S.SubscriptionTypeSection>
          <S.SubscriptionTypeSection>
            <S.TitleWrapper>
              <h3>Organization</h3>
              <Popup
                content={
                  'Simulating the subscription only applies to this selected organization. To test banners, please go to the team page of this organization. Applicable for non-personal subscription.'
                }
                trigger={<S.InfoIcon name="info" />}></Popup>
            </S.TitleWrapper>
            <Dropdown
              options={orgOptions}
              direction="left"
              value={orgID}
              disabled={subscriptionType === 'free_personal'}
              onChange={(e, data) => {
                if (data?.value == null) {
                  return;
                }
                setOrgID(data.value as string);
              }}></Dropdown>
          </S.SubscriptionTypeSection>
          <S.LimitTypeSection>
            <h3>Simulate the usage</h3>
            {(['Storage GB', 'Tracked GB', 'Tracked hours'] as LimitType[]).map(
              limitType => {
                const attr = getLimitInputAttributes(limitType);
                if (attr == null) {
                  return null;
                }
                return (
                  <LimitComponent
                    key={limitType}
                    type={limitType}
                    attr={attr}
                    planInfo={planInfo}
                  />
                );
              }
            )}
            <S.LimitTypeRow>
              <S.TitleWrapper>
                Days until expiration
                <Popup
                  content={
                    'Setting a number (x) greater than 0 would simulate expiring trial subscription with x days remaining. If you set 0, you can see the banners for expired trial subscription. Applicable only for trial subscription types.'
                  }
                  trigger={<S.InfoIcon name="info" />}></Popup>
              </S.TitleWrapper>
              <NumberInput
                value={daysUntilExpire ?? undefined}
                onChange={value => {
                  setDaysUntilExpire(value ?? 0);
                }}></NumberInput>
            </S.LimitTypeRow>
            <S.LimitTypeRow extraPadding={true}>
              <S.TitleWrapper>
                Disabled
                <Popup
                  content={`This can simulate a situation where their subscription is disabled (deactivated).`}
                  trigger={<S.InfoIcon name="info" />}></Popup>
              </S.TitleWrapper>
              <Checkbox
                toggle
                checked={
                  subscriptionStatus === OrganizationSubscriptionStatus.Disabled
                }
                onChange={(e, {checked}) => {
                  setSubscriptionStatus(
                    checked
                      ? OrganizationSubscriptionStatus.Disabled
                      : OrganizationSubscriptionStatus.Enabled
                  );
                }}></Checkbox>
            </S.LimitTypeRow>
            <S.LimitTypeRow extraPadding={true}>
              <S.TitleWrapper>
                Aggressive
                <Popup
                  content={`This can simulate a situation where a user has seen the compute hour banner for ${COMPUTE_HOUR_AGGRESSIVE_DAYS_THRESHOLD}+ days, but haven't upgraded the subscription. Applicable only when you set compute hour greater than ${getLimitString(
                    planInfo?.basicPlan.defaultPrivileges.compute_hours as
                      | number
                      | undefined,
                    'hrs'
                  )}.`}
                  trigger={<S.InfoIcon name="info" />}></Popup>
              </S.TitleWrapper>
              <Checkbox
                toggle
                checked={aggressive}
                // aggressive attribute is only relevant to
                // compute hour nudge bar
                disabled={computeHourLimit != null && computeHourLimit <= 250}
                onChange={(e, {checked}) => {
                  setAggressive(checked!);
                }}></Checkbox>
            </S.LimitTypeRow>
          </S.LimitTypeSection>
        </WBModalContent>
        <WBModalActions>
          <S.ResetButton content="Reset to default" onClick={onReset} />
          <WBModalButton content="Cancel" onClick={onClose} />
          <WBModalButton content="Apply" onClick={onSubmit} primary />
        </WBModalActions>
      </WBModal>
    );
  }
);

interface LimitComponentProps {
  type: LimitType;
  attr: LimitInputAttributes;
  planInfo: PlanInfoWithActualPlans | null;
}

const LimitComponent: React.FC<LimitComponentProps> = ({
  type,
  attr,
  planInfo,
}) => {
  const getLimitStringForPlan = useCallback(
    (plan?: Plan) => {
      return getLimitString(
        plan?.defaultPrivileges[attr.privilegeLimitName] as number | undefined,
        attr.unit
      );
    },
    [attr]
  );

  return (
    <S.LimitTypeRow key={type}>
      <S.TitleWrapper>
        {type}
        <Popup
          content={`${type} limit is ${getLimitStringForPlan(
            planInfo?.basicPlan
          )} for basic plan, ${getLimitStringForPlan(
            planInfo?.monthlyPlan
          )} for starter tier 1 plan and ${getLimitStringForPlan(
            planInfo?.enterprisePlan
          )} for academic / enterprise plan.`}
          trigger={<S.InfoIcon name="info" />}></Popup>
      </S.TitleWrapper>
      <NumberInput
        value={attr.limit ?? undefined}
        onChange={value => {
          attr.setLimit(value ?? 0);
        }}></NumberInput>
    </S.LimitTypeRow>
  );
};

export default UsageSimulationModal;
