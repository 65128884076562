import {ClickAwayListener} from '@material-ui/core';
import FocusTrap from 'focus-trap-react';
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {ActivationMethods} from './../../services/analytics/types';
import globalHistory from './../../util/history';
import {hotkeyPressed} from './../../util/hotkeys';
import {BaseLink} from './BaseLink';
import {useLauncherAnalytics} from './useLauncherAnalytics';
import {useLauncherResults} from './useLauncherResults';
import {useShortcuts} from './useShortcuts';

const LaunchWrapper = styled.div`
  background: rgba(0, 0, 0, 0.88);
  border-radius: 5px;
  color: white;
  left: 50%;
  margin-left: -300px;
  padding: 5px;
  position: fixed;
  width: 600px;
  top: calc(15vh);
  z-index: 1001;
`;

const InnerLauncher = styled.input`
  background: rgba(0, 0, 0, 0.6);
  border: solid 1px #333;
  color: #fff;
  display: block;
  line-height: 40px;
  height: 50px;
  padding: 5px 10px;
  opacity: 0.8;
  outline: none;
  width: 100%;

  :focus {
    border: solid 1px rgb(255, 201, 51);
    border-radius: 5px;
  }

  ::selection {
    background: #888;
    color: white;
  }

  ::placeholder {
    color: gray;
  }
`;

const OptionTrap = styled.ul`
  max-height: 70vh;
  margin: 0;
  padding: 0;
  overflow: scroll;
`;

const LauncherPane = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selection, setSelection] = useState(0);

  const handleChange = (e: {target: {value: string}}) => {
    setSearchTerm(e.target.value);
  };

  const shortcuts = useShortcuts(searchTerm);
  const results = useLauncherResults(searchTerm.toLowerCase());

  const fullResults = [...shortcuts, ...results];

  const {trackAppLauncherUsed, trackAppLauncherFailure} =
    useLauncherAnalytics();

  const trackClicks = useCallback(
    (destination: string) => {
      trackAppLauncherUsed(destination, 'option-selected');
    },
    [trackAppLauncherUsed]
  );

  const handlePress: KeyboardEventHandler = e => {
    if (e.key === 'Enter') {
      const links = document.querySelectorAll<HTMLAnchorElement>(
        '#launcher-option-trap > a'
      );
      if (links.length > 0) {
        const details = links[selection];
        // we don't need to track app launcher use here since the `onclick` handler on each
        // child link will track it
        details.click();
      } else if (searchTerm.startsWith('/')) {
        trackAppLauncherUsed(searchTerm, 'direct-navigation');

        globalHistory.push(encodeURI(searchTerm.trim()));
      } else {
        trackAppLauncherFailure(searchTerm);
      }
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (selection < fullResults.length - 1) {
        setSelection(selection + 1);
      }
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (selection > 0) {
        setSelection(selection - 1);
      }
    }
  };

  // update the selection value if the num of results changes outside the selection
  useEffect(() => {
    if (fullResults.length === 0) {
      setSelection(0);
    } else if (fullResults.length > 0 && selection > fullResults.length - 1) {
      setSelection(fullResults.length - 1);
    }
  }, [fullResults.length, selection]);

  return (
    <div onKeyDown={handlePress}>
      <FocusTrap
        focusTrapOptions={{
          clickOutsideDeactivates: true,
        }}>
        <LaunchWrapper>
          <InnerLauncher
            autoFocus
            onChange={handleChange}
            placeholder={'Search'}
            value={searchTerm}
            type="text"
          />
          {searchTerm && (
            <OptionTrap id="launcher-option-trap">
              {fullResults.map((item, index) => (
                <BaseLink
                  key={item.id}
                  isSelected={selection === index}
                  trackClick={trackClicks}
                  {...item}
                />
              ))}
            </OptionTrap>
          )}
        </LaunchWrapper>
      </FocusTrap>
    </div>
  );
};

export const Launcher = () => {
  const location = useLocation();
  const [isLaunched, setIsLaunched] = useState(false);

  const closeLauncher = useCallback(() => {
    setIsLaunched(false);
  }, []);

  // clear the launcher and hide it whenever the route changes
  useEffect(() => {
    closeLauncher();
  }, [closeLauncher, location.pathname]);

  const {trackAppLauncherOnScreen} = useLauncherAnalytics();

  const handleGlobalKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (hotkeyPressed(e, 'launcher')) {
        if (!isLaunched) {
          trackAppLauncherOnScreen(ActivationMethods.KeyboardHotkey);
        }
        setIsLaunched(prevIsLaunched => !prevIsLaunched);
      }
    },
    [isLaunched, trackAppLauncherOnScreen]
  );

  const handleEscapePress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeLauncher();
      }
    },
    [closeLauncher]
  );

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState !== 'visible') {
      closeLauncher();
    }
  }, [closeLauncher]);

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown);
    document.addEventListener('keydown', handleEscapePress);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
      document.removeEventListener('keydown', handleEscapePress);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleGlobalKeyDown, handleEscapePress, handleVisibilityChange]);

  if (!isLaunched) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setIsLaunched(false)}>
      <div>
        <LauncherPane />
      </div>
    </ClickAwayListener>
  );
};
