import styled from 'styled-components';

export const JobVersionAndDropdownContainer = styled.div`
  margin-bottom: 0;
  top: 24px;
`;

export const JobVersionContainer = styled.div`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
`;
