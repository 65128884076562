import {toast} from '@wandb/common/components/elements/Toast';
import {captureError} from '@wandb/common/util/integrations';
import {all} from 'redux-saga/effects';

import RunsSagas from './runs-low/sagas';
import {ApolloClient} from './types';
import ViewsSagas from './views/sagas';

export default function* rootSaga(client: ApolloClient) {
  try {
    yield all([RunsSagas(client), ViewsSagas(client)]);
  } catch (e) {
    // Hopefully(!), we will never hit this catch statement.
    //
    // Here in the root saga, it's very hard to take a correct
    // course of action to update state correctly, and it's
    // also hard to tell the user to take any corrective actions themselves.
    //
    // We should generally avoid throwing in sagas and if we call code that
    // can throw exceptions, put try/catches around those calls

    console.error('Caught error in root saga:', e);
    toast(
      'An error has occurred. We suggest reloading the page. If this keeps on happening, please contact support@wandb.com'
    );
    captureError(e, 'rootSaga');
  }
}
