import React, {useState, useEffect} from 'react';
import {Editor, Transforms, createEditor} from 'slate';
import {ReactEditor, Slate, withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import {Ref} from 'semantic-ui-react';

import {useWeaveContext} from '../../context';

import * as S from './styles';
import {
  useWeaveDecorate,
  useWeaveExpressionState,
  useSuggestionTaker,
  useRunButtonVisualState,
} from './hooks';
import {Leaf} from './leaf';
import {Suggestions} from './suggestions';
import {WeaveExpressionProps} from './types';
import {ID} from '@wandb/common/util/id';
import {trace} from './util';

// We attach some stuff to the window for test automation (see automation.ts)
declare global {
  interface Window {
    SlateLibs: {
      Transforms: typeof Transforms;
      Editor: typeof Editor;
      ReactEditor: typeof ReactEditor;
    };
    weaveExpressionEditors: {
      [editorId: string]: {
        applyPendingExpr: () => void;
        editor: any;
        onChange: (newValue: any) => void;
      };
    };
  }
}

window.SlateLibs = {Transforms, Editor, ReactEditor};
window.weaveExpressionEditors = {};

export const WeaveExpression: React.FC<WeaveExpressionProps> = props => {
  const weave = useWeaveContext();
  const [editor] = useState(() =>
    withReact(withHistory(createEditor() as any))
  );
  const {
    onChange,
    slateValue,
    suggestions,
    tsRoot,
    isValid,
    isBusy,
    applyPendingExpr,
    exprIsModified,
    suppressSuggestions,
    hideSuggestions,
  } = useWeaveExpressionState(props, editor, weave);
  const decorate = useWeaveDecorate(editor, tsRoot);
  const {takeSuggestion, suggestionIndex, setSuggestionIndex} =
    useSuggestionTaker(suggestions, weave, editor);
  const [, forceRender] = useState({});
  const {containerRef, applyButtonRef, onBlur, onFocus} =
    useRunButtonVisualState(editor, exprIsModified, isValid);

  // Store the editor on the window, so we can modify its state
  // from automation.ts (test automation)
  const [editorId] = useState(ID());
  useEffect(() => {
    window.weaveExpressionEditors[editorId] = {
      applyPendingExpr,
      editor,
      onChange: (newValue: any) => onChange(newValue, props.frame),
    };
    return () => {
      delete window.weaveExpressionEditors[editorId];
    };
  }, [applyPendingExpr, editor, editorId, onChange, props.frame]);

  // Wrap onChange so that we reset suggestion index back to top
  // on any interaction
  const onChangeResetSuggestion = React.useCallback(
    newValue => {
      setSuggestionIndex(0);
      onChange(newValue, props.frame);
    },
    [setSuggestionIndex, onChange, props.frame]
  );

  // Override default copy handler to make sure we're getting
  // the contents we want
  const copyHandler = React.useCallback(
    ev => {
      const selectedText = Editor.string(editor, editor!.selection!);
      ev.clipboardData.setData('text/plain', selectedText);
      ev.preventDefault();
    },
    [editor]
  );

  // Certain keys have special behavior
  const keyDownHandler = React.useCallback(
    ev => {
      if (ev.key === 'Enter' && !ev.shiftKey && !props.liveUpdate) {
        // Apply outstanding changes to expression
        ev.preventDefault();
        ev.stopPropagation();
        if (exprIsModified && isValid && !isBusy) {
          applyPendingExpr();
          forceRender({});
        }
        hideSuggestions(500);
      } else if (
        ev.key === 'Tab' &&
        !ev.shiftKey &&
        suggestions.items.length > 0
      ) {
        // Apply selected suggestion
        ev.preventDefault();
        ev.stopPropagation();
        takeSuggestion(suggestions.items[suggestionIndex]);
      } else if (ev.key === 'Escape') {
        // Blur the editor, hiding suggestions
        ev.preventDefault();
        ev.stopPropagation();
        ReactEditor.blur(editor);
        forceRender({});
      } else if (
        ev.key === 'ArrowDown' &&
        !ev.shiftKey &&
        suggestions.items.length > 0
      ) {
        // Suggestion cursor down
        ev.preventDefault();
        ev.stopPropagation();
        setSuggestionIndex((suggestionIndex + 1) % suggestions.items.length);
      } else if (
        ev.key === 'ArrowUp' &&
        !ev.shiftKey &&
        suggestions.items.length > 0
      ) {
        // Suggestion cursor up
        ev.preventDefault();
        ev.stopPropagation();
        setSuggestionIndex(
          (suggestions.items.length + suggestionIndex - 1) %
            suggestions.items.length
        );
      }
    },
    [
      props.liveUpdate,
      applyPendingExpr,
      editor,
      exprIsModified,
      hideSuggestions,
      isBusy,
      isValid,
      setSuggestionIndex,
      suggestionIndex,
      suggestions,
      takeSuggestion,
    ]
  );

  trace(
    `Render WeaveExpression ${editorId}`,
    props.expr,
    `editor`,
    editor,
    `slateValue`,
    slateValue,
    `exprIsModified`,
    exprIsModified,
    `suggestions`,
    suggestions,
    `isBusy`,
    isBusy,
    `suppressSuggestions`,
    suppressSuggestions
  );

  // Run button placement
  return (
    <div spellCheck="false">
      <Slate
        editor={editor}
        value={slateValue}
        onChange={onChangeResetSuggestion}>
        <S.EditableContainer
          ref={containerRef}
          data-test="expression-editor-container"
          data-test-ee-id={editorId}
          noBox={props.noBox}>
          <S.WeaveEditable
            // LastPass will mess up typing experience.  Tell it to ignore this input
            data-lpignore
            placeholder="Weave expression"
            className={isValid ? 'valid' : 'invalid'}
            onCopy={copyHandler}
            onKeyDown={keyDownHandler}
            onBlur={onBlur}
            onFocus={onFocus}
            decorate={decorate}
            renderLeaf={leafProps => <Leaf {...leafProps} />}
            style={{overflowWrap: 'anywhere'}}
            scrollSelectionIntoView={() => {}} // no-op to disable Slate's default scroll behavior when dragging an overflowed element
          />
          {!props.liveUpdate && (
            <Ref
              innerRef={element =>
                (applyButtonRef.current = element?.ref?.current)
              }>
              <S.ApplyButton
                primary
                size="tiny"
                disabled={!exprIsModified || !isValid || isBusy}
                onMouseDown={(ev: any) => {
                  // Prevent this element from taking focus
                  // otherwise it disappears before the onClick
                  // can register!
                  ev.preventDefault();
                }}
                onClick={applyPendingExpr}>
                Run {isBusy ? '⧗' : '⏎'}
              </S.ApplyButton>
            </Ref>
          )}
        </S.EditableContainer>
        <Suggestions
          forceHidden={suppressSuggestions || isBusy}
          {...suggestions}
          suggestionIndex={suggestionIndex}
        />
      </Slate>
    </div>
  );
};

export const focusEditor = (editor: Editor): void => {
  ReactEditor.focus(editor);
  Transforms.select(editor, Editor.end(editor, []));
};
