import {artifactMembershipNiceName} from '@wandb/common/util/artifacts';
import React, {FC, useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Checkbox, Header, Modal} from 'semantic-ui-react';

import * as Generated from '../../../../generated/graphql';
import {
  extractStatusCodeFromApolloError,
  propagateErrorsContext,
} from '../../../../util/errors';

const getErrorMessage = (error: unknown) => {
  const statusCode = extractStatusCodeFromApolloError(error);
  const message = (error as any)?.result?.errors[0]?.message;
  if (statusCode === 400 && message.contains('existing alias')) {
    return 'This artifact cannot be deleted because of an existing alias.';
  }

  return 'Something went wrong while trying to delete this artifact.';
};

type DeleteMembershipModalProps = {
  open: boolean;
  onClose: () => void;
  isPortfolio: boolean;
  artifactName: string;
  versionIndex: number;
  hasAliases: boolean;
  hasMemberships: boolean;
  afterDelete: () => void;
  artifactId: string;
  artifactVersionId: string;
};

export const DeleteMembershipModal: FC<DeleteMembershipModalProps> = ({
  open,
  onClose,
  isPortfolio,
  artifactName,
  versionIndex,
  hasAliases,
  hasMemberships,
  afterDelete,
  artifactId,
  artifactVersionId,
}) => {
  const [deleteAliases, setDeleteAliases] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [deleteArtifact] = Generated.useDeleteArtifactMutation({
    context: propagateErrorsContext(),
  });
  const [unlinkArtifact] = Generated.useUnlinkArtifactMutation();

  const onDeleteClick = useCallback(async () => {
    setDeleting(true);

    try {
      if (isPortfolio) {
        await unlinkArtifact({
          variables: {
            artifactPortfolioID: artifactId ?? '',
            artifactID: artifactVersionId,
          },
        });
      } else {
        await deleteArtifact({
          variables: {
            artifactID: artifactVersionId,
            deleteAliases,
          },
        });
      }
      afterDelete();
    } catch (error) {
      console.error(error);
      toast(getErrorMessage(error));
    }

    setDeleting(false);
  }, [
    afterDelete,
    artifactId,
    artifactVersionId,
    deleteAliases,
    deleteArtifact,
    isPortfolio,
    unlinkArtifact,
  ]);

  return (
    <Modal open={open} onClose={onClose}>
      <Header>
        <p>
          Are you sure you want to {isPortfolio ? 'unlink' : 'delete'}{' '}
          <b>
            {artifactMembershipNiceName(artifactName, {
              versionIndex,
            })}
          </b>
          ?
        </p>
      </Header>
      <Modal.Content>
        <p>
          {isPortfolio
            ? 'You are about to unlink an artifact version. Warning - it will break any scripts referencing this artifact version or its aliases.'
            : 'The artifact and its files will no longer be accessible! Additionally, any scripts referencing this artifact by an alias will break.'}
        </p>
        {hasAliases && (
          <Checkbox
            label={
              'Delete existing aliases' + (hasMemberships ? ' and links' : '')
            }
            data-test="delete-artifact-checkbox"
            onChange={() => setDeleteAliases(!deleteAliases)}
            checked={deleteAliases}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic size="tiny" onClick={onClose}>
          Nevermind
        </Button>
        <Button
          negative
          size="tiny"
          loading={isDeleting}
          disabled={hasAliases && !deleteAliases}
          data-cy="artifact-delete-confirm"
          onClick={onDeleteClick}>
          Delete version
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
