import * as _ from 'lodash';

import * as Urls from '../../_external/util/urls';
import * as callFunction from '../../callers';
import {constNumber} from '../../model/graph/construction';
import * as GraphTypes from '../../model/graph/types';
import * as TypeHelpers from '../../model/helpers';
import * as Types from '../../model/types';
import {jsValToCGType, replaceInputVariables} from '../../refineHelpers';
import {docType} from '../../util/docs';
import * as JSONNan from '../../util/jsonnan';
import * as Obj from '../../util/obj';
import * as String from '../../util/string';
import * as OpKinds from '../opKinds';
import {connectionToNodes} from './util';

const makeRunOp = OpKinds.makeTaggingStandardOp;

const runArgTypes = {
  run: 'run' as const,
};

const runArgDescriptions = `A ${docType('run')}`;

const isTableTypeHistoryKeyType = (id: string) => {
  return ['table-file', 'partitioned-table', 'joined-table'].includes(id);
};

export const opGetRunTag = OpKinds.makeTagGetterOp({
  name: 'tag-run',
  tagName: 'run',
  tagType: 'run',
});

export const opRunInternalId = makeRunOp({
  hidden: true,
  name: 'run-internalId',
  argTypes: runArgTypes,
  description: `Returns the internal id of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The internal id of the ${docType('run')}`,
  returnType: inputTypes => 'string',
  resolver: ({run}) => {
    return run.id;
  },
});

// Keep this hidden until we're sure users need it.
export const opRunId = makeRunOp({
  hidden: true,
  name: 'run-id',
  argTypes: runArgTypes,
  description: `Returns the id of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The id of the ${docType('run')}`,
  returnType: inputTypes => 'string',
  resolver: ({run}) => run.name,
});

export const opRunName = makeRunOp({
  name: 'run-name',
  argTypes: runArgTypes,
  description: `Returns the name of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The name of the ${docType('run')}`,
  returnType: inputTypes => 'string',
  resolver: ({run}) => run.displayName,
});

export const opRunJobType = makeRunOp({
  name: 'run-jobType',
  argTypes: runArgTypes,
  description: `Returns the job type of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The job type of the ${docType('run')}`,
  returnType: inputTypes => 'string',
  resolver: ({run}) => run.jobType,
});

export const opRunLink = makeRunOp({
  hidden: true,
  name: 'run-link',
  argTypes: runArgTypes,
  description: `Returns the link to the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The link to the ${docType('run')}`,
  returnType: inputTypes => 'link',
  resolver: ({run}) => ({
    name: run.displayName,
    url: Urls.run({
      entityName: run.project.entityName,
      projectName: run.project.name,
      name: run.name,
    }),
  }),
});

export const opRunProject = makeRunOp({
  hidden: true,
  name: 'run-project',
  argTypes: runArgTypes,
  description: `Returns the ${docType('project')} of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The ${docType('project')} of the ${docType('run')}`,
  returnType: inputTypes => 'project',
  resolver: ({run}) => run.project,
});

export const opRunUser = makeRunOp({
  name: 'run-user',
  argTypes: runArgTypes,
  description: `Returns the ${docType('user')} of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The ${docType('user')} of the ${docType('run')}`,
  returnType: inputTypes => 'user',
  resolver: ({run}) => run.user,
});

export const opRunCreatedAt = makeRunOp({
  name: 'run-createdAt',
  argTypes: runArgTypes,
  description: `Returns the created at datetime of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The created at datetime of the ${docType('run')}`,
  returnType: inputTypes => 'date',
  resolver: ({run}) => new Date(run.createdAt + 'Z'),
});

export const opRunHeartbeatAt = makeRunOp({
  name: 'run-heartbeatAt',
  argTypes: runArgTypes,
  description: `Returns the last heartbeat datetime of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The last heartbeat datetime of the ${docType(
    'run'
  )}`,
  returnType: inputTypes => 'date',
  resolver: ({run}) => new Date(run.heartbeatAt + 'Z'),
});

// Hiding... does job type want to be something more than a string?
export const opRunJobtype = makeRunOp({
  hidden: true,
  name: 'run-jobtype',
  argTypes: runArgTypes,
  description: `Returns the job type of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The job type of the ${docType('run')}`,
  returnType: inputTypes => 'string',
  resolver: ({run}) => run.jobType,
});

// Create a Type from config or summary json.
// Note: this is not complete! It doesn't handle nested keys
// at all.
export const wandbJsonType = (json: any): Types.Type => {
  const propertyTypes: {[key: string]: Types.Type} = {};
  for (const key of Object.keys(json ?? {})) {
    propertyTypes[key] = jsValToCGType(json[key]);
  }
  return {type: 'typedDict', propertyTypes};
};

export const wandbJsonWithArtifacts = (json: {[key: string]: any}) => {
  return _.mapValues(json, val => {
    if (
      val != null &&
      isTableTypeHistoryKeyType(val._type) &&
      val.artifact_path != null
    ) {
      const parsedRef = TypeHelpers.parseArtifactRef(val.artifact_path);
      return {
        ...val,
        artifact: {id: parsedRef.artifactId},
        path: parsedRef.assetPath,
      };
    }
    return val;
  });
};

export const opRunConfig = makeRunOp({
  name: 'run-config',
  argTypes: runArgTypes,
  description: `Returns the config ${docType('typedDict')} of the ${docType(
    'run'
  )}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The config ${docType('typedDict')} of the ${docType(
    'run'
  )}`,
  returnType: inputTypes => TypeHelpers.typedDict({}),
  resolver: ({run}) => {
    const parsed = JSONNan.JSONparseNaN(run.config) ?? {};
    const fixed = _.mapValues(parsed, (child: any) => child.value);
    return wandbJsonWithArtifacts(fixed);
  },
  // TODO: resolveOutputType does not perform all the correct
  // unwrapping!
  resolveOutputType: async (inputTypes, node, executableNode, client) => {
    const runConfigNode = replaceInputVariables(executableNode, client.opStore);
    const config: any = await client.query(runConfigNode);
    if (_.isArray(config)) {
      if (config.length === 0) {
        // This will happen in cases that the incoming run list is empty!
        return TypeHelpers.typedDict({});
      }
      return TypeHelpers.union(config.map(wandbJsonType));
    } else {
      return wandbJsonType(config);
    }
  },
});

export const opRunSummary = makeRunOp({
  name: 'run-summary',
  argTypes: runArgTypes,
  description: `Returns the summary ${docType('typedDict')} of the ${docType(
    'run'
  )}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The summary ${docType('typedDict')} of the ${docType(
    'run'
  )}`,
  returnType: inputTypes => TypeHelpers.typedDict({}),
  resolver: ({run}) => {
    const res = JSONNan.JSONparseNaN(run.summaryMetrics) ?? {};
    return wandbJsonWithArtifacts(res);
  },
  // This TODO is important! Need to use the same OpTypes behaviors
  // we do in returnType in all resolveOutputType calls
  // TODO: resolveOutputType does not perform all the correct
  // unwrapping!
  resolveOutputType: async (inputTypes, node, executableNode, client) => {
    const runSummaryNode = replaceInputVariables(
      executableNode,
      client.opStore
    );
    const summary: any = await client.query(runSummaryNode);
    if (_.isArray(summary)) {
      if (summary.length === 0) {
        // This will happen in cases that the incoming run list is empty!
        return TypeHelpers.typedDict({});
      }
      return TypeHelpers.union(summary.map(wandbJsonType));
    } else {
      return wandbJsonType(summary);
    }
  },
});

// TODO: missing test
export const opRunHistoryKeyInfo = makeRunOp({
  hidden: true,
  name: '_run-historykeyinfo',
  argTypes: runArgTypes,
  description: `Returns the history key info for each key of the ${docType(
    'run'
  )}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The history key info for each key of the ${docType(
    'run'
  )}`,
  returnType: inputTypes => TypeHelpers.typedDict({}),
  resolver: ({run}) => run.historyKeys ?? {keys: {}, sets: []},
});

// TODO: missing test
export const opRunHistory = makeRunOp({
  name: 'run-history',
  argTypes: runArgTypes,
  description: `Returns the log history of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The log history of the ${docType('run')}`,
  returnType: inputTypes => TypeHelpers.list(TypeHelpers.typedDict({})),
  resolver: ({run}) =>
    run?.history?.map((row: string) => {
      const res = JSONNan.JSONparseNaN(row);
      return wandbJsonWithArtifacts(res);
    }) ?? [],
  // TODO: resolveOutputType does not perform all the correct
  // unwrapping!

  resolveOutputType: async (inputTypes, node, executableNode, client) => {
    // TODO: We don't need this firstRunNode thing here... This function is
    // just all wrong now. See more correct implementation in opRunSummary/
    // opRunConfig

    // See opRunSummary for comment
    const firstRunNode = callFunction.mapNodes(executableNode, mapped => {
      if (
        mapped.nodeType === 'output' &&
        mapped.fromOp.name === 'index' &&
        mapped.fromOp.inputs.index.nodeType === 'var'
      ) {
        return {
          nodeType: 'output',
          // wrong type, but we don't need type to execute
          // the summary call... TODO: there must be a
          // better way
          type: 'invalid',
          fromOp: {
            ...mapped.fromOp,
            inputs: {
              ...mapped.fromOp.inputs,
              index: constNumber(0),
            },
          },
        };
      }
      return mapped;
    }) as GraphTypes.OutputNode;
    const run = firstRunNode.fromOp.inputs.run;

    // Execute ourself!
    const runHistoryNode = opRunHistory({run});
    // TODO: This is very expensive right now! It reads the entire history
    // and all keys, for the table logic below. Totally unnecessary.
    let history = await client.query(runHistoryNode);

    const runHistoryTypeNode = opRunHistoryKeyInfo({run});
    let historyKeyInfo = await client.query(runHistoryTypeNode);
    if (_.isArray(historyKeyInfo)) {
      history = history[0];
      historyKeyInfo = historyKeyInfo[0];
    }

    const propertyTypes: {[key: string]: Types.Type} = {};
    for (const key of Object.keys(historyKeyInfo?.keys ?? {})) {
      const val = historyKeyInfo.keys[key];
      const valType = val.typeCounts[0].type;
      if (valType === 'string') {
        propertyTypes[key] = 'string';
      } else if (valType === 'number') {
        propertyTypes[key] = 'number';
      } else if (isTableTypeHistoryKeyType(valType)) {
        const vals = history.map((hRow: any) => hRow[key]).filter(Obj.notEmpty);
        if (vals.length !== 0) {
          const val0 = vals[0];
          if (val0.artifact_path == null) {
            // TODO: This will throw an error in the non-artifact case.
            // Shouldn't be an error. Ideally we'd load from the run.
            throw new Error(
              'opRunHistory: expected artifact_path to be non-null'
            );
          }
          if (isTableTypeHistoryKeyType(val0._type)) {
            if (val0.artifact_path != null) {
              propertyTypes[key] = TypeHelpers.filePathToType(
                TypeHelpers.parseArtifactRef(val0.artifact_path).assetPath
              );
            } else {
              propertyTypes[key] = TypeHelpers.filePathToType(val0.path);
            }
          }
        }
      }
    }

    const outputType: Types.Type = {
      type: 'list',
      objectType: {
        type: 'typedDict',
        propertyTypes,
      },
    };
    return outputType;
  },
});

export const opRunHistoryAsOfStep = makeRunOp({
  hidden: true,
  name: 'run-historyAsOf',
  argTypes: {
    run: 'run' as const,
    asOfStep: 'number' as const,
  },
  returnType: inputTypes => TypeHelpers.list(TypeHelpers.typedDict({})),
  resolver: ({run, asOfStep}) => {
    const returnedHistory = run?.[`historyAsOf_${asOfStep}`];
    if (returnedHistory == null) {
      return {};
    } else {
      return wandbJsonWithArtifacts(JSONNan.JSONparseNaN(returnedHistory[0]));
    }
  },

  resolveOutputType: async (inputTypes, node, executableNode, client) => {
    const history = await client.query(
      replaceInputVariables(executableNode, client.opStore)
    );
    if (_.isArray(history)) {
      if (history.length === 0) {
        // This will happen in cases that the incoming run list is empty!
        return TypeHelpers.typedDict({});
      }
      return TypeHelpers.union(history.map(wandbJsonType));
    } else {
      return wandbJsonType(history);
    }
  },
});

export const opRunLoggedArtifactVersion = makeRunOp({
  name: 'run-loggedArtifactVersion',
  argTypes: {...runArgTypes, artifactVersionName: 'string'},
  description: `Returns the ${docType(
    'artifactVersion'
  )} logged by the ${docType('run')} for a given name and alias`,
  argDescriptions: {
    run: runArgDescriptions,
    artifactVersionName: `The name:alias of the ${docType('artifactVersion')}`,
  },
  returnValueDescription: `The ${docType(
    'artifactVersion'
  )} logged by the ${docType('run')} for a given name and alias`,
  returnType: inputTypes => 'artifactVersion',
  resolver: ({run, artifactVersionName}) => {
    // tslint:disable-next-line: prefer-const
    let [artifactName, version] = String.splitOnce(artifactVersionName, ':');
    if (version == null) {
      version = 'latest';
    }
    const foundNode = connectionToNodes(run.outputArtifacts).find(
      (node: any) =>
        (node.versionIndex.toString() === version?.slice(1) ||
          node.aliases.some((a: any) => a.alias === version)) &&
        node.artifactSequence.name === artifactName
    );
    if (foundNode == null) {
      // We don't want to error here. in the case that you have
      // runs.loggedArtifactVersion("a:v1"), not all runs will
      // have the version.
      return null;
    }
    return foundNode;
  },
});

export const opRunLoggedArtifactVersions = makeRunOp({
  name: 'run-loggedArtifactVersions',
  argTypes: runArgTypes,
  description: `Returns all of the ${docType('artifactVersion', {
    plural: true,
  })} logged by the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The ${docType('artifactVersion', {
    plural: true,
  })} logged by the ${docType('run')}`,
  returnType: inputTypes => TypeHelpers.list('artifactVersion'),
  resolver: ({run}) => connectionToNodes(run.outputArtifacts),
});

export const opRunUsedArtifactVersions = makeRunOp({
  name: 'run-usedArtifactVersions',
  argTypes: runArgTypes,
  description: `Returns all of the ${docType('artifactVersion', {
    plural: true,
  })} used by the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The ${docType('artifactVersion', {
    plural: true,
  })} used by the ${docType('run')}`,
  returnType: inputTypes => TypeHelpers.list('artifactVersion'),
  resolver: ({run}) => connectionToNodes(run.inputArtifacts),
});

export const opRunRuntime = makeRunOp({
  name: 'run-runtime',
  argTypes: runArgTypes,
  description: `Returns the runtime in seconds of the ${docType('run')}`,
  argDescriptions: {run: runArgDescriptions},
  returnValueDescription: `The runtime in seconds of the ${docType('run')}`,
  returnType: inputTypes => 'number',
  resolver: ({run}) => {
    return run.computeSeconds;
  },
});
