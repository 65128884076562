import {useCallback, useContext} from 'react';
import {Node, NodeEntry, Path, Range, Text} from 'slate';

import {ReportDiscussionContext} from '../../ReportDiscussionContext';
import {
  INLINE_COMMENT_PREFIX,
  InlineCommentMark,
} from './inline-comments-common';

// check if the markKey is for InlineCommentMark
export function isInlineCommentMark(markKey: string): boolean {
  return markKey.startsWith(INLINE_COMMENT_PREFIX);
}

// get RefID from the markKey. Use this with isInlineCommentMark(key) check.
export function getRefID(key: string): string {
  return key.split(INLINE_COMMENT_PREFIX)[1];
}

// get the first key for inlineCommentMark from a slate node
export function getMarkKeyFromNode(node: Node): string | undefined {
  return Object.keys(node).find(key => isInlineCommentMark(key));
}

// get a list of all the threadIDs associtated with the slate text node
export function getThreadIDsFromNode(node: Node): string[] {
  const threadIDs: string[] = [];

  Object.entries(node).forEach(([key, content]) => {
    if (isInlineCommentMark(key)) {
      const threadID = (content as InlineCommentMark).threadID;
      if (threadID != null) {
        threadIDs.push(threadID);
      }
    }
  });

  return threadIDs;
}

// this fn returns a list of all the slate nodes that should be highlighted
// as temporary inline text node to indicate that the text is getting commented on.
export function useInlineCommentHighlighting() {
  const {inlineCommentDetails} = useContext(ReportDiscussionContext);

  const decorate = useCallback(
    ([node, path]: NodeEntry<Node>) => {
      const ranges: Range[] = [];

      if (!Text.isText(node)) {
        return ranges;
      }

      // decorate doesn't split the range to apply marks (highlight) for
      // multi selected ranges so we would have to manually split the range
      inlineCommentDetails?.forEach(detail => {
        const detailRange = detail.rangeRef.current;
        if (detailRange == null) {
          return;
        }

        const detailStartPoint = Range.start(detailRange);
        const detailEndPoint = Range.end(detailRange);

        // out of range
        if (
          Path.compare(path, detailStartPoint.path) < 0 ||
          Path.compare(detailEndPoint.path, path) < 0
        ) {
          return;
        }

        // detailRange only covers one node, just return the current range
        if (Path.compare(detailStartPoint.path, detailEndPoint.path) === 0) {
          ranges.push({
            ...detailRange,
            tempInlineComment: true,
          });
          return;
        }

        // detailRange covers multiple ranges, split and add them to the list
        // left most range
        if (Path.compare(path, detailStartPoint.path) === 0) {
          const startRange: Range = {
            anchor: {path, offset: detailStartPoint.offset},
            focus: {path, offset: node.text.length},
          };
          ranges.push({
            ...startRange,
            tempInlineComment: true,
          });
          return;
        }

        // right most range
        if (Path.compare(path, detailEndPoint.path) === 0) {
          const endRange: Range = {
            anchor: {path, offset: 0},
            focus: {path, offset: detailEndPoint.offset},
          };
          ranges.push({
            ...endRange,
            tempInlineComment: true,
          });
          return;
        }

        // middle range
        const middleRange: Range = {
          anchor: {path, offset: 0},
          focus: {path, offset: node.text.length},
        };
        ranges.push({...middleRange, tempInlineComment: true});
      });
      return ranges;
    },
    [inlineCommentDetails]
  );
  return decorate;
}
