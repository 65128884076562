import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

import editIcon from '../assets/ic-edit.svg';
import {UpdateOverlaySize} from './EditableImage';
import {WBButton} from './WBButtonNew';

export const EditIcon = styled.img.attrs({src: editIcon})`
  margin-right: calc(${globals.spu} * 0.5);
`;

export const UpdateButton = styled(WBButton)<{
  fontSize: UpdateOverlaySize;
}>`
  &&&&& {
    position: absolute;
    right: 5px;
    bottom: 5px;
    margin: 0;
    color: ${globals.white};
    background-color: ${globals.TEAL_LIGHT};
    &:hover {
      background-color: ${globals.TEAL_LIGHT};
    }

    ${p =>
      p.fontSize === `small` &&
      css`
        font-size: 14px;
        ${EditIcon} {
          width: 18px;
        }
      `}
  }
`;
