import * as globals from '@wandb/common/css/globals.styles';
import {TargetBlank} from '@wandb/common/util/links';
import styled from 'styled-components';

export const ContactSalesLink = styled(TargetBlank)`
  display: inline-flex;
  align-items: center;
  color: ${globals.gray500};
  &:hover {
    color: ${globals.gray500};
  }
`;
