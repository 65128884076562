import React, {ComponentType} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {auth} from '../setup';
import {setAuthRedirect} from '../util/auth';
import RouteWithLayout, {RouteWithLayoutProps} from './RouteWithLayout';

const mapStateToProps = (state: any) => ({
  hasCurrentViewer: !!state.viewer.viewer,
});

const authComponents: {[key: string]: ComponentType} = {};

export interface PrivateRouteProps extends RouteWithLayoutProps {
  component: ComponentType;
  routeCache: string;
  allowAnonymous: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  routeCache,
  allowAnonymous,
  ...rest
}) => {
  let AuthComponent;
  if (routeCache) {
    // We do this to avoid remounting child components here (on initial load your component
    // will mount and unmount if you don't do this).
    AuthComponent = authComponents[routeCache];
    if (!AuthComponent) {
      AuthComponent = connect(mapStateToProps)(Component);
      authComponents[routeCache] = AuthComponent;
    }
  } else {
    AuthComponent = connect(mapStateToProps)(Component);
  }

  if (!auth.loggedIn() && !allowAnonymous) {
    // TODO: we can move this redirect logic to the server once auth logic is unified.
    const redirectTo = window.location.href.replace(window.location.origin, '');
    setAuthRedirect(redirectTo);
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {prompt: true, from: rest.location, internal: true},
        }}
      />
    );
  } else {
    return (
      <RouteWithLayout
        {...rest}
        component={auth.loggedIn() ? AuthComponent : Component}
      />
    );
  }
  /*
return (
  <Route
    {...rest}
    render={props => {
      return auth.loggedIn() ? (
        <AuthComponent {...props} />
      ) : allowAnonymous ? (
        <Component {...props} />
      ) : (
        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
      );
    }}
  />
);
*/
};

export default connect(mapStateToProps)(PrivateRoute);
