import config, {envIsIntegration, envIsLocal} from '@wandb/common/config';

import {ALL_FEATURE_FLAGS, FeatureFlag} from './featureFlags';

export const useFeatureFlagAggregateRunsEnabled = (entityName: string) => {
  return useFeatureFlag({
    featureKey: 'aggregate-runs',
    enabledFor: entityName,
  });
};

export const useFeatureFlagVegaEnabled = (entityName: string) => {
  return useFeatureFlag({
    featureKey: 'vega-panel',
    enabledFor: entityName,
  });
};

export const useFeatureFlagCreateBenchmarkEnabled = (entityName: string) => {
  return useFeatureFlag({
    featureKey: 'vega-panel',
    enabledFor: entityName,
  });
};

const useFeatureFlag = ({featureKey, enabledFor}: FeatureFlag): boolean => {
  // All features are enabled for CI
  if (envIsIntegration) {
    return true;
  }

  // Enable benchmarks and vega panels in local
  if (
    envIsLocal &&
    ['create-benchmark', 'vega-panel'].indexOf(featureKey) > -1
  ) {
    return true;
  }

  // Enable benchmarks for lyft
  if (
    config.ENVIRONMENT_NAME === 'wandb-lyft' &&
    featureKey === 'create-benchmark'
  ) {
    return true;
  }

  return ALL_FEATURE_FLAGS[featureKey][enabledFor] === 1;
};
