import './CreateReportPrompt.less';

import React, {useCallback, useEffect, useState} from 'react';

import {ReportPreview} from './../ReportPreview/ReportPreview';
import * as S from './CreateReportPrompt.styles';

type CreateReportPromptProps = {
  location: string;
  entityName: string;
  projectName: string;
  onCreateReport: () => void;
};

export const CreateReportPrompt: React.FC<CreateReportPromptProps> = React.memo(
  ({location, entityName, projectName, onCreateReport}) => {
    useEffect(() => {
      window.analytics?.track(`First Report Prompt Viewed`, {location});
      window.analytics?.track('Empty State Viewed', {
        location: 'reports empty tab',
      });
    }, [location]);

    const [creating, setCreating] = useState(false);
    const createReport = useCallback(() => {
      if (creating) {
        return;
      }

      window.analytics?.track('Create Report Clicked', {
        location: 'reports empty tab',
      });

      setCreating(true);
      onCreateReport();
    }, [creating, onCreateReport]);

    return (
      <>
        <S.MissingText>You haven't created any reports yet.</S.MissingText>
        <S.Container>
          <ReportPreview
            entityName={entityName}
            projectName={projectName}
            small
            onClick={createReport}
          />
          <S.Prompt>
            <S.PromptHeader>
              Create your first report for {projectName}
            </S.PromptHeader>
            <S.PromptBody>
              Reports are your brain dumps, eureka moments, and everything in
              between. They play nicely with everything else in W&B, so you can
              share, store or explain your findings.
            </S.PromptBody>
            <S.PromptButton
              primary
              onClick={createReport}
              disabled={creating}
              loading={creating}>
              Create report
            </S.PromptButton>
            <S.PromptHint>
              <S.PromptHintIcon name="left-arrow" />
              <S.PromptHintBody>
                Click to see how your charts from{' '}
                <span className="fake-link" onClick={createReport}>
                  {projectName}
                </span>{' '}
                look in a report
              </S.PromptHintBody>
            </S.PromptHint>
          </S.Prompt>
        </S.Container>
      </>
    );
  }
);

export default CreateReportPrompt;

type CreateReportTooltipPromptProps = {
  location: string;
  entityName: string;
  projectName: string;
};

export const CreateReportTooltipPrompt: React.FC<CreateReportTooltipPromptProps> =
  React.memo(({location, entityName, projectName}) => {
    useEffect(() => {
      window.analytics?.track(`First Report Prompt Viewed`, {location});
    }, [location]);
    return (
      <>
        <S.TooltipHeader>
          Create your first report for {projectName}
        </S.TooltipHeader>
        <S.TooltipBody>
          Click "Create report" to see your charts in a report where you can
          share, store or explain your findings.
        </S.TooltipBody>
        <ReportPreview entityName={entityName} projectName={projectName} />
      </>
    );
  });
