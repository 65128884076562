import * as _ from 'lodash';

import * as Normalize from '../normalize';
import * as PanelBankSectionConfigNormalize from '../panelBankSectionConfig/normalize';
import * as Types from './types';

const omit = _.omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = Normalize.normFn<Types.PanelBankConfigObjSchema>(
  'panel-bank-config',
  (whole, ctx) => {
    return {
      ...omit(whole, 'sections'),
      sectionRefs:
        whole.sections != null
          ? whole.sections.map(section =>
              PanelBankSectionConfigNormalize.normalize(section, ctx)
            )
          : [],
    };
  }
);

export const denormalize = Normalize.denormFn<Types.PanelBankConfigObjSchema>(
  (part, ctx) => {
    return {
      ...omit(part, 'sectionRefs'),
      sections:
        part.sectionRefs != null
          ? part.sectionRefs.map(ref =>
              PanelBankSectionConfigNormalize.denormalize(ref, ctx)
            )
          : [],
    };
  }
);
