import styled from 'styled-components';

import WBModal from './WBModal';

export const StyledWBModal = styled(WBModal)`
  p {
    margin-bottom: 16px !important;
  }
  .field {
    margin-left: 16px;
    margin-bottom: 8px !important;
  }
`;
