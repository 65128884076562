/**
 * Select a Run from within a Project.
 */
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';

import {
  FetchProjectRunsQuery,
  useFetchProjectRunsQuery,
} from '../../generated/graphql';
import Alert from './Alert';
import * as S from './SelectRun.styles';

interface SelectJobProps {
  entityName: string;
  projectName: string;
  runName?: string;
  onSelectRun: (runName: string) => void;
}

interface RunOption {
  value: string;
  label: string;
  hasGit: boolean;
}

const getRunOptions = (data: FetchProjectRunsQuery) => {
  const edges = data?.project?.runs?.edges ?? [];
  const options = edges.map(edge => {
    const run = edge.node;
    return {
      value: run.name,
      label: run.displayName ?? '',
      hasGit: !!run.runInfo?.git,
    };
  });
  return options;
};

const formatOptionLabel = ({label, hasGit}: RunOption) => (
  <S.RunOption>
    <S.RunOptionLabel>{label}</S.RunOptionLabel>
    {hasGit && <Chip label="Git"></Chip>}
  </S.RunOption>
);

const SelectRun: React.FC<SelectJobProps> = ({
  entityName,
  projectName,
  runName,
  onSelectRun,
}) => {
  const runsQuery = useFetchProjectRunsQuery({
    variables: {
      entityName,
      projectName,
    },
  });
  // TODO: Better error display
  if (runsQuery.loading || runsQuery.error) {
    return <div>Loading...</div>;
  }

  const options = getRunOptions(runsQuery.data!);
  if (options.length === 0) {
    return <Alert>There are no runs in this project yet</Alert>;
  }

  const defaultValue = _.find(options, o => o.value === runName);
  const onChange = (option: RunOption | null) => {
    if (option) {
      onSelectRun(option.value);
    }
  };

  return (
    <Select
      options={options}
      placeholder="Select a run..."
      defaultValue={defaultValue}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
    />
  );
};

export default SelectRun;
