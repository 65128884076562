/**
 * This is a forked subset of CreateReportModal, currently only used
 * to export Panel2 panels from the artifacts page to reports.
 *
 * It is simpler and doesn't use the first modal to pick the report
 * template type. I also cleaned up some of the UI behavior (loading
 * & disabled states), and simplified and reorganized a bunch of the
 * code for readability.
 */

import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, {FC, useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {Button} from 'semantic-ui-react';

import {useUserProjectsQuery} from '../generated/graphql';
import {useApolloClient} from '../state/hooks';
import {appendSectionsToReport, pushReport} from '../state/reports/hooks';
import {useViewer} from '../state/viewer/hooks';
import {ReportConfig} from '../state/views/report/types';
import {getEmptyReportConfig} from '../util/report';
import DraftWarningModal from './DraftWarningModal';
import {WBSlateElementType} from './Slate/WBSlate';
import * as S from './WBModal.styles';

const NEW_REPORT_ID = 'new';

interface AddToReportProps {
  trigger: JSX.Element;
  reportBlock: WBSlateElementType;
}

interface DropdownOption {
  text: string;
  value: string | number;
}

const DRAFT_APPEND_MAIN_TEXT =
  'You have unsaved edits to this report. Do you want to resume or discard these unsaved edits?';
const DRAFT_APPEND_DISCARD_TEXT = 'Discard';

type UserProjectsQuery = ReturnType<typeof useUserProjectsQuery>;

function getTeamsFromUserProjectsQuery(query: UserProjectsQuery) {
  return _.compact(query.data?.user?.teams?.edges.map(e => e.node) ?? []);
}

function getProjectsFromUserProjectsQuery(query: UserProjectsQuery) {
  const teams = getTeamsFromUserProjectsQuery(query);
  const projectsPerTeam = teams.map(
    t => t?.projects?.edges.map(e => e.node) ?? []
  );
  return _.compact(_.flatten(projectsPerTeam));
}

interface AddToReportOptionsParams {
  userProjectsQuery: UserProjectsQuery;
  currentReport?: {
    id: string;
    parentID?: string;
  };
}

export function useAddToReportOptions({
  userProjectsQuery,
  currentReport,
}: AddToReportOptionsParams) {
  const projectOptions: DropdownOption[] = useMemo(() => {
    if (userProjectsQuery.data == null) {
      return [];
    }
    const teams = getTeamsFromUserProjectsQuery(userProjectsQuery);
    const opts: DropdownOption[] = [];
    for (const t of teams) {
      const teamOpts =
        t?.projects?.edges.map(e => ({
          text:
            teams.length > 1
              ? `${e.node?.entityName}/${e.node?.name}`
              : e.node?.name ?? '',
          value: e.node?.id ?? '',
        })) ?? [];
      opts.push(...teamOpts);
    }
    return opts;
  }, [userProjectsQuery]);

  const reportOptionsByProjectID: {
    [id: string]: DropdownOption[];
  } = useMemo(() => {
    if (userProjectsQuery.data == null) {
      return {};
    }
    const optionsByID: {
      [id: string]: DropdownOption[];
    } = {};
    const projects = getProjectsFromUserProjectsQuery(userProjectsQuery);
    for (const p of projects) {
      const reports = p.reports?.edges?.map(r => r.node) ?? [];
      optionsByID[p.id] = [
        {text: 'Create a new report', value: NEW_REPORT_ID},
        ...reports
          .map(r => ({
            text: r?.displayName ?? '',
            value: r?.id ?? '',
          }))
          .filter(o => {
            if (currentReport == null) {
              return true;
            }
            return o.value !== currentReport.parentID ?? currentReport.id;
          }),
      ];
    }
    return optionsByID;
  }, [userProjectsQuery, currentReport]);

  const projectInfoByID: {
    [id: string]: {
      entityName: string;
      name: string;
    };
  } = useMemo(() => {
    if (userProjectsQuery.data == null) {
      return {};
    }
    const infoByID: {
      [id: string]: {
        entityName: string;
        name: string;
      };
    } = {};
    const projects = getProjectsFromUserProjectsQuery(userProjectsQuery);
    for (const p of projects) {
      infoByID[p.id] = {entityName: p.entityName, name: p.name};
    }
    return infoByID;
  }, [userProjectsQuery]);

  return {
    projectOptions,
    reportOptionsByProjectID,
    projectInfoByID,
  };
}

const useReportActions = (onActionDone: () => void) => {
  const client = useApolloClient();
  const [saving, setSaving] = useState(false);

  const createReport = useCallback(
    (entityName: string, projectName: string, config: ReportConfig) => {
      setSaving(true);
      const doCreate = async () => {
        await pushReport({
          client,
          entityName,
          projectName,
          config,
          reportName: 'New report',
          opts: {
            newTab: true,
          },
        });
        onActionDone();
      };
      doCreate();
    },
    [client, onActionDone]
  );

  const addBlockToReport = useCallback(
    (
      entityName: string,
      projectName: string,
      reportID: string,
      block: WBSlateElementType
    ) => {
      setSaving(true);
      const doAppend = async () => {
        await appendSectionsToReport({
          client,
          entityName,
          projectName,
          reportID,
          newBlocks: [block],
          opts: {newTab: true},
        });
        onActionDone();
      };
      doAppend();
    },
    [client, onActionDone]
  );

  return {saving, createReport, addBlockToReport};
};

export const AddToReportModalContent: FC<
  AddToReportProps & {
    closeModal(): void;
  }
> = ({reportBlock, closeModal}) => {
  const viewer = useViewer();

  const [draftWarningModalOpen, setDraftWarningModalOpen] = useState(false);

  const [selectedProjectID, setSelectedProjectID] = useState('');
  const onChangeProject = useCallback(
    (e, data) => setSelectedProjectID(data.value),
    [setSelectedProjectID]
  );

  const [reportSearchQuery, setReportSearchQuery] = useState('');
  const onReportSearchQueryChange = useCallback(
    (e, data) => setReportSearchQuery(data.searchQuery),
    [setReportSearchQuery]
  );

  const [selectedReportID, setSelectedReportID] = useState(NEW_REPORT_ID);
  const onChangeReport = useCallback(
    (e, data) => {
      setSelectedReportID(data.value);
      setReportSearchQuery('');
    },
    [setSelectedReportID, setReportSearchQuery]
  );

  const userProjectsQueryResult = useUserProjectsQuery({
    variables: {userName: viewer?.username ?? '', includeReports: true},
    fetchPolicy: 'cache-and-network',
    skip: viewer == null,
  });
  const projects = useMemo(
    () => getProjectsFromUserProjectsQuery(userProjectsQueryResult),
    [userProjectsQueryResult]
  );

  const {projectOptions, reportOptionsByProjectID, projectInfoByID} =
    useAddToReportOptions({
      userProjectsQuery: userProjectsQueryResult,
    });

  // If the user hasn't chosen a project ID, default to the one from the current
  // route. If the current route doesn't contain entityName and projectName
  // default to the first project we find.
  const params = useParams<{entityName?: string; projectName?: string}>();
  const defaultEntityName = params.entityName;
  const defaultProjectName = params.projectName;
  const targetProjectID = useMemo(() => {
    if (selectedProjectID !== '') {
      return selectedProjectID;
    }
    const defaultProject = projects.find(
      p => p.entityName === defaultEntityName && p.name === defaultProjectName
    );
    if (defaultProject != null) {
      return defaultProject.id;
    }
    // didn't find the default project, use first project instead
    const firstProject = projects[0];
    return firstProject != null ? firstProject.id : '';
  }, [selectedProjectID, defaultEntityName, defaultProjectName, projects]);

  const {targetEntityName, targetProjectName} = useMemo(() => {
    const projectInfo =
      targetProjectID != null ? projectInfoByID[targetProjectID] : undefined;
    return {
      targetEntityName: projectInfo?.entityName ?? '',
      targetProjectName: projectInfo?.name ?? '',
    };
  }, [projectInfoByID, targetProjectID]);

  const reportConfig = useMemo(() => {
    const conf = getEmptyReportConfig([reportBlock]);
    conf.width = 'fluid';
    return conf;
  }, [reportBlock]);

  const {saving, createReport, addBlockToReport} = useReportActions(closeModal);
  const loading = userProjectsQueryResult.loading;
  const busy = loading || saving;

  return (
    <>
      <S.WBModalHeader>Add to report</S.WBModalHeader>
      <S.WBModalContent>
        <div className={classNames('destination-section')}>
          <p>Pick a destination report.</p>
          <div className="dest-row">
            <div className="dest-row-dest">Project</div>
            <ModifiedDropdown
              selection
              search
              loading={loading}
              disabled={busy}
              className="save-snapshot-modal__select"
              options={projectOptions}
              value={targetProjectID}
              onChange={onChangeProject}
            />
          </div>
          <div className="dest-row">
            <div className="dest-row-dest">Report</div>
            <ModifiedDropdown
              selection
              loading={loading}
              disabled={busy}
              className="save-snapshot-modal__select"
              options={reportOptionsByProjectID[targetProjectID] ?? []}
              value={selectedReportID}
              onChange={onChangeReport}
              search
              searchQuery={reportSearchQuery}
              onSearchChange={onReportSearchQueryChange}
            />
          </div>
        </div>
      </S.WBModalContent>
      <S.WBModalActions>
        <Button
          primary
          loading={saving}
          disabled={busy}
          onClick={() => {
            if (selectedReportID === NEW_REPORT_ID) {
              createReport(targetEntityName, targetProjectName, reportConfig);
            } else {
              setDraftWarningModalOpen(true);
            }
          }}
          content={
            selectedReportID === NEW_REPORT_ID
              ? 'Create report'
              : 'Add To Report'
          }
        />
      </S.WBModalActions>

      {draftWarningModalOpen && selectedReportID !== NEW_REPORT_ID && (
        <DraftWarningModal
          parentViewID={selectedReportID}
          mainText={DRAFT_APPEND_MAIN_TEXT}
          discardText={DRAFT_APPEND_DISCARD_TEXT}
          renderResumeButton={draftID => (
            <Button
              size="tiny"
              loading={saving}
              disabled={busy}
              onClick={() => {
                const projectInfo = projectInfoByID[targetProjectID];
                if (projectInfo == null) {
                  return;
                }
                const {entityName, name} = projectInfo;
                addBlockToReport(entityName, name, draftID, reportBlock);
              }}>
              Resume
            </Button>
          )}
          flipButtonColors
          onNoDraftFound={() => {
            addBlockToReport(
              targetEntityName,
              targetProjectName,
              selectedReportID,
              reportBlock
            );
          }}
          onDiscard={() => {
            addBlockToReport(
              targetEntityName,
              targetProjectName,
              selectedReportID,
              reportBlock
            );
          }}
          onClose={() => setDraftWarningModalOpen(false)}
        />
      )}
    </>
  );
};

export const AddToReportModal: FC<AddToReportProps> = props => {
  const [open, setOpen] = useState(false);
  return (
    <S.WBModal
      className="save-snapshot-modal"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={props.trigger}>
      <AddToReportModalContent {...props} closeModal={() => setOpen(false)} />
    </S.WBModal>
  );
};
