import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {fuzzyMatchHighlight} from '@wandb/common/util/fuzzyMatch';
import React from 'react';
import {Link} from 'react-router-dom';
import {Image} from 'semantic-ui-react';

import {LocalSearchResult} from './types';
import {useSearchNavAnalytics} from './useSearchNavAnalytics';

interface LocalSearchResultItemProps {
  result: LocalSearchResult;
  selected?: boolean;
  query: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const LocalSearchResultItem: React.FC<LocalSearchResultItemProps> =
  React.memo(({result, query, onClick, selected}) => {
    const {trackSearchNavUsed} = useSearchNavAnalytics();
    return (
      <Link
        onClick={e => {
          trackSearchNavUsed(result.url);
          onClick(e);
        }}
        className={`search-result ${selected ? 'selected' : ''}`}
        to={result.url}>
        <div className="search-result-left-wrapper">
          {result.type === 'project' && (
            <LegacyWBIcon name="folder" className="search-result-icon" />
          )}
          {result.type === 'team' && result.photoUrl && (
            <Image src={result.photoUrl} className="search-result-photo" />
          )}
          <div className="search-result-name">
            {fuzzyMatchHighlight(result.name, query)}
          </div>
        </div>
        {result.meta && <div className="search-result-meta">{result.meta}</div>}
      </Link>
    );
  });
