import {Element} from 'slate';

export function getDeepLinkId(element: Element): string | undefined {
  return element.type === 'heading'
    ? element.children
        .map(c => c.text)
        .join('')
        .replace(/\s+/g, '-')
        .toLowerCase()
    : undefined;
}

export function getDeepLinkIdFromText(text: string): string {
  return text.replace(/\s+/g, '-').toLowerCase();
}

// HAX: In read-only mode, Slate adds the 'uFEFF' zero-width character in text.
// We use this function on copy to strip this character in the user's clipboard.
// Without this, users will see nonsense characters on paste.
export function stripZeroWidthChars(
  event: React.ClipboardEvent<HTMLDivElement>
): void {
  const text = window.getSelection()?.toString();
  if (!text) {
    return;
  }

  const regex = /\uFEFF/g;
  const hasZeroWidthChar = regex.test(text);
  if (!hasZeroWidthChar) {
    return;
  }

  const modifiedText = text.replace(regex, '');
  event.clipboardData.setData('text/plain', modifiedText);
  event.preventDefault();
}
