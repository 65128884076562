import docUrl from './doc_urls';

// return type of useBetaFeature and associated hooks
export interface BetaFeature {
  isEnabled: boolean;
  toggleBetaFeature: () => void;
}

export interface BetaFeatureMetadata {
  featureKey: BetaFeatureKey; // unique id of the feature, e.g. 'model-registry'
  name: string; // human-readable name of the feature
  description: string; // human-readable description of the feature
  docUrl?: string; // link to the docs for the feature
}

// Beta Features are experimental features that users can enable/disable on their settings page, and only affect that user.
// Note: This is different from Feature Flags, which are enabled/disabled by W&B internally, typically for a whole entity.
// (See featureFlags.ts and useFeatureFlag.ts if that's what you're looking for.)

// This includes all beta features (customer-facing beta features, and internal beta features only available to wandb employees)
export const ALL_BETA_FEATURE_KEYS = [
  /* CUSTOMER-FACING BETA FEATURES */
  'night', // Enable night mode
  'unicorn-plot', // Enable unicorn plot cursor

  /* ADMIN-ONLY BETA FEATURES (only visible to wandb internal employees) */
  'account-settings', // Enable new account settings page
  'instant replay', // Enable run queues (Launch UI)
  'lazy-table', // Enable stupid easter egg (spin the table using css)
  'model-registry', // Enable Model Registry
  'vega3', // Enable Vega3 panel in workspaces
  'weave-devpopup', // Enable the hoverable weave panel2 dev popup
  'weave-python-ecosystem', // Enable weave ecosystem panels in reports.
  'weave-python-performance', // Enable weave backend execution for performance improvements.
  'weave1', // Enable weave1 execution of all weave queries.
] as const;

export type BetaFeatureKey = typeof ALL_BETA_FEATURE_KEYS[number];

// These are beta features that are available to all users in UserSettingsPage.tsx
export const BETA_FEATURES: ReadonlyArray<BetaFeatureMetadata> = [
  // Enable Night Mode (Dark Theme)
  {
    featureKey: 'night',
    name: 'Night mode',
    description:
      'Invert the colors everywhere! This makes pages dark, but might make colors on charts less easy to distinguish.',
  },
  // Enable run queues
  {
    featureKey: 'instant replay',
    name: 'W&B Launch',
    description: 'Launch jobs on your own infrastructure from W&B.',
    docUrl: docUrl.launch,
  },
  // Enable super secret easter egg
  {
    featureKey: 'unicorn-plot',
    name: 'Unicorn mode',
    description:
      '🦄 Change your cursor on charts from a boring pointer to a unicorn!',
  },
] as const;

// These are internal beta features that are only visible to wandb employees in UserSettingsPage.tsx
export const ADMIN_BETA_FEATURES: ReadonlyArray<BetaFeatureMetadata> = [
  {
    // Enable weave remote execution for python ecosystem panels
    featureKey: 'weave-python-ecosystem',
    name: 'Weave Python Ecosystem',
    description:
      'Allows you to create & view `Weave Python` panels in reports.',
    docUrl: docUrl.weave,
  },
  {
    // Enable weave remote execution for performance improvements
    featureKey: 'weave-python-performance',
    name: 'Weave Python Performance',
    description:
      '(Should not be used in customer demos) Will conservatively attempt to execute Weave queries using Weave Python when possible.',
    docUrl: docUrl.weave,
  },
  {
    // Enable weave1 execution of all weave queries
    featureKey: 'weave1',
    name: 'Weave1',
    description:
      '(Should not be used in customer demos) Will attempt to execute all Weave queries using Weave1.',
    docUrl: docUrl.weave,
  },
  // Enable the weave panel2 dev popup
  {
    featureKey: 'weave-devpopup',
    name: 'Weave DevPopup',
    description: 'Enable the Weave Panel2 dev popup.',
    docUrl: docUrl.weave,
  },
  // Enable demo easter egg
  {
    featureKey: 'lazy-table',
    name: 'Lazy table',
    description: 'Enable table-spinning easter egg.',
  },
  // Enable Model Registry
  {
    featureKey: 'model-registry',
    name: 'Model Registry',
    description: 'Override the usage limits imposed on Model Registry',
    docUrl: docUrl.modelManagement,
  },
  // Enable Vega3 panel in workspaces
  {
    featureKey: 'vega3',
    name: 'Vega3',
    description: 'Enable the Vega3 panel in workspaces.',
  },
  // Enable new /account-settings page
  {
    featureKey: 'account-settings',
    name: 'Account Settings Page',
    description: 'Enable the new account settings page.',
  },
];
