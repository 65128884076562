import config, {
  envIsLocal,
  envIsPublicCloud,
  urlPrefixed,
} from '@wandb/common/config';
import * as globals from '@wandb/common/css/globals.styles';
import docUrl from '@wandb/common/util/doc_urls';
import {TargetBlank} from '@wandb/common/util/links';
import classNames from 'classnames';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Dropdown, Image, Label, Popup} from 'semantic-ui-react';
import styled from 'styled-components';

import AutoRefreshSettings from '../containers/AutoRefreshSettings';
import {useServerInfoQuery, useViewerPhotoUrlQuery} from '../generated/graphql';
import {auth} from '../setup';
import {useViewer} from '../state/viewer/hooks';
import {
  setViewerUsingAdminPrivileges,
  useAdminModeActive,
  viewerUsingAdminPrivileges,
  viewingAs,
} from '../util/admin';
import {openBeamer} from '../util/beamer';
import history from '../util/history';
import * as Urls from '../util/urls';
import BeamerIcon from './BeamerIcon';
import BetaVersionModal, {betaVersion} from './BetaVersionModal';
import CopyableText from './CopyableText';
import {ImpersonateUserModal} from './ImpersonateUserModal';
import {LoginButton} from './LoginButton';
import UsageSimulationModal from './UsageSimulationModal';

type ProfileDropdownMenuProps = {
  isSignup?: boolean;
};

const ProfileDropdownMenu: React.FC<ProfileDropdownMenuProps> = React.memo(
  ({isSignup = false}) => {
    const viewer = useViewer();
    const admin = useAdminModeActive();

    const serverInfoQuery = useServerInfoQuery();
    const isGoogleIap = serverInfoQuery.data?.serverInfo?.isGoogleIap;

    const {data: viewerPhotoURLData} = useViewerPhotoUrlQuery({
      fetchPolicy: `network-only`,
    });
    const viewerPhotoURL = viewerPhotoURLData?.viewer?.photoUrl;

    const [limitConfigOpen, setLimitConfigOpen] = useState(false);

    const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;
    const isVm = isOnPrem && config.ENVIRONMENT_NAME === 'onprem-vm';
    const canLogout = !config.AUTH_STUB_JWT || config.AUTH_STUB_JWT === 'false';

    return (
      <>
        <div className="viewer-dropdown">
          {viewer ? (
            <Dropdown
              className="nav-propic-dropdown"
              direction="left"
              trigger={
                <>
                  <Image
                    className="nav-propic"
                    src={viewerPhotoURL ?? viewer.photoUrl}
                    avatar
                  />
                  <BeamerIcon absolute />
                </>
              }>
              <Dropdown.Menu>
                {!isSignup && (
                  <>
                    <Link to={Urls.profilePage(viewer.username ?? '')}>
                      <Dropdown.Item>Profile</Dropdown.Item>
                    </Link>
                    <Link to={Urls.settings()}>
                      <Dropdown.Item>Settings</Dropdown.Item>
                    </Link>
                    {!isOnPrem && (
                      <Link to={Urls.subscriptions()}>
                        <Dropdown.Item>Subscriptions</Dropdown.Item>
                      </Link>
                    )}
                    {!isOnPrem && (
                      <Link to={'/create-team'}>
                        <Dropdown.Item
                          onClick={() =>
                            window.analytics?.track('Create Team Started', {
                              location: 'search nav',
                            })
                          }>
                          Create team
                        </Dropdown.Item>
                      </Link>
                    )}
                    <AutoRefreshSettings>
                      {(autoRefreshEnabled, setAutoRefreshEnabled) => (
                        <Dropdown.Item
                          onClick={() =>
                            setAutoRefreshEnabled(!autoRefreshEnabled)
                          }>
                          Auto-refresh
                          <Label
                            className={classNames('auto-refresh-label', {
                              active: autoRefreshEnabled,
                            })}
                            horizontal>
                            {autoRefreshEnabled ? 'On' : 'Off'}
                          </Label>
                        </Dropdown.Item>
                      )}
                    </AutoRefreshSettings>
                    {viewer.admin && !envIsLocal && (
                      <Dropdown.Item
                        onClick={() => {
                          setViewerUsingAdminPrivileges(
                            !viewerUsingAdminPrivileges()
                          );
                          window.location.reload();
                        }}>
                        Admin powers{' '}
                        <Label
                          className={classNames('auto-refresh-label', {
                            active: viewerUsingAdminPrivileges(),
                          })}
                          horizontal>
                          {viewerUsingAdminPrivileges() ? 'On' : 'Off'}
                        </Label>
                      </Dropdown.Item>
                    )}
                    {viewer.admin && envIsPublicCloud && (
                      // Wandb internal admin pages
                      <Dropdown.Item
                        href="/admin/home"
                        target="__blank"
                        text="Admin pages"
                      />
                    )}
                    {admin && !envIsLocal && (
                      <Dropdown.Item onClick={() => setLimitConfigOpen(true)}>
                        Simulate usage
                      </Dropdown.Item>
                    )}
                    {((viewer.admin && !envIsLocal) || viewingAs() !== '') && (
                      <ImpersonateUserModal
                        trigger={
                          <Dropdown.Item>
                            {viewingAs() !== ''
                              ? 'Impersonating ' + viewingAs()
                              : 'Impersonate'}{' '}
                            <Label
                              className={classNames('auto-refresh-label', {
                                active: viewingAs() !== '',
                              })}
                              horizontal>
                              {viewingAs() !== '' ? 'On' : 'Off'}
                            </Label>
                          </Dropdown.Item>
                        }
                        onCreate={() => history.push(`/home`)}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      />
                    )}
                    {isVm && (
                      <Dropdown.Item href="/vm-settings" text="VM Settings" />
                    )}
                    {envIsLocal && viewer.admin && (
                      <>
                        <Dropdown.Item
                          href={urlPrefixed('/system-admin', false)}
                          text="System Settings"
                        />
                        <Link to={Urls.userManagement()}>
                          <Dropdown.Item text="Users" />
                        </Link>
                        <Dropdown.Item
                          href={urlPrefixed('/admin/usage', false)}
                          text="Activity Dashboard"
                        />
                      </>
                    )}
                    <Dropdown.Divider />
                  </>
                )}

                <Dropdown.Item href={docUrl.root} text="Documentation" />
                {!isVm && !envIsLocal && (
                  <>
                    <Dropdown.Item onClick={openBeamer}>
                      What's new
                      <BeamerIcon />
                    </Dropdown.Item>
                    <TargetBlank href={Urls.communityForum()}>
                      <Dropdown.Item>Community</Dropdown.Item>
                    </TargetBlank>
                    <Link to={Urls.reportGallery()}>
                      <Dropdown.Item>Fully Connected</Dropdown.Item>
                    </Link>
                  </>
                )}
                {canLogout && (
                  <>
                    <Dropdown.Divider />
                    {isGoogleIap ? (
                      <Popup
                        content="This instance is using Google IAP for authentication, so you'll need to log out of your Google account to log out of wandb."
                        position="left center"
                        trigger={
                          <Dropdown.Item
                            className="disabled-log-out-dropdown-item"
                            disabled
                            text="Log out"
                          />
                        }
                      />
                    ) : (
                      <Dropdown.Item
                        onClick={() => auth.logout()}
                        text="Log out"
                      />
                    )}
                  </>
                )}
                {betaVersion && <BetaVersionDropdownItem />}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div className="logged-out-menu">
              <Dropdown
                direction="left"
                text={'Help'}
                className="help-dropdown">
                <Dropdown.Menu>
                  <Dropdown.Item href={'/site'} text="Company website" />
                  <Dropdown.Divider />
                  <Dropdown.Item href={docUrl.root} text="Documentation" />
                  {!isVm && !envIsLocal && (
                    <>
                      <Dropdown.Item
                        as={TargetBlank}
                        href={Urls.communityForum()}
                        text="Community"
                      />
                      <Dropdown.Item
                        as={Link}
                        to={Urls.reportGallery()}
                        text="Fully Connected"
                      />
                      <Dropdown.Divider />
                      <Dropdown.Item
                        href={Urls.contactSalesDemo()}
                        text="Request a demo"
                      />
                      <Dropdown.Item
                        href="/site/pricing"
                        text="Private teams"
                      />
                    </>
                  )}
                  {betaVersion && <BetaVersionDropdownItem />}
                </Dropdown.Menu>
              </Dropdown>
              <LoginButton />
            </div>
          )}
        </div>
        {limitConfigOpen && (
          <UsageSimulationModal onClose={() => setLimitConfigOpen(false)} />
        )}
      </>
    );
  }
);

export default ProfileDropdownMenu;

type BetaVersionDropdownItemProps = {};

const BetaVersionDropdownItem: React.FC<BetaVersionDropdownItemProps> =
  React.memo(() => {
    const [betaVersionModalOpen, setBetaVersionModalOpen] = useState(false);
    const location = useLocation();
    const betaVersionLink = `https://beta.wandb.ai${location.pathname}?betaVersion=${betaVersion}`;

    return (
      <>
        <Dropdown.Divider />
        <BVDropdownItem onClick={() => setBetaVersionModalOpen(true)}>
          Beta v. {betaVersion}
          <CopyableText
            text={''}
            copyText={betaVersionLink}
            toastText="Copied beta link to clipboard."
            icon="link"
          />
        </BVDropdownItem>
        {betaVersionModalOpen && (
          <BetaVersionModal onClose={() => setBetaVersionModalOpen(false)} />
        )}
      </>
    );
  });

const BVDropdownItem = styled(Dropdown.Item)`
  &&&&& {
    color: ${globals.gold};
    .copyable-text {
      margin-left: 8px;
      padding-left: 4px;
      i.icon.copy-icon {
        opacity: 1;
        font-size: 1em;
      }
    }
  }
`;
