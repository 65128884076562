import React from 'react';

import {RESULTS_LIMIT} from './constants';
import {LocalSearchResultItem} from './LocalSearchResultItem';
import {useSearchContext} from './SearchContext';

export interface EntityResultsListProps {
  blurAndClear: () => void;
  setShowAllEntityResults: (showAll: boolean) => void;
  showAllEntityResults: boolean;
}

export const EntityResultsList = ({
  blurAndClear,
  setShowAllEntityResults,
  showAllEntityResults,
}: EntityResultsListProps) => {
  const {
    currentQuery,
    displayedResults,
    hiddenResultsCount,
    results,
    selectedIndex,
  } = useSearchContext();

  return (
    <>
      {displayedResults.entity.map((result, index) => (
        <LocalSearchResultItem
          key={result.name + '|' + result.meta}
          onClick={blurAndClear}
          result={result}
          query={currentQuery}
          selected={selectedIndex === index}
        />
      ))}
      {!showAllEntityResults && results.entity.length > RESULTS_LIMIT && (
        <div
          className="show-more"
          onClick={() => {
            setShowAllEntityResults(true);
          }}>
          {hiddenResultsCount.entity} more result
          {hiddenResultsCount.entity === 1 ? '' : 's'} in this entity ➞
        </div>
      )}
    </>
  );
};
