import {Analytics} from '../../services/analytics/Analytics';

export function trackPageViewed(page: string, tab?: string) {
  window.analytics?.page(page, {tab});
}

export function trackBreadcrumbClicked(destination: string) {
  Analytics.track('Top nav breadcrumb clicked', {destination});
}

export function trackProjectTabClicked(opts: {[key: string]: any}) {
  Analytics.track('Project tab clicked', opts);
}

export function trackModelRegistryClicked(location: string) {
  Analytics.track('Model registry clicked', {
    location,
  });
}

export function trackLaunchClicked(location: string) {
  Analytics.track('Launch clicked', {
    location,
  });
}

export function trackCreatePortfolioButtonClicked(location: string) {
  Analytics.track('Create Portfolio button clicked', {
    location,
  });
}

export function trackModelRegistryLimitsReached(limitType: string) {
  Analytics.track('Model Registry Limits Reached in App', {limitType});
}

export function trackArtifactInteraction(opts: {[key: string]: any}) {
  Analytics.track('Artifact interaction', opts);
}

export function trackArtifactLineageTabInteraction(dagView: string) {
  Analytics.track('Artifact lineage tab view selected', {
    dagView,
  });
}
