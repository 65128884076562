import React, {memo, ReactElement} from 'react';

import {DEFAULT_EXPERIMENT_DATA} from './types';
import {useExperiment} from './utils';
import {
  getControlBucketValue,
  validateChildrenAndStoreBuckets,
} from './validation';
import * as S from './VariantWrapper.styles';

type VariantWrapperProps = {
  graphqlExperimentId: string; // This is the graphql id which must match an experiment with a "Not Started" status
  observationalUnitId: string; // This value must correspond to the experiment's observational unit
  loader?: ReactElement; // Custom loader to overlay if experiments query is not finished
  children: any; // Only Variant components can be descendants of this wrapper component
};

const VariantWrapperComp = ({
  graphqlExperimentId,
  observationalUnitId,
  loader,
  children,
}: VariantWrapperProps) => {
  // Throw errors if the variant components are misconfigured
  const bucketValues = validateChildrenAndStoreBuckets(
    graphqlExperimentId,
    children
  );

  const controlBucketValue = getControlBucketValue(
    graphqlExperimentId,
    children
  );

  const {isLoading, error, activeExperiment, experimentData} = useExperiment(
    graphqlExperimentId,
    observationalUnitId,
    controlBucketValue,
    bucketValues
  );

  // Show loader when experiments query is still being fetched
  if (isLoading) {
    return (
      loader ?? <S.VariantLoader name="experiment-variant-loader" active />
    );
  }

  // Experiment not found or query has errors
  if (error || activeExperiment == null) {
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        experimentData: {
          ...DEFAULT_EXPERIMENT_DATA,
          treatment: controlBucketValue,
        },
      })
    );
  }

  // Inject experiment data into each child Variant component
  return React.Children.map(children, child =>
    React.cloneElement(child, {experimentData})
  );
};

export const VariantWrapper = memo(VariantWrapperComp);
