/**
 * An icon button that takes the user to view a URL.
 * Icon displayed will update to one representing a known destination.
 */

import React from 'react';
import {Popup} from 'semantic-ui-react';

import * as S from './UrlButton.styles';

interface UrlButtonProps {
  url: string;
}

const UrlButton: React.FC<UrlButtonProps> = ({url}) => {
  let isValid = false;
  let isGitHub = false;
  try {
    const parsedUrl = new URL(url);
    isValid = true;
    isGitHub = parsedUrl.hostname === 'github.com';
  } catch (e) {
    // Could not parse entered text as a URL, but let user continue anyway.
  }
  const icon = isGitHub ? 'github' : 'world';
  return (
    <Popup
      size="mini"
      position="top right"
      trigger={
        <S.Button
          name={icon}
          disabled={!isValid}
          onClick={() => {
            window.open(url, '_blank'); // eslint-disable-line wandb/no-unprefixed-urls
          }}
        />
      }>
      Open URL
    </Popup>
  );
};

export default UrlButton;
