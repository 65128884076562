import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React from 'react';
import {Input} from 'semantic-ui-react';

export interface SearchInputProps {
  blur: () => void;
  clearQuery: () => void;
  currentQuery: string;
  inputRef: React.MutableRefObject<Input | null>;
  // change to React.KeyboardEvent<HTMLInputElement>
  onKeyDown: (event: any) => void;
  setCurrentQuery: React.Dispatch<React.SetStateAction<string>>;
  setShowAllEntityResults: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAllContributedResults: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAllRemainingResults: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SearchInput = ({
  blur,
  clearQuery,
  currentQuery,
  inputRef,
  onKeyDown,
  setCurrentQuery,
  setShowAllEntityResults,
  setShowAllContributedResults,
  setShowAllRemainingResults,
}: SearchInputProps) => (
  <>
    <Input
      autoFocus
      icon={<LegacyWBIcon name="search" />}
      ref={inputRef}
      iconPosition="left"
      className="local-search-input"
      placeholder={'Find project or team'}
      value={currentQuery}
      onChange={e => {
        setCurrentQuery(e.currentTarget.value);
        setShowAllEntityResults(false);
        setShowAllContributedResults(false);
        setShowAllRemainingResults(false);
      }}
      onKeyDown={onKeyDown}
    />
    {currentQuery && (
      <LegacyWBIcon
        name="close"
        className="close-button"
        onMouseDown={(e: any) => e.preventDefault()}
        onClick={clearQuery}
      />
    )}
    <div
      className="cancel-button"
      onMouseDown={(e: any) => e.preventDefault()}
      onClick={blur}>
      Cancel
    </div>
  </>
);
