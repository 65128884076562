/* tslint:disable:no-bitwise */
export function countOnesUint8(n: number): number {
  if (!Number.isInteger(n) || n < 0 || n > 255) {
    throw new Error(`Not a uint8: ${n}`);
  }
  // This is used in a loop in keyBitmapToKeys, which is HOT CODE
  // so this is SUPERHOT CODE

  // Unrolled and optimized form of:
  // let count = 0;
  // if (n & 1 === 1) {
  //   count++
  // }
  // if (n & 2 === 2) {
  //   count++
  // }
  // ...etc...

  // x & (1 << n) "filters" the unsigned integer x to the nth bit
  // then we divide by that same value (performantly, via right bitshift)
  // to get 0 or 1 for each bit!
  return (
    (n & 1) +
    ((n & 2) >> 1) +
    ((n & 4) >> 2) +
    ((n & 8) >> 3) +
    ((n & 16) >> 4) +
    ((n & 32) >> 5) +
    ((n & 64) >> 6) +
    ((n & 128) >> 7)
  );
}
/* tslint:enable:no-bitwise */
