import React, {useMemo} from 'react';
import {Icon} from 'semantic-ui-react';

import {Sort} from '../../state/views/sort/types';
import * as Run from '../../util/runs';

export type WBTableSortIndicatorComponent = React.ComponentType<{
  columnKeyString: string;
}>;

interface WBTableSortIndicatorProps {
  sort: Sort;
  columnKeyString: string;
}

export const WBTableSortIndicator = ({
  sort,
  columnKeyString,
}: WBTableSortIndicatorProps) => {
  const sortKeyIdx = sort.keys.findIndex(
    ({key}) => Run.keyToString(key) === columnKeyString
  );
  const style = useMemo(() => ({opacity: 1 / (sortKeyIdx + 1)}), [sortKeyIdx]);
  if (sortKeyIdx === -1) {
    return null;
  }
  const {ascending} = sort.keys[sortKeyIdx];
  return (
    <Icon
      className={`triangle ${ascending ? 'up' : 'down'}`}
      style={style}
      size="small"
    />
  );
};
