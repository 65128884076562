import React from 'react';

import {ContributedResultsList} from './ContributedResultsList';
import {RemainingResultsList} from './RemainResultsList';
import {useSearchContext} from './SearchContext';

export interface HomePageResultsListProps {
  blurAndClear: () => void;

  setShowAllContributedResults: (value: boolean) => void;
  setShowAllRemainingResults: (value: boolean) => void;
  showAllContributedResults: boolean;
  showAllRemainingResults: boolean;
}
export const HomePageResultsLists = ({
  blurAndClear,

  setShowAllContributedResults,
  setShowAllRemainingResults,
  showAllContributedResults,
  showAllRemainingResults,
}: HomePageResultsListProps) => {
  const {displayedResults} = useSearchContext();

  const hasResults =
    displayedResults.contributed.length + displayedResults.remaining.length > 0;

  if (!hasResults) {
    return <div className="no-search-results">No results</div>;
  }

  return (
    <>
      {displayedResults.contributed.length > 0 && (
        <>
          <div className="search-result-header">Contributed to</div>
          <ContributedResultsList
            blurAndClear={blurAndClear}
            setShowAllContributedResults={setShowAllContributedResults}
            showAllContributedResults={showAllContributedResults}
          />
        </>
      )}
      {displayedResults.remaining.length > 0 && (
        <>
          <div className="search-result-header">From your teams</div>
          <RemainingResultsList
            blurAndClear={blurAndClear}
            setShowAllRemainingResults={setShowAllRemainingResults}
            showAllRemainingResults={showAllRemainingResults}
          />
        </>
      )}
    </>
  );
};
