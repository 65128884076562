import {envIsCloudOnprem, envIsLocal} from '@wandb/common/config';
import makeComp from '@wandb/common/util/profiler';
import queryString from 'query-string';
import React, {FC, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Checkbox, Divider, Image, List} from 'semantic-ui-react';
import styled from 'styled-components';

import {
  OrganizationSubscriptionType,
  useOrganizationSubscriptionsQuery,
  useUpdateUserMutation,
} from '../../generated/graphql';
import {getAuthRedirect} from '../../util/auth';
import {navigateTo} from '../../util/history';
import {teamPage} from '../../util/urls';
import {Banner} from '../Banner';
import {CreateTeamButton} from '../CreateTeamModal';
import {Icon} from '../Icon';
import {ViewTeamsModal} from '../ViewTeamsModal';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

const ViewTeamsButton = styled.span`
  cursor: pointer;
`;

export const UserSettingsTeams: FC = makeComp(
  () => {
    const {viewer, refetch} = useUserSettingsContext();

    const authRedirect = getAuthRedirect();
    const {query} = queryString.parseUrl(authRedirect);
    const redirectFromAddEmail = query.addEmail === `true`;

    const mostRecentEmail = viewer?.emails[viewer?.emails.length - 1];
    const isMostRecentEmailCorporate =
      mostRecentEmail?.type === 'CORPORATE' && mostRecentEmail.verified;

    const verifiedCorporateEmails = viewer?.emails.filter(
      email => email.type === 'CORPORATE' && email.verified
    );

    const showJoinTeamsBanner = verifiedCorporateEmails?.length;

    const justAddedVerifiedCorporateEmail =
      redirectFromAddEmail && isMostRecentEmailCorporate;

    if (justAddedVerifiedCorporateEmail) {
      window.analytics?.track('Added work email', {
        entityName: viewer?.name,
        location: 'Settings Page',
      });
    }
    // Open modal immediately if email just added is corporate and verified
    const [isViewTeamsModalOpen, setIsViewTeamsModalOpen] = useState<boolean>(
      justAddedVerifiedCorporateEmail
    );

    const subscriptions = useOrganizationSubscriptionsQuery();
    const [updateUser] = useUpdateUserMutation();

    const trialSub = useMemo(
      () =>
        subscriptions.data?.viewer?.organizations?.find(org =>
          org.subscriptions?.some(
            sub =>
              sub.subscriptionType === OrganizationSubscriptionType.UserLedTrial
          )
        ),
      [subscriptions.data]
    );

    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }

    const trialOrgID = trialSub?.id;
    let trialRemainingText = '';
    if (trialSub?.subscriptions[0].expiresAt) {
      const trialRemaining =
        new Date(trialSub.subscriptions[0].expiresAt + 'Z').getTime() -
        Date.now();
      const trialRemainingDays = Math.ceil(trialRemaining / (1000 * 3600 * 24));
      if (trialRemainingDays === 0) {
        trialRemainingText = ' - Your trial expires today.';
      } else if (trialRemainingDays < -1) {
        trialRemainingText =
          ' - ' + trialRemainingDays * -1 + ' days overdue in trial';
      } else if (trialRemainingDays >= 2) {
        trialRemainingText =
          ' - ' + trialRemainingDays + ' days remaining in trial';
      } else if (trialRemainingDays === 1) {
        trialRemainingText =
          ' - ' + trialRemainingDays + ' day remaining in trial';
      } else if (trialRemainingDays === -1) {
        trialRemainingText =
          ' - ' + trialRemainingDays * -1 + ' day overdue in trial';
      }
    }

    return (
      <>
        {showJoinTeamsBanner && (
          <Banner
            variant="announcement"
            Icon={<Icon name="share-with" />}
            boldText="We found teams for you to join!"
            Actions={
              <ViewTeamsButton onClick={() => setIsViewTeamsModalOpen(true)}>
                View teams
              </ViewTeamsButton>
            }
          />
        )}
        {isViewTeamsModalOpen && (
          <ViewTeamsModal
            setIsModalOpen={setIsViewTeamsModalOpen}
            viewerEmail={
              verifiedCorporateEmails && verifiedCorporateEmails[0].emailAddress
            }
          />
        )}
        <List>
          {viewer.teams.edges.map((t, index) => {
            const team = t.node;
            return (
              <React.Fragment key={t.node.id}>
                <List.Item className="team-listitem">
                  <Image avatar src={team.photoUrl} />
                  <Link to={teamPage(team.name)}>
                    {team.name}{' '}
                    {team.organizationId === trialOrgID &&
                      trialOrgID != null &&
                      trialRemainingText}
                  </Link>
                </List.Item>
                {index !== viewer.teams.edges.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
        {envIsLocal || envIsCloudOnprem ? (
          <CreateTeamButton onCreate={() => refetch?.()} />
        ) : (
          <Button
            size="tiny"
            onClick={() => {
              navigateTo({pathname: '/create-team'});
              window.analytics?.track('Create Team Started', {
                location: 'settings page',
              });
            }}>
            New team
          </Button>
        )}
        <Divider style={{marginTop: 20}} />
        <div className="defaults-wrapper">
          Hide teams in public profile
          <Checkbox
            style={{marginLeft: 'auto'}}
            toggle
            checked={viewer.hideTeamsFromPublic}
            onChange={(e, {checked}) =>
              updateUser({
                variables: {
                  hideTeamsFromPublic: checked,
                },
              })
            }
          />
        </div>
      </>
    );
  },
  {id: 'UserSettingsTeams', memo: true}
);
