import {TargetBlank} from '@wandb/common/util/links';
import React, {FC, memo, useContext, useEffect, useState} from 'react';

import {StandardPlanCheckoutModal} from '../pages/Billing/Checkout/StandardPlanCheckoutModal';
import {
  TeamOrgQueryResult,
  useCurrentTeamOrg,
  useShowNudgeBar,
} from '../util/nudgeBars';
import {CONTACT_SALES_CLICKED_ANALYTICS, Org} from '../util/pricing';
import {maybePluralizeWord} from '../util/uihelpers';
import * as urls from '../util/urls';
import * as S from './ExpiringSubscriptionNudgeBar.styles';
import {
  GlobalNudgeBarContext,
  GlobalNudgeBarState,
  GlobalNudgeBarUpdaterContext,
  NudgeBarType,
} from './GlobalNudgeBarContextProvider';
import {
  NudgeBarLarge,
  NudgeBarLargeActionButton,
  NudgeBarLargeActionLink,
  NudgeBarLargeText,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

const ExpiringSubscriptionNudgeBarComp: FC = () => {
  const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);

  const {states} = useContext(GlobalNudgeBarContext);
  const {refetch} = useContext(GlobalNudgeBarUpdaterContext);

  const currentTeamOrg: TeamOrgQueryResult | null = useCurrentTeamOrg();
  const showNudgeBar = useShowNudgeBar();

  // no state means that this viewer isn't in any organization that should be nudged or enforced
  if (states == null || states.length === 0) {
    return null;
  }

  // on /usage or /subscriptions page, we don't show nudge bar
  if (!showNudgeBar) {
    return null;
  }

  const currentOrganizationStates = states?.filter(
    state =>
      state.organization?.name === currentTeamOrg?.entity?.organization?.name
  );

  // organizations without state are assumed to be in good standing and thus don't need a nudge bar
  if (currentOrganizationStates == null) {
    return null;
  }

  const expiringState = currentOrganizationStates?.find(
    state =>
      state.renderedNudgeBar ===
        NudgeBarType.UpgradeNudgeBarForExpiringSubscription ||
      state.renderedNudgeBar ===
        NudgeBarType.ContactUsNudgeBarForExpiringSubscription
  );

  // this organization's subscription is not expiring and thus don't need a nudge bar
  if (expiringState == null) {
    return null;
  }

  // if the organization's subscription is expiring, show a warning nudge bar
  // to upgrade on their own or reach out to us to keep using W&B
  const title = (
    <NudgeBarLargeTitle data-test="nudge-bar-title">
      Your organization's trial subscription is about to expire.
    </NudgeBarLargeTitle>
  );

  const onUpgrade = () => {
    window.analytics?.track('Upgrade Subscription Clicked', {
      location: 'expiring subscription nudge bar',
    });
    setOpenCheckoutModal(true);
  };

  return (
    <>
      <NudgeBarLarge data-test="expiring-nudge-bar">
        {title}
        <ExpiringNudgeBarText expiringState={expiringState} />
        <ExpiringNudgeBarAction
          expiringState={expiringState}
          onUpgrade={onUpgrade}
        />
      </NudgeBarLarge>

      {openCheckoutModal && (
        <StandardPlanCheckoutModal
          data-test="upgrade-checkout-modal"
          org={expiringState.organization as Org}
          onTransactionCompleted={refetch}
          onClose={() => setOpenCheckoutModal(false)}
        />
      )}
    </>
  );
};

function onContactSalesClick(orgID?: string) {
  window.analytics?.track(CONTACT_SALES_CLICKED_ANALYTICS, {
    location: 'expiring subscription nudge bar',
    org: orgID,
  });
}

type ExpiringNudgeBarTextProps = {
  expiringState: GlobalNudgeBarState;
};

// helper components for the nudge bar content
const ExpiringNudgeBarText: FC<ExpiringNudgeBarTextProps> = ({
  expiringState,
}) => {
  const showsUpgradeText =
    expiringState.renderedNudgeBar ===
    NudgeBarType.UpgradeNudgeBarForExpiringSubscription;

  // we use this component to trigger an analytics for nudge bar
  // to avoid sending analytics at the top of parent component ExpiringSubscriptionNudgeBar
  useEffect(() => {
    if (expiringState == null) {
      return;
    }

    if (showsUpgradeText) {
      window.analytics?.track('Upgrade Subscription Banner Displayed', {
        context: expiringState.renderedNudgeBar,
        location: 'expiring subscription nudge bar',
        org: expiringState.organization?.id,
        orgName: expiringState.organization?.name,
      });
    }

    // renderedNudgBar is NudgeBarType.ContactUsNudgeBarForExpiringSubscription
    window.analytics?.track('Contact Us Banner Displayed', {
      context: expiringState.renderedNudgeBar,
      location: 'expiring subscription nudge bar',
      org: expiringState.organization?.id,
      orgName: expiringState.organization?.name,
    });
    // disabling the lint check since we only want to
    // send analytics once when this components first mounts
    // eslint-disable-next-line
  }, []);

  if (expiringState == null) {
    return null;
  }

  if (showsUpgradeText) {
    return (
      <NudgeBarLargeText data-test="nudge-bar-upgrade-text">
        Your {expiringState.organization?.name} team will expire in{' '}
        {expiringState.daysUntilEnforcement}{' '}
        {maybePluralizeWord(expiringState.daysUntilEnforcement ?? 0, 'day')}.
        Please upgrade your subscription below or{' '}
        <S.ContactSalesLink
          href={urls.contactSalesPricing()}
          onClick={() => onContactSalesClick(expiringState.organization?.name)}>
          contact us
        </S.ContactSalesLink>{' '}
        to continue using W&B without interruption.
      </NudgeBarLargeText>
    );
  }

  // renderedNudgBar is NudgeBarType.ContactUsNudgeBarForExpiringSubscription
  return (
    <NudgeBarLargeText data-test="nudge-bar-contact-us-text">
      Your {expiringState.organization?.name} team subscription will expire in{' '}
      {expiringState.daysUntilEnforcement}{' '}
      {maybePluralizeWord(expiringState.daysUntilEnforcement ?? 0, 'day')}.
      Please contact us to upgrade your subscription and continue using W&B
      without interruption.
    </NudgeBarLargeText>
  );
};

type ExpiringNudgeBarActionProps = {
  expiringState: GlobalNudgeBarState;
  onUpgrade: () => void;
};

const ExpiringNudgeBarAction: FC<ExpiringNudgeBarActionProps> = ({
  expiringState,
  onUpgrade,
}) => {
  if (expiringState == null) {
    return null;
  }

  if (
    expiringState.renderedNudgeBar ===
    NudgeBarType.UpgradeNudgeBarForExpiringSubscription
  ) {
    return (
      <NudgeBarLargeActionLink
        onClick={onUpgrade}
        data-test="nudge-bar-upgrade-button">
        <NudgeBarLargeActionButton>Upgrade</NudgeBarLargeActionButton>
      </NudgeBarLargeActionLink>
    );
  }

  // renderedNudgBar is NudgeBarType.ContactUsNudgeBarForExpiringSubscription
  return (
    <TargetBlank
      href={urls.contactSalesPricing()}
      onClick={() => onContactSalesClick(expiringState.organization?.name)}>
      <NudgeBarLargeActionLink data-test="nudge-bar-contact-us-button">
        <NudgeBarLargeActionButton>Contact us</NudgeBarLargeActionButton>
      </NudgeBarLargeActionLink>
    </TargetBlank>
  );
};

export const ExpiringSubscriptionNudgeBar = memo(
  ExpiringSubscriptionNudgeBarComp
);
