import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

import {TargetBlank} from '../../../common/src/util/links';

export const SearchNav = styled.div`
  position: relative;
`;

export const ExperimentalTestBar = styled.div`
  position: absolute;
  top: 0;
  left: 86px;
  right: 146px;
  z-index: 1;
  min-height: 1px;
  background-color: ${globals.gray900};
`;

export const OpenPageLink = styled(TargetBlank)`
  align-items: center;
  display: flex;
  cursor: pointer;
  color: ${globals.gray800} !important;
  height: 100%;

  &&&:hover {
    color: ${globals.primaryText} !important;
  }
`;
