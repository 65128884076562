import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {ImageExportType} from '@wandb/common/util/panelExport';
import React, {FC, forwardRef, useContext, useState} from 'react';
import {Menu, Popup} from 'semantic-ui-react';

import {PanelBankUpdaterContext} from '../../state/panelbank/context';
import {RunQueryContext} from '../../state/runs/context';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelBankSectionConfigActions from '../../state/views/panelBankSectionConfig/actions';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import {getPanelSpec, isExportable} from '../../util/panels';
import {DefaultMovePanelOption, PanelMoverMenu} from '../PanelMoverMenu';
import {PanelContext} from './PanelContextProvider';
import {PanelExportMenu} from './PanelExportMenu';

const POPUP_WIDTH = 148;
const PANEL_MOVER_LEFT_OFFSET = -82;

export enum ExportType {
  API,
  CSV,
  Image,
}

enum Submenu {
  MOVE_PANEL,
  EXPORT_PANEL,
}

interface PanelActionsOverflowProps {
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  panelSpec: ReturnType<typeof getPanelSpec>;
  setExportType: (exportType: ExportType) => void;
  setImageExportType: (imageType: ImageExportType) => void;
  sharePanel(analyticsOrigin: string): void;
}

export const PanelActionsMenu: FC<PanelActionsOverflowProps> = forwardRef<
  HTMLElement,
  PanelActionsOverflowProps
>(
  (
    {
      defaultMoveToSectionRef,
      panelSpec,
      setExportType,
      setImageExportType,
      sharePanel,
    },
    ref: React.Ref<HTMLElement>
  ) => {
    const [activeSubmenu, setActiveSubmenu] = useState<Submenu>();
    const [isOpen, setIsOpen] = useState(false);

    const runsContext = useContext(RunQueryContext);
    const {setExportingPanelRefID} = useContext(PanelBankUpdaterContext);
    const {panelBankSectionConfigRef, panelBankConfigRef, panelRef} =
      useContext(PanelContext);

    const duplicatePanel = ViewHooks.useViewAction(
      panelBankSectionConfigRef,
      PanelBankSectionConfigActions.duplicatePanel
    );

    const deletePanel = ViewHooks.useViewAction(
      panelBankSectionConfigRef,
      PanelBankSectionConfigActions.deletePanel
    );
    const onRemovePanel = () => deletePanel(panelRef, panelBankConfigRef);

    const handleOpen = () => setIsOpen(true);

    const handleClose = () => {
      setActiveSubmenu(undefined);
      setIsOpen(false);
    };

    return (
      <Popup
        content="More actions"
        inverted
        size="mini"
        position="top center"
        trigger={
          <div>
            <Popup
              className="popup-menu"
              basic
              position="bottom right"
              on="click"
              onOpen={handleOpen}
              open={isOpen}
              onClose={handleClose}
              style={{
                width: POPUP_WIDTH,
                top: -8,
                left:
                  activeSubmenu === Submenu.MOVE_PANEL
                    ? PANEL_MOVER_LEFT_OFFSET
                    : undefined,
              }}
              // offset="0, -16px"
              // offset={'-320px, -16px'}
              trigger={
                <LegacyWBIcon
                  style={{cursor: 'pointer'}}
                  name="overflow"
                  onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                />
              }
              content={
                <>
                  {activeSubmenu == null && (
                    <Menu vertical borderless style={{width: POPUP_WIDTH}}>
                      <Menu.Item
                        className="panel-action-add-to-report"
                        onClick={() => {
                          window.analytics?.track('Add to Report Clicked', {
                            location: 'panel menu',
                          });
                          setExportingPanelRefID(panelRef.id);
                          handleClose();
                        }}>
                        <LegacyWBIcon name="report" />
                        Add to report
                      </Menu.Item>
                      <Menu.Item
                        className="panel-action-share"
                        onClick={() => {
                          sharePanel('panel menu');
                          handleClose();
                        }}>
                        <LegacyWBIcon name="share" />
                        Share panel
                      </Menu.Item>
                      <Menu.Item
                        className="panel-action-duplicate"
                        onClick={() => {
                          duplicatePanel(panelRef);
                          handleClose();
                        }}>
                        <LegacyWBIcon name="copy" />
                        Duplicate panel
                      </Menu.Item>
                      {runsContext.report == null && (
                        <>
                          {defaultMoveToSectionRef &&
                            panelBankConfigRef &&
                            panelBankSectionConfigRef !==
                              defaultMoveToSectionRef && (
                              <DefaultMovePanelOption
                                panelBankConfigRef={panelBankConfigRef}
                                toSectionRef={defaultMoveToSectionRef}
                              />
                            )}
                          <Menu.Item
                            onClick={() =>
                              setActiveSubmenu(Submenu.MOVE_PANEL)
                            }>
                            <LegacyWBIcon name="move" />
                            Move panel...
                          </Menu.Item>
                        </>
                      )}
                      {isExportable(panelSpec) && (
                        <Menu.Item
                          onClick={() =>
                            setActiveSubmenu(Submenu.EXPORT_PANEL)
                          }>
                          <LegacyWBIcon name="download" />
                          Export panel...
                        </Menu.Item>
                      )}
                      <Menu.Item
                        className="panel-action-delete"
                        onClick={() => {
                          onRemovePanel();
                          handleClose();
                        }}>
                        <LegacyWBIcon name="delete" />
                        Delete panel
                      </Menu.Item>
                    </Menu>
                  )}
                  {activeSubmenu === Submenu.MOVE_PANEL &&
                    panelBankConfigRef &&
                    panelBankSectionConfigRef && (
                      // The "Move panel to {default section}" menu option
                      <PanelMoverMenu
                        panelBankConfigRef={panelBankConfigRef}
                        panelBankSectionConfigRef={panelBankSectionConfigRef}
                      />
                    )}
                  {activeSubmenu === Submenu.EXPORT_PANEL && (
                    <PanelExportMenu
                      panelSpec={panelSpec}
                      handleClose={handleClose}
                      setExportType={setExportType}
                      setImageExportType={setImageExportType}
                    />
                  )}
                </>
              }
            />
          </div>
        }
      />
    );
  }
);
