import _ from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';

import {useSelector} from '../../state/hooks';
import {getReportPart, getReportRef} from '../../state/reports/selectors';
import * as ReportTypes from '../../state/reports/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import {setBlocks} from '../../state/views/report/actions';
import {WBSlate, WBSlateElementType} from './WBSlate';

interface WBSlateReduxBridgeContextValue {
  entityName: string;
  projectName: string;
  viewId: string;
  viewRef: ReportTypes.ReportViewRef | null;
  panelSettingsRef: PanelSettingsTypes.Ref | null;
}

export const WBSlateReduxBridgeContext =
  React.createContext<WBSlateReduxBridgeContextValue>({} as any);

export type WBSlateReduxBridgeDebouncedProps = {
  projectName: string;
  entityName: string;
  viewId: string;
  viewRef: ReportTypes.ReportViewRef;
  readOnly: boolean;
};

const WBSlateReduxBridgeDebounced: React.FC<WBSlateReduxBridgeDebouncedProps> =
  React.memo(({viewRef, projectName, entityName, viewId, readOnly}) => {
    const dispatch = useDispatch();

    const viewPartRef = useSelector(state => getReportRef(viewRef)(state));
    const viewPart = useSelector(state => getReportPart(viewRef)(state));
    const {blocks, panelSettingsRef, width} = viewPart;

    const reduxValue = React.useMemo(
      () => ({
        entityName,
        projectName,
        viewId,
        panelSettingsRef,
        viewRef,
      }),
      [entityName, projectName, viewId, panelSettingsRef, viewRef]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateRedux = React.useCallback(
      _.debounce((newValue: WBSlateElementType[]) => {
        dispatch(setBlocks(viewPartRef, newValue));
      }, 1000),
      [dispatch, viewPartRef]
    );

    const [value, setValue] = React.useState<WBSlateElementType[]>(blocks);
    const onChange = React.useCallback(
      (newValue: WBSlateElementType[]) => {
        // For some reason, this is fired whenever the selection changes.
        // Only update when the actual content changes.
        if (newValue !== value) {
          setValue(newValue);
          updateRedux(newValue);
        }
      },
      [value, updateRedux]
    );

    return (
      <WBSlateReduxBridgeContext.Provider value={reduxValue}>
        <WBSlate
          readOnly={readOnly}
          value={value}
          widthMode={width}
          viewRef={viewRef}
          viewID={viewId}
          onChange={onChange}
        />
      </WBSlateReduxBridgeContext.Provider>
    );
  });

export default WBSlateReduxBridgeDebounced;

export type WBSlateReduxBridgeDisabledProps = Pick<
  WBSlateReduxBridgeDebouncedProps,
  `entityName` | `projectName` | `viewId` | `readOnly`
> & {
  value: WBSlateElementType[];
  onChange: (newValue: WBSlateElementType[]) => void;
};

export const WBSlateReduxBridgeDisabled: React.FC<WBSlateReduxBridgeDisabledProps> =
  React.memo(({projectName, entityName, viewId, readOnly, value, onChange}) => {
    const reduxValue = React.useMemo(
      () => ({
        entityName,
        projectName,
        viewId,
        panelSettingsRef: null,
        viewRef: null,
      }),
      [entityName, projectName, viewId]
    );

    return (
      <WBSlateReduxBridgeContext.Provider value={reduxValue}>
        <WBSlate
          readOnly={readOnly}
          value={value}
          widthMode={`profile`}
          viewID={viewId}
          viewRef={null}
          onChange={onChange}
        />
      </WBSlateReduxBridgeContext.Provider>
    );
  });
