import * as Track from '../analytics';

const isPerformanceResource = (
  resource: PerformanceEntry
): resource is PerformanceResourceTiming => 'initiatorType' in resource;

export const getPixelRequests = () =>
  performance
    .getEntriesByType('resource')
    .filter(isPerformanceResource)
    .filter(
      r => r.initiatorType === 'img' && r.name.includes('pendo.io/data/ptm.gif')
    );

/**
 *
 */
async function scanForResource(counter: number): Promise<string> {
  const pendoRequests = getPixelRequests();
  const trackingPixelIsFound = pendoRequests.length > 0;
  if (trackingPixelIsFound) {
    return pendoRequests[0].name;
  }

  if (counter < 40) {
    // page loading can be slow on beta, so this might be overkill. It exits early though.
    await new Promise(resolve => setTimeout(resolve, 250));
    return scanForResource(counter + 1);
  }

  return '';
}

/**
 * The problem here is to detect when a user is accessing wandb behind an ad-blocker. These ad-blockers limit the ability of third-party tools (like Pendo) from storing some information about the user that we need for important tasks like segmenting users into a bucket that is eligible for an NPS survey. In the long run we'll serve Pendo from behind a new CNAME which should protect it from aggressive blocking.
 *
 * In the meantime we need a workaround. There are a variety of proxy methods to detect the presence of an ad-blocker, but because we're mostly concerned with Pendo this method seems to be a fairly clean way to directly test its availability. It works because Pendo injects a tracking pixel into the page. Ad-blockers will block this pixel, so the outcome of that resource request should give us the status. The URL for the pixel is dynamically constructed inside the Pendo script (so we don't have access to it), but we can retrieve it via the browser's performance history. Every resource request gets entered into the performance history, so searching back through we can locate all image requests related to Pendo. Rerunning that fetch with that URL should give us the status.
 * */
export const checkPendoPixelAvailability = async () => {
  // We can't hook directly into the completion of the Pendo script because its "ready" callback fires before the request for the tracking pixel is complete. So instead we're going to inspect the performance history every couple of hundred milliseconds until we're either sure it's empty or we've found a Pendo tracking entry

  // I suspect that there are a variety of failure modes (support for the performance API, a rejected URL fetch, etc...) so the idea here is to return false for anything not on the happy path as a safeguard. As all of this is related to side-effect code running on top of the app, we want to be super safe about making sure any unexpected errors don't crash the app and ensure the worst outcome is an eligible user not being included in a survey.
  try {
    const pixelTrackerURL = await scanForResource(1);

    // if there is a URL, then we can try to refetch it. A successful request means there's no adblocker, a rejection likely means there is.
    if (pixelTrackerURL) {
      // eslint-disable-next-line wandb/no-unprefixed-urls
      await fetch(pixelTrackerURL);

      // We also log the outcome of this check to Segment in case we want to size the problem and maybe do future things like display prompts encouraging users to whitelist us.
      Track.checkIsAdBlockerActive({isActive: 'false'});
      window?.pendo?.identify({
        visitor: {
          userAdBlockingStatus: 'inactive',
        },
      });
    } else {
      // I don't know exactly what conditions this might be hit, but I don't know for sure that it _can't_ be hit, so I'm trying to track the frequency here.
      Track.checkIsAdBlockerActive({isActive: 'unknown'});
      window?.pendo?.identify({
        visitor: {
          userAdBlockingStatus: 'unknown',
        },
      });
    }
  } catch (e) {
    Track.checkIsAdBlockerActive({isActive: 'true'});
    /**
     * We're attemping to update the Pendo visitor metadata here but it's most likely that this call will fail for the same reason that the pixel tracked failed: it's blocked by the adblocker. The way Pendo seems to send this information is by encoding events into query params on the underlying pixel request URL. So it stands to reason that if we can't retrieve the tracking pixel because the browser interrupts the fetch, then Pendo can't call home using updated parameters on the pixel URL.
     *
     * There are REST API ways for Pendo to udpate this information, but for right now they're out of scope. By defaulting users to "true" on the `activeadblocker` field, we can target the NPS survey to non-ad-blocked users where a phone-home event has been successful and their data reflects a `false` value for `activeadblocker`.
     */
    window?.pendo?.identify({
      visitor: {
        userAdBlockingStatus: 'active',
      },
    });
  }
};
