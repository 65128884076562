import config from '@wandb/common/config';
import React, {FC, useCallback, useContext, useState} from 'react';
import {toast} from 'react-toastify';
import {Checkbox} from 'semantic-ui-react';

import {
  useCreateAccessTokenMutation,
  useFetchPreviewFriendlyUrlQuery,
  useRevokeAccessTokenMutation,
} from '../../generated/graphql';
import {useSelector} from '../../state/hooks';
import {getReportPart} from '../../state/reports/selectors';
import * as ViewActions from '../../state/views/actions';
import {useViewRefAction} from '../../state/views/hooks';
import {propagateErrorsContext} from '../../util/errors';
import {useIsMounted} from '../../util/hooks';
import {useReportProjects} from '../../util/report';
import {reportView} from '../../util/urls/shared';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';
import {
  createPreviewFriendlyURL,
  firstPanelExportableAsImageFromViewPart,
  isPreviewFriendlyURLOutdated,
  useMaybeSurreptitiouslyCreatePreviewFriendlyURL,
} from './util';

/**
 * This component is responsible for creating and revoking access tokens, and
 * creating the associated preview-friendly URL when applicable.
 *
 * Because the preview-friendly URL creation process takes a few seconds, we
 * attempt to surreptitiously create the preview-friendly URL in the background
 * upon mount. This is only applicable if there is not already a preview-friendly
 * URL associated with the report. If the report does not have a panel that is exportable
 * as an image, we do not attempt to create a preview-friendly URL.
 *
 * We track the state of the surreptitious creation process in the state variable
 * `isSurreptitiouslyCreatingAndRevokingMagicLink`, and whether we have
 * attempted to create a preview-friendly URL since mount in the state variable
 * `didAttemptCreatePFURLSinceMount`. The latter is used to prevent us from attempting
 * to create a preview-friendly URL more than once per mount in case the user toggles
 * the checkbox multiple times. This is set to true either during the surreptitious
 * creation process or when the user explicitly toggles the checkbox (the latter case
 * applies when a valid access token with a preview-friendly URL already exists upon mount,
 * and the user toggles the checkbox to revoke the access token, then toggles it back on -
 * in this case, since we don't know how long ago the previous PF-URL was created, we
 * recreate one to capture the latest state of the report).
 *
 * Terminology:
 * - "Access token" refers to a token that can be used (as a query parameter in a "Magic Link",
 *   see below) to access a report regardless of the viewer's permissions or logged-in state.
 * - "Magic Link" refers to a URL containing a public-read access token as a query param,
 *   making the report viewable by anyone with the link.
 * - "Preview-Friendly URL" refers to a URL that links to a placeholder page that contains
 *   the necessary meta og tags to render a preview of the report when the link is shared
 *   on social media platforms. This page simply redirects to the report view.
 */
export const MagicLinkSection: FC = () => {
  const {
    canChangeSharingSettings,
    currentAccessToken,
    viewRef,
    view,
    userIsMemberOfTeam,
  } = useContext(ShareReportTriggerContext);

  const isMounted = useIsMounted();

  const {origin, host} = window.location;
  const encodedPathname = encodeURI(
    reportView({
      entityName: view.project?.entityName ?? '',
      projectName: view.project?.name ?? '',
      reportID: view.id ?? '',
      reportName: view.displayName,
    })
  );
  const accessTokenQS =
    currentAccessToken != null
      ? `?accessToken=${currentAccessToken.token}`
      : '';

  const viewPart = useSelector(state => getReportPart(viewRef)(state));

  const [isMagicLinkAnimated, setIsMagicLinkAnimated] = useState(false);
  const animateMagicLink = useCallback(() => {
    setIsMagicLinkAnimated(false);
    setTimeout(() => {
      setIsMagicLinkAnimated(true);
    });
  }, []);

  const addAccessToken = useViewRefAction(viewRef, ViewActions.addAccessToken);

  const removeAccessToken = useViewRefAction(
    viewRef,
    ViewActions.removeAccessToken
  );

  const projectSpecifiers = useReportProjects(viewRef);
  const [createAccessToken] = useCreateAccessTokenMutation({
    variables: {
      projects: projectSpecifiers,
      viewId: view.id!,
    },
    context: propagateErrorsContext(),
  });
  const [revokeAccessToken] = useRevokeAccessTokenMutation();
  const {data: previewFriendlyURLData, refetch: refetchPreviewFriendlyURL} =
    useFetchPreviewFriendlyUrlQuery({
      variables: {
        viewID: view.id ?? '',
      },
      context: propagateErrorsContext(),
    });

  const [isCreatingAccessToken, setIsCreatingAccessToken] = useState(false);
  const [isRevokingAccessToken, setIsRevokingAccessToken] = useState(false);
  const [
    isSurreptitiouslyCreatingAndRevokingToken,
    setIsSurreptitiouslyCreatingAndRevokingToken,
  ] = useState(false);
  const [didAttemptCreatePFURLSinceMount, setDidAttemptCreatePFURLSinceMount] =
    useState(false);

  const magicLinkDisplayText = `${host}${encodedPathname}${accessTokenQS}`;
  const magicLinkURL = `${origin}${encodedPathname}${accessTokenQS}`;

  const currentPreviewFriendlyURL =
    previewFriendlyURLData?.previewFriendlyURL?.URL;

  const firstPanelExportableAsImage =
    firstPanelExportableAsImageFromViewPart(viewPart);

  const createMagicLink = useCallback(
    async (shouldSkipPFURLCreation: boolean = false) => {
      if (isCreatingAccessToken || currentAccessToken != null) {
        return currentAccessToken?.token;
      }
      window.analytics?.track?.('Report View Only Link Created', {
        reportId: view.cid,
      });
      setIsCreatingAccessToken(true);
      const result = await createAccessToken();
      const accessToken = result.data?.createAccessToken?.accessToken;
      if (accessToken == null) {
        if (isMounted()) {
          toast.error('Failed to create access token -- please try again.');
          setIsCreatingAccessToken(false);
        }
        return;
      }
      addAccessToken(accessToken);
      setIsCreatingAccessToken(false);

      // The rest of this function is concerned with creating a preview-friendly URL
      if (didAttemptCreatePFURLSinceMount || shouldSkipPFURLCreation) {
        return accessToken.token;
      }
      if (config.ENVIRONMENT_IS_PRIVATE) {
        console.log(
          'Private environment detected; skipping creation of preview-friendly URL.'
        );
        return accessToken.token;
      }
      if (firstPanelExportableAsImage == null) {
        console.warn(
          "No panels are exportable as images; can't create preview-friendly url."
        );
        return accessToken.token;
      }

      // Only create a new PFURL if the view has been updated since the current PFURL was created/updated
      const isCurrentPFURLOutdated = await isPreviewFriendlyURLOutdated({
        viewLastUpdatedAt: view.updatedAt,
        fetchPreviewFriendlyURLData: previewFriendlyURLData,
        refetchPreviewFriendlyURL,
      });
      if (!isCurrentPFURLOutdated) {
        return accessToken.token;
      }
      await createPreviewFriendlyURL({
        token: accessToken.token,
        firstPanelExportableAsImage,
        setDidAttemptCreatePFURLSinceMount,
        origin,
        encodedPathname,
        view,
        reportAuthors: viewPart.authors ?? [],
        isMounted,
        refetchPreviewFriendlyURL,
      });
      return accessToken.token;
    },
    [
      isCreatingAccessToken,
      currentAccessToken,
      view,
      createAccessToken,
      addAccessToken,
      didAttemptCreatePFURLSinceMount,
      firstPanelExportableAsImage,
      origin,
      encodedPathname,
      viewPart.authors,
      isMounted,
      refetchPreviewFriendlyURL,
      previewFriendlyURLData,
    ]
  );

  const deactivateLink = useCallback(
    async (inputToken?: string) => {
      const token = inputToken ?? currentAccessToken?.token ?? '';
      if (isRevokingAccessToken || token === '') {
        console.warn(
          "Can't revoke access token; already revoking or no token."
        );
        return;
      }
      window.analytics?.track?.('Report Deactivate View Only Link Clicked', {
        reportId: view.cid,
      });
      setIsRevokingAccessToken(true);
      try {
        const result = await revokeAccessToken({
          variables: {
            token,
          },
        });
        if (result.data?.revokeAccessToken?.success) {
          removeAccessToken(token);
        }
      } catch (e) {
        console.error(e);
      }
      setIsRevokingAccessToken(false);
    },
    [
      isRevokingAccessToken,
      currentAccessToken,
      removeAccessToken,
      revokeAccessToken,
      view.cid,
    ]
  );

  const handleToggleMagicLink = async () => {
    if (!canChangeSharingSettings) {
      return;
    }
    if (currentAccessToken != null) {
      await deactivateLink();
    } else {
      await createMagicLink();
    }
  };

  useMaybeSurreptitiouslyCreatePreviewFriendlyURL({
    firstPanelExportableAsImage,
    origin,
    encodedPathname,
    view,
    reportAuthors: viewPart.authors ?? [],
    isMounted,
    setDidAttemptCreatePFURLSinceMount,
    currentAccessToken: currentAccessToken?.token,
    refetchPreviewFriendlyURL,
    createMagicLink,
    deactivateLink,
    shouldCreateAndRevokeAccessTokenIfNotExists: true,
    setIsSurreptitiouslyCreatingAndRevokingToken,
    userIsMemberOfTeam: userIsMemberOfTeam ?? false,
    viewLastUpdatedAt: view.updatedAt,
    fetchPreviewFriendlyURLData: previewFriendlyURLData,
  });

  const isMagicLinkEnabled =
    currentAccessToken != null &&
    currentAccessToken.revokedAt == null &&
    !isSurreptitiouslyCreatingAndRevokingToken;

  return (
    <>
      <S.RowWrapper>
        <S.MagicLinkIconTextWrapper>
          <S.GrayCircleWBIcon name="link" />
          <S.MagicLinkText
            disabled={
              currentAccessToken == null ||
              isSurreptitiouslyCreatingAndRevokingToken
            }>
            Anyone with the magic link can view
          </S.MagicLinkText>
        </S.MagicLinkIconTextWrapper>
        <S.Toggle>
          <S.MagicLinkStatusTextWrapper
            disabled={
              currentAccessToken == null ||
              isSurreptitiouslyCreatingAndRevokingToken
            }>
            {isMagicLinkEnabled ? 'On' : 'Off'}
          </S.MagicLinkStatusTextWrapper>
          <S.Tooltip
            on="hover"
            inverted
            position="top center"
            size="mini"
            content={
              canChangeSharingSettings
                ? ''
                : 'Only team admin or author can deactivate magic link'
            }
            hidden={
              canChangeSharingSettings ||
              (currentAccessToken == null && !canChangeSharingSettings)
            }
            trigger={
              <Checkbox
                toggle
                onChange={handleToggleMagicLink}
                checked={isMagicLinkEnabled}
                disabled={isCreatingAccessToken || isRevokingAccessToken}
              />
            }
          />
        </S.Toggle>
      </S.RowWrapper>
      {(isMagicLinkEnabled || !canChangeSharingSettings) && (
        <S.CopyableLinkWrapper>
          {isMagicLinkEnabled && (
            <S.CopyableLink
              isAnimated={isMagicLinkAnimated}
              text={currentPreviewFriendlyURL || magicLinkDisplayText}
              copyText={currentPreviewFriendlyURL || magicLinkURL}
              toastText="Link copied"
              onClick={() => {
                animateMagicLink();
                window.analytics?.track?.('Report View Only Link Copied', {
                  reportId: view.cid,
                  isPreviewFriendlyLink: currentPreviewFriendlyURL != null,
                });
              }}
            />
          )}
          {currentAccessToken == null && !canChangeSharingSettings && (
            <S.SharingDisabledMessageWrapper>
              Sharing not available for this report. Ask team admin or author to
              turn on magic link to enable sharing.
            </S.SharingDisabledMessageWrapper>
          )}
        </S.CopyableLinkWrapper>
      )}
    </>
  );
};
