import {ApolloError} from 'apollo-client';
import {get} from 'lodash';

export function getErrMsgFromError(err: any): string | null {
  const apolloErrMsg = extractErrorMessageFromApolloError(err);
  if (apolloErrMsg) {
    return apolloErrMsg;
  }

  if (typeof err.message === `string` && err.message) {
    return err.message;
  }

  if (typeof err === `string` && err) {
    return err;
  }

  return null;
}

/**
 * Constructs a context that informs the error middleware to propagate errors
 * to the request issuer rather than intercepting them.
 */
export function propagateErrorsContext(): {propagateErrors: boolean} {
  return {
    propagateErrors: true,
  };
}

export function doNotRetryContext(): {doNotRetry: boolean} {
  return {
    doNotRetry: true,
  };
}

export function extractErrorMessageFromApolloError(
  err: unknown
): string | undefined {
  if (!(err instanceof ApolloError) && !(err instanceof Error)) {
    return;
  }

  const errMsg =
    get(err, 'graphQLErrors[0].message') ??
    get(err, 'networkError.result.errors[0].message') ??
    get(err, 'message');

  return errMsg;
}

export function extractStatusCodeFromApolloError(err: any): number | undefined {
  const statusCode =
    err?.networkError?.statusCode ?? err?.graphQLErrors?.[0]?.statusCode;

  return statusCode;
}
