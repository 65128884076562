import styled from 'styled-components';

export const ConfirmationButtons = styled.div`
  border: none;
  color: white;
  flex-direction: row;
  &:hover {
    background: darken(rgb(73, 119, 208), 10%);
    color: white;
  }
  &.current-plan {
    background: white;
    color: rgb(73, 119, 208);
    border: 1px solid rgb(73, 119, 208);
    cursor: default;
  }
`;

export const AddEmailWarningContent = styled.div``;

export const AddEmailWarningEmailsList = styled.div`
  margin-top: 12px;
`;
