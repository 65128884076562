// Functions for querying and mutating views.
//
// Uses apollo-client to send graphql requests. Internally these functions use
// the generated graphql types, which are noisy because our schema has a lot
// of nullable fields that are actually never null. So we hand write simpler
// types to make using the API cleaner.
import * as Obj from '@wandb/cg';
import {ID} from '@wandb/cg';
import * as _ from 'lodash';

import * as Generated from '../../generated/graphql';
import {ApolloClient} from '../types';
import {DiscussionThread} from './discussionThread/types';
import * as Types from './types';

type ThenArg<T> = T extends Promise<infer U> ? U : T;

// Load a single view by id
export const load = (client: ApolloClient, id: string) =>
  client
    .query<Generated.Views2ViewQuery>({
      query: Generated.Views2ViewDocument,
      fetchPolicy: 'no-cache',
      variables: {id},
    })
    .then(result => {
      const view = result.data.view;
      if (view == null) {
        return Promise.reject('not found');
      }
      const parsedView = parseViewMetadata(view);

      if (parsedView == null) {
        return Promise.reject('parse error');
      }
      let spec: any;
      try {
        spec = JSON.parse(view.spec);
      } catch {
        return Promise.reject('json parse error');
      }
      return Promise.resolve({
        ...parsedView,
        spec,
      });
    });

export type LoadResultType = ThenArg<ReturnType<typeof load>>;

// Load a list of views, not including spec
export const loadMetadataList = (
  client: ApolloClient,
  params: Types.LoadMetadataListParams
) =>
  client
    .query<Generated.Views2MetadataQuery>({
      query: Generated.Views2MetadataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        ...params,
        name: params.projectName,
      },
    })
    .then(result => {
      const project = result.data.project;
      if (project == null) {
        throw new Error('View query failed with invalid project');
      }
      const views = project.allViews;
      if (views == null) {
        throw new Error('Unexpected result for ViewsQuery, missing allViews');
      }
      const nodes = views.edges.map(e => {
        const parsedView =
          e.node != null ? parseViewMetadata(e.node) : undefined;
        if (parsedView == null) {
          console.warn("Couldn't parse view from server: ", e.node);
        }
        return parsedView;
      });
      return Promise.resolve(nodes.filter(Obj.notEmpty));
    });

export type LoadMetadataListResultType = ThenArg<
  ReturnType<typeof loadMetadataList>
>;

// Load a list of views, including spec
export const loadMetadataSpecList = (
  client: ApolloClient,
  params: Types.LoadMetadataListParams
) =>
  client
    .query<Generated.Views2MetadataSpecQuery>({
      query: Generated.Views2MetadataSpecDocument,
      fetchPolicy: 'no-cache',
      variables: {
        ...params,
        name: params.projectName,
      },
    })
    .then(result => {
      const project = result.data.project;
      if (project == null) {
        throw new Error('View query failed with invalid project');
      }
      const views = project.allViews;
      if (views == null) {
        throw new Error('Unexpected result for ViewsQuery, missing allViews');
      }
      const nodes = views.edges.map(e => {
        if (e.node == null) {
          console.warn('Invalid server response');
          return undefined;
        }
        const parsedView = parseViewMetadata(e.node);
        if (parsedView == null) {
          console.warn("Couldn't parse view from server: ", e.node);
          return undefined;
        }
        let parsedSpec: any;
        try {
          parsedSpec = JSON.parse(e.node.spec);
        } catch {
          console.warn("Couldn't parse view from server: ", e.node);
        }
        return {
          ...parsedView,
          spec: parsedSpec,
        };
      });
      return Promise.resolve(nodes.filter(Obj.notEmpty));
    });

export type LoadMetadataSpecListResultType = ThenArg<
  ReturnType<typeof loadMetadataList>
>;

export const save = (
  client: ApolloClient,
  view: Types.SaveableView,
  context?: any
) => {
  if (view.id == null) {
    if ((view.name == null && view.displayName == null) || view.type == null) {
      throw new Error(
        "If a view ID isn't provided, a name and type must be provided."
      );
    }
  }

  return client
    .mutate<Generated.UpsertView2Mutation>({
      mutation: Generated.UpsertView2Document,
      // Make sure to skip cache here. If we don't, apollo stores all the
      // variables, forever, which includes our spec, which can be huge.
      fetchPolicy: 'no-cache',
      context,
      variables: {
        id: view.id,
        entityName: view.project ? view.project.entityName : undefined,
        projectName: view.project ? view.project.name : undefined,
        name: view.name,
        displayName: view.displayName,
        type: view.type,
        description: view.description,
        spec: view.spec ? JSON.stringify(view.spec) : undefined,
        parentId: view.parentId,
        locked: view.locked,
        // previewUrl is a signed url to cloud storage, we normalize it here
        previewUrl: view.previewUrl ? `preview.png` : undefined,
        coverUrl: view.coverUrl ? `cover.png` : undefined,
        createdUsing: view.createdUsing,
      },
    })
    .then(result => {
      const data = result.data;
      if (data == null) {
        throw new Error('View save query failed');
      }
      const savedView = data.upsertView && data.upsertView.view;
      if (savedView == null) {
        throw new Error('Unexpected result for UpsertView, missing view');
      }
      const parsedView = parseViewMetadata(savedView);
      if (parsedView == null) {
        throw new Error(
          "Couldn't parse view from server: " + JSON.stringify(savedView)
        );
      }
      return parsedView;
    });
};

export type SaveResultType = ThenArg<ReturnType<typeof save>>;

export const deleteView = (
  client: ApolloClient,
  id: string,
  deleteDrafts: boolean = false
) =>
  client.mutate<Generated.DeleteView2Mutation>({
    mutation: Generated.DeleteView2Document,
    variables: {id, deleteDrafts},
  });

export type DeleteResultType = ThenArg<ReturnType<typeof deleteView>>;

export const deleteViews = (
  client: ApolloClient,
  ids: string[],
  deleteDrafts: boolean = false
) =>
  client.mutate<Generated.DeleteViewsMutation>({
    mutation: Generated.DeleteViewsDocument,
    variables: {ids, deleteDrafts},
  });

export type BatchDeleteResultType = ThenArg<ReturnType<typeof deleteViews>>;

function parseViewMetadata(
  view: Generated.ViewFragmentMetadata2Fragment
): Types.View | undefined {
  const name = view.name;
  if (name == null) {
    return;
  }
  const displayName = view.displayName;
  if (displayName == null) {
    return;
  }
  const type = view.type;
  if (type == null) {
    return;
  }

  if (!_.includes(Types.VIEW_TYPES, type)) {
    return;
  }

  const updatedAt = view.updatedAt;
  if (updatedAt == null) {
    return;
  }

  const updatedBy = parseUser(view.updatedBy);

  const createdAt = view.createdAt;
  if (createdAt == null) {
    return;
  }

  const user = parseUser(view.user);
  if (user == null) {
    return;
  }

  const project = parseProject(view.project);

  if (view.starred == null) {
    view.starred = false;
  }

  if (view.locked == null) {
    view.locked = true;
  }

  return {
    cid: ID(),
    id: view.id,
    type: type as Types.ViewType,
    name,
    displayName,
    description: view.description || '',
    updatedAt,
    updatedBy,
    createdAt,
    user,
    entityName: view.entityName,
    project,
    starCount: view.starCount,
    starred: view.starred,
    parentId: view.parentId || undefined,
    locked: view.locked,
    previewUrl: view.previewUrl || undefined,
    coverUrl: view.coverUrl || undefined,
    viewCount: view.viewCount,
    alertSubscription: view.alertSubscription || undefined,
    accessTokens: view.accessTokens ?? undefined,
  };
}

export function parseUser(
  user: Generated.ViewFragmentMetadata2Fragment['user']
): Types.View['user'] | undefined {
  if (user == null) {
    return;
  }
  const username = user.username;
  if (username == null) {
    return;
  }
  return {
    id: user.id,
    username,
    name: user.name,
    photoUrl: user.photoUrl || undefined,
    admin: user.admin || false,
  };
}

export const parseDiscussionThreads = (
  data?: Generated.ViewDiscussionThreadsQuery
): DiscussionThread[] => {
  if (data?.view == null) {
    return [];
  }
  return data.view.discussionThreads.edges.map(t => {
    const thread = t.node;
    return {
      id: thread.id,
      poster: parseUser(thread.poster),
      createdAt: thread.createdAt,
      comments: thread.comments.edges.map(c => {
        const comment = c.node;
        return {
          id: comment.id,
          body: comment.body,
          poster: parseUser(comment.poster),
          createdAt: comment.createdAt,
          updatedAt: comment.updatedAt ?? undefined,
        };
      }),
    };
  });
};

function parseProject(
  project: Generated.ViewFragmentMetadata2Fragment['project']
): Types.View['project'] | undefined {
  if (project == null) {
    return;
  }
  const name = project.name;
  if (name == null) {
    return;
  }
  const entityName = project.entityName;
  if (entityName == null) {
    return;
  }
  return {
    id: project.id,
    name,
    entityName,
    readOnly: project.readOnly || false,
  };
}

export const starView = (client: ApolloClient, id: string) =>
  client
    .mutate<Generated.StarViewMutation>({
      mutation: Generated.StarViewDocument,
      variables: {id},
    })
    .then(result => {
      const data = result.data;
      if (data == null) {
        throw new Error('Star view mutation failed');
      }
      const starCount =
        data.starView && data.starView.view && data.starView.view.starCount;
      if (starCount == null) {
        throw new Error('Unexpected result for StarView, missing star count');
      }
      return starCount;
    });

export const unstarView = (client: ApolloClient, id: string) =>
  client
    .mutate<Generated.UnstarViewMutation>({
      mutation: Generated.UnstarViewDocument,
      variables: {id},
    })
    .then(result => {
      const data = result.data;
      if (data == null) {
        throw new Error('Unstar view mutation failed');
      }
      const starCount =
        data.unstarView &&
        data.unstarView.view &&
        data.unstarView.view.starCount;
      if (starCount == null) {
        throw new Error('Unexpected result for UnstarView, missing star count');
      }
      return starCount;
    });
