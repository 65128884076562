import Tabs from '@material-ui/core/Tabs';
import {toast} from '@wandb/common/components/elements/Toast';
import {backendHost} from '@wandb/common/config';
import {TargetBlank} from '@wandb/common/util/links';
import {WBIcon} from '@wandb/ui';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Dropdown, DropdownItemProps, Modal} from 'semantic-ui-react';

import {
  CreateRunQueueMutationVariables,
  DefaultResourceConfig,
  useEntityDefaultResourceConfigsQuery,
  useFetchEntityProjectsQuery,
  useFetchJobVersionsQuery,
  useFetchRunQueuesFromProjectQuery,
  useProjectPageQuery,
  usePushToRunQueueMutation,
} from '../../generated/graphql';
import {DEFAULT_LAUNCH_PROJECT_NAME} from '../../pages/EntityLaunchPage';
import {useViewer} from '../../state/viewer/hooks';
import {entityLaunch, projectRunQueue, run} from '../../util/urls';
import Alert from './Alert';
import EditResourceConfigModal from './DefaultResourceConfig/EditResourceConfigModal';
import * as S from './EditLaunchConfigModal.styles';
import {useGetQueueOptions} from './hooks';
import {
  Job,
  LaunchConfigEditor,
  RESOURCE_CONFIGS,
  ResourceConfigKeys,
} from './LaunchConfigEditor';
import SelectJob from './SelectJob';
import SelectJobVersion, {
  getJobVersionOptions,
  JobVersionGroupOption,
} from './SelectJobVersion';
import SelectLocalStorage from './SelectLocalStorage';
import SelectProject, {ProjectOption} from './SelectProject';
import SelectQueue, {
  GroupedQueueOptions,
  isGroupedQueueOption,
  QueueOption,
} from './SelectQueue';
import SelectRun from './SelectRun';
import UrlButton from './UrlButton';
import {useLocalStorage} from './useLocalStorage';
import {
  countQueueOptions,
  getProjectOptions,
  parseJobInput,
  suggestDefaultQueueForGrouped,
} from './utils';

interface EditLaunchConfigModalControlledProps {
  entityName: string;
  projectName: string;

  // Should pass both or neither of these to put dialog in Clone Run mode.
  runName?: string;
  runDisplayName?: string;

  job?: Job;

  runQueueId?: string;
  givenResourceConfigId?: string;
  onClose?: () => void;
}

type EditLaunchConfigModalProps = EditLaunchConfigModalControlledProps & {
  queues: GroupedQueueOptions[] | QueueOption[];
};

type RunQueueOptionsAndMap = {
  runQueueOptions: DropdownItemProps[];
  pushQueueNameIdMap: {[key: string]: string};
};

export function useCreateRunQueueOptionsAndMap(
  entityName: string,
  projectName?: string
): RunQueueOptionsAndMap {
  const useFetchRunQueuesFromProjectQueryResult =
    useFetchRunQueuesFromProjectQuery({
      variables: {
        entityName,
        projectName: projectName ?? 'uncategorized',
      },
    });
  // TODO: what should we do if the run queue query fails?
  if (useFetchRunQueuesFromProjectQueryResult.error != null) {
    console.error('Error fetching run queues.');
  }

  const pushQueueNameIdMap = Object.fromEntries(
    useFetchRunQueuesFromProjectQueryResult.data?.project?.runQueues?.map(
      runQueue => {
        return [runQueue.name, runQueue.id, runQueue.defaultResourceConfigID];
      }
    ) ?? []
  );

  const runQueueOptions = Object.keys(pushQueueNameIdMap).map(name => {
    return {
      key: name,
      text: name,
      value: name,
    };
  });

  return {pushQueueNameIdMap, runQueueOptions};
}
interface Config {
  uri?: string;
  job?: string;
  docker?: {
    docker_image?: string;
  };
  overrides?: {
    args?: string[];
    run_config?: {[key: string]: string};
    entry_point?: string[];
  };

  project?: string;
  entity?: string;
  resource?: string;
  resource_args?: {};
}

export interface CreateRunQueueArgs {
  variables: CreateRunQueueMutationVariables;
}

export type LaunchDropdown = {
  key: string;
  label: string;
  options: DropdownItemProps[];
  value?: string;
  setValue: (v: string | ResourceConfigKeys) => void;
  placeholder?: string;
};

export const EditLaunchConfigModalControlled: React.FC<EditLaunchConfigModalControlledProps> =
  props => {
    const {entityName, projectName} = props;
    const {queues, loading, error} = useGetQueueOptions(
      entityName,
      projectName
    );

    // TODO: Better error display
    if (loading || error != null) {
      return <div>Loading...</div>;
    }

    return <EditLaunchConfigModal {...props} queues={queues} />;
  };

export const renderLaunchDropdown = (
  d: LaunchDropdown,
  disabled: boolean = false
) => (
  <S.DropdownDiv key={d.key}>
    <S.Label>{d.label}</S.Label>
    <Dropdown
      options={d.options}
      disabled={disabled}
      selection
      search
      value={d.value}
      placeholder={d.placeholder}
      onChange={(e, {value}) => {
        d.setValue(value as string);
      }}
    />
  </S.DropdownDiv>
);

export const RESOURCE_OPTIONS: Readonly<DropdownItemProps[]> = [
  {
    key: 'local-container',
    text: 'Local Container',
    value: 'local-container',
  },
  {
    key: 'local-process',
    text: 'Local Process',
    value: 'local-process',
  },
  {
    key: 'kubernetes',
    text: 'Kubernetes',
    value: 'kubernetes',
  },
  {
    key: 'sagemaker',
    text: 'Amazon SageMaker',
    value: 'sagemaker',
  },
  {
    key: 'gcp-vertex',
    text: 'GCP Vertex',
    value: 'gcp-vertex',
  },
];

const EditLaunchConfigModal: React.FC<EditLaunchConfigModalProps> = ({
  entityName,
  projectName,
  job,
  runName,
  runDisplayName,
  queues,
  runQueueId,
  givenResourceConfigId,
  onClose,
}) => {
  const jobInfo = parseJobInput(job?.fullJobName);
  const [tab, setTab] = useState<string>(runName ? 'run' : 'job');
  const [input, setInput] = useState<string>(
    runName ? runName : jobInfo ? jobInfo.jobName : ''
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
    setInput('');
  };

  const [jobVersion, setJobVersion] = useState<string>(
    jobInfo != null ? jobInfo.jobVersion! : ''
  );
  const [jobVersionOptions, setJobVersionOptions] = useState<
    JobVersionGroupOption[]
  >([]);
  const queryJobInfo = parseJobInput(input);
  const versionsQuery = useFetchJobVersionsQuery({
    variables: {
      entityName,
      projectName: queryJobInfo?.jobProject ?? projectName,
      jobName: queryJobInfo?.jobName ?? '',
    },
    skip: tab !== 'job' || queryJobInfo == null,
  });

  const defaultQueue = isGroupedQueueOption(queues)
    ? suggestDefaultQueueForGrouped(queues, runQueueId)
    : queues.find(x => x.value === runQueueId);
  const defaultResourceConfigQuery = useEntityDefaultResourceConfigsQuery({
    variables: {entityName},
  });
  const drcs = useMemo(() => {
    return defaultResourceConfigQuery.data?.entity?.defaultResourceConfigs?.edges.map(
      x => x.node
    );
  }, [defaultResourceConfigQuery]);

  const defaultQueueDRC =
    (drcs?.find(
      x => x.id === defaultQueue?.defaultResourceConfigID
    ) as DefaultResourceConfig) ?? null;
  const [selectedDRC, setSelectedDRC] = useState<DefaultResourceConfig | null>(
    null
  );
  const [savedGit, setSavedGit] = useLocalStorage('launch.git', []);
  const [savedDocker, setSavedDocker] = useLocalStorage('launch.docker', []);
  const [savedPath, setSavedPath] = useLocalStorage('launch.path', []);
  const localStorageGetters: {[key: string]: any} = {
    git: savedGit,
    docker: savedDocker,
    path: savedPath,
  };
  const localStorageSetters: {[key: string]: any} = {
    git: setSavedGit,
    docker: setSavedDocker,
    path: setSavedPath,
  };

  const [pushToRunQueue] = usePushToRunQueueMutation();
  const [launchConfig, setLaunchConfig] = useState<string>('{}');

  const changeDRC = (defaultResourceConfigID: string) => {
    const selectedQueueDRC = _.find(
      drcs,
      d => d.id === defaultResourceConfigID
    );
    if (selectedQueueDRC != null) {
      setSelectedDRC(selectedQueueDRC as DefaultResourceConfig);
    } else {
      setSelectedDRC(null);
      // TODO(gst): Handle a found DRC id, but no matching DRC (drc deleted)
    }
  };

  const [pushQueue, setPushQueue] = useState<QueueOption | null>(
    defaultQueue ?? null
  );
  const [targetProject, setTargetProject] = useState<ProjectOption | null>(
    projectName !== DEFAULT_LAUNCH_PROJECT_NAME
      ? {value: projectName, label: projectName}
      : null
  );

  const [projectOptions, setProjectOptions] = useState<ProjectOption[]>([]);

  const entityProjectsQuery = useFetchEntityProjectsQuery({
    variables: {entityName},
    skip: projectName !== DEFAULT_LAUNCH_PROJECT_NAME,
  });

  useEffect(() => {
    if (entityProjectsQuery.data) {
      const options = getProjectOptions(entityProjectsQuery.data);
      setProjectOptions(options);
    }
  }, [entityProjectsQuery.data]);
  const onSelectJob = (selectedJob: string) => {
    setInput(selectedJob);
  };

  const onSelectProject = (selectedProject: ProjectOption) => {
    setTargetProject(selectedProject);
  };

  useEffect(() => {
    if (tab === 'job' && versionsQuery.data) {
      const [options, defaultAlias] = getJobVersionOptions(versionsQuery.data);
      setJobVersionOptions(options);
      setJobVersion(defaultAlias);
    }
  }, [input, tab, versionsQuery]);

  const onSelectJobAlias = (jobAlias: string) => {
    setJobVersion(jobAlias);
  };

  const onSelectRun = (selectedRunName: string) => {
    setInput(selectedRunName);
  };

  const onSelectQueue = (option: QueueOption) => {
    setPushQueue(option);
    changeDRC(option.defaultResourceConfigID);
  };

  const onSelectLocalStorage = (value: string | null, config: Config) => {
    // When user selects a saved item from LocalStorage update input and config.
    if (value === null) {
      const historySetter = localStorageSetters[tab];
      historySetter([]);
      return;
    }
    setInput(value);
    if (config) {
      setLaunchConfig(JSON.stringify(config, null, 2));
    }
  };

  const [pushing, setPushing] = useState(false);

  const viewer = useViewer();
  const history = useHistory();
  const [editedConfig, setEditedConfig] = useState(false);

  const projectQuery = useProjectPageQuery({
    variables: {
      entityName,
      projectName:
        targetProject != null ? targetProject.label : 'uncategorized',
    },
    skip: targetProject == null,
  });

  const onModalClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const editLaunchConfig = React.useCallback(
    (val: string) => {
      if (!editedConfig) {
        setEditedConfig(true);
        window.analytics?.track('In launch config editor, edited config', {
          entity: viewer?.entity,
          sourceEntity: entityName,
          sourceProject: projectName,
        });
      }
      setLaunchConfig(val);
    },
    [
      setLaunchConfig,
      setEditedConfig,
      viewer?.entity,
      entityName,
      projectName,
      editedConfig,
    ]
  );

  const pushRunToQueue = async () => {
    window.analytics?.track('In launch config editor, clicked push run', {
      entity: viewer?.entity,
      entityName,
      targetProject,
    });
    if (pushing || targetProject === null || pushQueue == null) {
      return;
    }
    setPushing(true);
    let parsedConfig = {};
    try {
      parsedConfig = JSON.parse(launchConfig || '{}');
    } catch {
      toast(
        'Problem parsing launch config. Please check your JSON and try again.'
      );
      setPushing(false);
      return;
    }

    const config: Config = {
      ...parsedConfig,
      project: targetProject.label,
      entity: entityName,
    };

    switch (tab) {
      case 'job':
        const pushJobInfo = parseJobInput(input);
        if (pushJobInfo == null) {
          toast('Invalid job input. Please check your input and try again.');
        } else if (pushJobInfo.jobProject != null) {
          // input has project info, use that
          config.job = `${entityName}/${pushJobInfo.jobProject}/${pushJobInfo.jobName}:${jobVersion}`;
        } else {
          // input does not have project info, use current project
          config.job = `${entityName}/${projectName}/${input}:${jobVersion}`;
        }
        break;
      case 'run':
        config.uri =
          backendHost() +
          run({entityName, projectName, name: runName || input});
        break;
      case 'docker':
        config.docker = {
          docker_image: input,
        };
        break;
      case 'git':
      case 'path':
        config.uri = input;
        break;
    }

    if (!(selectedDRC ?? defaultQueueDRC)) {
      // Queue doesn't have resource, must manually set
      if (!config.resource) {
        toast(
          'No resource set. Select a queue with a resource configuration or manually set a top level "resource" key in the editor.'
        );
        setPushing(false);
        return;
      } else if (!Object.keys(RESOURCE_CONFIGS).includes(config.resource)) {
        toast(
          'Manual resource selection invalid. Available resources: ' +
            Object.keys(RESOURCE_CONFIGS).join(', ')
        );
        setPushing(false);
        return;
      } else {
        window.analytics?.track(
          'In launch config editor, manually set resource was successful',
          {
            entity: viewer?.entity,
            sourceEntity: entityName,
            sourceProject: projectName,
            resource: config.resource,
            queue: pushQueue.queueID,
          }
        );
      }
    }

    const MAX_SAVED = 5;
    const historySetter = localStorageSetters[tab];
    if (historySetter) {
      const saved = localStorageGetters[tab];
      saved.unshift({
        value: input,
        config: parsedConfig,
        at: new Date().getTime(),
      });
      saved.length = Math.min(saved.length, MAX_SAVED);
      historySetter(saved);
    }

    const pushedRunSpec = JSON.stringify(config);

    const pushResp = await pushToRunQueue({
      variables: {
        queueID: pushQueue.queueID,
        runSpec: pushedRunSpec,
      },
    });

    await projectQuery.refetch();

    onModalClose();

    const location =
      pushQueue.project === DEFAULT_LAUNCH_PROJECT_NAME
        ? entityLaunch(entityName)
        : projectRunQueue({entityName, name: pushQueue.project});
    if (
      pushResp.errors == null &&
      pushResp.data?.pushToRunQueue?.runQueueItemId != null
    ) {
      history.push(location);
    }
  };

  const queueCount = countQueueOptions(queues);

  return (
    <Modal open={true} className="edit-run-config-modal" onClose={onModalClose}>
      <Modal.Header>
        Add {runName && ' Cloned'} Run to Launch Queue
      </Modal.Header>
      <S.ModalContent>
        {runName ? (
          <S.ClonedRunLabel>
            Cloned Run: <strong>{runDisplayName}</strong>
          </S.ClonedRunLabel>
        ) : (
          <>
            <S.SourceRow>
              <S.SourceLabel>Source:</S.SourceLabel>
              <Tabs onChange={handleChangeTab} value={tab} centered>
                <S.SourceTab label="Job" value="job" />
                {projectName !== DEFAULT_LAUNCH_PROJECT_NAME && (
                  <S.SourceTab label="Clone Run" value="run" />
                )}
                <S.SourceTab label="Git URL" value="git" />
                <S.SourceTab label="Docker Image" value="docker" />
                <S.SourceTab label="Local Path" value="path" />
              </Tabs>
            </S.SourceRow>
            <S.Spacer />
            {tab === 'job' && (
              <S.JobRow>
                <S.JobSelect>
                  <SelectJob
                    entityName={entityName}
                    projectName={projectName}
                    selectedJob={input}
                    onSelectJob={onSelectJob}
                  />
                </S.JobSelect>
                {input && versionsQuery.data && (
                  <S.JobVersion>
                    <SelectJobVersion
                      options={jobVersionOptions}
                      selectedAlias={jobVersion}
                      onSelectJobAlias={onSelectJobAlias}
                    />
                  </S.JobVersion>
                )}
              </S.JobRow>
            )}
            {tab === 'run' && !runName && (
              <SelectRun
                entityName={entityName}
                projectName={projectName}
                onSelectRun={onSelectRun}
              />
            )}
            {tab === 'git' && (
              <S.GitRow>
                <S.GitSelect>
                  <SelectLocalStorage
                    placeholder="Enter a GitHub, GitLab, or Bitbucket URL"
                    saved={savedGit}
                    onSelect={onSelectLocalStorage}
                  />
                </S.GitSelect>
                <UrlButton url={input} />
              </S.GitRow>
            )}
            {tab === 'docker' && (
              <SelectLocalStorage
                placeholder="Enter a Docker image"
                saved={savedDocker}
                onSelect={onSelectLocalStorage}
              />
            )}
            {tab === 'path' && (
              <SelectLocalStorage
                placeholder="Enter a local file path"
                saved={savedPath}
                onSelect={onSelectLocalStorage}
              />
            )}
            <S.Spacer />
          </>
        )}

        <LaunchConfigEditor
          launchConfig={launchConfig}
          setLaunchConfig={editLaunchConfig}
          runName={tab === 'run' && input ? input : undefined}
          job={job}
          entityName={entityName}
          projectName={projectName}
        />
        <S.Spacer />
        {queueCount > 0 ? (
          <S.SelectRow>
            <S.SelectLabel>Queue:</S.SelectLabel>
            <S.SelectContainer>
              <SelectQueue
                defaultQueueOption={defaultQueue}
                options={queues}
                onSelectQueue={onSelectQueue}
              />
            </S.SelectContainer>
            {(selectedDRC ?? defaultQueueDRC) && (
              <S.DefaultResourceConfigBin>
                <EditResourceConfigModal
                  entityName={entityName || ''}
                  mode="VIEW"
                  refetch={defaultResourceConfigQuery.refetch}
                  config={selectedDRC ?? defaultQueueDRC}
                />
              </S.DefaultResourceConfigBin>
            )}
          </S.SelectRow>
        ) : (
          <Alert>
            There are no queues in this project.{' '}
            <S.QueueCreateLink
              onClick={() => {
                onModalClose();
                if (targetProject != null) {
                  history.push(
                    projectRunQueue({entityName, name: targetProject.label!})
                  );
                }
              }}>
              Create one <WBIcon name="right-arrow" />
            </S.QueueCreateLink>
          </Alert>
        )}
        {projectName === DEFAULT_LAUNCH_PROJECT_NAME && (
          <div>
            <S.Spacer />
            <S.SelectRow>
              <S.SelectLabel>Project:</S.SelectLabel>
              <S.SelectContainer>
                <SelectProject
                  options={projectOptions}
                  onSelectProject={onSelectProject}
                />
              </S.SelectContainer>
            </S.SelectRow>
          </div>
        )}
      </S.ModalContent>
      <Modal.Actions>
        <S.ActionsBottom>
          <span>
            Check the{' '}
            <TargetBlank href="https://docs.wandb.ai/guides/launch">
              launch documentation
            </TargetBlank>{' '}
            to see what options are available.
          </span>
          <S.Buttons>
            <Button
              size="tiny"
              disabled={pushing}
              onClick={() => {
                window.analytics?.track(
                  'In launch config editor, clicked cancel',
                  {
                    entity: viewer?.entity,
                    sourceEntity: entityName,
                    sourceProject: projectName,
                  }
                );
                onModalClose();
              }}>
              Cancel
            </Button>
            <Button
              size="tiny"
              primary
              disabled={
                pushing || targetProject == null || !input || pushQueue == null
              }
              loading={pushing}
              onClick={pushRunToQueue}>
              Push Run
            </Button>
          </S.Buttons>
        </S.ActionsBottom>
      </Modal.Actions>
    </Modal>
  );
};

export default EditLaunchConfigModal;
