/**
 * This file contains hooks that help callers enforce model registry permissions.
 *
 * The following hooks are available:
 * - useEntityHasReachedModelLimit: returns true if the entity has matched or exceeded its registered model limit.
 * - useEntityActionHistoryLimit: return the number of actions to display for the entity.
 *
 * Each of these hooks calls its corresponding backend endpoint in Gorilla.
 * The backend logic switches based on deployment type: on-prem or public cloud SaaS.
 *
 * on-prem (W&B Server/Local) - limits are placed on the license.
 * public cloud SaaS - limits are placed on the entity's Organization. If the entity does not have an Organization,
 *    they are personal, free users and therefore do not have limits for model registry usage.
 *
 * Note that the action history limit is currently set to 2 * registered model limit.
 */

import type {OutputNode} from '@wandb/cg';
import {constString, opEntityOrg, opOrgName, opRootEntity} from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import React from 'react';
import {Message} from 'semantic-ui-react';

import {
  useActionHistoryLimitQuery,
  useRegisteredModelLimitReachedQuery,
} from '../generated/graphql';
import {trackModelRegistryLimitsReached} from './navigation';

const MAIL_TO_ADDRESS = 'contact@wandb.ai'; // 'sales@wandb.ai'; // 'support@wandb.ai';

type Loadable<T> = {
  result?: T;
  loading: boolean;
};

export const useEntityHasReachedModelLimit = (
  entityName: string
): Loadable<boolean> => {
  const registeredModelLimitReachedQuery = useRegisteredModelLimitReachedQuery({
    fetchPolicy: 'no-cache',
    variables: {
      entityName,
    },
    onCompleted: d => {
      if (d.serverInfo?.registeredModelLimitReached) {
        trackModelRegistryLimitsReached('registered_model_count');
      }
    },
  });

  return {
    loading: registeredModelLimitReachedQuery.loading,
    result:
      registeredModelLimitReachedQuery.data?.serverInfo
        ?.registeredModelLimitReached,
  };
};

export const useEntityActionHistoryLimit = (
  entityName: string,
  artifactCollectionID?: string
): Loadable<number> => {
  const actionHistoryLimitQuery = useActionHistoryLimitQuery({
    fetchPolicy: 'no-cache',
    skip: artifactCollectionID == null,
    variables: {
      entityName,
      artifactCollectionID,
    },
  });

  return React.useMemo(
    () => ({
      loading:
        actionHistoryLimitQuery.loading || actionHistoryLimitQuery.data == null,
      result: actionHistoryLimitQuery.data?.serverInfo?.actionHistoryLimit,
    }),
    [actionHistoryLimitQuery.data, actionHistoryLimitQuery.loading]
  );
};

export const ModelLimitReachedMessage: React.FC<{
  entityName: string;
}> = ({entityName}) => {
  return (
    <LimitReachedMessage
      entityName={entityName}
      title={'Registered Model Collection Limit Reached'}
    />
  );
};

export const HistoryLimitReachedMessage: React.FC<{
  entityName: string;
}> = ({entityName}) => {
  React.useEffect(() => {
    trackModelRegistryLimitsReached('action_history');
  }, []);
  return (
    <LimitReachedMessage
      entityName={entityName}
      title={'Action History Limit Reached'}
    />
  );
};

const LimitReachedMessage: React.FC<{
  title: string;
  entityName: string;
}> = ({title, entityName}) => {
  const orgNameValue = useEntityOrgName(entityName);
  const orgName = orgNameValue.loading ? null : orgNameValue.result;
  return (
    <Message color="orange">
      <Message.Header>{title}</Message.Header>
      <p>
        {orgName != null ? (
          <>This entity's organization ({orgName})</>
        ) : (
          <>This entity ({entityName})</>
        )}{' '}
        has reached the limit of free Model Registry usage, please{' '}
        <strong>contact</strong> us at{' '}
        <a href={`mailto:${MAIL_TO_ADDRESS}`}>{MAIL_TO_ADDRESS}</a> to increase
        your registered model collection limit and get full history records.
      </p>
    </Message>
  );
};

const useEntityOrgName = (entityName: string): Loadable<string> => {
  const orgNameNode = React.useMemo(() => {
    const node = opOrgName({
      org: opEntityOrg({
        entity: opRootEntity({
          entityName: constString(entityName),
        }),
      }),
    }) as OutputNode<'string'>;
    return node;
  }, [entityName]);
  // This is really silly. I keep getting this compile error:
  // `Type instantiation is excessively deep and possibly infinite.`
  // the only way to fix it is to cast as unknown, then the true type....
  return useNodeValue(orgNameNode) as unknown as Loadable<string>;
};
