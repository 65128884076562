import {isEmpty} from 'lodash';
import React, {
  ChangeEvent,
  memo,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {Input, InputOnChangeData} from 'semantic-ui-react';

import {isInJupyterNotebook} from '../../setup';
import {useDebounceState} from '../../util/hooks';

const PanelSearchInputContext = React.createContext<{
  searchQuery: string; // original query typed by user (not debounced)
  searchInputCallbackRef(node: Input | null): void;
  onChange(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void;
}>({
  searchQuery: '',
  searchInputCallbackRef: () => {},
  onChange: () => {},
});
PanelSearchInputContext.displayName = 'PanelSearchInputContext';

const PanelSearchDebouncedContext = React.createContext<{
  isSearching: boolean;
  debouncedSearchQuery: string;
}>({isSearching: false, debouncedSearchQuery: ''});
PanelSearchDebouncedContext.displayName = 'PanelSearchDebouncedContext';

const PanelSearchContextProviderComp: React.FC<{children: ReactElement}> = ({
  children,
}) => {
  // Focus the search input if we're not in a jupyter notebooke
  const searchInputCallbackRef = useCallback((node: Input | null) => {
    if (!isInJupyterNotebook() && node != null) {
      node.focus();
    }
  }, []);

  const [searchQuery, debouncedSearchQuery, setSearchQuery] = useDebounceState(
    '',
    100
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
      setSearchQuery(value);
    },
    [setSearchQuery]
  );

  const inputState = useMemo(
    () => ({
      searchInputCallbackRef,
      searchQuery, // original query typed by user (not debounced). used for display.
      onChange,
    }),
    [onChange, searchInputCallbackRef, searchQuery]
  );

  const debouncedState = useMemo(
    () => ({
      debouncedSearchQuery: debouncedSearchQuery.trim(),
      isSearching: !isEmpty(debouncedSearchQuery.trim()),
    }),
    [debouncedSearchQuery]
  );

  return (
    <PanelSearchInputContext.Provider value={inputState}>
      <PanelSearchDebouncedContext.Provider value={debouncedState}>
        {children}
      </PanelSearchDebouncedContext.Provider>
    </PanelSearchInputContext.Provider>
  );
};

export const PanelSearchContextProvider = memo(PanelSearchContextProviderComp);

export function usePanelSearchDebouncedContext() {
  return useContext(PanelSearchDebouncedContext);
}

export function usePanelSearchInputContext() {
  return useContext(PanelSearchInputContext);
}
