import {useCallback, useMemo} from 'react';

import {useEntitySettingsQuery} from '../generated/graphql';
import {EntitySettingsFromQuery} from '../graphql/entitySettings';

type UseEntitySettingsReturnType = {
  loading: boolean;
  entitySettings: EntitySettingsFromQuery | null;
  refetch: () => Promise<void>;
};

export function useEntitySettings(
  entityName: string
): UseEntitySettingsReturnType {
  const {
    loading,
    data: entitySettingsData,
    refetch: refetchEntitySettingsQuery,
  } = useEntitySettingsQuery({
    variables: {name: entityName},
    skip: !entityName,
  });

  const entitySettings = useMemo(
    () => entitySettingsData?.entity?.settings ?? null,
    [entitySettingsData]
  );
  const refetch = useCallback(async () => {
    await refetchEntitySettingsQuery();
  }, [refetchEntitySettingsQuery]);

  return {loading, entitySettings, refetch};
}
