import LinkButton from '@wandb/common/components/LinkButton';
import {History, Location} from 'history';
import queryString from 'query-string';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Message} from 'semantic-ui-react';

import OnboardingBackground from '../assets/onboarding/signup-colorful-bkg.svg';
import {useResetPasswordMutation} from '../generated/graphql';
import {readCachedAuthState} from '../reducers/auth';
import {auth, isInJupyterNotebook} from '../setup';
import {displayError} from '../state/global/actions';
import {useViewer} from '../state/viewer/hooks';
import {doAuthRedirect} from '../util/auth';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {InstrumentedLoader as Loader} from './../components/utility/InstrumentedLoader';
import VerifyEmail from './VerifyEmail';

interface LoginProps {
  history: History;
  location: Location<LocationState>;
  currentIdToken?: string;
}

interface LocationState {
  prompt?: boolean; // if true, we display the wandb login form (used for local auth)
  from?: Location;
  internal?: boolean;
}

const getCachedTokenTail = () => {
  const currentCached = readCachedAuthState();

  if (currentCached.currentIdToken) {
    return currentCached.currentIdToken.slice(
      currentCached.currentIdToken.length - 4
    );
  }

  return 'null';
};

const Login: React.FC<LoginProps> = ({currentIdToken, history, location}) => {
  const [referrer, setReferrer] = useState<{prompt?: boolean}>(
    location.state || {}
  );
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState<boolean>(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] =
    useState<boolean>(false);

  const [resetPassword] = useResetPasswordMutation({
    context: propagateErrorsContext(),
  });

  const viewer = useViewer();

  const toggleShowResetPassword = useCallback(() => {
    setShowResetPassword(prev => !prev);
    setResetPasswordError(false);
    setResetPasswordSuccess(false);
    setError(false);
  }, []);

  const params = queryString.parse(location.search);
  const queryParams = new URLSearchParams(location.search);

  // If the email address is unverified, auth.go returns the error `Email must be verified when logging in email/password combo: ${email}`
  // We extract the email address from the error message to display it in <VerifyEmail>
  const errUnverifiedEmail: undefined | string =
    queryParams.get('error_description') != null &&
    queryParams.get('error_description')?.includes('Email must be verified')
      ? queryParams.get('error_description')?.split(': ')[1]
      : undefined;

  const emailIsInUse =
    typeof queryParams.get('error_description') === 'string' &&
    queryParams.get('error_description') === 'Email in use';

  const licenseLimitReached =
    typeof queryParams.get('error_description') === 'string' &&
    queryParams.get('error_description') === 'License seats limit reached';

  useEffect(() => {
    // Just redirect when we're actually logged in.
    if (viewer) {
      history.replace({pathname: '/', state: {internal: true}});
    }
  }, [history, viewer]);

  useEffect(() => {
    if (auth.loggedIn()) {
      if (emailIsInUse === true) {
        try {
          localStorage.setItem('emailInUse', 'true');
        } catch (e) {
          return;
        }
        window.location.reload();
        return;
      }
      doAuthRedirect(history);
      return;
    }

    // Jupyter / iframe mode
    if (isInJupyterNotebook()) {
      if (currentIdToken) {
        doAuthRedirect(history);
      } else {
        // Auth works funky in iframes, we attempt to reload to get creds
        setReferrer({prompt: true});
        setTimeout(() => window.location.reload(), 10000);
      }
      return;
    }

    const hasInviteLink = queryParams.getAll('invite').length > 0;
    const signup =
      queryParams.get('signUp') !== null ||
      queryParams.get('signup') !== null ||
      hasInviteLink;
    const loggedIn = queryParams.get('loggedIn') !== null;
    const options = {signup, loggedIn};

    if (hasInviteLink) {
      const [invited] = queryParams.getAll('invite');
      try {
        localStorage.setItem('auth.invited', invited);
      } catch (e) {
        return;
      }
    }

    if (!queryParams.get('upgradeAuth') && auth.loggedIn()) {
      // Already logged in, just go home. This happens when
      // people visit /site and click the login button on the
      // top-right (and are already logged in).
      history.replace('/');
    } else if (
      queryParams.get('error') &&
      queryParams.get('error') === 'access_denied'
    ) {
      console.error('Access denied', queryParams.get('error_description'));
      if (licenseLimitReached) {
        auth.store!.dispatch(
          displayError({
            code: 403,
            message: (
              <p>
                The allocated number of seats has been reached. Please contact
                your administrator about increasing the seat limit.
              </p>
            ),
          })
        );
      } else if (!errUnverifiedEmail) {
        auth.store!.dispatch(
          displayError({
            code: 403,
            message: (
              <>
                <p>
                  We're sorry, but there was a problem authenticating your
                  account: {params.error_description}
                </p>
                <p>
                  <Button
                    icon="refresh"
                    size="small"
                    onClick={() => auth.login(options)}
                  />{' '}
                  Please click to try again.
                </p>
                <p>
                  If you continue to see this message, please email{' '}
                  <a href="mailto:support@wandb.com">support@wandb.com</a>.
                </p>
              </>
            ),
          })
        );
      }
    } else {
      if (!params.local) {
        // if we're not local, then try /oidc/login
        auth.login(options, location.state);
      } else {
        // if server says we're local, then try auto login
        auth.autoLogin();
        setReferrer({prompt: true});
      }
      return;
    }
    // eslint-disable-next-line
  }, []);

  const form = useRef<HTMLFormElement>(null);

  if (errUnverifiedEmail != null) {
    return <VerifyEmail email={errUnverifiedEmail} />;
  }

  if (!referrer.prompt || !params.local) {
    return <Loader name="login-loader" samplingRate={0.001} />;
  }

  return (
    <div
      className="onboarding-flow"
      style={{backgroundImage: `url(${OnboardingBackground})`}}>
      <div className="onboarding-dialogue">
        <h2>{showResetPassword ? 'Reset Password' : 'Login'}</h2>

        <div>
          <Form
            ref={form}
            onSubmit={async () => {
              setLoading(true);
              if (showResetPassword) {
                setResetPasswordSuccess(false);
                setResetPasswordError(false);
                console.log(`Resetting password for ${email}`);
                try {
                  await resetPassword({variables: {email}});
                  setResetPasswordSuccess(true);
                } catch (e) {
                  const extracted = extractErrorMessageFromApolloError(e);
                  console.error(
                    `Error requesting password reset: ${extracted}`
                  );
                  setResetPasswordError(true);
                } finally {
                  setLoading(false);
                }
                return;
              }
              setError(false);
              console.log(`Logging in as ${email}`);

              console.log(`Prior to login, token is ${getCachedTokenTail()}`);
              let success = false;
              try {
                success = await auth.remoteLogin(email, password);
              } catch (e) {
                const extracted = extractErrorMessageFromApolloError(e);
                console.error(`Error logging in: ${extracted}`);
              }
              if (success) {
                const url = doAuthRedirect();
                console.log(`Login successful; redirecting to ${url}`);
                console.log(
                  `At time of redirect, token is ${getCachedTokenTail()}`
                );
              } else {
                console.error(`Login failed.`);
                console.error(
                  `After login failure, token is ${getCachedTokenTail()}`
                );
                setLoading(false);
                setError(true);
              }
            }}>
            <Message
              success
              visible={resetPasswordSuccess}
              content="Password reset email sent"
            />
            <Form.Input
              label="Email"
              name="email"
              data-test="email"
              error={
                resetPasswordError && 'Error while trying to reset password'
              }
              onChange={(e, {value}) => setEmail(value.replace(/\s+/g, ''))}
            />
            {!showResetPassword && (
              <Form.Field error={error}>
                <label>Password</label>
                <Input
                  type="password"
                  name="password"
                  data-test="password"
                  onChange={(e, {value}) => setPassword(value)}
                />
                <div
                  style={{display: error ? 'inline-block' : 'none'}}
                  role="alert"
                  className="ui pointing above prompt label">
                  Invalid password
                </div>
              </Form.Field>
            )}
            {!showResetPassword && (
              <span style={{display: 'block', textAlign: 'right'}}>
                <LinkButton
                  data-test="forgot-your-password"
                  onClick={toggleShowResetPassword}>
                  Forgot your password?
                </LinkButton>
              </span>
            )}
            <Button type="submit" style={{display: 'none'}}>
              Login
            </Button>
          </Form>
        </div>
        <div className="actions">
          {showResetPassword && (
            <Button onClick={toggleShowResetPassword}>Cancel</Button>
          )}
          <Button
            primary
            loading={loading}
            data-test="submit"
            onClick={e => {
              form.current?.handleSubmit(e);
            }}>
            {showResetPassword ? 'Reset Password' : 'Login'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
