import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import * as globals from '@wandb/common/css/globals.styles';
import {Link, TargetBlank} from '@wandb/common/util/links';
import {WBIcon} from '@wandb/ui';
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Modal as SemanticModal,
  Segment,
  Select,
  TextArea,
} from 'semantic-ui-react';
import styled, {css} from 'styled-components';

import Datetime from '../compat/react-datetime';
import PageWithSidebar from '../components/PageWithSidebar';
import {InstrumentedLoader} from '../components/utility/InstrumentedLoader';

export const TABLET_WIDTH = 1095;
export const MOBILE_WIDTH = 600;

export const MAX_PAGE_WIDTH = 1000;
const MAX_FOOTER_WIDTH = 1120;
export const FC_NAV_HEIGHT = 70;
const SPACING = 24;

export const Page = styled.div``;

export const LoaderContainer = styled.div`
  position: relative;
  height: 300px;
  margin-top: 20px;
`;

export const Loader = styled(InstrumentedLoader)``;

export const TopNav = styled.nav`
  display: flex;
  justify-content: center;
  background-color: ${globals.gray900};

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding: 10px 0;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 48px 24px;
  }
`;

export const TopNavContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH}px;

  @media (max-width: ${TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`;

export const TopNavLogo = styled(Link)`
  display: flex;
  align-items: center;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  padding-right: 16px;
  color: ${globals.white};
  &:hover {
    color: ${globals.white};
  }
`;

export const TopNavLogoImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;

const topNavLinkStyles = css`
  padding: 16px;
  font-weight: 600;
  color: ${globals.white};
  &:hover {
    color: ${globals.white};
  }
`;

export const TopNavLink = styled(Link)`
  ${topNavLinkStyles}
`;

export const TopNavDropdownTrigger = styled.div`
  ${topNavLinkStyles};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TopNavDropdownIcon = styled(WBIcon)`
  font-size: 24px;
  margin-left: 4px;
`;

export const TopNavDropdown = styled(PopupDropdown)``;

export const TopNavDropdownMenu = styled(Dropdown.Menu)`
  &&&&& {
    padding: 8px;
    background-color: ${globals.gray800};
  }
`;

export const TopNavDropdownItem = styled(Link)<{withPadding?: boolean}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: ${globals.white};
  &:hover {
    color: ${globals.white};
  }

  ${p => p.withPadding && `padding: 8px;`}
`;

export const TopNavDropdownItemImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

export const TopNavDropdownItemText = styled.span`
  line-height: 22px;
`;

export const LoginButtons = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 172px;

  @media (min-width: ${1401}px) {
    right: -172px;
  }

  @media (max-width: ${1400}px) {
    right: 0;
  }
`;

export const LoginLink = styled(Link)`
  ${topNavLinkStyles};
  display: flex;
  align-items: center;
`;

export const LoginLinkIcon = styled(WBIcon)`
  font-size: 20px;
  margin-right: 4px;
`;

export const ProfileDropdownMenuContainer = styled.div`
  &&& {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }
`;

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${globals.gray900};
  color: ${globals.white};
  text-align: center;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding: 0 28px 28px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 24px 24px 48px 24px;
  }
`;

export const IntroContent = styled.div`
  max-width: 820px;
`;

export const IntroHeader = styled.h1`
  font-family: 'Source Serif Pro';
  font-size: 40px;
  color: ${globals.gold};
  margin: 0;
`;

export const IntroBody = styled.p`
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0px;
  margin: 12px 0 20px;
`;

export const IntroButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IntroButtonImg = styled.img`
  height: 60px;
  margin: 0 18px;
  cursor: pointer;
`;

export const FCNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  background-color: ${globals.gray800};
`;

export const FCNavContent = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${FC_NAV_HEIGHT}px;
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH}px;
`;

export const FCNavLink = styled(Link)<{active?: boolean}>`
  position: relative;
  color: ${globals.white};
  padding: 16px 6px;
  font-size: 20px;

  &:hover {
    color: ${globals.white};
  }

  ${p =>
    p.active &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8px;
        height: 2px;
        background-color: ${globals.white};
      }
    `}
`;

export const FCPageContainer = styled.div<{viewingReport?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${globals.gray100};

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding: 48px 48px 80px 48px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 24px ${p => (p.viewingReport ? 0 : 24)}px
      ${p => (p.viewingReport ? 0 : 40)}px;
  }
`;

export const FCContent = styled.div<{wider?: boolean; reportMode?: boolean}>`
  position: relative;
  min-height: 300px;
  ${p =>
    p.reportMode
      ? css`
          max-width: 100%;
        `
      : css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          max-width: ${p.wider ? 1280 : MAX_PAGE_WIDTH}px;
          width: 100%;
        `}
`;

export const Columns = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const LeftColumn = styled.div`
  @media (min-width: ${TABLET_WIDTH + 1}px) {
    width: 640px;
    flex-shrink: 0;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  margin-left: ${SPACING}px;

  @media (max-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 28px;
  letter-spacing: 0px;
  padding-left: ${SPACING}px;
  margin: ${SPACING}px 0 0;
`;

export const SectionPicker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    margin-bottom: -42px;
    padding: 0 160px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    margin-bottom: -46px;
    padding: 0 80px;
  }

  ${SectionHeader} {
    padding-left: 0;
  }
`;

export const SectionPickerItem = styled.div<{active?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  ${p =>
    p.active &&
    css`
      position: relative;
      z-index: 1;
    `};
  ${p =>
    !p.active &&
    css`
      opacity: 0.5;
    `};
`;

export const SectionPickerIcon = styled.img`
  width: 44px;
  height: 44px;
`;

export const Section = styled.div<{
  smallMargin?: boolean;
  smallPadding?: boolean;
  extraPadding?: boolean;
}>`
  position: relative;
  background-color: ${globals.white};
  padding: ${p =>
    p.smallPadding ? `14px` : `${p.extraPadding ? 28 : 20}px 24px`};
  border-radius: 8px;
  border: 1px solid ${globals.gray400};

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    margin-top: ${p => (p.smallMargin ? 12 : 20)}px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    margin-top: 24px;
  }
`;

export const PostWrapperWrapper = styled.div<{hidden?: boolean}>`
  position: relative;
  padding-left: 20px;
  ${p =>
    p.hidden &&
    css`
      display: none;
    `}
`;

export const PostHeart = styled(LegacyWBIcon)`
  &&&&& {
    color: ${globals.heartColor};
    position: absolute;
    cursor: pointer;

    @media (min-width: ${TABLET_WIDTH + 1}px) {
      left: 0;
      top: 20px;
    }

    @media (max-width: ${TABLET_WIDTH}px) {
      left: -4px;
      top: 28px;
    }
  }
`;

export const BlogPageEvent = styled(Link)<{noMargin?: boolean}>`
  position: relative;
  display: block;

  &,
  &:hover {
    color: ${globals.textPrimary};
  }

  ${p =>
    !p.noMargin &&
    css`
      &:not(:first-child) {
        margin-top: 24px;
      }
    `}// transition: transform 0.3s, box-shadow 0.3s;
  // @media (min-width: ${TABLET_WIDTH + 1}px) {
  //   &:hover {
  //     transform: scale(1.005);
  //     box-shadow: 1px 1px 14px rgb(0 0 0 / 8%);
  //   }
  // }
`;

export const BlogPageEventImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
`;

export const BlogPageEventTime = styled.div`
  font-size: 15px;
`;

export const BlogPageEventName = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 6px 0;
`;

export const BlogPageEventLink = styled(Link)`
  font-size: 18px;
  font-weight: 600;
  color: ${globals.textPrimary};
  text-decoration: underline;
  &:hover {
    color: ${globals.textPrimary};
    text-decoration: underline;
  }
`;

export const BlogPageListHeader = styled.h4`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const BlogPageListItem = styled(Link)<{active?: boolean}>`
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 4px 0 4px 16px;

  color: ${globals.textPrimary};
  ${p => p.active && `text-decoration: underline;`}
  &:hover {
    color: ${globals.textPrimary};
    ${p => p.active && `text-decoration: underline;`}
  }

  &:before {
    content: '';
    background-color: ${globals.gold};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 10px;
  background-color: ${globals.white};
  box-shadow: 0 4px 0 -3px ${globals.gray400};
`;

const paginationButtonStyles = css<{disabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${globals.textPrimary};
  &:hover {
    color: ${globals.textPrimary};
  }

  ${p =>
    !p.disabled &&
    css`
      cursor: pointer;
    `}
`;

export const PaginationIcon = styled(WBIcon)`
  position: relative;
  top: 1px;
  font-size: 26px;
`;

export const PaginationEdge = styled(Link)<{disabled?: boolean}>`
  ${paginationButtonStyles}

  ${p =>
    p.disabled &&
    css`
      opacity: 0.7;
      cursor: auto;
    `};

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    width: 108px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    width: 48px;
  }
`;

export const PaginationPages = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  &:before {
    content: '';
    position: absolute;
    top: 6px;
    bottom: 6px;
    width: 1px;
    background-color: ${globals.gray400};
    left: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 6px;
    bottom: 6px;
    width: 1px;
    background-color: ${globals.gray400};
    right: 0;
  }
`;

const paginationPageStyles = css`
  ${paginationButtonStyles};
  position: relative;
  width: 48px;
  z-index: 1;
`;

export const PaginationPage = styled(Link)<{
  active?: boolean;
  disabled?: boolean;
}>`
  ${paginationPageStyles}
  ${p =>
    p.active &&
    css`
      border: 1px solid ${globals.gray900};
    `}
`;

export const PaginationPageDisabled = styled.span<{
  active?: boolean;
  disabled?: boolean;
}>`
  ${paginationPageStyles}
`;

export const PaginationEllipsis = styled.span`
  position: relative;
  top: -4px;
`;

export const CTA = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${globals.gray800};

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding: 96px 24px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 48px 24px 56px;
  }
`;

export const CTAContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    width: 100%;
    max-width: ${MAX_FOOTER_WIDTH}px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    flex-direction: column;
  }
`;

export const CTAText = styled.div`
  font-family: 'Source Serif Pro';
  font-size: 36px;
  line-height: 44px;
  max-width: 500px;
  color: ${globals.white};

  @media (max-width: ${TABLET_WIDTH}px) {
    text-align: center;
    line-height: 60px;
  }
`;

export const CTAButtons = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${TABLET_WIDTH}px) {
    margin-top: 40px;
  }
`;

const ctaButtonStyles = css`
  padding: 20px 36px;
  text-transform: uppercase;
`;

export const CTAButtonGold = styled(Link)`
  ${ctaButtonStyles};
  background-color: ${globals.gold};
  font-weight: 600;
  color: ${globals.textPrimary};
  &:hover {
    color: ${globals.textPrimary};
  }
`;

export const CTAButtonWhite = styled(Link)`
  ${ctaButtonStyles};
  background-color: ${globals.white};
  margin-left: 24px;
  color: ${globals.textPrimary};
  &:hover {
    color: ${globals.textPrimary};
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  background-color: ${globals.gray900};
  color: ${globals.white};

  padding: 0 24px;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    max-width: ${MAX_FOOTER_WIDTH}px;
  }
  @media (max-width: ${TABLET_WIDTH}px) {
    max-width: 600px;
  }
`;

export const FooterMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 72px 0 48px;
  width: 100%;

  @media (max-width: ${TABLET_WIDTH}px) {
    flex-direction: column;
    align-items: center;
    padding: 36px 0 20px;
  }
`;

export const FooterLeftColumn = styled.div``;

export const FooterLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Serif Pro';
  font-size: 24px;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding-right: 40px;
  }
`;

export const FooterLogoImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;

export const FooterSocials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 36px 0 48px;
`;

export const FooterSocialText = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: ${globals.gray500};
`;

export const FooterSocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterSocialLink = styled(Link)`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin: 0 6px;
`;

export const FooterSubscribe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: ${globals.gray500};
  text-align: center;
`;

export const FooterSubscribeButton = styled(Button)`
  &&&&& {
    background-color: ${globals.gray400};
    padding: 10px 14px;
    border-radius: 5px;
    margin-top: 16px;
  }
`;

export const FooterNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    width: 600px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    flex-direction: column;
    width: 100%;
    border-top: 1px solid ${globals.gray700};
    margin-top: 32px;
    padding-top: 24px;
  }
`;

export const FooterNavColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${TABLET_WIDTH}px) {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`;

export const FooterNavColumnHeader = styled.div`
  text-transform: uppercase;
  font-weight: 600;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    margin-bottom: 18px;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    margin-bottom: 6px;
  }
`;

export const FooterNavColumnLinks = styled.div`
  display: flex;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    flex-direction: column;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    align-items: center;
    flex-wrap: wrap;
    margin-left: -6px;
  }
`;

export const FooterNavColumnLink = styled(Link)<{mobileOnly?: true}>`
  font-size: 15px;
  color: ${globals.gray400};
  &:hover {
    color: ${globals.gray400};
  }

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding: 6px 0;
    ${p => p.mobileOnly && `display: none;`}
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 2px 6px;
  }
`;

export const FooterNavLinkDivider = styled.div`
  background-color: ${globals.gray400};
  width: 2px;
  height: 18px;

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    display: none;
  }
`;

export const FooterFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${MAX_FOOTER_WIDTH}px;
  border-top: 1px solid ${globals.gray700};
`;

export const FooterFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 720px;
  color: ${globals.gray400};

  @media (min-width: ${TABLET_WIDTH + 1}px) {
    padding: 30px 0;
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    padding: 24px 0;
  }
`;

export const FooterFooterLeftColumn = styled.div``;

export const FooterFooterRightColumn = styled.div`
  @media (max-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

export const FooterFooterLink = styled(Link)`
  padding: 16px;
  color: ${globals.gray400};

  &:hover {
    color: ${globals.gray400};
  }
`;

export const GoldText = styled.span`
  font-family: 'Source Serif Pro';
  color: ${globals.gold};
`;

export const ClearSearchButton = styled.div`
  padding: 2px 8px;
  border-radius: 20px;
  border: 1px solid ${globals.gray400};
  margin: 0 20px;
  cursor: pointer;
`;

export const Search = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled(WBIcon)`
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 0;
  bottom: 0;
  height: 24px;
  margin: auto;
  font-size: 24px;
  color: ${globals.gray500};
`;

export const SearchInput = styled(Input)`
  flex-grow: 1;
  height: 40px;

  &&&&& input {
    padding: 0 ${SPACING / 2}px 0 ${SPACING / 2 + 28}px;
    background-color: ${globals.gray50};
    border: none;
    border-radius: 6px;
  }
`;

export const EventsContainer = styled.div`
  width: 100%;
  max-width: ${MAX_PAGE_WIDTH}px;
  background-color: ${globals.white};
`;

export const EventsControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 48px 24px;
  border-bottom: 1px solid ${globals.gray500};
`;

const eventsControlsChildStyle = css`
  width: 100%;
  &:not(:first-child) {
    margin-left: 8px;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const eventsControlsStyle = css`
  ${eventsControlsChildStyle};
  display: inline-flex;
  position: relative;
  height: 38px;
  font-size: 12px;
  border: 1px solid ${globals.gray400};
  border-radius: 0;
  line-height: 36px;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 0;

  input {
    font-family: 'Source Sans Pro';
    width: 100%;
    padding: 7px 30px 7px 11px;
    border: none;
    line-height: 36px;
    outline: 0;
    &::placeholder {
      color: ${globals.gray500};
    }
  }
`;

export const EventsControlsInput = styled(Input)`
  &&&&& {
    ${eventsControlsStyle}
  }
`;

export const EventsControlsDatetime = styled(Datetime)`
  &&&&& {
    ${eventsControlsStyle}

    .rdtPicker {
      top: 100%;
    }
  }
`;

export const EventsControlsDropdown = styled(Dropdown)`
  &&&&& {
    ${eventsControlsStyle}
  }
`;

export const EventsControlsIcon = styled(WBIcon)`
  position: absolute;
  top: 5px;
  right: 6px;
  font-size: 26px;
`;

export const EventsControlsDatetimeContainer = styled.div`
  position: relative;
  ${eventsControlsChildStyle}

  ${EventsControlsIcon} {
    top: 6px;
    right: 7px;
  }
`;

export const EventsCollection = styled.div`
  padding: 40px 24px 40px 24px;
`;

export const EventsHeader = styled.h2`
  position: relative;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 16px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 72px;
    height: 7px;
    background-color: ${globals.gold};
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    text-align: center;
    &:after {
      right: 0;
      margin: auto;
    }
  }
`;

export const EventsText = styled.p`
  margin: 32px 0 16px;
`;

export const EventEntries = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const EventEntry = styled(TargetBlank)`
  padding: 16px 8px;
  color: ${globals.gray800};
  &:hover {
    color: ${globals.gray800};
  }

  @media (min-width: ${MOBILE_WIDTH + 1}px) {
    width: 33.33%;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
  }
`;

export const EventEntryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 8px;

  @media (min-width: ${MOBILE_WIDTH + 1}px) {
    height: 160px;
  }
`;

export const EventEntryDate = styled.div`
  font-weight: 600;
  text-align: center;
`;

export const EventEntryName = styled.div`
  text-align: center;
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

export const ShowMoreButton = styled(Button)`
  &&&&& {
    display: flex;
    justify-content: center;
    background-color: transparent;
    width: 200px;
    padding: 10px 0;
    font-weight: 600;
    color: ${globals.textPrimary};
    border-color: ${globals.textPrimary};
    border-radius: 0;
  }
`;

export const EventsCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 32px;
`;

export const EventsCTAText = styled.p`
  font-size: 24px;
  font-weight: 600;
`;

export const EventsButton = styled(Button)`
  &&&&& {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${globals.gold};
    width: 100%;
    height: 38px;
    padding: 0;
    border: 2px solid ${globals.gray500};
    font-size: 20px;
    font-weight: 600;
    border-radius: 3px;
  }
`;

export const RepoList = styled.div``;

export const RepoListItem = styled(Link)`
  display: flex;
  padding: 16px;
  background-color: ${globals.white};
  border-radius: 4px;
  border: 1px solid ${globals.gray400};
  color: ${globals.gray700};
  &:hover {
    color: ${globals.gray700};
  }
  margin-top: 24px;
`;

export const RepoListItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 16px;
`;

export const RepoListItemContent = styled.div`
  flex-grow: 1;
`;

export const RepoListItemHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RepoListItemHeader = styled.div`
  font-size: 24px;
`;

export const RepoListItemStats = styled.div`
  display: flex;
`;

export const RepoListItemStat = styled.div`
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const RepoListItemBody = styled.div`
  margin-top: 8px;
`;

export const RepoListChartContainer = styled.div`
  margin-top: 24px;
`;

export const RepoPage = styled(PageWithSidebar)`
  width: 100%;
`;

export const RepoImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

export const RepoSidebarName = styled.h2`
  font-size: 32px;
  margin: 4px 0 0;
`;

export const RepoDescription = styled.div`
  margin-top: 16px;
`;

export const RepoGitHubMetadata = styled.div`
  margin-top: 48px;
`;

export const RepoGitHubMetadataRow = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const RepoGitHubMetadataIcon = styled(Icon)`
  &&&&& {
    font-size: 20px;
    margin-right: 8px;
  }
`;

export const RepoContent = styled.div`
  &&&&& {
    padding-top: 0;
  }
`;

export const RepoCustomContent = styled(Segment)`
  &&&&& {
    margin-bottom: 36px;
  }
`;

export const TopicPageHeader = styled.h2``;

export const TopicPageTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const TopicPageTag = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
`;

export const TopicPageTagImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
`;

export const TopicPageTagName = styled.div`
  margin-top: 16px;
  color: ${globals.textPrimary};
  font-size: 20px;
  font-weight: 600;
`;

export const SubscribeModal = styled(SemanticModal)`
  padding: 24px;
`;

export const InlineSubscribePrompt = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background: ${globals.gray200};
  margin: 8px;
  margin-bottom: -60px;
  padding: 20px;
  border-radius: 3px;
`;

export const SubscribeHeader = styled.h3<{inlinePrompt?: boolean}>`
  &&& {
    font-size: 28px;
    letter-spacing: 0px;

    ${props =>
      props.inlinePrompt &&
      css`
        font-size: 24px;
        font-weight: 600;
        margin: 8px 36px 8px 0;
        text-align: center;

        @media (max-width: ${MOBILE_WIDTH}px) {
          font-size: 20px;
        }

        @media (max-width: 756px) {
          margin-right: 0;
        }
      `}
  }
`;

export const SubscribeInputContainer = styled.div`
  display: flex;
  height: 40px;
`;

export const SubscribeInputMessageContainer = styled.div<{loggedIn?: boolean}>`
  position: relative;
  margin-right: 8px;
  ${props => props.loggedIn && 'display: none;'}
`;

export const SubscribeInput = styled(Input)<{invalid?: boolean}>`
  &&&&& {
    height: 40px;
    width: 195px;

    input {
      background-color: ${globals.gray50};
      border-radius: 6px;

      border: 1px solid
        ${props => (props.invalid ? globals.error : 'transparent')};
    }
  }
`;

export const SubscribeButton = styled(Button)<{inlinePrompt?: boolean}>`
  &&&&& {
    background-color: ${globals.gray300};
    width: 92px;
    padding: 10px 12px;
    margin: 0;
    color: ${globals.gray900};
    border: none;

    &:hover {
      background-color: ${globals.gray400};
    }

    ${props =>
      props.inlinePrompt &&
      css`
        background-color: ${globals.primary};
        color: white;

        &:hover {
          background-color: ${globals.primaryDark};
        }
      `}
  }
`;

export const SubscribeInvalidMessage = styled.div<{
  invalid?: boolean;
  inlinePrompt?: boolean;
}>`
  position: absolute;
  top: ${props => (props.inlinePrompt ? '95' : '100')}%;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${globals.errorText};
  ${props => !props.invalid && 'display: none;'}
`;

export const SubscribeSuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: ${globals.successText};

  i {
    margin-right: 4px;
  }
`;

export const SubscribeSection = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const SubmitPostModal = styled(SemanticModal)`
  &&&&& {
    width: auto;
  }
`;

export const SubmitPostModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 32px;
`;

export const Breadcrumbs = styled.div<{width: number}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  max-width: ${p => p.width}px;
  white-space: nowrap;
  overflow: auto;
`;

export const BreadcrumbsHeader = styled.div`
  margin-right: 4px;
`;

export const BreadcrumbsSeparator = styled.div`
  margin: 0 4px;
`;

const breadcrumbsSegmentStyle = `
  color: ${globals.gray600};
  &:hover {
    color: ${globals.gray600};
  }
`;

export const BreadcrumbsLink = styled(Link)`
  ${breadcrumbsSegmentStyle};
  text-decoration: underline;
`;

export const BreadcrumbsSpan = styled.span`
  ${breadcrumbsSegmentStyle}
`;

export const MobileNav = styled.div<{insideContainer?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${globals.gray700};
  color: ${globals.gold};
  padding: 16px 16px 16px 32px;

  ${p =>
    p.insideContainer &&
    css`
      margin: 24px -24px 0 -24px;
    `}
`;

export const MobileNavText = styled.div`
  font-size: 32px;
  font-family: 'Source Serif Pro';
`;

export const MobileNavMenuTrigger = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${globals.gold};
  height: 64px;
  width: 64px;
  cursor: pointer;
`;

export const MobileNavMenuTriggerBar = styled.div`
  background-color: ${globals.gray900};
  width: 40px;
  height: 3px;

  &:nth-child(2) {
    margin: 8px 0;
  }
`;

export const MobileNavMenu = styled.div<{active: boolean}>`
  position: absolute;
  z-index: 2;
  top: 100%;
  right: -16px;
  width: 100vw;
  margin-top: 16px;
  background-color: ${globals.gray900};

  ${p => !p.active && `display: none;`}
`;

export const MobileNavDropdownTrigger = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 24px;
  cursor: pointer;
  color: ${globals.white};
`;

export const MobileNavDropdownTriggerIcon = styled(WBIcon)`
  font-size: 32px;
  margin-left: 8px;
`;

export const MobileNavDropdown = styled.div`
  padding: 16px;
`;

export const MobileNavDropdownLink = styled(Link)`
  display: block;
  padding: 8px;
  font-size: 20px;
  color: ${globals.white};
  &:hover {
    color: ${globals.white};
  }
`;

export const MobileNavLink = styled(Link)`
  display: block;
  padding: 8px;
  font-size: 20px;
  color: ${globals.white};
  &:hover {
    color: ${globals.white};
  }
`;

export const MobileNavSearch = styled(Input)`
  &&&&& {
    width: 100%;
    height: 60px;

    input {
      border: none;
      border-bottom: 1px solid ${globals.white};
      background-color: ${globals.gray800};
      padding-left: 24px;
      padding-right: 24px;
      color: ${globals.white};
    }
  }
`;

export const MobileEvents = styled.div`
  position: relative;
  background-color: ${globals.white};
  border-radius: 8px;
  border: 1px solid ${globals.gray400};
  padding: 24px 24px 18px;
  margin-top: 16px;
`;

const mobileEventsArrowStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
`;

export const MobileEventsLeft = styled.div`
  ${mobileEventsArrowStyle};
  left: -16px;
  border-right: 32px solid ${globals.gold};
`;

export const MobileEventsRight = styled.div`
  ${mobileEventsArrowStyle};
  right: -16px;
  border-left: 32px solid ${globals.gold};
`;

export const Modal = styled(SemanticModal)`
  max-width: 600px;
`;

export const ModalWarning = styled.div`
  margin-bottom: 12px;
  font-style: italic;
`;

export const ModalField = styled.div`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const ModalLabel = styled.div`
  font-weight: bold;
`;

export const ModalInput = styled(Input)`
  &&&&& {
    display: block;
    margin-top: 8px;
  }
`;

export const ModalSelect = styled(Select)`
  &&&&& {
    display: block;
    margin-top: 8px;
  }
`;

export const ModalTextArea = styled(TextArea)`
  &&&&& {
    display: block;
    margin-top: 8px;
  }
`;

export const ModalCheckbox = styled(Checkbox)`
  &&&&& {
    margin-top: 8px;
  }
`;

export const ModalSubtext = styled.div`
  margin-top: 8px;
  font-style: italic;
`;

export const ModalHeader = styled.h3`
  font-size: 28px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
`;

export const ModalSubheader = styled.h4`
  font-size: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin: 8px 0 16px;
  font-style: italic;
`;

export const ModalLabelRequired = styled.span`
  color: ${globals.red};
`;
