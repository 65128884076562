import React, {
  Profiler,
  ProfilerOnRenderCallback,
  PropsWithChildren,
  ReactElement,
} from 'react';
import {
  datadogDebugOverride,
  envIsDev,
  frontendPerfLoggingEnabled,
} from '../config';
import {recordRender} from './recordRender';
import * as _ from 'lodash';
import {getDatadog} from './integrations';

export const PERF_LOG_PREFIX = 'WB_P'; // W&B Perf

const NETWORK_EVENT_CONTEXT = {isNetwork: true};
let userIsAdmin = false;

if (window.PerformanceObserver) {
  const observer = new PerformanceObserver(list => {
    list.getEntries().forEach(entry => {
      switch (entry.entryType) {
        case 'resource':
          if (entry.duration < MIN_NETWORK_ELAPSED_TIME_MILLIS) {
            return;
          }
          const url = entry.name;
          // GraphQL queries are logged using reportQueryPerfEvent
          if (url.endsWith('/graphql') || url.endsWith('/graphql2')) {
            return;
          }
          reportPerfObserverEvent(entry, {...NETWORK_EVENT_CONTEXT, url});
          break;
        case 'event':
        case 'first-input':
        case 'longtask':
          reportPerfObserverEvent(entry);
          break;
      }
    });
  });
  observer.observe({
    entryTypes: [],
  });
}

const reportPerfObserverEvent = (
  entry: PerformanceEntry,
  context: Record<string, any> = {}
) => {
  const perfObserverEventName =
    entry.entryType in FRIENDLY_ENTRY_TYPE_NAMES
      ? FRIENDLY_ENTRY_TYPE_NAMES[
          entry.entryType as keyof typeof FRIENDLY_ENTRY_TYPE_NAMES
        ]
      : entry.entryType;

  reportTimedEvent(
    // duration is in millis. We use floor because more precision isn't useful
    Math.floor(entry.duration),
    Math.floor(entry.startTime), // note that this will not use the same value for 0 as perfStartTimeMs
    'PerfObserver: ' + perfObserverEventName,
    context
  );
};

const FRIENDLY_ENTRY_TYPE_NAMES = {
  // A long task is any uninterrupted period where the main UI thread is busy for 50ms or longer
  // note that longtask and event/first-input will overlap and report the same issue multiple times
  longtask: 'UI thread busy',
  // resource performance event occurs when a network request takes a long time to fulfill
  resource: 'Network',
  // event and first-input both measure how long it takes for the app to respond to input from the user
  // the value of 'duration' is the time from startTime to the next rendering paint.
  event: 'Slow UI response time',
  'first-input': 'First input delay',
} as const;

const perfConsoleLog = (...args: any[]) => {
  if (!frontendPerfLoggingEnabled() || args.length < 1) {
    return;
  }
  // to preserve any colors set in the text, we need the first arg to be
  // kept in the first position
  console.log(`${PERF_LOG_PREFIX} ${args[0]}`, ...args.slice(1));
};

// We don't use performance.mark()/.measure() because per
// https://3perf.com/blog/react-monitoring/ it is memory intensive
export const perfEvent = (name: string, context?: Record<string, any>) => {
  const perfNow = Date.now();
  perfConsoleLog(
    `@${perfNow - pageStats.perfStartTimeMs}ms`,
    name,
    context ?? ''
  );
};

export const updateWorkspaceStats = (data: Partial<WorkspaceStats>) => {
  pageStats.workspace = {...pageStats.workspace, ...data};
  if (
    pageStats.workspace.numRuns != null &&
    pageStats.workspace.numKeys != null &&
    pageStats.workspace.numSteps != null
  ) {
    const workspaceData: Record<string, any> = {...pageStats.workspace};
    workspaceData[PERF_LOG_PREFIX] = true;

    const ddLogger = getDatadog(userIsAdmin);
    if (ddLogger) {
      ddLogger.logger.info(`${PERF_LOG_PREFIX} PerfStats: `, workspaceData);
    }
  }
};

export const perfStat = (
  name: string,
  value: number,
  interestingThreshold: number
) => {
  if (value > interestingThreshold) {
    const logLine = `PerfStat ${name}:${value}`;
    perfConsoleLog(logLine);

    const ddLogger = getDatadog(userIsAdmin);
    if (ddLogger) {
      const context = {} as any;
      context[name] = value;
      ddLogger.logger.info(logLine, context);
    }
  }
};

interface WorkspaceStats {
  numRuns: number;
  numKeys: number;
  numSteps: number;
}

interface PageStats {
  perfStartTimeMs: number;
  workspace: Partial<WorkspaceStats>;
}

const getDefaultPageStats = (): PageStats => {
  return {
    // We use Date.now() for the perfEvent work, only because it's slightly cheaper than
    // performance.now(), but switching would be very reasonable
    perfStartTimeMs: Date.now(),
    workspace: {},
  };
};

let pageStats = getDefaultPageStats();

export const startProfilerPageView = () => {
  pageStats = getDefaultPageStats();

  perfConsoleLog('Profiler page view start');
};

export const updateProfilerContext = (
  entityName: string | undefined,
  projectName: string | undefined,
  isAdmin: boolean | undefined
) => {
  userIsAdmin = isAdmin ?? false;
  const dd = getDatadog(userIsAdmin);
  // when value is undefined, it won't include that in context
  dd?.addLoggerGlobalContext('entityName', entityName);
  dd?.addLoggerGlobalContext('projectName', projectName);
};

interface TimedEvent {
  name: string;
  context?: Record<string, any>;
  startTimeMs: number;
}

export const SLOW_ELAPSED_TIME_MILLIS = datadogDebugOverride() ? 1 : 200;
// network events are generally slower, so don't report them all.
export const MIN_NETWORK_ELAPSED_TIME_MILLIS = 1000;
export const MIN_REPORT_TO_SERVER_TIME_MILLIS = datadogDebugOverride()
  ? 1
  : 500;

const timedEvents = new Map<string, TimedEvent>();

const getTimedEventKey = (name: string) => _.uniqueId(name);

const startTimedEvent = (eventKey: string, event: TimedEvent) => {
  if (timedEvents.has(eventKey)) {
    console.error(PERF_LOG_PREFIX, 'non-unique key:', eventKey);
  }
  timedEvents.set(eventKey, event);
  return eventKey;
};

export const reportQueryPerfEvent = (
  elapsedMs: number,
  startMs: number,
  operationName: string
) => {
  if (elapsedMs < MIN_NETWORK_ELAPSED_TIME_MILLIS) {
    return;
  }
  reportTimedEvent(
    elapsedMs,
    startMs - pageStats.perfStartTimeMs,
    'GraphQL: ' + operationName,
    NETWORK_EVENT_CONTEXT
  );
};

const reportTimedEvent = (
  elapsedMs: number,
  relativeStartTimeMs: number,
  name: string,
  context?: Record<string, any>
) => {
  if (elapsedMs <= 1) {
    // short circuit for this common case
    return;
  }

  let severityColor = 'black';
  if (elapsedMs > 500) {
    severityColor = 'blue';
  }
  if (elapsedMs > 1000) {
    severityColor = 'orange';
  }
  if (elapsedMs > 5000) {
    severityColor = 'red';
  }
  if (elapsedMs > SLOW_ELAPSED_TIME_MILLIS) {
    perfConsoleLog(
      `%cSlow event, started @${relativeStartTimeMs}ms, elapsed: ${elapsedMs}ms: ${name} `,
      'color: ' + severityColor + ';',
      context ?? ''
    );
  }

  const ddLogger = getDatadog(userIsAdmin);
  if (ddLogger && elapsedMs >= MIN_REPORT_TO_SERVER_TIME_MILLIS) {
    ddLogger.logger.warn(`${PERF_LOG_PREFIX} Slow client event`, {
      perfEvent: name,
      elapsedMs,
      ...context,
    });
  }
};

const endTimedEvent = (key: string) => {
  const nowMs = Date.now();
  const event = timedEvents.get(key);
  if (!event) {
    console.error(PERF_LOG_PREFIX, 'endTimedEvent key not found!', key);
    return;
  }
  const elapsedMs = nowMs - event.startTimeMs;
  const relativeStartTimeMs = event.startTimeMs - pageStats.perfStartTimeMs;
  reportTimedEvent(elapsedMs, relativeStartTimeMs, event.name, event.context);
  timedEvents.delete(key);
};

export const startTimedPerfEvent = (
  name: string,
  context?: Record<string, any>
): (() => void) => {
  const key = getTimedEventKey(name);
  startTimedEvent(key, {name, context, startTimeMs: Date.now()});

  return () => {
    endTimedEvent(key);
  };
};

// This is copied straight from React.FC but excludes the additional properties like displayName.
// We need to isolate the function call signature because otherwise TypeScript higher-order type inference breaks.
// See https://github.com/microsoft/TypeScript/pull/30215#issue-258109340
// Specifically, this is one of the requirements for it to work:
// "the called function is a generic function that returns a function type with a single call signature"
type FC<P = {}> = (
  props: PropsWithChildren<P>,
  context?: any
) => ReactElement<any, any> | null;

const onRenderCallback: ProfilerOnRenderCallback = (
  id,
  phase,
  actualDuration,
  baseDuration,
  startTime,
  commitTime,
  interactions
) => {
  recordRender(id, actualDuration);
};

function withProfiler<T>(id: string, Comp: FC<T>): FC<T> {
  const withProf: typeof Comp = props => {
    return (
      <Profiler id={id} onRender={onRenderCallback}>
        <Comp {...props} />
      </Profiler>
    );
  };
  (withProf as React.FC<T>).displayName = `${id}WithProfiler`;
  return withProf;
}

type PropsAreEqual<T> = (
  prevProps: Readonly<PropsWithChildren<T>>,
  nextProps: Readonly<PropsWithChildren<T>>
) => boolean;

interface MakeCompOpts<T> {
  id: string;
  memo?: true | PropsAreEqual<T>;
  disableProfiler?: true;
}

function makeComp<T>(
  Comp: FC<T>,
  {id, memo, disableProfiler}: MakeCompOpts<T>
): FC<T> {
  (Comp as React.FC<T>).displayName = id;

  if (false && envIsDev && !disableProfiler) {
    Comp = withProfiler(id, Comp);
  }

  if (memo) {
    Comp = React.memo(
      Comp,
      memo !== true ? memo : undefined
    ) as unknown as typeof Comp;
  }

  return Comp;
}

export default makeComp;
