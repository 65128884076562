import * as PanelRegistry from '@wandb/weave-ui/components/Panel2/PanelRegistry';

import {Spec as PanelArtifact} from './artifact/PanelArtifact/index';

const domainPanels = [PanelArtifact];

let DID_LOAD_PANELS = false;

// load custom panels
export const useWeaveInit = () => {
  if (!DID_LOAD_PANELS) {
    for (const panel of domainPanels) {
      PanelRegistry.registerPanel(panel);
    }
    DID_LOAD_PANELS = true;
  }
};
