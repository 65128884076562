import * as globals from '@wandb/common/css/globals.styles';
import {Checkbox, Header, Table} from 'semantic-ui-react';
import styled from 'styled-components';

export const SettingsContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export const SettingsWrapper = styled.div`
  padding: 2em;
`;

export const NotificationsContainer = styled.div`
  padding: 2em;
  border: 1px solid lightgrey;
  border-radius: 8px;
  max-width: 700px;
`;

export const NotificationsTable = styled.div`
  max-width: 700px;
`;

export const SectionHeader = styled(Header)`
  font-size: 20px;
`;

export const HeaderCellBold = styled(Table.HeaderCell)`
  font-weight: 600 !important;
`;

export const Toggle = styled(Checkbox)`
  &&& {
    > *:before {
      background-color: ${props => {
        if (props.$colorTheme === 'dark') {
          if (props.checked) {
            return `${globals.primaryLight} !important`;
          }
          return `${globals.gray100alpha38} !important`;
        }
        if (props.checked) {
          return `${globals.primary} !important`;
        }
        return '#d9d9d9 !important';
      }};
    }
  }
`;
