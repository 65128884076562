import {BasicValue} from './runs';

/**
 * Returns an artifact membership identifier based on any of the
 * available properties.
 */
export const makeArtifactMembershipIdentifier = (
  commitHash?: string | null,
  versionIndex?: number | null,
  digest?: string | null
): string => {
  if (commitHash != null) {
    return commitHash;
  } else if (versionIndex != null) {
    return `v${versionIndex}`;
  } else if (digest != null) {
    return digest;
  } else {
    throw new Error(
      'At least one of commitHash, versionIndex or digest must be specified'
    );
  }
};

/**
 * Determines if an artifact membership identifier is a version alias
 * or not, i.e is a string of the form "v{number}"
 */
export const isVersionAlias = (identifier: string | undefined): boolean => {
  return (
    typeof identifier === 'string' &&
    identifier.length >= 1 &&
    identifier[0] === 'v' &&
    !isNaN(parseInt(identifier.slice(1)[0], 10))
  );
};

export type ArtifactKeySection = 'artifact';

export type Value = BasicValue | BasicValue[];

export interface Key {
  section: ArtifactKeySection;
  name: string;
}

export function displayKey(k: Key) {
  if (k.section && k.name !== '') {
    if (k.section === 'artifact') {
      return k.name;
    } else {
      return k.section + ':' + k.name;
    }
  } else {
    return '-';
  }
}

export function keyToServerPath(k: Key) {
  if (k.section === 'artifact') {
    return k.name;
  }
  throw new Error('keyToServerPath error');
}

export function keyToString(k: Key) {
  return k.section + ':' + k.name;
}
