import {urlPrefixed} from '@wandb/common/config';
import React from 'react';
import {Editor, Element, Node, Path, Point, Range, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from 'slate-react';

import {useSelector} from '../../../state/hooks';
import {useUploadMarkdownImage} from '../../../util/images';
import {ReportContent} from '../../../util/reportExport';
import {useResizer} from '../../../util/resize';
import {WBSlateReduxBridgeContext} from '../WBSlateReduxBridge';
import {BlockWrapper, useDrag} from './drag-drop';
import * as S from './images.styles';

export interface Image extends Element {
  type: 'image';
  href?: string; // href the image links to (if linked image)
  url?: string; // image src
  file?: File;
  hasCaption?: boolean;
  width?: number;
}

export const isImage = (node: Node): node is Image => node.type === 'image';

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = _e => resolve(reader.result as string); // tslint:disable-line
    reader.onerror = _e => reject(reader.error); // tslint:disable-line
    reader.onabort = _e => reject(new Error('Read aborted')); // tslint:disable-line
    reader.readAsDataURL(blob);
  });
}

export const imageBlockToReportContent = async (
  node: Image,
  sectionIndex: number
): Promise<ReportContent | undefined> => {
  const imageName = `Section-${sectionIndex}`;
  try {
    const res = await fetch(urlPrefixed(node.url!));
    const blob = await res.blob();
    const imageDataURL = await blobToDataURL(blob);
    if (imageDataURL) {
      return {
        type: 'img',
        name: imageName,
        data: imageDataURL,
      };
    }
  } catch {
    console.warn('Unable to render image: ', node.url);
  }
  return undefined;
};

export const ImageElement: React.FC<RenderElementProps & {element: Image}> = ({
  attributes,
  element,
  children,
}) => {
  const selected = useSelected();
  const editor = useSlateStatic();
  const readOnly = useReadOnly();

  const {viewId, viewRef} = React.useContext(WBSlateReduxBridgeContext);
  const view = useSelector(state =>
    viewRef != null ? state.views.views[viewRef.id] : null
  );
  const publicAccess = view?.project == null || !view.project.readOnly;

  const {uploadImages, uploadState, setUploadState} = useUploadMarkdownImage(
    viewId,
    publicAccess
  );

  const [draggingImageOver, setDraggingImageOver] = React.useState(false);

  React.useEffect(() => {
    if (uploadState == null) {
      return;
    }

    const {url} = uploadState;

    if (url == null || element.url === url) {
      return;
    }

    Transforms.setNodes(
      editor,
      {url, file: undefined},
      {at: ReactEditor.findPath(editor, element)}
    );
  }, [editor, element, uploadState]);

  // from drag and drop or paste
  const {file} = element;
  React.useEffect(() => {
    if (file == null) {
      return;
    }

    uploadImages([file as File]);
  }, [file, uploadImages]);

  const {sourceAttributes, handleAttributes} = useDrag(element, {
    onHandleMouseDown() {
      if (readOnly) {
        return;
      }

      Transforms.select(editor, ReactEditor.findPath(editor, element));
    },
  });

  const {size, setSize, dropSize, cursor, onMouseDown, resizing} = useResizer(
    'right',
    element.width || 0,
    {deltaMultiplier: 2, min: 24}
  );

  const imageRef = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    if (size === 0) {
      return;
    }

    Transforms.setNodes(
      editor,
      {width: size},
      {at: ReactEditor.findPath(editor, element)}
    );
  }, [size, element, editor]);

  if (
    element.url == null ||
    uploadState?.uploading ||
    uploadState?.error != null
  ) {
    return (
      <BlockWrapper attributes={attributes} element={element}>
        <div contentEditable={false}>
          <S.ImagePlaceholderWrapper
            selected={selected}
            draggingImageOver={draggingImageOver}>
            <S.ImagePlaceholder>
              <S.ImagePlaceholderIcon />
              <span>
                {uploadState?.uploading
                  ? `Uploading ${uploadState.name}...`
                  : uploadState?.error != null
                  ? `Error: ${uploadState.error}`
                  : 'Drag an image here'}
              </span>
            </S.ImagePlaceholder>
            <S.ImagePlaceholderInput
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onDragEnter={e => {
                // Hack for Safari because it doesn't support dataTransfer.items
                if (e.dataTransfer.items.length === 0) {
                  e.stopPropagation();
                  setDraggingImageOver(true);
                }

                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                  if (e.dataTransfer.items[i].type.includes('image')) {
                    e.stopPropagation();
                    setDraggingImageOver(true);
                    break;
                  }
                }
              }}
              onDragLeave={e => {
                if (draggingImageOver) {
                  e.stopPropagation();
                  setDraggingImageOver(false);
                }
              }}
              onDrop={e => {
                if (draggingImageOver) {
                  e.stopPropagation();
                  setDraggingImageOver(false);
                }
              }}
              onChange={e => {
                const {files} = e.target;

                if (files == null) {
                  return;
                }

                uploadImages(Array.from(files));
              }}></S.ImagePlaceholderInput>
          </S.ImagePlaceholderWrapper>
        </div>
        {children}
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.ImageOuterWrapper>
        <S.ImageWrapper>
          <div contentEditable={false} style={{position: 'relative'}}>
            {(() => {
              const content = (
                <S.Image
                  {...sourceAttributes}
                  {...handleAttributes}
                  selected={selected}
                  src={element.url}
                  ref={imageRef}
                  width={element.width == null ? undefined : dropSize}
                  onDragStart={e => {
                    if (readOnly) {
                      e.stopPropagation();
                    }
                  }}
                  onError={() => {
                    if (!readOnly) {
                      setUploadState({
                        error: "This image couldn't be loaded.",
                        uploading: false,
                        name: 'Failed to load',
                      });
                    }
                  }}
                />
              );

              if (element.href == null) {
                return content;
              } else {
                return (
                  <S.LinkedImageWrapper href={element.href} target="_blank">
                    {content}
                  </S.LinkedImageWrapper>
                );
              }
            })()}
            {!readOnly && (
              <S.ResizeHandleWrapper
                cursor={cursor}
                resizing={resizing}
                onMouseDown={e => {
                  if (imageRef.current != null) {
                    onMouseDown(e);
                    setSize(imageRef.current.clientWidth);
                  }
                }}>
                <S.ResizeHandle />
              </S.ResizeHandleWrapper>
            )}
          </div>
          {element.hasCaption ? (
            <S.ImageCaption
              empty={Editor.isEmpty(editor, element)}
              selected={selected}>
              {children}
            </S.ImageCaption>
          ) : (
            <>
              {!readOnly && selected && (
                <S.AddCaptionButton
                  contentEditable={false}
                  onMouseDown={e => {
                    e.preventDefault();
                    Transforms.setNodes(
                      editor,
                      {hasCaption: true},
                      {at: ReactEditor.findPath(editor, element)}
                    );
                  }}>
                  Write a caption...
                </S.AddCaptionButton>
              )}
              {children}
            </>
          )}
        </S.ImageWrapper>
      </S.ImageOuterWrapper>
    </BlockWrapper>
  );
};

export const withImages = <T extends Editor>(editor: T) => {
  const {isVoid, insertData, insertBreak, deleteBackward, normalizeNode} =
    editor;

  editor.isVoid = element => {
    return isImage(element) ? !element.hasCaption : isVoid(element);
  };

  editor.insertData = data => {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.files.length; i++) {
      const file = data.files[i];
      if (file.type.includes('image')) {
        EditorWithImages.insertImageFromData(editor, file);
        return;
      }
    }

    insertData(data);
  };

  editor.insertBreak = () => {
    const imageEntry = Editor.above(editor, {match: n => isImage(n)});
    if (imageEntry != null && (imageEntry[0] as any).hasCaption) {
      Transforms.splitNodes(editor, {always: true});
      Transforms.setNodes(editor, {type: 'paragraph'});
      return;
    }

    insertBreak();
  };

  editor.deleteBackward = (...args) => {
    const imageEntry = Editor.above(editor, {match: n => isImage(n)});

    if (imageEntry != null && Editor.isEmpty(editor, imageEntry[0])) {
      Transforms.unsetNodes(editor, ['hasCaption'], {at: imageEntry[1]});

      // hacky selection reset because setting voids breaks Slate's selection synchronization
      Transforms.deselect(editor);
      window.setTimeout(() => {
        Transforms.select(editor, imageEntry[1]);
      });
      return;
    }

    deleteBackward(...args);
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (isImage(node)) {
      if (node.file != null && !(node.file instanceof Blob)) {
        // Some situations (like an incomplete upload) can cause the draft spec
        // to be saved with the file property still set. JSON can't serialize it,
        // so it gets saved as a plain object. On reload, this object is treated
        // as a File, which can cause a crash.
        // See https://wandb.atlassian.net/browse/WB-5750
        Transforms.unsetNodes(editor, 'file', {at: path});
        return;
      }
    }

    normalizeNode(entry);
  };

  return editor;
};

interface EditorWithImagesInterface {
  insertImageFromData(
    editor: Editor,
    file: File,
    options?: {
      at?: Point | Range | Path | undefined;
      clone?: boolean;
    }
  ): void;
}

export const EditorWithImages: EditorWithImagesInterface = {
  insertImageFromData(editor, file, options = {}) {
    const {clone = true, ...insertOptions} = options;
    Transforms.insertNodes(
      editor,
      {
        type: 'image',
        children: [{text: ''}],
        file: clone ? new File([file], file.name, {type: file.type}) : file,
      },
      insertOptions
    );
  },
};
