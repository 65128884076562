import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {WBMenuOption, WBPopupMenuTrigger} from '@wandb/ui';
import React, {FC, useContext} from 'react';
import {toast} from 'react-toastify';

import {useRevokeAccessTokenEmailMutation} from '../../generated/graphql';
import {propagateErrorsContext} from '../../util/errors';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';

const INDIVIDUAL_SHARING_CAN_VIEW_VALUE = 'view';
const INDIVIDUAL_SHARING_REVOKE_VALUE = 'revoke';
const INDIVIDUAL_SHARING_OPTIONS: WBMenuOption[] = [
  {name: 'can view', value: INDIVIDUAL_SHARING_CAN_VIEW_VALUE},
  {name: 'revoke access', value: INDIVIDUAL_SHARING_REVOKE_VALUE},
];

export const SharedWithIndividualsSection: FC = () => {
  const {
    canChangeSharingSettings,
    currentIndividualSharingAccessToken,
    updateViewAccessToken,
    view,
  } = useContext(ShareReportTriggerContext);

  const [revokeAccessTokenEmail] = useRevokeAccessTokenEmailMutation({
    context: propagateErrorsContext(),
  });

  const revokeIndividualAccess = async (email: string) => {
    let result;
    try {
      result = await revokeAccessTokenEmail({
        variables: {
          token: currentIndividualSharingAccessToken!.token,
          email,
        },
      });
    } catch (err) {
      const errorMsg: string =
        (err as any)?.networkError?.result?.errors?.[0]?.message ??
        'An error occurred - please check that the email/username is valid';
      toast(errorMsg);
      return;
    }
    const accessToken = (result?.data as any)?.revokeAccessTokenEmail
      ?.accessToken;
    if (accessToken != null) {
      updateViewAccessToken(accessToken);
      toast(`Unshared with ${email}`);
      window.analytics?.track?.('Report unshared with individual', {
        reportId: view.cid,
        unsharedWithUserEmail: email,
      });
    }
  };

  return (
    <>
      {currentIndividualSharingAccessToken != null &&
        currentIndividualSharingAccessToken.emails.map(email => (
          <S.RowWrapper key={email}>
            <S.IconTextWrapper>
              <S.GrayCircleWBIcon name="username" />
              <S.EmailText title={email}>{email}</S.EmailText>
            </S.IconTextWrapper>
            <S.ShareOptionsWrapper>
              <WBPopupMenuTrigger
                options={INDIVIDUAL_SHARING_OPTIONS}
                selected={INDIVIDUAL_SHARING_CAN_VIEW_VALUE}
                onSelect={() => revokeIndividualAccess(email)}>
                {({anchorRef, open, setOpen}) => (
                  <S.Tooltip
                    on="hover"
                    inverted
                    position="top center"
                    size="mini"
                    content={
                      canChangeSharingSettings
                        ? ''
                        : 'Only team admin or author can change access permissions'
                    }
                    hidden={canChangeSharingSettings}
                    trigger={
                      <S.DropdownTrigger
                        disabled={!canChangeSharingSettings}
                        ref={anchorRef}
                        onClick={() => {
                          if (canChangeSharingSettings) {
                            setOpen(o => !o);
                          }
                        }}>
                        can view{' '}
                        <LegacyWBIcon name={open ? 'chevron-up' : 'down'} />
                      </S.DropdownTrigger>
                    }
                  />
                )}
              </WBPopupMenuTrigger>
            </S.ShareOptionsWrapper>
          </S.RowWrapper>
        ))}
    </>
  );
};
