import {createAction} from 'typesafe-actions';

import * as Api from './api';
import * as Types from './types';

// Internal Actions
export const loadMetadataListFinished = createAction(
  '@view/loadMetadataListFinished',
  action => (id: string, result: Api.LoadMetadataListResultType) =>
    action({id, result})
);

export const unloadMetadataList = createAction(
  '@view/unloadMetadatalist',
  action => (id: string) => action({id})
);

export const unloadView = createAction(
  '@view/unloadView',
  action => (id: string) => action({id})
);

export const loadStarted = createAction(
  '@view/loadStarted',
  action => (id: string) => action({id})
);

export const loadFinished = createAction(
  '@view/loadFinished',
  action => (id: string, result: Api.LoadResultType, autoSave: boolean) =>
    action({id, result, autoSave})
);

export const addNormalizedPanelGrid = createAction(
  '@view/addNormalizedPanelGrid',
  action => (id: string, partsWithRefs: any) => action({id, partsWithRefs})
);

export const removeNormalizedPanelGrid = createAction(
  '@view/removeNormalizedPanelGrid',
  action => (id: string, sectionRef: any) => action({id, sectionRef})
);

export const markModified = createAction(
  '@view/markModified',
  action => (id: string) => action({id})
);

export const saveStarted = createAction(
  '@view/saveStarted',
  action => (id: string) => action({id})
);

export const saveFinished = createAction(
  '@view/saveFinished',
  action => (id: string, result: Api.SaveResultType) => action({id, result})
);

export const saveFailed = createAction(
  '@view/saveFailed',
  action => (id: string) => action({id})
);

// This replaces the whole view, it's used to copy other workspaces into the
// user's current workspace. It's in this internal file because other modules
// should use the thunk instead.
export const updateViewSpec = createAction(
  '@view/updateViewSpec',
  action => (cid: string, spec: any) => action({cid, spec})
);

// Similarly, deleting objects is dangerous, so most modules should use the thunk instead.
export const deleteObject = createAction(
  '@view/deleteObject',
  action => (ref: Types.AllPartRefs) => action({ref})
);

export const deleteHistoryForObject = createAction(
  '@view/deleteHistoryForObject',
  action => (ref: Types.AllPartRefs) => action({ref})
);

// Note: This probably isn't strictly necessary and might be covered already by other cleanup
// logic, but in case we mess up cleanup somewhere along the line, having bad undo/redo state
// is more pernicious than orphaned view parts.
export const deleteUndoRedoHistory = createAction(
  '@view/deleteUndoRedoHistory',
  action => () => action()
);

export const starViewStarted = createAction(
  '@view/starViewStarted',
  action => (id: string) => action({id})
);

export const starViewFinished = createAction(
  '@view/starViewFinished',
  action => (id: string, starCount: number) => action({id, starCount})
);

export const unstarViewStarted = createAction(
  '@view/unstarViewStarted',
  action => (id: string) => action({id})
);

export const unstarViewFinished = createAction(
  '@view/unstarViewFinished',
  action => (id: string, starCount: number) => action({id, starCount})
);
