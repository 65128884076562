import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import * as Redux from '../../../types/redux';
import {Ref} from './types';

export function useAllPanelBankSectionConfigsAction<
  RefType extends Ref,
  ArgType extends any[]
>(
  panelBankSectionConfigRefs: RefType[],
  fn: (
    panelBankSectionConfigRefs: RefType[],
    ...fnArgs: ArgType
  ) => Redux.DispatchableAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(panelBankSectionConfigRefs, ...args)),
    [dispatch, panelBankSectionConfigRefs, fn]
  );
}
