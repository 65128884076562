import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import classNames from 'classnames';
import React, {FC, memo, useMemo, useState} from 'react';
import Measure from 'react-measure';

import * as ReportsTypes from '../../state/reports/types';
import * as RunsHooks from '../../state/runs/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelBankConfigActions from '../../state/views/panelBankConfig/actions';
import * as PanelSettingsViewTypes from '../../state/views/panelSettings/types';
import * as SectionViewTypes from '../../state/views/section/types';
import {getSectionsWithVisiblePanels} from '../../util/panelbank';
import {GRID_ITEM_MARGIN, GRID_ROW_HEIGHT} from '../../util/panelbankGrid';
import {keyExistsInQueryString} from '../../util/query-string';
import {CreateReportModal} from '../CreateReportModal';
import {PanelContainer} from '../Panel';
import {PanelBankGridSection} from '../PanelBank';
import {RunSets} from '../RunSets';
import {useReportSectionProps} from './hooks';
import {ReportPanelGridActions} from './ReportPanelGridActions';
import * as S from './ReportPanelGridSection.styles';
import {IMPORT_PANEL_QS, REPORT_PANEL_GRID_MENU} from './types';

export type ReportPanelGridSectionProps = {
  className?: string;
  entityName: string;
  projectName: string;
  panelSettingsRef: PanelSettingsViewTypes.Ref;
  sectionRef: SectionViewTypes.Ref;
  viewRef: ReportsTypes.ReportViewRef;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  onRunSetHeightChange?(): void;
};

const ReportPanelGridSectionComp: FC<ReportPanelGridSectionProps> = props => {
  const {
    className,
    entityName,
    projectName,
    panelSettingsRef,
    sectionRef,
    viewRef,
    readOnly,
    disableRunLinks,
    onRunSetHeightChange,
  } = props;
  const {activePanelRefs, sectionPart} = useReportSectionProps(props);

  const [panelBankWidth, setPanelBankWidth] = useState(0);
  const movePanel = ViewHooks.useViewAction(
    sectionPart.panelBankConfigRef,
    PanelBankConfigActions.movePanel
  );
  // make sure this is well memoized or the panel components will rerender
  // every update
  const panelSettings = ViewHooks.useWhole(panelSettingsRef);

  const useExportableSections = () => {
    const panelBankSectionConfig = ViewHooks.useWhole(
      sectionPart.panelBankSectionConfigRef
    );
    return useMemo(
      () => getSectionsWithVisiblePanels([panelBankSectionConfig]),
      [panelBankSectionConfig]
    );
  };

  // To control the grid panel menu popups
  const initialOpenedMenu = keyExistsInQueryString(IMPORT_PANEL_QS)
    ? REPORT_PANEL_GRID_MENU.IMPORT_PANEL
    : undefined;
  const [openedMenu, setOpenedMenu] = useState<
    REPORT_PANEL_GRID_MENU | undefined
  >(initialOpenedMenu);

  // Make a history keys query so the state is in redux.
  // This allows query merging for panels in the section.
  const keyInfoQuery = RunsHooks.useKeyInfoQuery(sectionPart.runSetRefs);
  if (keyInfoQuery.error) {
    return (
      <div className="report-section">
        <S.EmptyReportSection>
          This set of panels contains runs from a private project, which cannot
          be shown in this report
        </S.EmptyReportSection>
      </div>
    );
  }

  return (
    <div className={classNames('report-section', 'panel-bank', className)}>
      <Measure
        bounds
        onResize={contentRect => {
          setPanelBankWidth(contentRect.bounds ? contentRect.bounds.width : 0);
        }}>
        {({measureRef}) => (
          <>
            <div className="grid-section-wrapper">
              <div ref={measureRef}>
                {panelBankWidth > 0 && (
                  <PanelBankGridSection
                    readOnly={readOnly}
                    panelBankSectionConfigRef={
                      sectionPart.panelBankSectionConfigRef
                    }
                    panelBankWidth={panelBankWidth}
                    gridItemMargin={[-1, -1]}
                    gridRowHeight={GRID_ROW_HEIGHT + GRID_ITEM_MARGIN[1]}
                    gridContainerPadding={[0, 0]}
                    showGridDots={!readOnly}
                    hideEmptyWatermark
                    onClickGrid={() => {
                      if (openedMenu !== REPORT_PANEL_GRID_MENU.ADD_PANEL) {
                        // HAX: we need the setTimeout to ensure this runs
                        // after the add vis Popup's onClose handler
                        setTimeout(() =>
                          setOpenedMenu(REPORT_PANEL_GRID_MENU.ADD_PANEL)
                        );
                      }
                    }}
                    // TODO: re-enable this??
                    // singleRun={singleRun}
                    activePanelRefs={activePanelRefs}
                    inactivePanelRefs={[]}
                    movePanelBetweenSections={movePanel}
                    renderPanel={(panelRef, onContentHeightChange) => {
                      return (
                        <PanelContainer
                          customRunColorsRef={sectionPart.customRunColorsRef}
                          disableRunLinks={disableRunLinks}
                          historyKeyInfo={
                            !keyInfoQuery.loading && keyInfoQuery.error == null
                              ? keyInfoQuery.historyKeyInfo
                              : undefined
                          }
                          isReadOnly={readOnly}
                          onContentHeightChange={onContentHeightChange}
                          panelBankSectionConfigRef={
                            sectionPart.panelBankSectionConfigRef
                          }
                          panelRef={panelRef}
                          panelSettings={panelSettings}
                          runSetRefs={sectionPart.runSetRefs}
                          shouldTurnOnComments
                        />
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {!readOnly && (
              <ReportPanelGridActions
                entityName={entityName}
                projectName={projectName}
                sectionPart={sectionPart}
                panelSettings={panelSettings}
                viewRef={viewRef}
                openedMenu={openedMenu}
                setOpenedMenu={setOpenedMenu}
              />
            )}
            {!(readOnly && sectionPart.hideRunSets) && (
              <RunSets
                entityName={entityName}
                projectName={projectName}
                sectionRef={sectionRef}
                readOnly={readOnly}
                disableRunLinks={disableRunLinks}
                onHeightChange={onRunSetHeightChange}
                isInReport={true}
              />
            )}
          </>
        )}
      </Measure>
      <CreateReportModal
        useExportableSections={useExportableSections}
        panelSettingsRef={sectionPart.panelSettingsRef}
        runSetRefs={sectionPart.runSetRefs}
        customRunColorsRef={sectionPart.customRunColorsRef}
      />
    </div>
  );
};

export const ReportPanelGridSection = memo(
  ReportPanelGridSectionComp,
  makePropsAreEqual({
    name: 'ReportPanelGridSection',
    deep: ['config', 'customRunColors', 'panelSettings', 'queries'],
    ignoreFunctions: false,
    debug: false,
    verbose: true,
  })
);
