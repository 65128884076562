import React from 'react';
import produce from 'immer';
import {useState, useCallback} from 'react';
import * as Panel2 from './panel';
import * as CGReact from '../../cgreact';
import {Panel2Loader} from './PanelComp';
import {ChildPanelConfigComp, ChildPanel} from './ChildPanel';
import {Outline} from '../Sidebar/Outline';
import {PanelContextProvider} from './PanelContext';

const inputType = {type: 'Panel' as const};
type PanelPanelProps = Panel2.PanelProps<typeof inputType, any>;

export const PanelPanelConfig: React.FC<PanelPanelProps> = props => {
  const {updateConfig, updateConfig2} = props;
  const panelQuery = CGReact.useNodeValue(props.input);

  const defaultConfig = panelQuery.result as any;

  const [selectedPanel, setSelectedPanel] = useState<string[]>([]);
  const panelConfig = props.config ?? defaultConfig;

  const panelUpdateConfig = useCallback(
    (newConfig: any) => {
      updateConfig({...panelConfig, ...newConfig});
    },
    [updateConfig, panelConfig]
  );
  const panelUpdateConfig2 = useCallback(
    (change: <T>(oldConfig: any) => Partial<T>) => {
      if (updateConfig2 == null) {
        return;
      }
      updateConfig2((currentConfig: any) => {
        currentConfig = currentConfig ?? defaultConfig;
        const configChanges = change(currentConfig);
        const newConfig = produce(currentConfig, (draft: any) => {
          for (const key of Object.keys(configChanges)) {
            (draft as any)[key] = (configChanges as any)[key];
          }
        });
        return newConfig;
      });
    },
    [updateConfig2, defaultConfig]
  );

  if (panelQuery.loading) {
    return <Panel2Loader />;
  }
  if (panelQuery.result == null) {
    return <div>-</div>;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: 'auto',
        // textAlign: 'center',
        wordBreak: 'normal',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-around',
        justifyContent: 'space-around',
        // alignItems: 'center',
      }}>
      <div style={{flex: '0 0 200px', overflowY: 'scroll'}}>
        <div style={{borderBottom: '1px solid #eee'}}>Outline</div>
        <Outline
          config={panelConfig}
          updateConfig={updateConfig}
          setSelected={setSelectedPanel}
          selected={selectedPanel}
        />
      </div>
      <div style={{flex: '1 0 auto', borderTop: '1px solid #aaa'}}>
        <div>Inspector</div>
        <PanelContextProvider newVars={{}} selectedPath={selectedPanel}>
          <ChildPanelConfigComp
            config={panelConfig}
            updateConfig={panelUpdateConfig}
            updateConfig2={panelUpdateConfig2}
          />
        </PanelContextProvider>
      </div>
      {/* <PanelContextProvider newVars={{config: props.input_node}}>
        <PanelComp2
          input={inputNode}
          inputType={'none'}
          loading={false}
          panelSpec={panelSpec}
          configMode={false}
          context={props.context}
          config={panelConfig}
          updateConfig={updateConfig}
          updateContext={props.updateContext}
          noPanelControls
        />
      </PanelContextProvider> */}
    </div>
  );
};

export const PanelPanel: React.FC<PanelPanelProps> = props => {
  const {updateConfig, updateConfig2} = props;
  const panelQuery = CGReact.useNodeValue(props.input);

  const defaultConfig = panelQuery.result as any;

  const panelConfig = props.config ?? defaultConfig;
  const panelUpdateConfig = useCallback(
    (newConfig: any) => {
      updateConfig({...panelConfig, ...newConfig});
    },
    [updateConfig, panelConfig]
  );
  const panelUpdateConfig2 = useCallback(
    (change: <T>(oldConfig: any) => Partial<T>) => {
      if (updateConfig2 == null) {
        return;
      }
      updateConfig2((currentConfig: any) => {
        currentConfig = currentConfig ?? defaultConfig;
        const configChanges = change(currentConfig);
        const newConfig = produce(currentConfig, (draft: any) => {
          for (const key of Object.keys(configChanges)) {
            (draft as any)[key] = (configChanges as any)[key];
          }
        });
        return newConfig;
      });
    },
    [updateConfig2, defaultConfig]
  );

  if (panelQuery.loading) {
    return <Panel2Loader />;
  }
  if (panelQuery.result == null) {
    return <div>-</div>;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: 'auto',
        wordBreak: 'normal',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-around',
        justifyContent: 'space-around',
      }}>
      <ChildPanel
        config={panelConfig}
        updateConfig={panelUpdateConfig}
        updateConfig2={panelUpdateConfig2}
      />
    </div>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'panel',
  ConfigComponent: PanelPanelConfig,
  Component: PanelPanel,
  inputType,
};
