import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import React, {memo} from 'react';

import {WBTableColumn, WBTableHoverCellCoords} from './WBTable';
import {
  WBTableCellColumnHeaderMemo,
  WBTableCellColumnHeaderProps,
} from './WBTableCellColumnHeader';
import {WBTableSortIndicatorComponent} from './WBTableSortIndicator';

interface WBTableColumnHeaderProps {
  displayedRows: any[]; // table rows that are displayed in the current page
  columns: WBTableColumn[];
  columnDragAccessor?: string;
  columnDropAccessor?: string;
  columnResizingAccessor?: string;
  SortIndicatorComponent?: WBTableSortIndicatorComponent;
  hoverCellCoords: WBTableHoverCellCoords;
  expanded?: boolean;
  isInReport?: boolean;
  childProps(
    column: WBTableColumn,
    columnIndex: number,
    readOnly?: boolean
  ): WBTableCellColumnHeaderProps;
}

function hoverColumn(coords: WBTableHoverCellCoords) {
  if (!coords) {
    return undefined;
  }
  return coords[1];
}

// This component exists solely to prevent calling `shouldComponentUpdate` on
// every individual column header when there are many.
const WBTableColumnHeadersComp: React.FC<WBTableColumnHeaderProps> = ({
  columns,
  childProps,
  displayedRows,
  SortIndicatorComponent,
  isInReport,
}) => {
  return (
    <React.Fragment>
      <div className="wb-tree-padding" />
      {columns.map((column, columnIndex) => {
        const props = childProps(column, columnIndex);
        return (
          <WBTableCellColumnHeaderMemo
            {...props}
            displayedRows={displayedRows}
            key={columnIndex}
            SortIndicatorComponent={SortIndicatorComponent}
            isInReport={isInReport}
          />
        );
      })}
      <div className="wb-tree-padding" />
    </React.Fragment>
  );
};

export const WBTableColumnHeaders = memo(
  WBTableColumnHeadersComp,
  (prevProps, nextProps) => {
    if (
      hoverColumn(prevProps.hoverCellCoords) !==
      hoverColumn(nextProps.hoverCellCoords)
    ) {
      return false;
    }
    const propsAreEqual = makePropsAreEqual({
      name: 'WBTableColumnHeaders',
      deep: [
        'displayedRows',
        'columns',
        'groupTempSelection',
        'tempSelectedCount',
      ],
      // handled by shouldComponentUpdate
      ignore: ['hoverCellCoords'],
      ignoreFunctions: true,
      debug: false,
      verbose: true,
    });
    return propsAreEqual(prevProps, nextProps);
  }
);
