import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {DebouncedFunc, isEqual} from 'lodash';
import _ from 'lodash';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNodeArray,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Link} from 'react-router-dom';
import reactStringReplace from 'react-string-replace';

import {CreateViewDiscussionCommentMutationVariables} from '../generated/graphql';
import {useReportDiscussion} from '../state/reports/hooks';
import {ReportViewRef} from '../state/reports/types';
import {Viewer} from '../state/viewer/types';
import {Ref as DiscussionCommentRef} from '../state/views/discussionComment/types';
import {Ref as DiscussionThreadRef} from '../state/views/discussionThread/types';
import {useWholeMaybe} from '../state/views/hooks';
import {Ref as PanelRef} from '../state/views/panel/types';
import {
  getPanelSpec,
  LayedOutPanel,
  LayedOutPanelWithRef,
} from '../util/panels';
import {profilePage} from '../util/urls';
import {MentionableUser} from './ReportDiscussion';
import * as S from './ReportDiscussionContext.styles';
import {
  getInlineRefIDsFromCommentBody,
  InlineCommentDetail,
} from './Slate/plugins/inline-comments-common';

interface ReportDiscussionContextState {
  reportViewRef: ReportViewRef | null;
  reportServerID: string | null;
  viewer: Viewer | undefined;
  readOnly: boolean;
  loadingDiscussionThreads: boolean;
  loadingCreateComment: boolean;
  // All discussion threads for this report
  discussionThreadRefs: DiscussionThreadRef[];
  // Used for @user mentions in comments
  teamMembers: MentionableUser[];
  // All panels in the report, used for &panel mentions in comments
  reportPanels: CommentableItem[];
  // Panels that should be highlighted with a yellow border,
  // to indicate that they're referenced in the active thread or in the current draft comment
  highlightIds: string[];
  // If 'editor', <CommentEditorFrame> (new thread) will be visible.
  // if 'thread', <CommentThreadFrame> (view / reply to existing thread) will be visible.
  // If undefined, no comment frame will be visible
  commentFrame?: 'editor' | 'thread' | undefined;
  // The currently-visible thread in <CommentThreadFrame>
  activeThreadRef?: DiscussionThreadRef | undefined;
  // All currently-viewable threads in <CommentThreadFrame>
  // Defaults to all (discussionThreadRefs) but is filtered if you're viewing a single panel's threads
  allActiveThreadRefs: DiscussionThreadRef[];
  // If true, autofocus the reply text box
  autofocus: boolean;
  // Whether or not users can add comments to panels (or &-reference panels in comments)
  // Panel comments are disabled in old reports until the author saves them again, because the panels don't have persistent IDs
  // See ActionsInternal.loadFinished in reducer.ts for more info
  panelCommentsEnabled: boolean;
  inlineCommentDetails?: InlineCommentDetail[];
  debouncedDeleteDetails: DebouncedFunc<(value: string) => void>;
  getPanelData(
    panelId: string,
    fallbackDisplayText?: string
  ): {
    panel?: CommentableItem;
    displayText: string;
    displayIcon: string;
  };
}

// Draft text state is put in a separate context to avoid unnecessary re-renders on every keypress
interface ReportDiscussionDraftContextState {
  // Draft text from <CommentEditorFrame>
  commentEditorFrameCommentBody: string;
  // Draft text from <CommentThreadFrame>
  // Saved as a map of {[threadRefId]: 'unsaved reply'} so we can change threads without losing reply state
  commentThreadFrameReplyDraftMap: {[key: string]: string};
  setCommentEditorFrameCommentBody(body: string): void;
  setCommentThreadFrameReplyDraftMap(newDraftMap: {
    [key: string]: string;
  }): void;
}

export type createCommentCallbackFnType = (
  details?: InlineCommentDetail[],
  threadID?: string,
  commentID?: string
) => void;

export const ReportDiscussionDraftContext =
  createContext<ReportDiscussionDraftContextState>({
    commentEditorFrameCommentBody: '',
    commentThreadFrameReplyDraftMap: {},
    setCommentEditorFrameCommentBody: () => {},
    setCommentThreadFrameReplyDraftMap: () => {},
  });

export type CommentableItem = LayedOutPanelWithRef;
export type CommentableRef = PanelRef;

export const ReportDiscussionContext =
  createContext<ReportDiscussionContextState>({
    reportViewRef: null,
    reportServerID: null,
    viewer: undefined,
    readOnly: true,
    loadingDiscussionThreads: false,
    loadingCreateComment: false,
    discussionThreadRefs: [],
    teamMembers: [],
    reportPanels: [],
    highlightIds: [],
    commentFrame: undefined,
    activeThreadRef: undefined,
    allActiveThreadRefs: [],
    autofocus: true,
    panelCommentsEnabled: false,
    inlineCommentDetails: undefined,
    debouncedDeleteDetails: _.debounce(() => {}),
    getPanelData: () => ({
      displayIcon: 'panel-line-plot',
      displayText: 'Panel',
    }),
  });

interface ReportDiscussionContextUpdaters {
  setCommentFrame: Dispatch<SetStateAction<'editor' | 'thread' | undefined>>;
  createComment(
    mutationVars: Omit<CreateViewDiscussionCommentMutationVariables, 'viewID'>,
    discussionThreadRef?: DiscussionThreadRef,

    callbackFn?: (
      details?: InlineCommentDetail[],
      threadID?: string,
      commentID?: string
    ) => void
  ): void;
  deleteComment(params: {
    discussionCommentServerID: string;
    discussionCommentRef: DiscussionCommentRef;
    discussionThreadRef: DiscussionThreadRef;
    deleteThread: boolean;
  }): void;
  setActiveThread(
    threadRef?: DiscussionThreadRef,
    setFocus?: boolean,
    allActiveThreadRefs?: DiscussionThreadRef[]
  ): void;
  setAutofocus(af: boolean): void;
  appendPanelMentionToComment(panelId: string): void;
  appendInlineTextMentionToComment(detail: InlineCommentDetail): void;
  replacePanelMentionTokens(
    commentBody: string,
    panelMentionOnClick?: (panelId?: string) => void,
    inlineTextMentionOnClick?: (inlineRefID?: string) => void
  ): ReactNodeArray;
  setCreateCommentCallbackFn(callbackFn: createCommentCallbackFnType): void;
  setInlineCommentDetails(inlineCommentDetails?: InlineCommentDetail[]): void;
}

export const ReportDiscussionUpdaterContext =
  createContext<ReportDiscussionContextUpdaters>({
    createComment: () => {},
    deleteComment: () => {},
    setCommentFrame: () => {},
    setActiveThread: () => {},
    setAutofocus: () => {},
    appendPanelMentionToComment: () => {},
    appendInlineTextMentionToComment: () => {},
    replacePanelMentionTokens: () => [<></>],
    setCreateCommentCallbackFn: () => {},
    setInlineCommentDetails: () => {},
  });

export const USER_MENTION_REGEXP = /@\[(.*?]\(.*?)\)/g;
export const PANEL_MENTION_REGEXP = /&\[(.*?]\(.*?)\)/g;
export const INLINE_MENTION_REGEXP = /\|\[(.*?]\(.*?)\)/g;

export const ReportDiscussionContextProvider: FC<{
  reportViewRef: ReportViewRef;
  reportServerID: string;
}> = React.memo(({reportViewRef, reportServerID, children}) => {
  // Type of comment frame that's currently visible, either 'editor' (new thread/comment) or 'thread' (viewing/replying to existing thread)
  const [commentFrame, setCommentFrame] = useState<
    'editor' | 'thread' | undefined
  >(undefined);

  // Draft text content of the <CommentEditorFrame>
  const [commentEditorFrameCommentBody, setCommentEditorFrameCommentBody] =
    useState('');

  // Draft text from the <CommentThreadFrame>
  // Saved as a map of {[threadRefId]: 'unsaved reply'} so we can change threads without losing reply state
  const [commentThreadFrameReplyDraftMap, setCommentThreadFrameReplyDraftMap] =
    useState<{[key: string]: string}>({});

  // Draft text state is put in a different context to avoid unnecessary re-renders on every keypress
  const draftState = useMemo<ReportDiscussionDraftContextState>(
    () => ({
      commentEditorFrameCommentBody,
      commentThreadFrameReplyDraftMap,
      setCommentEditorFrameCommentBody,
      setCommentThreadFrameReplyDraftMap,
    }),
    [commentEditorFrameCommentBody, commentThreadFrameReplyDraftMap]
  );

  // allActiveThreadRefs is the set of threads available in the CommentThreadFrame.
  // defaults to all threads, but if you're viewing a single panel's threads,
  // allActiveThreadRefs will be the subset of threads which reference that panel
  const [allActiveThreadRefs, setAllActiveThreadRefs] = useState<
    DiscussionThreadRef[]
  >([]);

  // activeThreadRef is the single currently-visible thread in the CommentThreadFrame
  const [activeThreadRef, setActiveThreadRef] = useState<
    DiscussionThreadRef | undefined
  >(undefined);

  const [autofocus, setAutofocus] = useState(true); // if true, it'll autofocus the reply textarea in CommentThreadFrame

  const [createCommentCallbackFn, setCreateCommentCallbackFn] = useState<
    createCommentCallbackFnType | undefined
  >(undefined);

  const [inlineCommentDetails, setInlineCommentDetails] = useState<
    InlineCommentDetail[] | undefined
  >(undefined);

  const {
    loadingDiscussionThreads,
    discussionThreadRefs,
    loadingCreateComment,
    viewer,
    readOnly,
    teamMembers,
    reportPanels,
    createComment,
    deleteComment,
    panelCommentsEnabled,
  } = useReportDiscussion({
    reportServerID,
    reportViewRef,
  });

  // Takes an array of panel mention tokens, returns the corresponding panel IDs
  const tokensToIds = useCallback(
    (panelTokens?: RegExpMatchArray | null) => {
      if (panelTokens == null) {
        return [];
      }
      const ids: string[] = [];
      panelTokens.forEach(token => {
        const panelIdMatch = token.match(/\((.*)\)/);
        if (panelIdMatch != null) {
          const panelId = panelIdMatch[1];
          const panel = reportPanels.find(p => p.__id__ === panelId);
          if (panel != null) {
            ids.push(panel.__id__);
          }
        }
      });
      return ids;
    },
    [reportPanels]
  );

  // highlightIds is the list of currently highlighted panels (yellow border)
  const [highlightIds, setHighlightIds] = useState<string[]>([]);

  // Finds all panels mentioned in the active thread or in the current comment draft.
  // This is used to add the highlight border to those panels.
  const activeThread = useWholeMaybe(activeThreadRef);
  useEffect(() => {
    let newHighlightIds: string[] = [];
    if (commentFrame === 'editor') {
      // Find panel mentions in the current comment draft
      const editorPanelTokens =
        commentEditorFrameCommentBody.match(PANEL_MENTION_REGEXP);
      newHighlightIds = newHighlightIds.concat(tokensToIds(editorPanelTokens));
    } else if (commentFrame === 'thread') {
      // Find panel mentions in the current reply draft
      const activeReplyDraft =
        activeThreadRef != null
          ? commentThreadFrameReplyDraftMap[activeThreadRef.id]
          : undefined;
      if (activeReplyDraft != null) {
        const replyPanelTokens = activeReplyDraft.match(PANEL_MENTION_REGEXP);
        newHighlightIds = newHighlightIds.concat(tokensToIds(replyPanelTokens));
      }
      // Find panel mentions in all comments in the current thread
      if (activeThread != null) {
        activeThread.comments.forEach(c => {
          const threadPanelTokens = c.body.match(PANEL_MENTION_REGEXP);
          newHighlightIds = newHighlightIds.concat(
            tokensToIds(threadPanelTokens)
          );
        });
      }
    }
    setHighlightIds(prevHighlightIds => {
      return isEqual(prevHighlightIds, newHighlightIds.sort())
        ? prevHighlightIds
        : newHighlightIds.sort();
    });
  }, [
    activeThread,
    activeThreadRef,
    commentFrame,
    commentEditorFrameCommentBody,
    commentThreadFrameReplyDraftMap,
    tokensToIds,
  ]);

  // Lookup panel by the persistent id (i.e., panel.__id__, not panel.ref.id)
  // Also returns text and icon strings for displaying in comment mentions
  const getPanelData = useCallback(
    (panelId: string, fallbackDisplayText?: string) => {
      const commentableItem = reportPanels.find(p => p.__id__ === panelId);
      const defaultData = {
        panel: commentableItem,
        displayText: 'Panel',
        displayIcon: 'panel-line-plot',
      };
      // TODO: display this state in the UI? (e.g. mark outdated?, strikethrough panel reference?)
      if (commentableItem == null || !('viewType' in commentableItem)) {
        return defaultData;
      }
      const panelSpec = getPanelSpec(
        (commentableItem as LayedOutPanel).viewType
      );
      let displayText =
        panelSpec.getTitleFromConfig?.(commentableItem.config as any) ??
        fallbackDisplayText;
      if (displayText == null || displayText?.trim() === '') {
        displayText = panelSpec.type ?? 'panel';
      }
      const displayIcon = panelSpec.icon ?? 'panel-line-plot';
      return {
        panel: commentableItem,
        displayText,
        displayIcon,
      };
    },
    [reportPanels]
  );

  const deleteDetailsFromCommentBody = useCallback(
    value => {
      // if comment body is changed and the inline text comment get deleted,
      // remove that detail from context's inlineCommentDetails to not highlight as commented
      // and not incorrectly save inlineCommentMark in text node
      if (inlineCommentDetails != null) {
        const nonDeletedCommentRefIDs = getInlineRefIDsFromCommentBody(value);

        if (nonDeletedCommentRefIDs.length < inlineCommentDetails.length) {
          const nonDeletedCommentDetails = inlineCommentDetails.filter(detail =>
            nonDeletedCommentRefIDs.includes(detail.refID)
          );
          setInlineCommentDetails(nonDeletedCommentDetails);
          return nonDeletedCommentDetails;
        }
      }
      return inlineCommentDetails;
    },
    [inlineCommentDetails, setInlineCommentDetails]
  );

  const debouncedDeleteDetails = useMemo(() => {
    // use debounce to call deleteDetails less often which should improve performance
    return _.debounce((value: string) => {
      deleteDetailsFromCommentBody(value);
    }, 200);
  }, [deleteDetailsFromCommentBody]);

  // flush to clear the previous debounce function
  useEffect(
    () => () => debouncedDeleteDetails.flush(),
    [debouncedDeleteDetails]
  );

  const state = useMemo<ReportDiscussionContextState>(
    () => ({
      reportServerID,
      reportViewRef,
      viewer,
      readOnly,
      loadingDiscussionThreads,
      loadingCreateComment,
      discussionThreadRefs,
      teamMembers,
      reportPanels,
      highlightIds,
      commentFrame,
      activeThreadRef,
      allActiveThreadRefs,
      autofocus,
      panelCommentsEnabled,
      inlineCommentDetails,
      debouncedDeleteDetails,
      getPanelData,
    }),
    [
      reportServerID,
      reportViewRef,
      viewer,
      readOnly,
      loadingDiscussionThreads,
      loadingCreateComment,
      discussionThreadRefs,
      teamMembers,
      reportPanels,
      highlightIds,
      commentFrame,
      activeThreadRef,
      allActiveThreadRefs,
      autofocus,
      panelCommentsEnabled,
      inlineCommentDetails,
      debouncedDeleteDetails,
      getPanelData,
    ]
  );

  const setActiveThread = useCallback(
    (
      threadRef?: DiscussionThreadRef,
      setFocus?: boolean, // if true, it'll autofocus the reply textarea
      allActiveRefs?: DiscussionThreadRef[] // use this to filter the set of threads to a subset of all threads (e.g. viewing discussion threads for a panel)
    ) => {
      setAllActiveThreadRefs(allActiveRefs || discussionThreadRefs);
      setActiveThreadRef(threadRef);
      setCommentFrame(threadRef == null ? undefined : 'thread');
      setAutofocus(!!setFocus);
    },
    [discussionThreadRefs]
  );

  // helper function for append<Panel/InlineText>MentionTokenToComment
  const addMentionTokenToCommentBody = useCallback((mentionToken: string) => {
    setCommentFrame(prevCommentFrame => {
      if (prevCommentFrame == null || prevCommentFrame === 'editor') {
        // Creating a new comment/thread with <CommentEditorFrame>
        setCommentEditorFrameCommentBody(prevCommentBody => {
          return prevCommentBody === ''
            ? mentionToken
            : `${prevCommentBody} ${mentionToken}`;
        });
      } else {
        // Replying to an existing thread (prevCommentFrame === 'thread') with <CommentThreadFrame>
        setActiveThreadRef(prevActiveThreadRef => {
          if (prevActiveThreadRef != null) {
            setCommentThreadFrameReplyDraftMap(prevReplyDraftMap => {
              const prevReplyDraft =
                prevReplyDraftMap[prevActiveThreadRef.id] || '';
              const newReplyDraft = {
                ...prevReplyDraftMap,
                [prevActiveThreadRef.id]:
                  prevReplyDraft === ''
                    ? mentionToken
                    : `${prevReplyDraft} ${mentionToken}`,
              };
              return newReplyDraft;
            });
          }
          return prevActiveThreadRef;
        });
      }
      return prevCommentFrame == null ? 'editor' : prevCommentFrame;
    });
  }, []);

  const appendPanelMentionToComment = useCallback(
    (panelId: string) => {
      // Get the panel data from redux
      const panel = reportPanels.find(p => {
        return p.__id__ === panelId;
      });
      if (panel == null) {
        return;
      }

      const {displayText} = getPanelData(panelId);
      const panelMentionToken = `&[${displayText}](${panelId}) `;

      addMentionTokenToCommentBody(panelMentionToken);
    },
    [getPanelData, reportPanels, addMentionTokenToCommentBody]
  );

  const appendInlineTextMentionToComment = useCallback(
    (commentDetail: InlineCommentDetail) => {
      if (commentDetail == null) {
        return;
      }

      // format the inline text to add to commentBody
      const displayText = commentDetail.text;
      const inlineTextMentionToken = `|[${displayText}](${commentDetail.refID}) `;

      addMentionTokenToCommentBody(inlineTextMentionToken);
    },
    [addMentionTokenToCommentBody]
  );

  // Takes a comment body string, replaces @user and &panel mention tokens with clickable links
  const replacePanelMentionTokens = useCallback(
    (
      commentBody: string,
      panelMentionOnClick?: (panelId?: string) => void,
      inlineTextMentionOnClick?: (inlineRefID?: string) => void
    ) => {
      // Replace @[name](username) with a profile link
      let renderComment = reactStringReplace(
        commentBody,
        USER_MENTION_REGEXP,
        (match, i) => {
          const [name, username] = match.split('](');
          return panelMentionOnClick == null ? (
            name
          ) : (
            <Link
              key={match + i}
              to={profilePage(username)}
              onClick={() => {
                window.analytics?.track('Reports Comment Mention Clicked', {
                  reportID: reportServerID,
                  mode: readOnly ? 'view' : 'edit',
                  type: 'user',
                  destination: username,
                });
              }}>
              {name}
            </Link>
          );
        }
      );

      // Replace |[text][refID] with a inline text comment style
      renderComment = reactStringReplace(
        renderComment,
        INLINE_MENTION_REGEXP,
        (match, i) => {
          const [text, refID] = match.split('](');
          return (
            <S.CommentWrapper
              key={match + i}
              data-test="inline-comment-mention"
              onClick={() => {
                window.analytics?.track('Reports Comment Mention Clicked', {
                  reportID: reportServerID,
                  mode: readOnly ? 'view' : 'edit',
                  type: 'inline text',
                  destination: refID,
                });
                inlineTextMentionOnClick?.(refID);
              }}>
              {text}
            </S.CommentWrapper>
          );
        }
      );

      // Replace &[panelDisplay](panelId) with an anchor link to the panel
      renderComment = reactStringReplace(
        renderComment,
        PANEL_MENTION_REGEXP,
        (match, i) => {
          const [commentPanelDisplay, panelId] = match.split('](');
          // Look up the current display text using panelId.
          // commentPanelDisplay is the panel title embedded in the comment text,
          // which might be stale (e.g., the panel has been renamed) so we only use it as a fallback
          const {panel, displayText, displayIcon} = getPanelData(
            panelId,
            commentPanelDisplay
          );
          return (
            <div
              key={match + i}
              className="comment-mention"
              onClick={() => {
                if (panel != null && panelMentionOnClick != null) {
                  window.analytics?.track('Reports Comment Mention Clicked', {
                    reportID: reportServerID,
                    mode: readOnly ? 'view' : 'edit',
                    type: 'Panel',
                    destination: panelId,
                  });
                  panelMentionOnClick(panelId);
                }
              }}>
              <LegacyWBIcon name={displayIcon} />
              {displayText}
            </div>
          );
        }
      );
      return renderComment;
    },
    [getPanelData, reportServerID, readOnly]
  );

  const createCommentFn = useCallback(
    (
      mutationVars: Omit<
        CreateViewDiscussionCommentMutationVariables,
        'viewID' | 'notifyAllSubscribers'
      >,
      discussionThreadRef?: DiscussionThreadRef
    ) => {
      // for the edge case that the user deletes inline text reference from the comment body
      // right before creating comment, make sure to have up-to-date comment details
      const updatedInlineCommentDetails = deleteDetailsFromCommentBody(
        mutationVars.body
      );

      createComment(
        mutationVars,
        discussionThreadRef,
        updatedInlineCommentDetails,
        createCommentCallbackFn
      );
    },
    [createComment, createCommentCallbackFn, deleteDetailsFromCommentBody]
  );

  const updaters = useMemo<ReportDiscussionContextUpdaters>(
    () => ({
      createComment: createCommentFn,
      deleteComment,
      setCommentFrame,
      setActiveThread,
      setAutofocus,
      appendPanelMentionToComment,
      appendInlineTextMentionToComment,
      replacePanelMentionTokens,
      setCreateCommentCallbackFn,
      setInlineCommentDetails,
    }),
    [
      appendPanelMentionToComment,
      appendInlineTextMentionToComment,
      createCommentFn,
      deleteComment,
      replacePanelMentionTokens,
      setActiveThread,
      setInlineCommentDetails,
    ]
  );

  return (
    <ReportDiscussionContext.Provider value={state}>
      <ReportDiscussionDraftContext.Provider value={draftState}>
        <ReportDiscussionUpdaterContext.Provider value={updaters}>
          {children}
        </ReportDiscussionUpdaterContext.Provider>
      </ReportDiscussionDraftContext.Provider>
    </ReportDiscussionContext.Provider>
  );
});
