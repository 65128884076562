import * as globals from '@wandb/common/css/globals.styles';
import {Input as SemanticInput} from 'semantic-ui-react';
import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  color: ${globals.gray800};
`;

export const Input = styled(SemanticInput)`
  &&&&& {
    &.disabled {
      opacity: 1;
    }
    input:disabled {
      background-color: ${globals.gray200};
      color: ${globals.gray600};
    }
  }
`;

export const ProcessingMessage = styled.div`
  padding: 12px 0;
  line-height: 20px;
  background-color: ${globals.green};
  color: ${globals.white};
`;

export const AgreementLabel = styled.div`
  &&& {
    font-size: 14px;
    color: ${globals.gray600};
  }
  text-align: left;
`;
