import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import * as globals from '@wandb/common/css/globals.styles';
import {Link} from '@wandb/common/util/links';
import React, {ReactNode, useState} from 'react';
import {Button, Icon, Label, Modal} from 'semantic-ui-react';

import {ReactComponent as IconLockClosed} from '../assets/SVG/icon-lock-closed.svg';
import {ReactComponent as IconLockOpen} from '../assets/SVG/icon-lock-open.svg';
import {useViewer} from '../state/viewer/hooks';
import {navigateTo} from '../util/history';
import {
  AccessOptions,
  getDescriptionForProjectAccess,
  getNameForProjectAccess,
  getTooltipTextForProjectAccess,
} from '../util/permissions';
import * as urls from '../util/urls';
import * as S from './ProjectAccess.styles';

const accessMatches = (accessA: AccessOptions, accessB: AccessOptions) => {
  return (
    accessA === accessB ||
    (accessA !== 'USER_READ' &&
      accessA !== 'USER_WRITE' &&
      accessB !== 'USER_READ' &&
      accessB !== 'USER_WRITE')
  );
};

export interface ProjectAccessOptionProps {
  name: string;
  icon: ReactNode;
  access: AccessOptions;
  description: string;
  disabled: boolean;
  warning: boolean;
  default: boolean;
  selected: boolean;
  advanced?: boolean;
  select(): void;
}

export interface ProjectAccessInput {
  projectAccess: AccessOptions;
  defaultAccess: AccessOptions;
  privateOnly: boolean;
  isTeam: boolean;
  selectAccess(access: string): void;
}

export function projectAccessOptions(
  input: ProjectAccessInput
): ProjectAccessOptionProps[] {
  const {projectAccess, defaultAccess, privateOnly, isTeam, selectAccess} =
    input;

  const privateOption: ProjectAccessOptionProps = {
    name: getNameForProjectAccess(`ENTITY_READ`, isTeam),
    icon: <IconLockClosed />,
    access: 'ENTITY_READ',
    description: getDescriptionForProjectAccess(`ENTITY_READ`, isTeam),
    disabled: false,
    warning: false,
    default: accessMatches('ENTITY_READ', defaultAccess),
    selected: accessMatches('ENTITY_READ', projectAccess),
    advanced: false,
    select: () => selectAccess('ENTITY_READ'),
  };

  const publicOption: ProjectAccessOptionProps = {
    name: getNameForProjectAccess(`USER_READ`, isTeam),
    icon: <IconLockOpen />,
    access: 'USER_READ',
    description: getDescriptionForProjectAccess(`USER_READ`, isTeam),
    disabled: isTeam && privateOnly,
    warning: isTeam,
    default: accessMatches('USER_READ', defaultAccess),
    selected: accessMatches('USER_READ', projectAccess),
    advanced: false,
    select: () => selectAccess('USER_READ'),
  };

  const openOption: ProjectAccessOptionProps = {
    name: getNameForProjectAccess(`USER_WRITE`, isTeam),
    icon: <LegacyWBIcon name="open-project" />,
    access: 'USER_WRITE',
    description: getDescriptionForProjectAccess(`USER_WRITE`, isTeam),
    disabled: isTeam && privateOnly,
    warning: isTeam,
    default: accessMatches('USER_WRITE', defaultAccess),
    selected: accessMatches('USER_WRITE', projectAccess),
    advanced: true,
    select: () => selectAccess('USER_WRITE'),
  };

  return [privateOption, publicOption, openOption];
}

export const ProjectAccessOption: React.FC<ProjectAccessOptionProps> =
  React.memo(
    ({
      name,
      icon,
      access,
      description,
      disabled,
      warning,
      default: optionDefault,
      selected,
      select,
    }) => {
      return (
        <S.ProjectAccessModalCheckbox
          radio
          label={
            <label>
              <S.ProjectAccessOptionLabel>
                {icon}
                <span>{name}</span>
                {warning && <Icon name="warning sign" color="red" />}
                {optionDefault && <Label color="grey">DEFAULT</Label>}
              </S.ProjectAccessOptionLabel>
              <S.ProjectAccessOptionDescription>
                {description}
              </S.ProjectAccessOptionDescription>
            </label>
          }
          value={access}
          key={access}
          checked={selected}
          disabled={disabled}
          readOnly={disabled}
          onClick={disabled ? undefined : select}
        />
      );
    }
  );

export interface ProjectAccessProject {
  access: AccessOptions;
  entity: {
    id: string;
    name: string;
    readOnlyAdmin: boolean;
    defaultAccess: AccessOptions;
    privateOnly: boolean;
    isTeam: boolean;
  };
}

export interface ProjectAccessProps {
  project: ProjectAccessProject;
  compact?: boolean;
  updateProjectAccess(access: AccessOptions): void;
}

// This is the project access level icon (locked or unlocked) and its popup,
// including the option to change the access level (if you have permission)

const ProjectAccess: React.FC<ProjectAccessProps> = React.memo(
  ({project, compact, updateProjectAccess}) => {
    const {
      entity: {
        id: entityID,
        name: entityName,
        readOnlyAdmin,
        isTeam,
        privateOnly,
        defaultAccess,
      },
    } = project;
    // access can be null for older projects (before we added the private projects flag)
    // see availableOptions() in ProjectEditor.js
    // TODO: data migration to fix this in the db!
    // TODO: Should change the type to reflect that this can be null
    const projectAccess = convertOldAccess(project.access || defaultAccess);

    const viewer = useViewer();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAccess, setSelectedAccess] =
      useState<AccessOptions>(projectAccess);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(
      projectAccess === 'USER_WRITE'
    );
    const [tooltipOpen, setTooltipOpen] = useState(false);

    let accessBadgeText: string;
    let accessBadgeColor: string;
    if (projectAccess === 'USER_READ') {
      accessBadgeText = 'PUBLIC';
      accessBadgeColor = globals.fern;
    } else if (projectAccess === 'USER_WRITE') {
      accessBadgeText = 'OPEN';
      accessBadgeColor = globals.orchid;
    } else if (isTeam) {
      accessBadgeText = 'TEAM';
      accessBadgeColor = globals.aqua;
    } else {
      accessBadgeText = 'PRIVATE';
      accessBadgeColor = globals.cerulean;
    }

    const options = projectAccessOptions({
      projectAccess: selectedAccess,
      defaultAccess,
      privateOnly,
      isTeam,
      selectAccess: (access: AccessOptions) => setSelectedAccess(access),
    });

    const currentOption = options.find(
      option => option.access === projectAccess
    );
    const selectedOption = options.find(
      option => option.access === selectedAccess
    );
    const disabled = selectedOption ? selectedOption.disabled : false;

    const viewerIsTeamMember =
      viewer?.teams.edges.some(e => e.node.id === entityID) ?? false;
    const canEdit =
      !readOnlyAdmin || (isTeam && viewerIsTeamMember && !privateOnly);

    const onIndicatorClick = () => {
      if (!canEdit) {
        return;
      }
      setModalOpen(true);
      setTooltipOpen(false);
      setSelectedAccess(projectAccess);
    };

    const projectInPersonalEntity = !isTeam && viewer?.username === entityName;

    return (
      <>
        {compact ? (
          <S.TooltipPopup
            open={tooltipOpen}
            offset={3}
            onOpen={() => setTooltipOpen(true)}
            onClose={() => setTooltipOpen(false)}
            position={`top center`}
            popperModifiers={{
              preventOverflow: {
                boundariesElement: 'viewport',
              },
            }}
            trigger={
              <S.CompactProjectAccessIcon onClick={onIndicatorClick}>
                {currentOption != null && currentOption.icon}
              </S.CompactProjectAccessIcon>
            }>
            {getTooltipTextForProjectAccess(projectAccess, isTeam)}
          </S.TooltipPopup>
        ) : (
          <S.ProjectAccessIconWithLabel
            $color={accessBadgeColor}
            size="medium"
            onClick={onIndicatorClick}>
            {currentOption != null && currentOption.icon}
            {accessBadgeText}
          </S.ProjectAccessIconWithLabel>
        )}
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Header>Project Settings</Modal.Header>
          <Modal.Content>
            <p>
              Privacy settings affect your whole project, including runs,
              reports, artifacts, etc.
            </p>
            {options
              .filter(o => (showAdvancedSettings ? true : !o.advanced))
              .map(option => {
                return <ProjectAccessOption key={option.access} {...option} />;
              })}
            {!showAdvancedSettings && (
              <S.ShowAdvancedSettingsLink
                onClick={() => setShowAdvancedSettings(true)}>
                + Show advanced settings
              </S.ShowAdvancedSettingsLink>
            )}
            {isTeam && privateOnly && (
              <S.TeamSettingsPrompt>
                To make this project Public or Open, turn off the “Force all
                projects in this team to be private” setting in{' '}
                <Link to={urls.teamSettings(entityName)}>Team Settings</Link>.
              </S.TeamSettingsPrompt>
            )}
            {projectInPersonalEntity && <CreateTeamPrompt />}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button
              primary
              disabled={disabled}
              onClick={() => {
                updateProjectAccess(selectedAccess);
                setModalOpen(false);
              }}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
);

export default ProjectAccess;

const CreateTeamPrompt: React.FC = React.memo(() => {
  return (
    <S.CreateTeamPrompt>
      <S.CreateTeamText>
        Create a team to collaborate on private projects.
      </S.CreateTeamText>
      <S.CreateTeamButton
        primary
        basic
        onClick={() => {
          window.analytics?.track('Create Team Started', {
            location: 'project-settings',
          });
          navigateTo({pathname: '/create-team'});
        }}>
        Create Team
      </S.CreateTeamButton>
    </S.CreateTeamPrompt>
  );
});

function convertOldAccess(access: AccessOptions): AccessOptions {
  if (access === 'PRIVATE') {
    return 'ENTITY_READ';
  }
  return access;
}
