import {ID} from '@wandb/cg';
import React, {FC, useCallback, useContext} from 'react';
import {Editor} from 'slate';
import {useSlate} from 'slate-react';

import {useViewer} from '../../../state/viewer/hooks';
import {setAuthRedirect} from '../../../util/auth';
import {navigateTo} from '../../../util/history';
import {
  ReportDiscussionContext,
  ReportDiscussionUpdaterContext,
} from '../../ReportDiscussionContext';
import {
  EditorWithComments,
  InlineCommentDetail,
  InlineCommentMark,
} from '../plugins/inline-comments-common';
import * as S from './HoveringToolbar.styles';

interface CommentFormatButtonProps {
  onClose: () => void;
  readOnly: boolean;
}

export const CommentFormatButton: FC<CommentFormatButtonProps> = ({
  onClose,
  readOnly,
}) => {
  const editor = useSlate();
  const viewer = useViewer();
  const isUserLoggedIn = viewer != null;

  const {inlineCommentDetails} = useContext(ReportDiscussionContext);
  const {
    setInlineCommentDetails,
    setCreateCommentCallbackFn,
    appendInlineTextMentionToComment,
  } = useContext(ReportDiscussionUpdaterContext);

  const insertInlineCommentHandler = useCallback(() => {
    // This callbackFn is saved in context and called in reports/hooks.ts
    // once createComment is succesfully done with returned threadID and commentID.
    // This updates the slate text node to have InlineCommentMark which highlightes as commented text.
    const callbackFn = (
      details?: InlineCommentDetail[],
      threadID?: string,
      commentID?: string
    ) => {
      if (details == null || threadID == null || commentID == null) {
        return;
      }

      details.forEach(detail => {
        const mark: InlineCommentMark = {
          refID: detail.refID,
          threadID,
          commentID,
        };
        if (threadID && commentID) {
          EditorWithComments.addMark(editor, mark, detail.rangeRef);
        }
      });
    };

    if (editor.selection != null) {
      const refID = ID();
      const rangeRef = Editor.rangeRef(editor, editor.selection);

      setCreateCommentCallbackFn(() => callbackFn);

      const detail: InlineCommentDetail = {
        refID,
        text: Editor.string(editor, editor.selection),
        rangeRef,
      };
      const newCommentDetails =
        inlineCommentDetails != null
          ? [...inlineCommentDetails, detail]
          : [detail];
      setInlineCommentDetails(newCommentDetails);
      // adds inlineCommentDetail to the mentionable text in comment frame
      appendInlineTextMentionToComment(detail);
    }

    onClose();
  }, [
    editor,
    setCreateCommentCallbackFn,
    inlineCommentDetails,
    setInlineCommentDetails,
    appendInlineTextMentionToComment,
    onClose,
  ]);

  return (
    <S.CommentButtonWrapper
      data-test="inline-comment-button"
      onMouseDown={() => {
        window.analytics?.track('Comment started', {
          loggedIn: isUserLoggedIn,
        });

        if (isUserLoggedIn) {
          insertInlineCommentHandler();

          window.analytics?.track('Inline text mention added', {
            mode: readOnly ? 'view' : 'edit',
          });
        } else {
          setAuthRedirect(window.location.href);
          navigateTo({pathname: '/login'});
        }
      }}>
      <S.CommentButtonIcon name={'chat'}></S.CommentButtonIcon>
      {readOnly && (
        <S.CommentButtonText>
          {isUserLoggedIn ? 'Comment' : 'Login to comment'}
        </S.CommentButtonText>
      )}
    </S.CommentButtonWrapper>
  );
};
