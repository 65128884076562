import * as _ from 'lodash';
import React from 'react';
import {useCallback, useMemo} from 'react';
import * as Panel2 from './panel';
import * as CG from '@wandb/cg';
import {WeaveExpression} from '../../panel/WeaveExpression';
import {usePanelContext} from './PanelContext';
import {useAction, useLet, useNodeValue} from '../../cgreact';
import {toWeaveType} from './PanelGroup2';
import {isFunctionType} from '@wandb/cg';

const inputType = 'invalid';
interface PanelExpressionEditorConfig {
  expr: CG.ConstNode<CG.FunctionTypeSpecific<{[key: string]: CG.Type}, 'any'>>;
}
type PanelExpressionEditorProps = Panel2.PanelProps<
  typeof inputType,
  PanelExpressionEditorConfig
>;

export const PanelExpressionEditor: React.FC<PanelExpressionEditorProps> =
  props => {
    const {frame} = usePanelContext();
    const {config, updateConfig} = props;

    const loadFunc = useNodeValue(config?.expr ?? CG.voidNode());
    const func =
      (loadFunc.result as unknown as CG.ConstNode<CG.FunctionType>) ??
      CG.voidNode();

    const statements = useMemo(
      () => ({
        value: config?.expr,
      }),
      [config]
    );
    const updateConfigVal = useCallback(
      (key: string, newVal: CG.ConstNode<CG.FunctionTypeSpecific>) =>
        updateConfig({...config, expr: newVal}),
      [config, updateConfig]
    );

    const letStatements = useLet(statements, updateConfigVal);
    const valueNode = useMemo(
      () => CG.opExecute({node: letStatements.value as any}),
      [letStatements]
    );
    // const value = useNodeValue(valueNode);
    const setExpr = useAction(
      CG.constNodeUnsafe(toWeaveType(valueNode), valueNode),
      'set'
    );
    const updateVal = useCallback(
      (newVal: any) => {
        // TODO: Are we guaranteed to have a function here?
        const inputTypes = isFunctionType(func.type)
          ? func.type.inputTypes
          : {};
        const outputType = newVal.type;
        setExpr({
          val: CG.constNodeUnsafe(
            {type: 'function', inputTypes, outputType},
            newVal
          ),
        });
      },
      [setExpr, func.type]
    );

    // const func = config?.expr ?? defineFunction({}, () => voidNode());
    const frameWithVars = useMemo(
      () => ({
        ...frame,
        ..._.mapValues(func.type.inputTypes, (type, name) =>
          CG.varNode(type, name)
        ),
      }),
      [frame, func.type.inputTypes]
    );
    console.log('FRAME WITH VARS', frameWithVars);
    return (
      <div style={{width: '100%', height: '100%'}}>
        <WeaveExpression
          frame={frameWithVars}
          expr={func.val}
          setExpression={updateVal}
          noBox
          // liveUpdate
        />
      </div>
    );
  };

export const Spec: Panel2.PanelSpec = {
  hidden: true,
  id: 'ExpressionEditor',
  Component: PanelExpressionEditor,
  inputType,
};
