import * as GraphTypes from '@wandb/cg';
import * as Types from '@wandb/cg';
import * as Op from '@wandb/cg';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import HighlightedIcon from '@wandb/common/components/HighlightedIcon';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import * as CGReact from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {Panel2Loader} from '@wandb/weave-ui/components/Panel2/PanelComp';
import * as Panel2Style from '@wandb/weave-ui/components/Panel2/PanelComp.styles';
import {usePanelContext} from '@wandb/weave-ui/components/Panel2/PanelContext';
import {Cell} from '@wandb/weave-ui/components/Panel2/PanelTable/Cell';
import * as Table from '@wandb/weave-ui/components/Panel2/PanelTable/tableState';
import {useWeaveContext} from '@wandb/weave-ui/context';
import _ from 'lodash';
import React from 'react';
import {useMemo} from 'react';
import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

import {useGatedValue} from '../../state/hooks';
import WBReactTable from '../WBReactTable';
import {useTableStateWithRefinedExpressions} from './PanelTable';

export const MenuWrapper = styled.div`
  height: 24px;
  width: 24px;
  transform: translateY(-4px);
  font-size: 21px;
`;

const inputType = {type: 'list' as const, objectType: 'any' as const};

// TODO: change the TableState to be nested below
type PanelWBReactTableProps = Panel2.PanelProps<
  typeof inputType,
  Table.TableState
>;

type PanelWBReactTableExtraProps = {
  onRowClick?: (index: any, node: GraphTypes.Node) => void;
  actions?: Array<{
    key: string;
    onClick: (index: any, node: GraphTypes.Node) => void;
    icon: string;
    text: string;
  }>;
};

export const PanelWBReactTable: React.FC<
  PanelWBReactTableProps & PanelWBReactTableExtraProps
> = props => {
  // TODO: Make the react table pagination work with the table query. For now,
  // we are just blinding selecting all rows.
  const {input, updateConfig, updateContext, onRowClick} = props;
  const weave = useWeaveContext();

  const inputNode = input as any as GraphTypes.Node;

  const [pageSize, setPageSize] = React.useState(10);
  const [maxPage, setMaxPage] = React.useState(1);

  const config = useMemo(
    () =>
      props.config == null || props.config.columns == null
        ? Table.emptyTable()
        : props.config,
    [props.config]
  );

  const limitedRowsNode = useMemo(() => {
    return Op.opLimit({
      arr: inputNode,
      limit: Op.constNumber(pageSize * maxPage),
    });
  }, [inputNode, pageSize, maxPage]);

  const rowsNode = useMemo(
    () =>
      Table.getRowsNode(
        config.preFilterFunction,
        config.groupBy,
        config.columnSelectFunctions,
        config.columnNames,
        config.order,
        config.sort,
        limitedRowsNode,
        weave
      ),
    [
      config.preFilterFunction,
      config.groupBy,
      config.columnSelectFunctions,
      config.columnNames,
      config.order,
      config.sort,
      limitedRowsNode,
      weave,
    ]
  );

  const rowNodesUse = CGReact.useEach(rowsNode as any);

  const rowNodes = useMemo(
    () => (rowNodesUse.loading ? [] : rowNodesUse.result),
    [rowNodesUse.loading, rowNodesUse.result]
  );

  const columns: React.ComponentProps<typeof WBReactTable>['columns'] = useMemo(
    () => [
      ...(onRowClick == null
        ? []
        : [
            {
              id: '__view__',
              Header: '',
              minWidth: 75,
              sortable: false,
              Cell: ({original}: any) => {
                return (
                  <Button
                    size="mini"
                    onClick={() => onRowClick(original.index, original.node)}>
                    View
                  </Button>
                );
              },
            },
          ]),
      ..._.map(config.order, colId => ({
        id: colId,
        resizable: true,
        Header: config.columnNames[colId],
        sortable: true,
        Cell: ({original}: any) => {
          return (
            <Cell
              table={config}
              colId={colId}
              inputNode={inputNode}
              rowNode={original.node}
              selectFunction={config.columnSelectFunctions[colId]}
              panelId={config.columns[colId].panelId}
              config={config.columns[colId].panelConfig}
              panelContext={props.context}
              updateTableState={updateConfig}
              updatePanelContext={updateContext}
            />
          );
        },
      })),
      ...(props.actions != null
        ? [
            {
              id: '__actions__',

              Header: '',
              sortable: false,
              maxWidth: 50,
              Cell: ({original}: any) => {
                return (
                  <MenuWrapper>
                    <PopupDropdown
                      offset={'10px, -10px'}
                      position="bottom right"
                      trigger={
                        <HighlightedIcon
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                          }}>
                          <LegacyWBIcon name="overflow" />
                        </HighlightedIcon>
                      }
                      options={props.actions?.map(action => {
                        return {
                          ...action,
                          onClick: () => {
                            action.onClick(original.index, original.node);
                          },
                        };
                      })}
                    />
                  </MenuWrapper>
                );
              },
            },
          ]
        : []),
    ],
    [
      config,
      props.context,
      props.actions,
      inputNode,
      updateConfig,
      updateContext,
      onRowClick,
    ]
  );

  const numRows = CGReact.useNodeValue(
    useMemo(() => Op.opCount({arr: inputNode}), [inputNode])
  );

  const data = useMemo(() => {
    return rowNodes.map((row: any, i: number) => ({
      row: {
        index: i,
        node: row,
      },
      searchString: '',
    }));
  }, [rowNodes]);
  const loading = rowNodesUse.loading || numRows.loading;
  const gatedData = useGatedValue(data, () => !loading);
  return (
    <WBReactTable
      data={gatedData}
      columns={columns}
      loading={loading}
      // Search
      noSearch={true}
      // Paging
      // noPaging?: boolean;
      pageSize={pageSize}
      onChangePageSize={setPageSize}
      onFetchNextPage={() => {
        setMaxPage(maxPage + 1);
      }}
      hasNextPage={maxPage * pageSize < numRows.result}
      // fetchingNextPage?: boolean;
      // pageSizeOptions?: number[];

      // Sorting
      // sortable={false}
      // defaultSorted?: SortingRule[];
      // onSortedChange?: SortedChangeFunction;
      // defaultSortMethod?: SortFunction;
      style={{
        overflowY: 'auto',
        marginBottom: '0px',
        border: 'none',
        height: '100%',
      }}
    />
  );
};

const dummyUpdateContext = () => {};
const dummyContext = {};

export const QuickPanelWBReactTable: React.FC<
  {
    input: GraphTypes.OutputNode<Types.ListType<'any'>>;
    columns: Array<{
      name: string;
      selectFn: GraphTypes.Node;
      panelDef?: {panelID: string; panelConfig: any};
    }>;
  } & PanelWBReactTableExtraProps
> = props => {
  const weave = useWeaveContext();
  const {input, columns} = props;
  const tableStateDef = React.useMemo(() => {
    let state = Table.emptyTable();

    columns.forEach(({name, selectFn, panelDef}) => {
      state = Table.addNamedColumnToTable(state, name, selectFn, panelDef);
    });
    return state;
  }, [columns]);

  const {frame} = usePanelContext();

  const {loading, result: tableState} = useTableStateWithRefinedExpressions(
    tableStateDef,
    input,
    frame,
    weave
  );

  // NOTE: `|| tableState == null` should not be needed
  // since it will always be true if loading and always
  // be false if not loading. Once TS is updated, we can
  // remove this.
  if (loading || tableState == null) {
    return <Panel2Loader />;
  }

  return (
    <QuickPanelWBReactTableInner
      {...props}
      input={input}
      tableStateDef={tableState}
    />
  );
};
const QuickPanelWBReactTableInner: React.FC<
  {
    input: GraphTypes.OutputNode<Types.ListType<'any'>>;
    tableStateDef: Table.TableState;
  } & PanelWBReactTableExtraProps
> = props => {
  const {tableStateDef} = props;
  const initialTableDef = React.useRef(tableStateDef);
  const [tableState, updateTableState] = React.useState(tableStateDef);

  React.useEffect(() => {
    if (initialTableDef.current !== tableStateDef) {
      initialTableDef.current = tableStateDef;
      updateTableState(tableStateDef);
    }
  }, [tableStateDef]);

  const updateConfig = React.useCallback(
    (newState: Partial<Table.TableState>) => {
      updateTableState({
        ...tableState,
        ...newState,
      });
    },
    [tableState, updateTableState]
  );

  // OK to not memoize since we use the spread operator below
  const innerProps = _.omit(props, ['tableStateDef']);

  return (
    <Panel2Style.Panel2SizeBoundary>
      <PanelWBReactTable
        {...innerProps}
        context={dummyContext}
        config={tableState}
        updateConfig={updateConfig}
        updateContext={dummyUpdateContext}
      />
    </Panel2Style.Panel2SizeBoundary>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'wb-react-table',
  Component: PanelWBReactTable,
  inputType,
};
