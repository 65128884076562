export function padToLength(str: string, length: number, padStr = ' '): string {
  let padded = str;
  while (padded.length < length) {
    padded = padStr + padded;
  }
  return padded;
}

export function capitalizeFirstLetter(str: string): string {
  return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : '';
}
