import wait from '@wandb/common/util/wait';
import fetchJsonp from 'fetch-jsonp';
import React, {useEffect, useRef, useState} from 'react';
import {Editor, Element, Node, Transforms} from 'slate';
import {RenderElementProps, useSelected} from 'slate-react';

import {InstrumentedLoader as Loader} from './../../../components/utility/InstrumentedLoader';
import {BlockWrapper} from './drag-drop';
import * as S from './twitter.styles';

export interface Twitter extends Element {
  type: 'twitter';
  html: string;
}

export const isTwitter = (node: Node): node is Twitter =>
  node.type === 'twitter';

export const TwitterElement: React.FC<
  RenderElementProps & {
    element: Twitter;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    twitterize();
    (async () => {
      while (true) {
        const blockquote = containerRef.current?.querySelector('blockquote');
        if (blockquote == null) {
          setLoaded(true);
          return;
        }
        await wait(200);
      }
    })();
  }, []);
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.Container
        selected={selected}
        ref={containerRef}
        loaded={loaded}
        dangerouslySetInnerHTML={{__html: element.html}}
      />
      {!loaded && <Loader name="twitter-loader" />}
      {children}
    </BlockWrapper>
  );
};

const TWITTER_REGEX =
  /^(?<url>https?:\/\/(www\.)?twitter\.com\/[^/]+\/status\/\d+)\/?(\?.*)?$/;

export const withTwitter = <T extends Editor>(editor: T) => {
  const {isVoid, insertText} = editor;

  editor.isVoid = element => {
    return isTwitter(element) ? true : isVoid(element);
  };

  editor.insertText = text => {
    (async () => {
      const match = TWITTER_REGEX.exec(text);
      if (match?.groups != null) {
        try {
          const response = await fetchJsonp(
            `https://publish.twitter.com/oembed?url=${match.groups.url}&omit_script=t`
          );
          const {html} = await response.json();
          if (html && typeof html === 'string') {
            insertTwitterElement(html);
          }
          return;
        } catch (e) {
          console.error(`error embedding Twitter link:`, e);
        }
      }

      insertText(text);
    })();
  };

  return editor;

  function insertTwitterElement(html: string) {
    const node: Node = {
      type: 'twitter',
      html,
      children: [{text: ''}],
    };

    Transforms.splitNodes(editor);
    Transforms.insertNodes(editor, node);
  }
};

function twitterize() {
  const twttr = (window as any).twttr;
  if (twttr != null && twttr.widgets != null) {
    twttr.widgets.load();
    return;
  }
  const script = document.createElement('script');
  script.async = true;
  script.src = '//platform.twitter.com/widgets.js';
  script.charset = 'utf-8';
  document.getElementsByTagName('body')[0].appendChild(script);
}
