import {Node, OutputNode} from '@wandb/cg';
import {
  constFunction,
  constNone,
  constString,
  isAssignableTo,
  opArtifactAliasAlias,
  opArtifactId,
  opArtifactIsPortfolio,
  opArtifactLastMembership,
  opArtifactMembershipArtifactAliases,
  opArtifactMembershipArtifactVersion,
  opArtifactMembershipForAlias,
  opArtifactMemberships,
  opArtifactMembershipVersionIndex,
  opArtifactName,
  opArtifactProject,
  opArtifactType,
  opArtifactTypeName,
  opArtifactVersionId,
  opArtifactVersions,
  opCount,
  opDict,
  opEntityName,
  opMap,
  opProjectEntity,
  opProjectName,
} from '@wandb/cg';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {WBPopupMenuTrigger} from '@wandb/ui';
import {
  useNodeValue,
  useNodeValueExecutor,
  useRefreshAllNodes,
} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import _ from 'lodash';
import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown} from 'semantic-ui-react';

import {isVersionAlias} from '../../../../util/artifacts';
import * as Urls from '../../../../util/urls';
import ArtifactActionHistory from '../../../ArtifactActionHistory';
import ArtifactCollectionSettings from '../../../ArtifactCollectionSettings';
import NoMatch from '../../../NoMatch';
import {
  ConfigType as PanelArtifactMembershipConfigType,
  PanelArtifactMembership,
} from '../../artifactMembership/PanelArtifactMembership';
import {DeleteMembershipModal} from '../../artifactMembership/PanelArtifactMembershipOverview/DeleteMembershipModal';
import {
  ConfigType as PanelArtifactHistoryConfigType,
  PanelArtifactHistory,
} from '../PanelArtifactHistory';
import {PanelEmptyArtifact} from '../PanelEmptyArtifact';
import {
  ArtifactCollectionViewType,
  isArtifactCollectionView,
} from './ArtifactPanelRoot';
import * as S from './style';

const inputType = 'artifact' as const;

type selectedCollectionViewType = 'membership' | ArtifactCollectionViewType;

export type ConfigType = {
  // selectedCollectionView determines the collection-level view selected - null should default to membership
  selectedCollectionView?: selectedCollectionViewType;
  tabConfigs?: {
    overview?: PanelArtifactMembershipConfigType; // for legacy reasons, `overview` is used for `membership` config
    versions?: PanelArtifactHistoryConfigType;
  };
  // The following field is only applicable when selectedCollectionView is 'membership'
  // selectedMembershipIdentifier determines the membership identifier selected - null should default to latest membership version
  selectedMembershipIdentifier?: string;
};

const PanelArtifact: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {artifactRegistry?: boolean}
> = props => {
  const panelArtifactValue = useNodeValue(props.input);
  if (panelArtifactValue.loading) {
    return <WandbLoader />;
  } else if (panelArtifactValue.result == null) {
    return <NoMatch />;
  }
  return <PAInner {...props} />;
};

const PAInner: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {artifactRegistry?: boolean}
> = props => {
  // 1. Extract Props
  const {updateConfig, config} = props;
  const artifactCollectionNode = props.input;
  const collectionView = config?.selectedCollectionView ?? 'membership';
  const membershipIdentifier = config?.selectedMembershipIdentifier;

  // 2. Component State
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);

  // 3. Initialize Child Configs
  const tabConfig = Panel2.useConfigChild('tabConfigs', config, updateConfig);
  const membershipConfig = Panel2.useConfigChild(
    'overview',
    tabConfig.config,
    tabConfig.updateConfig
  );
  const versionsConfig = Panel2.useConfigChild(
    'versions',
    tabConfig.config,
    tabConfig.updateConfig
  );

  // 4. General Hooks
  const refreshAll = useRefreshAllNodes();
  const cgExecutor = useNodeValueExecutor();
  // TODO: This should be removed - we don't want URL management done
  // in panels - very bad
  const history = useHistory();

  // 5. Define User Actions
  const setSelectedMembershipIdentifier = React.useCallback(
    (newIdentifier: string) => {
      updateConfig({
        selectedMembershipIdentifier: newIdentifier,
        selectedCollectionView: 'membership',
        // When selecting a membership, we reset to the overview subtab
        tabConfigs: {
          ...config?.tabConfigs,
          overview: {
            ...config?.tabConfigs?.overview,
            selectedTab: 'overview',
          },
        },
      });
    },
    [updateConfig, config]
  );

  const setSelectedCollectionView = React.useCallback(
    (newView: ArtifactCollectionViewType) => {
      updateConfig({
        selectedMembershipIdentifier: undefined,
        selectedCollectionView: newView,
        tabConfigs: {
          ...config?.tabConfigs,
          overview: {
            ...config?.tabConfigs?.overview,
            selectedTab: undefined,
          },
        },
      });
    },
    [updateConfig, config]
  );

  const onHistorySelect = React.useCallback(
    (index: number, node: Node) => {
      if (isAssignableTo(node.type, 'artifactMembership')) {
        cgExecutor(
          opArtifactMembershipVersionIndex({artifactMembership: node})
        ).then(versionIndex => {
          if (versionIndex != null) {
            setSelectedMembershipIdentifier('v' + versionIndex);
          }
        });
      }
    },
    [cgExecutor, setSelectedMembershipIdentifier]
  );

  // 6. Panel Business Logic
  // Get the collection data needed for this component via Weave Query
  const artifactCollectionData = useNodeValue(
    React.useMemo(
      () => dataDictNode(artifactCollectionNode),
      [artifactCollectionNode]
    )
  );

  // Get the Weave Membership Node
  const artifactMembershipNode = React.useMemo(() => {
    if (membershipIdentifier == null) {
      return constNone();
    }
    return opArtifactMembershipForAlias({
      artifact: artifactCollectionNode,
      aliasName: constString(membershipIdentifier),
    }) as OutputNode<'artifactMembership'>;
  }, [artifactCollectionNode, membershipIdentifier]);

  // Get the Weave Data Node for the membership version
  const currentVersionDictNode = React.useMemo(() => {
    return opDict({
      index: opArtifactMembershipVersionIndex({
        artifactMembership: artifactMembershipNode,
      }),
      id: opArtifactVersionId({
        artifactVersion: opArtifactMembershipArtifactVersion({
          artifactMembership: artifactMembershipNode,
        }),
      }),
    } as any) as OutputNode<{
      type: 'typedDict';
      propertyTypes: {
        index: 'number';
        id: 'string';
      };
    }>;
  }, [artifactMembershipNode]);

  // Get the current version data needed for this component
  const currentVersionValue = useNodeValue(currentVersionDictNode);

  // Extract some derived properties
  const isPortfolio = artifactCollectionData.result?.collectionIsPortfolio;
  const hasAliases = _.some(
    artifactCollectionData.result?.collectionMemberships ?? [],
    m => m.aliases.length > 0
  );
  const lastVersionIndex =
    artifactCollectionData.result?.lastMembershipVersionIndex;
  const selectedMembershipMissing =
    membershipIdentifier != null &&
    !currentVersionValue.loading &&
    currentVersionValue.result?.id === null;
  const collectionIsEmpty =
    !artifactCollectionData.loading && lastVersionIndex == null;

  // 7. Effects
  // Here we want to update the selected membership when viewing the membership tab, under 2 cases:
  // a. No identifier is selected, but there is a default identifier (at least one member).
  // b. An identifier is selected, but it is not present in the collection
  React.useEffect(() => {
    // If we are looking a the membership tab, and there exists at least one member
    if (collectionView === 'membership' && lastVersionIndex != null) {
      // If there is no selected membership, or the selected membership is messing,
      if (membershipIdentifier == null || selectedMembershipMissing) {
        // switch to the latest membership
        updateConfig({
          selectedMembershipIdentifier: `v${lastVersionIndex}`,
        });
      }
    }
  }, [
    collectionView,
    lastVersionIndex,
    membershipIdentifier,
    selectedMembershipMissing,
    setSelectedMembershipIdentifier,
    updateConfig,
  ]);

  // 8. Child Props
  const dropdownOptions = React.useMemo(() => {
    if (artifactCollectionData.result == null) {
      return [];
    }
    let options: Array<{
      value?: string;
      text?: string;
      header?: boolean;
      headerContent?: string;
      divider?: boolean;
    }> = [{value: 'versions', text: 'All Versions'}];
    if (isPortfolio) {
      options.unshift({value: 'settings', text: 'Settings'});
      options.push({value: 'action_history', text: 'Action History'});
    }
    const alias2version: {[key: string]: string} = {};
    const versions = artifactCollectionData.result.collectionMemberships.map(
      m => {
        const v = `v${m.versionIndex}`;
        m.aliases.filter(a => a !== v).forEach(a => (alias2version[a] = v));
        return {
          value: v,
          text: v,
        };
      }
    );

    const aliasOptions = Object.keys(alias2version).map(a => {
      return {value: `${a}`, text: a};
    });

    options = [
      ...options,
      {header: true, headerContent: 'Aliases'},
      {divider: true},
      ...aliasOptions,
      {header: true, headerContent: 'Versions'},
      {divider: true},
      ...versions,
    ];

    return options;
  }, [artifactCollectionData.result, isPortfolio]);

  const dropdownText = React.useMemo(() => {
    if (currentVersionValue.loading) {
      return '';
    }
    if (collectionView === 'action_history') {
      return 'Action History';
    } else if (collectionView === 'versions') {
      return 'Versions';
    } else if (collectionView === 'settings') {
      return 'Settings';
    } else if (isVersionAlias(membershipIdentifier)) {
      return `Version ${currentVersionValue.result.index}`;
    } else {
      return membershipIdentifier;
    }
  }, [currentVersionValue, collectionView, membershipIdentifier]);

  if (artifactCollectionData.loading) {
    return <WandbLoader name="artifact-collection" />;
  }

  const artifactCollectionDataValue = artifactCollectionData.result!;

  return (
    <S.ArtifactCollectionMain>
      {!collectionIsEmpty && (
        <S.PanelArtifactHeaderContent>
          <S.PanelArtifactHeaderTitle>
            {artifactCollectionDataValue.collectionName}
            <S.CollectionDropdown
              data-test="artifact-collection-dropdown"
              button
              compact
              scrolling
              text={dropdownText}>
              <Dropdown.Menu>
                {dropdownOptions.map((option, i) => {
                  if (option.header === true) {
                    return (
                      <Dropdown.Header
                        key={`header-${i}`}
                        content={option.headerContent}
                      />
                    );
                  } else if (option.divider === true) {
                    return <Dropdown.Divider key={`divider-${i}`} />;
                  } else {
                    return (
                      <Dropdown.Item
                        key={`item-${i}`}
                        text={option.text}
                        value={option.value}
                        onClick={(d, {value}) => {
                          const v = value as string;
                          if (isArtifactCollectionView(v)) {
                            setSelectedCollectionView(v);
                          } else {
                            setSelectedMembershipIdentifier('' + v);
                          }
                        }}
                      />
                    );
                  }
                })}
              </Dropdown.Menu>
            </S.CollectionDropdown>
          </S.PanelArtifactHeaderTitle>
          {!currentVersionValue.loading && collectionView === 'membership' && (
            <>
              <S.TriggerContainer>
                <WBPopupMenuTrigger
                  data-test="membership-options-popup"
                  options={[
                    {
                      value: 'delete',
                      name: isPortfolio ? 'Unlink' : 'Delete',
                      icon: 'delete',
                    },
                  ]}
                  onSelect={value => {
                    if (value === 'delete') {
                      setDeleteModalOpen(true);
                    }
                  }}>
                  {({anchorRef, setOpen}) => (
                    <S.VersionMenuButton
                      data-test="membership-options-trigger"
                      name="overflow"
                      title="menu"
                      ref={anchorRef}
                      onClick={() => setOpen(true)}
                    />
                  )}
                </WBPopupMenuTrigger>
              </S.TriggerContainer>
              <DeleteMembershipModal
                open={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                isPortfolio={isPortfolio}
                artifactName={artifactCollectionDataValue.collectionName}
                versionIndex={currentVersionValue.result.index}
                hasAliases={hasAliases}
                hasMemberships={
                  artifactCollectionDataValue.collectionMemberships.length > 0
                }
                afterDelete={() => {
                  const url = Urls.artifactSequence({
                    entityName: artifactCollectionDataValue.entityName,
                    projectName: artifactCollectionDataValue.projectName,
                    artifactTypeName:
                      artifactCollectionDataValue.artifactTypeName,
                    artifactSequenceName:
                      artifactCollectionDataValue.collectionName,
                  });
                  refreshAll().then(() => history.push(url));
                }}
                artifactId={artifactCollectionDataValue.collectionId}
                artifactVersionId={currentVersionValue.result.id}
              />
            </>
          )}
        </S.PanelArtifactHeaderContent>
      )}
      {collectionView === 'settings' && (
        <S.PanelWrapper>
          <ArtifactCollectionSettings
            // This is bad form - we convert to strings then back to Weave.
            // TODO: Convert ArtifactCollectionSettings into a proper WeavePanel
            entityName={artifactCollectionDataValue.entityName}
            projectName={artifactCollectionDataValue.projectName}
            artifactCollectionName={artifactCollectionDataValue.collectionName}
          />
        </S.PanelWrapper>
      )}
      {collectionView === 'action_history' && (
        <S.PanelWrapper>
          <ArtifactActionHistory
            entityName={artifactCollectionDataValue.entityName}
            projectName={artifactCollectionDataValue.projectName}
            artifactTypeName={artifactCollectionDataValue.artifactTypeName}
            artifactCollectionName={artifactCollectionDataValue.collectionName}
            pageSize={10}
          />
        </S.PanelWrapper>
      )}
      {collectionView === 'versions' && (
        <S.PanelWrapper>
          <PanelArtifactHistory
            input={props.input}
            config={versionsConfig.config}
            updateConfig={versionsConfig.updateConfig}
            context={props.context}
            updateContext={props.updateContext}
            onRowClick={onHistorySelect}
          />
        </S.PanelWrapper>
      )}
      {collectionView === 'membership' &&
        (collectionIsEmpty ? (
          <S.BlankPageWrapper>
            <PanelEmptyArtifact
              {...Panel2.dummyProps}
              input={props.input}
              isPortfolio={isPortfolio}
            />
          </S.BlankPageWrapper>
        ) : currentVersionValue.loading ? (
          <WandbLoader name="artifact-collection-membership" />
        ) : (
          !selectedMembershipMissing &&
          membershipIdentifier != null && (
            <PanelArtifactMembership
              input={artifactMembershipNode as any}
              config={membershipConfig.config}
              context={props.context}
              updateContext={props.updateContext}
              updateConfig={membershipConfig.updateConfig}
              targetIdentifier={membershipIdentifier}
              artifactRegistry={props.artifactRegistry}
            />
          )
        ))}
    </S.ArtifactCollectionMain>
  );
};

export default PanelArtifact;

function dataDictNode(artifactNode: Node<'artifact'>) {
  return opDict({
    collectionName: opArtifactName({artifact: artifactNode}),
    collectionId: opArtifactId({artifact: artifactNode}),
    collectionIsPortfolio: opArtifactIsPortfolio({
      artifact: artifactNode,
    }),
    collectionMemberships: opMap({
      arr: opArtifactMemberships({
        artifact: artifactNode,
      }),
      mapFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opDict({
          versionIndex: opArtifactMembershipVersionIndex({
            artifactMembership: row,
          }),
          versionId: opArtifactMembershipArtifactVersion({
            artifactMembership: row,
          }),
          aliases: opArtifactAliasAlias({
            artifactAlias: opArtifactMembershipArtifactAliases({
              artifactMembership: row,
            }),
          }),
        } as any);
      }),
    }),
    versionCount: opCount({
      arr: opArtifactVersions({artifact: artifactNode}),
    }),
    lastMembershipVersionIndex: opArtifactMembershipVersionIndex({
      artifactMembership: opArtifactLastMembership({
        artifact: artifactNode,
      }),
    }),
    entityName: opEntityName({
      entity: opProjectEntity({
        project: opArtifactProject({
          artifact: artifactNode,
        }),
      }),
    }),
    projectName: opProjectName({
      project: opArtifactProject({
        artifact: artifactNode,
      }),
    }),
    artifactTypeName: opArtifactTypeName({
      artifactType: opArtifactType({
        artifact: artifactNode,
      }),
    }),
  } as any) as OutputNode<{
    type: 'typedDict';
    propertyTypes: {
      collectionName: 'string';
      collectionId: 'string';
      collectionIsPortfolio: 'boolean';
      collectionMemberships: {
        type: 'list';
        objectType: {
          type: 'typedDict';
          propertyTypes: {
            versionIndex: 'number';
            versionId: 'string';
            aliases: {type: 'list'; objectType: 'string'};
          };
        };
      };
      versionCount: 'number';
      lastMembershipVersionIndex: 'number';
      entityName: 'string';
      projectName: 'string';
      artifactTypeName: 'string';
    };
  }>;
}
