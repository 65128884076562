import * as globals from '@wandb/common/css/globals.styles';
import {TargetBlank} from '@wandb/common/util/links';
import isUrl from 'is-url';
import React, {useEffect, useMemo, useState} from 'react';
import {Checkbox} from 'semantic-ui-react';

import BarChart from '../components/vis/BarChart';
import * as PlotHelpers from '../util/plotHelpers';
import * as S from './CollapsibleJSONTable.styles';

type RenderDataProps = {
  data: any;
  label: string;
  level?: number;
  ignoreUnderscoreKeys?: boolean;
  searchQuery?: string;
  parentMatchesSearch?: boolean;
};

const recursiveObjKeys = (obj: {[key: string]: any}) => {
  const keys: Set<string> = new Set();
  const addKeys = (subObj: {[key: string]: any} = obj) => {
    Object.keys(subObj).forEach(k => {
      keys.add(k);
      if (typeof subObj[k] === 'object' && subObj[k] != null) {
        addKeys(subObj[k]);
      }
    });
  };
  addKeys(obj);
  return Array.from(keys);
};

const groupByKeyPrefix = (obj: {[key: string]: any}) => {
  const objKeys = Object.keys(obj).sort();
  const groupedByKeyPrefix = objKeys.reduce((acc, key) => {
    const keyPrefix = key.split('/')[0];
    const keySuffix = key.split('/').slice(1).join('/');
    if (acc[keyPrefix] == null) {
      acc[keyPrefix] = {};
    }
    if (keySuffix && typeof acc[keyPrefix] !== 'object') {
      const value = acc[keyPrefix];
      acc[keyPrefix] = {};
      acc[keyPrefix][''] = value;
    }
    if (
      keySuffix &&
      typeof acc[keyPrefix] === 'object' &&
      acc[keyPrefix] != null &&
      !Array.isArray(acc[keyPrefix])
    ) {
      try {
        acc[keyPrefix][keySuffix] = obj[key];
      } catch (e) {
        console.error(e);
      }
    } else {
      acc[keyPrefix] = obj[key];
    }
    return acc;
  }, {} as {[key: string]: {[key: string]: any}});
  return groupedByKeyPrefix;
};

const RenderData: React.FC<RenderDataProps> = React.memo(
  ({
    label,
    data,
    level = 0,
    ignoreUnderscoreKeys = true,
    searchQuery = '',
    parentMatchesSearch = false,
  }) => {
    const dataValue = useMemo(() => {
      if (data?.value != null) {
        return data.value;
      } else {
        return data;
      }
    }, [data]);

    const [childrenExpanded, setChildrenExpanded] = useState(
      !Array.isArray(dataValue) &&
        label !== 'gradients' &&
        label !== 'parameters'
    );

    const childKeys = useMemo(() => {
      if (typeof dataValue === 'object' && dataValue != null) {
        return recursiveObjKeys(dataValue);
      } else {
        return [];
      }
    }, [dataValue]);

    const childMatchesSearchQuery = useMemo(() => {
      return (
        searchQuery === '' ||
        childKeys
          .filter(k => (ignoreUnderscoreKeys ? !k.startsWith('_') : true))
          .some(k => k.includes(searchQuery))
      );
    }, [childKeys, ignoreUnderscoreKeys, searchQuery]);

    const matchesSearchQuery = useMemo(() => {
      return searchQuery === '' || label.toLowerCase().includes(searchQuery);
    }, [label, searchQuery]);

    const [searchQueryToPassDown, setSearchQueryToPassDown] =
      useState(searchQuery);

    useEffect(() => {
      if (childrenExpanded && childMatchesSearchQuery) {
        setSearchQueryToPassDown(searchQuery);
      }
    }, [childMatchesSearchQuery, searchQuery, childrenExpanded]);

    const shouldDisplay = useMemo(() => {
      return (
        (ignoreUnderscoreKeys ? !label.startsWith('_') : true) &&
        (matchesSearchQuery || childMatchesSearchQuery || parentMatchesSearch)
      );
    }, [
      ignoreUnderscoreKeys,
      label,
      matchesSearchQuery,
      childMatchesSearchQuery,
      parentMatchesSearch,
    ]);

    const [isHistogram, setIsHistogram] = useState(false);
    const [histogramData, setHistogramData] = useState<PlotHelpers.Bar[]>([]);

    useEffect(() => {
      if (
        dataValue?._type === 'histogram' &&
        dataValue.bins &&
        dataValue.values
      ) {
        const dataPoints: PlotHelpers.Bar[] = dataValue.values.map(
          (elem: number, index: number) => {
            const key =
              dataValue.bins && dataValue.bins.length > index
                ? dataValue.bins[index].toPrecision(4)
                : '-';
            return {
              key,
              value: elem,
              color: globals.darkBlue,
              title: 'x= ' + key + ', y=',
            };
          }
        );
        setHistogramData(dataPoints);
        setIsHistogram(true);
      }
    }, [dataValue]);

    return (
      <div
        style={{
          display: shouldDisplay ? 'block' : 'none',
        }}
        key={`${label}${level}`}>
        {typeof dataValue !== 'object' || dataValue == null ? (
          <S.Row>
            <S.LabelCell level={level}>{label}</S.LabelCell>
            <S.ValueCell>
              {typeof dataValue === 'string' && isUrl(dataValue) ? (
                <TargetBlank href={dataValue}>{dataValue}</TargetBlank>
              ) : (
                JSON.stringify(dataValue)
              )}
            </S.ValueCell>
          </S.Row>
        ) : isHistogram && histogramData.length > 0 ? (
          <S.Row>
            <S.LabelCell level={level}>{label}</S.LabelCell>
            <S.ValueCell extraPadding={true}>
              <BarChart
                bars={histogramData}
                vertical={true}
                height={150}></BarChart>
            </S.ValueCell>
          </S.Row>
        ) : (
          <>
            <S.Row>
              <S.LabelCell
                isParent
                level={level}
                onClick={() => {
                  setChildrenExpanded(curr => !curr);
                }}>
                {childrenExpanded ? (
                  <S.CaretIcon name="down" />
                ) : (
                  <S.CaretIcon name="next" />
                )}{' '}
                {label}{' '}
                {childrenExpanded
                  ? ''
                  : `(${
                      Object.keys(dataValue).filter(key =>
                        ignoreUnderscoreKeys ? !key.startsWith('_') : true
                      ).length
                    } collapsed)`}
              </S.LabelCell>
            </S.Row>
            <div
              style={{
                display: childrenExpanded && shouldDisplay ? 'block' : 'none',
              }}>
              {Object.keys(dataValue)
                .sort()
                .map(key => (
                  <RenderData
                    key={`${key}${level}`}
                    label={key}
                    data={dataValue[key]}
                    level={level + 1}
                    searchQuery={searchQueryToPassDown}
                    parentMatchesSearch={matchesSearchQuery}
                    ignoreUnderscoreKeys={ignoreUnderscoreKeys}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    );
  }
);

const CollapsibleJSONTable: React.FC<{
  data: any;
  ignoreWandbKey?: boolean;
}> = ({data, ignoreWandbKey = true}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [ignoreUnderscoreKeys, setIgnoreUnderscoreKeys] = useState(true);

  const groupedData = useMemo(() => {
    if (data != null && typeof data === 'object' && !Array.isArray(data)) {
      try {
        return groupByKeyPrefix(data);
      } catch (e) {
        console.error(e);
        return data;
      }
    } else {
      return data;
    }
  }, [data]);

  return (
    <S.TableWrapper>
      <S.StickySearchBar>
        <S.TextInput
          icon={{
            className: 'wbic-ic-search',
          }}
          iconPosition="left"
          placeholder="Search keys"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setSearchQuery(
              (e.target as HTMLInputElement).value.toLowerCase().trim()
            );
          }}
        />
        <S.CheckboxWrapper>
          <Checkbox
            checked={ignoreUnderscoreKeys}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setIgnoreUnderscoreKeys(prev => !prev);
            }}
            toggle
          />
          &nbsp;&nbsp;Ignore underscore keys
        </S.CheckboxWrapper>
      </S.StickySearchBar>
      <S.StickyHeader>
        <S.HeaderCell isKeyHeader>Key</S.HeaderCell>
        <S.HeaderCell>Value</S.HeaderCell>
      </S.StickyHeader>
      {typeof groupedData === 'object' &&
        groupedData != null &&
        Object.keys(groupedData)
          .filter(key => (ignoreWandbKey ? key !== '_wandb' : true))
          .sort()
          .map(key => (
            <RenderData
              key={key}
              label={key}
              data={groupedData[key]}
              ignoreUnderscoreKeys={ignoreUnderscoreKeys}
              searchQuery={searchQuery}
            />
          ))}
    </S.TableWrapper>
  );
};

export default CollapsibleJSONTable;
