export type LabelColor = 'green' | 'teal' | 'sienna' | 'gold' | 'red' | 'gray';
export type LabelMode = 'light' | 'night';
export type LabelVariant = 'pill' | 'tag';

export const LABEL_COLORS: {[key: string]: LabelColor} = {
  GREEN: 'green',
  TEAL: 'teal',
  SIENNA: 'sienna',
  GOLD: 'gold',
  RED: 'red',
  GRAY: 'gray',
};

export const LABEL_MODES: {[key: string]: LabelMode} = {
  LIGHT: 'light',
  NIGHT: 'night',
};

export const LABEL_VARIANTS: {[key: string]: LabelVariant} = {
  PILL: 'pill',
  TAG: 'tag',
};
