import produce from 'immer';
import _ from 'lodash';
import React from 'react';
import {useCallback} from 'react';

import {useDispatch} from '../../state/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as SortActions from '../../state/views/sort/actions';
import * as SortTypes from '../../state/views/sort/types';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import {NamedProjectFieldSelector} from '../ProjectFieldSelector';
import {
  WBTableActionSort,
  WBTableActionSortButton,
  WBTableActionSortPicker,
} from '../WBTable/WBTableActionsSort';

interface RunsSortButtonProps {
  compact?: boolean;
  isInReport?: boolean;
  sortOpen: boolean;
  sortRef: SortTypes.Ref;
}

const RunsSortButton = (props: RunsSortButtonProps) => {
  const sort = ViewHooks.useWhole(props.sortRef);

  const passThroughProps = _.omit(props, 'compact', 'sortOpen', 'sortRef');
  return (
    <WBTableActionSortButton
      {...passThroughProps} // Required for use as popup trigger
      compact={props.compact}
      sortOpen={props.sortOpen}
      sort={sort}
    />
  );
};

interface RunsSortPickerProps {
  entityName: string;
  projectName: string;
  sortRef: SortTypes.Ref;
  onSortChanged(): void;
}

const RunsSortPicker = (props: RunsSortPickerProps) => {
  const {entityName, projectName, sortRef, onSortChanged} = props;

  const defaultKeys = [
    'run:displayName',
    'run:state',
    'run:createdAt',
    'run:duration',
    'run:username',
    'run:sweep',
    'run:host',
  ];

  const sort = ViewHooks.useWhole(props.sortRef);
  const dispatch = useDispatch();
  const setSort = useCallback(
    (newSort: Query.Sort) => {
      dispatch(SortActions.set(sortRef, newSort));
      onSortChanged();
    },
    [dispatch, sortRef, onSortChanged]
  );

  return (
    <WBTableActionSortPicker
      sort={sort}
      setSort={setSort}
      makeDropdown={sortKeyIdx => (
        <NamedProjectFieldSelector
          data-test="sort-dropdown"
          className="sort"
          entityName={entityName}
          projectName={projectName}
          defaultKeys={defaultKeys}
          multi={false}
          selection
          searchByKeyAndText
          value={Run.keyToString(sort.keys[sortKeyIdx].key)}
          setValue={value =>
            setSort(
              produce(sort, draft => {
                draft.keys[sortKeyIdx].key = Run.keyFromString(value)!;
              })
            )
          }
        />
      )}
    />
  );
};

type RunsSortTableActionProps = RunsSortPickerProps & {
  compact?: boolean;
  isInReport?: boolean;
  open: boolean;
  onOpen(): void;
  onClose(): void;
};

export const RunsSortTableAction = (props: RunsSortTableActionProps) => {
  return (
    <WBTableActionSort
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      trigger={open => (
        <RunsSortButton
          compact={props.compact}
          isInReport={props.isInReport}
          sortOpen={open}
          sortRef={props.sortRef}
        />
      )}
      content={
        <RunsSortPicker
          entityName={props.entityName}
          projectName={props.projectName}
          sortRef={props.sortRef}
          onSortChanged={props.onSortChanged}
        />
      }
    />
  );
};
