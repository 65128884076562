import memoize from 'memoize-one';
import React from 'react';

import * as ViewHooks from '../../state/views/hooks';
import * as SortTypes from '../../state/views/sort/types';
import {WBTableSortIndicator} from '../WBTable/WBTableSortIndicator';

interface RunsSortIndicatorProps {
  columnKeyString: string;
  sortRef: SortTypes.Ref;
}

export const RunsSortIndicator = (props: RunsSortIndicatorProps) => {
  const sort = ViewHooks.useWhole(props.sortRef);
  return (
    <WBTableSortIndicator sort={sort} columnKeyString={props.columnKeyString} />
  );
};

export const makeRunsSortIndicator = memoize((sortRef: SortTypes.Ref) => {
  const SortIndicator: React.FC<{
    columnKeyString: string;
  }> = props => {
    return (
      <RunsSortIndicator
        columnKeyString={props.columnKeyString}
        sortRef={sortRef}
      />
    );
  };
  return SortIndicator;
});
