import {SetState} from '@wandb/common/util/types';
import {useLayoutEffect, useState} from 'react';

import {usePrevious} from '../../state/hooks';

export const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 20, 50];
const DEFAULT_PAGE_SIZE = 5;

type UsePageEffectsParams = {
  rowCount: number;
  pageSize: number;
  setPage: SetState<number>;
};

type UsePageEffectsReturnType = {
  setAwaitingMoreData: SetState<boolean>;
};

export function usePageEffects({
  rowCount,
  pageSize,
  setPage,
}: UsePageEffectsParams): UsePageEffectsReturnType {
  useLayoutEffect(() => {
    if (rowCount === 0) {
      return;
    }

    // If the dataset or page size changes, we must update
    // page to ensure that it remains within bounds.
    const lastPage = Math.floor((rowCount - 1) / pageSize);
    setPage(prev => Math.min(prev, lastPage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount, pageSize]);

  const [awaitingMoreData, setAwaitingMoreData] = useState(false);
  const prevRowCount = usePrevious(rowCount);
  useLayoutEffect(() => {
    if (rowCount === 0) {
      return;
    }

    // go to the freshly fetched page after fetching
    if (awaitingMoreData && prevRowCount !== rowCount) {
      setAwaitingMoreData(false);
      setPage(prev => prev + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount, prevRowCount, awaitingMoreData]);

  return {setAwaitingMoreData};
}

type UseWBReactTablePageSizeParams = {
  rowCount: number;
  defaultPageSize?: number;
  noPaging?: boolean;
};

type UseWBReactTablePageSizeReturnType = {
  pageSize: number;
  setPageSize: SetState<number | null>;
};

export function useWBReactTablePageSize(
  params: UseWBReactTablePageSizeParams
): UseWBReactTablePageSizeReturnType {
  const [statePageSize, setPageSize] = useState<number | null>(null);
  const pageSize = getPageSize({...params, statePageSize});
  return {pageSize, setPageSize};
}

type GetPageSizeParams = UseWBReactTablePageSizeParams & {
  statePageSize: number | null;
};

function getPageSize({
  rowCount,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  statePageSize,
  noPaging,
}: GetPageSizeParams): number {
  const unclampedPageSize: number = (() => {
    if (noPaging) {
      return rowCount;
    }
    if (statePageSize != null) {
      return statePageSize;
    }
    if (rowCount > 0) {
      return defaultPageSize;
    }
    return 1;
  })();

  return Math.min(unclampedPageSize, rowCount);
}

export type GetShownRowsRangeParams = {
  rowCount: number;
  page: number;
  pageSize: number;
};

export function getShownRowsRange(params: GetShownRowsRangeParams): string {
  return `${getFirstShownRowIndex(params)}-${getLastShownRowIndex(params)}`;
}

function getFirstShownRowIndex({
  page,
  pageSize,
}: GetShownRowsRangeParams): number {
  return (page + 1) * pageSize - (pageSize - 1);
}

function getLastShownRowIndex({
  rowCount,
  page,
  pageSize,
}: GetShownRowsRangeParams): number {
  const onLastPage = page + 1 > Math.floor(rowCount / pageSize);
  const notEnoughRowsToFillLastPage = rowCount % pageSize !== 0;

  if (onLastPage && notEnoughRowsToFillLastPage) {
    return rowCount;
  }
  return (page + 1) * pageSize;
}

type GetSortValue<T> = (x: T) => number | string;

type SortMethod<T> = (a: T, b: T, desc: boolean) => number;

export function makeColumnSortMethod<T>(
  getSortValue: GetSortValue<T>
): SortMethod<T> {
  return (a, b, desc) => {
    const aVal = getSortValue(a);
    const bVal = getSortValue(b);
    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
    return 0;
  };
}
