/**
 * This file tracks events specific to experimentation.
 */
import {makeTrackEvent} from './makeTrackEvent';

export const enum ExperimentEventNames {
  AllocatedToExperiment = 'Allocated To Experiment', // tracks each time a user is allocated to an experiment variant
}

type AlloctedToExperimentData = {
  experimentName: string;
  graphqlId: string;
  id: string;
  observationalUnitId: string;
  hash: number;
  bucket: number; // To be deprecated in the future
  experimentBucket: number;
};

type AllocatedToExperimentDescriptions = {
  experimentName: {
    description: 'The name of the experiment user is allocated to.';
    exampleValues: ['AA Test', 'Feature Flag Test'];
  };
  graphqlId: {
    description: 'The graphql id of the experiment user is allocated to.';
    exampleValues: [
      'RXhwZXJpbWVudDo0ZWJlMDE3MS0wMjZkLTQ1OGEtOWY3Yi0xYjEwODQxYWUyODE='
    ];
  };
  id: {
    description: 'The sql experiment id. This value is decoded from the graphql experiment id.';
    exampleValues: ['4ebe0171-026d-458a-9f7b-1b10841ae281'];
  };
  observationalUnitId: {
    description: "This value is populated into the Variant component based on the experiment's observational unit. For example, if the experiment observational unit is User, than we should use the viewer's id.";
    exampleValues: ['VXNlcjoyOTU3ODk=', 'T3JnYW5pemF0aW9uOjM4MTgx'];
  };
  hash: {
    description: 'This value is the hash of the experimentId and the observationalUnitId. This hash is used to derive which treatment a user should receive.';
    exampleValues: [3253794768724839];
  };
  bucket: {
    description: 'This is a reserved work in Mixpanel and needs to be deprecated in the future.';
    exampleValues: [];
  };
  experimentBucket: {
    description: 'This value represents which Variant was displayed for the user.';
    exampleValues: [0, 1, 2];
  };
};

type AllocatedToExperimentMeta = {
  _description: 'A user is allocated to an experiment';
  _location: 'VariantWrapper -> Variant';
  _motivation: 'Track data for experiment analysis';
};

export const allocatedToExperiment = makeTrackEvent<
  AlloctedToExperimentData,
  AllocatedToExperimentDescriptions & AllocatedToExperimentMeta
>(ExperimentEventNames.AllocatedToExperiment);
