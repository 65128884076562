import {useFetchProjectLaunchQueuesQuery} from '../../generated/graphql';
import {DEFAULT_LAUNCH_PROJECT_NAME} from '../../pages/EntityLaunchPage';
import {GroupedQueueOptions, QueueOption} from './SelectQueue';
import {constructQueueOptions, sortQueues} from './utils';

// Query for the available launch queues and return a sorted array in the select widget format.
export const useGetQueueOptions = (entityName: string, projectName: string) => {
  const projectRunQueuesQuery = useFetchProjectLaunchQueuesQuery({
    variables: {
      entityName,
      projectName,
    },
  });
  const skipEntityQuery = projectName === DEFAULT_LAUNCH_PROJECT_NAME;
  const entityRunQueuesQuery = useFetchProjectLaunchQueuesQuery({
    variables: {
      entityName,
      projectName: DEFAULT_LAUNCH_PROJECT_NAME,
    },
    skip: skipEntityQuery,
  });
  const projectQueues = constructQueueOptions(projectRunQueuesQuery);
  sortQueues(projectQueues);
  if (skipEntityQuery) {
    return {
      queues: projectQueues,
      loading: projectRunQueuesQuery.loading,
      error: projectRunQueuesQuery.error,
    };
  }
  const entityQueues = constructQueueOptions(entityRunQueuesQuery);
  sortQueues(entityQueues);
  const queues: GroupedQueueOptions[] | QueueOption[] =
    projectName !== DEFAULT_LAUNCH_PROJECT_NAME
      ? [
          {
            label: 'Launch',
            options: entityQueues,
          },
          {
            label: projectName,
            options: projectQueues,
          },
        ]
      : projectQueues;
  return {
    queues,
    loading: projectRunQueuesQuery.loading || entityRunQueuesQuery.loading,
    error: projectRunQueuesQuery.error || entityRunQueuesQuery.error,
  };
};
