import {TargetBlank} from '@wandb/common/util/links';
import _ from 'lodash';
import React, {FC, useCallback, useMemo, useState} from 'react';
import {Modal} from 'semantic-ui-react';

import {CheckoutPlan, PlanInfoWithActualPlans} from '../../../util/pricing';
import * as urls from '../../../util/urls';
import * as S from './CheckoutModal.styles';
import {UsagePricingDetails} from './UsagePricingDetails';

type CheckoutModalContextState = {
  submitting: boolean;
};
export const CheckoutModalContext =
  React.createContext<CheckoutModalContextState | null>(null);

type CheckoutModalUpdaterContextState = {
  setSubmitting: (s: boolean) => void;
};
export const CheckoutModalUpdaterContext =
  React.createContext<CheckoutModalUpdaterContextState | null>(null);

type RenderFormParams = {
  plan: CheckoutPlan;
  seats: number;
  pricePerPeriod: string;
  onTransactionCompleted: () => void;
};

type CheckoutModalProps = {
  planInfo: PlanInfoWithActualPlans;
  orgName: string;
  defaultSeats?: number;
  onTransactionCompleted: () => void;
  onClose: () => void;
  renderForm: (p: RenderFormParams) => React.ReactNode;
  renderSuccess: () => React.ReactNode;
};

const CheckoutModalComp: FC<CheckoutModalProps> = props => {
  const {
    planInfo,
    orgName,
    defaultSeats,
    onTransactionCompleted,
    onClose,
    renderForm,
    renderSuccess,
  } = props;

  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const contextValue = useMemo(() => ({submitting}), [submitting]);
  const updaterContextValue = useMemo(() => ({setSubmitting}), []);

  const onCloseWhenNotSubmitting = useCallback(() => {
    if (!submitting) {
      onClose();
    }
  }, [submitting, onClose]);

  return (
    <CheckoutModalContext.Provider value={contextValue}>
      <CheckoutModalUpdaterContext.Provider value={updaterContextValue}>
        <Modal
          open
          basic
          className="checkout-modal"
          onClose={onCloseWhenNotSubmitting}>
          {!transactionCompleted ? (
            <CheckoutModalForm
              planInfo={planInfo}
              orgName={orgName}
              defaultSeats={defaultSeats}
              onTransactionCompleted={() => {
                setTransactionCompleted(true);
                onTransactionCompleted();
              }}
              renderForm={renderForm}
            />
          ) : (
            renderSuccess()
          )}
        </Modal>
      </CheckoutModalUpdaterContext.Provider>
    </CheckoutModalContext.Provider>
  );
};

export const CheckoutModal = React.memo(CheckoutModalComp);

type CheckoutModalFormProps = {
  planInfo: PlanInfoWithActualPlans;
  orgName: string;
  defaultSeats?: number;
  onTransactionCompleted: () => void;
  renderForm: (p: RenderFormParams) => React.ReactNode;
};

const CheckoutModalFormComp: FC<CheckoutModalFormProps> = props => {
  const {
    planInfo: {title, monthlyPlan, yearlyPlan},
    orgName,
    defaultSeats,
    onTransactionCompleted,
    renderForm,
  } = props;

  const [selectedPlan, setSelectedPlan] = useState(yearlyPlan);
  const [seats, setSeats] = useState(defaultSeats ?? yearlyPlan.maxSeats);
  const [openUsagePricing, setOpenUsagePricing] = useState(false);

  const monthlyPrice = (monthlyPlan.unitPrice ?? 0) / 100;
  const yearlyPrice = (yearlyPlan.unitPrice ?? 0) / 100;
  const yearlyPricePerMonth = yearlyPrice / 12;
  const discountPercentage = monthlyPrice
    ? (((monthlyPrice - yearlyPricePerMonth) * 100) / monthlyPrice).toFixed()
    : 0;
  const isMonthly = selectedPlan.id === monthlyPlan.id;
  const selectedPrice = isMonthly ? monthlyPrice : yearlyPrice;
  const pricePerPeriod = (selectedPrice * seats).toLocaleString();
  const discountString = discountPercentage
    ? `, save ${discountPercentage}%`
    : '';
  const totalPrice = (isMonthly ? monthlyPrice : yearlyPrice) * seats;
  const billingCycle = isMonthly ? 'month' : 'year';

  const onInputSeatsChanged = useCallback(
    (newVal: number | undefined) => {
      if (newVal == null || !_.isFinite(newVal)) {
        return;
      }
      if (newVal > yearlyPlan.maxSeats) {
        newVal = yearlyPlan.maxSeats;
      }
      if (newVal < 1) {
        newVal = 1;
      }
      setSeats(newVal);
    },
    [setSeats, yearlyPlan]
  );

  return (
    <S.Container>
      <S.MainContainer>
        <S.PlanOverviewSection>
          <S.Title>{title}</S.Title>
          <S.Subtitle>
            {isMonthly ? 'Monthly' : 'Annual'} Subscription for {orgName}
          </S.Subtitle>
          <S.TotalAmountSection>
            <S.TotalAmount>${totalPrice}</S.TotalAmount>
            <S.BillingCycle>/{billingCycle}</S.BillingCycle>
          </S.TotalAmountSection>
          <S.PeriodPickerLink
            data-test="period-picker"
            onClick={() =>
              setSelectedPlan(
                selectedPlan.id === monthlyPlan.id ? yearlyPlan : monthlyPlan
              )
            }>
            {' '}
            <S.SwitchIcon name="refresh" />
            {selectedPlan.id === monthlyPlan.id
              ? `Switch to annual${discountString}`
              : 'Switch to monthly'}
          </S.PeriodPickerLink>
          <S.SeatsPickerTitle>Seats</S.SeatsPickerTitle>
          <S.SeatsPicker>
            <S.SeatsInputWrapper>
              <S.SeatsInput
                data-test="choose-seats-input"
                value={seats}
                stepper
                min={defaultSeats}
                max={yearlyPlan.maxSeats}
                onChange={onInputSeatsChanged}
              />
            </S.SeatsInputWrapper>
            {` x  $${selectedPrice}/${billingCycle} = $${totalPrice}/${billingCycle}
          `}
          </S.SeatsPicker>
          {
            <S.SavingMessage>
              {!isMonthly &&
                discountPercentage &&
                `You are saving ${discountPercentage}% with an annual subscription.`}
            </S.SavingMessage>
          }

          <S.PlanList>
            <S.PlanItem>
              <S.CheckIcon name="check" /> One team, up to 10 users
            </S.PlanItem>
            <S.PlanItem>
              <S.CheckIcon name="check" /> Up to 5000 cumulative tracked hours
              included
            </S.PlanItem>
            <S.PlanItem>
              <S.CheckIcon name="check" /> 100 GB storage and artifacts tracking
              included
            </S.PlanItem>
            <S.PlanItem>
              <S.CheckIcon name="check" /> Support through email and chat
            </S.PlanItem>
          </S.PlanList>
          {!openUsagePricing && (
            <S.UsagePricingLink
              onClick={() => setOpenUsagePricing(prev => !prev)}>
              Show usage pricing
            </S.UsagePricingLink>
          )}
          <S.UsageSection>
            {openUsagePricing && (
              <UsagePricingDetails
                closePricingDetails={() => setOpenUsagePricing(false)}
              />
            )}
          </S.UsageSection>
        </S.PlanOverviewSection>
        <S.ContactSalesSection>
          <TargetBlank
            href={urls.contactSalesPricing()}
            onClick={() => {
              window.analytics?.track('Standard plan contact us clicked');
            }}>
            <S.LinkText>Contact sales</S.LinkText>
          </TargetBlank>{' '}
          to learn more or customize your plan.
        </S.ContactSalesSection>
      </S.MainContainer>
      <S.SideContainer>
        <div className="checkout-summary">
          {renderForm({
            plan: selectedPlan as CheckoutPlan,
            seats,
            pricePerPeriod,
            onTransactionCompleted,
          })}
        </div>
      </S.SideContainer>
    </S.Container>
  );
};

export const CheckoutModalForm = React.memo(CheckoutModalFormComp);
