import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

import {ReportPanelGridSection} from '../../../ReportPanelGridSection';

export const PanelGridWrapper = styled.div``;

export const PanelGrid = styled(ReportPanelGridSection)<{$selected: boolean}>`
  margin-top: 12px !important;
  white-space: normal;
  ${props =>
    !props.readOnly &&
    css`
      .grid-section-wrapper {
        cursor: pointer;
        &:hover {
          .grid-dot {
            background: #c9c9c9;
          }
        }
      }
    `}

  .grid-section-wrapper,
  .run-sets-wrapper {
    padding: 0 !important;
  }
  ${props =>
    props.$selected &&
    css`
      .editable-panel,
      .run-sets-wrapper.active .run-sets,
      .run-sets-wrapper:not(.active) .run-set-summary {
        box-shadow: 0 0 0 6px rgb(208, 225, 252);
        transition: none;
      }
      .grid-dot {
        background: rgb(178, 195, 232);
      }
    `}
`;

export const PanelGridErrorWrapper = styled.div`
  padding: 60px 0;
  margin: 12px 64px;
  color: ${globals.gray500};
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdcdc;
  display: flex;
`;

export const ErrorImageWrapper = styled.img`
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
`;

export const ErrorMessageWrapper = styled.div`
  line-height: 20px;

  h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  a {
    color: ${globals.gray600};
    font-weight: 600;
  }
`;
