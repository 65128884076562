import React, {FC} from 'react';
import {Button, StrictInputProps} from 'semantic-ui-react';
import {Editor, Element, Node, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSlate,
} from 'slate-react';

import * as S from './cta-button.styles';
import {BlockWrapper} from './drag-drop';

export type CTAButton = Element & {
  type: 'cta-button';
  link?: string;
  buttonText?: string;
};

export const isCTAButton = (node: Node): node is CTAButton =>
  node.type === 'cta-button';

export const CTAButtonElement: FC<
  RenderElementProps & {
    element: CTAButton;
  }
> = ({attributes, element, children}) => {
  const {buttonText = '', link = ''} = element;
  const urlPrefix =
    link.startsWith('/') || link.startsWith(`http`) ? '' : 'https://';

  const readOnly = useReadOnly();
  const editor = useSlate();

  return (
    <BlockWrapper attributes={attributes} element={element}>
      {!readOnly && (
        <div contentEditable={false}>
          <S.CTAInput
            placeholder="Set your button text"
            value={buttonText}
            onChange={
              (e => {
                Transforms.setNodes(
                  editor,
                  {buttonText: e.target.value},
                  {at: ReactEditor.findPath(editor, element)}
                );
              }) as StrictInputProps['onChange']
            }
          />
          <S.CTAInput
            placeholder="Set your button link"
            value={link}
            onChange={
              (e => {
                Transforms.setNodes(
                  editor,
                  {link: e.target.value},
                  {at: ReactEditor.findPath(editor, element)}
                );
              }) as StrictInputProps['onChange']
            }
          />
        </div>
      )}
      <S.CTAContainer contentEditable={false}>
        <Button
          primary
          content={buttonText || 'Click'}
          target="_blank"
          href={urlPrefix + link}
        />
        {children}
      </S.CTAContainer>
    </BlockWrapper>
  );
};

export const withCTAButton = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isCTAButton(element) ? true : isVoid(element);
  };
  return editor;
};
