import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import {assertUnreachable} from '@wandb/common/util/types';
import _ from 'lodash';
import React, {useCallback} from 'react';

import {useProjectTagsQuery} from '../../state/graphql/projectTagsQuery';
import {useRunValueSuggestionsQuery} from '../../state/graphql/runValueSuggestionsQuery';
import * as FilterActions from '../../state/views/filter/actions';
import * as FilterTypes from '../../state/views/filter/types';
import * as ViewHooks from '../../state/views/hooks';
import {useViewAction} from '../../state/views/hooks';
import * as FiltersUtil from '../../util/filters';
import * as RunUtil from '../../util/runs';
import {FilterKeySelectorCreatorProps} from '../Filters/FilterKeySelector';
import {
  filterValueOptionTransform,
  FilterValueSelector,
  FilterValueSelectorCreatorProps,
  FilterValueSelectorDate,
  FilterValueSelectorTime,
} from '../Filters/FilterValueSelector';
import {NamedProjectFieldSelector} from '../ProjectFieldSelector';
import {
  WBTableActionFilter,
  WBTableActionFilterButton,
  WBTableActionFilterPicker,
} from '../WBTable/WBTableActionsFilter';

interface RunsFilterButtonProps {
  compact?: boolean;
  notificationOpen: boolean;
  filtersOpen: boolean;
  filtersRef: FilterTypes.Ref;
  isInReport?: boolean;
}

export type RunFilterValueSelectorCreatorProps =
  FilterValueSelectorCreatorProps<RunUtil.Key>;

const RunsFilterButton = (props: RunsFilterButtonProps) => {
  const {filtersRef} = props;

  const filters = ViewHooks.useWhole(filtersRef);

  const passThroughProps = _.omit(
    props,
    'compact',
    'filtersOpen',
    'filtersRef'
  );
  return (
    <WBTableActionFilterButton
      {...passThroughProps} // Required for use as popup trigger
      compact={props.compact}
      filtersOpen={props.filtersOpen}
      filters={filters}
    />
  );
};

interface RunsFilterPickerProps {
  entityName: string;
  projectName: string;
  filtersRef: FilterTypes.Ref;

  defaultToggleFilters?: FiltersUtil.DefaultToggleFilter[];
  onFiltersChanged(): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(
    props: RunFilterValueSelectorCreatorProps
  ): React.ReactNode;
}

export const RunsFilterPicker = (props: RunsFilterPickerProps) => {
  const {filtersRef, onFiltersChanged, defaultToggleFilters} = props;

  const filters = ViewHooks.useWhole(filtersRef);
  const setFilters = useViewAction(filtersRef, FilterActions.set);

  const setFiltersWrapped = useCallback(
    (...args: Parameters<typeof setFilters>) => {
      setFilters(...args);
      onFiltersChanged();
    },
    [setFilters, onFiltersChanged]
  );

  return (
    <WBTableActionFilterPicker
      filters={filters}
      defaultToggleFilters={defaultToggleFilters}
      setFilters={setFiltersWrapped}
      filterKeySelector={props.filterKeySelector}
      filterValueSelector={props.filterValueSelector}
    />
  );
};

export const RUNS_FILTER_DEFAULT_KEYS = [
  'run:displayName',
  'tags:-',
  'run:state',
  'run:createdAt',
  'run:duration',
  'run:username',
  'run:sweep',
  'run:group',
  'run:jobType',
  'run:host',
  'run:inputArtifacts',
  'run:outputArtifacts',
];

type RunsFilterKeySelectorProps = FilterKeySelectorCreatorProps & {
  entityName: string;
  projectName: string;
};

export const RunsFilterKeySelector = (props: RunsFilterKeySelectorProps) => {
  const {entityName, projectName, keyValue, onValidSelection, focusOnMount} =
    props;
  return (
    <NamedProjectFieldSelector
      data-test="filter-key"
      className="filter-dropdown filter-list__key"
      entityName={entityName}
      projectName={projectName}
      defaultKeys={RUNS_FILTER_DEFAULT_KEYS}
      focusOnMount={focusOnMount}
      closeOnChange
      selection
      multi={false}
      value={keyValue}
      setValue={onValidSelection}
      searchByKeyAndText
    />
  );
};

type RunFilterValueSelectorProps = RunFilterValueSelectorCreatorProps & {
  entityName: string;
  projectName: string;
};

export const RunsFilterValueSelector = (props: RunFilterValueSelectorProps) => {
  const {entityName, projectName} = props;

  const setPartial = (
    partialFilter: Partial<FiltersUtil.IndividualFilter<RunUtil.Key>>
  ) => {
    props.setFilter({
      ...props.filter,
      ...partialFilter,
    } as FiltersUtil.IndividualFilter<RunUtil.Key>);
  };

  const filterValueType = FiltersUtil.getFilterValueType(props.filter);
  switch (filterValueType) {
    case FiltersUtil.ValueType.Duration:
      return (
        <FilterValueSelectorTime
          meta={FiltersUtil.assertIsValueFilter(props.filter).meta}
          value={props.filter.value as string}
          setFilter={setPartial}
          minimumUnit={
            props.filter.op === 'WITHINSECONDS' ? 'minutes' : null
            /* the withinseconds operation isn't guaranteed to be accurate
               on the scale of seconds and there isn't a known use case. */
          }
        />
      );
    case FiltersUtil.ValueType.Date:
      return (
        <FilterValueSelectorDate
          value={props.filter.value as string}
          setFilter={setPartial}
        />
      );
    case FiltersUtil.ValueType.Tags:
      return (
        <RunsFilterTagNameSelector
          entityName={entityName}
          projectName={projectName}
          filter={props.filter as FiltersUtil.ValueFilter<RunUtil.Key>}
          setFilter={setPartial}
        />
      );
    case FiltersUtil.ValueType.Default:
      return (
        <RunsFilterSimpleValueSelector
          entityName={entityName}
          projectName={projectName}
          filter={props.filter}
          setFilter={props.setFilter}
        />
      );
    default:
      assertUnreachable(filterValueType);
  }
};

interface RunsFilterTagNameSelectorProps {
  entityName: string;
  projectName: string;
  filter: FiltersUtil.IndividualFilter<RunUtil.Key>;
  setFilter(filter: Partial<FiltersUtil.IndividualFilter<RunUtil.Key>>): void;
}

const RunsFilterTagNameSelector = (props: RunsFilterTagNameSelectorProps) => {
  const {entityName, projectName} = props;
  const projectTagsQuery = useProjectTagsQuery({entityName, projectName});
  const tagCounts = !projectTagsQuery.loading
    ? projectTagsQuery.project.tagCounts
    : [];
  const suggestions = tagCounts.map(tc => ({
    key: tc.name,
    value: tc.name,
    text: tc.name,
    count: tc.count,
  }));
  const tagNames = tagCounts.map(tc => tc.name);
  let currentValue: any;
  const isMulti = props.filter.op === 'IN' || props.filter.op === 'NIN';
  if (isMulti) {
    currentValue = props.filter.value;
    for (const name of currentValue) {
      if (!_.includes(tagNames, name)) {
        tagNames.push(name);
      }
    }
  } else {
    currentValue = props.filter.key.name;
    const valueTagName = props.filter.key.name;
    if (!_.includes(tagNames, valueTagName)) {
      tagNames.push(valueTagName);
    }
  }
  return (
    <ModifiedDropdown
      className="filter-dropdown filter-list__value"
      // With current optimizations, some components do not update on function prop changes.
      // To avoid a stale filter callback, we include an empty style prop to force a re-render.
      // (This is a workaround, not the ideal solution)
      // Refer to shouldUpdate.ts : L16
      style={{}}
      options={suggestions}
      optionTransform={filterValueOptionTransform}
      loading={projectTagsQuery.loading}
      placeholder="value"
      search
      multiple={isMulti}
      inline
      value={currentValue}
      onChange={(e, {value}) => {
        const f = props.filter;
        if (isMulti) {
          props.setFilter({
            ...f,
            value,
            disabled: false,
          } as FiltersUtil.IndividualFilter<RunUtil.Key>);
        } else {
          props.setFilter({
            ...f,
            key: {section: 'tags', name: value as string},
            disabled: false,
          });
        }
      }}
    />
  );
};

interface RunsFilterSimpleValueSelectorProps {
  entityName: string;
  projectName: string;
  filter: FiltersUtil.IndividualFilter<RunUtil.Key>;
  setFilter(filter: FiltersUtil.IndividualFilter<RunUtil.Key>): void;
}

const RunsFilterSimpleValueSelector = (
  props: RunsFilterSimpleValueSelectorProps
) => {
  const {entityName, projectName} = props;
  const keyPath = RunUtil.keyToServerPath(props.filter.key);
  const valueSugg = useRunValueSuggestionsQuery({
    entityName,
    projectName,
    keyPath,
    filters: FiltersUtil.EMPTY_FILTERS,
  });
  const sugg = !valueSugg.loading ? valueSugg.valueSuggestions : [];
  return (
    <FilterValueSelector
      {...props}
      loading={valueSugg.loading}
      suggestions={sugg}
    />
  );
};

interface RunsFilterTableActionProps {
  entityName: string;
  projectName: string;
  compact?: boolean;
  filtersRef: FilterTypes.Ref;
  isInReport?: boolean;
  notificationOpen: boolean;
  pickerOpen: boolean;

  defaultToggleFilters?: FiltersUtil.DefaultToggleFilter[];
  setPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFiltersChanged(): void;
}

export const RunsFilterTableAction = (props: RunsFilterTableActionProps) => {
  const {notificationOpen, pickerOpen, setPickerOpen} = props;
  return (
    <WBTableActionFilter
      open={pickerOpen}
      setOpen={setPickerOpen}
      trigger={filtersOpen => (
        <RunsFilterButton
          notificationOpen={notificationOpen}
          compact={props.compact}
          filtersOpen={filtersOpen}
          filtersRef={props.filtersRef}
          isInReport={props.isInReport}
        />
      )}
      content={
        <RunsFilterPicker
          entityName={props.entityName}
          projectName={props.projectName}
          filtersRef={props.filtersRef}
          defaultToggleFilters={props.defaultToggleFilters}
          onFiltersChanged={props.onFiltersChanged}
          filterKeySelector={keySelProps => (
            <RunsFilterKeySelector
              {...keySelProps}
              entityName={props.entityName}
              projectName={props.projectName}
            />
          )}
          filterValueSelector={valSelProps => (
            <RunsFilterValueSelector
              {...valSelProps}
              entityName={props.entityName}
              projectName={props.projectName}
            />
          )}
        />
      }
    />
  );
};
