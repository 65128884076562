import * as Graph from '@wandb/cg';
import {defaultLanguageBinding} from '@wandb/cg';
import PanelError from '@wandb/common/components/elements/PanelError';
import {WBButton} from '@wandb/ui';
import * as _ from 'lodash';
import React, {useMemo, useRef} from 'react';
import {useCallback, useState} from 'react';

import {ErrorBoundary} from '../../components/ErrorBoundary';
import * as Panels from '../../util/panels';

const MAX_CONFIG_STRING_LENGTH = 2500;

const hashStr = (str?: string) => {
  // From https://stackoverflow.com/a/7616484
  let hash = 0;
  let i: number;
  let chr: number;
  if (str == null || str.length === 0) {
    return hash;
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // tslint:disable-next-line: no-bitwise
    hash = (hash << 5) - hash + chr;
    // tslint:disable-next-line: no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

// Do not nest these components - you will get weird undo cycles.
type MinimumPanelProps<T> = Pick<
  Panels.PanelProps<T>,
  'config' | 'updateConfig'
>;

type ReversibleWeaveErrorBoundaryProps<T extends MinimumPanelProps<any>> = T & {
  innerComponent: React.ComponentType<T>;
  sourcePanel: string;
  panelConfigKey: string;
  hardResetValue?: any;
};

export const ReversibleWeaveErrorBoundary: React.FC<
  ReversibleWeaveErrorBoundaryProps<any>
> = props => {
  const {reversibleUpdateConfig, canUndo, undoUpdate, canReset, resetConfig} =
    useReversibleUpdateConfig(
      props.config,
      props.updateConfig,
      useMemo(() => props.hardResetValue ?? {}, [props.hardResetValue])
    );
  const innerProps = useMemo(() => {
    return {
      ..._.omit(props, ['innerComponent', 'sourcePanel', 'hardResetValue']),
      updateConfig: reversibleUpdateConfig,
    };
  }, [props, reversibleUpdateConfig]);
  return (
    <WeaveErrorBoundary
      canReset={canReset}
      onReset={resetConfig}
      canUndo={canUndo}
      onUndo={undoUpdate}
      errorConfig={props.config[props.panelConfigKey]}
      sourcePanel={props.sourcePanel}>
      <props.innerComponent {...innerProps} />
    </WeaveErrorBoundary>
  );
};

const trimString = (str?: string) => {
  if (str == null) {
    return '';
  }
  if (str.length > MAX_CONFIG_STRING_LENGTH) {
    return str.slice(0, MAX_CONFIG_STRING_LENGTH) + '...';
  }
  return str;
};

const minifiedConfig = (config: any): any => {
  if (config == null) {
    return '';
  } else if (Graph.isVoidNode(config)) {
    return '<VOID>';
  } else if (Graph.isConstNode(config)) {
    return `[${minifiedConfig(config.val)}]`;
  } else if (Graph.isVarNode(config)) {
    return `{{${config.varName}}}`;
  } else if (Graph.isOutputNode(config)) {
    const argKeys = Object.keys(config.fromOp.inputs);
    const prefix = minifiedConfig(config.fromOp.inputs[argKeys[0]]);
    let args = argKeys
      .slice(1)
      .map(k => minifiedConfig(config.fromOp.inputs[k]))
      .join(', ');
    if (args !== '') {
      args = `(${args})`;
    }
    return `${prefix}.${config.fromOp.name}${args}`;
  } else if (config.type != null) {
    try {
      return defaultLanguageBinding
        .printType(config)
        .replace(/[\s\r\n\t]/g, '');
    } catch (e) {
      // pass
    }
  } else if (config.autoColumns) {
    // `autoColumns is used in table state to indicate an auto table
    // the config will have a bunch of boilerplate and this is a logically
    // equivalent representation of the config
    return {
      autoColumns: true,
    };
  } else if (_.isArray(config)) {
    return config.map(minifiedConfig);
  } else if (_.isObject(config)) {
    return _.mapValues(config, (v: any) => minifiedConfig(v));
  }
  return config;
};

export const WeaveErrorBoundary: React.FC<{
  canReset: boolean;
  onReset: () => void;
  canUndo: boolean;
  onUndo: () => void;
  errorConfig: any;
  sourcePanel: string;
}> = ({
  canReset,
  onReset,
  canUndo,
  onUndo,
  errorConfig,
  sourcePanel,
  children,
}) => {
  const [key, setKey] = useState(0);

  const onError = useCallback(
    (error: Error) => {
      let payload = {};
      try {
        const configString = JSON.stringify(errorConfig);
        const minifiedConfigString = JSON.stringify(
          minifiedConfig(errorConfig),
          null,
          '\t'
        );
        payload = {
          sourcePanel: trimString(sourcePanel),
          errorMessage: trimString(error.message),
          errorName: trimString(error.name),
          errorStack: trimString(error.stack),
          hashedErrorMessage: hashStr(error.message),
          hashedErrorStack: hashStr(error.stack),
          hashedConfig: hashStr(configString),
          // segment analytics creates a context_page_url which is supposed to
          // be the current page, but in practice, this seems to be broken
          // about 15% of the time. Adding this manually for now.
          windowLocationURL: trimString(window.location.href),
          minifiedConfigString: trimString(minifiedConfigString),
        };
      } catch (e) {
        // ignore - this is an error boundary, so ignore issues here.
      }
      window.analytics?.track('Weave Panel Error Boundary', payload);
    },
    [errorConfig, sourcePanel]
  );

  const resetPanel = useCallback(() => {
    onReset();
    setKey(k => k + 1);
  }, [onReset, setKey]);

  const undoChange = useCallback(() => {
    onUndo();
    setKey(k => k + 1);
  }, [onUndo, setKey]);

  const renderPanelError = useCallback(() => {
    return (
      <div style={{padding: 40}}>
        <PanelError
          message={
            <div>
              <div>
                Oops, something went wrong. If this keeps happening, message
                support@wandb.com with a link to this page
              </div>
              <div>
                {canUndo ? (
                  <WBButton
                    style={{margin: 10}}
                    color="default"
                    variant="contained"
                    onClick={undoChange}>
                    Undo Last Change
                  </WBButton>
                ) : canReset ? (
                  <WBButton
                    style={{margin: 10}}
                    color="info"
                    variant="contained"
                    onClick={resetPanel}>
                    Reset Panel
                  </WBButton>
                ) : null}
              </div>
            </div>
          }
        />
      </div>
    );
  }, [canUndo, undoChange, canReset, resetPanel]);

  return (
    <ErrorBoundary onError={onError} renderError={renderPanelError} key={key}>
      {children}
    </ErrorBoundary>
  );
};

const useReversibleUpdateConfig: <T>(
  config: T,
  updateConfig: (config: Partial<T>) => void,
  hardResetValue?: T,
  historyLength?: number
) => {
  reversibleUpdateConfig: (partialConfig: Partial<T>) => void;
  canUndo: boolean;
  undoUpdate: () => void;
  canReset: boolean;
  resetConfig: () => void;
} = (config, updateConfig, hardResetValue, historyLength = 3) => {
  type ConfigType = typeof config;
  type UndoHistoryType = Array<ConfigType | null>;
  const [originalConfig] = useState(config);
  const resetValue = useMemo(() => {
    let base: any;
    if (originalConfig !== config || hardResetValue == null) {
      base = {...originalConfig};
    } else {
      base = {...hardResetValue};
    }
    Object.keys(config).forEach(key => {
      if (!(key in base)) {
        base[key] = undefined;
      }
    });
    return base;
  }, [hardResetValue, originalConfig, config]);
  const undoHistory = useRef<UndoHistoryType>(
    Array.from({length: historyLength}, () => null)
  );
  const [undoHistoryIndex, setUndoHistoryIndex] = useState(-1);

  const reversibleUpdateConfig = useCallback(
    (partialConfig: ConfigType) => {
      const undoAction = _.pick(config, Object.keys(partialConfig));
      const nextHistoryIndex = (undoHistoryIndex + 1) % historyLength;
      undoHistory.current[nextHistoryIndex] = undoAction as any;
      setUndoHistoryIndex(nextHistoryIndex);
      updateConfig(partialConfig);
    },
    [config, updateConfig, historyLength, undoHistoryIndex]
  );

  const undoUpdate = useCallback(() => {
    const undoAction = undoHistory.current[undoHistoryIndex];
    if (undoAction) {
      updateConfig(undoAction);
      setUndoHistoryIndex((undoHistoryIndex - 1) % historyLength);
    }
  }, [
    undoHistory,
    undoHistoryIndex,
    updateConfig,
    setUndoHistoryIndex,
    historyLength,
  ]);

  const canUndo = useMemo(() => {
    return undoHistory.current[undoHistoryIndex] != null;
  }, [undoHistory, undoHistoryIndex]);

  const resetConfig = useCallback(() => {
    reversibleUpdateConfig(resetValue);
  }, [resetValue, reversibleUpdateConfig]);

  const canReset = useMemo(() => {
    return resetValue !== config;
  }, [resetValue, config]);

  return useMemo(() => {
    return {
      reversibleUpdateConfig: reversibleUpdateConfig as any,
      canUndo,
      undoUpdate,
      canReset,
      resetConfig,
    };
  }, [reversibleUpdateConfig, undoUpdate, canUndo, canReset, resetConfig]);
};
