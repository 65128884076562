import React, {createContext, FC, ReactNode, useMemo} from 'react';

import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';

// TODO - as panels gets refactored some more, consider separating some of these out to a PanelBankContextProvider

interface PanelContextProps {
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  historyKeyInfo?: RunHistoryKeyInfo; // Used in PanelRunsLinePlot
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigTypes.Ref;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  panelRef: PanelTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  searchQuery?: string;
}

export const PanelContext = createContext<PanelContextProps>({
  customRunColorsRef: {id: '', type: 'run-colors', viewID: ''},
  historyKeyInfo: undefined,
  panelBankSectionConfigRef: {
    id: '',
    type: 'panel-bank-section-config',
    viewID: '',
  },
  panelRef: {
    id: '',
    type: 'panel',
    viewID: '',
  },
  runSetRefs: [
    {
      id: '',
      type: 'runSet',
      viewID: '',
    },
  ],
});
PanelContext.displayName = 'PanelContext';

interface PanelContextProviderProps {
  children: ReactNode;
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  historyKeyInfo?: RunHistoryKeyInfo; // Used in PanelRunsLinePlot
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigTypes.Ref; // the panelbank config, used for PanelMover
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref; // the panelbank section that contains this panel, used for PanelMover
  panelRef: PanelTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
}

export const PanelContextProvider: FC<PanelContextProviderProps> = ({
  children,
  customRunColorsRef,
  historyKeyInfo,
  isReadOnly,
  panelBankConfigRef,
  panelBankSectionConfigRef,
  panelRef,
  runSetRefs,
}) => {
  const state = useMemo(
    () => ({
      customRunColorsRef,
      historyKeyInfo,
      isReadOnly,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      panelRef,
      runSetRefs,
    }),
    [
      customRunColorsRef,
      historyKeyInfo,
      isReadOnly,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      panelRef,
      runSetRefs,
    ]
  );

  return (
    <PanelContext.Provider value={state}>{children} </PanelContext.Provider>
  );
};
