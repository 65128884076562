import {
  ReportSearchQuery,
  SearchNavQueryQuery,
} from './../../generated/graphql';
import {ResultTypes} from './types';

export type Project = {
  entityName: string;
  id: string;
  name: string;
  resultType: ResultTypes.Project;
};
export function extractProjects(queryData: SearchNavQueryQuery) {
  const projects = queryData.viewer?.projects?.edges.map(edge => ({
    ...edge.node,
    resultType: ResultTypes.Project,
  }));

  return projects as Project[];
}

export type Report = {
  displayName: string;
  entityName: string;
  id: string;
  projectName: string;
  resultType: ResultTypes.Report;
};
export function extractReports(reportData: ReportSearchQuery) {
  const reports =
    reportData.reportSearch?.edges.map(edge => ({
      ...edge.node,
      resultType: ResultTypes.Report,
    })) ?? [];
  return reports as Report[];
}

export type Team = {
  id: string;
  name: string;
  resultType: ResultTypes.Team;
};
export function extractTeams(queryData: SearchNavQueryQuery) {
  const teams = queryData.viewer?.teams?.edges.map(edge => ({
    ...edge.node,
    resultType: ResultTypes.Team,
  }));

  return teams as Team[];
}
