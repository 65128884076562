import {TargetBlank} from '@wandb/common/util/links';
import React from 'react';
import {Divider} from 'semantic-ui-react';

import {
  artifactFeatures,
  storageFeatures,
  TRACKED_HOURS,
} from '../../../util/storage';
import * as urls from '../../../util/urls';
import * as S from './UsagePricingDetails.styles';

const UsageBox = ({title, details}: {title: string; details: string[]}) => {
  return (
    <S.UsageBoxContainer>
      <S.BoldTitle>{title}</S.BoldTitle>
      {details.map((pricingDetail: string) => (
        <span>{pricingDetail}</span>
      ))}
    </S.UsageBoxContainer>
  );
};
export const UsagePricingDetails = ({
  closePricingDetails,
}: {
  closePricingDetails: () => void;
}) => {
  return (
    <>
      <Divider />
      <S.Header>
        <S.UsagePricingDetailsTitle>Usage pricing</S.UsagePricingDetailsTitle>
        <S.ActionText onClick={closePricingDetails}>Hide</S.ActionText>
      </S.Header>
      <UsageBox title="Storage" details={storageFeatures} />
      <UsageBox title="Artifact tracking" details={artifactFeatures} />
      <UsageBox title="Starter plan" details={TRACKED_HOURS} />
      <TargetBlank
        href={urls.lineageTrackingPricing()}
        onClick={() => {
          window.analytics?.track('Storage Pricing Link Clicked', {
            location: 'storage checkout modal',
          });
        }}>
        <S.ActionText>Learn more</S.ActionText>
      </TargetBlank>
    </>
  );
};
