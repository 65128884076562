import {Struct} from '@wandb/common/util/types';
import * as React from 'react';
import {useEffect, useMemo} from 'react';
import {Card} from 'semantic-ui-react';

import * as Track from '../services/analytics';
import {labelComponent, makeCaptions} from '../util/media';
import {runLink} from '../util/runhelpers';
import {getObject3DMediaPath, MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import PointCloud from './PointCloud';
import Viewer3D from './Viewer3D';

export const Object3DCard: React.FunctionComponent<MediaCardProps> = React.memo(
  props => {
    const {
      run,
      globalStep,
      mediaKey,
      mediaIndex,
      runSignature,
      height,
      width,
      tileMedia,
      mediaPanelRef,
    } = props;

    const mediaPath =
      getObject3DMediaPath(
        {historyRow: tileMedia?.historyRow, mediaKey},
        mediaIndex
      ) ?? '';

    const isPointCloud = mediaPath.endsWith('.pts.json');
    // In the past, not all point cloud media files were uploaded with the
    // pts.json extension. This is a workaround to allow those files to still
    // be viewed. It can be removed in the future assuming the below analytics
    // does not fire often
    const sdkBugWorkaround = !isPointCloud && mediaPath.endsWith('.json');

    const Media3DComponent =
      isPointCloud || sdkBugWorkaround ? PointCloud : Viewer3D;

    useEffect(() => {
      if (sdkBugWorkaround) {
        Track.pointCloudFileWorkaroundUsed({});
      }
    }, [sdkBugWorkaround]);
    const currentMediaMetadata: Struct | undefined =
      tileMedia?.historyRow?.[mediaKey];
    const showMetadataInline = width > 100; // if this is false, metadata will appear in a popup on the image

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    const captions = useMemo(() => {
      return makeCaptions(currentMediaMetadata, mediaIndex);
    }, [currentMediaMetadata, mediaIndex]);

    return (
      <Card className="object3D-card" style={{width}}>
        {labelComponent(props, tileMedia?.step, titleLink)}

        {tileMedia == null ? (
          <MessageMediaNotFound
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaIndex={mediaIndex}
            mediaType="object3D"
          />
        ) : (
          <div
            style={{
              width,
              height,
            }}>
            <Media3DComponent
              width={width}
              height={height}
              url={tileMedia.directURL}
              blob={tileMedia.blob}
              controls={props.controls}
              mediaKey={mediaKey}
              mediaPanelRefId={mediaPanelRef.id}
            />
          </div>
        )}
        {/* CAPTIONS */}
        {showMetadataInline && captions.length > 0 && (
          <div className="image-card-caption">{captions}</div>
        )}
      </Card>
    );
  }
);

export default Object3DCard;
