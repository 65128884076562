import {TargetBlank} from '@wandb/common/util/links';
import React from 'react';
import {Table} from 'semantic-ui-react';

import {useBetaFeatureUnicornModeEnabled} from '../../util/useBetaFeature';
import * as S from '../Alerts.styles';

type FeatureToggleProps = {
  name: string;
  description: string;
  docUrl?: string;
  isEnabled: boolean;
  toggleFeature: () => void;
};

export const FeatureToggle: React.FC<FeatureToggleProps> = React.memo(
  ({name, description, docUrl, isEnabled, toggleFeature}) => {
    const unicornModeEnabled = useBetaFeatureUnicornModeEnabled();

    return (
      <Table.Row>
        <Table.Cell collapsing>
          <span style={{fontWeight: 600}}>{name}&nbsp;</span>
        </Table.Cell>
        <Table.Cell>
          {description}{' '}
          {docUrl != null && (
            <TargetBlank href={docUrl}>Learn&nbsp;more&nbsp;→</TargetBlank>
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          <S.ToggleWrapper>
            <S.Toggle
              toggle
              checked={isEnabled}
              onChange={toggleFeature}
              $unicornModeEnabled={unicornModeEnabled}
            />
          </S.ToggleWrapper>
        </Table.Cell>
      </Table.Row>
    );
  }
);
