import React, {FC} from 'react';
import {RenderLeafProps} from 'slate-react';

import {LeafNode} from './LeafNode';
import {LeafNodeWithComments} from './LeafNodeWithComments';
import {TextWithFormatting} from './types';
import {isTextWithComments} from './utils';

export const WBSlateLeaf: FC<RenderLeafProps> = props => {
  if (isTextWithComments(props.leaf)) {
    return <LeafNodeWithComments {...props}></LeafNodeWithComments>;
  }

  const typedLeaf = props.leaf as TextWithFormatting;
  return (
    <LeafNode
      data-test="leaf-node-with-formatting"
      {...props}
      leaf={typedLeaf}></LeafNode>
  );
};
