import {isNil as _isNil} from 'lodash';
import React, {FC, useState} from 'react';

import * as Filter from '../../util/filters';
import * as Run from '../../util/runs';
import {FilterEditable} from './FilterEditable';
import {FilterKeySelectorCreatorProps} from './FilterKeySelector';
import {FilterValueSelectorCreatorProps} from './FilterValueSelector';
import {handleFilterOpChange} from './logic';

interface FilterListProps {
  filters: Filter.RootFilter<Filter.FilterKey>['filters'][0];
  index: number;
  canAdd: boolean;
  pushFilter(filter: Filter.Filter<Filter.FilterKey>): void;
  deleteFilter(index: number): void;
  setFilter(
    index: number,
    filter: Filter.IndividualFilter<Filter.FilterKey>
  ): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(
    props: FilterValueSelectorCreatorProps<Filter.FilterKey>
  ): React.ReactNode;
}

const FilterList: FC<FilterListProps> = React.memo(
  ({
    filters: rootFilters,
    canAdd,
    pushFilter,
    deleteFilter,
    setFilter,
    filterKeySelector,
    filterValueSelector,
  }) => {
    const [addedFilterIndex, setAddedFilterIndex] = useState(-1);
    const filters = rootFilters.filters;

    return (
      <div className="filter-list">
        {filters.map((filter, i) => {
          // TODO: display compound filters
          if (!Filter.isIndividual(filter)) {
            return null;
          }
          return (
            <FilterEditable
              key={`filter=${i}`}
              justAdded={addedFilterIndex === i}
              filter={filter}
              setFilterDisabled={(disabled: boolean) => {
                const f: Filter.IndividualFilter<Filter.FilterKey> =
                  filter as Filter.IndividualFilter<Filter.FilterKey>;
                setFilter(i, {...f, disabled});
              }}
              setFilter={filt => setFilter(i, filt)}
              setFilterOp={(newFilterOp: Filter.IndividualOp) => {
                setFilter(i, handleFilterOpChange(filter, newFilterOp));
              }}
              setFilterValue={(filterValue: Run.Value) => {
                setFilter(i, {
                  ...filter,
                  value: filterValue,
                  disabled: _isNil(filterValue),
                } as Filter.ValueFilter<Filter.FilterKey> & Filter.ToggleableFilter);
              }}
              setFilterMeta={(meta: any) => {
                setFilter(i, {
                  ...filter,
                  meta,
                } as Filter.ValueFilter<Filter.FilterKey>);
              }}
              setFilterMultiValue={(filterValue: Run.Value[]) => {
                setFilter(i, {
                  ...filter,
                  value: filterValue,
                  disabled: _isNil(filterValue),
                } as Filter.MultiValueFilter<Filter.FilterKey> & Filter.ToggleableFilter);
              }}
              deleteFilter={() => deleteFilter(i)}
              filterKeySelector={filterKeySelector}
              filterValueSelector={filterValueSelector}
            />
          );
        })}

        {canAdd && (
          <span
            className="fake-link filter__new"
            data-test="filter-add"
            // onClick={() => pushFilter(Filter.defaultNewFilter)}>
            onClick={() => {
              // pushFilter(defaultNewRunFilter);
              // TODO: check if it's ok to add this as an empty filter
              setAddedFilterIndex(filters.length);
              pushFilter({
                key: {section: 'run', name: ''},
                op: '=',
                value: '',
                disabled: true,
              });
            }}>
            + Add filter
          </span>
        )}
      </div>
    );
  }
);

export default FilterList;
