import config, {envIsCloudOnprem, envIsLocal} from '@wandb/common/config';
import {TargetBlank} from '@wandb/common/util/links';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {toast} from 'react-toastify';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Header,
  Input,
  InputProps,
  Modal,
  SemanticCOLORS,
  SemanticSIZES,
} from 'semantic-ui-react';

import {
  CreateTeamMutationVariables,
  OrgType,
  useAvailableTeamQuery,
  useCreateTeamMutation,
  useUserOrganizationsQuery,
} from '../generated/graphql';
import {Analytics} from '../services/analytics';
import {useViewer} from '../state/viewer/hooks';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../util/errors';
import {navigateTo} from '../util/history';
import {userFlags} from '../util/permissions';
import {CONTACT_SALES_CLICKED_ANALYTICS} from '../util/pricing';
import {slugFormat} from '../util/text';
import * as urls from '../util/urls';
import {useStorageBucketConfig} from './CreateTeam/hooks';
import StorageBucketInfoInput from './CreateTeam/StorageBucketInfoInput';
import * as S from './CreateTeamModal.styles';
import WBModal from './WBModal';

const TEAM_LIMIT_ERROR_MSG =
  'organization does not have any private teams left';

interface CreateTeamButtonProps {
  size?: SemanticSIZES;
  color?: SemanticCOLORS;
  readonly?: boolean;
  renderButton?(onClick: () => void): JSX.Element;
  onCreate(teamName?: string): void;
}

interface CreateTeamModalContentProps extends CreateTeamButtonProps {
  orgOptions: Array<{text: string; value: string}>;
  teamAdminUsername?: string;
  open?: boolean;
  trigger?: React.ReactNode;
  onClose?(): void;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

const TeamNameInput = Input as any as FunctionComponent<
  InputProps & {onBlur?(): void}
>;

export const CreateTeamModal: FunctionComponent<CreateTeamModalContentProps> =
  props => {
    const {orgOptions, teamAdminUsername, onClose} = props;
    const viewer = useViewer();
    const [teamName, setTeamName] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState<
      string | null
    >(null);
    const [selectedOrgName, setSelectedOrgName] = useState<string | null>(null);
    const [hitTeamLimit, setHitTeamLimit] = useState(false);

    const availableTeamQuery = useAvailableTeamQuery({
      fetchPolicy: 'network-only',
      variables: {teamName},
      skip: teamName === '',
    });

    const isTeamNameAvailable =
      !availableTeamQuery.loading &&
      (availableTeamQuery?.data?.entity?.available ?? false);

    // Set the first org as the default selection when it's available.
    useEffect(() => {
      if (orgOptions.length > 0) {
        setSelectedOrganization(orgOptions[0].value);
        setSelectedOrgName(orgOptions[0].text);
      }
    }, [orgOptions]);

    const handleTeamNameChange = useCallback((e: any, data: any) => {
      setTeamName(slugFormat(data.value));
    }, []);

    const handleOrganizationChange = useCallback(
      (_, data: DropdownProps) => {
        setSelectedOrganization(data.value as string);
        setSelectedOrgName(data.text as string);
      },
      [setSelectedOrganization, setSelectedOrgName]
    );

    const [teamNameEdited, setTeamNameEdited] = useState(false);

    const [createTeam] = useCreateTeamMutation({
      onCompleted: () => props.onCreate(teamName),
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

    const storageBucketInfoProps = useStorageBucketConfig();

    const isSubmitDisabled = useMemo(() => {
      if (teamName.length < 3) {
        return true;
      }
      if (isTeamNameAvailable !== true) {
        return true;
      }

      if (
        storageBucketInfoProps.usingExternalStorage &&
        storageBucketInfoProps.isValidState.state !== 'valid'
      ) {
        return true;
      }

      return false;
    }, [teamName, isTeamNameAvailable, storageBucketInfoProps]);

    const teamNameIcon = useMemo(
      () => ({
        name: (isSubmitDisabled ? 'stop' : 'check') + ' circle outline',
        color: isSubmitDisabled ? 'red' : 'green',
        size: 'small',
      }),
      [isSubmitDisabled]
    );

    const createTeamCallback = useCallback(async () => {
      Analytics.track('Team Submitted', {
        organizationName: selectedOrgName,
        entityName: teamName,
        location: 'create team modal',
      });
      try {
        const variables: CreateTeamMutationVariables = {
          teamName,
          storageBucketInfo: storageBucketInfoProps.bucketInfo,
        };

        if (selectedOrganization != null && selectedOrganization !== 'none') {
          variables.organizationId = selectedOrganization;
        }
        if (teamAdminUsername != null) {
          variables.teamAdminUserName = teamAdminUsername;
        }

        await createTeam({
          variables,
        });
        onClose?.();
        // after closing the modal, reset its state in case we open it again
        setTeamName('');
        setSelectedOrgName(null);
        storageBucketInfoProps.resetBucketInfo();
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        let analyticsMsg = '';
        if (errMsg === TEAM_LIMIT_ERROR_MSG) {
          setHitTeamLimit(true);
          analyticsMsg = `"contact sales" modal`;
        } else {
          toast('Permission denied, please contact support');
          analyticsMsg = `"permission denied, contact support" message`;
        }
        Analytics.track(`Create Team Error Viewed`, {
          organizationName: selectedOrgName,
          location: 'create team modal',
          error: analyticsMsg,
        });
      }
    }, [
      selectedOrgName,
      teamName,
      selectedOrganization,
      teamAdminUsername,
      storageBucketInfoProps,
      createTeam,
      onClose,
    ]);

    const upgradePlanName = 'Enterprise Plan';
    const teamLimitMessage = `Your current organization plan ${
      selectedOrgName != null ? `for ${selectedOrgName}` : ''
    } only allows you to create one team. Please contact sales to upgrade to the ${upgradePlanName} and unlock multiple teams.`;

    const isOnPrem = config.ENVIRONMENT_IS_PRIVATE;

    const loading = viewer == null || availableTeamQuery.loading;

    const closeModal = useCallback(() => {
      onClose?.();
      setHitTeamLimit(false);
    }, [onClose, setHitTeamLimit]);

    if (orgOptions.length === 0) {
      const message = envIsLocal ? (
        <>
          Teams functionality requires a valid license. Contact{' '}
          <a href="mailto:sales@wandb.com">sales@wandb.com</a> to get started.
        </>
      ) : (
        <>It's completely free to try, get started today!</>
      );
      const modalAction = envIsLocal
        ? undefined
        : {
            content: 'Create team',
            onClick: () => {
              Analytics.track('Create Team Started', {
                location: 'create team modal',
              });
              navigateTo({pathname: '/create-team'});
            },
          };
      return (
        <WBModal
          trigger={props.trigger}
          open={props.open}
          onClose={closeModal}
          onClick={props.onClick}
          header="Collaborative Teams"
          primaryAction={modalAction}>
          Collaborate on ML projects in a central dashboard with W&B Teams.{' '}
          {message}
        </WBModal>
      );
    }

    return (
      <Modal
        open={props.open}
        onClose={closeModal}
        onClick={props.onClick}
        trigger={props.trigger}>
        <Header>{hitTeamLimit ? 'Upgrade Plan' : 'Create a team'}</Header>
        <S.CreateTeamModalContent>
          {hitTeamLimit ? (
            teamLimitMessage
          ) : (
            <Form size="large">
              {!isOnPrem && (
                <Form.Field inline>
                  <label>Organization</label>
                  <Dropdown
                    data-test="choose-org-dropdown"
                    options={orgOptions}
                    value={selectedOrganization ?? ''}
                    direction="left"
                    onChange={handleOrganizationChange}
                    loading={loading}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>Team name</label>
                <TeamNameInput
                  data-test="team-name-input"
                  name="defaultEntity"
                  placeholder="Select a unique team name"
                  loading={teamNameEdited ? loading : false}
                  value={teamName}
                  onChange={handleTeamNameChange}
                  onBlur={() => setTeamNameEdited(true)}
                  icon={teamNameEdited ? teamNameIcon : null}
                />
              </Form.Field>

              <Form.Field>
                <label>Storage type</label>
                <StorageBucketInfoInput {...storageBucketInfoProps} />
              </Form.Field>
            </Form>
          )}
        </S.CreateTeamModalContent>
        <Modal.Actions>
          <S.ActionsWrapper>
            <S.DoubleCheckMessage>
              <S.ActionIcon name="exclamation triangle" />
              Double-check your entries. You will not be able to edit these
              settings later.
            </S.DoubleCheckMessage>
            <S.ActionButtonsWrapper>
              {onClose && <Button content="Cancel" onClick={closeModal} />}
              {hitTeamLimit ? (
                <TargetBlank
                  href={urls.contactSalesPricing()}
                  onClick={() =>
                    Analytics.track(CONTACT_SALES_CLICKED_ANALYTICS, {
                      organizationName: selectedOrgName,
                      location: 'create team modal',
                    })
                  }>
                  <Button primary content="Contact sales" />
                </TargetBlank>
              ) : (
                <Button
                  data-test="submit-create-team"
                  primary
                  disabled={isSubmitDisabled || loading}
                  onClick={createTeamCallback}>
                  Create team
                </Button>
              )}
            </S.ActionButtonsWrapper>
          </S.ActionsWrapper>
        </Modal.Actions>
      </Modal>
    );
  };

export const CreateTeamButton: FunctionComponent<CreateTeamButtonProps> =
  props => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpen = useCallback(() => setModalOpen(true), [setModalOpen]);
    const handleClose = useCallback(() => setModalOpen(false), [setModalOpen]);

    const viewer = useViewer();
    const userOrganizations = useUserOrganizationsQuery();

    // TODO(adrnswanberg | cvp): Replace this with organization limits.
    const isLocalAndTeamsAllowed = viewer?.limits.teams > 0;

    const orgOptions = useMemo(() => {
      if (viewer == null || userOrganizations.loading) {
        return [];
      }

      // Filter out the personal organizations
      const orgs =
        userOrganizations.data?.viewer?.organizations
          .filter(o => o.orgType !== OrgType.Personal)
          .map(o => ({
            text: o.name,
            value: o.id,
          })) ?? [];

      // For now, always make legacy teams an option in onprem.
      // It's only sometimes an option in local.
      const legacyTeamsOption =
        userFlags(viewer).teams_enabled ||
        envIsCloudOnprem ||
        (envIsLocal && isLocalAndTeamsAllowed)
          ? [{text: 'none', value: 'none'}]
          : [];
      return [...legacyTeamsOption, ...orgs];
    }, [
      viewer,
      userOrganizations.loading,
      userOrganizations.data,
      isLocalAndTeamsAllowed,
    ]);
    return (
      <>
        {props.renderButton?.(handleOpen) ?? (
          <Button
            data-test="create-team-button"
            className="create-team-button"
            size={props.size ?? 'tiny'}
            color={props.color}
            disabled={props.readonly === true}
            onClick={() => {
              Analytics.track('Create Team Started', {
                location: 'create team modal',
              });
              handleOpen();
            }}>
            New team
          </Button>
        )}
        <CreateTeamModal
          {...props}
          orgOptions={orgOptions}
          open={modalOpen}
          onClose={handleClose}
        />
      </>
    );
  };
