import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React from 'react';

import * as S from './QueryEditorDeleteButton.styles';

const QueryEditorDeleteButton: React.FC<React.HTMLAttributes<HTMLDivElement>> =
  props => {
    return (
      <S.Wrapper {...props}>
        <LegacyWBIcon name="close" />
      </S.Wrapper>
    );
  };

export default QueryEditorDeleteButton;
