import {WBIcon} from '@wandb/ui';
import styled from 'styled-components';

import {GLOBAL_COLORS} from '../../util/colors';

export const Item = styled.div<{hovered?: boolean}>`
  color: white;
  padding: 8px 24px 8px 12px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${props =>
    props.hovered ? GLOBAL_COLORS.primary.toString() : 'none'};
  word-break: break-word;
`;

export const ItemIcon = styled(WBIcon)`
  margin-left: 2px;
  margin-right: 14px;
  font-size: 20px;
  width: 16px;
  flex-shrink: 0;
`;
