import {isNotNullOrUndefined} from '@wandb/common/util/types';
import React, {FC, useCallback, useContext} from 'react';
import {RenderLeafProps} from 'slate-react';

import {Ref as DiscussionThreadRef} from '../../../state/views/discussionThread/types';
import * as ViewHooks from '../../../state/views/hooks';
import {
  ReportDiscussionContext,
  ReportDiscussionUpdaterContext,
} from '../../ReportDiscussionContext';
import {getThreadIDsFromNode} from '../plugins/inline-comment';
import {LeafNode} from './LeafNode';
import {TextWithFormatting} from './types';

export const LeafNodeWithComments: FC<
  RenderLeafProps & {leaf: TextWithFormatting}
> = props => {
  const {activeThreadRef, discussionThreadRefs} = useContext(
    ReportDiscussionContext
  );
  const {setActiveThread} = useContext(ReportDiscussionUpdaterContext);
  const threadIDs = getThreadIDsFromNode(props.leaf);
  const allThreadsParts = ViewHooks.useParts(discussionThreadRefs);
  const allThreads = allThreadsParts.map((part, i) => ({
    ...part,
    ref: discussionThreadRefs[i],
  }));

  const openInlineComments = useCallback(() => {
    const relatedThreadRefs = allThreads
      .filter(
        thread =>
          threadIDs.includes(thread.id) && isNotNullOrUndefined(thread.ref)
      )
      .map(thread => thread.ref);

    if (relatedThreadRefs == null || relatedThreadRefs.length <= 0) {
      return;
    }

    setActiveThread(
      relatedThreadRefs[0],
      true,
      relatedThreadRefs as DiscussionThreadRef[]
    );
  }, [allThreads, threadIDs, setActiveThread]);

  const activeThread = ViewHooks.usePartMaybe(activeThreadRef);

  if (activeThread != null && threadIDs.includes(activeThread.id)) {
    const childLeafWithActiveComment = {
      ...props.leaf,
      activeInlineComment: true,
      onClick: (e: any) => {
        openInlineComments();
      },
    };
    return (
      <LeafNode
        data-test="leaf-node-with-comment"
        {...props}
        leaf={childLeafWithActiveComment as TextWithFormatting}
      />
    );
  }

  const childLeafWithComment = {
    ...props.leaf,
    inlineComment: true,
    onClick: (e: any) => {
      openInlineComments();
    },
  };
  return (
    <LeafNode {...props} leaf={childLeafWithComment as TextWithFormatting} />
  );
};
