import {JSONObject} from '@wandb/common/types/json';
import {NOOP} from '@wandb/common/util/constants';
import _ from 'lodash';
import {useCallback, useState} from 'react';

import {useUpsertViewMutation, useUserContextQuery} from '../generated/graphql';
import {viewingAs} from './admin';

const USER_CONTEXT_VIEW_TYPE = 'userContext';
const PARSE_FAIL_ERROR = 'Failed to parse view spec';

interface UserContextParams<T> {
  whileLoading: T;
  initialState: T;
  storageKey: string;
}

interface UserContextResult<T> {
  state: T;
  setState: (state: T) => void;
  loading: boolean;
}

export default function useUserContext<T extends JSONObject>({
  whileLoading,
  initialState,
  storageKey,
}: UserContextParams<T>): UserContextResult<T> {
  const [upsertView] = useUpsertViewMutation();
  const {data, loading} = useUserContextQuery();
  const spec = data?.viewer?.views?.edges[0]?.node?.spec || '{}';
  const userIsLoggedIn = data?.viewer != null;

  // Adds the initial state for the key to the view.
  // Initializes an empty view if none exist
  const updateView = useCallback(
    (newState: T) => {
      if (!userIsLoggedIn) {
        return;
      }

      if (viewingAs() !== '') {
        return;
      }

      try {
        const view = JSON.parse(spec);
        // Initialize a view with the initial state
        view[storageKey] = newState;

        upsertView({
          variables: {
            name: USER_CONTEXT_VIEW_TYPE,
            type: USER_CONTEXT_VIEW_TYPE,
            spec: JSON.stringify(view),
          },
        });
      } catch (e) {
        console.error(e, PARSE_FAIL_ERROR);
      }
    },
    [userIsLoggedIn, spec, storageKey, upsertView]
  );

  const key = storageKey;

  let state: T;
  if (loading) {
    state = whileLoading;
  } else if (!userIsLoggedIn) {
    state = initialState;
  } else {
    // User has no view
    let view;
    try {
      view = JSON.parse(spec);
      if (!view[key]) {
        state = initialState;
      } else {
        state = view[key];
      }
    } catch (e) {
      state = whileLoading;
      console.error(e, PARSE_FAIL_ERROR);
    }
  }

  const setState = loading ? NOOP : updateView;

  return {state, setState, loading};
}

const ONBOARDING_CONTEXT_STORAGE_KEY = 'onboarded';
export const CREATE_REPORT_FLASHER_FEATURE_KEY = 'createReportFlasher';
export const ONBOARDING_RESOURCES_POPUP_FEATURE_KEY =
  'onboardingResourcesPopup';
export const CREATED_FIRST_REPORT_FEATURE_KEY = 'createdFirstReport';
export const USER_TOGGLED_TABLE_VIEW_FEATURE_KEY = 'toggledTableView';

interface OnboardingFeatures extends JSONObject {
  createReportFlasher: boolean | null; // the blue flashing dot on <CreateReportButton />
  onboardingResourcesPopup: boolean | null;
  createdFirstReport: boolean | null;
}

interface OnboardingContextResult {
  onboardingState: OnboardingFeatures;
  setOnboardingState: (onboardingState: OnboardingFeatures) => void;
  loading: boolean;
}

export function useOnboardingContext(
  featureKey: keyof OnboardingFeatures
): OnboardingContextResult {
  const {
    state: onboardingState,
    setState: setOnboardingState,
    loading,
  } = useUserContext<OnboardingFeatures>({
    whileLoading: {[featureKey]: false} as OnboardingFeatures,
    initialState: {[featureKey]: false} as OnboardingFeatures,
    storageKey: ONBOARDING_CONTEXT_STORAGE_KEY,
  });
  return {onboardingState, setOnboardingState, loading};
}

const AUTO_REFRESH_STORAGE_KEY = 'autorefresh';

interface AutoRefreshSettings extends JSONObject {
  disabled: boolean | null;
}

interface AutoRefreshContextResult {
  state: AutoRefreshSettings;
  setState: (s: AutoRefreshSettings) => void;
  loading: boolean;
}

export function useAutoRefreshContext(): AutoRefreshContextResult {
  const {state, setState, loading} = useUserContext<AutoRefreshSettings>({
    whileLoading: {disabled: true},
    initialState: {disabled: false},
    storageKey: AUTO_REFRESH_STORAGE_KEY,
  });
  return {state, setState, loading};
}

const TABLE_ROWS_STORAGE_KEY = 'tablerows';

interface TableRowsByPage extends JSONObject {
  [page: string]: TableRows;
}

interface TableRows {
  [key: string]: number | null;
}

export interface TableRowsContextResult {
  state: TableRowsByPage;
  setState: (s: TableRowsByPage) => void;
  loading: boolean;
}

export function useTableRowsContext(): TableRowsContextResult {
  const {state, setState, loading} = useUserContext<TableRowsByPage>({
    whileLoading: {},
    initialState: {},
    storageKey: TABLE_ROWS_STORAGE_KEY,
  });
  return {state, setState, loading};
}

const GALLERY_FRESHNESS_STORAGE_KEY = 'galleryFreshness';

interface GalleryFreshness {
  [key: string]: number;
}

export interface GalleryFreshnessContextResult {
  state: GalleryFreshness;
  setState: (s: GalleryFreshness) => void;
  loading: boolean;
}

export function useGalleryFreshnessContext(): GalleryFreshnessContextResult {
  const {state, setState, loading} = useUserContext<GalleryFreshness>({
    whileLoading: {},
    initialState: {},
    storageKey: GALLERY_FRESHNESS_STORAGE_KEY,
  });

  // HAX: support logged out users with localStorage
  const [, forceRender] = useState({});
  let stateWithLocalStorage = state;
  let setStateWithLocalStorage = setState;
  if (localStorage != null) {
    setStateWithLocalStorage = (s: GalleryFreshness) => {
      setState(s);
      localStorage.setItem(GALLERY_FRESHNESS_STORAGE_KEY, JSON.stringify(s));
      forceRender({});
    };
    if (_.isEmpty(state)) {
      try {
        stateWithLocalStorage = JSON.parse(
          localStorage.getItem(GALLERY_FRESHNESS_STORAGE_KEY) ?? '{}'
        );
      } catch {
        // do nothing
      }
    }
  }

  return {
    state: stateWithLocalStorage,
    setState: setStateWithLocalStorage,
    loading,
  };
}

const WYSIWYG_CONVERSION_STORAGE_KEY = 'wysiwyg';

const WYSIWYG_DISMISSAL_DURATION = 1000 * 60 * 60 * 24 * 7; // 7 days

interface WysiwygConversionSettings extends JSONObject {
  dismissedUntil: number | null;
}

export interface WysiwygConversionContextResult {
  dismissed: boolean;
  dismiss: () => void;
  loading: boolean;
}

export function useWysiwygConversionContext(): WysiwygConversionContextResult {
  const {
    state: {dismissedUntil},
    setState,
    loading,
  } = useUserContext<WysiwygConversionSettings>({
    whileLoading: {dismissedUntil: null},
    initialState: {dismissedUntil: null},
    storageKey: WYSIWYG_CONVERSION_STORAGE_KEY,
  });
  return {
    dismissed: dismissedUntil != null && Date.now() < dismissedUntil,
    dismiss: () =>
      setState({dismissedUntil: Date.now() + WYSIWYG_DISMISSAL_DURATION}),
    loading,
  };
}

const GRAMMARLY_NOTICE_STORAGE_KEY = 'grammarly';

const GRAMMARLY_DISMISSAL_DURATION = 1000 * 60 * 60 * 24 * 7; // 7 days

interface GrammarlyNoticeSettings extends JSONObject {
  dismissedUntil: number | null;
}

export interface GrammarlyNoticeContextResult {
  dismissed: boolean;
  dismiss: () => void;
  loading: boolean;
}

export function useGrammarlyNoticeContext(): GrammarlyNoticeContextResult {
  const {
    state: {dismissedUntil},
    setState,
    loading,
  } = useUserContext<GrammarlyNoticeSettings>({
    whileLoading: {dismissedUntil: null},
    initialState: {dismissedUntil: null},
    storageKey: GRAMMARLY_NOTICE_STORAGE_KEY,
  });
  return {
    dismissed: dismissedUntil != null && Date.now() < dismissedUntil,
    dismiss: () =>
      setState({dismissedUntil: Date.now() + GRAMMARLY_DISMISSAL_DURATION}),
    loading,
  };
}

// TODO: convert this old code into a unit test
//
// <UserContext storeKey="counter" initialState={{ count: 4 }}>
//   {(state , set) => (
//     <div>
//       <div>Count: {state.count}</div>
//       <button
//         onClick={() =>
//           set({count: (state.count || 0) + 1})
//         }>
//         Increment
//       </button>
//     </div>
//   )}
// </UserContext>
// <UserContext storeKey="counter" initialState={{count: 4}}>
//   {(state, set) => (
//     <div>
//       <div>Count: {state.count}</div>
//     </div>
//   )}
// </UserContext>
// <UserContext
//   storeKey="counter-2"
//   initialState={1}>
//   {(state, set) => (
//     <div>
//       <div>Count: {state.count}</div>
//       <button
//         onClick={({count}) =>  count++)}
//         }>
//         Increment
//       </button>
//     </div>
//   )}
// </UserContext>

// <UserContext
//   storeKey="notifications"
//   loadingState={0}
//   initialState={1}>
//   {(state, set) => (
//     <div>
//       <div>Count: {state.count}</div>
//       <button
//         onClick={({count}) => setState({count: count++})
//         }>
//         Increment
//       </button>
//     </div>
//   )}
// </UserContext>
