import produce from 'immer';
import _ from 'lodash';

import {useUserProjectsQuery} from '../../generated/graphql';
import * as ColorUtil from '../../util/colors';
import {expressionToString} from '../../util/expr';
import {getNewGridItemLayout} from '../../util/panelbankGrid';
import {LayedOutPanel, LayoutParameters} from '../../util/panels';
import {
  getAllMetrics,
  getLegendOverrideKey,
  getMetricIdentifiersFromExpressions,
} from '../../util/plotHelpers';
import * as Report from '../../util/report';
import {parseExpressions} from '../PanelExpressionOptions';
import {PANEL_TYPE as runsLinePlotType} from '../PanelRunsLinePlot';
import {isPanelGrid} from '../Slate/plugins/panel-grid';

const overrideKeys = [
  'overrideColors',
  'overrideMarks',
  'overrideSeriesTitles',
];

function addRunNameToOverrides(runName: string, p: LayedOutPanel): void {
  for (const overrideKey of overrideKeys) {
    const override: {[key: string]: unknown} | undefined = (p.config as any)[
      overrideKey
    ];
    if (override != null) {
      for (const k of Object.keys(override)) {
        override[`${runName}:${k}`] = override[k];
        delete override[k];
      }
    }
  }
}

function addDefaultColorsToOverride(runName: string, p: LayedOutPanel): void {
  if (p.viewType !== runsLinePlotType) {
    return;
  }
  const override = p.config.overrideColors ?? {};

  const {expressions, xExpression} = parseExpressions(
    p.config.expressions,
    p.config.xExpression
  );
  const {expressionMetricIdentifiers, xExpressionMetricIdentifiers} =
    getMetricIdentifiersFromExpressions(expressions, xExpression);
  const metrics = getAllMetrics(
    p.config.metrics ?? [],
    expressionMetricIdentifiers,
    xExpressionMetricIdentifiers
  );

  const addToOverride = (metricName: string, i: number) => {
    const key = getLegendOverrideKey({
      uniqueId: runName,
      metricName,
    });
    if (override[key] != null) {
      return;
    }
    const color = ColorUtil.color(i);
    const transparentColor = ColorUtil.color(i, 0.1);
    override[key] = {color, transparentColor};
  };

  for (let i = 0; i < metrics.length; i++) {
    addToOverride(metrics[i], i);
  }
  if (expressions != null && expressions.length > 0) {
    for (let i = 0; i < expressions.length; i++) {
      addToOverride(expressionToString(expressions[i]), i);
    }
  }

  p.config.overrideColors = override;
}

export function normalizePanelLayouts(reportConfig: Report.SlateReport): void {
  reportConfig.blocks.forEach((block, bi) => {
    if (isPanelGrid(block)) {
      const panelLayouts: LayoutParameters[] = [];
      block.metadata.panelBankSectionConfig.panels.forEach((p, pi) => {
        block.metadata.panelBankSectionConfig.panels[pi].layout =
          getNewGridItemLayout(panelLayouts);
        panelLayouts.push(
          block.metadata.panelBankSectionConfig.panels[pi].layout
        );
      });
      reportConfig.blocks[bi] = block;
    }
  });
}

export function normalizeRunPagePanelExport(
  runName: string,
  panels: LayedOutPanel[]
): LayedOutPanel[] {
  return produce(panels, ps => {
    for (const p of ps) {
      addRunNameToOverrides(runName, p);
      addDefaultColorsToOverride(runName, p);
    }
  });
}

type UserProjectsQuery = ReturnType<typeof useUserProjectsQuery>;

export function getTeamsFromUserProjectsQuery(query: UserProjectsQuery) {
  return _.compact(query.data?.user?.teams?.edges.map(e => e.node) ?? []);
}

export function getProjectsFromUserProjectsQuery(query: UserProjectsQuery) {
  const teams = getTeamsFromUserProjectsQuery(query);
  const projectsPerTeam = teams.map(
    t => t?.projects?.edges.map(e => e.node) ?? []
  );
  return _.compact(_.flatten(projectsPerTeam));
}
