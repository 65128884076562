import React, {createContext, FC, ReactNode, useMemo} from 'react';

import {ReportViewRef} from '../../state/reports/types';
import {Viewer} from '../../state/viewer/types';
import * as ViewActions from '../../state/views/actions';
import {useViewRefAction} from '../../state/views/hooks';
import {AccessToken, LoadableView} from '../../state/views/types';
import {isOwnView} from '../../state/views/util';
import {viewerUsingAdminPrivileges} from '../../util/admin';

interface ShareReportTriggerContextProps {
  canChangeSharingSettings?: boolean;
  currentAccessToken?: AccessToken;
  currentIndividualSharingAccessToken?: AccessToken;
  isPrivateAccess: boolean;
  isPrivateWriteAccess: boolean;
  updateViewAccessToken: (accessToken: AccessToken) => void;
  userIsMemberOfTeam?: boolean;
  view: LoadableView;
  viewRef: ReportViewRef;
  viewer?: Viewer;
}

export const ShareReportTriggerContext =
  createContext<ShareReportTriggerContextProps>({
    isPrivateAccess: false,
    isPrivateWriteAccess: false,
    updateViewAccessToken: () => {},
    userIsMemberOfTeam: false,
    view: {
      type: 'runs',
      name: '',
      displayName: '',
      description: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      updatedBy: {
        id: '',
        username: '',
      },
      user: {
        id: '',
        name: '',
        username: '',
        admin: false,
      },
      entityName: '',
      cid: '',
      locked: false,
      loading: false,
      autoSave: false,
      saving: false,
      modified: false,
      starLoading: false,
      panelCommentsEnabled: false,
    },
    viewRef: {
      type: 'runs',
      id: '',
    },
  });

interface ShareReportTriggerContextProviderProps {
  access: string;
  children: ReactNode;
  currentAccessToken?: AccessToken;
  isPrivateAccess: boolean;
  userIsMemberOfTeam?: boolean;
  userIsTeamAdmin?: boolean;
  view: LoadableView;
  viewRef: ReportViewRef;
  viewer?: Viewer;
}

export const ShareReportTriggerContextProvider: FC<ShareReportTriggerContextProviderProps> =
  ({
    access,
    children,
    userIsMemberOfTeam,
    userIsTeamAdmin,
    view,
    viewRef,
    viewer,
  }) => {
    const updateViewAccessToken = useViewRefAction(
      viewRef,
      ViewActions.updateAccessToken
    );

    const currentAccessToken = view.accessTokens?.find(
      token => token.type === 'PUBLIC'
    );
    const currentIndividualSharingAccessToken = view.accessTokens?.find(
      token => token.type === 'INDIVIDUAL_SHARING'
    );

    const isPrivateAccess = ['USER_READ', 'USER_WRITE'].indexOf(access) === -1;
    const isPrivateWriteAccess = access !== 'USER_WRITE';

    const hasAdminSharePrivileges = useMemo(
      () =>
        viewer != null &&
        (view.user.id === viewer.id ||
          (viewer.admin && viewerUsingAdminPrivileges()) ||
          userIsTeamAdmin),
      [view, viewer, userIsTeamAdmin]
    );
    const canChangeSharingSettings = useMemo(
      () => isOwnView(viewer, view) || hasAdminSharePrivileges,
      [viewer, view, hasAdminSharePrivileges]
    );

    const state = useMemo(
      () => ({
        canChangeSharingSettings,
        currentAccessToken,
        currentIndividualSharingAccessToken,
        isPrivateAccess,
        isPrivateWriteAccess,
        userIsMemberOfTeam,
        updateViewAccessToken,
        view,
        viewRef,
        viewer,
      }),
      [
        canChangeSharingSettings,
        currentAccessToken,
        currentIndividualSharingAccessToken,
        isPrivateAccess,
        isPrivateWriteAccess,
        updateViewAccessToken,
        userIsMemberOfTeam,
        view,
        viewRef,
        viewer,
      ]
    );

    return (
      <ShareReportTriggerContext.Provider value={state}>
        {children}
      </ShareReportTriggerContext.Provider>
    );
  };
