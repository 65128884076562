import {colorIndex} from '@wandb/common/components/Artifact';
import {toast} from '@wandb/common/components/elements/Toast';
import {isVersionAlias} from '@wandb/common/util/artifacts';
import * as React from 'react';
import {useState} from 'react';
import {Button, Header, Modal} from 'semantic-ui-react';

import * as Generated from '../generated/graphql';
import {TagAddButton} from './TagAddButton';
import {Tag} from './Tags';

interface AliasProps {
  alias: Pick<Generated.ArtifactAlias, 'alias'>;
  onDelete?: () => Promise<any>;
  onClick?(): void;
}

export const Alias = (props: AliasProps) => {
  const {alias, onDelete, onClick} = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRemoving, setRemoving] = useState(false);

  return (
    <>
      <Tag
        tag={{
          name: alias.alias,
          colorIndex: colorIndex(alias),
        }}
        onDelete={onDelete ? () => setModalOpen(true) : undefined}
        onClick={onClick}
      />
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Header>
          <p>
            Are you sure you want to remove the <b>{alias.alias}</b> alias?
          </p>
        </Header>
        <Modal.Content>
          This will break any scripts that use this alias!
        </Modal.Content>
        <Modal.Actions>
          <Button basic size="tiny" onClick={() => setModalOpen(false)}>
            Nevermind
          </Button>
          <Button
            loading={isRemoving}
            negative
            size="tiny"
            onClick={async () => {
              setRemoving(true);

              if (onDelete) {
                await onDelete();
              }

              setRemoving(false);
              setModalOpen(false);
            }}>
            Remove alias
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface AddAliasButtonProps {
  aliases: Array<Pick<Generated.ArtifactAlias, 'alias'>>;
  availableAliases: Array<Pick<Generated.ArtifactAlias, 'alias'>>;
  onCreate: (newAlias: string) => Promise<any>;
}

export const AddAliasButton = (props: AddAliasButtonProps) => {
  const {aliases, availableAliases, onCreate} = props;

  return (
    <TagAddButton
      tags={aliases.map(a => {
        return {name: a.alias, colorIndex: colorIndex(a)};
      })}
      availableTags={availableAliases.map(a => {
        return {name: a.alias, colorIndex: colorIndex(a)};
      })}
      addTag={async tag => {
        if (isVersionAlias({alias: tag.name})) {
          toast('Custom aliases matching /^v(d+)$/ are not allowed.');
          return;
        }
        await onCreate(tag.name);
      }}
      compact={true}
      direction="bottom right"
      noun={'alias'}
    />
  );
};
