import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {TargetBlank} from '@wandb/common/util/links';
import {isNotNullOrUndefined} from '@wandb/common/util/types';
import React, {
  CSSProperties,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Card} from 'semantic-ui-react';

import emptyImg from '../assets/il-no-reports.png';
import emptyImg2x from '../assets/il-no-reports@2x.png';
import ImageIconHeader from '../components/ImageIconHeader';
import {useUpsertView2Mutation} from '../generated/graphql';
import {useApolloClient} from '../state/hooks';
import * as ViewApi from '../state/views/api';
import {flashFocusCircle} from '../util/flash';
import {
  DISCUSSION_CATEGORY_ID,
  getGalleryTagQS,
  ReportIDWithTagIDs,
  ReportMetadata,
  TagV2,
  useGallerySpec,
  useReportMetadata,
} from '../util/gallery';
import {imgSrcWithDefault} from '../util/images';
import {galleryDiscussionView, galleryPostView, reportView} from '../util/urls';
import {useWindowSize} from '../util/window';
import EditableImage from './EditableImage';
import EmptyWatermark from './EmptyWatermark';
import {ReportsTableReport} from './ReportsTable';
import {GalleryLinkURL} from './Slate/plugins/gallery';

export const NARROW_SCREEN_BREAKPOINT = 1200;

export type ShowcaseItem = ReportsTableReport | GalleryLinkURL;

function isReportShowcaseItem(i: ShowcaseItem): i is ReportsTableReport {
  return `id` in i;
}

interface ReportShowcaseProps {
  items: ShowcaseItem[];
  readOnly?: boolean;
  inProfilePage?: boolean;
}

const ReportShowcase: React.FC<ReportShowcaseProps> = ({
  items,
  readOnly,
  inProfilePage,
}) => {
  const {width: windowWidth} = useWindowSize();
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current == null || window.location.hash !== '#report-showcase') {
      return;
    }
    ref.current.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth',
    });
  }, []);

  // fetch gallery spec to handle projectless reports and FC tags
  const {
    gallerySpec: {reportIDsWithTagV2IDs, tagsV2},
  } = useGallerySpec();

  const galleryReportByID: Map<string, ReportIDWithTagIDs> = useMemo(
    () =>
      new Map<string, ReportIDWithTagIDs>(
        reportIDsWithTagV2IDs.map(r => [r.id, r])
      ),
    [reportIDsWithTagV2IDs]
  );

  const tagByID: Map<string, TagV2> = useMemo(
    () => new Map(tagsV2.map(t => [t.id, t])),
    [tagsV2]
  );

  const projectlessReportIDs = useMemo(
    () =>
      items
        .map(r => (isReportShowcaseItem(r) && r.project == null ? r.id : null))
        .filter(isNotNullOrUndefined),
    [items]
  );

  const {reportMetadatas} = useReportMetadata(projectlessReportIDs);

  const projectlessReportByID: Map<string, ReportMetadata> = useMemo(
    () =>
      new Map<string, ReportMetadata>(
        reportMetadatas?.map(r => [r.id, r]) ?? []
      ),
    [reportMetadatas]
  );

  return (
    <>
      {inProfilePage && (
        <div className="header-wrapper">
          <ImageIconHeader icon="gallery" text="Showcase" />
        </div>
      )}
      {items.length > 0 ? (
        <div ref={ref} id="report-showcase" className="report-showcase">
          <Card.Group
            itemsPerRow={windowWidth <= NARROW_SCREEN_BREAKPOINT ? 1 : 2}>
            {items.map((item, i) => {
              return isReportShowcaseItem(item) ? (
                <ShowcaseItemReport
                  key={i}
                  {...{
                    report: item,
                    galleryReportByID,
                    tags: tagsV2,
                    tagByID,
                    projectlessReportByID,
                    readOnly,
                    inProfilePage,
                  }}
                />
              ) : (
                <ShowcaseItemLink key={i} {...item} />
              );
            })}
          </Card.Group>
        </div>
      ) : (
        <EmptyWatermark
          className="report-showcase-empty-watermark"
          imageSource={emptyImg}
          imageSource2x={emptyImg2x}
          header="Highlight your favorite reports."
          details={
            <>
              Select reports from public projects to feature here.
              <br />
              <span
                className="add-report-to-showcase underline-dashed"
                onClick={() => {
                  const el = document.querySelector(
                    '[data-test="showcase-report-button"]'
                  );
                  if (el != null) {
                    flashFocusCircle(el, {
                      minSpaceFromBottom: 200,
                      padding: 0,
                      offsetY: -3,
                      popping: true,
                    });
                  }
                }}>
                Add a report
              </span>
            </>
          }
          wide
        />
      )}
    </>
  );
};

export default ReportShowcase;

type ShowcaseItemReportProps = {
  report: ReportsTableReport;
  galleryReportByID: Map<string, ReportIDWithTagIDs>;
  tags: TagV2[];
  tagByID: Map<string, TagV2>;
  projectlessReportByID: Map<string, ReportMetadata>;
  readOnly?: boolean;
  inProfilePage?: boolean;
};

const ShowcaseItemReportComp: React.FC<ShowcaseItemReportProps> = ({
  report,
  galleryReportByID,
  tags,
  tagByID,
  projectlessReportByID,
  readOnly,
  inProfilePage,
}) => {
  const [upsertView] = useUpsertView2Mutation();
  const unshowcaseReport = (
    r: ReportsTableReport,
    analyticsLocation: string
  ) => {
    window.analytics?.track('Unshowcase Report Clicked', {
      location: analyticsLocation,
      reportName: r.displayName,
      reportID: r.id,
      projectName: r.project.name,
    });
    upsertView({variables: {id: r.id, showcasedAt: new Date(0)}});
  };

  const client = useApolloClient();
  const savePreviewImage = useCallback(
    (r: ReportsTableReport, previewUrl: string) =>
      ViewApi.save(client, {
        id: r.id,
        previewUrl,
      }),
    [client]
  );

  // if we can't find a projectless report's FC metadata, omit it.
  // This should rarely, if ever, happen.
  const projectlessReportMetadata = projectlessReportByID.get(report.id);
  if (report.project == null && projectlessReportMetadata == null) {
    return null;
  }

  // generate different links based on whether report is projectless
  const link =
    report.project != null
      ? reportView({
          entityName: report.project.entityName,
          projectName: report.project.name,
          reportID: report.id,
          reportName: report.displayName,
        })
      : (projectlessReportMetadata?.tags?.some(
          t => t.id === DISCUSSION_CATEGORY_ID
        )
          ? galleryDiscussionView
          : galleryPostView)({
          entityName: projectlessReportMetadata?.entityName ?? '',
          reportID: report.id,
          reportName: report.displayName,
        });

  // if report is in FC, fetch the first tag for it and build query string
  const galleryReportMetadata = galleryReportByID.get(report.id);
  const galleryReportTag =
    galleryReportMetadata != null && galleryReportMetadata.tagIDs.length > 0
      ? galleryReportMetadata.tagIDs[0]
      : '';
  const galleryTagMetadata = tagByID.get(galleryReportTag);
  const galleryQS =
    galleryTagMetadata != null
      ? getGalleryTagQS(tags, galleryTagMetadata.name)
      : '';

  return (
    <TargetBlank
      className="ui card report-showcase-item"
      href={link + galleryQS}>
      <Card.Content className="report-showcase-item-content">
        <EditableImage
          className="report-showcase-item-image"
          photoUrl={imgSrcWithDefault(report.previewUrl)}
          displaySize={120}
          label={false}
          readOnly={readOnly}
          viewID={report.id}
          updateOverlay="Update Preview"
          save={url => savePreviewImage(report, url)}
        />
        <div className="report-showcase-item-text">
          <Card.Header className="report-showcase-item-title">
            <MaxLines lines={2} text={report.displayName} />
          </Card.Header>
          {report.description.length > 0 && (
            <Card.Description className="report-showcase-item-description">
              <MaxLines lines={2} text={report.description} />
            </Card.Description>
          )}
        </div>
        {!readOnly && inProfilePage && (
          <LegacyWBIcon
            name="close"
            className="report-showcase-item-close"
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              unshowcaseReport(report, 'report showcase');
            }}
          />
        )}
      </Card.Content>
    </TargetBlank>
  );
};
const ShowcaseItemReport = React.memo(ShowcaseItemReportComp);

type ShowcaseItemLinkProps = GalleryLinkURL;

const ShowcaseItemLinkComp: React.FC<ShowcaseItemLinkProps> = ({
  url,
  title,
  description,
  imageURL,
}) => {
  return (
    <TargetBlank className="ui card report-showcase-item" href={url}>
      <Card.Content className="report-showcase-item-content">
        <img
          className="report-showcase-item-image"
          src={imgSrcWithDefault(imageURL)}
          alt="link"
        />
        <div className="report-showcase-item-text">
          <Card.Header className="report-showcase-item-title">
            <MaxLines lines={2} text={title} />
          </Card.Header>
          {description.length > 0 && (
            <Card.Description className="report-showcase-item-description">
              <MaxLines lines={2} text={description} />
            </Card.Description>
          )}
        </div>
      </Card.Content>
    </TargetBlank>
  );
};

const ShowcaseItemLink = React.memo(ShowcaseItemLinkComp);

const LINE_HEIGHT_BUFFER = 0.1;

interface MaxLinesProps {
  lines: number;
  text: string;
}

export const MaxLines: React.FC<MaxLinesProps> = ({lines, text}) => {
  const {width: windowWidth} = useWindowSize();
  const prevWindowWidthRef = useRef(windowWidth);
  const [ready, setReady] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  // update truncated value on prop updates
  useLayoutEffect(() => {
    truncate();
    // eslint-disable-next-line
  }, [lines, text]);

  // update truncated value on viewport resize
  useLayoutEffect(() => {
    const prevWindowWidth = prevWindowWidthRef.current;
    prevWindowWidthRef.current = windowWidth;
    // TODO(axel): more efficient truncation/growing starting from current truncated value
    if (windowWidth < prevWindowWidth) {
      truncate();
    } else if (windowWidth > prevWindowWidth) {
      truncate();
    }
    // eslint-disable-next-line
  }, [windowWidth]);

  const visibilityStyle = useMemo<CSSProperties>(
    () => ({visibility: ready ? 'visible' : 'hidden'}),
    [ready]
  );

  if (text == null) {
    return null;
  }

  return <div ref={ref} style={visibilityStyle} />;

  function truncate(): void {
    const el = ref.current;
    if (el == null) {
      return;
    }

    el.textContent = '-';
    const lineHeight = el.getBoundingClientRect().height;

    let t = text;

    el.textContent = t;
    while (
      el.getBoundingClientRect().height >
      lineHeight * lines + LINE_HEIGHT_BUFFER
    ) {
      if (t.slice(t.length - 3) === '...') {
        t = t.slice(0, t.length - 4);
      } else {
        t = t.slice(0, t.length - 1);
      }
      t += '...';
      el.textContent = t;
    }

    if (!ready) {
      setReady(true);
    }
  }
};
