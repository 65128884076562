import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

import WBReactTable from './WBReactTable';

export const TriggersTable = styled(WBReactTable)`
  margin: 16px;
`;

export const TriggersHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  font-size: 18px;
`;

export const NumberCircle = styled.div<{disabled?: boolean}>`
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  background: ${props => (props.disabled ? globals.gray400 : globals.primary)};
  color: white;
  margin-right: 12px;
`;

export const TriggerStep = styled.div<{disabled?: boolean}>`
  border-left: 1px solid #ccc;
  margin: 12px 16px;
  opacity: ${props => (props.disabled ? 0.4 : 1)} . > div {
    opacity: ${props => (props.disabled ? 0.4 : 1)};
  }
`;

export const TriggerStepHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  margin-bottom: 6px;
  position: relative;
  right: 20px;
`;

export const TriggerStepAction = styled.div`
  margin: 12px 0px 12px 36px;
`;

export const InlineBlockAction = styled.div`
  display: inline-block;
  margin-left: 36px;
`;

export const TemplateStringHelperDiv = styled.div`
  margin-left: 18px;
  margin-bottom: 12px;
  background: white;
  border: 1px solid ${globals.gray300};
  max-width: 800px;
  min-width: 592px;
  border-radius: 3px;
`;

export const TemplateHelperRow = styled.div`
  marin-bottom: 12px;
  display: flex;
`;
export const TemplateStringName = styled.div`
  font-size: 18px;
`;

export const TemplateStringHelp = styled.div`
  font-size: 16px;
  color: ${globals.gray600};
  margin-bottom: 8px;
  margin-left: 2px;
`;

export const SpacerDiv = styled.div`
  margin-left: 12px;
`;

export const EditorDiv = styled.div`
  max-width: 800px;
  min-width: 592px;
  border-radius: 3px;
  margin-bottom: 12px;
  margin-left: 18px;
  .editor-wrapper {
    position: relative;
    min-height: 200px;
  }
`;

export const TriggerStepDescription = styled.div`
  margin-left: 36px;
  margin-top: 12px;
`;

export const PopupCellText = styled.div`
  display: inline;
`;

export const TriggerCreationHeader = styled.div`
  margin-left: 12px;
  font-size: 18px;
`;

export const TriggerCreationTitle = styled.div`
  margin-left: 12px;
  font-size: 24px;
  margin-bottom: 12px;
`;

export const DescriptionSelectorDiv = styled.div`
  margin-left: 18px;
  margin-bottom: 8px;
`;
