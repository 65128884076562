import React, {ElementType, SVGProps} from 'react';

import {ReactComponent as ImportAddNew} from '../../assets/SVG/icon-add-new.svg';
import {ReactComponent as ImportAmazonSagemaker} from '../../assets/SVG/icon-amazon-sagemaker.svg';
import {ReactComponent as ImportBack} from '../../assets/SVG/icon-back.svg';
import {ReactComponent as ImportBlogReport} from '../../assets/SVG/icon-blog-report.svg';
import {ReactComponent as ImportCheckmarkCircle} from '../../assets/SVG/icon-checkmark-circle.svg';
import {ReactComponent as ImportChevronBack} from '../../assets/SVG/icon-chevron-back.svg';
import {ReactComponent as ImportChevronDown} from '../../assets/SVG/icon-chevron-down.svg';
import {ReactComponent as ImportChevronNext} from '../../assets/SVG/icon-chevron-next.svg';
import {ReactComponent as ImportChevronUp} from '../../assets/SVG/icon-chevron-up.svg';
import {ReactComponent as ImportClose} from '../../assets/SVG/icon-close.svg';
import {ReactComponent as ImportCodeAlt} from '../../assets/SVG/icon-code-alt.svg';
import {ReactComponent as ImportCopy} from '../../assets/SVG/icon-copy.svg';
import {ReactComponent as ImportCubeContainer} from '../../assets/SVG/icon-cube-container.svg';
import {ReactComponent as ImportDashboardBlackboard} from '../../assets/SVG/icon-dashboard-blackboard.svg';
import {ReactComponent as ImportDelete} from '../../assets/SVG/icon-delete.svg';
import {ReactComponent as ImportDockerWhale} from '../../assets/SVG/icon-docker-whale.svg';
import {ReactComponent as ImportDocument} from '../../assets/SVG/icon-document.svg';
import {ReactComponent as ImportDocumentation} from '../../assets/SVG/icon-documentation.svg';
import {ReactComponent as ImportDownload} from '../../assets/SVG/icon-download.svg';
import {ReactComponent as ImportEducationAcademic} from '../../assets/SVG/icon-education-academic.svg';
import {ReactComponent as ImportEmailAt} from '../../assets/SVG/icon-email-at.svg';
import {ReactComponent as ImportEmailEnvelope} from '../../assets/SVG/icon-email-envelope.svg';
import {ReactComponent as ImportFailed} from '../../assets/SVG/icon-failed.svg';
import {ReactComponent as ImportFolderAlt} from '../../assets/SVG/icon-folder-alt.svg';
import {ReactComponent as ImportForumChatBubble} from '../../assets/SVG/icon-forum-chat-bubble.svg';
import {ReactComponent as ImportForwardNext} from '../../assets/SVG/icon-forward-next.svg';
import {ReactComponent as ImportFullScreenModeExpand} from '../../assets/SVG/icon-full-screen-mode-expand.svg';
import {ReactComponent as ImportHome} from '../../assets/SVG/icon-home.svg';
import {ReactComponent as ImportIdle} from '../../assets/SVG/icon-idle.svg';
import {ReactComponent as ImportInfo} from '../../assets/SVG/icon-info.svg';
import {ReactComponent as ImportJobProgramCode} from '../../assets/SVG/icon-job-program-code.svg';
import {ReactComponent as ImportJoyceIconTransformersLogo} from '../../assets/SVG/icon-joyce-transformers-logo.svg';
import {ReactComponent as ImportJoyceIconXgboostLogo} from '../../assets/SVG/icon-joyce-xgboost-logo.svg';
import {ReactComponent as ImportKerasLogo} from '../../assets/SVG/icon-keras-logo.svg';
import {ReactComponent as ImportKeyAdmin} from '../../assets/SVG/icon-key-admin.svg';
import {ReactComponent as ImportKubernetes} from '../../assets/SVG/icon-kubernetes.svg';
import {ReactComponent as ImportLaptopLocalComputer} from '../../assets/SVG/icon-laptop-local-computer.svg';
import {ReactComponent as ImportLightbulbInfo} from '../../assets/SVG/icon-lightbulb-info.svg';
import {ReactComponent as ImportLoading} from '../../assets/SVG/icon-loading.svg';
import {ReactComponent as ImportLockClosed} from '../../assets/SVG/icon-lock-closed.svg';
import {ReactComponent as ImportLockOpen} from '../../assets/SVG/icon-lock-open.svg';
import {ReactComponent as ImportLogoColab} from '../../assets/SVG/icon-logo-colab.svg';
import {ReactComponent as ImportMinimizeMode} from '../../assets/SVG/icon-minimize-mode.svg';
import {ReactComponent as ImportModel} from '../../assets/SVG/icon-model.svg';
import {ReactComponent as ImportOpenNewTab} from '../../assets/SVG/icon-open-new-tab.svg';
import {ReactComponent as ImportOrganizationCorporate} from '../../assets/SVG/icon-organization-corporate.svg';
import {ReactComponent as ImportOverflowHorizontal} from '../../assets/SVG/icon-overflow-horizontal.svg';
import {ReactComponent as ImportPencilEdit} from '../../assets/SVG/icon-pencil-edit.svg';
import {ReactComponent as ImportPythonLogo} from '../../assets/SVG/icon-python-logo.svg';
import {ReactComponent as ImportPytorchLightningLogo} from '../../assets/SVG/icon-pytorch-lightning-logo.svg';
import {ReactComponent as ImportPytorchLogo} from '../../assets/SVG/icon-pytorch-logo.svg';
import {ReactComponent as ImportQueue} from '../../assets/SVG/icon-queue.svg';
import {ReactComponent as ImportQueued} from '../../assets/SVG/icon-queued.svg';
import {ReactComponent as ImportRecentClock} from '../../assets/SVG/icon-recent-clock.svg';
import {ReactComponent as ImportRocketLaunch} from '../../assets/SVG/icon-rocket-launch.svg';
import {ReactComponent as ImportRun} from '../../assets/SVG/icon-run.svg';
import {ReactComponent as ImportScikitLogo} from '../../assets/SVG/icon-scikit-logo.svg';
import {ReactComponent as ImportSearch} from '../../assets/SVG/icon-search.svg';
import {ReactComponent as ImportSettings} from '../../assets/SVG/icon-settings.svg';
import {ReactComponent as ImportShareWith} from '../../assets/SVG/icon-share-with.svg';
import {ReactComponent as ImportTable} from '../../assets/SVG/icon-table.svg';
import {ReactComponent as ImportTag} from '../../assets/SVG/icon-tag.svg';
import {ReactComponent as ImportTensorflowLogo} from '../../assets/SVG/icon-tensorflow-logo.svg';
import {ReactComponent as ImportTriggerAlt} from '../../assets/SVG/icon-trigger-alt.svg';
import {ReactComponent as ImportUserProfilePersonal} from '../../assets/SVG/icon-user-profile-personal.svg';
import {ReactComponent as ImportVersionsLayers} from '../../assets/SVG/icon-versions-layers.svg';
import {ReactComponent as ImportVertexGCP} from '../../assets/SVG/icon-vertex-gcp.svg';
import {ReactComponent as ImportVideoPlay} from '../../assets/SVG/icon-video-play.svg';
import {ReactComponent as ImportWarning} from '../../assets/SVG/icon-warning.svg';
import {IconName} from './types';

type SVGIconProps = SVGProps<SVGElement>;

// The natural width/height in our SVG files is 24x24 when the design team provides them.
// However, the design team would also like the default icon size to be 20x20 in our app,
// so we override those attributes for the icon exports.
const updateIconProps = (props: SVGIconProps) => {
  return {
    width: 20,
    height: 20,
    ...props,
  };
};
export const IconAddNew = (props: SVGIconProps) => (
  <ImportAddNew {...updateIconProps(props)} />
);
export const IconAmazonSagemaker = (props: SVGIconProps) => (
  <ImportAmazonSagemaker {...updateIconProps(props)} />
);
export const IconBack = (props: SVGIconProps) => (
  <ImportBack {...updateIconProps(props)} />
);
export const IconBlogReport = (props: SVGIconProps) => (
  <ImportBlogReport {...updateIconProps(props)} />
);
export const IconCheckmarkCircle = (props: SVGIconProps) => (
  <ImportCheckmarkCircle {...updateIconProps(props)} />
);
export const IconChevronBack = (props: SVGIconProps) => (
  <ImportChevronBack {...updateIconProps(props)} />
);
export const IconChevronDown = (props: SVGIconProps) => (
  <ImportChevronDown {...updateIconProps(props)} />
);
export const IconChevronNext = (props: SVGIconProps) => (
  <ImportChevronNext {...updateIconProps(props)} />
);
export const IconChevronUp = (props: SVGIconProps) => (
  <ImportChevronUp {...updateIconProps(props)} />
);
export const IconClose = (props: SVGIconProps) => (
  <ImportClose {...updateIconProps(props)} />
);
export const IconCodeAlt = (props: SVGIconProps) => (
  <ImportCodeAlt {...updateIconProps(props)} />
);
export const IconCopy = (props: SVGIconProps) => (
  <ImportCopy {...updateIconProps(props)} />
);
export const IconCubeContainer = (props: SVGIconProps) => (
  <ImportCubeContainer {...updateIconProps(props)} />
);
export const IconDashboardBlackboard = (props: SVGIconProps) => (
  <ImportDashboardBlackboard {...updateIconProps(props)} />
);
export const IconDelete = (props: SVGIconProps) => (
  <ImportDelete {...updateIconProps(props)} />
);
export const IconDockerWhale = (props: SVGIconProps) => (
  <ImportDockerWhale {...updateIconProps(props)} />
);
export const IconDocument = (props: SVGIconProps) => (
  <ImportDocument {...updateIconProps(props)} />
);
export const IconDocumentation = (props: SVGIconProps) => (
  <ImportDocumentation {...updateIconProps(props)} />
);
export const IconDownload = (props: SVGIconProps) => (
  <ImportDownload {...updateIconProps(props)} />
);
export const IconEducationAcademic = (props: SVGIconProps) => (
  <ImportEducationAcademic {...updateIconProps(props)} />
);
export const IconEmailAt = (props: SVGIconProps) => (
  <ImportEmailAt {...updateIconProps(props)} />
);
export const IconEmailEnvelope = (props: SVGIconProps) => (
  <ImportEmailEnvelope {...updateIconProps(props)} />
);
export const IconFailed = (props: SVGIconProps) => (
  <ImportFailed {...updateIconProps(props)} />
);
export const IconFolderAlt = (props: SVGIconProps) => (
  <ImportFolderAlt {...updateIconProps(props)} />
);
export const IconForumChatBubble = (props: SVGIconProps) => (
  <ImportForumChatBubble {...updateIconProps(props)} />
);
export const IconForwardNext = (props: SVGIconProps) => (
  <ImportForwardNext {...updateIconProps(props)} />
);
export const IconFullScreenModeExpand = (props: SVGIconProps) => (
  <ImportFullScreenModeExpand {...updateIconProps(props)} />
);
export const IconHome = (props: SVGIconProps) => (
  <ImportHome {...updateIconProps(props)} />
);
export const IconIdle = (props: SVGIconProps) => (
  <ImportIdle {...updateIconProps(props)} />
);
export const IconInfo = (props: SVGIconProps) => (
  <ImportInfo {...updateIconProps(props)} />
);
export const IconJobProgramCode = (props: SVGIconProps) => (
  <ImportJobProgramCode {...updateIconProps(props)} />
);
export const IconJoyceTransformersLogo = (props: SVGIconProps) => (
  <ImportJoyceIconTransformersLogo {...updateIconProps(props)} />
);
export const IconJoyceXgboostLogo = (props: SVGIconProps) => (
  <ImportJoyceIconXgboostLogo {...updateIconProps(props)} />
);
export const IconKerasLogo = (props: SVGIconProps) => (
  <ImportKerasLogo {...updateIconProps(props)} />
);
export const IconKeyAdmin = (props: SVGIconProps) => (
  <ImportKeyAdmin {...updateIconProps(props)} />
);
export const IconKubernetes = (props: SVGIconProps) => (
  <ImportKubernetes {...updateIconProps(props)} />
);
export const IconLaptopLocalComputer = (props: SVGIconProps) => (
  <ImportLaptopLocalComputer {...updateIconProps(props)} />
);
export const IconLightbulbInfo = (props: SVGIconProps) => (
  <ImportLightbulbInfo {...updateIconProps(props)} />
);
export const IconLoading = (props: SVGIconProps) => (
  <ImportLoading {...updateIconProps(props)} />
);
export const IconLockClosed = (props: SVGIconProps) => (
  <ImportLockClosed {...updateIconProps(props)} />
);
export const IconLockOpen = (props: SVGIconProps) => (
  <ImportLockOpen {...updateIconProps(props)} />
);
export const IconLogoColab = (props: SVGIconProps) => (
  <ImportLogoColab {...updateIconProps(props)} />
);
export const IconMinimizeMode = (props: SVGIconProps) => (
  <ImportMinimizeMode {...updateIconProps(props)} />
);
export const IconModel = (props: SVGIconProps) => (
  <ImportModel {...updateIconProps(props)} />
);
export const IconOpenNewTab = (props: SVGIconProps) => (
  <ImportOpenNewTab {...updateIconProps(props)} />
);
export const IconOrganizationCorporate = (props: SVGIconProps) => (
  <ImportOrganizationCorporate {...updateIconProps(props)} />
);
export const IconOverflowHorizontal = (props: SVGIconProps) => (
  <ImportOverflowHorizontal {...updateIconProps(props)} />
);
export const IconPencilEdit = (props: SVGIconProps) => (
  <ImportPencilEdit {...updateIconProps(props)} />
);
export const IconPythonLogo = (props: SVGIconProps) => (
  <ImportPythonLogo {...updateIconProps(props)} />
);
export const IconPytorchLightningLogo = (props: SVGIconProps) => (
  <ImportPytorchLightningLogo {...updateIconProps(props)} />
);
export const IconPytorchLogo = (props: SVGIconProps) => (
  <ImportPytorchLogo {...updateIconProps(props)} />
);
export const IconQueue = (props: SVGIconProps) => (
  <ImportQueue {...updateIconProps(props)} />
);
export const IconQueued = (props: SVGIconProps) => (
  <ImportQueued {...updateIconProps(props)} />
);
export const IconRecentClock = (props: SVGIconProps) => (
  <ImportRecentClock {...updateIconProps(props)} />
);
export const IconRocketLaunch = (props: SVGIconProps) => (
  <ImportRocketLaunch {...updateIconProps(props)} />
);
export const IconRun = (props: SVGIconProps) => (
  <ImportRun {...updateIconProps(props)} />
);
export const IconScikitLogo = (props: SVGIconProps) => (
  <ImportScikitLogo {...updateIconProps(props)} />
);
export const IconSearch = (props: SVGIconProps) => (
  <ImportSearch {...updateIconProps(props)} />
);
export const IconSettings = (props: SVGIconProps) => (
  <ImportSettings {...updateIconProps(props)} />
);
export const IconShareWith = (props: SVGIconProps) => (
  <ImportShareWith {...updateIconProps(props)} />
);
export const IconTable = (props: SVGIconProps) => (
  <ImportTable {...updateIconProps(props)} />
);
export const IconTag = (props: SVGIconProps) => (
  <ImportTag {...updateIconProps(props)} />
);
export const IconTensorflowLogo = (props: SVGIconProps) => (
  <ImportTensorflowLogo {...updateIconProps(props)} />
);
export const IconTriggerAlt = (props: SVGIconProps) => (
  <ImportTriggerAlt {...updateIconProps(props)} />
);
export const IconUserProfilePersonal = (props: SVGIconProps) => (
  <ImportUserProfilePersonal {...updateIconProps(props)} />
);
export const IconVersionsLayers = (props: SVGIconProps) => (
  <ImportVersionsLayers {...updateIconProps(props)} />
);
export const IconVertexGCP = (props: SVGIconProps) => (
  <ImportVertexGCP {...updateIconProps(props)} />
);
export const IconVideoPlay = (props: SVGIconProps) => (
  <ImportVideoPlay {...updateIconProps(props)} />
);
export const IconWarning = (props: SVGIconProps) => (
  <ImportWarning {...updateIconProps(props)} />
);

const ICON_NAME_TO_ICON: Record<IconName, ElementType> = {
  'add-new': IconAddNew,
  'amazon-sagemaker': IconAmazonSagemaker,
  back: IconBack,
  'blog-report': IconBlogReport,
  'checkmark-circle': IconCheckmarkCircle,
  'chevron-back': IconChevronBack,
  'chevron-down': IconChevronDown,
  'chevron-next': IconChevronNext,
  'chevron-up': IconChevronUp,
  close: IconClose,
  'code-alt': IconCodeAlt,
  copy: IconCopy,
  'cube-container': IconCubeContainer,
  'dashboard-blackboard': IconDashboardBlackboard,
  delete: IconDelete,
  'docker-whale': IconDockerWhale,
  document: IconDocument,
  documentation: IconDocumentation,
  download: IconDownload,
  'education-academic': IconEducationAcademic,
  'email-at': IconEmailAt,
  'email-envelope': IconEmailEnvelope,
  failed: IconFailed,
  'folder-alt': IconFolderAlt,
  'forum-chat-bubble': IconForumChatBubble,
  'forward-next': IconForwardNext,
  'full-screen-mode-expand': IconFullScreenModeExpand,
  home: IconHome,
  idle: IconIdle,
  info: IconInfo,
  'job-program-code': IconJobProgramCode,
  'joyce-transformers-logo': IconJoyceTransformersLogo,
  'joyce-xgboost-logo': IconJoyceXgboostLogo,
  'keras-logo': IconKerasLogo,
  'key-admin': IconKeyAdmin,
  kubernetes: IconKubernetes,
  'laptop-local-computer': IconLaptopLocalComputer,
  'lightbulb-info': IconLightbulbInfo,
  loading: IconLoading,
  'lock-closed': IconLockClosed,
  'lock-open': IconLockOpen,
  'logo-colab': IconLogoColab,
  'minimize-mode': IconMinimizeMode,
  model: IconModel,
  'open-new-tab': IconOpenNewTab,
  'organization-corporate': IconOrganizationCorporate,
  'overflow-horizontal': IconOverflowHorizontal,
  'pencil-edit': IconPencilEdit,
  'python-logo': IconPythonLogo,
  'pytorch-lightning-logo': IconPytorchLightningLogo,
  'pytorch-logo': IconPytorchLogo,
  queue: IconQueue,
  queued: IconQueued,
  'recent-clock': IconRecentClock,
  'rocket-launch': IconRocketLaunch,
  run: IconRun,
  'scikit-logo': IconScikitLogo,
  search: IconSearch,
  settings: IconSettings,
  'share-with': IconShareWith,
  table: IconTable,
  tag: IconTag,
  'tensorflow-logo': IconTensorflowLogo,
  'trigger-alt': IconTriggerAlt,
  'user-profile-personal': IconUserProfilePersonal,
  'versions-layers': IconVersionsLayers,
  'vertex-gcp': IconVertexGCP,
  'video-play': IconVideoPlay,
  warning: IconWarning,
};

export interface IconProps {
  name: IconName;
  [x: string]: any;
}

export const Icon = ({name, ...props}: IconProps) => {
  const IconComponent: ElementType = ICON_NAME_TO_ICON[name];
  return <IconComponent {...props} />;
};
