import WBQueryMenu from '@wandb/common/components/elements/WBQueryMenu';
import {WBPopup} from '@wandb/ui';
import React from 'react';
import {Editor, Node, Text, Transforms} from 'slate';
import {useSlate} from 'slate-react';

import {useReportContext} from '../../state/reports/context';
import {useAdminModeActive} from '../../util/admin';
import {
  EMPTY_PANEL_BANK_CONFIG,
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
} from '../../util/panelbank';
import {EMPTY_SINGLE_TAB} from '../../util/panels';
import {emptyReportRunSetSelectAll} from '../../util/section';
import {useBetaFeatureWeavePythonEcosystem} from '../../util/useBetaFeature';
import {isMarkdownBlock} from './plugins/markdown-blocks-common';
import {PanelGrid} from './plugins/panel-grid';
import * as S from './SlashMenu.styles';
import {WBSlateElementType} from './WBSlate';

export function applyNewNode(editor: Editor, node: Node) {
  if (Editor.isInline(editor, node)) {
    Editor.insertNode(editor, node);
    return;
  }

  const blockEntry = Editor.above(editor, {
    match: n => Editor.isBlock(editor, n),
  });

  if (blockEntry == null) {
    return;
  }

  if (Editor.isEmpty(editor, blockEntry[0])) {
    Transforms.setNodes(editor, node);

    // hacky selection reset because setting voids breaks Slate's selection synchronization
    if (Editor.isVoid(editor, node) && !isMarkdownBlock(node)) {
      const currentSelection = editor.selection;
      if (currentSelection != null) {
        Transforms.deselect(editor);
        window.setTimeout(() => {
          Transforms.select(editor, currentSelection);
        });
      }
    }
    return;
  }

  if (Editor.isVoid(editor, node)) {
    Transforms.splitNodes(editor);
    Transforms.insertNodes(editor, node);
    return;
  }

  Transforms.splitNodes(editor, {always: true});
  Transforms.setNodes(editor, node);
}

function defaultNewSection(): PanelGrid['metadata'] {
  return {
    openViz: true,
    panels: EMPTY_SINGLE_TAB,
    panelBankConfig: EMPTY_PANEL_BANK_CONFIG,
    panelBankSectionConfig: EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
    customRunColors: {},
    runSets: [emptyReportRunSetSelectAll()],
    openRunSet: 0,
    name: 'unused-name',
  };
}

export function getNodeFromValue(value: string): WBSlateElementType {
  const [type, version] = (value as string).split('|');

  const newNode: Node = {
    type,
    children: [{text: ''}],
  };

  if (version != null) {
    if (type === 'heading') {
      newNode.level = parseInt(version, 10);
      newNode.collapsedChildren = undefined;
    }

    if (type === 'list-item') {
      if (version === 'o') {
        newNode.ordered = true;
      } else if (version === 'c') {
        newNode.checked = false;
      }
    }
  }

  if (type === 'markdown-block') {
    newNode.content = '';
    newNode.autoFocus = true;
  }

  if (type === 'latex') {
    newNode.content = '';
    newNode.autoFocus = true;
    if (version === 'block') {
      newNode.block = true;
    }
  }

  if (type === 'panel-grid') {
    newNode.metadata = defaultNewSection();
  }

  if (type === 'gallery') {
    newNode.ids = [];
  }

  return newNode as WBSlateElementType;
}

type SlashMenuOption = {
  name: string;
  value: string;
  icon: string;
  synonyms?: string[];
  adminOnly?: boolean;
};

export interface SlashMenuProps {
  className?: string;
  open: boolean;
  onClose?(): void;
}

const SlashMenu: React.FC<SlashMenuProps> = ({className, open, onClose}) => {
  const {disablePanels, disableTableOfContents, disableLinkToReport} =
    useReportContext();
  const weaveEcosystemEnabled = useBetaFeatureWeavePythonEcosystem();
  const editor = useSlate();
  const adminModeActive = useAdminModeActive();

  const [rect, setRect] = React.useState<DOMRect | null>(null);
  const [query, setQuery] = React.useState('');
  React.useEffect(() => {
    if (!open) {
      return;
    }

    const domSelection = window.getSelection();
    if (domSelection != null && domSelection.rangeCount > 0) {
      const domRange = domSelection.getRangeAt(0);
      setRect(domRange.getBoundingClientRect());
    }
  }, [open]);

  React.useEffect(() => {
    if (!open) {
      return;
    }
    window.setTimeout(() => {
      if (editor.selection == null) {
        return;
      }
      const leaf = Editor.node(editor, editor.selection);
      if (leaf != null) {
        const [node] = leaf;
        if (Text.isText(node)) {
          const slashOffset = node.text.lastIndexOf('/');
          if (slashOffset === -1) {
            onClose?.();
            return;
          }
          const queryText = Editor.string(editor, {
            ...editor.selection,
            anchor: {...editor.selection.anchor, offset: slashOffset + 1},
          });
          setQuery(queryText);
        }
      }
    });
  }, [open, editor, editor.selection, onClose]);

  const options: SlashMenuOption[] = [
    ...(!disablePanels
      ? [
          {
            name: 'Panel grid',
            value: 'panel-grid',
            icon: 'panel-grid',
            synonyms: ['pg'],
          },
        ]
      : []),
    {name: 'Heading 1', value: 'heading|1', icon: 'h1', synonyms: ['#', 'h1']},
    {name: 'Heading 2', value: 'heading|2', icon: 'h2', synonyms: ['##', 'h2']},
    {
      name: 'Heading 3',
      value: 'heading|3',
      icon: 'h3',
      synonyms: ['###', 'h3'],
    },
    {
      name: 'Bulleted list',
      value: 'list-item|u',
      icon: 'list',
      synonyms: ['-', '*'],
    },
    {
      name: 'Numbered list',
      value: 'list-item|o',
      icon: 'numbered-list',
      synonyms: ['1.'],
    },
    {
      name: 'Checklist',
      value: 'list-item|c',
      icon: 'check',
      synonyms: ['-[]', '[]'],
    },
    {
      name: 'Horizontal rule',
      value: 'horizontal-rule',
      icon: 'page-break',
      synonyms: ['---', '***'],
    },
    {
      name: 'Block quote',
      value: 'block-quote',
      icon: 'quote',
      synonyms: ['>', '|'],
    },
    ...(!disableTableOfContents
      ? [
          {
            name: 'Table of Contents',
            value: 'table-of-contents',
            icon: 'table',
            synonyms: ['toc'],
          },
        ]
      : []),
    {
      name: 'Callout',
      value: 'callout-line',
      icon: 'lightbulb',
      synonyms: ['>>>'],
    },
    {name: 'Code', value: 'code-line', icon: 'code', synonyms: ['```']},
    {name: 'Markdown', value: 'markdown-block', icon: 'markdown'},
    {name: 'Image', value: 'image', icon: 'image', synonyms: ['![]']},
    {
      name: 'Inline equation (LaTeX)',
      value: 'latex|inline',
      icon: 'xaxis',
      synonyms: ['$', '\\(\\)', '\\begin{math}'],
    },
    {
      name: 'Block equation (LaTeX)',
      value: 'latex|block',
      icon: 'xaxis',
      synonyms: ['$$', '\\[\\]', '\\begin{equation}'],
    },
    ...(!disableLinkToReport
      ? [{name: 'Link to report', value: 'gallery', icon: 'image'}]
      : []),
    ...(!disablePanels
      ? [{name: 'Weave', value: 'weave-panel', icon: 'search'}]
      : []),
    ...(!disablePanels && weaveEcosystemEnabled
      ? [
          {
            name: '(Beta) Weave Python',
            value: 'weave-python-panel',
            icon: 'search',
          },
        ]
      : []),
    {name: 'Craiyon', value: 'craiyon', icon: 'image', adminOnly: true},
    {name: 'CTA Banner', value: 'cta-banner', icon: 'image', adminOnly: true},
    {name: 'Button', value: 'cta-button', icon: 'image', adminOnly: true},
  ]
    .filter(o => !o.adminOnly || adminModeActive)
    .filter(o => {
      const matchStrs = [o.name, ...(o.synonyms ?? [])];
      return matchStrs.some(s => s.toLowerCase().includes(query.toLowerCase()));
    });

  React.useEffect(() => {
    if (options.length === 0 && query.length > 8) {
      setQuery('');
      onClose?.();
    }
  }, [options.length, query.length, onClose]);

  if (!open || rect == null) {
    return null;
  }

  return (
    <WBPopup x={rect.x} y={rect.y + rect.height} direction="bottom right">
      {
        <WBQueryMenu
          options={options}
          highlightFirst
          optionRenderer={({hovered, option, selected}) => (
            <S.Item hovered={hovered}>
              <S.ItemIcon
                name={option.icon ?? (selected ? 'check' : 'blank')}
              />
              {option.name}
            </S.Item>
          )}
          onEsc={onClose}
          onSelect={value => {
            if (editor.selection == null) {
              return;
            }
            const leaf = Editor.node(editor, editor.selection);
            if (leaf != null) {
              const [node, path] = leaf;
              if (Text.isText(node)) {
                const slashOffset = node.text.indexOf('/');
                Transforms.delete(editor, {
                  at: {
                    ...editor.selection,
                    anchor: {path, offset: slashOffset},
                  },
                });

                const newNode = getNodeFromValue(value as string);

                applyNewNode(editor, newNode);

                onClose?.();
              }
            }
          }}
        />
      }
    </WBPopup>
  );
};

export default SlashMenu;
