import Tooltip from '@material-ui/core/Tooltip';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import * as _ from 'lodash';
import * as React from 'react';
import {useState} from 'react';
import {Button, Checkbox, Divider, Label, Popup} from 'semantic-ui-react';

import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import * as UIHelpers from '../../util/uihelpers';

interface WBTableActionGroupButtonProps {
  compact?: boolean;
  groupOpen: boolean;
  grouping: Query.Grouping;
  isInReport?: boolean;
}

export const WBTableActionGroupButton = (
  props: WBTableActionGroupButtonProps
) => {
  let groupButtonClass;
  const groupCount = props.grouping.length;

  const {compact, grouping, groupOpen, isInReport, ...rest} = props;

  if (props.groupOpen) {
    groupButtonClass = 'action-button--focused';
  } else if (groupCount > 0) {
    groupButtonClass = 'action-button--active';
  } else {
    groupButtonClass = 'action-button--static';
  }

  const buttonText =
    groupCount > 0
      ? 'Grouped by ' + UIHelpers.maybePluralize(groupCount, 'field')
      : 'Group';
  return (
    <Tooltip
      placement="top"
      enterDelay={0}
      title={props.compact ? 'Group' : ''}
      arrow>
      <span
        onClick={() => {
          const actionLocation = props.isInReport ? 'report' : 'runs table';
          window.analytics?.track('Group dropdown clicked', {
            location: actionLocation,
          });
        }}>
        <Button
          {...rest}
          data-test="group-popup"
          size="tiny"
          className={groupButtonClass + ' wb-icon-button table-group-button'}>
          <LegacyWBIcon name="group-runs" title={buttonText} />
          {props.compact ? '' : buttonText}
        </Button>
      </span>
    </Tooltip>
  );
};

interface WBTableActionGroupDropdownProps {
  groupableKeys: string[];
  loading: boolean;
  grouping: Query.Grouping;

  setGrouping(grouping: Query.Grouping): void;
}

export const WBTableActionGroupDropdown = (
  props: WBTableActionGroupDropdownProps
) => {
  const {groupableKeys, loading, grouping, setGrouping} = props;
  const options = UIHelpers.makeOptions(groupableKeys);
  return (
    <ModifiedDropdown
      style={{minWidth: '205px'}}
      placeholder="Group by..."
      data-test="group-dropdown"
      closeOnBlur
      multiple
      lazyLoad
      selection
      optionTransform={UIHelpers.beautify}
      options={options}
      search={UIHelpers.searchFunction}
      value={grouping.map(v => Run.keyToString(v))}
      disabled={loading}
      loading={loading}
      itemLimit={100}
      closeOnChange
      onChange={(e, {value}) => {
        // We know it's string[] because we pass in options
        const typedValue: string[] = value as string[];
        // We filter out nulls so casting to Run.Key[] is safe
        const newGrouping: Run.Key[] = typedValue
          .map(v => Run.keyFromString(v))
          .filter(o => o != null) as Run.Key[];
        setGrouping(newGrouping);
      }}
    />
  );
};

interface WBTableActionGroupPickerProps {
  grouping: Query.Grouping;
  recommendedGrouping?: Query.Grouping; // A good grouping for the current data
  dropdown: JSX.Element;

  setGrouping(grouping: Query.Grouping): void;
}

export const WBTableActionGroupPicker = (
  props: WBTableActionGroupPickerProps
) => {
  const {recommendedGrouping, grouping, dropdown, setGrouping} = props;

  return (
    <div>
      {dropdown}
      {recommendedGrouping != null && recommendedGrouping.length !== 0 && (
        <React.Fragment>
          <Divider />
          <div className="filter-list__toggle">
            <span style={{marginRight: 16, whiteSpace: 'nowrap'}}>
              Recommended:{' '}
              {recommendedGrouping.map((k, i) => (
                <Label key={i}>
                  {UIHelpers.getImage(k)} {Run.keyDisplayName(k, true)}
                </Label>
              ))}
            </span>
            <Checkbox
              className="filter-list__action"
              toggle
              checked={_.isEqual(grouping, recommendedGrouping)}
              onChange={() => {
                if (_.isEqual(grouping, recommendedGrouping)) {
                  setGrouping([]);
                } else {
                  setGrouping(recommendedGrouping);
                }
              }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

interface WBTableActionGroupProps {
  trigger: (isOpen: boolean) => React.ReactNode;
  content: React.ReactNode;
}

export const WBTableActionGroup = (props: WBTableActionGroupProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Popup
      basic
      className="wb-table-action-popup"
      on="click"
      position="bottom left"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={props.trigger(open)}
      content={props.content}
      popperModifiers={{
        preventOverflow: {enabled: false},
        flip: {enabled: false},
      }}
    />
  );
};
