import React from 'react';
import {useCallback, useMemo} from 'react';
import * as Panel2 from './panel';
// import * as CGReact from '../../cgreact';
// import {Panel2Loader} from './PanelComp';
import SliderInput from '@wandb/common/components/elements/SliderInput';
import {useAction, useLet, useNodeValue} from '../../cgreact';
import * as CG from '@wandb/cg';
import {toWeaveType} from './PanelGroup2';
import {usePanelContext} from './PanelContext';
import * as ConfigPanel from './ConfigPanel';

// import * as Op from '@wandb/cg/browser/ops';

const inputType = 'invalid';
interface PanelSlider2Config {
  value: CG.Node<'number'>;
  min: number;
  max: number;
  step: number;
}
type PanelSlider2Props = Panel2.PanelProps<
  typeof inputType,
  PanelSlider2Config
>;

export const PanelSliderConfig2: React.FC<PanelSlider2Props> = props => {
  const {config, updateConfig} = props;
  const {path, selectedPath} = usePanelContext();
  const pathStr = path.join('.');
  const selectedPathStr = selectedPath?.join('.') ?? '';

  if (pathStr === selectedPathStr) {
    // We are selected, render our config
    return (
      <div>
        <ConfigPanel.ConfigOption label="min">
          <ConfigPanel.NumberInputConfigField
            value={config?.min}
            onChange={newVal => {
              updateConfig({
                min: newVal,
              });
            }}
          />
        </ConfigPanel.ConfigOption>
      </div>
    );
  }
  return <div />;
};

export const PanelSlider2: React.FC<PanelSlider2Props> = props => {
  // const updateVal = CGReact.useAction(props.input, 'number-set');
  // const nodeValueQuery = CGReact.useNodeValue(props.input);
  // if (nodeValueQuery.loading) {
  //   return <Panel2Loader />;
  // }
  // if (nodeValueQuery.result == null) {
  //   return <div>-</div>;
  // }
  const {config, updateConfig} = props;
  const statements = useMemo(
    () => ({
      value: config?.value,
    }),
    [config]
  );
  const updateConfigVal = useCallback(
    (key: string, newVal: number) => {
      updateConfig({
        ...config,
        value: CG.constNodeUnsafe(toWeaveType(newVal), newVal),
      });
    },
    [config, updateConfig]
  );
  const letStatements = useLet(statements, updateConfigVal);
  const valueNode = useMemo(() => letStatements.value, [letStatements]);
  const value = useNodeValue(valueNode);
  const setA = useAction(
    CG.constNodeUnsafe(toWeaveType(valueNode), valueNode),
    'set'
  );
  const updateVal = useCallback(
    (newVal: number) => setA({val: CG.constNumber(newVal)}),
    [setA]
  );
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: 'auto',
        textAlign: 'center',
        wordBreak: 'normal',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-around',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}>
      <div
        style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <SliderInput
          data-test-weave-id="slider"
          min={props.config?.min ?? 0.1}
          max={props.config?.max ?? 1}
          step={props.config?.step ?? 0.01}
          value={value.result}
          onChange={updateVal}
          // onChange={val => updateVal({val: Op.constNumber(val)})}
        />
        <div style={{marginLeft: 12}}>{JSON.stringify(value.result)}</div>
      </div>
    </div>
  );
};

export const Spec: Panel2.PanelSpec = {
  hidden: true,
  id: 'Slider2',
  ConfigComponent: PanelSliderConfig2,
  Component: PanelSlider2,
  inputType,
};
