import {PanelExportRef} from '@wandb/common/util/panelHelpers';

import * as PanelBarChart from '../components/PanelBarChart';
import * as PanelRunsLinePlot from '../components/PanelRunsLinePlot';
import {InjectedRunsDataProps} from '../containers/RunsDataLoader';
import * as RunSetViewTypes from '../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../types/run';
import * as Query from '../util/queryts';
import * as RunHelpers from '../util/runhelpers';
import * as SM from '../util/selectionmanager';
import {PlotFontSize} from './plotHelpers';
import {RunColorConfig} from './section';

export * from '@wandb/common/util/panelHelpers';

export function isGrouped(
  query: Query.Query,
  config: PanelRunsLinePlot.RunsLinePlotConfig | PanelBarChart.BarChartConfig
) {
  // Runs can be grouped in two ways:
  // 1) If there is grouping in the data populating the plot, the plot
  // will have grouping.
  // 2) the user selected grouping in the panel (config.aggregate)
  if (
    config.useRunsTableGroupingInPanels ||
    config.useRunsTableGroupingInPanels == null
  ) {
    return (
      RunHelpers.isGrouped(query) || config.aggregate || config.aggregateMetrics
    );
  } else {
    return config.aggregate || config.aggregateMetrics;
  }
}

export function isSingleRun(props: {pageQuery: Query.Query}) {
  // runName is set in the query only if we are looking at a singleRun page
  return props.pageQuery.runName != null;
}

export type BasePanelProps = {
  configMode: boolean;
  initialConfigState?: {[key: string]: any};
  currentHeight: number;
  exportMode?: boolean;

  // This is undefined in render mode, but always defined in edit
  // mode
  // TODO: don't pass this in, let panels fetch it themselves when
  // they need it
  keyInfo?: RunHistoryKeyInfo;
  dimensions: {w: number; h: number};
  pageQuery: Query.Query;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  customRunColors?: RunColorConfig;
  runSetRefs: RunSetViewTypes.Ref[];
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
  convertSelectionsToFilters?(
    selections: SM.PanelSelections,
    axes?: string[]
  ): void;
  onContentHeightChange?(h: number): void;
} & InjectedRunsDataProps;

export type PanelProps<ConfigType> = BasePanelProps & {
  config: ConfigType;
  updateConfig(config: Partial<ConfigType>): void;
};

export type PanelComponentType<ConfigType> = React.ComponentType<
  PanelProps<ConfigType>
>;

export function getFontSize(
  fontSize: PlotFontSize | 'auto',
  panelHeight: number
): PlotFontSize {
  if (fontSize === 'auto') {
    return getDefaultFontSizeFromHeight(panelHeight);
  } else {
    return fontSize;
  }
}

export function getDefaultFontSizeFromHeight(
  panelHeight: number
): PlotFontSize {
  if (panelHeight < 300) {
    return 'small';
  } else if (panelHeight < 600) {
    return 'medium';
  } else {
    return 'large';
  }
}
