import config from '@wandb/common/config';
import React from 'react';
import {Route} from 'react-router';

import {createProjectPage} from '../util/urls';
import {
  BENCHMARK_PATHS,
  ENTITY_LAUNCH_PATHS,
  ENTITY_REGISTRY_PATHS,
  GALLERY_DISCUSSION_EDIT_PATH,
  GALLERY_DISCUSSION_VIEW_PATH,
  GALLERY_PATH,
  GALLERY_PATH_SEGMENT,
  GALLERY_POST_EDIT_PATH,
  GALLERY_POST_VIEW_PATH,
  GROUP_PATHS,
  ONBOARDING_FLOW_PATH,
  PROFILE_LIKES_PATH,
  PROFILE_PATH,
  PROFILE_PROJECTS_PATH,
  PROFILE_REPORTS_PATH,
  PROJECT_BASE_PATH,
  PROJECT_PATHS,
  QUICKSTART_PATH,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  RUN_PATHS,
  STORAGE_EXPLORER_TRACKED_PATH,
  SWEEP_PATHS,
  TEAM_MEMBERS_PATH,
} from './paths';
import PrivateRoute from './PrivateRoute';
import RouteWithLayout from './RouteWithLayout';

const IS_ON_PREM = config.ENVIRONMENT_IS_PRIVATE;

export const ROUTES_DATA = [
  {
    RouteComponent: Route,
    path: '/__test_page',
    component: React.lazy(() => import('../pages/TestPage')),
    exact: true,
  },
  {
    RouteComponent: Route,
    path: ['/__registry', '/__registry/:tab'],
    component: React.lazy(() => import('../components/Panel2/RegistryPage')),
    exact: true,
  },
  {
    RouteComponent: Route,
    path: ['/__wbench', '/__wbench/:tab'],
    component: React.lazy(
      () => import('../components/Panel2/InternalBenchmarkPage')
    ),
    exact: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/',
    component: React.lazy(() => import('../pages/HomePage/HomePageRouter')),
    exact: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/login',
    component: React.lazy(() => import('../components/Login')),
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/login-redirect',
    component: React.lazy(() => import('../components/LoginRedirect')),
  },
  {
    RouteComponent: Route,
    path: '/logout',
    component: React.lazy(() => import('../components/Logout')),
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/trending',
    component: React.lazy(() => import('../pages/TrendingRepos')),
  },
  {
    RouteComponent: PrivateRoute,
    path: '/home',
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'HomePage',
    exact: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: QUICKSTART_PATH,
    component: React.lazy(() => import('../pages/HomePage/HomePageWithData')),
    routeCache: 'QuickStartPage',
  },
  {
    RouteComponent: PrivateRoute,
    path: `/${GALLERY_PATH_SEGMENT}/create-post`,
    component: React.lazy(() => import('../pages/GalleryCreatePost')),
    routeCache: 'GalleryCreatePost',
  },
  {
    RouteComponent: PrivateRoute,
    path: `/${GALLERY_PATH_SEGMENT}/create-discussion`,
    component: React.lazy(() => import('../pages/GalleryCreateDiscussion')),
    routeCache: 'GalleryCreateDiscussion',
  },
  {
    RouteComponent: RouteWithLayout,
    path: GALLERY_PATH,
    component: React.lazy(() => import('../pages/FC')),
  },
  {
    path: '/fullyconnected',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/fc',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/gallery',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/__test/simple_error',
    component: React.lazy(() => import('../pages/ErrorTestPage')),
    exact: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: ONBOARDING_FLOW_PATH,
    component: React.lazy(() => import('../pages/OnboardingFlow')),
    routeCache: 'OnboardingFlow',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/change-password',
    component: React.lazy(() => import('../pages/ChangePassword')),
    routeCache: 'ChangePassword',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/authorize',
    component: React.lazy(() => import('../pages/AuthorizePage')),
    routeCache: 'AuthorizePage',
    allowIframes: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/settings',
    component: React.lazy(
      () => import('../components/UserSettings/UserSettingsPage')
    ),
    routeCache: 'UserSettingsPage',
    exact: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: ['/billing', '/subscriptions'],
    component: React.lazy(() => import('../pages/Billing/BillingPage')),
    routeCache: 'BillingPage',
    exact: true,
    condition: !IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: ['/account-settings', '/account-setting'],
    component: React.lazy(
      () => import('../pages/Billing/AccountSettings/AccountSettingsPage')
    ),
    routeCache: 'AccountSettingsPage',
    exact: true,
    condition: !IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/usage',
    component: React.lazy(() => import('../pages/UsageAdmin')),
    routeCache: 'UsageAdmin',
    exact: true,
    condition: IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/organization',
    component: React.lazy(
      () => import('../wandb-admin/pages/OrganizationAdminPage')
    ),
    routeCache: 'OrganizationAdmin',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/users',
    component: React.lazy(() => import('../pages/UsersAdminPage')),
    routeCache: 'UsersAdmin',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/license',
    component: React.lazy(() => import('../pages/LicenseAdminPage')),
    routeCache: 'LicenseAdmin',
  },
  {
    RouteComponent: PrivateRoute,
    path: `/admin/${GALLERY_PATH_SEGMENT}`,
    component: React.lazy(() => import('../pages/GalleryAdmin')),
    routeCache: 'GalleryAdmin',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/ramp',
    component: React.lazy(() => import('../pages/RampAdmin')),
    routeCache: 'RampAdmin',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/experiments',
    component: React.lazy(
      () => import('../wandb-admin/pages/ExperimentAdminPage')
    ),
    routeCache: 'ExperimentAdminPage',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/create-team',
    component: React.lazy(() => import('../pages/CreateTeam')),
    routeCache: 'CreateTeam',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/teams/:entityName',
    component: React.lazy(() => import('../pages/TeamSettings')),
    routeCache: 'TeamPage',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/admin/home',
    component: React.lazy(() => import('../wandb-admin/pages/HomeAdminPage')),
    routeCache: 'AdminDashboard',
  },
  {
    RouteComponent: RouteWithLayout,
    path: [
      '/usage/:entityName',
      STORAGE_EXPLORER_TRACKED_PATH,
      '/usage/:entityName/:filePath*',
    ],
    component: React.lazy(() => import('../pages/StorageExplorer')),
    routeCache: 'StorageExplorer',
    exact: true,
    allowAnonymous: true,
  },
  {
    path: '/storage/:entityName/tracked/:filePath*',
    redirect: STORAGE_EXPLORER_TRACKED_PATH,
  },
  {
    path: '/storage/:entityName/:filePath*',
    redirect: '/usage/:entityName/:filePath*',
  },
  {
    path: '/storage/:entityName',
    redirect: '/usage/:entityName',
  },
  {
    RouteComponent: PrivateRoute,
    path: createProjectPage(),
    component: React.lazy(() => import('../pages/CreateProject')),
    routeCache: 'NewProject',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/new-benchmark',
    component: React.lazy(() => import('../pages/NewBenchmark')),
    routeCache: 'NewBenchmark',
  },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityname/benchmark/new',
    component: React.lazy(() => import('../pages/NewBenchmark')),
    routeCache: 'NewBenchmarkPage',
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_REPORTS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    additionalProps: {tab: 'reports'},
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_PROJECTS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    additionalProps: {tab: 'projects'},
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_LIKES_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    additionalProps: {tab: 'likes'},
  },
  {
    RouteComponent: RouteWithLayout,
    path: TEAM_MEMBERS_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    additionalProps: {tab: 'members'},
  },
  {
    RouteComponent: RouteWithLayout,
    path: ENTITY_REGISTRY_PATHS,
    component: React.lazy(() => import('../pages/EntityRegistryPage')),
    routeCache: 'EntityPage',
    exact: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: ENTITY_LAUNCH_PATHS,
    component: React.lazy(() => import('../pages/EntityLaunchPage')),
    routeCache: 'EntityPage',
    exact: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: RUN_PATHS,
    component: React.lazy(() => import('../pages/RunPage2')),
    routeCache: 'RunPage2',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: GROUP_PATHS,
    component: React.lazy(() => import('../pages/GroupPage')),
    routeCache: 'GroupPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityName/:projectName/create-sweep',
    component: React.lazy(() => import('../pages/CreateSweepPage')),
    routeCache: 'CreateSweepPage',
    allowIframes: true,
    exact: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: SWEEP_PATHS,
    component: React.lazy(() => import('../pages/SweepPage')),
    routeCache: 'SweepPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: BENCHMARK_PATHS,
    component: React.lazy(() => import('../pages/Benchmark/BenchmarkPage')),
    routeCache: 'BenchmarkPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  // {
  //   RouteComponent: PrivateRoute,
  //   path: `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}`,
  //   component: React.lazy(() => import('../pages/PublishedWorkPage')),
  //   routeCache: 'PublishedWorkPage',
  //   exact: true,
  //   allowAnonymous: true,
  // },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityName/:projectName/reports',
    component: React.lazy(() => import('../pages/ReportPageEditOld')),
    routeCache: 'ReportPageEditOld',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityName/:projectName/published',
    component: React.lazy(() => import('../pages/ReportPageViewOld')),
    routeCache: 'ReportPageViewOld',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/:entityName/:projectName/reports/:reportNameAndID/edit',
    component: React.lazy(() => import('../pages/ReportPageEdit')),
    routeCache: 'ReportPageEdit',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: REPORT_PAGE_VIEW_PATH,
    component: React.lazy(() => import('../pages/ReportPageView')),
    routeCache: 'ReportPageView',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    component: React.lazy(() => import('../pages/ReportPageView')),
    routeCache: 'ReportPageViewPublishedWork',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: GALLERY_POST_EDIT_PATH,
    component: React.lazy(() => import('../pages/ReportPageEdit')),
    routeCache: 'ReportPageEdit',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: GALLERY_POST_VIEW_PATH,
    component: React.lazy(() => import('../pages/ReportPageView')),
    routeCache: 'ReportPageView',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: GALLERY_DISCUSSION_EDIT_PATH,
    component: React.lazy(() => import('../pages/ReportPageEdit')),
    routeCache: 'ReportPageEdit',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: GALLERY_DISCUSSION_VIEW_PATH,
    component: React.lazy(() => import('../pages/ReportPageView')),
    routeCache: 'ReportPageView',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/:entityName/:projectName/querydemo',
    component: React.lazy(() => import('../pages/QueryDemoPage')),
    routeCache: 'QueryDemoPage',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: RouteWithLayout,
    path: '/:entityName/:projectName/weave-sandbox',
    component: React.lazy(() => import('../pages/WeaveSandboxPage')),
    routeCache: 'WeaveSandboxPage',
    exact: true,
    allowAnonymous: true,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/org/:orgName',
    component: React.lazy(() => import('../pages/OrganizationPage')),
    routeCache: 'OrganizationPage',
    exact: true,
    allowAnonymous: false,
    condition: !IS_ON_PREM,
  },
  {
    RouteComponent: PrivateRoute,
    path: '/repo/:repoName',
    component: React.lazy(() => import('../pages/RepoInsightsPage')),
    routeCache: 'RepoInsightsPage',
    exact: true,
    allowAnonymous: false,
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROJECT_PATHS,
    component: React.lazy(() => import('../pages/ProjectPage')),
    routeCache: 'ProjectPage',
    exact: true,
    allowIframes: true,
    allowAnonymous: true,
  },
  {
    // this is here to truncate extra path segments after the actual path
    path: PROJECT_BASE_PATH,
    redirect: PROJECT_BASE_PATH,
  },
  {
    RouteComponent: RouteWithLayout,
    path: PROFILE_PATH,
    component: React.lazy(() => import('../pages/EntityPage')),
    routeCache: 'EntityPage',
    exact: true,
    allowAnonymous: true,
  },
];
