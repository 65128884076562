import * as CG from '@wandb/cg';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {Spec as PanelExpressionSpec} from '@wandb/weave-ui/components/Panel2/PanelExpression';
import {useWeaveContext} from '@wandb/weave-ui/context';
import {RemoteEcosystemComputeGraphContextProvider} from '@wandb/weave-ui/contextProviders';
import * as _ from 'lodash';
import React, {useContext} from 'react';
import {useCallback, useMemo, useState} from 'react';
import {Resizable} from 'react-resizable';
import {Message} from 'semantic-ui-react';
import {useReadOnly} from 'slate-react';

import {RunQueryContext} from '../../state/runs/context';
import {useBetaFeatureWeavePythonEcosystem} from '../../util/useBetaFeature';
import {useWeaveInit} from '../panels.domain/weaveInit';
import {ReversibleWeaveErrorBoundary} from './ErrorBoundary';

export interface RootQueryPanelConfig {
  panelConfig: any;
  height?: number;
}

type RootQueryPanelProps = {
  fullHeight?: boolean;
  config: RootQueryPanelConfig;
  updateConfig: (newRootQueryConfig: Partial<RootQueryPanelConfig>) => void;
};

export const RootQueryPanel: React.FC<RootQueryPanelProps> = props => {
  const weaveEcosystemEnabled = useBetaFeatureWeavePythonEcosystem();
  // This is a temporary change to inform the EE that it is in a report
  // editing mode. We do this here, instead of in the EE, because the EE
  // is a generic Weave UI component and should not be aware of W&B context.
  const rq = useContext(RunQueryContext);
  const readOnlySlate = useReadOnly();
  const isEditingReport = rq?.report?.id != null && !readOnlySlate;
  // The following block should only ever be entered for panels from super early adopters of Weave
  // Python panels (before July 21, 2022) - and ONLY for internal employees. This can be safely removed
  // after a few weeks.
  if (props.config?.panelConfig?.__weaveBackendRequired__) {
    if (!isEditingReport) {
      return <></>;
    }
    if (weaveEcosystemEnabled) {
      return (
        <RemoteEcosystemComputeGraphContextProvider>
          <ConversionCTA exp={props.config?.panelConfig.exp} />
        </RemoteEcosystemComputeGraphContextProvider>
      );
    } else {
      // The following is displayed to users (possibly customers) who are viewing a report
      // which has a normal weave panel created with the weave-backend flag
      return (
        <Message
          info
          content="This panel is hidden because it uses beta features which are not currently enabled."
          size="mini"
        />
      );
    }
  }
  return (
    <ReversibleWeaveErrorBoundary
      {...props}
      innerComponent={RootQueryPanelInner}
      sourcePanel={'RootQueryPanel'}
      panelConfigKey="panelConfig"
    />
  );
};

const ConversionCTA: React.FC<{exp: any}> = ({exp}) => {
  const weave = useWeaveContext();
  const text = weave.expToString(exp);
  // The following is displayed only to those who have weave-backend enabled.
  return (
    <Message
      info
      content={`This is a normal Weave Panel which was configured with weave-backend. \
      To migrate, copy the following expression and paste it into a new Weave Python panel: ${text}`}
      size="mini"
    />
  );
};

export const RootQueryPanelInner: React.FC<RootQueryPanelProps> = props => {
  const {config, updateConfig, fullHeight} = props;

  useWeaveInit();
  const height = useMemo(() => config.height ?? 400, [config.height]);
  const [resizeHeight, setResizeHeight] = useState(height);
  const updateHeight = useCallback(
    (newHeight: number) => {
      updateConfig({height: newHeight});
    },
    [updateConfig]
  );
  // Stick path in to make dir panel work.
  // TODO: fix this to let dir panel update input instead of relying on context
  const [context, setContext] = useState<Panel2.PanelContext>({path: []});
  // TODO: any
  const updateContext = useCallback<Panel2.UpdateContext>(
    newContext => {
      setContext({...context, ...newContext});
    },
    [context]
  );

  const expressionConfig = useMemo(() => {
    if (_.isEmpty(config)) {
      return {autoFocus: true};
    }

    const oldConfig = config as any;
    if (config.panelConfig?.exp) {
      return config.panelConfig;
    } else {
      return {
        ..._.omit(config.panelConfig ?? {}, 'exp', 'panelConfig'),
        exp: oldConfig.exp,
        panelConfig: oldConfig.panelConfig,
      };
    }
  }, [config]);

  const updatePanelExpressionConfig = useCallback<any>(
    (newConfig: any) =>
      updateConfig({panelConfig: {...expressionConfig, ...newConfig}}),
    [updateConfig, expressionConfig]
  );

  const memoedInput = useMemo(() => {
    return CG.voidNode();
  }, []);

  if (fullHeight) {
    return (
      <div style={{height: '100%'}}>
        <PanelExpressionSpec.Component
          input={memoedInput as any}
          loading={false}
          configMode={false}
          context={context}
          config={expressionConfig}
          updateConfig={updatePanelExpressionConfig}
          updateContext={updateContext}
        />
      </div>
    );
  }

  return (
    <Resizable
      width={400}
      height={resizeHeight}
      onResize={(e, data) => {
        setResizeHeight(data.size.height);
      }}
      onResizeStop={() => updateHeight(resizeHeight)}>
      <div style={{height, padding: '6px'}}>
        <PanelExpressionSpec.Component
          input={memoedInput as any}
          loading={false}
          configMode={false}
          context={context}
          config={expressionConfig}
          updateConfig={updatePanelExpressionConfig}
          updateContext={updateContext}
        />
      </div>
    </Resizable>
  );
};
