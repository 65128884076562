import SliderInput from '@wandb/common/components/elements/SliderInput';
import {
  downloadElementAsPDF,
  downloadElementAsPNG,
  downloadElementAsSVG,
  ImageExportType,
} from '@wandb/common/util/panelExport';
import * as React from 'react';
import {RefObject, useCallback, useMemo, useState} from 'react';
import {Button, Input, Modal} from 'semantic-ui-react';

import {PanelType} from '../../util/panels';
import * as S from './ImageExportModal.styles';
import {PanelExportRef} from './PanelImageExportModal';

type ImageExportModalProps = {
  type: ImageExportType;
  onClose: () => void;
  panelDOMRef: RefObject<HTMLDivElement | null>;
  panelExportRef: React.MutableRefObject<PanelExportRef | undefined>;
  viewType: PanelType;
  open?: boolean;
};

const ImageExportModal: React.FunctionComponent<ImageExportModalProps> =
  React.memo(
    ({
      type,
      onClose,
      panelDOMRef,
      panelExportRef,
      viewType,
      open,
      children,
    }) => {
      const [width, setWidth] = useState(Math.min(600, window.innerWidth - 50));
      const [height, setHeight] = useState(300);
      const [exporting, setExporting] = useState(false);
      const [name, setName] = useState('');
      const defaultName = useMemo(getDefaultExportName, []);
      const nameWithDefault = name || defaultName;

      const downloadSpecial = useCallback(async (): Promise<boolean> => {
        if (panelExportRef.current == null) {
          return false;
        }

        const {onDownloadPDF, onDownloadSVG, onDownloadPNG} =
          panelExportRef.current;
        if (type === 'pdf') {
          await onDownloadPDF(nameWithDefault);
        } else if (type === 'svg') {
          await onDownloadSVG(nameWithDefault);
        } else {
          await onDownloadPNG(nameWithDefault);
        }

        return true;
      }, [type, panelExportRef, nameWithDefault]);

      const download = useCallback(async (): Promise<void> => {
        if (await downloadSpecial()) {
          return;
        }
        if (panelDOMRef.current == null) {
          return;
        }

        if (type === 'pdf') {
          await downloadElementAsPDF(panelDOMRef.current, nameWithDefault);
        } else if (type === 'svg') {
          await downloadElementAsSVG(panelDOMRef.current, nameWithDefault);
        } else {
          await downloadElementAsPNG(panelDOMRef.current, nameWithDefault);
        }
      }, [type, panelDOMRef, nameWithDefault, downloadSpecial]);

      const onDownloadButtonClick = useCallback(
        async (e: React.MouseEvent): Promise<void> => {
          window.analytics?.track('Export panel', {
            viewType,
            downloadType: type,
          });
          setExporting(true);
          await download();
          setExporting(false);
          onClose();
        },
        [type, viewType, download, onClose]
      );

      return (
        <Modal open={open} onClose={onClose}>
          <Modal.Header>Export {type.toUpperCase()}</Modal.Header>
          <Modal.Content>
            <S.Controls>
              Name:
              <Input
                style={{marginLeft: 5}}
                size="mini"
                placeholder={defaultName}
                onChange={(e, d) => setName(d.value)}
                value={name}></Input>
              <S.HorizontalSpace />
              Width:
              <SliderInput
                step={1}
                min={20}
                minLabel={'20'}
                max={1600}
                maxLabel={'1600'}
                value={width}
                onChange={v => {
                  setWidth(v);
                }}
                sliderInPopup
                hasInput
              />
              <S.HorizontalSpace />
              Height:
              <SliderInput
                sliderInPopup
                step={1}
                min={20}
                minLabel={'20'}
                max={800}
                maxLabel={'800'}
                value={height}
                onChange={v => setHeight(v)}
                hasInput
              />
              <S.HorizontalSpace />
            </S.Controls>
            <S.Preview>
              <S.PreviewInner width={width} height={height}>
                {children}
              </S.PreviewInner>
            </S.Preview>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              disabled={exporting}
              loading={exporting}
              onClick={onDownloadButtonClick}>
              Download {type.toUpperCase()}
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
  );

export default ImageExportModal;

function getDefaultExportName(): string {
  const dateString = new Date().toLocaleString('default');
  return `W&B Chart ${dateString}`;
}
