import {toast} from '@wandb/common/components/elements/Toast';
import {DateFromUTCString} from '@wandb/common/util/time';
import React, {useCallback, useState} from 'react';
import {Icon} from 'semantic-ui-react';
import {Button, Modal} from 'semantic-ui-react';

import {
  DefaultResourceConfig,
  useDeleteDefaultResourceConfigMutation,
} from '../../../generated/graphql';
import {propagateErrorsContext} from '../../../util/errors';
import * as S from './DefaultResourceConfig.styles';

interface DeleteDRCProps {
  config: DefaultResourceConfig;
  entityName?: string;
  refetch?: () => void;
}

interface DeleteDRCInnerProps extends DeleteDRCProps {
  onClose: () => void;
}

const DeleteDRCModal: React.FC<DeleteDRCProps> = props => {
  const [open, setOpen] = useState<boolean>(false);

  const onCloseHandler = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <S.DeleteButton name="delete" onClick={() => setOpen(true)} />
      {open && <DeleteDRCInnerModal onClose={onCloseHandler} {...props} />}
    </>
  );
};

const DeleteDRCInnerModal: React.FC<DeleteDRCInnerProps> = ({
  config,
  entityName,
  refetch,
  onClose,
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(true);
  const [deleteDefaultResourceConfig] = useDeleteDefaultResourceConfigMutation({
    context: propagateErrorsContext(),
  });

  const removeDRC = useCallback(async () => {
    try {
      const deleteDRCresult = await deleteDefaultResourceConfig({
        variables: {defaultResourceConfigIDs: [config.id]},
      });

      if (deleteDRCresult.errors != null) {
        toast(
          `Oops, something went wrong with removing default resource config: ${config.id} from ${entityName}`
        );
        onClose();
        return;
      }

      setConfirmDelete(false);
      // TODO(gst): propagate to runqueues that pointed to this DRC?
    } catch (err) {
      toast(
        `Oops, something went wrong when removing the default resource config`
      );
      onClose();
    }
  }, [config, entityName, deleteDefaultResourceConfig, onClose]);

  // TODO(gst): Query all Queues pointing at a specific DRC, "you sure? these queues will have to be reconfigured"

  return (
    <Modal open onClose={onClose} size="small">
      <Modal.Header>
        <Icon name="exclamation triangle" /> Danger
      </Modal.Header>
      <Modal.Content>
        {confirmDelete ? (
          <p>
            Are you sure you want to remove the configuration for the resource:{' '}
            <S.Bold>{config.resource ?? config?.scope.Type}</S.Bold> created at{' '}
            <S.Bold>
              {DateFromUTCString(config.createdAt).toLocaleString()}
            </S.Bold>
            ?
          </p>
        ) : (
          <>
            You have successfully removed <S.Bold>{config?.resource}</S.Bold>{' '}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {confirmDelete ? (
          <S.ButtonWrapper>
            <Button onClick={onClose} content="Cancel" />
            <Button onClick={removeDRC} content="Yes" color="red" />
          </S.ButtonWrapper>
        ) : (
          <Button
            content="Close"
            onClick={() => {
              onClose();
              refetch?.();
            }}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteDRCModal;
