import {WBIcon} from '@wandb/ui';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {Feed, Pagination} from 'semantic-ui-react';

import * as Generated from '../generated/graphql';
import {useEntityActionHistoryLimit} from '../util/modelRegistryRestrictions';
import * as Urls from '../util/urls';
import {InstrumentedLoader as Loader} from './../components/utility/InstrumentedLoader';
import * as S from './ArtifactActionHistory.styles';

interface ArtifactActionHistoryLinksProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  pageSize: number;
  membershipNoun: string;
}

interface ArtifactActionIconProps {
  actionType: string;
}

enum MembershipActionType {
  INSERT = 'insert_membership',
  DELETE = 'delete_membership',
}

const ActionIcon: React.FC<ArtifactActionIconProps> = props => {
  const {actionType} = props;
  if (actionType === MembershipActionType.INSERT) {
    return <WBIcon name="plus" style={{fontSize: '1em'}} />;
  } else if (actionType === MembershipActionType.DELETE) {
    return <WBIcon name="delete" />;
  } else {
    return <WBIcon name="file" />;
  }
};

type ActionInfo = {
  entityName: string;
  actionType: string;
  user: string;
  artifactTypeName: string;
  artifactVersion?: {
    artifactSequence: {
      projectName: string;
      name: string;
    };
    versionIndex: number | null | undefined;
  };
  createdAt: string;
};

interface ArtifactActionMessageProps {
  actionInfo: ActionInfo;
}

const ActionMessage: React.FC<ArtifactActionMessageProps> = props => {
  const {
    entityName,
    actionType,
    user,
    artifactTypeName,
    artifactVersion,
    createdAt,
  } = props.actionInfo;
  const verbs: {[key: string]: string} = {
    insert_membership: 'added',
    delete_membership: 'removed',
  };
  const artifactUrl = Urls.artifact({
    entityName,
    projectName: artifactVersion?.artifactSequence.projectName ?? '',
    artifactTypeName,
    artifactCollectionName: artifactVersion?.artifactSequence.name ?? '',
    artifactCommitHash: 'v' + artifactVersion?.versionIndex,
  });

  const userUrl = `/${user}`;
  return (
    <>
      <S.UserTime>
        <Link to={userUrl}>{user}</Link>
        <S.Dot>&#183;</S.Dot>
        <S.CreatedAt>
          <TimeAgo date={createdAt} />
        </S.CreatedAt>
      </S.UserTime>
      <S.Message>
        <S.MessageItems>
          {verbs[actionType] + ' '}
          {artifactVersion == null ? (
            <>Private Artifact</>
          ) : (
            <Link to={artifactUrl} style={{marginLeft: '3px'}}>
              {'  ' +
                artifactVersion?.artifactSequence.name +
                ':v' +
                artifactVersion?.versionIndex}
            </Link>
          )}
          .
        </S.MessageItems>
      </S.Message>
    </>
  );
};

interface ArtifactActionEventProps {
  event: ActionInfo;
}

const ActionEvent: React.FC<ArtifactActionEventProps> = ({event}) => {
  return (
    <S.FeedEvent>
      <S.LabelWrapper>
        <Feed.Label>
          <S.IconWrapper>
            <ActionIcon actionType={event.actionType} />
          </S.IconWrapper>
        </Feed.Label>
      </S.LabelWrapper>

      <S.FeedContent>
        <Feed.Summary>
          <ActionMessage actionInfo={event} />
        </Feed.Summary>
      </S.FeedContent>
    </S.FeedEvent>
  );
};

const ArtifactActionHistoryLinks: React.FC<ArtifactActionHistoryLinksProps> = ({
  entityName,
  projectName,
  artifactTypeName,
  artifactCollectionName,
  pageSize,
  membershipNoun,
}) => {
  const [offset, setOffset] = useState(1);
  const {data, loading} = Generated.useArtifactCollectionActionHistoryQuery({
    variables: {
      entityName,
      projectName,
      artifactTypeName,
      artifactCollectionName,
    },
  });
  const historyLimitQuery = useEntityActionHistoryLimit(
    entityName,
    data?.project?.artifactType?.artifactCollection?.id
  );

  const globalLinkActions = React.useMemo(() => {
    const actions =
      data?.project?.artifactType?.artifactCollection?.artifactCollectionActions
        ?.edges ?? [];

    if (historyLimitQuery.loading) {
      return [];
    }

    return actions
      .filter(
        n =>
          n.node.actionType === 'insert_membership' ||
          n.node.actionType === 'delete_membership'
      )
      .slice(0, historyLimitQuery.result)
      .map(n => {
        return {
          entityName,
          artifactTypeName,
          actionType: n.node.actionType,
          user: n.node.user?.username ?? '',
          artifactVersion: n.node.artifact
            ? {
                artifactSequence: {
                  name: n.node.artifact.artifactSequence.name,
                  projectName: n.node.artifact.artifactSequence.project.name,
                },
                versionIndex: n.node.artifact?.versionIndex,
              }
            : undefined,
          createdAt: n.node.createdAt + 'Z',
        };
      });
  }, [
    data,
    historyLimitQuery.loading,
    historyLimitQuery.result,
    entityName,
    artifactTypeName,
  ]);

  const membershipText =
    membershipNoun[0].toUpperCase() + membershipNoun.slice(1) + 's';

  if (loading) {
    return <Loader name={'artifact-action-history'} />;
  }
  return globalLinkActions.length === 0 ? (
    <S.ArtifactActionsContainer />
  ) : (
    <S.ArtifactLinkActionsWrapper>
      <S.Header>
        <S.HeaderText>{membershipText}</S.HeaderText>
      </S.Header>
      <S.RowsContainer>
        {globalLinkActions.map(a => (
          <ActionEvent
            event={a}
            key={
              a.artifactVersion?.artifactSequence.name +
              a.actionType +
              a.createdAt
            }
          />
        ))}
      </S.RowsContainer>
      <Pagination
        style={{marginTop: '1em'}}
        activePage={offset}
        onPageChange={(e, value) => {
          setOffset(value.activePage as number);
        }}
        size="mini"
        totalPages={Math.trunc(globalLinkActions.length / pageSize) + 1}
      />
    </S.ArtifactLinkActionsWrapper>
  );
};

export default ArtifactActionHistoryLinks;
