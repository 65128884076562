import React, {FC, useContext} from 'react';
import {Image} from 'semantic-ui-react';

import {MagicLinkSection} from './MagicLinkSection';
import {NormalLinkSection} from './NormalLinkSection';
import {SharedWithIndividualsSection} from './SharedWithIndividualsSection';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';

export const PersonalContent: FC = () => {
  const {canChangeSharingSettings, isPrivateAccess, viewer} = useContext(
    ShareReportTriggerContext
  );

  if (isPrivateAccess) {
    return (
      <>
        <S.RowWrapper>
          <S.IconTextWrapper>
            <Image src={viewer?.photoUrl} avatar />
            &nbsp;{`${viewer?.name} (you)`}
          </S.IconTextWrapper>
          <S.SimpleSmallTextWrapper>owner</S.SimpleSmallTextWrapper>
        </S.RowWrapper>
        {canChangeSharingSettings && <SharedWithIndividualsSection />}
        <MagicLinkSection />
      </>
    );
  }

  return <NormalLinkSection />;
};
