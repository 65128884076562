import {ApolloQueryResult} from 'apollo-client';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {
  CreateAlertPayload,
  CreateGitHubIntegrationMutationVariables,
  CreateGitHubOAuthIntegrationInput,
  CreateIntegrationPayload,
  CreateSlackIntegrationInput,
  CreateSlackIntegrationMutationVariables,
} from '../generated/graphql';

export const CREATE_SLACK_INTEGRATION = gql`
  mutation CreateSlackIntegration(
    $code: String!
    $entityName: String!
    $redirectURI: String!
  ) {
    createSlackIntegration(
      input: {code: $code, entityName: $entityName, redirectURI: $redirectURI}
    ) {
      integration {
        id
      }
    }
  }
`;

export const CREATE_GITHUB_INTEGRATION = gql`
  mutation CreateGitHubIntegration(
    $code: String!
    $entityName: String!
    $redirectURI: String!
    $state: String!
  ) {
    createGitHubOAuthIntegration(
      input: {
        code: $code
        entityName: $entityName
        state: $state
        redirectURI: $redirectURI
      }
    ) {
      integration {
        id
      }
    }
  }
`;

export type CreateSlackIntegrationMutationFn = (
  variables: CreateSlackIntegrationMutationVariables
) => Promise<ApolloQueryResult<CreateAlertPayload>>;

export const createSlackIntegrationMutation = graphql<
  {},
  CreateSlackIntegrationInput,
  CreateSlackIntegrationMutationVariables,
  {}
>(CREATE_SLACK_INTEGRATION, {
  props: ({mutate}) => ({
    createSlackIntegration: (
      variables: CreateSlackIntegrationMutationVariables
    ) => mutate!({variables}),
  }),
});

export const createGitHubIntegrationMutation = graphql<
  {},
  CreateGitHubOAuthIntegrationInput,
  CreateGitHubIntegrationMutationVariables,
  {}
>(CREATE_GITHUB_INTEGRATION, {
  props: ({mutate}) => ({
    createGitHubIntegration: (
      variables: CreateGitHubIntegrationMutationVariables
    ) => mutate!({variables}),
  }),
});

export type CreateGitHubIntegrationMutationFn = (
  variables: CreateGitHubOAuthIntegrationInput
) => Promise<ApolloQueryResult<CreateIntegrationPayload>>;

export const DELETE_INTEGRATION = gql`
  mutation DeleteIntegration($id: ID!) {
    deleteIntegration(input: {id: $id}) {
      success
    }
  }
`;
