import * as _ from 'lodash';
import React, {useCallback} from 'react';
import {
  ChildPanelFullConfig,
  ChildPanelConfig,
  getFullChildPanel,
  childPanelFromTableState,
} from '../Panel2/ChildPanel';
import styled from 'styled-components';
import {Icon, Button, Menu} from 'semantic-ui-react';
import {getPanelStacksForType} from '../Panel2/availablePanels';
import produce from 'immer';

const OutlineItem = styled.div``;

const OutlineItemTitle = styled.div<{selected: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  border: ${props => (props.selected ? '1px solid blue' : 'none')};

  &:hover {
    background-color: #ebfbff;
  }
`;

const OutlineItemChildren = styled.div`
  padding-left: 8px;
  // border-left: 1px solid #eee;
`;

const panelChildren = (
  panelId: string,
  panel: ChildPanelFullConfig
): {[key: string]: ChildPanelFullConfig} | null => {
  let items: {[key: string]: ChildPanelConfig};
  // TODO: This should not need to be hardcoded but we don't
  // have panel config types available in the WeaveJS right now
  if (panelId === 'Group2') {
    items = panel.config?.items ?? null;
  } else if (panelId === 'LabeledItem') {
    items = {item: getFullChildPanel(panel.config?.item ?? null)};
  } else if (panelId === 'Facet') {
    items = {
      cell: childPanelFromTableState(
        panel.config.table,
        panel.config.dims.select
      ),
    };
  } else {
    return null;
  }
  return _.mapValues(items, (childPanel: ChildPanelConfig) =>
    getFullChildPanel(childPanel)
  );
};

const TitleContent = styled.div`
  width: 100%;
  height: 30px;

  & .ui.button {
    display: none;
  }

  &:hover .ui.button {
    display: inherit;
  }
`;

const ControlButton = styled(Button)`
  margin-right: -5px !important;
  padding: 5px !important;
  background: none !important;
  border: none !important;

  & > i {
    margin: 0 !important;
  }

  &:hover i {
    color: #56acfc !important;
  }
`;

export type OutlinePanelProps = OutlineProps & {
  name: string;
  localConfig: ChildPanelFullConfig;
  path: string[];
};

const OutlinePanel: React.FC<OutlinePanelProps> = props => {
  const {name, localConfig, selected, setSelected, path, config, updateConfig} =
    props;
  const [expanded, setExpanded] = React.useState(true);
  const curPanelId = getPanelStacksForType(
    localConfig?.input_node?.type ?? 'invalid',
    localConfig?.id
  ).curPanelId;
  const children = panelChildren(curPanelId!, localConfig); // TODO: curPanelId!

  const isSelected = path.join('.') === selected.join('.');
  // We need to derive the panel ID in case input type doesn't match
  // panel.id

  const handleDelete = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();

      updateConfig(
        produce(config, draft => {
          let cursor: any = draft;
          const remainingPath = path;
          while (remainingPath.length > 1) {
            const childPath = remainingPath.shift()!; // We'll always have an element to shift off here
            if (cursor.id === 'Group2') {
              cursor = cursor.config.items[childPath];
            } else if (cursor.id === 'LabeledItem') {
              cursor = cursor.config[childPath];
            } else {
              throw new Error(
                `Outline delete failed: Cannot traverse config for panel id ${cursor.id}`
              );
            }
          }
          const lastStep = remainingPath.shift() as string;
          if (cursor.id === 'Group2') {
            delete cursor.config.items[lastStep];
          } else if (cursor.id === 'LabeledItem') {
            delete cursor.config[lastStep];
          } else {
            throw new Error(
              `Outline delete failed: Cannot traverse config for panel id ${cursor.id} (path = ${lastStep})`
            );
          }
        })
      );
    },
    [path, config, updateConfig]
  );

  const content = (
    <TitleContent>
      <Menu
        borderless
        style={{
          border: 'none',
          boxShadow: 'none',
          padding: '5px',
          minHeight: '20px',
          background: 'none',
        }}>
        <Menu.Menu style={{flex: '1 1 auto', height: '20px'}}>
          <Menu.Item style={{padding: 0, height: '20px'}}>
            <strong>{name}</strong>
            <span
              style={{marginLeft: '5px', color: '#bbb', fontStyle: 'italic'}}>
              {curPanelId}
            </span>
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position="right" style={{flex: '0 0 auto', height: '20px'}}>
          {path.length > 0 ? (
            <Menu.Item style={{padding: 0, height: '20px'}}>
              <ControlButton onClick={handleDelete}>
                <Icon name="delete" size="small" />
              </ControlButton>
            </Menu.Item>
          ) : null}
          {/* <Menu.Item style={{padding: 0, height: '20px'}}>
            <ControlButton onClick={handleRename}>
              <Icon name="pencil alternate" size="small" />
            </ControlButton>
          </Menu.Item> */}
        </Menu.Menu>
      </Menu>
    </TitleContent>
  );

  return children == null ? (
    <OutlineItem>
      <OutlineItemTitle
        onClick={() => {
          setSelected(path);
          if (isSelected || !expanded) {
            setExpanded(!expanded);
          }
        }}
        selected={isSelected}>
        <Icon size="mini" name="chart bar" />
        {content}
      </OutlineItemTitle>
    </OutlineItem>
  ) : (
    <OutlineItem>
      <OutlineItemTitle
        onClick={() => {
          // TODO: DRY
          setSelected(path);
          if (isSelected || !expanded) {
            setExpanded(!expanded);
          }
        }}
        selected={isSelected}>
        <Icon size="mini" name={expanded ? 'chevron down' : 'chevron right'} />
        {content}
      </OutlineItemTitle>
      {expanded && (
        <OutlineItemChildren>
          {_.map(children, (conf, key) => (
            <OutlinePanel
              key={key}
              name={key}
              // root config is passed all the way down so we can operate on the whole thing
              config={props.config}
              localConfig={conf}
              updateConfig={props.updateConfig}
              selected={selected}
              setSelected={setSelected}
              path={[...path, key]}
            />
          ))}
        </OutlineItemChildren>
      )}
    </OutlineItem>
  );
};

export interface OutlineProps {
  config: ChildPanelFullConfig;
  updateConfig: (newConfig: ChildPanelFullConfig) => void;
  selected: string[];
  setSelected: (path: string[]) => void;
}

export const Outline: React.FC<OutlineProps> = props => {
  return (
    <OutlinePanel
      name="root"
      // root config is passed all the way down so we can operate on the whole thing
      config={props.config}
      updateConfig={props.updateConfig}
      localConfig={props.config}
      selected={props.selected}
      setSelected={props.setSelected}
      path={[]}
    />
  );
};
