import React from 'react';
import {useMemo} from 'react';
import * as Panel2 from './panel';
import {useCallback} from 'react';
import styled from 'styled-components';
import {ChildPanelConfig, ChildPanel} from './ChildPanel';
import * as CGReact from '../../cgreact';
import {PanelContextProvider} from './PanelContext';
import * as CG from '@wandb/cg';
import {useWeaveContext} from '../../context';
import {Panel2Loader} from './PanelComp';

interface PanelEachConfig {
  x: CG.NodeOrVoidNode;
  y: CG.NodeOrVoidNode;
  w: CG.NodeOrVoidNode;
  h: CG.NodeOrVoidNode;
  // OK this is where the Weave model is missing something!
  // ChildPanelConfig actually _must_ be a function that accepts an item
  // from our input list as the first argument. But we can't express that
  // at the moment.
  // Yup, this is where Weave breaks down. We need to include Config Type
  // in a Panel's entire input type.
  // Then you can have a configured PanelEach that has a very specificy type...
  render: ChildPanelConfig;
}

const PANEL_EACH_DEFAULT_CONFIG: PanelEachConfig = {
  x: CG.voidNode(),
  y: CG.voidNode(),
  w: CG.voidNode(),
  h: CG.voidNode(),
  render: CG.voidNode(),
};

const inputType = {type: 'list' as const, objectType: 'any' as const};

type PanelEachProps = Panel2.PanelProps<typeof inputType, PanelEachConfig>;

export const Each = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  // align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
`;

export const EachItem = styled.div<{
  position?: 'absolute' | 'static';
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}>`
  // flex-grow: 1;
  position: ${props => props.position ?? 'static'};
  top: ${props => (props.top != null ? props.top + 'px' : undefined)};
  left: ${props => (props.left != null ? props.left + 'px' : undefined)};
  width: ${props => (props.width != null ? props.width + 'px' : '50px')};
  height: ${props => (props.height != null ? props.height + 'px' : '50px')};
`;

export const PanelEach: React.FC<PanelEachProps> = props => {
  const weave = useWeaveContext();
  const config = props.config ?? PANEL_EACH_DEFAULT_CONFIG;
  const {updateConfig} = props;
  const updateChildPanelConfig = useCallback(
    newItemConfig =>
      updateConfig({
        ...config,
        render: newItemConfig, // Don't splat with ...config.item! ChildPanel always sends full config, and sometimes restructures its shape
      }),

    [config, updateConfig]
  );
  const layoutNode = useMemo(() => {
    const applyDict: {[key: string]: CG.NodeOrVoidNode} = {};
    if (CG.isAssignableTo(config.x.type, 'number')) {
      applyDict.x = config.x;
    }
    if (CG.isAssignableTo(config.y.type, 'number')) {
      applyDict.y = config.y;
    }
    if (CG.isAssignableTo(config.w.type, 'number')) {
      applyDict.w = config.w;
    }
    if (CG.isAssignableTo(config.h.type, 'number')) {
      applyDict.h = config.h;
    }
    if (Object.keys(applyDict).length === 0) {
      return CG.voidNode();
    }
    return CG.opMap({
      arr: props.input,
      mapFn: CG.constFunction({row: props.input.type.objectType}, ({row}) =>
        CG.opDict(applyDict as any)
      ),
    });
  }, [config.h, config.w, config.x, config.y, props.input]);
  console.log('LAYOUT NODE', weave.expToString(layoutNode));
  const layout = CGReact.useNodeValue(layoutNode);
  console.log('LAYOUT', layout);

  const rowNodesUse = CGReact.useEach(props.input);
  if (layout.loading || rowNodesUse.loading) {
    return <Panel2Loader />;
  }

  return (
    <Each>
      {/* We want like CGreact.map(item) */}
      {rowNodesUse.result.map((item, i) => {
        let top;
        let left;
        let width;
        let height;
        let position: 'static' | 'absolute' = 'static';
        if (layoutNode.nodeType !== 'void') {
          position = 'absolute';
          top = layout.result[i].y;
          left = layout.result[i].x;
          width = layout.result[i].w;
          height = layout.result[i].h;
        }
        return (
          <EachItem
            key={i}
            position={position}
            top={top}
            left={left}
            width={width}
            height={height}>
            {/* Still have to hardcode the variable name due to Weave limitations! */}
            <PanelContextProvider newVars={{row: item}}>
              <ChildPanel
                key={i}
                config={config.render}
                updateConfig={updateChildPanelConfig}
              />
            </PanelContextProvider>
          </EachItem>
        );
      })}
    </Each>
  );
};

export const Spec: Panel2.PanelSpec = {
  id: 'Each',
  Component: PanelEach,
  inputType,
  hidden: true,
};
