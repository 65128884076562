import config, {backendHost} from '@wandb/common/config';
import {Nullable} from '@wandb/common/util/types';
import gql from 'graphql-tag';
import React from 'react';

import {apolloClient} from '../apolloClient';
import {propagateErrorsContext} from './errors';

type HeadersType = Record<string, string>;

const maybeAddAzureHeader = (headers: HeadersType, url: string) => {
  /* Azure needs an extra header, this is what's in files.uploadHeaders.  We
  assume we can just check for a windows.net domain.  TODO: can we assume this?
  */
  const parser = document.createElement('a');
  parser.href = url;
  if (parser.hostname.match(/windows.net$/)) {
    headers['x-ms-blob-type'] = 'BlockBlob';
  }
  return headers;
};

const parseAndAddHeaders = (
  existingHeaders: HeadersType,
  headersArray: string[]
) => {
  headersArray.forEach(header => {
    const [key, ...values] = header.split(':');
    if (key.length) {
      existingHeaders[key] = values.join(':');
    }
  });
  return existingHeaders;
};

export async function publicImageUpload(image: Blob): Promise<string> {
  const {uploadUrl, imageUrl, uploadHeaders} = (
    await apolloClient.query({
      query: gql`
        query PublicImageUploadURL {
          publicImageUploadUrl {
            uploadUrl
            imageUrl
            uploadHeaders
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
  ).data.publicImageUploadUrl;
  const headers = maybeAddAzureHeader({'Content-Type': 'image/png'}, uploadUrl);
  // See https://cloud.google.com/storage/docs/xml-api/reference-headers#xgoogacl
  headers['x-goog-acl'] = 'public-read';
  if (uploadHeaders) {
    parseAndAddHeaders(headers, uploadHeaders);
  }

  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return imageUrl;
}

export const panelImageUpload = async (
  image: Blob,
  entityName: string,
  title: string,
  description: string,
  panelID: string,
  viewID: string,
  redirectURL: string,
  author: string
) => {
  const {panelLink, panelImageUploadUrl} =
    (
      await apolloClient.mutate({
        context: propagateErrorsContext(),
        mutation: gql`
          mutation UploadPanel(
            $entityName: String!
            $title: String!
            $description: String!
            $panelID: String!
            $viewID: ID!
            $redirectURL: String!
            $author: String!
          ) {
            uploadPanel(
              input: {
                entityName: $entityName
                title: $title
                description: $description
                panelID: $panelID
                viewID: $viewID
                redirectURL: $redirectURL
                author: $author
              }
            ) {
              panelLink
              panelImageUploadUrl
            }
          }
        `,
        variables: {
          entityName,
          title,
          description,
          panelID,
          viewID,
          redirectURL,
          author,
        },
      })
    )?.data?.uploadPanel ?? {};

  if (panelLink == null || panelImageUploadUrl == null) {
    throw new Error('Unable to upload panel');
  }

  const headers: Record<string, string> = {
    'Content-Type': 'image/png',
    'x-goog-acl': 'public-read',
  };
  // eslint-disable-next-line wandb/no-unprefixed-urls
  await fetch(panelImageUploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });

  return panelLink as string;
};

export const profileImageUpload = async (image: Blob) => {
  const viewer = (
    await apolloClient.query({
      query: gql`
        query ViewerUploadUrl {
          viewer {
            photoUploadUrl
            uploadHeaders
            username
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
  ).data.viewer;
  const uploadUrl = viewer.photoUploadUrl;
  const updatedUrl = `${viewer.username}/profile.png`;
  let headers: HeadersType = {'Content-Type': 'image/png'};
  headers = maybeAddAzureHeader(headers, uploadUrl);
  if (viewer.uploadHeaders?.length) {
    headers = parseAndAddHeaders(headers, viewer.uploadHeaders);
  }

  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return updatedUrl;
};

export const entityImageUpload = async (entityName: string, image: Blob) => {
  const entity = (
    await apolloClient.query({
      query: gql`
        query EntityUploadUrl($entityName: String!) {
          entity(name: $entityName) {
            photoUploadUrl
            uploadHeaders
          }
        }
      `,
      variables: {entityName},
      fetchPolicy: 'no-cache',
    })
  ).data.entity;
  const uploadUrl = entity.photoUploadUrl;
  const updatedUrl = `${entityName}/profile.png`;
  let headers: HeadersType = {'Content-Type': 'image/png'};
  headers = maybeAddAzureHeader(headers, uploadUrl);
  if (entity.uploadHeaders?.length) {
    headers = parseAndAddHeaders(headers, entity.uploadHeaders);
  }

  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return updatedUrl;
};

export const viewImageUpload = async (
  image: Blob,
  name: string,
  id: string,
  mimeType: string,
  publicImg?: boolean
) => {
  const view = (
    await apolloClient.query({
      query: gql`
        query ViewUploadUrl($id: ID!, $name: String!) {
          view(id: $id) {
            imageUploadUrl(name: $name) {
              url
              publicUrl
              path
            }
            uploadHeaders
          }
        }
      `,
      variables: {
        id,
        name,
      },
      fetchPolicy: 'no-cache',
    })
  ).data.view;
  const {
    uploadHeaders,
    imageUploadUrl: {url, publicUrl, path},
  } = view;
  const privateImg =
    !publicImg || publicUrl === '' || config.ENVIRONMENT_IS_PRIVATE;
  const uploadUrl = privateImg ? url : publicUrl;
  const updatedUrl = path;
  let headers = maybeAddAzureHeader({'Content-Type': mimeType}, uploadUrl);
  if (uploadHeaders?.length) {
    headers = parseAndAddHeaders(headers, uploadHeaders);
  }
  if (!privateImg) {
    // See https://cloud.google.com/storage/docs/xml-api/reference-headers#xgoogacl
    headers['x-goog-acl'] = 'public-read';
  }
  await fetch(uploadUrl, {
    method: 'PUT',
    headers,
    body: image,
  });
  return updatedUrl;
};

export const squareCrop = (img: HTMLImageElement, size: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const aspectRatio = img.width / img.height;
  let renderableWidth = size;
  let renderableHeight = size;
  let xStart = 0;
  let yStart = 0;
  if (aspectRatio > 1) {
    renderableWidth = img.width * (size / img.height);
    xStart = (size - renderableWidth) / 2;
  } else if (aspectRatio < 1) {
    renderableHeight = img.height * (size / img.width);
    yStart = (size - renderableHeight) / 2;
  }

  // TODO: handle non-center cropping?
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, xStart, yStart, renderableWidth, renderableHeight);
  }
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => (blob ? resolve(blob) : reject('Unable to resize image')),
      'image/png'
    );
  });
};

export const ensureMaxSize = (img: HTMLImageElement, maxSize: number) => {
  const canvas = document.createElement('canvas');
  let width = img.width;
  let height = img.height;
  let ratio = 1;
  if (img.width > maxSize && img.width > img.height) {
    ratio = img.width / img.height;
    height = maxSize / ratio;
    width = maxSize;
  } else if (img.height > maxSize && img.height > img.width) {
    ratio = img.height / img.width;
    width = maxSize / ratio;
    height = maxSize;
  }
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height);
  }
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      blob => (blob ? resolve(blob) : reject('Unable to resize image')),
      'image/png'
    );
  });
};

const MARKDOWN_IMAGE_MAX_SIZE = 2048;

export interface UploadState {
  uploading: boolean;
  name: string;
  url?: string;
  error?: string;
}

export function useUploadMarkdownImage(
  viewId: string | null,
  publicImg = false
) {
  const [uploadState, setUploadState] = React.useState<UploadState>();

  const upload = React.useCallback(
    async (image: Blob, name: string): Promise<string | void> => {
      if (viewId == null) {
        return;
      }

      try {
        let ext = '.png';
        if (image.type === 'image/gif') {
          ext = '.gif';
        }
        const uniqueName =
          (Math.random().toString(16) + '000000000').substr(2, 8) + ext;
        const path = await viewImageUpload(
          image,
          uniqueName,
          viewId,
          image.type,
          publicImg
        );
        // Local can use relative urls, prod needs absolute
        const host = config.ENVIRONMENT_NAME === 'local' ? '' : backendHost();
        const downloadUrl = host + path;
        setUploadState({
          url: downloadUrl,
          uploading: false,
          name,
        });
        return downloadUrl;
      } catch (error) {
        console.error(error);
        setUploadState({
          error: 'An error occurred trying to upload image.',
          uploading: false,
          name,
        });
      }
    },
    [setUploadState, viewId, publicImg]
  );

  const uploadImages = React.useCallback(
    (files: File[]) => {
      if (viewId == null) {
        console.warn('Attempted to upload an image outside of a view context.');
        return Promise.resolve();
      }
      return Promise.all(
        files.map(file => {
          if (file.type === 'image/gif') {
            // TODO: prevent MASSIVE gifs?
            setUploadState({
              uploading: true,
              name: file.name,
            });
            return upload(file, file.name);
          }
          if (file instanceof Blob) {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                const origImg = new Image();
                if (reader.result) {
                  origImg.src = reader.result.toString();
                  origImg.onload = () => {
                    setUploadState({
                      uploading: true,
                      name: file.name,
                    });
                    ensureMaxSize(origImg, MARKDOWN_IMAGE_MAX_SIZE)
                      .then(blob => upload(blob, file.name))
                      .then(resolve)
                      .catch(error => {
                        setUploadState({
                          uploading: false,
                          name: file.name,
                          error,
                        });
                        reject(error);
                      });
                  };
                }
              };
              reader.readAsDataURL(file);
            });
          }
          return Promise.resolve();
        })
      );
    },
    [upload, viewId]
  );

  return {uploadImages, uploadState, setUploadState};
}

export function imgSrcWithDefault(
  imgSrc: Nullable<string>,
  defaultSrc = `/logo.png`
): string {
  return imgSrc || defaultSrc;
}
