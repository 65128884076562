import styled from 'styled-components';

import {GLOBAL_COLORS} from '../util/colors';

export const Wrapper = styled.div<{collapsed: boolean}>`
  height: 100vh;
  width: ${props => (props.collapsed ? 0 : 280)}px;
  background: white;
  position: sticky;
  top: 0;
  border-left: 1px solid ${GLOBAL_COLORS.outline.toString()};
  z-index: 100;
`;

export const Title = styled.div`
  padding: 10px 16px;
  margin-bottom: 4px;
  border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
`;

export const PropertyEditorWrapper = styled.div`
  padding: 6px 16px;
`;

export const InspectorPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const InspectorPropertyLabel = styled.div`
  width: 100px;
  color: #888;
`;
