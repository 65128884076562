import {Severity} from '@sentry/types';
import {backendHost} from '@wandb/common/config';
import {getCookie} from '@wandb/common/util/cookie';
import {captureError} from '@wandb/common/util/integrations';
import {ApolloLink, Observable, Operation} from 'apollo-link';
import {onError} from 'apollo-link-error';
import {createHttpLink} from 'apollo-link-http';
import {RetryLink} from 'apollo-link-retry';
import * as queryString from 'query-string';
import {Dispatch, Store} from 'redux';

import {displayError, displayErrorPortal} from '../state/global/actions';
import {getPerfTimingLink} from './apollo';
import {isOnReportView} from './url';

// These global vars are set by `createCircularDependencies` below :P
// There should definitely be a better way of doing this.
let dispatch: Dispatch;
// auth is from auth.js and is currently untyped
let auth: any = null;

const apolloEndpoint = `${backendHost()}/graphql2`;

const httpLink = createHttpLink({
  uri: apolloEndpoint,
  // Our credentials may be a cookie on a different domain to backend
  // `api.wandb.ai` vs `wandb.ai`
  credentials: 'include',
});

// Adds a header specifying that an admin wants to use their
// admin privileges based on a cookie that they
// can manually toggle.
const useAdminPrivilegesMiddleware = new ApolloLink((operation, forward) => {
  const useAdminPrivileges = getCookie('use_admin_privileges') === 'true';
  if (useAdminPrivileges) {
    setHeader(operation, 'use-admin-privileges', 'true');
  }
  return forward!(operation);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const safeForward = forward!;
  return new Observable(observer => {
    auth.ensureCurrentIdToken().then((t: string | null) => {
      // The signup flow accepts a token
      const qs = queryString.parse(window.location.search);
      const token = qs.token || t;
      const apiKey = qs.apiKey || localStorage.getItem('wandb_anon_api_key');
      const accessToken = qs.accessToken;

      setHeader(operation, 'X-Origin', window.location.origin);

      if (token) {
        setHeader(operation, 'authorization', `Bearer ${token}`);
      }

      if (apiKey) {
        // The user is using an API key instead of a JWT to authenticate.
        localStorage.setItem('wandb_anon_api_key', apiKey as string);

        setHeader(
          operation,
          'X-Wandb-Anonymous-Auth-Id',
          btoa(apiKey as string)
        );
      }

      // If the query string contains the API key, scrub it so that users
      // don't accidentally share links containing their key.
      if (qs.apiKey) {
        const newQueryString = {...qs};
        delete newQueryString.apiKey;
        window.location.search = queryString.stringify(newQueryString);
      }

      if (accessToken && isOnReportView()) {
        setHeader(operation, 'access-token', accessToken as string);
      }

      safeForward(operation).subscribe(observer);
    });
  });
});

const stackdriverMiddleware = new ApolloLink((operation, forward) => {
  const safeForward = forward!;
  const qs = queryString.parse(window.location.search);

  if (qs.trace) {
    console.log('DOING TRACE');
    const requestCountString = localStorage.getItem('request_count');
    const count =
      requestCountString != null ? parseInt(requestCountString, 10) : 0;
    operation.setContext(({headers = {}}: {headers: any}) => ({
      headers: {
        ...headers,
        'X-Cloud-Trace-Context':
          localStorage.getItem('page_id') + '/' + count + ';o=1',
      },
    }));
    localStorage.setItem('request_count', (count + 1).toString());
  }

  return safeForward(operation);
});

const errorLink = onError(
  ({operation, response, networkError, graphQLErrors}) => {
    // NOTE: Don't accidentally return something from this function! You'll
    // potentially get an error that says "retriedResult.subscribe is not a
    // function" because you're not returning an observable.

    console.log({operation, response, networkError, graphQLErrors});

    // Regardless of propagateErrors, we need to bubble up 401's so the frontend
    // can prompt us to login.
    if (networkError && (networkError as any).statusCode === 401) {
      dispatch(displayErrorPortal('Session expired. Forcing login...'));
      dispatch(
        displayError({
          code: 401,
          message: networkError.message,
        })
      );
      return;
    }

    // When propagateErrors is set on the context, the query issuer wants to
    // handle errors manually so abort processing.
    if (Boolean(operation.getContext().propagateErrors)) {
      return;
    }

    if (graphQLErrors) {
      // Capture GraphQL errors
      graphQLErrors.forEach(({message, locations, path}) => {
        captureError(`[GraphQL Error] ${message}`, 'apollo_graphql_error', {
          level: Severity.Info,
          extra: {
            operation: operation.operationName,
            path,
            locations,
          },
        });
      });
      return;
    }
  }
);

// Note apollo is a POS and doesn't consistently return the error object.
// We check for the case where error.statusCode is not present, which typically
// means a request failed in a retryable way (for us).
// https://github.com/apollographql/apollo-link/issues/300
const retryLink = new RetryLink({
  // This setup will retry for
  //   sum_i:0-6(200*2**i) = ~25s (but with jitter it can be between 0 and 50s)
  delay: {
    initial: 200,
    max: 100000,
    jitter: true,
  },
  attempts: {
    max: 7,
    retryIf: (error, operation) => {
      if (operation.getContext().doNotRetry) {
        return false;
      }

      // Only retry internal errors
      return (
        // TODO(adrian): We should probably not be retrying 500s.
        // It's safer for us to aggressively retry like this but it
        // causes lots of spurious requests and makes a mess in the
        // logs and in Sentry.
        (error && error.statusCode && error.statusCode >= 500) ||
        // Happens when the fetch API receives a rejected promise, which happens on
        // temporary server failures sometimes.
        (error && error.statusCode == null) ||
        error.message === 'Unexpected token < in JSON at position 0'
      );
    },
  },
});

export const apolloLink = ApolloLink.from([
  useAdminPrivilegesMiddleware,
  authMiddleware,
  stackdriverMiddleware,
  errorLink,
  retryLink,
  // Right now these queries don't have an operation name since
  // they are user defined.
  getPerfTimingLink('CustomChartQuery:'),
  httpLink,
]);

export const createCircularDependencies = (store: Store, authIn: any) => {
  dispatch = store.dispatch;
  auth = authIn;
};

interface OperationContext {
  headers?: {[key: string]: string};
}

function setHeader(op: Operation, key: string, value: string) {
  op.setContext(({headers = {}}: OperationContext) => ({
    headers: {
      ...headers,
      [key]: value,
    },
  }));
}
