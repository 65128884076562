import {QueryResult} from '@apollo/react-common';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import docUrl from '@wandb/common/util/doc_urls';
import {isNotNullOrUndefined} from '@wandb/common/util/types';
import {ApolloCurrentQueryResult} from 'apollo-client';
import * as _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {ExecutionResult} from 'react-apollo';
import {
  Button,
  Checkbox,
  Input,
  InputOnChangeData,
  Modal,
  Popup,
} from 'semantic-ui-react';
import {Viewer} from 'src/state/viewer/types';

import emptyImg2x from '../assets/il-no-launch-alt.png';
import {
  CreateRunQueueMutation,
  DefaultResourceConfig,
  FetchLaunchAgentsFromProjectQuery,
  FetchRunQueuesFromProjectQuery,
  RunQueue,
  RunQueueAccessType,
  RunQueueItem,
  useCreateRunQueueMutation,
  useDeleteRunQueuesMutation,
  useFetchRunQueuesFromProjectQuery,
  useRunsStateQuery,
  useTeamInfoQuery,
} from '../generated/graphql';
import {DEFAULT_LAUNCH_PROJECT_NAME} from '../pages/EntityLaunchPage';
import {ProjectPageQueryData} from '../state/graphql/projectPageQuery';
import {runNamesFilter, toMongo} from '../util/filters';
import ActiveRunIndicator from './ActiveRunIndicator';
import BlankPage from './BlankPage';
import EditResourceConfigModal from './Launch/DefaultResourceConfig/EditResourceConfigModal';
import {
  CreateRunQueueArgs,
  EditLaunchConfigModalControlled,
} from './Launch/EditLaunchConfigModal';
import SelectDefaultResourceConfig from './Launch/SelectDefaultResourceConfig';
import {RunQueueItemsTable} from './RunQueueItemsTable';
import * as S from './RunQueueTab.styles';
import {InstrumentedLoader as Loader} from './utility/InstrumentedLoader';

export const DEFAULT_RUN_QUEUE_NAME = 'default';

interface RunQueueTabProps {
  viewer?: Viewer;
  entityName: string;
  projectLoading: boolean;
  projectName: string;
  isTeam: boolean;
  runQueues: RunQueue[];
  defaultResourceConfigs: DefaultResourceConfig[];
  launchAgentsQuery: QueryResult<FetchLaunchAgentsFromProjectQuery>;
  projectWriteAccess: boolean;
  refetchProject: () => Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
}

const RunQueueTab: React.FC<RunQueueTabProps> = props => {
  const [creatingRunQueue, setCreatingRunQueue] = useState(false);
  const [deleteRunQueue, setDeleteRunQueue] = useState<RunQueue | null>();
  const [createRunQueue] = useCreateRunQueueMutation();
  const sortedRunQueues = _.sortBy(props.runQueues, rq =>
    new Date(rq.createdAt + 'Z').getTime()
  );
  const memberData = useTeamInfoQuery({
    variables: {entityName: props.entityName},
  });
  const viewer = props.viewer;
  const teamMembers = memberData?.data?.entity?.members;

  const launchAgents = props.launchAgentsQuery.data?.project?.launchAgents;
  const launchAgentsMap = useMemo(() => {
    if (launchAgents != null) {
      return Object.fromEntries(launchAgents.map(la => [la.id, la.name]));
    }
    return {};
  }, [launchAgents]);

  useEffect(() => {
    window.analytics?.track('Viewed launch run queues tab', {
      entity: viewer?.entity,
      sourceEntity: props.entityName,
      sourceProject: props.projectName,
    });
  }, [props.entityName, props.projectName, viewer]);

  function isTeamMember() {
    if (viewer == null) {
      return false;
    } else if (teamMembers != null) {
      return (
        teamMembers.findIndex(member => member.username === viewer.username) !==
        -1
      );
    }
    return false;
  }

  function isTeamAdmin() {
    if (viewer == null) {
      return false;
    } else if (teamMembers != null) {
      const member = teamMembers.find(m => m.username === viewer.username);
      if (member == null) {
        return false;
      }
      return member.admin ?? false;
    }
    return false;
  }

  const viewerIsTeamMember = props.isTeam && isTeamMember();
  const viewerIsTeamAdmin = props.isTeam && isTeamAdmin();
  const isViewersProject = props.entityName === viewer?.username;
  const canCreateRunQueue = isViewersProject || viewerIsTeamMember;
  const canDeleteProjectAccessQueue = isViewersProject || viewerIsTeamAdmin;
  const showTabContents = viewer != null && memberData.data != null;
  if (!showTabContents) {
    return <Loader name="run-queue-tab-contents-loader" />;
  }
  return (
    <>
      <RunQueueTabContents
        {...props}
        runQueues={sortedRunQueues}
        defaultResourceConfigs={props.defaultResourceConfigs}
        canCreateRunQueue={canCreateRunQueue}
        creatingRunQueue={creatingRunQueue}
        launchAgentsMap={launchAgentsMap}
        canDeleteProjectAccessQueue={canDeleteProjectAccessQueue}
        createRunQueue={createRunQueue}
        setCreatingRunQueue={setCreatingRunQueue}
        setDeleteRunQueue={setDeleteRunQueue}
        projectWriteAccess={props.projectWriteAccess}
        viewer={viewer}
      />
      {creatingRunQueue && (
        <AddRunQueueModal
          open
          onClose={() => {
            setCreatingRunQueue(false);
          }}
          entityName={props.entityName}
          projectName={props.projectName}
          isTeam={props.isTeam}
          runQueues={props.runQueues}
          refetchProject={props.refetchProject}
        />
      )}
      {deleteRunQueue && (
        <DeleteRunQueueModal
          open
          runQueue={deleteRunQueue}
          projectName={props.projectName}
          entityName={props.entityName}
          onClose={() => {
            setDeleteRunQueue(null);
          }}
          onDelete={async () => {
            await props.refetchProject();
            setDeleteRunQueue(null);
          }}
        />
      )}
    </>
  );
};

export default RunQueueTab;

interface RunQueueTabContentsProps extends RunQueueTabProps {
  runQueues: RunQueue[];
  defaultResourceConfigs: DefaultResourceConfig[];
  canCreateRunQueue: boolean;
  creatingRunQueue: boolean;
  launchAgentsMap: {[key: number]: string};
  canDeleteProjectAccessQueue: boolean;
  isTeam: boolean;
  projectWriteAccess: boolean;
  viewer: Viewer;
  createRunQueue: (
    variables: CreateRunQueueArgs
  ) => Promise<ExecutionResult<CreateRunQueueMutation>>;
  refetchProject: () => Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
  setDeleteRunQueue(state: RunQueue): void;
  setCreatingRunQueue(state: boolean): void;
}

const RunQueueTabContents: React.FC<RunQueueTabContentsProps> = ({
  runQueues,
  defaultResourceConfigs,
  projectName,
  entityName,
  canCreateRunQueue,
  projectLoading,
  launchAgentsMap,
  canDeleteProjectAccessQueue,
  isTeam,
  projectWriteAccess,
  viewer,
  setCreatingRunQueue,
  setDeleteRunQueue,
  refetchProject,
}) => {
  const [runQueuesFilter, setRunQueuesFilter] = useState('');

  const [selectedQueueId, setSelectedQueueId] = useState<string>('');
  const [selectedResourceConfigId, setSelectedDefaultResourceConfigId] =
    useState<string>('');

  const isInLaunchEntityhProject = projectName === DEFAULT_LAUNCH_PROJECT_NAME;

  const entityRunQueuesQuery = useFetchRunQueuesFromProjectQuery({
    variables: {
      projectName: DEFAULT_LAUNCH_PROJECT_NAME,
      entityName,
    },
    skip: isInLaunchEntityhProject,
  });

  const refetchEntityRunQueues = entityRunQueuesQuery.refetch;

  const entityRunQueues = entityRunQueuesQuery.data?.project?.runQueues ?? [];

  const NoRunQueuesMessage = () => {
    React.useEffect(() => {
      window.analytics?.track('Empty State Viewed', {location: 'launch'});
    }, []);
    return (
      <BlankPage
        extraTopMargin
        header="Launch"
        subheader="Containerized reproducible workflows"
        info={
          'Connect your own SageMaker or Kubernetes cluster, then ' +
          'easily queue and manage jobs using W&B Launch. Kick off ' +
          'jobs on your own infrastructure from the W&B UI or CLI.'
        }
        graphics={[
          {
            src: emptyImg2x,
            alt: 'no-launch',
          },
        ]}
        primaryAction={{
          content: 'Create Queue',
          onClick: () => {
            setCreatingRunQueue(true);
            window.analytics?.track('Create Queue Clicked', {
              location: 'launch splash page',
            });
          },
        }}
        secondaryAction={{
          content: 'Try Code',
          href: 'https://github.com/wandb/examples/tree/master/examples/launch/launch-quickstart',
          onClick: () => {
            window.analytics?.track('Try Code Clicked', {
              location: 'launch splash page',
            });
          },
        }}
        docLink={{
          href: docUrl.launch,
          onClick: () => {
            window.analytics?.track('See Docs Clicked', {
              location: 'launch splash page',
            });
          },
        }}
      />
    );
  };
  const allRunQueues = [...runQueues, ...entityRunQueues];
  if (allRunQueues.length === 0) {
    if (projectLoading || entityRunQueuesQuery.loading) {
      return <Loader name="no-run-queues-loader" />;
    } else {
      return <NoRunQueuesMessage />;
    }
  }
  const filteredRunQueues = runQueues.filter(rq =>
    rq.name.includes(runQueuesFilter)
  );

  const filteredEntityRunQueues = entityRunQueues.filter(rq =>
    rq.name.includes(runQueuesFilter)
  );

  return (
    <>
      <S.RunQueueTabHeader>
        <div>
          <Input
            icon={{
              className: 'wbic-ic-search',
            }}
            iconPosition="left"
            placeholder="Search queues"
            value={runQueuesFilter}
            onChange={(e, data) => {
              setRunQueuesFilter(data.value);
            }}
          />
        </div>
        {canCreateRunQueue && projectName === DEFAULT_LAUNCH_PROJECT_NAME && (
          <S.CreateQueueDiv
            onClick={() => {
              setCreatingRunQueue(true);
            }}>
            + Create Queue
          </S.CreateQueueDiv>
        )}
      </S.RunQueueTabHeader>

      {selectedQueueId && (
        <EditLaunchConfigModalControlled
          entityName={entityName}
          projectName={projectName}
          runQueueId={selectedQueueId}
          givenResourceConfigId={selectedResourceConfigId}
          onClose={() => {
            setSelectedQueueId('');
            refetchProject();
          }}
        />
      )}

      {selectedResourceConfigId !== '' && (
        <EditResourceConfigModal
          entityName={entityName}
          projectName={projectName}
          config={defaultResourceConfigs.find(
            x => x.id === selectedResourceConfigId
          )}
          mode="VIEW"
          onClose={() => setSelectedDefaultResourceConfigId('')}
        />
      )}
      {!isInLaunchEntityhProject && (
        <>
          <h3>Entity Queues</h3>
          {entityRunQueues.length > 0 &&
            filteredEntityRunQueues.map((r, index) => {
              return (
                <RunQueuesTableRow
                  key={r.id}
                  initiallyExpanded={
                    index === 0 && r.runQueueItems.edges.length > 0
                  }
                  projectName={DEFAULT_LAUNCH_PROJECT_NAME}
                  entityName={entityName}
                  runQueue={r as RunQueue}
                  launchAgentsMap={launchAgentsMap}
                  canDeleteProjectAccessQueue={canDeleteProjectAccessQueue}
                  isTeam={isTeam}
                  refetchQueues={refetchEntityRunQueues}
                  setDeleteRunQueue={setDeleteRunQueue}
                  projectWriteAccess={projectWriteAccess}
                  viewer={viewer}
                  setSelectedQueueId={setSelectedQueueId}
                  setSelectedDefaultResourceConfig={
                    setSelectedDefaultResourceConfigId
                  }
                />
              );
            })}
          {filteredEntityRunQueues.length === 0 &&
            entityRunQueues.length !== 0 && (
              <S.Header>
                No run queues found matching {runQueuesFilter}.
              </S.Header>
            )}
        </>
      )}
      {runQueues.length > 0 && projectName !== DEFAULT_LAUNCH_PROJECT_NAME && (
        <h3>Project Queues</h3>
      )}
      {filteredRunQueues.length > 0 &&
        filteredRunQueues.map((r, index) => {
          return (
            <RunQueuesTableRow
              key={r.id}
              initiallyExpanded={
                index === 0 && r.runQueueItems.edges.length > 0
              }
              projectName={projectName}
              entityName={entityName}
              runQueue={r}
              launchAgentsMap={launchAgentsMap}
              canDeleteProjectAccessQueue={canDeleteProjectAccessQueue}
              isTeam={isTeam}
              refetchQueues={refetchProject}
              setDeleteRunQueue={setDeleteRunQueue}
              projectWriteAccess={projectWriteAccess}
              viewer={viewer}
              setSelectedQueueId={setSelectedQueueId}
              setSelectedDefaultResourceConfig={
                setSelectedDefaultResourceConfigId
              }
            />
          );
        })}
      {filteredRunQueues.length === 0 && runQueues.length !== 0 && (
        <S.Header>No run queues found matching {runQueuesFilter}.</S.Header>
      )}
    </>
  );
};

interface RunQueuesTableRowProps {
  runQueue: RunQueue;
  projectName: string;
  entityName: string;
  launchAgentsMap: {[key: number]: string};
  canDeleteProjectAccessQueue: boolean;
  initiallyExpanded: boolean;
  isTeam: boolean;
  projectWriteAccess: boolean;
  viewer: Viewer;
  refetchQueues: () => Promise<
    ApolloCurrentQueryResult<
      ProjectPageQueryData | FetchRunQueuesFromProjectQuery
    >
  >;
  setSelectedQueueId: (runQueue: string) => void;
  setSelectedDefaultResourceConfig: (drcID: string) => void;
  setDeleteRunQueue(state: RunQueue): void;
}

const RunQueuesTableRow: React.FC<RunQueuesTableRowProps> = ({
  runQueue,
  projectName,
  entityName,
  launchAgentsMap,
  canDeleteProjectAccessQueue,
  initiallyExpanded,
  isTeam,
  projectWriteAccess,
  viewer,
  refetchQueues,
  setSelectedQueueId,
  setSelectedDefaultResourceConfig,
  setDeleteRunQueue,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const runQueueRunIds = runQueue.runQueueItems.edges.map(e => {
    return e.node.associatedRunId ?? null;
  });
  const filteredRunIds: string[] = runQueueRunIds.filter(isNotNullOrUndefined);
  const filter = runNamesFilter(filteredRunIds);
  const runStatesQuery = useRunsStateQuery({
    variables: {
      projectName,
      entityName,
      filters: JSON.stringify(toMongo(filter)),
    },
  });

  const runStates = useMemo(
    () =>
      runStatesQuery.data?.project?.runs?.edges.map(edge => {
        return {
          id: edge.node.name,
          state: edge.node.state,
          displayName: edge.node.displayName,
          urlName: edge.node.name,
        };
      }),
    [runStatesQuery]
  );
  const runningItems: RunQueueItem[] = useMemo(
    () =>
      runQueue.runQueueItems.edges.flatMap(e => {
        if (e.node.state === 'PENDING' || e.node.state === 'LEASED') {
          return [];
        }
        const runIdAndState = runStates?.find(
          rs => rs.id === e.node.associatedRunId
        );
        if (runIdAndState?.state === 'running') {
          return e.node;
        }
        return [];
      }),
    [runQueue.runQueueItems, runStates]
  );

  const runQueueItemsWithRunInfo: RunQueueItemWithRunInfo[] = useMemo(() => {
    return runQueue.runQueueItems.edges.flatMap(e => {
      if (e.node.state === 'PENDING' || e.node.state === 'LEASED') {
        return {
          ...e.node,
          runState: e.node.state === 'LEASED' ? 'starting' : 'queued',
        };
      } else if (e.node.state === 'CLAIMED') {
        const runIDAndState = runStates?.find(
          rs => rs.id === e.node.associatedRunId
        );
        if (
          runIDAndState == null ||
          runIDAndState.state === 'finished' ||
          runIDAndState.state === 'failed' ||
          runIDAndState.state === 'crashed'
        ) {
          return [];
        } else {
          return {
            ...e.node,
            runState: runIDAndState?.state ?? 'starting',
            runName: runIDAndState?.displayName,
            urlName: runIDAndState?.urlName,
          };
        }
      }
      return [];
    });
  }, [runQueue, runStates]);

  if (runStatesQuery.loading) {
    return <></>;
  }
  // TODO: add run queue item created by. Change disabled to be
  // for run queues that have runs not created by current viewer
  const projectAccessAndNotAdmin =
    runQueue.access === RunQueueAccessType.Project &&
    !canDeleteProjectAccessQueue;
  const options = [
    {
      key: 'create-job',
      text: 'Add Run to Queue',
      icon: <LegacyWBIcon name="rocket" />,
      disabled: !projectWriteAccess,
      onClick: () => {
        window.analytics?.track(
          'from run queue tab, open launch config modal',
          {
            entityName,
            projectName,
            queueName: runQueue.name,
            username: viewer.username,
          }
        );
        setSelectedQueueId(runQueue.id);
      },
    },
    {
      key: 'view-drc',
      text: 'View Config',
      icon: <LegacyWBIcon name="pencil" />,
      disabled: !runQueue.defaultResourceConfigID,
      onClick: () => {
        window.analytics?.track(
          'from run queue tab, open Default Resource Config modal',
          {
            entityName,
            projectName,
            queueName: runQueue.name,
            username: viewer.username,
            defaultResourceConfigID: runQueue.defaultResourceConfigID,
          }
        );
        setSelectedDefaultResourceConfig(
          runQueue.defaultResourceConfigID || ''
        );
      },
    },
    {
      key: 'delete',
      text: 'Delete queue',
      icon: <LegacyWBIcon name="delete" />,
      disabled: projectAccessAndNotAdmin,
      onClick: () => setDeleteRunQueue(runQueue),
    },
  ];
  return (
    <>
      <S.SuperTableRowOutter>
        <S.SuperTableRow onClick={() => setExpanded(!expanded)}>
          <S.SuperTableCellName>
            <S.QueueNameSpan expanded={expanded}>
              <LegacyWBIcon
                className={expanded ? 'open' : undefined}
                name="next"
              />
              {runQueue.name} ({runQueueItemsWithRunInfo.length})
            </S.QueueNameSpan>
          </S.SuperTableCellName>
          {runQueue.access === 'USER' && isTeam && (
            <S.SuperTableCell>
              <Popup
                content="Private - Only you can see this queue"
                inverted
                size="mini"
                position="top center"
                trigger={<LegacyWBIcon name="lock" />}
              />
            </S.SuperTableCell>
          )}
          <S.SuperTableCell>
            <Popup
              content={`${runningItems.length} active runs`}
              inverted
              size="mini"
              position="top center"
              trigger={
                <S.SuperTableSubCell>
                  {runningItems.length > 0 && <ActiveRunIndicator />}{' '}
                  <div style={{marginLeft: '3px'}}>{runningItems.length}</div>
                </S.SuperTableSubCell>
              }
            />
          </S.SuperTableCell>

          {projectWriteAccess && (
            <PopupDropdown
              position="bottom right"
              offset={'-45px, -1px'}
              trigger={
                <S.SuperTableCell
                  onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                  }}>
                  <LegacyWBIcon name="overflow" size="big" />
                </S.SuperTableCell>
              }
              options={options}
            />
          )}
        </S.SuperTableRow>
        {expanded && <S.Linebreak />}
        {expanded && runQueue.runQueueItems.edges.length > 0 && (
          <S.SubTableDiv>
            <RunQueueItemsTable
              runQueue={runQueue}
              runQueueItemsWithRunInfo={runQueueItemsWithRunInfo}
              refetchQueues={refetchQueues}
              projectName={projectName}
              entityName={entityName}
              launchAgentsMap={launchAgentsMap}
            />
          </S.SubTableDiv>
        )}
      </S.SuperTableRowOutter>
    </>
  );
};

export interface RunQueueItemWithRunInfo extends RunQueueItem {
  runState: string;
  runName?: string | null;
  urlName?: string | null;
}

interface AddRunQueueModalProps {
  open: boolean;
  entityName: string;
  projectName: string;
  viewingEntity?: string;
  isTeam: boolean;
  runQueues: RunQueue[];
  refetchProject(): Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
  onClose(): void;
}

const AddRunQueueModal: React.FC<AddRunQueueModalProps> = props => {
  const [createRunQueue] = useCreateRunQueueMutation();
  const [queueName, setQueueName] = useState<string | undefined>(undefined);
  const [defaultResourceConfigId, setDefaultResourceConfigId] = useState<
    string | undefined
  >(undefined);
  const [isPrivate, setIsPrivate] = useState(false);

  const access =
    props.isTeam && !isPrivate
      ? RunQueueAccessType.Project
      : RunQueueAccessType.User;

  const {disabled, message} = useMemo(() => {
    if (queueName === DEFAULT_RUN_QUEUE_NAME && props.isTeam && isPrivate) {
      return {
        disabled: true,
        message: `"${DEFAULT_RUN_QUEUE_NAME}" runQueue name cannot be private`,
      };
    } else if (props.runQueues.some(rq => rq.name === queueName)) {
      return {
        disabled: true,
        message: 'a runQueue with that name already exists in this project',
      };
    } else if (queueName == null) {
      return {
        disabled: true,
        message: 'must select a queue name',
      };
    }
    return {disabled: false, message: null};
  }, [queueName, props.runQueues, isPrivate, props.isTeam]);

  const onCreateRunQueueClick = async () => {
    window.analytics?.track('create new run queue', {
      entityName: props.entityName,
      projectName: props.projectName,
      queueName,
      access,
      defaultResourceConfigID: defaultResourceConfigId,
    });
    if (queueName == null) {
      return;
    }

    const {data} = await createRunQueue({
      variables: {
        projectName: props.projectName,
        entityName: props.entityName,
        queueName,
        access,
        defaultResourceConfigID: defaultResourceConfigId,
      },
    });
    if (
      data == null ||
      !data.createRunQueue?.success ||
      data?.createRunQueue?.queueID == null
    ) {
      alert('Run Queue creation failed');
    } else {
      props.refetchProject();
    }
    props.onClose();
  };

  return (
    <S.Modal open={props.open} onClose={props.onClose}>
      <Modal.Content>
        <S.ModalTitle>Create Queue</S.ModalTitle>
        <S.ModalInputContainer>
          <S.Input
            size="small"
            placeholder="Name"
            onChange={(e: React.SyntheticEvent, {value}: InputOnChangeData) =>
              setQueueName(value)
            }
            value={queueName}
          />
        </S.ModalInputContainer>
        <S.DRCSelect>
          <SelectDefaultResourceConfig
            entityName={props.entityName}
            onSelectDRC={setDefaultResourceConfigId}
          />
        </S.DRCSelect>
        {props.isTeam && (
          <S.ModalText>
            <div>Private to user:</div>
            <S.Radio
              type="radio"
              toggle
              checked={isPrivate}
              onChange={() => setIsPrivate(!isPrivate)}
            />
          </S.ModalText>
        )}
        <S.ModalActions>
          <S.ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
          </S.ButtonContainer>
          <S.ButtonContainer>
            <Button
              tooltip={message}
              disabled={disabled}
              color="blue"
              onClick={() => onCreateRunQueueClick()}>
              Create Queue
            </Button>
          </S.ButtonContainer>
        </S.ModalActions>
      </Modal.Content>
    </S.Modal>
  );
};

interface DeleteRunQueueModalProps {
  open: boolean;
  runQueue: RunQueue;
  projectName: string;
  entityName: string;
  onClose(): void;
  onDelete(): void;
}

const DeleteRunQueueModal: React.FC<DeleteRunQueueModalProps> = ({
  open,
  runQueue,
  projectName,
  entityName,
  onClose,
  onDelete,
}) => {
  const [deleteRunQueue] = useDeleteRunQueuesMutation({
    variables: {queueIDs: [runQueue.id]},
  });
  const [allowDelete, setAllowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  return (
    <Modal className="runQueue-delete-modal" open={open} onClose={onClose}>
      <Modal.Content>
        <p>
          Are you sure you want to DELETE queue "{runQueue.name}"? All starting
          or queued runs will be removed everywhere. Finished runs from this
          queue will still be available. This action cannot be undone!
        </p>
        <Checkbox
          label={`Delete "${runQueue.name}"`}
          checked={allowDelete}
          onChange={() => {
            setAllowDelete(!allowDelete);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          color="red"
          disabled={!allowDelete || deleting}
          loading={deleting}
          onClick={async e => {
            window.analytics?.track('delete new run queue', {
              entityName,
              projectName,
              queueName: runQueue.name,
            });
            setDeleting(true);
            e.preventDefault();
            e.stopPropagation();
            await deleteRunQueue();
            setDeleting(false);
            onDelete();
          }}>
          Delete this queue
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
