import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

export const CodeBlock = styled.div`
  color: ${globals.white};
  background-color: #2a2a2a;
  padding: 10px;
  margin: 10px 0 10px 0;
  border-radius: 0.3em;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: 'Inconsolata', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  hyphens: none;
  overflow-x: auto;
  text-overflow: ellipsis;
`;
