import {ApolloQueryResult} from 'apollo-client';
import React, {FC, useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Input,
  Modal,
} from 'semantic-ui-react';

import {
  ProjectArtifactsQuery,
  useArtifactTypesQuery,
  useDeleteArtifactPortfolioMutation,
  useDeleteArtifactSequenceMutation,
} from '../generated/graphql';
import {propagateErrorsContext} from '../util/errors';

interface RenameArtifactModalProps {
  name: string;
  modalOpen: boolean;
  onRenameArtifact(newName: string): void;
  onClose(): void;
}

export const RenameArtifactModal = (props: RenameArtifactModalProps) => {
  const {name, modalOpen, onRenameArtifact, onClose} = props;
  const [newName, setNewName] = useState(name);

  // The value for the input is set to `newName` which is a state variable.
  // Without this useEffect, any changes in props.name will not be propagated to the input value.
  React.useEffect(() => {
    setNewName(name);
  }, [name]);

  return (
    <Modal size="tiny" open={modalOpen} onClose={onClose}>
      <Modal.Header>
        Are you sure you want to rename this artifact?
      </Modal.Header>
      <Modal.Content>
        <p>
          This will break all aliases associated with the current name. You will
          need to update your scripts to refer to the updated name.
        </p>
        <Input
          fluid
          value={newName}
          placeholder={'Artifact name...'}
          onChange={(e, data) => {
            setNewName(data.value);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="green"
          disabled={newName === name || newName === ''}
          onClick={() => {
            onRenameArtifact(newName);
          }}>
          Rename
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface MoveArtifactModalProps {
  entityName: string;
  projectName: string;
  modalOpen: boolean;
  onMoveArtifact(destinationArtifactTypeName: string): void;
  onClose(): void;
}

export const MoveArtifactModal = (props: MoveArtifactModalProps) => {
  const {entityName, projectName, modalOpen, onMoveArtifact, onClose} = props;
  const [value, setValue] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const {loading} = useArtifactTypesQuery({
    fetchPolicy: 'no-cache',
    skip: !modalOpen,
    onCompleted: data => {
      setOptions(
        data!.project!.artifactTypes.edges.map(edge => {
          return {
            key: edge.node!.name,
            value: edge.node!.name,
            text: edge.node!.name,
          };
        })
      );
    },
    variables: {
      entityName,
      projectName,
    },
  });

  return (
    <Modal size="tiny" open={modalOpen} onClose={onClose}>
      <Modal.Header>Are you sure you want to move this artifact?</Modal.Header>
      <Modal.Content>
        <p>
          You will need to update your scripts to log new versions using the
          updated type name.
        </p>
        <Dropdown
          fluid
          search
          selection
          allowAdditions
          placeholder={'Destination artifact type...'}
          options={options}
          loading={loading}
          value={value}
          onChange={(e, data) => {
            setValue(data.value as string);
          }}
          onAddItem={(e, data) => {
            setOptions([
              {
                key: data.value as string,
                value: data.value as string,
                text: data.value as string,
              },
              ...options,
            ]);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="green"
          disabled={value === undefined || value === ''}
          onClick={() => onMoveArtifact(value!)}>
          Move
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface DeleteArtifactModalProps {
  id: string;
  entityName: string;
  isModalOpen: boolean;
  isPortfolio?: boolean;
  newURL?: string;
  projectName: string;
  refetchProjectArtifacts: () =>
    | Promise<ApolloQueryResult<ProjectArtifactsQuery>>
    | Promise<void>;
  setIsDeleteModalOpen(isModalOpen: boolean): void;
}

export const DeleteArtifactModal: FC<DeleteArtifactModalProps> = ({
  id,
  entityName,
  isModalOpen,
  isPortfolio,
  projectName,
  refetchProjectArtifacts,
  setIsDeleteModalOpen,
  newURL,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();

  const [deleteArtifactSequence] = useDeleteArtifactSequenceMutation({
    onCompleted: refetchProjectArtifacts,
    context: propagateErrorsContext(),
  });
  const [deleteArtifactPortfolio] = useDeleteArtifactPortfolioMutation({
    onCompleted: refetchProjectArtifacts,
    context: propagateErrorsContext(),
  });

  const closeModal = useCallback(
    () => setIsDeleteModalOpen(false),
    [setIsDeleteModalOpen]
  );

  const onDeleteArtifact = useCallback(async () => {
    setIsDeleting(true);

    try {
      if (isPortfolio) {
        await deleteArtifactPortfolio({
          variables: {
            artifactPortfolioID: id ?? '',
          },
        });
      } else {
        await deleteArtifactSequence({
          variables: {
            artifactSequenceID: id ?? '',
          },
        });
      }

      if (newURL != null) {
        history.push(newURL);
      }
      closeModal();
    } catch (error) {
      console.error(error);
      toast('Something went wrong while trying to delete this artifact.');
    }

    setIsDeleting(false);
  }, [
    closeModal,
    deleteArtifactPortfolio,
    deleteArtifactSequence,
    history,
    id,
    isPortfolio,
    newURL,
  ]);

  return (
    <Modal size="tiny" open={isModalOpen} onClose={closeModal}>
      <Modal.Header>
        Are you sure you want to delete this artifact?
      </Modal.Header>
      <Modal.Content>
        {isPortfolio ? (
          <p>
            Warning - this is a permanent action - it will break any scripts
            referencing this artifact.
          </p>
        ) : (
          <p>
            All versions of the artifact and the underlying files will be
            scheduled for deletion.
          </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          color="red"
          data-cy="delete-artifact-sequence-confirm"
          loading={isDeleting}
          onClick={onDeleteArtifact}>
          Delete this artifact
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
