import emoji from 'node-emoji';
import React from 'react';
import {
  Descendant,
  Editor,
  Element,
  Node,
  Point,
  Range,
  Transforms,
} from 'slate';
import {RenderElementProps} from 'slate-react';

import * as S from './callout-blocks.styles';
import {BlockWrapper} from './drag-drop';
import {EditorWithHardBreak} from './hard-break';

export interface CalloutBlock extends Element {
  type: 'callout-block';
  emoji?: string;
}

export const isCalloutBlock = (node: Node): node is CalloutBlock =>
  node.type === 'callout-block';

export const CalloutBlockElement: React.FC<
  RenderElementProps & {
    element: CalloutBlock;
  }
> = ({attributes, element, children}) => {
  const calloutEmoji = element.emoji ?? 'bulb';

  // put children before emoji so cursor doesn't focus on the prev line
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.CalloutBlock>
        <S.CalloutLine>{children}</S.CalloutLine>
        <S.CalloutEmojiContainer>
          <S.CalloutEmoji>{emoji.get(calloutEmoji)}</S.CalloutEmoji>
        </S.CalloutEmojiContainer>
      </S.CalloutBlock>
    </BlockWrapper>
  );
};

export interface CalloutLine extends Element {
  type: 'callout-line';
}

export const isCalloutLine = (node: Node): node is CalloutLine =>
  node.type === 'callout-line';

export const CalloutLineElement: React.FC<
  RenderElementProps & {
    element: CalloutLine;
  }
> = ({attributes, element, children}) => {
  return <div {...attributes}>{children}</div>;
};

export const withCallout = <T extends Editor>(editor: T) => {
  const {normalizeNode, insertBreak, deleteBackward} = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (isCalloutBlock(node)) {
      const hasCalloutLine = node.children.some(isCalloutLine);
      if (!hasCalloutLine) {
        Transforms.unwrapNodes(editor, {at: path});
        return;
      }

      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        const childPath = [...path, i];
        if (!isCalloutLine(child)) {
          Transforms.liftNodes(editor, {at: childPath});
          return;
        }
      }
    }

    if (Editor.isEditor(node) || Element.isElement(node)) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i] as Descendant;

        if (isCalloutLine(child) && !isCalloutBlock(node)) {
          Transforms.wrapNodes(
            editor,
            {type: 'callout-block', children: []},
            {at: [...path, i]}
          );
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    const {selection} = editor;

    const match = Editor.above(editor, {
      match: n => isCalloutBlock(n),
    });

    if (selection && match) {
      const [block, path] = match;

      if (
        isCalloutBlock(block) &&
        Range.isCollapsed(selection) &&
        Point.equals(selection.anchor, Editor.end(editor, path))
      ) {
        const allowedBlankLines = 2;
        let blankLines = 0;

        for (let i = 0; i < allowedBlankLines; i++) {
          const child = block.children[block.children.length - 1 - i];
          if (
            child != null &&
            Editor.isBlock(editor, child) &&
            Editor.isEmpty(editor, child)
          ) {
            blankLines++;
          } else {
            break;
          }
        }

        if (blankLines === allowedBlankLines) {
          EditorWithHardBreak.hardBreak(editor);
          for (let i = 0; i < allowedBlankLines; i++) {
            Editor.deleteBackward(editor);
          }
          return;
        }
      }
    }

    insertBreak();
  };

  editor.deleteBackward = (...args) => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const blockMatch = Editor.above(editor, {
        match: n => isCalloutBlock(n) && n.children.length === 1,
      });
      const lineMatch = Editor.above(editor, {
        match: n => isCalloutLine(n) && Editor.isEmpty(editor, n),
      });

      if (blockMatch != null && lineMatch != null) {
        Transforms.setNodes(editor, {type: 'paragraph'});
        return;
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

export const calloutBlockNodeToLatex = (node: CalloutBlock, inner: string) => {
  return `\\begin{tcolorbox}[boxrule=0pt, arc=0mm]\n${inner}\\end{tcolorbox}`;
};

// tcolorbox needs two new lines to format a new line in the box
export const calloutLineNodeToLatex = (node: CalloutLine, inner: string) => {
  return `${inner}\n\n`;
};
