import styled from 'styled-components';

export const SelectOption = styled.div`
  display: flex;
`;

export const SelectGroupOptionLabel = styled.div`
  fontweight: bold;
  display: flex;
`;

export const SelectOptionLabel = styled.div`
  flex: 1 1 auto;
`;
