import type {MatchMode} from '@wandb/common/util/columnMatching';
import React, {useMemo, useState} from 'react';

import {useProjectFieldsQuery} from '../../state/graphql/projectFieldsQuery';
import {useRunsQueryContext} from '../../state/runs/hooks';
import {useDebounceState} from '../../util/hooks';
import * as RunFeed from '../../util/runfeed';
import * as Run from '../../util/runs';
import {
  DEFAULT_RUNSELECTOR_KEYS,
  DEFAULT_SWEEP_EARLY_TERMINATE_KEYS,
  DEFAULT_SWEEP_KEYS,
} from '../../util/tablecols';
import TableEditor from '../TableEditor';

interface RunsColumnEditorProps {
  entityName: string;
  projectName: string;

  config: RunFeed.Config;
  fixedColumns?: string[];

  update(config: RunFeed.Config): void;
}

export const RunsColumnEditor = (props: RunsColumnEditorProps) => {
  const [search, searchDebounced, setSearch] = useDebounceState('', 200);

  const [searchMode, setMatchMode] = useState<MatchMode>('fuzzy');
  const pattern = useMemo(() => {
    if (searchDebounced === '' || searchMode === 'regex') {
      return null;
    }

    if (searchMode === 'exact') {
      return '%' + searchDebounced + '%';
    }

    return '%' + searchDebounced.split('').join('%') + '%';
  }, [searchDebounced, searchMode]);

  // strictly limit the query count when a user is searching so that the load isn't too much
  const queryCount = useMemo(() => (pattern == null ? 10000 : 200), [pattern]);

  const projectFieldsData = useProjectFieldsQuery({
    entityName: props.entityName,
    projectName: props.projectName,
    types: ['string', 'number', 'boolean', 'other'],
    columns: ['config', 'summary_metrics'],
    count: queryCount,
    pattern,
  });

  // These keys have broken behavior when they're not filtered out.
  // This filtering seems to be duplicated in other places.
  // Someone smarter than me should refactor the way we handle special keys.
  const keys = projectFieldsData.keys.filter(
    key =>
      !(
        key === 'config:wandb_version' ||
        key === 'summary:_runtime' ||
        key === 'summary:_step' ||
        key === 'summary:_timestamp'
      )
  );

  const {sweep} = useRunsQueryContext();
  const defaultKeys =
    sweep == null
      ? DEFAULT_RUNSELECTOR_KEYS
      : sweep.earlyTerminate
      ? DEFAULT_SWEEP_EARLY_TERMINATE_KEYS
      : DEFAULT_SWEEP_KEYS;

  const allColumns = useMemo(
    () => [...defaultKeys.map(k => Run.keyToString(k)), ...keys],
    [keys, defaultKeys]
  );

  return (
    <TableEditor
      config={props.config}
      allColumns={allColumns}
      fixedColumns={props.fixedColumns}
      searchQuery={search}
      searchLoading={projectFieldsData.loading}
      searchMode={searchMode}
      setMatchMode={setMatchMode}
      setSearch={setSearch}
      update={props.update}
    />
  );
};
