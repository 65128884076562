import {Subset} from '@wandb/common/types/base';
import gql from 'graphql-tag';

import {Entity, Graph, ProjectField, Tag, TagCount} from '../../types/graphql';
import * as Filter from '../../util/filters';
import {addSearchFilter} from '../../util/queryts';
import * as Run from '../../util/runs';
import * as SM from '../../util/selectionmanager';
import {useQuery} from './query';

///// Query definition

export const RUN_SELECTOR_QUERY = gql`
  query RunSelector(
    $name: String!
    $entityName: String
    $tempFilters: JSONString
  ) {
    project(name: $name, entityName: $entityName) {
      id
      runCount
      fields(types: ["number", "string", "boolean", "other"], first: 1000) {
        edges {
          node {
            path
          }
        }
      }
      tags {
        name
        colorIndex
      }
      tagCounts(filters: $tempFilters) {
        name
        count
      }
      entity {
        id
        settings {
          storageBucketInfo {
            ID
          }
        }
      }
    }
  }
`;

// These types define the inputs and outputs of the above gql query

export interface RunSelectorData {
  id: string;
  runCount: number;
  fields: Graph<Subset<ProjectField, 'path'>>;
  tags: Tag[];
  tagCounts: TagCount[];
  entity: Entity;
}

// The query's output shape.
interface Data {
  project: RunSelectorData;
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  entityName: string;
  projectName: string;
  mergeFilters?: Filter.Filter<Run.Key>;
  filters: Filter.Filter<Run.Key>;
  groupTempSelection: SM.GroupSelectionState;
  pollInterval: number;
}

export function combinedFilter(
  userFilters?: Filter.Filter<Run.Key>,
  groupSelection?: SM.GroupSelectionState,
  spamFilters?: Filter.Filter<Run.Key>,
  mergeFilters?: Filter.Filter<Run.Key>,
  searchQuery?: string
) {
  let filter = userFilters ?? Filter.EMPTY_FILTERS;
  if (SM.isSelectingIndividualRuns(groupSelection)) {
    // The user is hand-selecting runs so there is no reason to
    // pass the full user filter down. The full filter might be
    // significantly more expensive than a handful of run id lookups.
    filter = Filter.EMPTY_FILTERS;
  }

  if (searchQuery) {
    filter = addSearchFilter(filter, searchQuery, groupSelection?.grouping);
  }
  const allFilters = [filter];
  if (groupSelection) {
    allFilters.push(SM.toRunFilter(groupSelection));
  }
  if (spamFilters) {
    allFilters.push(spamFilters);
  }
  if (mergeFilters) {
    allFilters.push(mergeFilters);
  }
  return Filter.toMongo(Filter.And(allFilters));
}

export function useRunSelectorQuery(inputProps: InputProps) {
  const variables = {
    entityName: inputProps.entityName,
    name: inputProps.projectName,
    tempFilters: JSON.stringify(
      combinedFilter(
        inputProps.filters,
        inputProps.groupTempSelection,
        undefined,
        inputProps.mergeFilters
      )
    ),
  };
  return useQuery<Data, typeof variables>(RUN_SELECTOR_QUERY, {variables});
}
