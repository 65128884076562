import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {Button, Dropdown} from 'semantic-ui-react';
import styled from 'styled-components';

import {GLOBAL_COLORS} from '../../../../../util/colors';

export const OverviewPanelHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 8px;
  .ui.button {
    margin-left: 15px;
  }
  color: ${GLOBAL_COLORS.lightGray.toString()};
`;

export const SourceDescriptor = styled.div`
  color: ${GLOBAL_COLORS.lightGray.toString()};
  margin-right: 8px;
  font-size: 1.2em;
  font-weight: 500;
`;
export const SourceBlock = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Panel = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  margin: 0px 8px;
`;

export const InputOutputPanelContents = styled.div`
  background-color: ${globals.gray50};
`;

export const Overview = styled.div`
  background-color: white;
  height: 100%;
`;

export const OverviewPanelHeader = styled.div`
  margin-top: 16px;
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PanelJobHeaderContent = styled.div`
  height: 3.4em;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const PanelArtifactHeaderTitle = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  padding-left: 0.5em;
`;

export const TriggerContainer = styled.div`
  margin-left: auto;
  margin-right: 10px;
`;

export const VersionMenuButton = styled(WBIcon)`
  font-size: 1.9em;
  border-radius: 5px;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: #ddd;
  }
`;

export const CollectionDropdown = styled(Dropdown)`
  margin-left: 1em !important;
  font-size: 16px !important;
`;

export const BlankPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const JobOverviewMain = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const InputOutputDiv = styled.div`
  margin-left: 10px;
`;

export const TableDiv = styled.div`
  height: 800px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const BlueButton = styled(Button)`
  &&& {
    color: white;
    background-color: ${globals.primary};
    transition: all 0.2s ease;
    &:hover {
      background-color: ${globals.primaryDark};
    }
  }
`;

export const TitleAndMenusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
