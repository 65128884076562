import React, {useEffect, useMemo, useState} from 'react';

import {backendWeaveExecutionUrl} from '@wandb/common/config';
import {
  Client,
  createRemoteClient,
  StaticOpStore,
  makeEcosystemMixedOpStore,
  GlobalCGEventTracker,
} from '@wandb/cg';

import {ClientContext} from './context';
import {useLoadWeaveObjects} from './components/Panel2/weaveBackend';

export const ComputeGraphContextProviderFromClient: React.FC<{client: Client}> =
  React.memo(({client, children}) => {
    const context = useMemo(() => ({client}), [client]);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      const subscription = context.client
        .loadingObservable()
        .subscribe(setIsLoading);
      return () => subscription.unsubscribe();
    }, [context]);

    return (
      <div
        data-test="compute-graph-provider"
        data-test-num-shadow-server-requests-counter={
          GlobalCGEventTracker.shadowServerRequests
        }
        className={isLoading ? 'loading cg-executing' : ''}>
        <ClientContext.Provider value={context}>
          {children}
        </ClientContext.Provider>
      </div>
    );
  });

const useRemoteEcosystemClient = (
  isAdmin: boolean = false,
  tokenFunc: () => Promise<string | undefined>
) => {
  const {loading, remoteOpStore} = useLoadWeaveObjects();
  return useMemo(() => {
    if (loading || remoteOpStore == null) {
      return null;
    }
    return createRemoteClient(
      backendWeaveExecutionUrl(),
      tokenFunc,
      isAdmin,
      makeEcosystemMixedOpStore(StaticOpStore.getInstance(), remoteOpStore)
    );
  }, [isAdmin, remoteOpStore, tokenFunc, loading]);
};

export const RemoteEcosystemComputeGraphContextProvider: React.FC<{
  isAdmin?: boolean;
  tokenFunc?: () => Promise<string | undefined>;
}> = React.memo(({isAdmin, tokenFunc, children}) => {
  const tf = useMemo(() => {
    const dummy = async () => undefined;
    return tokenFunc != null ? tokenFunc : dummy;
  }, [tokenFunc]);
  const client = useRemoteEcosystemClient(!!isAdmin, tf);
  if (client == null) {
    return <></>;
  }
  return (
    <ComputeGraphContextProviderFromClient
      client={client}
      children={children}
    />
  );
});
