import {onNextExitFullscreen} from '@wandb/common/util/fullscreen';
import {Struct} from '@wandb/common/util/types';
import * as React from 'react';
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Card, Icon, Placeholder} from 'semantic-ui-react';

import {labelComponent, makeCaptions} from '../util/media';
import {blobToFile} from '../util/requests';
import {runLink} from '../util/runhelpers';
import {MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';

export const MoleculeCard: React.FunctionComponent<MediaCardProps> = React.memo(
  props => {
    const {
      run,
      globalStep,
      mediaKey,
      mediaIndex,
      runSignature,
      height,
      width,
      moleculeConfig,
      tileMedia,
    } = props;
    const blob = tileMedia?.blob;
    const directURL = tileMedia?.directURL;
    const historyRow = tileMedia?.historyRow;

    const stageMountRef = useRef<HTMLDivElement | null>(null);

    const [screenshotURL, setScreenshotURL] = useState<string | null>(null);

    const representation = moleculeConfig?.representation;
    const settings = useMemo(
      () => ({
        representation,
      }),
      [representation]
    );

    // Use async import for ngl code to split bundle
    type NGLLib = typeof import('@wandb/common/util/nglviewerRender');
    const [nglLib, setNglLib] = useState<NGLLib | null>(null);
    useEffect(() => {
      import('@wandb/common/util/nglviewerRender').then(setNglLib);
    }, []);

    useLayoutEffect(() => {
      if (
        nglLib == null ||
        directURL == null ||
        blob == null ||
        stageMountRef.current == null
      ) {
        return;
      }
      const file = blobToFile(blob, directURL);
      if (file == null) {
        return;
      }

      const ext = getMoleculeFileExt(directURL);

      (async () => {
        const imageBlob = await nglLib.moleculeScreenshot(
          file,
          {width, height},
          settings,
          ext
        );
        setScreenshotURL(window.URL.createObjectURL(imageBlob));
      })();
    }, [blob, directURL, width, height, settings, nglLib]);

    const requestFullscreen = () => {
      if (
        nglLib == null ||
        directURL == null ||
        blob == null ||
        stageMountRef.current == null
      ) {
        return;
      }
      const file = blobToFile(blob, directURL);
      if (file == null) {
        return;
      }

      const screenW = window.screen.width;
      const screenH = window.screen.height;
      const ext = getMoleculeFileExt(directURL);

      const stage = nglLib.moleculeStage(
        stageMountRef.current,
        file,
        {width: screenW, height: screenH},
        {representation},
        ext
      );

      onNextExitFullscreen(() => {
        stage.dispose();
        if (stageMountRef.current) {
          stageMountRef.current.innerHTML = '';
        }
      });

      try {
        stageMountRef.current?.requestFullscreen?.();
      } catch (e) {
        throw new Error('Fullscreen request invalid: ' + e);
      }
    };

    const titleLink = runLink(runSignature, run.displayName, {
      className: 'hide-in-run-page',
      target: '_blank',
      rel: 'noopener noreferrer',
    });

    const currentMediaMetadata: Struct | undefined = historyRow?.[mediaKey];
    const captions = useMemo(() => {
      return makeCaptions(currentMediaMetadata, mediaIndex);
    }, [currentMediaMetadata, mediaIndex]);

    return (
      <Card className="molecule-card media-card" style={{width, height}}>
        {labelComponent(props, tileMedia?.step, titleLink)}

        {tileMedia == null ? (
          <MessageMediaNotFound
            mediaKey={mediaKey}
            stepIndex={globalStep}
            mediaIndex={mediaIndex}
            mediaType="molecule"
          />
        ) : (
          screenshotURL == null && (
            <Placeholder style={{width, height}}>
              <Placeholder.Image />
            </Placeholder>
          )
        )}
        <div ref={stageMountRef}></div>
        {screenshotURL != null && (
          <div>
            <img
              alt={`Representation of molecule ${mediaKey}`}
              style={{position: 'absolute', height: '100%'}}
              src={screenshotURL}
            />
            <div className="media-card__fullscreen" onClick={requestFullscreen}>
              <Icon size="large" link name="expand arrows alternate" />
            </div>
          </div>
        )}
        {/* CAPTIONS */}
        {captions.length > 0 && (
          <div className="image-card-caption">{captions}</div>
        )}
      </Card>
    );
  }
);

export default MoleculeCard;

function getMoleculeFileExt(directURL: string): string {
  const ext = directURL.split('?')[0].split('.').pop();
  if (ext == null) {
    throw new Error('Molecule file must have an extension');
  }
  return ext;
}
