import {ImageExportType} from '@wandb/common/util/panelExport';
import * as React from 'react';
import {useCallback} from 'react';
import {Menu} from 'semantic-ui-react';

import {getPanelSpec, isExportableAs} from '../../util/panels';
import {ExportType} from './PanelActionsMenu';

interface PanelExportMenuProps {
  ref: React.RefObject<HTMLElement>;
  panelSpec: ReturnType<typeof getPanelSpec>;
  handleClose(): void;
  setExportType(type: ExportType): void;
  setImageExportType(imageType: ImageExportType): void;
}

export const PanelExportMenu = React.forwardRef<
  HTMLElement,
  PanelExportMenuProps
>(({panelSpec, handleClose, setExportType, setImageExportType}, ref) => {
  const onExportImageClick = useCallback(
    (imageType: ImageExportType) => {
      setExportType(ExportType.Image);
      setImageExportType(imageType);
      handleClose();
    },
    [handleClose, setExportType, setImageExportType]
  );

  const onExportClick = useCallback(
    (exportType: ExportType) => {
      setExportType(exportType);
      handleClose();
    },
    [handleClose, setExportType]
  );

  return (
    <Menu vertical borderless className="popup-submenu">
      {isExportableAs(panelSpec, 'image') && (
        <>
          <Menu.Item onClick={() => onExportImageClick(ImageExportType.PNG)}>
            PNG
          </Menu.Item>
          <Menu.Item onClick={() => onExportImageClick(ImageExportType.SVG)}>
            SVG
          </Menu.Item>
          <Menu.Item onClick={() => onExportImageClick(ImageExportType.PDF)}>
            PDF
          </Menu.Item>
        </>
      )}
      {isExportableAs(panelSpec, 'api') && (
        <Menu.Item
          title="Export API"
          onClick={() => onExportClick(ExportType.API)}>
          Export API
        </Menu.Item>
      )}
      {isExportableAs(panelSpec, 'csv') && (
        <Menu.Item
          title="Export CSV"
          onClick={() => onExportClick(ExportType.CSV)}>
          CSV Export
        </Menu.Item>
      )}
    </Menu>
  );
});
