import React, {useCallback, useMemo} from 'react';
import * as HL from '@wandb/cg';
import {PanelComp2} from '../PanelComp';
import {usePanelStacksForType} from '../availablePanels';
import * as TypeHelpers from '@wandb/cg';
import * as GraphTypes from '@wandb/cg';
import * as Table from './tableState';
import {PanelContextProvider} from '../PanelContext';
import {makeEventRecorder} from '../panellib/libanalytics';
import * as TH from './hooks';
import {useWhenOnScreenAfterNewValueDebounced} from '@wandb/common/state/hooks';
import {useWeaveContext} from '../../../context';

const recordEvent = makeEventRecorder('Table');

export const Cell: React.FC<{
  table: Table.TableState;
  inputNode: GraphTypes.Node;
  rowNode: GraphTypes.Node;
  selectFunction: GraphTypes.NodeOrVoidNode;
  colId: string;
  panelId: string;
  config: any;
  panelContext: any;
  updateTableState(newConfig: any): void;
  updatePanelContext(newContext: any): void;
  updateInput?(newInput: any): void;
}> = ({
  table,
  inputNode,
  rowNode,
  selectFunction,
  panelId,
  colId,
  config,
  panelContext,
  updateTableState,
  updatePanelContext,
  updateInput,
}) => {
  const weave = useWeaveContext();
  const updatePanelConfig = TH.useUpdatePanelConfig(
    updateTableState,
    table,
    colId
  );
  const selectedNode = useMemo(
    () => Table.getCellValueNode(weave, rowNode, selectFunction),
    [rowNode, selectFunction, weave]
  );

  const {handler, curPanelId} = usePanelStacksForType(
    selectedNode.type,
    panelId,
    {
      excludeTable: true,
      excludePlot: true,
    }
  );

  // Only render when on screen for the first time. Each time selectedNode
  // changes, this behavior resets.
  const [domRef, shouldRender] =
    useWhenOnScreenAfterNewValueDebounced(selectedNode);

  const updatePanelInput = useCallback<any>(
    (newInput: GraphTypes.Node) => {
      if (selectFunction.nodeType === 'void') {
        throw new Error('Invalid cell selection function (void)');
      }
      if (
        HL.filterNodes(
          newInput,
          checkNode =>
            checkNode.nodeType === 'var' && checkNode.varName === 'input'
        ).length === 0
      ) {
        if (updateInput != null) {
          updateInput(newInput);
        }
        return;
      }
      const called = weave.callFunction(newInput, {input: selectFunction});
      const doUpdate = async () => {
        recordEvent('UPDATE_COLUMN_EXPRESSION_VIA_CELL');
        try {
          const refined = await weave.refineNode(called, {row: rowNode});
          updateTableState(Table.updateColumnSelect(table, colId, refined));
        } catch (e) {
          return Promise.reject(e);
        }
        return Promise.resolve();
      };
      doUpdate().catch(e => {
        console.error('PanelTable error', e);
        throw new Error(e);
      });
    },
    [
      colId,
      rowNode,
      selectFunction,
      table,
      updateTableState,
      updateInput,
      weave,
    ]
  );
  const newContextVars = useMemo(() => {
    return {
      // TODO: This is just plain wrong, callFunction doesn't adjust types!
      domain: weave.callFunction(selectFunction, {row: inputNode}),
      row: rowNode,
    };
  }, [selectFunction, inputNode, rowNode, weave]);
  return (
    <div
      ref={domRef}
      data-test-should-render={shouldRender}
      style={{width: '100%', height: '100%'}}
      // style={{
      //   ...getPanelStackDims(handler, selectedNode.type, config),
      // }}>
    >
      {curPanelId == null ? (
        <div>-</div>
      ) : (
        shouldRender &&
        selectFunction.nodeType !== 'void' &&
        selectedNode.nodeType !== 'void' &&
        handler != null && (
          <PanelContextProvider
            // Make a new variable "domain" available to child cells.
            // This can be used to get the full range of the input data.
            newVars={newContextVars}>
            <PanelComp2
              input={selectedNode}
              inputType={selectFunction.type}
              loading={false}
              panelSpec={handler}
              configMode={false}
              context={panelContext}
              config={config}
              updateConfig={updatePanelConfig}
              updateContext={updatePanelContext}
              updateInput={updatePanelInput}
            />
          </PanelContextProvider>
        )
      )}
    </div>
  );
};

export const Value: React.FC<{
  table: Table.TableState;
  valueNode: GraphTypes.Node;
  config: any;
  panelContext: any;
  colId: string;
  updateTableState(newConfig: any): void;
  updatePanelContext(newContext: any): void;
}> = ({
  table,
  valueNode,
  config,
  panelContext,
  colId,
  updateTableState,
  updatePanelContext,
}) => {
  const updatePanelConfig = TH.useUpdatePanelConfig(
    updateTableState,
    table,
    colId
  );
  const {handler, curPanelId} = usePanelStacksForType(valueNode.type, '', {
    excludeTable: true,
    excludePlot: true,
  });

  return (
    <>
      {curPanelId == null ? (
        <div>
          No panel for type{' '}
          {TypeHelpers.defaultLanguageBinding.printType(valueNode.type)}
        </div>
      ) : (
        handler != null && (
          <PanelComp2
            input={valueNode}
            inputType={valueNode.type}
            loading={false}
            panelSpec={handler}
            configMode={false}
            context={panelContext}
            config={config}
            updateConfig={updatePanelConfig}
            updateContext={updatePanelContext}
          />
        )
      )}
    </>
  );
};
