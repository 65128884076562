import * as CG from '@wandb/cg';
import * as TypeHelpers from '@wandb/cg';
import * as Op from '@wandb/cg';
import {useNodeWithServerType} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import {QuickPanelWBReactTable} from '../../../components/Panel2/PanelWBReactTable';

const inputType = 'artifactVersion' as const;
type ConfigType = {};

export const PanelArtifactVersionConsumptionTable: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType>
> = props => {
  const usageRunsNode = React.useMemo(
    () =>
      Op.opArtifactVersionUsedBy({
        artifactVersion: props.input,
      }),
    [props.input]
  );

  const usageRunsNodeSummaryNode = React.useMemo(
    () => Op.opRunSummary({run: usageRunsNode}),
    [usageRunsNode]
  );
  const usageRunsNodeSummaryNodeType = useNodeWithServerType(
    usageRunsNodeSummaryNode
  );

  const downCols = React.useMemo(() => {
    let cols = downstreamTableArtifactColumns();
    if (
      !usageRunsNodeSummaryNodeType.loading &&
      TypeHelpers.isAssignableTo(
        usageRunsNodeSummaryNodeType.result.type,
        TypeHelpers.list(TypeHelpers.typedDict({}))
      )
    ) {
      cols = [
        cols[0],
        ...Object.entries(
          TypeHelpers.typedDictPropertyTypes(
            TypeHelpers.listObjectType(usageRunsNodeSummaryNodeType.result.type)
          )
        ).reduce((acc, a) => {
          if (
            !a[0].startsWith('_') &&
            TypeHelpers.isAssignableTo(
              a[1],
              TypeHelpers.maybe(TypeHelpers.union(['number']))
            )
          ) {
            acc.push({
              name: `s.${a[0]}`,
              selectFn: Op.opPick({
                obj: Op.opRunSummary({run: CG.varNode('run', 'row')}),
                key: Op.constString(a[0]),
              }),
            });
          }
          return acc;
        }, [] as typeof cols),
        ...cols.slice(1),
      ];
    }
    return cols;
  }, [
    usageRunsNodeSummaryNodeType.loading,
    usageRunsNodeSummaryNodeType.result,
  ]);

  return (
    <div style={{height: '100%'}}>
      <QuickPanelWBReactTable input={usageRunsNode as any} columns={downCols} />
    </div>
  );
};

function downstreamTableArtifactColumns() {
  const rowType = 'run';
  return [
    {
      name: 'Run',
      selectFn: Op.opRunLink({
        run: CG.varNode(rowType, 'row'),
      }),
    },
    {
      name: 'Job Type',
      selectFn: Op.opRunJobType({
        run: CG.varNode(rowType, 'row'),
      }),
    },
    {
      name: 'Project',
      selectFn: Op.opProjectLink({
        project: Op.opRunProject({
          run: CG.varNode(rowType, 'row'),
        }),
      }),
    },
    {
      name: 'User',
      selectFn: Op.opUserLink({
        user: Op.opRunUser({
          run: CG.varNode(rowType, 'row'),
        }),
      }),
    },
    {
      name: 'Used Artifacts',
      selectFn: Op.opJoinToStr({
        arr: Op.opArtifactVersionName({
          artifactVersion: Op.opRunUsedArtifactVersions({
            run: CG.varNode(rowType, 'row'),
          }),
        }),
        sep: Op.constString(', '),
      }),
    },
    {
      name: 'Logged Artifacts',
      selectFn: Op.opJoinToStr({
        arr: Op.opArtifactVersionName({
          artifactVersion: Op.opRunLoggedArtifactVersions({
            run: CG.varNode(rowType, 'row'),
          }),
        }),
        sep: Op.constString(', '),
      }),
    },

    {
      name: 'Ran On',
      selectFn: Op.opRunCreatedAt({
        run: CG.varNode(rowType, 'row'),
      }),
    },
    {
      name: 'Duration (sec)',
      selectFn: Op.opRunRuntime({
        run: CG.varNode(rowType, 'row'),
      }),
    },
  ];
}
