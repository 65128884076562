import {MatchParams} from '@wandb/common/types/base';
import {fuzzyMatchWithMapping} from '@wandb/common/util/fuzzyMatch';
import React, {useCallback, useRef, useState} from 'react';
import {Input} from 'semantic-ui-react';

import * as Urls from '../../util/urls';
import {RESULTS_LIMIT} from './constants';
import {SearchContextProvider} from './SearchContext';
import {SearchInput} from './SearchInput';
import {SearchResultsDropdown} from './SearchResultsDropdown';
import {
  LocalSearchResult,
  SearchNavProject,
  SearchNavQueryData,
  SearchNavTeam,
} from './types';
import {useSearchNavAnalytics} from './useSearchNavAnalytics';
interface LocalSearchProps {
  data: SearchNavQueryData;
  matchParams: MatchParams;
  history: any;
  onClose: () => void;
}

export const LocalSearch = React.memo(
  ({data, matchParams, history, onClose}: LocalSearchProps) => {
    const {trackSearchNavUsed} = useSearchNavAnalytics();
    const [currentQuery, setCurrentQuery] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showAllEntityResults, setShowAllEntityResults] = useState(false);
    const [showAllContributedResults, setShowAllContributedResults] =
      useState(false);
    const [showAllRemainingResults, setShowAllRemainingResults] =
      useState(false);

    const inputRef = useRef<Input | null>(null);
    const keyboardOptionsRef = useRef<LocalSearchResult[]>([]);

    const blur = useCallback(() => {
      onClose();
      if (document.activeElement) {
        (document.activeElement as any).blur();
      }
    }, [onClose]);

    const clearQuery = useCallback(() => {
      setCurrentQuery('');
    }, []);

    const blurAndClear = useCallback(() => {
      onClose();
      setCurrentQuery('');
      if (document.activeElement) {
        (document.activeElement as any).blur();
      }
    }, [onClose]);

    const onKeyDown = useCallback(
      (e: any) => {
        if (e.keyCode === 27) {
          blur();
        }
        if (e.keyCode === 13) {
          const option = keyboardOptionsRef.current[selectedIndex];
          if (option != null) {
            trackSearchNavUsed(option.url);
            history.push(option.url);
            blurAndClear();
          }
        }
        if (e.keyCode === 38) {
          e.preventDefault();
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
          }
        }
        if (e.keyCode === 40) {
          e.preventDefault();
          if (selectedIndex < keyboardOptionsRef.current.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          }
        }
      },
      [blur, blurAndClear, history, selectedIndex, trackSearchNavUsed]
    );

    const projectToLocalSearchResult: (
      project: SearchNavProject
    ) => LocalSearchResult = useCallback((project: SearchNavProject) => {
      return {
        type: 'project',
        name: project.name,
        meta: project.entityName,
        url: Urls.project({
          name: project.name,
          entityName: project.entityName,
        }),
      };
    }, []);

    const teamToLocalSearchResult: (team: SearchNavTeam) => LocalSearchResult =
      useCallback((team: SearchNavTeam) => {
        return {
          type: 'team',
          name: team.name,
          url: Urls.teamPage(team.name),
          photoUrl: team.photoUrl,
        };
      }, []);

    const {entityName} = matchParams;

    if (!data.viewer) {
      return <></>;
    }

    const alreadyFoundId: {
      [key: string]: boolean;
    } = {};

    const entityOptions = data.projects.edges.map(project => {
      alreadyFoundId[project.node.id] = true;
      return projectToLocalSearchResult(project.node);
    });

    const contributedOptions: LocalSearchResult[] = [];
    if (currentQuery) {
      data.viewer.projects.edges.forEach(project => {
        if (!alreadyFoundId[project.node.id]) {
          alreadyFoundId[project.node.id] = true;
          contributedOptions.push(projectToLocalSearchResult(project.node));
        }
      });
    }

    const remainingOptions: LocalSearchResult[] = [];
    if (currentQuery) {
      data.viewer.teams.edges.forEach(team => {
        remainingOptions.push(teamToLocalSearchResult(team.node));
        team.node.projects.edges.forEach(project => {
          if (!alreadyFoundId[project.node.id]) {
            remainingOptions.push(projectToLocalSearchResult(project.node));
          }
        });
      });
    }

    /**
     * Filter down all of the available options to those that fuzzy match
     * the current query
     */
    const contributedResults = fuzzyMatchWithMapping<LocalSearchResult>(
      contributedOptions,
      currentQuery,
      result => result.name
    );

    const entityResults = fuzzyMatchWithMapping<LocalSearchResult>(
      entityOptions,
      currentQuery,
      result => result.name
    );

    const remainingResults = fuzzyMatchWithMapping<LocalSearchResult>(
      remainingOptions,
      currentQuery,
      result => result.name
    );

    /**
     * The displayed options either show the first N results up to the
     * RESULTS_LIMIT, or if the `showAll[type]Results` flag is flipped
     * the entire list is displayed
     */
    const displayedResults = {
      contributed: showAllContributedResults
        ? contributedResults
        : contributedResults.slice(0, RESULTS_LIMIT),
      entity: showAllEntityResults
        ? entityResults
        : entityResults.slice(0, RESULTS_LIMIT),
      remaining: showAllRemainingResults
        ? remainingResults
        : remainingResults.slice(0, RESULTS_LIMIT),
    };

    /**
     * The keyboard options ref is a flat list of all the displayed results
     */
    keyboardOptionsRef.current = [
      ...displayedResults.entity,
      ...displayedResults.contributed,
      ...displayedResults.remaining,
    ];

    return (
      <SearchContextProvider
        currentQuery={currentQuery}
        displayedResults={displayedResults}
        entityName={entityName}
        hiddenResultsCount={{
          contributed: contributedResults.length - RESULTS_LIMIT,
          entity: entityResults.length - RESULTS_LIMIT,
          remaining: remainingResults.length - RESULTS_LIMIT,
        }}
        results={{
          contributed: contributedResults,
          entity: entityResults,
          remaining: remainingResults,
        }}
        selectedIndex={selectedIndex}>
        <div
          className={`local-search ${currentQuery ? '' : 'empty'}`}
          onBlur={blur}>
          <SearchInput
            blur={blur}
            clearQuery={clearQuery}
            currentQuery={currentQuery}
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            setCurrentQuery={setCurrentQuery}
            setShowAllContributedResults={setShowAllContributedResults}
            setShowAllEntityResults={setShowAllEntityResults}
            setShowAllRemainingResults={setShowAllRemainingResults}
          />
          <SearchResultsDropdown
            blurAndClear={blurAndClear}
            setShowAllContributedResults={setShowAllContributedResults}
            setShowAllEntityResults={setShowAllEntityResults}
            setShowAllRemainingResults={setShowAllRemainingResults}
            showAllContributedResults={showAllContributedResults}
            showAllEntityResults={showAllEntityResults}
            showAllRemainingResults={showAllRemainingResults}
            teams={data.viewer.teams}
          />
        </div>
      </SearchContextProvider>
    );
  }
);
