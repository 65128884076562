import styled, {css} from 'styled-components';

import {GLOBAL_COLORS} from '../../util/colors';
import InspectorPopout from '../InspectorPopout';
import QueryEditor from '../Vega3/QueryEditor';
import WBReactTable from '../WBReactTable';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 4px;
`;

export const EditButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  i.icon {
    margin: 0;
  }
`;

export const TextAreaWrapper = styled.div<{editing: boolean}>`
  position: relative;
  ${EditButton} {
    opacity: 0;
    transition: opacity 0.15s;
    ${props =>
      props.editing &&
      css`
        opacity: 1;
        background: rgb(70, 160, 244);
      `}
  }
  &:hover {
    ${EditButton} {
      opacity: 1;
    }
  }
`;

export const TextArea = styled.textarea`
  background: #f6f6f6;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Inconsolata;
  width: 100%;
  font-size: 13px;
  padding: 4px;
`;

export const Popout = styled(InspectorPopout)`
  padding: 0;
  display: flex;
  height: 400px;
`;

export const StyledQueryEditor = styled(QueryEditor)`
  width: 340px;
  height: 100%;
`;

export const ResultTableWrapper = styled.div`
  border-left: 1px solid ${GLOBAL_COLORS.outline.toString()};
  height: 100%;
  width: 340px;
`;

export const ResultTable = styled(WBReactTable)`
  border: none;
  border-radius: 0;
  width: 100%;
  height: 100%;
  .ui.secondary.menu {
    display: none;
  }
  .ReactTable {
    height: 100%;
  }
  .rt-td {
    white-space: nowrap;
  }
  .rt-tr-group {
    max-height: 40px;
  }
  .rt-thead .rt-th {
    border-top: none !important;
  }
`;
