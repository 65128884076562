import {VegaPanel2Config} from '@wandb/common/components/PanelVega2/common';
import * as _ from 'lodash';
import React from 'react';

import {Table} from '../../../common/src/util/csv';
import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import {useRunsQueryContext} from '../../state/runs/hooks';
import * as Panels from '../../util/panels';
import * as QueryTS from '../../util/queryts';
import * as VegaLib3 from '../../util/vega3';
export type {VegaPanel2Config};
export {getPanelDefID} from '@wandb/common/components/PanelVega2/common';

const PANEL_TYPE = 'Vega2';

export const INITIAL_CONFIG: VegaPanel2Config = {
  transform: VegaLib3.DEFAULT_TRANSFORM,
};

function transformQuery(query: QueryTS.Query, config: VegaPanel2Config) {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

const useTableData = (pageQuery: QueryTS.Query, config: VegaPanel2Config) => {
  const context = useRunsQueryContext();
  const {templateVals} = VegaLib3.queryTemplates(pageQuery, context);

  const {loading, result, cols} = VegaLib3.useVegaQuery(
    config.userQuery ?? VegaLib3.defaultRunSetsQuery,
    config.transform || VegaLib3.TRANSFORM_WHEN_MISSING,
    templateVals
  );

  // remove unmapping field
  const colsWithoutUnmap = _.without(cols, '');

  const table: Table = {cols: colsWithoutUnmap, data: result};

  return {table, loading};
};

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, VegaPanel2Config> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
  useTableData,

  exportable: {
    csv: true,
    image: true,
  },
};
