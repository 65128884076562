import {WBMenuOption} from '@wandb/ui';
import * as _ from 'lodash';
import React from 'react';
import {ThemeContext} from 'styled-components';

import {__Field} from '../../generated/apiSchema';
import {
  fieldIsHidden,
  getDefaultFieldFromSchemaField,
  QueryField,
  QueryTemplateArg,
} from '../../util/vega3';
import QueryArgsEditor from './QueryArgsEditor';
import {schemaTypeToColoredString} from './QueryEditor';
import * as QueryEditorStyles from './QueryEditor.styles';
import QueryEditorDeleteButton from './QueryEditorDeleteButton';
import * as S from './QueryFieldEditor.styles';
import QueryFieldsEditor from './QueryFieldsEditor';

interface QueryFieldEditorProps {
  typeName: string;
  field: QueryField;
  alias?: string;
  schemaFields: __Field[];
  fixedField?: QueryField;
  templateArgs: QueryTemplateArg[];
  indentLevel: number;
  deleteField(): void;
  setField(field: QueryField): void;
}

const QueryFieldEditor: React.FC<QueryFieldEditorProps> = props => {
  const {
    alias,
    typeName,
    field,
    fixedField,
    schemaFields,
    templateArgs,
    indentLevel,
    deleteField,
    setField,
  } = props;
  const themeContext = React.useContext(ThemeContext);
  const [hoveringDelete, setHoveringDelete] = React.useState(false);
  const schemaField = _.find(schemaFields, f => f.name === field.name);
  let nextType = schemaField?.type;
  let nextTypeName = nextType?.name;
  while (nextType != null && nextTypeName == null && nextType.ofType != null) {
    nextType = nextType?.ofType;
    nextTypeName = nextType?.name;
  }

  const isFixed = fixedField != null;

  const options: WBMenuOption[] = schemaFields
    .filter(sf => !fieldIsHidden(sf.name))
    .map(sf => ({
      key: sf.name,
      value: sf.name,
      text: sf.name,
      render: ({hovered, selected}) => (
        <S.FieldOption hovered={hovered}>
          <S.FieldOptionIcon name={selected ? 'check' : 'blank'} />
          {sf.name}:&nbsp;{schemaTypeToColoredString(sf.type)}
        </S.FieldOption>
      ),
    }));
  return (
    <div>
      <div
        style={{
          display: 'inline',
          position: 'relative',
        }}>
        <S.SelectWrapper>
          {!isFixed && (
            <QueryEditorDeleteButton
              onMouseEnter={() => setHoveringDelete(true)}
              onMouseLeave={() => setHoveringDelete(false)}
              onClick={deleteField}
            />
          )}
          <QueryEditorStyles.Fadeable fade={hoveringDelete}>
            {alias != null && alias + ':'}
            <S.Select
              popupStyle={{
                borderRadius: 2,
                boxShadow: `0px 0px 4px 1px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px ${themeContext.popupBorder}`,
                transform: 'translateX(-36px)',
              }}
              menuBackgroundColor={themeContext.clickable}
              options={options}
              value={field.name}
              autoMenuWidth
              disabled={isFixed}
              onSelect={v => {
                const selectedSchemaField = _.find(
                  schemaFields,
                  sf => sf.name === v
                );
                if (!selectedSchemaField) {
                  throw new Error('Selected nonexistent schema field');
                }
                setField(getDefaultFieldFromSchemaField(selectedSchemaField));
              }}></S.Select>
          </QueryEditorStyles.Fadeable>
        </S.SelectWrapper>
        {/* <ModifiedDropdown
        loading={false}
        style={{flexGrow: 1}}
        placeholder="+ new field"
        search
        options={options}
        debounceTime={50}
        value={field.name}
        onChange={(e, {value}) => {
          setField({name: value as string, fields: []});
        }}
      /> */}
        <QueryEditorStyles.Fadeable
          fade={hoveringDelete}
          style={{
            display: 'inline',
            alignItems: 'center',
          }}>
          {(schemaField?.args || []).length !== 0 && (
            <QueryArgsEditor
              typeName={typeName}
              fieldName={field.name}
              args={field.args}
              fixedArgs={fixedField?.args}
              schemaArgs={schemaField?.args || []}
              templateArgs={templateArgs}
              indentLevel={indentLevel}
              charsBefore={field.name.length + 2}
              setArgs={newArgs => setField({...field, args: newArgs})}
            />
          )}
          {/* {field.name !== '' && (
        <Popup
          position="bottom center"
          hoverable
          trigger={
            <span className="fake-link" style={{marginLeft: 8}}>
              schema
            </span>
          }
          content={
            <pre
              style={{
                flexGrow: 1,
                fontSize: 12,
                maxHeight: 300,
                overflow: 'auto',
              }}>
              {JSON.stringify(schemaField, undefined, 2)}
            </pre>
          }
        />
      )} */}
          {nextType?.kind === 'OBJECT' && <span>&nbsp;{'{'}</span>}
        </QueryEditorStyles.Fadeable>
      </div>
      <QueryEditorStyles.Fadeable
        fade={hoveringDelete}
        style={{display: 'block'}}>
        {nextType?.kind === 'OBJECT' && (
          <>
            <QueryFieldsEditor
              typeName={nextTypeName || ''}
              fields={field.fields}
              fixedFields={fixedField?.fields}
              templateArgs={templateArgs}
              indentLevel={indentLevel}
              setFields={newFields => {
                setField({...field, fields: newFields});
              }}
            />
            {'}'}
          </>
        )}
      </QueryEditorStyles.Fadeable>
    </div>
  );
};

export default QueryFieldEditor;
