import {
  applyFilter,
  getFilterFromBBoxConfig,
} from '@wandb/common/util/PointCloudFiltering';
import {
  BabylonPointCloud,
  RenderFullscreen,
  RenderScreenshot,
} from '@wandb/common/util/render_babylon';
import {
  getFilteringOptionsForPointCloud,
  loadPointCloud,
} from '@wandb/common/util/SdkPointCloudToBabylon';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Icon} from 'semantic-ui-react';

import * as Analytics from '../services/analytics/productEvents';
import {loadBoxData} from '../state/media/actions';
import {MediaPanelCardControl} from './MediaCard';

export interface Media3DProps {
  width: number;
  height: number;
  url: string;
  blob: Blob;
  controls?: MediaPanelCardControl;
  mediaKey: string;
  mediaPanelRefId: string;
}

export const PointCloud = ({
  width,
  height,
  blob,
  mediaKey,
  mediaPanelRefId,
  controls,
}: Media3DProps) => {
  const [renderErrorMessage, setRenderErrorMessage] = useState<string>();
  const {pointCloud, loadErrorMessage} = usePointCloud(
    blob,
    mediaKey,
    mediaPanelRefId,
    controls
  );

  const babylonContainerRef = useRef<HTMLDivElement>(null);

  // Load babylon lib asynchronously to perform bundle splitting
  type BabylonLib = typeof import('@wandb/common/util/render_babylon');
  const [babylonLib, setBabylon] = useState<BabylonLib>();
  useEffect(() => {
    import('@wandb/common/util/render_babylon').then(setBabylon);
  }, []);

  const [screenshot, setScreenshot] = useState<string>();

  useEffect(() => {
    let cleanup: CallableFunction | undefined;
    const renderScreenshot = async () => {
      if (babylonContainerRef.current && babylonLib && pointCloud) {
        try {
          const result = babylonLib.renderJsonPoints<RenderScreenshot>(
            pointCloud,
            {
              fullscreen: false,
              width,
              height,
            },
            controls?.cameraControl
          );
          cleanup = result.cleanup;
          const img = await babylonLib.renderScreenshot(result);
          setScreenshot(img);
        } catch (e) {
          if (e instanceof Error) {
            setRenderErrorMessage(e.message);
          }
          console.error(e);
        }
      }
    };

    renderScreenshot();

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [width, height, pointCloud, babylonLib, controls]);

  useEffect(() => {
    Analytics.pointCloudViewed({type: 'preview'});
  }, []);

  // Callback launch fullscreen viewer
  // NOTE: This has to stay a callback because fullscreen
  // requests can only happen as a response to user actions
  const requestFullscreen = useCallback(() => {
    let cleanup: CallableFunction | undefined;
    const renderFullscreen = async () => {
      if (babylonLib && babylonContainerRef.current && pointCloud) {
        const domElement = babylonContainerRef.current;
        const result = await babylonLib.renderJsonPoints<RenderFullscreen>(
          pointCloud,
          {
            domElement,
            fullscreen: true,
          }
        );
        babylonLib.renderFullscreen(result);
        cleanup = result.cleanup;
        Analytics.pointCloudViewed({type: 'fullscreen'});
      }
    };
    renderFullscreen();

    return cleanup;
  }, [pointCloud, babylonLib]);

  const errorMessage = renderErrorMessage ?? loadErrorMessage;
  if (errorMessage) {
    return <div className="card3d">Error: {errorMessage}</div>;
  }

  return (
    <div className="media-card">
      <div className="object3D-card-babylon" ref={babylonContainerRef} />
      {screenshot && (
        <>
          <img alt="point-cloud-card" src={screenshot} />
          <div className="media-card__fullscreen" onClick={requestFullscreen}>
            <Icon
              size="large"
              link
              className="media-card__fullscreen-button"
              name="expand arrows alternate"
            />
          </div>
        </>
      )}
    </div>
  );
};

const usePointCloud = (
  blob: Blob,
  mediaKey: string,
  mediaPanelRefId: string,
  controls: MediaPanelCardControl | undefined
): {
  pointCloud: BabylonPointCloud | undefined;
  loadErrorMessage: string | undefined;
} => {
  const dispatch = useDispatch();
  const [pointCloud, setPointCloud] = useState<BabylonPointCloud>();
  const [rawPointCloud, setRawPointCloud] = useState<BabylonPointCloud>();
  const [loadErrorMessage, setLoadErrorMessage] = useState<string>();

  useEffect(() => {
    new Response(blob).text().then(t => {
      try {
        setRawPointCloud(loadPointCloud(t));
      } catch (e) {
        if (e instanceof Error) {
          setLoadErrorMessage(e.message);
        } else {
          setLoadErrorMessage('unknown error');
          console.error('unknown render error:', e);
        }
      }
    });
  }, [blob]);

  // Dispatch of pointcloud data and applying the filter run in separate useEffects
  // because we only want to run dispatch after the pointcloud data itself
  // changes.
  useEffect(() => {
    if (rawPointCloud) {
      const {boxData, newClassIdToLabel} =
        getFilteringOptionsForPointCloud(rawPointCloud);
      setPointCloud(
        applyFilter(
          rawPointCloud,
          getFilterFromBBoxConfig(
            controls?.boundingBoxControl,
            newClassIdToLabel
          )
        )
      );

      // The filtering UI (useBoundingBoxData()) needs a way to know labels the
      // bounding boxes have in order to enable the user to select filtering options.
      // Rather than having it load the point cloud bounding box data files on its own,
      // we put the data we just read from the point cloud file into redux,
      // and then the filter can load it as needed.
      dispatch(
        loadBoxData({
          mediaKey,
          panelID: mediaPanelRefId,
          mediaID: '1', // This value is not currently used by selectors for this data
          boxData,
        })
      );
    }
  }, [
    rawPointCloud,
    dispatch,
    mediaKey,
    mediaPanelRefId,
    controls?.boundingBoxControl,
  ]);

  return {pointCloud, loadErrorMessage};
};

export default PointCloud;
