import React, {memo} from 'react';
import {useEffect, useRef} from 'react';

import {useSelectedCounts} from '../../state/graphql/selectedCountQuery';
import * as ViewHooks from '../../state/views/hooks';
import * as RunSetTypes from '../../state/views/runSet/types';
import * as TempSelectionsActions from '../../state/views/tempSelections/actions';
import * as TempSelectionsTypes from '../../state/views/tempSelections/types';
import * as Run from '../../util/runs';
import * as SM from '../../util/selectionmanager';
import {MultiStateCheckbox} from '../MultiStateCheckbox';
import {RunSelectAll} from '../RunSelectAll';
import {DisabledFeaturesMap} from './RunSelectorInner';

export interface RunsTableHeaderProps {
  totalRowCount?: number;
  displayedRows: Run.Run[];
  runSetRef: RunSetTypes.Ref;
  tempSelectionsRef: TempSelectionsTypes.Ref;
  showTempSelection: boolean;
  onlyShowSelected?: boolean;
  readOnly?: boolean;
  isSingleMode?: boolean;
  isInReport?: boolean;
  disabledFeatures?: DisabledFeaturesMap;
  onAllItemsTempSelected(): void;
  onAllItemsTempUnselected(): void;
  onVisibilitySelectAll(): void;
  onVisibilitySelectNone(): void;
  onSetOnlyShowSelected(newSetting: boolean): void;
}

const RunsTableHeaderComp = (props: RunsTableHeaderProps) => {
  const prevRowCountRef = useRef(props.totalRowCount);
  useEffect(() => {
    prevRowCountRef.current = props.totalRowCount;
  });
  const selectedCounts = useSelectedCounts(
    props.runSetRef,
    props.tempSelectionsRef,
    prevRowCountRef.current !== props.totalRowCount
  );

  const runSetPart = ViewHooks.usePart(props.runSetRef);
  const tempSelectNone = ViewHooks.useViewActionBindAll(
    TempSelectionsActions.selectNone,
    props.tempSelectionsRef,
    runSetPart.groupSelectionsRef
  );
  const tempSelectSome = ViewHooks.useViewActionBindAll(
    TempSelectionsActions.selectSome,
    props.tempSelectionsRef,
    runSetPart.groupSelectionsRef,
    props.displayedRows
  );
  const tempSelectAllVisible = ViewHooks.useViewActionBindAll(
    TempSelectionsActions.selectAllVisible,
    props.tempSelectionsRef,
    runSetPart.groupSelectionsRef
  );

  const groupSelections = ViewHooks.useWhole(runSetPart.groupSelectionsRef);
  const tempSelections = ViewHooks.useWhole(props.tempSelectionsRef);
  const groupTempSelections = {
    grouping: groupSelections.grouping,
    selections: tempSelections,
    expandedRowAddresses: groupSelections.expandedRowAddresses,
  };
  const rootTempCheckedState = SM.getCheckedState(
    groupTempSelections,
    {} as Run.Run,
    0
  );

  return (
    <>
      {!props.readOnly && props.showTempSelection && (
        <MultiStateCheckbox
          data-test="select-all-runs-checkbox"
          className="row-index-or-checkbox"
          checked={rootTempCheckedState}
          onClick={() => {
            if (rootTempCheckedState !== 'unchecked') {
              tempSelectNone();
              props.onAllItemsTempUnselected();
            } else {
              tempSelectSome();
              props.onAllItemsTempSelected();
            }

            const actionLocation = props.isInReport ? 'report' : 'runs table';
            window.analytics?.track('Select all runs clicked', {
              location: actionLocation,
            });
          }}
        />
      )}
      {!props.readOnly &&
        !props.isSingleMode &&
        !props.disabledFeatures?.['toggle-visibility'] && (
          <RunSelectAll
            allCheckedState={SM.getCheckedState(
              groupSelections,
              {} as Run.Run,
              0
            )}
            onSelectAll={props.onVisibilitySelectAll}
            onSelectNone={props.onVisibilitySelectNone}
            onlyShowSelected={props.onlyShowSelected}
            isInReport={props.isInReport}
            selectAllVisible={tempSelectAllVisible}
            setOnlyShowSelected={props.onSetOnlyShowSelected}
          />
        )}
      <span>
        Name&nbsp;{''}
        <span className="selected-count">
          {selectedCounts.tempSelectedCount > 0
            ? `(${selectedCounts.tempSelectedCount} selected)`
            : `(${selectedCounts.selectedCount} visualized)`}
        </span>
      </span>
    </>
  );
};

export const RunsTableHeader = memo(RunsTableHeaderComp);
