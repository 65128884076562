import React, {FC, ReactNode} from 'react';
import {Button, Popup} from 'semantic-ui-react';

import {REPORT_PANEL_GRID_MENU} from './types';

type ReportPanelGridButtonProps = {
  buttonText: string;
  dataTest?: string;
  openedMenu?: REPORT_PANEL_GRID_MENU;
  menu: REPORT_PANEL_GRID_MENU;
  menuPopup: ReactNode;
  analytics?: any;
  setOpenedMenu: (menu?: REPORT_PANEL_GRID_MENU) => void;
};

export const ReportPanelGridButton: FC<ReportPanelGridButtonProps> = ({
  buttonText,
  dataTest,
  openedMenu,
  menu,
  menuPopup,
  analytics,
  setOpenedMenu,
}) => {
  return (
    <Popup
      basic
      on="click"
      position="top center"
      open={openedMenu === menu}
      onOpen={() => setOpenedMenu(menu)}
      onClose={() => {
        analytics?.();
        setOpenedMenu(undefined);
      }}
      trigger={<Button data-test={dataTest}>{buttonText}</Button>}
      popperModifiers={{
        preventOverflow: {enabled: false},
        flip: {enabled: false},
      }}>
      {menuPopup}
    </Popup>
  );
};
