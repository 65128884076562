import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import * as globals from '@wandb/common/css/globals.styles';
import React, {memo, useCallback, useState} from 'react';
import TimeAgo from 'react-timeago';
import {Popup} from 'semantic-ui-react';

import {ValueOp} from '../../util/filters';
import {Key as RunKey, Value as RunValue} from '../../util/runs';
import {FilterUneditable} from '../Filters/FilterUneditable';

interface WBTableCellCreatedAtProps {
  createdAt: string;
  addFilter?(key: RunKey, op: ValueOp, value: RunValue): void;
}

function abbreviateTimeUnit(unit: string): string {
  if (unit === 'month') {
    return 'mo';
  } else {
    return unit[0];
  }
}

const WBTableCellCreatedAtComp: React.FC<WBTableCellCreatedAtProps> = ({
  addFilter,
  createdAt,
}) => {
  const [hover, setHover] = useState(false);
  const onIconHover = useCallback(() => setHover(true), []);
  const onIconUnhover = useCallback(() => setHover(false), []);

  const createdAtFilter = React.useMemo(
    () => ({
      key: {section: 'run', name: 'createdAt'} as RunKey,
      op: '<=' as ValueOp,
      value: createdAt,
    }),
    [createdAt]
  );

  const addCreatedAtFilter = React.useCallback(() => {
    if (addFilter != null) {
      addFilter(createdAtFilter.key, createdAtFilter.op, createdAtFilter.value);
    }
  }, [
    addFilter,
    createdAtFilter.key,
    createdAtFilter.op,
    createdAtFilter.value,
  ]);

  const filterIcon = React.useMemo(
    () => (
      <LegacyWBIcon
        title=""
        className="icon-filter"
        name="snowflake"
        style={{pointerEvents: 'none'}}
      />
    ),
    []
  );

  return (
    <>
      <TimeAgo
        live={true}
        date={new Date(createdAt)}
        formatter={(val, unit, suffix) =>
          `${val}${abbreviateTimeUnit(unit)} ${suffix}`
        }
      />
      {addFilter && (
        <div
          style={{display: 'inline-block', cursor: 'pointer'}}
          className="icon-wrap"
          onClick={addCreatedAtFilter}
          onMouseEnter={onIconHover}
          onMouseLeave={onIconUnhover}>
          {hover ? (
            <Popup
              className="popup--cell-header"
              inverted
              position="bottom center"
              on="hover"
              hoverable
              flowing
              open
              trigger={filterIcon}
              size="mini"
              content={
                <React.Fragment>
                  <div style={{color: globals.gray500}}>Freeze run set:</div>
                  <div style={{display: 'flex'}}>
                    <FilterUneditable filter={createdAtFilter} />
                  </div>
                </React.Fragment>
              }
            />
          ) : (
            filterIcon
          )}
        </div>
      )}
    </>
  );
};

export const WBTableCellCreatedAt = memo(WBTableCellCreatedAtComp);
