import React from 'react';

interface PageWithSidebarProps {
  className?: string;
}

const PageWithSidebar: React.FC<PageWithSidebarProps> = React.memo(
  ({className, children}) => {
    return (
      <div className={'page-with-sidebar ' + (className ? className : '')}>
        {children}
      </div>
    );
  }
);

export default PageWithSidebar;
