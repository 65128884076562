import * as globals from '@wandb/common/css/globals.styles';
import {TargetBlank} from '@wandb/common/util/links';
import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

export const BlankPageContainer = styled.div<{extraTopMargin?: boolean}>`
  margin: 0 auto;
  padding-top: ${props => (props.extraTopMargin ? '90' : '42')}px;
  text-align: center;
  max-width: 638px;

  h1 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 34px;
    line-height: 43px;
    font-weight: 600;
  }

  h2 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
  }

  h3 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
`;

export const BlankPageGraphics = styled.div`
  display: flex;
  height: 160px;
  margin-bottom: 24px;
  justify-content: space-evenly;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 288px;
  margin: 0 auto 24px;
`;

export const SecondaryButton = styled(Button)`
  &&&&& {
    width: 136px;
    border-radius: 3px;
    border-color: ${globals.gray500};
    background: none;
    padding: 11px 0;

    &:hover {
      background: ${globals.hover};
    }
  }
`;

export const PrimaryButton = styled(Button)`
  &&&&& {
    width: 136px;
    border-radius: 3px;
    border-color: ${globals.primary};
    color: white;
    background: ${globals.primary};
    padding: 11px 0;

    &:hover {
      background: ${globals.primaryDark};
    }
  }
`;

export const DocLink = styled(TargetBlank)`
  color: ${globals.gray600};
  margin: 0 auto;
  text-align: center;
`;
