import {unEscapeDots} from '@wandb/cg';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
import * as Panels from '../../util/panels';
import * as QueryTS from '../../util/queryts';
import {WeaveConfig} from './common';
export type {WeaveConfig};
export {weavePanelForSummaryTableKey} from './common';

const PANEL_TYPE = 'Weave';

export function getKeyOfWeavePanel(panel: Panels.PanelWithConfig<'Weave'>) {
  const expNodeFromOp = panel.config?.panel2Config?.exp?.fromOp;
  const targetKey = panel.config?.key;
  if (targetKey == null) {
    return null;
  }
  let res: string | null = null;
  if (expNodeFromOp?.name === 'pick') {
    const val: string | null = expNodeFromOp?.inputs?.key?.val;
    const expKey = val == null ? val : unEscapeDots(val);
    if (targetKey === expKey) {
      const expRunSummary = expNodeFromOp?.inputs?.obj?.fromOp;
      if (expRunSummary?.name === 'run-summary') {
        if (expRunSummary?.inputs?.run?.nodeType === 'var') {
          res = expKey ?? null;
        }
      }
    }
  } else if (expNodeFromOp?.name === Panel2.panelIdToPanelOpName('merge')) {
    // This branch will be useful in the case we uncomment the above injection.
    // After automatically doing .merge
    res = expNodeFromOp?.inputs?.input?.fromOp?.inputs?.key?.val ?? null;
  }
  return res;
}

function transformQuery(
  query: QueryTS.Query,
  config: WeaveConfig
): RunsDataQuery {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

export const Spec: Panels.PanelSpec<typeof PANEL_TYPE, WeaveConfig> = {
  type: PANEL_TYPE,
  noEditMode: true,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
  getTitleFromConfig: config => `Weave: ${config.key || '(empty)'}`,
};
