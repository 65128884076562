import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

import {REPORT_FONT_SIZE} from '../constants';

export const Paragraph = styled.div<{
  showHint?: boolean;
  textAlign?: undefined | 'center';
}>`
  font-family: Source Serif Pro;
  font-size: ${REPORT_FONT_SIZE}px;
  line-height: ${REPORT_FONT_SIZE * 1.5}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px !important;
  text-align: ${props =>
    props.textAlign == null ? 'default' : props.textAlign};
  ${props =>
    props.showHint &&
    css`
      &::before {
        content: "Type '/' for commands";
        position: absolute;
        color: ${globals.gray500};
      }
    `}
`;
