import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {constString} from '@wandb/cg';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {Panel2Loader} from '@wandb/weave-ui/components/Panel2/PanelComp';
import {PanelContextProvider} from '@wandb/weave-ui/components/Panel2/PanelContext';
import {useRefineExpressionEffect} from '@wandb/weave-ui/components/Panel2/panellib/libexp';
import {useWeaveContext} from '@wandb/weave-ui/context';
import {RemoteEcosystemComputeGraphContextProvider} from '@wandb/weave-ui/contextProviders';
import React from 'react';
import {useCallback, useMemo, useState} from 'react';

import {RootQueryPanel} from './RootQueryPanel';

interface WeaveArtifactObjectProps {
  uri: string;
  varName: string;
}

export type PanelWeaveArtifactObjectProps = Panel2.PanelProps<
  'artifactVersion',
  any
>;

// convert an artifact name into a name that is safe to use in the expression editor
export function fixVarName(varName: string): string {
  return varName === '' ? 'obj' : varName.replace(/[^a-zA-Z0-9_]/g, '_');
}

export const PanelWeaveArtifactObject: React.FC<PanelWeaveArtifactObjectProps> =
  props => {
    const {input} = props;

    const dataNode = useMemo(() => {
      const artifactNode = Op.opArtifactVersionArtifactSequence({
        artifactVersion: input,
      });
      const artifactNameNode = Op.opArtifactName({artifact: artifactNode});
      const versionedNameNode = Op.opArtifactVersionName({
        artifactVersion: input,
      }) as GraphTypes.Node<'string'>;
      const sequenceNode = Op.opArtifactVersionArtifactSequence({
        artifactVersion: input,
      });
      const projectNode = Op.opArtifactProject({artifact: sequenceNode});
      const projectNameNode = Op.opProjectName({project: projectNode});
      const entityNameNode = Op.opEntityName({
        entity: Op.opProjectEntity({project: projectNode}),
      });
      const entityProjectNameNode = Op.opStringAppend({
        str: Op.opStringAppend({str: entityNameNode, suffix: constString('/')}),
        suffix: projectNameNode,
      });

      const uriNode = Op.opStringAppend({
        str: Op.opStringAppend({
          str: Op.opStringAppend({
            str: constString('wandb-artifact://'),
            suffix: entityProjectNameNode,
          }),
          suffix: constString('/'),
        }),
        suffix: versionedNameNode,
      }) as GraphTypes.Node<'string'>;

      return Op.opDict({
        uri: uriNode,
        varName: artifactNameNode,
      } as any);
    }, [input]);

    const {result, loading} = useNodeValue(dataNode);

    return !loading ? (
      <RemoteEcosystemComputeGraphContextProvider>
        <WeaveArtifactObject
          uri={result.uri}
          varName={fixVarName(result.varName)}
        />
      </RemoteEcosystemComputeGraphContextProvider>
    ) : (
      <Panel2Loader />
    );
  };

export const WeaveArtifactObject: React.FC<WeaveArtifactObjectProps> =
  props => {
    const {uri, varName} = props;
    const weave = useWeaveContext();

    const [config, setConfig] = useState({
      panelConfig: {exp: Op.varNode('invalid', varName)},
    });
    const updateConfig = useCallback(
      (newConfig: any) => {
        setConfig({...config, ...newConfig});
      },
      [config]
    );
    const objectNode = useMemo(
      () => Op.opGet({uri: Op.constString(uri)}),
      [uri]
    );

    const frame = useMemo(() => ({}), []);

    const {loading: isRefining, result: refinedObjectNode} =
      useRefineExpressionEffect(objectNode as any, frame, weave);
    const vars = useMemo(
      () => ({
        [varName]: refinedObjectNode,
      }),
      [refinedObjectNode, varName]
    );

    if (isRefining) {
      return <WandbLoader />;
    }

    return (
      <div>
        <PanelContextProvider newVars={vars as any}>
          <RootQueryPanel config={config} updateConfig={updateConfig} />
        </PanelContextProvider>
      </div>
    );
  };
