import {TargetBlank} from '@wandb/common/util/links';
import React, {FC, memo, useContext, useEffect, useState} from 'react';

import {StandardPlanCheckoutModal} from '../pages/Billing/Checkout/StandardPlanCheckoutModal';
import {
  TeamOrgQueryResult,
  useCurrentTeamOrg,
  useShowNudgeBar,
} from '../util/nudgeBars';
import {CONTACT_SALES_CLICKED_ANALYTICS, Org} from '../util/pricing';
import * as urls from '../util/urls';
import {AggressiveRedirectEnforcement} from './AggressiveRedirectEnforcement';
import * as S from './ExpiringSubscriptionNudgeBar.styles';
import {
  EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE,
  GlobalNudgeBarContext,
  GlobalNudgeBarState,
  GlobalNudgeBarUpdaterContext,
  NudgeBarType,
} from './GlobalNudgeBarContextProvider';
import {
  NudgeBarLarge,
  NudgeBarLargeActionButton,
  NudgeBarLargeActionLink,
  NudgeBarLargeText,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

// TODO: 1. The nudge bar part of this component would be removed after
// - the release of enforcement for expired subscription
// TODO: 2. This component would be irrelevant and can be removed once we introduce both
// - the backend change to update the subscription status to "disabled" on expiration date
// - the enforcement for disabled (deactivated) subscription
const ExpiredSubscriptionEnforcementComp: FC = () => {
  const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);

  const {states} = useContext(GlobalNudgeBarContext);
  const {refetch} = useContext(GlobalNudgeBarUpdaterContext);

  const currentTeamOrg: TeamOrgQueryResult | null = useCurrentTeamOrg();
  const showNudgeBar = useShowNudgeBar();

  // if there is no nudge bar state, we have no nudge bar to show
  if (states == null || states.length === 0) {
    return null;
  }

  // if user is on usage page or on billing page, no need to show this nudge bar
  if (!showNudgeBar) {
    return null;
  }

  const currentOrganizationStates = states?.filter(
    state =>
      state.organization?.name === currentTeamOrg?.entity?.organization?.name
  );

  // if this organization doesn't have state, no need to show this nudge bar
  if (currentOrganizationStates == null) {
    return null;
  }

  const expiredState = currentOrganizationStates?.find(
    state =>
      state.renderedNudgeBar ===
        NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade ||
      state.renderedNudgeBar ===
        NudgeBarType.ExpiredSubscriptionEnforcementWithContactUs
  );

  // if there is no relevant nudge bar type (i.e. ExpiredSubscriptionEnforcement),
  // no need to show this nudge bar
  if (expiredState == null) {
    return null;
  }

  // if their subscription is expired after enforcement
  // we lock users in the subscriptions page (a.k.a. AggressiveRedirectEnforcement)
  if (EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE <= new Date()) {
    return (
      <AggressiveRedirectEnforcement
        data-test="aggressive-redirect"
        analyticsLocation="expired subscription enforcement"
        entityName={currentTeamOrg?.entity.name}
        organizationName={currentTeamOrg?.entity.organization?.name}
      />
    );
  }

  // until the enforcement release, we show a nudge bar to encourage
  // user with expired subscription to upgrade or reach out to use to avoid enforcement
  const title = (
    <NudgeBarLargeTitle data-test="nudge-bar-title">
      Your organization's trial subscription is expired.
    </NudgeBarLargeTitle>
  );

  const onUpgrade = () => {
    window.analytics?.track('Upgrade Subscription Clicked', {
      location: 'expired subscription enforcement',
    });
    setOpenCheckoutModal(true);
  };

  return (
    <>
      <NudgeBarLarge data-test="expired-nudge-bar">
        {title}
        <ExpiredNudgeBarText expiredState={expiredState} />
        <ExpiredNudgeBarAction
          expiredState={expiredState}
          onUpgrade={onUpgrade}
        />
      </NudgeBarLarge>

      {openCheckoutModal && (
        <StandardPlanCheckoutModal
          data-test="upgrade-checkout-modal"
          org={expiredState.organization as Org}
          onTransactionCompleted={refetch}
          onClose={() => setOpenCheckoutModal(false)}
        />
      )}
    </>
  );
};

function onContactSalesClick(orgName?: string) {
  window.analytics?.track(CONTACT_SALES_CLICKED_ANALYTICS, {
    location: 'expiring subscription nudge bar',
    orgName,
  });
}

type ExpiredNudgeBarTextProps = {
  expiredState: GlobalNudgeBarState;
};

// helper components for the expired nudge bar content
const ExpiredNudgeBarText: FC<ExpiredNudgeBarTextProps> = ({expiredState}) => {
  const showsUpgradeText =
    expiredState.renderedNudgeBar ===
    NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade;

  // we use this component to trigger an analytics for nudge bar
  // to avoid sending analytics at the top of parent component ExpiredSubscriptionEnforcement
  useEffect(() => {
    if (expiredState == null) {
      return;
    }

    if (showsUpgradeText) {
      window.analytics?.track('Upgrade Subscription Banner Displayed', {
        context: expiredState.renderedNudgeBar,
        location: 'expired subscription nudge bar',
        org: expiredState.organization?.id,
        orgName: expiredState.organization?.name,
      });
    }

    // renderedNudgBar is NudgeBarType.ContactUsNudgeBarForExpiringSubscription
    window.analytics?.track('Contact Us Subscription Banner Displayed', {
      context: expiredState.renderedNudgeBar,
      location: 'expired subscription nudge bar',
      org: expiredState.organization?.id,
      orgName: expiredState.organization?.name,
    });
    // disabling the lint check since we only want to
    // send analytics once when this components first mounts
    // eslint-disable-next-line
  }, []);

  if (expiredState == null) {
    return null;
  }

  if (
    expiredState.renderedNudgeBar ===
    NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade
  ) {
    return (
      <NudgeBarLargeText data-test="nudge-bar-upgrade-text">
        You have {expiredState.daysUntilEnforcement} days to upgrade to keep
        using your {expiredState.organization?.name} team. Please upgrade your
        subscription below or{' '}
        <S.ContactSalesLink
          href={urls.contactSalesPricing()}
          onClick={() => onContactSalesClick(expiredState.organization?.name)}>
          contact us
        </S.ContactSalesLink>{' '}
        to continue using W&B without interruption.
      </NudgeBarLargeText>
    );
  }

  //  renderedNudgeBar is NudgeBarType.ExpiredSubscriptionEnforcementWithContactUs
  return (
    <NudgeBarLargeText data-test="nudge-bar-contact-us-text">
      You have {expiredState.daysUntilEnforcement} days to upgrade to keep using
      your {expiredState.organization?.name} team. Please contact us to upgrade
      your subscription and continue using W&B without interruption.
    </NudgeBarLargeText>
  );
};

type ExpiredNudgeBarActionProps = {
  expiredState: GlobalNudgeBarState;
  onUpgrade: () => void;
};

const ExpiredNudgeBarAction: FC<ExpiredNudgeBarActionProps> = ({
  expiredState,
  onUpgrade,
}) => {
  if (expiredState == null) {
    return null;
  }

  if (
    expiredState.renderedNudgeBar ===
    NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade
  ) {
    return (
      <NudgeBarLargeActionLink
        onClick={onUpgrade}
        data-test="nudge-bar-upgrade-button">
        <NudgeBarLargeActionButton>Upgrade</NudgeBarLargeActionButton>
      </NudgeBarLargeActionLink>
    );
  }

  //  renderedNudgeBar is NudgeBarType.ExpiredSubscriptionEnforcementWithContactUs
  return (
    <TargetBlank
      href={urls.contactSalesPricing()}
      onClick={() => onContactSalesClick(expiredState.organization?.name)}>
      <NudgeBarLargeActionLink data-test="nudge-bar-contact-us-button">
        <NudgeBarLargeActionButton>Contact us</NudgeBarLargeActionButton>
      </NudgeBarLargeActionLink>
    </TargetBlank>
  );
};

export const ExpiredSubscriptionEnforcement = memo(
  ExpiredSubscriptionEnforcementComp
);
