import {defaultLanguageBinding} from '../../language/default';
import {makeOp} from '../../opStore';
import {docType} from '../../util/docs';

// Not exposed yet. Needs to handle tags, nulls (see number.ts)

export const opTypeString = makeOp({
  hidden: true,
  name: 'type-string',
  argTypes: {type: 'type'},
  description: `Returns the ${docType('string')} representation of a ${docType(
    'type'
  )}`,
  argDescriptions: {type: `The ${docType('type')}`},
  returnValueDescription: `The ${docType(
    'string'
  )} representation of the ${docType('type')}`,
  returnType: 'string',
  resolver: async (inputs, forwardGraph, forwardOp, context) => {
    return defaultLanguageBinding.printType(inputs.type);
  },
});
