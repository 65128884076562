import PanelError from '@wandb/common/components/elements/PanelError';
import {MediaString} from '@wandb/common/types/media';
import docUrl, {lookupDocUrl} from '@wandb/common/util/doc_urls';
import {TargetBlank} from '@wandb/common/util/links';
import {isUndefined} from 'lodash';
import * as React from 'react';
import {Icon, Popup} from 'semantic-ui-react';

interface MessageMediaNotFoundProps {
  stepIndex?: number;
  mediaKey?: string;
  mediaIndex?: number;
  mediaType?: MediaString;
  basic?: boolean; // If true, shows a question mark icon with the message as a popup
}
const MessageMediaNotFound = (props: MessageMediaNotFoundProps) => {
  const {stepIndex, mediaKey, mediaIndex, basic} = props;
  let mediaType: string = props.mediaType || 'media';
  const docHref = lookupDocUrl(mediaType) || docUrl.logs;
  if (mediaType === 'table') {
    mediaType = 'tables';
  } else if (mediaType === 'object3D') {
    mediaType = '3D objects';
  }
  const errorMessage = (
    <PanelError
      className="media-missing"
      message={
        <>
          <div>
            This run didn't log {mediaType || 'media'}
            {mediaKey && ` for key "${mediaKey}", step ${stepIndex}`}
            {isUndefined(mediaIndex) ? '' : `, index ${mediaIndex}`}.{' '}
            <TargetBlank href={docHref}>Docs →</TargetBlank>
          </div>
        </>
      }
    />
  );
  return basic ? (
    <Popup
      flowing
      hoverable
      trigger={
        <Icon
          name="question circle outline"
          size="big"
          style={{margin: 'auto'}}
        />
      }
      content={errorMessage}
    />
  ) : (
    errorMessage
  );
};

export default MessageMediaNotFound;
