/**
 * Select widget that supports user entered text with N previous choices + config saved in the browser's localStorage.
 */
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, {useRef} from 'react';
import CreatableSelect from 'react-select/creatable';

import * as S from './SelectLocalStorage.styles';

interface LocalStorageOption {
  value: string;
  config: {[key: string]: any};
  at: number;
}

interface SelectOption {
  value: number;
  config: {[key: string]: any};
  label: string;
  __isNew__?: boolean;
}

interface SelectLocalStorageProps {
  saved: LocalStorageOption[];
  placeholder: string;
  onSelect: (value: string | null, config: {[key: string]: any}) => void;
}

const formatOptionLabel = ({value, label, __isNew__}: SelectOption) => {
  if (value === 0) {
    return <S.ClearOption>{label}</S.ClearOption>;
  }
  return (
    <S.SavedOption>
      <Typography>{label}</Typography>
      {!__isNew__ && value !== 0 && (
        <S.Timestamp variant="caption">
          Launched {moment(value).format('ddd YYYY-MM-DD hh:mm:ss a zz')}
        </S.Timestamp>
      )}
    </S.SavedOption>
  );
};

const SelectLocalStorage: React.FC<SelectLocalStorageProps> = ({
  saved,
  placeholder,
  onSelect,
}) => {
  const selectRef: any = useRef();

  const options = saved.map(s => {
    return {value: s.at, label: s.value, config: s.config};
  });
  if (options.length) {
    // If we have saved items, add an option that deletes them.
    options.push({value: 0, label: 'Clear saved items', config: {}});
  }

  const onChange = (option: SelectOption | null) => {
    if (option) {
      if (option.value === 0) {
        setTimeout(() => selectRef.current.clearValue(), 0);
        onSelect(null, {});
      } else {
        onSelect(option.label, option.config);
      }
    }
  };

  return (
    <CreatableSelect
      ref={selectRef}
      options={options}
      noOptionsMessage={({inputValue}) =>
        !inputValue ? 'No saved values' : 'No results found'
      }
      formatOptionLabel={formatOptionLabel}
      formatCreateLabel={value => value} // Don't show "Create <user text>", just user text
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default SelectLocalStorage;
