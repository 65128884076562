import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

export const textStyles = css`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
`;

// used for links and closing the details
export const ActionText = styled.span`
  ${textStyles}
  font-weight: 600;
  line-height: 24px;

  cursor: pointer;
  color: ${globals.TEAL_DARK};
`;

export const BoldTitle = styled.span`
  ${textStyles}
  font-weight: 600;
  color: ${globals.GRAY_800};
`;

export const UsagePricingDetailsTitle = styled(BoldTitle)`
  font-size: 16px;
`;

export const UsageBoxContainer = styled.div`
  ${textStyles}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 4px;

  background: rgba(24, 27, 31, 0.03);
  border-radius: 4px;
  margin-bottom: 8px;
`;
