import React from 'react';
import {useCallback, useMemo} from 'react';

import {backendWeaveOpsUrl} from '@wandb/common/config';
import * as Graph from '@wandb/cg';
import * as Types from '@wandb/cg';
import {registerPanel} from './PanelRegistry';
import * as Panel2 from './panel';
import {useLet, useNodeValue} from '../../cgreact';
import {Panel, Panel2Loader} from './PanelComp';
import {PanelContextProvider} from './PanelContext';
import {constNodeUnsafe, loadRemoteOpStore, ServerOpDef} from '@wandb/cg';
import {memoize} from 'lodash';
import {useWeaveContext} from '../../context';

// This file contains the implementation of a
// "pure weave panel", ie one that is defined entirely in terms of
// weave objects, with no corresponding React components.
import {WeaveInterface} from '@wandb/cg';
import {OpStore} from '@wandb/cg';
import * as TypeHelpers from '@wandb/cg';
import * as GraphTypes from '@wandb/cg';
import {callOpVeryUnsafe} from '@wandb/cg';
// import {constNodeUnsafe} from '@wandb/cg/browser/basicNodes';
import {toWeaveType} from './PanelGroup2';
import {WeaveMessage} from './WeaveMessage';
import {useDeepMemo} from '@wandb/common/state/hooks';

interface UserPanelConfig {
  // The panel returned by the panel's config op.
  _configRenderAsPanel?: {
    id: string;
    input_node: GraphTypes.Node;
    config: any;
  };
  // The panel returned by the panel's render op.
  _renderAsPanel?: {
    id: string;
    input_node: GraphTypes.Node;
    config: any;
  };
  [key: string]: any;
}
type UserPanelProps = Panel2.PanelProps<'any', UserPanelConfig>;

const useUserPanelVars = (
  props: UserPanelProps,
  panelInputName: string,
  panelInputNodeType: Types.Type,
  opConfigType: Types.Type,
  renderOpName: string,
  renderAsPanelConfigAttr: '_renderAsPanel' | '_configRenderAsPanel',
  weave: WeaveInterface,
  skip: boolean = false
) => {
  const {config, updateConfig, updateConfig2} = props;

  // We can figure out the type as a TypedDict
  // but we want to send it back as the actual ObjectType!
  const configTypedDictType = toWeaveType(config);
  const configType = useMemo(
    () => ({
      ...(opConfigType as any),
      ...configTypedDictType.propertyTypes,
    }),
    [configTypedDictType.propertyTypes, opConfigType]
  );

  const updateConfigVal = useCallback(
    (key: string, newVal: any) => {
      if (updateConfig2 != null) {
        updateConfig2(() => {
          return newVal;
        });
      }
    },
    [updateConfig2]
  );
  let statements = useMemo(
    () => ({
      config: constNodeUnsafe(config == null ? 'none' : configType, config),
    }),
    [config, configType]
  );
  statements = useDeepMemo(statements);

  const updatableConfig = useLet(statements, updateConfigVal).config;
  // const configType = toWeaveType(props.config);
  const calledRender = useMemo(() => {
    const renderOpArgs: {[key: string]: GraphTypes.Node} = {
      [panelInputName]: {
        nodeType: 'const' as const,
        type: {
          type: 'function' as const,
          inputTypes: {},
          outputType: panelInputNodeType,
        },
        val: {
          nodeType: 'var' as const,
          type: panelInputNodeType,
          name: panelInputName,
        },
      },
    };
    if (opConfigType !== 'none') {
      renderOpArgs.config = {
        nodeType: 'const' as const,
        type: {
          type: 'function' as const,
          inputTypes: {},
          outputType: configType,
        },
        val: {
          nodeType: 'var' as const,
          type: configType,
          name: 'config',
        },
      };
    }
    return !skip && props.config?.[renderAsPanelConfigAttr] == null
      ? (callOpVeryUnsafe(renderOpName, renderOpArgs) as GraphTypes.Node)
      : Graph.voidNode();
  }, [
    panelInputName,
    panelInputNodeType,
    opConfigType,
    configType,
    skip,
    props.config,
    renderAsPanelConfigAttr,
    renderOpName,
  ]);
  console.log('CALLED RENDER', calledRender);
  const renderOpResult = useNodeValue(calledRender);
  const renderAsPanel = useMemo(
    () =>
      // If the panel has been modified in the UI, use the modified panel config
      // instead of what would be returned by the render op
      props.config?.[renderAsPanelConfigAttr] ??
      (renderOpResult.loading
        ? {
            id: '',
            input_node: Graph.voidNode(),
            config: undefined,
          }
        : {
            id: renderOpResult.result.id,
            input_node: renderOpResult.result.input_node,
            config: renderOpResult.result.config,
          }),
    [props.config, renderAsPanelConfigAttr, renderOpResult]
  );
  const updatePanelConfig = useCallback(
    (newPanelConfig: any) => {
      updateConfig({
        [renderAsPanelConfigAttr]: {
          id: renderAsPanel.id,
          input_node: renderAsPanel.input_node,
          config: {...renderAsPanel.config, ...newPanelConfig},
        },
      });
    },
    [
      updateConfig,
      renderAsPanelConfigAttr,
      renderAsPanel.id,
      renderAsPanel.input_node,
      renderAsPanel.config,
    ]
  );
  const updatePanelConfig2 = useCallback(
    (change: <T>(oldConfig: T) => Partial<T>) => {
      if (updateConfig2 == null) {
        return;
      }
      updateConfig2((oldConfig: UserPanelConfig): UserPanelConfig => {
        const oldRenderAsPanel = oldConfig[renderAsPanelConfigAttr] ?? {
          id: renderOpResult.result.id,
          input_node: renderOpResult.result.input_node,
          config: renderOpResult.result.config,
        };
        return {
          [renderAsPanelConfigAttr]: {
            id: oldRenderAsPanel.id,
            input_node: oldRenderAsPanel.input_node,
            config: {
              ...oldRenderAsPanel.config,
              ...change(oldConfig[renderAsPanelConfigAttr]),
            },
          },
        };
      });
    },
    [renderAsPanelConfigAttr, renderOpResult.result, updateConfig2]
  );

  // We shouldn't need to do the dereferencing here. It should happen in
  // the framework, right before execution time (maybe in useNodeValue).
  // But doing it more broadly will need some good testing to make sure
  // we don't breaking anything.
  // TODO: fix
  const inputNode = useMemo(
    () =>
      weave.callFunction(renderAsPanel.input_node, {
        [panelInputName]: props.input,
      }),
    [panelInputName, props.input, renderAsPanel.input_node, weave]
  );
  return useMemo(
    () => ({
      renderAsPanel,
      updatableConfig,
      updatePanelConfig,
      updatePanelConfig2,
      modified: props.config?.[renderAsPanelConfigAttr] != null,
      inputNode,
      loading: calledRender.nodeType !== 'void' && renderOpResult.loading,
    }),
    [
      renderAsPanel,
      updatableConfig,
      updatePanelConfig,
      updatePanelConfig2,
      props.config,
      renderAsPanelConfigAttr,
      inputNode,
      calledRender.nodeType,
      renderOpResult.loading,
    ]
  );
};

const registerUserPanel = (op: ServerOpDef, configOp?: ServerOpDef) => {
  const inputNames = Object.keys(op.input_types);
  const inputTypes = Object.values(op.input_types);
  const opInputName0 = inputNames[0];
  if (!TypeHelpers.isFunction(inputTypes[0])) {
    console.warn('non-function type for panel op: ', op);
    return;
  }
  const inputType = inputTypes[0].outputType;

  if (inputNames[1] != null && inputNames[1] !== 'config') {
    console.warn('panel op 2nd arg name is not "config": ', op);
    return;
  }
  const configPropertyTypes: {[key: string]: Types.Type} = {};
  let opConfigType: Types.Type = 'none';
  if (inputTypes[1] != null) {
    opConfigType = inputTypes[1];
    for (const [name, type] of Object.entries(opConfigType)) {
      if (name !== 'type' && name !== '_is_object') {
        configPropertyTypes[name] = type;
      }
    }
    if (!(inputTypes[1] as any)._is_object) {
      console.warn('panel op config type must be ObjectType: ', op);
    }
  }

  const ConfigComponent: React.FC<UserPanelProps> | undefined =
    configOp == null
      ? undefined
      : props => {
          const weave = useWeaveContext();
          const {
            updatableConfig,
            renderAsPanel,
            updatePanelConfig,
            updatePanelConfig2,
            loading,
            inputNode,
          } = useUserPanelVars(
            props,
            opInputName0,
            props.input.type,
            opConfigType,
            configOp.name,
            '_configRenderAsPanel',
            weave
          );
          const panelVars = useMemo(() => {
            const v: {[key: string]: GraphTypes.Node} = {
              [opInputName0]: props.input,
            };
            if (opConfigType !== 'none') {
              v.config = updatableConfig;
            }
            return v;
          }, [props.input, updatableConfig]);
          if (loading) {
            return <Panel2Loader />;
          }
          return (
            <PanelContextProvider newVars={panelVars}>
              <Panel
                panelSpec={renderAsPanel.id}
                input={inputNode}
                config={renderAsPanel.config}
                updateConfig={updatePanelConfig}
                updateConfig2={updatePanelConfig2}
                updateInput={props.updateInput}
              />
            </PanelContextProvider>
          );
        };
  const RenderComponent: React.FC<UserPanelProps> = props => {
    const weave = useWeaveContext();
    const curConfigType = toWeaveType(props.config) as Types.TypedDictType;
    const targetConfigType = {
      type: 'typedDict' as const,
      propertyTypes: configPropertyTypes,
    };
    const configIsValid =
      opConfigType === 'none' ||
      TypeHelpers.isAssignableTo(curConfigType, targetConfigType);
    const {
      updatableConfig,
      renderAsPanel,
      updatePanelConfig,
      updatePanelConfig2,
      loading,
      inputNode,
    } = useUserPanelVars(
      props,
      opInputName0,
      props.input.type,
      opConfigType,
      op.name,
      '_renderAsPanel',
      weave,
      !configIsValid
    );
    const panelVars = useMemo(() => {
      const v: {[key: string]: GraphTypes.Node} = {
        [opInputName0]: props.input,
      };
      if (opConfigType !== 'none') {
        v.config = updatableConfig;
      }
      return v;
    }, [props.input, updatableConfig]);
    if (loading) {
      return <Panel2Loader />;
    }
    if (opConfigType !== 'none' && props.config == null) {
      return <WeaveMessage>Panel is not yet configured.</WeaveMessage>;
    }
    if (!configIsValid) {
      const invalidKeys: string[] = [];
      for (const key of new Set([
        ...Object.keys(curConfigType.propertyTypes),
        ...Object.keys(targetConfigType.propertyTypes),
      ])) {
        const curPropType = curConfigType.propertyTypes[key];
        const targetPropType = targetConfigType.propertyTypes[key];
        if (targetConfigType.propertyTypes[key] == null) {
          // This is ok, we can have extra keys in curPropType
        } else if (
          curPropType == null ||
          !TypeHelpers.isAssignableTo(curPropType, targetPropType)
        ) {
          invalidKeys.push(key);
        }
      }
      return (
        <WeaveMessage>
          <div>
            Panel's stored config type does not match its expected config type.
          </div>
          <div>These keys differ:</div>
          <ul>
            {invalidKeys.map(key => {
              const curPropType = curConfigType.propertyTypes[key];
              const targetPropType = targetConfigType.propertyTypes[key];
              return (
                <li>
                  <i>{key}</i>: target=
                  {weave.typeToString(targetPropType, false)} actual=
                  {curPropType == null
                    ? 'missing'
                    : weave.typeToString(curPropType, false)}
                </li>
              );
            })}
          </ul>
        </WeaveMessage>
      );
    }
    return (
      <PanelContextProvider newVars={panelVars}>
        <Panel
          panelSpec={renderAsPanel.id}
          input={inputNode}
          config={renderAsPanel.config}
          updateConfig={updatePanelConfig}
          updateConfig2={updatePanelConfig2}
          updateInput={props.updateInput}
        />
      </PanelContextProvider>
    );
  };

  registerPanel({
    id: op.name,
    ConfigComponent,
    Component: RenderComponent,
    inputType,
  });
};

const loadWeaveObjects = (): Promise<OpStore> => {
  return loadRemoteOpStore(backendWeaveOpsUrl()).then(
    ({remoteOpStore, userPanelOps}) => {
      // For now we use the convention that config op names end with '_config'
      const renderOps: ServerOpDef[] = [];
      const configOps: {[opName: string]: ServerOpDef} = {};
      for (const op of userPanelOps) {
        if (op.name.endsWith('_config')) {
          configOps[op.name] = op;
        } else {
          renderOps.push(op);
        }
      }
      for (const op of renderOps) {
        registerUserPanel(op, configOps[op.name + '_config']);
      }
      return remoteOpStore;
    }
  );
};

const memoedLoadWeaveObject = memoize(loadWeaveObjects);

export const useLoadWeaveObjects = (
  skip?: boolean
):
  | {loading: true; remoteOpStore: OpStore | null}
  | {loading: false; remoteOpStore: OpStore} => {
  const [loading, setLoading] = React.useState(true);
  const [remoteOpStore, setRemoteOpStore] = React.useState<OpStore | null>(
    null
  );

  React.useEffect(() => {
    if (!skip) {
      memoedLoadWeaveObject().then(loadedRemoteOpStore => {
        setRemoteOpStore(loadedRemoteOpStore);
        setLoading(false);
      });
    }
  }, [skip]);

  if (loading || remoteOpStore == null) {
    return {loading: true, remoteOpStore};
  }
  return {loading, remoteOpStore};
};
