import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import {GLOBAL_COLORS} from '../../util/colors';
import {
  InspectorPropertyLabel,
  InspectorPropertyWrapper,
} from '../Inspector.styles';
import {PropertyEditorComponent} from './property-editors';

const OptionIcon = styled(LegacyWBIcon)`
  &:hover,
  &.active {
    color: ${GLOBAL_COLORS.linkBlue.toString()};
  }
`;

/**
 * Compact way to offer a small list of options.
 * Default is required.
 */
const IconMenuPropertyEditor: PropertyEditorComponent<'icon-menu'> = props => {
  return (
    <InspectorPropertyWrapper>
      <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
      {props.options.map(option => (
        <OptionIcon
          onClick={() => {
            props.save(option.value);
          }}
          className={classNames({
            active: props.values.indexOf(option.value) !== -1,
          })}
          name={option.icon}
        />
      ))}
    </InspectorPropertyWrapper>
  );
};

export default IconMenuPropertyEditor;
