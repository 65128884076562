import {isVersionAlias} from '../util/artifacts';

export function colorIndex(alias: {alias: string}): number {
  if (isVersionAlias(alias)) {
    return -1;
  }

  let h = 0;
  for (let i = 0; i < alias.alias.length; i++) {
    const char = alias.alias.charCodeAt(i);
    // tslint:disable-next-line:no-bitwise
    h = (h << 5) - h + char;
    // tslint:disable-next-line:no-bitwise
    h = h & h; // Convert to 32bit integer
  }
  return Math.abs(h);
}
