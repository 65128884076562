import {DateFromUTCString} from '@wandb/common/util/time';
import {ApolloQueryResult} from 'apollo-client';
import React, {FC, useCallback, useMemo} from 'react';
import {CellInfo, SortFunction} from 'react-table';
import {Loader} from 'semantic-ui-react';

import {DefaultResourceConfig} from '../../../generated/graphql';
import WBReactTable from '../../WBReactTable';
import * as S from './DefaultResourceConfigTable.styles';
import DeleteDefaultResourceConfigModal from './DeleteDefaultResourceConfigModal';
import EditResourceConfigModal from './EditResourceConfigModal';

interface DefaultResourceConfigTableProps {
  entityName: string;
  configs: DefaultResourceConfig[];
  isInSettingsPage: boolean;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

export const DefaultResourceConfigTable: FC<DefaultResourceConfigTableProps> =
  ({entityName, configs, refetch, isInSettingsPage}) => {
    const columns = useMemo(() => {
      const baseColumns = [
        {
          Header: 'Resource Type',
          accessor: 'resource',
          minWidth: 70,
          Cell: (cellInfo: CellInfo) => {
            return <span title={cellInfo.value}>{cellInfo.value}</span>;
          },
        },
        {
          Header: 'Created',
          accessor: 'createdAt',
          minWidth: 60,
          Cell: (cellInfo: CellInfo) => {
            const createdAt = cellInfo.value;
            return (
              <span title={createdAt}>
                {DateFromUTCString(createdAt).toLocaleString()}
              </span>
            );
          },
        },
        {
          Header: 'Edit JSON',
          accessor: 'config',
          minWidth: 70,
          maxWidth: 100,
          Cell: (cellInfo: CellInfo) => {
            const drc = cellInfo.value;
            return (
              <span>
                <EditResourceConfigModal
                  entityName={entityName}
                  config={drc}
                  mode="EDIT"
                  refetch={refetch}
                />
              </span>
            );
          },
        },
        {
          Header: '',
          accessor: 'delete',
          minWidth: 40,
          maxWidth: 40,
          Cell: (cellInfo: CellInfo) => {
            const config = cellInfo.value;
            return (
              <span>
                <DeleteDefaultResourceConfigModal
                  config={config}
                  entityName={entityName}
                  refetch={refetch}
                />
              </span>
            );
          },
        },
      ];

      return baseColumns;
    }, [entityName, refetch]);

    const rows = useMemo(
      () =>
        configs?.map((drc: DefaultResourceConfig) => {
          const {resource = '', scope = null, createdAt = ''} = drc;

          return {
            searchString: `${resource}${scope?.Type}`,
            row: {
              resource,
              scope: scope?.Type,
              createdAt,
              config: drc,
              delete: drc,
            },
          };
        }),
      [configs]
    );

    // TODO: DRY with `defaultSortMethod` in `TeamMembersTable.tsx`
    // and possibly rewrite to be better typed instead of `a: any, b: any`
    const defaultSortMethod: SortFunction = useCallback((a, b, desc) => {
      const aVal = a?.toLowerCase().trim();
      const bVal = b?.toLowerCase().trim();

      // Empty cells are always at bottom of table when sorted
      if (desc) {
        if (aVal === '' && bVal !== '') {
          return -1;
        }
        if (aVal !== '' && bVal === '') {
          return 1;
        }
        if (aVal === '' && aVal === bVal) {
          return 0;
        }
      }

      // perform sort
      if (aVal > bVal) {
        return -1;
      }
      if (bVal > aVal) {
        return 1;
      }

      return 0;
    }, []);

    if (!entityName) {
      return (
        <S.LoaderWrapper>
          <Loader name="drc-table-loader" />
        </S.LoaderWrapper>
      );
    }

    return (
      <S.DRCTable $isInSettingsPage={isInSettingsPage}>
        <WBReactTable
          dataTest="drc-table"
          data={rows || []}
          columns={columns}
          defaultSorted={[{id: 'createdAt', desc: true}]}
          defaultSortMethod={defaultSortMethod}
          pageSize={10}
        />
      </S.DRCTable>
    );
  };
