import {TargetBlank} from '@wandb/common/util/links';
import styled from 'styled-components';

import WBReactTable from './WBReactTable';

export const StyledReactTable = styled(WBReactTable)`
  margin: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-top-width: 1px solid #e6e6e6;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const HeaderInfo = styled.div`
  font-size: 12px;
  &&& {
    cursor: mouse !important;
  }
`;

export const HeaderLink = styled(TargetBlank)`
  &&& {
    color: blue !important;
    cursor: pointer;
  }
`;
