import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React, {FC, useState} from 'react';
import {Mutation} from 'react-apollo';
import {Button, Divider, Header, Icon, Popup} from 'semantic-ui-react';

import {useOrganizationSubscriptionsQuery} from '../../generated/graphql';
import {
  DELETE_API_KEY_MUTATION,
  DeleteApiKeyMutationData,
  DeleteApiKeyMutationVariables,
  GENERATE_API_KEY_MUTATION,
  GenerateApiKeyMutationData,
  GenerateApiKeyMutationVariables,
} from '../../graphql/users';
import CopyableText from '../CopyableText';
import {DeleteUserConfirmationModal} from '../DeleteUserConfirmationModal';
import {useUserSettingsContext} from './UserSettingsContext';
import * as S from './UserSettingsDangerZone.style';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsDangerZone: FC = () => {
  const {viewer, refetch} = useUserSettingsContext();
  const subscriptions = useOrganizationSubscriptionsQuery();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  if (viewer == null) {
    return <UserSettingsPlaceholder />;
  }

  const isBillingUser =
    subscriptions.data?.viewer?.organizations?.some(
      org => org.billingUser?.username === viewer.username
    ) || false;

  const hasMultipleMembers =
    subscriptions.data?.viewer?.organizations?.some(
      org => org.members.length > 1
    ) || false;

  return (
    <>
      <div>
        <Header as="h4">API keys</Header>
        <Mutation<DeleteApiKeyMutationData, DeleteApiKeyMutationVariables>
          mutation={DELETE_API_KEY_MUTATION}
          onCompleted={refetch}>
          {deleteApiKey => {
            return (
              <div className="api-keys">
                {viewer.apiKeys.edges.map((k, index) => {
                  const key = k.node;
                  return (
                    <React.Fragment key={'api-block' + index}>
                      <div className="api-key-wrapper">
                        <div className="api-key">
                          <Icon className="key-icon" name="key" />
                          <CopyableText
                            text={key.name}
                            toastText="Copied key to clipboard."
                          />
                        </div>
                        <LegacyWBIcon
                          className="delete-action"
                          name="delete"
                          onClick={() =>
                            deleteApiKey({
                              variables: {id: k.node.id},
                            })
                          }
                        />
                      </div>
                      {index !== viewer.apiKeys.edges.length - 1 && <Divider />}
                    </React.Fragment>
                  );
                })}
              </div>
            );
          }}
        </Mutation>

        <Mutation<GenerateApiKeyMutationData, GenerateApiKeyMutationVariables>
          mutation={GENERATE_API_KEY_MUTATION}
          onCompleted={refetch}>
          {generateApiKey => {
            return (
              <Button size="tiny" onClick={() => generateApiKey()}>
                New key
              </Button>
            );
          }}
        </Mutation>
      </div>
      <S.DangerZoneDivider />
      <Header as="h4">Delete Account</Header>
      <div>
        <div>
          Watch out, account deletion cannot be reversed. <br />
          <br />
        </div>
        <DeleteUserConfirmationModal
          isOpen={isModalOpen}
          username={viewer.username}
          email={viewer.email}
          onClose={() => setIsModalOpen(false)}
        />
        {isBillingUser && hasMultipleMembers ? (
          <Popup
            inverted
            size="mini"
            position="top right"
            trigger={
              <span>
                <Button size="tiny" disabled={true} onClick={() => {}}>
                  Delete Account
                </Button>
              </span>
            }
            content="Billing user cannot be deleted"
          />
        ) : (
          <Button
            size="tiny"
            color="red"
            onClick={() => {
              setIsModalOpen(true);
            }}>
            Delete Account
          </Button>
        )}
      </div>
    </>
  );
};
