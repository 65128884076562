import callFunction, {callOpVeryUnsafe} from './callers';
import {Client} from './client';
import {
  expandAll,
  expandGeneratedWeaveOp,
  nodeIsExecutable,
  refineEditingNode,
  refineNode,
  replaceNodeAndUpdateDownstreamTypes,
  shouldSkipOpFirstInput,
  updateFunctionForInputs,
} from './hl';
import {ExpressionResult, JSLanguageBinding, LanguageBinding} from './language';
import {
  EditingNode,
  EditingOp,
  EditingOpInputs,
  Frame,
  isAssignableTo,
  Node,
  NodeOrVoidNode,
  OutputNode,
  Type,
  voidNode,
} from './model';
import {OpDefGeneratedWeave} from './opStore';
import {autosuggest} from './suggest';
import {WeaveInterface} from './weaveInterface';

// TODO (ts): move this implementation should be in the cg package
export class Weave implements WeaveInterface {
  private readonly languageBinding: LanguageBinding;

  constructor(readonly client: Client) {
    this.languageBinding = new JSLanguageBinding(this);
  }

  // Type-related
  typeIsAssignableTo(source: Type, target: Type): boolean {
    return isAssignableTo(source, target);
  }

  typeToString(type: Type, simple?: boolean): string {
    return this.languageBinding.printType(type, simple);
  }

  // Op-related
  op(id: string) {
    const op = this.client.opStore.getOpDef(id);
    if (op == null) {
      throw new Error(`Cannot find op with id "${id}"`);
    }
    return op;
  }

  callOp(opName: string, inputs: EditingOpInputs) {
    return callOpVeryUnsafe(opName, inputs);
  }

  // Language/expression-related
  // Convert an expression from expression language to actual CG
  // TODO(np): May contain voids?
  async expression(
    input: string,
    frame: Frame = {}
  ): Promise<ExpressionResult> {
    const result = await this.languageBinding.parse(input, frame);
    return {
      ...result,
      // TODO(np): May not be necessary here, consolidate this error handling
      expr: result.expr ?? voidNode(),
    };
  }

  forwardExpression(expr: EditingNode): EditingNode[] {
    const result: EditingNode[] = [];
    let cursor: EditingNode | null = expr;
    while (cursor != null) {
      result.push(cursor);
      switch (cursor.nodeType) {
        case 'void':
        case 'const':
        case 'var':
          cursor = null;
          break;
        case 'output':
          const opDef = this.op(cursor.fromOp.name);
          if (!shouldSkipOpFirstInput(opDef)) {
            cursor = null;
          } else {
            cursor = Object.values(cursor.fromOp.inputs)[0];
          }
          break;
      }
    }

    return result.reverse();
  }

  expToString(node: EditingNode, indent: number | null = 0): string {
    return this.languageBinding.printGraph(node, indent);
  }

  nodeIsExecutable(node: EditingNode): node is NodeOrVoidNode {
    return nodeIsExecutable(node);
  }

  callFunction(
    functionNode: NodeOrVoidNode,
    inputs: {[argName: string]: Node | EditingNode}
  ): Node {
    return callFunction(functionNode, inputs);
  }

  expandAll(node: EditingNode, frame: Frame) {
    return expandAll(this.client, node, frame);
  }

  expandGeneratedWeaveOp(
    opDef: OpDefGeneratedWeave,
    node: OutputNode,
    frame: Frame
  ) {
    return expandGeneratedWeaveOp(this.client, opDef, node, frame);
  }

  refineEditingNode(
    node: EditingNode,
    frame: Frame,
    cache?: Map<EditingNode, EditingNode>
  ) {
    return refineEditingNode(this.client, node, frame, cache);
  }

  refineNode(node: Node, frame: Frame) {
    return refineNode(this.client, node, frame);
  }

  replaceEditingNodeAndUpdateDownstreamTypes(
    graph: EditingNode,
    toReplace: EditingNode,
    replaceWith: EditingNode,
    frame: Frame
  ) {
    return replaceNodeAndUpdateDownstreamTypes(
      this.client,
      graph,
      toReplace,
      replaceWith,
      frame
    );
  }

  updateFunctionForInputs(
    fnNode: Node,
    // TODO(np): This is identical to a frame?
    inputs: Frame
  ) {
    return updateFunctionForInputs(this.client, fnNode, inputs);
  }

  // Suggestions
  suggestions(
    nodeOrOp: EditingNode | EditingOp,
    graph: EditingNode,
    frame: Frame,
    query?: string
  ) {
    return autosuggest(this.client, nodeOrOp, graph, frame, query);
  }
}
