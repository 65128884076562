/**
 * Contains common components and utilities for building config panels.
 * I expect this file to mature and eventually become a general-purpose
 * Weave graph editor.
 */

import React from 'react';
import * as _ from 'lodash';
import {ThemeProvider} from 'styled-components';

import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import NumberInput from '@wandb/common/components/elements/NumberInput';
import {TextInput} from '@wandb/common/components/elements/TextInput';

import * as S from './styles';

import {WeaveExpression} from '../../../panel/WeaveExpression';

import {themes} from '../Editor.styles';

export const ConfigOption: React.FC<
  {
    label: string;
    // component to append after the the config component, on the same line, such as
    // "add new y" buttons or "delete option" buttons. see panelplot config y for
    // an example.
    postfixComponent?: React.ReactElement;
  } & {[key: string]: any}
> = props => {
  return (
    <S.ConfigOption
      {..._.omit(props, ['label', 'children', 'postfixComponent'])}>
      {props.label !== '' ? (
        <S.ConfigOptionLabel>{props.label}</S.ConfigOptionLabel>
      ) : (
        <></>
      )}
      <S.ConfigOptionField>{props.children}</S.ConfigOptionField>
      {props.postfixComponent}
    </S.ConfigOption>
  );
};

export const ModifiedDropdownConfigField: React.FC<
  React.ComponentProps<typeof ModifiedDropdown>
> = props => {
  return (
    <ModifiedDropdown style={{flex: '1 1 auto', width: '100%'}} {...props} />
  );
};

export const NumberInputConfigField: React.FC<
  React.ComponentProps<typeof NumberInput>
> = props => {
  return (
    <NumberInput
      containerStyle={{flex: '1 1 auto', width: '100%'}}
      inputStyle={{width: '100%'}}
      {...props}
    />
  );
};

export const ExpressionConfigField: React.FC<
  React.ComponentProps<typeof WeaveExpression>
> = props => {
  return (
    <div style={{flex: '1 1 auto', width: '100%'}}>
      <ThemeProvider theme={themes.light}>
        <WeaveExpression
          noBox={true}
          frame={props.frame ?? {}}
          setExpression={props.setExpression}
          expr={props.expr}
          liveUpdate
        />
      </ThemeProvider>
    </div>
  );
};

export const TextInputConfigField: React.FC<
  React.ComponentProps<typeof TextInput>
> = props => {
  return (
    <div style={{flex: '1 1 auto', width: '100%'}}>
      <TextInput {...props} />
    </div>
  );
};
