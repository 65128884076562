import * as globals from '@wandb/common/css/globals.styles';
import {gray400, gray500, gray700} from '@wandb/common/css/globals.styles';
import {WBIcon, WBIconButton} from '@wandb/ui';
import {Input, Label} from 'semantic-ui-react';
import styled from 'styled-components';

export const EllipsesMenuButton = styled(WBIcon)`
  font-size: 24px;
  margin-left: 4px;
  cursor: pointer;
  align-items: center;
  padding-right: 1px;
`;

export const ArtifactItemString = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ArtifactTypeContainer = styled.div`
  margin-top: 4px;
  &:first-child {
    margin-top: 0;
  }
`;

export const ArtifactTypeBox = styled.div`
  position: sticky;
  z-index: 2;
  top: 0px;
  background-color: white;
  flex-grow: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${gray500};
  font-weight: 600;
  text-transform: uppercase;
  user-select: none;
`;

export const ArtifactsBox = styled.div`
  margin-left: 8px;
  padding-left: 4px;
  border-left: 1px solid ${gray400};
`;

export const AliasLabel = styled(Label)`
  padding: 0.2em !important;
  font-weight: 600 !important;
  font-size: 14px !important;
`;

export const ArtifactSidebarTitle = styled.div`
  padding-top: 25px;
  padding-left: 10px;
  font-size: 28px;
  background-color: white;
  display: flex;
  height: 49px;
  width: 100%;
`;

export const ArtifactSidebarRegistryLinkHeader = styled.div`
  padding-top: 15px;
  padding-left: 5px;
  background-color: white;
  display: flex;
  height: 49px;
  width: 100%;
`;

export const ArtifactSidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  background-color: white;
  height: 49px;
  width: 100%;
`;

export const ArtifactSearchBar = styled(Input)`
  flex: 1;
  padding: 8px;
  width: 100%;
  color: ${globals.gray600};
  & > i {
    line-height: 24px !important;
    font-size: 24px !important;
    color: ${globals.gray600} !important;
    > .icon {
      width: 1.8em;
    }
  }
` as unknown as typeof Input;

export const ArtifactsSidebarArtifactList = styled.div`
  margin-left: 9px;
  border-left: 1px solid ${gray400};
`;

export const ArtifactSidebarArtifactPaginate = styled.span`
  color: ${gray500};
`;

export const ArtifactSidebarTypePaginate = styled.span`
  color: ${gray700};
`;

export const ArtifactVersionLoad = styled.span`
  color: ${gray700};
  margin-left: 4px;
  font-size: 14px;
`;

export const ArtifactVersionContainer = styled.div`
  margin-bottom: 0;
  top: 24px;
  position: sticky;
  background-color: white;
`;

export const ArtifactSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

export const ArtifactSidebarContents = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;
  padding-left: 8px;
`;

export const ArtifactSidebarFooter = styled.div`
  flex: 0 0 auto;
  width: 100%;
  border-top: 1px solid rgb(221, 221, 221);
  display: flex;
  justify-content: flex-end;
`;

export const ArtifactSidebarFooterIconButton = styled(WBIconButton)`
  flex: 0 0 auto;
  margin: 4px !important;
  .MuiIcon-root {
    font-size: 1rem;
  }
`;
