import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import copyToClipboard from 'copy-to-clipboard';
import React, {FC, useContext} from 'react';
import {toast} from 'react-toastify';

import EditReportTrigger from '../EditReportTrigger';
import {ShareReportModalHeaderAction} from './ShareReportTrigger';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';

interface ModalHeaderActionProps {
  modalHeaderAction: ShareReportModalHeaderAction;
}

export const ModalHeaderAction: FC<ModalHeaderActionProps> = ({
  modalHeaderAction,
}) => {
  const {canChangeSharingSettings, currentAccessToken, view, viewRef} =
    useContext(ShareReportTriggerContext);

  const onEmbedCodeSectionClick = () => {
    if (currentAccessToken == null && !canChangeSharingSettings) {
      return;
    }
    const iframeEmbedCode = `<iframe src="${window.location.href}" style="border:none;height:1024px;width:100%">`;

    copyToClipboard(iframeEmbedCode);
    toast('Copied to clipboard');
    window.analytics?.track?.('Report Iframe Embedd Link Clicked', {
      reportId: view.cid,
    });
  };

  switch (modalHeaderAction) {
    case ShareReportModalHeaderAction.COPY_EMBEDED_CODE:
      return (
        <S.ModalHeaderAction
          modalHeaderAction={modalHeaderAction}
          onClick={onEmbedCodeSectionClick}>
          <LegacyWBIcon name="code" size="large" />
          Copy embed code
        </S.ModalHeaderAction>
      );
    case ShareReportModalHeaderAction.EDIT_REPORT_BEFORE_SHARING:
      return (
        <S.ModalHeaderAction>
          <EditReportTrigger viewRef={viewRef}>
            <LegacyWBIcon name="pencil" />
            Edit before sharing
          </EditReportTrigger>
        </S.ModalHeaderAction>
      );
    default:
      return null;
  }
};
