import {downloadElementAsPNG} from '@wandb/common/util/panelExport';
import {formatDurationWithLetters} from '@wandb/common/util/time';
import wait from '@wandb/common/util/wait';
import BadWordsFilter from 'bad-words';
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {StrictInputProps} from 'semantic-ui-react';
import {Editor, Element, Node} from 'slate';
import {RenderElementProps, useSelected} from 'slate-react';

import * as S from './craiyon.styles';
import craiyonBadWords from './craiyon-bad-words.json';
import {BlockWrapper} from './drag-drop';

const badWordsFilter = new BadWordsFilter();
badWordsFilter.addWords(...craiyonBadWords);

const FETCH_RETRY_INTERVAL_MS = 10000;

export interface Craiyon extends Element {
  type: 'craiyon';
}

export const isCraiyon = (node: Node): node is Craiyon =>
  node.type === 'craiyon';

export const CraiyonElement: React.FC<
  RenderElementProps & {
    element: Craiyon;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();

  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(``);
  const [lastPrompt, setLastPrompt] = useState<string | null>(null);
  const [images, setImages] = useState<string[]>([]);

  const cancelFnRef = useRef<(() => void) | null>(null);
  const sendPrompt: FormEventHandler<HTMLFormElement> = useCallback(
    async e => {
      e.preventDefault();
      if (loading) {
        return;
      }

      if (badWordsFilter.isProfane(input)) {
        alert(
          `We’re trying to reduce the amount of unsavory content out there in the world. Please refrain from prompts that involve nudity, violence, or other adult topics. Thanks!`
        );
        window.analytics?.track(`Craiyon Prompt Censored`, {
          prompt: input,
          location: `Craiyon Embed`,
        });
        return;
      }

      window.analytics?.track(`Craiyon Prompt Sent`, {
        prompt: input,
        location: `Craiyon Embed`,
      });

      setLoading(true);
      setLastPrompt(input);

      let cancelled = false;
      cancelFnRef.current = () => {
        cancelled = true;
      };

      while (!cancelled) {
        const fetchedImages = await fetchImages(input);
        if (cancelled) {
          return;
        }

        if (fetchedImages != null) {
          setImages(fetchedImages);
          break;
        }

        await wait(FETCH_RETRY_INTERVAL_MS);
      }

      window.analytics?.track(`Craiyon Result Received`, {
        prompt: input,
        location: `Craiyon Embed`,
      });
      setLoading(false);
    },
    [input, loading]
  );
  useEffect(() => {
    return () => {
      cancelFnRef.current?.();
    };
  }, []);

  const cancel = useCallback(() => {
    setLoading(false);
    cancelFnRef.current?.();
  }, []);

  const [downloadingResults, setDownloadingResults] = useState(false);
  const downloadAreaRef = useRef<HTMLDivElement>(null);
  const downloadResults = useCallback(async () => {
    const downloadAreaEl = downloadAreaRef.current;
    if (downloadAreaEl == null || lastPrompt == null || downloadingResults) {
      return;
    }

    window.analytics?.track(`Craiyon Result Download Triggered`, {
      prompt: lastPrompt,
      location: `Craiyon Embed`,
    });

    setDownloadingResults(true);

    await downloadElementAsPNG(
      downloadAreaEl,
      getDownloadFilename(lastPrompt),
      {
        backgroundColor: S.CRAIYON_BACKGROUND_COLOR,
      }
    );

    window.analytics?.track(`Craiyon Result Download Started`, {
      prompt: lastPrompt,
      location: `Craiyon Embed`,
    });
    setDownloadingResults(false);
  }, [downloadingResults, lastPrompt]);

  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.Container contentEditable={false} selected={selected}>
        <S.DownloadArea ref={downloadAreaRef}>
          <S.DownloadContent>
            <S.CraiyonLink to={`https://www.craiyon.com`}>
              <S.CraiyonLogo src={`/craiyon.png`} />
            </S.CraiyonLink>
            <S.InputContainer onSubmit={sendPrompt}>
              <S.Input
                value={input}
                disabled={loading}
                onChange={
                  (e => {
                    setInput(e.target.value);
                  }) as StrictInputProps['onChange']
                }
              />
              <S.Button primary disabled={loading} loading={loading}>
                Run
              </S.Button>
            </S.InputContainer>
            <S.ImageGrid>
              {loading ? (
                <>
                  <ProgressBar />
                  <S.CancelButtonContainer>
                    <S.CancelButton primary onClick={cancel}>
                      Cancel
                    </S.CancelButton>
                  </S.CancelButtonContainer>
                </>
              ) : (
                images.map((src, i) => <S.Image key={i} src={src} />)
              )}
            </S.ImageGrid>
          </S.DownloadContent>
        </S.DownloadArea>

        {images.length > 0 && (
          <S.DownloadButton
            primary
            onClick={downloadResults}
            disabled={downloadingResults}
            loading={downloadingResults}>
            Download
          </S.DownloadButton>
        )}

        <S.Disclaimer>
          We're saving prompts so we can share how people interact with this
          model in the near future.
        </S.Disclaimer>
      </S.Container>
      {children}
    </BlockWrapper>
  );
};

const ProgressBar: React.FC = React.memo(() => {
  const startRef = useRef(Date.now());
  const [activeTimeSeconds, setActiveTimeSeconds] = useState(0);
  useEffect(() => {
    const intervalID = setInterval(() => {
      setActiveTimeSeconds(Math.floor((Date.now() - startRef.current) / 1000));
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const intervalID = setInterval(() => {
      setProgress(prev => Math.min(prev + 3, 100));
    }, 5000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <S.ProgressBarContainer>
      <S.ProgressBar>
        <S.ProgressBarFill progress={progress} />
      </S.ProgressBar>
      <S.ProgressBarMessage>
        <S.ProgressBarMessageIcon name={`pencil`} /> Drawing... This may take up
        to 3 minutes
      </S.ProgressBarMessage>
      <S.ProgressBarMessage>
        You've been waiting for {formatDurationWithLetters(activeTimeSeconds)}
      </S.ProgressBarMessage>
    </S.ProgressBarContainer>
  );
});

export const withCraiyon = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => {
    return isCraiyon(element) ? true : isVoid(element);
  };

  return editor;
};

type ResponseData = {
  images: string[];
  version: string;
};

async function fetchImages(prompt: string): Promise<string[] | null> {
  try {
    // eslint-disable-next-line wandb/no-unprefixed-urls
    const response = await fetch(`https://bf.dallemini.ai/generate`, {
      method: `POST`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({prompt}),
    });

    if (!response.ok) {
      console.error(
        `invalid status code ${response.status} (${response.statusText})`
      );
      const responseText = await response.text();
      throw new Error(
        `request failed with response status ${response.status}: ${responseText}`
      );
    }

    const responseData: ResponseData = await response.json();
    return responseData.images.map(
      imgBase64Data => `data:image/png;base64,${imgBase64Data}`
    );
  } catch (err) {
    console.error(`Error requesting images for craiyon: ${err}`);
    return null;
  }
}

function getDownloadFilename(prompt: string): string {
  try {
    return prompt.toLowerCase().replace(/\s+/g, `_`);
  } catch {
    return prompt.toLowerCase();
  }
}
