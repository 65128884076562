import React, {useCallback, useMemo} from 'react';

import {useViewSpecQuery} from '../generated/graphql';
import {ReportConfig} from '../state/views/report/types';
import {getPanelSpec} from '../util/panels';
import {fromJSON} from '../util/report';
import {DropdownOption} from './CreateReportModal';
import * as S from './ReportSectionPicker.styles';
import {isPanelGrid} from './Slate/plugins/panel-grid';

const NEW_SECTION_LABEL = 'New section';
export const NEW_SECTION_IDX = -1;
const NEW_SECTION_OPTION: DropdownOption = {
  text: NEW_SECTION_LABEL,
  value: NEW_SECTION_IDX,
};

interface ReportSectionPickerProps {
  reportID: string;
  selectedSectionIdx: number;
  className?: string;
  onSelectSection(i: number): void;
}

const ReportSectionPicker: React.FC<ReportSectionPickerProps> = React.memo(
  ({reportID, selectedSectionIdx, className, onSelectSection}) => {
    const {data} = useViewSpecQuery({variables: {id: reportID}});

    const opts: DropdownOption[] = useMemo(() => {
      const spec = data?.view?.spec;
      if (spec == null) {
        return [NEW_SECTION_OPTION];
      }
      const reportConfig: ReportConfig = fromJSON(JSON.parse(spec));

      const sectionPanelTitles = reportConfig.blocks
        .filter(isPanelGrid)
        .map(pg => {
          const {metadata} = pg;
          return metadata.panelBankSectionConfig.panels
            .map(p => {
              const panelSpec = getPanelSpec(p.viewType);
              return (
                panelSpec.getTitleFromConfig?.(p.config as any) ||
                panelSpec.type
              );
            })
            .join(', ');
        });

      const choices = sectionPanelTitles.map((t, i) => {
        let text = `Section ${i + 1}`;
        if (t !== '') {
          text += ` (${t})`;
        }
        return {
          text,
          value: i,
        };
      });

      return [NEW_SECTION_OPTION, ...choices];
    }, [data]);

    const onChange = useCallback(
      (e, {value}) => onSelectSection(value),
      [onSelectSection]
    );

    return (
      <S.Dropdown
        selection
        className={className}
        options={opts}
        value={selectedSectionIdx}
        onChange={onChange}
      />
    );
  }
);

export default ReportSectionPicker;
