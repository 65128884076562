import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {Button, Input, Modal as SemanticModal, Popup} from 'semantic-ui-react';
import styled, {css, keyframes} from 'styled-components';

import CopyableText from '../CopyableText';
import {ShareReportModalHeaderAction} from './ShareReportTrigger';

export const Modal = styled(SemanticModal)`
  border-radius: 0.75rem !important;
  max-width: 420px;
`;

export const ModalContent = styled(Modal.Content)`
  &&& {
    border-radius: 0.75rem !important;
    padding: 24px 24px 32px !important;
    width: inherit;

    @media (max-width: 991px) {
      width: unset;
    }
  }
`;

export const ModalTitleWrapper = styled.div<{
  isHeaderActionEnabled?: boolean;
  modalHeaderAction?: ShareReportModalHeaderAction;
}>`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: unset;
    word-break: break-word;
    padding-right: 8px;
  }

  ${({isHeaderActionEnabled, modalHeaderAction}) =>
    isHeaderActionEnabled &&
    modalHeaderAction ===
      ShareReportModalHeaderAction.EDIT_REPORT_BEFORE_SHARING &&
    css`
      @media (max-width: ${globals.MOBILE_WIDTH}px) {
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 100%;
        p {
          width: 100%;
          padding: unset;
        }
      }
    `}
`;

export const ModalHeaderAction = styled.div<{
  modalHeaderAction?: ShareReportModalHeaderAction;
}>`
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  color: ${globals.primaryText};
  width: max-content;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }

  ${({modalHeaderAction}) =>
    modalHeaderAction ===
      ShareReportModalHeaderAction.EDIT_REPORT_BEFORE_SHARING &&
    css`
      @media (max-width: ${globals.MOBILE_WIDTH}px) {
        margin-top: 8px;
      }
    `}
`;

export const ShareInputSection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  width: 100%;

  @media (max-width: 420px) {
    flex-wrap: wrap !important;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const TextInputLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  cursor: default;
  margin: ${globals.spu};
  color: ${globals.gray800};
`;

interface InputProps {
  error?: boolean;
  disabled?: boolean;
  focused?: boolean;
}
const getTextInputBorderColor = (props: InputProps) => {
  const {error, disabled, focused} = props;
  if (error) {
    return globals.error;
  }
  if (disabled) {
    return globals.gray400;
  }
  if (focused) {
    return globals.primary;
  }
  return globals.gray400;
};
export const TextInput = styled(Input)<InputProps>`
  &&& {
    width: 100%;
    margin-right: ${globals.spu};
    border: 1px solid ${props => getTextInputBorderColor(props)};
    box-sizing: border-box;
    border-radius: 5px;
    outline: none !important;
    input {
      border: none;
      outline: none;
      box-shadow: none;
    }
    .label {
      border: none;
      outline: none;
      box-shadow: none;
      font-weight: 400;
      font-size: 15px;
      color: ${props => (props.disabled ? globals.gray500 : globals.gray800)};
    }

    @media (max-width: ${globals.MOBILE_WIDTH}px) {
      width: 80%;
    }

    @media (max-width: 420px) {
      width: 100%;
      margin: 0 0 4px 0;
    }
  }
`;

export const SimpleSmallTextWrapper = styled.div`
  font-size: 14px;
  line-height: 15px;
  color: ${globals.gray800};
  font-weight: 400;
`;

export const ControlsWrapper = styled.div`
  margin-top: 12px;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  color: ${globals.gray800};

  @media (max-width: 200px) {
  }
`;

export const EmphasizedText = styled.span`
  font-weight: 600;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80%;

  @media (max-width: ${globals.MOBILE_WIDTH}px) {
    width: 70%;
  }

  @media (max-width: 390px) {
    width: 75%;
  }
`;

export const GrayCircleWBIcon = styled(WBIcon)`
  &&& {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    color: ${globals.white};
    background-color: ${globals.gray400};
    border-radius: 50%;
    margin-right: ${globals.spu};
    font-size: 20px;
  }
`;

export const EmailText = styled.span`
  width: calc(100% - 32px); // Account for circle icon
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MagicLinkIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 8px;
`;

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MagicLinkText = styled.span<{disabled?: boolean}>`
  width: calc(100% - 4rem); // Account for toggle
  white-space: nowrap;
  color: ${props => (props.disabled ? globals.gray600 : globals.gray800)};

  @media (max-width: 430px) {
    white-space: unset;
  }
`;

export const MagicLinkStatusTextWrapper = styled.div<{disabled?: boolean}>`
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  margin-right: ${globals.spu};
  color: ${props => (props.disabled ? globals.gray600 : globals.gray800)};
`;

export const CopyableLinkWrapper = styled.div`
  margin-top: 16px;
`;

const growthPulse = keyframes`
  0% {
      font-size: 24px;
  }
  35% {
      font-size: 28px;
  }
  65% {
      font-size: 28px;
  }
  100% {
      font-size: 24px;
  }
`;
export const CopyableLink = styled(CopyableText)<{isAnimated?: boolean}>`
  // color: ${globals.gray800};
  background: ${globals.white} !important;
  border: 1px solid ${globals.gray400} !important;
  border-sizing: border-box !important;
  border-radius: 5px !important;
  i::before {
    color: ${globals.primary};
  }

  color: ${globals.gray700};
  background: ${globals.gray100};
  width: auto;
  max-width: unset;
  margin: 12px 0 0;
  padding: 0.5em 0.75em;

  i {
    opacity: 1 !important;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({isAnimated}) =>
      isAnimated &&
      css`
        animation: ${growthPulse} 1s;
      `}
  }

  .copyable-text-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  :hover {
    color: ${globals.textPrimary};
    .copy-icon {
      color: ${globals.textPrimary} !important;
    }
  }
`;

export const SharingDisabledMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${globals.gray300};
  border-radius: 3px;
  padding: 16px;
  line-height: 20px
  font-size: 16px;
  color: ${globals.gray800};
  margin-top: 16px;
`;

// TODO - fix font size
export const ShareOptionsWrapper = styled.div`
  > i {
    margin-right: 0;
    font-size: 20px;
    height: 16px;
    width: 16px;
    vertical-align: top;
    margin-top: 1px;
  }

  cursor: pointer;
`;

export const DropdownTrigger = styled.span<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  margin-right: -8px;
  font-size: 14px;
  color: ${props => (props.disabled ? globals.gray600 : globals.gray800)};
  :hover {
    cursor: pointer;
  }
`;

export const Tooltip = styled(Popup)<{hidden?: boolean}>`
  &&& {
    display: ${props => (props.hidden ? 'none' : 'block')} !important;
    background-color: ${globals.gray700} !important;
    white-space: pre !important;
    ::before {
      background-color: ${globals.gray700} !important;
      display: ${props => (props.hidden ? 'none' : 'block')} !important;
    }
    color: ${globals.white} !important;
  }
`;

export const ShareButton = styled(Button)<{disabled?: boolean}>`
  ${({disabled}) =>
    disabled
      ? `
        background: rgba(0, 0, 0, 0.04) !important;
        border: 1px solid rgba(0, 0, 0, 0.04) !important;
        color: ${globals.gray400} !important;
        `
      : `
        @media (max-width: 420px) {
          width: 100%;
        }
        `}
`;
