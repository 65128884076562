import {toast} from '@wandb/common/components/elements/Toast';
import {generatePNGDataURL} from '@wandb/common/util/panelExport';
import copyToClipboard from 'copy-to-clipboard';

import {panelImageUpload} from '../../util/images';
import {LayedOutPanel} from '../../util/panels';

type GeneratePanelURLProps = {
  panelDomEl: HTMLElement | null;
  entityName: string;
  title?: string;
  description?: string;
  panel: LayedOutPanel;
  viewID: string;
  shouldCopyToClipboard?: boolean;
  redirectURL: string;
  author?: string;
};

export const generatePanelURL = async ({
  panelDomEl,
  entityName,
  title,
  description,
  panel,
  viewID,
  shouldCopyToClipboard,
  redirectURL,
  author = 'Weights & Biases',
}: GeneratePanelURLProps) => {
  if (panelDomEl == null) {
    console.error('Could not generate link - DOM element not found');
    return;
  }
  let imageUrl = '';
  try {
    imageUrl = await generatePNGDataURL(panelDomEl);
  } catch (e) {
    console.error(e);
  }
  if (!imageUrl) {
    console.error('Could not generate link - imageURL is empty or null');
    return;
  }
  try {
    // eslint-disable-next-line wandb/no-unprefixed-urls
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    const url = await panelImageUpload(
      blob,
      entityName,
      title ?? '',
      description ?? '',
      panel.__id__,
      viewID,
      redirectURL,
      author
    );
    if (shouldCopyToClipboard) {
      if (copyToClipboard(url)) {
        toast('Link copied to clipboard');
      } else {
        toast('Error copying link to clipboard - please try again');
      }
    }
    return url;
  } catch (e) {
    console.error(e);
  }
  return;
};
