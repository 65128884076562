import _ from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
  useRef,
} from 'react';

import * as CG from '@wandb/cg';
import {EditingNode} from '@wandb/cg';
import {Client, WeaveInterface} from '@wandb/cg';
import {callOpVeryUnsafe, isFunctionLiteral} from '@wandb/cg';
import {GlobalCGEventTracker} from '@wandb/cg';

import {useDeepMemo} from '@wandb/common/state/hooks';
import {usePanelContext} from './components/Panel2/PanelContext';
import {toWeaveType} from './components/Panel2/PanelGroup2';

import {ClientContext, useWeaveContext} from './context';
/**
 * React hook-style function to get the
 * @param node the Weave CG node for which to evaluate
 * @param memoCacheId a unique id to use for memoization. If provided,
 * the caller can effectively clear the cache and force a re-evaluation.
 * Note: this will not clear the backend compute graph cache, so only
 * root nodes will be re-evaluated - recursively re-evaluating child
 * nodes only if the output changes.
 */

class ReactCGEventTracker {
  // Number of calls to useNodeValue
  useNodeValue: number = 0;
  private cgEventTracker: typeof GlobalCGEventTracker;

  constructor() {
    this.cgEventTracker = GlobalCGEventTracker;
  }

  public reset() {
    this.useNodeValue = 0;
    this.cgEventTracker.reset();
  }

  summary() {
    const cgSummary = this.cgEventTracker.summary();
    const toNodeSubscriptions =
      cgSummary._1_nodeSubscriptions.toRouted.toRemoteCache +
      cgSummary._1_nodeSubscriptions.toRouted.toBasicClientLocal +
      cgSummary._1_nodeSubscriptions.toEcosystem.toRemoteCache +
      cgSummary._1_nodeSubscriptions.toProduction.toBasicClientLocal;
    return {
      _0_useNodeValue: {
        resolvedWithCache: this.useNodeValue - toNodeSubscriptions,
        toNodeSubscriptions,
      },
      ...cgSummary,
    };
  }
}

export const GlobalCGReactTracker = new ReactCGEventTracker();

export const useClientContext = () => {
  return useContext(ClientContext);
};

export const useNodeValue = <T extends CG.Type>(
  node: CG.NodeOrVoidNode<T>,
  memoCacheId: number = 0
): {loading: boolean; result: CG.TypeToTSTypeInner<T>} => {
  const context = useClientContext();
  const client = context.client;
  const frame = usePanelContext().frame;
  node = useMemo(
    () => CG.callFunction(node, frame),
    [node, frame]
  ) as CG.NodeOrVoidNode<T>;
  // Note this is probably expensive and we do it way too
  // often. TODO: check perf!
  GlobalCGReactTracker.useNodeValue++;
  node = useDeepMemo({node, memoCacheId}).node;
  const [error, setError] = useState();
  const [result, setResult] = useState<{
    node: CG.NodeOrVoidNode;
    value: any;
  }>({node: CG.voidNode(), value: undefined});
  useEffect(() => {
    if (!CG.isVoidNode(node)) {
      if (client == null) {
        throw new Error('client not initialized!');
      }
      const obs = client.subscribe(node);
      const sub = obs.subscribe(
        nodeRes => {
          setResult({node, value: nodeRes});
        },
        caughtError => {
          setError(caughtError);
        }
      );
      return () => sub.unsubscribe();
    } else {
      return;
    }
  }, [client, node, memoCacheId]);
  const finalResult = useMemo(() => {
    // Just rethrow the error in the render thread so it can be caught
    // by an error boundary.
    if (error != null) {
      console.error('useNodeValue error', error);
      throw new Error(error);
    }
    return {
      loading: node !== result.node,
      result: result.value,
    };
  }, [error, node, result.node, result.value]);

  return finalResult;
};

/**
 * The useNodeValueExecutor hook allows the user
 * to retrieve the value of a node as a promise. This
 * is more of an edge case - please consider using useNodeValue
 * instead. However, if you need to get the value of a node conditionally
 * - for example, only after some user behavior - then this hook can be used.
 * After constructing the executor, the caller should be inside of a useEffect
 * or useCallback.
 */
export const useNodeValueExecutor = () => {
  const context = useClientContext();
  const client = context.client;
  if (client == null) {
    throw new Error('client not initialized!');
  }
  return useCallback(
    async (node: CG.NodeOrVoidNode): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (!CG.isVoidNode(node)) {
          const obs = client!.subscribe(node);
          const sub = obs.subscribe(
            res => {
              sub.unsubscribe();
              resolve(res);
            },
            err => {
              sub.unsubscribe();
              reject(err);
            }
          );
        } else {
          return resolve(null);
        }
      });
    },
    [client]
  );
};

/**
 * useValue is a hook that wraps useNodeValue, but also the returned object
 * contains a `refresh` method which can be used to force
 * a re-evaluation of the node even if it is referentially equal. This would
 * be useful if the node's graph has a remote data fetch that is not a root op.
 * @param node the node to evaluate
 * @param defaultValue the default value to return while the node is loading
 */
export const useValue = <T extends CG.Type>(
  node: CG.NodeOrVoidNode<T>
): {
  loading: boolean;
  result: CG.TypeToTSTypeInner<T>;
  refresh: () => void;
} => {
  // Would be better to support refreshing a single node
  // or maybe more particularly, refreshing all nodes in
  // the listening graph which depend on an ancestor of this node.
  const refreshAllNodes = useRefreshAllNodes();
  const [memoTrigger, setMemoTrigger] = useState(0);
  const refresh = useCallback(() => {
    refreshAllNodes();
    setMemoTrigger(t => t + 1);
  }, [refreshAllNodes, setMemoTrigger]);

  const res = useNodeValue(node, memoTrigger);

  return useMemo(
    () => ({
      ...res,
      refresh,
    }),
    [res, refresh]
  );
};

const weaveStateCallbacks: {[key: string]: (newVal: any) => void} = {};

// Something like this could maybe be used to update the root expression
// too?
export const useWeaveState = (val: any, onUpdate: (newVal: any) => void) => {
  const id = useRef(CG.ID());
  const stateId = useMemo(
    () =>
      CG.constNode(
        {
          type: 'typedDict',
          propertyTypes: {_weaveStateId: 'string', value: toWeaveType(val)},
        },
        {_weaveStateId: id.current, value: val}
      ),
    [val]
  );
  weaveStateCallbacks[stateId.val._weaveStateId] = onUpdate;
  return useMemo(
    () => CG.opPick({obj: stateId, key: CG.constString('value')}),
    [stateId]
  );
};

export const useLet = (
  statements: {[key: string]: any},
  onUpdate: (key: string, newVal: any) => void
) => {
  const stateId = useRef(CG.ID());
  return useMemo(() => {
    const resultStatements: {[key: string]: CG.Node} = {};
    for (let [key, val] of Object.entries(statements)) {
      if (val?.nodeType == null) {
        val = CG.constNode(toWeaveType(val), val);
      }
      if (val.nodeType === 'const') {
        const ref = CG.constNode(
          {
            type: 'typedDict',
            propertyTypes: {
              _weaveStateId: 'string',
              value: val.type,
            },
          },
          {_weaveStateId: stateId.current, value: val.val}
        );
        // Create a slot.
        // TODO: We need to clean this up when the memo reruns!
        weaveStateCallbacks[ref.val._weaveStateId] = (newVal: any) =>
          onUpdate(key, newVal);
        resultStatements[key] = CG.opPick({
          obj: ref,
          key: CG.constString('value'),
        });
      } else {
        resultStatements[key] = val;
      }
    }
    return resultStatements;
  }, [statements, onUpdate]);
};

// Returns a callable "action" function that mutates target.
// actionName is the underlying op name.
// TODO:
//   - should we call this useMutation?
//   - don't ship like this! its not typesafe. we need to generate typescript
//     from the Python op definitions to fix. That means Op code should be
//     installable as npm packages.
//     - Idea: we should automatically generate pip and npm packages for every
//       underlying Weave package.

export const useAction = (target: CG.Node, actionName: string) => {
  // const context = useContext(ClientContext);
  const frame = usePanelContext().frame;
  target = useMemo(() => CG.callFunction(target, frame), [target, frame]);
  const callAction = useCallback(
    (client: Client, inputs: CG.OpInputs) => {
      // Assumes self convention (that self is the name of the first argument).
      // TODO: is that what we want everywhere? Its very Pythonic... Its a good
      // idea to try to remain friendly to all languages.
      const calledNode = callOpVeryUnsafe(actionName, {
        self: target,
        ...inputs,
      });
      return client.query(calledNode as any).then(final => {
        if (final._weaveStateId != null) {
          weaveStateCallbacks[final._weaveStateId](final.value);
        }

        // TODO: Need to bust all the caches!
        // context.incMutationId();
        // We actually get the mutated object back right now.
        // But don't send this to the user! For one reason, we shouldn't
        // keep the behavior of sending the mutated object back. That could
        // be very expensive if it was a large object. But also, this would
        // encourage bad usage patterns by users! They don't need to know
        // The result of the mutation. They'll already be subscribed to viewing
        // the node or a parent of the node that we mutated somewhere up the
        // tree. That node will be notified of the change, and everything
        // will rerender automatically. The user does not need to worry about
        // how to apply the mutation results back to some user-held state
        // (unlike in graphql).
        return true;
      });
    },
    [actionName, target]
  );
  return useClientBound(callAction);
};

export const useRefreshAllNodes = () => {
  const context = useClientContext();
  const client = context.client;
  return useCallback(async () => {
    if (client != null) {
      await client.refreshAll();
    }
  }, [client]);
};

export const useClientBound = <T extends any[], R>(
  fn: (client: Client, ...rest: T) => R
): ((...args: T) => R) => {
  const client = useClientContext().client;
  if (client == null) {
    throw new Error('CG context not initialized');
  }
  return useCallback((...args: T) => fn(client, ...args), [client, fn]);
};

// Given an array node, return a set of valid nodes, one for
// each item in the array.
// TODO: in the future it would be cool to move this logic down,
//   it doesn't need to depend on react hooks.
export const useEach = (node: CG.Node<{type: 'list'; objectType: 'any'}>) => {
  const countNode = useMemo(() => CG.opCount({arr: node}), [node]);
  const countValue = useNodeValue(countNode);
  const result = useMemo(
    () =>
      _.range(countValue.result).map(i =>
        CG.opIndex({arr: node, index: CG.constNumber(i)})
      ),

    [countValue.result, node]
  );
  const finalResult = useMemo(() => {
    return {
      loading: countValue.loading,
      result,
    };
  }, [countValue.loading, result]);

  return finalResult;
};

export const useSimplifiedNode = (node: CG.Node) => {
  node = useDeepMemo(node);
  const context = useClientContext();
  const [result, setResult] = useState<
    {loading: true} | {loading: false; result: CG.Node}
  >({loading: true});
  useEffect(() => {
    setResult({loading: true});
    const doSimplify = async () => {
      const simpler = await CG.simplify(context.client!, node);
      setResult({loading: false, result: simpler});
    };
    doSimplify();
  }, [context.client, node]);
  return result;
};

export const useNodeWithServerType = (
  node: CG.NodeOrVoidNode,
  frame?: CG.Frame
): {loading: boolean; result: CG.NodeOrVoidNode} => {
  const actualFrame = frame ?? usePanelContext().frame;
  const [error, setError] = useState();
  node = useDeepMemo(node);
  node = useMemo(
    () => CG.callFunction(node, actualFrame),
    [node, actualFrame]
  ) as CG.NodeOrVoidNode;
  if (
    node.nodeType !== 'output' &&
    node.nodeType !== 'void' &&
    node.nodeType !== 'const'
  ) {
    throw new Error(
      'useNodeWithServerType: expected output or void node, found ' +
        node.nodeType
    );
  }
  const [result, setResult] = useState<{
    node: CG.NodeOrVoidNode;
    value: any;
  }>({node: CG.voidNode(), value: undefined});
  const weave = useWeaveContext();
  useEffect(() => {
    let isMounted = true;
    if (node.nodeType === 'const') {
      setResult({node, value: node});
    }
    if (node.nodeType !== 'output') {
      return;
    }
    // TODO: This is a race if we have multiple loading in parallel!
    weave
      .refineNode(node, actualFrame)
      .then(newNode => {
        if (isMounted) {
          setResult({node, value: newNode});
        }
      })
      .catch(e => setError(e));
    return () => {
      isMounted = false;
    };
  }, [weave, node, actualFrame]);
  const finalResult = useMemo(() => {
    if (error != null) {
      // rethrow in render thread
      console.error('useNodeWithServerType error', error);
      throw new Error(error);
    }
    return {
      loading: node !== result.node,
      result: node === result.node ? result.value : node,
    };
  }, [result, node, error]);
  return finalResult;
};

export const useExpandedNode = (
  node: CG.NodeOrVoidNode,
  frame: CG.Frame
): {loading: boolean; result: CG.NodeOrVoidNode} => {
  const [error, setError] = useState();
  node = useDeepMemo(node);
  const [result, setResult] = useState<{
    node: CG.NodeOrVoidNode;
    value: any;
  }>({node: CG.voidNode(), value: undefined});
  const context = useClientContext();
  useEffect(() => {
    let isMounted = true;
    if (node.nodeType !== 'output') {
      return;
    }
    // TODO: This is a race if we have multiple loading in parallel!
    CG.expandAll(context.client!, node as any, frame)
      .then(newNode => {
        if (isMounted) {
          setResult({node, value: newNode});
        }
      })
      .catch(e => setError(e));
    return () => {
      isMounted = false;
    };
  }, [context, node, frame]);
  const finalResult = useMemo(() => {
    if (error != null) {
      // rethrow in render thread
      console.error('useExpanded error', error);
      throw new Error(error);
    }
    return {
      loading: node.nodeType !== 'output' ? false : node !== result.node,
      result:
        node.nodeType !== 'output'
          ? node
          : node === result.node
          ? result.value
          : node,
    };
  }, [result, node, error]);
  return finalResult;
};
type NodeDebugInfoInputType = {[name: string]: NodeDebugInfoType | null};
type NodeDebugInfoType = {
  node?: CG.NodeOrVoidNode;
  nodeString?: string;
  refinedNode?: CG.NodeOrVoidNode;
  refineError?: any;
  nodeValue?: any;
  valueError?: any;
  inputs?: NodeDebugInfoInputType;
  invalidators?: ReturnType<typeof getInvalidatorNodesWithInfo>;
};

function getInvalidatorNodesWithInfo(node: EditingNode, weave: WeaveInterface) {
  const invalidators = getInvalidators(node);

  if (!invalidators) {
    return undefined;
  }

  return invalidators.map(invalidatorNode => {
    let invalidatedBy:
      | {
          [inputName: string]: {
            expectedType: CG.Type;
            actualValue: EditingNode;
          };
        }
      | undefined;
    if (invalidatorNode.nodeType === 'output') {
      const opDef = weave.client.opStore.getOpDef(invalidatorNode.fromOp.name);
      invalidatedBy = Object.fromEntries(
        Object.entries(invalidatorNode.fromOp.inputs)
          .filter(
            ([inputName, value]) =>
              !CG.isAssignableTo(value.type, opDef.inputTypes[inputName])
          )
          .map(([inputName, value]) => [
            inputName,
            {
              expectedType: opDef.inputTypes[inputName],
              actualValue: value,
            },
          ])
      );
    }

    return {
      node: invalidatorNode,
      invalidatedBy,
    };
  });
}
function getInvalidators(node: EditingNode): null | EditingNode[] {
  if (node.type !== 'invalid') {
    return null;
  }

  if (isFunctionLiteral(node)) {
    return getInvalidators(node.val);
  }
  if (node.nodeType !== 'output') {
    return [node];
  }

  const invalidInputs = Object.values(node.fromOp.inputs).filter(
    input => input.type === 'invalid'
  );

  if (invalidInputs.length === 0) {
    // if we have no invalid inputs, then this is an invalidating node
    return [node];
  }

  return _.compact(invalidInputs.flatMap(getInvalidators));
}

async function makeDebugNode(
  weave: WeaveInterface,
  node: CG.NodeOrVoidNode
): Promise<NodeDebugInfoType> {
  const result = {
    node,
    nodeString: weave.expToString(node),
  };

  if (weave.client == null) {
    throw new Error('client not initialized!');
  }
  if (node.nodeType === 'void') {
    return Promise.resolve(result);
  } else {
    return new Promise(async resolve => {
      weave
        .refineNode(node, {})
        .then(async refinedNode => {
          const invalidators = getInvalidatorNodesWithInfo(refinedNode, weave);
          // From Shawn: I removed strip tags = false here. Sorry,
          // I want to make sure we never rely on strip tags in production
          // code, so the client doesn't even allow it anymore. To bring
          // it back, I think it'd be ok to have a client._queryDebug that
          // does it... this would need to be routed through to the server.
          const nodeValue = await weave.client.query(node);
          if (node.nodeType === 'output') {
            const keys = _.keys(node.fromOp.inputs);
            const inputNodes = await Promise.all(
              keys.map(key => makeDebugNode(weave, node.fromOp.inputs[key]))
            );
            const inputs = _.fromPairs(
              keys.map((key, ndx) => [key, inputNodes[ndx]])
            );

            resolve({
              ...result,
              refinedNode,
              nodeValue,
              inputs,
              invalidators,
            });
          } else {
            resolve({
              ...result,
              refinedNode,
              nodeValue,
              invalidators,
            });
          }
        })
        .catch(refineError => {
          resolve({
            ...result,
            refineError,
          });
        });
    });
  }
}

// Warning: Only use for debugging - costly and inefficient.
export function useNodeDebugInfo(node: CG.NodeOrVoidNode): {
  loading: boolean;
  result: NodeDebugInfoType | null;
} {
  const weave = useWeaveContext();
  const [result, setResult] = useState<NodeDebugInfoType | null>();
  node = useDeepMemo(node);

  useEffect(() => {
    makeDebugNode(weave, node).then(setResult);
  }, [weave, node, setResult]);

  return useMemo(() => {
    if (result == null) {
      return {loading: true, result: null};
    } else {
      return {
        loading: false,
        result,
      };
    }
  }, [result]);
}
