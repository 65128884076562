import {
  Input as SemanticInput,
  Modal as SemanticModal,
  Radio as SemanticRadio,
} from 'semantic-ui-react';
import styled, {css} from 'styled-components';

export const Modal = styled(SemanticModal)`
  max-width: 500px;
`;

export const Header = styled.div`
  padding-top: 20px;
  margin-left: 20px;
  font-size: 20px;
  color: gray;
`;

export const ModalText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
`;

export const Radio = styled(SemanticRadio)`
  margin: 0px 15px;
  z-index: 0; // very important, moves slider beneath dropdown menu
`;

export const Input = styled(SemanticInput)`
  flex-grow: 1;
`;

export const QueueNameSpan = styled.span<{expanded: boolean}>`
  cursor: pointer;
  font-size: 20px;
  transform: translateY(-3px);
  .wbic-ic-next {
    font-size: 22px;
    color: @gray500;

    transform: translateY(3px)
      ${props =>
        props.expanded &&
        css`
        rotate(90deg)
      `};
  }
`;

export const SubTableDiv = styled.div`
  flex: 1;
`;

export const SuperTableCellName = styled.div`
  flex: 1;
  display: flex;
  justify-items: left;
  size: 18px;
`;

export const SuperTableCell = styled.div`
  margin: 15px;
  display: flex;
`;
export const SuperTableSubCell = styled.div`
  display: flex;
`;

export const SuperTableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: space-between;
  align-items: center;
  line-height: normal;
  background: white;
}
`;

export const SuperTableRowOutter = styled.div`
  margin: 16px 16px 0px 16px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  margin-left: 12px;
  margin-bottom: 10px;
`;

export const ModalInputContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  margin-left: 16px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0px;
`;

export const RunQueueTabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
`;

export const CreateQueueDiv = styled.div`
  color: #2e78c7;
  cursor: pointer;
`;

export const Linebreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const DRCSelect = styled.div`
  flex: 1 1 auto;
  margin-top: 4px;
  margin-bottom: 4px;
`;
