import {colorIndex} from '@wandb/common/components/Artifact';
import {WBIcon} from '@wandb/ui';
import React, {useCallback, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {Feed} from 'semantic-ui-react';

import * as Generated from '../generated/graphql';
import {
  HistoryLimitReachedMessage,
  useEntityActionHistoryLimit,
} from '../util/modelRegistryRestrictions';
import * as Urls from '../util/urls';
import {InstrumentedLoader as Loader} from './../components/utility/InstrumentedLoader';
import * as S from './ArtifactActionHistory.styles';
import ArtifactActionHistoryLinks from './ArtifactActionHistoryLinks';
import {AddAliasButton} from './ArtifactAlias';
import {Tag} from './Tags';

interface ArtifactActionHistoryProps {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  pageSize: number;
}

interface ArtifactAliasActionIconProps {
  actionType: string;
}

type ActionInfo = {
  entityName: string;
  alias: string;
  actionType: string;
  username: string;
  artifactTypeName: string;
  sourceArtifactVersion?: {
    artifactSequence: {
      projectName: string;
      name: string;
    };
    versionIndex: number | null | undefined;
  };
  targetArtifactVersion?: {
    artifactSequence: {
      projectName: string;
      name: string;
    };
    versionIndex: number | null | undefined;
  };
  createdAt: string;
};

interface ArtifactAliasActionSummaryProps {
  actionInfo: ActionInfo;
  onAliasClick: (alias: string) => void;
}

interface ArtifactAliasActionEventProps {
  actionInfo: ActionInfo;
  onAliasClick: (alias: string) => void;
}

const ActionEvent: React.FC<ArtifactAliasActionEventProps> = ({
  actionInfo,
  onAliasClick,
}) => {
  return (
    <S.FeedEvent>
      <S.LabelWrapper>
        <Feed.Label>
          <S.IconWrapper>
            <ActionIcon actionType={actionInfo.actionType} />
          </S.IconWrapper>
        </Feed.Label>
      </S.LabelWrapper>

      <S.FeedContent>
        <Feed.Summary>
          <ActionMessage actionInfo={actionInfo} onAliasClick={onAliasClick} />
        </Feed.Summary>
      </S.FeedContent>
    </S.FeedEvent>
  );
};

enum AliasActionType {
  INSERT = 'insert_alias',
  DELETE = 'delete_alias',
  MOVE = 'move_alias',
}

const ActionIcon: React.FC<ArtifactAliasActionIconProps> = ({actionType}) => {
  if (actionType === AliasActionType.INSERT) {
    return <WBIcon name="plus" style={{fontSize: '1em'}} />;
  } else if (actionType === AliasActionType.MOVE) {
    return <WBIcon name="move" />;
  } else if (actionType === AliasActionType.DELETE) {
    return <WBIcon name="delete" />;
  } else {
    return <WBIcon name="file" />;
  }
};

const ActionMessage: React.FC<ArtifactAliasActionSummaryProps> = ({
  actionInfo,
  onAliasClick,
}) => {
  const {
    entityName,
    alias,
    actionType,
    username,
    sourceArtifactVersion,
    targetArtifactVersion,
    artifactTypeName,
    createdAt,
  } = actionInfo;
  const verbs: {[key: string]: string} = {
    [AliasActionType.INSERT]: 'added',
    [AliasActionType.DELETE]: 'deleted',
    [AliasActionType.MOVE]: 'moved',
  };
  const isMove = actionType === AliasActionType.MOVE;
  const targetArtifactUrl = Urls.artifact({
    entityName,
    projectName: targetArtifactVersion?.artifactSequence.projectName ?? '',
    artifactTypeName,
    artifactCollectionName: targetArtifactVersion?.artifactSequence.name ?? '',
    artifactCommitHash: 'v' + targetArtifactVersion?.versionIndex,
  });

  const userUrl = `/${username}`;
  return (
    <>
      <S.UserTime>
        <Link to={userUrl}>{username}</Link>
        <S.Dot>&#183;</S.Dot>
        <S.CreatedAt>
          <TimeAgo date={createdAt} />
        </S.CreatedAt>
      </S.UserTime>
      <S.Message>
        <S.MessageItems>
          {verbs[actionType]} the alias{' '}
          <S.Alias>
            <Tag
              tag={{
                name: alias,
                colorIndex: colorIndex({alias}),
              }}
              onClick={() => {
                onAliasClick(alias);
              }}
            />
          </S.Alias>
          {isMove && sourceArtifactVersion ? (
            <>
              {'from '}
              {sourceArtifactVersion == null ? (
                <>Private Artifact</>
              ) : (
                <S.SpacedLink
                  to={Urls.artifact({
                    entityName,
                    projectName:
                      sourceArtifactVersion.artifactSequence.projectName,
                    artifactTypeName,
                    artifactCollectionName:
                      sourceArtifactVersion.artifactSequence.name,
                    artifactCommitHash:
                      'v' + sourceArtifactVersion.versionIndex,
                  })}>
                  {sourceArtifactVersion.artifactSequence.name +
                    ':v' +
                    sourceArtifactVersion.versionIndex}
                </S.SpacedLink>
              )}
              {'  to  '}
              {targetArtifactVersion == null ? (
                <>Private Artifact</>
              ) : (
                <S.LeftSpacedLink to={targetArtifactUrl}>
                  {'  ' +
                    targetArtifactVersion?.artifactSequence.name +
                    ':v' +
                    targetArtifactVersion?.versionIndex}
                </S.LeftSpacedLink>
              )}
              .
            </>
          ) : (
            <>
              {'on '}
              {targetArtifactVersion == null ? (
                <>Private Artifact</>
              ) : (
                <S.LeftSpacedLink to={targetArtifactUrl}>
                  {'  ' +
                    targetArtifactVersion?.artifactSequence.name +
                    ':v' +
                    targetArtifactVersion?.versionIndex}
                </S.LeftSpacedLink>
              )}
              .
            </>
          )}
        </S.MessageItems>
      </S.Message>
    </>
  );
};

export const ArtifactActionHistory = (props: ArtifactActionHistoryProps) => {
  const {
    entityName,
    projectName,
    artifactTypeName,
    artifactCollectionName,
    pageSize,
  } = props;

  const [offset, setOffset] = useState(1);
  const [selectedAliases, setSelectedAliases] = useState<string[]>([]);

  const {data, loading} =
    Generated.useArtifactCollectionAliasActionHistoryQuery({
      variables: {
        entityName,
        projectName,
        artifactTypeName,
        artifactCollectionName,
      },
    });

  const historyLimitQuery = useEntityActionHistoryLimit(
    entityName,
    data?.project?.artifactType?.artifactCollection?.id
  );

  const globalAliasActions = useMemo(() => {
    const actions =
      data?.project?.artifactType?.artifactCollection?.aliasActionHistory
        ?.edges ?? [];

    if (historyLimitQuery.loading) {
      return [];
    }
    return actions.slice(0, historyLimitQuery.result).map(n => ({
      alias: n.node.alias,
      actionType: n.node.actionType,
      entityName,
      artifactTypeName,
      username: n.node.user?.username ?? '',
      targetArtifactVersion: n.node.targetArtifact
        ? {
            artifactSequence: {
              name: n.node.targetArtifact.artifactSequence.name,
              projectName: n.node.targetArtifact.artifactSequence.project.name,
            },
            versionIndex: n.node.targetArtifact?.versionIndex,
          }
        : undefined,
      sourceArtifactVersion: n.node.sourceArtifact
        ? {
            artifactSequence: {
              name: n.node.sourceArtifact.artifactSequence.name,
              projectName: n.node.sourceArtifact.artifactSequence.project.name,
            },
            versionIndex: n.node.sourceArtifact?.versionIndex,
          }
        : undefined,
      createdAt: n.node.createdAt + 'Z',
    }));
  }, [
    data,
    historyLimitQuery.loading,
    historyLimitQuery.result,
    entityName,
    artifactTypeName,
  ]);

  const selectedAliasActions = useMemo(() => {
    let actions = globalAliasActions;
    if (selectedAliases.length > 0) {
      actions = actions.filter(a => selectedAliases.includes(a.alias));
    }
    return actions;
  }, [globalAliasActions, selectedAliases]);

  const currentPaginatedAliasActions = useMemo(() => {
    return selectedAliasActions.filter(
      (a, i) =>
        i >= (offset - 1) * pageSize && i < pageSize + (offset - 1) * pageSize
    );
  }, [selectedAliasActions, offset, pageSize]);

  const currentCollectionAliases = useMemo(() => {
    const aliasDict: {[key: string]: boolean} = {};
    globalAliasActions.forEach(a => {
      aliasDict[a.alias] = true;
    });
    return Object.keys(aliasDict).map(n => {
      return {
        alias: n,
      };
    });
  }, [globalAliasActions]);

  const resolveTotalPages = useCallback(() => {
    if (selectedAliases.length > 0) {
      const len = selectedAliasActions.length;
      if (len >= pageSize && len % pageSize === 0) {
        return len / pageSize;
      }
      return Math.trunc(len / pageSize) + 1;
    }
    return Math.trunc(globalAliasActions.length / pageSize) + 1;
  }, [selectedAliases, selectedAliasActions, globalAliasActions, pageSize]);

  const onAliasClick = (alias: string) => {
    if (!selectedAliases.includes(alias)) {
      setSelectedAliases(prevState => [...prevState, alias]);
    }
    setOffset(1);
  };

  if (loading) {
    return <Loader name={'artifact-action-history'} />;
  }
  return selectedAliasActions.length === 0 ? (
    <S.ArtifactActionsContainer />
  ) : (
    <S.ArtifactActionsContainer>
      <S.ArtifactActionsWrapper>
        <div style={{background: 'white', padding: '2em'}}>
          {!historyLimitQuery.loading &&
            globalAliasActions.length === historyLimitQuery.result && (
              <HistoryLimitReachedMessage entityName={entityName} />
            )}
          <S.Header>
            <S.HeaderText>Aliases</S.HeaderText>
            <S.AliasHeader>
              {selectedAliases.map(alias => (
                <Tag
                  tag={{
                    name: alias,
                    colorIndex: colorIndex({alias}),
                  }}
                  onDelete={() => {
                    setSelectedAliases(prevState =>
                      [...prevState].filter(a => a !== alias)
                    );
                  }}
                />
              ))}
            </S.AliasHeader>
            <AddAliasButton
              aliases={currentCollectionAliases}
              availableAliases={currentCollectionAliases}
              onCreate={alias => {
                if (
                  currentCollectionAliases.find(a => a.alias === alias) &&
                  !selectedAliases.includes(alias)
                ) {
                  setSelectedAliases(prevState => [...prevState, alias]);
                }
                return Promise.resolve();
              }}
            />
          </S.Header>
          <S.RowsContainer>
            {currentPaginatedAliasActions.map((a, i) => {
              return (
                <ActionEvent
                  actionInfo={a}
                  onAliasClick={onAliasClick}
                  key={`${i}` + a.alias + a.createdAt}
                />
              );
            })}
          </S.RowsContainer>
          <S.Paginator
            activePage={offset}
            onPageChange={(e: any, value: any) => {
              setOffset(value.activePage as number);
            }}
            size="mini"
            totalPages={resolveTotalPages()}
          />
        </div>
      </S.ArtifactActionsWrapper>
      <ArtifactActionHistoryLinks
        entityName={entityName}
        projectName={projectName}
        artifactTypeName={artifactTypeName}
        artifactCollectionName={artifactCollectionName}
        pageSize={10}
        membershipNoun={'link'}
      />
    </S.ArtifactActionsContainer>
  );
};

export default ArtifactActionHistory;
