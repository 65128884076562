import {
  BENCHMARK_PATHS,
  ENTITY_LAUNCH_PATHS,
  ENTITY_REGISTRY_PATHS,
  GALLERY_DISCUSSION_EDIT_PATH,
  GALLERY_DISCUSSION_VIEW_PATH,
  GALLERY_PATH,
  GALLERY_PATH_SEGMENT,
  GALLERY_POST_EDIT_PATH,
  GALLERY_POST_VIEW_PATH,
  GROUP_PATHS,
  ONBOARDING_FLOW_PATH,
  PROFILE_LIKES_PATH,
  PROFILE_PATH,
  PROFILE_PROJECTS_PATH,
  PROFILE_REPORTS_PATH,
  PROJECT_BASE_PATH,
  PROJECT_PATHS,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  RUN_PATHS,
  STORAGE_EXPLORER_TRACKED_PATH,
  SWEEP_PATHS,
  TEAM_MEMBERS_PATH,
  USAGE_PATH,
} from './paths';

type SimpleRoute = {
  path: string | string[];
  exact?: true;
  redirect?: string;
};

export const ROUTES: SimpleRoute[] = [
  {
    path: '/__test_page',
    exact: true,
  },
  {
    path: ['/__registry', '/__registry/:tab'],
    exact: true,
  },
  {
    path: ['/__wbench', '/__wbench/:tab'],
    exact: true,
  },
  {
    path: '/',
    exact: true,
  },
  {
    path: '/login',
  },
  {
    path: '/login-redirect',
  },
  {
    path: '/logout',
  },
  {
    path: '/trending',
  },
  {
    path: '/home',
    exact: true,
  },
  {
    path: '/quickstart/:framework?',
  },
  {
    path: `/${GALLERY_PATH_SEGMENT}/create-post`,
  },
  {
    path: `/${GALLERY_PATH_SEGMENT}/create-discussion`,
  },
  {
    path: GALLERY_PATH,
  },
  {
    path: '/fullyconnected',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/fc',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/gallery',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/__test/simple_error',
    exact: true,
  },
  {
    path: ONBOARDING_FLOW_PATH,
  },
  {
    path: '/change-password',
  },
  {
    path: '/authorize',
  },
  {
    path: '/settings',
    exact: true,
  },
  {
    path: ['/billing', '/subscriptions'],
    exact: true,
  },
  {
    path: ['/account-settings', '/account-setting'],
    exact: true,
  },
  {
    path: '/admin/usage',
    exact: true,
  },
  {
    path: '/admin/organization',
  },
  {
    path: '/admin/users',
  },
  {
    path: '/admin/license',
  },
  {
    path: `/admin/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: `/admin/ramp`,
  },
  {
    path: '/admin/experiments',
  },
  {
    path: '/create-team',
  },
  {
    path: '/teams/:entityName',
  },
  {
    path: '/admin/home',
  },
  {
    path: [
      USAGE_PATH,
      STORAGE_EXPLORER_TRACKED_PATH,
      '/usage/:entityName/:filePath*',
    ],
    exact: true,
  },
  {
    path: '/storage/:entityName/tracked/:filePath*',
    redirect: STORAGE_EXPLORER_TRACKED_PATH,
  },
  {
    path: '/storage/:entityName/:filePath*',
    redirect: '/usage/:entityName/:filePath*',
  },
  {
    path: '/storage/:entityName',
    redirect: '/usage/:entityName',
  },
  {
    path: '/new-project',
  },
  {
    path: '/new-benchmark',
  },
  {
    path: '/:entityname/benchmark/new',
  },
  {
    path: PROFILE_REPORTS_PATH,
    exact: true,
  },
  {
    path: PROFILE_PROJECTS_PATH,
    exact: true,
  },
  {
    path: PROFILE_LIKES_PATH,
    exact: true,
  },
  {
    path: TEAM_MEMBERS_PATH,
    exact: true,
  },
  {
    path: ENTITY_REGISTRY_PATHS,
    exact: true,
  },
  {
    path: ENTITY_LAUNCH_PATHS,
    exact: true,
  },
  {
    path: RUN_PATHS,
    exact: true,
  },
  {
    path: GROUP_PATHS,
    exact: true,
  },
  {
    path: '/:entityName/:projectName/create-sweep',
    exact: true,
  },
  {
    path: SWEEP_PATHS,
    exact: true,
  },
  {
    path: BENCHMARK_PATHS,
    exact: true,
  },
  // {
  //   path: `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}`,
  //   exact: true,
  // },
  {
    path: '/:entityName/:projectName/reports',
    exact: true,
  },
  {
    path: '/:entityName/:projectName/published',
    exact: true,
  },
  {
    path: '/:entityName/:projectName/reports/:reportNameAndID/edit',
    exact: true,
  },
  {
    path: REPORT_PAGE_VIEW_PATH,
    exact: true,
  },
  {
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    exact: true,
  },
  {
    path: GALLERY_POST_EDIT_PATH,
    exact: true,
  },
  {
    path: GALLERY_POST_VIEW_PATH,
    exact: true,
  },
  {
    path: GALLERY_DISCUSSION_EDIT_PATH,
    exact: true,
  },
  {
    path: GALLERY_DISCUSSION_VIEW_PATH,
    exact: true,
  },
  {
    path: '/:entityName/:projectName/querydemo',
    exact: true,
  },
  {
    path: '/:entityName/:projectName/weave-sandbox',
    exact: true,
  },
  {
    path: '/org/:orgName',
    exact: true,
  },
  {
    path: '/repo/:repoName',
    exact: true,
  },
  {
    path: PROJECT_PATHS,
    exact: true,
  },
  {
    // this is here to truncate extra path segments after the actual path
    path: PROJECT_BASE_PATH,
    redirect: PROJECT_BASE_PATH,
  },
  {
    path: PROFILE_PATH,
    exact: true,
  },
];
