import {urlPrefixed} from '@wandb/common/config';
import _ from 'lodash';
import {useLayoutEffect, useState} from 'react';

import {isFirefox} from './browser';
import globalHistory from './history';
import {stripOriginFromURL} from './url';

// Expect these items to be in the window object.
declare global {
  interface Window {
    ga: any;
    Beamer: any;
    __REDUX_DEVTOOLS_EXTENSION__: any;
    __PREVIOUSLY_LOGGED_IN?: boolean;
    __WB_authRedirect?: string;
    __INITIALIZE_PENDO?: () => void;
    __WB_WYSIWYG_CONVERSION_REVERT?: () => void;
  }
}

export interface WindowSize {
  width: number;
  height: number;
}

const windowSize: WindowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

function refreshWindowSize() {
  windowSize.width = window.innerWidth;
  windowSize.height = window.innerHeight;
}

const componentsWatchingWindowSize: Set<() => void> = new Set();

function renderWatchingComponents() {
  for (const renderComponent of componentsWatchingWindowSize) {
    renderComponent();
  }
}

const initWindowResizeListener = _.once(() => {
  window.addEventListener('resize', () => {
    refreshWindowSize(); // cheap. ok to run on every tick.
    renderWatchingComponents(); // expensive. ensure all renders are throttled.
  });
  refreshWindowSize();
});

export function useRenderOnWindowResize(throttleMS = 200): void {
  initWindowResizeListener();
  const [, forceRender] = useState({});
  useLayoutEffect(() => {
    const throttledRender = _.throttle(() => forceRender({}), throttleMS);
    componentsWatchingWindowSize.add(throttledRender);
    return () => {
      componentsWatchingWindowSize.delete(throttledRender);
    };
    // eslint-disable-next-line
  }, []);
}

export function useWindowSize(throttleMS?: number): WindowSize {
  useRenderOnWindowResize(throttleMS);
  return windowSize;
}

export function openInNewTab(url: string): void {
  if (isFirefox) {
    // HAX: Firefox blocks new tabs by default, treating it as a popup window
    globalHistory.push(stripOriginFromURL(url));
    return;
  }
  window.open(urlPrefixed(url), undefined, 'noopener,noreferrer');
}
