import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {toast} from '@wandb/common/components/elements/Toast';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import {
  DragHandle,
  DragSource,
  DropTarget,
} from '@wandb/common/containers/DragDropContainer';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, {ReactElement, useContext, useState} from 'react';
import {Label, Popup} from 'semantic-ui-react';

import {PanelBankUpdaterContext} from '../../state/panelbank/context';
import {usePanelSearchDebouncedContext} from '../../state/panelbank/PanelSearchContextProvider';
import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigActions from '../../state/views/panelBankSectionConfig/actions';
import {useAllPanelBankSectionConfigsAction} from '../../state/views/panelBankSectionConfig/hooks';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {
  isDraggingWithinSection,
  isPanel,
  isPanelBankSection,
  PANEL_BANK_HIDDEN_SECTION_NAME,
} from '../../util/panelbank';
import {EditableLabel} from '../elements/EditableLabel';
import {PanelContainer} from '../Panel';
import * as PanelRunsLinePlot from '../PanelRunsLinePlot';
import {
  LinePlotPanel,
  LocalPanelSettingsComponentControlled,
} from '../PanelSettings';
import {PanelBankFlowSection} from './PanelBankFlowSection';
import {PanelBankGridSection} from './PanelBankGridSection';

interface PanelBankSectionProps {
  readOnly?: boolean;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankWidth: number;
  // query: Query.Query;
  workspacePanelSettingsRef: PanelSettingsTypes.Ref;
  // These are the panels that should be rendered. Note they may be a subset
  // of the panels present in config, due to searching and data availability.
  // It's important to use the config's panels in update logic.
  activePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  // These are panels that will not be rendered.  They were filtered out either
  // by hasDataForPanel or searchQueryMatchesPanel
  inactivePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  runSetRefs: RunSetViewTypes.Ref[];
  historyKeyInfo: RunHistoryKeyInfo;
  searchQuery?: string;
  addVisButton?: ReactElement;
  forceCollapsed?: boolean;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  moveSectionBefore(
    moveRef: PanelBankSectionConfigTypes.Ref,
    beforeSectionRef: PanelBankSectionConfigTypes.Ref
  ): void;
  addSectionAbove(): void;
  addSectionBelow(): void;
  deleteSection(): void;
  movePanel(
    panelRef: PanelTypes.Ref,
    fromSectionRef: PanelBankSectionConfigTypes.Ref,
    toSectionRef: PanelBankSectionConfigTypes.Ref,
    toIndex: number
  ): void;
}

export function usePanelBankSectionActions(
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref
) {
  const toggleType = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.toggleType
  );
  const toggleIsOpen = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.toggleIsOpen
  );
  const updateName = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.updateName
  );

  return {
    toggleType,
    toggleIsOpen,
    updateName,
  };
}

export function usePanelBankSectionConfig(
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref
) {
  return {
    panelBankSectionConfig: ViewHooks.useWhole(panelBankSectionConfigRef),
  };
}

function usePanelBankSectionProps(
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref
) {
  return {
    ...usePanelBankSectionActions(panelBankSectionConfigRef),
    ...usePanelBankSectionConfig(panelBankSectionConfigRef),
  };
}

export const PanelBankSection = (props: PanelBankSectionProps) => {
  const {panelBankSectionConfig, toggleType, toggleIsOpen, updateName} =
    usePanelBankSectionProps(props.panelBankSectionConfigRef);
  const [editingName, setEditingName] = useState(false);
  const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);

  const {
    panelBankConfigRef,
    panelBankSectionConfigRef,
    activePanelRefs,
    inactivePanelRefs,
    addSectionAbove,
    addSectionBelow,
    deleteSection,
    customRunColorsRef,
    workspacePanelSettingsRef,
    panelBankWidth,
    moveSectionBefore,
    runSetRefs,
    historyKeyInfo,
    movePanel,
    addVisButton,
    forceCollapsed,
    readOnly,
    defaultMoveToSectionRef,
  } = props;

  const normalizedPanelBankConfig = ViewHooks.usePart(
    panelBankSectionConfigRef
  );
  const localPanelSettingsRef = normalizedPanelBankConfig.localPanelSettingsRef;

  const localPanelSettings = ViewHooks.useWhole(localPanelSettingsRef);

  const workspacePanelSettings = ViewHooks.useWhole(workspacePanelSettingsRef);

  const useAllLinePlotPanels = () => {
    return React.useMemo(
      () =>
        panelBankSectionConfig.panels.filter(
          p => p.viewType === PanelRunsLinePlot.PANEL_TYPE
        ) as unknown as LinePlotPanel[],
      []
    );
  };

  // make sure this is properly memoized, or
  // the entire section will rerender on every update
  const usedPanelSettings = React.useMemo(() => {
    const usedXAxisSettings = _.pick(
      localPanelSettings.xAxisActive
        ? localPanelSettings
        : workspacePanelSettings,
      'xAxis',
      'xAxisMin',
      'xAxisMax',
      'xAxisActive'
    );

    const usedSmoothingSettings = _.pick(
      localPanelSettings.smoothingActive
        ? localPanelSettings
        : workspacePanelSettings,
      'smoothingWeight',
      'smoothingType',
      'smoothingActive'
    );

    return {
      ...usedXAxisSettings,
      ...usedSmoothingSettings,
      ignoreOutliers: workspacePanelSettings.ignoreOutliers,
      useRunsTableGroupingInPanels:
        workspacePanelSettings.useRunsTableGroupingInPanels,
    };
  }, [localPanelSettings, workspacePanelSettings]);

  const {setExportingSectionRefID} = useContext(PanelBankUpdaterContext);

  const sortPanels = useAllPanelBankSectionConfigsAction(
    [panelBankSectionConfigRef],
    PanelBankSectionConfigActions.sortPanels
  );

  const activePanelCount = activePanelRefs.length;
  const totalPanelCount = panelBankSectionConfig.panels.length;
  const isMobile = panelBankWidth <= 768; // tablet breakpoint from globals.less
  const isHiddenPanelsSection =
    panelBankSectionConfig.name === PANEL_BANK_HIDDEN_SECTION_NAME;

  const {isSearching} = usePanelSearchDebouncedContext();

  const SectionComponent =
    // Don't use grid section (custom layout) on mobile
    !isMobile &&
    // Don't use grid section if searching (otherwise you get weird blank spaces in the layout)
    !isSearching &&
    panelBankSectionConfig.type === 'grid'
      ? PanelBankGridSection
      : PanelBankFlowSection;

  const MAX_CUSTOM_PANEL_COUNT = 50; // panel limit for custom layout (grid) sections

  const sectionMenuOptions = _.compact([
    !isMobile && {
      key: 'toggleSectionType',
      text: `Switch to ${
        panelBankSectionConfig.type === 'grid' ? 'standard' : 'custom'
      } layout`,
      icon: `wbic-ic-${
        panelBankSectionConfig.type === 'grid' ? 'standard' : 'custom'
      }-layout`,
      onClick: () => {
        // Disable custom layout if there's more than MAX_CUSTOM_PANEL_COUNT panels
        if (
          panelBankSectionConfig.type !== 'grid' &&
          activePanelCount > MAX_CUSTOM_PANEL_COUNT
        ) {
          toast(
            `Custom Layout is limited to ${MAX_CUSTOM_PANEL_COUNT} panels.`
          );
        } else {
          toggleType();
        }
      },
    },
    {
      key: 'sortPanelsInSection',
      text: 'Sort panels A-Z',
      icon: 'wbic-ic-sort',
      onClick: sortPanels,
    },
    {
      key: 'addSectionAbove',
      text: 'Add section above',
      icon: 'wbic-ic-add-above',
      onClick: addSectionAbove,
    },
    {
      key: 'addSectionBelow',
      text: 'Add section below',
      icon: 'wbic-ic-add-below',
      onClick: addSectionBelow,
    },
    {
      key: 'renameSection',
      text: 'Rename section',
      icon: 'wbic-ic-edit',
      onClick: () => setEditingName(true),
    },
    {
      key: 'addSectionToReport',
      text: 'Add section to report',
      icon: 'wbic-ic-report',
      onClick: () => setExportingSectionRefID(panelBankSectionConfigRef.id),
    },
    {
      key: 'deleteSection',
      text: 'Delete section',
      icon: 'wbic-ic-delete',
      onClick: deleteSection,
    },
  ]);
  const sectionName = panelBankSectionConfig.name;

  // Collapse all sections if we're dragging a section (forceCollapsed=true)
  const sectionIsOpen = panelBankSectionConfig.isOpen && !forceCollapsed;

  return (
    <>
      <DropTarget
        isValidDropTarget={({dragRef, dragData}) => {
          return (
            isPanelBankSection(dragRef) ||
            (isPanel(dragRef) &&
              !isDraggingWithinSection(panelBankSectionConfigRef, dragData))
          );
        }}
        partRef={panelBankSectionConfigRef}
        getClassName={({dragRef, dropRef}) => {
          const elementClassNames = ['panel-bank__section'];
          if (!sectionIsOpen) {
            elementClassNames.push('collapsed');
          }
          if (isHiddenPanelsSection) {
            elementClassNames.push('panel-bank__section__hidden-panels');
          }
          if (dragRef != null && isPanel(dragRef)) {
            // always added when dragging a panel, even if the panel is not in this section
            elementClassNames.push('dragging-panel');
          }
          if (
            dropRef != null &&
            !_.isEqual(dragRef, dropRef) &&
            _.isEqual(panelBankSectionConfigRef, dropRef)
          ) {
            if (isPanelBankSection(dragRef)) {
              elementClassNames.push('active-section-drop-target');
            }
            if (
              isPanel(dragRef) &&
              (!sectionIsOpen ||
                activePanelRefs.length + inactivePanelRefs.length === 0)
            ) {
              elementClassNames.push('active-box-drop-target');
            }
          }
          return elementClassNames.join(' ');
        }}
        onDrop={({dragRef, dragData}) => {
          // Drag panel onto section
          if (isPanel(dragRef) && dragData) {
            movePanel(
              dragRef,
              dragData.fromSectionRef,
              panelBankSectionConfigRef,
              0
            );
          }
          // Drag section onto section
          if (isPanelBankSection(dragRef)) {
            if (!_.isEqual(dragRef, panelBankSectionConfigRef)) {
              moveSectionBefore(dragRef, panelBankSectionConfigRef);
            }
          }
        }}>
        <DragSource partRef={panelBankSectionConfigRef}>
          <div className="panel-bank__section-title">
            <span
              className="panel-bank__section-title-left"
              onClick={toggleIsOpen}>
              <LegacyWBIcon
                className={sectionIsOpen ? 'open' : undefined}
                name="next"
              />
              <EditableLabel
                title={sectionName}
                onSave={updateName}
                onBlur={() => setEditingName(false)}
                editing={editingName}
                serverText={sectionName}
                onClick={() => {
                  // we use the menu option to trigger editing mode, rather than direct click
                  return;
                }}
              />
              <Label className="count-label">
                {isSearching
                  ? `${activePanelCount} of ${totalPanelCount}`
                  : activePanelCount}
              </Label>
            </span>
            {!isHiddenPanelsSection && (
              <>
                <DragHandle
                  className="panel-bank__section__drag-handle"
                  partRef={panelBankSectionConfigRef}
                  key={`${panelBankSectionConfig.isOpen}${panelBankSectionConfig.name}${activePanelCount}`}>
                  <LegacyWBIcon
                    title=""
                    name="handle"
                    onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                  />
                </DragHandle>
                <div className="panel-bank__section-title-right">
                  <Popup
                    content="More"
                    position="bottom right"
                    size="small"
                    trigger={
                      <div
                        className={classNames({
                          'panel-section-options-visible': settingsPopupOpen,
                          'panel-section-options': !settingsPopupOpen,
                        })}>
                        <PopupDropdown
                          trigger={
                            <LegacyWBIcon
                              name="overflow"
                              size="big"
                              onClick={(e: React.SyntheticEvent) =>
                                e.stopPropagation()
                              }
                            />
                          }
                          options={sectionMenuOptions}
                          position="bottom left"
                          offset={'-320px, -16px'}
                        />
                      </div>
                    }
                  />
                  <LocalPanelSettingsComponentControlled
                    runSetRefs={runSetRefs}
                    useAllLinePlotPanels={useAllLinePlotPanels}
                    workspacePanelSettingsRef={workspacePanelSettingsRef}
                    localPanelSettingsRef={localPanelSettingsRef}
                    settingsPopupOpen={settingsPopupOpen}
                    setSettingsPopupOpen={setSettingsPopupOpen}
                  />
                  {addVisButton}
                </div>
              </>
            )}
          </div>

          {/* using display: none because we don't want to unmount the panels if forceCollapsed is true */}
          <div style={!sectionIsOpen ? {display: 'none'} : {}}>
            {panelBankSectionConfig.isOpen && (
              <SectionComponent
                panelBankSectionConfigRef={panelBankSectionConfigRef}
                panelBankWidth={panelBankWidth}
                activePanelRefs={activePanelRefs}
                inactivePanelRefs={inactivePanelRefs}
                movePanelBetweenSections={movePanel}
                addVisButton={addVisButton}
                // HAXX: this prop is not used, but needed to make the PanelBankFlowSection
                // rerender when settings are changed
                panelSettings={usedPanelSettings}
                renderPanel={panelRef => (
                  <PanelContainer
                    customRunColorsRef={customRunColorsRef}
                    defaultMoveToSectionRef={defaultMoveToSectionRef}
                    historyKeyInfo={historyKeyInfo}
                    isReadOnly={readOnly}
                    panelBankSectionConfigRef={panelBankSectionConfigRef}
                    panelBankConfigRef={panelBankConfigRef}
                    panelRef={panelRef}
                    panelSettings={usedPanelSettings}
                    runSetRefs={runSetRefs}
                  />
                )}
              />
            )}
          </div>
        </DragSource>
      </DropTarget>
      <div className="panel-bank__divider" />
    </>
  );
};
