import {Subtract} from '@wandb/common/types/base';
import * as React from 'react';

import {useRunsData} from './hooks';
import * as Types from './types';

// Props injected by the runs components.
export interface InjectedRunsDataProps {
  loading: boolean;
  data: Types.Data;
  query: Types.Query;

  runsDataQuery: ReturnType<typeof useRunsData>;
}

export const RunsDataLoader = (props: {
  query: Types.Query;
  children: (props: InjectedRunsDataProps) => JSX.Element;
}) => {
  const runsProps = useRunsData(props.query);
  return props.children({
    query: props.query,
    runsDataQuery: runsProps,
    ...runsProps,
  });
};

export interface WithRunsDataLoaderProps {
  query: Types.Query;
}

export const withRunsDataLoader =
  <P extends InjectedRunsDataProps>(
    Component: React.ComponentType<P>
  ): React.SFC<Subtract<P, InjectedRunsDataProps> & WithRunsDataLoaderProps> =>
  ({query, ...props}: WithRunsDataLoaderProps) =>
    (
      <RunsDataLoader query={query}>
        {injectedProps => <Component {...(props as P)} {...injectedProps} />}
      </RunsDataLoader>
    );
