import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const SavedOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearOption = styled(Typography)`
  color: #888;
`;

export const Timestamp = styled(Typography)`
  color: #888;
`;
