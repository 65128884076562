/**
 * Select a Default Resource Config (drc)
 */
import {DateFromUTCString} from '@wandb/common/util/time';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';

import {
  EntityDefaultResourceConfigsQuery,
  useEntityDefaultResourceConfigsQuery,
} from '../../generated/graphql';
import Alert from './Alert';

interface SelectDRCProps {
  entityName: string;
  onSelectDRC: (drcId: string) => void;
}

interface DRCOption {
  value: string;
  label: string;
}

const getDRCOptions = (data: EntityDefaultResourceConfigsQuery) => {
  const drcsWithConfig =
    data.entity?.defaultResourceConfigs?.edges
      .map(e => e.node)
      .filter(drc => drc.config != null) ?? [];

  const options = drcsWithConfig.map(drc => ({
    value: drc.id,
    label: `${drc.resource}, Created: ${DateFromUTCString(
      drc.createdAt
    ).toLocaleString()}`,
  }));

  return _.sortBy(options, 'label');
};

const SelectDefaultResourceConfig: React.FC<SelectDRCProps> = ({
  entityName,
  onSelectDRC,
}) => {
  const drcQuery = useEntityDefaultResourceConfigsQuery({
    variables: {
      entityName,
    },
  });
  if (drcQuery.loading || drcQuery.error || drcQuery.data == null) {
    return <div>Loading...</div>;
  }
  const options = getDRCOptions(drcQuery.data);
  if (options.length === 0) {
    return <Alert>There are no resource configurations in this team yet</Alert>;
  }

  const onChange = (option: DRCOption | null) => {
    if (option) {
      onSelectDRC(option.value);
    }
  };

  return (
    <Select
      options={options}
      placeholder="Select a resource configuration..."
      onChange={onChange}
    />
  );
};

export default SelectDefaultResourceConfig;
