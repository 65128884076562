import styled from 'styled-components';

export const StringContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  text-align: left;
  // Hide scrollbars
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const StringItem = styled.div`
  margin: auto;
  width: 100%;
  /* padding: 4px; */
  margin: auto;
  max-height: 100%;
`;
