import React from 'react';

import {ContributedResultsList} from './ContributedResultsList';
import {EntityResultsList} from './EntityResultsList';
import {RemainingResultsList} from './RemainResultsList';
import {useSearchContext} from './SearchContext';

export interface EntityPageResultsListsProps {
  blurAndClear: () => void;
  setShowAllContributedResults: (value: boolean) => void;
  setShowAllEntityResults: (value: boolean) => void;
  showAllContributedResults: boolean;
  showAllEntityResults: boolean;
  setShowAllRemainingResults: (value: boolean) => void;
  showAllRemainingResults: boolean;
}

export const EntityPageResultsLists = ({
  blurAndClear,

  setShowAllContributedResults,
  setShowAllEntityResults,
  setShowAllRemainingResults,
  showAllEntityResults,
  showAllContributedResults,
  showAllRemainingResults,
}: EntityPageResultsListsProps) => {
  const {displayedResults} = useSearchContext();

  const hasResults =
    displayedResults.entity.length +
      displayedResults.contributed.length +
      displayedResults.remaining.length >
    0;

  return hasResults ? (
    <>
      {displayedResults.entity.length > 0 && (
        <>
          <div className="search-result-header">In this entity</div>
          <EntityResultsList
            blurAndClear={blurAndClear}
            setShowAllEntityResults={setShowAllEntityResults}
            showAllEntityResults={showAllEntityResults}
          />
        </>
      )}
      {displayedResults.contributed.length > 0 && (
        <>
          <div className="search-result-header">Contributed to</div>
          <ContributedResultsList
            blurAndClear={blurAndClear}
            setShowAllContributedResults={setShowAllContributedResults}
            showAllContributedResults={showAllContributedResults}
          />
        </>
      )}
      {displayedResults.remaining.length > 0 && (
        <>
          <div className="search-result-header">From your teams</div>
          <RemainingResultsList
            blurAndClear={blurAndClear}
            setShowAllRemainingResults={setShowAllRemainingResults}
            showAllRemainingResults={showAllRemainingResults}
          />
        </>
      )}
    </>
  ) : (
    <div className="no-search-results">No results</div>
  );
};
