import {omit as _omit} from 'lodash';

import * as Normalize from '../normalize';
import * as PanelNormalize from '../panel/normalize';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import {EMPTY_SETTINGS} from './../../../util/panelsettings';
import * as Types from './types';

const omit = _omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize =
  Normalize.normFn<Types.PanelBankSectionConfigObjSchema>(
    'panel-bank-section-config',
    (whole, ctx) => {
      return {
        ...omit(whole, 'panels'),
        ...omit(whole, 'localPanelSettings'),
        panelRefs:
          whole.panels != null
            ? whole.panels.map(panel => PanelNormalize.normalize(panel, ctx))
            : [],
        localPanelSettingsRef: PanelSettingsNormalize.normalize(
          whole.localPanelSettings ?? EMPTY_SETTINGS,
          ctx
        ),
      };
    }
  );

export const denormalize =
  Normalize.denormFn<Types.PanelBankSectionConfigObjSchema>((part, ctx) => {
    return {
      ...omit(part, 'panelRefs'),
      ...omit(part, 'localPanelSettingsRef'),
      panels:
        part.panelRefs != null
          ? part.panelRefs.map(ref => PanelNormalize.denormalize(ref, ctx))
          : [],
      localPanelSettings:
        part.localPanelSettingsRef != null
          ? PanelSettingsNormalize.denormalize(part.localPanelSettingsRef, ctx)
          : EMPTY_SETTINGS,
    };
  });
