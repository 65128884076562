import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {toast} from '@wandb/common/components/elements/Toast';
import {TABLE_MIN_COLUMN_WIDTH} from '@wandb/common/util/constants';
import {makePropsAreEqual} from '@wandb/common/util/shouldUpdate';
import {WBIcon} from '@wandb/ui';
import {WBPopupMenuTrigger} from '@wandb/ui';
import {WBMenuOption} from '@wandb/ui';
import copyText from 'copy-to-clipboard';
import React, {memo} from 'react';
import {ResizableBox as ResizableBoxOriginal} from 'react-resizable';
import {Popup} from 'semantic-ui-react';

import {
  Counts as RunCounts,
  keyDisplayName,
  keyToCss,
  keyToString,
} from '../../util/runs';
import {WBTableColumn} from './WBTable';
import {WBTableSortIndicatorComponent} from './WBTableSortIndicator';

// The available types for react-resizable are wrong.
// (as of react-resizable 3.0.2, @types/react-resizable 3.0.2)
// The docs claim that unknown props on ResizableBox are passed through,
// but ResizableBoxProps disagrees.
const ResizableBox = ResizableBoxOriginal as any;

export interface WBTableCellColumnHeaderProps {
  runCounts?: RunCounts;
  displayedRows?: any[]; // table rows that are displayed in the current page
  columnIndex: number;
  column: WBTableColumn;
  columnMenuItems: WBMenuOption[];
  SortIndicatorComponent?: WBTableSortIndicatorComponent;
  hovering: boolean;
  draggable: boolean;
  readOnly?: boolean;
  isInReport?: boolean;
  columnResizingProps: {
    resizing?: boolean;
    onResizeStart(): void;
    onResize(offset: number): void;
    resizeColumn(width: number): void;
  };
  columnMovingProps: {
    dragging?: boolean;
    dropping?: boolean;
    dragHandleProps: {
      onMouseDown(): void;
      onMouseUp(): void;
    };
    onDragEnd(): void;
    onDragEnter(): void;
    onDrop(): void;
  };
  columnWidth: number;
  cellHoverProps: {
    onMouseEnter(): void;
    onMouseLeave(): void;
  };
}

const WBTableCellColumnHeader: React.FC<WBTableCellColumnHeaderProps> = ({
  displayedRows,
  column,
  columnIndex,
  columnResizingProps,
  columnMovingProps,
  draggable,
  hovering,
  cellHoverProps,
  columnMenuItems,
  columnWidth,
  SortIndicatorComponent,
  readOnly,
  isInReport,
}: WBTableCellColumnHeaderProps) => {
  const columnKey = column.key;
  const keyCss = keyToCss(columnKey);
  const className = `wb-tree-cell wb-tree-cell--header wb-tree-cell--header-column
            wb-tree-cell--column-${keyCss}
            wb-tree-cell--column-${columnIndex}
            ${
              hovering && keyCss !== 'run_name'
                ? 'wb-tree-cell--hovering-header'
                : ''
            }
            ${columnResizingProps.resizing ? 'resizing' : ''}
            ${columnMovingProps.dragging ? 'dragging' : ''}
            ${columnMovingProps.dropping ? 'wb-tree-cell--dropping' : ''}`;

  const columnDisplayName = keyDisplayName(column.key);
  return (
    <div className="wb-tree-cell--header-column-wrapper" key={column.accessor}>
      <ResizableBox
        className={className}
        width={columnWidth}
        height={44}
        minConstraints={[TABLE_MIN_COLUMN_WIDTH, 0]}
        axis={readOnly ? undefined : 'x'}
        onResizeStart={columnResizingProps.onResizeStart}
        onResizeStop={(e: React.SyntheticEvent, data: any) => {
          columnResizingProps.resizeColumn(data.size.width);
          window.analytics?.track('Workspace resized');
        }}
        onResize={(e: React.SyntheticEvent, data: any) => {
          columnResizingProps.onResize(data.size.width - columnWidth);
        }}
        onMouseEnter={cellHoverProps.onMouseEnter}
        onMouseLeave={cellHoverProps.onMouseLeave}
        /* BEGIN: drag-and-drop for column reordering */
        draggable={!readOnly && columnMovingProps.dragging}
        onDragEnter={columnMovingProps.onDragEnter}
        onDragOver={(e: React.SyntheticEvent) => {
          e.preventDefault(); // this is necessary for onDrop to work
        }}
        onDragStart={(e: React.DragEvent) => {
          e.stopPropagation();
          e.dataTransfer.setData('text', ''); // this is necessary for drag+drop to work in firefox
        }}
        onDragEnd={columnMovingProps.onDragEnd}
        onDrop={columnMovingProps.onDrop}>
        <>
          {column.renderHeader ? (
            column.renderHeader(displayedRows)
          ) : (
            <Popup
              offset={-11}
              hoverable
              inverted
              content={
                <span
                  className="wb-table-header--copyable"
                  onClick={() => {
                    copyText(columnDisplayName);
                    toast(`Column header copied to clipboard`);
                  }}>
                  <LegacyWBIcon name="copy" className={'copy-icon'} />
                  {columnDisplayName}
                </span>
              }
              size="mini"
              style={{padding: '8px 10px'}}
              // This is the drag handle and column name
              trigger={
                <span
                  className={draggable ? 'drag-handle' : ''}
                  onMouseDown={
                    draggable
                      ? columnMovingProps.dragHandleProps.onMouseDown
                      : undefined
                  }
                  onMouseUp={
                    draggable
                      ? columnMovingProps.dragHandleProps.onMouseUp
                      : undefined
                  }>
                  {columnDisplayName}
                </span>
              }
            />
          )}

          {SortIndicatorComponent && (
            <SortIndicatorComponent columnKeyString={keyToString(column.key)} />
          )}

          <span className="wb-tree-cell--header-column-spacer" />

          {!readOnly && (
            <WBPopupMenuTrigger options={columnMenuItems}>
              {({anchorRef, setOpen, open}) => (
                <WBIcon
                  className="column-actions-trigger"
                  style={{display: open ? 'block' : undefined}}
                  name="overflow"
                  ref={anchorRef}
                  onClick={() => {
                    setOpen(o => !o);
                    const actionLocation = isInReport ? 'report' : 'runs table';
                    window.analytics?.track('Column header dropdown clicked', {
                      location: actionLocation,
                    });
                  }}
                />
              )}
            </WBPopupMenuTrigger>
          )}
        </>
      </ResizableBox>
    </div>
    // </StickyHeader>
  );
};

export const WBTableCellColumnHeaderMemo: React.FC<WBTableCellColumnHeaderProps> =
  memo(
    WBTableCellColumnHeader,
    makePropsAreEqual({
      name: 'WBTableCellColumnHeader',
      deep: [
        'column',
        'runCounts',
        'columnKey',
        'columnMenuItems',
        'columnResizingProps',
        'columnMovingProps',
      ],
      ignore: ['cellHoverProps'],
      ignoreFunctions: true,
      debug: false,
      verbose: true,
    })
  );
