import * as _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import {Button, Loader as SemanticLoader, Tab} from 'semantic-ui-react';
import {calculatePosition, Handler} from 'vega-tooltip';
import {VisualizationSpec} from 'react-vega';
import {produce} from 'immer';

import {
  Node,
  ConstNode,
  Frame,
  list,
  listObjectType,
  isTypedDict,
} from '@wandb/cg';
import {
  constNumber,
  constString,
  escapeDots,
  isAssignableTo,
  numberBin,
  maybe,
  oneOrMany,
  opArray,
  opIndex,
  opPick,
  opRunId,
  opRunName,
  opUnnest,
  toPythonTyping,
  union,
  varNode,
  voidNode,
} from '@wandb/cg';
import CustomPanelRenderer, {
  MultiTableDataType,
} from '@wandb/common/components/Vega3/CustomPanelRenderer';
import {ActivityDashboardContext} from '@wandb/common/components/ActivityDashboardContext';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {RepoInsightsDashboardContext} from '@wandb/common/components/RepoInsightsDashboardContext';
import PanelError from '@wandb/common/components/elements/PanelError';
import * as globals from '@wandb/common/css/globals.styles';
import {useGatedValue} from '@wandb/common/state/hooks';
import HighlightedIcon from '@wandb/common/components/HighlightedIcon';
import {toast} from '@wandb/common/components/elements/Toast';
import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';

import * as LLReact from '../../../cgreact';
import {useWeaveContext, WeaveWBBetaFeaturesContext} from '../../../context';
import {Panel2Loader, PanelComp2} from '../PanelComp';
import * as Panel2 from '../panel';
import * as TableState from '../PanelTable/tableState';
import {getPanelStackDims, getPanelStacksForType} from '../availablePanels';
import {usePanelContext} from '../PanelContext';
import {useTableStatesWithRefinedExpressions} from '../PanelTable/tableStateReact';
import * as TableType from '../PanelTable/tableType';
import {makeEventRecorder} from '../panellib/libanalytics';
import * as ConfigPanel from '../ConfigPanel';

import {
  AnyPlotConfig,
  DIM_NAME_MAP,
  MarkOption,
  migrate,
  PLOT_DIMS_UI,
  PlotConfig,
  POINT_SHAPES,
  SeriesConfig,
} from './versions';
import * as PlotState from './plotState';
import {
  defaultPlot,
  DimensionLike,
  ExpressionDimName,
  isValidConfig,
} from './plotState';
import * as v1 from './versions/v1';

const recordEvent = makeEventRecorder('Plot');

const defaultFontStyleDict = {
  titleFont: 'Source Sans Pro',
  titleFontWeight: 'normal',
  titleColor: globals.gray900,
  labelFont: 'Source Sans Pro',
  labelFontWeight: 'normal',
  labelColor: globals.gray900,
  labelSeparation: 5,
};

type DimOption = {
  text: string;
  icon: string;
  onClick: () => void;
};

type DimOptionOrSection = DimOption | DimOption[];

const isEmptyConfig = (config: any): config is null | undefined => {
  return config == null || Object.keys(config).length === 0;
};

const hasVersion = (config: any): config is AnyPlotConfig => {
  return config?.configVersion != null;
};

const assumePropsConfigIsUnmarkedV1 = (config: any) => {
  return (
    config != null &&
    !isEmptyConfig(config) &&
    !(config as any).configVersion &&
    (config as v1.PlotConfig).dims != null
  );
};

const useConfig = (
  inputNode: Node,
  propsConfig?: AnyPlotConfig
): {config: PlotConfig; isRefining: boolean} => {
  const {frame} = usePanelContext();
  const weave = useWeaveContext();

  const newConfig = useMemo(() => {
    // TODO: (ts) Should reset config when the incoming type changes (similar to table - maybe a common refactor?)

    // This is a hack that handles the fact that our config migrator needs an explicitly versioned config object, but
    // many of our plotConfigs were persisted before the introduction of the configVersion key. This adds configVersion = 1

    if (assumePropsConfigIsUnmarkedV1(propsConfig)) {
      const imputedConfig = produce(propsConfig as v1.PlotConfig, draft => {
        draft.configVersion = 1;
      });
      return migrate(imputedConfig);
    } else if (hasVersion(propsConfig)) {
      return migrate(propsConfig);
    } else {
      return defaultPlot(inputNode, frame);
    }
  }, [propsConfig, inputNode, frame]);

  const defaultColNameStrippedConfig = useMemo(
    () =>
      produce(newConfig, draft => {
        draft.series.forEach(s => {
          ['pointShape' as const, 'pointSize' as const].forEach(colName => {
            if (s.table.columnNames[s.dims[colName]] === colName) {
              s.table = TableState.updateColumnName(
                s.table,
                s.dims[colName],
                ''
              );
            }
          });
        });
      }),
    [newConfig]
  );

  const tableStates = useMemo(
    () => defaultColNameStrippedConfig.series.map(s => s.table),
    [defaultColNameStrippedConfig.series]
  );

  const loadable = useTableStatesWithRefinedExpressions(
    tableStates,
    inputNode,
    frame,
    weave
  );

  const configWithRefinedExpressions = useMemo(() => {
    return loadable.loading
      ? newConfig
      : produce(newConfig, draft => {
          draft.series.forEach((s, i) => {
            s.table = loadable.result[i];
          });
        });
  }, [loadable, newConfig]);

  return useMemo(
    () => ({
      config: configWithRefinedExpressions,
      isRefining: loadable.loading,
    }),
    [configWithRefinedExpressions, loadable.loading]
  );
};

export const inputType = TableType.GeneralTableLikeType;
export type PanelPlotProps = Panel2.PanelProps<typeof inputType, AnyPlotConfig>;

const PanelPlotConfig: React.FC<PanelPlotProps> = props => {
  const {input} = props;

  const inputNode = useMemo(() => TableType.normalizeTableLike(input), [input]);
  const typedInputNodeUse = LLReact.useNodeWithServerType(inputNode);
  const newProps = useMemo(() => {
    return {
      ...props,
      input: typedInputNodeUse.result as any,
    };
  }, [props, typedInputNodeUse.result]);

  const isRepoInsightsDash =
    Object.keys(useContext(RepoInsightsDashboardContext).frame).length > 0;
  const loaderComp = isRepoInsightsDash ? (
    <SemanticLoader active inline className="cgLoader" />
  ) : (
    <WandbLoader name="panel-plot-config" />
  );

  if (typedInputNodeUse.loading) {
    return loaderComp;
  } else if (typedInputNodeUse.result.nodeType === 'void') {
    return <></>;
  } else {
    return <PanelPlotConfigInner {...newProps} />;
  }
};

const PanelPlotConfigInner: React.FC<PanelPlotProps> = props => {
  const {input, updateConfig: propsUpdateConfig} = props;

  const inputNode = input;

  const {'weave-python-ecosystem': weavePythonEcosystemEnabled} =
    React.useContext(WeaveWBBetaFeaturesContext);
  const weave = useWeaveContext();
  const {frame} = usePanelContext();

  // this migrates the config and returns a config of the latest version
  const {config} = useConfig(inputNode, props.config);

  const updateConfig = useCallback(
    (newConfig?: Partial<PlotConfig>) => {
      if (!newConfig) {
        // if config is undefined, just use the default plot
        propsUpdateConfig(defaultPlot(input, frame));
      } else {
        propsUpdateConfig({
          ...config,
          ...newConfig,
        });
      }
    },
    [config, propsUpdateConfig, input, frame]
  );

  const resetConfig = useCallback(() => {
    updateConfig(undefined);
  }, [updateConfig]);

  const condense = useCallback(() => {
    const newConfig = PlotState.condensePlotConfig(config, weave);
    updateConfig(newConfig);
  }, [weave, config, updateConfig]);

  const exportAsCode = useCallback(() => {
    if (navigator?.clipboard == null) {
      return;
    }

    if (config.series.length !== 1) {
      toast('Multi-series plots are not currently supported');
      return;
    }

    const series = config.series[0];

    const dimConfigured = (dim: keyof SeriesConfig['dims']) =>
      series.table.columnSelectFunctions[series.dims[dim]].type !== 'invalid';

    if (!dimConfigured('x') || !dimConfigured('y')) {
      toast(
        "Can't export to code: Required dimensions x and/or y are not configured"
      );
      return;
    }

    const dimArgument = (dim: keyof SeriesConfig['dims']) =>
      `${dim}=lambda row: ${weave.expToString(
        series.table.columnSelectFunctions[series.dims[dim]],
        null
      )},`;

    const inputTypeText = toPythonTyping(input.type);

    const dims: Array<keyof SeriesConfig['dims']> = [
      'x',
      'y',
      'label',
      'tooltip',
    ];

    const codeText = [
      '@weave.op()',
      `def my_panel(input: weave.Node[${inputTypeText}]) -> panels.Plot:`,
      '  return panels.Plot(',
      '    input,',
      ...dims.reduce<string[]>((memo, field) => {
        if (dimConfigured(field)) {
          memo.push(`    ${dimArgument(field)}`);
        }
        return memo;
      }, [] as string[]),
      '  )\n',
    ].join('\n');

    navigator.clipboard
      .writeText(codeText)
      .then(() => toast('Code copied to clipboard!'));
  }, [config, input.type, weave]);

  const labelConfigDom = useMemo(() => {
    return (
      <>
        {['X Axis Label', 'Y Axis Label', 'Color Legend Label'].map(name => {
          const dimName = name.split(' ')[0].toLowerCase() as
            | 'x'
            | 'y'
            | 'color';
          return (
            <ConfigPanel.ConfigOption key={name} label={name}>
              <ConfigPanel.TextInputConfigField
                dataTest={`${name}-label`}
                value={config.axisSettings[dimName].title}
                label={''}
                onChange={(event, {value}) => {
                  updateConfig({
                    axisSettings: {
                      ...config.axisSettings,
                      [dimName]: {
                        ...config.axisSettings[dimName],
                        title: value,
                      },
                    },
                  });
                }}
              />
            </ConfigPanel.ConfigOption>
          );
        })}
        {config.series.map((series, i) => {
          const seriesName = `Series ${i + 1} Name`;
          return (
            <ConfigPanel.ConfigOption
              key={seriesName}
              label={config.series.length > 1 ? seriesName : 'Series'}>
              <ConfigPanel.TextInputConfigField
                dataTest={`${seriesName}-label`}
                value={series.name}
                label={''}
                onChange={(event, {value}) => {
                  updateConfig(
                    produce(config, draft => {
                      draft.series[i].name = value;
                    })
                  );
                }}
              />
            </ConfigPanel.ConfigOption>
          );
        })}
      </>
    );
  }, [config, updateConfig]);

  const seriesConfigDom = useMemo(() => {
    const firstSeries = config.series[0];

    return (
      <>
        {PLOT_DIMS_UI.map(dimName => {
          const dimIsShared = PlotState.isDimShared(
            config.series,
            dimName,
            weave
          );

          const dimIsExpanded = config.configOptionsExpanded[dimName];
          const dimObject = PlotState.dimConstructors[dimName](
            firstSeries,
            weave
          );

          const dimIsSharedInUI = dimIsShared && !dimIsExpanded;
          return dimIsSharedInUI ? (
            <ConfigDimComponent
              key={dimName}
              input={input}
              config={config}
              updateConfig={updateConfig}
              indentation={0}
              isShared={dimIsSharedInUI}
              dimension={dimObject}
            />
          ) : (
            <>
              {config.series.map((s, i) => {
                const seriesDim = PlotState.dimConstructors[dimName](s, weave);
                return (
                  <ConfigDimComponent
                    key={`${dimName}-${i}`}
                    input={input}
                    config={config}
                    updateConfig={updateConfig}
                    indentation={0}
                    isShared={dimIsSharedInUI}
                    dimension={seriesDim}
                  />
                );
              })}
            </>
          );
        })}
      </>
    );
  }, [config, weave, input, updateConfig]);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const configTabs = useMemo(() => {
    const panes: Array<{menuItem: string; render: () => React.ReactElement}> = [
      {
        menuItem: 'Data',
        render: () => seriesConfigDom,
      },
      {
        menuItem: 'Labels',
        render: () => labelConfigDom,
      },
    ];
    return (
      <Tab
        panes={panes}
        onTabChange={(e, {activeIndex}) => {
          setActiveTabIndex(activeIndex as number);
        }}
        activeIndex={activeTabIndex}
      />
    );
  }, [activeTabIndex, seriesConfigDom, labelConfigDom]);

  const seriesButtons = useMemo(
    () => (
      <>
        <Button size="tiny" onClick={resetConfig}>
          {'Reset & Automate Plot'}
        </Button>
        <Button size="tiny" onClick={condense}>
          {'Condense'}
        </Button>
        {weavePythonEcosystemEnabled && (
          <Button size="tiny" onClick={exportAsCode}>
            {'Export as Code'}
          </Button>
        )}
      </>
    ),
    [resetConfig, condense, exportAsCode, weavePythonEcosystemEnabled]
  );

  return useMemo(
    () => (
      <>
        {configTabs}
        {activeTabIndex === 0 && seriesButtons}
      </>
    ),
    [configTabs, seriesButtons, activeTabIndex]
  );
};

const useLoader = () => {
  const isRepoInsightsDash =
    Object.keys(useContext(RepoInsightsDashboardContext).frame).length > 0;
  return isRepoInsightsDash ? (
    <SemanticLoader active inline className="cgLoader" />
  ) : (
    <WandbLoader name="repo-insights-loader" />
  );
};

const PanelPlot2: React.FC<PanelPlotProps> = props => {
  const {input} = props;

  const inputNode = useMemo(() => TableType.normalizeTableLike(input), [input]);
  const typedInputNodeUse = LLReact.useNodeWithServerType(inputNode);
  const newProps = useMemo(() => {
    return {
      ...props,
      input: typedInputNodeUse.result as any,
    };
  }, [props, typedInputNodeUse.result]);

  const loaderComp = useLoader();

  if (typedInputNodeUse.loading) {
    return <div style={{height: '100%', width: '100%'}}>{loaderComp}</div>;
  } else if (typedInputNodeUse.result.nodeType === 'void') {
    return <div style={{height: '100%', width: '100'}}></div>;
  } else {
    return (
      <div style={{height: '100%', width: '100%'}}>
        <PanelPlot2ConfigBarrier {...newProps} />
      </div>
    );
  }
};

const PanelPlot2ConfigBarrier: React.FC<PanelPlotProps> = props => {
  const {config, isRefining} = useConfig(props.input, props.config);
  const loaderComp = useLoader();
  if (isRefining) {
    return loaderComp;
  }
  return <PanelPlot2Inner {...props} config={config} />;
};

const stringIsColorLike = (val: string): boolean => {
  return (
    val.match('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$') != null || // matches hex code
    (val.startsWith('rgb(') && val.endsWith(')')) || // rgb
    (val.startsWith('hsl(') && val.endsWith(')')) // hsl
  );
};

export type DimComponentInputType = {
  input: PanelPlotProps['input'];
  config: PlotConfig;
  updateConfig: (config?: Partial<PlotConfig>) => void;
  indentation: number;
  isShared: boolean;
  dimension: DimensionLike;
  extraOptions?: DimOptionOrSection[];
};

const ConfigDimLabel: React.FC<
  Omit<DimComponentInputType, 'extraOptions'> & {
    postfixComponent?: React.ReactElement;
  }
> = props => {
  return (
    <div style={{paddingLeft: 10 * props.indentation}}>
      <ConfigPanel.ConfigOption
        label={
          DIM_NAME_MAP[props.dimension.name] +
          (props.isShared || props.config.series.length === 1
            ? ''
            : ` ${props.config.series.indexOf(props.dimension.series) + 1}`)
        }
        data-test={`${props.dimension.name}-dim-config`}
        postfixComponent={props.postfixComponent}>
        {props.children}
      </ConfigPanel.ConfigOption>
    </div>
  );
};

export const WeaveExpressionDimConfig: React.FC<{
  dimName: ExpressionDimName;
  input: PanelPlotProps['input'];
  series: SeriesConfig[];
  config: PlotConfig;
  updateConfig: PanelPlotProps['updateConfig'];
}> = props => {
  const {config, input, updateConfig, series} = props;

  const seriesIndices = useMemo(
    () => series.map(s => config.series.indexOf(s)),
    [series, config.series]
  );
  const updateDims = useCallback(
    (node: Node) => {
      const newConfig = produce(config, draft => {
        seriesIndices.forEach(i => {
          const s = draft.series[i];
          s.table = TableState.updateColumnSelect(
            s.table,
            s.dims[props.dimName],
            node
          );
        });
      });
      updateConfig(newConfig);
    },
    [config, props.dimName, seriesIndices, updateConfig]
  );
  const {frame} = usePanelContext();
  const weave = useWeaveContext();

  const tableConfigs = useMemo(() => series.map(s => s.table), [series]);
  const rowsNodes = useMemo(() => {
    return series.map(
      s =>
        TableState.tableGetResultTableNode(s.table, input, frame, weave)
          .rowsNode
    );
  }, [series, input, frame, weave]);
  const colIds = useMemo(
    () => series.map(s => s.dims[props.dimName]),
    [series, props.dimName]
  );

  const cellFrames = useMemo(
    () =>
      rowsNodes.map((rowsNode, i) => {
        const tableState = tableConfigs[i];
        const colId = colIds[i];
        return TableState.getCellFrame(
          input,
          rowsNode,
          frame,
          tableState.groupBy,
          tableState.columnSelectFunctions,
          colId
        );
      }),
    [rowsNodes, input, frame, tableConfigs, colIds]
  );

  return (
    <ConfigPanel.ExpressionConfigField
      frame={cellFrames[0]}
      expr={tableConfigs[0].columnSelectFunctions[colIds[0]]}
      setExpression={updateDims as any}
    />
  );
};

export const ConfigDimComponent: React.FC<DimComponentInputType> = props => {
  const {
    updateConfig,
    config,
    dimension,
    isShared,
    indentation,
    input,
    extraOptions,
  } = props;
  const weave = useWeaveContext();
  const makeUnsharedDimDropdownOptions = useCallback(
    (series: SeriesConfig, dimName: typeof PLOT_DIMS_UI[number]) => {
      const removeSeriesDropdownOption =
        config.series.length > 1
          ? {
              text: 'Remove series',
              icon: 'wbic-ic-delete',
              onClick: () => {
                updateConfig(PlotState.removeSeries(config, series));
              },
            }
          : null;

      const addSeriesDropdownOption = {
        text: 'Add series from this series',
        icon: 'wbic-ic-plus',
        onClick: () => {
          const newConfig = PlotState.addSeriesFromSeries(
            config,
            series,
            dimName,
            weave
          );
          updateConfig(newConfig);
        },
      };

      const collapseDimDropdownOption =
        config.series.length > 1
          ? {
              text: 'Collapse dimension',
              icon: 'wbic-ic-collapse',
              onClick: () => {
                updateConfig(
                  PlotState.makeDimensionShared(config, series, dimName, weave)
                );
              },
            }
          : null;

      return [
        removeSeriesDropdownOption,
        addSeriesDropdownOption,
        collapseDimDropdownOption,
      ];
    },
    [config, updateConfig, weave]
  );

  const makeSharedDimDropdownOptions = useCallback(
    (dimName: typeof PLOT_DIMS_UI[number]) => {
      const expandDim =
        config.series.length > 1
          ? {
              text: 'Expand dimension',
              icon: 'wbic-ic-expand',
              onClick: () => {
                const newConfig = produce(config, draft => {
                  draft.configOptionsExpanded[dimName] = true;
                });
                updateConfig(newConfig);
              },
            }
          : null;

      return [expandDim];
    },
    [config, updateConfig]
  );

  const uiStateOptions = useMemo(() => {
    // return true if an expression can be directly switched to a constant
    const isDirectlySwitchable = (
      dim: PlotState.DropdownWithExpressionDimension
    ): boolean => {
      const options = dim.dropdownDim.options;
      const expressionValue = dim.expressionDim.state().value;
      const expressionIsConst = expressionValue.nodeType === 'const';
      return options.some(
        o =>
          expressionIsConst &&
          _.isEqual(o.value, (expressionValue as ConstNode).val) &&
          o.representableAsExpression
      );
    };

    const clickHandler = (
      dim: PlotState.DropdownWithExpressionDimension,
      kernel: (
        series: SeriesConfig,
        dimension: PlotState.DropdownWithExpressionDimension
      ) => void
    ): void => {
      const newConfig = produce(config, draft => {
        const seriesToIterateOver = isShared
          ? draft.series
          : _.compact([
              draft.series.find(series => _.isEqual(series, dim.series)),
            ]);
        seriesToIterateOver.forEach(s => kernel(s, dim));
      });

      updateConfig(newConfig);
    };

    const switchState = PlotState.isDropdownWithExpression(dimension)
      ? [
          {
            text: 'Input method',
            icon: null,
            disabled: true,
          },
          {
            text: 'Select via dropdown',
            icon: 'wbic-ic-list',
            active: dimension.mode() === 'dropdown',
            onClick: () => {
              clickHandler(dimension, (s, dim) => {
                if (s.uiState[dim.name] === 'expression') {
                  s.uiState[dim.name] = 'dropdown';
                  const expressionValue = dim.expressionDim.state().value;

                  // If the current expression has a corresponding dropdown option, use that dropdown value
                  if (isDirectlySwitchable(dim)) {
                    s.constants[dim.name] = (expressionValue as ConstNode)
                      .val as any;
                  }
                }
              });
            },
          },
          {
            text: 'Enter a Weave Expression',
            icon: 'wbic-ic-xaxis',

            active: dimension.mode() === 'expression',
            onClick: () => {
              clickHandler(dimension, (s, dim) => {
                if (s.uiState[dim.name] === 'dropdown') {
                  s.uiState[dim.name] = 'expression';

                  // If the current dropdown is representable as an expression, use that expression
                  if (isDirectlySwitchable(dim)) {
                    const colId = s.dims[dim.name];
                    s.table = TableState.updateColumnSelect(
                      s.table,
                      colId,
                      constString(s.constants[dim.name])
                    );
                  }
                }
              });
            },
          },
        ]
      : null;
    return [switchState];
  }, [config, updateConfig, isShared, dimension]);

  const topLevelDimOptions = useCallback(
    (dimName: typeof PLOT_DIMS_UI[number]) => {
      return isShared
        ? makeSharedDimDropdownOptions(dimName)
        : makeUnsharedDimDropdownOptions(dimension.series, dimName);
    },
    [
      makeSharedDimDropdownOptions,
      makeUnsharedDimDropdownOptions,
      dimension.series,
      isShared,
    ]
  );

  const dimOptions = useMemo(
    () =>
      _.compact([
        ...(PlotState.isTopLevelDimension(dimension.name)
          ? topLevelDimOptions(dimension.name)
          : []),
        ...uiStateOptions,
        ...(extraOptions || []),
      ]),
    [dimension, uiStateOptions, topLevelDimOptions, extraOptions]
  );

  const postFixComponent = useMemo(
    () =>
      dimOptions.length > 0 ? (
        <PopupDropdown
          offset={'10px, -10px'}
          position="bottom right"
          trigger={
            <div style={{marginLeft: '10px'}}>
              <HighlightedIcon>
                <LegacyWBIcon name="overflow" />
              </HighlightedIcon>
            </div>
          }
          options={dimOptions.filter(o => !Array.isArray(o))}
          sections={dimOptions.filter(o => Array.isArray(o)) as DimOption[][]}
        />
      ) : undefined,
    [dimOptions]
  );

  if (PlotState.isDropdownWithExpression(dimension)) {
    return (
      <ConfigDimComponent
        {...props}
        dimension={
          dimension.mode() === 'expression'
            ? dimension.expressionDim
            : dimension.dropdownDim
        }
        extraOptions={uiStateOptions as DimOptionOrSection[]}
      />
    );
  } else if (PlotState.isGroup(dimension)) {
    const primary = dimension.primaryDimension();
    return (
      <>
        {dimension.activeDimensions().map(dim => {
          const isPrimary = dim.equals(primary);
          return (
            <ConfigDimComponent
              {...props}
              key={dim.name}
              indentation={isPrimary ? indentation : indentation + 1}
              dimension={dim}
            />
          );
        })}
      </>
    );
  } else if (PlotState.isDropdown(dimension)) {
    const dimName = dimension.name;
    return (
      <ConfigDimLabel
        {...props}
        postfixComponent={postFixComponent || undefined}>
        <ConfigPanel.ModifiedDropdownConfigField
          selection
          placeholder={dimension.defaultState().compareValue}
          value={dimension.state().value}
          options={dimension.options}
          onChange={(e, {value}) => {
            const newSeries = produce(config.series, draft => {
              draft.forEach(s => {
                if (isShared || _.isEqual(s, dimension.series)) {
                  // @ts-ignore
                  s.constants[dimName] = value;
                }
              });
            });
            updateConfig({
              series: newSeries,
            });
          }}
        />
      </ConfigDimLabel>
    );
  } else if (PlotState.isWeaveExpression(dimension)) {
    return (
      <ConfigDimLabel
        {...props}
        postfixComponent={postFixComponent || undefined}>
        <WeaveExpressionDimConfig
          dimName={dimension.name}
          input={input}
          config={config}
          updateConfig={updateConfig}
          series={isShared ? config.series : [dimension.series]}
        />
      </ConfigDimLabel>
    );
  }
  return <></>;
};

const useVegaReadyTables = (series: SeriesConfig[], frame: Frame) => {
  // This function assigns smart defaults for the color of a point based on the label.

  // TODO(DG): figure out how to memoize these. If we memoize and depend on series, then
  // tooltips do not disappear on mouseout.
  const tables = series.map(s => s.table);
  const allDims = series.map(s => s.dims);

  return useMemo(() => {
    return tables.map((table, i) => {
      const dims = allDims[i];
      const labelSelectFn = table.columnSelectFunctions[dims.label];
      if (labelSelectFn.nodeType !== 'void') {
        const labelType = TableState.getTableColType(table, dims.label);
        if (frame.runColors != null) {
          if (isAssignableTo(labelType, maybe('run'))) {
            let retTable = TableState.updateColumnSelect(
              table,
              dims.color,
              opPick({
                obj: varNode(frame.runColors.type, 'runColors'),
                key: opRunId({
                  run: labelSelectFn,
                }),
              })
            );

            retTable = TableState.updateColumnSelect(
              retTable,
              dims.label,
              opRunName({
                run: labelSelectFn,
              })
            );

            return retTable;
          } else if (
            labelSelectFn.nodeType === 'output' &&
            labelSelectFn.fromOp.name === 'run-name'
          ) {
            return TableState.updateColumnSelect(
              table,
              dims.color,
              opPick({
                obj: varNode(frame.runColors.type, 'runColors'),
                key: opRunId({
                  run: labelSelectFn.fromOp.inputs.run,
                }),
              })
            );
          }
        }

        if (
          isAssignableTo(
            labelType,
            oneOrMany(maybe(union(['number', 'string', 'boolean'])))
          )
        ) {
          return TableState.updateColumnSelect(
            table,
            dims.color,
            labelSelectFn
          );
        }
      }
      return table;
    });
  }, [tables, allDims, frame.runColors]);
};

const PanelPlot2Inner: React.FC<
  PanelPlotProps & {config: NonNullable<PanelPlotProps['config']>}
> = props => {
  const weave = useWeaveContext();
  const {input, updateConfig} = props;

  useEffect(() => {
    recordEvent('VIEW');
  }, []);

  // TODO(np): Hack to detect when we are on an activity dashboard
  const isOrgDashboard =
    Object.keys(useContext(ActivityDashboardContext).frame).length > 0;

  const isRepoInsightsDashboard =
    Object.keys(useContext(RepoInsightsDashboardContext).frame).length > 0;

  const isDashboard = isOrgDashboard || isRepoInsightsDashboard;

  const inputNode = input;
  const {frame} = usePanelContext();
  const {config} = useConfig(inputNode, props.config);
  const vegaReadyTables = useVegaReadyTables(config.series, frame);
  const vegaCols = useMemo(
    () =>
      vegaReadyTables.map((vegaReadyTable, i) =>
        PlotState.dimNames(vegaReadyTable, config.series[i].dims, weave)
      ),
    [config.series, vegaReadyTables, weave]
  );

  const listOfTableNodes = useMemo(() => {
    return vegaReadyTables.map(
      (val, i) =>
        TableState.tableGetResultTableNode(val, inputNode, frame, weave)
          .resultNode
    );
  }, [vegaReadyTables, inputNode, frame, weave]);

  const flatResultNode = useMemo(() => {
    const arrayArg: {
      [key: number]: ReturnType<
        typeof TableState.tableGetResultTableNode
      >['resultNode'];
    } = {};
    const reduced = vegaReadyTables.reduce((acc, val, i) => {
      acc[i] = opUnnest({
        arr: TableState.tableGetResultTableNode(val, inputNode, frame, weave)
          .resultNode as any,
      });
      return acc;
    }, arrayArg);
    return opArray(reduced as any);
  }, [vegaReadyTables, inputNode, frame, weave]);

  const result = LLReact.useNodeValue(flatResultNode);
  const plotTables = useMemo(
    () => (result.loading ? [] : (result.result as any[][])),
    [result]
  );
  const flatPlotTables = useMemo(() => {
    return plotTables.map(table => {
      return table.map(row => {
        return _.mapKeys(row, (v, k) => PlotState.fixKeyForVega(k));
      });
    });
  }, [plotTables]);

  // insert _seriesIndex on realized tables.
  // TODO(DG): figure out how to memoize this
  // NOTE: it is very important that this not be memoized
  // otherwise, tooltips will not disappear on mouseout
  flatPlotTables.forEach((table, i) => {
    table.forEach(row => {
      row._seriesIndex = i;
      row._seriesName =
        config.series[i].name ??
        PlotState.defaultSeriesName(config.series[i], weave);
    });
  });

  // If the color field can actually be interpreted as a color
  // then consider it a range, else we should use the default
  // color scheme from vega.
  const colorFieldIsRangeForSeries = useMemo(() => {
    return flatPlotTables.map((table, i) => {
      let isRange = false;
      for (const row of table) {
        if (row[vegaCols[i].color] != null) {
          isRange = stringIsColorLike(String(row[vegaCols[i].color]));
          break;
        }
      }
      return isRange;
    });
  }, [flatPlotTables, vegaCols]);

  // If the color field is not a range but contains data values, enumerate
  // those values for later use in a custom tooltip.
  const colorFieldValuesForSeries = useMemo(() => {
    return flatPlotTables.map((table, i) => {
      const colorKey = vegaCols[i].color;
      const colorValues = new Set<string>();
      for (const row of table) {
        if (row[colorKey] != null) {
          colorValues.add(String(row[colorKey]));
        }
      }
      return Array.from(colorValues);
    });
  }, [flatPlotTables, vegaCols]);

  const labelFieldValuesForSeries = useMemo(() => {
    return flatPlotTables.map((table, i) => {
      const labelKey = vegaCols[i].label;
      const labelValues = new Set<string>();
      for (const row of table) {
        if (row[labelKey] != null) {
          labelValues.add(String(row[labelKey]));
        }
      }
      return Array.from(labelValues);
    });
  }, [flatPlotTables, vegaCols]);

  const layerSpecs = useMemo(() => {
    return flatPlotTables.map((flatPlotTable, i) => {
      const vegaReadyTable = vegaReadyTables[i];
      const series = config.series[i];
      // filter out weave1 _type key
      const dims = _.omitBy(series.dims, (v, k) =>
        k.startsWith('_')
      ) as SeriesConfig['dims'];
      const dimTypes = _.mapValues(dims, colId =>
        TableState.getTableColType(vegaReadyTable, colId)
      );

      const colorFieldIsRange = colorFieldIsRangeForSeries[i];
      const colorFieldValues = colorFieldValuesForSeries[i];
      const labelFieldValues = labelFieldValuesForSeries[i];

      let mark: MarkOption = 'point';
      let xAxisType: string | undefined;
      let xTimeUnit: string | undefined;
      let yAxisType: string | undefined;
      let yTimeUnit: string | undefined;
      let colorAxisType: string | undefined;
      const objType = listObjectType(listOfTableNodes[i].type);

      if (objType != null && objType !== 'invalid') {
        if (!isTypedDict(objType)) {
          throw new Error('Invalid plot data type');
        }
        if (
          isAssignableTo(dimTypes.x, maybe('number')) &&
          isAssignableTo(dimTypes.y, maybe('number'))
        ) {
          mark = 'point';
        } else if (
          isAssignableTo(dimTypes.x, union(['string', 'date', numberBin])) &&
          isAssignableTo(dimTypes.y, maybe('number'))
        ) {
          mark = 'bar';
        } else if (
          isAssignableTo(dimTypes.x, maybe('number')) &&
          isAssignableTo(dimTypes.y, union(['string', 'date']))
        ) {
          mark = 'bar';
        } else if (
          isAssignableTo(dimTypes.x, list(maybe('number'))) &&
          isAssignableTo(dimTypes.y, union(['string', 'number']))
        ) {
          mark = 'boxplot';
        } else if (
          isAssignableTo(dimTypes.y, list(maybe('number'))) &&
          isAssignableTo(dimTypes.x, union(['string', 'number']))
        ) {
          mark = 'boxplot';
        } else if (
          isAssignableTo(dimTypes.x, list('number')) &&
          isAssignableTo(dimTypes.y, list('number'))
        ) {
          mark = 'line';
        }
        if (
          isAssignableTo(dimTypes.x, oneOrMany(maybe('number'))) ||
          isAssignableTo(dimTypes.x, numberBin)
        ) {
          xAxisType = 'quantitative';
        } else if (
          isAssignableTo(dimTypes.x, oneOrMany(maybe('string'))) ||
          isAssignableTo(dimTypes.x, oneOrMany(maybe('boolean')))
        ) {
          xAxisType = 'nominal';
        } else if (isAssignableTo(dimTypes.x, oneOrMany(maybe('date')))) {
          xAxisType = 'temporal';
          // TODO: hard-coded to month, we should encode this in the
          // type system and make it automatic (we know we used opDateRoundMonth)
          xTimeUnit = isDashboard ? 'yearweek' : 'yearmonth';
        } else if (
          isAssignableTo(
            dimTypes.x,
            oneOrMany(maybe({type: 'timestamp', unit: 'ms'}))
          )
        ) {
          xAxisType = 'temporal';
        }
        if (
          isAssignableTo(dimTypes.y, oneOrMany(maybe('number'))) ||
          isAssignableTo(dimTypes.y, numberBin)
        ) {
          yAxisType = 'quantitative';
        } else if (
          isAssignableTo(dimTypes.y, oneOrMany(maybe('string'))) ||
          isAssignableTo(dimTypes.y, oneOrMany(maybe('boolean')))
        ) {
          yAxisType = 'nominal';
        } else if (isAssignableTo(dimTypes.y, oneOrMany(maybe('date')))) {
          yAxisType = 'temporal';
          // TODO: hard-coded to month, we should encode this in the
          // type system and make it automatic (we know we used opDateRoundMonth)
          yTimeUnit = isDashboard ? 'yearweek' : 'yearmonth';
        } else if (
          isAssignableTo(
            dimTypes.y,
            oneOrMany(maybe({type: 'timestamp', unit: 'ms'}))
          )
        ) {
          yAxisType = 'temporal';
        }
        if (dimTypes.color != null) {
          if (isAssignableTo(dimTypes.color, oneOrMany(maybe('number')))) {
            colorAxisType = 'quantitative';
          } else if (
            isAssignableTo(
              dimTypes.color,
              oneOrMany(maybe(union(['string', 'boolean'])))
            )
          ) {
            colorAxisType = 'nominal';
          }
        }
      }

      const markType = series.constants.mark ?? mark;
      let newSpec = _.merge(
        _.cloneDeep(PLOT_TEMPLATE),
        isOrgDashboard ? _.cloneDeep(ORG_DASHBOARD_TEMPLATE_OVERLAY) : {},
        config?.vegaOverlay ?? {}
      );

      // create the data spec for the layer
      newSpec.data = {name: `wandb-${i}`};
      newSpec.name = `Layer${i + 1}`;
      newSpec.params[0].name = `grid-${i}`;
      newSpec.transform = [];

      const fixKeyForVega = (key: string) => {
        return PlotState.fixKeyForVega(
          TableState.getTableColumnName(
            vegaReadyTable.columnNames,
            vegaReadyTable.columnSelectFunctions,
            key,
            weave.client.opStore
          )
        );
      };

      if (xAxisType != null) {
        const fixedXKey = fixKeyForVega(dims.x);
        if (isAssignableTo(dimTypes.x, numberBin)) {
          if (markType === 'bar') {
            newSpec.encoding.x = {
              field: fixedXKey + '.start',
              type: xAxisType,
            };
            newSpec.encoding.x2 = {
              field: fixedXKey + '.stop',
              type: xAxisType,
            };
          } else {
            newSpec.transform.push({
              calculate: `0.5 * (datum['${fixedXKey}'].stop + datum['${fixedXKey}'].start)`,
              as: `${fixedXKey}_center`,
            });
            newSpec.encoding.x = {
              field: `${fixedXKey}_center`,
              type: xAxisType,
            };
          }
        } else {
          newSpec.encoding.x = {
            field: fixedXKey,
            type: xAxisType,
          };
        }
        if (xAxisType === 'temporal' && xTimeUnit) {
          newSpec.encoding.x.timeUnit = xTimeUnit;
        }
      }

      newSpec.mark.type = series.constants.mark ?? mark;

      if (yAxisType != null) {
        const fixedYKey = fixKeyForVega(dims.y);
        if (
          isAssignableTo(dimTypes.y, numberBin) &&
          newSpec.mark.type !== 'area'
        ) {
          newSpec.encoding.y = {
            field: fixedYKey + '.start',
            type: yAxisType,
          };
          newSpec.encoding.y2 = {
            field: fixedYKey + '.stop',
            type: yAxisType,
          };
        } else if (
          newSpec.mark.type === 'area' &&
          isAssignableTo(dimTypes.y2, dimTypes.y)
        ) {
          const fixedY2Key = fixKeyForVega(dims.y2);
          newSpec.encoding.y = {
            field: fixedYKey,
            type: yAxisType,
          };
          newSpec.encoding.y2 = {
            field: fixedY2Key,
            type: yAxisType,
          };
          newSpec.mark.opacity = 0.2;
        } else {
          newSpec.encoding.y = {
            field: fixedYKey,
            type: yAxisType,
          };
          if (yAxisType === 'temporal' && yTimeUnit) {
            newSpec.encoding.y.timeUnit = yTimeUnit;
          }
        }
      }

      if (colorAxisType != null && series.uiState.label === 'expression') {
        newSpec.encoding.color = {
          field: fixKeyForVega(dims.color),
          type: colorAxisType,
        };
        if (
          vegaReadyTable.columnSelectFunctions[dims.label].type !== 'invalid'
        ) {
          newSpec.encoding.color.field = fixKeyForVega(dims.label);
          if (colorFieldIsRange) {
            newSpec.encoding.color.scale = {
              range: {field: fixKeyForVega(dims.color)},
            };
          }
        }
      } else if (series.uiState.label === 'dropdown') {
        newSpec.encoding.color = {
          field: '_seriesName',
          title: 'series',
          legend: {...defaultFontStyleDict},
        };
      }

      if (newSpec.mark.type === 'point') {
        newSpec.mark.filled = true;

        if (dims.pointSize && dimTypes.pointSize) {
          const pointSizeKey = fixKeyForVega(dims.pointSize);
          let pointSizeIsConst = false;
          const pointSizesSeen = new Set<number>();
          if (isAssignableTo(dimTypes.pointSize, 'number')) {
            for (const row of flatPlotTable) {
              pointSizesSeen.add(row[pointSizeKey]);
            }
            if (pointSizesSeen.size === 1) {
              pointSizeIsConst = true;
            }
          }

          // if the expression provided for the point size evaluates to a
          // column where every cell is the same number, then interpret the
          // point not as an encoding but as a direct specification of the
          // point size in absolute units. otherwise, encode the values
          // using the default vega-lite scale.
          if (pointSizeIsConst) {
            newSpec.mark.size = pointSizesSeen.values().next().value;
          } else {
            newSpec.encoding.size = {
              field: fixKeyForVega(dims.pointSize),
              legend: {...defaultFontStyleDict},
            };

            if (isAssignableTo(dimTypes.pointSize, maybe('number'))) {
              newSpec.encoding.size.type = 'quantitative';
            }
          }
        }

        if (
          dims.pointShape &&
          dimTypes.pointShape &&
          series.uiState.pointShape === 'expression'
        ) {
          const pointShapeKey = fixKeyForVega(dims.pointShape);
          const pointShapesShouldBeEncoded = !(
            isAssignableTo(dimTypes.pointShape, 'string') &&
            flatPlotTable.every(row =>
              POINT_SHAPES.includes(row[pointShapeKey])
            )
          );

          newSpec.encoding.shape = {
            legend: {...defaultFontStyleDict},
            field: fixKeyForVega(dims.pointShape),
          };

          if (!pointShapesShouldBeEncoded) {
            newSpec.encoding.shape.scale = null;
          }
        }

        if (series.uiState.pointShape === 'dropdown') {
          if (series.constants.pointShape !== 'series') {
            newSpec.mark.shape = series.constants.pointShape;
          } else {
            newSpec.encoding.shape = {
              legend: {...defaultFontStyleDict},
              field: '_seriesName',
              title: 'series',
            };
          }
        }
      }

      if (newSpec.mark.type === 'line') {
        const lineStyle = {
          mark: {
            type: 'line',
            strokeDash:
              series.constants.lineStyle !== 'series'
                ? series.constants.lineStyle === 'solid'
                  ? [1, 0]
                  : series.constants.lineStyle === 'dashed'
                  ? [8, 8]
                  : series.constants.lineStyle === 'dotted'
                  ? [2, 1]
                  : series.constants.lineStyle === 'dot-dashed'
                  ? [8, 4, 4, 4]
                  : [1, 0]
                : undefined,
          },
          encoding: {
            strokeDash:
              series.constants.lineStyle === 'series'
                ? {
                    field: '_seriesName',
                    legend: {...defaultFontStyleDict},
                    title: 'series',
                  }
                : undefined,
          },
        };

        newSpec.mark = {...newSpec.mark, ...lineStyle.mark};
        if (newSpec.encoding.color != null && !newSpec.encoding.tooltip) {
          const tooltipValues: Array<{field: string; type: string}> = [];
          if (isRepoInsightsDashboard) {
            for (const colorFieldValue of colorFieldValues) {
              tooltipValues.push({
                field: colorFieldValue,
                type: 'quantitative',
              });
            }
          } else {
            for (const labelFieldValue of labelFieldValues) {
              tooltipValues.push({
                field: labelFieldValue,
                type: 'quantitative',
              });
            }
          }

          const xToolTipValue: {
            field: string;
            type: string;
            format?: string;
          } = {
            field: newSpec.encoding.x.field,
            type: newSpec.encoding.x.type,
          };

          if (
            newSpec.encoding.x.type === 'temporal' &&
            isAssignableTo(
              dimTypes.x,
              oneOrMany(maybe({type: 'timestamp', unit: 'ms'}))
            )
          ) {
            xToolTipValue.format = '%B %d, %Y %X';
          }
          tooltipValues.push(xToolTipValue);

          const mergeSpec = {
            layer: [
              {
                encoding: {
                  color: newSpec.encoding.color,
                  y: newSpec.encoding.y,
                },
                layer: [
                  lineStyle,
                  {
                    transform: [{filter: {param: `hover-${i}`, empty: false}}],
                    mark: 'point',
                    params: [
                      {
                        name: `grid-${i}`,
                        select: 'interval',
                        bind: 'scales',
                      },
                    ],
                  },
                ],
              },
              {
                transform: [
                  {
                    pivot: newSpec.encoding.color.field,
                    value: newSpec.encoding.y.field,
                    groupby: [newSpec.encoding.x.field],
                  },
                ],
                mark: 'rule',
                encoding: {
                  opacity: {
                    condition: {value: 0.3, param: `hover-${i}`, empty: false},
                    value: 0,
                  },
                  tooltip: tooltipValues,
                },
                params: [
                  {
                    name: `hover-${i}`,
                    select: {
                      type: 'point',
                      fields: [newSpec.encoding.x.field],
                      nearest: true,
                      on: 'mouseover',
                      clear: 'mouseout',
                    },
                  },
                ],
              },
            ],
          };

          delete newSpec.encoding.color;
          delete newSpec.encoding.y;

          newSpec = _.merge(newSpec, mergeSpec);
        } else {
          newSpec.encoding.strokeDash = lineStyle.encoding.strokeDash;
        }
      }

      if (newSpec.mark.type === 'boxplot' && !newSpec.encoding.tooltip) {
        newSpec.mark = 'boxplot';
        newSpec.encoding.tooltip = {
          field: TableState.getTableColumnName(
            vegaReadyTable.columnNames,
            vegaReadyTable.columnSelectFunctions,
            dims.tooltip ?? dims.y,
            weave.client.opStore
          ),
          // type is autodetected by vega
        };
      }

      return newSpec;
    });
  }, [
    flatPlotTables,
    vegaReadyTables,
    colorFieldIsRangeForSeries,
    colorFieldValuesForSeries,
    labelFieldValuesForSeries,
    listOfTableNodes,
    isOrgDashboard,
    config,
    isDashboard,
    weave.client.opStore,
    isRepoInsightsDashboard,
  ]);

  const dataToPassToCustomPanelRenderer: MultiTableDataType = useMemo(() => {
    const data: MultiTableDataType = {};
    flatPlotTables.forEach((table, i) => {
      data[`wandb-${i}`] =
        layerSpecs[i].mark?.type === 'line'
          ? table.filter(
              row => row[vegaCols[i].x] != null && row[vegaCols[i].y] != null
            )
          : table;
    });
    return data;
  }, [flatPlotTables, vegaCols, layerSpecs]);

  const vegaSpec = useMemo(() => {
    const newSpec: any = {layer: layerSpecs};
    const {axisSettings, legendSettings} = config;
    newSpec.encoding = {x: {axis: {}}, y: {axis: {}}, color: {axis: {}}};
    if (layerSpecs.some(spec => spec.encoding.x != null)) {
      ['x' as const, 'y' as const, 'color' as const].forEach(axisName => {
        newSpec.encoding[axisName] = {};
        newSpec.encoding[axisName].axis = {};
      });

      // TODO(np): fixme (Applied on org dash only)
      newSpec.encoding.x.axis = isDashboard
        ? {
            format: '%m/%d/%y',
            grid: false,
            ...defaultFontStyleDict,
          }
        : {...defaultFontStyleDict};
    }
    // TODO(np): fixme
    if (axisSettings.x.scale != null) {
      newSpec.encoding.x.scale = axisSettings.x.scale;
    }
    if (axisSettings.x.noTitle) {
      newSpec.encoding.x.axis.title = null;
    }
    if (axisSettings.x.title != null) {
      newSpec.encoding.x.axis.title = axisSettings.x.title;
    }
    if (axisSettings.x.noLabels) {
      newSpec.encoding.x.axis.labels = false;
    }
    if (axisSettings.x.noTicks) {
      newSpec.encoding.x.axis.ticks = false;
    }
    if (
      axisSettings.x.noTitle &&
      axisSettings.x.noLabels &&
      axisSettings.x.noTicks
    ) {
      newSpec.encoding.x.axis = false;
    }
    if (newSpec.encoding.y != null) {
      newSpec.encoding.y.axis = {...defaultFontStyleDict};
      // TODO(np): fixme
      if (axisSettings.y.scale != null) {
        newSpec.encoding.y.scale = axisSettings.y.scale;
      }
      if (axisSettings.y.noTitle) {
        newSpec.encoding.y.axis.title = null;
      }
      if (axisSettings.y.title != null) {
        newSpec.encoding.y.axis.title = axisSettings.y.title;
      }
      if (axisSettings.y.noLabels) {
        newSpec.encoding.y.axis.labels = false;
      }
      if (axisSettings.y.noTicks) {
        newSpec.encoding.y.axis.ticks = false;
      }
      if (
        axisSettings.y.noTitle &&
        axisSettings.y.noLabels &&
        axisSettings.y.noTicks
      ) {
        newSpec.encoding.y.axis = false;
      }

      if (
        axisSettings.y.noTitle &&
        axisSettings.y.noLabels &&
        axisSettings.y.noTicks
      ) {
        newSpec.encoding.y.axis = false;
      }
    }

    if (newSpec.encoding.color != null) {
      if (axisSettings?.color?.scale) {
        newSpec.encoding.color.scale = axisSettings.color.scale;
      }

      if (axisSettings.color && axisSettings.color.title != null) {
        newSpec.encoding.color.title = axisSettings.color.title;
      }

      if (legendSettings.color.noLegend) {
        newSpec.encoding.color.legend = false;
      } else if (!newSpec.encoding.color.legend) {
        newSpec.encoding.color.legend = {...defaultFontStyleDict};
      }
    }
    return newSpec;
  }, [config, isDashboard, layerSpecs]);

  // get the series object from the config by its index
  const getSeriesBySeriesIndex = useCallback(
    (index: number | undefined) => {
      if (index !== undefined) {
        return config.series[index];
      }
      return undefined;
    },
    [config.series]
  );

  const toolTipRef = useRef<HTMLDivElement>(null);

  const [toolTipPos, setTooltipPos] = useState<{
    x: number | undefined;
    y: number | undefined;
    value: any;
  }>({x: undefined, y: undefined, value: undefined});

  const currentlySelectedSeries = useMemo(() => {
    return getSeriesBySeriesIndex(toolTipPos.value?._seriesIndex);
  }, [getSeriesBySeriesIndex, toolTipPos.value]);

  const seriesIndex = useMemo(() => {
    if (currentlySelectedSeries) {
      return config.series.indexOf(currentlySelectedSeries);
    }
    return undefined;
  }, [config.series, currentlySelectedSeries]);

  // create a default tooltip handler
  const handler = useMemo(() => new Handler(), []);

  const handleTooltip = useCallback(
    (toolTipHandler: any, event: any, item: any, value: any) => {
      if (!toolTipRef.current || value?._seriesIndex === undefined) {
        // clear the weave custom tooltip
        setTooltipPos({x: undefined, y: undefined, value: undefined});

        // set the default tooltip
        return handler.call(toolTipHandler, event, item, value);
      } else {
        const {x, y} = calculatePosition(
          event,
          toolTipRef.current?.getBoundingClientRect()!,
          10,
          10
        );
        // clear the default tooltip
        handler.call(toolTipHandler, event, item, null);

        // set the weave custom tooltip
        setTooltipPos({x, y, value});
      }
    },
    [handler]
  );

  const tooltipNodes = useMemo(() => {
    return config.series.map((s, index) => {
      const valueResultIndex = toolTipPos.value?._index;
      if (valueResultIndex == null) {
        return voidNode();
      }
      const row = opIndex({
        arr: listOfTableNodes[index],
        index: constNumber(valueResultIndex),
      });
      const toolTipFn =
        vegaReadyTables[index].columnSelectFunctions[s.dims.tooltip];
      if (toolTipFn.nodeType === 'void' || toolTipFn.type === 'invalid') {
        return row;
      }
      return opPick({
        obj: row,
        key: constString(
          escapeDots(
            TableState.getTableColumnName(
              s.table.columnNames,
              s.table.columnSelectFunctions,
              s.dims.tooltip,
              weave.client.opStore
            )
          )
        ),
      });
    });
  }, [
    config.series,
    toolTipPos.value,
    listOfTableNodes,
    vegaReadyTables,
    weave.client.opStore,
  ]);

  const handlers = useMemo(
    () =>
      tooltipNodes.map(
        node =>
          getPanelStacksForType(node.type, undefined, {
            excludeTable: true,
            excludePlot: true,
          }).handler
      ),
    [tooltipNodes]
  );

  const updateTooltipConfigs = useMemo(() => {
    return vegaReadyTables.map((table, index) => {
      return (newPanelConfig: any) => {
        const newSeries = produce(config.series, draft => {
          draft[index].table = TableState.updateColumnPanelConfig(
            table,
            draft[index].dims.tooltip,
            newPanelConfig
          );
        });
        return updateConfig({series: newSeries});
      };
    });
  }, [vegaReadyTables, config.series, updateConfig]);

  const isRepoInsightsDash =
    Object.keys(useContext(RepoInsightsDashboardContext).frame).length > 0;
  const loaderComp = isRepoInsightsDash ? (
    <SemanticLoader active inline className="cgLoader" />
  ) : (
    <Panel2Loader />
  );

  const contents = useGatedValue(
    <>
      {result.loading ? (
        <div style={{width: '100%', height: '100%'}}>{loaderComp}</div>
      ) : (
        <div data-test-weave-id="plot" style={{width: '100%', height: '100%'}}>
          {handlers.map((h, i) => {
            return (
              <TooltipPortal key={i}>
                <div
                  ref={toolTipRef}
                  style={{
                    position: 'fixed',
                    visibility:
                      toolTipPos.x == null || seriesIndex !== i
                        ? 'hidden'
                        : 'visible',
                    borderRadius: 2,
                    padding: 4,
                    top: toolTipPos.y,
                    left: toolTipPos.x,
                    // 2147483605 is the default z-index for panel models to get over
                    // intercom. Boy that is nasty - should make better
                    zIndex: 2147483605 + 9000,
                    background: '#fff',
                    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)',
                    ...getPanelStackDims(h, tooltipNodes[i].type, config),
                  }}>
                  {tooltipNodes[i].nodeType !== 'void' &&
                    h &&
                    seriesIndex === i && (
                      <PanelComp2
                        input={tooltipNodes[i]}
                        inputType={tooltipNodes[i].type}
                        loading={false}
                        panelSpec={h}
                        configMode={false}
                        context={props.context}
                        config={
                          config.series[i].table.columns[
                            config.series[i].dims.tooltip
                          ].panelConfig
                        }
                        updateConfig={updateTooltipConfigs[i]}
                        updateContext={props.updateContext}
                      />
                    )}
                </div>
              </TooltipPortal>
            );
          })}
          <CustomPanelRenderer
            spec={vegaSpec}
            loading={false}
            slow={false}
            data={dataToPassToCustomPanelRenderer}
            userSettings={{
              // TODO: I'm putting ! in here cause our fieldSettings
              // doesn't allow undefined. Fix that to allow it.
              fieldSettings: {title: config.title!},
              stringSettings: {
                title: '',
              },
            }}
            handleTooltip={handleTooltip}
          />
        </div>
      )}
      {/* Plot config
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {`${plotType}, ${axisTypes.x}, ${axisTypes.y}, ${axisTypes.color}`}
      </pre>
      Vega spec
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {JSON.stringify(vegaSpec, undefined, 2)}
      </pre> */}
      {/* Compute graph query
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {toString(resultNode)}
      </pre> */}
      {/* <pre style={{fontSize: 12}}>
        {JSON.stringify(plotTable, undefined, 2)}
      </pre>  */}
      {/* Query result
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {JSON.stringify(flatPlotTable, undefined, 2)}
      </pre> */}
      {/* Input row type
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {toString(exampleInputFrame.x.type)}
      </pre>
      Grouped row type
      <pre style={{marginLeft: 16, fontSize: 12}}>
        {toString(exampleRowFrame.x.type)}
      </pre>{' '} */}
    </>,
    x => !result.loading
  );

  return (
    <div
      data-test="panel-plot-2-wrapper"
      style={{height: '100%', width: '100%'}}
      className={result.loading ? 'loading' : ''}>
      {flatPlotTables.every(t => t.length === 0) && !result.loading ? (
        <PanelError message="No data" />
      ) : (
        contents
      )}
    </div>
  );
};

const TooltipPortal: React.FC<{}> = props => {
  const toolTipRef = useRef(document.createElement('div'));
  useEffect(() => {
    const el = toolTipRef.current;
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);
  return ReactDOM.createPortal(props.children, toolTipRef.current);
};

/* eslint-disable no-template-curly-in-string */

export const Spec: Panel2.PanelSpec = {
  id: 'plot',
  ConfigComponent: PanelPlotConfig,
  Component: PanelPlot2,
  inputType,
  defaultFixedSize: {
    width: 200,
    height: (9 / 16) * 200,
  },
  isValid: (config: PlotConfig): boolean => {
    return isValidConfig(config).valid;
  },
};

const PLOT_TEMPLATE: VisualizationSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
  data: {
    name: 'wandb',
  },
  padding: 1,
  title: '${field:title}',
  mark: {
    tooltip: {
      content: 'data',
    },
  } as any,
  params: [
    {
      name: 'grid',
      select: 'interval',
      bind: 'scales',
    },
  ],
  encoding: {
    // opacity: {
    //   value: 0.6,
    // },
  },
};

const ORG_DASHBOARD_TEMPLATE_OVERLAY = {
  config: {
    legend: {
      disable: true,
    },
    axis: {
      // labels: false,
      title: null,
    },
    style: {
      cell: {
        stroke: 'transparent',
      },
    },
  },
};
