import KaTeX from 'katex';
import {get} from 'lodash';
import React from 'react';
import {Editor, Element, Node, Range, Transforms} from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useSelected,
  useSlate,
} from 'slate-react';

import {useTyping} from '../TypingContext';
import {BlockWrapper} from './drag-drop';
import * as S from './latex.styles';

export interface Latex extends Element {
  type: 'latex';
  content: string;
  block?: boolean;
  autoFocus?: boolean;
}

export const isLatex = (node: Node): node is Latex => node.type === 'latex';

export const LatexElement: React.FC<
  RenderElementProps & {
    element: Latex;
  }
> = ({attributes, element, children}) => {
  const selected = useSelected();

  let wrap: (inner: React.ReactNode) => JSX.Element;

  if (element.block) {
    wrap = inner => (
      <BlockWrapper attributes={attributes} element={element}>
        <S.BlockLatexWrapper>{inner}</S.BlockLatexWrapper>
      </BlockWrapper>
    );
  } else {
    wrap = inner => <span {...attributes}>{inner}</span>;
  }

  if (element.content === '') {
    return wrap(
      <>
        <S.InlineLatexPlaceholder
          selected={selected}
          contentEditable={false}
          dangerouslySetInnerHTML={{__html: KaTeX.renderToString('\\LaTeX')}}
        />
        {children}
      </>
    );
  }

  let html: string | undefined;
  let error: string | undefined;
  try {
    html = KaTeX.renderToString(element.content);
  } catch (e) {
    error = get(e, 'message');
  }

  if (error != null) {
    return wrap(
      <>
        <S.InlineLatexError selected={selected} contentEditable={false}>
          {error}
        </S.InlineLatexError>
        {children}
      </>
    );
  }

  return wrap(
    <>
      <S.InlineLatex
        selected={selected}
        contentEditable={false}
        dangerouslySetInnerHTML={
          html
            ? {
                __html: html,
              }
            : undefined
        }
      />
      {children}
    </>
  );
};

export const withLatex = <T extends Editor>(editor: T) => {
  const {isVoid, isInline, insertText} = editor;

  editor.isVoid = element => {
    return isLatex(element) ? true : isVoid(element);
  };

  editor.isInline = element => {
    return isLatex(element) ? !element.block : isInline(element);
  };

  editor.insertText = text => {
    const {selection} = editor;

    if (text === '$' && selection && Range.isCollapsed(selection)) {
      const start = Editor.before(editor, selection, {
        unit: 'offset',
        distance: 1,
      });
      if (start != null) {
        const str = Editor.string(editor, {
          anchor: start,
          focus: selection.focus,
        });
        if (str === '$') {
          Editor.deleteBackward(editor);
          Editor.insertNode(editor, {
            type: 'latex',
            content: '',
            autoFocus: true,
            children: [{text: ''}],
          });
          return;
        }
      }
    }

    insertText(text);
  };

  return editor;
};

interface EditLatexWidgetProps {
  node: Latex;
  open: boolean;
  onClose(): void;
}

export const EditLatexWidget: React.FC<EditLatexWidgetProps> = ({
  onClose,
  node,
  open,
}) => {
  const editor = useSlate();
  const {selection} = editor;
  const [focused, setFocused] = React.useState(false);
  const [value, setValue] = React.useState(node.content);
  const {typing} = useTyping();

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    setValue(node.content);
  }, [node]);

  React.useEffect(() => {
    if (focused) {
      return;
    }

    if (
      selection == null ||
      !Range.isCollapsed(selection) ||
      Editor.above(editor, {match: n => isLatex(n)}) == null
    ) {
      onClose();
    }
  }, [editor, focused, onClose, selection]);

  const autoFocus = node.autoFocus;
  React.useEffect(() => {
    if (open && !typing) {
      textareaRef.current?.focus();
    }

    if (open && autoFocus) {
      // Not sure why timeout is needed
      window.setTimeout(() => {
        textareaRef.current?.focus();
        Transforms.unsetNodes(editor, ['autoFocus'], {
          at: ReactEditor.findPath(editor, node),
        });
      });
    }
  }, [open, autoFocus, typing, editor, node]);

  return (
    <S.EditLatexWrapper>
      <S.LatexInput
        placeholder="e=mc^2"
        ref={tag => (textareaRef.current = tag)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={value}
        autoFocus={!typing}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            onClose();
            e.preventDefault();
            const path = ReactEditor.findPath(editor, node);
            const rightAfterNode = Editor.after(editor, path, {unit: 'offset'});
            if (rightAfterNode != null) {
              ReactEditor.focus(editor);
              Transforms.select(editor, rightAfterNode);
            }
          }

          if (e.key === 'Escape') {
            onClose();
            const path = ReactEditor.findPath(editor, node);
            const rightAfterNode = Editor.after(editor, path, {unit: 'offset'});
            if (rightAfterNode != null) {
              ReactEditor.focus(editor);
              Transforms.select(editor, rightAfterNode);
            }
          }
        }}
        onChange={e => {
          setValue(e.target.value);
          Transforms.setNodes(
            editor,
            {content: e.target.value},
            {
              at: ReactEditor.findPath(editor, node),
            }
          );
        }}
      />
    </S.EditLatexWrapper>
  );
};

export const latexNodeToLatex = (node: Latex) => {
  if (node.block) {
    return `
\\[ ${node.content} \\]`;
  } else {
    return node.content;
  }
};
