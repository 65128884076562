/**
 * Select a Target Project.
 */
import React from 'react';
import Select from 'react-select';

import * as S from './SelectComponents.styles';

export type ProjectOption = {
  value: string;
  label: string;
};

type SelectProjectProps = {
  options: ProjectOption[];
  onSelectProject: (option: ProjectOption) => void;
};

const OptionLabel = ({label}: ProjectOption) => (
  <S.SelectOption>
    <S.SelectOptionLabel>{label}</S.SelectOptionLabel>
  </S.SelectOption>
);

const SelectProject: React.FC<SelectProjectProps> = ({
  options,
  onSelectProject,
}) => {
  const onChange = (option: ProjectOption | null) => {
    if (option != null) {
      onSelectProject(option);
    }
  };
  return (
    <Select
      options={options}
      formatOptionLabel={OptionLabel}
      placeholder="Select a Target Project..."
      onChange={onChange}
    />
  );
};

export default SelectProject;
