import {useEffect, useState} from 'react';

import {PanelBankSettings} from './../../util/panelbank';

export const useEditableWorkspaceSettings = (
  savedSettings: PanelBankSettings
) => {
  const [editedState, setEditedState] = useState<PanelBankSettings>({
    autoOrganizePrefix: savedSettings.autoOrganizePrefix,
    showEmptySections: savedSettings.showEmptySections,
    sortAlphabetically: savedSettings.sortAlphabetically,
    toggleRunAggregation: savedSettings.toggleRunAggregation ?? true,
  });

  /**
   * Whenever new saved state gets set we need to update the values of the modal to whatever is currently in place. Then we can mutate on top of that until `Apply` is clicked
   */
  useEffect(() => {
    setEditedState({
      autoOrganizePrefix: savedSettings.autoOrganizePrefix,
      showEmptySections: savedSettings.showEmptySections,
      sortAlphabetically: savedSettings.sortAlphabetically,
      toggleRunAggregation: savedSettings.toggleRunAggregation ?? true,
    });
  }, [savedSettings]);

  return [editedState, setEditedState] as const;
};
