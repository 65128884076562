/**
 * These exports used to be in markdown-blocks.ts, but they were imported
 * in util/report/index.ts, a util module used from other components,
 * ultimately causing an import cycle under certain import conditions in
 * tests.
 */
import {Element, Node} from 'slate';

export interface MarkdownBlock extends Element {
  type: 'markdown-block';
  content: string;
  autoFocus?: boolean;
  collapsedChildren?: Node[];
}

export function getCollapsibleMarkdownHeading(text: string): string | null {
  const firstLine = text.split('\n')[0];
  if (/^\s*# .+$/.test(firstLine)) {
    return firstLine;
  }
  return null;
}

export const isMarkdownBlock = (node: Node): node is MarkdownBlock =>
  node.type === 'markdown-block';
