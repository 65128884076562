import docUrl from '@wandb/common/util/doc_urls';
import {TargetBlank} from '@wandb/common/util/links';
import React, {memo} from 'react';
import {Modal} from 'semantic-ui-react';

import {Highlight, Python} from '../Code';

type RunsExportAPIProps = {
  entityName: string;
  projectName: string;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
};

const RunsExportAPIComp: React.FC<RunsExportAPIProps> = ({
  entityName,
  projectName,
  trigger,
  open,
  onClose,
}) => {
  return (
    <Modal trigger={trigger} open={open} onClose={onClose}>
      <Modal.Content>
        <h2>Python Export API</h2>
        <p>
          Use the{' '}
          <TargetBlank href={docUrl.pythonApi}>Python Export API</TargetBlank>{' '}
          to download a CSV of runs data.
        </p>
        <Python>
          <Highlight>{runsExportAPICode(entityName, projectName)}</Highlight>
        </Python>
        <p>
          <TargetBlank href={docUrl.pythonApi}>
            Learn more in our docs →
          </TargetBlank>
        </p>
      </Modal.Content>
    </Modal>
  );
};

function runsExportAPICode(entityName: string, projectName: string) {
  return `import pandas as pd 
import wandb
api = wandb.Api()

# Project is specified by <entity/project-name>
runs = api.runs("${entityName}/${projectName}")

summary_list, config_list, name_list = [], [], []
for run in runs: 
    # .summary contains the output keys/values for metrics like accuracy.
    #  We call ._json_dict to omit large files 
    summary_list.append(run.summary._json_dict)

    # .config contains the hyperparameters.
    #  We remove special values that start with _.
    config_list.append(
        {k: v for k,v in run.config.items()
          if not k.startswith('_')})

    # .name is the human-readable name of the run.
    name_list.append(run.name)

runs_df = pd.DataFrame({
    "summary": summary_list,
    "config": config_list,
    "name": name_list
    })

runs_df.to_csv("project.csv")`;
}

export const RunsExportAPI = memo(RunsExportAPIComp);
