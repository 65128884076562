import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {RunQueue} from '../../generated/graphql';
import {fragments, ViewMetadata} from '../../graphql/views';
import {BenchmarkProject, Graph} from '../../types/graphql';
import {AccessOptions} from '../../util/permissions';
import {useQuery, UseQueryOptions} from './query';

///// Query definition

// TODO: We don't need all the reports, we should replace fetching
// them with fetching reportCount (need to add the field on the backend)
export const PROJECT_PAGE_QUERY = gql`
  query ProjectPage($projectName: String!, $entityName: String) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      entityName
      lastActive
      access
      description
      readOnly
      totalRuns
      totalRunTime
      computeHours
      totalRunsWithGroup
      totalRunsWithJobType
      totalSweeps
      totalUsers
      runCount
      isBenchmark
      views
      runQueues {
        createdAt
        createdBy
        access
        updatedAt
        id
        name
        defaultResourceConfigID
        runQueueItems {
          edges {
            node {
              createdAt
              updatedAt
              id
              runSpec
              state
              associatedRunId
              launchAgentId
            }
          }
        }
      }
      user {
        id
        username
        photoUrl
      }
      linkedBenchmark {
        id
        entityName
        name
        gitHubSubmissionRepo
        views
      }
      reports: allViews(viewType: "runs") {
        edges {
          node {
            ...ViewFragmentMetadata
          }
        }
      }
      entity {
        id
        name
        defaultAccess
        readOnlyAdmin
        privateOnly
        isTeam
        claimingEntity {
          id
          name
        }
      }
    }
  }
  ${fragments.viewMetadata}
`;

export interface ProjectData {
  id: string;
  name: string;
  entityName: string;
  description: string;
  lastActive: string;
  totalRunsWithGroup: number;
  totalRunsWithJobType: number;
  readOnly: boolean;
  access: AccessOptions;
  totalRuns: number;
  totalRunTime: number;
  computeHours: number;
  runCount: number;
  totalSweeps: number;
  totalUsers: number;
  filteredCount: number;
  selectedCount: number;
  isBenchmark: boolean;
  linkedBenchmark: BenchmarkProject;
  views: string;
  runQueues: RunQueue[];
  user: {
    id: string;
    username: string;
    photoUrl: string;
  };
  reports: Graph<ViewMetadata>;
  entity: {
    id: string;
    name: string;
    defaultAccess: AccessOptions;
    readOnlyAdmin: boolean;
    privateOnly: boolean;
    isTeam: boolean;
    claimingEntity: {
      id: string;
      name: string;
    };
  };
}

export interface ProjectPageQueryData {
  project: ProjectData;
}

export function useProjectPageQuery(
  variables: Generated.ProjectPageQueryVariables,
  options: UseQueryOptions
) {
  return useQuery<ProjectPageQueryData, typeof variables>(PROJECT_PAGE_QUERY, {
    ...options,
    variables,
  });
}
