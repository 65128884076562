import Observable from 'zen-observable';

import {GlobalCGEventTracker} from '../analytics/tracker';
import {Cache, InMemoryCache} from '../cache';
import {Hasher, MemoizedHasher} from '../model/graph/editing/hash';
import {Node} from '../model/graph/types';
import {Type} from '../model/types';
import {OpStore} from '../opStore/types';
import {Client} from './types';

export class CachedClient implements Client {
  readonly opStore: OpStore;
  private readonly cache: Cache;
  private readonly hasher: Hasher;
  public constructor(private readonly client: Client) {
    this.hasher = new MemoizedHasher();
    this.cache = new InMemoryCache({
      maxElements: 1000,
      keyFn: this.hasher.typedNodeId,
    });
    this.opStore = client.opStore;
  }
  subscribe<T extends Type>(node: Node<T>): Observable<any> {
    GlobalCGEventTracker.cachedClientSubscriptions++;
    if (this.cache.has(node)) {
      GlobalCGEventTracker.cachedClientCacheHits++;
      return this.cache.get(node);
    }

    const observable = this.client.subscribe(node);
    this.cache.set(node, observable, 30);
    return observable;
  }

  async query<T extends Type>(node: Node<T>): Promise<any> {
    const obs = this.subscribe(node);
    return new Promise((resolve, reject) => {
      const sub = obs.subscribe(
        nodeRes => {
          resolve(nodeRes);
          sub.unsubscribe();
        },
        caughtError => {
          reject(caughtError);
          sub.unsubscribe();
        }
      );
    });
  }

  loadingObservable(): Observable<boolean> {
    return this.client.loadingObservable();
  }
  public refreshAll(): Promise<void> {
    return this.client.refreshAll();
  }
}
