import ImportedDatetime from 'react-datetime';

// There's a bug in the @rollup/plugin-commonjs that causes
// this import to resolve as { default: Datetime } in production
// builds. This file abastracts over the difference between dev
// and prod
const ex: typeof ImportedDatetime =
  (ImportedDatetime as any).default || ImportedDatetime;

export default ex;
