import {PUBLISHED_PROJECT_NAME} from '@wandb/common/util/constants';

import {
  GALLERY_PATH_SEGMENT,
  PUBLISHED_WORK_PATH_SEGMENT,
} from '../../routes/paths';
import {makeNameAndID} from '../url/shared';

export function createTeam(): string {
  return `/create-team`;
}

export type ReportParams = {
  entityName: string;
  projectName: string;
  reportID: string;
  reportName?: string;
};

export type GalleryPostParams = {
  entityName: string;
  reportID: string;
  reportName?: string;
  isDiscussion?: boolean;
};

export function reportEdit(r: ReportParams, queryString?: string) {
  const extra = queryString || '';
  return `${reportViewWithoutPublished(r)}/edit${extra}`;
}

export function reportView(r: ReportParams | GalleryPostParams) {
  if (isReportParams(r)) {
    return toPublishedReportURL(reportViewWithoutPublished(r));
  }
  if (r.isDiscussion) {
    return galleryDiscussionView(r);
  }
  return galleryPostView(r);
}

function isReportParams(
  r: ReportParams | GalleryPostParams
): r is ReportParams {
  return 'projectName' in r && !!r.projectName;
}

export function reportViewWithoutPublished(r: ReportParams) {
  return `/${r.entityName}/${r.projectName}/reports/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function toPublishedReportURL(url: string): string {
  const searchStr = `${PUBLISHED_PROJECT_NAME}/reports`;
  const i = url.indexOf(searchStr);

  if (i === -1) {
    return url;
  }

  return (
    url.slice(0, i) +
    PUBLISHED_WORK_PATH_SEGMENT +
    url.slice(i + searchStr.length)
  );
}

type PublishedReportParams = Omit<ReportParams, 'projectName'>;

export function publishedReportView(r: PublishedReportParams): string {
  return `/${r.entityName}/published-work/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function galleryPostEdit(
  r: GalleryPostParams,
  queryString?: string
): string {
  const extra = queryString || '';
  return `${galleryPostView(r)}/edit${extra}`;
}

export function galleryPostView(r: GalleryPostParams): string {
  return `/${r.entityName}/posts/${makeNameAndID(r.reportID, r.reportName)}`;
}

export function galleryDiscussionEdit(
  r: GalleryPostParams,
  queryString?: string
): string {
  const extra = queryString || '';
  return `${galleryDiscussionView(r)}/edit${extra}`;
}

export function galleryDiscussionView(r: GalleryPostParams): string {
  return `/${r.entityName}/discussions/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function fullyConnected(): string {
  return `/${GALLERY_PATH_SEGMENT}`;
}

export function createFCPost(): string {
  return `/${GALLERY_PATH_SEGMENT}/create-post`;
}

export function experimentTracking(): string {
  return `https://wandb.ai/site/experiment-tracking`;
}

export function tables(): string {
  return `https://wandb.ai/site/tables`;
}

export function sweeps(): string {
  return `https://wandb.ai/site/sweeps`;
}

export function models(): string {
  return `https://wandb.ai/site/models`;
}

export function launch(): string {
  return `https://wandb.ai/site/launch`;
}

export function artifacts(): string {
  return `https://wandb.ai/site/artifacts`;
}

export function reports(): string {
  return `https://wandb.ai/site/reports`;
}

export function articles(): string {
  return `https://wandb.ai/site/articles`;
}

export function podcast(): string {
  return `https://wandb.ai/site/podcast`;
}

export function caseStudies(): string {
  return `https://wandb.ai/site/case-studies`;
}

export function tutorials(): string {
  return `https://wandb.ai/site/tutorials`;
}

export function benchmarks(): string {
  return `https://wandb.ai/site/benchmarks`;
}

export function company(): string {
  return `https://wandb.ai/site/company`;
}

export function authors(): string {
  return `https://wandb.ai/site/authors`;
}

export function termsOfService(): string {
  return `https://wandb.ai/site/terms`;
}

export function privacyPolicy(): string {
  return `https://wandb.ai/site/privacy`;
}

export function pricing(): string {
  return `https://wandb.ai/site/pricing`;
}

export function lineageTrackingPricing(): string {
  return `https://wandb.ai/site/pricing#lineage-tracking`;
}

export function enterprise(): string {
  return `https://wandb.ai/site/enterprise`;
}

export function benchmarkList(): string {
  return `https://wandb.ai/site/benchmarks`;
}

export function contact(): string {
  return `https://wandb.ai/site/contact`;
}

export function contactSalesPricing(): string {
  return `https://wandb.ai/site/contact?interested-in=pricing`;
}

export function contactSalesDemo(): string {
  return `https://wandb.ai/site/contact?interested-in=demo`;
}

export function contactSupportOther(): string {
  return `https://wandb.ai/site/contact?interested-in=other`;
}

export function communityForum(): string {
  return 'https://community.wandb.ai';
}

export function courses(): string {
  return 'https://www.wandb.courses';
}

export function careers(): string {
  return `https://jobs.lever.co/wandb`;
}

export function facebook(): string {
  return `https://www.facebook.com/weightsandbiases`;
}

export function instagram(): string {
  return `https://www.instagram.com/weightsandbiases`;
}

// export function pinterest(): string {
//   return ``;
// }

export function twitter(): string {
  return `https://twitter.com/weights_biases`;
}

export function youtube(): string {
  return `https://www.youtube.com/channel/UCBp3w4DCEC64FZr4k9ROxig`;
}

export function allReports(entityName: string): string {
  return `/${entityName}/reports`;
}

export function allProjects(entityName: string): string {
  return `/${entityName}/projects`;
}

export function teamMembers(entityName: string): string {
  return `/${entityName}/members`;
}
