import {createContext, useContext} from 'react';

export type ReportContextValue = {
  disableComments?: boolean;
  disableCopyLinkToSection?: boolean;
  disablePanels?: boolean;
  disableTableOfContents?: boolean;
  disableLinkToReport?: boolean;
  disablePersistentTopLine?: boolean;
};

export const ReportContext = createContext<ReportContextValue>({});

export function useReportContext(): ReportContextValue {
  return useContext(ReportContext);
}
