import {
  constFunction,
  opArtifactId,
  opArtifactName,
  opDict,
  opMap,
} from '@wandb/cg';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import ModifiedDropdown from '@wandb/common/components/elements/ModifiedDropdown';
import {WBIcon} from '@wandb/ui';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import React, {useEffect, useMemo, useState} from 'react';
import {QueryResult} from 'react-apollo';
import {Button, Input, Popup} from 'semantic-ui-react';

import {
  EventTriggeringConditionType,
  Exact,
  FetchProjectTriggersQuery,
  TriggeredActionType,
  TriggerScopeType,
  useAllProjectsQuery,
  useCreateFilterTriggerMutation,
  useDeleteTriggerMutation,
  useEntityArtifactsOfTypeQuery,
  useFetchProjectTriggersQuery,
  useProjectArtifactCollectionsQuery,
} from '../generated/graphql';
import {DEFAULT_LAUNCH_PROJECT_NAME} from '../pages/EntityLaunchPage';
import {getArtifactPortfoliosNode} from '../pages/EntityRegistryPage';
import {Key as ArtifactKey} from '../util/artifacts';
import {FilterKey} from '../util/filters';
import {
  EMPTY_FILTER_VALUE,
  toMongo,
  Update,
  ValueFilter,
} from '../util/filters';
import {DropdownOption} from '../util/uihelpers';
import {
  LaunchDropdown,
  renderLaunchDropdown,
  useCreateRunQueueOptionsAndMap,
} from './Launch/EditLaunchConfigModal';
import {Job, LaunchConfigEditor} from './Launch/LaunchConfigEditor';
import * as S from './TriggersTab.styles';

interface TriggerRootFilter {
  op: 'OR';
  filters: [
    {
      op: 'AND';
      filters: Array<ValueFilter<FilterKey>>;
    }
  ];
}

const EMPTY_TRIGGER_FILTERS: TriggerRootFilter = {
  op: 'OR',
  filters: [
    {
      op: 'AND',
      filters: [],
    },
  ],
};

interface EventConfigurationFilterProps {
  descriptionString: string;
  index: number;
  filter: ValueFilter<FilterKey>;
  projectName: string;
  entityName: string;
  triggerScopeID?: string;
  setFilter(index: number, filter: ValueFilter<FilterKey>): void;
  setTriggerScopeID(val: string): void;
}

interface LinkModelFilterConfigurerProps {
  index: number;
  filter: ValueFilter<FilterKey>;
  projectName: string;
  entityName: string;
  triggerScopeID?: string;
  setTriggerScopeID(val: string): void;
  setFilter(index: number, filter: ValueFilter<FilterKey>): void;
}

interface EventConfigurationProps {
  filters: TriggerRootFilter['filters'][0];
  projectName: string;
  entityName: string;
  projectID: string;
  triggerScopeID?: string;
  setFilter(i: number, filter: ValueFilter<FilterKey>): void;
  pushFilter(newFilter: ValueFilter<FilterKey>): void;
  deleteFilter(index: number): void;
  setTriggerScopeType?(val: TriggerScopeType): void;
  setTriggerScopeID(val: string): void;
}

const ArtifactAliasFilterCreator: React.FC<EventConfigurationProps> = ({
  filters: rootFilters,
  projectName,
  entityName,
  projectID,
  setFilter,
  deleteFilter,
  setTriggerScopeType,
  setTriggerScopeID,
}) => {
  const [pushed, setPushed] = useState(false);
  useEffect(() => {
    setTriggerScopeID(projectID);
  }, [setTriggerScopeID, projectID]);
  return (
    <div>
      <S.TriggerStepAction>
        <div>Alias Regex:</div>
        <Input
          onChange={e => {
            setFilter(0, {
              key: {section: 'artifact', name: 'alias'},
              op: 'REGEX',
              value: e.target.value as string,
            });
          }}
          style={{width: '422px'}}
        />
      </S.TriggerStepAction>
      {!pushed && (
        <span
          className="fake-link filter__new"
          data-test="filter-add"
          onClick={() => {
            setPushed(true);
            if (setTriggerScopeType != null) {
              setTriggerScopeType(TriggerScopeType.ArtifactCollection);
            }
          }}>
          <S.TriggerStepAction>+ Add collection filter</S.TriggerStepAction>
        </span>
      )}
      {pushed && (
        <div>
          <ArtifactCollectionSelector
            index={1}
            descriptionString={'Within the artifact collection:'}
            entityName={entityName}
            projectName={projectName}
            filter={rootFilters.filters[1] ?? EMPTY_FILTER_VALUE}
            setFilter={(index: number, f: ValueFilter<ArtifactKey>) => {
              setFilter(index, f);
            }}
            setTriggerScopeID={setTriggerScopeID}
          />
          <Popup
            inverted
            size="mini"
            content="Remove filter"
            position="right center"
            on="hover"
            trigger={
              <S.InlineBlockAction>
                <LegacyWBIcon
                  data-test="filter-delete"
                  name="close"
                  onClick={() => {
                    setPushed(false);
                    setTriggerScopeID(projectID);
                    if (setTriggerScopeType != null) {
                      setTriggerScopeType(TriggerScopeType.Project);
                    }

                    deleteFilter(1);
                  }}
                  title="remove filter"
                />
              </S.InlineBlockAction>
            }
          />
        </div>
      )}
    </div>
  );
};

const LinkModelFilterCreator: React.FC<EventConfigurationProps> = props => {
  return (
    <LinkModelFilterConfigurer
      {...props}
      index={0}
      setFilter={props.setFilter}
      filter={props.filters.filters[0]}
    />
  );
};

type artifactPortfolioInfo = {
  id: string;
  name: string;
};

const LinkModelFilterConfigurer: React.FC<LinkModelFilterConfigurerProps> =
  props => {
    const portfoliosNode = React.useMemo(
      () => getArtifactPortfoliosNode('model', props.entityName),
      [props.entityName]
    );

    const portfoliosDictNode = opMap({
      arr: portfoliosNode,
      mapFn: constFunction({row: 'artifact'}, ({row}) => {
        return opDict({
          id: opArtifactId({artifact: row}),
          name: opArtifactName({artifact: row}),
        } as any);
      }),
    });
    const p = useNodeValue(portfoliosDictNode);
    if (p.loading) {
      return <></>;
    }
    const options = p.result.map((port: artifactPortfolioInfo) => {
      return {
        key: port.name,
        value: port.id,
        text: port.name,
      };
    });
    if (options.length === 0) {
      return (
        <S.InlineBlockAction>
          No portfolios available, create a model registry to use this event.
        </S.InlineBlockAction>
      );
    }
    return (
      <S.InlineBlockAction>
        When an artifact is added to this portfolio:
        <ModifiedDropdown
          style={{width: '500px'}}
          selection
          value={props.triggerScopeID}
          options={options}
          onChange={(_, {value}) => {
            props.setTriggerScopeID(value as string);
          }}
        />
      </S.InlineBlockAction>
    );
  };

const ArtifactCollectionFilterCreator: React.FC<EventConfigurationProps> =
  props => {
    return (
      <ArtifactCollectionSelector
        {...props}
        index={0}
        setFilter={props.setFilter}
        filter={props.filters.filters[0]}
        descriptionString="Pick an artifact to watch for new versions"
        triggerScopeID={props.triggerScopeID}
        setTriggerScopeID={props.setTriggerScopeID}
      />
    );
  };

const ArtifactCollectionSelector: React.FC<EventConfigurationFilterProps> =
  props => {
    const {data, loading} = useProjectArtifactCollectionsQuery({
      variables: {entityName: props.entityName, projectName: props.projectName},
    });
    const setTriggerScopeID = props.setTriggerScopeID;
    useEffect(() => {
      setTriggerScopeID('');
    }, [setTriggerScopeID]);
    if (loading || data == null) {
      return <div></div>;
    }

    const artifactCollections: DropdownOption[] =
      data.project?.artifactTypes.edges
        .map(artifactTypeEdge => {
          return artifactTypeEdge.node?.artifactCollections?.edges.map(
            artifactCollectionEdge => {
              return {
                key: artifactCollectionEdge.node?.id as string,
                text: artifactCollectionEdge.node?.name as string,
                value: artifactCollectionEdge.node?.id as string,
              };
            }
          );
        })
        .flat()
        .filter((v): v is DropdownOption => v != null) ?? [];
    return (
      <S.InlineBlockAction>
        <div>{props.descriptionString}</div>
        <ModifiedDropdown
          style={{width: '500px'}}
          selection
          value={props.triggerScopeID}
          options={artifactCollections}
          onChange={(_, {value}) => {
            setTriggerScopeID(value as string);
          }}
        />
      </S.InlineBlockAction>
    );
  };

interface TriggerTableProps {
  triggersQuery: QueryResult<
    FetchProjectTriggersQuery,
    Exact<{
      entityName: string;
      projectName: string;
    }>
  >;
}

interface TriggersTabProps {
  projectName: string;
  entityName: string;
  projectID: string;
}

interface Trigger {
  text: string;
  defaultEventScopeType: TriggerScopeType;
  templateStrings: TemplateValue[];
  eventConfiguration?: React.FC<EventConfigurationProps>;
}

interface TemplateValue {
  value: string;
  help: string;
}

const artifactTriggerTemplateValues: TemplateValue[] = [
  {
    value: '${artifactVersion}', // eslint-disable-line no-template-curly-in-string
    help: 'Refers to the specific artifact version that triggered the action.',
  },
  {
    value: '${artifactCollectionName}', // eslint-disable-line no-template-curly-in-string
    help: 'Refers to the name of the artifact collection that the artifact version is linked to.',
  },
];

const aliasTriggerTemplateValues: TemplateValue[] = [
  {
    value: '${alias}', // eslint-disable-line no-template-curly-in-string
    help: 'Refers to the alias that is being updated.',
  },
];

// const runTriggerTemplateValues: TemplateValue[] = [
//   {
//     value: '${runName}', // eslint-disable-line no-template-curly-in-string
//     help: 'Refers to the name of the run that triggered the action.',
//   },
//   {
//     value: '${runUrl}', // eslint-disable-line no-template-curly-in-string
//     help: 'Refers to the URL of the run that triggered the action.',
//   },
//   {
//     value: '${runSummary}', // eslint-disable-line no-template-curly-in-string
//     help: 'Refers to the summary of the run that triggered the action. To access a specific add a metric name to the end of the key. For example: ${runSummary}:metricName.', // eslint-disable-line no-template-curly-in-string
//   },
//   {
//     value: '${runConfig}', // eslint-disable-line no-template-curly-in-string
//     help: 'Refers to the config of the run that triggered the action. To access a specific add a metric name to the end of the key. For example: ${runConfig}:metricName.', // eslint-disable-line no-template-curly-in-string
//   },
// ];

const projectTriggerTemplateValues: TemplateValue[] = [
  {
    value: '${projectName}', // eslint-disable-line no-template-curly-in-string
    help: 'Refers to the name of the project owning the mutation that triggered the action.',
  },
];

const entityTriggerTemplateValues: TemplateValue[] = [
  {
    value: '${entityName}', // eslint-disable-line no-template-curly-in-string
    help: 'Refers to the name of the entity owning the mutation that triggered the action.',
  },
];

const UpdateAliasTrigger: Trigger = {
  text: 'An artifact alias is updated',
  defaultEventScopeType: TriggerScopeType.Project,
  templateStrings: [
    ...aliasTriggerTemplateValues,
    ...artifactTriggerTemplateValues,
    ...projectTriggerTemplateValues,
    ...entityTriggerTemplateValues,
  ],
  eventConfiguration: ArtifactAliasFilterCreator,
};
// TODO: Add with run finish trigger
// const RunFinishTrigger: Trigger = {
//   text: 'Run finish',
//   templateStrings: [
//     ...runTriggerTemplateValues,
//     ...projectTriggerTemplateValues,
//     ...entityTriggerTemplateValues,
//   ],
// };

const CreateArtifactTrigger: Trigger = {
  text: 'A new version of an artifact is created in a collection',
  defaultEventScopeType: TriggerScopeType.ArtifactCollection,
  templateStrings: [
    ...artifactTriggerTemplateValues,
    ...projectTriggerTemplateValues,
    ...entityTriggerTemplateValues,
  ],
  eventConfiguration: ArtifactCollectionFilterCreator,
};

const LinkModelTrigger: Trigger = {
  text: 'A new version is added to a registered model',
  defaultEventScopeType: TriggerScopeType.ArtifactCollection,
  templateStrings: [
    ...artifactTriggerTemplateValues,
    ...projectTriggerTemplateValues,
    ...entityTriggerTemplateValues,
  ],
  eventConfiguration: LinkModelFilterCreator,
};

const triggers: {[key in EventTriggeringConditionType]: Trigger} = {
  [EventTriggeringConditionType.UpdateArtifactAlias]: UpdateAliasTrigger,
  [EventTriggeringConditionType.CreateArtifact]: CreateArtifactTrigger,
  [EventTriggeringConditionType.LinkModel]: LinkModelTrigger,
  // TODO: add run finish trigger
  // RUN_FINISH: RunFinishTrigger,
};

const triggerOptions: DropdownOption[] = Object.keys(triggers).map(
  (key: string) => {
    return {
      key,
      text: triggers[key as EventTriggeringConditionType].text,
      value: key,
    };
  }
);

interface CreateTriggerTabProps {
  projectName: string;
  entityName: string;
  projectID: string;
  setCreatingTrigger: (creating: boolean) => void;
  refetchTriggers: () => Promise<any>;
}
const CreateTriggerTab: React.FC<CreateTriggerTabProps> = ({
  projectName,
  entityName,
  projectID,
  setCreatingTrigger,
  refetchTriggers,
}) => {
  const [createTriggerMutation] = useCreateFilterTriggerMutation();
  const [triggerName, setTriggerName] = useState('');
  const [triggerDescription, setTriggerDescription] = useState<
    string | undefined
  >();
  const [trigger, setTrigger] = useState<Trigger | undefined>();
  const [eventTriggeringConditionType, setEventTriggeringConditionType] =
    useState<EventTriggeringConditionType | undefined>();
  const [triggerScopeType, setTriggerScopeType] = useState<
    TriggerScopeType | undefined
  >();
  const [triggerScopeID, setTriggerScopeID] = useState<string | undefined>();
  const onSelectTrigger = (value: EventTriggeringConditionType) => {
    const selectedTrigger = triggers[value];
    setTrigger(selectedTrigger);
    setEventTriggeringConditionType(value);
    setTriggerScopeType(selectedTrigger.defaultEventScopeType);
  };
  const {data} = useAllProjectsQuery({
    variables: {entityName, first: 1000, order: '-createdAt'},
    fetchPolicy: 'cache-and-network',
  });

  const allProjects =
    data?.projects?.edges
      .map(p => p.node?.name)
      .filter(p => p != null && p !== DEFAULT_LAUNCH_PROJECT_NAME) ?? [];

  const projectOptions = allProjects.map(name => {
    return {
      key: name,
      text: name,
      value: name,
    };
  });

  const [queuedJob, setQueuedJob] = useState<Job | undefined>();
  const [launchSpecTemplate, setLaunchSpecTemplate] = useState<string>('{}');
  const [targetProject, setTargetProject] = useState<string>(projectName);
  const [targetQueueName, setTargetQueueName] = useState<string>('default');

  const [filters, setFilters] = useState<TriggerRootFilter>(
    EMPTY_TRIGGER_FILTERS
  );
  const [loading, setLoading] = useState(false);
  const {pushQueueNameIdMap, runQueueOptions} = useCreateRunQueueOptionsAndMap(
    entityName,
    DEFAULT_LAUNCH_PROJECT_NAME
  );

  const jobArtifacts = useEntityArtifactsOfTypeQuery({
    variables: {entityName, artifactType: 'job'},
  });

  const jobsMap: {[key: string]: Job} = useMemo(() => {
    if (jobArtifacts.data?.entity?.projects == null) {
      return {};
    }
    const jobTuples: Array<[string, Job]> = [];
    jobArtifacts.data?.entity?.projects?.edges.forEach(p => {
      p.node?.artifactType?.artifactSequences?.edges.forEach(as =>
        as.node?.artifacts.edges.forEach(a =>
          a.node.aliases.forEach(alias => {
            const fullJobName = `${entityName}/${p.node?.name}/${as.node?.name}:${alias.alias}`;
            jobTuples.push([
              fullJobName,
              {
                fullJobName,
                createdBy: {
                  config:
                    a.node.createdBy.__typename === 'Run'
                      ? a.node.createdBy.config
                      : undefined,
                },
              },
            ]);
          })
        )
      );
    });
    return Object.fromEntries(jobTuples);
  }, [jobArtifacts, entityName]);
  const jobOptions: DropdownOption[] = useMemo(() => {
    return Object.keys(jobsMap).map(key => {
      return {
        key,
        text: key,
        value: key,
      };
    });
  }, [jobsMap]);

  useEffect(() => {
    setFilters(EMPTY_TRIGGER_FILTERS);
  }, [trigger]);

  const step2Disabled = trigger == null || triggerScopeID == null;
  const step3Disabled = queuedJob == null || step2Disabled;

  const EventConfiguration = trigger?.eventConfiguration;

  const effectSetupDiv = useMemo(() => {
    return (
      <>
        <S.TriggerStepAction>
          This job will get queued:
          <ModifiedDropdown
            style={{width: '500px'}}
            selection
            value={queuedJob?.fullJobName}
            options={jobOptions}
            disabled={step2Disabled}
            onChange={(_, d) => {
              if (d.value !== '') {
                setQueuedJob(jobsMap[d.value as string]);
              }
            }}
          />
        </S.TriggerStepAction>
      </>
    );
  }, [step2Disabled, jobsMap, jobOptions, queuedJob?.fullJobName]);

  const templateStringHelpDiv = (
    <S.TemplateStringHelperDiv>
      {trigger?.templateStrings.map(ts => (
        <S.TemplateHelperRow key={ts.value}>
          <S.TemplateStringName>{ts.value}:</S.TemplateStringName>{' '}
          <S.TemplateStringHelp>{ts.help}</S.TemplateStringHelp>
        </S.TemplateHelperRow>
      ))}
    </S.TemplateStringHelperDiv>
  );

  const launchDropdownsRendered = useMemo(() => {
    const launchDropdowns: LaunchDropdown[] = [
      {
        key: 'projectDropdown',
        label: "Run's Target Project:",
        options: projectOptions,
        value: targetProject,
        setValue: setTargetProject,
        placeholder: 'Select project',
      },
      {
        key: 'runQueueDropdown',
        label: 'Queue:',
        options: runQueueOptions,
        value: targetQueueName,
        setValue: setTargetQueueName,
      },
    ];
    return launchDropdowns.map(dropdown => renderLaunchDropdown(dropdown));
  }, [
    projectOptions,
    targetProject,
    setTargetProject,
    runQueueOptions,
    targetQueueName,
    setTargetQueueName,
  ]);

  return (
    <>
      <S.TriggerCreationTitle>Create Trigger</S.TriggerCreationTitle>
      <S.TriggerCreationHeader>
        Use triggers to automatically run jobs.
      </S.TriggerCreationHeader>
      <S.TriggerStep>
        <S.TriggerStepHeader>
          <S.NumberCircle>1</S.NumberCircle>
          Pick an event to trigger an action
        </S.TriggerStepHeader>
        <S.TriggerStepAction>
          <ModifiedDropdown
            selection
            options={triggerOptions}
            value={trigger?.text}
            placeholder="Select an event type..."
            onChange={(_, d) =>
              onSelectTrigger(d.value as EventTriggeringConditionType)
            }
            style={{width: '500px'}}
          />
        </S.TriggerStepAction>
        {EventConfiguration != null && (
          <div>
            <EventConfiguration
              filters={filters.filters[0]}
              setFilter={(index: number, f: ValueFilter<FilterKey>) =>
                setFilters(Update.setFilter(filters, [0, index], f))
              }
              pushFilter={(newFilter: ValueFilter<FilterKey>) => {
                setFilters(Update.groupPush(filters, [0], newFilter));
              }}
              deleteFilter={(index: number) =>
                setFilters(Update.groupRemove(filters, [0], index))
              }
              projectName={projectName}
              entityName={entityName}
              setTriggerScopeType={setTriggerScopeType}
              setTriggerScopeID={setTriggerScopeID}
              triggerScopeID={triggerScopeID}
              projectID={projectID}
            />
          </div>
        )}
      </S.TriggerStep>
      <S.TriggerStep>
        <S.TriggerStepHeader>
          <S.NumberCircle disabled={step2Disabled}>2</S.NumberCircle>
          Pick a job that queues whenever this event happens
        </S.TriggerStepHeader>
        {effectSetupDiv}
      </S.TriggerStep>

      <S.TriggerStep>
        <S.TriggerStepHeader>
          <S.NumberCircle disabled={step3Disabled}>3</S.NumberCircle>
          Describe and configure the action:
        </S.TriggerStepHeader>
      </S.TriggerStep>

      {queuedJob != null && !step3Disabled && (
        <div>
          <S.TriggerStepAction>
            {templateStringHelpDiv}
            <S.EditorDiv>
              <LaunchConfigEditor
                projectName={projectName}
                entityName={entityName}
                job={queuedJob}
                setLaunchConfig={setLaunchSpecTemplate}
                launchConfig={launchSpecTemplate}
              />
            </S.EditorDiv>
            <S.SpacerDiv>{launchDropdownsRendered}</S.SpacerDiv>
            <S.DescriptionSelectorDiv>
              Trigger Name:{' '}
              <Input
                placeholder="Name"
                value={triggerName}
                disabled={step3Disabled}
                onChange={e => setTriggerName(e.target.value)}
              />
            </S.DescriptionSelectorDiv>
            <S.DescriptionSelectorDiv>
              Trigger Description:{' '}
              <Input
                placeholder="Description"
                value={triggerDescription}
                disabled={step3Disabled}
                onChange={e => setTriggerDescription(e.target.value)}
              />
            </S.DescriptionSelectorDiv>
          </S.TriggerStepAction>
        </div>
      )}
      <S.TriggerStepAction>
        <Button
          content="Cancel"
          onClick={() => {
            setFilters(EMPTY_TRIGGER_FILTERS);
            setCreatingTrigger(false);
          }}
        />
        <Button
          content="Create Trigger"
          loading={loading}
          disabled={triggerName === '' || queuedJob == null || loading}
          onClick={async () => {
            if (
              trigger == null ||
              EventTriggeringConditionType == null ||
              queuedJob == null ||
              triggerScopeType == null ||
              triggerScopeID == null ||
              eventTriggeringConditionType == null ||
              filters == null
            ) {
              return;
            }
            const parsedTemplate = JSON.parse(launchSpecTemplate);
            const triggeredActionConfig = {
              queueJobActionInput: {
                queueID: pushQueueNameIdMap[targetQueueName],
                template: JSON.stringify(
                  {
                    job: queuedJob.fullJobName,
                    entity: entityName,
                    project: targetProject,
                    ...parsedTemplate,
                  },
                  null,
                  2
                ),
              },
            };
            setLoading(true);

            await createTriggerMutation({
              variables: {
                name: triggerName,
                description: triggerDescription,
                triggeringEventType: eventTriggeringConditionType,
                scopeType: triggerScopeType,
                scopeID: triggerScopeID,
                eventFilter: JSON.stringify(toMongo(filters)),
                triggeredActionType: TriggeredActionType.QueueJob,
                triggeredActionConfig,
                enabled: true,
              },
            });
            await refetchTriggers();

            setCreatingTrigger(false);
            setLoading(false);
          }}
        />
      </S.TriggerStepAction>
    </>
  );
};

const TriggersTab: React.FC<TriggersTabProps> = props => {
  const [creatingTrigger, setCreatingTrigger] = useState(false);
  const triggersQuery = useFetchProjectTriggersQuery({
    variables: {projectName: props.projectName, entityName: props.entityName},
  });
  const refetchTriggers = triggersQuery.refetch;
  return (
    <>
      {creatingTrigger && (
        <CreateTriggerTab
          projectName={props.projectName}
          entityName={props.entityName}
          projectID={props.projectID}
          setCreatingTrigger={setCreatingTrigger}
          refetchTriggers={refetchTriggers}
        />
      )}
      {!creatingTrigger && (
        <>
          <S.TriggersHeader>
            <div>All Actions</div>
            <Button
              content="Create trigger"
              onClick={() => {
                setCreatingTrigger(true);
              }}
              color="blue"
            />
          </S.TriggersHeader>
          <TriggerTable triggersQuery={triggersQuery} />
        </>
      )}
    </>
  );
};

export default TriggersTab;

const TriggerTable: React.FC<TriggerTableProps> = ({triggersQuery}) => {
  const [deleteTriggerMutation] = useDeleteTriggerMutation();
  const rows = useMemo(
    () =>
      triggersQuery.data?.project?.triggers
        ?.map(trigger => {
          return {
            row: {
              id: trigger.id,
              name: trigger.name,
              description: trigger.description,
              triggerEventConfig: (
                <>
                  <div>
                    Event type:{' '}
                    {
                      triggers[
                        trigger.triggeringCondition
                          .eventType as EventTriggeringConditionType
                      ].text
                    }
                  </div>
                  <div>
                    Event Scope: {trigger.scope.__typename}:{' '}
                    {trigger.scope.name}
                  </div>
                  <div>Event filter:</div>
                  <code>{trigger.triggeringCondition.filter}</code>
                </>
              ),
              triggerActionConfig: trigger.triggeredAction.__typename ===
                'QueueJobTriggeredAction' && (
                <>
                  <div>Type: Queue Job</div>
                  <div>Queue: {trigger.triggeredAction.queue.name}</div>
                  <div>Template:</div>

                  <code>{trigger.triggeredAction.template}</code>
                </>
              ),
              deleteTrigger: (
                <Popup
                  content="Delete trigger"
                  inverted
                  size="mini"
                  position="top center"
                  popperModifiers={{
                    preventOverflow: {
                      boundariesElement: 'offsetParent',
                    },
                  }}
                  trigger={
                    <WBIcon
                      size="huge"
                      style={{cursor: 'pointer'}}
                      name="delete"
                      onClick={async (e: React.SyntheticEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await deleteTriggerMutation({
                          variables: {triggerID: trigger.id},
                        });
                        await triggersQuery.refetch();
                      }}
                    />
                  }
                />
              ),
            },
            searchString: trigger.name,
          };
        })
        .filter(r => r.row.id != null) || [],
    [triggersQuery, deleteTriggerMutation]
  );
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Event Filter',
      accessor: 'triggerEventConfig',
    },
    {
      Header: 'Action Config',
      accessor: 'triggerActionConfig',
    },
    {
      Header: '',
      accessor: 'deleteTrigger',
    },
  ];

  return (
    <S.TriggersTable
      data={rows}
      loading={triggersQuery.data == null}
      columns={columns}
      pageSize={20}
      defaultSorted={[{id: 'name', desc: true}]}
    />
  );
};
