import {BetaFeatureMetadata} from '@wandb/common/util/betaFeatures';
import React, {FC} from 'react';

import {useBetaFeature} from '../../util/useBetaFeature';
import {FeatureToggle} from './FeatureToggle';
type BetaFeatureToggleProps = {
  betaFeature: BetaFeatureMetadata;
};

// This is a single beta feature toggle row
export const BetaFeatureToggle: FC<BetaFeatureToggleProps> = React.memo(
  ({betaFeature}) => {
    const {isEnabled, toggleBetaFeature} = useBetaFeature(
      betaFeature.featureKey
    );

    return (
      <FeatureToggle
        {...{
          name: betaFeature.name,
          description: betaFeature.description,
          docUrl: betaFeature.docUrl,
          isEnabled,
          toggleFeature: toggleBetaFeature,
        }}
      />
    );
  }
);
