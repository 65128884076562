import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';

export const ModalTriggerButton: React.FC<{
  modalHeader: string;
  modalContent: JSX.Element;
  buttonIcon: string;
  buttonText: string;
  onClick?: () => void;
}> = ({modalContent, modalHeader, buttonIcon, buttonText, onClick}) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <React.Fragment>
      {/* COLUMNS CONFIG */}
      <Modal
        className="small"
        trigger={
          <Button
            size="tiny"
            className="wb-icon-button"
            onClick={() => {
              openModal();
              onClick?.();
            }}>
            <LegacyWBIcon name={buttonIcon} />
            {buttonText}
          </Button>
        }
        open={showModal}
        onClose={closeModal}>
        <Modal.Header>{modalHeader}</Modal.Header>
        <Modal.Content>{modalContent}</Modal.Content>
        <Modal.Actions>
          <Button primary onClick={closeModal}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
