import EditableField from '@wandb/common/components/EditableField';
import makeComp from '@wandb/common/util/profiler';
import update from 'immutability-helper';
import React, {FC, useCallback} from 'react';
import {Divider} from 'semantic-ui-react';

import {useUpdateEntityMutation} from '../../generated/graphql';
import {useEntitySettings} from '../../util/entitySettings';
import * as AlertS from '../Alerts.styles';
import EditableImage from '../EditableImage';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';
import * as S from './UserSettingsProfile.styles';

export const UserSettingsProfile: FC = makeComp(
  () => {
    const {viewer, updateUserInfo} = useUserSettingsContext();

    const entityName = viewer?.username ?? ``;
    const {entitySettings, refetch: refetchEntitySettings} =
      useEntitySettings(entityName);

    const [updateEntity] = useUpdateEntityMutation();

    const isEnabled = entitySettings?.[`entityProfileEnabled`] ?? false;
    const toggleFeature = useCallback(async () => {
      await updateEntity({
        variables: {
          entityName,
          settings: {
            entityProfileEnabled: !isEnabled,
          },
        },
      });
      await refetchEntitySettings();
    }, [updateEntity, refetchEntitySettings, entityName, isEnabled]);

    if (viewer == null || updateUserInfo == null) {
      return <UserSettingsPlaceholder />;
    }

    const defaultUserInfo = {
      bio: '',
      location: '',
      company: '',
      websiteUrl: '',
    };

    const userInfo = {
      ...defaultUserInfo,
      ...viewer.userInfo,
    };

    return (
      <>
        <EditableImage
          profile
          displaySize={200}
          photoUrl={viewer.photoUrl}
          save={newVal => {
            updateUserInfo({
              variables: {
                photoUrl: newVal,
              },
            });
          }}
        />
        <Divider />
        <EditableField
          label="Name"
          placeholder="Geoffrey Hinton"
          value={viewer.name}
          updateValue={false}
          maxLength={64}
          save={newVal => {
            updateUserInfo({
              variables: {
                name: newVal,
              },
            });
          }}
        />
        <Divider />

        <EditableField
          readOnly
          label="Username"
          value={viewer.username ?? ''}
          placeholder="Username"
        />
        <Divider />
        <EditableField
          label="Bio"
          placeholder="I teach robots how to learn."
          multiline
          value={userInfo.bio}
          updateValue={false}
          maxLength={512}
          save={newVal => {
            updateUserInfo({
              variables: {
                userInfo: JSON.stringify(
                  update(viewer.userInfo, {bio: {$set: newVal}})
                ),
              },
            });
          }}
        />
        <Divider />
        <EditableField
          label="Institution"
          placeholder="University of Toronto"
          value={userInfo.company}
          updateValue={false}
          maxLength={64}
          save={newVal => {
            updateUserInfo({
              variables: {
                userInfo: JSON.stringify(
                  update(viewer.userInfo, {company: {$set: newVal}})
                ),
              },
            });
          }}
        />
        <Divider />
        <EditableField
          label="Location"
          placeholder="Canada"
          value={userInfo.location}
          updateValue={false}
          maxLength={64}
          save={newVal => {
            updateUserInfo({
              variables: {
                userInfo: JSON.stringify(
                  update(viewer.userInfo, {
                    location: {$set: newVal},
                  })
                ),
              },
            });
          }}
        />
        <Divider />
        <EditableField
          label="Website"
          placeholder="example.com"
          type="url"
          value={userInfo.websiteUrl}
          updateValue={false}
          maxLength={1024}
          save={newVal => {
            updateUserInfo({
              variables: {
                userInfo: JSON.stringify(
                  update(viewer.userInfo, {
                    websiteUrl: {$set: newVal},
                  })
                ),
              },
            });
          }}
        />
        {viewer?.username && entitySettings != null && (
          <>
            <Divider />
            <S.Row>
              <S.Label>Customization</S.Label>
              <S.Description>
                Customize your user profile page to showcase your ML portfolio
              </S.Description>
              <S.Tooltip
                inverted
                position="top center"
                popperModifiers={{
                  preventOverflow: {
                    // prevent popper from erroneously constraining the popup to the
                    // table header
                    boundariesElement: 'viewport',
                  },
                }}
                trigger={
                  <S.ToggleContainer>
                    <AlertS.Toggle
                      toggle
                      checked={isEnabled}
                      onChange={toggleFeature}
                    />
                  </S.ToggleContainer>
                }>
                Disable for the classic profile experience
              </S.Tooltip>
            </S.Row>
          </>
        )}
      </>
    );
  },
  {id: 'UserSettingsProfile', memo: true}
);
