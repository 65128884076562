import {backendHost} from '@wandb/common/config';
import React from 'react';

import {auth} from '../setup';
import * as S from './VerifyEmail.styles';

const VerifyEmail: React.FC<{email?: string}> = ({email}) => {
  const [verifyEmailClicked, setVerifyEmailClicked] = React.useState(false);
  const verifyEmail = React.useCallback(async () => {
    try {
      const resp = await fetch(backendHost('/email'), {
        method: 'POST',
        credentials: 'include',
      });

      if (!resp.ok) {
        const msg = await resp.text();
        console.log('Error sending verification email: ', msg);
      }
    } catch (err) {
      console.log('Failed to request verification email.');
    }
  }, []);

  return (
    <S.Background>
      <S.PromptContainer>
        <S.Header>Check your inbox</S.Header>
        <S.Subheader>Let's get your email address verified</S.Subheader>
        <S.Instructions>
          <p>{`Please verify the email address associated with your W&B account${
            email == null ? '.' : ':'
          }`}</p>
          <p>
            <strong>{email}</strong>
          </p>
        </S.Instructions>
        <S.LightButton onClick={() => auth.login()}>
          I already verified my email
        </S.LightButton>
        <S.DarkButton
          disabled={verifyEmailClicked}
          onClick={async () => {
            verifyEmail();
            setVerifyEmailClicked(true);
          }}>
          {verifyEmailClicked ? 'Sent!' : 'Resend email'}
        </S.DarkButton>
      </S.PromptContainer>
      <S.Postscript>
        Not finding the message? Please check your spam folder.
        <br />
        If you'd prefer,{' '}
        <S.Darklink onClick={() => auth.login()}>
          login with a different email
        </S.Darklink>
        .
      </S.Postscript>
    </S.Background>
  );
};

export default VerifyEmail;
