import React, {useContext} from 'react';

// focused shows whether the slate content isFocused = true and it
// updates and rerender the components that subscribe to this context
type FocusedContextValue = boolean;

export const FocusedContext = React.createContext<FocusedContextValue>(false);

export const useFocused = () => {
  return useContext(FocusedContext);
};
