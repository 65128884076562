import {useLauncherData} from './useLauncherData';

export const useLauncherResults = (searchTerm: string) => {
  const {projects = [], reports = [], teams = []} = useLauncherData(searchTerm);

  const results = [
    ...projects.filter(
      p =>
        p.name.toLowerCase().includes(searchTerm) ||
        searchTerm.startsWith('project')
    ),
    ...reports.filter(
      r =>
        r.displayName.toLowerCase().includes(searchTerm) ||
        searchTerm.startsWith('report')
    ),
    ...teams.filter(
      t =>
        t.name.toLowerCase().includes(searchTerm) ||
        searchTerm.startsWith('team')
    ),
  ];

  return results;
};
