import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import {WeaveErrorBoundary} from '../../../Panel2/ErrorBoundary';
import PanelJob, {ConfigType as PanelJobConfigType} from './PanelJob/component';

interface JobPanelRootProps {
  entityName: string;
  projectName: string;
  selectedJobVersion: string;
  setSelectedJobVersion: (version: string) => void;
}

export const JobPanelRoot = (props: JobPanelRootProps) => {
  const {entityName, projectName, selectedJobVersion, setSelectedJobVersion} =
    props;

  const [context, updateContext] = React.useState({});
  const [config, updateConfig] = Panel2.useConfig<PanelJobConfigType>({});
  const artifactCollectionName = selectedJobVersion.split(':')[0];
  const controlledUpdateConfig: typeof updateConfig = React.useCallback(
    update => {
      if (update.selectedJobVersion != null) {
        setSelectedJobVersion(update.selectedJobVersion);
      }
      updateConfig(update);
    },
    [setSelectedJobVersion, updateConfig]
  );
  React.useEffect(() => {
    updateConfig({
      selectedJobVersion,
    });
  }, [selectedJobVersion, updateConfig]);
  const input = React.useMemo(() => {
    return Op.opProjectArtifact({
      project: Op.opRootProject({
        entityName: Op.constString(entityName),
        projectName: Op.constString(projectName),
      }),
      artifactName: Op.constString(artifactCollectionName),
    }) as GraphTypes.OutputNode<'artifact'>;
  }, [artifactCollectionName, entityName, projectName]);

  return (
    <WeaveErrorBoundary
      canReset={false}
      canUndo={false}
      errorConfig={config}
      sourcePanel={'PanelJob'}
      onReset={() => {}}
      onUndo={() => {}}>
      <PanelJob
        {...{
          input,
          context,
          updateContext,
          config,
          updateConfig: controlledUpdateConfig,
        }}
      />
    </WeaveErrorBoundary>
  );
};
