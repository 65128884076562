import docUrl from '@wandb/common/util/doc_urls';
import {TargetBlank} from '@wandb/common/util/links';
import classNames from 'classnames';
import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import Measure from 'react-measure';
import {shallowEqual} from 'react-redux';

import {PanelBankContextProvider} from '../../state/panelbank/context';
import {PanelSearchContextProvider} from '../../state/panelbank/PanelSearchContextProvider';
import {useKeyInfoQuery} from '../../state/runs/hooks';
import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as InteractStateActions from '../../state/views/interactState/actions';
import * as InteractStateContext from '../../state/views/interactState/context';
import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import {updateAllLocalAndWorkspacePanelSettings} from '../../state/views/panelSettings/actions';
import {useAllPanelSettingsAction} from '../../state/views/panelSettings/hooks';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {PANEL_BANK_PADDING, PanelBankSectionConfig} from '../../util/panelbank';
import {LayedOutPanelWithRef} from '../../util/panels';
import {isReservedKey, keyBitmapToKeys} from '../../util/runs';
import WarningMessage from '../elements/WarningMessage';
import {InstrumentedLoader as Loader} from '../utility/InstrumentedLoader';
import PanelBankControls from './PanelBankControls';
import PanelBankSections from './PanelBankSections';
import {
  PanelBankConfigWithRefs,
  usePanelBankConfigWithRefs,
} from './usePanelBankConfigWithRefs';
import {PanelBankConfigProvider} from './usePanelBankContext';

export interface PanelBankProps {
  entityName: string;
  projectName: string;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  singleRun?: boolean; // set to true in RunWorkspace, false otherwise (determines if we use LinePlot or RunsLinePlot)
  panelSettingsRef: PanelSettingsTypes.Ref;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  customRunColorsRef: CustomRunColorsViewTypes.Ref; // This is passed into PanelBankSections.
  runSetRefs: RunSetViewTypes.Ref[];
  showSlowWarning?: boolean;
  showCliVersionWarning?: boolean;
  workspaceID?: string;
  hideSlowWarning?(): void;
  hideCliVersionWarning?(): void;
}

function historyWeight(historyKeyInfo: RunHistoryKeyInfo): number {
  // Backend will suppress key_info.sets for very large projects
  // Treat these as if their history is infinitely heavy.
  if (
    historyKeyInfo.sets.length === 0 &&
    Object.keys(historyKeyInfo.keys).length > 0
  ) {
    return Infinity;
  }

  const allKeys = Object.keys(historyKeyInfo.keys).sort();
  let totalSteps = 0;
  for (const set of historyKeyInfo.sets) {
    const keys = keyBitmapToKeys(allKeys, set.keyBitmap);
    let hasSystem = false;
    for (const key of keys) {
      if (key.startsWith('system/')) {
        hasSystem = true;
        break;
      } else if (!isReservedKey(key)) {
        break;
      }
    }
    if (!hasSystem) {
      totalSteps += set.count;
    }
  }
  let estimatedSize = 0;
  for (const key of allKeys) {
    if (key.startsWith('system/')) {
      continue;
    }
    const info = historyKeyInfo.keys[key];
    for (const tc of info.typeCounts) {
      let valSize = key.length;
      switch (tc.type) {
        case 'number':
          valSize += 8;
          break;
        case 'string':
          valSize += 16;
          break;
        case 'histogram':
          valSize += 1024;
          break;
        default:
          // this will include things like images which have some metadata about them
          valSize += 256;
          break;
      }
      estimatedSize += valSize * tc.count;
    }
  }

  let estimatedRuns = Math.round(
    totalSteps / Math.max(1, historyKeyInfo.lastStep || 0)
  );
  estimatedRuns = Math.min(100, Math.max(1, estimatedRuns));

  return estimatedSize / estimatedRuns;
}

export type SectionWithRef = Omit<PanelBankSectionConfig, 'panels'> & {
  ref: PanelBankSectionConfigTypes.Ref;
  panels: LayedOutPanelWithRef[];
  localPanelSettingsRef: PanelSettingsTypes.Ref;
};

const PanelBankLayoutComp: React.FC<PanelBankProps> = props => {
  const {
    entityName,
    projectName,
    readOnly,
    panelBankConfigRef,
    panelSettingsRef,
    runSetRefs,
    customRunColorsRef,
    hideSlowWarning,
    hideCliVersionWarning,
    singleRun,
  } = props;
  const [panelBankWidth, setPanelBankWidth] = useState(0);
  const keyInfoQuery = useKeyInfoQuery(props.runSetRefs);
  const setPanelSelection = InteractStateContext.useInteractStateAction(
    InteractStateActions.setPanelSelection
  );
  const panelBankConfigWithRefs = usePanelBankConfigWithRefs(
    props.panelBankConfigRef
  );
  const localPanelSettingsRefs = panelBankConfigWithRefs.sections.map(
    section => section.localPanelSettingsRef
  );
  const resetAllLocals = useAllPanelSettingsAction(
    localPanelSettingsRefs,
    panelSettingsRef,
    updateAllLocalAndWorkspacePanelSettings
  );
  const slowHistory = useMemo(() => {
    if (keyInfoQuery.loading || keyInfoQuery.error != null) {
      return false;
    }
    /* tslint:disable:no-bitwise */
    return historyWeight(keyInfoQuery.historyKeyInfo) > 500 << 20; // 500 MB
    /* tslint:enable:no-bitwise */
  }, [keyInfoQuery]);

  useEffect(() => {
    if (slowHistory && props.showSlowWarning) {
      window.analytics?.track('Workspace Slow Warning');
    }
  }, [slowHistory, props.showSlowWarning]);

  if (keyInfoQuery.loading || keyInfoQuery.error != null) {
    return <Loader active name="key-info-query-loader" size="huge" />;
  }
  const {historyKeyInfo, viz: historyKeyViz} = keyInfoQuery;

  /**
   * The config provider here is a duplication of the behavior currently in the prop drilling. But the existing drilling doens't go far enough down to toggle run aggregation within the panels. This context avoids extra drilling to support that, and also given the the panelBankConfigWithRefs value is very stable, we can gradually thin out the drilling
   */
  return (
    <PanelBankConfigProvider
      entityName={entityName}
      panelBankConfigWithRefs={panelBankConfigWithRefs}
      projectName={projectName}
      workspaceId={props.workspaceID ?? ''}>
      <div
        className={classNames('panel-bank', {
          'panel-bank-with-runs': !singleRun,
        })}>
        {slowHistory && props.showSlowWarning && (
          <WarningMessage
            content={
              <p>
                You're logging a lot of data – this page may load slowly.{' '}
                <TargetBlank href={docUrl.limits}>Learn more →</TargetBlank>
              </p>
            }
            className="slow-warning"
            onDismiss={hideSlowWarning}
          />
        )}
        {props.showCliVersionWarning && (
          <WarningMessage
            content={
              <p>
                Your version of wandb is outdated. Please upgrade with: pip
                install wandb --upgrade
              </p>
            }
            className="slow-warning"
            onDismiss={hideCliVersionWarning}
          />
        )}
        <PanelBankControls
          entityName={entityName}
          projectName={projectName}
          readOnly={readOnly}
          panelBankConfigRef={panelBankConfigRef}
          panelBankConfigWithRefs={panelBankConfigWithRefs}
          panelSettingsRef={panelSettingsRef}
          runSetRefs={runSetRefs}
          customRunColorsRef={customRunColorsRef}
          singleRun={singleRun}
          historyKeyInfo={historyKeyInfo}
          panelBankWidth={panelBankWidth}
          workspaceID={props.workspaceID}
          resetAllLocals={resetAllLocals}
        />

        <PanelBankInner
          panelBankProps={props}
          warningVisible={slowHistory && props.showSlowWarning}
          historyKeyInfo={historyKeyInfo}
          historyKeyViz={historyKeyViz}
          panelBankWidth={panelBankWidth}
          setPanelBankWidth={setPanelBankWidth}
          setPanelSelection={setPanelSelection}
          panelBankConfigWithRefs={panelBankConfigWithRefs}
        />
      </div>
    </PanelBankConfigProvider>
  );
};
const PanelBankLayout = memo(PanelBankLayoutComp);

interface PanelBankInnerProps {
  panelBankProps: PanelBankProps;
  panelBankConfigWithRefs: PanelBankConfigWithRefs;
  warningVisible?: boolean;
  historyKeyInfo: RunHistoryKeyInfo;
  historyKeyViz: {
    [key: string]: any;
  };
  panelBankWidth: number;
  setPanelBankWidth(width: number): void;
  setPanelSelection(args: any): any;
}

const PanelBankInnerComp = (props: PanelBankInnerProps) => {
  const {
    panelBankProps,
    warningVisible,
    historyKeyInfo,
    historyKeyViz,
    panelBankWidth,
    panelBankConfigWithRefs,
    setPanelBankWidth,
    setPanelSelection,
  } = props;

  const panelBankSectionsRef = useRef<HTMLDivElement>(null);

  return (
    <Measure
      bounds
      onResize={contentRect => {
        setPanelBankWidth(
          contentRect.bounds
            ? contentRect.bounds.width - PANEL_BANK_PADDING * 2
            : 0
        );
      }}>
      {({measureRef}) => {
        return (
          <div
            ref={measureRef}
            onMouseDown={e => {
              if ((e.target as Element).closest('.panel-bank__panel') == null) {
                setPanelSelection([]);
              }
            }}>
            <div className="panel-bank__sections" ref={panelBankSectionsRef}>
              {panelBankWidth > 0 && (
                <PanelBankSections
                  key={panelBankProps.panelBankConfigRef.id}
                  {...panelBankProps}
                  warningVisible={warningVisible}
                  historyKeyInfo={historyKeyInfo}
                  historyKeyViz={historyKeyViz}
                  panelBankWidth={panelBankWidth}
                  panelBankConfigWithRefs={panelBankConfigWithRefs}
                  panelBankSectionsRef={panelBankSectionsRef}
                />
              )}
            </div>
          </div>
        );
      }}
    </Measure>
  );
};

const PanelBankInner = memo(PanelBankInnerComp, (prevProps, nextProps) => {
  return (
    prevProps.historyKeyInfo === nextProps.historyKeyInfo &&
    prevProps.panelBankWidth === nextProps.panelBankWidth &&
    shallowEqual(
      prevProps.panelBankConfigWithRefs,
      nextProps.panelBankConfigWithRefs
    ) &&
    prevProps.setPanelBankWidth === nextProps.setPanelBankWidth &&
    prevProps.setPanelSelection === nextProps.setPanelSelection &&
    shallowEqual(prevProps.panelBankProps, nextProps.panelBankProps)
  );
});

const PanelBankComp: React.FC<PanelBankProps> = props => (
  <PanelBankContextProvider>
    <PanelSearchContextProvider>
      <PanelBankLayout {...props} />
    </PanelSearchContextProvider>
  </PanelBankContextProvider>
);

export const PanelBank = memo(PanelBankComp);
