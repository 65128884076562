import {ResultTypes} from './types';

const supportedRoutes = {
  '/': '/',
  'create team': '/create-team',
  'fully connected': '/fully-connected',
  home: '/home',
  subscriptions: '/subscriptions',
  settings: '/settings',
};

const quickStartMap = {
  pytorch: '/quickstart/pytorch',
  'pytorch-lightning': '/quickstart/pytorch-lightning',
  keras: '/quickstart/keras',
  tensorflow: '/quickstart/tensorflow',
  'hugging-face': '/quickstart/hugging-face',
  xgboost: '/quickstart/xgboost',
  'scikit-learn': '/quickstart/scikit-learn',
  'python-script': '/quickstart/python-script',
};

/**
 * The shortcut values are hardcoded for now, so we can do the mapping and sorting
 * one time outside of React lifecycle
 */
const shortcuts = Object.entries({
  ...supportedRoutes,
  ...quickStartMap,
})
  .map(
    ([key, value]): ShortcutResult => ({
      id: `shortcut-${key}`,
      key,
      resultType: ResultTypes.Navigation,
      to: value,
    })
  )
  .sort((a, b) => a.to.localeCompare(b.to));

type ShortcutResult = {
  id: string;
  key: string;
  resultType: ResultTypes.Navigation;
  to: string;
};

export const useShortcuts = (searchTerm: string) =>
  shortcuts.filter(
    term =>
      searchTerm === '/' ||
      term.to.startsWith(searchTerm) ||
      term.key.startsWith(searchTerm)
  );
