import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import styled from 'styled-components';

export const HideRunSetsIcon = styled(WBIcon)`
  font-size: 28px;
  padding: 2px 0 0 4px;
  color: ${globals.gray500};
  cursor: pointer;
  z-index: 200;
  &:hover {
    color: darken(${globals.gray500}, 12%);
  }
`;
