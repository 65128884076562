import * as _ from 'lodash';
import React, {useCallback} from 'react';
import produce from 'immer';
import {Checkbox, Button} from 'semantic-ui-react';
import styled, {css} from 'styled-components';

import * as CG from '@wandb/cg';

import * as Panel2 from './panel';
import {
  ChildPanelConfig,
  ChildPanel,
  ChildPanelFullConfig,
  getChildPanelConfig,
  ChildPanelConfigComp,
  nextPanelName,
  getFullChildPanel,
} from './ChildPanel';
import {PanelContextProvider} from './PanelContext';
import {usePanelContext} from './PanelContext';
import * as ConfigPanel from './ConfigPanel';
import {getPanelStacksForType} from './availablePanels';

export interface PanelGroup2Config {
  layered?: boolean;
  preferHorizontal?: boolean;
  equalSize?: boolean;
  style?: string;
  items: {[key: string]: ChildPanelConfig};
}

export const PANEL_GROUP_DEFAULT_CONFIG = (): PanelGroup2Config => ({
  equalSize: true,
  items: {},
});

const inputType = 'invalid';

type PanelGroup2Props = Panel2.PanelProps<typeof inputType, PanelGroup2Config>;

export const Group2 = styled.div<{
  layered?: boolean;
  preferHorizontal?: boolean;
  compStyle?: string;
}>`
  ${props =>
    props.layered
      ? css`
          width: 100%;
          height: 100%;
          position: relative;
        `
      : props.preferHorizontal
      ? css`
          display: flex;
          height: 100%;
          flex-direction: row;
        `
      : css`
          display: flex;
          height: 100%;
          width: 100%;
          flex-direction: column;
        `}
  ${props => props.compStyle}
`;

export const Group2Item = styled.div<{
  width?: string;
  layered?: boolean;
  preferHorizontal?: boolean;
  equalSize?: boolean;
}>`
  ${props =>
    props.layered
      ? css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        `
      : props.preferHorizontal
      ? props.width
        ? css`
            width: ${props.width};
          `
        : props.equalSize
        ? css`
            flex: 1;
          `
        : css`
            flex-grow: 1;
          `
      : props.equalSize
      ? css`
          flex: 1;
          width: 100%;
          margin-bottom 12px;
        `
      : css`
          // flex-grow: 1;
          width: 100%;
          margin-bottom: 12px;
        `}
`;

export function toWeaveType(o: any): any {
  if (o == null) {
    return 'none';
  }

  if (o.id != null && o.input_node != null) {
    // Such hacks
    const curPanelId = getPanelStacksForType(
      o.input_node != null ? o.input_node.type : 'invalid',
      o.id
    ).curPanelId;
    // Filter out 'row.' since weave python doesn't know about it
    // yet.
    if (curPanelId?.startsWith('row')) {
      return 'none';
    }

    // This is a panel...
    return {
      type: curPanelId,
      _is_object: true,
      vars: {
        type: 'typedDict',
        propertyTypes: _.mapValues(o.vars, toWeaveType),
      },
      input_node: toWeaveType(o.input_node),
      config: {
        type: curPanelId + 'Config',
        _is_object: true,
        ..._.mapValues(o.config, toWeaveType),
      },
      _renderAsPanel: toWeaveType(o.config?._renderAsPanel),
    };
  } else if (o.nodeType != null) {
    if (o.nodeType === 'const' && CG.isFunctionType(o.type)) {
      return o.type;
    }
    return {
      type: 'function',
      inputTypes: {},
      outputType: o.type,
    };
  } else if (_.isArray(o)) {
    return {
      type: 'list',
      objectType: o.length === 0 ? 'unknown' : toWeaveType(o[0]),
    };
  } else if (_.isObject(o)) {
    return {
      type: 'typedDict',
      propertyTypes: _.mapValues(o, toWeaveType),
    };
  } else if (_.isString(o)) {
    return 'string';
  } else if (_.isNumber(o)) {
    return 'number'; // TODO
  } else if (_.isBoolean(o)) {
    return 'boolean';
  }
  throw new Error('Type conversion not implemeneted for value: ' + o);
}

export function dereferenceAllVariables(obj: any, frame: CG.Frame): any {
  if (obj?.nodeType != null) {
    return CG.callFunction(obj, frame);
  } else if (_.isArray(obj)) {
    return obj.map((v: any) => dereferenceAllVariables(v, frame));
  } else if (_.isObject(obj)) {
    return _.mapValues(obj, (v: any) => dereferenceAllVariables(v, frame));
  }
  return obj;
}

export const PanelGroup2ConfigComponent: React.FC<PanelGroup2Props> = props => {
  const config = props.config ?? PANEL_GROUP_DEFAULT_CONFIG();
  const {updateConfig, updateConfig2} = props;
  const {frame, path, selectedPath} = usePanelContext();
  const newVars: {[name: string]: CG.Node} = {};

  const pathStr = path.join('.');
  const selectedPathStr = selectedPath?.join('.') ?? '';

  const handleAddChild = useCallback(() => {
    updateConfig({
      items: {
        ...config.items,
        [nextPanelName()]: {
          input_node: CG.voidNode(),
          id: 'Group2',
          vars: {},
          config: PANEL_GROUP_DEFAULT_CONFIG(),
        } as any,
      },
    });
  }, [updateConfig, config.items]);

  if (selectedPathStr === pathStr) {
    // We are selected.  Render our config
    return (
      <div>
        <ConfigPanel.ConfigOption label="Layered">
          <Checkbox
            checked={config.layered ?? false}
            onChange={(e, {checked}) => updateConfig({layered: !!checked})}
          />
        </ConfigPanel.ConfigOption>
        <ConfigPanel.ConfigOption label="Horizontal">
          <Checkbox
            checked={config.preferHorizontal ?? false}
            onChange={(e, {checked}) =>
              updateConfig({preferHorizontal: !!checked})
            }
          />
        </ConfigPanel.ConfigOption>
        <ConfigPanel.ConfigOption label="Equal size">
          <Checkbox
            checked={config.equalSize ?? false}
            onChange={(e, {checked}) => updateConfig({equalSize: !!checked})}
          />
        </ConfigPanel.ConfigOption>
        <ConfigPanel.ConfigOption label="Style">
          <ConfigPanel.TextInputConfigField
            dataTest={`style`}
            value={config.style ?? ''}
            label={''}
            onChange={(event, {value}) => {
              updateConfig({
                style: value,
              });
            }}
          />
        </ConfigPanel.ConfigOption>
        <Button fluid size="mini" onClick={handleAddChild}>
          Add child
        </Button>
      </div>
    );
  }

  // One of our descendants is selected.  Render children only
  return (
    <div>
      {_.map(config.items, (item, name) => {
        const renderedItem = (
          <div key={name}>
            <PanelContextProvider newVars={newVars}>
              <ChildPanelConfigComp
                pathEl={'' + name}
                config={item}
                updateConfig={newItemConfig =>
                  updateConfig(
                    produce(config, draft => {
                      draft.items[name] = newItemConfig;
                    })
                  )
                }
                updateConfig2={(change: (oldConfig: any) => any) => {
                  if (updateConfig2 == null) {
                    return;
                  }
                  return updateConfig2(currentConfig => {
                    currentConfig =
                      currentConfig ?? PANEL_GROUP_DEFAULT_CONFIG();
                    const fullChildPanel = getFullChildPanel(
                      currentConfig?.items?.[name]
                    );
                    const changed = change(fullChildPanel);
                    return produce(currentConfig, draft => {
                      draft.items[name] = changed;
                    });
                  });
                }}
              />
            </PanelContextProvider>
          </div>
        );

        // A hack, if there are any nodes represented in the object
        // tree, deference their variables before sending to backend.
        let fullItem: ChildPanelFullConfig = dereferenceAllVariables(
          getFullChildPanel(item),
          {
            ...frame,
            ...newVars,
          }
        ) as ChildPanelFullConfig;
        const curPanelId = getPanelStacksForType(
          fullItem.input_node != null ? fullItem.input_node.type : 'invalid',
          fullItem.id
        ).curPanelId;
        fullItem = {
          ...fullItem,
          id: fullItem.input_node != null ? curPanelId : fullItem.id,
          config: curPanelId === fullItem.id ? fullItem.config : undefined,
          _renderAsPanel: fullItem?.config?._renderAsPanel,
        } as any;

        const weaveType = toWeaveType(fullItem);
        newVars[name] = CG.constNodeUnsafe(weaveType, fullItem);

        return renderedItem;
      })}
    </div>
  );
};

export const PanelGroup2Item: React.FC<{
  item: ChildPanelConfig;
  name: string;
  config: PanelGroup2Config;
  updateConfig: (newConfig: PanelGroup2Config) => void;
  updateConfig2: (
    change: (oldConfig: PanelGroup2Config) => Partial<PanelGroup2Config>
  ) => void;
}> = ({item, name, config, updateConfig, updateConfig2}) => {
  const itemUpdateConfig = useCallback(
    (newItemConfig: any) =>
      updateConfig(
        produce(config, draft => {
          draft.items[name] = newItemConfig;
        })
      ),
    [config, name, updateConfig]
  );
  const itemUpdateConfig2 = useCallback(
    (change: (oldConfig: any) => any) => {
      if (updateConfig2 == null) {
        return;
      }
      return updateConfig2(currentConfig => {
        currentConfig = currentConfig ?? PANEL_GROUP_DEFAULT_CONFIG();
        const fullItem = getFullChildPanel(currentConfig?.items?.[name]);
        const changed = change(fullItem);
        return produce(currentConfig, draft => {
          draft.items[name] = changed;
        });
      });
    },
    [name, updateConfig2]
  );
  return (
    <ChildPanel
      config={item}
      updateConfig={itemUpdateConfig}
      updateConfig2={itemUpdateConfig2}
    />
  );
};

export const PanelGroup2: React.FC<PanelGroup2Props> = props => {
  const config = props.config ?? PANEL_GROUP_DEFAULT_CONFIG();
  const {updateConfig, updateConfig2} = props;

  if (updateConfig2 == null) {
    // For dev only
    throw new Error('PanelGroup2 requires updateConfig2');
  }
  const frame = usePanelContext().frame;
  const newVars: {[name: string]: CG.Node} = {};
  return (
    <Group2
      layered={config.layered}
      preferHorizontal={config.preferHorizontal}
      compStyle={config.style}>
      {_.map(config.items, (item, name) => {
        // Hacky: pull width up from child to here.
        // TODO: fix
        const childPanelConfig = getChildPanelConfig(item);
        let width: string | undefined;
        if (childPanelConfig?.style != null) {
          const styleItems: string[] = childPanelConfig.style.split(';');
          const widthItem = styleItems.find(i => i.includes('width'));
          width = widthItem?.split(':')[1];
        }

        const renderedItem = (
          <Group2Item
            key={name}
            width={width}
            layered={config.layered}
            preferHorizontal={config.preferHorizontal}
            equalSize={config.equalSize}>
            <PanelContextProvider newVars={newVars}>
              <PanelGroup2Item
                item={item}
                name={name}
                config={config}
                updateConfig={updateConfig}
                updateConfig2={updateConfig2}
              />
            </PanelContextProvider>
          </Group2Item>
        );

        // A hack, if there are any nodes represented in the object
        // tree, deference their variables before sending to backend.
        let fullItem: ChildPanelFullConfig = dereferenceAllVariables(
          getFullChildPanel(item),
          {
            ...frame,
            ...newVars,
          }
        ) as ChildPanelFullConfig;
        const curPanelId = getPanelStacksForType(
          fullItem.input_node != null ? fullItem.input_node.type : 'invalid',
          fullItem.id
        ).curPanelId;
        fullItem = {
          ...fullItem,
          id: fullItem.input_node != null ? curPanelId : fullItem.id,
          config: curPanelId === fullItem.id ? fullItem.config : undefined,
          _renderAsPanel: fullItem?.config?._renderAsPanel,
        } as any;

        const weaveType = toWeaveType(fullItem);
        newVars[name] = CG.constNodeUnsafe(weaveType, fullItem);

        return renderedItem;
      })}
    </Group2>
  );
};

export const Spec: Panel2.PanelSpec = {
  hidden: true,
  id: 'Group2',
  Component: PanelGroup2,
  ConfigComponent: PanelGroup2ConfigComponent,
  inputType,
};
