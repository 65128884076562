import {TargetBlank} from '@wandb/common/util/links';
import {diffInDays} from '@wandb/common/util/time';
import React, {memo, useContext, useEffect} from 'react';

import {
  TeamOrgQueryResult,
  useCurrentTeamOrg,
  useShowNudgeBar,
} from '../util/nudgeBars';
import {CONTACT_SALES_CLICKED_ANALYTICS} from '../util/pricing';
import {maybePluralizeWord} from '../util/uihelpers';
import * as urls from '../util/urls';
import {AggressiveRedirectEnforcement} from './AggressiveRedirectEnforcement';
import {
  DISABLED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE,
  GlobalNudgeBarContext,
  GlobalNudgeBarState,
  NudgeBarType,
} from './GlobalNudgeBarContextProvider';
import {
  NudgeBarLarge,
  NudgeBarLargeActionButton,
  NudgeBarLargeActionLink,
  NudgeBarLargeText,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

const DisabledSubscriptionEnforcementComp = () => {
  const {states} = useContext(GlobalNudgeBarContext);

  const currentTeamOrg: TeamOrgQueryResult | null = useCurrentTeamOrg();
  const showNudgeBar = useShowNudgeBar();

  // no state means that this viewer isn't in any organization that should be nudged or enforced
  if (states == null || states.length === 0) {
    return null;
  }

  // on /usage or /subscriptions page, we don't show nudge bar
  if (!showNudgeBar) {
    return null;
  }

  const currentOrganizationStates = states?.filter(
    state =>
      state.organization?.name === currentTeamOrg?.entity?.organization?.name
  );

  // organizations without state are assumed to be in good standing and thus don't need a nudge bar
  if (currentOrganizationStates == null) {
    return null;
  }

  const disabledState = currentOrganizationStates.find(
    state =>
      state.renderedNudgeBar === NudgeBarType.DisabledSubscriptionEnforcement
  );

  // this organization's subscription is not disabled and thus don't need a nudge bar
  if (disabledState == null) {
    return null;
  }

  // TODO: remove this if statement to show nudge bar after the enforcement
  // since this nudge bar is just a temporary heads-up that we are starting to enforce soon
  if (new Date() < DISABLED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE) {
    return <DisabledNudgeBar disabledState={disabledState} />;
  }

  return (
    <AggressiveRedirectEnforcement
      analyticsLocation="disabled subscription enforcement"
      organizationName={currentTeamOrg?.entity.organization?.name}
      entityName={currentTeamOrg?.entity.name}
    />
  );
};

// TODO: Remove this subcomponents after the enforcement since this is temporary nudge bar
// to give heads-up to users we are starting enforcement.
type DisabledNudgeBarProps = {
  disabledState: GlobalNudgeBarState;
};

const DisabledNudgeBar = ({disabledState}: DisabledNudgeBarProps) => {
  useEffect(() => {
    if (disabledState == null) {
      return;
    }

    window.analytics?.track('Contact Us Banner Displayed', {
      location: 'pre enforcement disabled subscription nudge bar',
      context: disabledState.renderedNudgeBar,
      org: disabledState.organization?.id,
      orgName: disabledState.organization?.name,
    });
    // disabling the lint check since we only want to
    // send analytics once when this component first mounts
    // eslint-disable-next-line
  }, []);

  if (disabledState == null) {
    return null;
  }

  const daysUntilEnforcement = diffInDays(
    DISABLED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE,
    new Date()
  );

  const title = (
    <NudgeBarLargeTitle data-test="nudge-bar-title">
      Your organization’s subscription is disabled.
    </NudgeBarLargeTitle>
  );
  const text = (
    <NudgeBarLargeText data-test="nudge-bar-contact-us-text">
      Access to your {disabledState.organization?.name} team will discontinue in{' '}
      {daysUntilEnforcement} {maybePluralizeWord(daysUntilEnforcement, 'day')}.
      Please contact us to upgrade your subscription and continue using W&B
      without interruption.
    </NudgeBarLargeText>
  );
  const action = (
    <TargetBlank
      href={urls.contactSalesPricing()}
      onClick={() => {
        window.analytics?.track(CONTACT_SALES_CLICKED_ANALYTICS, {
          location: 'disabled subscription enforcement',
          org: disabledState.organization?.id,
          orgName: disabledState.organization?.name,
        });
      }}>
      <NudgeBarLargeActionLink data-test="nudge-bar-contact-us-button">
        <NudgeBarLargeActionButton>Contact us</NudgeBarLargeActionButton>
      </NudgeBarLargeActionLink>
    </TargetBlank>
  );

  return (
    <NudgeBarLarge data-test="disabled-nudge-bar">
      {title}
      {text}
      {action}
    </NudgeBarLarge>
  );
};

export const DisabledSubscriptionEnforcement = memo(
  DisabledSubscriptionEnforcementComp
);
