import React from 'react';

import {RESULTS_LIMIT} from './constants';
import {LocalSearchResultItem} from './LocalSearchResultItem';
import {useSearchContext} from './SearchContext';

export interface ContributedResultsListProps {
  blurAndClear: () => void;
  setShowAllContributedResults: (value: boolean) => void;
  showAllContributedResults: boolean;
}

export const ContributedResultsList = ({
  blurAndClear,
  setShowAllContributedResults,
  showAllContributedResults,
}: ContributedResultsListProps) => {
  const {
    currentQuery,
    displayedResults,
    hiddenResultsCount,
    results,
    selectedIndex,
  } = useSearchContext();

  return (
    <>
      {displayedResults.contributed.map((result, index) => (
        <LocalSearchResultItem
          key={result.name + '|' + result.meta}
          onClick={blurAndClear}
          result={result}
          query={currentQuery}
          selected={selectedIndex === displayedResults.entity.length + index}
        />
      ))}
      {!showAllContributedResults &&
        results.contributed.length > RESULTS_LIMIT && (
          <div
            className="show-more"
            onClick={() => {
              setShowAllContributedResults(true);
            }}>
            {hiddenResultsCount.contributed} more contributed result
            {hiddenResultsCount.contributed === 1 ? '' : 's'} ➞
          </div>
        )}
    </>
  );
};
