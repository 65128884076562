import * as _ from 'lodash';

import * as Filter from '../../util/filters';
import * as QueryTS from '../../util/queryts';
import * as RunUtil from '../../util/runs';
import * as Types from './types';

export interface QueryToRunsDataQueryParams {
  selectionsAsFilters?: boolean;
  mergeFilters?: Filter.Filter<RunUtil.Key>;
  disableGrouping?: boolean;
}

export function toRunsDataQuery(
  query: QueryTS.Query,
  params?: QueryToRunsDataQueryParams,
  attrs?: Partial<Types.Query>
): Types.Query {
  let queries: QueryTS.SingleQuery[] = [];
  const inParams = params || {};
  if (query.runSets == null || query.runSets.length === 0) {
    queries = [_.cloneDeep(query)];
    if (inParams.selectionsAsFilters && query.selections != null) {
      queries[0].filters = Filter.And([query.filters, query.selections]);
    }
  } else {
    queries = query.runSets
      .filter(rs => rs.enabled)
      .map(rs => {
        let entityName = rs.entityName;
        if (!entityName) {
          entityName = query.entityName;
        }
        let projectName = rs.projectName;
        if (!projectName) {
          projectName = query.projectName;
        }
        const filterClauses = [query.filters, rs.filters];
        if (inParams.mergeFilters) {
          filterClauses.push(inParams.mergeFilters);
        }
        const filters = Filter.And(filterClauses);

        const selectionClauses: Array<Filter.Filter<RunUtil.Key>> = [];
        if (query.selections) {
          selectionClauses.push(query.selections);
        }
        if (rs.selections) {
          selectionClauses.push(rs.selections);
        }
        let selections: Filter.Filter<RunUtil.Key> = Filter.MATCH_NONE_FILTER;
        if (selectionClauses.length > 0) {
          selections = Filter.And(selectionClauses);
        }

        return {
          entityName,
          projectName,
          id: rs.id,
          name: rs.name,
          filters: inParams.selectionsAsFilters
            ? Filter.And([filters, selections])
            : filters,
          selections: inParams.selectionsAsFilters
            ? Filter.MATCH_NONE_FILTER
            : selections,
          grouping: inParams.disableGrouping ? [] : rs.grouping,
          sort: rs.sort,
        };
      });
  }
  return {
    entityName: query.entityName,
    projectName: query.projectName,
    runName: query.runName,
    displayName: query.displayName,
    keysLoading: query.keysLoading || false,
    queries,
    pollInterval: query.pollInterval,
    configKeys: query.configKeys,
    summaryKeys: query.summaryKeys,
    wandbKeys: query.wandbKeys,
    ...attrs,
  };
}
