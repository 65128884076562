import {getCookieBool, setCookie, unsetCookie} from '@wandb/common/util/cookie';
import gql from 'graphql-tag';
import React, {useCallback, useContext, useState} from 'react';
import {useLocation} from 'react-router';

import {useContactUsForComputeHoursMutation} from '../generated/graphql';
import {StandardPlanCheckoutModal} from '../pages/Billing/Checkout/StandardPlanCheckoutModal';
import {
  getRouteMatch,
  hasEntityNameRouteParameter,
  isUsagePath,
} from '../routes/utils';
import {useQuery} from '../state/graphql/query';
import {AggressiveRedirectEnforcement} from './AggressiveRedirectEnforcement';
import * as S from './ComputeHourNudgeBar.styles';
import {
  GlobalNudgeBarContext,
  GlobalNudgeBarUpdaterContext,
  NudgeBarType,
} from './GlobalNudgeBarContextProvider';
import {
  NudgeBarLarge,
  NudgeBarLargeAction,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

const COMPUTE_HOUR_NUDGE_COOKIE_NAME = 'hide compute hour nudge';

const TEAM_ORG_QUERY = gql`
  query TeamOrg($entityName: String!) {
    entity(name: $entityName) {
      id
      organization {
        id
        name
      }
    }
  }
`;

interface TeamOrgQueryResult {
  entity: {
    id: string;
    organization?: {
      id: string;
      name: string;
    };
  };
}

interface TeamOrgQueryVariables {
  entityName: string;
}

const ComputeHourNudgeBar: React.FC = React.memo(() => {
  const {states} = useContext(GlobalNudgeBarContext);
  const {refetch} = useContext(GlobalNudgeBarUpdaterContext);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);

  const [contactUs] = useContactUsForComputeHoursMutation();
  const onContactUsClicked = useCallback(async () => {
    setCookie(COMPUTE_HOUR_NUDGE_COOKIE_NAME, 'true');

    try {
      await contactUs();
    } catch (err) {
      console.error(`Error contacting sales for tracked hours: ${err}`);
      unsetCookie(COMPUTE_HOUR_NUDGE_COOKIE_NAME);
    }
  }, [contactUs]);

  const location = useLocation();
  const onEntityPage = hasEntityNameRouteParameter(location.pathname);
  const onUsagePage = isUsagePath(location.pathname);
  const routeMatch = getRouteMatch(location.pathname);
  const params = routeMatch?.params as TeamOrgQueryVariables | undefined;
  const entityName = params?.entityName;

  const teamOrgQuery = useQuery<TeamOrgQueryResult, TeamOrgQueryVariables>(
    TEAM_ORG_QUERY,
    {
      variables: {
        entityName: entityName as string,
      },
      skip: !entityName || onUsagePage,
    }
  );

  if (
    !onEntityPage ||
    onUsagePage ||
    teamOrgQuery.loading ||
    !teamOrgQuery.entity.organization
  ) {
    return null;
  }

  const currentOrg = teamOrgQuery.entity.organization.name;

  if (states == null || states.length === 0) {
    return null;
  }

  const showBanner = states.some(
    state =>
      state?.organization?.name === currentOrg &&
      (state.renderedNudgeBar === NudgeBarType.StandardComputeHours ||
        state.renderedNudgeBar === NudgeBarType.AdvancedComputeHours)
  );

  if (!showBanner) {
    return null;
  }

  const aggressive = states.some(
    state => state?.organization?.name === currentOrg && state.aggressive
  );

  if (aggressive) {
    return (
      <AggressiveRedirectEnforcement
        analyticsLocation="tracked hour nudge bar"
        entityName={entityName}
        organizationName={currentOrg}
      />
    );
  }

  const alreadyContacted = getCookieBool(COMPUTE_HOUR_NUDGE_COOKIE_NAME);
  const contactSalesContent = !alreadyContacted ? (
    <NudgeBarLargeAction>
      <NudgeBarLargeActionLink onClick={onContactUsClicked}>
        Contact sales
      </NudgeBarLargeActionLink>
    </NudgeBarLargeAction>
  ) : (
    <NudgeBarLargeTitle>
      Our sales team will reach out over email to help you upgrade.
    </NudgeBarLargeTitle>
  );

  return (
    <>
      {states.map((state, i) => {
        const showUpgradeButton =
          state.renderedNudgeBar !== NudgeBarType.AdvancedComputeHours &&
          !state.organization?.flags?.noContact;
        const shouldShowUpgradeBar =
          (state.renderedNudgeBar === NudgeBarType.AdvancedComputeHours ||
            state.renderedNudgeBar === NudgeBarType.StandardComputeHours) &&
          state.organization;

        window.analytics?.track('Upgrade Subscription Banner Displayed', {
          context: state.renderedNudgeBar,
          location: 'tracked hour nudge bar',
          org: state.organization?.id,
        });

        return (
          shouldShowUpgradeBar && (
            <NudgeBarLarge key={i} data-test="tracked-hour-nudge-bar">
              <S.UpgradeMessage shiftVertically={showUpgradeButton}>
                <NudgeBarLargeTitle>
                  Your organization {state.organization?.name} has used up your{' '}
                  {state.renderedNudgeBar ===
                    NudgeBarType.StandardComputeHours && 'free'}{' '}
                  {state.maxHours} tracked hours.{' '}
                </NudgeBarLargeTitle>
                {showUpgradeButton ? (
                  <NudgeBarLargeAction>
                    <NudgeBarLargeActionLink
                      onClick={() => {
                        window.analytics?.track(
                          'banner upgrade plan button clicked',
                          {
                            org: state.organization?.id,
                            location: 'tracked hour nudge bar',
                          }
                        );
                        setCheckoutModalOpen(true);
                      }}>
                      Upgrade
                    </NudgeBarLargeActionLink>
                  </NudgeBarLargeAction>
                ) : (
                  contactSalesContent
                )}
              </S.UpgradeMessage>

              {checkoutModalOpen && state.organization && (
                <StandardPlanCheckoutModal
                  org={state.organization}
                  onTransactionCompleted={refetch}
                  onClose={() => setCheckoutModalOpen(false)}
                />
              )}
            </NudgeBarLarge>
          )
        );
      })}
    </>
  );
});

export default ComputeHourNudgeBar;
