import {unsetCookie} from '@wandb/common/util/cookie';
import React, {useEffect, useState} from 'react';

import {USE_VIEW_AS_COOKIE_KEY, viewingAs} from '../util/admin';
import NudgeBar from './NudgeBar';

const ImpersonatingNudgeBar: React.FC = React.memo(() => {
  const [showNudge, setShowNudge] = useState<boolean>(false);
  const mainText =
    'You are currently impersonating [' + viewingAs() + '] as view-only.';
  const onNudgeDismiss = () => {
    unsetCookie(USE_VIEW_AS_COOKIE_KEY);
    setShowNudge(false);
    window.location.reload();
  };

  useEffect(() => {
    setShowNudge(viewingAs() !== '');
  }, []);
  return (
    <>
      {showNudge && (
        <NudgeBar
          content={mainText}
          trackingLocation={'impersonating nudge bar'}
          buttonText={'Stop impersonating'}
          onClickButton={onNudgeDismiss}
          showDismissOption={true}
          onClose={onNudgeDismiss}
          bgcolor={'#9377b8'}></NudgeBar>
      )}
    </>
  );
});

export default ImpersonatingNudgeBar;
