import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import styled, {css} from 'styled-components';

import {REPORT_FONT_SIZE} from '../constants';

export const DeepLink = styled(WBIcon).attrs({
  name: 'link',
})`
  position: absolute;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  width: 24px;
  height: 100%;
  left: -46px;
  padding-left: 4px;
  &::before {
    padding-top: 4px;
    width: 18px;
    height: 18px;
  }
  &:hover {
    color: black;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeadingCollapser = styled(WBIcon).attrs({
  name: 'chevron-expanded',
})<{$collapsed: boolean}>`
  position: absolute;
  font-size: 22px;
  color: #999;
  cursor: pointer;
  left: -24px;
  &::before {
    width: 22px;
    height: 22px;
  }
  ${props =>
    props.$collapsed &&
    css`
      &::before {
        display: block;
        transform: rotate(-90deg);
      }
    `}
  &:hover {
    color: black;
  }
  && {
    width: 24px;
    height: 100%;
  }
`;

export const CopyLinkIcon = styled(WBIcon).attrs({
  name: 'link',
})`
  color: ${globals.gray500};
  position: absolute;
  left: -42px;
  cursor: pointer;
  &:hover {
    color: ${globals.black};
  }
  && {
    height: 28px;
    font-size: ${REPORT_FONT_SIZE}px;
  }
  visibility: hidden;
`;

export const commonHeadingStyles = css`
  position: relative;
  font-family: Source Serif Pro;
  &:hover ${CopyLinkIcon} {
    visibility: visible;
  }
`;

/** Our SEO consultant says multiple h1's hurt SEO, so secretly downlevel all headers */
export const HeadingOne = styled.h2`
  font-size: ${Math.round(REPORT_FONT_SIZE * 2)}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.5)}px !important;
  margin-top: ${Math.round(REPORT_FONT_SIZE * 1.9)}px !important;
  ${HeadingCollapser}, ${DeepLink} {
    padding-top: ${REPORT_FONT_SIZE * 0.42}px;
  }
  ${CopyLinkIcon} {
    padding-top: ${REPORT_FONT_SIZE * 0.48}px;
  }
  ${commonHeadingStyles}
`;
export const HeadingTwo = styled.h3`
  font-size: ${Math.round(REPORT_FONT_SIZE * 1.5)}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.4)}px !important;
  margin-top: ${Math.round(REPORT_FONT_SIZE * 1.4)}px !important;
  ${HeadingCollapser}, ${DeepLink} {
    padding-top: ${REPORT_FONT_SIZE * 0.25}px;
  }
  ${CopyLinkIcon} {
    padding-top: ${REPORT_FONT_SIZE * 0.3}px;
  }
  ${commonHeadingStyles}
`;
export const HeadingThree = styled.h4`
  font-size: ${Math.round(REPORT_FONT_SIZE * 1.3)}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.4)}px !important;
  margin-top: ${Math.round(REPORT_FONT_SIZE * 1.1)}px !important;
  ${HeadingCollapser}, ${DeepLink} {
    padding-top: ${REPORT_FONT_SIZE * 0.15}px;
  }
  ${CopyLinkIcon} {
    padding-top: ${REPORT_FONT_SIZE * 0.2}px;
  }
  ${commonHeadingStyles}
`;
export const HeadingFour = styled.h5`
  font-size: ${Math.round(REPORT_FONT_SIZE * 1)}px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.4)}px !important;
  margin-top: ${Math.round(REPORT_FONT_SIZE * 0.9)}px;
`;
