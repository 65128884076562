import {notEmpty} from '@wandb/cg';
import makeComp from '@wandb/common/util/profiler';
import React, {FC, useMemo} from 'react';
import {Mutation} from 'react-apollo';
import {Link} from 'react-router-dom';
import {Checkbox, Divider, Dropdown} from 'semantic-ui-react';

import {
  UPDATE_ENTITY,
  UpdateEntityMutationData,
  UpdateEntityMutationVariables,
} from '../../graphql/users';
import ProjectAccess from '../ProjectAccess';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsProjectDefaults: FC = makeComp(
  () => {
    const {viewer, refetch, updateUserInfo} = useUserSettingsContext();
    const teamEdges = useMemo(
      () => viewer?.teams.edges.filter(notEmpty) ?? [],
      [viewer]
    );

    const teamOptions = useMemo(
      () =>
        teamEdges.map(e => ({
          text: e.node.name,
          value: e.node.name,
        })),
      [teamEdges]
    );

    const defaultTeam = useMemo(() => {
      if (viewer == null) {
        return undefined;
      }
      const defaultTeamEdge = teamEdges.find(
        e => e.node.name === viewer.entity
      );
      return defaultTeamEdge?.node;
    }, [teamEdges, viewer]);

    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }

    const userEntity = viewer.userEntity;
    const userEntityNotDefault = viewer.entity !== userEntity.name;

    return (
      <>
        {teamOptions.length > 1 && (
          <>
            <div className="defaults-wrapper">
              Default location to create new projects
              <Dropdown
                options={teamOptions}
                value={defaultTeam ? defaultTeam.name : undefined}
                direction="left"
                onChange={(_, {value}) => {
                  window.analytics?.track('Default Entity Updated', {
                    entityName: userEntity.name,
                    location: 'settings page',
                  });
                  updateUserInfo?.({
                    variables: {
                      defaultEntity: value as string,
                    },
                  }).then(() => refetch?.());
                }}
              />
            </div>
            {!userEntityNotDefault && <Divider />}
          </>
        )}
        {userEntityNotDefault && (
          <div className="defaults-wrapper">
            <p className="description">
              Below are the default settings for new projects you create in your
              personal account. To view your organization's settings, go to your{' '}
              <Link to={`/teams/${viewer.entity}`}>team settings page</Link>.
            </p>
          </div>
        )}
        <Mutation<UpdateEntityMutationData, UpdateEntityMutationVariables>
          mutation={UPDATE_ENTITY}
          onCompleted={refetch}>
          {updateEntity => (
            <>
              <div className="defaults-wrapper">
                Default project privacy in your personal account
                <ProjectAccess
                  project={{
                    access: userEntity.defaultAccess,
                    entity: {
                      id: userEntity.id,
                      name: userEntity.name,
                      readOnlyAdmin: false,
                      defaultAccess: userEntity.defaultAccess,
                      privateOnly: false,
                      isTeam: userEntity.isTeam,
                    },
                  }}
                  updateProjectAccess={(access: string) => {
                    updateEntity({
                      variables: {
                        entityName: userEntity.name,
                        defaultAccess: access,
                      },
                    });
                  }}
                />
              </div>
              <Divider />
              <div className="defaults-wrapper">
                Enable code saving in your personal account
                <Checkbox
                  style={{marginLeft: 'auto'}}
                  toggle
                  checked={userEntity.codeSavingEnabled === true}
                  data-test="code-save-checkbox"
                  onChange={() =>
                    updateEntity({
                      variables: {
                        entityName: userEntity.name,
                        codeSavingEnabled: !userEntity.codeSavingEnabled,
                      },
                    }).then(refetch)
                  }
                />
              </div>
            </>
          )}
        </Mutation>
      </>
    );
  },
  {id: 'UserSettingsProjectDefaults', memo: true}
);
