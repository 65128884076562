import {isValidEmail} from '@wandb/cg';
import React, {FC, useCallback, useContext, useState} from 'react';
import {toast} from 'react-toastify';

import {
  useCreateAccessTokenMutation,
  useUpdateAccessTokenMutation,
} from '../../generated/graphql';
import {propagateErrorsContext} from '../../util/errors';
import {useReportProjects} from '../../util/report';
import * as S from './ShareReportTrigger.styles';
import {ShareReportTriggerContext} from './ShareReportTriggerContextProvider';

export const ShareInputSection: FC = () => {
  const {
    canChangeSharingSettings,
    currentIndividualSharingAccessToken,
    currentAccessToken,
    isPrivateAccess,
    updateViewAccessToken,
    viewRef,
    view,
  } = useContext(ShareReportTriggerContext);

  const projectSpecifiers = useReportProjects(viewRef);
  const [createAccessToken] = useCreateAccessTokenMutation({
    variables: {
      projects: projectSpecifiers,
      viewId: view.id!,
    },
    context: propagateErrorsContext(),
  });
  const [updateAccessToken] = useUpdateAccessTokenMutation({
    context: propagateErrorsContext(),
  });

  const [
    shareWithIndividualsInputFocused,
    setShareWithIndividualsInputFocused,
  ] = useState(false);
  const [shareWithIndividualInput, setShareWithIndividualInput] = useState('');
  const [isInvalidEmailInput, setIsInvalidEmailInput] = useState(false);

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setShareWithIndividualInput(value);
    if (isInvalidEmailInput === true) {
      setIsInvalidEmailInput(!isValidEmail(value));
    }
  };

  const handleClickShareWithIndividual = useCallback(async () => {
    if (shareWithIndividualInput === '') {
      return;
    }
    let result;
    let mutationName = 'createAccessToken';
    const isEmailAddress = isValidEmail(shareWithIndividualInput);
    try {
      if (currentIndividualSharingAccessToken == null) {
        result = await createAccessToken({
          variables: {
            projects: projectSpecifiers,
            viewId: view.id!,
            [isEmailAddress ? 'email' : 'username']: shareWithIndividualInput,
          },
        });
      } else {
        if (isEmailAddress) {
          if (
            currentIndividualSharingAccessToken?.emails.includes(
              shareWithIndividualInput
            )
          ) {
            toast(`Already shared with ${shareWithIndividualInput}`);
            setShareWithIndividualInput('');
            return;
          }
        }
        mutationName = 'updateAccessToken';
        result = await updateAccessToken({
          variables: {
            token: currentIndividualSharingAccessToken.token,
            [isEmailAddress ? 'email' : 'username']: shareWithIndividualInput,
          },
        });
      }
      const accessToken = (result?.data as any)?.[mutationName]?.accessToken;
      if (accessToken != null) {
        if (
          (result?.data as any)?.[mutationName]?.recipientAlreadyOnTeam === true
        ) {
          toast(
            `${shareWithIndividualInput} is already a member of this team, so can already view this report. An email has been sent to them linking to this report.`
          );
        } else {
          updateViewAccessToken(accessToken);
          toast(`Shared with ${shareWithIndividualInput}`);
        }
        setShareWithIndividualInput('');
        window.analytics?.track?.('Invite to View Report Submitted', {
          reportId: view.cid,
          invitedUserEmail: shareWithIndividualInput,
          newUser: (result?.data as any)?.[mutationName]?.toNewUser,
        });
      }
    } catch (err) {
      const errorMsg: string =
        (err as any)?.networkError?.result?.errors?.[0]?.message ??
        'An error occurred - please check that the email/username is valid';
      toast(errorMsg);
      return;
    }
  }, [
    createAccessToken,
    updateAccessToken,
    currentIndividualSharingAccessToken,
    projectSpecifiers,
    view,
    shareWithIndividualInput,
    updateViewAccessToken,
  ]);

  const textInputLabelWithTooltip = (
    <S.Tooltip
      on="hover"
      inverted
      position="top center"
      size="mini"
      trigger={<S.TextInputLabel>can view</S.TextInputLabel>}
      content="The recipient will need to log in with this email &#xa;to view the report. You can use the magic link &#xa;to let anyone view without logging in first."
    />
  );

  return (
    <S.ShareInputSection>
      <S.TextInput
        onFocus={() => {
          setShareWithIndividualsInputFocused(true);
        }}
        onBlur={() => {
          setShareWithIndividualsInputFocused(false);
        }}
        autoFocus
        onChange={handleEmailInputChange}
        value={shareWithIndividualInput}
        placeholder="Email or username"
        label={textInputLabelWithTooltip}
        labelPosition="right"
        error={isInvalidEmailInput}
        disabled={
          currentAccessToken == null &&
          !canChangeSharingSettings &&
          isPrivateAccess
        }
        focused={shareWithIndividualsInputFocused}
      />
      <S.ShareButton
        primary
        onClick={handleClickShareWithIndividual}
        disabled={
          isInvalidEmailInput ||
          (currentAccessToken == null &&
            !canChangeSharingSettings &&
            isPrivateAccess)
        }>
        Share
      </S.ShareButton>
    </S.ShareInputSection>
  );
};
