import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {immerReducer} from '../reducer';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as Types from './types';

export type ActionType = TSActionType<typeof Actions | typeof ActionsInternal>;

function reports(draft: Types.StateType, action: ActionType) {
  switch (action.type) {
    case getType(ActionsInternal.loadFinished): {
      draft.viewRefs[action.payload.id] = action.payload.viewID;
      break;
    }
    case getType(ActionsInternal.unloadReport): {
      delete draft.viewRefs[action.payload.reportID];
      break;
    }
    case getType(ActionsInternal.pushReport): {
      // it seems like this is never hit
      draft.viewRefs[action.payload.id] = {
        type: Types.REPORT_VIEW_TYPE,
        id: action.payload.id,
      };
      draft.pushedRef = action.payload.id;
      break;
    }
    case getType(ActionsInternal.clearPushedReport): {
      draft.pushedRef = null;
      break;
    }
  }
}

export default immerReducer<Types.StateType, ActionType>(reports, {
  viewRefs: {},
  pushedRef: null,
});
