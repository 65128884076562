import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {useEffectExceptFirstRender} from '@wandb/common/util/hooks';
import {isEqual} from 'lodash';
import React, {useCallback, useState} from 'react';
import {Button, Checkbox, Popup} from 'semantic-ui-react';

import {usePrevious} from '../../state/hooks';
import * as FilterActions from '../../state/views/filter/actions';
import * as FilterTypes from '../../state/views/filter/types';
import {useViewAction} from '../../state/views/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as Filter from '../../util/filters';
import * as Run from '../../util/runs';
import {FilterValueSelectorDate} from '../Filters/FilterValueSelector';
import {RunsFilterTableAction} from './RunsFilters';

interface RunsFreezeTableActionProps {
  notificationOpen: boolean;
  pickerOpen: boolean;
  filtersRef: FilterTypes.Ref;
  isInReport?: boolean;
  setPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFreezeChanged(): void;
}

export const RunsFreezeTableAction = (props: RunsFreezeTableActionProps) => {
  const {notificationOpen, pickerOpen, setPickerOpen} = props;

  const rootFilter = ViewHooks.useWhole(props.filtersRef);

  const isFrozen = React.useMemo(() => {
    const freezeFilterIndex = Filter.findFreezeFilterIndex(rootFilter);
    if (freezeFilterIndex < 0) {
      return false;
    }
    const freezeFilter = rootFilter.filters[0].filters[
      freezeFilterIndex
    ] as Filter.IndividualFilter<Run.Key>;
    return !freezeFilter.disabled;
  }, [rootFilter]);

  const freezeButtonClass = pickerOpen
    ? 'action-button--focused'
    : isFrozen
    ? 'action-button--active'
    : 'action-button--static';

  return (
    <Popup
      open={notificationOpen}
      disabled={pickerOpen}
      inverted
      position="bottom left"
      pinned
      style={{top: 24, marginLeft: -6, padding: '4px 8px', cursor: 'pointer'}}
      content={'Run set frozen'}
      trigger={
        // div is necessary for nested popups (hover and click)
        <div style={{display: 'inline', marginRight: 12}}>
          <Popup
            disabled={pickerOpen}
            content="Freeze run set"
            position="top right"
            style={{marginRight: -6, top: -3}}
            popperModifiers={{
              preventOverflow: {
                // prevent popper from erroneously constraining the popup to the
                // table header
                boundariesElement: 'viewport',
              },
            }}
            trigger={
              // div is necessary for nested popups (hover and click)
              <div style={{display: 'inline'}}>
                <Popup
                  basic
                  className="wb-table-action-popup"
                  on="click"
                  position="bottom left"
                  open={pickerOpen}
                  onOpen={() => setPickerOpen(true)}
                  onClose={() => setPickerOpen(false)}
                  trigger={
                    <Button
                      size="tiny"
                      onClick={() => {
                        const actionLocation = props.isInReport
                          ? 'report'
                          : 'runs table';
                        window.analytics?.track('Clicked freeze run set', {
                          location: actionLocation,
                        });
                      }}
                      className={
                        freezeButtonClass +
                        ' wb-icon-button only-icon table-freeze-button'
                      }>
                      <LegacyWBIcon name="snowflake" title="Freeze run set" />
                    </Button>
                  }
                  content={
                    <RunsFreezePicker
                      filtersRef={props.filtersRef}
                      onFreezeChanged={props.onFreezeChanged}
                    />
                  }
                  popperModifiers={{
                    preventOverflow: {enabled: false},
                    flip: {enabled: false},
                  }}
                />
              </div>
            }
          />
        </div>
      }
    />
  );
};

interface RunsFreezePickerProps {
  filtersRef: FilterTypes.Ref;
  onFreezeChanged(): void;
}

export const RunsFreezePicker = (props: RunsFreezePickerProps) => {
  const {filtersRef, onFreezeChanged} = props;

  const rootFilter = ViewHooks.useWhole(filtersRef);
  const setFiltersAction = useViewAction(filtersRef, FilterActions.set);

  const setFilters = useCallback(
    (...args: Parameters<typeof setFiltersAction>) => {
      setFiltersAction(...args);
      onFreezeChanged();
    },
    [setFiltersAction, onFreezeChanged]
  );

  const freezeFilterIndex = React.useMemo(() => {
    return Filter.findFreezeFilterIndex(rootFilter);
  }, [rootFilter]);

  React.useEffect(() => {
    // If a 'createdAt <=' filter doesn't exist, create it
    if (freezeFilterIndex < 0) {
      setFilters(
        Filter.Update.groupPush(rootFilter, [0], {
          key: Filter.createdAtKey,
          op: '<=',
          value: new Date().toISOString(),
          disabled: true,
        })
      );
    }
  }, [freezeFilterIndex, rootFilter, setFilters]);

  const freezeFilter = React.useMemo(() => {
    return rootFilter.filters[0].filters[
      freezeFilterIndex
    ] as Filter.IndividualFilter<Run.Key>;
  }, [freezeFilterIndex, rootFilter.filters]);

  const setFreezeFilterValue = React.useCallback(
    (partialFilter: Partial<Filter.IndividualFilter<Run.Key>>) => {
      setFilters(
        Filter.Update.setFilter(rootFilter, [0, freezeFilterIndex], {
          ...freezeFilter,
          ...partialFilter,
        } as Filter.IndividualFilter<Run.Key>)
      );
    },
    [freezeFilter, freezeFilterIndex, rootFilter, setFilters]
  );

  const toggleFreezeFilterDisabled = React.useCallback(() => {
    setFilters(
      Filter.Update.setFilter(rootFilter, [0, freezeFilterIndex], {
        ...freezeFilter,
        disabled: !freezeFilter.disabled,
      } as Filter.IndividualFilter<Run.Key>)
    );
  }, [freezeFilter, freezeFilterIndex, rootFilter, setFilters]);

  if (freezeFilter == null) {
    return <div />;
  }

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{marginTop: 6, marginRight: 8}}>
          <Checkbox
            checked={!freezeFilter.disabled}
            onChange={toggleFreezeFilterDisabled}
          />
        </div>
        <div>Only include runs created before</div>
      </div>
      <div style={{marginLeft: 18, marginTop: 4}}>
        <FilterValueSelectorDate
          value={freezeFilter.value as string | null}
          setFilter={setFreezeFilterValue}
        />
      </div>
    </div>
  );
};

interface RunsFreezeAndFilterProps {
  entityName: string;
  projectName: string;
  username: string;
  enableFreezeRunset: boolean;
  filtersRef: FilterTypes.Ref;
  isInReport?: boolean;
  compact: boolean;
  onChange(): void;
}

// Freezing runs is the same as filtering by 'createdAt <=',
// so the Freeze and Filter button components share notification state in this wrapper component
export const RunsFreezeAndFilter = (props: RunsFreezeAndFilterProps) => {
  const {
    entityName,
    projectName,
    username,
    enableFreezeRunset,
    filtersRef,
    isInReport,
    compact,
    onChange,
  } = props;

  const [freezePickerOpen, setFreezePickerOpen] = useState(false);
  const [filterPickerOpen, setFilterPickerOpen] = useState(false);

  // Show popup notification when filters are changed outside of the filter popup
  // (e.g. parcoor selections-to-filters, or adding a filter via a table cell)
  // If the new filter matches "createdAt <=", we show a 'Run set frozen' notification on the freeze button
  // Otherwise, we show a 'Filters changed' notification on the filter button
  const [notificationPopupOpen, setNotificationPopupOpen] = useState<
    'freeze' | 'filter' | false
  >(false);
  const rootFilters = ViewHooks.useWhole(filtersRef);
  const prevRootFilters = usePrevious(rootFilters);
  useEffectExceptFirstRender(() => {
    if (freezePickerOpen || filterPickerOpen) {
      return;
    }
    const isFrozen = Filter.findFreezeFilterIndex(rootFilters) >= 0;
    const prevFrozen =
      prevRootFilters && Filter.findFreezeFilterIndex(prevRootFilters) >= 0;
    if (!isEqual(prevRootFilters, rootFilters)) {
      setNotificationPopupOpen(isFrozen && !prevFrozen ? 'freeze' : 'filter');
      setTimeout(() => {
        setNotificationPopupOpen(false);
      }, 8000);
      return;
    }
  }, [freezePickerOpen, filterPickerOpen, rootFilters, prevRootFilters]);

  const defaultToggleFilters = React.useMemo(
    () => [
      {
        filter: Filter.usernameFilter(username),
        label: Filter.FILTER_LABEL_SHOW_MY_WORK,
      },
      {
        filter: Filter.hideCrashed,
        label: Filter.FILTER_LABEL_HIDE_CRASHED,
      },
    ],
    [username]
  );

  return (
    <>
      {enableFreezeRunset && (
        <RunsFreezeTableAction
          filtersRef={filtersRef}
          onFreezeChanged={onChange}
          pickerOpen={freezePickerOpen}
          isInReport={isInReport}
          setPickerOpen={setFreezePickerOpen}
          notificationOpen={notificationPopupOpen === 'freeze'}
        />
      )}
      <RunsFilterTableAction
        entityName={entityName}
        projectName={projectName}
        filtersRef={filtersRef}
        compact={compact}
        isInReport={isInReport}
        pickerOpen={filterPickerOpen}
        notificationOpen={notificationPopupOpen === 'filter'}
        setPickerOpen={setFilterPickerOpen}
        defaultToggleFilters={defaultToggleFilters}
        onFiltersChanged={onChange}
      />
    </>
  );
};
