import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import * as _ from 'lodash';
import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {Dropdown, Menu} from 'semantic-ui-react';

import * as ViewHooks from '../state/views/hooks';
import * as PanelBankConfigActions from '../state/views/panelBankConfig/actions';
import * as PanelBankConfigTypes from '../state/views/panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../state/views/panelBankSectionConfig/types';
import {PanelContext} from './Panel';

interface PanelMoverProps {
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
}

// TODO - move file into Panel folder and rename to MovePanelSubmenu

// "Move panel to section..." submenu
export const PanelMoverMenu: React.FC<PanelMoverProps> = ({
  panelBankConfigRef,
  panelBankSectionConfigRef,
}) => {
  const {panelRef} = useContext(PanelContext);

  const panelMoverRef = useRef<HTMLDivElement>(null);
  const panelBankSectionRefs =
    ViewHooks.usePart(panelBankConfigRef).sectionRefs;
  const panelBankSectionNames = ViewHooks.useWholeMapped(
    panelBankConfigRef,
    pbc => pbc.sections.map(s => s.name)
  );
  const panelMoverOptions = _.compact(
    panelBankSectionRefs.map(r => {
      const sectionIndex = _.findIndex(panelBankSectionRefs, r);
      return sectionIndex < 0 || _.isEqual(r, panelBankSectionConfigRef)
        ? undefined
        : {value: r.id, text: panelBankSectionNames[sectionIndex]};
    })
  );

  const movePanelToNewSection = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.movePanelToNewSection
  );
  const movePanel = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.movePanel
  );
  const onAddSection = useCallback(
    (newSectionName: string) => {
      movePanelToNewSection({
        fromSectionRef: panelBankSectionConfigRef,
        panelRef,
        newSectionName,
      });
    },
    [movePanelToNewSection, panelBankSectionConfigRef, panelRef]
  );
  const onChange = useCallback(
    toSectionRef => {
      movePanel(panelRef, panelBankSectionConfigRef, toSectionRef, 0);
    },
    [movePanel, panelBankSectionConfigRef, panelRef]
  );

  useEffect(() => {
    setTimeout(() => {
      panelMoverRef.current?.querySelector('input')?.focus();
    }, 1);
  }, []);

  return (
    <div className="popup-submenu" ref={panelMoverRef}>
      <Menu vertical borderless compact>
        <Menu.Item header>Move panel to section...</Menu.Item>
        <Menu.Item>
          <Dropdown
            selection
            search
            allowAdditions
            additionLabel="Create section: "
            additionPosition="bottom"
            open
            options={panelMoverOptions}
            placeholder="Find or create section..."
            selectOnNavigation={false}
            onAddItem={(e, data) => onAddSection(data.value as string)}
            selectOnBlur={false}
            onChange={(e, data) => {
              const toSectionRef = _.find(
                panelBankSectionRefs,
                s => s.id === data.value
              );
              if (toSectionRef) {
                onChange(toSectionRef);
              }
            }}
          />
        </Menu.Item>
      </Menu>
    </div>
  );
};

// The menu option for "Move panel to {default section}"
export const DefaultMovePanelOption: React.FC<{
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  toSectionRef: PanelBankSectionConfigTypes.Ref;
}> = ({panelBankConfigRef, toSectionRef}) => {
  const toSectionName = ViewHooks.useWhole(toSectionRef).name;
  const {panelBankSectionConfigRef, panelRef} = useContext(PanelContext);

  const movePanel = ViewHooks.useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.movePanel
  );
  const defaultMovePanel = () => {
    movePanel(panelRef, panelBankSectionConfigRef, toSectionRef, 0);
  };

  return (
    <Menu.Item onClick={defaultMovePanel}>
      <LegacyWBIcon name="move" style={{color: 'green'}} />
      <span style={{lineHeight: '1.2em'}}>
        Move panel to
        <br />
        <span style={{fontWeight: 600}}>
          {_.truncate(toSectionName, {length: 14})}
        </span>
      </span>
    </Menu.Item>
  );
};
