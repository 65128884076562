// graphql query for polling a runs files.

import {captureError} from '@wandb/common/util/integrations';
import gql from 'graphql-tag';
import _ from 'lodash';
import * as React from 'react';
import {Query} from 'react-apollo';

import {useAdminModeActive} from '../util/admin';

///// Simple type helpers
// Type T minus the single key K
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
// Type T minus all keys in K
type Subtract<T, K> = Omit<T, keyof K>;

///// Query definition

// This loads 2000 for the entity to make it work for OpenAI.
export const QUERY = gql`
  query ProjectPickerQuery($entityName: String!) {
    entity(name: $entityName) {
      id
      projects(first: 2000) {
        edges {
          node {
            id
            name
            entityName
            createdAt
          }
        }
      }
    }
    viewer {
      id
      projects(first: 500) {
        edges {
          node {
            id
            name
            entityName
            createdAt
          }
        }
      }
      teams(first: 100) {
        edges {
          node {
            id
            projects(first: 500) {
              edges {
                node {
                  id
                  name
                  entityName
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface ProjectData {
  id: string;
  name: string;
  entityName: string;
  createdAt: string;
}

// The query's output shape.
interface ViewerData {
  id: string;
  projects: {
    edges: Array<{
      node: ProjectData;
    }>;
  };
  teams: {
    edges: Array<{
      node: {
        id: string;
        projects: {
          edges: Array<{
            node: ProjectData;
          }>;
        };
      };
    }>;
  };
}

interface Data {
  viewer: ViewerData;
  entity: {
    projects: {
      edges: Array<{
        node: ProjectData;
      }>;
    };
  };
}

///// HOC definition

// We define two types of query results, one for the loading state and one for
// the loaded state. This way the consumer can safely check the loading prop once.
// If it's false project is guaranteed to be defined.
interface QueryResultLoadingProps {
  loading: true;
  projects: undefined;
}

interface QueryResultLoadedProps {
  loading: false;
  projects: ProjectData[];
}

// The props that will be injected into your component, as a result of the query.
// They are derived from the result of the query in the HOC.
export interface QueryResultProps {
  projectPickerQuery: QueryResultLoadedProps | QueryResultLoadingProps;
}

interface InputProps {
  entityName: string;
}

export interface ProjectPickerLoaderResults {
  isAdmin: boolean;
}

export const withQuery =
  <P extends object>(Component: React.ComponentType<P & QueryResultProps>) =>
  (
    inputProps: Subtract<P, QueryResultProps & ProjectPickerLoaderResults> &
      InputProps
  ) => {
    const variables = {entityName: inputProps.entityName};
    return (
      <Query<Data> variables={variables} query={QUERY}>
        {rawQueryResult => {
          const r: QueryResultProps = {
            projectPickerQuery: {
              loading: true,
              projects: undefined,
            },
          };
          if (!rawQueryResult.loading) {
            if (rawQueryResult.data == null) {
              captureError(
                'Unexpected Apollo error, loading: false, data: undefined',
                'projectPickerQuery',
                {extra: {rawQueryResult}}
              );
              throw new Error(
                'Unexpected Apollo error, loading: false, data: undefined'
              );
            } else {
              let projects: ProjectData[] = [];
              if (rawQueryResult.data.viewer) {
                projects = projects.concat(
                  rawQueryResult.data.viewer.projects.edges.map(e => e.node)
                );
                for (const teamEdge of rawQueryResult.data.viewer.teams.edges) {
                  projects = projects.concat(
                    teamEdge.node.projects.edges.map(e => e.node)
                  );
                }
                projects = projects.concat(
                  rawQueryResult.data.entity.projects.edges.map(e => e.node)
                );
              }
              projects = _.sortBy(projects, p => p.createdAt);
              projects = _.sortedUniqBy(projects, p => p.createdAt);
              projects = _.reverse(projects);
              r.projectPickerQuery = {
                loading: false,
                projects,
              };
            }
          }
          const isAdmin = useAdminModeActive();
          return <Component {...(inputProps as P)} {...r} isAdmin={isAdmin} />;
        }}
      </Query>
    );
  };
