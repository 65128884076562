import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import {Popup} from 'semantic-ui-react';
import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 450px;
`;

export const ProjectSection = styled.div`
  margin: 16px 0;
  width: 215px;
`;

export const ScrollableMenu = styled.div`
  margin: 20px 0;
  height: 380px;
  overflow: auto;
`;

export const ListHeader = styled.div`
  align-self: flex-start;
  padding: 4px 24px;
  text-align: left;
  font-size: 13px;
  color: ${globals.textSecondary};
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  white-space: nowrap;
  list-style-type: none;
  text-align: left;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 30px;
  color: @textColor;
`;

type ItemProps = {
  selected?: boolean;
};

export const ProjectItem = styled.li<ItemProps>`
  cursor: pointer;
  padding: 4px 24px;
  &.disabled {
    color: ${globals.disabled};
    .icon {
      color: ${globals.disabled};
    }
  }
  &:hover {
    background: ${props => (props.selected ? globals.selected : globals.hover)};
  }
  background: ${props => (props.selected ? globals.selected : '')};
`;

export const ViewAllWrapper = styled.button`
  color: ${globals.blue};
  padding: 4px 24px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const HorizontalDivider = styled.div`
  border-left: 1px solid #e5e5e5;
  height: auto;
  margin-top: 20px;
`;

export const PanelSection = styled.div`
  margin: 16px 0;
  width: 320px;
`;

export const SearchWrapper = styled.div`
  padding: 4px 18px;
`;

export const SearchPopup = styled(Popup)`
  &&&&& {
    color: ${globals.white};
    background-color: ${globals.gray800};
    max-width: 200px;
    border-radius: 5px;
    &:before {
      background-color: ${globals.gray800};
    }
  }
`;

type PanelItemProps = {
  selected?: boolean;
  hover?: boolean;
};

function getBackgroundColor(props: PanelItemProps): string {
  const {selected, hover} = props;
  if (selected && hover) {
    return globals.selectedHover;
  }
  if (selected) {
    return globals.lightSlateBlue;
  }
  if (hover) {
    return globals.hover;
  }
  return '';
}

export const PanelItem = styled.li<PanelItemProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 24px;

  &.disabled {
    color: ${globals.disabled};
    .icon {
      color: ${globals.disabled};
    }
  }
  &:hover {
    background: ${props =>
      props.selected ? globals.selectedHover : globals.hover};
  }
  ${props => css`
    ${(props.selected || props.hover) &&
    `background: ${getBackgroundColor(props)};`}
  `}
`;

export const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  &:hover {
    overflow: visible;
  }
  flex-grow: 0.6;
`;

export const CheckIconWrapper = styled.div`
  background: ${globals.blue};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const CheckIcon = styled(WBIcon)`
  color: ${globals.white};
  display: flex;
  justify-content: center;
  align-: center;
  padding: 2px;
`;
