import * as globals from '@wandb/common/css/globals.styles';
import React from 'react';
import {Modal} from 'semantic-ui-react';
import styled from 'styled-components';

import JoinMatchingOrgTeams from './JoinMatchingOrgTeams';

export const DialogHeader = styled.h2<{smallBottomMargin?: boolean}>`
  color: ${globals.gray800};
  margin: 32px 0px 24px;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;

export const ViewTeamsModal = ({
  setIsModalOpen,
  viewerEmail,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewerEmail?: string;
}) => {
  return (
    <Modal open={true} size="tiny">
      <Modal.Content>
        <DialogHeader>We found teams for you to join!</DialogHeader>
        <JoinMatchingOrgTeams
          onSkip={() => setIsModalOpen(false)}
          onSuccess={() => {
            setIsModalOpen(false);
            window.analytics?.track('AppSettings: completed joinMatchingTeams');
          }}
          maxTeamsShown={3}
          isOnboardingFlow={false}
          viewerEmail={viewerEmail}
        />
      </Modal.Content>
    </Modal>
  );
};
