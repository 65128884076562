import React, {FC, memo} from 'react';

import {Org, StripeElements, useSubscriptionPlans} from '../../../util/pricing';
import {BillingAddressContextProvider} from './BillingAddressContext';
import {CheckoutModal} from './CheckoutModal';
import {
  UpgradeSubscriptionForm,
  UpgradeSuccess,
} from './UpgradeSubscriptionForm';

type StandardPlanCheckoutModalProps = {
  org: Org;
  onTransactionCompleted: () => void;
  onClose: () => void;
};

const StandardPlanCheckoutModalComp: FC<StandardPlanCheckoutModalProps> =
  props => {
    const {org, onClose} = props;
    const {planInfo, storagePlanID, trackingPlanID} = useSubscriptionPlans();

    if (planInfo == null || storagePlanID == null || trackingPlanID == null) {
      return null;
    }

    return (
      <StripeElements>
        <CheckoutModal
          planInfo={planInfo}
          orgName={org.name}
          defaultSeats={org.usedSeats}
          onTransactionCompleted={props.onTransactionCompleted}
          onClose={onClose}
          renderForm={({plan, seats, onTransactionCompleted}) => (
            <BillingAddressContextProvider>
              <UpgradeSubscriptionForm
                plan={plan}
                seats={seats}
                storagePlanID={storagePlanID}
                trackingPlanID={trackingPlanID}
                org={org}
                onSubscriptionCompleted={onTransactionCompleted}
              />
            </BillingAddressContextProvider>
          )}
          renderSuccess={() => <UpgradeSuccess org={org} />}
        />
      </StripeElements>
    );
  };

export const StandardPlanCheckoutModal = memo(StandardPlanCheckoutModalComp);
