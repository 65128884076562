import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Editor, Node, Range, Transforms} from 'slate';
import {ReactEditor, useSlate} from 'slate-react';
import useResizeObserver from 'use-resize-observer';

import {useReportContext} from '../../../state/reports/context';
import {EditLatexWidget, isLatex} from '../plugins/latex';
import {
  EditLinkWidget,
  EditorWithLinks,
  InsertLinkWidget,
  isLink,
} from '../plugins/links';
import {
  EditMarkdownBlockWidget,
  markdownBlockIsConvertable,
} from '../plugins/markdown-blocks';
import {isMarkdownBlock} from '../plugins/markdown-blocks-common';
import {useTyping} from '../TypingContext';
import {CommentFormatButton} from './CommentFormatButton';
import {FormatButton} from './FormatButton';
import * as S from './HoveringToolbar.styles';

/**
 * All hovering toolbars/editors are simply different modes of the same toolbar,
 * to reuse its positioning and animation logic, and to ensure that only one is
 * ever open at a time.
 * 'viewing': readOnly mode
 */
export type HoveringToolbarMode = 'default' | 'link' | 'editing' | 'viewing';

export interface HoveringToolbarProps {
  className?: string;
  readOnly: boolean;
}

export const HoveringToolbar: FC<HoveringToolbarProps> = ({
  className,
  readOnly,
}) => {
  const {disableComments} = useReportContext();
  const editor = useSlate();
  const {typing} = useTyping();
  const ref = useRef<HTMLDivElement>(null);

  /** Keep track of whether the mouse is up to avoid showing the toolbar mid-selection. */
  const [mouseIsUp, setMouseIsUp] = useState(true);

  const [open, setOpen] = useState(false);

  /** The toolbar retains its position even after closing, for a clean exit animation. */
  const [position, setPosition] = useState<{x: number; y: number}>({
    x: -1000,
    y: -1000,
  });

  /** For animating up/down movement */
  const [direction, setDirection] = useState<'up' | 'down'>('up');

  /** The currently active mode; remains even after closing, for animation purposes */
  const [mode, setMode] = useState<HoveringToolbarMode>(
    readOnly ? 'viewing' : 'default'
  );

  /** The last selection that needed to be saved because the browser can only focus on one input. */
  const [savedSelection, setSavedSelection] = useState<Range | null>(null);

  /** The last node that was hovered over for long enough to start edit */
  const [editingNode, setEditingNode] = useState<Node | null>(null);

  /** The currently hovered over node; only includes hoverable nodes */
  const hoveringNode = useRef<Node | null>(null);

  const domSelection = window.getSelection();
  const {selection} = editor;

  const {width = 1, height = 1} = useResizeObserver<HTMLDivElement>({ref});

  /**
   * Navigates the toolbar to the given rect, adapting to edges of screen
   */
  const setPositionFromRect = useCallback(
    (rect: DOMRect) => {
      const el = ref.current;

      if (el == null) {
        return;
      }

      const editorDOMNode = ReactEditor.toDOMNode(editor, editor);
      const editorDOMRect = editorDOMNode.getBoundingClientRect();

      let newDirection: 'up' | 'down' = 'up';
      if (rect.top < 120) {
        newDirection = 'down';
      }

      setPosition({
        x: Math.max(
          Math.min(
            rect.left - editorDOMRect.left + rect.width / 2,
            window.innerWidth - width / 2 - 8
          ),
          width / 2 + 8
        ),
        y:
          newDirection === 'up'
            ? rect.top - editorDOMRect.top - 6
            : rect.bottom - editorDOMRect.top + 6,
      });
      setDirection(newDirection);
    },
    [editor, width]
  );

  /**
   * Open editor after hovering over an editable element
   */
  useEffect(() => {
    const editorDOMNode = ReactEditor.toDOMNode(editor, editor);

    const onMouseEnter = (e: MouseEvent) => {
      const eventTarget: HTMLElement = e.target as HTMLElement;

      // Ignore checks if mouse hovers over weave panel expression
      // Note: make sure the selector check matches the value in Panel Expression
      if (
        open ||
        !mouseIsUp ||
        eventTarget.closest('[data-test="panel-expression-expression"]') != null
      ) {
        return;
      }

      const slateNode: Node = ReactEditor.toSlateNode(editor, eventTarget);
      let matchNode: Node | null = null;

      if (isLink(slateNode) || isMarkdownBlock(slateNode)) {
        matchNode = slateNode;
      }

      if (matchNode == null) {
        const match = Editor.above(editor, {
          at: ReactEditor.findPath(editor, slateNode),
          match: n => isLink(n) || isMarkdownBlock(n),
        });

        if (match != null) {
          matchNode = match[0];
        }
      }

      if (matchNode != null && isMarkdownBlock(matchNode)) {
        if (
          (e.target as HTMLElement).closest('.inline-markdown-editor') === null
        ) {
          matchNode = null;
        }
      }

      if (matchNode == null) {
        hoveringNode.current = null;
        return;
      }

      hoveringNode.current = matchNode;

      if (
        isMarkdownBlock(matchNode) &&
        !markdownBlockIsConvertable(matchNode)
      ) {
        return;
      }

      window.setTimeout(() => {
        if (
          readOnly ||
          matchNode == null ||
          hoveringNode.current !== matchNode
        ) {
          return;
        }

        const matchDOMNode = ReactEditor.toDOMNode(editor, matchNode);
        setPositionFromRect(matchDOMNode.getBoundingClientRect());
        setOpen(true);
        setMode('editing');
        setEditingNode(matchNode);
      }, 250);
    };

    editorDOMNode.addEventListener('mouseover', onMouseEnter);

    return () => {
      editorDOMNode.removeEventListener('mouseover', onMouseEnter);
    };
  }, [
    open,
    mode,
    mouseIsUp,
    editingNode,
    editor,
    readOnly,
    setPositionFromRect,
  ]);

  /**
   * Detect when mouse is up and down
   */
  useEffect(() => {
    const editorDOMNode = ReactEditor.toDOMNode(editor, editor);

    const onMouseUp = (e: MouseEvent) => {
      // Wait for selection to clear (if you clicked on it)
      window.setTimeout(() => {
        setMouseIsUp(true);
      });
    };
    const onMouseDown = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as HTMLElement)) {
        setMouseIsUp(false);
      }
    };

    editorDOMNode.addEventListener('mouseup', onMouseUp);
    editorDOMNode.addEventListener('mousedown', onMouseDown);

    return () => {
      editorDOMNode.removeEventListener('mouseup', onMouseUp);
      editorDOMNode.removeEventListener('mousedown', onMouseDown);
    };
  }, [editor]);

  /**
   * Close on mouse down or type
   */
  useEffect(() => {
    if (!mouseIsUp || typing) {
      setOpen(false);
      return;
    }
  }, [mouseIsUp, typing]);

  /**
   * Open default menu after selecting anything
   */
  useEffect(() => {
    const shouldOpenToolbar =
      !open &&
      mouseIsUp &&
      !typing &&
      selection != null &&
      !Range.isCollapsed(selection) &&
      Editor.string(editor, selection) !== '' &&
      (readOnly || ReactEditor.isFocused(editor));

    if (shouldOpenToolbar) {
      setOpen(true);
      if (!readOnly) {
        setMode('default');
      }

      if (domSelection == null || domSelection.rangeCount === 0) {
        return;
      }

      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      setPositionFromRect(rect);
    }
  }, [
    editor,
    selection,
    mouseIsUp,
    typing,
    open,
    domSelection,
    readOnly,
    setPositionFromRect,
  ]);

  useEffect(() => {
    if (!readOnly && selection != null && Range.isCollapsed(selection)) {
      const latexEntry = Editor.above(editor, {match: n => isLatex(n)});
      if (latexEntry != null) {
        setOpen(true);
        setMode('editing');
        setEditingNode(latexEntry[0]);

        const latexDOMNode = ReactEditor.toDOMNode(editor, latexEntry[0]);
        const latexDOMRect = latexDOMNode.getBoundingClientRect();
        setPositionFromRect(latexDOMRect);
      }
    }
  }, [editor, selection, setPositionFromRect, typing, readOnly]);

  const onCommentClose = useCallback(() => {
    setOpen(false);
    // when canceling on the comment frame which should close the toolbar, it incorrectly
    // kept the selection and the toolbar open so deselect the range to close the toolbar
    Transforms.deselect(editor);
  }, [editor]);

  const left = position.x - width / 2;
  const top = position.y - (direction === 'up' ? height : 0);

  const toolbarContent = useMemo(() => {
    switch (mode) {
      case 'viewing': {
        return !disableComments ? (
          <S.DefaultWrapper>
            <CommentFormatButton onClose={onCommentClose} readOnly={readOnly} />
          </S.DefaultWrapper>
        ) : null;
      }
      case 'link': {
        if (savedSelection != null) {
          return (
            <InsertLinkWidget
              savedSelection={savedSelection}
              onCancel={() => {
                setMode('default');
                Transforms.select(editor, savedSelection);
                ReactEditor.focus(editor);
              }}
              onClose={() => {
                setOpen(false);
              }}></InsertLinkWidget>
          );
        }
        return null;
      }
      case 'editing': {
        if (editingNode == null) {
          return null;
        }
        if (isLink(editingNode)) {
          return (
            <EditLinkWidget
              linkNode={editingNode}
              direction={direction}
              onClose={() => {
                setOpen(false);
              }}></EditLinkWidget>
          );
        }
        if (isMarkdownBlock(editingNode)) {
          return (
            <EditMarkdownBlockWidget
              open={open}
              direction={direction}
              node={editingNode}
              onClose={() => {
                setOpen(false);
              }}></EditMarkdownBlockWidget>
          );
        }
        if (isLatex(editingNode)) {
          return (
            <EditLatexWidget
              node={editingNode}
              open={open}
              onClose={() => {
                setOpen(false);
              }}></EditLatexWidget>
          );
        }
        return null;
      }
      default: {
        return (
          <S.DefaultWrapper>
            <FormatButton format="strong" icon="bold" />
            <FormatButton format="emphasis" icon="italic" />
            <FormatButton format="delete" icon="strikethrough" />
            <FormatButton format="inlineCode" icon="code" />
            <S.FormatButton
              name={'link'}
              $small={true}
              useNewIconComponent
              $active={EditorWithLinks.isLinkActive(editor)}
              onMouseDown={e => e.preventDefault()}
              onClick={() => {
                if (EditorWithLinks.isLinkActive(editor)) {
                  EditorWithLinks.unwrapLink(editor);
                } else {
                  setMode('link');
                  setSavedSelection(editor.selection);
                }
              }}></S.FormatButton>
            {!disableComments && (
              <CommentFormatButton
                onClose={onCommentClose}
                readOnly={readOnly}
              />
            )}
          </S.DefaultWrapper>
        );
      }
    }
  }, [
    mode,
    direction,
    editingNode,
    editor,
    open,
    savedSelection,
    onCommentClose,
    readOnly,
    disableComments,
  ]);

  if (toolbarContent == null) {
    return null;
  }

  return (
    <S.Wrapper
      data-test="report-hovering-toolbar"
      contentEditable={false}
      ref={ref}
      left={left}
      top={top}
      direction={direction}
      open={open}
      className={className}
      mode={mode}>
      {toolbarContent}
    </S.Wrapper>
  );
};
