import type {OutputNode} from '@wandb/cg';
import {
  constFunction,
  constNumber,
  opArtifactAliasAlias,
  opArtifactAliases,
  opArtifactId,
  opArtifactIsPortfolio,
  opArtifactMembershipArtifactAliases,
  opArtifactMembershipArtifactVersion,
  opArtifactMembershipCollection,
  opArtifactMemberships,
  opArtifactMembershipVersionIndex,
  opArtifactName,
  opArtifactProject,
  opArtifactType,
  opArtifactTypeName,
  opArtifactVersionArtifactSequence,
  opArtifactVersionArtifactType,
  opArtifactVersionCollections,
  opArtifactVersionCreatedAt,
  opArtifactVersionCreatedBy,
  opArtifactVersionCreatedByUser,
  opArtifactVersionDescription,
  opArtifactVersionDigest,
  opArtifactVersionFiles,
  opArtifactVersionId,
  opArtifactVersionLink,
  opArtifactVersionMemberships,
  opArtifactVersionName,
  opArtifactVersionSize,
  opArtifactVersionUsedBy,
  opCount,
  opDict,
  opEntityArtifactPortfolios,
  opEntityName,
  opFilter,
  opMap,
  opNoneCoalesce,
  opNumberGreaterEqual,
  opProjectEntity,
  opProjectName,
  opRunLink,
  opRunUsedArtifactVersions,
  opStringEqual,
  opUserLink,
} from '@wandb/cg';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {
  useEach,
  useNodeValue,
  useRefreshAllNodes,
} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import {PanelLink} from '@wandb/weave-ui/components/Panel2/PanelLink';
import moment from 'moment';
import numeral from 'numeral';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import CopyableText from '../../../../components/CopyableText';
import * as Generated from '../../../../generated/graphql';
import * as urls from '../../../../util/urls';
import {useReadOnly} from '../../useReadOnly';
import {PanelArtifactMembershipOverviewAliasList} from '../PanelArtifactMembershipOverviewAliasList';
import {LinkModal} from './LinkModal';
import {OItem} from './OItem';
import {PanelArtifactMembershipOverviewCollectionSection} from './PanelArtifactMembershipOverviewCollectionSection';
import * as S from './style';

export type ConfigType = {};
const inputType = 'artifactMembership' as const;
export const PanelArtifactMembershipOverview: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {
    updateSelectedMembershipIdentifier?: (identifier: string) => void;
    artifactRegistry?: boolean;
  }
> = props => {
  const artifactMembershipNode = props.input;
  const [linkIsOpen, setLinkIsOpen] = React.useState(false);
  const [updateArtifact] = Generated.useUpdateArtifactMutation();
  const refreshAll = useRefreshAllNodes();
  const readOnly = useReadOnly();
  const history = useHistory();
  const artifactVersionNode = React.useMemo(
    () =>
      opArtifactMembershipArtifactVersion({
        artifactMembership: artifactMembershipNode,
      }),
    [artifactMembershipNode]
  );
  const artifactNode = React.useMemo(
    () =>
      opArtifactMembershipCollection({
        artifactMembership: artifactMembershipNode,
      }),
    [artifactMembershipNode]
  );
  const portfolioMembershipsNode = React.useMemo(() => {
    return opFilter({
      arr: opArtifactVersionMemberships({
        artifactVersion: artifactVersionNode,
      }),
      filterFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opArtifactIsPortfolio({
          artifact: opArtifactMembershipCollection({
            artifactMembership: row,
          }),
        });
      }),
    });
  }, [artifactVersionNode]);

  const artifactVersionLinkNode = React.useMemo(
    () =>
      opArtifactVersionLink({
        artifactVersion: artifactVersionNode,
      }) as any,
    [artifactVersionNode]
  );

  const createdByLinkNode = React.useMemo(
    () =>
      opNoneCoalesce({
        lhs: opRunLink({
          run: opArtifactVersionCreatedBy({
            artifactVersion: artifactVersionNode,
          }),
        }),
        rhs: opUserLink({
          user: opArtifactVersionCreatedByUser({
            artifactVersion: artifactVersionNode,
          }),
        }),
      }) as any,
    [artifactVersionNode]
  );

  const {result, loading} = useNodeValue(
    React.useMemo(
      () =>
        getDataDict(
          artifactNode as any,
          artifactVersionNode as any,
          artifactMembershipNode as any
        ),
      [artifactMembershipNode, artifactNode, artifactVersionNode]
    )
  );
  const portfolioMembershipNodes = useEach(portfolioMembershipsNode as any);

  const afterLink = React.useCallback(
    res => {
      refreshAll().then(() =>
        history.push(
          urls.objectRegistry(
            'model',
            {
              entityName: res.entityName,
              projectName: res.projectName,
              collectionName: res.artifactCollectionName,
            },
            res.entityName
          )
        )
      );
    },
    [history, refreshAll]
  );

  if (loading || portfolioMembershipNodes.loading) {
    return <WandbLoader name="artifact-membership-overview" />;
  }

  return (
    <S.OverviewWrapper>
      <S.OverviewPanel>
        {/* COLLECTION  */}
        <PanelArtifactMembershipOverviewCollectionSection
          {...Panel2.dummyProps}
          readOnly={readOnly}
          artifactRegistry={props.artifactRegistry}
          input={artifactNode as any}
        />
        {/* Membership  */}
        <S.OverviewPanelSection
          style={{
            marginTop: '1.5em',
          }}>
          <S.OverviewPanelSectionHeaderTitle>
            Version
            {!readOnly &&
              result.artifactTypeName === 'model' &&
              !result.artifactIsPortfolio && (
                <Button
                  compact
                  data-test="link-artifact"
                  onClick={() => setLinkIsOpen(true)}>
                  Link to Registry
                </Button>
              )}
          </S.OverviewPanelSectionHeaderTitle>
          <S.OverviewPanelSectionBody>
            <S.OverviewPanelSectionBodyRow>
              <S.OverviewPanelSectionBodyColumn>
                <OItem
                  label={'Full Name'}
                  value={
                    <CopyableText
                      text={`${result.entityName}/${result.projectName}/${result.artifactName}:v${result.versionIndex}`}
                      toastText={'Copied version to clipboard'}
                    />
                  }
                />
                <OItem
                  label={'Aliases'}
                  value={
                    <PanelArtifactMembershipOverviewAliasList
                      {...Panel2.dummyProps}
                      input={artifactMembershipNode}
                    />
                  }
                />
                <OItem label={'Digest'} value={result.digest} />
                {result.artifactIsPortfolio ? (
                  <OItem
                    label={'Source Version'}
                    value={
                      <PanelLink
                        {...Panel2.dummyProps}
                        input={artifactVersionLinkNode}
                      />
                    }
                  />
                ) : (
                  result.canLink && (
                    // These links will have to be updated in the future when
                    // portfolios are more than just Models in the registry
                    <OItem
                      label={'Linked To'}
                      value={
                        <>
                          {result.portfolioMembershipsMaterialized.map(
                            (node: any, ndx: number) => {
                              return (
                                <div key={ndx}>
                                  <Link
                                    to={urls.objectRegistry(
                                      'model',
                                      {
                                        entityName: node.entityName,
                                        projectName: node.projectName,
                                        collectionName: node.collectionName,
                                      },
                                      result.entityName,
                                      undefined,
                                      `v${node.versionIndex}`,
                                      'overview'
                                    )}>{`${node.collectionName}:v${node.versionIndex}`}</Link>
                                </div>
                              );
                            }
                          )}
                        </>
                      }
                    />
                  )
                )}
                <OItem
                  label={'Created By'}
                  value={
                    <PanelLink
                      {...Panel2.dummyProps}
                      input={createdByLinkNode}
                    />
                  }
                />
              </S.OverviewPanelSectionBodyColumn>
              <S.OverviewPanelSectionBodyColumn>
                <OItem
                  label={'Created At'}
                  value={moment(moment.utc(result.createdAt).toDate()).format(
                    'MMMM Do, YYYY HH:mm:ss'
                  )}
                />
                <OItem label={'Num Consumers'} value={result.numConsumers} />
                <OItem label={'Num Files'} value={result.numFiles} />
                <OItem
                  label={'Size'}
                  value={numeral(result.artifactSize).format('0.0b')}
                />
                {result.peerArtifacts != null &&
                  result.peerArtifacts.length > 0 && (
                    <OItem
                      label={'Upstream Artifacts'}
                      value={result.peerArtifacts.map((p, i) => {
                        const [artifactCollectionName, artifactCommitHash] =
                          p.versionName.split(':');
                        return (
                          <Link
                            key={`${i}-${artifactCommitHash}`}
                            to={urls.artifact({
                              entityName: p.entityName,
                              projectName: p.projectName,
                              artifactTypeName: p.type,
                              artifactCollectionName,
                              artifactCommitHash,
                            })}>
                            {p.versionName}
                          </Link>
                        );
                      })}
                    />
                  )}
              </S.OverviewPanelSectionBodyColumn>
            </S.OverviewPanelSectionBodyRow>
            <S.OverviewPanelSectionBodyRow>
              <OItem
                label={'Description'}
                value={
                  <S.OverviewValueMarkdownEditor
                    readOnly={readOnly}
                    saveText={false}
                    placeholder={'What changed in this version?'}
                    serverText={result.versionDescription}
                    onChange={value => {
                      updateArtifact({
                        variables: {
                          artifactID: result.artifactVersionId,
                          description: value,
                        },
                      });
                    }}
                  />
                }
              />
            </S.OverviewPanelSectionBodyRow>
          </S.OverviewPanelSectionBody>
        </S.OverviewPanelSection>
      </S.OverviewPanel>

      <LinkModal
        open={linkIsOpen}
        onClose={() => {
          setLinkIsOpen(false);
        }}
        afterLink={afterLink}
        artifactTypeName={result.artifactTypeName}
        artifactName={result.artifactName}
        entityName={result.entityName}
        projectName={result.projectName}
        currentArtifactVersionID={result.artifactVersionId}
      />
    </S.OverviewWrapper>
  );
};

function getDataDict(
  artifactNode: OutputNode<'artifact'>,
  artifactVersionNode: OutputNode<'artifactVersion'>,
  artifactMembershipNode: OutputNode<'artifactMembership'>
) {
  const artifactTypeNode = opArtifactType({artifact: artifactNode});
  const artifactProjectNode = opArtifactProject({
    artifact: artifactNode,
  });
  const memberships = opFilter({
    arr: opArtifactVersionCollections({
      artifactVersion: artifactVersionNode,
    }),
    filterFn: constFunction({row: 'artifact'}, ({row}) => {
      return opArtifactIsPortfolio({artifact: row});
    }),
  });

  const portfolioMemberships = opFilter({
    arr: opArtifactVersionMemberships({
      artifactVersion: artifactVersionNode,
    }),
    filterFn: constFunction({row: 'artifactMembership'}, ({row}) => {
      return opArtifactIsPortfolio({
        artifact: opArtifactMembershipCollection({
          artifactMembership: row,
        }),
      });
    }),
  });

  const peerArtifactVersions = opRunUsedArtifactVersions({
    run: opArtifactVersionCreatedBy({
      artifactVersion: artifactVersionNode,
    }),
  });

  return opDict({
    artifactVersionId: opArtifactVersionId({
      artifactVersion: opArtifactMembershipArtifactVersion({
        artifactMembership: artifactMembershipNode,
      }),
    }),
    artifactId: opArtifactId({
      artifact: artifactNode,
    }),
    artifactAliases: opArtifactAliasAlias({
      artifactAlias: opArtifactAliases({
        artifact: artifactNode,
      }),
    }),
    artifactTypeName: opArtifactTypeName({
      artifactType: artifactTypeNode,
    }),
    artifactMembershipsMaterialized: opMap({
      arr: opArtifactMemberships({
        artifact: artifactNode,
      }),
      mapFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opDict({
          versionIndex: opArtifactMembershipVersionIndex({
            artifactMembership: row,
          }),
          aliases: opArtifactAliasAlias({
            artifactAlias: opArtifactMembershipArtifactAliases({
              artifactMembership: row,
            }),
          }),
        } as any);
      }),
    }),
    portfolioMembershipsMaterialized: opMap({
      arr: portfolioMemberships,
      mapFn: constFunction({row: 'artifactMembership'}, ({row}) => {
        return opDict({
          versionIndex: opArtifactMembershipVersionIndex({
            artifactMembership: row,
          }),
          entityName: opEntityName({
            entity: opProjectEntity({
              project: opArtifactProject({
                artifact: opArtifactMembershipCollection({
                  artifactMembership: row,
                }),
              }),
            }),
          }),
          projectName: opProjectName({
            project: opArtifactProject({
              artifact: opArtifactMembershipCollection({
                artifactMembership: row,
              }),
            }),
          }),
          collectionName: opArtifactName({
            artifact: opArtifactMembershipCollection({
              artifactMembership: row,
            }),
          }),
        } as any);
      }),
    }),
    entityName: opEntityName({
      entity: opProjectEntity({
        project: artifactProjectNode,
      }),
    }),
    projectName: opProjectName({
      project: artifactProjectNode,
    }),
    artifactName: opArtifactName({
      artifact: artifactNode,
    }),
    versionIndex: opArtifactMembershipVersionIndex({
      artifactMembership: artifactMembershipNode,
    }),
    versionDescription: opArtifactVersionDescription({
      artifactVersion: artifactVersionNode,
    }),
    digest: opArtifactVersionDigest({
      artifactVersion: artifactVersionNode,
    }),
    createdAt: opArtifactVersionCreatedAt({
      artifactVersion: artifactVersionNode,
    }),
    numConsumers: opCount({
      arr: opArtifactVersionUsedBy({
        artifactVersion: artifactVersionNode,
      }),
    }),
    numFiles: opCount({
      arr: opArtifactVersionFiles({
        artifactVersion: artifactVersionNode,
      }),
    }),
    artifactSize: opArtifactVersionSize({
      artifactVersion: artifactVersionNode,
    }),
    artifactIsPortfolio: opArtifactIsPortfolio({
      artifact: artifactNode,
    }),
    portfolioMemberships: opMap({
      arr: memberships,
      mapFn: constFunction({row: 'artifact'}, ({row}) => {
        return opDict({
          name: opArtifactName({artifact: row}),
          typeName: opArtifactTypeName({
            artifactType: opArtifactType({artifact: row}),
          }),
          project: opProjectName({
            project: opArtifactProject({artifact: row}),
          }),
          entity: opEntityName({
            entity: opProjectEntity({
              project: opArtifactProject({artifact: row}),
            }),
          }),
        } as any);
      }),
    }),
    peerArtifacts: opMap({
      arr: peerArtifactVersions,
      mapFn: constFunction({row: 'artifactVersion'}, ({row}) => {
        return opDict({
          versionName: opArtifactVersionName({
            artifactVersion: row,
          }),
          type: opArtifactTypeName({
            artifactType: opArtifactVersionArtifactType({
              artifactVersion: row,
            }),
          }),
          entityName: opEntityName({
            entity: opProjectEntity({
              project: opArtifactProject({
                artifact: opArtifactVersionArtifactSequence({
                  artifactVersion: row,
                }),
              }),
            }),
          }),
          projectName: opProjectName({
            project: opArtifactProject({
              artifact: opArtifactVersionArtifactSequence({
                artifactVersion: row,
              }),
            }),
          }),
        } as any);
      }),
    }),
    canLink: opNumberGreaterEqual({
      lhs: opCount({
        arr: opFilter({
          arr: opEntityArtifactPortfolios({
            entity: opProjectEntity({
              project: artifactProjectNode,
            }),
          }),
          filterFn: constFunction({row: 'artifact'}, ({row}) => {
            return opStringEqual({
              lhs: opArtifactTypeName({
                artifactType: opArtifactType({artifact: row}),
              }),
              rhs: opArtifactTypeName({artifactType: artifactTypeNode}),
            });
          }),
        }),
      }),
      rhs: constNumber(1),
    }),
  } as any) as OutputNode<{
    type: 'typedDict';
    propertyTypes: {
      artifactId: 'string';
      artifactAliases: {type: 'list'; objectType: 'string'};
      artifactMembershipsMaterialized: {
        type: 'list';
        objectType: {
          type: 'typedDict';
          propertyTypes: {
            versionIndex: 'number';
            aliases: {type: 'list'; objectType: 'string'};
          };
        };
      };
      portfolioMembershipsMaterialized: {
        type: 'list';
        objectType: {
          type: 'typedDict';
          propertyTypes: {
            versionIndex: 'number';
            entityName: 'string';
            projectName: 'string';
            collectionName: 'string';
          };
        };
      };
      artifactVersionId: 'string';
      artifactTypeName: 'string';
      entityName: 'string';
      projectName: 'string';
      artifactName: 'string';
      versionIndex: 'number';
      versionDescription: 'string';
      numConsumers: 'number';
      numFiles: 'number';
      artifactSize: 'number';
      digest: 'string';
      createdAt: 'any';
      artifactIsPortfolio: 'any';
      portfolioMemberships: 'any';
      peerArtifacts: {
        type: 'list';
        objectType: {
          type: 'typedDict';
          propertyTypes: {
            versionName: 'string';
            type: 'string';
            entityName: 'string';
            projectName: 'string';
          };
        };
      };
      canLink: 'boolean';
    };
  }>;
}
