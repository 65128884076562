import makeComp from '@wandb/common/util/profiler';
import React, {FC} from 'react';

import GitHubIntegration from '../GitHubIntegration';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

// TODO: are we still using this? it seems to be for benchmarks
export const UserSettingsPersonalGithub: FC = makeComp(
  () => {
    const {viewer, refetch} = useUserSettingsContext();
    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }
    return (
      <>
        <p>Connect a personal GitHub for submitting benchmark runs.</p>
        <GitHubIntegration entity={viewer.userEntity} entityRefetch={refetch} />
      </>
    );
  },
  {id: 'UserSettingsPersonalGithub', memo: true}
);
