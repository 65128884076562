import {WBIcon} from '@wandb/ui';
import {Dropdown, Tab} from 'semantic-ui-react';
import styled from 'styled-components';

export const PanelArtifactWrapper = styled.div`
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PanelArtifactHeaderContent = styled.div`
  height: 3.4em;
  display: flex;
  align-items: center;
`;

export const PanelArtifactHeaderTitle = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  padding-left: 0.5em;
`;

export const TriggerContainer = styled.div`
  margin-left: auto;
  margin-right: 10px;
`;

export const VersionMenuButton = styled(WBIcon)`
  font-size: 1.9em;
  border-radius: 5px;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: #ddd;
  }
`;

export const PanelArtifactContent = styled.div<{isTall: boolean}>`
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.isTall ? 'column' : 'row')};
`;

export const CollectionDropdown = styled(Dropdown)`
  margin-left: 1em !important;
  font-size: 16px !important;
`;

export const ArtifactOverview = styled.div`
  padding: 16px 32px;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  flex: 0 0 72px;
`;

export const OverviewTitle = styled.div`
  font-size: 1.5em;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
`;

export const OverviewTitleDropdown = styled(Dropdown)`
  z-index: 1010;
  font-size: 16px !important;
`;

export const CollectionTabs = styled(Tab)`
  display: flex;
  flex-direction: column;
  border-top: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1 50px;
  > .ui.tabular.menu {
    /* justify-content: flex-end; */
    height: 39px;
    flex: 0 0 39px;
    padding: 0px;
    /* margin-left: 50px; */
    .item:last-child {
      /* margin-right: 2em; */
    }
    .item:first-child {
      margin-left: 10px;
    }
    border-top: none;
    font-size: 16px;
    min-height: fit-content;
    max-width: 100%;
    /* overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      display: none;
    } */
    > .item {
      position: relative;
      /* opacity: 0.95; */
      transition: none;
      background-color: none;
      border: none;
      .icon {
        /* margin-top: 4px; */
      }
      /* If there's an ellispis menu, make it fit nicely */
      > .dropdown.icon > i.icon {
        width: auto;
        margin-left: 24px;
        margin-right: 0;
      }
    }
    > .item,
    > .active.item {
      margin-bottom: 0;
      cursor: pointer;
      font-weight: normal;
      padding: 12px 16px 11px;
      margin-right: 10px;
      background: none;
      .label {
        background-color: white;
        font-size: 18px;
        margin-left: 8px;
        padding: 1px 3px;
      }
    }
    > .active.item {
      color: black;
      border: 1px solid rgb(212, 212, 213);
      border-bottom: 2px solid #fff;
      background: none #fff;
      margin-bottom: -1px;
    }
  }
`;

export const OverviewTabs = styled(Tab)`
  display: flex;
  flex-direction: column;
  border-top: none;
  background-color: transparent;
  height: 100%;
  flex: 1 1 50px;
  .ui.tabular.menu {
    flex: 0 0 39px;
    padding: 0px;
    .item:last-child {
      margin-right: 0px;
    }
  }
`;

export const PanelWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1 1 50px;
  overflow: hidden;
  padding: 0px;
  background-color: #fff;
`;

export const BlankPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const ArtifactCollectionMain = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;
