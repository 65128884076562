import {CardElement, useElements} from '@stripe/react-stripe-js';
import docUrl from '@wandb/common/util/doc_urls';
import {captureError} from '@wandb/common/util/integrations';
import {TargetBlank} from '@wandb/common/util/links';
import React, {FC, memo, useCallback, useMemo} from 'react';

import {
  useCreateStorageSubscriptionMutation,
  useUpgradeCustomerSubscriptionMutation,
} from '../../../generated/graphql';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../../util/errors';
import {navigateTo} from '../../../util/history';
import {CheckoutPlan, Org} from '../../../util/pricing';
import {StripeForm, SubmitParams} from './StripeForm';
import * as S from './UpgradeSubscriptionForm.styles';

type UpgradeSubscriptionFormProps = {
  plan: CheckoutPlan;
  seats: number;
  storagePlanID: string;
  trackingPlanID: string;
  org: Org;
  onSubscriptionCompleted: () => void;
};

const UpgradeSubscriptionFormComp: FC<UpgradeSubscriptionFormProps> = props => {
  const {
    plan,
    seats,
    storagePlanID,
    trackingPlanID,
    org,
    onSubscriptionCompleted,
  } = props;

  const elements = useElements();

  const [upgradeCustomerSubscriptionMutation] =
    useUpgradeCustomerSubscriptionMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const [createStorageSubscriptionMutation] =
    useCreateStorageSubscriptionMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const upgradeSubscription = useCallback(
    async ({
      stripe,
      custoEmail,
      paymentMethodID,
      setErrMsg,
    }: SubmitParams): Promise<boolean> => {
      if (seats < org.usedSeats) {
        setErrMsg(
          `You have ${org.usedSeats} people on your team, so you need at least ${org.usedSeats} seats.`
        );
        return false;
      }

      const subResult = await upgrade();
      if (subResult == null) {
        return false;
      }

      const {organizationID, subscription} = subResult;
      const paymentIntent = subscription.latest_invoice.payment_intent;
      const clientSecret = paymentIntent?.client_secret;
      const paymentIntentStatus = paymentIntent?.status;
      const needsPayment =
        ['requires_action', 'requires_payment_method'].indexOf(
          paymentIntentStatus
        ) !== -1;

      if (needsPayment) {
        const paymentConfirmed = await confirmPayment(clientSecret);
        if (!paymentConfirmed) {
          return false;
        }
      }

      const storageSubscription = await subscribeStorage(organizationID);
      if (storageSubscription == null) {
        return false;
      }

      const paymentIntentStorage =
        storageSubscription.latest_invoice.payment_intent;
      const clientSecretStorage = paymentIntentStorage?.client_secret;
      const paymentIntentStatusStorage = paymentIntentStorage?.status;
      const needsPaymentStorage =
        ['requires_action', 'requires_payment_method'].indexOf(
          paymentIntentStatusStorage
        ) !== -1;

      if (needsPaymentStorage) {
        const paymentConfirmedStorage = await confirmPaymentStorage(
          clientSecretStorage
        );
        if (!paymentConfirmedStorage) {
          return false;
        }
      }

      window.analytics?.track('upgrade subscription completed', {
        org: org.id,
        plan: plan.id,
        units: seats,
      });
      onSubscriptionCompleted();
      return true;

      // HELPER FUNCTIONS

      async function upgrade(): Promise<{
        organizationID: string;
        subscription: {
          latest_invoice: any;
        };
      } | null> {
        if (paymentMethodID == null) {
          throw new Error(`missing paymentMethodID`);
        }

        try {
          const upgradeSubResult = await upgradeCustomerSubscriptionMutation({
            variables: {
              organizationID: org.id,
              planID: plan.id,
              paymentMethod: paymentMethodID,
              quantity: seats,
            },
          });

          const sub =
            upgradeSubResult.data?.upgradeCustomerSubscription?.subscription;
          const orgID =
            upgradeSubResult.data?.upgradeCustomerSubscription?.organizationID;

          if (upgradeSubResult.errors != null || sub == null || orgID == null) {
            onError({
              err: "Unknown error upgrading subscription. Exception didn't throw but success was not reported.",
              op: 'handleUpgrade-unknown',
              analyticsEvent: 'Upgrade Subscription Error',
              extra: {errorType: 'unknown'},
              userFacingErrorMsg:
                'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
            });
            return null;
          }

          return {organizationID: orgID, subscription: sub};
        } catch (err) {
          const errMsg = extractErrorMessageFromApolloError(err);
          onError({
            err,
            op: 'upgradeSubResult',
            extra: {
              reason: errMsg,
              errorType: 'processing payment',
            },
            analyticsEvent: 'Upgrade Subscription Error',
            userFacingErrorMsg: `Error processing payment: ${errMsg}`,
          });
          return null;
        }
      }

      async function subscribeStorage(orgID: string): Promise<{
        latest_invoice: any;
      } | null> {
        try {
          const createStorageSubResult =
            await createStorageSubscriptionMutation({
              variables: {
                storagePlanId: storagePlanID,
                trackingPlanId: trackingPlanID,
                paymentMethod: paymentMethodID,
                organizationId: orgID,
                trial: false,
              },
            });

          const storageSub =
            createStorageSubResult.data?.createStorageSubscription
              ?.subscription;

          if (createStorageSubResult.errors != null || storageSub == null) {
            onError({
              err: "Unknown error confirming subscription. Exception didn't throw but success was not reported.",
              op: 'handleStorageSubscribe-unknown',
              analyticsEvent: 'Upgrade Subscription Error',
              extra: {
                errorType: 'creating storage subscription',
                reason: createStorageSubResult.errors,
              },
              userFacingErrorMsg:
                'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
            });
            return null;
          }

          return storageSub;
        } catch (err) {
          const errMsg = extractErrorMessageFromApolloError(err);
          onError({
            err,
            op: 'createStorageSubResult',
            extra: {
              reason: errMsg,
              errorType: 'creating storage subscription',
            },
            analyticsEvent: 'Upgrade Subscription Error',
            userFacingErrorMsg: `Error processing storage subscription payment: ${errMsg}`,
          });
          return null;
        }
      }

      async function confirmPayment(secret: string): Promise<boolean> {
        try {
          if (!stripe || !elements) {
            throw new Error('Stripe not loaded.');
          }

          const cardElement = elements.getElement(CardElement);
          if (cardElement == null) {
            throw new Error('Stripe card element no loaded.');
          }

          const result = await stripe.confirmCardPayment(secret);

          if (result.error != null) {
            onError({
              err: result.error.message!,
              op: 'handleCardPayment',
              analyticsEvent: 'Upgrade Subscription Error',
              extra: {errorType: 'confirming card payment'},
              userFacingErrorMsg: `We upgraded your subscription, but had trouble confirming your payment: ${result.error.message}`,
            });
            return false;
          }

          return true;
        } catch (err) {
          onError({
            err,
            op: 'handleCardPayment',
            analyticsEvent: 'Upgrade Subscription Error',
            extra: {errorType: 'confirming cared payment'},
            userFacingErrorMsg: `We upgraded your subscription, but had trouble confirming your payment: ${err}`,
          });
          return false;
        }
      }

      async function confirmPaymentStorage(secret: string): Promise<boolean> {
        try {
          const result = await stripe.confirmCardPayment(secret);

          if (result.error != null) {
            onError({
              err: result.error.message!,
              op: 'handleCardPaymentStorage',
              analyticsEvent: 'Upgrade Subscription Error',
              extra: {errorType: 'cofirming card payment'},
              userFacingErrorMsg: `We had trouble confirming your payment: ${result.error.message}`,
            });
            return false;
          }

          return true;
        } catch (err) {
          onError({
            err,
            op: 'handleCardPaymentStorage',
            analyticsEvent: 'Upgrade Subscription Error',
            extra: {errorType: 'confiming card payment'},
            userFacingErrorMsg: `We had trouble confirming your payment: ${err}`,
          });
          return false;
        }
      }

      type ErrOpts = {
        err: unknown;
        op: string;
        userFacingErrorMsg: string;
        analyticsEvent?: string;
        extra?: {[key: string]: any};
      };
      function onError(opts: ErrOpts) {
        const {err, op, userFacingErrorMsg, analyticsEvent} = opts;
        if (!(err instanceof Error) && typeof err !== 'string') {
          return;
        }

        const extra = {
          email: custoEmail,
          storageID: storagePlanID,
          trackingID: trackingPlanID,
          org: org.id,
          plan: plan.id,
          units: seats,
          ...opts.extra,
        };
        captureError(err, `UpgradeSubscriptionForm-${op}`, {
          extra,
        });
        if (analyticsEvent != null) {
          window.analytics?.track(analyticsEvent, extra);
        }
        setErrMsg(userFacingErrorMsg);
      }
    },
    [
      storagePlanID,
      trackingPlanID,
      org,
      plan.id,
      seats,
      elements,
      onSubscriptionCompleted,
      upgradeCustomerSubscriptionMutation,
      createStorageSubscriptionMutation,
    ]
  );

  return (
    <StripeForm
      orgName={org.name}
      renderButtons={({submitWrapper, submitButtonDisabled, submitting}) => {
        return (
          <S.ConfirmButton
            primary
            onClick={() => {
              window.analytics?.track(
                'confirm upgrade subscription button clicked',
                {
                  org: org.id,
                  plan: plan.id,
                  units: seats,
                }
              );
              submitWrapper(upgradeSubscription);
            }}
            disabled={submitButtonDisabled}>
            {submitting ? 'Processing...' : 'Subscribe'}
          </S.ConfirmButton>
        );
      }}
    />
  );
};

export const UpgradeSubscriptionForm = memo(UpgradeSubscriptionFormComp);

type UpgradeSuccessProps = {org: Org};

export const UpgradeSuccess: React.FC<UpgradeSuccessProps> = React.memo(
  ({org}) => {
    const actionButton = useMemo(() => {
      if (org.teams == null || org.teams.length === 0) {
        return (
          <S.ConfirmButton
            primary
            onClick={() => {
              window.analytics?.track('Create Team Started', {
                location: 'upgrade subscription form upgrade success',
              });
              navigateTo({pathname: '/create-team'});
            }}>
            Create a team now!
          </S.ConfirmButton>
        );
      }

      return (
        <S.ConfirmButton
          primary
          onClick={() => {
            window.analytics?.track('Open Team Page Clicked', {
              location: 'upgrade subscription form upgrade success',
            });
            navigateTo({pathname: `/${org.teams[0].name}`});
          }}>
          Open Team Page →
        </S.ConfirmButton>
      );
    }, [org.teams]);

    return (
      <S.SuccessContainer>
        <S.SuccessHeader>Team Successfully Upgraded</S.SuccessHeader>
        <S.SuccessContent>
          <TargetBlank href={docUrl.teams}>Learn more about teams</TargetBlank>,
          or reach out to support@wandb.com for help.
        </S.SuccessContent>
        {actionButton}
      </S.SuccessContainer>
    );
  }
);
