import {createAction} from 'typesafe-actions';

import * as Types from './types';

export const hideSlowWarning = createAction(
  '@view/runPage/hideSlowWarning',
  action => (ref: Types.Ref) => action({ref, at: new Date().toISOString()})
);

export const hideCliVersionWarning = createAction(
  '@view/runPage/hideCliVersionWarning',
  action => (ref: Types.Ref) => action({ref, at: new Date().toISOString()})
);
