import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {immerReducer} from '../reducer';
import * as Actions from './actions';

export interface StateType {
  basePollInterval: number;

  serverPollingOK: boolean;
  userPollingOK: boolean;
  userInactive: boolean;
  tabBackgrounded: boolean;
}

export type ActionType = TSActionType<typeof Actions>;

function globalReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case getType(Actions.setServerPollingOK): {
      const {serverPollingOK} = action.payload;
      state.serverPollingOK = serverPollingOK;
      break;
    }
    case getType(Actions.setUserPollingOK): {
      const {userPollingOK} = action.payload;
      state.userPollingOK = userPollingOK;
      break;
    }
    case getType(Actions.setUserInactive): {
      const {userInactive} = action.payload;
      state.userInactive = userInactive;
      break;
    }
    case getType(Actions.setTabBackgrounded): {
      const {tabBackgrounded} = action.payload;
      state.tabBackgrounded = tabBackgrounded;
      break;
    }
    case getType(Actions.setBasePollInterval): {
      const {basePollInterval} = action.payload;
      state.basePollInterval = basePollInterval;
      break;
    }
  }
}

export default immerReducer<StateType, ActionType>(globalReducer, {
  serverPollingOK: true,
  userPollingOK: true,
  userInactive: false,
  tabBackgrounded: false,
  basePollInterval: 0,
});
