export type ExperimentData = {
  graphqlExperimentId: string;
  treatment: number;
  log: boolean;
  experimentName: string;
  observationalUnitId: string;
  hash: number;
};

export type ExperimentQueryParams = {
  experimentIdParam: string;
  experimentBucketParam: string;
};

export const DEFAULT_EXPERIMENT_DATA: ExperimentData = {
  graphqlExperimentId: '',
  treatment: 0,
  log: false,
  experimentName: '',
  observationalUnitId: '',
  hash: 0,
};
