import config from '@wandb/common/config';
import {captureError} from '@wandb/common/util/integrations';
import React, {createContext, FC, useMemo} from 'react';

import {
  ActiveExperiment,
  useActiveExperimentsQuery,
} from '../../generated/graphql';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../util/errors';
import {updateLocalStorageExperiments} from './localStorage';

type ExperimentContextProps = {
  activeExperiments: ActiveExperiment[];
  isExperimentsQueryLoading: boolean;
};

export const ExperimentContext = createContext<ExperimentContextProps>({
  activeExperiments: [],
  isExperimentsQueryLoading: false,
});

export const ExperimentContextProvider: FC = ({children}) => {
  const {
    data,
    networkStatus,
    loading: isExperimentsQueryLoading,
    error,
  } = useActiveExperimentsQuery({
    context: propagateErrorsContext(),
    skip: config.ENVIRONMENT_IS_PRIVATE,
    pollInterval: 1000 * 60 * 15, // poll every 15 minutes
  });

  const activeExperiments: ActiveExperiment[] = useMemo(() => {
    if (!config.ENVIRONMENT_IS_PRIVATE && !isExperimentsQueryLoading) {
      if (error == null) {
        const experiments = data?.activeExperiments ?? [];
        updateLocalStorageExperiments(experiments);
        return experiments;
      }
      const errMsg = extractErrorMessageFromApolloError(error);
      captureError(
        `Apollo response error: ${errMsg}`,
        'useFetchAllExperimentsQuery',
        {
          extra: {error, networkStatus},
        }
      );
    }
    return [];
  }, [data, networkStatus, isExperimentsQueryLoading, error]);

  const state = useMemo(
    () => ({
      activeExperiments,
      isExperimentsQueryLoading,
    }),
    [activeExperiments, isExperimentsQueryLoading]
  );

  return (
    <ExperimentContext.Provider value={state}>
      {children}
    </ExperimentContext.Provider>
  );
};
