import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

export const Background = styled.div`
  background: ${globals.gray900};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PromptContainer = styled.div`
  margin-top: 16px;
  width: 300px;
`;

export const Header = styled.div`
  color: white;
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
`;

export const Subheader = styled.div`
  color: ${globals.gray500};
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  margin-top: 16px;
`;

export const Instructions = styled.div`
  color: white;
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  margin: 32px 0px 24px 0px;
`;

interface ButtonProps {
  disabled?: boolean;
}

export const LightButton = styled.div<ButtonProps>`
  background: ${globals.deepBlue};
  border-radius: 4px;
  color: white;
  cursor: pointer;
  height: 40px;
  justify-content: center;
  margin: 16px 0px;
  width: 100%;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
`;

export const DarkButton = styled.div<ButtonProps>`
  background: ${globals.gray800};
  border: 1px solid ${globals.gray500};
  border-radius: 4px;
  color: white;
  cursor: pointer;
  height: 40px;
  justify-content: center;
  margin: 16px 0px;
  width: 100%;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  text-align: center;

  ${props =>
    props.disabled &&
    `
      cursor: unset;
      background: #666d73;
    `}
`;

export const Postscript = styled.div`
  color: ${globals.gray500};
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0px;
  text-align: center;
`;

export const Darklink = styled.a`
  color: ${globals.gray500};
  text-decoration: underline;
`;
