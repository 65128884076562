import {getCookieBool, setCookie} from '@wandb/common/util/cookie';
import React, {useContext, useState} from 'react';

import {useContactUsForUserLedTrialMutation} from '../generated/graphql';
import {StandardPlanCheckoutModal} from '../pages/Billing/Checkout/StandardPlanCheckoutModal';
import {
  GlobalNudgeBarContext,
  GlobalNudgeBarUpdaterContext,
  NudgeBarType,
} from './GlobalNudgeBarContextProvider';
import {
  NudgeBarLarge,
  NudgeBarLargeAction,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

const TRIAL_NUDGE_COOKIE_NAME = 'hide trial nudge';

const TrialNudgeBar: React.FC = React.memo(() => {
  const {states} = useContext(GlobalNudgeBarContext);

  const [createContactUsForUserLedTrial] =
    useContactUsForUserLedTrialMutation();
  const {refetch} = useContext(GlobalNudgeBarUpdaterContext);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const onContactUsClicked = async () => {
    setCookie(TRIAL_NUDGE_COOKIE_NAME, 'true');

    try {
      await createContactUsForUserLedTrial();
    } catch (e) {
      console.error('ERROR SENDING INFO TO CONTACT US', e);
    }
  };

  if (states == null || states.length === 0) {
    return null;
  }

  const alreadyContacted = getCookieBool(TRIAL_NUDGE_COOKIE_NAME);
  const contactSalesContent = !alreadyContacted ? (
    <NudgeBarLargeAction>
      <NudgeBarLargeActionLink onClick={onContactUsClicked}>
        Contact sales
      </NudgeBarLargeActionLink>
    </NudgeBarLargeAction>
  ) : (
    <NudgeBarLargeTitle>
      Our sales team will reach out over email to help you upgrade.
    </NudgeBarLargeTitle>
  );

  return (
    <>
      {states.map((state, i) => {
        const showUpgradeButton =
          state.renderedNudgeBar !== NudgeBarType.EnterpriseToAdvanced &&
          !state.organization?.flags?.noContact;
        const shouldShowNudgeBar =
          (state.renderedNudgeBar === NudgeBarType.EnterpriseToAdvanced ||
            state.renderedNudgeBar === NudgeBarType.EnterpriseToStandard) &&
          state.organization;

        if (!shouldShowNudgeBar) {
          return null;
        }

        window.analytics?.track('Upgrade Subscription Banner Displayed', {
          context: state.renderedNudgeBar,
          location: 'trial nudge bar',
          org: state.organization?.id,
        });

        return (
          <NudgeBarLarge key={i}>
            <NudgeBarLargeTitle>
              Your organization {state.organization?.name}'s 30 day free trial
              is over.{' '}
            </NudgeBarLargeTitle>
            {showUpgradeButton ? (
              <NudgeBarLargeAction>
                <NudgeBarLargeActionLink
                  onClick={() => {
                    window.analytics?.track(
                      'banner upgrade plan button clicked',
                      {
                        org: state.organization?.id,
                        location: 'trial nudge bar',
                      }
                    );
                    setCheckoutModalOpen(true);
                  }}>
                  Upgrade
                </NudgeBarLargeActionLink>
              </NudgeBarLargeAction>
            ) : (
              contactSalesContent
            )}
            {checkoutModalOpen && state.organization && (
              <StandardPlanCheckoutModal
                org={state.organization}
                onTransactionCompleted={refetch}
                onClose={() => setCheckoutModalOpen(false)}
              />
            )}
          </NudgeBarLarge>
        );
      })}
    </>
  );
});

export default TrialNudgeBar;
