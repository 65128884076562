import React, {useEffect, useRef, useState} from 'react';
import {Popup, Portal, Ref, StrictPopupProps} from 'semantic-ui-react';

import {scrollIntoBounds} from '../../util/flash';
import {useBoundingClientRect} from '../../util/hooks';
import * as S from './PersistentFlasher.styles';

interface PersistentFlasherProps {
  style?: React.CSSProperties;
  visible: boolean;
  trigger: JSX.Element;
  offsetX?: number;
  offsetY?: number;
  scrollIntoView?: boolean; // if true, the window will autoscroll to the trigger
  minSpaceFromTop?: number; // used if scrollIntoView is true
  minSpaceFromBottom?: number; // used if scrollIntoView is true
  popupClassName?: string;
  popupContent?: string | JSX.Element;
  popupPosition?: StrictPopupProps['position'];
  popupOffsetX?: number;
  popupOffsetY?: number;
}

// This adds a flashing blue dot to the trigger component
// Use it to draw the user's attention to a button, for example
export const PersistentFlasher: React.FC<PersistentFlasherProps> = ({
  style = {},
  trigger,
  visible,
  offsetX = 0,
  offsetY = 0,
  scrollIntoView = false,
  minSpaceFromTop = 20,
  minSpaceFromBottom = 20,
  popupClassName,
  popupContent,
  popupPosition = 'bottom center',
  popupOffsetX = 0,
  popupOffsetY = 10,
}) => {
  const triggerRef = useRef<HTMLElement>(null);
  const [position, setPosition] = useState([0, 0]); // absolute position of the flasher, in percent window width/height
  const bcr = useBoundingClientRect(triggerRef, !visible);

  useEffect(() => {
    if (triggerRef.current != null && visible) {
      const rect = triggerRef.current.getBoundingClientRect();
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const leftPx = rect.left + rect.width / 2 + offsetX + scrollLeft;
      const topPx = rect.top + rect.height / 2 + offsetY + scrollTop;
      setPosition([leftPx, topPx]);
      if (scrollIntoView) {
        scrollIntoBounds(rect, minSpaceFromTop, minSpaceFromBottom);
      }
    }
  }, [
    bcr,
    visible,
    offsetX,
    offsetY,
    minSpaceFromTop,
    minSpaceFromBottom,
    scrollIntoView,
  ]);

  const bluedot = (
    <S.BlueDot
      onClick={() => {
        if (triggerRef.current != null) {
          triggerRef.current.click();
        }
      }}
      style={{
        left: position[0],
        top: position[1],
        ...style,
      }}
    />
  );

  return (
    <Portal open={visible} trigger={<Ref innerRef={triggerRef}>{trigger}</Ref>}>
      {popupContent == null ? (
        bluedot
      ) : (
        <Popup
          className={popupClassName}
          trigger={bluedot}
          content={popupContent}
          position={popupPosition}
          size="tiny"
          offset={`${popupOffsetX}px, ${popupOffsetY}px`}
        />
      )}
    </Portal>
  );
};
