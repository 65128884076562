import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import {useBetaFeatureWeavePythonEcosystem} from '../../../util/useBetaFeature';
import {PanelWeaveArtifactObject} from '../../Panel2/WeaveArtifactObject';
import {PanelArtifactVersionConsumptionTable} from '../artifactVersion/PanelArtifactVersionConsumptionTable';
import {PanelArtifactVersionFiles} from '../artifactVersion/PanelArtifactVersionFiles';
import {PanelArtifactVersionLineage} from '../artifactVersion/PanelArtifactVersionLineage';
import {PanelArtifactVersionMetadata} from '../artifactVersion/PanelArtifactVersionMetadata';
import * as S from './PanelArtifactMembership.styles';
import {PanelArtifactMembershipAPI} from './PanelArtifactMembershipAPI';
import {PanelArtifactMembershipOverview} from './PanelArtifactMembershipOverview';

const inputType = 'artifactMembership' as const;
export const possibleTabs = [
  // TODO: Placeholder, we will enable this tab
  // 'artifact_card',
  'overview',
  'metadata',
  'usage',
  'files',
  'lineage',
  'weave',
] as const;

export function isPossibleMembershipTab(
  tabName: string
): tabName is typeof possibleTabs[number] {
  return possibleTabs.includes(tabName as any);
}
export type ConfigType = {
  // selectedTab determines the tab selected in the membership view
  selectedTab?: typeof possibleTabs[number];
  // tabConfigs are configs for each tab, keyed by the selectedTab string
  tabConfigs?: {
    overview?: any;
    metadata?: any;
    usage?: any;
    files?: any;
    lineage?: any;
    weave?: any;
  };
};

export const PanelArtifactMembership: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType> & {
    updateSelectedMembershipIdentifier?: (identifier: string) => void;
    targetIdentifier?: string;
    artifactRegistry?: boolean;
  }
> = props => {
  const {updateConfig, config} = props;
  const targetTabIndex = React.useMemo(() => {
    const propsTab = config?.selectedTab;
    if (propsTab != null) {
      if (possibleTabs.indexOf(propsTab) > -1) {
        return possibleTabs.indexOf(propsTab);
      }
    }
    return 0;
  }, [config]);

  const setSelectedTab = React.useCallback(
    (tabKey: string) => {
      if (tabKey === 'files') {
        updateConfig({
          selectedTab: tabKey,
          tabConfigs: {
            ...(config?.tabConfigs ?? {}),
            files: {
              ...(config?.tabConfigs?.files ?? {}),
              filesPanel: {
                ...(config?.tabConfigs?.files?.filesPanel ?? {}),
                path: [],
              },
            },
          },
        });
      } else if (isPossibleMembershipTab(tabKey)) {
        updateConfig({
          selectedTab: tabKey,
        });
      }
    },
    [config, updateConfig]
  );

  const tabConfig = Panel2.useConfigChild(
    'tabConfigs',
    config,
    props.updateConfig
  );

  const overviewConfig = Panel2.useConfigChild(
    'overview',
    tabConfig.config,
    tabConfig.updateConfig
  );
  const apiConfig = Panel2.useConfigChild(
    'api',
    tabConfig.config,
    tabConfig.updateConfig
  );
  const metadataConfig = Panel2.useConfigChild(
    'metadata',
    tabConfig.config,
    tabConfig.updateConfig
  );
  const filesConfig = Panel2.useConfigChild(
    'files',
    tabConfig.config,
    tabConfig.updateConfig
  );
  const lineageConfig = Panel2.useConfigChild(
    'lineage',
    tabConfig.config,
    tabConfig.updateConfig
  );

  const weaveConfig = Panel2.useConfigChild(
    'weave',
    tabConfig.config,
    tabConfig.updateConfig
  );

  const artifactVersionNode = React.useMemo(() => {
    return Op.opArtifactMembershipArtifactVersion({
      artifactMembership: props.input,
    }) as GraphTypes.OutputNode<'artifactVersion'>;
  }, [props.input]);

  const isWeaveNode = Op.opArtifactVersionIsWeaveObject({
    artifactVersion: artifactVersionNode,
  });
  const {result: isWeave, loading} = useNodeValue(isWeaveNode);
  const weaveEcosystemEnabled = useBetaFeatureWeavePythonEcosystem();

  return (
    <S.VersionTabs
      data-test="version-tabs"
      activeIndex={targetTabIndex}
      onTabChange={(e: any, tab: any) => {
        setSelectedTab(tab.panes[tab.activeIndex].menuItem.key);
      }}
      panes={[
        // TODO: Placeholder, we will enable this tab
        // {
        //   menuItem: {
        //     key: 'artifact_card',
        //     content: 'Card',
        //   },
        //   render: () => (
        //     <S.TabWrapper>
        //       <PanelArtifactMembershipOverviewCollectionSection
        //         {...Panel2.dummyProps}
        //         readOnly={readOnly}
        //         input={artifactNode}
        //       />
        //     </S.TabWrapper>
        //   ),
        // },

        {
          menuItem: {
            key: 'overview',
            content: `Overview`,
          },
          render: () => (
            <S.TabWrapper>
              <PanelArtifactMembershipOverview
                input={props.input}
                config={overviewConfig.config}
                updateConfig={overviewConfig.updateConfig}
                context={props.context}
                updateContext={props.updateContext}
                updateSelectedMembershipIdentifier={
                  props.updateSelectedMembershipIdentifier
                }
                artifactRegistry={props.artifactRegistry}
              />
            </S.TabWrapper>
          ),
        },
        {
          menuItem: {key: 'metadata', content: 'Metadata'},
          render: () => {
            return (
              <S.TabWrapper>
                <PanelArtifactVersionMetadata
                  input={artifactVersionNode}
                  config={metadataConfig.config}
                  updateConfig={metadataConfig.updateConfig}
                  context={props.context}
                  updateContext={props.updateContext}
                />
              </S.TabWrapper>
            );
          },
        },
        {
          menuItem: {
            key: 'usage',
            content: 'Usage',
          },
          render: () => (
            <S.TabWrapperColumn>
              <S.TabWrapperColumnSectionHeader>
                Usage API
              </S.TabWrapperColumnSectionHeader>
              <div style={{flex: '0 0 auto'}}>
                <PanelArtifactMembershipAPI
                  input={props.input}
                  config={apiConfig.config}
                  updateConfig={apiConfig.updateConfig}
                  context={props.context}
                  updateContext={props.updateContext}
                />
              </div>
              <S.TabWrapperColumnSectionHeader>
                Used By
              </S.TabWrapperColumnSectionHeader>
              <div style={{flex: '1 1 auto'}}>
                <PanelArtifactVersionConsumptionTable
                  input={artifactVersionNode}
                  config={apiConfig.config}
                  updateConfig={apiConfig.updateConfig}
                  context={props.context}
                  updateContext={props.updateContext}
                />
              </div>
            </S.TabWrapperColumn>
          ),
        },
        {
          menuItem: {
            key: 'files',
            content: 'Files',
          },
          render: () => (
            <S.TabWrapper>
              <PanelArtifactVersionFiles
                input={artifactVersionNode}
                config={filesConfig.config}
                updateConfig={filesConfig.updateConfig}
                context={props.context}
                updateContext={props.updateContext}
              />
            </S.TabWrapper>
          ),
        },
        {
          menuItem: {
            key: 'lineage',
            content: 'Lineage',
          },
          render: () => (
            <S.TabWrapper>
              <PanelArtifactVersionLineage
                input={artifactVersionNode}
                config={lineageConfig.config}
                updateConfig={lineageConfig.updateConfig}
                context={props.context}
                updateContext={props.updateContext}
              />
            </S.TabWrapper>
          ),
        },
        ...(isWeave && !loading && weaveEcosystemEnabled
          ? [
              {
                menuItem: {
                  key: 'weave',
                  content: 'Weave',
                },
                render: () => (
                  <S.TabWrapper>
                    <PanelWeaveArtifactObject
                      input={artifactVersionNode}
                      config={weaveConfig.config}
                      updateConfig={weaveConfig.updateConfig}
                      context={props.context}
                      updateContext={props.updateContext}
                    />
                  </S.TabWrapper>
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
