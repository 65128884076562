import {MatchParams} from '@wandb/common/types/base';
import {useLocation} from 'react-router-dom';

import {useSearchNavQueryQuery} from './../../generated/graphql';
import {getRouteMatch} from './../../routes/utils';
import {decodeURIComponentSafe} from './../../util/url';
import {useReports} from './useReports';
import {extractProjects, extractTeams} from './utils';

export const useLauncherData = (searchTerm: string) => {
  const location = useLocation();

  const match: {params: MatchParams} = getRouteMatch(location.pathname) ?? {
    params: {},
  };
  const {entityName, runName, sweepName} = match.params;

  const projectName =
    match.params.projectName != null
      ? decodeURIComponentSafe(match.params.projectName)
      : undefined;

  const {data: reportData} = useReports(searchTerm.toLowerCase());
  const {data: searchNavData} = useSearchNavQueryQuery({
    variables: {
      entityName: entityName ?? '',
      projectName: projectName ?? '',
      runName: runName ?? '',
      sweepName: sweepName ?? '',
    },
  });

  return {
    projects: searchNavData ? extractProjects(searchNavData) : [],
    reports: reportData,
    teams: searchNavData ? extractTeams(searchNavData) : [],
  };
};
