// TODO: merge this with ArtifactSidebarSequence
import {ApolloQueryResult} from 'apollo-client';
import classnames from 'classnames';
import _ from 'lodash';
import React, {FC, MouseEventHandler, useMemo, useState} from 'react';
import {Icon} from 'semantic-ui-react';

import {
  Artifact,
  useArtifactCollectionQuery,
  useUpdateArtifactCollectionMutation,
  useUpdateArtifactPortfolioMutation,
} from '../../generated/graphql';
import * as S from '../ArtifactSidebar.styles';
import {
  DeleteArtifactModal,
  RenameArtifactModal,
} from '../ArtifactSidebarActions';
import {
  ArtifactHeaderPopupDropdown,
  ArtifactSidebarPaginate,
} from '../ArtifactSidebarSequence';
import * as SJobs from './JobSidebarSequence.styles';
interface JobMin {
  id: string;
  name: string;
}

type JobItemVersionProps = Pick<Artifact, 'state'> & {
  isSelected: boolean;
  aliases: Array<{alias: string}>;
  collectionName: string;
  onSelect: (v: string) => void;
};

const isTagVersion = (v: string) =>
  // check if it's a tag version, this is exactly how the check is done in the backend
  v.startsWith('v') &&
  v.substring(1).length > 0 &&
  Number.isInteger(Number(v.substring(1)));

const JobItemVersion: FC<JobItemVersionProps> = props => {
  const {state, aliases, isSelected, collectionName, onSelect} = props;
  const tags = aliases.map(a => a.alias);
  const version = tags.find(a => isTagVersion(a));
  const aliasesStrings = tags.filter(t => t !== version);

  return (
    <S.ArtifactItemString
      onClick={() => {
        onSelect(`${collectionName}:${version}`);
      }}
      className={classnames('artifact-sidebar-item', 'version-option', {
        'selected-option': isSelected,
        'deleted-option': state === 'DELETED',
      })}>
      <div>
        <span data-cy="artifact-version">{version} </span>
        {aliasesStrings.map(alias => (
          <S.AliasLabel data-cy="artifact-tag" key={alias}>
            {alias}
          </S.AliasLabel>
        ))}
      </div>
    </S.ArtifactItemString>
  );
};

type JobSequenceHeaderProps = JobMin & {
  entityName: string;
  projectName: string;
  isPortfolio?: boolean;
  expanded: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  refetchProjectJobs: () => Promise<ApolloQueryResult<any>>;
};

const JobSequenceHeader: FC<JobSequenceHeaderProps> = props => {
  const {
    id,
    name,
    expanded,
    entityName,
    projectName,
    isPortfolio,
    onClick,
    refetchProjectJobs,
  } = props;
  const icon = isPortfolio
    ? expanded
      ? 'folder open outline'
      : 'folder outline'
    : expanded
    ? 'caret down'
    : 'caret right';
  const [isRenaming, setRenaming] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [updateArtifactCollection] = useUpdateArtifactCollectionMutation();
  const [updateArtifactPortfolio] = useUpdateArtifactPortfolioMutation();

  return (
    <SJobs.JobVersionAndDropdownContainer className={'overview-option'}>
      <RenameArtifactModal
        name={name}
        modalOpen={isRenaming}
        onRenameArtifact={async newName => {
          if (isPortfolio) {
            await updateArtifactPortfolio({
              variables: {
                artifactPortfolioID: id,
                name: newName,
              },
            });
          } else {
            await updateArtifactCollection({
              variables: {
                artifactSequenceID: id,
                name: newName,
              },
            });
          }
          setRenaming(false);
        }}
        onClose={async () => {
          setRenaming(false);
        }}
      />
      <DeleteArtifactModal
        id={id}
        entityName={entityName}
        isModalOpen={isDeleteModalOpen}
        isPortfolio={isPortfolio}
        projectName={projectName}
        refetchProjectArtifacts={refetchProjectJobs}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
      <SJobs.JobVersionContainer onClick={onClick}>
        <Icon name={icon} />
        <span>{name}</span>
      </SJobs.JobVersionContainer>
      <div className={'overview-option-ellipses'}>
        <ArtifactHeaderPopupDropdown
          onRenameArtifact={() => setRenaming(true)}
          onDeleteArtifact={() => setIsDeleteModalOpen(true)}
        />
      </div>
    </SJobs.JobVersionAndDropdownContainer>
  );
};

const JOBS_PER_PAGE = 20;

interface JobSidebarSequenceProps extends JobMin {
  entityName: string;
  projectName: string;
  collectionTypeName: string;
  expanded: boolean;
  selectedJobVersion: string;
  onHeaderClick: MouseEventHandler;
  refetchProjectJobs: () => Promise<ApolloQueryResult<any>>;
  setSelectedJob: (v: string) => void;
}

export const JobSidebarSequence: FC<JobSidebarSequenceProps> = props => {
  const {
    name,
    expanded,
    entityName,
    projectName,
    onHeaderClick,
    setSelectedJob,
    collectionTypeName,
    selectedJobVersion,
  } = props;
  const {data, loading, refetch} = useArtifactCollectionQuery({
    variables: {
      artifactFirst: JOBS_PER_PAGE,
      entityName,
      projectName,
      artifactTypeName: 'job',
      artifactCollectionName: name,
    },
    skip: !expanded,
  });

  const artifactCollection = data?.project?.artifactType?.artifactCollection;
  const totalCount = artifactCollection?.artifacts?.totalCount;
  const pageInfo = artifactCollection?.artifactMemberships?.pageInfo;
  const artifactMemberships = useMemo(
    () =>
      artifactCollection?.artifactMemberships?.edges
        .map(n => n.node)
        .filter(n => n?.artifact != null),
    [artifactCollection]
  );
  const hasArtifacts =
    artifactMemberships != null && artifactMemberships?.length > 0;

  const fetchWithCursor = (cursor?: string) => {
    refetch({
      entityName,
      projectName,
      artifactTypeName: 'job',
      artifactCollectionName: name,
      artifactCursor: cursor,
    });
  };

  const [cursors, setCursors] = useState<string[]>([]);
  const nextPage = () => {
    const cursor = pageInfo?.endCursor;
    if (cursor != null) {
      setCursors([...cursors, cursor]);
      fetchWithCursor(cursor);
    }
  };

  const previousPage = () => {
    const tmpCursors = [...cursors];
    tmpCursors.pop();
    const cursor = _.last(tmpCursors);
    setCursors(tmpCursors);
    fetchWithCursor(cursor);
  };

  const [selectedJobCollectionName, selectedJobVersionIdentifier] =
    selectedJobVersion.split(':');

  return (
    <div>
      <JobSequenceHeader
        {...props}
        onClick={onHeaderClick}
        isPortfolio={collectionTypeName === 'ArtifactPortfolio'}
      />
      {expanded && (
        <S.ArtifactsSidebarArtifactList>
          {loading && data == null ? (
            <S.ArtifactVersionLoad>Loading jobs...</S.ArtifactVersionLoad>
          ) : (
            <>
              {!hasArtifacts && (
                <span style={{marginLeft: '10px'}}>No job versions found.</span>
              )}
              {artifactMemberships!.map(membership => {
                const artifact = membership!.artifact!;
                const aliases = membership!.aliases;
                const digest = artifact.digest!;
                const isSelected =
                  (`v${membership!.versionIndex}` ===
                    selectedJobVersionIdentifier ||
                    digest === selectedJobVersionIdentifier) &&
                  artifactCollection?.name === selectedJobCollectionName;
                return (
                  <JobItemVersion
                    key={artifact!.id}
                    {...artifact}
                    aliases={aliases}
                    isSelected={isSelected}
                    collectionName={name}
                    onSelect={setSelectedJob}
                  />
                );
              })}
            </>
          )}
          <S.ArtifactSidebarArtifactPaginate>
            <ArtifactSidebarPaginate
              page={cursors.length}
              perPage={JOBS_PER_PAGE}
              total={totalCount}
              pageInfo={pageInfo ?? {}}
              onPreviousClick={previousPage}
              onNextClick={nextPage}
            />
          </S.ArtifactSidebarArtifactPaginate>
        </S.ArtifactsSidebarArtifactList>
      )}
    </div>
  );
};
