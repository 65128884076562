import {Editor, Element, Node, Transforms} from 'slate';

import {isPanelGrid} from './panel-grid';
import {isParagraph} from './ParagraphTypes';

type PersistentBlankLineOptions = {
  disablePersistentTopLine?: boolean;
};

export const EditorWithPersistentBlankLines = {
  ensureTopBlankLine(editor: Editor): boolean {
    let firstBlock: Node | null = null;
    if (editor.children.length > 0) {
      firstBlock = Node.child(editor, 0);
    }
    if (firstBlock == null || !isParagraph(firstBlock)) {
      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [{text: ''}],
        },
        {at: [0]}
      );
      return true;
    }
    return false;
  },

  ensureBottomBlankLine(editor: Editor): boolean {
    let lastBlock: Node | null = null;
    if (editor.children.length > 0) {
      lastBlock = Node.child(editor, editor.children.length - 1);
    }
    if (
      lastBlock == null ||
      (Element.isElement(lastBlock) &&
        (editor.isVoid(lastBlock) || !Editor.isEmpty(editor, lastBlock)))
    ) {
      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [{text: ''}],
        },
        {at: [editor.children.length]}
      );
      return true;
    }
    return false;
  },

  ensureBlankLineAbovePanelGrid(editor: Editor): boolean {
    let prevNode: Node | null = null;
    for (let i = 0; i < editor.children.length; i++) {
      const node = editor.children[i];
      if (
        isPanelGrid(node) &&
        Element.isElement(prevNode) &&
        editor.isVoid(prevNode)
      ) {
        Transforms.insertNodes(
          editor,
          {
            type: 'paragraph',
            children: [{text: ''}],
          },
          {at: [i]}
        );
        return true;
      }
      prevNode = node;
    }
    return false;
  },

  ensureBlankLine(
    editor: Editor,
    {disablePersistentTopLine}: PersistentBlankLineOptions
  ): boolean {
    if (
      !disablePersistentTopLine &&
      EditorWithPersistentBlankLines.ensureTopBlankLine(editor)
    ) {
      return true;
    }
    if (EditorWithPersistentBlankLines.ensureBottomBlankLine(editor)) {
      return true;
    }
    if (EditorWithPersistentBlankLines.ensureBlankLineAbovePanelGrid(editor)) {
      return true;
    }
    return false;
  },
};

export function withPersistentBlankLine<T extends Editor>(
  opts: PersistentBlankLineOptions
): (editor: T) => T {
  return editor => {
    const {normalizeNode} = editor;

    editor.normalizeNode = entry => {
      const [node] = entry;
      if (Editor.isEditor(node)) {
        if (EditorWithPersistentBlankLines.ensureBlankLine(node, opts)) {
          return;
        }
      }

      normalizeNode(entry);
    };

    return editor;
  };
}
