import {compact} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {
  CloudProvider,
  ErrorSeverity,
  StorageBucketInfo,
  useAvailableBucketProvidersQuery,
  useStorageBucketInfosQuery,
  useTestBucketStoreConnectionMutation,
} from '../../generated/graphql';
import {propagateErrorsContext} from '../../util/errors';
import {useDebounceState} from '../../util/hooks';
import {StorageBucketInfoFormValue, StorageBucketValidState} from './types';

export const cloudProviderValuesToDisplayNames = {
  [CloudProvider.Aws]: 'AWS',
  [CloudProvider.Azure]: 'Azure',
  [CloudProvider.Gcp]: 'Google',
  [CloudProvider.Minio]: 'Other S3-compatible storage',
};

export interface AzureName {
  accountName: string;
  containerName: string;
}
export function getAzureName(
  bucketInfo?: StorageBucketInfoFormValue
): AzureName | null {
  if (!bucketInfo || bucketInfo.provider !== CloudProvider.Azure) {
    return null;
  }

  const sections = bucketInfo.name.split('/');

  return {
    accountName: sections[0] ?? '',
    containerName: sections[1] ?? '',
  };
}

export function useCloudProviderOptions(): [
  isLoading: boolean,
  options: Array<{
    value: CloudProvider;
    text: string;
  }>,
  refetch: () => void
] {
  const {data, loading, refetch} = useAvailableBucketProvidersQuery();

  return useMemo(() => {
    if (loading) {
      return [true, [], refetch];
    }

    const options = (data?.serverInfo?.availableBucketProviders || []).map(
      provider => {
        return {
          value: provider,
          text: cloudProviderValuesToDisplayNames[provider],
        };
      }
    );

    return [false, options, refetch];
  }, [data, loading, refetch]);
}

export function useStorageBucketInfoOptions(): [
  isLoading: boolean,
  options: StorageBucketInfo[],
  refetch: () => void
] {
  const {data, loading, refetch} = useStorageBucketInfosQuery({
    context: propagateErrorsContext(),
    onError: ({graphQLErrors}) => {
      graphQLErrors = graphQLErrors.filter(
        err => err.message !== 'permission denied'
      );

      if (graphQLErrors.length > 0) {
        throw graphQLErrors[0];
      }
    },
  });

  return useMemo(() => {
    if (loading) {
      return [true, [], refetch];
    }

    return [
      false,
      compact(
        data?.entities?.edges.map(
          edge => edge?.node?.settings.storageBucketInfo
        ) ?? []
      ),
      refetch,
    ];
  }, [data, loading, refetch]);
}

export interface StorageBucketInfoProps {
  /**
   * If this is false, the rest of the storage bucket input will be hidden.
   */
  usingExternalStorage: boolean;
  setUsingExternalStorage: (val: boolean) => void;

  formIsReady: boolean;

  /**
   * The bucket info seen by the parent -- might be an existing bucket (with an ID)
   * or a new bucket (without one)
   */
  bucketInfo: StorageBucketInfoFormValue;
  setBucketInfo: (val: StorageBucketInfoFormValue) => void;
  getInitialBucketInfo: () => NonNullable<StorageBucketInfoFormValue>;

  cloudProviderOptions: Array<{
    value: CloudProvider;
    text: string;
  }>;
  storageBucketInfoOptions: StorageBucketInfo[];

  isValidState: StorageBucketValidState;

  resetBucketInfo: () => void;
}

function useBucketValidState(
  bucketInfo: StorageBucketInfoFormValue,
  debouncedBucketInfo: StorageBucketInfoFormValue
): StorageBucketValidState {
  const [valid, setValid] = useState<StorageBucketValidState>({state: 'unset'});

  const latestBucketInfo = useRef<StorageBucketInfoFormValue>(bucketInfo);
  const [testBucketStoreConnectionMutation] =
    useTestBucketStoreConnectionMutation();

  useEffect(() => {
    // we need this in a ref so we can check it in a callback *without*
    // invalidating the callback on changes
    latestBucketInfo.current = bucketInfo;
  }, [bucketInfo]);

  useEffect(() => {
    (async (validatingForBucketInfo: StorageBucketInfoFormValue) => {
      if (!validatingForBucketInfo || validatingForBucketInfo.name === '') {
        return;
      }

      setValid({state: 'loading'});

      const {data} = await testBucketStoreConnectionMutation({
        variables: {
          input: {
            ...validatingForBucketInfo,
          },
        },
      });

      // this is a guard against out-of-order responses when rapidly changing bucketInfo
      const dataRepresentsCurrentBucketInfo =
        latestBucketInfo.current === validatingForBucketInfo;

      const hasErrors = (data?.testBucketStoreConnection?.length ?? 0) > 0;

      if (dataRepresentsCurrentBucketInfo && hasErrors) {
        const newValidState: StorageBucketValidState = {
          state: 'invalid',
          errors: data?.testBucketStoreConnection
            .filter(err => err.severity === ErrorSeverity.Error)
            .map(err => err.message),
          warnings: data?.testBucketStoreConnection
            .filter(err => err.severity === ErrorSeverity.Warn)
            .map(err => err.message),
        };
        setValid(newValidState);
        return;
      } else if (dataRepresentsCurrentBucketInfo) {
        setValid({
          state: 'valid',
        });
      }
    })(debouncedBucketInfo);
  }, [debouncedBucketInfo, testBucketStoreConnectionMutation]);

  if (bucketInfo !== debouncedBucketInfo) {
    return {state: 'loading'};
  }

  return valid;
}

export function useStorageBucketConfig(): StorageBucketInfoProps {
  const [usingExternalStorage, setUsingExternalStorage] = useState(false);

  const [
    bucketInfo,
    debouncedBucketInfo,
    setBucketInfo,
    setBucketInfoAndDebouncedBucketInfo,
  ] = useDebounceState<StorageBucketInfoFormValue>(null, 500);

  const [
    storageBucketInfoOptionsLoading,
    storageBucketInfoOptions,
    storageBucketInfoOptionsRefetch,
  ] = useStorageBucketInfoOptions();

  const [
    cloudProviderOptionsLoading,
    cloudProviderOptions,
    cloudProviderOptionsRefetch,
  ] = useCloudProviderOptions();

  const formIsReady =
    !storageBucketInfoOptionsLoading && !cloudProviderOptionsLoading;

  const getInitialBucketInfo = useCallback(() => {
    if (storageBucketInfoOptions.length > 0) {
      setBucketInfoAndDebouncedBucketInfo(storageBucketInfoOptions[0]);
    }

    if (cloudProviderOptions.length < 1) {
      throw new Error(
        `No cloud providers available for BYOB. This is an invalid state; please contact support.`
      );
    }

    return {
      name: '',
      provider: cloudProviderOptions[0].value,
    };
  }, [
    cloudProviderOptions,
    setBucketInfoAndDebouncedBucketInfo,
    storageBucketInfoOptions,
  ]);

  // when the form has loaded, select the first available bucket if there
  // is one; otherwise populate a new bucket with an empty name and the
  // first available cloud provider
  useEffect(() => {
    if (!formIsReady || bucketInfo !== null) {
      return;
    }

    setBucketInfoAndDebouncedBucketInfo(getInitialBucketInfo());
  }, [
    bucketInfo,
    formIsReady,
    getInitialBucketInfo,
    setBucketInfoAndDebouncedBucketInfo,
  ]);

  const reset = useCallback(() => {
    setBucketInfoAndDebouncedBucketInfo(null);
    cloudProviderOptionsRefetch();
    storageBucketInfoOptionsRefetch();
  }, [
    cloudProviderOptionsRefetch,
    setBucketInfoAndDebouncedBucketInfo,
    storageBucketInfoOptionsRefetch,
  ]);

  const validState = useBucketValidState(bucketInfo, debouncedBucketInfo);

  return {
    usingExternalStorage,
    setUsingExternalStorage,

    formIsReady,

    bucketInfo: usingExternalStorage ? bucketInfo : null,
    setBucketInfo,
    getInitialBucketInfo,

    cloudProviderOptions,
    storageBucketInfoOptions,

    isValidState: validState,

    resetBucketInfo: reset,
  };
}
