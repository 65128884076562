import * as Obj from '@wandb/cg';
import {WBSuggesterOptionFetcher} from '@wandb/common/components/elements/WBSuggester';
import {parse} from 'graphql';
import React from 'react';

import {apolloClient} from '../../apolloClient';
import * as ApiSchemaTypes from '../../generated/apiSchema';
import * as Generated from '../../generated/graphql';
import {ProjectFieldsQueryData} from '../../state/graphql/projectFieldsQuery';
import {useRunsQueryContext} from '../../state/runs/hooks';
import {useApolloClient2} from '../../util/apollo2-hooks';
import {propagateErrorsContext} from '../../util/errors';
import {serverPathToKey} from '../../util/runs';
import {QueryArg, QueryTemplateArg} from '../../util/vega3';
import * as VegaLib3 from '../../util/vega3';
import * as S from './QueryArgEditor.styles';
import * as QueryEditorStyles from './QueryEditor.styles';
import QueryEditorDeleteButton from './QueryEditorDeleteButton';
import QueryInputValueEditor from './QueryInputValueEditor';

interface QueryArgEditorProps {
  argIndex: number;
  schemaArg: ApiSchemaTypes.__InputValue;
  arg: QueryArg;
  fixedArg?: QueryArg;
  templateArg?: QueryTemplateArg;
  fieldName: string;
  expandedView?: boolean;
  indentLevel: number;
  charsBefore: number;
  tableName?: string;
  setArg(arg: QueryArg): void;
  deleteArg(): void;
}
/* eslint-disable no-template-curly-in-string */
const QueryArgEditor: React.FC<QueryArgEditorProps> = props => {
  const [hoveringDelete, setHoveringDelete] = React.useState(false);
  let type = props.schemaArg.type;
  let required = false;
  if (props.schemaArg.type.kind === 'NON_NULL') {
    type = props.schemaArg.type.ofType!;
    required = true;
  }
  const isFixed = props.fixedArg != null || props.templateArg != null;
  const context = useRunsQueryContext();
  const client2 = useApolloClient2();

  function patternFromQuery(q: string) {
    if (q === '') {
      return null;
    }
    return (
      '%' +
      q
        .split('')
        .map(c => ('%?_'.indexOf(c) !== -1 ? '\\' + c : c))
        .join('%') +
      '%'
    );
  }

  let autocompleteOptions: WBSuggesterOptionFetcher | undefined;
  if (props.arg.name === 'keys' || props.arg.name === 'extraKeys') {
    switch (props.fieldName) {
      case 'config':
      case 'configFold':
        autocompleteOptions = async query => {
          const data = await apolloClient.query<ProjectFieldsQueryData>({
            query: Generated.ProjectFieldsDocument,
            variables: {
              projectName: context.projectName,
              entityName: context.entityName,
              types: ['number', 'string'],
              columns: ['config'],
              pattern: patternFromQuery(query),
              count: 50,
            },
          });
          const keys = data.data.project.fields.edges
            .map(e => e.node.path)
            .map(serverPathToKey)
            .filter(Obj.notEmpty)
            .map(k => k.name)
            .filter(k => !k.startsWith('_wandb'))
            .map(k => k.replace('.value', ''));
          return {
            options: keys.map(k => ({
              value: k,
            })),
          };
        };
        break;
      case 'summary':
      case 'summaryFold':
        autocompleteOptions = async query => {
          const data = await apolloClient.query<ProjectFieldsQueryData>({
            query: Generated.ProjectFieldsDocument,
            variables: {
              projectName: context.projectName,
              entityName: context.entityName,
              types: ['number', 'string'],
              columns: ['summary_metrics'],
              pattern: patternFromQuery(query),
              count: 50,
            },
          });
          const keys = data.data.project.fields.edges
            .map(e => e.node.path)
            .map(serverPathToKey)
            .filter(Obj.notEmpty)
            .map(k => k.name);
          return {
            options: keys.map(k => ({
              value: k,
            })),
          };
        };
        break;
      case 'history':
      case 'historyFold':
      case 'historyTable':
        autocompleteOptions = async query => {
          const data = await apolloClient.query<Generated.HistoryKeysQuery>({
            query: Generated.HistoryKeysDocument,
            variables: {
              projectName: context.projectName,
              entityName: context.entityName,
            },
          });
          const allKeys = Object.keys(
            data.data.project?.runs?.historyKeys?.keys ?? {}
          ).sort();
          let sets = (data.data.project?.runs?.historyKeys?.sets ?? []).map(
            (s: any) => s.keys
          ) as string[][];

          const current = (props.arg.value as string[]).filter(
            k => !query.includes(k)
          );

          sets = sets.filter(set => {
            const sset = new Set(set);
            return current.every(av => sset.has(av));
          });

          const keys = [...new Set(sets.flat())];
          keys.sort();

          // fall back to all keys when keys result is empty
          const options = (keys.length > 0 ? keys : allKeys)
            .filter(
              k => k?.includes?.(query) && !current.includes(k) && k !== '_step'
            )
            .map(k => ({value: k}));

          return {options};
        };
        break;
    }
  } else if (props.arg.name === 'tableKey') {
    switch (props.fieldName) {
      case 'summaryTable':
        autocompleteOptions = async query => {
          const data = await apolloClient.query<ProjectFieldsQueryData>({
            query: Generated.ProjectFieldsDocument,
            variables: {
              projectName: context.projectName,
              entityName: context.entityName,
              types: ['table', 'table-file'],
              columns: ['summary_metrics'],
              pattern: patternFromQuery(query),
              count: 50,
            },
          });
          const keys = data.data.project.fields.edges
            .map(e => e.node.path)
            .map(serverPathToKey)
            .filter(Obj.notEmpty)
            .map(k => k.name);
          return {
            options: keys.map(k => ({
              value: k,
            })),
          };
        };
        break;
      case 'historyTable':
        autocompleteOptions = async query => {
          const data = await apolloClient.query<Generated.HistoryKeysQuery>({
            query: Generated.HistoryKeysDocument,
            variables: {
              projectName: context.projectName,
              entityName: context.entityName,
            },
          });

          const keyMap = (data.data.project?.runs?.historyKeys?.keys ??
            {}) as any;

          let keys = Object.keys(keyMap);

          keys = keys.filter(
            k =>
              keyMap[k].typeCounts.find(
                (tc: any) => tc.type === 'table-file' || tc.type === 'table'
              ) != null
          );

          return {
            options: keys.filter(k => k.includes(query)).map(k => ({value: k})),
          };
        };
    }
  } else if (props.arg.name === 'tableColumns' && props.tableName != null) {
    autocompleteOptions = async query => {
      const userQuery: VegaLib3.Query = {
        queryFields: [
          {
            name: 'runSets',
            args: [{name: 'runSets', value: '${runSets}'}],
            fields: [
              {
                name: 'summaryTable',
                args: [{name: 'tableKey', value: props.tableName}],
                fields: [],
              },
            ],
          },
        ],
      };
      const templateVals = {
        '${runSets}': [
          {
            entityName: context.entityName,
            projectName: context.projectName,
          },
        ],
      };

      const stringQuery = VegaLib3.toGraphql(userQuery, templateVals);

      const queryResult = await client2.query({
        query: parse(stringQuery),
        context: propagateErrorsContext(),
        fetchPolicy: 'no-cache',
      });

      const result = queryResult
        ? [VegaLib3.stripResultObj(queryResult.data)]
        : [];
      const {cols} = VegaLib3.transformQueryResultToTable(
        userQuery,
        VegaLib3.DEFAULT_TRANSFORM,
        result
      );

      return {
        options: cols.filter(k => k?.includes?.(query)).map(k => ({value: k})),
      };
    };
  }

  return (
    <>
      <S.ParamWrapper expanded={props.expandedView}>
        {!required && !isFixed && (
          <QueryEditorDeleteButton
            onMouseEnter={() => setHoveringDelete(true)}
            onMouseLeave={() => setHoveringDelete(false)}
            onClick={() => {
              props.deleteArg();
            }}></QueryEditorDeleteButton>
        )}
        <QueryEditorStyles.Fadeable fade={hoveringDelete}>
          <QueryEditorStyles.ParamSpan>
            {props.schemaArg.name}
          </QueryEditorStyles.ParamSpan>
        </QueryEditorStyles.Fadeable>
      </S.ParamWrapper>
      <span>:&nbsp;</span>
      <QueryEditorStyles.Fadeable fade={hoveringDelete}>
        <QueryInputValueEditor
          autocompleteOptions={autocompleteOptions}
          type={type}
          val={props.arg.value}
          disabled={isFixed}
          isTemplate={props.templateArg != null}
          indentLevel={props.indentLevel}
          charsBefore={props.charsBefore + props.schemaArg.name.length + 2}
          setVal={v => {
            props.setArg({...props.arg, value: v});
          }}
        />
      </QueryEditorStyles.Fadeable>
    </>
  );
};

export default QueryArgEditor;
