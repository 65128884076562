import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import styled from 'styled-components';

export const Bold = styled.b`
  font-weight: 600;
`;

export const DeleteButton = styled(WBIcon)`
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SectionHeader = styled.h3<{smallMargin?: boolean}>`
  margin-bottom: ${p => (p.smallMargin ? 8 : 16)}px;
  opacity: 0.7;
`;

export const SectionSubheader = styled.div<{smallMargin?: boolean}>`
  font-size: 14px;
  color: ${globals.gray600};
  margin-bottom: ${p => (p.smallMargin ? 8 : 16)}px;
`;
