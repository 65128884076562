import config from '@wandb/common/config';
import makeComp from '@wandb/common/util/profiler';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import {storagePage} from '../../util/urls';
import StoragePercentage from '../StoragePercentage';
import {useUserSettingsContext} from './UserSettingsContext';
import {UserSettingsPlaceholder} from './UserSettingsPage';

export const UserSettingsStorage: FC = makeComp(
  () => {
    const {viewer} = useUserSettingsContext();
    if (viewer == null) {
      return <UserSettingsPlaceholder />;
    }
    return (
      <>
        <StoragePercentage
          available={config.MAX_BYTES}
          used={viewer.userEntity.storageBytes}
        />
        <Link to={storagePage(viewer.entity)}>
          <Button style={{marginTop: 10}}>Manage Storage</Button>
        </Link>
      </>
    );
  },
  {id: 'UserSettingsStorage', memo: true}
);
