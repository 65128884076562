import {toast} from '@wandb/common/components/elements/Toast';
import WandbLoader from '@wandb/common/components/WandbLoader';
import {Match} from '@wandb/common/types/base';
import {History as RHistory} from 'history';
import React, {useCallback, useState} from 'react';

import LaunchPage from '../components/Launch';
import {useUpsertModelMutation} from '../generated/graphql';
import {useProjectPageQuery} from '../state/graphql/projectPageQuery';
import {useViewer} from '../state/viewer/hooks';

type EntityLaunchPageProps = {match: Match; history: RHistory};

export const DEFAULT_LAUNCH_PROJECT_NAME = 'model-registry';

export const EntityLaunchPage: React.FC<EntityLaunchPageProps> = ({
  match,
  history,
}) => {
  const {entityName} = match.params;
  const path = match.path.split('/');
  const tab =
    path[path.length - 1] === 'launch' ? undefined : path[path.length - 1];
  const viewer = useViewer();
  const launchEntity = entityName != null ? entityName : viewer?.username;
  const projectQuery = useProjectPageQuery(
    {
      projectName: DEFAULT_LAUNCH_PROJECT_NAME,
      entityName: launchEntity,
    },
    {
      skip: launchEntity == null,
      enablePolling: true,
    }
  );
  const [created, setCreated] = useState(false);
  const [upsertProject] = useUpsertModelMutation();
  const onNoProject = useCallback(async () => {
    if (
      !projectQuery.initialLoading &&
      !projectQuery.loading &&
      projectQuery.project == null &&
      !created
    ) {
      setCreated(true);
      const createResp = await upsertProject({
        variables: {
          entityName,
          name: DEFAULT_LAUNCH_PROJECT_NAME,
        },
      });
      if (createResp.errors != null) {
        toast('Error loading Launch page. Please try again.');
      }
      await projectQuery.refetch();
    }
  }, [entityName, upsertProject, projectQuery, created]);

  // TODO: Inform user about why this page is blank with an empty page
  if (launchEntity == null) {
    return null;
  }
  if (projectQuery.loading) {
    return <WandbLoader />;
  }
  const project = projectQuery.project;
  if (project == null) {
    onNoProject();
    return <WandbLoader />;
  }

  return (
    <LaunchPage
      tab={tab}
      project={project}
      entityName={launchEntity}
      projectLoading={projectQuery.loading}
      fromLaunchEntityPage
      refetchProject={projectQuery.refetch}
    />
  );
};

export default EntityLaunchPage;
