/**
 * These tables currently aren't used because they aren't
 * functional enough yet.
 */

import React from 'react';
import {Editor, Element, Node, Point, Range} from 'slate';
import {RenderElementProps} from 'slate-react';

import {BlockWrapper} from './drag-drop';
import * as S from './tables.styles';

type AlignType = 'left' | 'right' | 'center' | null;
export interface Table extends Element {
  type: 'table';
  align?: AlignType[];
}

export const isTable = (node: Node): node is Table => node.type === 'table';

export const TableElement: React.FC<RenderElementProps & {element: Table}> = ({
  attributes,
  element,
  children,
}) => {
  return (
    <BlockWrapper attributes={attributes} element={element}>
      <S.TableWrapper>
        <S.Table>
          <tbody>{children}</tbody>
        </S.Table>
      </S.TableWrapper>
    </BlockWrapper>
  );
};

export interface TableRow extends Element {
  type: 'table-row';
}

export const isTableRow = (node: Node): node is TableRow =>
  node.type === 'table-row';

export const TableRowElement: React.FC<
  RenderElementProps & {
    element: TableRow;
  }
> = ({attributes, element, children}) => {
  return <S.TableRow {...attributes}>{children}</S.TableRow>;
};

export interface TableCell extends Element {
  type: 'table-cell';
}

export const isTableCell = (node: Node): node is TableCell =>
  node.type === 'table-cell';

export const TableCellElement: React.FC<
  RenderElementProps & {
    element: TableCell;
  }
> = ({attributes, element, children}) => {
  return <S.TableCell {...attributes}>{children}</S.TableCell>;
};

export const withTables = <T extends Editor>(editor: T) => {
  const {deleteBackward, deleteForward, insertBreak} = editor;

  editor.deleteBackward = unit => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && isTableCell(n),
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = unit => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && isTableCell(n),
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const {selection} = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && isTable(n),
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  return editor;
};
