import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import produce from 'immer';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Input} from 'semantic-ui-react';

import {useUserProjectsQuery} from '../../generated/graphql';
import {useSelector} from '../../state/hooks';
import * as ReportHooks from '../../state/reports/hooks';
import {useViewer} from '../../state/viewer/hooks';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankSectionConfigActions from '../../state/views/panelBankSectionConfig/actions';
import {SectionNormalized} from '../../state/views/section/types';
import {getPanelSpec, LayedOutPanelWithRef} from '../../util/panels';
import {keyExistsInQueryString} from '../../util/query-string';
import {getTeamsFromUserProjectsQuery} from '../CreateReportModal';
import * as S from './ImportPanelMenu.styles';
import {IMPORT_PANEL_QS} from './types';

const INITIAL_PROJECT_COUNT = 5;
let importPanelTooltipShown = false;

interface ImportPanelMenuProps {
  entityName: string;
  projectName: string;
  sectionPart: SectionNormalized;
  viewRefID: string;
}
interface PanelItem {
  name: string;
  panel: LayedOutPanelWithRef;
}

interface SectionPanels {
  sectionName: string;
  panels: PanelItem[];
}

const ImportPanelMenu = React.memo((props: ImportPanelMenuProps) => {
  const [projectName, setProjectName] = useState<string>(props.projectName);
  const [entityName, setEntityName] = useState<string>(props.entityName);
  const [projectSearchQuery, setProjectSearchQuery] = useState<string>('');
  const [panelSearchQuery, setPanelSearchQuery] = useState<string>('');
  const [selectedPanelRefs, setSelectedPanelRefs] = useState<
    Map<string, PanelTypes.Ref>
  >(new Map());
  const [showAllProjects, setShowAllProjects] = useState<boolean>(false);
  const inputRef = useRef<Input | null>(null);
  const lineRefs = useRef<HTMLElement[]>([]);
  const [cursor, setCursor] = useState<number>(0);

  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  useEffect(() => {
    if (keyExistsInQueryString(IMPORT_PANEL_QS) && !importPanelTooltipShown) {
      window.analytics?.track(`Import Panel Tooltip Viewed`, {
        location: `import panel menu`,
      });
      setSearchPopupOpen(true);
      importPanelTooltipShown = true;
    }
  }, []);

  const viewer = useViewer();

  const view = useSelector(state => state.views.views[props.viewRefID]);
  const reportID = view.id;
  if (reportID == null) {
    throw new Error('invalid state');
  }

  const addPanelWithoutRef = ViewHooks.useViewAction(
    props.sectionPart.panelBankSectionConfigRef,
    PanelBankSectionConfigActions.addPanelWithoutRef
  );
  const deletePanel = ViewHooks.useViewAction(
    props.sectionPart.panelBankSectionConfigRef,
    PanelBankSectionConfigActions.deletePanel
  );

  const projectFilterFn = useCallback(
    (project: string) => {
      return (
        project.toLowerCase().indexOf(projectSearchQuery.toLowerCase()) > -1
      );
    },
    [projectSearchQuery]
  );
  const panelFilterFn = useCallback(
    (panel: PanelItem) => {
      return (
        panel.name.toLowerCase().indexOf(panelSearchQuery.toLowerCase()) > -1
      );
    },
    [panelSearchQuery]
  );

  // autofocus the input for panel search with timeout to prevent auto scrolling to top
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  const userProjectsQuery = useUserProjectsQuery({
    variables: {userName: viewer?.username ?? '', includeReports: false},
    skip: !viewer?.username,
  });

  const entityProjects: Map<string, string[]> = useMemo(() => {
    if (userProjectsQuery.data == null) {
      return new Map<string, string[]>();
    }

    const entityProjectsMap = new Map<string, string[]>();
    const teams = getTeamsFromUserProjectsQuery(userProjectsQuery);
    for (const t of teams) {
      if (!t.projects || t.projects.edges.length < 1) {
        continue;
      }
      const entity = t.name;
      const projectItems: string[] =
        t?.projects?.edges.map(e => {
          return e.node?.name ?? '';
        }) ?? [];
      // sort and put the current project to the front of project list
      const sortedProjectItems =
        entity === props.entityName
          ? _.sortBy(projectItems, name => (name === props.projectName ? 0 : 1))
          : projectItems;
      entityProjectsMap.set(entity, sortedProjectItems);
    }
    return entityProjectsMap;
  }, [userProjectsQuery, props.projectName, props.entityName]);

  const projectCount = useMemo(() => {
    let count = 0;
    entityProjects.forEach(
      (projectList: string[]) => (count += projectList.length)
    );
    return count;
  }, [entityProjects]);

  const filteredEntityProjects = useMemo(() => {
    let originalEntityProjects = entityProjects;
    const newEntityProjects = new Map<string, string[]>();

    // if "View all" not clicked, we show the first 5 projects from the user
    if (!showAllProjects) {
      const userProjectList = originalEntityProjects.get(props.entityName);
      if (userProjectList != null && userProjectList.length > 0) {
        originalEntityProjects = new Map<string, string[]>();
        const projectList =
          userProjectList.length <= INITIAL_PROJECT_COUNT
            ? userProjectList
            : userProjectList.slice(0, INITIAL_PROJECT_COUNT);
        originalEntityProjects.set(props.entityName, projectList);
      }
    }

    // filter out projects by search query for project name
    originalEntityProjects.forEach((projectItems, entity) => {
      const filteredProjectItems = projectItems.filter(projectFilterFn);
      if (filteredProjectItems.length > 0) {
        newEntityProjects.set(entity, filteredProjectItems);
      }
    });
    return newEntityProjects;
  }, [entityProjects, showAllProjects, projectFilterFn, props.entityName]);

  useEffect(() => {
    // if projectName is not in filteredEntityProjects, set projectName and entityName
    // to the first thing in filteredEntityProjects
    const projectList = filteredEntityProjects.get(entityName);
    const exists = projectList?.some(pn => pn === projectName) ?? false;

    if (exists) {
      return;
    }

    const firstEntity: string = filteredEntityProjects.keys().next().value;
    const firstProjectList = filteredEntityProjects.get(firstEntity);
    if (!!firstProjectList && firstProjectList.length > 0) {
      setEntityName(firstEntity);
      setProjectName(firstProjectList[0]);
    }
    // eslint-disable-next-line
  }, [filteredEntityProjects]);

  const loadSpecQuery = ReportHooks.useLoadSpec(
    entityName,
    projectName,
    'project-view',
    viewer?.name
  );

  // create a map for sections and panels based on selected entityName and projectName
  const sectionPanels = useMemo(() => {
    if (loadSpecQuery.loading || !loadSpecQuery.loadedSpec) {
      return new Array<SectionPanels>();
    }

    const sectionPanelsList = new Array<SectionPanels>();
    const sections =
      loadSpecQuery.loadedSpec?.section?.panelBankConfig?.sections ?? [];
    sections.forEach((section: any) => {
      if (!section.panels || section.panels.length === 0) {
        return;
      }

      const panelItemList: PanelItem[] = section.panels.map(
        (p: LayedOutPanelWithRef) => {
          const panelSpec = getPanelSpec(p.viewType);
          const panelName =
            panelSpec.getTitleFromConfig?.(p.config as any) || panelSpec.type;
          return {name: panelName, panel: p} as PanelItem;
        }
      );

      panelItemList.sort((p1, p2) => {
        if (p1.name.toLowerCase() < p2.name.toLowerCase()) {
          return -1;
        }
        if (p1.name.toLowerCase() > p2.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const sectionPanelItem: SectionPanels = {
        sectionName: section.name,
        panels: panelItemList,
      };
      sectionPanelsList.push(sectionPanelItem);
    });

    return sectionPanelsList;
  }, [loadSpecQuery]);

  const filteredSectionPanels = useMemo(() => {
    const newSectionPanels = new Array<SectionPanels>();
    sectionPanels.forEach(sectionPanelItem => {
      const filteredItem: SectionPanels = {
        sectionName: sectionPanelItem.sectionName,
        panels: sectionPanelItem.panels.filter(panelFilterFn),
      };
      newSectionPanels.push(filteredItem);
    });
    return newSectionPanels;
  }, [sectionPanels, panelFilterFn]);

  const totalPanelCount = useMemo(() => {
    let count = 0;
    filteredSectionPanels.forEach(sectionPanelItem => {
      count += sectionPanelItem.panels.length;
    });
    return count;
  }, [filteredSectionPanels]);

  const onSelectPanel = useCallback(
    (panel: LayedOutPanelWithRef) => {
      const alreadyAddedPanelRef = selectedPanelRefs.get(panel.ref.id);
      if (alreadyAddedPanelRef != null) {
        deletePanel(alreadyAddedPanelRef);
        const newSelectedPanelRefs = produce(selectedPanelRefs, draft => {
          draft.delete(panel.ref.id);
          return draft;
        });
        setSelectedPanelRefs(newSelectedPanelRefs);
      } else {
        window.analytics?.track(`Panel Imported To Report`, {
          location: `import panel menu`,
        });
        const addNewPanelRefCallback = (
          originalPanel: LayedOutPanelWithRef,
          newPanelRef: PanelTypes.Ref
        ) => {
          const newSelectedPanelRefs = produce(selectedPanelRefs, draft =>
            draft.set(originalPanel.ref.id, newPanelRef)
          );
          setSelectedPanelRefs(newSelectedPanelRefs);
        };
        addPanelWithoutRef(panel, false, addNewPanelRefCallback);
      }
      setSearchPopupOpen(false);
    },
    [addPanelWithoutRef, deletePanel, selectedPanelRefs]
  );

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        const panelItems: PanelItem[] = [];
        filteredSectionPanels.forEach(sectionPanelItem => {
          panelItems.push(...sectionPanelItem.panels);
        });
        if (cursor >= panelItems.length) {
          return;
        }
        const selectedPanelItem = panelItems[cursor];
        onSelectPanel(selectedPanelItem.panel);
      }

      let newCursor = 0;
      if (e.key === 'ArrowUp' && cursor > 0) {
        newCursor = cursor - 1;
      } else if (e.key === 'ArrowUp' && cursor === 0) {
        newCursor = totalPanelCount - 1;
      } else if (e.key === 'ArrowDown' && cursor < totalPanelCount - 1) {
        newCursor = cursor + 1;
      } else if (e.key === 'ArrowDown' && cursor === totalPanelCount - 1) {
        newCursor = 0;
      } else {
        return;
      }

      if (lineRefs.current[newCursor] != null) {
        lineRefs.current[newCursor].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
      setCursor(newCursor);
    },
    [totalPanelCount, cursor, filteredSectionPanels, onSelectPanel]
  );

  return (
    <S.Wrapper>
      <S.ProjectSection>
        <S.SearchWrapper>
          <Input
            value={projectSearchQuery}
            transparent
            onChange={(e, {value}) => setProjectSearchQuery(value)}
            icon={<LegacyWBIcon name="search" size="large" />}
            iconPosition="left"
            placeholder="Search projects"
          />
        </S.SearchWrapper>
        <S.ScrollableMenu>
          {Array.from(filteredEntityProjects).map(([entity, projects]) => {
            return (
              <React.Fragment key={entity}>
                <S.ListHeader>{entity}</S.ListHeader>
                <S.List>
                  {projects.map((pn, i) => {
                    return (
                      <S.ProjectItem
                        selected={pn === projectName}
                        key={`project-option-${i}`}
                        onClick={(e: any) => {
                          setProjectName(pn);
                          setEntityName(entity);
                        }}>
                        {pn}
                      </S.ProjectItem>
                    );
                  })}
                </S.List>
              </React.Fragment>
            );
          })}
          {!showAllProjects && (
            <S.ViewAllWrapper onClick={() => setShowAllProjects(true)}>
              View all ({projectCount})
            </S.ViewAllWrapper>
          )}
        </S.ScrollableMenu>
      </S.ProjectSection>
      <S.HorizontalDivider />
      <S.PanelSection>
        <S.SearchPopup
          open={searchPopupOpen}
          position={`top center`}
          offset={-36}
          trigger={
            <S.SearchWrapper>
              <Input
                value={panelSearchQuery}
                transparent
                ref={inputRef}
                onKeyDown={onKeyDown}
                onChange={(e, {value}) => setPanelSearchQuery(value)}
                icon={<LegacyWBIcon name="search" size="large" />}
                iconPosition="left"
                placeholder="Search panels"
              />
            </S.SearchWrapper>
          }>
          Pick some charts to add to the report.
        </S.SearchPopup>
        <S.ScrollableMenu>
          {sectionPanels.length > 0 ? (
            filteredSectionPanels.map((sectionPanelItem, sectionIndex, arr) => {
              const {sectionName, panels} = sectionPanelItem;
              // add panel counts of each section before this section
              // to use cursor index to select panel with key up/down
              let panelCounts = 0;
              arr.forEach((value, i) => {
                if (i < sectionIndex) {
                  panelCounts += value.panels.length;
                }
              });

              if (panels.length === 0) {
                return null;
              }

              return (
                <React.Fragment key={sectionIndex}>
                  <S.ListHeader>{sectionName}</S.ListHeader>
                  <S.List>
                    {panels.map((item, i) => {
                      const panelIndex = i + panelCounts;
                      return (
                        <S.PanelItem
                          key={`panel-option-${panelIndex}`}
                          ref={val => {
                            if (val != null) {
                              lineRefs.current[panelIndex] = val;
                            }
                          }}
                          selected={selectedPanelRefs.has(item.panel.ref.id)}
                          hover={panelIndex === cursor}
                          onClick={(e: any) => {
                            onSelectPanel(item.panel);
                          }}>
                          <S.TextWrapper>{item.name}</S.TextWrapper>
                          {selectedPanelRefs.has(item.panel.ref.id) && (
                            <S.CheckIconWrapper>
                              <S.CheckIcon name="check" />
                            </S.CheckIconWrapper>
                          )}
                        </S.PanelItem>
                      );
                    })}
                  </S.List>
                </React.Fragment>
              );
            })
          ) : (
            <S.ListHeader>No charts available for this project</S.ListHeader>
          )}
        </S.ScrollableMenu>
      </S.PanelSection>
    </S.Wrapper>
  );
});

export default ImportPanelMenu;
