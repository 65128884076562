import {createContext} from 'react';
import {Node} from 'slate';

interface WBSlateDragDropContextValue {
  parentViewId?: string;
  dragItem?: Node;
  dragHandleMenuOpen: boolean; // True if any element's drag handle menu is open, disables popups on the other drag handles
  setDragItem(node: Node | undefined): void;
  setDragHandleMenuOpen(open: boolean): void;
}

// TODO - Add the Provider logic into this file and change filename as well
export const WBSlateDragDropContext =
  createContext<WBSlateDragDropContextValue>({} as any);
