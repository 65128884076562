import {envIsCloudOnprem, envIsLocal} from '@wandb/common/config';
import React from 'react';

import {OrgType} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {navigateTo} from '../util/history';
import {CreateTeamButton} from './CreateTeamModal';
import * as S from './InviteTeamButton.styles';

export const InviteTeamButton = () => {
  const viewer = useViewer();

  if (viewer == null) {
    return <></>;
  }

  const hasNonPersonalOrgs = viewer.organizations.find(
    o => o.orgType !== OrgType.Personal
  );

  if (hasNonPersonalOrgs) {
    return <></>;
  }

  if (envIsLocal || envIsCloudOnprem) {
    // In local/onprem, we don't use /create-team.
    return (
      <CreateTeamButton
        onCreate={() => window.location.reload()}
        renderButton={onClick => (
          <S.Button
            onClick={() => {
              onClick();
              window.analytics?.track('Create Team Started', {
                location: 'search nav',
              });
            }}>
            Create Team
          </S.Button>
        )}
      />
    );
  }

  return (
    <S.Button
      onClick={() => {
        navigateTo({pathname: '/create-team'});
        window.analytics?.track('Create Team Started', {
          location: 'search nav',
        });
      }}>
      Create Team
    </S.Button>
  );
};
