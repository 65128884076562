import React, {ReactChild} from 'react';

import {IconClose} from '../Icon';
import {
  LABEL_COLORS,
  LABEL_MODES,
  LABEL_VARIANTS,
  LabelColor,
  LabelMode,
  LabelVariant,
} from './Label.constants';
import {StyledLabel} from './Label.styles';

export interface LabelProps {
  /** Label Variant */
  variant?: LabelVariant;
  /** Label Color */
  color?: LabelColor;
  /** Label Mode */
  mode?: LabelMode;
  /** What renders inside the label */
  children?: ReactChild;
  /** An Icon to render at the beginning of the Label */
  Icon?: ReactChild;
  /** Function to run when closing a Tag Label */
  onClose?: () => void;
  /** Whether or not to show the CloseIcon */
  showCloseIcon?: boolean;
  /** Icon to render at the end of the TagLabel */
  CloseIcon?: ReactChild;
}

export const Label = ({
  children,
  Icon,
  variant = LABEL_VARIANTS.PILL,
  color = LABEL_COLORS.GRAY,
  mode = LABEL_MODES.NIGHT,
  onClose,
  showCloseIcon = true,
  CloseIcon = <IconClose onClick={onClose} width="16px" height="16px" />,
  ...props
}: LabelProps) => {
  return (
    <StyledLabel variant={variant} mode={mode} color={color} {...props}>
      {Icon}
      {children}
      {variant === LABEL_VARIANTS.TAG && showCloseIcon && CloseIcon}
    </StyledLabel>
  );
};

// MODES
export const LightLabel = (props: LabelProps) => (
  <Label mode={LABEL_MODES.LIGHT} {...props} />
);
export const NightLabel = (props: LabelProps) => (
  <Label mode={LABEL_MODES.NIGHT} {...props} />
);

// VARIANTS
export const PillLabel = (props: LabelProps) => (
  <Label variant={LABEL_VARIANTS.PILL} {...props} />
);
export const TagLabel = (props: LabelProps) => (
  <Label variant={LABEL_VARIANTS.TAG} {...props} />
);

// COLORS
export const GreenLabel = (props: LabelProps) => (
  <Label color={LABEL_COLORS.GREEN} {...props} />
);
export const TealLabel = (props: LabelProps) => (
  <Label color={LABEL_COLORS.TEAL} {...props} />
);
export const SiennaLabel = (props: LabelProps) => (
  <Label color={LABEL_COLORS.SIENNA} {...props} />
);
export const GoldLabel = (props: LabelProps) => (
  <Label color={LABEL_COLORS.GOLD} {...props} />
);
export const RedLabel = (props: LabelProps) => (
  <Label color={LABEL_COLORS.RED} {...props} />
);
export const GrayLabel = (props: LabelProps) => (
  <Label color={LABEL_COLORS.GRAY} {...props} />
);
