import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import _ from 'lodash';
import React, {FC, useCallback, useState} from 'react';
import {Button, Divider, Input, List, Popup} from 'semantic-ui-react';

import {
  MultiStateCheckbox,
  MultiStateCheckboxCheckedState,
} from '../MultiStateCheckbox';
import {Tag as TagLabel} from '../Tags';

export interface TagState {
  name: string;
  colorIndex: number;
  count: number;
}

interface BulkTagEditorProps {
  selectedCount: number;
  isInReport?: boolean;
  tags: TagState[];
  addTag(tag: string): void;
  removeTag(tag: string): void;
}

const DEFAULT_TAGS = ['baseline', 'hidden'];

function countToCheckState(
  count: number,
  selectedCount: number
): MultiStateCheckboxCheckedState {
  if (count === 0) {
    return 'unchecked';
  } else if (count < selectedCount) {
    return 'partial';
  } else {
    return 'checked';
  }
}

const BulkTagEditor: FC<BulkTagEditorProps> = React.memo(
  ({selectedCount, isInReport, tags, addTag, removeTag}) => {
    const [open, setOpen] = useState(false);
    const [tagSearch, setTagSearch] = useState('');

    const renderTag = useCallback(
      (tag: TagState, count: number, isNew: boolean) => {
        const addOnClick = tag.count === 0 || tag.count < count;
        return (
          <List.Item key={tag.name}>
            <MultiStateCheckbox
              checked={countToCheckState(tag.count, count)}
              onClick={() => {
                if (addOnClick) {
                  addTag(tag.name);
                } else {
                  removeTag(tag.name);
                }
              }}
            />
            {isNew && <span className="create-new-tag"> Create new tag </span>}
            <TagLabel tag={tag} />
          </List.Item>
        );
      },
      [addTag, removeTag]
    );

    const renderTags = useCallback(() => {
      const tagSearchTrimmed = tagSearch.trim();

      const tagsCopy = [...tags];
      DEFAULT_TAGS.forEach(tagName => {
        if (!tagsCopy.find(t => t.name === tagName)) {
          tagsCopy.push({
            name: tagName,
            colorIndex: tagsCopy.length,
            count: 0,
          });
        }
      });

      let filteredTags = tagsCopy;

      if (tagSearchTrimmed) {
        filteredTags = _.sortBy(
          tagsCopy.filter(tag =>
            tag.name.toLowerCase().includes(tagSearchTrimmed)
          ),
          tag =>
            tag.name.toLowerCase() === tagSearchTrimmed.toLowerCase() ? 0 : 1
        );
      }

      const newTag =
        tagSearchTrimmed && !tagsCopy.find(t => t.name === tagSearchTrimmed);

      return (
        <List>
          {filteredTags.map(tag => renderTag(tag, selectedCount, false))}
          {newTag &&
            renderTag(
              {
                name: tagSearchTrimmed,
                colorIndex: tagsCopy.length,
                count: 0,
              },
              selectedCount,
              true
            )}
        </List>
      );
    }, [renderTag, selectedCount, tagSearch, tags]);

    // TODO: setup states properly
    let buttonClass;
    if (open) {
      buttonClass = 'action-button--focused';
    } else {
      buttonClass = 'action-button--static';
    }

    const button = (
      <Button
        size="tiny"
        data-test="tag-popup"
        onClick={() => {
          const actionLocation = isInReport ? 'report' : 'runs table';
          window.analytics?.track('Tag dropdown clicked', {
            location: actionLocation,
          });
        }}
        className={
          buttonClass +
          ' enable-pointer-events wb-icon-button' +
          (selectedCount === 0 ? ' disabled' : '')
        }>
        <LegacyWBIcon name="tag-gray" />
        Tag
      </Button>
    );

    if (selectedCount === 0) {
      return (
        <Popup
          basic
          on="hover"
          popperModifiers={{
            preventOverflow: {
              // prevent popper from erroneously constraining the popup to the
              // table header
              boundariesElement: 'viewport',
            },
          }}
          content={
            <p className="hint-text small">
              Select runs to tag by hovering over a row and clicking the
              checkbox on the left.
            </p>
          }
          trigger={button}
        />
      );
    }

    return (
      <Popup
        basic
        className="wb-table-action-popup"
        on="click"
        position="bottom left"
        open={open}
        onOpen={() => {
          setOpen(true);
          setTagSearch('');
        }}
        onClose={() => setOpen(false)}
        trigger={button}
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        content={
          <div className="tag-popup">
            {selectedCount === 0 ? (
              <div className="title">
                Select runs in the table to apply tags
              </div>
            ) : (
              <React.Fragment>
                <div className="title">
                  {`Tag ${selectedCount} selected ${
                    selectedCount === 1 ? 'run' : 'runs'
                  }:`}
                </div>
                <Input
                  className="tag"
                  icon="search"
                  placeholder="Search or create a tag"
                  value={tagSearch}
                  onChange={(e, {value}) =>
                    setTagSearch(value.substring(0, 64))
                  }
                />
                <Divider fitted />
                {renderTags()}
              </React.Fragment>
            )}
          </div>
        }
      />
    );
  }
);

export default BulkTagEditor;
