import {useCallback} from 'react';
import {useLocation} from 'react-router';

import {
  appLauncherFailure,
  appLauncherOnScreen,
  appLauncherUsed,
  QuickNavData,
} from '../../services/analytics/productEvents';
import {ActivationMethods} from './../../services/analytics/types';

/**
 * Creates a hook to track all the analytics events related to the launcher. This allows the centralization of the dependencies for each analytics event in a single place, and minimizes how much noise is introduced into each component file that needs to dispatch an event.
 */
export function useLauncherAnalytics() {
  const location = useLocation();

  const trackAppLauncherOnScreen = useCallback(
    (activationMethod: ActivationMethods) => {
      appLauncherOnScreen({
        activationMethod,
        location: location.pathname,
      });
    },
    [location.pathname]
  );

  const trackAppLauncherUsed = useCallback(
    (destination: string, type: QuickNavData['type']) => {
      appLauncherUsed({
        destination,
        location: location.pathname,
        type,
      });
    },
    [location.pathname]
  );

  const trackAppLauncherFailure = useCallback(
    (destination: string) => {
      appLauncherFailure({
        destination,
        location: location.pathname,
      });
    },
    [location.pathname]
  );

  return {
    trackAppLauncherOnScreen,
    trackAppLauncherUsed,
    trackAppLauncherFailure,
  };
}
