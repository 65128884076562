import _ from 'lodash';
import React from 'react';
import {Icon, Label} from 'semantic-ui-react';
import * as GQLTypes from '../types/graphql';

import {SingleLineText} from './elements/Text';

interface TagProps {
  size?: 'medium' | 'small';
  tag: GQLTypes.Tag;

  onDelete?(e: React.MouseEvent<HTMLElement>): void;
  onClick?(): void;
}

function colorIndexToName(index: number): string {
  if (index === -1) {
    return 'lightGray';
  }

  switch (index % 10) {
    case 0:
      return 'purple';
    case 1:
      return 'berry';
    case 2:
      return 'red';
    case 3:
      return 'peach';
    case 4:
      return 'yellow';
    case 5:
      return 'acid';
    case 6:
      return 'green';
    case 7:
      return 'teal';
    case 8:
      return 'blue';
    case 9:
      return 'midnight';
    default:
      throw new Error('colorNameToIndex programming error');
  }
}

export const Tag: React.FC<TagProps> = React.memo(
  ({size: propsSize, tag, onDelete, onClick}) => {
    const size = propsSize || 'small';
    const colorName = 'tag-' + colorIndexToName(tag.colorIndex);
    return (
      <Label
        className={'run-tag ' + size + ' ' + colorName}
        key={tag.name}
        onClick={onClick}>
        <SingleLineText>{tag.name}</SingleLineText>
        {onDelete && (
          <span className="delete-mask">
            <Icon
              name="delete"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                onDelete(e);
              }}
            />
          </span>
        )}
      </Label>
    );
  }
);

interface TagsProps {
  size?: 'medium' | 'small';
  tags: GQLTypes.Tag[];
  enableDelete?: boolean;

  deleteTag?(tag: GQLTypes.Tag): void;
  onClick?(tag: string): void;
}

export const Tags: React.FC<TagsProps> = React.memo(
  ({size, tags, enableDelete, deleteTag, onClick}) => {
    return (
      <span className="run-tags">
        {_.sortBy(tags, 'name').map(tag => (
          <Tag
            key={tag.name}
            tag={tag}
            size={size}
            onDelete={
              enableDelete && deleteTag
                ? e => {
                    if (deleteTag) {
                      e.stopPropagation();
                      deleteTag(tag);
                    }
                  }
                : undefined
            }
            onClick={() => onClick?.(tag.name)}
          />
        ))}
      </span>
    );
  }
);
