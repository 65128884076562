/**
 * Select a Job
 */
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';

import {
  FetchEntityJobsQuery,
  FetchProjectJobsQuery,
  useFetchEntityJobsQuery,
  useFetchProjectJobsQuery,
} from '../../generated/graphql';
import {DEFAULT_LAUNCH_PROJECT_NAME} from '../../pages/EntityLaunchPage';
import Alert from './Alert';

interface SelectJobProps {
  entityName: string;
  projectName: string;
  selectedJob: string;
  onSelectJob: (jobId: string) => void;
}

export type JobInfo = {
  jobName: string;
  jobVersion: string;
  jobProject?: string;
};

interface JobOption {
  value: string;
  label: string;
}
const getJobOptions = (data: FetchProjectJobsQuery) => {
  const options = [];
  const collectionEdges =
    data?.project?.artifactType?.artifactCollections?.edges ?? [];
  for (const collectionEdge of collectionEdges) {
    // Can only run a Job if it has versions associated with it.
    // It might not if it was created through the Artifacts UI.
    const artifactCollection = collectionEdge.node;
    const versionEdges = artifactCollection?.artifacts?.edges ?? [];
    if (versionEdges.length) {
      options.push({
        value: artifactCollection?.id ?? '',
        label: artifactCollection?.name ?? '',
      });
    }
  }
  return _.sortBy(options, 'label');
};

const getJobOptionsForEntity = (data: FetchEntityJobsQuery) => {
  const options = [];
  const projectEdges = data?.entity?.projects?.edges ?? [];
  for (const projectEdge of projectEdges) {
    const projectName = projectEdge?.node?.name;
    const collectionEdges =
      projectEdge?.node?.artifactType?.artifactCollections?.edges ?? [];
    for (const collectionEdge of collectionEdges) {
      const artifactCollection = collectionEdge.node;
      const versionEdges = artifactCollection?.artifacts?.edges ?? [];
      if (versionEdges.length && projectName != null) {
        options.push({
          value: artifactCollection?.id ?? '',
          label:
            artifactCollection?.name != null
              ? `${projectName}/${artifactCollection.name}`
              : '',
        });
      }
    }
  }
  return options;
};

const SelectJob: React.FC<SelectJobProps> = ({
  entityName,
  projectName,
  selectedJob,
  onSelectJob,
}) => {
  const isInEntityLaunchProject = projectName === DEFAULT_LAUNCH_PROJECT_NAME;
  const jobsQuery = useFetchProjectJobsQuery({
    variables: {
      entityName,
      projectName,
    },
    skip: isInEntityLaunchProject,
  });

  const entityJobsQuery = useFetchEntityJobsQuery({
    variables: {
      entityName,
    },
    skip: !isInEntityLaunchProject,
  });

  // TODO: Better error display
  if (
    jobsQuery.loading ||
    jobsQuery.error ||
    entityJobsQuery.loading ||
    entityJobsQuery.error
  ) {
    return <div>Loading...</div>;
  }

  const options = isInEntityLaunchProject
    ? getJobOptionsForEntity(entityJobsQuery.data!)
    : getJobOptions(jobsQuery.data!);
  const scopeOfSearchString = isInEntityLaunchProject ? 'entity' : 'project';
  if (options.length === 0) {
    return <Alert>There are no jobs in this {scopeOfSearchString} yet</Alert>;
  }

  const onChange = (option: JobOption | null) => {
    if (option) {
      onSelectJob(option.label);
    }
  };
  return (
    <Select
      options={options}
      placeholder="Select a job..."
      onChange={onChange}
      defaultValue={
        selectedJob !== '' ? {value: selectedJob, label: selectedJob} : null
      }
    />
  );
};

export default SelectJob;
