import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import {Highlight, Python} from '../../../components/Code';
const inputType = 'artifactMembership' as const;
type ConfigType = {};

export const PanelArtifactMembershipAPI: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType>
> = ({input: artifactMembershipNode}) => {
  const {loading, result} = useNodeValue(
    React.useMemo(() => {
      const artifactNode = Op.opArtifactMembershipCollection({
        artifactMembership: artifactMembershipNode,
      });
      const artifactTypeNode = Op.opArtifactType({artifact: artifactNode});
      const artifactProjectNode = Op.opArtifactProject({
        artifact: artifactNode,
      });
      return Op.opDict({
        artifactTypeName: Op.opArtifactTypeName({
          artifactType: artifactTypeNode,
        }),
        entityName: Op.opEntityName({
          entity: Op.opProjectEntity({
            project: artifactProjectNode,
          }),
        }),
        projectName: Op.opProjectName({
          project: artifactProjectNode,
        }),
        artifactName: Op.opArtifactName({
          artifact: artifactNode,
        }),
        versionIndex: Op.opArtifactMembershipVersionIndex({
          artifactMembership: artifactMembershipNode,
        }),
      } as any) as unknown as GraphTypes.OutputNode<'any'>;
    }, [artifactMembershipNode])
  );

  if (loading) {
    return <></>;
  }
  return (
    <Python>
      <Highlight iconOnly>
        {`import wandb
run = wandb.init()
artifact = run.use_artifact('${result.entityName}/${result.projectName}/${result.artifactName}:v${result.versionIndex}', type='${result.artifactTypeName}')
artifact_dir = artifact.download()`}
      </Highlight>
    </Python>
  );
};
