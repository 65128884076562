import * as globals from '@wandb/common/css/globals.styles';
import {Popup} from 'semantic-ui-react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const Label = styled.label`
  text-transform: uppercase;
  width: 110px;
  flex-shrink: 0;
  font-size: 13px;
  color: ${globals.gray700};
  margin-top: 2px;
  align-self: flex-start;
`;

export const Description = styled.div`
  flex-grow: 1;
`;

export const ToggleContainer = styled.div`
  display: flex;
`;

export const Tooltip = styled(Popup)`
  &&&&& {
    border-radius: 4px;
    background: rgba(75, 78, 82, 95%);
    padding: 14px 16px;
    font-size: 14px;
    color: ${globals.white};

    &:before {
      background-color: rgba(75, 78, 82, 95%);
    }
  }
`;
