import {envIsLocal} from '@wandb/common/config';
import {TimeDelta} from '@wandb/common/util/time';
import numeral from 'numeral';
import * as React from 'react';

interface StoragePercentageProps {
  available: number;
  used: number;
  selected?: number;
  computeHours?: boolean;
  labelOnTop?: boolean;
}

const StoragePercentage: React.FC<StoragePercentageProps> = React.memo(
  ({available, used, selected, computeHours, labelOnTop}) => {
    if (selected == null) {
      selected = 0;
    }
    let percent = used / available;
    let selectedPercent = (used - selected) / available;
    let over = 0;
    if (percent > 1) {
      over = percent - 1;
      percent = available / used;
      selectedPercent = (used - selected) / used;
    }

    const label = (
      <div>
        {computeHours
          ? `Organization Usage Plan: ${new TimeDelta(used).toHoursString()} ${
              available >= 0
                ? `/ ${new TimeDelta(available).toHoursString()}`
                : ''
            }`
          : `Basic Storage Plan: ${numeral(used - selected).format('0.0b')} /
  ${numeral(available).format('0.0b')}`}
        {/* TODO: hook up billing <a style={{float: 'right'}} href="/billing">
Upgrade →
</a>*/}
      </div>
    );

    return (
      <div>
        {envIsLocal ? (
          <>
            <div
              className="progress"
              style={{
                width: '100%',
                height: 25,
                marginBottom: 10,
                background: '#CCC',
                borderRadius: 5,
                position: 'relative',
              }}>
              <span
                className="used"
                style={{
                  display: 'inline-block',
                  width: '5%',
                  height: '100%',
                  background: 'rgb(175,220,234)',
                  borderRadius: 5,
                }}></span>
            </div>
            <div>
              {numeral(used - selected).format('0.0b')} Used
              {/* TODO: hook up billing <a style={{float: 'right'}} href="/billing">
        Upgrade →
      </a>*/}
            </div>
          </>
        ) : (
          <>
            {labelOnTop && label}
            <div
              className="progress"
              style={{
                width: '100%',
                height: 25,
                marginBottom: 10,
                background:
                  over > 0 && selectedPercent > percent ? '#ffe3e6' : '#CCC',
                borderRadius: 5,
                position: 'relative',
              }}>
              <span
                className="used"
                style={{
                  display: 'inline-block',
                  width: percent * 100 + '%',
                  height: '100%',
                  background: 'rgb(175,220,234)',
                  borderRadius: 5,
                }}></span>
            </div>
            {!labelOnTop && label}
          </>
        )}
      </div>
    );
  }
);

export default StoragePercentage;
