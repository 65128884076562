import React from 'react';
import {useCallback, useMemo, useState} from 'react';
import * as Panel from './panel';
import * as CG from '@wandb/cg';
// import * as CGReact from '../../cgreact';
// import {PanelLoader} from './PanelComp';
import {useNodeValue} from '../../cgreact';
import styled from 'styled-components';
import {ChildPanel} from './ChildPanel';
import {Icon} from 'semantic-ui-react';

const inputType = {type: 'list' as const, objectType: 'any' as const};
interface PanelObjectPickerConfig {
  // This is a function of input, that produces a single item from
  // input.
  // Well, that's what Select() does. But it produces it as a const.
  //
  // Is this the same?
  label: string;
  choice: CG.NodeOrVoidNode;
}
type PanelObjectPickerProps = Panel.PanelProps<
  typeof inputType,
  PanelObjectPickerConfig
>;

const Picker = styled.div``;

const Item = styled.div<{preferHorizontal?: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  margin-right: 8px;
`;

const Value = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MenuItem = styled.div<{preferHorizontal?: boolean}>`
  cursor: pointer;
  padding-left: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: #eee;
  }
`;

export const PanelObjectPicker: React.FC<PanelObjectPickerProps> = props => {
  const {config, updateConfig} = props;
  const [expanded, setExpanded] = useState(false);
  // const updateVal = useCallback(
  //   (newVal: number) => updateConfig({...config, value: newVal}),
  //   [config, updateConfig]
  // );
  const inputPageNode = useMemo(
    () =>
      CG.opLimit({
        arr: CG.opOffset({arr: props.input, offset: CG.constNumber(0)}),
        limit: CG.constNumber(10),
      }),
    [props.input]
  );
  const choose = useCallback(
    (choice: any) => {
      const objConstNode = CG.constNodeUnsafe(
        props.input.type.objectType,
        choice
      );
      updateConfig({choice: objConstNode});
      // setExpanded(false);
    },
    [props.input.type.objectType, updateConfig]
  );
  const objs = useNodeValue(inputPageNode);
  return (
    <Picker>
      <Item onClick={() => setExpanded(!expanded)}>
        <Icon
          onClick={(e: React.SyntheticEvent<HTMLInputElement>) =>
            console.log('bla')
          }
          size="mini"
          name={`chevron ${expanded ? 'down' : 'right'}`}
        />
        <Label>{props.config?.label}: </Label>
        <Value>
          <ChildPanel
            config={{
              vars: {},
              input_node: config?.choice ?? CG.voidNode(),
              id: '',
              config: {},
            }}
            updateConfig={(newConfig: any) =>
              console.warn('DROPPING CONFIG IN PANEL OBJECT PICKER')
            }
          />
        </Value>
      </Item>
      {/* <div>Filter expression editor.</div> */}
      {!objs.loading && expanded && (
        <div>
          {objs.result.map((o: any) => {
            const objConstNode = CG.constNodeUnsafe(
              props.input.type.objectType,
              o
            );
            return (
              <MenuItem onClick={() => choose(o)}>
                <ChildPanel
                  config={{
                    vars: {},
                    input_node: objConstNode,
                    id: '',
                    config: undefined,
                  }}
                  updateConfig={(newConfig: any) =>
                    console.warn('DROPPING CONFIG IN PANEL OBJECT PICKER')
                  }
                />
              </MenuItem>
            );
          })}
        </div>
      )}
    </Picker>
  );
};

export const Spec: Panel.PanelSpec = {
  hidden: true,
  id: 'ObjectPicker',
  Component: PanelObjectPicker,
  inputType,
};
