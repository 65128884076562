import _ from 'lodash';
import React from 'react';
import {useCallback} from 'react';

import * as GroupSelectionsActions from '../../state/views/groupSelections/actions';
import * as GroupSelectionsTypes from '../../state/views/groupSelections/types';
import * as ViewHooks from '../../state/views/hooks';
import {useViewAction} from '../../state/views/hooks';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import {NamedProjectFieldSelector} from '../ProjectFieldSelector';
import {
  WBTableActionGroup,
  WBTableActionGroupButton,
  WBTableActionGroupPicker,
} from '../WBTable/WBTableActionsGroup';

interface RunsGroupButtonProps {
  compact?: boolean;
  groupingOpen: boolean;
  groupSelectionsRef: GroupSelectionsTypes.Ref;
  isInReport?: boolean;
}

const RunsGroupButton = (props: RunsGroupButtonProps) => {
  const {groupSelectionsRef} = props;

  const groupSelections = ViewHooks.usePart(groupSelectionsRef);

  const passThroughProps = _.omit(
    props,
    'compact',
    'groupingOpen',
    'groupSelectionsRef'
  );
  return (
    <WBTableActionGroupButton
      {...passThroughProps} // Required for use as popup trigger
      compact={props.compact}
      groupOpen={props.groupingOpen}
      grouping={groupSelections.grouping}
    />
  );
};

interface RunsGroupPickerProps {
  entityName: string;
  projectName: string;
  groupSelectionsRef: GroupSelectionsTypes.Ref;
  recommendedGrouping?: Query.Grouping; // A good grouping for the current data
  onGroupingChanged(): void;
}

export const RunsGroupPicker = (props: RunsGroupPickerProps) => {
  const {entityName, projectName, groupSelectionsRef, onGroupingChanged} =
    props;

  const defaultKeys = [
    Run.keyToString(Run.GROUP_BY_ALL_KEY),
    'run:displayName',
    'run:state',
    'run:username',
    'run:sweep',
    'run:group',
    'run:jobType',
    'run:host',
  ];

  const groupSelections = ViewHooks.usePart(groupSelectionsRef);
  const setGrouping = useViewAction(
    groupSelectionsRef,
    GroupSelectionsActions.setGrouping
  );

  const setGroupingWrapped = useCallback(
    (...args: Parameters<typeof setGrouping>) => {
      setGrouping(...args);
      onGroupingChanged();
    },
    [setGrouping, onGroupingChanged]
  );

  const setGroupingWrappedString = useCallback(
    (args: string[]) =>
      setGroupingWrapped(args.map(key => Run.keyFromString(key)!)),
    [setGroupingWrapped]
  );

  return (
    <WBTableActionGroupPicker
      grouping={groupSelections.grouping}
      recommendedGrouping={props.recommendedGrouping}
      setGrouping={setGroupingWrapped}
      dropdown={
        <NamedProjectFieldSelector
          data-test="group-dropdown"
          entityName={entityName}
          projectName={projectName}
          placeholder="No grouping"
          defaultKeys={defaultKeys}
          reorderable
          selection
          multi={true}
          value={groupSelections.grouping.map(key => Run.keyToString(key))}
          setValue={setGroupingWrappedString}
          searchByKeyAndText
        />
      }
    />
  );
};

type RunsGroupTableActionProps = RunsGroupPickerProps & {
  compact?: boolean;
  isInReport?: boolean;
};

export const RunsGroupTableAction = (props: RunsGroupTableActionProps) => {
  return (
    <WBTableActionGroup
      trigger={open => (
        <RunsGroupButton
          compact={props.compact}
          groupingOpen={open}
          groupSelectionsRef={props.groupSelectionsRef}
          isInReport={props.isInReport}
        />
      )}
      content={
        <RunsGroupPicker
          entityName={props.entityName}
          projectName={props.projectName}
          groupSelectionsRef={props.groupSelectionsRef}
          onGroupingChanged={props.onGroupingChanged}
          recommendedGrouping={props.recommendedGrouping}
        />
      }
    />
  );
};
