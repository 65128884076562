import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import NumberInput from '@wandb/common/components/elements/NumberInput';
import * as globals from '@wandb/common/css/globals.styles';
import {WBIcon} from '@wandb/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &&&& {
    border-radius: 3px;
  }

  background-color: ${globals.white};
  color: ${globals.textPrimary};
  @media only screen and (max-width: ${globals.tabletBreakpoint}) {
    flex-flow: row wrap;
  }
`;

const containerStyle = `
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px;
  flex-shrink: 0;
  @media only screen and (max-width: ${globals.tabletBreakpoint}) {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  ${containerStyle};

  background-color: ${globals.gray100};
  color: ${globals.textPrimary};
  width: 360px;
  overflow-y: auto;
  flex-shrink: 0;
`;

export const SideContainer = styled.div`
  ${containerStyle};

  background-color: ${globals.white};
  color: ${globals.textPrimary};
  flex: 1;
`;

export const PlanOverviewSection = styled.div`
  width: 100%;
`;

export const UsageSection = styled.div`
  padding-bottom: 12px;
  align-self: start;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  line-height: 20px;
  font-size: 16px;
  align-self: start;
  margin-bottom: 16px;
  color: ${globals.gray600};
`;

export const TotalAmountSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: baseline;
`;

export const TotalAmount = styled.div`
  color: ${globals.gray800};
  font-weight: 600;
  font-size: 34px;
  line-height: 43px;
`;

export const BillingCycle = styled.div`
  font-size: 20px;
  color: ${globals.gray600};
`;

export const SeatsPickerTitle = styled.div`
  color: ${globals.gray800};
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 4px;
`;

export const SeatsPicker = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

export const SeatsInputWrapper = styled.div`
  padding-right: 12px;
`;

export const SeatsInput = styled(NumberInput)`
  width: 80px;
`;

export const PlanList = styled.div`
  padding-bottom: 4px;
`;

export const PlanItem = styled.div`
  margin-bottom: 14px;
  padding-left: 24px;
  position: relative;
  line-height: 1em;
  font-size: 14px;
`;

export const PeriodPickerLink = styled.span`
  color: ${globals.gray600};
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  &:hover {
    color: ${globals.gray800};
  }
`;

export const SwitchIcon = styled(WBIcon)`
  padding-right: 3px;
  font-size: 16px;
  top: 2px;
`;

export const SavingMessage = styled.div`
  color: ${globals.GREEN};
  font-size: 13px;
  min-height: 16px;
  padding-bottom: 20px;
`;

export const ContactSalesSection = styled.p`
  font-size: 13px;
  color: ${globals.gray600};
`;

export const ContactSalesLink = styled.p`
  color: ${globals.primary};
  padding: 2px;
  cursor: pointer;
`;

export const Link = styled.a`
  display: block;
  color: ${globals.primary};
  cursor: pointer;
`;

export const CheckIcon = styled(WBIcon)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${globals.black};
`;

export const OpenUsagePricingIcon = styled(LegacyWBIcon)<{open: boolean}>`
  transform: rotate(${props => Number(props.open) * 180 + 90}deg);
  position: relative;
  top: 1px;

  &&& {
    font-size: 20px;
  }
`;

export const LinkText = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  color: ${globals.TEAL_DARK};
`;

export const UsagePricingLink = styled(LinkText)``;
