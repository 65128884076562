import {ApolloCurrentQueryResult} from 'apollo-client';
import React, {useEffect, useMemo} from 'react';

import {
  DefaultResourceConfig,
  RunQueue,
  useEntityDefaultResourceConfigsQuery,
  useFetchLaunchAgentsFromProjectQuery,
} from '../generated/graphql';
import {
  ProjectData,
  ProjectPageQueryData,
} from '../state/graphql/projectPageQuery';
import * as ViewerHooks from '../state/viewer/hooks';
import {entityLaunch, projectRunQueue} from '../util/urls';
import JobsViewer from './Jobs/JobsViewer';
import * as S from './Launch.styles';
import DefaultResourceConfigTab from './Launch/DefaultResourceConfig/DefaultResourceConfigTab';
import LaunchAgentTab from './Launch/LaunchAgentTab';
import RoutedTab from './RoutedTab';
import RunQueueTab from './RunQueueTab';
import TriggersTab from './TriggersTab';

const ENTITY_LAUNCH_PAGE_TABS = ['queues', 'agents', 'resources'];

const PROJECT_LAUNCH_PAGE_TABS = [
  'queues',
  'agents',
  'resources',
  'jobs',
  'triggers',
];

interface LaunchProps {
  tab?: string;
  project: ProjectData;
  entityName: string;
  projectLoading: boolean;
  fromLaunchEntityPage?: boolean;
  refetchProject: () => Promise<ApolloCurrentQueryResult<ProjectPageQueryData>>;
}

const LaunchPage: React.FC<LaunchProps> = ({
  tab,
  project,
  entityName,
  projectLoading,
  fromLaunchEntityPage = false,
  refetchProject,
}) => {
  const projectName = project.name;
  const viewer = ViewerHooks.useViewer();
  const launchAgentsQuery = useFetchLaunchAgentsFromProjectQuery({
    variables: {
      projectName,
      entityName,
    },
    fetchPolicy: 'cache-and-network',
  });
  const defaultResourceConfigData = useEntityDefaultResourceConfigsQuery({
    variables: {entityName},
  });

  useEffect(() => {
    refetchProject();
  }, [refetchProject]);

  const defaultResourceConfigs = useMemo(() => {
    return defaultResourceConfigData.data?.entity?.defaultResourceConfigs?.edges.map(
      x => x.node
    ) as DefaultResourceConfig[];
  }, [defaultResourceConfigData]);
  const refetchDefaultResourceConfigs = defaultResourceConfigData.refetch;
  const baseUrl = fromLaunchEntityPage
    ? entityLaunch(entityName)
    : projectRunQueue({
        entityName,
        name: projectName!,
      });
  const tabSlugs = fromLaunchEntityPage
    ? ENTITY_LAUNCH_PAGE_TABS
    : PROJECT_LAUNCH_PAGE_TABS;

  const projectID = project.id;
  const isTeam = project.entity.isTeam;
  const runQueues: RunQueue[] = project.runQueues;
  const projectWriteAccess = !project.readOnly;
  const panes = [
    {
      menuItem: 'Launch Queues',
      render: () => (
        <RunQueueTab
          viewer={viewer}
          entityName={entityName}
          projectLoading={projectLoading}
          projectName={projectName}
          isTeam={isTeam}
          runQueues={runQueues}
          defaultResourceConfigs={defaultResourceConfigs}
          launchAgentsQuery={launchAgentsQuery}
          projectWriteAccess={projectWriteAccess}
          refetchProject={refetchProject}
        />
      ),
    },
    {
      menuItem: 'Launch Agents',
      render: () => (
        <LaunchAgentTab
          runQueues={runQueues}
          launchAgentsQuery={launchAgentsQuery}
          projectName={projectName}
          entityName={entityName}
          viewer={viewer}
        />
      ),
    },
    {
      menuItem: 'Resources',
      render: () => (
        <DefaultResourceConfigTab
          entityName={entityName}
          defaultResourceConfigs={defaultResourceConfigs}
          refetchDefaultResourceConfigs={refetchDefaultResourceConfigs}
        />
      ),
    },
  ];
  if (!fromLaunchEntityPage) {
    panes.push({
      menuItem: 'Jobs',
      render: () => (
        <JobsViewer projectName={projectName} entityName={entityName} />
      ),
    });
    panes.push({
      menuItem: 'Triggers',
      render: () => (
        <TriggersTab
          projectName={projectName}
          entityName={entityName}
          projectID={projectID}
        />
      ),
    });
  }
  return (
    <S.LaunchRoutedDiv>
      <RoutedTab
        baseUrl={baseUrl}
        // TODO: make this an enum/const and use later
        tabSlugs={tabSlugs}
        activeTabSlug={tab}
        panes={panes}
      />
    </S.LaunchRoutedDiv>
  );
};

export default LaunchPage;
