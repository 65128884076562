import * as ViewHooks from '../../state/views/hooks';
import {ReportPanelGridSectionProps} from './ReportPanelGridSection';

export const useReportSectionProps = (props: ReportPanelGridSectionProps) => {
  const {sectionRef} = props;
  const sectionPart = ViewHooks.usePart(sectionRef);

  if (sectionPart.runSetRefs.length === 0) {
    // There's always at least one runset
    throw new Error('invalid state');
  }
  // TODO: Filter histograms if multi-run?
  const activePanelRefs = ViewHooks.usePart(
    sectionPart.panelBankSectionConfigRef
  ).panelRefs;

  return {
    activePanelRefs,
    sectionPart,
  };
};
