import {toast} from '@wandb/common/components/elements/Toast';
import {captureError} from '@wandb/common/util/integrations';

import {apolloClient} from '../../apolloClient';
import {RunSignature} from '../../types/run';
import {downloadDataUrl, fetchFileInfo} from '../../util/requests';

function drawCanvasesInElement(
  context: CanvasRenderingContext2D,
  el: HTMLDivElement | null
): void {
  if (el == null) {
    return;
  }

  const allCanvases = el.querySelectorAll('canvas');

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < allCanvases.length; i++) {
    const canvas = allCanvases[i];
    context.drawImage(canvas, 0, 0);
  }
}

export function downloadSpritePart(
  runSignature: RunSignature,
  filename: string,
  offset: number,
  width: number,
  opts?: {
    boxesRef?: React.RefObject<HTMLDivElement>;
    masksRef?: React.RefObject<HTMLDivElement>;
  }
): void {
  // We must create a new Image element instead of using the one already in
  // the dom because the one in the dom will throw a CORS TaintedCanvas
  // Error when converted to a dataUrl
  const img = new Image();
  img.crossOrigin = 'anonymous';

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context == null) {
    throw new Error(`failed to get canvas rendering context`);
  }

  img.onload = () => {
    const height = img.height;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(img, offset, 0, width, height, 0, 0, width, height);

    const masksContainerEl = opts?.masksRef?.current ?? null;
    const bboxContainerEl = opts?.boxesRef?.current ?? null;
    drawCanvasesInElement(context, masksContainerEl);
    drawCanvasesInElement(context, bboxContainerEl);

    const dataUrl = canvas.toDataURL();
    downloadDataUrl(dataUrl, filename);
  };

  (async () => {
    try {
      const file = await fetchFileInfo(apolloClient, {
        ...runSignature,
        filename,
      });
      if (file?.directUrl) {
        img.src = file.directUrl;
      }
    } catch (err) {
      console.error(`ImageCard download error: ${err}`);
      captureError(err, 'Image Card Download');
      toast('File download failed');
    }
  })();
}
