import config from '@wandb/common/config';
import {
  DateFromUTCString,
  diffInDays,
  SECONDS_IN_HOUR,
  secondsToHours,
} from '@wandb/common/util/time';
import {produce} from 'immer';
import _ from 'lodash';
import React, {createContext, useMemo} from 'react';

import {
  OrganizationSubscription,
  OrganizationSubscriptionsQuery,
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
  useOrganizationSubscriptionsQuery,
} from '../generated/graphql';
import {
  getPrimarySub,
  isDisabledSubscription,
  isExpiredSubscription,
  isExpiringSubscription,
  Org,
  PlanInfoWithActualPlans,
  PLANS_WITH_COMPUTE_HOUR_LIMIT,
  shouldEnforceExpiration,
  useSubscriptionPlans,
} from '../util/pricing';
import {
  getAggressiveCookie,
  getComputeHourLimitCookie,
  getDaysUntilExpireCookie,
  getOrganizationIDCookie,
  getSubscriptionStatusCookie,
  getSubscriptionTypeCookie,
} from '../util/usageLimit';

const DEFAULT_SEATS_LIMIT = 100;
// TODO: remove this once we finish releasing the enforcement
export const EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE = new Date(
  '2022-09-27T17:00:00Z' // 10AM PT September 27th 2022
);
// TODO: remove this after the release of enforcement for disabled subscription
export const DISABLED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE = new Date(
  '2022-10-04T17:00:00Z' // 10AM PT October 4th 2022
);
// we start showing nudge bar when their expiration is within 14 days from today
const EXPIRING_NUDGE_BAR_DISPLAY_THRESHOLD = 14;

export type GlobalNudgeBarState = {
  organization: Org | null;
  renderedNudgeBar: NudgeBarType | null;
  maxHours: number | null;
  aggressive?: boolean;
  daysUntilEnforcement?: number;
};

type GlobalNudgeBarStates = {
  states: GlobalNudgeBarState[] | null;
};

export const GlobalNudgeBarContext = createContext<GlobalNudgeBarStates>({
  states: [],
});

type GlobalNudgeBarUpdaterState = {
  refetch: () => Promise<any>;
};
export const GlobalNudgeBarUpdaterContext =
  createContext<GlobalNudgeBarUpdaterState>({
    refetch: () => Promise.resolve(),
  });

export const GlobalNudgeBarContextProvider = React.memo(({children}) => {
  const {data, refetch} = useOrganizationSubscriptionsQuery();
  const {planInfo} = useSubscriptionPlans();

  const viewerOrgs = useMemo(() => data?.viewer?.organizations ?? [], [data]);
  const contextValue = useMemo(
    () => getGlobalNudgeBarState(viewerOrgs, planInfo),
    [viewerOrgs, planInfo]
  );

  const updaterContextValue = useMemo(() => ({refetch}), [refetch]);

  return (
    <GlobalNudgeBarContext.Provider value={contextValue}>
      <GlobalNudgeBarUpdaterContext.Provider value={updaterContextValue}>
        {children}
      </GlobalNudgeBarUpdaterContext.Provider>
    </GlobalNudgeBarContext.Provider>
  );
});

export default GlobalNudgeBarContextProvider;

type ViewerOrgs = NonNullable<
  OrganizationSubscriptionsQuery['viewer']
>['organizations'];
type ViewerOrg = ViewerOrgs[number];

function getGlobalNudgeBarState(
  viewerOrgs: ViewerOrgs,
  plans: PlanInfoWithActualPlans | null
): GlobalNudgeBarStates {
  const states: GlobalNudgeBarState[] = [];

  for (const viewerOrg of viewerOrgs) {
    const viewerOrSimulatedOrg = getViewerOrSimulatedOrg(viewerOrg, plans);
    const nudgeBarForOrg = getNudgeBarTypeForViewerOrg(viewerOrSimulatedOrg);
    if (nudgeBarForOrg != null) {
      const organization = orgFromViewerOrg(viewerOrSimulatedOrg);
      const renderedNudgeBar = nudgeBarForOrg.type;
      const maxHours = nudgeBarForOrg.maxComputeHours ?? null;
      const aggressive = nudgeBarForOrg.aggressive;
      const daysUntilEnforcement = nudgeBarForOrg.daysUntilEnforcement;
      states.push({
        organization,
        renderedNudgeBar,
        maxHours,
        aggressive,
        daysUntilEnforcement,
      });
    }
  }

  return {states};
}

// type SubscriptionsWithoutTypename = {
//   subscriptions: Omit<ViewerOrg['subscriptions'][number], '__typename'>[];
// };
// type ViewerOrgWithoutSubscriptionTypename = ViewerOrg &
//   SubscriptionsWithoutTypename;
function getViewerOrSimulatedOrg(
  viewerOrg: ViewerOrg,
  plans: PlanInfoWithActualPlans | null
): ViewerOrg {
  const simulatedOrgID = getOrganizationIDCookie();
  const simulatedSubscription = getSimulatedSubscription(plans);

  if (viewerOrg.id !== simulatedOrgID || simulatedSubscription == null) {
    return viewerOrg;
  }

  return produce(viewerOrg, draft => {
    /**
     * The following ignore is a hack on the types because trying to selectively remove the __typename property from each ViewerOrg['subscriptions'] is eluding me. This is an artifact of the problem of coupling app code to autogenerated types detailed here: https://www.notion.so/wandbai/Reduce-coupling-and-improve-type-legibility-for-autogenerated-API-queries-cd97dd45172b4c5ab807a059b799e2f0#603a8d3bd6524de8a138cc620e9c5092
     */
    // @ts-ignore
    draft.subscriptions = [simulatedSubscription];

    const simulatedAggressive = getAggressiveCookie();

    if (simulatedAggressive) {
      // simulate the situation where users used excessive compute hours
      // for more than 14 days and ignored the warning nudge banner
      const computeHourSub = getComputeHourSubscription(draft);
      const maxComputeHour = computeHourSub?.privileges.compute_hours as
        | number
        | undefined;
      if (maxComputeHour != null) {
        const simulatedOldComputeHours = maxComputeHour * SECONDS_IN_HOUR;
        // set the first team to have the exceeding oldComputeHour and set the rest to 0
        draft.teams.forEach((team, i) => {
          team.oldComputeHours = i === 0 ? simulatedOldComputeHours : 0;
        });
      }
    } else {
      // simulate the situation where users don't have oldComputeHour to ignore nudge bar
      draft.teams.forEach(team => {
        team.oldComputeHours = 0;
      });
    }

    const simulatedComputeHour = getComputeHourLimitCookie();
    if (simulatedComputeHour != null) {
      const simulatedComputeHours = simulatedComputeHour * SECONDS_IN_HOUR;
      // set the first team to have the exceeding oldComputeHour and set the rest to 0
      draft.teams.forEach((team, i) => {
        team.computeHours = i === 0 ? simulatedComputeHours : 0;
      });
    }
  });
}

const COMMON_SUBSCRIPTION_PROPERTIES = {
  seats: DEFAULT_SEATS_LIMIT,
  availableSeatsToPurchase: 0,
  __typename: 'OrganizationSubscription',
  nextPlans: [],
};

function getSimulatedSubscription(
  plans: PlanInfoWithActualPlans | null
): Omit<OrganizationSubscription, '__typename'> | null {
  if (plans == null) {
    return null;
  }

  const subscriptionType = getSubscriptionTypeCookie();
  const simulatedDaysUntilExpiration = getDaysUntilExpireCookie();
  const expiresAt = getSimulatedExpiresAt(simulatedDaysUntilExpiration);
  const status = getSubscriptionStatusCookie();

  const id = 'simulatedSubscriptionID';

  const enterprisePlan = plans.enterprisePlan;
  const basicPlan = plans.basicPlan;
  const starterTier1MonthlyPlan = plans.monthlyPlan;

  switch (subscriptionType) {
    case 'free_academic_trial':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: enterprisePlan,
        privileges: {num_private_teams: 3},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.AcademicTrial,
      };
    case 'free_starter_plan_trial':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: basicPlan,
        privileges: {num_private_teams: 1, compute_hours: 250},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.UserLedTrial,
      };
    case 'starter_tier_1_plan':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: starterTier1MonthlyPlan,
        privileges: {num_private_teams: 1, compute_hours: 5000},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.Stripe,
      };
    case 'free_enterprise_plan_trial':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: enterprisePlan,
        privileges: {num_private_teams: 3},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.ManualTrial,
      };
    case 'enterprise_plan':
      return {
        ...COMMON_SUBSCRIPTION_PROPERTIES,
        id,
        plan: enterprisePlan,
        privileges: {num_private_teams: 5},
        expiresAt,
        status,
        subscriptionType: OrganizationSubscriptionType.Enterprise,
      };
    default:
      return null;
  }
}

function getSimulatedExpiresAt(
  daysUntilExpiration: number | null
): Date | null {
  if (daysUntilExpiration == null) {
    return null;
  }

  const expiresAt = new Date();
  expiresAt.setDate(expiresAt.getDate() + daysUntilExpiration);

  return expiresAt;
}

function orgFromViewerOrg(org: ViewerOrg): Org {
  let flags = {};
  try {
    if (org.flags != null) {
      flags = JSON.parse(org.flags);
    }
  } catch {
    // do nothing
  }

  return {
    id: org.id,
    name: org.name,
    usedSeats: org.usedSeats,
    teams: org.teams,
    subscriptions: org.subscriptions,
    flags,
  };
}

export enum NudgeBarType {
  StandardComputeHours = 'standard-compute-hours',
  AdvancedComputeHours = 'advanced-compute-hours',
  EnterpriseToStandard = 'enterprise-to-standard',
  EnterpriseToAdvanced = 'enterprise-to-advanced',
  UpgradeNudgeBarForExpiringSubscription = 'upgrade-nudge-bar-for-expiring-subscription',
  ContactUsNudgeBarForExpiringSubscription = 'contact-us-nudge-bar-for-expiraing-subscription',
  ExpiredSubscriptionEnforcementWithUpgrade = 'expired-subscription-enforcement-with-upgrade',
  ExpiredSubscriptionEnforcementWithContactUs = 'expired-subscription-enforcement-with-contact-us',
  DisabledSubscriptionEnforcement = 'disabled-subscription-enforcement',
}

type NudgeBarInfo = {
  type: NudgeBarType;
  maxComputeHours?: number;
  aggressive?: boolean;
  daysUntilEnforcement?: number;
};

function getNudgeBarTypeForViewerOrg(org: ViewerOrg): NudgeBarInfo | null {
  const totalComputeHoursInSeconds = org.teams
    .map(t => t.computeHours)
    .reduce((a, b) => a + b, 0);
  const totalOldComputeSeconds = org.teams
    .map(t => t.oldComputeHours)
    .reduce((a, b) => a + b, 0);

  // TODO(haruka): Put getNudgeBarTypeForDisabledSubscription at the top after enforcement
  // Right now, tracked hour enforcement and expired subscription enforcement are more
  // powerful until the release of enforcement for disabled subscription.
  // But after the disabled enforcement, we want to prioritize enforcing with disabled status.
  for (const getNudgeBarType of [
    getNudgeBarTypeForComputeHourSub,
    getNudgeBarTypeForExpiringSubscription,
    getNudgeBarTypeForExpiredSubscription,
    getNudgeBarTypeForDisabledSubscription,
    getNudgeBarTypeForStandardTrialSub,
  ]) {
    const nb = getNudgeBarType(
      org,
      totalComputeHoursInSeconds,
      totalOldComputeSeconds
    );
    if (nb != null) {
      return nb;
    }
  }

  return null;
}

function getNudgeBarTypeForComputeHourSub(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number,
  totalOldComputeSeconds: number
): NudgeBarInfo | null {
  const computeHourSub = getComputeHourSubscription(org);
  if (computeHourSub == null) {
    return null;
  }

  const maxComputeHours = computeHourSub.privileges.compute_hours as number;
  if (secondsToHours(totalComputeHoursInSeconds) <= maxComputeHours) {
    return null;
  }
  const aggressive = secondsToHours(totalOldComputeSeconds) >= maxComputeHours;

  if (computeHourSub.plan.name === 'basic') {
    return {
      type: NudgeBarType.StandardComputeHours,
      maxComputeHours,
      aggressive,
    };
  }

  return {type: NudgeBarType.AdvancedComputeHours, maxComputeHours, aggressive};
}

function getNudgeBarTypeForExpiringSubscription(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): NudgeBarInfo | null {
  const primarySubscription = getPrimarySub(org);

  if (primarySubscription == null) {
    return null;
  }

  if (!shouldEnforceExpiration(primarySubscription)) {
    return null;
  }

  const {isExpiring, daysUntilExpire} =
    isExpiringSubscription(primarySubscription);

  if (!isExpiring) {
    return null;
  }

  if (daysUntilExpire == null) {
    throw new Error(
      'daysUntilExpire should exist when the subscription isExpiring'
    );
  }

  // If they have more than 14 days until their expiration, we don't show the nudge bar
  if (EXPIRING_NUDGE_BAR_DISPLAY_THRESHOLD < daysUntilExpire) {
    return null;
  }

  const daysUntilRelease = diffInDays(
    EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE,
    new Date()
  );

  // if their subscription expires before the release,
  // they actually have daysUntilRelease to get affected by enforcement
  // if their subscription expires after the release,
  // enforcement should happen on their expiration date
  const daysUntilEnforcement =
    daysUntilExpire < daysUntilRelease ? daysUntilRelease : daysUntilExpire;

  // Ideally, we want to use the compute_hours privilege from the subscription
  // but some users don't have this. To avoid more free rideres,
  // we use the hardcoded 5000 tracked hour limit to check if the organization
  // can upgrade their subscription on their own via UI
  const canUpgradeToStarterTier1 =
    totalComputeHoursInSeconds <= config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS;

  // if they are on free user led trial and under starter tier 1 tracked hour limit,
  // they can upgrade themselves through UI
  if (
    primarySubscription.subscriptionType ===
      OrganizationSubscriptionType.UserLedTrial &&
    primarySubscription.plan?.name === 'basic' &&
    canUpgradeToStarterTier1
  ) {
    return {
      type: NudgeBarType.UpgradeNudgeBarForExpiringSubscription,
      daysUntilEnforcement,
    };
  }

  // if they are not on free user led trial or they exceed starter tier 1 limit,
  // they need to contact us
  return {
    type: NudgeBarType.ContactUsNudgeBarForExpiringSubscription,
    daysUntilEnforcement,
  };
}

function getNudgeBarTypeForExpiredSubscription(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): NudgeBarInfo | null {
  const primarySubscription = getPrimarySub(org);

  if (primarySubscription == null) {
    return null;
  }

  if (!shouldEnforceExpiration(primarySubscription)) {
    return null;
  }

  const isExpired = isExpiredSubscription(primarySubscription);

  if (!isExpired) {
    return null;
  }

  // Ideally, we want to use the compute_hours privilege from the subscription
  // but some users don't have this. To avoid more free rideres,
  // we use the hardcoded 5000 tracked hour limit to check if the organization
  // can upgrade their subscription on their own via UI
  const canUpgradeToStarterTier1 =
    totalComputeHoursInSeconds <= config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS;

  /*
    TODO: Replace the rest of this function with the following return statement
    after the enforcement for expired subscription get released

    if (canUpgradeToStarterTier1) {
      return {type: NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade}
    }

    return {type: NudgeBarType.ExpiredSUbscriptionEnforcementWithContactUs}
  */

  const daysUntilRelease = diffInDays(
    EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE,
    new Date()
  );
  const daysUntilEnforcement = daysUntilRelease;

  // if they are on free user led trial and under starter tier 1 tracked hour limit,
  // they can upgrade themselves through UI
  if (
    primarySubscription.subscriptionType ===
      OrganizationSubscriptionType.UserLedTrial &&
    primarySubscription.plan?.name === 'basic' &&
    canUpgradeToStarterTier1
  ) {
    return {
      type: NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade,
      daysUntilEnforcement,
    };
  }

  // if they are not on free user led trial or they exceed starter tier 1 limit,
  // they need to contact us
  return {
    type: NudgeBarType.ExpiredSubscriptionEnforcementWithContactUs,
    daysUntilEnforcement,
  };
}

function getNudgeBarTypeForDisabledSubscription(
  org: ViewerOrg
): NudgeBarInfo | null {
  const primarySubscription = getPrimarySub(org);

  if (primarySubscription == null) {
    return null;
  }

  const isDisabled = isDisabledSubscription(primarySubscription);

  if (!isDisabled) {
    return null;
  }

  return {
    type: NudgeBarType.DisabledSubscriptionEnforcement,
  };
}

function getNudgeBarTypeForStandardTrialSub(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): NudgeBarInfo | null {
  const oldSub = org.subscriptions.some(sub => {
    return (
      sub.subscriptionType === OrganizationSubscriptionType.UserLedTrial &&
      sub.status === OrganizationSubscriptionStatus.Enabled &&
      sub.expiresAt != null &&
      DateFromUTCString(sub.expiresAt) <= new Date()
    );
  });
  if (!oldSub) {
    return null;
  }

  if (
    totalComputeHoursInSeconds > config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS
  ) {
    // TODO(haruka): rename the type to starterToEnterprise
    return {type: NudgeBarType.EnterpriseToAdvanced};
  }

  if (totalComputeHoursInSeconds > config.MAX_COMPUTE_HOURS_IN_SECONDS) {
    return {type: NudgeBarType.EnterpriseToStandard};
  }

  return null;
}

function getComputeHourSubscription(viewerOrg: ViewerOrg) {
  const computeHourSub = viewerOrg.subscriptions.find(
    sub =>
      typeof sub.privileges.compute_hours === 'number' &&
      _.includes(PLANS_WITH_COMPUTE_HOUR_LIMIT, sub.plan.name)
  );
  return computeHourSub;
}
