import {WBSelect} from '@wandb/common/components/WBSelect';
import * as globals from '@wandb/common/css/globals.styles';
import {TargetBlank} from '@wandb/common/util/links';
import {DropdownArrow, WBIcon} from '@wandb/ui';
import styled, {css} from 'styled-components';

export const TableOfContents = styled.div<{selected?: boolean}>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.selected &&
    css`
      background: ${globals.lightSlateBlue};
    `}
`;

export const NoHeadingMessage = styled.div`
  color: ${globals.gray500};
  line-height: 1.8;
`;

export const LearnMoreLink = styled(TargetBlank)`
  color: ${globals.gray500};
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;

export const Anchor = styled.a<{level: number}>`
  color: ${globals.gray500};
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
  font-size: 16px;
  font-family: Source Serif Pro;
  line-height: 1.8;
  padding-left: ${props => (props.level - 1) * 20}px;
  :hover {
    background: ${globals.gray100};
    color: ${globals.gray500};
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
  }
`;

export const ConfigIcon = styled(WBIcon).attrs({name: 'configuration'})`
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 40px;
  cursor: pointer;
`;

export const HeaderSelect = styled(WBSelect)`
  position: absolute;
  top: 2px;
  right: 34px;
  font-size: 14px;
  border-radius: 4px;
  justify-content: space-around;
  width: 152px;
  height: 24px;
  background: ${globals.gray100};
  ${DropdownArrow} {
    border-top: 4px solid ${globals.textPrimary};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;
