import React, {useContext} from 'react';
import {createContext, useMemo} from 'react';
import {Client} from '@wandb/cg';

import {WeaveApp} from '.';

export interface ClientState {
  client?: Client;
}

// minimal allow-list of feature flags mapping to beta feature flags.
export type WeaveWBBetaFeatures = {
  'weave-python-ecosystem'?: boolean;
  'weave-devpopup'?: boolean;
};

export const ClientContext = React.createContext<ClientState>({
  client: undefined,
});

export const WeaveContext = createContext<WeaveApp | null>(null);

export const useWeaveContext = () => {
  const clientContext = useContext(ClientContext);
  const {client} = clientContext;
  if (client == null) {
    throw new Error(
      'Component calling useWeaveContext must have cgreact.ClientContext ancestor'
    );
  }
  return useMemo(() => new WeaveApp(client), [client]);
};
export const WeaveWBBetaFeaturesContext = createContext<WeaveWBBetaFeatures>(
  {}
);
