import {useApolloClient as useApolloApolloClient} from '@apollo/react-hooks';
import {envIsDev} from '@wandb/common/config';
import {usePrevious} from '@wandb/common/state/hooks';
import {difference} from '@wandb/common/util/data';
import _ from 'lodash';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import {
  shallowEqual,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import {Selector} from 'reselect';

import {Dispatch, RootState} from '../types/redux';
import {PanelBankContext} from './panelbank/context';
import * as Types from './types';

export * from '@wandb/common/state/hooks';

// This is a nicer way to make reselect selectors that depend on props
// work with hooks. We recreate the hook whenever the passed in args
// change. You'd typically unpack the props you want and pass them
// in as args.
export const usePropsSelector = <Args extends any[], R>(
  selectorFactory: (...args: Args) => Selector<RootState, R>,
  ...args: Args
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectorFactory(...args), args);
  return useReduxSelector(selector, shallowEqual);
};

export const useDispatch = () => {
  return useReduxDispatch<Dispatch>();
};

export function useChangedFromPrevious<T>(value: T): boolean {
  const prevValue = usePrevious(value);
  return value !== prevValue;
}

export function useChangedFromPreviousDeep<T>(value: T): boolean {
  const prevValue = usePrevious(value);
  return _.isEqual(value, prevValue);
}

type UseSelector = <TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
  debugStr?: string
) => TSelected;

const useSelectorInner: UseSelector = (selector, equalityFn, debugStr) =>
  useReduxSelector(selector, equalityFn);

const useSelectorInnerWithDebug: UseSelector = (
  selector,
  equalityFn = (a, b) => a === b,
  debugStr
) => {
  const result = useSelectorInner(selector, equalityFn);

  const prevResult = usePrevious(result);
  if (debugStr && !equalityFn(prevResult!, result)) {
    console.group(`[useSelector]: ${debugStr} returned different result`);
    console.log('prev', prevResult);
    console.log('next', result);
    console.log('diff', difference(prevResult, result));
    console.groupEnd();
  }

  return result;
};

export const useSelector = envIsDev
  ? useSelectorInnerWithDebug
  : useSelectorInner;

export const useMemoizedSelector: UseSelector = <T>(
  selector: (state: RootState) => T,
  equalityFn: (left: T, right: T) => boolean = (a, b) => a === b,
  debugStr?: string
) => {
  const resultRef = useRef<T>();
  const memoizedSelector = useMemo(
    () => (state: RootState) => {
      const result = selector(state);
      if (resultRef.current == null || !equalityFn(resultRef.current, result)) {
        resultRef.current = result;
      }
      return resultRef.current;
    },
    [selector, equalityFn]
  );
  return useSelector(memoizedSelector, undefined, debugStr);
};

// From https://usehooks.com/useOnScreen/
export function useOnScreen(
  domRef: React.MutableRefObject<Element | null>,
  rootMargin: string = '0px'
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    const domRefValue = domRef.current;
    if (domRefValue) {
      observer.observe(domRefValue);
    }
    return () => {
      if (domRefValue) {
        observer.unobserve(domRefValue);
      }
    };
  }, [domRef, rootMargin]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

// Returns true when domRef becomes onScreen for the first time, and stays
// true until value changes.
export function useWhenOnScreenAfterNewValue(value: any) {
  const {ref, inView} = useInView();
  const prevValue = usePrevious(value);
  // TODO: it would be nice to be able to init ready = true
  // but onScreen always starts false currently
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (inView) {
      if (!ready) {
        setReady(true);
      }
      return;
    }
    if (value !== prevValue && !inView) {
      setReady(false);
    }
  }, [inView, ready, prevValue, value]);
  return [ref, ready] as const;
}

/* Returns true after a ref is on screen for the first time */
export function useWaitToLoadTilOnScreen(
  domRef: React.MutableRefObject<Element | null>
) {
  const elementPageYOffset = domRef.current
    ? (window.pageYOffset || document.documentElement.scrollTop) +
      domRef.current.getBoundingClientRect().top
    : null;
  // Always load content near the top of the page immediately.
  const isAboveFold =
    elementPageYOffset != null ? elementPageYOffset < 1000 : false;
  const [hasRendered, setHasRendered] = useState(false);
  const onScreenTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const isOnScreen = useOnScreen(domRef, '300px');
  const {disableWaitToLoad} = useContext(PanelBankContext);

  useEffect(() => {
    if (hasRendered) {
      return;
    }
    if (isAboveFold || disableWaitToLoad) {
      setHasRendered(true);
    }
    if (!onScreenTimer.current && isOnScreen) {
      onScreenTimer.current = setTimeout(() => {
        setHasRendered(true);
      }, 200);
    } else if (onScreenTimer.current && !isOnScreen) {
      clearTimeout(onScreenTimer.current);
      onScreenTimer.current = undefined;
    }
  }, [hasRendered, isAboveFold, isOnScreen, disableWaitToLoad]);

  if (hasRendered) {
    return true;
  }

  return false;
}

export const useApolloClient: () => Types.ApolloClient = () => {
  return useApolloApolloClient() as Types.ApolloClient;
};
