import * as globals from '@wandb/common/css/globals.styles';
import Color from 'color';
import {Button, Checkbox, Label, Popup} from 'semantic-ui-react';
import styled from 'styled-components';

export const ProjectAccessModalCheckbox = styled(Checkbox)`
  display: block !important;
  margin-bottom: 16px;
`;

export const ProjectAccessOptionLabel = styled.div`
  display: flex;
  align-items: center;

  // Style the new icons
  svg {
    width: 20px;
    height: 20px;
  }

  // Style the global icon that's using LegacyWBIcon - need to move off of this
  i {
    font-size: 20px;
  }

  // Style the red warning icons
  .red {
    font-size: 16px;
  }

  // Add spacing between the label text
  > * {
    margin-right: 8px;
  }
`;

export const ProjectAccessOptionDescription = styled.div`
  margin-left: 30px;
  margin-top: 4px;
  color: ${globals.gray500};
`;

export const ShowAdvancedSettingsLink = styled.span`
  color: ${globals.gray500};
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
    color: darken(${globals.gray500}, 10%);
  }
`;

export const CompactProjectAccessIcon = styled.span`
  margin-right: 4px;

  svg {
    width: 18px;
    height: 18px;
    transform: translateY(4px);
  }

  i {
    font-size: 18px;
    transform: translateY(4px);
    margin: 0 !important;
  }

  &:hover {
    cursor: pointer;
    svg {
      color: white;
    }
  }
`;

export const ProjectAccessIconWithLabel = styled(Label)<{
  $color: string;
}>`
  display: flex !important;
  align-items: center;
  background-color: ${props => props.$color} !important;
  color: white !important;

  svg {
    width: 16px !important;
    height: 16px;
    margin-right: 4px;
  }

  i {
    font-size: 16px;
    margin-right: 4px !important;
  }

  &:hover {
    cursor: pointer;
    background: ${props =>
      Color(props.$color).darken(0.1).toString()} !important;
  }
`;

export const CreateTeamPrompt = styled.div`
  background-color: ${globals.gray100};
  padding: 24px;
  border-radius: 5px;
  margin-top: 16px;
`;

export const CreateTeamText = styled.div`
  font-weight: 600;
`;

export const CreateTeamButton = styled(Button)`
  &&&&& {
    margin-top: 8px;
    box-shadow: none !important;
  }
`;

export const TeamSettingsPrompt = styled.div`
  background-color: ${globals.gray100};
  padding: 16px;
  border-radius: 5px;
  margin-top: 16px;
`;

export const TooltipPopup = styled(Popup)`
  &&&&& {
    border: none;
    background-color: ${globals.gray700};
    border-color: ${globals.gray700};
    color: ${globals.white};
    padding: 3px 8px 5px;
    font-size: 12px;
    max-width: none;
    border-radius: 3px;

    &:before {
      background-color: ${globals.gray700};
      border-color: ${globals.gray700};
      box-shadow: none;
    }
  }
`;
