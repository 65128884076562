import React from 'react';
import {Graph} from 'src/types/graphql';

import {EntityPageResultsLists} from './EntityPageResultsLists';
import {EntityPageStarterContent} from './EntityPageStarterContent';
import {HomePageResultsLists} from './HomePageResultsLists';
import {HomePageStarterContent} from './HomePageStarterContent';
import {useSearchContext} from './SearchContext';
import {SearchNavTeam} from './types';

export interface SearchResultsDropdownProps {
  blurAndClear: () => void;
  setShowAllContributedResults: (value: boolean) => void;
  setShowAllEntityResults: (value: boolean) => void;
  setShowAllRemainingResults: (value: boolean) => void;
  showAllContributedResults: boolean;
  showAllEntityResults: boolean;
  showAllRemainingResults: boolean;
  teams: Graph<SearchNavTeam>;
}
export const SearchResultsDropdown = ({
  blurAndClear,
  setShowAllContributedResults,
  setShowAllEntityResults,
  setShowAllRemainingResults,
  showAllContributedResults,
  showAllEntityResults,
  showAllRemainingResults,
  teams,
}: SearchResultsDropdownProps) => {
  const {currentQuery, entityName} = useSearchContext();

  return (
    <div
      className="local-search-dropdown"
      onMouseDown={(e: any) => e.preventDefault()}>
      <div className="dropdown-content">
        {currentQuery ? (
          <div className="search-results">
            {entityName ? (
              <EntityPageResultsLists
                blurAndClear={blurAndClear}
                setShowAllContributedResults={setShowAllContributedResults}
                setShowAllEntityResults={setShowAllEntityResults}
                setShowAllRemainingResults={setShowAllRemainingResults}
                showAllContributedResults={showAllContributedResults}
                showAllEntityResults={showAllEntityResults}
                showAllRemainingResults={showAllRemainingResults}
              />
            ) : (
              <HomePageResultsLists
                blurAndClear={blurAndClear}
                setShowAllContributedResults={setShowAllContributedResults}
                setShowAllRemainingResults={setShowAllRemainingResults}
                showAllContributedResults={showAllContributedResults}
                showAllRemainingResults={showAllRemainingResults}
              />
            )}
          </div>
        ) : entityName ? (
          <EntityPageStarterContent
            blurAndClear={blurAndClear}
            setShowAllEntityResults={setShowAllEntityResults}
            showAllEntityResults={showAllEntityResults}
          />
        ) : (
          <HomePageStarterContent blurAndClear={blurAndClear} teams={teams} />
        )}
      </div>
    </div>
  );
};
