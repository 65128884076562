import {JSONNaN} from '@wandb/cg';
import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import PanelError from '@wandb/common/components/elements/PanelError';
import {
  isTableMetadata,
  TableCellValue,
  TableMetadata,
} from '@wandb/common/types/media';
import {saveMediaTableAsCSV} from '@wandb/common/util/csv';
import _ from 'lodash';
import React, {useLayoutEffect, useState} from 'react';
import {Column} from 'react-table';

import {getLastHistoryRowWithKey, labelComponent} from '../util/media';
import {runLink} from '../util/runhelpers';
import {MediaCardProps} from './MediaCard';
import MessageMediaNotFound from './MessageMediaNotFound';
import WBReactTable, {DataRow} from './WBReactTable';

const TableCard: React.FunctionComponent<MediaCardProps> = React.memo(props => {
  const {run, mediaKey, mediaIndex, runSignature} = props;
  const {tableData, step, error} = useTableCardData(props);

  const {tableDataRows, tableColumns} = getRowsAndColumns(tableData);

  const titleLink = runLink(runSignature, run.displayName, {
    className: 'hide-in-run-page',
    target: '_blank',
    rel: 'noopener noreferrer',
  });

  return (
    <div className="table-card content-card" style={{width: '100%'}}>
      {/* TITLE (Run Name or Description) */}
      {labelComponent(props, step, titleLink)}
      {tableData != null && (
        <>
          <WBReactTable
            className="panel-table"
            data={tableDataRows}
            columns={tableColumns}
            pageSize={50}
            pageSizeOptions={[20, 50, 100]}
            sortable
            resizable
          />
          <LegacyWBIcon
            className="content-card__download"
            onClick={() => saveMediaTableAsCSV(tableData)}
            name="download"
          />
        </>
      )}
      {tableData == null &&
        (error != null ? (
          <PanelError className="media-missing" message={<div>{error}</div>} />
        ) : (
          <MessageMediaNotFound
            mediaIndex={mediaIndex}
            mediaKey={mediaKey}
            stepIndex={step}
            mediaType="table"
          />
        ))}
    </div>
  );
});

export default TableCard;

type TableCardData = {
  tableData: TableMetadata | null;
  step: number | undefined;
  error: string | undefined;
};

function useTableCardData(props: MediaCardProps): TableCardData {
  const {run, globalStep, mediaKey, tileMedia} = props;
  const blob = tileMedia?.blob;

  // If this is file-based media, we put the contents of the file here once
  // loaded
  const [fileTableData, setFileTableData] = useState<TableMetadata | null>(
    null
  );
  const [error, setError] = useState<string | undefined>();

  useLayoutEffect(() => {
    if (blob == null) {
      return;
    }
    (async () => {
      const fileContents = await new Response(blob).text();
      const parsedTable = JSONNaN.JSONparseUserFile(fileContents);

      if (parsedTable.error) {
        setError("Couldn't parse JSON data.");
        return;
      }

      setFileTableData({
        _type: 'table',
        columns: parsedTable.result.columns
          .filter((col: any) => col != null)
          .map((col: any) => (col === '' ? ' ' : col)),
        data: parsedTable.result.data,
      });
    })();
  }, [blob]);

  const historyRow = getLastHistoryRowWithKey(run, mediaKey, globalStep);
  const historyRowMediaValue = historyRow?.[mediaKey];
  const tableDataFromHistoryRow = isTableMetadata(historyRowMediaValue)
    ? historyRowMediaValue
    : null;

  return {
    tableData: fileTableData ?? tableDataFromHistoryRow,
    step: tileMedia?.step ?? historyRow?._step,
    error,
  };
}

type RowsAndColumns = {
  tableDataRows: DataRow[];
  tableColumns: Array<Column<any>>;
};

function getRowsAndColumns(tableData: TableMetadata | null): RowsAndColumns {
  if (tableData == null) {
    return {tableDataRows: [], tableColumns: []};
  }

  const tableDataRows: DataRow[] = [];
  if (tableData.data != null && tableData.data.length > 0) {
    if (_.isArray(tableData.data[0])) {
      for (const row of tableData.data as TableCellValue[][]) {
        tableDataRows.push(getTableDataRow(row));
      }
    } else {
      tableDataRows.push(getTableDataRow(tableData.data));
    }
  }

  const tableColumns: Column[] = tableData.columns
    .filter(colName => colName)
    .map((colName, i) => ({
      id: colName.toString(),
      Header: colName,
      accessor: r => r[i],
    }));

  return {tableDataRows, tableColumns};
}

type TableDataRow = {
  row: Array<string | number>;
  searchString: string;
};

function getTableDataRow(row: TableCellValue[]): TableDataRow {
  // Narrow the typing to what is supported in ReactTable
  const simplified = row.map(c => {
    if (typeof c === 'string') {
      return c;
    }
    if (typeof c === 'number') {
      return c;
    }
    return JSON.stringify(c);
  });
  return {
    row: simplified,
    searchString: simplified.join(' '),
  };
}
