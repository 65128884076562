import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

export const CommentWrapper = styled.span`
  border-left: 3px solid ${globals.gray500};
  color: ${globals.gray600};
  margin: 8px 0;
  padding-left: 12px;
  display: flex;
`;
