import styled, {css} from 'styled-components';

import {REPORT_FONT_SIZE} from '../constants';

export const EmbeddedIframe = styled.iframe<{
  selected: boolean;
}>`
  display: block;
  height: 170px;
  margin-bottom: ${Math.round(REPORT_FONT_SIZE * 0.8)}px !important;

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 0 6px rgb(208, 225, 252);
    `}
`;
