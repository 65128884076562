import * as globals from '@wandb/common/css/globals.styles';
import styled from 'styled-components';

import {GLOBAL_COLORS} from '../../util/colors';

export const Wrapper = styled.div`
  .routed-tab .ui.tabular.menu {
    padding: 0;
    background: none;
    border: none;
    margin-bottom: 24px;
    border-bottom: 1px solid ${GLOBAL_COLORS.outline.toString()};
    overflow: visible;
    .item {
      border: none;
    }
    .item.active {
      border: 1px solid ${GLOBAL_COLORS.outline.toString()};
      border-bottom: none;
      background: ${GLOBAL_COLORS.background.toString()};
      margin-bottom: -1px;
      padding-bottom: 12px;
    }
  }
`;

// TODO - move this into a EditableImage.styles.ts file
export const AvatarPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 64px;
    color: ${globals.gray700};
  }
`;
