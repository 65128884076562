import * as globals from '@wandb/common/css/globals.styles';
import styled, {css} from 'styled-components';

import {GLOBAL_COLORS} from '../../util/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Header = styled.div`
  margin-bottom: 4px;
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% + 8px);
  margin: 0 -4px;
`;

export const MappingColumn = styled.div<{align: string}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align === 'left' ? 'flex-start' : 'flex-end')};
`;

export const MappingItemWrapper = styled.div<{
  active?: boolean;
  pairing?: boolean;
  error?: boolean;
}>`
  position: relative;
  background: ${props => (props.error ? '#f9d9e0' : '#d1f0fb')};
  color: ${props => (props.error ? '#e21a46' : globals.primary)};
  border-radius: 14px;
  margin-bottom: 6px;
  width: fit-content;
  max-width: 100px;
  border: 1px solid transparent;
  cursor: default;
  &:hover {
    ${props =>
      !props.active &&
      !props.pairing &&
      css`
        filter: saturate(1.3) brightness(0.97);
        // border: 1px solid ${GLOBAL_COLORS.primary.alpha(0.25).toString()};
      `}
  }
  ${props =>
    props.pairing &&
    css`
      border: 1px dashed ${GLOBAL_COLORS.primary.toString()};
    `}
  ${props =>
    props.active &&
    css`
      border: 1px dashed ${GLOBAL_COLORS.primary.toString()};
    `}
`;

export const MappingItemWrapperInner = styled.div`
  direction: rtl;
  overflow: auto;
  scrollbar-width: 0;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    display: none;
  }
  mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 4px,
      rgba(0, 0, 0, 1) 12px,
      rgba(0, 0, 0, 1) 55%,
      rgba(0, 0, 0, 0) 55%
    ),
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 4px,
      rgba(0, 0, 0, 1) 12px,
      rgba(0, 0, 0, 1) 55%,
      rgba(0, 0, 0, 0) 55%
    );
`;

export const MappingItem = styled.div`
  direction: rtl;
  font-size: 14px;
  padding: 2px 12px;
  width: fit-content;
`;

export const MappingLine = styled.line`
  border: 1px solid green;
  &:hover {
    opacity: 0.25;
  }
`;
