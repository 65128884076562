import {ToastContainer} from '@wandb/common/components/elements/Toast';
import {envIsLocal} from '@wandb/common/config';
import _ from 'lodash';
import React from 'react';
import {Redirect, Switch} from 'react-router';

import App from './App';
import AddEmailAlert from './components/AddEmailAlert';
import ComputeHourNudgeBar from './components/ComputeHourNudgeBar';
import {DisabledSubscriptionEnforcement} from './components/DisabledSubscriptionEnforcement';
import {ExpiredSubscriptionEnforcement} from './components/ExpiredSubscriptionEnforcement';
import {ExpiringSubscriptionNudgeBar} from './components/ExpiringSubscriptionNudgeBar';
import GlobalNudgeBarContextProvider from './components/GlobalNudgeBarContextProvider';
import ImpersonatingNudgeBar from './components/ImpersonatingNudgeBar';
import {GetLicenseNudgeBar} from './components/LocalLicenseGetNudge';
import LocalLicenseNudgeBar from './components/LocalLicenseNudgeBar';
import LocalNudgeBar from './components/LocalNudgeBar';
import MessageOfTheDay from './components/MessageOfTheDay';
import NavContextProvider from './components/NavContextProvider';
import NoMatch from './components/NoMatch';
import SearchNav from './components/Search/SearchNav';
import StorageNudgeBar from './components/StorageNudgeBar';
import TrialNudgeBar from './components/TrialNudgeBar';
import {InstrumentedLoader as Loader} from './components/utility/InstrumentedLoader';
import {ROUTES} from './routes/routes';
import {ROUTES_DATA} from './routes/routesData';
import RouteWithLayout from './routes/RouteWithLayout';
import {RouteData} from './routes/types';
import {useViewer} from './state/viewer/hooks';

export function makeRoutes(): RouteData[] {
  // HAX: These checks ensure that the route mapping above is in sync with routes/routes.ts.
  // routes/routes.ts is shared between the main app and the host function.
  if (ROUTES_DATA.length !== ROUTES.length) {
    throw new Error(
      `mismatched routes length: ${ROUTES_DATA.length}, ${ROUTES.length}`
    );
  }
  for (let i = 0; i < ROUTES_DATA.length; i++) {
    const r = ROUTES_DATA[i];
    const rc = ROUTES[i];
    if (
      !_.isEqual(r.path, rc.path) ||
      r.redirect !== rc.redirect ||
      r.exact !== rc.exact
    ) {
      throw new Error(`mismatched routes: ${r.path}, ${rc.path}`);
    }
  }

  return ROUTES_DATA;
}

const MaybeLocalNudgeBar: React.FC = () => {
  const viewer = useViewer();
  return envIsLocal && viewer != null ? <LocalNudgeBar /> : <></>;
};

const Routes: React.FC = () => {
  return (
    <App>
      <GlobalNudgeBarContextProvider>
        <NavContextProvider>
          <SearchNav />
          {!envIsLocal && <ImpersonatingNudgeBar />}
          <StorageNudgeBar />
          <MessageOfTheDay />
          {envIsLocal && <GetLicenseNudgeBar />}
          {envIsLocal && <LocalLicenseNudgeBar />}
          <MaybeLocalNudgeBar />
          {!envIsLocal && <ExpiringSubscriptionNudgeBar />}
          {!envIsLocal && <ExpiredSubscriptionEnforcement />}
          {!envIsLocal && <DisabledSubscriptionEnforcement />}
          {!envIsLocal && <ComputeHourNudgeBar />}
          {!envIsLocal && <TrialNudgeBar />}
          <AddEmailAlert />
          <div className="main">
            <ToastContainer />
            <React.Suspense
              fallback={<Loader name="fallback-loader" samplingRate={0} />}>
              <Switch>
                {makeRoutes()
                  .filter(r => r.condition !== false)
                  .map((r, i) => {
                    if ('redirect' in r) {
                      return <Redirect key={i} from={r.path} to={r.redirect} />;
                    }
                    const {RouteComponent, condition, ...rest} = r;
                    const RC = RouteComponent as any;
                    return <RC key={i} {...rest} />;
                  })}
                <RouteWithLayout component={NoMatch} />
              </Switch>
            </React.Suspense>
          </div>
        </NavContextProvider>
      </GlobalNudgeBarContextProvider>
    </App>
  );
};

export default Routes;
