import styled, {keyframes} from 'styled-components';

const FLASHER_SIZE = 14;

const flash = keyframes`
  0%   {
    transform: scale(1);
    opacity: 0.4;
  }
  50%  {
    transform: scale(4);
    opacity: 0;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
`;

export const BlueDot = styled.div`
  position: absolute;
  width: ${FLASHER_SIZE}px;
  height: ${FLASHER_SIZE}px;
  transform: translate(-${FLASHER_SIZE / 2}px, -${FLASHER_SIZE / 2}px);
  border-radius: 50%;
  background: rgb(17, 187, 255);
  // We probably want to set the z-index case-by-case
  // NOTE: if you add this to workspace panels,
  // don't forget that the expanded table (z-index: 100) should cover it
  // z-index: 10000;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(17, 187, 255);
    animation: 2s ease-out 0s ${flash};
    animation-iteration-count: infinite;
  }
`;
