import wait from '@wandb/common/util/wait';
import _ from 'lodash';
import {useEffect, useState} from 'react';

const BEAMER_INIT_RETRIES = 10;

let newPosts = 0;
const newPostsListeners: {[key: string]: (newPosts: number) => void} = {};

function updateNewPosts(np: number): void {
  newPosts = np;
  for (const fn of Object.values(newPostsListeners)) {
    fn(np);
  }
}

export function useBeamerNewPosts(): number {
  const [np, setNP] = useState(newPosts);

  useEffect(() => {
    const key = Math.random();
    newPostsListeners[key] = setNP;
    return () => {
      delete newPostsListeners[key];
    };
  }, []);

  return np;
}

export const beamerInit = _.once(() => {
  return new Promise(async resolveInit => {
    let tries = 0;
    while (window.Beamer == null && tries < BEAMER_INIT_RETRIES) {
      tries++;
      await wait(500);
    }
    const b = window.Beamer;
    if (b == null) {
      resolveInit(false);
      return;
    }
    b.update({
      callback: (np: number) => {
        updateNewPosts(np);
        resolveInit(true);
      },
      onopen: () => {
        updateNewPosts(0);
      },
    });
    b.init();
  });
});

export async function openBeamer() {
  if (await beamerInit()) {
    window.Beamer.show();
  }
}

export function disableBeamer() {
  localStorage.setItem(
    'disableBeamerUntil',
    // Disabled for 1 day
    (Date.now() + 1000 * 60 * 60 * 24 * 1).toString()
  );
}

export function openBeamerOnLoad() {
  if (
    localStorage.disableBeamerUntil == null ||
    Date.now() >= Number(localStorage.disableBeamerUntil)
  ) {
    beamerInit();
  }
}
