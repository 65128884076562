import * as Panel2 from '../panel';
import React from 'react';
import {inputType} from './common';
export {defaultFacet} from './common';

export const Spec: Panel2.PanelSpec = {
  hidden: true,
  id: 'Facet',

  ConfigComponent: React.lazy(() =>
    import('./Component').then(module => ({default: module.PanelFacetConfig}))
  ),

  Component: React.lazy(() => import('./Component')),
  inputType,
};
