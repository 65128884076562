import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import React, {useCallback} from 'react';
import {toast} from 'react-toastify';
import {Popup, Table} from 'semantic-ui-react';

import {
  EventTriggeringConditionType,
  FetchProjectTriggersQueryResult,
  Trigger,
  TriggeredActionType,
  TriggerScopeType,
  useCreateFilterTriggerMutation,
  useDeleteTriggerMutation,
  useEntityIntegrationsQuery,
  useEntityQuery,
  useFetchProjectTriggersQuery,
} from '../generated/graphql';
import * as S from './ArtifactCollectionSettings.styles';
import SlackIntegration, {isSlackIntegration} from './SlackIntegration';
import {UserSettingsPlaceholder} from './UserSettings/UserSettingsPage';

type Slack = {
  id: string;
  type: 'slack';
  teamName: string;
  channelName: string;
};

type Integration = Slack; // Make this a union type when we support other integrations (i.e email)

type EntityIntegrations = {
  loading: boolean;
  entityName: string;
  data: Integration[];
  refetch: () => Promise<any>;
};

interface ArtifactCollectionSettingsOuterProps {
  entityName: string;
  projectName: string;
  artifactCollectionName: string;
}

interface ArtifactCollectionSettingsProps {
  integrations: EntityIntegrations;
  artifactCollectionID: string;
  artifactCollectionName: string;
  triggersQueryResult: FetchProjectTriggersQueryResult;
  triggers?: Trigger[];
}

const useEntityIntegrations = (entityName: string): EntityIntegrations => {
  const {data, loading, error, refetch} = useEntityIntegrationsQuery({
    variables: {
      name: entityName,
    },
    fetchPolicy: 'network-only',
  });
  if (loading || !data || !data.entity || error) {
    return {
      loading,
      refetch,
      entityName: '',
      data: [],
    };
  }
  const edges = data.entity.integrations?.edges ?? [];
  const integrations = edges
    .map((e: any) => e.node)
    .filter((n: any) => !!n)
    .filter((n: any) => isSlackIntegration(n as any))
    .map((n: any) => {
      const node = n as any;
      return {
        id: node.id,
        type: 'slack',
        teamName: node.teamName,
        channelName: node.channelName,
      } as Integration;
    });

  return {
    loading,
    refetch,
    entityName: data.entity.name,
    data: integrations,
  };
};

const ArtifactCollectionSettingsOuter = (
  props: ArtifactCollectionSettingsOuterProps
) => {
  const {entityName, projectName, artifactCollectionName} = props;
  const entityIntegrations = useEntityIntegrations(entityName);

  const triggersQuery = useFetchProjectTriggersQuery({
    variables: {projectName, entityName},
  });

  const artifactCollectionNode = Op.opProjectArtifact({
    project: Op.opRootProject({
      entityName: Op.constString(entityName),
      projectName: Op.constString(projectName),
    }),
    artifactName: Op.constString(artifactCollectionName),
  }) as GraphTypes.OutputNode<'artifact'>;

  const artifactCollection = useNodeValue(artifactCollectionNode);

  if (
    entityIntegrations.loading ||
    triggersQuery.loading ||
    triggersQuery.data == null ||
    artifactCollection.loading
  ) {
    return <UserSettingsPlaceholder />;
  }

  return (
    <ArtifactCollectionSettingsInner
      integrations={entityIntegrations}
      artifactCollectionID={artifactCollection.result.id}
      triggersQueryResult={triggersQuery}
      artifactCollectionName={artifactCollectionName}
    />
  );
};

const ArtifactCollectionSettingsInner: React.FC<ArtifactCollectionSettingsProps> =
  ({
    integrations,
    artifactCollectionID,
    triggersQueryResult,
    artifactCollectionName,
  }) => {
    // we need this for the SlackIntegrations component below
    // TODO: refactor SlackIntegrations so that this entityQuery is not required
    const entityQuery = useEntityQuery({
      variables: {
        name: integrations.entityName,
      },
      fetchPolicy: 'cache-and-network',
    });

    if (entityQuery.loading || !entityQuery.data) {
      return <UserSettingsPlaceholder />;
    }

    return (
      <S.SettingsContainer>
        <S.SettingsWrapper>
          <S.NotificationsContainer>
            <S.SectionHeader as="h2">
              {'Automated Notifications'}
            </S.SectionHeader>
            <p style={{color: 'darkgrey'}}>
              Notify the team when changes happen in the model registry.
            </p>
            <S.NotificationsTable>
              <NotificationsTable
                integrations={integrations}
                artifactCollectionID={artifactCollectionID}
                triggersQueryResult={triggersQueryResult}
                artifactCollectionName={artifactCollectionName}
              />
            </S.NotificationsTable>
            <SlackIntegration
              entity={entityQuery.data.entity}
              entityRefetch={entityQuery.refetch}
              integrationReason={
                'Configure a Slack integration so we can send you alerts.'
              }
              hideDisconnect={true}
            />
          </S.NotificationsContainer>
        </S.SettingsWrapper>
      </S.SettingsContainer>
    );
  };

interface NotificationTriggerRowProps {
  eventType: EventTriggeringConditionType;
  eventDescription: string;
  slackIntegration?: typeof SlackIntegration;
  artifactCollectionID: string;
  artifactCollectionName: string;
  triggerID?: string;
  enabled: boolean;
  entityRefetch: any;
  triggersQueryResult: FetchProjectTriggersQueryResult;
}

interface NotificationsTableProps {
  integrations: EntityIntegrations;
  artifactCollectionID: string;
  artifactCollectionName: string;
  triggersQueryResult: FetchProjectTriggersQueryResult;
}

const NotificationsTable: React.FC<NotificationsTableProps> = ({
  integrations,
  artifactCollectionID,
  triggersQueryResult,
  artifactCollectionName,
}) => {
  const slackIntegration = React.useMemo(() => {
    for (const i of integrations.data) {
      if (i.type === 'slack') {
        return i;
      }
    }
    return null;
  }, [integrations]);

  // Fetch all notification triggers for this artifact collection
  const notificationTriggers = React.useMemo(() => {
    // TODO: Add triggers edge from ArtifactCollection to make this cleaner.
    const projectTriggers = triggersQueryResult.data?.project?.triggers ?? [];
    const artifactCollectionScopes = ['ArtifactSequence', 'ArtifactPortfolio'];
    return projectTriggers.filter(t => {
      return (
        t.triggeredAction.__typename === 'NotificationTriggeredAction' &&
        artifactCollectionScopes.includes(t.scope.__typename) &&
        t.scope.id === artifactCollectionID
      );
    });
  }, [artifactCollectionID, triggersQueryResult.data?.project?.triggers]);

  const getDescription = (eventType: EventTriggeringConditionType) => {
    if (eventType === EventTriggeringConditionType.LinkModel) {
      return 'Model version linked to the model registry.';
    }
    return '';
  };

  const supportedEvents = [EventTriggeringConditionType.LinkModel];

  type triggerEnabled = {
    enabled: boolean;
    id?: string;
  };

  const enabledNotifications: {
    [T in EventTriggeringConditionType]: triggerEnabled;
  } = {
    [EventTriggeringConditionType.LinkModel]: {enabled: false},
    [EventTriggeringConditionType.CreateArtifact]: {enabled: false},
    [EventTriggeringConditionType.UpdateArtifactAlias]: {enabled: false},
  };

  for (const n of notificationTriggers) {
    const current = n.triggeringCondition.eventType;
    enabledNotifications[current] = {
      enabled: true,
      id: n.id,
    };
  }

  return (
    <Table className={'alerts--table'} basic={'very'}>
      <Table.Header>
        <Table.Row>
          <S.HeaderCellBold>Events</S.HeaderCellBold>
          <S.HeaderCellBold>Slack</S.HeaderCellBold>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supportedEvents.map((e, i) => {
          return (
            <NotificationTriggerRow
              key={e}
              eventType={e}
              eventDescription={getDescription(e)}
              artifactCollectionID={artifactCollectionID}
              enabled={enabledNotifications[e].enabled}
              triggerID={enabledNotifications[e].id}
              slackIntegration={slackIntegration}
              entityRefetch={integrations.refetch}
              triggersQueryResult={triggersQueryResult}
              artifactCollectionName={artifactCollectionName}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

const getTitleAndMessage = (
  eventType: EventTriggeringConditionType,
  artifactCollectionName: string
) => {
  let title = 'Automated Notification';
  let message = 'Event fired';
  if (eventType === EventTriggeringConditionType.LinkModel) {
    title = `New model version linked to the registered model: ${artifactCollectionName}`;
    message = `Insert Artifact Info here`;
  }
  return {title, message};
};

const NotificationTriggerRow: React.FC<NotificationTriggerRowProps> = ({
  eventType,
  eventDescription,
  slackIntegration,
  artifactCollectionID,
  triggerID,
  enabled,
  entityRefetch,
  triggersQueryResult,
  artifactCollectionName,
}) => {
  // TODO: Delete after testing
  // const [currentTriggerID, setTriggerID] = useState<string>();
  const [createTriggerMutation] = useCreateFilterTriggerMutation();
  const [deleteTriggerMutation] = useDeleteTriggerMutation();

  // TODO: Delete after testing
  // React.useEffect(() => {
  //   if (triggerID != null) {
  //     setTriggerID(triggerID);
  //   }
  // }, [triggerID]);

  const deleteNotificationTrigger = useCallback(async () => {
    if (triggerID != null) {
      await deleteTriggerMutation({
        variables: {triggerID},
      });
      await entityRefetch();
      await triggersQueryResult.refetch();
    }
    return null;
  }, [triggerID, deleteTriggerMutation, entityRefetch, triggersQueryResult]);

  const createNotificationTrigger = useCallback(async () => {
    if (slackIntegration == null) {
      return;
    }

    const {title, message} = getTitleAndMessage(
      eventType,
      artifactCollectionName
    );
    const actionPayload = {
      notificationActionInput: {
        integrationID: slackIntegration.id,
        title,
        message,
      },
    };
    try {
      await createTriggerMutation({
        variables: {
          name: `slack-link-artifact`, // name is unique within scope
          triggeringEventType: eventType,
          scopeType: TriggerScopeType.ArtifactCollection,
          scopeID: artifactCollectionID,
          eventFilter: JSON.stringify({}),
          triggeredActionType: TriggeredActionType.Notification,
          triggeredActionConfig: actionPayload,
          enabled: true,
        },
      });
      await entityRefetch();
      await triggersQueryResult.refetch();
    } catch (error) {
      console.error(error);
      toast(
        'Something went wrong when trying to create this automated Slack notification.'
      );
    }
  }, [
    slackIntegration,
    artifactCollectionID,
    artifactCollectionName,
    createTriggerMutation,
    entityRefetch,
    eventType,
    triggersQueryResult,
  ]);

  return (
    <Table.Row>
      <Table.Cell>{eventDescription}</Table.Cell>
      <Table.Cell>
        <SlackToggle
          disabled={slackIntegration == null}
          disabledReason={'Connect Slack to trigger alerts for this event'}
          onToggleTrue={createNotificationTrigger}
          onToggleFalse={deleteNotificationTrigger}
          initialChecked={enabled}
        />
      </Table.Cell>
    </Table.Row>
  );
};

interface SlackToggleProps {
  initialChecked: boolean;
  disabled: boolean;
  disabledReason: string;
  onToggleTrue: () => Promise<any>;
  onToggleFalse: () => Promise<any>;
}

const SlackToggle: React.FC<SlackToggleProps> = ({
  disabled,
  disabledReason,
  onToggleTrue,
  onToggleFalse,
  initialChecked,
}) => {
  const [checked, setChecked] = React.useState(initialChecked);
  return (
    <Popup
      disabled={!disabled}
      content={disabledReason}
      on={'hover'}
      trigger={
        <>
          <S.Toggle
            toggle
            disabled={disabled}
            checked={checked}
            onChange={(e: any, data: any) => {
              if (data.checked) {
                onToggleTrue().then(() => {
                  toast('Notification created successfully!');
                });
              } else {
                onToggleFalse().then(() => {
                  toast('Removed automated notification.');
                });
              }
              setChecked(!checked);
            }}
          />
        </>
      }
    />
  );
};

export default ArtifactCollectionSettingsOuter;
