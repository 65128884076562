import {escapeHTML} from '@wandb/common/util/html';

import {RunWithRunsetInfo} from '../../state/runs/types';
import * as Run from '../../util/runs';
import {RunDataSeries} from './types';

export type LegendPosition = 'north' | 'south' | 'east' | 'west';
export function isLegendPosition(s: string): s is LegendPosition {
  return s === 'north' || s === 'south' || s === 'east' || s === 'west';
}

export type LegendOrientation = 'horizontal' | 'vertical';
export function isLegendOrientation(s: string): s is LegendOrientation {
  return s === 'horizontal' || s === 'vertical';
}

export function getLegendOverrideKeyBackwardsCompatible(
  line: RunDataSeries,
  useRunOrGroupId: boolean,
  overrides: {[key: string]: any}
) {
  // Previously we always used the useRunOrGroupId=false in the getLegendOverrideKey,
  // so we check for that first.  At some point perhaps we can remove this weird
  // logic but I really don't want to affect anyone's charts.  (This would not affect
  // reports or runs pages, so the risk isn't enormous)
  if (useRunOrGroupId === true) {
    return getLegendOverrideKey(line);
  }

  const oldKey = getLegendOverrideKey(line);
  if (oldKey in overrides) {
    return oldKey;
  }
  return getLegendOverrideKey(line, false);
}

export function getLegendOverrideKey(
  line: RunDataSeries,
  useRunOrGroupId: boolean = true
) {
  // Do not change this function! It is the key for the overrides
  // this is the key for the override maps

  // We don't want to use the runOrGroupId for a per run plot
  // because we want to keep the legend overrides when the
  // user changes the run - see: WB-3257
  if (useRunOrGroupId === false) {
    return line.metricName || '';
  }

  // line.uniqueId is unique per run, but we want our legend override
  // to distinguish between metrics in the case that a plot
  // has multiple metrics for a single line
  return (line.uniqueId || '') + ':' + (line.metricName || '');
}

export function legendHTMLFromRun(
  run: RunWithRunsetInfo,
  keys: string[]
): string {
  const legendFields = keys.map(key => {
    const val = Run.getValueFromKeyString(run, key);
    return {
      title: Run.keyStringDisplayName(key),
      value: Run.isTimeKeyString(key)
        ? Run.formatTimestamp(val)
        : Run.isDurationKeyString(key)
        ? Run.formatDuration(val)
        : Run.displayValue(val),
    };
  });
  return legendFields
    .map(legendField => {
      return (
        "<div><span class='key'>" +
        escapeHTML(legendField.title) +
        "</span>: <span class='value'>" +
        escapeHTML(legendField.value) +
        '</span></div>'
      );
    })
    .join('\n');
}
