import classNames from 'classnames';
import * as React from 'react';
import {Link} from 'react-router-dom';

type WBLogoProps = {
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
};

const WBLogo: React.FC<WBLogoProps> = ({className, onClick}) => {
  const onSignup = window.location.pathname.endsWith('/signup');
  return (
    <Link
      className={classNames('wandb-logo', className)}
      onClick={onClick}
      to={onSignup ? '/signup' : '/'}>
      <div className="dots-column">
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot big-dot" />
        <div className="dot big-dot" />
      </div>
      <div className="dots-column">
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
      </div>
      <div className="dots-column">
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
      </div>
    </Link>
  );
};

export default WBLogo;
