import {SmoothingType} from '@wandb/common/util/math';

import {LinePlotPanel} from '../components/PanelSettings';
import * as PlotHelpers from './plotHelpers';
export interface Settings {
  xAxisActive?: boolean;
  smoothingActive?: boolean;
  smoothingWeight: number;
  smoothingType: SmoothingType;
  xAxis: PlotHelpers.XAxisValues | string;
  ignoreOutliers: boolean;
  useRunsTableGroupingInPanels: boolean;
  xAxisMin?: number;
  xAxisMax?: number;
  localSmoothingSettingsActive?: number;
  localxAxisSettingsActive?: number;
}

export interface SmoothingSettings {
  smoothingWeight: number;
  smoothingType: SmoothingType;
}

export interface XAxisSettings {
  xAxis: PlotHelpers.XAxisValues | string;
  xAxisMin?: number;
  xAxisMax?: number;
}

export const EMPTY_XAXIS_SETTINGS: XAxisSettings = {
  xAxis: PlotHelpers.XAxisValues.Step,
  xAxisMin: undefined,
  xAxisMax: undefined,
};

export const EMPTY_SMOOTHING_SETTINGS: SmoothingSettings = {
  smoothingWeight: 0,
  smoothingType: 'exponential',
};

export const EMPTY_SETTINGS: Settings = {
  ...EMPTY_XAXIS_SETTINGS,
  ...EMPTY_SMOOTHING_SETTINGS,
  ignoreOutliers: false,
  xAxisActive: false,
  smoothingActive: false,
  useRunsTableGroupingInPanels: true,
};

const panelUsingOwnSmoothingSettings = (panel: LinePlotPanel) => {
  const {
    smoothingWeight,
    smoothingType,
    useGlobalSmoothingWeight,
    useLocalSmoothing,
  } = panel.config;
  return (
    (useGlobalSmoothingWeight == null || useGlobalSmoothingWeight === false) &&
    (useLocalSmoothing == null || !useLocalSmoothing) &&
    (smoothingWeight != null || smoothingType != null)
  );
};

export const getPanelSettingsGroups = (
  panels: LinePlotPanel[],
  usingWorkspaceSettings: boolean
) => {
  const linePlotPanelsUsingOwnSmoothing: LinePlotPanel[] = [];
  const linePlotPanelsUsingLocalSmoothing: LinePlotPanel[] = [];
  const linePlotPanelsUsingSettingSmoothing: LinePlotPanel[] = [];
  panels.forEach(panel => {
    const usingOwnSettings = panelUsingOwnSmoothingSettings(panel);
    if (usingOwnSettings) {
      linePlotPanelsUsingOwnSmoothing.push(panel);
      return;
    }
    const {useLocalSmoothing} = panel.config;
    const workspaceSettingsButPanelUsesLocal =
      useLocalSmoothing && usingWorkspaceSettings;
    if (workspaceSettingsButPanelUsesLocal) {
      linePlotPanelsUsingLocalSmoothing.push(panel);
      return;
    }
    linePlotPanelsUsingSettingSmoothing.push(panel);
  });
  return {
    linePlotPanelsUsingOwnSmoothing,
    linePlotPanelsUsingLocalSmoothing,
    linePlotPanelsUsingSettingSmoothing,
  };
};
