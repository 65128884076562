import {WBIconButton} from '@wandb/ui';
import styled from 'styled-components';

const colorBarHeight = 3;
const cardPadding = 8;
const registryHighlightColor = '#ffc933';
const listViewWidth = 300;
const itemPaddingTop = 12;
const itemPaddingBottom = 5;
const itemPaddingRight = 12;
const itemPaddingLeft = 15;
const itemRadius = 3;
const tileColor = '#ebebeb';
const paperColor = '#fff';

export const EmptyRegistryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: #f9f9f9;
`;

export const TitleHeader = styled.div`
  font-size: 27px;
  font-weight: 600;
  padding: 25px;
`;

export const TitleDescription = styled.div`
  width: 530px;
  margin-bottom: 20px;
  font-size: 17px;
  color: #787877;
`;

export const Quickstart = styled.div`
  width: 600px;
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  border: 1px solid #e3e2e1;
  margin-bottom: 40px;
`;

export const LightCodeLine = styled.code`
  display: inline-block;
  margin-top: 10px;
  width: 80%;
  font-size: 16px;
  background-color: #e3e2e1;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  > .copyable__item {
    width: 100%;
  }
  > .copyable__item:hover {
    background-color: lightgrey;
    width: 100%;
  }
  border-radius: 3px;
  font-family: 'Inconsolata', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
`;

export const SectionHeader = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

export const SectionText = styled.div`
  margin-bottom: 25px;
  font-size: 18px;
  margin-top: 10px;
`;

export const IframeContainer = styled.div`
  text-align: center;
`;

export const QuickstartListContainer = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  > ol {
    padding-left: 20px;
  }

  > ol > li.checked-quickstart-step:first-child:before {
    content: '✓';
    position: relative;
    right: 20px;
    display: inline-block;
    background: white;
    font-size: 22px;
    color: lightgrey;
  }

  > ol > li {
    margin-bottom: 20px;
  }
`;

export const GeneralPageContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  // This is needed to get the height to
  // be correct for children.
  height: calc(100vh - 52px - ${colorBarHeight}px);
  /* background-color: white; */
`;

export const DesktopLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const RegistryHeader = styled.div`
  background-color: white;
  border-top: ${colorBarHeight}px solid ${registryHighlightColor};
  flex: 0 0 auto;
  width: 100%;
  padding: ${cardPadding + itemPaddingLeft}px;
  border-bottom: 2px solid #f0f0f0;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  flex-direction: row;
  justify-content: space-between;

  > button {
    margin-left: auto !important;
    margin-right: 1em;
  }
`;

export const HeaderTitle = styled.div`
  flex: 0 0 auto;
  font-size: 1.6em;
  line-height: 1.3em;
  font-weight: 800;
  > span {
    line-height: 1em;
    font-weight: 500;
  }
`;

export const RegistryContents = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  padding-top: 1px;
  padding-bottom: ${cardPadding}px;
`;

export const MinimizedSidebar = styled.div<{collapsed?: boolean}>`
  height: 100%;
  width: 60px;
  ${props => (props.collapsed ? `` : `display: none;`)}
`;

export const RegistrySidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 5em;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
`;

export const RegistrySidebarEntityName = styled.span`
  font-weight: 400;
  font-size: 1.8em;
`;

export const RegistrySidebarRegistryName = styled.span`
  font-weight: 600;
  font-size: 2em;
`;

export const CollectionListView = styled.div<{collapsed?: boolean}>`
  height: 100%;
  width: 100%;
  padding-left: ${cardPadding}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 ${listViewWidth}px;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 1;

  ${props => (props.collapsed ? `display: none;` : ``)}
`;

export const ChevronRight = styled(WBIconButton)`
  margin-top: 5px !important;
  height: 2.4em;
  width: 100%;
  > span > span > i {
    font-size: 1.5em;
  }
`;

export const ChevronLeft = styled(WBIconButton)`
  > span > span > i {
    font-size: 1.5em;
  }
`;

export const CollectionListItem = styled.div<{selected?: boolean}>`
  flex: 0 0 auto;
  padding-bottom: ${cardPadding}px;
  padding-right: ${cardPadding}px;
  &:last-child {
    padding-bottom: 0px;
  }
  width: 100%;
  min-width: 0;
  ${props =>
    props.selected
      ? `
        padding-right: 0px;
        position: sticky;
        top: 0;
        bottom: -${cardPadding}px;
        &:last-child {
          bottom: 0px;
        }
      `
      : ''}
`;

export const SearchButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const CollectionListSearch = styled.div`
  flex: 1 1 auto;
  padding: ${cardPadding + 1}px ${cardPadding}px ${cardPadding}px 0px;
  > .ui.input > input {
    height: 34px;
  }
  /* border-bottom: 1px solid #f0f0f0; */
`;
export const CollectionList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 1;
`;

export const CollectionListItemContents = styled.div<{selected?: boolean}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: ${tileColor};
  border-radius: ${itemRadius}px;
  padding: ${itemPaddingTop}px ${itemPaddingRight}px ${itemPaddingBottom}px
    ${itemPaddingLeft}px;
  ${props =>
    props.selected
      ? `
      background-color: ${paperColor};
      padding-right: ${cardPadding + itemPaddingRight}px;
      box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
      border-left: ${colorBarHeight}px solid ${registryHighlightColor};
      padding-left: ${itemPaddingLeft - colorBarHeight}px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      z-index: 1;
      `
      : ''}
`;

export const CollectionListItemName = styled.div`
  font-size: 1.2em;
  color: #1a1c1f;
  line-height: 1.2em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

export const CollectionListItemProjectName = styled.div`
  font-size: 1em;
  font-weight: 400;
  color: #757575;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CollectionListItemStats = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  font-size: 0.9em;
  font-weight: 300;
  border-top: 1px solid #e3e3e3;
  margin-top: 5px;
`;

export const CollectionListItemVersions = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

export const CollectionListItemUpdatedAt = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
`;

export const CollectionDetailViewContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 4 1 400px;
  padding-right: ${cardPadding}px;
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
`;

export const CollectionDetailView = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: ${paperColor};
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  /* padding: 15px; */
`;

export const ModalHeaderWrapper = styled.div`
  font-weight: 600;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5em 2em;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 4px;
  justify-content: flex-end;
`;

export const InUseMessageWrapper = styled.span`
  padding-top: 8px;
`;
