import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import * as Filter from '../../util/filters';
import * as Section from '../../util/section';
import * as SelectionManager from '../../util/selectionmanager';
import {useQuery} from './query';

///// Query definition

export const RUN_SET_SUMMARY_QUERY = gql`
  query RunSetSummary(
    $projectName: String!
    $entityName: String!
    $selections: JSONString!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      selectedCount: runCount(filters: $selections)
    }
  }
`;

export interface RunSetSummaryQueryData {
  project: {
    id: string;
    selectedCount: number;
  };
}
interface InputProps {
  pageEntityName: string;
  pageProjectName: string;
  runSet: Section.RunSetConfig;
}

export function useRunSetSummaryQuery(props: InputProps) {
  const {pageEntityName, pageProjectName, runSet} = props;
  const variables: Generated.RunSetSummaryQueryVariables = {
    entityName: runSet.project ? runSet.project.entityName : pageEntityName,
    projectName: runSet.project ? runSet.project.name : pageProjectName,
    selections: JSON.stringify(
      Filter.toMongo(
        Filter.And([runSet.filters, SelectionManager.toRunFilter(runSet)])
      )
    ),
  };
  return useQuery<RunSetSummaryQueryData, typeof variables>(
    RUN_SET_SUMMARY_QUERY,
    {variables}
  );
}
