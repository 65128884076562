import * as globals from '@wandb/common/css/globals.styles';
import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

export const UpgradeMessage = styled.div<{shiftVertically?: boolean}>`
  position: relative;
  ${props => props.shiftVertically && `top: -5px;`}
`;

export const UpgradeButton = styled(Button)`
  &&&&& {
    padding: 8px 16px;
    color: ${globals.white};
    border-color: ${globals.white};
    background-color: transparent;
    margin-left: 16px;
  }
`;

export const ContactSales = styled.span`
  margin-left: 16px;
  cursor: pointer;
  font-size: 1.4rem;
`;
