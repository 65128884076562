import React, {createContext, ReactNode, useContext} from 'react';

import {LocalSearchResult} from './types';

type SearchContextProps = {
  currentQuery: string;
  displayedResults: {
    contributed: LocalSearchResult[];
    entity: LocalSearchResult[];
    remaining: LocalSearchResult[];
  };
  entityName: string | undefined;
  /* The number of items that might be hidden based on the RESULTS_LIMIT */
  hiddenResultsCount: {
    contributed: number;
    entity: number;
    remaining: number;
  };
  results: {
    contributed: LocalSearchResult[];
    entity: LocalSearchResult[];
    remaining: LocalSearchResult[];
  };
  selectedIndex: number;
};
export const SearchContext = createContext<SearchContextProps | undefined>(
  undefined
);

type SearchContextProviderProps = SearchContextProps & {children: ReactNode};
export const SearchContextProvider = ({
  children,
  ...values
}: SearchContextProviderProps) => {
  return (
    <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }

  return context;
};
