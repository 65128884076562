import {toast} from '@wandb/common/components/elements/Toast';
import {WBPopupTrigger} from '@wandb/ui';
import React, {useContext, useState} from 'react';
import {Ref} from 'semantic-ui-react';

import {PanelBankUpdaterContext} from '../../state/panelbank/context';
import {useViewer} from '../../state/viewer/hooks';
import * as CustomRunColorsTypes from '../../state/views/customRunColors/types';
import * as ViewHooks from '../../state/views/hooks';
import * as PanelViewTypes from '../../state/views/panel/types';
import * as PanelBankSectionConfigActions from '../../state/views/panelBankSectionConfig/actions';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsViewTypes from '../../state/views/panelSettings/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
import {SectionPanelSorting} from '../../util/panelbank';
import AddVisMenu from '../AddVisMenu';
import {AddVisNewPanelEditor} from '../AddVisNewPanelEditor';

interface AddVisButtonProps {
  entityName: string;
  singleRun?: boolean;
  runSetRefs: RunSetViewTypes.Ref[];

  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  panelSettingsRef?: PanelSettingsViewTypes.Ref;
  customRunColorsRef: CustomRunColorsTypes.Ref;
}

const PanelBankAddVisButton: React.FC<AddVisButtonProps> = ({
  entityName,
  singleRun,
  runSetRefs,
  panelBankSectionConfigRef,
  panelSettingsRef,
  customRunColorsRef,
}) => {
  const viewer = useViewer();

  const [newPanel, setNewPanel] = useState<PanelViewTypes.Panel>();
  const [noEdit, setNoEdit] = useState<boolean | undefined>();

  const addPanel = ViewHooks.useViewAction(
    panelBankSectionConfigRef,
    PanelBankSectionConfigActions.addPanel
  );

  const {sorted, isOpen} = ViewHooks.usePart(panelBankSectionConfigRef);

  const panelSettings = ViewHooks.usePartMaybe(panelSettingsRef) ?? undefined;

  const {setCurrentPageForSectionRefID} = useContext(PanelBankUpdaterContext);

  return (
    <>
      {newPanel && (
        <AddVisNewPanelEditor
          panelBankSectionConfigRef={panelBankSectionConfigRef}
          currentHeight={400}
          editable={true}
          customRunColorsRef={customRunColorsRef}
          runSetRefs={runSetRefs}
          panelSettings={panelSettings}
          panel={newPanel}
          noEdit={noEdit}
          onCancel={() => {
            setNewPanel(undefined);
          }}
          onOK={panelRef => {
            setNewPanel(undefined);
            addPanel(panelRef);
            if (sorted === SectionPanelSorting.Alphabetical) {
              toast('Panel added alphabetically to section');
            } else {
              // default sorting adds new panels to the first page
              setCurrentPageForSectionRefID(panelBankSectionConfigRef.id, 0);
            }
          }}
        />
      )}
      <WBPopupTrigger
        triangleColor="white"
        direction="bottom left"
        onParentScroll="disable"
        popupContent={({close}) => (
          <AddVisMenu
            entityName={entityName}
            singleRun={singleRun}
            runSetRefs={runSetRefs}
            onSelect={(panel, skipEdit) => {
              close();
              setNewPanel(panel);
              setNoEdit(skipEdit);
            }}
          />
        )}>
        {({anchorRef, setOpen}) => (
          <Ref innerRef={anchorRef}>
            <div
              data-test="add-vis"
              className={`add-vis-button${isOpen ? '' : '__closed'}`}
              onClick={(e: any) => {
                e.stopPropagation();
                setOpen(open => !open);
                window.analytics?.track('User clicked "Add Panel" button', {
                  username: viewer?.username,
                  entityName,
                });
              }}>
              <div>Add panel</div>
            </div>
          </Ref>
        )}
      </WBPopupTrigger>
    </>
  );
};

export default PanelBankAddVisButton;
