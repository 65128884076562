import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import * as React from 'react';

export interface WarningMessageProps {
  className?: string;
  content: string | JSX.Element;
  onDismiss?(): void;
}

const WarningMessage = (props: WarningMessageProps) => {
  const {className, content, onDismiss} = props;
  return (
    <div
      className={
        'ui warning message' + (className != null ? ' ' + className : '')
      }>
      {/* icon */}
      {onDismiss != null && (
        <LegacyWBIcon
          name="close"
          className="close-button"
          onClick={onDismiss}
        />
      )}
      <div className="content">{content}</div>
    </div>
  );
};

export default WarningMessage;
