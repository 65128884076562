import {LegacyWBIcon} from '@wandb/common/components/elements/LegacyWBIcon';
import {useEffectExceptFirstRender} from '@wandb/common/util/hooks';
import {WBIcon} from '@wandb/ui';
import {memo, ReactNode} from 'react';
import React, {useEffect, useState} from 'react';
import {Button, Form, Popup} from 'semantic-ui-react';

import * as ProjectPickerQuery from '../graphql/projectPickerQuery';
import * as S from './ProjectPicker.styles';

interface ProjectPickerProps {
  entityName: string;
  projectName: string | undefined;
  triggerOverride?: ReactNode;
  requireSameEntity?: boolean;
  requireSameEntityReason?: string;
  onOpen?: () => void;
  onClose?: () => void;
  setProject(entityName: string, projectName: string): void;
}

export const ProjectPickerComp: React.FC<
  ProjectPickerProps &
    ProjectPickerQuery.QueryResultProps &
    ProjectPickerQuery.ProjectPickerLoaderResults
> = ({
  entityName,
  projectName,
  triggerOverride,
  requireSameEntity,
  requireSameEntityReason,
  onOpen,
  onClose,
  setProject,
  projectPickerQuery,
  isAdmin,
}) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffectExceptFirstRender(() => {
    if (open) {
      onOpen?.();
    } else {
      onClose?.();
    }
    // eslint-disable-next-line
  }, [open]);

  const projects = projectPickerQuery.projects ?? [];

  let projectsWithCurrentProject = projects;
  let currentProjectIndex = projectsWithCurrentProject.findIndex(
    p => p.entityName === entityName && p.name === projectName
  );
  if (currentProjectIndex === -1 && projectName != null) {
    projectsWithCurrentProject = [
      {
        entityName,
        name: projectName,
        id: '',
        createdAt: '',
      },
      ...projectsWithCurrentProject,
    ];
    currentProjectIndex = 0;
  }

  let projectsWithAdminSuggestion = projectsWithCurrentProject;
  const searchQuerySplit = searchQuery.split(' / ');
  if (isAdmin && searchQuerySplit.length === 2) {
    const [searchEntityName, searchProjectName] = searchQuerySplit;
    const adminSuggestionIncluded = projectsWithCurrentProject.some(
      p => p.entityName === searchEntityName && p.name === searchProjectName
    );
    if (!adminSuggestionIncluded) {
      projectsWithAdminSuggestion = [
        {
          entityName: searchEntityName,
          name: searchProjectName,
          id: '',
          createdAt: '',
        },
        ...projectsWithCurrentProject,
      ];
    }
  }

  const options = projectsWithAdminSuggestion.map((p, value) => {
    const disabled = requireSameEntity && p.entityName !== entityName;
    const showPopup = disabled && requireSameEntityReason;
    const text = `${p.entityName}/${p.name}`;
    return {
      text,
      value,
      disabled,
      content: showPopup ? (
        <Popup
          basic
          on="hover"
          size="mini"
          className="wb-table-action-popup"
          position="bottom right"
          content={
            <span className="hint-text small">{requireSameEntityReason}</span>
          }
          trigger={
            <S.DisabledDropdownOption>
              <span>{text}</span>
              <WBIcon name="lock" />
            </S.DisabledDropdownOption>
          }
        />
      ) : (
        <span>{text}</span>
      ),
    };
  });
  // sort disabled options to the bottom of the list
  options.sort(a => (a.disabled ? 1 : -1));

  useEffect(() => {
    if (
      !projectPickerQuery.loading &&
      currentProjectIndex === -1 &&
      projectsWithAdminSuggestion.length > 0
    ) {
      setProject(
        projectsWithAdminSuggestion[0].entityName,
        projectsWithAdminSuggestion[0].name
      );
    }
  }, [
    projectPickerQuery.loading,
    currentProjectIndex,
    projectsWithAdminSuggestion,
    setProject,
  ]);

  return (
    <Popup
      basic
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className="wb-table-action-popup"
      on="click"
      position="bottom left"
      popperModifiers={{
        preventOverflow: {
          // prevent popper from erroneously constraining the popup to the
          // table header
          boundariesElement: 'viewport',
        },
      }}
      trigger={
        triggerOverride ? (
          triggerOverride
        ) : (
          <Button size="tiny" className={'wb-icon-button'}>
            <LegacyWBIcon name="folder" />
            {projectName != null ? entityName + ' / ' + projectName : ''}
          </Button>
        )
      }
      content={
        <Form>
          <Form.Dropdown
            selection
            search
            searchQuery={searchQuery}
            onSearchChange={(e, {searchQuery: newSearchQuery}) =>
              setSearchQuery(newSearchQuery)
            }
            value={currentProjectIndex}
            options={options}
            onChange={(e, {value}) => {
              if (typeof value === 'number') {
                const proj = projectsWithAdminSuggestion[value];
                setProject(proj.entityName, proj.name);
                setOpen(false);
              }
            }}
          />
        </Form>
      }
    />
  );
};

export const ProjectPicker = ProjectPickerQuery.withQuery(
  memo(ProjectPickerComp)
);
