import {RequireSome, Subtract} from '@wandb/common/types/base';
import {captureError} from '@wandb/common/util/integrations';
import {TargetBlank} from '@wandb/common/util/links';
import {ApolloQueryResult} from 'apollo-client';
import gql from 'graphql-tag';
import _, {flowRight as compose} from 'lodash';
import React, {FC, memo, useCallback, useState} from 'react';
import {graphql, Query} from 'react-apollo';
import {RouteComponentProps, withRouter} from 'react-router';
import {Link, useHistory} from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Modal,
  Radio,
  Segment,
  Table,
} from 'semantic-ui-react';

import {
  BenchmarkRun,
  File,
  GitHubOAuthIntegration,
  Project,
} from '../generated/graphql';
import {
  PUBLISH_BENCHMARK_RUN_MUTATION,
  PublishBenchmarkRunMutationFn,
  SUBMIT_BENCHMARK_RUN_MUTATION,
  SubmitBenchmarkRunMutationFn,
} from '../graphql/benchmarks';
import {RUN_UPSERT} from '../graphql/runs';
import {GetViewerQueryResponse, withViewer} from '../graphql/users_get_viewer';
import {getTheme, Theme} from '../pages/Benchmark/Theme';
import {Graph} from '../types/graphql';
import {BenchmarkProject, User} from '../types/graphql';
import {propagateErrorsContext} from '../util/errors';
import * as RunHelpers from '../util/runhelpers';
import * as Run from '../util/runs';
import * as urls from '../util/urls';
import GitHubIntegration, {getGitHubIntegration} from './GitHubIntegration';
import {InstrumentedLoader as Loader} from './utility/InstrumentedLoader';

const SUBMISSION_FILENAME = 'model_predictions.csv';

interface SubmitModalBaseProps {
  runName: string;
  run: Run.Run;
  project: {
    entityName: string;
    name: string;
  };
  runSummary: Run.KeyVal;
  runId: string;
  trigger?: React.ReactElement;
  benchmarkMeta: BenchmarkProject;
  submitToBenchmark: SubmitBenchmarkRunMutationFn;
  publishBenchmarkRun: PublishBenchmarkRunMutationFn;
  shouldShowModal?: boolean;
  closeModal?: () => void;
  updateRun(vars: {id: string; notes: string}): Promise<ApolloQueryResult<{}>>;
}

type SubmitToBenchmarkModalProps = SubmitModalBaseProps;

type AllSubmitToBenchmarkModalProps = SubmitToBenchmarkModalProps &
  RouteComponentProps<any> &
  GetViewerQueryResponse &
  SubmissionResult;

const SubmitToBenchmarkModalComp: FC<AllSubmitToBenchmarkModalProps> = ({
  run,
  closeModal,
  publishBenchmarkRun,
  runId,
  benchmarkMeta,
  updateRun,
  submitToBenchmark,
  viewerLoading,
  viewer: propsViewer,
  submissionCSV,
  project,
  runSummary,
  refetch,
  loading,
  shouldShowModal,
  trigger,
}) => {
  const history = useHistory();
  const controlledComponent = shouldShowModal != null;
  const [hideCode, setHideCode] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [runNotes, setRunNotes] = useState(run.notes);
  const [error, setError] = useState<string | undefined>();
  // This stores the request response. It could be done with apollo.
  // NOTE: Maybe we should use apollo here, but its local cache is frustrating
  const [localScore, setLocalScore] = useState<string | null | undefined>();

  const theme = getTheme(benchmarkMeta);

  const handleOpen = useCallback(() => setModalOpen(true), []);

  const handleClose = useCallback(() => {
    setModalOpen(false);
    closeModal?.();
  }, [closeModal]);

  const publish = useCallback(async () => {
    setSubmitting(true);
    const req = publishBenchmarkRun({
      id: runId,
      benchmarkName: benchmarkMeta.name,
      benchmarkEntityName: benchmarkMeta.entityName,
    });

    let res;
    try {
      res = await req;
    } catch {
      setError('Error creating github Pull Request');
    }
    setSubmitting(false);

    if (res) {
      const prUrl =
        res.data.publishBenchmarkRun.benchmarkRun.gitHubSubmissionPR;

      // If a github PR url
      if (prUrl) {
        window.location.href = prUrl; // eslint-disable-line wandb/no-unprefixed-urls
      }
    }

    return req;
  }, [
    benchmarkMeta.entityName,
    benchmarkMeta.name,
    publishBenchmarkRun,
    runId,
  ]);

  const submit = useCallback(async () => {
    setSubmitting(true);

    const updateNotesRequest = updateRun({
      id: runId,
      notes: runNotes,
    });

    try {
      await updateNotesRequest;
    } catch (err) {
      // Do nothing
    }

    const req = submitToBenchmark({
      id: runId,
      isCodeHidden: theme.embargo != null && hideCode,
      benchmarkName: benchmarkMeta.name,
      benchmarkEntityName: benchmarkMeta.entityName,
    });

    // TODO: This code is hard to read, consider refactoring.
    // Or consider rewriting this whole component with working types.
    try {
      const res = await req;
      setLocalScore(res.data.submitBenchmarkRun.benchmarkRun.results);
    } catch (err) {
      // Do nothing
    }
    setSubmitting(false);

    if (benchmarkMeta.gitHubSubmissionRepo) {
      // Do nothing
    } else {
      history.push(
        urls.benchmarkMySubmissionsTab({
          benchmarkEntityName: benchmarkMeta.entityName,
          benchmarkProjectName: benchmarkMeta.name,
        })
      );
    }
    return req;
  }, [
    benchmarkMeta.entityName,
    benchmarkMeta.gitHubSubmissionRepo,
    benchmarkMeta.name,
    hideCode,
    history,
    runId,
    runNotes,
    submitToBenchmark,
    theme.embargo,
    updateRun,
  ]);

  const viewer = !viewerLoading ? propsViewer : undefined;
  const githubIntegration = viewer && getGitHubIntegration(viewer.userEntity);
  const submitViaPR = !!benchmarkMeta.gitHubSubmissionRepo;

  let submissionEnabled = true;

  const score =
    localScore ?? (run && run.benchmarkRun && run.benchmarkRun.results);

  // If submit via PR is enabled make sure all conditions are met to enable the submission button
  if (submitViaPR) {
    // Needs a github integration
    if (!githubIntegration) {
      submissionEnabled = false;
    }

    // A score is required for submission in PR mode
    if (!score) {
      submissionEnabled = false;
    }

    // Must include a submissonCSV
    if (!submissionCSV) {
      submissionEnabled = false;
    }
  }

  const computationInstructions = () => {
    const props = {
      entityName: project.entityName,
      projectName: project.name,
      run,
      benchmarkMeta,
      githubIntegration,
      theme,
      viewer: propsViewer,
      loading,
      refetch,
      submissionCSV,
      // Check the local cache first
      // NOTE: Maybe we should use apollo here, but its local cache is frustating
      score,
      submit: () => submit(),
      publish: () => publish(),
    };
    return <SubmissionComputation {...props} />;
  };

  const instructions = submitViaPR ? (
    computationInstructions()
  ) : (
    <>
      You're submitting to the <strong>{benchmarkMeta.name}</strong> benchmark
      by <strong>{benchmarkMeta.entityName}</strong>. A public copy of this run
      will be created and sent to the benchmark for review. Changes to your
      original run will not affect the copy.
    </>
  );

  return (
    <Modal
      open={controlledComponent ? shouldShowModal : modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      className="medium"
      trigger={trigger}>
      <Modal.Header>Submit Benchmark</Modal.Header>
      <Modal.Content>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>{instructions}</Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {!submitViaPR && (
              <Grid.Column>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Run Name</Table.HeaderCell>
                      {theme.keys.map(k => (
                        <Table.HeaderCell key={k.toString()}>
                          {Array.isArray(k) ? k.join('.') : k}
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{run.displayName}</Table.Cell>
                      {theme.keys.map((k, i) => (
                        <Table.Cell key={i}>
                          {typeof k === 'string' && k in (runSummary || {})
                            ? RunHelpers.displayValue(runSummary[k])
                            : '-'}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Submission notes</Header>
              <Form>
                <Form.TextArea
                  placeholder="Please describe how you approached the problem."
                  rows="2"
                  minRows={2}
                  value={runNotes}
                  onChange={e => setRunNotes(e.currentTarget.value)}
                  control={TextareaAutosize}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          {theme.codeRequired && (
            <Grid.Row>
              <Grid.Column>
                <Header as="h4">Code requirement</Header>
                <p style={{marginTop: 8}} className="hint-text">
                  The code that generated your run must be available for review.
                </p>
                {run.github ? (
                  <p>
                    <Icon name="check" color="green" />
                    Your run is linked to a GitHub commit. Please make sure{' '}
                    <TargetBlank href={run.github}>
                      this GitHub link
                    </TargetBlank>{' '}
                    is publicly accessible.
                  </p>
                ) : (
                  <p>
                    <Icon name="x" color="red" />
                    This run is not linked to a GitHub commit. Reviewers will
                    ask you to provide your code. Please run your script from
                    within a github repository and push your code to that repo.
                  </p>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
          {theme.embargo && (
            <Grid.Row>
              <Grid.Column>
                <Radio
                  toggle
                  label="Hide code"
                  checked={hideCode}
                  onChange={() => setHideCode(!hideCode)}
                />
                <p style={{marginTop: 8}} className="hint-text">
                  {theme.embargo}
                </p>
              </Grid.Column>
            </Grid.Row>
          )}
          {error && (
            <Message error>
              <Message.Header>{error}</Message.Header>
            </Message>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        {!submitViaPR ? (
          <Button disabled={!submissionEnabled} primary onClick={submit}>
            {submitting && <Loader name="submit-via-pr-loader" size="huge" />}
            Confirm Submission
          </Button>
        ) : (
          <Button disabled={!submissionEnabled} primary onClick={publish}>
            {submitting && <Loader name="" size="huge" />}
            Publish to GitHub
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

const withMutations = compose(
  graphql(SUBMIT_BENCHMARK_RUN_MUTATION, {
    options: {context: propagateErrorsContext()},
    props: ({mutate}) => ({
      submitToBenchmark: (variables: any) => {
        if (mutate) {
          return mutate({variables});
        }
        return;
      },
    }),
  }),
  graphql(PUBLISH_BENCHMARK_RUN_MUTATION, {
    props: ({mutate}) => ({
      publishBenchmarkRun: (variables: any) => {
        if (mutate) {
          return mutate({variables});
        }
        return;
      },
    }),
  }),
  graphql(RUN_UPSERT, {
    props: ({mutate}) => ({
      updateRun: (variables: any) =>
        mutate &&
        mutate({
          variables,
        }),
    }),
  })
) as any;

interface WithSubmissionProps {
  run: RequireSome<Run.Run, 'name'>;
  project: RequireSome<Project, 'name' | 'entityName'>;
}

interface SubmissionResult {
  loading: true | false;
  submissionCSV?: File;
}

export const withSubmissionFile =
  <P extends object>(Component: React.ComponentType<P & SubmissionResult>) =>
  (inputProps: Subtract<P, SubmissionResult> & WithSubmissionProps) => {
    if (!inputProps.project || !inputProps.run) {
      return <Loader name="with-submission-loader" />;
    }
    return (
      <Query
        query={FILE_SUBMISSION_QUERY}
        variables={{
          entityName: inputProps.project.entityName,
          projectName: inputProps.project.name,
          runName: inputProps.run.name,
          fileName: SUBMISSION_FILENAME,
        }}>
        {(rawQueryResult: FileQueryResult) => {
          const result: SubmissionResult = {loading: true};
          if (rawQueryResult.loading) {
            result.loading = true;
          } else {
            result.loading = false;

            const files =
              rawQueryResult.data &&
              rawQueryResult.data.project &&
              rawQueryResult.data.project.run &&
              rawQueryResult.data.project.run.submissionFiles;

            const submissionCSV =
              files &&
              files.edges.length !== 0 &&
              _.find(
                files.edges,
                node =>
                  // Gorilla returns stubs for missing file when requested by name
                  // We need to check the hash to make sure its not a stub
                  // TODO: Fix this in gorilla
                  node.node.md5 !== '0' &&
                  node.node.name === SUBMISSION_FILENAME
              );

            result.submissionCSV = submissionCSV
              ? submissionCSV.node
              : undefined;
          }

          return <Component {...(inputProps as P)} {...result} />;
        }}
      </Query>
    );
  };

const FILE_SUBMISSION_QUERY = gql`
  query RunCSV(
    $projectName: String!
    $entityName: String
    $runName: String!
    $fileName: String!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      run(name: $runName) {
        id
        name
        benchmarkRun {
          id
          results
        }
        submissionFiles: files(first: 1, names: [$fileName]) {
          edges {
            node {
              id
              name
              md5
            }
          }
        }
      }
    }
  }
`;

interface SubmissionComputationProps {
  entityName: string;
  projectName: string;
  run: Run.Run;
  benchmarkMeta: BenchmarkProject;
  viewer: User;
  loading: boolean;
  theme: Theme;
  refetch: CallableFunction;
  githubIntegration?: GitHubOAuthIntegration;
  submissionCSV?: File;
  score?: string | null;
  submit: () => SubmissionRequest;
  publish: () => PublishRequest;
}

const SubmissionComputation = (props: SubmissionComputationProps) => {
  const [processing, setProcessing] = React.useState(false);
  // TODO: Move this up a level so the whole modal share error handling code
  const [error, setError] = React.useState<string | undefined>(undefined);

  return (
    // Instructions for submitting a PR
    <>
      <p>
        You're submitting to the <strong>{props.benchmarkMeta.name}</strong>{' '}
        benchmark by <strong>{props.benchmarkMeta.entityName}</strong>. A public
        copy of this run will be created and submitted to the benchmark. Changes
        to your original run will not affect the copy.
      </p>
      {(() => {
        if (!props.viewer) {
          return <Loader name="submission-computation-viewer-loader" />;
        }
        if (props.loading) {
          return <Loader name="submission-computatation-props-loader" />;
        }

        if (!props.githubIntegration) {
          return (
            <Segment placeholder>
              <Header>GitHub Powered Benchmark</Header>
              <p>
                This benchmark stores its' record of submissions in a github
                repo. To submit an entry to a GitHub-powered benchmark, you must
                connect your github account below.
              </p>
              <GitHubIntegration
                entity={props.viewer.userEntity}
                entityRefetch={props.refetch}
              />
            </Segment>
          );
        }

        const score = props.score;

        const hasScore = !!score;

        if (!props.submissionCSV) {
          return (
            <Message warning>
              <Message.Header>{`Missing ${SUBMISSION_FILENAME} File`}</Message.Header>
              <p>
                {`Attach a ${SUBMISSION_FILENAME} file to your run by following `}
                <Link
                  to={urls.runBenchmarkTab(
                    props.entityName,
                    props.projectName,
                    props.run.name
                  )}>
                  these instructions
                </Link>
                .
              </p>
            </Message>
          );
        }
        return (
          <>
            <Message>
              <Message.Header>GitHub Powered Benchmark</Message.Header>
              <Message.Content>
                After the score has been computed, we create a fork on github of
                the main repo(or use your fork if you've already created one). A
                GitHub pull request will be created on the{' '}
                <TargetBlank href={props.benchmarkMeta.gitHubSubmissionRepo}>
                  benchmark repo
                </TargetBlank>{' '}
                to track your submission. Discussion of your pending submission
                will occur in the pull request on GitHub. Approval of the pull
                request will signify approval of the run submission and the
                result will appear on the W&B scoreboard.
              </Message.Content>
            </Message>
            <Message positive>
              <Message.Header>{`Detected ${SUBMISSION_FILENAME}`}</Message.Header>
              <Message.Content>
                {`A ${SUBMISSION_FILENAME} file has been detected attached to this run.
              Your file will be compared with a ground truth file to compute a
              score.`}
              </Message.Content>
              <Button
                style={{marginTop: 16}}
                loading={processing}
                disabled={processing || hasScore}
                onClick={() => {
                  setProcessing(true);
                  props
                    .submit()
                    .then(() => {
                      setProcessing(false);
                    })
                    .catch(err => {
                      // NOTE: This error is not always truthful. It could fail for some other strange
                      // reason, but it should mostly be CSV evaluation problems, since the request will retry.
                      // on network failures
                      setError(
                        'Invalid CSV format. Please upload a well-formatted CSV file.'
                      );
                      captureError(err, 'submittobenchmark', {
                        extra: {benchmark: props.benchmarkMeta.name},
                      });
                      setProcessing(false);
                    });
                }}>
                {hasScore ? 'Score Calculated' : 'Calculate Score'}
              </Button>
              {hasScore && (
                <span>{`${props.theme.calculatedResultsHeader}: ${score}`}</span>
              )}
            </Message>
            {error && (
              <Message error>
                <Message.Header>{error.toString()}</Message.Header>
              </Message>
            )}
          </>
        );
      })()}
    </>
  );
};

interface FileQueryResult {
  loading?: boolean;
  data: {
    project: {
      run: {
        submissionFiles: Graph<File>;
      };
    };
  };
}

type SubmissionRequest = Promise<
  ApolloQueryResult<{
    submitBenchmarkRun: {
      benchmarkRun: BenchmarkRun;
    };
  }>
>;

type PublishRequest = Promise<
  ApolloQueryResult<{
    publishBenchmarkRun: {
      benchmarkRun: BenchmarkRun;
    };
  }>
>;

const SubmitToBenchmarkModal = memo(SubmitToBenchmarkModalComp);

// TODO: Type this with a different method
// This strips a lot of type data
export default withMutations(
  withViewer(withRouter(withSubmissionFile(SubmitToBenchmarkModal)))
);
