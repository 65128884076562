import React from 'react';
import {Button} from 'semantic-ui-react';

type ArtifactCompareButtonProps = {
  show?: boolean;
  onClick?: React.MouseEventHandler;
};

export const ArtifactCompareButton: React.FC<ArtifactCompareButtonProps> = ({
  show,
  onClick,
}) => {
  return show ? (
    <Button
      className="artifact-compare-button"
      size="tiny"
      style={{marginLeft: 8, padding: '2px 4px'}}
      onClick={onClick}>
      Compare
    </Button>
  ) : null;
};
