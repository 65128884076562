import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Image} from 'semantic-ui-react';
import {Graph} from 'src/types/graphql';

import * as Urls from '../../util/urls';
import {SearchNavTeam} from './types';

type HomePageStarterContentProps = {
  blurAndClear: () => void;
  teams: Graph<SearchNavTeam>;
};
export const HomePageStarterContent: FC<HomePageStarterContentProps> = ({
  blurAndClear,
  teams,
}) => {
  return (
    <div className="team-items" id="home-page-starter-content">
      {teams.edges.map(team => (
        <Link
          className="team-item"
          key={team.node.id}
          onClick={blurAndClear}
          to={Urls.teamPage(team.node.name)}>
          <Image className="team-image" src={team.node.photoUrl} />
          <div className="team-name">{team.node.name}</div>
        </Link>
      ))}
    </div>
  );
};
