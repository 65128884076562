import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import React from 'react';

const inputType = 'artifact' as const;

export const Spec: Panel2.PanelSpec = {
  id: 'artifact-collection',
  displayName: 'Artifact',
  Component: React.lazy(() => import('./component')),
  inputType,
};
