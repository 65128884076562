import gql from 'graphql-tag';

import {useArtifactDagQuery} from '../../generated/graphql';

export const ARTIFACT_DAG_QUERY = gql`
  query ArtifactDAG(
    $projectName: String!
    $entityName: String!
    $artifactName: String!
    $limit: Int!
    $filterGeneratedArtifacts: Boolean!
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      artifact(name: $artifactName) {
        id
        artifactLineageDag(
          limit: $limit
          filterGeneratedArtifacts: $filterGeneratedArtifacts
        ) {
          artifacts {
            artifactNodeID
            entityName
            projectName
            artifactTypeName
            artifactSequenceName
            artifactCommitHash
            versionIndex
          }
          runs {
            runNodeID
            entityName
            projectName
            runName
            displayName
            jobType
          }
          edges {
            edgeID
            artifactNodeID
            runNodeID
            direction
          }
          hitLimit
        }
      }
    }
  }
`;

interface RunGroup {
  runs?: string[];
}

export type Run = RunGroup & {
  entityName: string;
  projectName: string;
  runName: string;
  displayName?: string;
  jobType?: string;
};

interface ArtifactGroup {
  artifacts?: string[];
}

export type Artifact = ArtifactGroup & {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  versionIndex: number;
};

export enum Direction {
  TowardArtifact,
  AwayFromArtifact,
}

const TOWARD_ARTIFACT = 'TowardArtifact';

interface EdgeCollapsed {
  jobType?: string;
  artifactTypeName?: string;
}

export type Edge = EdgeCollapsed & {
  artifactID: string;
  runID: string;
  dir: Direction;
};

export function useArtifactDagQueryCaller(
  entityName: string,
  projectName: string,
  artifactSequenceName: string,
  artifactCommitHash: string,
  limit: number,
  filterGeneratedArtifacts: boolean
) {
  const {data, loading} = useArtifactDagQuery({
    variables: {
      entityName,
      projectName,
      artifactName: `${artifactSequenceName}:${artifactCommitHash}`,
      limit,
      filterGeneratedArtifacts,
    },
  });

  const hitLimit = data?.project?.artifact?.artifactLineageDag?.hitLimit!;

  // Reformat artifacts response
  const artResp = data?.project?.artifact?.artifactLineageDag?.artifacts;
  const dagArtifacts: {[id: string]: Artifact} = {};
  if (artResp != null) {
    for (const a of artResp) {
      if (a != null) {
        const art = {
          entityName: a.entityName,
          projectName: a.projectName,
          artifactTypeName: a.artifactTypeName,
          artifactSequenceName: a.artifactSequenceName,
          artifactCommitHash: a.artifactCommitHash,
          versionIndex: a.versionIndex,
        };
        const artID = a.artifactNodeID;
        dagArtifacts[artID] = art;
      }
    }
  }

  // Reformat edges response
  const edgeResp = data?.project?.artifact?.artifactLineageDag?.edges;
  const dagEdges: {[id: string]: Edge} = {};
  if (edgeResp != null) {
    for (const e of edgeResp) {
      if (e != null) {
        const dirEnum =
          e.direction === TOWARD_ARTIFACT
            ? Direction.TowardArtifact
            : Direction.AwayFromArtifact;
        const edge = {
          artifactID: e.artifactNodeID,
          runID: e.runNodeID,
          dir: dirEnum,
        };
        const eID = e.edgeID;
        dagEdges[eID] = edge;
      }
    }
  }

  // Reformat runs response
  const runResp = data?.project?.artifact?.artifactLineageDag?.runs;
  const dagRuns: {[id: string]: Run} = {};
  if (runResp != null) {
    for (const r of runResp) {
      if (r != null) {
        const run = {
          entityName: r.entityName,
          projectName: r.projectName,
          runName: r.runName,
          displayName: r.displayName!,
          jobType: r.jobType!,
        };
        const rID = r.runNodeID;
        dagRuns[rID] = run;
      }
    }
  }

  return {
    dag: {
      artifacts: dagArtifacts,
      edges: dagEdges,
      runs: dagRuns,
    },
    limited: hitLimit,
    loading,
  };
}

export type Dag = ReturnType<typeof useArtifactDagQueryCaller>['dag'];
