import {ImageExportType} from '@wandb/common/util/panelExport';
import React, {FC, useContext} from 'react';

import * as PanelSettingsViewTypes from '../../state/views/panelSettings/types';
import {PanelExportRef} from '../../util/panelHelpers';
import {getPanelSpec, PanelCompRedux} from '../../util/panels';
import {PanelContext} from '../Panel';
import ImageExportModal from './ImageExportModal';
import * as S from './PanelImageExportModal.styles';

export type {PanelExportRef};

type PanelImageExportModalProps = {
  open?: boolean;
  type: ImageExportType;
  onClose: () => void;
  panelSpec: ReturnType<typeof getPanelSpec>;
  panelSettings?: PanelSettingsViewTypes.PanelSettings;
};

export const PanelImageExportModal: FC<PanelImageExportModalProps> = React.memo(
  ({open, type, onClose, panelSettings, panelSpec}) => {
    const panelDOMRef = React.useRef<HTMLDivElement>(null);
    const panelExportRef = React.useRef<PanelExportRef>();

    const {customRunColorsRef, isReadOnly, panelRef, runSetRefs} =
      useContext(PanelContext);

    return (
      <ImageExportModal
        open={open}
        type={type}
        panelDOMRef={panelDOMRef}
        panelExportRef={panelExportRef}
        viewType={panelSpec.type}
        onClose={onClose}>
        <S.PanelContainer
          className={
            panelSpec.type.toLowerCase().replace(' ', '-') +
            '-container' +
            ' fullscreen-mode'
          }
          ref={panelDOMRef}>
          <PanelCompRedux
            exportMode
            configMode={false}
            currentHeight={320}
            runSetRefs={runSetRefs}
            panelRef={panelRef}
            panelSettings={panelSettings}
            customRunColorsRef={customRunColorsRef}
            dimensions={undefined as any}
            readOnly={isReadOnly}
            panelExportRef={panelExportRef}
          />
        </S.PanelContainer>
      </ImageExportModal>
    );
  }
);
