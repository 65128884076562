import * as GraphTypes from '@wandb/cg';
import * as Op from '@wandb/cg';
import {useNodeValue} from '@wandb/weave-ui/cgreact';
import * as Panel2 from '@wandb/weave-ui/components/Panel2/panel';
import * as React from 'react';

import CollapsibleJSONTable from '../../CollapsibleJSONTable';

const inputType = 'artifactVersion' as const;
type ConfigType = {};

export const PanelArtifactVersionMetadata: React.FC<
  Panel2.PanelProps<typeof inputType, ConfigType>
> = ({input: artifactVersionNode}) => {
  const {loading, result} = useNodeValue(
    React.useMemo(() => {
      return Op.opDict({
        'Run Config': Op.opRunConfig({
          run: Op.opArtifactVersionCreatedBy({
            artifactVersion: artifactVersionNode,
          }),
        }),
        'Run History at Log Step': Op.opArtifactVersionRunHistoryRow({
          artifactVersion: artifactVersionNode,
        }),
        'Version Metadata': Op.opArtifactVersionMetadata({
          artifactVersion: artifactVersionNode,
        }),
      } as any) as unknown as GraphTypes.OutputNode<'any'>;
    }, [artifactVersionNode])
  );
  const data = React.useMemo(() => {
    const res = loading ? {} : result;
    for (const key of Object.keys(res)) {
      if (
        res[key] == null ||
        (typeof res[key] === 'object' && Object.keys(res[key]).length === 0)
      ) {
        delete res[key];
      }
    }
    return res;
  }, [loading, result]);

  return <CollapsibleJSONTable data={data} />;
};
