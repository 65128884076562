import {Elements} from '@stripe/react-stripe-js';
import {Stripe} from '@stripe/stripe-js';
import config from '@wandb/common/config';
import {DeepPartial} from '@wandb/common/types/base';
import {DateFromUTCString, diffInDays} from '@wandb/common/util/time';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
  Organization,
  OrganizationSubscription,
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
  OrgType,
  Plan,
  PlanType,
  useSubscriptionPlansQuery,
} from '../generated/graphql';
import {InstrumentedLoader as Loader} from './../components/utility/InstrumentedLoader';
import {propagateErrorsContext} from './errors';
import {isOneOf} from './utility';

export const TRIAL_TEAM_SEATS = 100;

export const PLANS_WITH_COMPUTE_HOUR_LIMIT = [
  'basic',
  'standard_yearly',
  'standard_monthly',
  'starter_tier_1_monthly',
  'starter_tier_1_yearly',
  'starter_tier_2_monthly',
  'starter_tier_2_yearly',
  'starter_tier_3_monthly',
  'starter_tier_3_yearly',
];

export const CONTACT_SALES_CLICKED_ANALYTICS = `Contact Sales Clicked`;

export type CheckoutPlan = {id: string; billingInterval: string};

export type OrganizationFlags = {
  noContact?: boolean;
  email_domain?: string;
};

export type Org = {
  id: string;
  name: string;
  usedSeats: number;
  teams: Team[];
  subscriptions?: Subscription[];
  flags?: OrganizationFlags;
};

export type Team = {id: string; name: string};

export type Subscription = {
  subscriptionType?: OrganizationSubscriptionType;
  status?: OrganizationSubscriptionStatus;
  expiresAt?: Date | null;
  planType?: PlanType;
};

export type PlanClientData = {
  title: string;
  subtitle: string;
  features: string[];
  planType: 'free' | 'stripe' | 'enterprise';
  dbName: string | null;
};

export type PlanInfo = PlanClientData & {
  monthlyPlan: Plan | null;
  yearlyPlan: Plan | null;
};
export type PlanInfoWithActualPlans = PlanClientData & {
  monthlyPlan: Plan;
  yearlyPlan: Plan;
  enterprisePlan: Plan;
  basicPlan: Plan;
};

const BASIC_PLAN_NAME = 'basic';
const ENTERPRISE_PLAN_NAME = 'enterprise';

export const STARTER_PLAN_TIER_1: PlanClientData = {
  planType: 'stripe',
  dbName: 'starter_tier_1',
  title: 'Upgrade to Starter Plan',
  subtitle: 'Annual Subscription',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const LEGACY_PLANS: PlanClientData[] = [
  {
    planType: 'free',
    dbName: null,
    title: 'Personal',
    subtitle: 'W&B basics for every practitioner',
    features: [
      'Unlimited public & private projects',
      'Public forum support',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'startup',
    title: 'Startup',
    subtitle: 'Private collaboration on a small scale for pre-funded startups',
    features: [
      'Up to 3 users',
      '1 team',
      'Unlimited public & private projects',
      'Email support',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'teams',
    title: 'Teams',
    subtitle: 'Essential management and security for small teams',
    features: [
      'Up to 5 users',
      '1 team',
      'Unlimited public & private projects',
      'Email support',
      'Hyperparameter optimization tools',
      'Service accounts',
    ],
  },
  {
    planType: 'enterprise',
    dbName: null,
    title: 'Enterprise',
    subtitle: 'Security, compliance, and flexible deployment',
    features: [
      'Unlimited private projects',
      'Single sign-on enabled',
      'Unlimited data storage',
      'Unlimited data retention',
      'Dedicated customer success',
      'Support & Service SLAs',
      'Professional services available',
      'On-Prem available',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'standard',
    title: 'Upgrade to Starter Plan',
    subtitle: 'Starter plan',
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
  },
];

export function orgSeatsRemaining(
  org: DeepPartial<
    Pick<Organization, 'subscriptions' | 'members' | 'pendingInvites'>
  >
): number | null {
  const {subscriptions, members, pendingInvites} = org;
  if (subscriptions == null || members == null || pendingInvites == null) {
    return null;
  }

  const primarySub = getPrimarySub(org);
  const seatCount = primarySub?.seats;
  if (seatCount == null) {
    return null;
  }

  // TODO: This role field should have an enum typpe.
  const memberCount = members.filter(m => m?.role !== 'billing-only').length;
  const inviteCount = pendingInvites.length;

  return seatCount - (memberCount + inviteCount);
}

export function orgPrimarySubUnitPrice(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): number | null {
  const primarySub = getPrimarySub(org);
  const unitPrice = primarySub?.plan?.unitPrice;
  if (unitPrice == null) {
    return null;
  }

  return unitPrice / 100;
}

export function orgPrimarySubBillingInterval(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): string | null {
  const primarySub = getPrimarySub(org);
  return primarySub?.plan?.billingInterval ?? null;
}

export function getPrimarySub(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): DeepPartial<OrganizationSubscription> | null {
  return (
    org?.subscriptions?.find(s => s?.plan?.planType === PlanType.Primary) ??
    null
  );
}

export function getStorageSub(
  organization: Organization
): OrganizationSubscription | undefined {
  return organization.subscriptions.find(
    sub => sub.plan.name === 'storage_monthly'
  );
}

export function getReferenceSub(
  organization: Organization
): OrganizationSubscription | undefined {
  return organization.subscriptions.find(
    sub => sub.plan.name === 'tracking_monthly'
  );
}

export function isDisabledSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return subscription.status === OrganizationSubscriptionStatus.Disabled;
}

export function isExpiredSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return (
    subscription.expiresAt != null &&
    DateFromUTCString(subscription.expiresAt as Date) <= new Date()
  );
}

type ExpiringSubscriptionResult = {
  isExpiring: boolean;
  daysUntilExpire?: number;
};

export function isExpiringSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): ExpiringSubscriptionResult {
  if (subscription.expiresAt == null) {
    return {isExpiring: false};
  }

  const expiresAtDate = DateFromUTCString(subscription.expiresAt as Date);
  const isExpiring = new Date() <= expiresAtDate;
  const daysUntilExpire = isExpiring
    ? diffInDays(expiresAtDate, new Date())
    : undefined;

  return {isExpiring, daysUntilExpire};
}

// all the subscription types except academic and academic trial
// should be enforced for the expired subscription nudge bar & enforcement
// paid self-service subscriptions are gated to not set expiration date in backend
// so technically manual trial, user led trial and enterprise gets enforced
export function shouldEnforceExpiration(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return (
    subscription.subscriptionType !== OrganizationSubscriptionType.Academic &&
    subscription.subscriptionType !== OrganizationSubscriptionType.AcademicTrial
  );
}

let globalStripePromise: Promise<Stripe | null> | null = null;

export const StripeElements: React.FC = React.memo(({children}) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(globalStripePromise);

  useEffect(() => {
    if (globalStripePromise != null) {
      return;
    }
    if (config.ENVIRONMENT_IS_PRIVATE || config.ENVIRONMENT_NAME === 'test') {
      return;
    }
    const stripeApiKey = config.STRIPE_API_KEY;
    if (stripeApiKey == null) {
      throw new Error('Stripe API key not set!');
    }

    import('@stripe/stripe-js').then(module => {
      const stripeLoadResult = module.loadStripe(stripeApiKey);
      setStripePromise(stripeLoadResult);
      globalStripePromise = stripeLoadResult;
    });
  }, []);

  if (stripePromise == null) {
    return <Loader name="stripe-elements" />;
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
});

type SubscriptionPlansResult = {
  loading: boolean;
  planInfo: PlanInfoWithActualPlans | null;
  storagePlanID: string | null;
  trackingPlanID: string | null;
};

export function useSubscriptionPlans(): SubscriptionPlansResult {
  const {loading, data} = useSubscriptionPlansQuery({
    context: propagateErrorsContext(),
  });

  const serverPlans = useMemo(() => data?.plans ?? [], [data]);

  const getServerPlan = useCallback(
    (serverPlanName: string) =>
      serverPlans.find(p => p?.name === serverPlanName) ?? null,
    [serverPlans]
  );

  const planInfo = useMemo(() => {
    const {dbName} = STARTER_PLAN_TIER_1;
    // monthlyPlan and yearlyPlan are tier 1 pricing
    // TODO(Haruka): add tier 2 & 3 plans here
    const monthlyPlan = getServerPlan(`${dbName}_monthly`);
    const yearlyPlan = getServerPlan(`${dbName}_yearly`);
    const basicPlan = getServerPlan(BASIC_PLAN_NAME);
    const enterprisePlan = getServerPlan(ENTERPRISE_PLAN_NAME);
    if (
      monthlyPlan == null ||
      yearlyPlan == null ||
      basicPlan == null ||
      enterprisePlan == null
    ) {
      return null;
    }
    return {
      ...STARTER_PLAN_TIER_1,
      monthlyPlan,
      yearlyPlan,
      basicPlan,
      enterprisePlan,
    };
  }, [getServerPlan]);

  const storagePlanID = useMemo(
    () => getServerPlan('storage_monthly')?.id ?? null,
    [getServerPlan]
  );

  const trackingPlanID = useMemo(
    () => getServerPlan('tracking_monthly')?.id ?? null,
    [getServerPlan]
  );

  return {loading, planInfo, storagePlanID, trackingPlanID};
}

export function isPersonalOrg({
  orgType,
}: DeepPartial<Pick<Organization, 'orgType'>>): boolean {
  return orgType === OrgType.Personal;
}

export function isAcademicOrg(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): boolean {
  return orgHasSubscriptionType(org, OrganizationSubscriptionType.Academic);
}

export function isAcademicTrialOrg(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): boolean {
  return orgHasSubscriptionType(
    org,
    OrganizationSubscriptionType.AcademicTrial
  );
}

export function isNonAcademicOrg(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): boolean {
  return !isAcademicOrg(org) && !isAcademicTrialOrg(org);
}

export function isNonPersonalAcademicOrg(
  org: DeepPartial<Pick<Organization, 'orgType' | 'subscriptions'>>
): boolean {
  return !isPersonalOrg(org) && isAcademicOrg(org);
}

export function isTrialOrg(
  org: DeepPartial<Pick<Organization, 'stripeBillingInfo' | 'subscriptions'>>
): boolean {
  return (
    org.stripeBillingInfo?.status === 'trialing' ||
    orgHasOneOfSubscriptionTypes(org, [
      OrganizationSubscriptionType.ManualTrial,
      OrganizationSubscriptionType.UserLedTrial,
      OrganizationSubscriptionType.AcademicTrial,
    ])
  );
}

export function isNonPersonalTrialOrg(
  org: DeepPartial<
    Pick<Organization, 'orgType' | 'stripeBillingInfo' | 'subscriptions'>
  >
): boolean {
  return !isPersonalOrg(org) && isTrialOrg(org);
}

export function isNonPersonalPaidOrg(
  org: DeepPartial<
    Pick<Organization, 'orgType' | 'stripeBillingInfo' | 'subscriptions'>
  >
): boolean {
  return (
    org.orgType === OrgType.Organization &&
    !isNonPersonalAcademicOrg(org) &&
    !isNonPersonalTrialOrg(org)
  );
}

export function orgHasSubscriptionType(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>,
  subscriptionType: OrganizationSubscriptionType
): boolean {
  return orgHasOneOfSubscriptionTypes(org, [subscriptionType]);
}

export function orgHasOneOfSubscriptionTypes(
  {subscriptions}: DeepPartial<Pick<Organization, 'subscriptions'>>,
  subscriptionTypes: OrganizationSubscriptionType[]
): boolean {
  return isOneOf(subscriptions?.[0]?.subscriptionType, subscriptionTypes);
}

export function prettifyPlanName(name: string): string {
  return _.capitalize(name.replace(/_/g, ' '));
}
