import styled from 'styled-components';

export const BabylonContainer = styled.div<{disableFullscreen?: boolean}>`
  ${({disableFullscreen}) =>
    disableFullscreen &&
    `
    .fullscreen {
      display: none;
    }
  `}
`;
