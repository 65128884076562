import {PopupDropdown} from '@wandb/common/components/PopupDropdown';
import React, {memo} from 'react';
import {Checkbox} from 'semantic-ui-react';

import {Counts as RunCounts} from '../util/runs';
import {MultiStateCheckbox} from './MultiStateCheckbox';

interface RunSelectAllProps {
  runCounts?: RunCounts;
  allCheckedState: 'unchecked' | 'checked' | 'partial' | 'unknown';
  onlyShowSelected?: boolean;
  isInReport?: boolean;
  setOnlyShowSelected?(newSetting: boolean): void;
  selectAllVisible(): void; // copies visualized runs to temp selections
  onSelectAll(): void;
  onSelectNone(): void;
}

const RunSelectAllComp: React.FC<RunSelectAllProps> = ({
  allCheckedState,
  onSelectAll,
  onSelectNone,
  runCounts,
  onlyShowSelected,
  isInReport,
  setOnlyShowSelected,
  selectAllVisible,
}) => {
  const selectedCount =
    runCounts && runCounts.runs && runCounts.selected && runCounts.selected;
  const dropdownOptions = [
    {
      key: 'selectall',
      content: 'Visualize all',
      onClick: onSelectAll,
    },
    {
      key: 'selectnone',
      content: 'Visualize none',
      onClick: onSelectNone,
    },
    {
      key: 'selectallvisible',
      content: 'Select all visible runs',
      onClick: selectAllVisible,
    },
    {
      key: 'onlyshowselected',
      onClick: () => {
        if (setOnlyShowSelected) {
          setOnlyShowSelected(!onlyShowSelected);
        }
      },
      content: (
        <Checkbox
          toggle
          checked={onlyShowSelected}
          label={`Only show visualized${
            selectedCount ? ` (${selectedCount})` : ''
          }`}
        />
      ),
    },
  ];
  return (
    <PopupDropdown
      options={dropdownOptions}
      className="popup-dropdown--selectall"
      trigger={
        <MultiStateCheckbox
          data-test="all-runs-visibility-checkbox"
          onClick={() =>
            window.analytics?.track('Visibility dropdown clicked', {
              location: isInReport ? 'report' : 'runs table',
            })
          }
          checked={allCheckedState}
          theme="visibility"
        />
      }
    />
  );
};

export const RunSelectAll = memo(RunSelectAllComp);
